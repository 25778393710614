import { Injectable } from '@angular/core';
import {
  LoadProfile,
  UpdateLastSelectedVehicleType,
  UpdateUserBroadcastPremiumFeatureSettings,
} from './profile.actions';
import { State } from './profile.reducer';
import { Store } from '@ngrx/store';
import { profileQuery } from './profile.selectors';
import { UpdateUserBroadcastPremiumFeatureSettingsCommand } from '@vpfa/rest-api/admin';

@Injectable({ providedIn: 'root' })
export class ProfileFacade {
  profile$ = this.store.select(profileQuery.getUserProfile);
  userContext$ = this.store.select(profileQuery.getUserContext);
  contentLanguage$ = this.store.select(profileQuery.getContentLanguage);
  userContextBusinessId$ = this.store.select(profileQuery.getUserContextBusinessId);
  userContextCountryId$ = this.store.select(profileQuery.getUserContextCountryId);
  userContextCountryCode$ = this.store.select(profileQuery.getUserContextCountryCode);
  defaultBranchId$ = this.store.select(profileQuery.getDefaultBranchId);
  userBusinessDetails$ = this.store.select(profileQuery.getUserBusinessDetails);
  userBranchDetails$ = this.store.select(profileQuery.getUserBranchDetails);
  userId$ = this.store.select(profileQuery.getUserId);
  isLoading$ = this.store.select(profileQuery.isLoading);
  isAnyAdmin$ = this.store.select(profileQuery.getIsAnyAdmin);
  isCountryAdmin$ = this.store.select(profileQuery.getIsCountryAdmin);
  isBusinessAdmin$ = this.store.select(profileQuery.getIsBusinessAdmin);
  isSystemAdmin$ = this.store.select(profileQuery.getIsSystemAdmin);
  haveOneBranch$ = this.store.select(profileQuery.getHaveOneBranch);
  countryDateRangeThreshold$ = this.store.select(profileQuery.getCountryDateRangeThreshold);
  countryNatcodePolicy$ = this.store.select(profileQuery.getCountryNatcodePolicy);
  countryVrmRegexPattern$ = this.store.select(profileQuery.getCountryVrmRegexPattern);
  countryKbaRegexPattern$ = this.store.select(profileQuery.getCountryKbaRegexPattern);
  countryMatriculationNumberRegexPattern$ = this.store.select(profileQuery.getCountryMatriculationNumberRegexPattern);
  identificationVehicleType$ = this.store.select(profileQuery.getIdentificationVehicleType);
  hideBroadcastPremiumFeaturePopup$ = this.store.select(profileQuery.getHideBroadcastPremiumFeaturePopup);

  constructor(private store: Store<State>) {}

  loadUserProfile() {
    this.store.dispatch(new LoadProfile());
  }

  updateLastSelectedVehicleType(vehicleType: number) {
    this.store.dispatch(new UpdateLastSelectedVehicleType({ identificationVehicleType: vehicleType }));
  }

  updateUserBroadcastPremiumFeatureSettings(command: UpdateUserBroadcastPremiumFeatureSettingsCommand) {
    this.store.dispatch(new UpdateUserBroadcastPremiumFeatureSettings(command));
  }
}
