import { DateTime } from 'luxon';
import { DateRangeThresholdDto } from '@vpfa/rest-api/valuation';
import { getCurrentDate } from 'libs/utils/src/lib/dates/get-local-date';

export function firstRegistrationRange(
  current: string,
  prodBegin: string,
  prodEnd: string,
  countryDateRangeThresholdDto: DateRangeThresholdDto,
  timezone: string
): boolean {
  const earliestDate = DateTime.fromISO(prodBegin).minus({
    months: countryDateRangeThresholdDto?.startProductionMonths,
  });

  // We need to consider the case when prodEnd is null
  // it will be also considered as separate story but we need same how handle this ege case right now
  // for now if prodEnd is null the we could set it to start

  const latestProdDate = (prodEnd ? DateTime.fromISO(prodEnd) : DateTime.local().startOf('month'))
    .plus({
      months: countryDateRangeThresholdDto?.endProductionMonths,
    })
    .endOf('month');

  const latestDate = DateTime.min(DateTime.fromISO(getCurrentDate(timezone)).startOf('day'), latestProdDate);

  const currentDate = DateTime.fromISO(current);

  return !(currentDate >= earliestDate && currentDate <= latestDate);
}
