import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, debounceTime, filter, map, mapTo, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  AdjustmentsActionTypes,
  BusinessSpecificationLoadCompleted,
  BusinessSpecificationLoadError,
  CaseAdjustmentComplete,
  CaseAdjustmentFinished,
  CaseAdjustmentStarted,
  CaseUpdateAdjustmentComplete,
  CaseUpdateAdjustmentError,
  CaseUpdateIsReimportedAdjustment,
  CaseUpdateMarketSituationAdjustment,
  CaseUpdateMissingEquipmentAdjustment,
  CaseUpdateNumberOfOwnersAdjustment,
  CaseUpdateServiceHistoryAdjustment,
  CaseUpdateSpecialColorAdjustment,
  CaseUpdateSpecialUsageAdjustment,
  CaseUpdateTechnicalInspectionAdjustment,
  CaseUpdateAppreciationDepreciationAdjustment,
  LoadBusinessSpecification,
} from './adjustments.actions';
import { BusinessAdjustmentsViewService, CaseService } from '@vpfa/rest-api/valuation';
import { CasesFacade } from '../cases.facade';
import { AdjustmentsFacade } from './adjustments.facade';
import { ValuationAdjustmentType } from '@vpfa/shared/interfaces';

@Injectable()
export class AdjustmentsEffects {
  // ON START
   afterAnyCaseAdjustmentStarted$ = createEffect(() => this.actions$.pipe(
    ofType(
      AdjustmentsActionTypes.CaseUpdateMissingEquipmentAdjustment,
      AdjustmentsActionTypes.CaseUpdateNumberOfOwnersAdjustment,
      AdjustmentsActionTypes.CaseUpdateSpecialUsageAdjustment,
      AdjustmentsActionTypes.CaseUpdateSpecialColorAdjustment,
      AdjustmentsActionTypes.CaseUpdateAppreciationDepreciationAdjustment,
      AdjustmentsActionTypes.CaseUpdateMarketSituationAdjustment,
      AdjustmentsActionTypes.CaseUpdateServiceHistoryAdjustment,
      AdjustmentsActionTypes.CaseUpdateIsReimportedAdjustment,
      AdjustmentsActionTypes.CaseUpdateTechnicalInspectionAdjustment
    ),
    map(() => new CaseAdjustmentStarted())
  ));

  // ON COMPLETE / ERROR
   afterAnyCaseAdjustmentCompleted$ = createEffect(() => this.actions$.pipe(
    ofType(AdjustmentsActionTypes.CaseUpdateAdjustmentComplete, AdjustmentsActionTypes.CaseUpdateAdjustmentError),
    map(() => new CaseAdjustmentComplete())
  ));

  // ON FINISHED

   loadCaseWhenAllAdjustmentsComplete$ = createEffect(() => this.actions$.pipe(
    ofType(AdjustmentsActionTypes.CaseAdjustmentComplete),
    debounceTime(1000),
    withLatestFrom(this.adjustmentsFacade.adjustmentsInProgress$, (action, progress) => ({ progress, action })),
    filter(({ progress }) => progress === 0),
    mapTo(new CaseAdjustmentFinished())
  ));

   loadCaseWhenAllAdjustmentsFinished$ = createEffect(() => this.actions$.pipe(
    ofType(AdjustmentsActionTypes.CaseAdjustmentFinished),
    withLatestFrom(this.casesFacade.activeCaseId$),
    map(([_, caseId]) => this.casesFacade.updateCaseRequestValuation(caseId, true))
  ), { dispatch: false });

   caseUpdateNumberOfOwnersAdjustmentError$ = createEffect(() => this.actions$.pipe(
    ofType<CaseUpdateAdjustmentError>(AdjustmentsActionTypes.CaseUpdateAdjustmentError),
    filter(({ payload }) => {
      return (
        payload === ValuationAdjustmentType.NumberOfOwnersAdjustment ||
        payload === ValuationAdjustmentType.SpecialUsageAdjustment
      );
    }),
    mapTo(new LoadBusinessSpecification())
  ));

  // BUSINESS SPECIFICATION

   loadBusinessSpecification$ = createEffect(() => this.actions$.pipe(
    ofType<LoadBusinessSpecification>(AdjustmentsActionTypes.LoadBusinessSpecification),
    switchMap(() =>
      this.businessAdjustmentsViewService.get().pipe(
        map(res => new BusinessSpecificationLoadCompleted(res)),
        catchError(() => of(new BusinessSpecificationLoadError()))
      )
    )
  ));

  // NUMBER OF OWNERS

   caseUpdateNumberOfOwnersAdjustment$ = createEffect(() => this.actions$.pipe(
    ofType<CaseUpdateNumberOfOwnersAdjustment>(AdjustmentsActionTypes.CaseUpdateNumberOfOwnersAdjustment),
    concatMap(action =>
      this.caseService.updateNumberOfOwnersAdjustment(action.payload).pipe(
        map(() => new CaseUpdateAdjustmentComplete()),
        catchError(() => of(new CaseUpdateAdjustmentError(ValuationAdjustmentType.NumberOfOwnersAdjustment)))
      )
    )
  ));

  // SPECIAL USAGE

   caseUpdateSpecialUsageAdjustment$ = createEffect(() => this.actions$.pipe(
    ofType<CaseUpdateSpecialUsageAdjustment>(AdjustmentsActionTypes.CaseUpdateSpecialUsageAdjustment),
    concatMap(action =>
      this.caseService.updateSpecialUsageAdjustment(action.payload).pipe(
        map(() => new CaseUpdateAdjustmentComplete()),
        catchError(() => of(new CaseUpdateAdjustmentError(ValuationAdjustmentType.SpecialUsageAdjustment)))
      )
    )
  ));

  // SPECIAL COLOR

   caseUpdateSpecialColorAdjustment$ = createEffect(() => this.actions$.pipe(
    ofType<CaseUpdateSpecialColorAdjustment>(AdjustmentsActionTypes.CaseUpdateSpecialColorAdjustment),
    concatMap(action =>
      this.caseService.updateSpecialColorAdjustment(action.payload).pipe(
        map(() => new CaseUpdateAdjustmentComplete()),
        catchError(() => of(new CaseUpdateAdjustmentError(ValuationAdjustmentType.SpecialColorAdjustment)))
      )
    )
  ));

  // APPRECIATION DEPRECIATION

   caseUpdateAppreciationDepreciationAdjustment$ = createEffect(() => this.actions$.pipe(
    ofType<CaseUpdateAppreciationDepreciationAdjustment>(
      AdjustmentsActionTypes.CaseUpdateAppreciationDepreciationAdjustment
    ),
    concatMap(action =>
      this.caseService.updateAppreciationDepreciationAdjustment(action.payload).pipe(
        map(() => new CaseUpdateAdjustmentComplete()),
        catchError(() => of(new CaseUpdateAdjustmentError(ValuationAdjustmentType.AppreciationDepreciationAdjustment)))
      )
    )
  ));

  // MARKET SITUATION

   caseUpdateMarketSituationAdjustment$ = createEffect(() => this.actions$.pipe(
    ofType<CaseUpdateMarketSituationAdjustment>(AdjustmentsActionTypes.CaseUpdateMarketSituationAdjustment),
    concatMap(action =>
      this.caseService.updateMarketSituationAdjustment(action.payload).pipe(
        map(() => new CaseUpdateAdjustmentComplete()),
        catchError(() => of(new CaseUpdateAdjustmentError(ValuationAdjustmentType.MarketSituationAdjustment)))
      )
    )
  ));

  // TECHNICAL INSPECTION

   caseUpdateTechnicalAdjustment$ = createEffect(() => this.actions$.pipe(
    ofType<CaseUpdateTechnicalInspectionAdjustment>(AdjustmentsActionTypes.CaseUpdateTechnicalInspectionAdjustment),
    concatMap(action =>
      this.caseService.updateTechnicalInspectionAdjustment(action.payload).pipe(
        map(() => new CaseUpdateAdjustmentComplete()),
        catchError(() => of(new CaseUpdateAdjustmentError(ValuationAdjustmentType.TechnicalInspectionAdjustment)))
      )
    )
  ));

  // SERVICE HISTORY

   caseUpdateServiceHistoryAdjustment$ = createEffect(() => this.actions$.pipe(
    ofType<CaseUpdateServiceHistoryAdjustment>(AdjustmentsActionTypes.CaseUpdateServiceHistoryAdjustment),
    concatMap(action =>
      this.caseService.updateServiceHistory(action.payload).pipe(
        map(() => new CaseUpdateAdjustmentComplete()),
        catchError(() => of(new CaseUpdateAdjustmentError()))
      )
    )
  ));

  // REIMPORTED

   CaseUpdateIsReimportedAdjustment$ = createEffect(() => this.actions$.pipe(
    ofType<CaseUpdateIsReimportedAdjustment>(AdjustmentsActionTypes.CaseUpdateIsReimportedAdjustment),
    concatMap(action =>
      this.caseService.updateReimport(action.payload).pipe(
        map(() => new CaseUpdateAdjustmentComplete()),
        catchError(() => of(new CaseUpdateAdjustmentError()))
      )
    )
  ));

  // MISSING EQUIPMENT

   caseUpdateMissingEquipmentAdjustment = createEffect(() => this.actions$.pipe(
    ofType<CaseUpdateMissingEquipmentAdjustment>(AdjustmentsActionTypes.CaseUpdateMissingEquipmentAdjustment),
    switchMap(action =>
      this.caseService.updateMissingEquipmentAdjustment(action.payload).pipe(
        map(() => new CaseUpdateAdjustmentComplete()),
        catchError(() => of(new CaseUpdateAdjustmentError()))
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private caseService: CaseService,
    private businessAdjustmentsViewService: BusinessAdjustmentsViewService,
    private casesFacade: CasesFacade,
    private adjustmentsFacade: AdjustmentsFacade
  ) {}
}
