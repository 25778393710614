import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { DeploymentVersionService, EnvironmentsService } from '@vpfa/environments/data';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AssetsTranslateFileDir, CdnTranslateFileDir } from '../constants/translate-file-dir';
import { deploymentVersionParamName, DummyFilename, EnInternationalFilename } from '../constants/translate-file-name';
import { defaultUiConfig, setNgZorroLocale } from '../utils/language.utils';

export class CustomTranslateHttpLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentsService,
    public suffix: string = '.json',
    private defaultLangName: string,
    private deploymentVersionService: DeploymentVersionService,
    private i18n: NzI18nService
  ) {}

  /**
   * Gets the translations from the server
   */
  public getTranslation(lang: string): Observable<Object> {
    const defaultLangPath = `${AssetsTranslateFileDir}${this.defaultLangName}${this.suffix}`;

    return this.environmentService.configLoaded$.pipe(
      switchMap(configLoaded => {
        if (configLoaded && lang !== EnInternationalFilename && lang !== DummyFilename) {
          const deploymentVersion = this.deploymentVersionService.currentVersion;
          const prefix = this.environmentService.config.cdnUrl + CdnTranslateFileDir;
          const filePath = `${prefix}${lang}${this.suffix}${deploymentVersionParamName}${deploymentVersion}`;

          return this.http.get(filePath).pipe(
            catchError(() => {
              console.warn(`file not found: "${filePath}", using default language: "${defaultLangPath}"`);
              setNgZorroLocale(defaultUiConfig.uiLang, this.i18n);
              return this.http.get(defaultLangPath);
            })
          );
        }

        if (lang === DummyFilename) {
          return this.http.get(`${AssetsTranslateFileDir}${lang}${this.suffix}`);
        }

        return this.http.get(defaultLangPath);
      })
    );
  }
}
