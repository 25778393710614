import { ImageFrameAction, ImageFrameActionTypes } from './image-frame.actions';
import { produce } from 'immer';
import { ImageFrameListItemDto } from '@vpfa/rest-api/valuation';

export const IMAGE_FRAME_FEATURE_KEY = 'imageFrame';

export interface ImageFramePartialState {
  readonly [IMAGE_FRAME_FEATURE_KEY]: ImageFrameState;
}

export interface ImageFramesList {
  loading: boolean;
  data: ImageFrameListItemDto[];
}

export interface ImageFramesEditor {
  loading: boolean;
  url: string;
}

export interface ModalState {
  isOpen: boolean;
  isProcessing: boolean;
}

export interface FramedImage {
  loading: boolean,
  preview: any
}


export interface ImageFrameState {
  list: ImageFramesList;
  editor: ImageFramesEditor;
  createFrameModal: ModalState;
  renameFrameModal: ModalState;
  framedImage: FramedImage,
}

export const initialState: ImageFrameState = {
  list: {
    loading: true,
    data: [],
  },
  editor: {
    loading: false,
    url: null,
  },
  createFrameModal: {
    isOpen: false,
    isProcessing: false,
  },
  renameFrameModal: {
    isOpen: false,
    isProcessing: false,
  },
  framedImage: {
    loading: false,
    preview: null
  }
};

export function reducer(state: ImageFrameState = initialState, action: ImageFrameAction): ImageFrameState {
  switch (action.type) {
    case ImageFrameActionTypes.BusinessImageFrameLoad:
      return produce(state, newState => {
        newState.list.loading = true;
      });
    case ImageFrameActionTypes.BusinessImageFrameLoaded:
      return produce(state, newState => {
        newState.list.data = action.payload;
        newState.list.loading = false;
      });
    case ImageFrameActionTypes.BusinessImageFrameLoadError:
      return produce(state, newState => {
        newState.list.loading = false;
      });

    case ImageFrameActionTypes.FramedImagePreviewLoad:
      return produce(state, newState => {
        newState.framedImage.loading = true;
      });
    case ImageFrameActionTypes.FramedImagePreviewLoaded:
      return produce(state, newState => {
        newState.framedImage.preview = action.payload.preview;
        newState.framedImage.loading = false;
      });
    case ImageFrameActionTypes.FramedImagePreviewLoadError:
      return produce(state, newState => {
        newState.framedImage.loading = false;
      });

    case ImageFrameActionTypes.ImageFramesLoad:
      return produce(state, newState => {
        newState.list.loading = true;
      });
    case ImageFrameActionTypes.ImageFramesLoaded:
      return produce(state, newState => {
        newState.list.data = action.payload;
        newState.list.loading = false;
      });
    case ImageFrameActionTypes.ImageFramesLoadError:
      return produce(state, newState => {
        newState.list.loading = false;
      });

    case ImageFrameActionTypes.BusinessImageFrameCreate:
      return produce(state, newState => {
        newState.list.loading = true;
        newState.createFrameModal.isProcessing = true;
      });
    case ImageFrameActionTypes.BusinessImageFrameCreated: {
      return produce(state, newState => {
        newState.list.loading = false;
        newState.createFrameModal.isProcessing = false;
        newState.createFrameModal.isOpen = false;
      });
    }
    case ImageFrameActionTypes.BusinessImageFrameCreateError:
      return produce(state, newState => {
        newState.list.loading = false;
        newState.createFrameModal.isProcessing = false;
      });

    case ImageFrameActionTypes.BusinessImageFrameUpdate:
      return produce(state, newState => {
        newState.list.loading = true;
        newState.renameFrameModal.isProcessing = true;
      });
    case ImageFrameActionTypes.BusinessImageFrameUpdated:
      return produce(state, newState => {
        newState.list.loading = false;
        newState.renameFrameModal.isProcessing = false;
        newState.renameFrameModal.isOpen = false;
      });
    case ImageFrameActionTypes.BusinessImageFrameUpdateError:
      return produce(state, newState => {
        newState.list.loading = false;
        newState.renameFrameModal.isProcessing = false;
      });

    case ImageFrameActionTypes.ImageFrameEditorURLLoad:
      return produce(state, newState => {
        newState.editor.loading = true;
      });
    case ImageFrameActionTypes.ImageFrameEditorURLLoaded:
      return produce(state, newState => {
        newState.editor.url = action.payload.url;
        newState.editor.loading = false;
      });
    case ImageFrameActionTypes.ImageFrameEditorURLLoadError:
      return produce(state, newState => {
        newState.editor.loading = false;
      });

    case ImageFrameActionTypes.CreateImageFrameModalOpen:
      return produce(state, newState => {
        newState.createFrameModal.isOpen = true;
      });
    case ImageFrameActionTypes.CreateImageFrameModalClose:
      return produce(state, newState => {
        newState.createFrameModal.isOpen = false;
      });
    case ImageFrameActionTypes.RenameImageFrameModalOpen:
      return produce(state, newState => {
        newState.renameFrameModal.isOpen = true;
      });
    case ImageFrameActionTypes.RenameImageFrameModalClose:
      return produce(state, newState => {
        newState.renameFrameModal.isOpen = false;
      });

    case ImageFrameActionTypes.ImageFrameDelete:
      return produce(state, newState => {
        newState.list.loading = true;
      });
    case ImageFrameActionTypes.ImageFrameDeleted:
    case ImageFrameActionTypes.ImageFrameDeleteError:
      return produce(state, newState => {
        newState.list.loading = false;
      });

    case ImageFrameActionTypes.FramedImagePreviewClear:
      return produce(state, newState => {
        newState.framedImage.preview = null;
      });
  }
  return state;
}
