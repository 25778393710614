import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { filter, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MakeDto } from '../models/make-dto';
import { ModelDto } from '../models/model-dto';
import { VehicleDto } from '../models/vehicle-dto';
import { SorcResponseDto } from '../models/sorc-response-dto';
import { trimEnd, uniqBy } from 'lodash';
import { EnvironmentsService } from '@vpfa/environments/data';
import { mapMakeDto } from '../utils/map-make-dto';
import { mapModelDto } from '../utils/map-model-dto';
import { mapVehicleDto } from '../utils/map-vehicle-dto';
import { makesListParams, vehiclesListParams } from '../utils/params-utils';
import { SelectedOptionalSearchFilters } from '../models/optional-search-filters';
import { MandatorySearchFilters } from '../models/mandatory-search-filters';
import { SorcDictionariesKeys, SorcDictionaryDto } from '../models/sorc-dictionary-dto';
import { mapDictionaryDto } from '../utils/map-dictionary-dto';

@Injectable({
  providedIn: 'root',
})
export class SorcIntegrationService {
  private SORC_URL: string;
  private SORC_LANGUAGE_CODE_CONVERSIONS: { [key: string]: string };

  constructor(private http: HttpClient, private environmentsService: EnvironmentsService) {
    this.environmentsService.configLoaded$
      .pipe(
        filter(loaded => loaded),
        take(1)
      )
      .subscribe(() => {
        this.SORC_URL = trimEnd(this.environmentsService.config?.sorcUrl, '/') + '/';
        this.SORC_LANGUAGE_CODE_CONVERSIONS = this.environmentsService.config?.sorcLanguageCodeConversions;
      });
  }

  /**
   * Getting list of makes by market and vehicle types
   *
   * @param countryCode
   * @param typeCodes - array because of possible combined vehicle types
   *
   * Example params: /makes?key=DE.10,DE.20,DE.40
   */
  public getMakesList(countryCode: string, typeCodes: number[]): Observable<MakeDto[]> {
    return this.http
      .get<SorcResponseDto>(`${this.SORC_URL}makes`, { params: makesListParams(typeCodes, countryCode) })
      .pipe(
        filter(response => response?.results?.length > 0),
        map(response => uniqBy(response?.results, 'code')),
        map(results => results?.map(make => mapMakeDto(make))),
        map(results => results?.sort((a, b) => a?.name?.localeCompare(b?.name)))
      );
  }

  /**
   * Getting list of models by market, vehicle types and make
   *
   * @param countryCode
   * @param typeCodes - array because of possible combined vehicle types
   * @param make
   */
  public getModelsList(countryCode: string, typeCodes: number[], make: MakeDto): Observable<ModelDto[]> {
    return this.http.get<SorcResponseDto>(`${this.SORC_URL}models/groups/${countryCode}/${make.code}`).pipe(
      filter(response => response?.results?.length > 0),
      map(response =>
        response?.results.filter(model => typeCodes.includes(model.vehtype?.id)).map(model => mapModelDto(make, model))
      ),
      map(results => results?.sort((a, b) => a?.name?.localeCompare(b?.name)))
    );
  }

  /**
   * Getting page of vehicles by market and model, filtered by mandatory and optional filters
   *
   * @param countryCode
   * @param contentLanguage
   * @param model
   * @param pageSize
   * @param mandatorySearchFilters - registrationDate, buildDate
   * @param optionalSearchFilters - numDoors, transmissionType, bodyType, fuelType, edition, engineCapacity, wheelbase, grossWeight
   */
  public getVehiclesList(
    countryCode: string,
    contentLanguage: string,
    model: ModelDto,
    pageSize: number,
    mandatorySearchFilters: MandatorySearchFilters,
    optionalSearchFilters?: SelectedOptionalSearchFilters
  ) {
    return this.http
      .get<SorcResponseDto>(
        `${this.SORC_URL}vehicles/groups/${countryCode}/${model?.make?.code}/${model?.code}/${model?.type}`,
        {
          params: vehiclesListParams(pageSize, mandatorySearchFilters, optionalSearchFilters),
        }
      )
      .pipe(
        map(
          response =>
            <SorcResponseDto<VehicleDto>>{
              totalResults: response.totalResults,
              totalElements: response.totalElements,
              results: response.results?.map(vehicle =>
                mapVehicleDto(vehicle, countryCode, contentLanguage, this.SORC_LANGUAGE_CODE_CONVERSIONS)
              ),
              values: response.values,
            }
        )
      );
  }

  /**
   * Getting list of descriptions by market and dictionary name
   *
   * @param countryCode
   * @param contentLanguage
   * @param dictionaryName - transmissiontype, fueltype, bodytype
   */
  public getDictionariesList(
    countryCode: string,
    contentLanguage: string,
    dictionaryName: SorcDictionariesKeys
  ): Observable<SorcDictionaryDto[]> {
    return this.http.get<SorcResponseDto>(`${this.SORC_URL}dictionaries/${dictionaryName}/${countryCode}`).pipe(
      filter(response => response?.results?.length > 0),
      map(response =>
        response?.results.map(dictionary =>
          mapDictionaryDto(dictionary, countryCode, contentLanguage, this.SORC_LANGUAGE_CODE_CONVERSIONS)
        )
      )
    );
  }
}
