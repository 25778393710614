import { Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { isNil } from 'lodash';

export const natCodeValidatorKey = 'natCode';

export function natCodeValidator(max: number): ValidatorFn {
  return (control: AbstractControl) => {
    const r = new RegExp(`^\\d{1,${max}}$`);
    const validateErrors = Validators.pattern(r)(control);
    if (isNil(validateErrors)) {
      return null;
    }
    return {
      [natCodeValidatorKey]: true,
    };
  }
}
