export const TEXT_LINE_MAX_LENGTH = 100;
export const EMAIL_MAX_LENGTH = 50;

export const TEXT_AREA_MAX_LENGTH = 5000;

export const F_PRICE_MIN = -999999.99;
export const PRICE_ZERO = 0;
export const F_PRICE_MAX = 999999.99;

export const MILEAGE_MIN = 0;
export const MILEAGE_MAX = 9999999;
export const MILEAGE_MAX_LENGTH = 7;

export const PERCENTS_MIN = -100.0;
export const PERCENTS_ZERO = 0;
export const PERCENTS_MAX = 100.0;

export const UC_NUMBER_MAX_LENGTH = 25;

export const GENERIC_NUMBER_MIN = 0;
export const GENERIC_NUMBER_MAX = 9999999;
