<vpfa-field-container
  [isCurrency]="isCurrency"
  [isNumber]="!isCurrency"
  [isText]="false"
  [displayErrors]="shouldDisplayErrors()"
  [control]="getFormControl()"
  [errorMessages]="errorMessages"
  [errorMsgVertPosition]="errorMsgVertPosition"
  [heightCompensationPx]="heightCompensationPx"
>
  <nz-input-group
    [fxFlex]="disableMaxWidth == false ? fieldWidth : ''"
    [nzPrefix]="(isCurrency && currencyPrefix) || showAsNegative ? prefix : ''"
    [nzSuffix]="isCurrency && !currencyPrefix ? suffix : clearSuffix"
    [nzSize]="size"
  >
    <input
      #input
      (focus)="onFocus()"
      (blur)="onBlur()"
      (keydown.enter)="submitValue.emit()"
      (keyup.esc)="escapeKeyAction.emit()"
      [formControl]="getFormControl()"
      [maxCharCount]="getDigitLimit()"
      [maxFractionNumber]="maxFractionNumber"
      [showFractionZeros]="showFractionZeros"
      [ngClass]="
        getInputClass(
          activeCurrencyDisplayFormat$ | async,
          currencyHintMode,
          getErrors(),
          isCurrency,
          shouldDisplayErrors(),
          hideErrorsOnBlur,
          !currencyPrefix,
          showAsNegative,
          left,
          right
        )
      "
      [placeholder]="placeholder"
      [style]="style"
      [readOnly]="readonly"
      nz-input
      type="text"
      vpfaCharCountLimiter
      vpfaNumberFormatter
      [allowNegativeNumber]="allowNegativeNumber"
      [convertNegativeValue]="convertNegativeValue"
    />
  </nz-input-group>
</vpfa-field-container>

<ng-template #prefix>
  <vpfa-active-currency
    *ngIf="isCurrency && currencyPrefix"
    (activeCurrencyMode)="activeCurrencyDisplayFormat$.next($event)"
    [currencyDisplayFormat]="currencyHintMode"
    [inputHintMode]="!!currencyHintMode"
  ></vpfa-active-currency>
  <span *ngIf="showAsNegative" class="negative-sign">-</span>
</ng-template>

<ng-template #suffix>
  <ng-container *ngTemplateOutlet="clearSuffix"></ng-container>

  <vpfa-active-currency
    *ngIf="isCurrency && !currencyPrefix"
    (activeCurrencyMode)="activeCurrencyDisplayFormat$.next($event)"
    [currencyDisplayFormat]="currencyHintMode"
    [inputHintMode]="!!currencyHintMode"
  ></vpfa-active-currency>
</ng-template>

<ng-template #clearSuffix>
  @if (allowClear) {
    <button class="clear-btn" [ngClass]="{ 'has-focus': hasFocus }" (click)="onClear()">
      <span nz-icon nzType="close-circle" nzTheme="fill"></span>
    </button>
  }
</ng-template>
