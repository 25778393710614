import { Component } from '@angular/core';
import { IFloatingFilterParams, IFloatingFilter, TextFilterModel, TextFilter } from 'ag-grid-community';
import { AgFrameworkComponent } from 'ag-grid-angular';

export interface NumberFloatingFilterParams extends IFloatingFilterParams {
  value: string;
  maxFractionNumber: number;
  maxCharCount: number;
}

@Component({
  selector: 'vpfa-number-floating-filter',
  templateUrl: './number-floating-filter.component.html',
  styleUrls: ['./number-floating-filter.component.scss'],
})
export class NumberFloatingFilterComponent
  implements IFloatingFilter, AgFrameworkComponent<NumberFloatingFilterParams> {
  private params: NumberFloatingFilterParams;

  public value: string;

  agInit(params: NumberFloatingFilterParams): void {
    this.value = params.value;
    this.params = params;
  }

  valueChanged() {
    this.params.parentFilterInstance((instance: TextFilter) => {
      if (this.value) {
        instance.onFloatingFilterChanged('contains', this.value);
      } else {
        instance.onFloatingFilterChanged('contains', null);
      }
    });
  }

  onParentModelChanged(parentModel: TextFilterModel): void {
    if (parentModel?.filter ?? null === this.value) {
      return;
    }
    if (!parentModel) {
      this.value = null;
    } else {
      this.value = parentModel.filter;
    }
  }

  get maxFractionNumber(): number {
    return this.params?.maxFractionNumber ?? 2;
  }

  get maxCharCount(): number {
    return this.params?.maxCharCount ?? 0;
  }
}
