import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { NotificationType } from '../../models/notification-type.enum';
import { NotificationModel } from '../../models/notification.model';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'vpfa-notification-content',
  templateUrl: './notification-content.component.html',
  styleUrls: ['./notification-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationContentComponent {
  @Input() notificationGroupList: KeyValue<any, NotificationModel[]>[];
  @Input() hideCount: boolean;

  getClass(type: NotificationType, additional: string) {
    switch (type) {
      case NotificationType.Success:
        return `success ${additional}`;
      case NotificationType.Warning:
        return `warning ${additional}`;
      case NotificationType.Error:
        return `error ${additional}`;
      case NotificationType.Mistake:
        return `mistake ${additional}`;
    }
  }
}
