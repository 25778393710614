import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LogoState, LOGO_FEATURE_KEY } from './logo.reducer';

const getLogoState = createFeatureSelector<LogoState>(LOGO_FEATURE_KEY);
const getBusinessState = createSelector(getLogoState, state => state.business);
const getCountryState = createSelector(getLogoState, state => state.country);

const getBusinessLogo = createSelector(getBusinessState, state => state.logo);
const getCountryLogo = createSelector(getCountryState, state => state.logo);

export const logoQuery = {
  getBusinessLogo,
  getCountryLogo,
};
