import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { MappingService } from './api/mapping.service';
import { MappingViewService } from './api/mappingView.service';
import { TemplateService } from './api/template.service';
import { TemplateSeedService } from './api/templateSeed.service';
import { TemplateViewService } from './api/templateView.service';
import { TransformService } from './api/transform.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    MappingService,
    MappingViewService,
    TemplateService,
    TemplateSeedService,
    TemplateViewService,
    TransformService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}