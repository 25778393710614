/**
 * Public SSM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0-private
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { ApiCaseBody } from '../model/apiCaseBody';
import { ApiCaseBody1 } from '../model/apiCaseBody1';
import { ApiCaseBody2 } from '../model/apiCaseBody2';
import { ApiCaseBody3 } from '../model/apiCaseBody3';
import { CaseAppreciationDepreciationAdjustmentBody } from '../model/caseAppreciationDepreciationAdjustmentBody';
import { CaseAppreciationDepreciationAdjustmentBody1 } from '../model/caseAppreciationDepreciationAdjustmentBody1';
import { CaseAppreciationDepreciationAdjustmentBody2 } from '../model/caseAppreciationDepreciationAdjustmentBody2';
import { CaseAppreciationDepreciationAdjustmentBody3 } from '../model/caseAppreciationDepreciationAdjustmentBody3';
import { CaseCancelReservationBody } from '../model/caseCancelReservationBody';
import { CaseCancelReservationBody1 } from '../model/caseCancelReservationBody1';
import { CaseCancelReservationBody2 } from '../model/caseCancelReservationBody2';
import { CaseCancelReservationBody3 } from '../model/caseCancelReservationBody3';
import { CaseCopyBody } from '../model/caseCopyBody';
import { CaseCopyBody1 } from '../model/caseCopyBody1';
import { CaseCopyBody2 } from '../model/caseCopyBody2';
import { CaseCopyBody3 } from '../model/caseCopyBody3';
import { CaseExpertAreaParametersBody } from '../model/caseExpertAreaParametersBody';
import { CaseExpertAreaParametersBody1 } from '../model/caseExpertAreaParametersBody1';
import { CaseExpertAreaParametersBody2 } from '../model/caseExpertAreaParametersBody2';
import { CaseExpertAreaParametersBody3 } from '../model/caseExpertAreaParametersBody3';
import { CaseLightDamageEstimatorBody } from '../model/caseLightDamageEstimatorBody';
import { CaseLightDamageEstimatorBody1 } from '../model/caseLightDamageEstimatorBody1';
import { CaseLightDamageEstimatorBody2 } from '../model/caseLightDamageEstimatorBody2';
import { CaseLightDamageEstimatorBody3 } from '../model/caseLightDamageEstimatorBody3';
import { CaseLoadVinEreDataBody } from '../model/caseLoadVinEreDataBody';
import { CaseLoadVinEreDataBody1 } from '../model/caseLoadVinEreDataBody1';
import { CaseLoadVinEreDataBody2 } from '../model/caseLoadVinEreDataBody2';
import { CaseLoadVinEreDataBody3 } from '../model/caseLoadVinEreDataBody3';
import { CaseLoadVinEreDataCommandHandlerResponse } from '../model/caseLoadVinEreDataCommandHandlerResponse';
import { CaseMarketSituationAdjustmentBody } from '../model/caseMarketSituationAdjustmentBody';
import { CaseMarketSituationAdjustmentBody1 } from '../model/caseMarketSituationAdjustmentBody1';
import { CaseMarketSituationAdjustmentBody2 } from '../model/caseMarketSituationAdjustmentBody2';
import { CaseMarketSituationAdjustmentBody3 } from '../model/caseMarketSituationAdjustmentBody3';
import { CaseMissingEquipmentAdjustmentBody } from '../model/caseMissingEquipmentAdjustmentBody';
import { CaseMissingEquipmentAdjustmentBody1 } from '../model/caseMissingEquipmentAdjustmentBody1';
import { CaseMissingEquipmentAdjustmentBody2 } from '../model/caseMissingEquipmentAdjustmentBody2';
import { CaseMissingEquipmentAdjustmentBody3 } from '../model/caseMissingEquipmentAdjustmentBody3';
import { CaseMoveFromDueToInStockBody } from '../model/caseMoveFromDueToInStockBody';
import { CaseMoveFromDueToInStockBody1 } from '../model/caseMoveFromDueToInStockBody1';
import { CaseMoveFromDueToInStockBody2 } from '../model/caseMoveFromDueToInStockBody2';
import { CaseMoveFromDueToInStockBody3 } from '../model/caseMoveFromDueToInStockBody3';
import { CaseNoOfOwnersAdjustmentBody } from '../model/caseNoOfOwnersAdjustmentBody';
import { CaseNoOfOwnersAdjustmentBody1 } from '../model/caseNoOfOwnersAdjustmentBody1';
import { CaseNoOfOwnersAdjustmentBody2 } from '../model/caseNoOfOwnersAdjustmentBody2';
import { CaseNoOfOwnersAdjustmentBody3 } from '../model/caseNoOfOwnersAdjustmentBody3';
import { CaseNotesBody } from '../model/caseNotesBody';
import { CaseNotesBody1 } from '../model/caseNotesBody1';
import { CaseNotesBody2 } from '../model/caseNotesBody2';
import { CaseNotesBody3 } from '../model/caseNotesBody3';
import { CaseOwnersBody } from '../model/caseOwnersBody';
import { CaseOwnersBody1 } from '../model/caseOwnersBody1';
import { CaseOwnersBody2 } from '../model/caseOwnersBody2';
import { CaseOwnersBody3 } from '../model/caseOwnersBody3';
import { CasePlateNumberBody } from '../model/casePlateNumberBody';
import { CasePlateNumberBody1 } from '../model/casePlateNumberBody1';
import { CasePlateNumberBody2 } from '../model/casePlateNumberBody2';
import { CasePlateNumberBody3 } from '../model/casePlateNumberBody3';
import { CasePreviousOwnersBody } from '../model/casePreviousOwnersBody';
import { CasePreviousOwnersBody1 } from '../model/casePreviousOwnersBody1';
import { CasePreviousOwnersBody2 } from '../model/casePreviousOwnersBody2';
import { CasePreviousOwnersBody3 } from '../model/casePreviousOwnersBody3';
import { CaseProspectCustomersBody } from '../model/caseProspectCustomersBody';
import { CaseProspectCustomersBody1 } from '../model/caseProspectCustomersBody1';
import { CaseProspectCustomersBody2 } from '../model/caseProspectCustomersBody2';
import { CaseProspectCustomersBody3 } from '../model/caseProspectCustomersBody3';
import { CaseProvenanceCheckBody } from '../model/caseProvenanceCheckBody';
import { CaseProvenanceCheckBody1 } from '../model/caseProvenanceCheckBody1';
import { CaseProvenanceCheckBody2 } from '../model/caseProvenanceCheckBody2';
import { CaseProvenanceCheckBody3 } from '../model/caseProvenanceCheckBody3';
import { CasePublicSiteConfigurationBody } from '../model/casePublicSiteConfigurationBody';
import { CasePublicSiteConfigurationBody1 } from '../model/casePublicSiteConfigurationBody1';
import { CasePublicSiteConfigurationBody2 } from '../model/casePublicSiteConfigurationBody2';
import { CasePublicSiteConfigurationBody3 } from '../model/casePublicSiteConfigurationBody3';
import { CasePurchaseVehicleBody } from '../model/casePurchaseVehicleBody';
import { CasePurchaseVehicleBody1 } from '../model/casePurchaseVehicleBody1';
import { CasePurchaseVehicleBody2 } from '../model/casePurchaseVehicleBody2';
import { CasePurchaseVehicleBody3 } from '../model/casePurchaseVehicleBody3';
import { CaseQuoteBody } from '../model/caseQuoteBody';
import { CaseQuoteBody1 } from '../model/caseQuoteBody1';
import { CaseQuoteBody2 } from '../model/caseQuoteBody2';
import { CaseQuoteBody3 } from '../model/caseQuoteBody3';
import { CaseReimportBody } from '../model/caseReimportBody';
import { CaseReimportBody1 } from '../model/caseReimportBody1';
import { CaseReimportBody2 } from '../model/caseReimportBody2';
import { CaseReimportBody3 } from '../model/caseReimportBody3';
import { CaseResellerSiteConfigurationBody } from '../model/caseResellerSiteConfigurationBody';
import { CaseResellerSiteConfigurationBody1 } from '../model/caseResellerSiteConfigurationBody1';
import { CaseResellerSiteConfigurationBody2 } from '../model/caseResellerSiteConfigurationBody2';
import { CaseResellerSiteConfigurationBody3 } from '../model/caseResellerSiteConfigurationBody3';
import { CaseReserveVehicleBody } from '../model/caseReserveVehicleBody';
import { CaseReserveVehicleBody1 } from '../model/caseReserveVehicleBody1';
import { CaseReserveVehicleBody2 } from '../model/caseReserveVehicleBody2';
import { CaseReserveVehicleBody3 } from '../model/caseReserveVehicleBody3';
import { CaseReturnReportBody } from '../model/caseReturnReportBody';
import { CaseReturnReportBody1 } from '../model/caseReturnReportBody1';
import { CaseReturnReportBody2 } from '../model/caseReturnReportBody2';
import { CaseReturnReportBody3 } from '../model/caseReturnReportBody3';
import { CaseReturnToStockBody } from '../model/caseReturnToStockBody';
import { CaseReturnToStockBody1 } from '../model/caseReturnToStockBody1';
import { CaseReturnToStockBody2 } from '../model/caseReturnToStockBody2';
import { CaseReturnToStockBody3 } from '../model/caseReturnToStockBody3';
import { CaseSellVehicleBody } from '../model/caseSellVehicleBody';
import { CaseSellVehicleBody1 } from '../model/caseSellVehicleBody1';
import { CaseSellVehicleBody2 } from '../model/caseSellVehicleBody2';
import { CaseSellVehicleBody3 } from '../model/caseSellVehicleBody3';
import { CaseServiceHistoryBody } from '../model/caseServiceHistoryBody';
import { CaseServiceHistoryBody1 } from '../model/caseServiceHistoryBody1';
import { CaseServiceHistoryBody2 } from '../model/caseServiceHistoryBody2';
import { CaseServiceHistoryBody3 } from '../model/caseServiceHistoryBody3';
import { CaseSoldPricesBody } from '../model/caseSoldPricesBody';
import { CaseSoldPricesBody1 } from '../model/caseSoldPricesBody1';
import { CaseSoldPricesBody2 } from '../model/caseSoldPricesBody2';
import { CaseSoldPricesBody3 } from '../model/caseSoldPricesBody3';
import { CaseSpecialColorAdjustmentBody } from '../model/caseSpecialColorAdjustmentBody';
import { CaseSpecialColorAdjustmentBody1 } from '../model/caseSpecialColorAdjustmentBody1';
import { CaseSpecialColorAdjustmentBody2 } from '../model/caseSpecialColorAdjustmentBody2';
import { CaseSpecialColorAdjustmentBody3 } from '../model/caseSpecialColorAdjustmentBody3';
import { CaseSpecialUsageAdjustmentBody } from '../model/caseSpecialUsageAdjustmentBody';
import { CaseSpecialUsageAdjustmentBody1 } from '../model/caseSpecialUsageAdjustmentBody1';
import { CaseSpecialUsageAdjustmentBody2 } from '../model/caseSpecialUsageAdjustmentBody2';
import { CaseSpecialUsageAdjustmentBody3 } from '../model/caseSpecialUsageAdjustmentBody3';
import { CaseStockOfferBody } from '../model/caseStockOfferBody';
import { CaseStockOfferBody1 } from '../model/caseStockOfferBody1';
import { CaseStockOfferBody2 } from '../model/caseStockOfferBody2';
import { CaseStockOfferBody3 } from '../model/caseStockOfferBody3';
import { CaseStockPricesBody } from '../model/caseStockPricesBody';
import { CaseStockPricesBody1 } from '../model/caseStockPricesBody1';
import { CaseStockPricesBody2 } from '../model/caseStockPricesBody2';
import { CaseStockPricesBody3 } from '../model/caseStockPricesBody3';
import { CaseTechnicalInspectionAdjustmentBody } from '../model/caseTechnicalInspectionAdjustmentBody';
import { CaseTechnicalInspectionAdjustmentBody1 } from '../model/caseTechnicalInspectionAdjustmentBody1';
import { CaseTechnicalInspectionAdjustmentBody2 } from '../model/caseTechnicalInspectionAdjustmentBody2';
import { CaseTechnicalInspectionAdjustmentBody3 } from '../model/caseTechnicalInspectionAdjustmentBody3';
import { CaseUniqueDataBody } from '../model/caseUniqueDataBody';
import { CaseUniqueDataBody1 } from '../model/caseUniqueDataBody1';
import { CaseUniqueDataBody2 } from '../model/caseUniqueDataBody2';
import { CaseUniqueDataBody3 } from '../model/caseUniqueDataBody3';
import { CaseUpdateRegistrationDateBody } from '../model/caseUpdateRegistrationDateBody';
import { CaseUpdateRegistrationDateBody1 } from '../model/caseUpdateRegistrationDateBody1';
import { CaseUpdateRegistrationDateBody2 } from '../model/caseUpdateRegistrationDateBody2';
import { CaseUpdateRegistrationDateBody3 } from '../model/caseUpdateRegistrationDateBody3';
import { CaseUpdateVehicleMileageBody } from '../model/caseUpdateVehicleMileageBody';
import { CaseUpdateVehicleMileageBody1 } from '../model/caseUpdateVehicleMileageBody1';
import { CaseUpdateVehicleMileageBody2 } from '../model/caseUpdateVehicleMileageBody2';
import { CaseUpdateVehicleMileageBody3 } from '../model/caseUpdateVehicleMileageBody3';
import { CaseUserVisitedExpertAreaBody } from '../model/caseUserVisitedExpertAreaBody';
import { CaseUserVisitedExpertAreaBody1 } from '../model/caseUserVisitedExpertAreaBody1';
import { CaseUserVisitedExpertAreaBody2 } from '../model/caseUserVisitedExpertAreaBody2';
import { CaseUserVisitedExpertAreaBody3 } from '../model/caseUserVisitedExpertAreaBody3';
import { CaseValuationDateBody } from '../model/caseValuationDateBody';
import { CaseValuationDateBody1 } from '../model/caseValuationDateBody1';
import { CaseValuationDateBody2 } from '../model/caseValuationDateBody2';
import { CaseValuationDateBody3 } from '../model/caseValuationDateBody3';
import { CaseValuationRequestBody } from '../model/caseValuationRequestBody';
import { CaseValuationRequestBody1 } from '../model/caseValuationRequestBody1';
import { CaseValuationRequestBody2 } from '../model/caseValuationRequestBody2';
import { CaseValuationRequestBody3 } from '../model/caseValuationRequestBody3';
import { CaseYoutubeUrlBody } from '../model/caseYoutubeUrlBody';
import { CaseYoutubeUrlBody1 } from '../model/caseYoutubeUrlBody1';
import { CaseYoutubeUrlBody2 } from '../model/caseYoutubeUrlBody2';
import { CaseYoutubeUrlBody3 } from '../model/caseYoutubeUrlBody3';
import { CommandHandlerResponse } from '../model/commandHandlerResponse';
import { CreateStockOfferCommandHandlerResponse } from '../model/createStockOfferCommandHandlerResponse';
import { QuoteResendLatestQuoteBody } from '../model/quoteResendLatestQuoteBody';
import { QuoteResendLatestQuoteBody1 } from '../model/quoteResendLatestQuoteBody1';
import { QuoteResendLatestQuoteBody2 } from '../model/quoteResendLatestQuoteBody2';
import { QuoteResendLatestQuoteBody3 } from '../model/quoteResendLatestQuoteBody3';
import { ResellerOfferBody } from '../model/resellerOfferBody';
import { ResellerOfferBody1 } from '../model/resellerOfferBody1';
import { ResellerOfferBody2 } from '../model/resellerOfferBody2';
import { ResellerOfferBody3 } from '../model/resellerOfferBody3';
import { ResellerOfferIsFlaggedBody } from '../model/resellerOfferIsFlaggedBody';
import { ResellerOfferIsFlaggedBody1 } from '../model/resellerOfferIsFlaggedBody1';
import { ResellerOfferIsFlaggedBody2 } from '../model/resellerOfferIsFlaggedBody2';
import { ResellerOfferIsFlaggedBody3 } from '../model/resellerOfferIsFlaggedBody3';
import { ResellerOfferIsReadBody } from '../model/resellerOfferIsReadBody';
import { ResellerOfferIsReadBody1 } from '../model/resellerOfferIsReadBody1';
import { ResellerOfferIsReadBody2 } from '../model/resellerOfferIsReadBody2';
import { ResellerOfferIsReadBody3 } from '../model/resellerOfferIsReadBody3';
import { StockOfferResendLatestStockOfferBody } from '../model/stockOfferResendLatestStockOfferBody';
import { StockOfferResendLatestStockOfferBody1 } from '../model/stockOfferResendLatestStockOfferBody1';
import { StockOfferResendLatestStockOfferBody2 } from '../model/stockOfferResendLatestStockOfferBody2';
import { StockOfferResendLatestStockOfferBody3 } from '../model/stockOfferResendLatestStockOfferBody3';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CaseService {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * AmsAction:PUT/api/Case/CancelReservation AmsOptionalAction:MakeReservation AmsOptionalAction:CancelReservationsOfOtherUsers AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelReservation(body?: CaseCancelReservationBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public cancelReservation(body?: CaseCancelReservationBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public cancelReservation(body?: CaseCancelReservationBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public cancelReservation(body?: CaseCancelReservationBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/CancelReservation`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:POST/api/Case/copy AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public copyCase(body?: CaseCopyBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public copyCase(body?: CaseCopyBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public copyCase(body?: CaseCopyBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public copyCase(body?: CaseCopyBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommandHandlerResponse>(`${this.basePath}/api/Case/copy`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:POST/api/Case AmsResource:{country}/{business}/case 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCase(body?: ApiCaseBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public createCase(body?: ApiCaseBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public createCase(body?: ApiCaseBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public createCase(body?: ApiCaseBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommandHandlerResponse>(`${this.basePath}/api/Case`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/Quote AmsOptionalAction:MakeValuationWithoutVIN AmsOptionalAction:MakeValuationWithoutNumberPlate AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createQuote(body?: CaseQuoteBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public createQuote(body?: CaseQuoteBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public createQuote(body?: CaseQuoteBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public createQuote(body?: CaseQuoteBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/Quote`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/StockOffer AmsOptionalAction:MakeValuationWithoutVIN AmsOptionalAction:MakeValuationWithoutNumberPlate AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createStockOffer(body?: CaseStockOfferBody, observe?: 'body', reportProgress?: boolean): Observable<CreateStockOfferCommandHandlerResponse>;
    public createStockOffer(body?: CaseStockOfferBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateStockOfferCommandHandlerResponse>>;
    public createStockOffer(body?: CaseStockOfferBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateStockOfferCommandHandlerResponse>>;
    public createStockOffer(body?: CaseStockOfferBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CreateStockOfferCommandHandlerResponse>(`${this.basePath}/api/Case/StockOffer`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:DELETE/api/Case/{id} AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{id} 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCase(id: string, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public deleteCase(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public deleteCase(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public deleteCase(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteCase.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<CommandHandlerResponse>(`${this.basePath}/api/Case/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param businessId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBusinessCases(businessId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getBusinessCases(businessId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getBusinessCases(businessId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getBusinessCases(businessId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling getBusinessCases.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/Case/business/${encodeURIComponent(String(businessId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/LoadVinEreData AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loadVinEreData(body?: CaseLoadVinEreDataBody, observe?: 'body', reportProgress?: boolean): Observable<CaseLoadVinEreDataCommandHandlerResponse>;
    public loadVinEreData(body?: CaseLoadVinEreDataBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CaseLoadVinEreDataCommandHandlerResponse>>;
    public loadVinEreData(body?: CaseLoadVinEreDataBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CaseLoadVinEreDataCommandHandlerResponse>>;
    public loadVinEreData(body?: CaseLoadVinEreDataBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CaseLoadVinEreDataCommandHandlerResponse>(`${this.basePath}/api/Case/LoadVinEreData`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/MoveFromDueToInStock AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public moveFromDueToInStock(body?: CaseMoveFromDueToInStockBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public moveFromDueToInStock(body?: CaseMoveFromDueToInStockBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public moveFromDueToInStock(body?: CaseMoveFromDueToInStockBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public moveFromDueToInStock(body?: CaseMoveFromDueToInStockBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/MoveFromDueToInStock`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public provenanceCheck(body?: CaseProvenanceCheckBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public provenanceCheck(body?: CaseProvenanceCheckBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public provenanceCheck(body?: CaseProvenanceCheckBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public provenanceCheck(body?: CaseProvenanceCheckBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/ProvenanceCheck`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/PurchaseVehicle AmsAction:AddVehicleToStock AmsOptionalAction:MakeValuationWithoutVIN AmsOptionalAction:MakeValuationWithoutNumberPlate AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public purchaseVehicle(body?: CasePurchaseVehicleBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public purchaseVehicle(body?: CasePurchaseVehicleBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public purchaseVehicle(body?: CasePurchaseVehicleBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public purchaseVehicle(body?: CasePurchaseVehicleBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/PurchaseVehicle`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:POST/api/Case/Quote/ResendLatestQuote AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resendLatestQuote(body?: QuoteResendLatestQuoteBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public resendLatestQuote(body?: QuoteResendLatestQuoteBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public resendLatestQuote(body?: QuoteResendLatestQuoteBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public resendLatestQuote(body?: QuoteResendLatestQuoteBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommandHandlerResponse>(`${this.basePath}/api/Case/Quote/ResendLatestQuote`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:POST/api/Case/StockOffer/ResendLatestStockOffer AmsAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resendLatestStockOffer(body?: StockOfferResendLatestStockOfferBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public resendLatestStockOffer(body?: StockOfferResendLatestStockOfferBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public resendLatestStockOffer(body?: StockOfferResendLatestStockOfferBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public resendLatestStockOffer(body?: StockOfferResendLatestStockOfferBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommandHandlerResponse>(`${this.basePath}/api/Case/StockOffer/ResendLatestStockOffer`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/ReserveVehicle AmsAction:MakeReservation AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reserveVehicle(body?: CaseReserveVehicleBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public reserveVehicle(body?: CaseReserveVehicleBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public reserveVehicle(body?: CaseReserveVehicleBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public reserveVehicle(body?: CaseReserveVehicleBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/ReserveVehicle`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/ReturnToStock AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public returnToStock(body?: CaseReturnToStockBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public returnToStock(body?: CaseReturnToStockBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public returnToStock(body?: CaseReturnToStockBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public returnToStock(body?: CaseReturnToStockBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/ReturnToStock`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/SellVehicle AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sellVehicle(body?: CaseSellVehicleBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public sellVehicle(body?: CaseSellVehicleBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public sellVehicle(body?: CaseSellVehicleBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public sellVehicle(body?: CaseSellVehicleBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/SellVehicle`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:POST/api/Case/Reseller/Offer AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendResellerOffer(body?: ResellerOfferBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public sendResellerOffer(body?: ResellerOfferBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public sendResellerOffer(body?: ResellerOfferBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public sendResellerOffer(body?: ResellerOfferBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommandHandlerResponse>(`${this.basePath}/api/Case/Reseller/Offer`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/AppreciationDepreciationAdjustment AmsOptionalAction:SeeValuationsOfOthersUsers AmsOptionalAction:OverrideVehicleDetailsAdjustments AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAppreciationDepreciationAdjustment(body?: CaseAppreciationDepreciationAdjustmentBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateAppreciationDepreciationAdjustment(body?: CaseAppreciationDepreciationAdjustmentBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateAppreciationDepreciationAdjustment(body?: CaseAppreciationDepreciationAdjustmentBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateAppreciationDepreciationAdjustment(body?: CaseAppreciationDepreciationAdjustmentBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/AppreciationDepreciationAdjustment`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/ExpertAreaParameters AmsOptionalAction:MakeValuationWithoutVIN AmsOptionalAction:MakeValuationWithoutNumberPlate AmsOptionalAction:SeeValuationsOfOthersUsers AmsOptionalAction:EditStockPrices AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCaseCostParameters(body?: CaseExpertAreaParametersBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateCaseCostParameters(body?: CaseExpertAreaParametersBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateCaseCostParameters(body?: CaseExpertAreaParametersBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateCaseCostParameters(body?: CaseExpertAreaParametersBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/ExpertAreaParameters`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:POST/api/Case/lightDamageEstimator AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCaseLightDamageParts(body?: CaseLightDamageEstimatorBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateCaseLightDamageParts(body?: CaseLightDamageEstimatorBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateCaseLightDamageParts(body?: CaseLightDamageEstimatorBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateCaseLightDamageParts(body?: CaseLightDamageEstimatorBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommandHandlerResponse>(`${this.basePath}/api/Case/lightDamageEstimator`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/Notes AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCaseNotes(body?: CaseNotesBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateCaseNotes(body?: CaseNotesBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateCaseNotes(body?: CaseNotesBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateCaseNotes(body?: CaseNotesBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/Notes`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/PlateNumber AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCasePlateNumber(body?: CasePlateNumberBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateCasePlateNumber(body?: CasePlateNumberBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateCasePlateNumber(body?: CasePlateNumberBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateCasePlateNumber(body?: CasePlateNumberBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/PlateNumber`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/MarketSituationAdjustment AmsOptionalAction:SeeValuationsOfOthersUsers AmsOptionalAction:OverrideVehicleDetailsAdjustments AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMarketSituationAdjustment(body?: CaseMarketSituationAdjustmentBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateMarketSituationAdjustment(body?: CaseMarketSituationAdjustmentBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateMarketSituationAdjustment(body?: CaseMarketSituationAdjustmentBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateMarketSituationAdjustment(body?: CaseMarketSituationAdjustmentBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/MarketSituationAdjustment`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/MissingEquipmentAdjustment AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMissingEquipmentAdjustment(body?: CaseMissingEquipmentAdjustmentBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateMissingEquipmentAdjustment(body?: CaseMissingEquipmentAdjustmentBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateMissingEquipmentAdjustment(body?: CaseMissingEquipmentAdjustmentBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateMissingEquipmentAdjustment(body?: CaseMissingEquipmentAdjustmentBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/MissingEquipmentAdjustment`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/NoOfOwnersAdjustment AmsOptionalAction:SeeValuationsOfOthersUsers AmsOptionalAction:OverrideVehicleDetailsAdjustments AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateNumberOfOwnersAdjustment(body?: CaseNoOfOwnersAdjustmentBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateNumberOfOwnersAdjustment(body?: CaseNoOfOwnersAdjustmentBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateNumberOfOwnersAdjustment(body?: CaseNoOfOwnersAdjustmentBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateNumberOfOwnersAdjustment(body?: CaseNoOfOwnersAdjustmentBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/NoOfOwnersAdjustment`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/Owners AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOwners(body?: CaseOwnersBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateOwners(body?: CaseOwnersBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateOwners(body?: CaseOwnersBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateOwners(body?: CaseOwnersBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/Owners`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/PreviousOwners AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePreviousOwners(body?: CasePreviousOwnersBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updatePreviousOwners(body?: CasePreviousOwnersBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updatePreviousOwners(body?: CasePreviousOwnersBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updatePreviousOwners(body?: CasePreviousOwnersBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/PreviousOwners`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/ProspectCustomers AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProspectCustomers(body?: CaseProspectCustomersBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateProspectCustomers(body?: CaseProspectCustomersBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateProspectCustomers(body?: CaseProspectCustomersBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateProspectCustomers(body?: CaseProspectCustomersBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/ProspectCustomers`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/PublicSiteConfiguration AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePublicSiteConfiguration(body?: CasePublicSiteConfigurationBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updatePublicSiteConfiguration(body?: CasePublicSiteConfigurationBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updatePublicSiteConfiguration(body?: CasePublicSiteConfigurationBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updatePublicSiteConfiguration(body?: CasePublicSiteConfigurationBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/PublicSiteConfiguration`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/UpdateRegistrationDate AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateRegistrationDate(body?: CaseUpdateRegistrationDateBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateRegistrationDate(body?: CaseUpdateRegistrationDateBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateRegistrationDate(body?: CaseUpdateRegistrationDateBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateRegistrationDate(body?: CaseUpdateRegistrationDateBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/UpdateRegistrationDate`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/Reimport AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateReimport(body?: CaseReimportBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateReimport(body?: CaseReimportBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateReimport(body?: CaseReimportBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateReimport(body?: CaseReimportBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/Reimport`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/ResellerOffer/IsFlagged AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateResellerOfferIsFlagged(body?: ResellerOfferIsFlaggedBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateResellerOfferIsFlagged(body?: ResellerOfferIsFlaggedBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateResellerOfferIsFlagged(body?: ResellerOfferIsFlaggedBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateResellerOfferIsFlagged(body?: ResellerOfferIsFlaggedBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/ResellerOffer/IsFlagged`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/ResellerOffer/IsRead AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateResellerOfferIsRead(body?: ResellerOfferIsReadBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateResellerOfferIsRead(body?: ResellerOfferIsReadBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateResellerOfferIsRead(body?: ResellerOfferIsReadBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateResellerOfferIsRead(body?: ResellerOfferIsReadBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/ResellerOffer/IsRead`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/ResellerSiteConfiguration AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateResellerSiteConfiguration(body?: CaseResellerSiteConfigurationBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateResellerSiteConfiguration(body?: CaseResellerSiteConfigurationBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateResellerSiteConfiguration(body?: CaseResellerSiteConfigurationBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateResellerSiteConfiguration(body?: CaseResellerSiteConfigurationBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/ResellerSiteConfiguration`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/ReturnReport AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateReturnReport(body?: CaseReturnReportBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateReturnReport(body?: CaseReturnReportBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateReturnReport(body?: CaseReturnReportBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateReturnReport(body?: CaseReturnReportBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/ReturnReport`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/ServiceHistory AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateServiceHistory(body?: CaseServiceHistoryBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateServiceHistory(body?: CaseServiceHistoryBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateServiceHistory(body?: CaseServiceHistoryBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateServiceHistory(body?: CaseServiceHistoryBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/ServiceHistory`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/SoldPrices AmsOptionalAction:SeeValuationsOfOthersUsers AmsOptionalAction:EditStockPrices AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSoldPrices(body?: CaseSoldPricesBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateSoldPrices(body?: CaseSoldPricesBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateSoldPrices(body?: CaseSoldPricesBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateSoldPrices(body?: CaseSoldPricesBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/SoldPrices`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/SpecialColorAdjustment AmsOptionalAction:SeeValuationsOfOthersUsers AmsOptionalAction:OverrideVehicleDetailsAdjustments AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSpecialColorAdjustment(body?: CaseSpecialColorAdjustmentBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateSpecialColorAdjustment(body?: CaseSpecialColorAdjustmentBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateSpecialColorAdjustment(body?: CaseSpecialColorAdjustmentBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateSpecialColorAdjustment(body?: CaseSpecialColorAdjustmentBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/SpecialColorAdjustment`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/SpecialUsageAdjustment AmsOptionalAction:OverrideVehicleDetailsAdjustments AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSpecialUsageAdjustment(body?: CaseSpecialUsageAdjustmentBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateSpecialUsageAdjustment(body?: CaseSpecialUsageAdjustmentBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateSpecialUsageAdjustment(body?: CaseSpecialUsageAdjustmentBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateSpecialUsageAdjustment(body?: CaseSpecialUsageAdjustmentBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/SpecialUsageAdjustment`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/StockPrices AmsOptionalAction:SeeValuationsOfOthersUsers AmsAction:EditStockPrices AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStockPrices(body?: CaseStockPricesBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateStockPrices(body?: CaseStockPricesBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateStockPrices(body?: CaseStockPricesBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateStockPrices(body?: CaseStockPricesBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/StockPrices`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/TechnicalInspectionAdjustment AmsOptionalAction:SeeValuationsOfOthersUsers AmsOptionalAction:OverrideVehicleDetailsAdjustments AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTechnicalInspectionAdjustment(body?: CaseTechnicalInspectionAdjustmentBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateTechnicalInspectionAdjustment(body?: CaseTechnicalInspectionAdjustmentBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateTechnicalInspectionAdjustment(body?: CaseTechnicalInspectionAdjustmentBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateTechnicalInspectionAdjustment(body?: CaseTechnicalInspectionAdjustmentBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/TechnicalInspectionAdjustment`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/UniqueData AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUniqueData(body?: CaseUniqueDataBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateUniqueData(body?: CaseUniqueDataBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateUniqueData(body?: CaseUniqueDataBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateUniqueData(body?: CaseUniqueDataBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/UniqueData`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/UserVisitedExpertArea AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserVisitedExpertArea(body?: CaseUserVisitedExpertAreaBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateUserVisitedExpertArea(body?: CaseUserVisitedExpertAreaBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateUserVisitedExpertArea(body?: CaseUserVisitedExpertAreaBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateUserVisitedExpertArea(body?: CaseUserVisitedExpertAreaBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/UserVisitedExpertArea`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/ValuationRequest AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateValuation(body?: CaseValuationRequestBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateValuation(body?: CaseValuationRequestBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateValuation(body?: CaseValuationRequestBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateValuation(body?: CaseValuationRequestBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/ValuationRequest`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a date for which valuations will be made, only current and future dates are possible
     * AmsAction:PUT/api/Case/ValuationDate AmsOptionalAction:SeeValuationsOfOthersUsers AmsOptionalAction:MakeValuationWithoutVIN AmsOptionalAction:MakeValuationWithoutNumberPlate AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateValuationDate(body?: CaseValuationDateBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateValuationDate(body?: CaseValuationDateBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateValuationDate(body?: CaseValuationDateBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateValuationDate(body?: CaseValuationDateBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/ValuationDate`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/UpdateVehicleMileage AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateVehicleMileage(body?: CaseUpdateVehicleMileageBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateVehicleMileage(body?: CaseUpdateVehicleMileageBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateVehicleMileage(body?: CaseUpdateVehicleMileageBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateVehicleMileage(body?: CaseUpdateVehicleMileageBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/UpdateVehicleMileage`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Case/YoutubeUrl AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateYoutubeUrl(body?: CaseYoutubeUrlBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateYoutubeUrl(body?: CaseYoutubeUrlBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateYoutubeUrl(body?: CaseYoutubeUrlBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateYoutubeUrl(body?: CaseYoutubeUrlBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Case/YoutubeUrl`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
