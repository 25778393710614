/**
 * Public SSM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0-private
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 

export enum VehicleGroup {

      NewCars = 0,
      UsedCars = 1,
      DemonstrationCars = 2,
      DayLicense = 3,
      RentalCars = 4,

};
