export const pdfTemplateNamePattern = '[0-9A-Za-z.\\-_ ()ßäöüÄÖÜ]*';

export const pdfReProRedirect = 'pdfReProRedirect';
export const pdfReProNewTemplate = 'pdfReProNewTemplate';
export const pdfReProNewTemplateValue = 'true';
export const pdfReProFrame = 'pdfReProFrame';
export const pdfReProFrameValue = 'true';

// INFO: https://confluence.autovistagroup.com/pages/viewpage.action?spaceKey=DEALERP&title=Input+Field+Descriptions#InputFieldDescriptions-PrintTemplates
export const templateNameMaxLength = 50;
export const templateDescriptionMaxLength = 4096;
