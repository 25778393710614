import { Directive } from '@angular/core';
import { FlexDirective } from '@ngbracket/ngx-layout';

const selector = `[fxFlex.gt-mv], [fxFlex.lt-mv]`;
const inputs = ['fxFlex.gt-mv', 'fxFlex.lt-mv'];

/**
 * @deprecated to be removed because FlexLayout is obsolete - see migration-from-flex-layout.md for details
 */
@Directive({ selector, inputs })
export class MobileFlexDirective extends FlexDirective {
  protected inputs = inputs;
}
