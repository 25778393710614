import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  CaseStatus,
  CaseTaxDto,
  PriceHistoryDto,
  TaxType,
  UpdateCaseSoldPricesCommand,
  UpdateCaseStockPricesCommand,
} from '@vpfa/rest-api/valuation';
import { isNil } from 'lodash';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { F_PRICE_MAX, PRICE_ZERO } from '@vpfa/shared/validators';

export interface PriceDisplayModel {
  name: string;
  date: string;
  price: number;
  tax: number;
  hidePrice?: boolean;
  history?: PriceHistoryDto[];
  fcName?: Extract<keyof (UpdateCaseSoldPricesCommand & UpdateCaseStockPricesCommand), string>;
  isEditable?: boolean;
  isNullable?: boolean;
  showHistory: boolean;
  hiddenByDefault?: boolean;
  isForecast?: boolean;
  valuationVersion?: string;
}

@Component({
  selector: 'vpfa-pricing-details-modal',
  templateUrl: './pricing-details-modal.component.html',
  styleUrls: ['./pricing-details-modal.component.scss'],
})
export class PricingDetailsModalComponent {
  @Input() isVisible = false;
  @Input() loading = false;
  @Input() activeCaseDataTaxes: CaseTaxDto;
  @Input() caseId: string;
  @Input() caseStatus: CaseStatus;
  @Input() notAvailable: boolean;
  @Input() set valuationPrices(prices: PriceDisplayModel[]) {
    if (!isNil(prices)) {
      this.valuationPricesFormGroup = this.initializePricesFormGroup(prices);
      this._valuationPrices = prices;
    }
  }
  @Input() set stockPrices(prices: PriceDisplayModel[]) {
    if (!isNil(prices)) {
      this.stockPricesFormGroup = this.initializePricesFormGroup(prices);
      this._stockPrices = prices;
    }
  }
  @Input() set soldPrices(prices: PriceDisplayModel[]) {
    if (!isNil(prices)) {
      this.soldPricesFormGroup = this.initializePricesFormGroup(prices);
      this._soldPrices = prices;
    }
  }

  @Output() closeAction = new EventEmitter();
  @Output() updatePricingDetails = new EventEmitter<UpdateCaseStockPricesCommand | UpdateCaseSoldPricesCommand>();

  private _valuationPrices: PriceDisplayModel[] = [];
  private _stockPrices: PriceDisplayModel[] = [];
  private _soldPrices: PriceDisplayModel[] = [];
  valuationPricesFormGroup: UntypedFormGroup;
  stockPricesFormGroup: UntypedFormGroup;
  soldPricesFormGroup: UntypedFormGroup;
  CaseStatus = CaseStatus;
  TaxType = TaxType;
  errorMessages = [
    {
      error: 'min',
      errorMsg: this.translateService.instant('common.forms.minimumValue', { minValue: PRICE_ZERO }),
    },
    {
      error: 'max',
      errorMsg: this.translateService.instant('common.forms.maximumValue', { maxValue: F_PRICE_MAX }),
    },
    {
      error: 'required',
      errorMsg: this.translateService.instant('common.forms.fieldIsRequired'),
    },
  ];

  get valuationPrices() {
    return this._valuationPrices;
  }

  get stockPrices() {
    return this._stockPrices;
  }

  get soldPrices() {
    return this._soldPrices;
  }

  constructor(private formBuilder: UntypedFormBuilder, private translateService: TranslateService) {
    this.valuationPricesFormGroup = this.formBuilder.group({});
    this.stockPricesFormGroup = this.formBuilder.group({});
    this.soldPricesFormGroup = this.formBuilder.group({});
  }

  savePricingDetails() {
    if (this.isSoldStatus() && this.soldPricesFormGroup.valid && this.stockPricesFormGroup.valid) {
      const soldCommand: UpdateCaseSoldPricesCommand = {
        aggregateRootId: this.caseId,
        ...this.soldPricesFormGroup.getRawValue(),
        ...this.stockPricesFormGroup.getRawValue(),
      };
      this.updatePricingDetails.emit(soldCommand);
    } else if (this.isStockStatus() && this.stockPricesFormGroup.valid) {
      const stockCommand: UpdateCaseStockPricesCommand = {
        aggregateRootId: this.caseId,
        ...this.stockPricesFormGroup.getRawValue(),
      };
      this.updatePricingDetails.emit(stockCommand);
    }
  }

  close(): void {
    this.closeAction.emit();
  }

  get canSave(): boolean {
    return (
      this.valuationPrices.filter(price => price.isEditable).length > 0 ||
      this.stockPrices.filter(price => price.isEditable).length > 0 ||
      this.soldPrices.filter(price => price.isEditable).length > 0
    );
  }

  labelWidth(price: PriceDisplayModel): string {
    return price.isEditable ? '70' : '80';
  }

  isSoldStatus() {
    return this.caseStatus === CaseStatus.Sold;
  }

  isStockStatus() {
    return this.caseStatus === CaseStatus.InStock || this.caseStatus === CaseStatus.DueInStock;
  }

  controlIsRequired(controlName: string, form: UntypedFormGroup): boolean {
    const control = form?.get(controlName);
    return !isNil(control) && control?.validator({} as AbstractControl)?.required;
  }

  getIconColor(priceModel: PriceDisplayModel) {
    if (priceModel?.isForecast === true) {
      return 'green';
    } else if (priceModel?.showHistory === true) {
      return 'dark';
    } else if (priceModel?.showHistory === false) {
      return 'dark-gray';
    }
  }

  onIconClicked(priceModel: PriceDisplayModel) {
    if (!priceModel.isForecast) {
      priceModel.showHistory = !priceModel.showHistory;
    }
  }

  private initializePricesFormGroup(prices: PriceDisplayModel[]): UntypedFormGroup {
    const formControls = prices
      .filter(x => x.isEditable)
      .reduce((formGroupObject, editablePrice) => {
        const validators = !editablePrice.isNullable
          ? [Validators.min(PRICE_ZERO), Validators.max(F_PRICE_MAX), Validators.required]
          : [Validators.min(PRICE_ZERO), Validators.max(F_PRICE_MAX)];
        const price = editablePrice?.price;

        return {
          ...formGroupObject,
          [editablePrice.fcName]: [price, validators],
        };
      }, {});
    return this.formBuilder.group(formControls);
  }
}
