import { Component, OnInit, Input, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { getPartsFromArea, getPartsTotalCost } from '../../utils/estimator-parts-helpers';
import { LightDamagePartDto, LightDamageAreaDto } from '@vpfa/rest-api/valuation';
import { UntypedFormGroup } from '@angular/forms';
import { values } from 'lodash';

@Component({
  selector: 'vpfa-estimator-table',
  templateUrl: './estimator-table.component.html',
  styleUrls: ['./estimator-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EstimatorTableComponent implements OnInit {
  filteredAreas: LightDamageAreaDto[] = [];
  private parts: LightDamagePartDto[] = [];
  private _visible: boolean;
  total = 0;

  @Input() set areas(newAreas: LightDamageAreaDto[]) {
    if (newAreas && newAreas.length) {
      this.filteredAreas = newAreas.filter(
        area => (area.subAreas && area.subAreas.length) || (area.parts && area.parts.length)
      );
      this.refreshEstimator();
    }
  }
  @Input() estimatorForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input()
  set visible(visible: boolean) {
    this._visible = visible;
    this.refreshEstimator();
  }
  get visible(): boolean {
    return this._visible;
  }

  @Output() totalValue = new EventEmitter<number>();

  ngOnInit() {
    this.refreshEstimator();
  }

  refreshEstimator() {
    this.parts = getPartsFromArea(this.filteredAreas);
    this.total = getPartsTotalCost(this.parts);
    this.totalValue.emit(this.total);
  }

  emitTotal() {
    this.updateTotalPrice();
    this.totalValue.emit(this.total);
  }

  updateTotalPrice() {
    let total = 0;
    const parts = values(this.estimatorForm.getRawValue()).filter(part => part.isIncludedInValuation);
    parts.forEach(part => {
      if (part.cost) {
        total += part.cost;
      }
    });
    this.total = total;
  }
}
