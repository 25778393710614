/**
 * Public SSM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0-private
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 

export enum ADDFlag {

      STANDARD_SERIAL_EQUIPMENT = 0,
      STANDARD_OPTION_FREE = 1,
      OPTION_AT_EXTRA_CHARGE = 2,
      OPTION_PRICE_NOT_GIVEN = 3,
      OPTION_NOT_AVAILABLE_YET = 4,
      MANDATORY_AT_EXTRA_CHARGE = 5,

};
