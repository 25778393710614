import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VehicleOverviewData } from '../../../models/vehicle-overview-data.model';
import { isNil } from 'lodash';

@Component({
  selector: 'vpfa-mobile-vehicle-overview-data',
  templateUrl: './mobile-vehicle-overview-data.component.html',
  styleUrls: ['./mobile-vehicle-overview-data.component.scss'],
})
export class MobileVehicleOverviewDataComponent {
  @Input() overviewData: VehicleOverviewData;
  @Input() isUpdatingMileage = false;
  @Input() isSharedWithResellers: boolean;
  @Input() resellersOfferUrl: string;
  @Input() isPublicSiteEnabled: boolean;
  @Input() publicOfferUrl: string;
  @Input() set hideDoors(hide: boolean) {
    this.bodyTypeDoorsLabel = hide ? 'vehicleOverviewData.bodyType' : 'vehicleOverviewData.bodyTypeDoors';
    this.bodyTypeDoorsValue = this.showBodyTypeAndDoors(hide);
  }

  @Output() showTechnicalData = new EventEmitter<void>();
  @Output() showUniqueData = new EventEmitter<void>();
  @Output() editMileage = new EventEmitter<void>();

  bodyTypeDoorsLabel: string;
  bodyTypeDoorsValue: string;


  onShowTechnicalData() {
    this.showTechnicalData.emit();
  }

  onShowUniqueData() {
    this.showUniqueData.emit();
  }

  onEditMileage() {
    this.editMileage.emit();
  }

  private showBodyTypeAndDoors(hideDoors: boolean) {
    if (!isNil(this.overviewData.bodyType)) {
      return hideDoors || isNil(this.overviewData.numberDoors)
        ? `${this.overviewData.bodyType}`
        : `${this.overviewData.bodyType}, ${this.overviewData.numberDoors}`;
    } else {
      return '';
    }
  }
}
