<ng-container [ngSwitch]="displayedContent">
  <div *ngSwitchCase="'main'">
    <ng-container *ngTemplateOutlet="main"></ng-container>
  </div>
  <div *ngSwitchCase="'damageEstimator'">
    <ng-container *ngTemplateOutlet="damageEstimator"></ng-container>
  </div>
</ng-container>

<ng-template #main>
  <div>
    <div ngClass.lt-mv="no-hor-scroll">
      <vpfa-mobile-page-title
        [title]="'caseValuationDetails.repairAndMaintenance' | translate"
        [tooltipContent]="'tooltip.repairAndMaintenance' | translate"
      ></vpfa-mobile-page-title>

      <vpfa-mobile-total-summary
        [totalValue]="minorTotal + majorTotal | oppositeValue | currencyLocale: null:null:'1.2-2' | lT"
        [notAvailable]="notAvailable"
      >
        <vpfa-mobile-total-summary-item
          id="minorDamageValue"
          [label]="'caseValuationDetails.minor' | translate"
          [value]="minorTotal | oppositeValue | currencyLocale: null:null:'1.2-2' | lT"
        ></vpfa-mobile-total-summary-item>
        <button
          id="openMinorDamageEstimatorButton"
          nz-button
          nzType="link"
          class="calculate-button"
          (click)="onCalculate()"
        >
          {{ 'caseValuationDetails.calculate' | translate | uppercase }}
        </button>
        <vpfa-mobile-total-summary-item
          *ngIf="!hideRepairEstimateCost"
          id="majorDamageValue"
          [label]="'caseValuationDetails.major' | translate"
          [value]="majorTotal | oppositeValue | currencyLocale: null:null:'1.2-2' | lT"
          [notAvailable]="notAvailable"
        ></vpfa-mobile-total-summary-item>
      </vpfa-mobile-total-summary>
    </div>
    <div class="sticky-btn">
      <vpfa-mobile-action-button id="repairAndMaintenanceBackButton" (click)="closeAction.emit()">
        {{ 'common.back' | translate }}
      </vpfa-mobile-action-button>
    </div>
  </div>
</ng-template>

<ng-template #damageEstimator>
  <vpfa-mobile-light-damage-estimator
    (save)="onSave($event)"
    (closeAction)="onClose()"
    [loading]="(isSavingDamageEstimator$ | async) || (isLoadingDamageEstimator$ | async)"
    [areas]="lightDamageEstimatorAreas$ | async"
  ></vpfa-mobile-light-damage-estimator>
</ng-template>
