/**
 * Utility to print files from the application instead of opening them in a new window.
 *
 * @returns ObjectUrl string (result of `URL.revokeObjectURL()`) that need to be disposed
 */
export async function printBase64(fileType: 'pdf' | 'other', fileInBase64: string): Promise<void> {
  let prefix = '';

  switch (fileType) {
    case 'pdf':
      prefix = 'data:application/pdf;base64,';
      break;

    default:
      console.warn('Unsupported file type: ' + fileType);
      break;
  }

  const blobData = await convertBase64ToBlob(prefix, fileInBase64);
  const objectUrl = URL.createObjectURL(blobData);

  return new Promise<void>((resolve, reject) => {
    try {
      const iframeElement = document.createElement('iframe');

      // make iframe invisible
      iframeElement.style.visibility = 'hidden';
      iframeElement.style.width = '0';
      iframeElement.style.height = '0';
      iframeElement.style.border = '0';
      iframeElement.style.margin = '0';
      iframeElement.style.padding = '0';

      iframeElement.src = objectUrl;

      iframeElement.addEventListener('load', () => {
        // give some time after load and force print from iframe
        setTimeout(() => {
          iframeElement.focus();
          iframeElement.contentWindow.print();
          resolve();
        }, 250);

        // it is impossible to detect when print was finished across all browsers, for example `onafterprint` is not
        // working in Chrome. So iframe is focused and it will be removed after loosing focus.
        iframeElement.addEventListener('focusout', () => {
          // when user clicks on "cancel print" this event will not be fired, but if then user clicks on the other
          // elements, it will fire. It is executed late - but it should be enough to get iframe removed.
          iframeElement.remove();
          URL.revokeObjectURL(objectUrl);
        });
      });

      // append iFrame to modal if it is opened, because modal have focus trap, and prevents to focus iFrame
      // when added added into body element. IFrame will be removed together with modal when closed.
      (document.querySelector('vpfa-modal-content') ?? document.body).appendChild(iframeElement);
    } catch (err) {
      reject(err);
    }
  });
}

async function convertBase64ToBlob(prefix: string, value: string) {
  return (await fetch(`${prefix}${value}`)).blob();
}
