import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  UpdateCaseMarketSituationAdjustmentCommand,
  UpdateCaseNumberOfOwnersAdjustmentCommand,
  UpdateCaseReimportCommand,
  UpdateCaseServiceHistoryCommand,
  UpdateCaseSpecialColorAdjustmentCommand,
  UpdateCaseSpecialUsageAdjustmentCommand,
  UpdateCaseTechnicalInspectionAdjustmentCommand,
  UpdateMissingEquipmentAdjustmentCommand,
  UpdateCaseAppreciationDepreciationAdjustmentCommand,
} from '@vpfa/rest-api/valuation';

import { adjustmentsQuery } from './adjustments.selectors';
import { fromAdjustmentsActions } from './adjustments.actions';
import { CasesPartialState } from '../cases.reducer';

@Injectable()
export class AdjustmentsFacade {
  businessSpecificationVersion$ = this.store.pipe(select(adjustmentsQuery.getBusinessSpecificationVersion));
  isBusinessSpecificationLoading$ = this.store.pipe(select(adjustmentsQuery.isBusinessSpecificationLoading));
  numberOfOwnersSpecifications$ = this.store.pipe(select(adjustmentsQuery.getNumberOfOwnersSpecifications));
  specialUsageSpecifications$ = this.store.pipe(select(adjustmentsQuery.getSpecialUsageSpecifications));
  localizedSpecialColorSpecifications$ = this.store.pipe(
    select(adjustmentsQuery.getLocalizedSpecialColorSpecifications)
  );

  numberOfOwners$ = this.store.pipe(select(adjustmentsQuery.getNumberOfOwners));
  specialUsage$ = this.store.pipe(select(adjustmentsQuery.getSpecialUsage));
  specialColor$ = this.store.pipe(select(adjustmentsQuery.getSpecialColor));
  appreciationDepreciation$ = this.store.pipe(select(adjustmentsQuery.getAppreciationDepreciation));
  marketSituation$ = this.store.pipe(select(adjustmentsQuery.getMarketSituation));
  technicalInspection$ = this.store.pipe(select(adjustmentsQuery.getTechnicalInspection));
  isReimported$ = this.store.select(adjustmentsQuery.getIsReimported);
  serviceHistory$ = this.store.select(adjustmentsQuery.getServiceHistory);
  conditionAdjustmentsErrors$ = this.store.select(adjustmentsQuery.getConditionAdjustmentsErrors);

  missingEquipmentDeduction$ = this.store.select(adjustmentsQuery.getMissingEquipmentDeduction);
  additionalEquipmentDeduction$ = this.store.select(adjustmentsQuery.getAdditionalEquipmentDeduction);
  getTotalEquipmentDeduction$ = this.store.select(adjustmentsQuery.getTotalEquipmentDeduction);
  getTotalAdjustedConditionDeduction$ = this.store.pipe(select(adjustmentsQuery.getTotalAdjustedConditionDeduction));

  adjustmentsInProgress$ = this.store.select(adjustmentsQuery.getAdjustmentsInProgress);
  isUpdatingAdjustments$ = this.store.select(adjustmentsQuery.getIsUpdatingAdjustments);

  constructor(private store: Store<CasesPartialState>) {}

  loadBusinessSpecification() {
    this.store.dispatch(new fromAdjustmentsActions.LoadBusinessSpecification());
  }

  updateSpecialUsageAdjustment(command: UpdateCaseSpecialUsageAdjustmentCommand) {
    this.store.dispatch(new fromAdjustmentsActions.CaseUpdateSpecialUsageAdjustment(command));
  }

  updateSpecialColorAdjustment(command: UpdateCaseSpecialColorAdjustmentCommand) {
    this.store.dispatch(new fromAdjustmentsActions.CaseUpdateSpecialColorAdjustment(command));
  }

  updateAppreciationDepreciationAdjustment(command: UpdateCaseAppreciationDepreciationAdjustmentCommand) {
    this.store.dispatch(new fromAdjustmentsActions.CaseUpdateAppreciationDepreciationAdjustment(command));
  }

  updateMarketSituationAdjustment(command: UpdateCaseMarketSituationAdjustmentCommand) {
    this.store.dispatch(new fromAdjustmentsActions.CaseUpdateMarketSituationAdjustment(command));
  }

  updateNumberOfOwnersAdjustment(command: UpdateCaseNumberOfOwnersAdjustmentCommand) {
    this.store.dispatch(new fromAdjustmentsActions.CaseUpdateNumberOfOwnersAdjustment(command));
  }

  updateMissingEquipmentAdjustment(command: UpdateMissingEquipmentAdjustmentCommand) {
    this.store.dispatch(new fromAdjustmentsActions.CaseUpdateMissingEquipmentAdjustment(command));
  }

  updateServiceHistoryAdjustment(command: UpdateCaseServiceHistoryCommand) {
    this.store.dispatch(new fromAdjustmentsActions.CaseUpdateServiceHistoryAdjustment(command));
  }

  updateIsReimportedAdjustment(command: UpdateCaseReimportCommand) {
    this.store.dispatch(new fromAdjustmentsActions.CaseUpdateIsReimportedAdjustment(command));
  }

  updateTechnicalInspectionAdjustment(command: UpdateCaseTechnicalInspectionAdjustmentCommand) {
    this.store.dispatch(new fromAdjustmentsActions.CaseUpdateTechnicalInspectionAdjustment(command));
  }
}
