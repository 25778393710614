import { isNil } from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { IToken } from '../models/token';

@Injectable()
export class ManageStorageService {
  private tokenStorageKey = 'token';
  private rememberMeLocalStorageKey = 'rememberMe';

  constructor(private cookieService: CookieService) {}

  public setRememberMe(rememberMe: boolean) {
    localStorage.setItem(this.rememberMeLocalStorageKey, JSON.stringify(rememberMe));
  }

  public setToken(token: IToken) {
    const rememberMe = this.getRememberMe();
    const tokenString = JSON.stringify(token);

    if (rememberMe) {
      const oneHour = 1000 * 60 * 60;
      this.cookieService.set(this.tokenStorageKey, tokenString, new Date(token.decodedToken.exp * 1000 + oneHour), '/');
    } else {
      this.cookieService.set(this.tokenStorageKey, tokenString, null, '/');
    }
  }

  public getToken(): string {
    return this.cookieService.get(this.tokenStorageKey);
  }

  public hasToken(): boolean {
    return !isNil(this.getToken()) && this.getToken()?.length > 0;
  }

  public clearToken() {
    localStorage.removeItem(this.rememberMeLocalStorageKey);
    this.cookieService.delete(this.tokenStorageKey, '/');

    const tokenRemoved = new Promise<void>((resolve, reject) => {
      const timer = setInterval(() => {
        
        if (!this.cookieService.check(this.tokenStorageKey)) {
          clearInterval(timer);
          resolve()
        }
      }, 10);
    });

    return tokenRemoved;
  }

  private getRememberMe(): boolean {
    const rememberMeString = localStorage.getItem(this.rememberMeLocalStorageKey);
    if (isNil(rememberMeString) || rememberMeString === '') {
      return true;
    } else {
      return JSON.parse(rememberMeString);
    }
  }
}
