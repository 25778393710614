import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PROFILE_FEATURE_KEY, State as ProfileState } from './profile.reducer';
import { UserContext } from '@vpfa/rest-api/admin';

const getProfileState = createFeatureSelector<ProfileState>(PROFILE_FEATURE_KEY);

const isLoading = createSelector(getProfileState, (state: ProfileState) => state.isLoading);

const getUserContext = createSelector(getProfileState, state => state.userContext);

const getContentLanguage = createSelector(getUserContext, state => state.contentLanguage);

const getIsAnyAdmin = createSelector(
  getUserContext,
  state => state?.isBusinessAdmin || state?.isCountryAdmin || state?.isSystemAdmin
);

const getIsCountryAdmin = createSelector(getUserContext, state => state?.isCountryAdmin);

const getIsBusinessAdmin = createSelector(getUserContext, state => state?.isBusinessAdmin);

const getIsSystemAdmin = createSelector(getUserContext, state => state?.isSystemAdmin);

const getHaveOneBranch = createSelector(getUserContext, state => state?.branchIds.length === 1);

const getUserContextBusinessId = createSelector(getUserContext, (state: UserContext) =>
  state ? state.businessId : null
);

const getUserContextCountryId = createSelector(getUserContext, (state: UserContext) =>
  state ? state.countryId : null
);

const getUserContextCountryCode = createSelector(getUserContext, (state: UserContext) => state?.countryCode);

const getUsername = createSelector(getProfileState, (state: ProfileState) => state.userContext.name);

const getUserId = createSelector(getProfileState, (state: ProfileState) => state.userContext?.subjectId);

const getDefaultBranchId = createSelector(getProfileState, (state: ProfileState) => state.userContext.branchId);

const getUserProfile = createSelector(getProfileState, (state: ProfileState) => state.profile);

const getUserBusinessDetails = createSelector(getProfileState, state => state.userBusinessDetails.item);

const getUserBranchDetails = createSelector(getProfileState, state => state.userBranchDetails.item);

const getCountryDateRangeThreshold = createSelector(
  getProfileState,
  state => state?.countryConfiguration.dateRangeThreshold
);

const getCountryNatcodePolicy = createSelector(getProfileState, state => state?.countryConfiguration.natcodePolicy);

const getCountryVrmRegexPattern = createSelector(getProfileState, state => state?.countryConfiguration.vrmRegexPattern);

const getCountryKbaRegexPattern = createSelector(getProfileState, state => state?.countryConfiguration.kbaRegexPattern);

const getCountryMatriculationNumberRegexPattern = createSelector(
  getProfileState,
  state => state?.countryConfiguration.matriculationNumberRegexPattern
);

const getIdentificationVehicleType = createSelector(getUserContext, state => state?.identificationVehicleType);

const getHideBroadcastPremiumFeaturePopup = createSelector(
  getUserContext,
  state => state?.hideBroadcastPremiumFeaturePopup
);

export const profileQuery = {
  isLoading,
  getUsername,
  getUserId,
  getDefaultBranchId,
  getUserProfile,
  getUserContext,
  getContentLanguage,
  getUserContextBusinessId,
  getUserBusinessDetails,
  getUserBranchDetails,
  getUserContextCountryId,
  getUserContextCountryCode,
  getIsAnyAdmin,
  getIsCountryAdmin,
  getIsBusinessAdmin,
  getIsSystemAdmin,
  getHaveOneBranch,
  getCountryDateRangeThreshold,
  getCountryNatcodePolicy,
  getCountryVrmRegexPattern,
  getCountryKbaRegexPattern,
  getCountryMatriculationNumberRegexPattern,
  getIdentificationVehicleType,
  getHideBroadcastPremiumFeaturePopup,
};
