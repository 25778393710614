import { Component, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { MixpanelService } from '@vpfa/utils';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { ScrollToTopService } from '@vpfa/shared/mobile-view';
import { SharedLoadersService } from '@vpfa/shared/loaders';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { AppFacade } from './+state/app.facade';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { LocaleFacade } from '@vpfa/locale';
import { ImagesCleanupService } from '@vpfa/shared/images/data';
import { FixSafariCdkScroll } from './services/fix-safari-cdk-scroll.service';

@Component({
  selector: 'vpfa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  isNavigationInProgress$ = this.appFacade.isNavigationInProgress$;
  isInitialized$ = new BehaviorSubject(false);
  isADealerApp$ = this.appFacade.isDealerApp$;

  shouldShowLoadingIndicator$ = combineLatest([
    this.isInitialized$,
    this.isADealerApp$,
    this.sharedLoadersService.isSectionLoaderActiveSubject$,
  ]).pipe(map(([isInitialized, , isSectionLoaderActive]) => !isInitialized && !isSectionLoaderActive));

  @HostListener('window:beforeunload')
  closeTab() {
    this.mixpanel.closeNavigationTab();
  }

  constructor(
    private mixpanel: MixpanelService,
    private elRef: ElementRef,
    private scrollService: ScrollToTopService,
    router: Router,
    private sharedLoadersService: SharedLoadersService,
    private appFacade: AppFacade,
    private titleService: Title,
    private translateService: TranslateService,
    private localeFacade: LocaleFacade,
    imagesCleanupService: ImagesCleanupService
  ) {
    new FixSafariCdkScroll();
    imagesCleanupService.start();
    router.events.subscribe(value => {
      switch (true) {
        case value instanceof NavigationStart:
          this.appFacade.setIsNavigationInProgressValue(true);
          if (false === (value as NavigationStart).url.startsWith('/admin', 0)) {
            this.appFacade.setIsDealerAppValue(true);
            this.appFacade.setIsAdminAppValue(false);
            // if user was in admin area and his features are now set for different country than from UserContext
            this.localeFacade.loadUserContextFeatures();
          } else {
            this.appFacade.setIsDealerAppValue(false);
            this.appFacade.setIsAdminAppValue(true);
          }
          return;
        case value instanceof NavigationEnd:
        case value instanceof NavigationCancel:
        case value instanceof NavigationError:
          this.setPageTitle();
          this.appFacade.setIsNavigationInProgressValue(false);

          if (!this.isInitialized$.getValue()) this.isInitialized$.next(true);
      }
    });
    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.setPageTitle());
    this.scrollService.setRoot(this.elRef);
  }

  setPageTitle() {
    this.titleService.setTitle(this.translateService.instant('browserPageTitle'));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
