// tslint:disable:no-bitwise triple-equals

export function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function isGuid(value: string): boolean {
  const regExp = /^([0-9a-fA-F]{8})-(([0-9a-fA-F]{4}\-){3})([0-9a-fA-F]{12})$/i;

  return value && value.match(regExp) !== null;
}

export function isEmptyGuid(value: string): boolean {
  return value === '00000000-0000-0000-0000-000000000000';
}
