import { createReducer, on } from '@ngrx/store';
import * as provenanceCheckActions from './provenance-check.actions';
import { produce } from 'immer';
import { CO2EmissionRangeDto } from '@vpfa/rest-api/valuation';

export const PROVENANCE_CHECK_FEATURE_KEY = 'provenanceCheck';

export interface ProvenanceCheckState {
  cO2Emission: CO2EmissionState;
  loading: boolean;
}

interface CO2EmissionState {
  ranges: CO2EmissionRangeDto[];
  loading: boolean;
}

export const initialState: ProvenanceCheckState = {
  cO2Emission: {
    ranges: null,
    loading: false,
  },
  loading: false,
};

export const provenanceCheckReducer = createReducer<ProvenanceCheckState>(
  initialState,
  on(provenanceCheckActions.checkProvenance, state =>
    produce(state, newState => {
      newState.loading = true;
    })
  ),
  on(provenanceCheckActions.checkProvenanceSuccess, provenanceCheckActions.checkProvenanceError, state =>
    produce(state, newState => {
      newState.loading = false;
    })
  ),
  on(provenanceCheckActions.loadCO2EmissionRanges, state =>
    produce(state, newState => {
      newState.cO2Emission.loading = true;
    })
  ),
  on(provenanceCheckActions.loadCO2EmissionRangesSuccess, (state, action) =>
    produce(state, newState => {
      newState.cO2Emission.ranges = action.ranges;
      newState.cO2Emission.loading = false;
    })
  ),
  on(provenanceCheckActions.loadCO2EmissionRangesError, state =>
    produce(state, newState => {
      newState.cO2Emission.loading = false;
    })
  )
);
