/**
 * Public SSM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0-private
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { CommandHandlerResponse } from '../model/commandHandlerResponse';
import { CountryAdjustmentsCreateNumberOfOwnersBody } from '../model/countryAdjustmentsCreateNumberOfOwnersBody';
import { CountryAdjustmentsCreateNumberOfOwnersBody1 } from '../model/countryAdjustmentsCreateNumberOfOwnersBody1';
import { CountryAdjustmentsCreateNumberOfOwnersBody2 } from '../model/countryAdjustmentsCreateNumberOfOwnersBody2';
import { CountryAdjustmentsCreateNumberOfOwnersBody3 } from '../model/countryAdjustmentsCreateNumberOfOwnersBody3';
import { CountryAdjustmentsCreateSpecialUsageBody } from '../model/countryAdjustmentsCreateSpecialUsageBody';
import { CountryAdjustmentsCreateSpecialUsageBody1 } from '../model/countryAdjustmentsCreateSpecialUsageBody1';
import { CountryAdjustmentsCreateSpecialUsageBody2 } from '../model/countryAdjustmentsCreateSpecialUsageBody2';
import { CountryAdjustmentsCreateSpecialUsageBody3 } from '../model/countryAdjustmentsCreateSpecialUsageBody3';
import { CountryAdjustmentsUpdateCo2PricesBody } from '../model/countryAdjustmentsUpdateCo2PricesBody';
import { CountryAdjustmentsUpdateCo2PricesBody1 } from '../model/countryAdjustmentsUpdateCo2PricesBody1';
import { CountryAdjustmentsUpdateCo2PricesBody2 } from '../model/countryAdjustmentsUpdateCo2PricesBody2';
import { CountryAdjustmentsUpdateCo2PricesBody3 } from '../model/countryAdjustmentsUpdateCo2PricesBody3';
import { CountryAdjustmentsUpdateDefaultMarketSituationCorrectionBody } from '../model/countryAdjustmentsUpdateDefaultMarketSituationCorrectionBody';
import { CountryAdjustmentsUpdateDefaultMarketSituationCorrectionBody1 } from '../model/countryAdjustmentsUpdateDefaultMarketSituationCorrectionBody1';
import { CountryAdjustmentsUpdateDefaultMarketSituationCorrectionBody2 } from '../model/countryAdjustmentsUpdateDefaultMarketSituationCorrectionBody2';
import { CountryAdjustmentsUpdateDefaultMarketSituationCorrectionBody3 } from '../model/countryAdjustmentsUpdateDefaultMarketSituationCorrectionBody3';
import { CountryAdjustmentsUpdateNumberOfOwnersBody } from '../model/countryAdjustmentsUpdateNumberOfOwnersBody';
import { CountryAdjustmentsUpdateNumberOfOwnersBody1 } from '../model/countryAdjustmentsUpdateNumberOfOwnersBody1';
import { CountryAdjustmentsUpdateNumberOfOwnersBody2 } from '../model/countryAdjustmentsUpdateNumberOfOwnersBody2';
import { CountryAdjustmentsUpdateNumberOfOwnersBody3 } from '../model/countryAdjustmentsUpdateNumberOfOwnersBody3';
import { CountryAdjustmentsUpdateSpecialColorBody } from '../model/countryAdjustmentsUpdateSpecialColorBody';
import { CountryAdjustmentsUpdateSpecialColorBody1 } from '../model/countryAdjustmentsUpdateSpecialColorBody1';
import { CountryAdjustmentsUpdateSpecialColorBody2 } from '../model/countryAdjustmentsUpdateSpecialColorBody2';
import { CountryAdjustmentsUpdateSpecialColorBody3 } from '../model/countryAdjustmentsUpdateSpecialColorBody3';
import { CountryAdjustmentsUpdateSpecialUsageBody } from '../model/countryAdjustmentsUpdateSpecialUsageBody';
import { CountryAdjustmentsUpdateSpecialUsageBody1 } from '../model/countryAdjustmentsUpdateSpecialUsageBody1';
import { CountryAdjustmentsUpdateSpecialUsageBody2 } from '../model/countryAdjustmentsUpdateSpecialUsageBody2';
import { CountryAdjustmentsUpdateSpecialUsageBody3 } from '../model/countryAdjustmentsUpdateSpecialUsageBody3';
import { CountryAdjustmentsUpdateTechnicalInspectionBody } from '../model/countryAdjustmentsUpdateTechnicalInspectionBody';
import { CountryAdjustmentsUpdateTechnicalInspectionBody1 } from '../model/countryAdjustmentsUpdateTechnicalInspectionBody1';
import { CountryAdjustmentsUpdateTechnicalInspectionBody2 } from '../model/countryAdjustmentsUpdateTechnicalInspectionBody2';
import { CountryAdjustmentsUpdateTechnicalInspectionBody3 } from '../model/countryAdjustmentsUpdateTechnicalInspectionBody3';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CountryAdjustmentsService {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Lazy check AmsAction:POST/api/CountryAdjustments/CreateNumberOfOwners AmsResource:{country}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createNumberOfOwnersSpecification(body?: CountryAdjustmentsCreateNumberOfOwnersBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public createNumberOfOwnersSpecification(body?: CountryAdjustmentsCreateNumberOfOwnersBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public createNumberOfOwnersSpecification(body?: CountryAdjustmentsCreateNumberOfOwnersBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public createNumberOfOwnersSpecification(body?: CountryAdjustmentsCreateNumberOfOwnersBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommandHandlerResponse>(`${this.basePath}/api/CountryAdjustments/CreateNumberOfOwners`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:POST/api/CountryAdjustments/CreateSpecialUsage AmsResource:{country}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSpecialUsageSpecification(body?: CountryAdjustmentsCreateSpecialUsageBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public createSpecialUsageSpecification(body?: CountryAdjustmentsCreateSpecialUsageBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public createSpecialUsageSpecification(body?: CountryAdjustmentsCreateSpecialUsageBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public createSpecialUsageSpecification(body?: CountryAdjustmentsCreateSpecialUsageBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommandHandlerResponse>(`${this.basePath}/api/CountryAdjustments/CreateSpecialUsage`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/CountryAdjustments/UpdateCo2Prices AmsResource:{country}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCo2PricesSpecification(body?: CountryAdjustmentsUpdateCo2PricesBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateCo2PricesSpecification(body?: CountryAdjustmentsUpdateCo2PricesBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateCo2PricesSpecification(body?: CountryAdjustmentsUpdateCo2PricesBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateCo2PricesSpecification(body?: CountryAdjustmentsUpdateCo2PricesBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/CountryAdjustments/UpdateCo2Prices`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/CountryAdjustments/UpdateDefaultMarketSituationCorrection AmsResource:{country}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDefaultMarketSituation(body?: CountryAdjustmentsUpdateDefaultMarketSituationCorrectionBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateDefaultMarketSituation(body?: CountryAdjustmentsUpdateDefaultMarketSituationCorrectionBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateDefaultMarketSituation(body?: CountryAdjustmentsUpdateDefaultMarketSituationCorrectionBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateDefaultMarketSituation(body?: CountryAdjustmentsUpdateDefaultMarketSituationCorrectionBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/CountryAdjustments/UpdateDefaultMarketSituationCorrection`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/CountryAdjustments/UpdateNumberOfOwners AmsResource:{country}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateNumberOfOwnersSpecification(body?: CountryAdjustmentsUpdateNumberOfOwnersBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateNumberOfOwnersSpecification(body?: CountryAdjustmentsUpdateNumberOfOwnersBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateNumberOfOwnersSpecification(body?: CountryAdjustmentsUpdateNumberOfOwnersBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateNumberOfOwnersSpecification(body?: CountryAdjustmentsUpdateNumberOfOwnersBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/CountryAdjustments/UpdateNumberOfOwners`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/CountryAdjustments/UpdateSpecialColor AmsResource:{country}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSpecialColorSpecification(body?: CountryAdjustmentsUpdateSpecialColorBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateSpecialColorSpecification(body?: CountryAdjustmentsUpdateSpecialColorBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateSpecialColorSpecification(body?: CountryAdjustmentsUpdateSpecialColorBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateSpecialColorSpecification(body?: CountryAdjustmentsUpdateSpecialColorBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/CountryAdjustments/UpdateSpecialColor`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/CountryAdjustments/UpdateSpecialUsage AmsResource:{country}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSpecialUsageSpecification(body?: CountryAdjustmentsUpdateSpecialUsageBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateSpecialUsageSpecification(body?: CountryAdjustmentsUpdateSpecialUsageBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateSpecialUsageSpecification(body?: CountryAdjustmentsUpdateSpecialUsageBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateSpecialUsageSpecification(body?: CountryAdjustmentsUpdateSpecialUsageBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/CountryAdjustments/UpdateSpecialUsage`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/CountryAdjustments/UpdateTechnicalInspection AmsResource:{country}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTechnicalInspectionSpecification(body?: CountryAdjustmentsUpdateTechnicalInspectionBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateTechnicalInspectionSpecification(body?: CountryAdjustmentsUpdateTechnicalInspectionBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateTechnicalInspectionSpecification(body?: CountryAdjustmentsUpdateTechnicalInspectionBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateTechnicalInspectionSpecification(body?: CountryAdjustmentsUpdateTechnicalInspectionBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/CountryAdjustments/UpdateTechnicalInspection`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
