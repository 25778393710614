import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { CaseTaxDto, TaxType } from '@vpfa/rest-api/valuation';

@Component({
  selector: 'vpfa-price-tax',
  templateUrl: './price-tax.component.html',
  styleUrls: ['./price-tax.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceTaxComponent {
  @Input() caseTax: CaseTaxDto;
  @Input() tax: string;
  @Input() taxValue: number;

  /**
   * Tax information below prices in black box should never be shown, unless tax type is 'VAT included' AND this flag is set to true (VP-15937).
   * For now this flag should be set to false everywhere, except prices in black box other than calculated trade-in price - this may change in future.
   */
  @Input() hideWhenTaxIncluded = false;

  taxType = TaxType;
}
