import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { range } from 'lodash';
import { SharedLoadersService } from '../../services/shared-loaders.service';
import { map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';

@Component({
  selector: 'vpfa-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent implements OnDestroy {
  @Input() noTopMenu = false;
  @Input() count = 20;

  private loadingStateOverride$ = new BehaviorSubject(true);
  private _onDestroy$ = new Subject<void>();
  @Input() set loadingStateOverride(setValue: boolean) {
    this.loadingStateOverride$.next(setValue);
  }

  isEnabled$ = combineLatest([
    this.loadingStateOverride$,
    this.sharedLoadersService.isSectionLoaderActiveSubject$,
  ]).pipe(
    map(
      ([isLoadingOverrideActive, isSectionLoaderActive]) => isLoadingOverrideActive && false === isSectionLoaderActive
    )
  );

  get style() {
    const heightCorrectionPx = this.noTopMenu ? 72 : 300;
    return { minHeight: `calc(100vh - ${heightCorrectionPx}px)` };
  }

  get range() {
    return range(1, this.count);
  }

  constructor(private sharedLoadersService: SharedLoadersService, private changeDetectorRef: ChangeDetectorRef) {
    this.isEnabled$.pipe(takeUntil(this._onDestroy$)).subscribe(v => {
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
