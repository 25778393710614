<vpfa-mobile-subtitle [title]="'vehicleOverviewData.mobile.title' | translate"> </vpfa-mobile-subtitle>

<div class="flex-mv-column">
  <div class="flex-mv-row justify-content-between align-items-start stats-entry">
    <vpfa-label [text]="'vehicleOverviewData.firstRegDate' | translate"> </vpfa-label>
    <vpfa-value id="registrationDateValue" [attr.data-registration-date]="overviewData?.regDate">
      {{ overviewData?.regDate | dateLocale }}
    </vpfa-value>
  </div>
  <nz-divider class="middle-divider"></nz-divider>
  <nz-spin [nzSpinning]="isUpdatingMileage">
    <div class="flex-mv-row justify-content-between align-items-start stats-entry">
      <div class="flex-mv-column justify-content-center align-items-start">
        <vpfa-label
          id="mileageLabel"
          [text]="('vehicleOverviewData.mileage' | translate) + (overviewData?.mileage | unitValue)"
        ></vpfa-label>
        <button nz-button nzType="link" class="edit-button" (click)="onEditMileage()">
          {{ 'vehicleOverviewData.mobile.edit' | translate | uppercase }}
        </button>
      </div>
      <vpfa-value *ngIf="!isUpdatingMileage" id="mileageValue" [attr.data-mileage]="overviewData?.mileage | json">
        {{ overviewData?.mileage?.value | numberLocale }}
      </vpfa-value>
    </div>
  </nz-spin>
  <nz-divider class="middle-divider"></nz-divider>
  <div class="flex-mv-row justify-content-between align-items-start stats-entry">
    <vpfa-label
      id="engineSizeLabel"
      [text]="
        ('vehicleFullTechnicalData.engineInformation.engineCapacity' | translate) +
        (overviewData?.engineSize | unitValue)
      "
    >
    </vpfa-label>
    <vpfa-value id="engineSizeValue" [attr.data-mileage]="overviewData?.engineSize | json">{{
      overviewData?.engineSize | engineCapacity
    }}</vpfa-value>
  </div>
  <nz-divider class="middle-divider"></nz-divider>
  <div class="flex-mv-row justify-content-between align-items-start stats-entry">
    <vpfa-label [text]="bodyTypeDoorsLabel | translate"> </vpfa-label>
    <vpfa-value
      id="bodyTypeDoors"
      [attr.data-body-type]="overviewData?.bodyType"
      [attr.data-doors]="overviewData?.numberDoors"
    >
      {{ bodyTypeDoorsValue }}
    </vpfa-value>
  </div>
  <nz-divider class="middle-divider"></nz-divider>
  <div class="flex-mv-row justify-content-between align-items-start stats-entry">
    <vpfa-label [text]="'vehicleOverviewData.listPrice' | translate"> </vpfa-label>
    <vpfa-value id="listPriceValue" [attr.data-list-price-value]="overviewData?.listPrice">
      {{ overviewData?.listPrice | currencyLocale: null:null:'1.2-2' | lT }}
    </vpfa-value>
  </div>
  <nz-divider class="middle-divider"></nz-divider>
  <div class="flex-mv-row justify-content-between align-items-start stats-entry">
    <vpfa-label [text]="'vehicleOverviewData.optionPrice' | translate"> </vpfa-label>
    <vpfa-value
      id="optionalEquipmentPriceValue"
      [attr.data-optional-equipment-value]="overviewData?.optionalEquipmentPrice"
    >
      {{ overviewData?.optionalEquipmentPrice | currencyLocale: null:null:'1.2-2' | lT }}
    </vpfa-value>
  </div>
  <nz-divider class="middle-divider"></nz-divider>
  <div class="flex-mv-row justify-content-between align-items-start stats-entry">
    <vpfa-label [text]="'vehicleOverviewData.totalPrice' | translate"> </vpfa-label>
    <vpfa-value id="totalPriceValue" [attr.data-total-price]="overviewData?.totalPrice">
      {{ overviewData?.totalPrice | currencyLocale: null:null:'1.2-2' | lT }}
    </vpfa-value>
  </div>
  <nz-divider class="middle-divider"></nz-divider>
  <div class="flex-mv-row justify-content-between align-items-start stats-entry">
    <vpfa-label [text]="'vehicleOverviewData.emissionClass' | translate"> </vpfa-label>
    <vpfa-value id="emissionClassValue" [attr.data-emission-class]="overviewData?.emissionClass | json">
      {{ overviewData?.emissionClass?.texts | lT }}
    </vpfa-value>
  </div>

  <div class="data-links">
    <vpfa-arrow-link id="technicalDataLink" icon="file-text" class="link" (clicked)="onShowTechnicalData()">
      {{ 'caseValuationDetails.fullTechnicalData' | translate }}
    </vpfa-arrow-link>
    <vpfa-arrow-link id="uniqueDataSectionLink" icon="file-text" class="link" (clicked)="onShowUniqueData()">
      {{ 'caseValuationDetails.uniqueDataSection' | translate }}
    </vpfa-arrow-link>
    <vpfa-arrow-link id="publicCaseOfferLink" icon="globe" class="link" *ngIf="isPublicSiteEnabled">
      <a [href]="publicOfferUrl" class="dark-link">
        {{ 'caseValuationDetails.publicOfferPreview' | translate }}
      </a>
    </vpfa-arrow-link>
    <vpfa-arrow-link id="publicCaseResellerLink" icon="globe" class="link" *ngIf="isSharedWithResellers">
      <a [href]="resellersOfferUrl" class="dark-link">
        {{ 'caseValuationDetails.resellerSitePreview' | translate }}
      </a>
    </vpfa-arrow-link>
  </div>
</div>
