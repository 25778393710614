/**
 * Dealer Network AdminService API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { UserGroupDefaultConfigurationDto } from '../model/userGroupDefaultConfigurationDto';
import { UserGroupDto } from '../model/userGroupDto';
import { VPError } from '../model/vPError';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserGroupViewService {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public defaultConfiguration(observe?: 'body', reportProgress?: boolean): Observable<UserGroupDefaultConfigurationDto>;
    public defaultConfiguration(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserGroupDefaultConfigurationDto>>;
    public defaultConfiguration(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserGroupDefaultConfigurationDto>>;
    public defaultConfiguration(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<UserGroupDefaultConfigurationDto>(`${this.basePath}/api/UserGroupView/defaultConfiguration`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:GET/api/UserGroupView/admin/business/{businessId}/{userGroupId} AmsResource:{country}/{business}/_* 
     * @param userGroupId 
     * @param businessId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public get(userGroupId: string, businessId: string, observe?: 'body', reportProgress?: boolean): Observable<UserGroupDto>;
    public get(userGroupId: string, businessId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserGroupDto>>;
    public get(userGroupId: string, businessId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserGroupDto>>;
    public get(userGroupId: string, businessId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userGroupId === null || userGroupId === undefined) {
            throw new Error('Required parameter userGroupId was null or undefined when calling get.');
        }

        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling get.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<UserGroupDto>(`${this.basePath}/api/UserGroupView/admin/business/${encodeURIComponent(String(businessId))}/${encodeURIComponent(String(userGroupId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:GET/api/UserGroupView/business/{businessId}/List AmsResource:{country}/{dealerNetwork}/{business}/_* 
     * @param businessId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getList(businessId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<UserGroupDto>>;
    public getList(businessId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserGroupDto>>>;
    public getList(businessId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserGroupDto>>>;
    public getList(businessId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling getList.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<UserGroupDto>>(`${this.basePath}/api/UserGroupView/business/${encodeURIComponent(String(businessId))}/List`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
