import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotificationsFacade } from '../../+state/notifications.facade';
import { map, delay, tap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { groupBy } from 'lodash';
import { FileNotificationType } from '../../models/file-notification-type.enum';

@Component({
  selector: 'vpfa-notifications-modal',
  templateUrl: './notifications-modal.component.html',
  styleUrls: ['./notifications-modal.component.scss'],
})
export class NotificationsModalComponent implements OnInit, OnDestroy {
  // show notification for files that was checked by FE and are invalid (before ConfirmBroadcastImages)
  openModalIfInvalidFiles$ = combineLatest([
    this.notificationsFacade.filesNotifications$,
    this.notificationsFacade.invalidFilesCount$,
  ]).pipe(
    map(([notifications, invalidFilesCount]) => ({
      notificationsCount: notifications?.filter(x => x.notificationType === FileNotificationType.NotAccepted).length,
      invalidFilesCount,
    })),
    map(
      ({ notificationsCount, invalidFilesCount }) => notificationsCount > 0 && notificationsCount === invalidFilesCount
    )
  );

  // show notification for files that are uploaded to BE (after ConfirmBroadcastImages)
  openModalAfterAllFilesUpload$ = combineLatest([
    this.notificationsFacade.filesNotifications$,
    this.notificationsFacade.filesUploadCount$,
  ]).pipe(
    map(
      ([currentNotifications, uploadFilesCount]) =>
        currentNotifications?.length > 0 && uploadFilesCount === currentNotifications.length
    )
  );

  modalOpen$ = combineLatest([
    this.notificationsFacade.basicNotifications$.pipe(map(items => items?.length > 0)),
    this.notificationsFacade.filesNotifications$.pipe(map(items => items?.length > 0)),
    this.openModalIfInvalidFiles$,
    this.openModalAfterAllFilesUpload$,
  ]).pipe(
    map(x => x.some(x => x === true)),
    tap(isOpened => {
      if (isOpened) {
        this.focusCloseButton();
      }
    })
  );

  modalMaskClosable = true;

  /**
   * To indicate if modal is currently closing. This variable is used to fix NzModal bug, preconditions:
   * 1. Modal was opened and is currently closing (animation is playing);
   * 2. Event/action is sent to modal to open it again
   *
   * When above happens, it is likely to modal be still closed but modal overlay to be visible and notifications
   * that meant to be displayed are lost.
   */
  isClosing$ = new BehaviorSubject(false);

  errorNotifications$ = this.notificationsFacade.errorNotifications$.pipe(map(list => groupBy(list, 'title')));
  warningNotifications$ = this.notificationsFacade.warningNotifications$.pipe(map(list => groupBy(list, 'title')));
  successNotifications$ = this.notificationsFacade.successNotifications$.pipe(map(list => groupBy(list, 'title')));
  mistakeNotifications$ = this.notificationsFacade.mistakeNotifications$.pipe(map(list => groupBy(list, 'title')));

  filesErrorsList$ = this.notificationsFacade.filesNotifications$.pipe(
    map(x => x.filter(x => x.notificationType === FileNotificationType.NotAccepted))
  );
  filesSuccessList$ = this.notificationsFacade.filesNotifications$.pipe(
    map(x => x.filter(x => x.notificationType === FileNotificationType.Accepted))
  );

  filesUploadCount$ = this.notificationsFacade.filesUploadCount$;
  invalidFilesCount$ = this.notificationsFacade.invalidFilesCount$;

  private _onDestroy$ = new Subject<void>();

  constructor(private notificationsFacade: NotificationsFacade) {}

  ngOnInit() {
    this.blockClosingOfModalWhenNewNotificationIsAdded();
  }

  onClose() {
    this.isClosing$.next(true);
    this.notificationsFacade.clearAll();
  }

  afterClose() {
    this.isClosing$.next(false);
  }

  focusCloseButton() {
    setTimeout(() => {
      document.querySelector<HTMLButtonElement>('#closeNotificationBtn')?.focus();
    }, 100);
  }

  private blockClosingOfModalWhenNewNotificationIsAdded() {
    this.notificationsFacade.basicNotifications$
      .pipe(
        tap(() => {
          this.modalMaskClosable = false;
        }),
        delay(600),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => {
        this.modalMaskClosable = true;
      });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
