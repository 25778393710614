import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { isNil } from 'lodash';

@Component({
  selector: 'vpfa-currency-renderer',
  templateUrl: './currency-renderer.component.html',
  styleUrls: ['./currency-renderer.component.scss'],
})
export class CurrencyRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  priceInformation: string;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.priceInformation = params?.colDef?.cellRendererParams?.priceInformation;
  }

  isPriceInformationVisible() {
    return !isNil(this.params?.value) && !isNil(this.priceInformation);
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
