import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MediaObserver } from '@ngbracket/ngx-layout';
import { mobileViewAlias } from '../providers/mobile-breakpoints';

import { map, distinctUntilChanged } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class IsMobileViewService {
  readonly isMobileView$: Observable<boolean> = this.mediaObserver.asObservable().pipe(
    map(mediaEvent => mediaEvent.some(me => me.mqAlias === mobileViewAlias)),
    distinctUntilChanged()
  );
  constructor(private mediaObserver: MediaObserver) {}
}
