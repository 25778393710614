import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CasesFacade } from '@vpfa/dealer/case/data';
import { NotesBase } from '../notes-base/notes-base';

@Component({
  selector: 'vpfa-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesComponent extends NotesBase {
  constructor(fb: UntypedFormBuilder, casesFacade: CasesFacade) {
    super(fb, casesFacade);
  }

  minRows = 6;
  maxRows = 30;

  updateNotes(){
    super.updateNotes();
  }
}
