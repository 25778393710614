import { Injectable } from '@angular/core';
import { CustomersFacade } from '../+state/customers.facade';
import { LocaleFacade } from '@vpfa/locale';
import { Features } from '@vpfa/rest-api/admin';
import { take } from 'rxjs/operators';
import { Filter } from '@vpfa/rest-api/valuation';

@Injectable()
export class CustomersListService {
  private currentPageIndex = 0;
  private _pageSize = 30;
  private _showNewList: boolean;
  private _lastQuery = null;

  showNewLists$ = this.localeFacade.isFeatureConfigurationEnabled(Features.ShowListsV2);
  private _searchParams: Partial<Filter>[] = [];

  constructor(private customersFacade: CustomersFacade, private localeFacade: LocaleFacade) {}

  get showNewList() {
    return this._showNewList;
  }

  get pageSize() {
    return this._pageSize;
  }

  loadCustomerList() {
    this.showNewLists$.pipe(take(1)).subscribe(showNewList => {
      this._showNewList = showNewList;
      if (showNewList === true) {
        this.loadFirstPage();
      } else if (showNewList === false) {
        this.customersFacade.loadAll();
      }
    });
  }

  updateFilter(query: string) {
    let cleanQuery = query?.trim();

    if (cleanQuery === '' || cleanQuery === undefined) {
      cleanQuery = null;
    }

    if (this._lastQuery === cleanQuery) {
      return;
    }

    if (!query?.length) {
      this._searchParams = [];
      this._lastQuery = null;
    } else {
      this._searchParams = [
        {
          fieldName: 'firstOrLastName',
          value: cleanQuery,
          isNull: false,
        },
      ];
      this._lastQuery = cleanQuery;
    }

    this.loadFirstPage();
  }

  loadFirstPage() {
    this.currentPageIndex = 0;
    this.loadCurrentPage();
  }

  loadNextPage() {
    this.currentPageIndex += 1;
    this.loadCurrentPage();
  }

  private loadCurrentPage() {
    this.customersFacade.loadPage(this.currentPageIndex, this.pageSize, this._searchParams as Filter[], [
      { fieldName: 'LastName', descending: false },
      { fieldName: 'FirstName', descending: false },
      { fieldName: 'Street', descending: false },
      { fieldName: 'City', descending: false },
    ]);
  }
}
