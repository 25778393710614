import { CustomerDto, CustomerListItemDto } from '@vpfa/rest-api/valuation';
import { AddRemoveCustomerListItem } from '@vpfa/dealer/customers/shared';

export function displayCustomer(customer: CustomerDto) {
  const title = customer.formOfAddress ? customer.formOfAddress : '';
  const firstName = customer.firstName ? customer.firstName : '';
  const lastName = customer.lastName ? customer.lastName : '';
  return `${title} ${firstName} ${lastName}`;
}

export const getCustomerSorting = (customer: AddRemoveCustomerListItem | CustomerListItemDto) => {
  const lastName = customer.lastName?.toLowerCase();
  const firstName = customer.firstName?.toLowerCase();
  const street = customer.street?.toLowerCase();
  const city = customer.city?.toLowerCase();

  // SSM-3950 & SSM-3952 -> main owner should be first
  if (isCustomerCaseListItemDto(customer)) {
    const isMain = (customer as any).isMain === true ? 0 : 1;

    return [isMain, lastName, firstName, street, city];
  }

  return [lastName, firstName, street, city];
};

function isCustomerCaseListItemDto(input: unknown) {
  return (input as AddRemoveCustomerListItem).isMain !== undefined;
}
