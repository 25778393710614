import { Component, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DataTableAction, DataTableActionEvent } from '../../models/data-table-action';

@Component({
  selector: 'vpfa-text-input-renderer',
  templateUrl: './text-input-renderer.component.html',
  styleUrls: ['./text-input-renderer.component.scss'],
})
export class TextInputRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  actionEmitter: EventEmitter<DataTableActionEvent>;
  formGroup = new UntypedFormGroup({
    field: new UntypedFormControl(null),
  });

  agInit(params: ICellRendererParams): void {
    if (params) {
      this.params = params;
      this.initValue(params.value);
    }

    if (this.params) {
      const context = this.params.context;
      if (context && context.componentParent && context.componentParent.actionClicked) {
        this.actionEmitter = context.componentParent.actionClicked;
      }
    }
  }

  refresh(params: any): boolean {
    this.initValue(params.value);
    return true;
  }

  initValue(value: string) {
    this.formGroup.reset({ field: value });
  }

  emitAction() {
    if (this.actionEmitter) {
      this.actionEmitter.emit({
        action: DataTableAction.RENAME,
        data: this.params.data,
        value: this.formGroup.get('field').value,
      });
    }
  }
}
