<vpfa-modal
  width="750px"
  [modalName]="modalName"
  [showBackdrop]="true"
  [isVisible]="visible"
  [isClosable]="false"
  [keyboard]="false"
  [maskClosable]="false"
  (closeAction)="closeAction.emit()"
>
  <vpfa-modal-content>
    <button
      class="ant-modal-close"
      nz-button
      nzType="link"
      nz-popconfirm
      nzPopconfirmTrigger="click"
      [nzPopconfirmTitle]="'common.cancelQuestion' | translate"
      [nzCancelText]="'common.no' | translate"
      [nzOkText]="'common.yes' | translate"
      nzPopconfirmPlacement="bottom"
      nzPopconfirmOverlayClassName="modal-confirm-popover"
      (nzOnConfirm)="closeAction.emit()"
    >
      <i nz-icon nzType="close-circle" nzTheme="fill"></i>
    </button>
    <form fxFlex fxLayout="column" fxLayoutGap="10px">
      <nz-spin [nzSpinning]="loading">
        <h1>{{ titleLabel | translate }}</h1>
        <p>
          {{ contentLabel | translate }}
        </p>
        <div fxFlex fxLayout="column" fxLayoutGap="10px">
          <vpfa-action-button buttonType="button" (click)="updateWithoutRevaluation.emit()">
            {{ updateButtonLabel | translate }}
          </vpfa-action-button>
          <vpfa-action-button
            buttonType="button"
            [disabled]="createNewValuationDisabled ? vinCallsModel === 'vin-requests' && vinCallsLeft === 0 : false"
            (click)="updateWithRevaluation.emit()"
          >
            {{ createNewValuationLabel | translate }}
          </vpfa-action-button>
          <div class="customer-button-box">
            <a
              nz-popconfirm
              [nzPopconfirmTitle]="'common.cancelQuestion' | translate"
              [nzCancelText]="'common.no' | translate"
              [nzOkText]="'common.yes' | translate"
              nzPopconfirmPlacement="top"
              nzPopconfirmOverlayClassName="modal-confirm-popover"
              (nzOnConfirm)="closeAction.emit()"
            >
              <vpfa-action-button [outline]="true" [border]="false">
                {{ 'common.cancel' | translate }}
              </vpfa-action-button>
            </a>
          </div>
        </div>
      </nz-spin>
    </form>
  </vpfa-modal-content>
</vpfa-modal>
