import { Action } from '@ngrx/store';
import { LoginCommand } from '@vpfa/rest-api/admin';

export enum AuthTemporaryActionTypes {
  TemporaryPassword = '[Change Password] Temporary Password',
  CheckPasswordCompatibility = '[Change Password] Check Password Compatibility',
  CheckPasswordCompatibilitySuccess = '[ChangePassword] Check Password Compatibility Success',
  CheckPasswordCompatibilityError = '[ChangePassword] Check Password Compatibility Error',
  ChangeTemporaryPassword = '[Change Password] Change Temporary Password',
  ChangeTemporaryPasswordSuccess = '[Change Password] Change Temporary Password Success',
  ChangeTemporaryPasswordError = '[Change Password] Change Temporary Password Error',
  ClearTemporaryPassword = '[Change Password] Clear Temporary Password',
}

export class TemporaryPassword implements Action {
  readonly type = AuthTemporaryActionTypes.TemporaryPassword;
  constructor(public payload: LoginCommand) {}
}

export class CheckPasswordCompatibility implements Action {
  readonly type = AuthTemporaryActionTypes.CheckPasswordCompatibility;
  constructor(public payload: string) {}
}

export class CheckPasswordCompatibilitySuccess implements Action {
  readonly type = AuthTemporaryActionTypes.CheckPasswordCompatibilitySuccess;
  constructor(public payload: boolean) {}
}

export class CheckPasswordCompatibilityError implements Action {
  readonly type = AuthTemporaryActionTypes.CheckPasswordCompatibilityError;
}

export class ClearTemporaryPassword implements Action {
  readonly type = AuthTemporaryActionTypes.ClearTemporaryPassword;
}

export class ChangeTemporaryPassword implements Action {
  readonly type = AuthTemporaryActionTypes.ChangeTemporaryPassword;
  constructor(public payload: string) { }
}

export class ChangeTemporaryPasswordSuccess implements Action {
  readonly type = AuthTemporaryActionTypes.ChangeTemporaryPasswordSuccess;
}

export class ChangeTemporaryPasswordError implements Action {
  readonly type = AuthTemporaryActionTypes.ChangeTemporaryPasswordError;
}

export type AuthTemporaryActions =
  | TemporaryPassword
  | ClearTemporaryPassword
  | CheckPasswordCompatibility
  | CheckPasswordCompatibilitySuccess
  | CheckPasswordCompatibilityError
  | ChangeTemporaryPassword
  | ChangeTemporaryPasswordSuccess
  | ChangeTemporaryPasswordError;
