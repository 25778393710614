<div style="cursor:pointer;text-align: center">
  <div class="upload-cloud">
    <span style="" class="zmdi zmdi-cloud-upload"></span>
  </div>

  <div class="info-text-section">
    <div class="size-and-type-info">
      <div>{{ uploadPrompt }}</div>
      <div *ngIf="maxSizeKB">
        {{ 'common.individualFileMaxSize' | translate }}
        <span style="padding-left: 5px">{{ maxSizeKB / 1024 }} KB</span>
      </div>
    </div>
    <div class="upload-text">
      {{ uploadText }}
    </div>
  </div>
</div>
