import { produce } from 'immer';
import { OAuthAction, OAuthActionTypes } from './oauth.actions';

export const OAUTH_FEATURE_KEY = 'oauth';

export interface OAuthState {
  loading: boolean;
}

export interface OauthPartialState {
  readonly [OAUTH_FEATURE_KEY]: OAuthState;
}

export const initialState: OAuthState = {
  loading: false,
};

export function reducer(state: OAuthState = initialState, action: OAuthAction): OAuthState {
  switch (action.type) {
    case OAuthActionTypes.Authorize:
      return produce(state, newState => {
        newState.loading = true;
      });
    case OAuthActionTypes.AuthorizeError:
      return produce(state, newState => {
        newState.loading = false;
      });
  }
  return state;
}
