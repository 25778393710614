import { Action } from '@ngrx/store';
import {
  BusinessConditionAdjustmentsDto,
  UpdateCaseMarketSituationAdjustmentCommand,
  UpdateCaseNumberOfOwnersAdjustmentCommand,
  UpdateCaseAppreciationDepreciationAdjustmentCommand,
  UpdateCaseReimportCommand,
  UpdateCaseServiceHistoryCommand,
  UpdateCaseSpecialColorAdjustmentCommand,
  UpdateCaseSpecialUsageAdjustmentCommand,
  UpdateCaseTechnicalInspectionAdjustmentCommand,
  UpdateMissingEquipmentAdjustmentCommand,
} from '@vpfa/rest-api/valuation';
import { ValuationAdjustmentType } from '@vpfa/shared/interfaces';

export enum AdjustmentsActionTypes {
  LoadBusinessSpecification = '[Adjustments] Load Business Specification',
  BusinessSpecificationLoadCompleted = '[Adjustments] Business Specification Load Completed',
  BusinessSpecificationLoadError = '[Adjustments] Business Specification Load Error',

  CaseUpdateMarketSituationAdjustment = '[Adjustments] Save Market Situation Adjustment',
  CaseUpdateNumberOfOwnersAdjustment = '[Adjustments] Save Number Of Owners Adjustment',
  CaseUpdateSpecialUsageAdjustment = '[Adjustments] Save Special Usage Adjustment',
  CaseUpdateSpecialColorAdjustment = '[Adjustments] Save Special Color Adjustment',
  CaseUpdateAppreciationDepreciationAdjustment = '[Adjustments] Save Appreciation Depreciation Adjustment',
  CaseUpdateTechnicalInspectionAdjustment = '[Adjustments] Update Technical Inspection Adjustment',
  CaseUpdateIsReimportedAdjustment = '[Adjustments] Update Is Reimported',
  CaseUpdateServiceHistoryAdjustment = '[Adjustments] Update service history',
  CaseUpdateMissingEquipmentAdjustment = '[Adjustments] Save Missing Equipment Adjustment',

  CaseUpdateAdjustmentError = '[Adjustments] Update Adjustment Error',
  CaseUpdateAdjustmentComplete = '[Adjustments] Update Adjustment Complete',

  CaseAdjustmentStarted = '[Adjustments] Case Adjustment Started',
  CaseAdjustmentComplete = '[Adjustments] Case Adjustment Complete',
  CaseAdjustmentFinished = '[Adjustments] Case Adjustments Finished',
}

export class LoadBusinessSpecification implements Action {
  readonly type = AdjustmentsActionTypes.LoadBusinessSpecification;
}

export class BusinessSpecificationLoadCompleted implements Action {
  readonly type = AdjustmentsActionTypes.BusinessSpecificationLoadCompleted;
  constructor(public payload: BusinessConditionAdjustmentsDto) {}
}

export class BusinessSpecificationLoadError implements Action {
  readonly type = AdjustmentsActionTypes.BusinessSpecificationLoadError;
}

export class CaseUpdateMarketSituationAdjustment implements Action {
  readonly type = AdjustmentsActionTypes.CaseUpdateMarketSituationAdjustment;
  constructor(public payload: UpdateCaseMarketSituationAdjustmentCommand) {}
}

export class CaseUpdateNumberOfOwnersAdjustment implements Action {
  readonly type = AdjustmentsActionTypes.CaseUpdateNumberOfOwnersAdjustment;
  constructor(public payload: UpdateCaseNumberOfOwnersAdjustmentCommand) {}
}

export class CaseUpdateSpecialUsageAdjustment implements Action {
  readonly type = AdjustmentsActionTypes.CaseUpdateSpecialUsageAdjustment;
  constructor(public payload: UpdateCaseSpecialUsageAdjustmentCommand) {}
}

export class CaseUpdateSpecialColorAdjustment implements Action {
  readonly type = AdjustmentsActionTypes.CaseUpdateSpecialColorAdjustment;
  constructor(public payload: UpdateCaseSpecialColorAdjustmentCommand) {}
}

export class CaseUpdateAppreciationDepreciationAdjustment implements Action {
  readonly type = AdjustmentsActionTypes.CaseUpdateAppreciationDepreciationAdjustment;
  constructor(public payload: UpdateCaseAppreciationDepreciationAdjustmentCommand) {}
}

export class CaseUpdateMissingEquipmentAdjustment implements Action {
  readonly type = AdjustmentsActionTypes.CaseUpdateMissingEquipmentAdjustment;
  constructor(public payload: UpdateMissingEquipmentAdjustmentCommand) {}
}

export class CaseUpdateTechnicalInspectionAdjustment implements Action {
  readonly type = AdjustmentsActionTypes.CaseUpdateTechnicalInspectionAdjustment;
  constructor(public payload: UpdateCaseTechnicalInspectionAdjustmentCommand) {}
}

export class CaseUpdateIsReimportedAdjustment implements Action {
  readonly type = AdjustmentsActionTypes.CaseUpdateIsReimportedAdjustment;
  constructor(public payload: UpdateCaseReimportCommand) {}
}

export class CaseUpdateServiceHistoryAdjustment implements Action {
  readonly type = AdjustmentsActionTypes.CaseUpdateServiceHistoryAdjustment;
  constructor(public payload: UpdateCaseServiceHistoryCommand) {}
}

export class CaseUpdateAdjustmentComplete implements Action {
  readonly type = AdjustmentsActionTypes.CaseUpdateAdjustmentComplete;
  constructor(public payload?: ValuationAdjustmentType) {}
}

export class CaseUpdateAdjustmentError implements Action {
  readonly type = AdjustmentsActionTypes.CaseUpdateAdjustmentError;
  constructor(public payload?: ValuationAdjustmentType) {}
}

export class CaseAdjustmentStarted implements Action {
  readonly type = AdjustmentsActionTypes.CaseAdjustmentStarted;
}

export class CaseAdjustmentComplete implements Action {
  readonly type = AdjustmentsActionTypes.CaseAdjustmentComplete;
}

export class CaseAdjustmentFinished implements Action {
  readonly type = AdjustmentsActionTypes.CaseAdjustmentFinished;
}

export type AdjustmentsAction =
  | LoadBusinessSpecification
  | BusinessSpecificationLoadCompleted
  | BusinessSpecificationLoadError
  | CaseUpdateMarketSituationAdjustment
  | CaseUpdateNumberOfOwnersAdjustment
  | CaseUpdateSpecialUsageAdjustment
  | CaseUpdateSpecialColorAdjustment
  | CaseUpdateAppreciationDepreciationAdjustment
  | CaseUpdateMissingEquipmentAdjustment
  | CaseUpdateTechnicalInspectionAdjustment
  | CaseUpdateIsReimportedAdjustment
  | CaseUpdateServiceHistoryAdjustment
  | CaseUpdateAdjustmentError
  | CaseAdjustmentStarted
  | CaseAdjustmentComplete
  | CaseAdjustmentFinished;

export const fromAdjustmentsActions = {
  LoadBusinessSpecification,
  BusinessSpecificationLoadCompleted,
  BusinessSpecificationLoadError,
  CaseUpdateMarketSituationAdjustment,
  CaseUpdateNumberOfOwnersAdjustment,
  CaseUpdateSpecialUsageAdjustment,
  CaseUpdateSpecialColorAdjustment,
  CaseUpdateAppreciationDepreciationAdjustment,
  CaseUpdateMissingEquipmentAdjustment,
  CaseUpdateTechnicalInspectionAdjustment,
  CaseUpdateIsReimportedAdjustment,
  CaseUpdateServiceHistoryAdjustment,
  CaseUpdateAdjustmentError,
  CaseAdjustmentStarted,
  CaseAdjustmentComplete,
  CaseAdjustmentFinished,
};
