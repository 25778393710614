import { Component, OnInit, Input } from '@angular/core';
import { CustomersFacade } from '@vpfa/dealer/customers/data';
import { isEmpty } from 'lodash';

@Component({
  selector: 'vpfa-confirm-remove-customer',
  templateUrl: './confirm-remove-customer.component.html',
  styleUrls: ['./confirm-remove-customer.component.scss'],
})
export class ConfirmRemoveCustomerComponent implements OnInit {
  @Input() id: string;

  hasRelatedCase$ = this.customersFacade.haveRelatedCase$;
  hasRelatedCaseLoading$ = this.customersFacade.haveRelatedCaseLoading$;

  constructor(private customersFacade: CustomersFacade) {}

  ngOnInit() {
    if (this.isManyCustomer) {
      this.customersFacade.loadHaveRelatedCaseForSelectedCustomers();
    } else {
      this.customersFacade.loadHaveRelatedCase([this.id]);
    }
  }

  get isManyCustomer(): boolean {
    return isEmpty(this.id);
  }
}
