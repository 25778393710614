import * as localForage from 'localforage';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { isNil } from 'lodash';
import {
  CacheStorageRecord,
  CacheWhitelistItem,
  getItemToCache,
  convertHoursToMilliseconds,
  shouldCache,
  checkExpired,
  isResponseSuccessful,
} from './cache.utils';

@Injectable({ providedIn: 'root' })
export class CacheInterceptor implements HttpInterceptor {
  /**
   * Intercept request and check if it should be cached ("GET" request and whitelisted).
   * If no - proceed with request,
   * If yes - return cached value (if already cached and not expired), or proceed with request and cache the response (if not yet cached or expired).
   *
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const cacheKey: string = req.urlWithParams;
    const itemToCache: CacheWhitelistItem = getItemToCache(cacheKey);

    if (shouldCache(req, itemToCache)) {
      return from(localForage.keys()).pipe(
        switchMap((keys: string[]) => this.getFromCache(keys, cacheKey, itemToCache)),
        switchMap((cache: HttpResponse<any>) =>
          !isNil(cache)
            ? of(cache)
            : next.handle(req).pipe(
                filter((event: HttpEvent<any>) => isResponseSuccessful(event)),
                switchMap((data: HttpResponse<any>) => this.addToCache(data, cacheKey, itemToCache)),
              ),
        ),
      );
    } else {
      return next.handle(req);
    }
  }

  private getFromCache(
    keys: string[],
    cacheKey: string,
    itemToCache: CacheWhitelistItem,
  ): Observable<HttpResponse<any | null>> {
    // INFO: key === cacheKey check is required because two different URLs can match to the same RegExp but both of them should be cached separately
    const key: string = keys.find(key => key.match(itemToCache.url) && key === cacheKey);
    if (isNil(key)) {
      return of(null);
    } else {
      return from(localForage.getItem(key)).pipe(switchMap((item: CacheStorageRecord) => checkExpired(item)));
    }
  }

  private addToCache(data: any, cacheKey: string, itemToCache: CacheWhitelistItem) {
    const value: CacheStorageRecord = {
      body: data.body,
      expires: Date.now() + convertHoursToMilliseconds(itemToCache.validFor),
    };
    return of(localForage.setItem(cacheKey, value)).pipe(map(() => data));
  }
}
