import { Component, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'vpfa-vehicle-technical-data-single-field',
  templateUrl: './vehicle-technical-data-single-field.component.html',
  styleUrls: ['./vehicle-technical-data-single-field.component.scss'],
})
export class VehicleTechnicalDataSingleFieldComponent implements AfterViewInit {
  @Input() label: string;
  @Input() value: string | number;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.cdr.markForCheck();
  }
}
