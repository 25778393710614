import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'vpfa-radar-unavailable',
  templateUrl: './radar-unavailable.component.html',
  styleUrls: ['./radar-unavailable.component.scss'],
})
export class RadarUnavailableComponent {
  constructor(private router: Router) {}

  goToDetails() {
    this.router.navigate(['stock', this.router.url.split('/')[2]]);
  }
}
