import { UserContext, IdentificationMethod } from '@vpfa/rest-api/admin';
import { sortBy } from 'lodash';

export function availableIdentificationMethods(countryCode: string, methods: IdentificationMethod[]) {
  if (countryCode === 'GB') {
    return sortBy(methods, method => (method === IdentificationMethod.VRM ? 0 : 1));
  } else {
    return sortBy(methods, method => (method === IdentificationMethod.VIN ? 0 : 1));
  }
  return methods;
}
