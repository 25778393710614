import { ValidationErrors } from '@angular/forms';
import { CURRENCY_DISPLAY_FORMAT } from '../../active-currency/active-currency.component';

export const getInputClass = (
  activeDisplayFormat: CURRENCY_DISPLAY_FORMAT,
  currencyHintMode: CURRENCY_DISPLAY_FORMAT,
  errors: ValidationErrors | null,
  isCurrency: boolean,
  displayErrors: boolean,
  hideErrorsOnBlur: boolean,
  currencySuffix: boolean,
  negative: boolean,
  left: boolean,
  right: boolean
) => {
  return {
    ['align-left']: left,
    ['align-right']: right,
    ['currency-text']:
      (isCurrency && activeDisplayFormat === CURRENCY_DISPLAY_FORMAT.CODE) ||
      currencyHintMode === CURRENCY_DISPLAY_FORMAT.CODE,
    ['currency-symbol']:
      isCurrency &&
      activeDisplayFormat === CURRENCY_DISPLAY_FORMAT.SYMBOL &&
      currencyHintMode === CURRENCY_DISPLAY_FORMAT.SYMBOL,
    ['errors']: errors && (displayErrors || hideErrorsOnBlur),
  };
};
