/**
 * Vehicle Identification Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { VPError } from '../model/vPError';
import { VehicleDto } from '../model/vehicleDto';
import { VehicleMakeDto } from '../model/vehicleMakeDto';
import { VehicleNatcodeUpdatesDto } from '../model/vehicleNatcodeUpdatesDto';
import { VehicleTypeDto } from '../model/vehicleTypeDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class VehicleService {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Vehicle types for admin users who could be associated with multiple country or business admin roles.
     * 
     * @param countryCode 
     * @param contentLanguage 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAdminVehicleTypes(countryCode?: string, contentLanguage?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VehicleTypeDto>>;
    public getAdminVehicleTypes(countryCode?: string, contentLanguage?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VehicleTypeDto>>>;
    public getAdminVehicleTypes(countryCode?: string, contentLanguage?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VehicleTypeDto>>>;
    public getAdminVehicleTypes(countryCode?: string, contentLanguage?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (countryCode !== undefined && countryCode !== null) {
          queryParameters = queryParameters.set('countryCode', <any>countryCode);
        }
        if (contentLanguage !== undefined && contentLanguage !== null) {
          queryParameters = queryParameters.set('contentLanguage', <any>contentLanguage);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<VehicleTypeDto>>(`${this.basePath}/api/Vehicle/admin/types`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param kba 
     * @param registrationDate 
     * @param buildDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByKBA(kba: string, registrationDate: string, buildDate?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VehicleDto>>;
    public getByKBA(kba: string, registrationDate: string, buildDate?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VehicleDto>>>;
    public getByKBA(kba: string, registrationDate: string, buildDate?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VehicleDto>>>;
    public getByKBA(kba: string, registrationDate: string, buildDate?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kba === null || kba === undefined) {
            throw new Error('Required parameter kba was null or undefined when calling getByKBA.');
        }

        if (registrationDate === null || registrationDate === undefined) {
            throw new Error('Required parameter registrationDate was null or undefined when calling getByKBA.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (buildDate !== undefined && buildDate !== null) {
          queryParameters = queryParameters.set('buildDate', <any>buildDate);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<VehicleDto>>(`${this.basePath}/api/Vehicle/kba/${encodeURIComponent(String(kba))}/registrationDate/${encodeURIComponent(String(registrationDate))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param matriculationNumber 
     * @param natcode 
     * @param specData 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByMatriculationNumber(matriculationNumber: string, natcode?: string, specData?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<VehicleDto>>;
    public getByMatriculationNumber(matriculationNumber: string, natcode?: string, specData?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VehicleDto>>>;
    public getByMatriculationNumber(matriculationNumber: string, natcode?: string, specData?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VehicleDto>>>;
    public getByMatriculationNumber(matriculationNumber: string, natcode?: string, specData?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (matriculationNumber === null || matriculationNumber === undefined) {
            throw new Error('Required parameter matriculationNumber was null or undefined when calling getByMatriculationNumber.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (natcode !== undefined && natcode !== null) {
          queryParameters = queryParameters.set('Natcode', <any>natcode);
        }
        if (specData !== undefined && specData !== null) {
          queryParameters = queryParameters.set('SpecData', <any>specData);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<VehicleDto>>(`${this.basePath}/api/Vehicle/matriculationNumber/${encodeURIComponent(String(matriculationNumber))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param vin 
     * @param natcode 
     * @param vinType 
     * @param specData 
     * @param repairData 
     * @param images 
     * @param registrationDate 
     * @param buildDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByVin(vin: string, natcode?: string, vinType?: string, specData?: boolean, repairData?: boolean, images?: boolean, registrationDate?: string, buildDate?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VehicleDto>>;
    public getByVin(vin: string, natcode?: string, vinType?: string, specData?: boolean, repairData?: boolean, images?: boolean, registrationDate?: string, buildDate?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VehicleDto>>>;
    public getByVin(vin: string, natcode?: string, vinType?: string, specData?: boolean, repairData?: boolean, images?: boolean, registrationDate?: string, buildDate?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VehicleDto>>>;
    public getByVin(vin: string, natcode?: string, vinType?: string, specData?: boolean, repairData?: boolean, images?: boolean, registrationDate?: string, buildDate?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (vin === null || vin === undefined) {
            throw new Error('Required parameter vin was null or undefined when calling getByVin.');
        }








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (natcode !== undefined && natcode !== null) {
          queryParameters = queryParameters.set('Natcode', <any>natcode);
        }
        if (vinType !== undefined && vinType !== null) {
          queryParameters = queryParameters.set('VinType', <any>vinType);
        }
        if (specData !== undefined && specData !== null) {
          queryParameters = queryParameters.set('SpecData', <any>specData);
        }
        if (repairData !== undefined && repairData !== null) {
          queryParameters = queryParameters.set('RepairData', <any>repairData);
        }
        if (images !== undefined && images !== null) {
          queryParameters = queryParameters.set('Images', <any>images);
        }
        if (registrationDate !== undefined && registrationDate !== null) {
          queryParameters = queryParameters.set('RegistrationDate', <any>registrationDate);
        }
        if (buildDate !== undefined && buildDate !== null) {
          queryParameters = queryParameters.set('BuildDate', <any>buildDate);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<VehicleDto>>(`${this.basePath}/api/Vehicle/vin/${encodeURIComponent(String(vin))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param vrm 
     * @param natcode 
     * @param vinType 
     * @param specData 
     * @param repairData 
     * @param images 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByVrm(vrm: string, natcode?: string, vinType?: string, specData?: boolean, repairData?: boolean, images?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<VehicleDto>>;
    public getByVrm(vrm: string, natcode?: string, vinType?: string, specData?: boolean, repairData?: boolean, images?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VehicleDto>>>;
    public getByVrm(vrm: string, natcode?: string, vinType?: string, specData?: boolean, repairData?: boolean, images?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VehicleDto>>>;
    public getByVrm(vrm: string, natcode?: string, vinType?: string, specData?: boolean, repairData?: boolean, images?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (vrm === null || vrm === undefined) {
            throw new Error('Required parameter vrm was null or undefined when calling getByVrm.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (natcode !== undefined && natcode !== null) {
          queryParameters = queryParameters.set('Natcode', <any>natcode);
        }
        if (vinType !== undefined && vinType !== null) {
          queryParameters = queryParameters.set('VinType', <any>vinType);
        }
        if (specData !== undefined && specData !== null) {
          queryParameters = queryParameters.set('SpecData', <any>specData);
        }
        if (repairData !== undefined && repairData !== null) {
          queryParameters = queryParameters.set('RepairData', <any>repairData);
        }
        if (images !== undefined && images !== null) {
          queryParameters = queryParameters.set('Images', <any>images);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<VehicleDto>>(`${this.basePath}/api/Vehicle/vrm/${encodeURIComponent(String(vrm))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param countryCode 
     * @param updatedSinceDate 
     * @param page 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNatcodeUpdates(countryCode: string, updatedSinceDate?: string, page?: number, observe?: 'body', reportProgress?: boolean): Observable<VehicleNatcodeUpdatesDto>;
    public getNatcodeUpdates(countryCode: string, updatedSinceDate?: string, page?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VehicleNatcodeUpdatesDto>>;
    public getNatcodeUpdates(countryCode: string, updatedSinceDate?: string, page?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VehicleNatcodeUpdatesDto>>;
    public getNatcodeUpdates(countryCode: string, updatedSinceDate?: string, page?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (countryCode === null || countryCode === undefined) {
            throw new Error('Required parameter countryCode was null or undefined when calling getNatcodeUpdates.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updatedSinceDate !== undefined && updatedSinceDate !== null) {
          queryParameters = queryParameters.set('updatedSinceDate', <any>updatedSinceDate);
        }
        if (page !== undefined && page !== null) {
          queryParameters = queryParameters.set('page', <any>page);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<VehicleNatcodeUpdatesDto>(`${this.basePath}/api/Vehicle/vehicles/updates/${encodeURIComponent(String(countryCode))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param natcode 
     * @param registrationDate 
     * @param buildDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVehicleByNatcode(natcode: string, registrationDate: string, buildDate?: string, observe?: 'body', reportProgress?: boolean): Observable<VehicleDto>;
    public getVehicleByNatcode(natcode: string, registrationDate: string, buildDate?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VehicleDto>>;
    public getVehicleByNatcode(natcode: string, registrationDate: string, buildDate?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VehicleDto>>;
    public getVehicleByNatcode(natcode: string, registrationDate: string, buildDate?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (natcode === null || natcode === undefined) {
            throw new Error('Required parameter natcode was null or undefined when calling getVehicleByNatcode.');
        }

        if (registrationDate === null || registrationDate === undefined) {
            throw new Error('Required parameter registrationDate was null or undefined when calling getVehicleByNatcode.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (buildDate !== undefined && buildDate !== null) {
          queryParameters = queryParameters.set('buildDate', <any>buildDate);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<VehicleDto>(`${this.basePath}/api/Vehicle/natCode/${encodeURIComponent(String(natcode))}/registrationDate/${encodeURIComponent(String(registrationDate))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param typeCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVehicleMakes(typeCode: number, observe?: 'body', reportProgress?: boolean): Observable<Array<VehicleMakeDto>>;
    public getVehicleMakes(typeCode: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VehicleMakeDto>>>;
    public getVehicleMakes(typeCode: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VehicleMakeDto>>>;
    public getVehicleMakes(typeCode: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (typeCode === null || typeCode === undefined) {
            throw new Error('Required parameter typeCode was null or undefined when calling getVehicleMakes.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<VehicleMakeDto>>(`${this.basePath}/api/Vehicle/makes/type/${encodeURIComponent(String(typeCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Vehicle makes for admin users who could be associated with multiple country or business admin roles.
     * 
     * @param typeCode 
     * @param countryCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVehicleMakes_1(typeCode: number, countryCode?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VehicleMakeDto>>;
    public getVehicleMakes_1(typeCode: number, countryCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VehicleMakeDto>>>;
    public getVehicleMakes_1(typeCode: number, countryCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VehicleMakeDto>>>;
    public getVehicleMakes_1(typeCode: number, countryCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (typeCode === null || typeCode === undefined) {
            throw new Error('Required parameter typeCode was null or undefined when calling getVehicleMakes_1.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (countryCode !== undefined && countryCode !== null) {
          queryParameters = queryParameters.set('countryCode', <any>countryCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<VehicleMakeDto>>(`${this.basePath}/api/Vehicle/admin/makes/type/${encodeURIComponent(String(typeCode))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVehicleTypes(observe?: 'body', reportProgress?: boolean): Observable<Array<VehicleTypeDto>>;
    public getVehicleTypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VehicleTypeDto>>>;
    public getVehicleTypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VehicleTypeDto>>>;
    public getVehicleTypes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<VehicleTypeDto>>(`${this.basePath}/api/Vehicle/types`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
