import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkewLineComponent } from './components/skew-line/skew-line.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SkewLineComponent],
  exports: [SkewLineComponent],
})
export class UiKitSkewLineModule {}
