/**
 * Public SSM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0-private
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { CO2EmissionRangeDto } from '../model/cO2EmissionRangeDto';
import { CaseByMatriculationNumberSearchListItemDto } from '../model/caseByMatriculationNumberSearchListItemDto';
import { CaseByVinSearchListItemDto } from '../model/caseByVinSearchListItemDto';
import { CaseByVrmSearchListItemDto } from '../model/caseByVrmSearchListItemDto';
import { CaseFuelSubtypeDto } from '../model/caseFuelSubtypeDto';
import { CaseListItemDto } from '../model/caseListItemDto';
import { CaseListItemDtoPageWithFiltersResult } from '../model/caseListItemDtoPageWithFiltersResult';
import { CaseSummaryDto } from '../model/caseSummaryDto';
import { EmissionInfoDto } from '../model/emissionInfoDto';
import { EquipmentDto } from '../model/equipmentDto';
import { EquipmentStateChange } from '../model/equipmentStateChange';
import { ExpertAreaDto } from '../model/expertAreaDto';
import { Filter } from '../model/filter';
import { LightDamageAreaDto } from '../model/lightDamageAreaDto';
import { Order } from '../model/order';
import { PdfDto } from '../model/pdfDto';
import { PublicCaseListItemDto } from '../model/publicCaseListItemDto';
import { PublicCaseSummaryDto } from '../model/publicCaseSummaryDto';
import { ResellerCaseListItemDto } from '../model/resellerCaseListItemDto';
import { ResellerCaseSummaryDto } from '../model/resellerCaseSummaryDto';
import { ResellerOfferDto } from '../model/resellerOfferDto';
import { ResellerOfferListItemDto } from '../model/resellerOfferListItemDto';
import { ReturnReportDto } from '../model/returnReportDto';
import { RoadTaxDataDto } from '../model/roadTaxDataDto';
import { TwinnerUrlDto } from '../model/twinnerUrlDto';
import { VPError } from '../model/vPError';
import { ValuationRequestPriceDetailsDto } from '../model/valuationRequestPriceDetailsDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CaseViewService {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param branchId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public countPublic(branchId: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public countPublic(branchId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public countPublic(branchId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public countPublic(branchId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (branchId === null || branchId === undefined) {
            throw new Error('Required parameter branchId was null or undefined when calling countPublic.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<number>(`${this.basePath}/api/CaseView/${encodeURIComponent(String(branchId))}/Public/Count`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get case within the user business. If the case exists it&#x27;s going to return error(ErrorCode.CaseNotFound) if it&#x27;s from other business.
     * AmsAction:GET/api/CaseView/{caseId} AmsOptionalAction:MakeValuationWithoutVIN AmsOptionalAction:MakeValuationWithoutNumberPlate AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{caseId} 
     * @param caseId Case id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCase(caseId: string, observe?: 'body', reportProgress?: boolean): Observable<CaseSummaryDto>;
    public getCase(caseId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CaseSummaryDto>>;
    public getCase(caseId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CaseSummaryDto>>;
    public getCase(caseId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getCase.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CaseSummaryDto>(`${this.basePath}/api/CaseView/${encodeURIComponent(String(caseId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get case equipments within the user business. If the case exists it&#x27;s going to return error(ErrorCode.CaseNotFound) if it&#x27;s from other business.
     * AmsAction:GET/api/CaseView/{caseId}/equipments AmsResource:{country}/{business}/case/{caseId} 
     * @param caseId Case id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCaseEquipments(caseId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<EquipmentDto>>;
    public getCaseEquipments(caseId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EquipmentDto>>>;
    public getCaseEquipments(caseId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EquipmentDto>>>;
    public getCaseEquipments(caseId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getCaseEquipments.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<EquipmentDto>>(`${this.basePath}/api/CaseView/${encodeURIComponent(String(caseId))}/equipments`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get case equipments within the user business. If the case exists it&#x27;s going to return error(ErrorCode.CaseNotFound) if it&#x27;s from other business.  Providing mocked equipment states impacts on recalculating prices and states on the response equipment list  Has to be POST because of swagger dysfunctions
     * AmsAction:POST/api/CaseView/{caseId}/equipments/changedStates AmsResource:{country}/{business}/case/{caseId} 
     * @param caseId Case id
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCaseEquipmentsWithMockedEquipmentStates(caseId: string, body?: Array<EquipmentStateChange>, observe?: 'body', reportProgress?: boolean): Observable<Array<EquipmentDto>>;
    public getCaseEquipmentsWithMockedEquipmentStates(caseId: string, body?: Array<EquipmentStateChange>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EquipmentDto>>>;
    public getCaseEquipmentsWithMockedEquipmentStates(caseId: string, body?: Array<EquipmentStateChange>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EquipmentDto>>>;
    public getCaseEquipmentsWithMockedEquipmentStates(caseId: string, body?: Array<EquipmentStateChange>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getCaseEquipmentsWithMockedEquipmentStates.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<EquipmentDto>>(`${this.basePath}/api/CaseView/${encodeURIComponent(String(caseId))}/equipments/changedStates`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CaseView/{caseId}/PriceDetails AmsOptionalAction:MakeValuationWithoutVIN AmsOptionalAction:MakeValuationWithoutNumberPlate AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{caseId} 
     * @param caseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCasePriceDetails(caseId: string, observe?: 'body', reportProgress?: boolean): Observable<ValuationRequestPriceDetailsDto>;
    public getCasePriceDetails(caseId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ValuationRequestPriceDetailsDto>>;
    public getCasePriceDetails(caseId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ValuationRequestPriceDetailsDto>>;
    public getCasePriceDetails(caseId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getCasePriceDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ValuationRequestPriceDetailsDto>(`${this.basePath}/api/CaseView/${encodeURIComponent(String(caseId))}/PriceDetails`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CaseView/SearchByMatriculationNumber/{matriculationNumber} AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/_* 
     * @param matriculationNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCasesByMatriculationNumber(matriculationNumber: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CaseByMatriculationNumberSearchListItemDto>>;
    public getCasesByMatriculationNumber(matriculationNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CaseByMatriculationNumberSearchListItemDto>>>;
    public getCasesByMatriculationNumber(matriculationNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CaseByMatriculationNumberSearchListItemDto>>>;
    public getCasesByMatriculationNumber(matriculationNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (matriculationNumber === null || matriculationNumber === undefined) {
            throw new Error('Required parameter matriculationNumber was null or undefined when calling getCasesByMatriculationNumber.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CaseByMatriculationNumberSearchListItemDto>>(`${this.basePath}/api/CaseView/SearchByMatriculationNumber/${encodeURIComponent(String(matriculationNumber))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CaseView/SearchByVin/{vin} AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/_* 
     * @param vin 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCasesByVin(vin: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CaseByVinSearchListItemDto>>;
    public getCasesByVin(vin: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CaseByVinSearchListItemDto>>>;
    public getCasesByVin(vin: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CaseByVinSearchListItemDto>>>;
    public getCasesByVin(vin: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (vin === null || vin === undefined) {
            throw new Error('Required parameter vin was null or undefined when calling getCasesByVin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CaseByVinSearchListItemDto>>(`${this.basePath}/api/CaseView/SearchByVin/${encodeURIComponent(String(vin))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CaseView/SearchByVrm/{vrm} AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/_* 
     * @param vrm 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCasesByVrm(vrm: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CaseByVrmSearchListItemDto>>;
    public getCasesByVrm(vrm: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CaseByVrmSearchListItemDto>>>;
    public getCasesByVrm(vrm: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CaseByVrmSearchListItemDto>>>;
    public getCasesByVrm(vrm: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (vrm === null || vrm === undefined) {
            throw new Error('Required parameter vrm was null or undefined when calling getCasesByVrm.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CaseByVrmSearchListItemDto>>(`${this.basePath}/api/CaseView/SearchByVrm/${encodeURIComponent(String(vrm))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCo2EmissionRanges(observe?: 'body', reportProgress?: boolean): Observable<Array<CO2EmissionRangeDto>>;
    public getCo2EmissionRanges(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CO2EmissionRangeDto>>>;
    public getCo2EmissionRanges(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CO2EmissionRangeDto>>>;
    public getCo2EmissionRanges(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CO2EmissionRangeDto>>(`${this.basePath}/api/CaseView/co2EmissionRanges`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CaseView/{caseId}/EmissionInfo AmsResource:{country}/{business}/case/{caseId} 
     * @param caseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEmissionInfo(caseId: string, observe?: 'body', reportProgress?: boolean): Observable<EmissionInfoDto>;
    public getEmissionInfo(caseId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EmissionInfoDto>>;
    public getEmissionInfo(caseId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EmissionInfoDto>>;
    public getEmissionInfo(caseId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getEmissionInfo.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<EmissionInfoDto>(`${this.basePath}/api/CaseView/${encodeURIComponent(String(caseId))}/EmissionInfo`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CaseView/{caseId}/lightDamageEstimator AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{caseId} 
     * @param caseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEstimator(caseId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<LightDamageAreaDto>>;
    public getEstimator(caseId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LightDamageAreaDto>>>;
    public getEstimator(caseId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LightDamageAreaDto>>>;
    public getEstimator(caseId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getEstimator.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LightDamageAreaDto>>(`${this.basePath}/api/CaseView/${encodeURIComponent(String(caseId))}/lightDamageEstimator`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CaseView/ExpertArea/{caseId} AmsOptionalAction:MakeValuationWithoutVIN AmsOptionalAction:MakeValuationWithoutNumberPlate AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{caseId} 
     * @param caseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getExpertArea(caseId: string, observe?: 'body', reportProgress?: boolean): Observable<ExpertAreaDto>;
    public getExpertArea(caseId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExpertAreaDto>>;
    public getExpertArea(caseId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExpertAreaDto>>;
    public getExpertArea(caseId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getExpertArea.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ExpertAreaDto>(`${this.basePath}/api/CaseView/ExpertArea/${encodeURIComponent(String(caseId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CaseView/{caseId}/FuelSubtypes AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{caseId} 
     * @param caseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFuelSubtypes(caseId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CaseFuelSubtypeDto>>;
    public getFuelSubtypes(caseId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CaseFuelSubtypeDto>>>;
    public getFuelSubtypes(caseId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CaseFuelSubtypeDto>>>;
    public getFuelSubtypes(caseId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getFuelSubtypes.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CaseFuelSubtypeDto>>(`${this.basePath}/api/CaseView/${encodeURIComponent(String(caseId))}/FuelSubtypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CaseView/{caseId}/images/{size}/{viewCode} AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{caseId} 
     * @param caseId 
     * @param size 
     * @param viewCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getImagesFromSorc(caseId: string, size: string, viewCode: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getImagesFromSorc(caseId: string, size: string, viewCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getImagesFromSorc(caseId: string, size: string, viewCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getImagesFromSorc(caseId: string, size: string, viewCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getImagesFromSorc.');
        }

        if (size === null || size === undefined) {
            throw new Error('Required parameter size was null or undefined when calling getImagesFromSorc.');
        }

        if (viewCode === null || viewCode === undefined) {
            throw new Error('Required parameter viewCode was null or undefined when calling getImagesFromSorc.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Blob>(`${this.basePath}/api/CaseView/${encodeURIComponent(String(caseId))}/images/${encodeURIComponent(String(size))}/${encodeURIComponent(String(viewCode))}`,
            {
                //@ts-ignore
                responseType:'blob',
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CaseView/{caseId}/quote/pdf AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{caseId} 
     * @param caseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLatestQuotePdf(caseId: string, observe?: 'body', reportProgress?: boolean): Observable<PdfDto>;
    public getLatestQuotePdf(caseId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PdfDto>>;
    public getLatestQuotePdf(caseId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PdfDto>>;
    public getLatestQuotePdf(caseId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getLatestQuotePdf.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PdfDto>(`${this.basePath}/api/CaseView/${encodeURIComponent(String(caseId))}/quote/pdf`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CaseView/{caseId}/stockOffer/pdf AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{caseId} 
     * @param caseId 
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLatestStockOfferPdf(caseId: string, customerId?: string, observe?: 'body', reportProgress?: boolean): Observable<PdfDto>;
    public getLatestStockOfferPdf(caseId: string, customerId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PdfDto>>;
    public getLatestStockOfferPdf(caseId: string, customerId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PdfDto>>;
    public getLatestStockOfferPdf(caseId: string, customerId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getLatestStockOfferPdf.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerId !== undefined && customerId !== null) {
          queryParameters = queryParameters.set('customerId', <any>customerId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PdfDto>(`${this.basePath}/api/CaseView/${encodeURIComponent(String(caseId))}/stockOffer/pdf`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CaseView/List AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/_* 
     * @param showOnlyStock 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getList(showOnlyStock?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<CaseListItemDto>>;
    public getList(showOnlyStock?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CaseListItemDto>>>;
    public getList(showOnlyStock?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CaseListItemDto>>>;
    public getList(showOnlyStock?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (showOnlyStock !== undefined && showOnlyStock !== null) {
          queryParameters = queryParameters.set('showOnlyStock', <any>showOnlyStock);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CaseListItemDto>>(`${this.basePath}/api/CaseView/List`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CaseView/List/v2 AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/_* 
     * @param filters 
     * @param order 
     * @param pageIndex 
     * @param pageSize 
     * @param showOnlyStock 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListAsync(filters?: Array<Filter>, order?: Array<Order>, pageIndex?: number, pageSize?: number, showOnlyStock?: boolean, observe?: 'body', reportProgress?: boolean): Observable<CaseListItemDtoPageWithFiltersResult>;
    public getListAsync(filters?: Array<Filter>, order?: Array<Order>, pageIndex?: number, pageSize?: number, showOnlyStock?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CaseListItemDtoPageWithFiltersResult>>;
    public getListAsync(filters?: Array<Filter>, order?: Array<Order>, pageIndex?: number, pageSize?: number, showOnlyStock?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CaseListItemDtoPageWithFiltersResult>>;
    public getListAsync(filters?: Array<Filter>, order?: Array<Order>, pageIndex?: number, pageSize?: number, showOnlyStock?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters) {

          filters.forEach((elementItem: any, elementIndex) => {

            const isQuerySearchOrOrderParams =
                elementItem.values !== undefined ||
                elementItem.from !== undefined ||
                elementItem.to !== undefined ||
                elementItem.value !== undefined ||
                elementItem.descending !== undefined ||
                elementItem.isNull !== undefined;

            if (isQuerySearchOrOrderParams) {
                queryParameters = queryParameters.set(`Filters[${elementIndex}].fieldName`, elementItem.fieldName);
        
                if (elementItem.values && Array.isArray(elementItem.values)) {
                    elementItem.values.forEach((selectedDropdownValue, index) => {
                        queryParameters = queryParameters.set(
                        `Filters[${elementIndex}].values[${index}]`,
                        selectedDropdownValue.toString()
                        );
                    });
                }
                if (elementItem.from !== undefined && elementItem.from !== null) {
                    queryParameters = queryParameters.set(`Filters[${elementIndex}].from`, elementItem.from);
                }
                if (elementItem.to !== undefined && elementItem.to !== null) {
                    queryParameters = queryParameters.set(`Filters[${elementIndex}].to`, elementItem.to);
                }
                if (elementItem.value !== undefined  && elementItem.value !== null) {
                    queryParameters = queryParameters.set(`Filters[${elementIndex}].value`, elementItem.value as string);
                }
                if (elementItem.descending !== undefined && elementItem.descending !== null) {
                    queryParameters = queryParameters.set(`Filters[${elementIndex}].descending`, Boolean(elementItem.descending).toString());
                }
                if (elementItem.isNull !== undefined && elementItem.isNull !== null) {
                    queryParameters = queryParameters.set(`Filters[${elementIndex}].isNull`, Boolean(elementItem.isNull).toString());
                }
            } else {
                queryParameters = queryParameters.append('Filters', <any>elementItem);
            }
            
          });



        }
        if (order) {

          order.forEach((elementItem: any, elementIndex) => {

            const isQuerySearchOrOrderParams =
                elementItem.values !== undefined ||
                elementItem.from !== undefined ||
                elementItem.to !== undefined ||
                elementItem.value !== undefined ||
                elementItem.descending !== undefined ||
                elementItem.isNull !== undefined;

            if (isQuerySearchOrOrderParams) {
                queryParameters = queryParameters.set(`Order[${elementIndex}].fieldName`, elementItem.fieldName);
        
                if (elementItem.values && Array.isArray(elementItem.values)) {
                    elementItem.values.forEach((selectedDropdownValue, index) => {
                        queryParameters = queryParameters.set(
                        `Order[${elementIndex}].values[${index}]`,
                        selectedDropdownValue.toString()
                        );
                    });
                }
                if (elementItem.from !== undefined && elementItem.from !== null) {
                    queryParameters = queryParameters.set(`Order[${elementIndex}].from`, elementItem.from);
                }
                if (elementItem.to !== undefined && elementItem.to !== null) {
                    queryParameters = queryParameters.set(`Order[${elementIndex}].to`, elementItem.to);
                }
                if (elementItem.value !== undefined  && elementItem.value !== null) {
                    queryParameters = queryParameters.set(`Order[${elementIndex}].value`, elementItem.value as string);
                }
                if (elementItem.descending !== undefined && elementItem.descending !== null) {
                    queryParameters = queryParameters.set(`Order[${elementIndex}].descending`, Boolean(elementItem.descending).toString());
                }
                if (elementItem.isNull !== undefined && elementItem.isNull !== null) {
                    queryParameters = queryParameters.set(`Order[${elementIndex}].isNull`, Boolean(elementItem.isNull).toString());
                }
            } else {
                queryParameters = queryParameters.append('Order', <any>elementItem);
            }
            
          });



        }
        if (pageIndex !== undefined && pageIndex !== null) {
          queryParameters = queryParameters.set('PageIndex', <any>pageIndex);
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (showOnlyStock !== undefined && showOnlyStock !== null) {
          queryParameters = queryParameters.set('showOnlyStock', <any>showOnlyStock);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CaseListItemDtoPageWithFiltersResult>(`${this.basePath}/api/CaseView/List/v2`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param caseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublicCase(caseId: string, observe?: 'body', reportProgress?: boolean): Observable<PublicCaseSummaryDto>;
    public getPublicCase(caseId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PublicCaseSummaryDto>>;
    public getPublicCase(caseId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PublicCaseSummaryDto>>;
    public getPublicCase(caseId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getPublicCase.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PublicCaseSummaryDto>(`${this.basePath}/api/CaseView/Public/${encodeURIComponent(String(caseId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param branchId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublicList(branchId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PublicCaseListItemDto>>;
    public getPublicList(branchId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PublicCaseListItemDto>>>;
    public getPublicList(branchId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PublicCaseListItemDto>>>;
    public getPublicList(branchId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (branchId === null || branchId === undefined) {
            throw new Error('Required parameter branchId was null or undefined when calling getPublicList.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<PublicCaseListItemDto>>(`${this.basePath}/api/CaseView/${encodeURIComponent(String(branchId))}/Public/List`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param caseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getResellerCase(caseId: string, observe?: 'body', reportProgress?: boolean): Observable<ResellerCaseSummaryDto>;
    public getResellerCase(caseId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResellerCaseSummaryDto>>;
    public getResellerCase(caseId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResellerCaseSummaryDto>>;
    public getResellerCase(caseId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getResellerCase.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ResellerCaseSummaryDto>(`${this.basePath}/api/CaseView/Reseller/${encodeURIComponent(String(caseId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param branchId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getResellerList(branchId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ResellerCaseListItemDto>>;
    public getResellerList(branchId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ResellerCaseListItemDto>>>;
    public getResellerList(branchId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ResellerCaseListItemDto>>>;
    public getResellerList(branchId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (branchId === null || branchId === undefined) {
            throw new Error('Required parameter branchId was null or undefined when calling getResellerList.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ResellerCaseListItemDto>>(`${this.basePath}/api/CaseView/${encodeURIComponent(String(branchId))}/Reseller/List`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CaseView/{caseId}/ResellerOffer/{resellerOfferId} AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{caseId} 
     * @param caseId 
     * @param resellerOfferId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getResellerOffer(caseId: string, resellerOfferId: number, observe?: 'body', reportProgress?: boolean): Observable<ResellerOfferDto>;
    public getResellerOffer(caseId: string, resellerOfferId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResellerOfferDto>>;
    public getResellerOffer(caseId: string, resellerOfferId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResellerOfferDto>>;
    public getResellerOffer(caseId: string, resellerOfferId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getResellerOffer.');
        }

        if (resellerOfferId === null || resellerOfferId === undefined) {
            throw new Error('Required parameter resellerOfferId was null or undefined when calling getResellerOffer.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ResellerOfferDto>(`${this.basePath}/api/CaseView/${encodeURIComponent(String(caseId))}/ResellerOffer/${encodeURIComponent(String(resellerOfferId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CaseView/{caseId}/ResellerOffer/List AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{caseId} 
     * @param caseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getResellerOfferList(caseId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ResellerOfferListItemDto>>;
    public getResellerOfferList(caseId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ResellerOfferListItemDto>>>;
    public getResellerOfferList(caseId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ResellerOfferListItemDto>>>;
    public getResellerOfferList(caseId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getResellerOfferList.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ResellerOfferListItemDto>>(`${this.basePath}/api/CaseView/${encodeURIComponent(String(caseId))}/ResellerOffer/List`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CaseView/ResellerOffer/List AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/_* 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getResellerOfferListByBusiness(observe?: 'body', reportProgress?: boolean): Observable<Array<ResellerOfferListItemDto>>;
    public getResellerOfferListByBusiness(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ResellerOfferListItemDto>>>;
    public getResellerOfferListByBusiness(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ResellerOfferListItemDto>>>;
    public getResellerOfferListByBusiness(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ResellerOfferListItemDto>>(`${this.basePath}/api/CaseView/ResellerOffer/List`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CaseView/ReturnReport/{caseId} AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{caseId} 
     * @param caseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReturnReport(caseId: string, observe?: 'body', reportProgress?: boolean): Observable<ReturnReportDto>;
    public getReturnReport(caseId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReturnReportDto>>;
    public getReturnReport(caseId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReturnReportDto>>;
    public getReturnReport(caseId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getReturnReport.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ReturnReportDto>(`${this.basePath}/api/CaseView/ReturnReport/${encodeURIComponent(String(caseId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CaseView/{caseId}/RoadTaxData AmsResource:{country}/{business}/case/{caseId} 
     * @param caseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRoadTaxData(caseId: string, observe?: 'body', reportProgress?: boolean): Observable<RoadTaxDataDto>;
    public getRoadTaxData(caseId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RoadTaxDataDto>>;
    public getRoadTaxData(caseId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RoadTaxDataDto>>;
    public getRoadTaxData(caseId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getRoadTaxData.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RoadTaxDataDto>(`${this.basePath}/api/CaseView/${encodeURIComponent(String(caseId))}/RoadTaxData`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CaseView/TwinnerUrl/{caseId} AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/case/{caseId} 
     * @param caseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTwinnerUrl(caseId: string, observe?: 'body', reportProgress?: boolean): Observable<TwinnerUrlDto>;
    public getTwinnerUrl(caseId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TwinnerUrlDto>>;
    public getTwinnerUrl(caseId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TwinnerUrlDto>>;
    public getTwinnerUrl(caseId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getTwinnerUrl.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TwinnerUrlDto>(`${this.basePath}/api/CaseView/TwinnerUrl/${encodeURIComponent(String(caseId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Validate case equipments within the user business. If the case exists it&#x27;s going to return error(ErrorCode.CaseNotFound) if it&#x27;s from other business.
     * AmsAction:GET/api/CaseView/{caseId}/IsEquipmentSetValid AmsResource:{country}/{business}/case/{caseId} 
     * @param caseId Case id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public isCaseEquipmentSetValid(caseId: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public isCaseEquipmentSetValid(caseId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public isCaseEquipmentSetValid(caseId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public isCaseEquipmentSetValid(caseId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling isCaseEquipmentSetValid.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<boolean>(`${this.basePath}/api/CaseView/${encodeURIComponent(String(caseId))}/IsEquipmentSetValid`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
