<div class="too-old" [ngClass]="{ 'show-icon': !hideIcon }" [fxLayoutAlign]="iconPlacement + ' center'">
  <ng-container *ngIf="!(isEditable && parentFormGroup && fcName); else editablePrice">
    <span [ngClass]="{ 'readonly-price': readonlyPrice }" class="value">{{ price | lT }}</span>
    <ng-container *ngTemplateOutlet="historyIcon"></ng-container>
  </ng-container>
</div>

<ng-template #editablePrice>
  <vpfa-number-field
    [currencyHintMode]="CURRENCY_DISPLAY_FORMAT.SYMBOL"
    [fcName]="fcName"
    [parentFormGroup]="parentFormGroup"
    [maxFractionNumber]="2"
    errorMsgVertPosition="outsideRight"
    [errorMessages]="errorMessages"
    [heightCompensationPx]="heightCompensationPx"
  >
  </vpfa-number-field>
  <div class="input-history-icon">
    <ng-container *ngTemplateOutlet="historyIcon"></ng-container>
  </div>
</ng-template>

<ng-template #historyIcon>
  <i
    *ngIf="!hideIcon"
    class="suffix-info"
    (click)="iconClicked.emit()"
    nz-icon
    [ngClass]="iconClass"
    nzType="info-circle"
    nzTheme="fill"
  ></i>
</ng-template>
