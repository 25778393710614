<p style="margin-top: 1em">{{ vehicleConfirmation?.makeModel }}</p>

<div [fxLayout]="'column'">
  <div class="status-block" [ngClass]="{ warning: vehicleConfirmation?.isStolen }">
    {{ stolenStatus | translate }}
  </div>

  <div class="status-block" [ngClass]="{ warning: vehicleConfirmation?.isHighRisk }">
    {{ highRiskStatus | translate }}
  </div>

  <div class="status-block" [ngClass]="financeStatusStyling">
    {{ financeStatus | translate }}
  </div>

  <div class="status-block" [ngClass]="{ warning: vehicleConfirmation?.isABICondition }">
    {{ abiConditionStatus | translate }}
  </div>

  <div class="status-block" [ngClass]="{ warning: vehicleConfirmation?.isScrapped }">
    {{ scrappedStatus | translate }}
  </div>

  <div class="status-block" [ngClass]="plateStatusStyling">
    {{ plateStatus | translate }}
  </div>

  <div class="status-block" [ngClass]="{ warning: vehicleConfirmation?.isExported }">
    {{ exportDataStatus | translate }}
  </div>

  <div class="status-block" [ngClass]="{ warning: vehicleConfirmation?.isImported }">
    {{ importDataStatus | translate }}
  </div>

  <div class="status-block" [ngClass]="extraDataStatusStyling">
    {{ extraDataStatus | translate }}
  </div>

  <vpfa-label-value
    [label]="'provenanceCheck.vehicleConfirmationStatus.vehicleRegistrationMark' | translate"
    [value]="vehicleConfirmation?.vrm"
  ></vpfa-label-value>

  <vpfa-label-value
    [label]="'provenanceCheck.vehicleConfirmationStatus.noOfGears' | translate"
    [value]="vehicleConfirmation?.numberOfGears | numberLocale: '1.0-2'"
  ></vpfa-label-value>

  <vpfa-label-value
    [label]="'provenanceCheck.vehicleConfirmationStatus.make' | translate"
    [value]="vehicleConfirmation?.make"
  ></vpfa-label-value>

  <vpfa-label-value
    [label]="'provenanceCheck.vehicleConfirmationStatus.model' | translate"
    [value]="vehicleConfirmation?.model"
  ></vpfa-label-value>

  <vpfa-label-value
    [label]="'provenanceCheck.vehicleConfirmationStatus.firstRegistered' | translate"
    [value]="vehicleConfirmation?.firstRegisteredDate | dateLocale"
  ></vpfa-label-value>

  <vpfa-label-value
    [label]="'provenanceCheck.vehicleConfirmationStatus.co2Emissions' | translate"
    [value]="vehicleConfirmation?.cO2Emissions | numberLocale: '1.0-2'"
  ></vpfa-label-value>

  <vpfa-label-value
    [label]="'provenanceCheck.vehicleConfirmationStatus.transmissionAndFuelType' | translate"
    [value]="transmissionAndFuelType"
  ></vpfa-label-value>

  <vpfa-label-value
    [label]="'provenanceCheck.vehicleConfirmationStatus.engineCapacity' | translate"
    [value]="{ value: vehicleConfirmation?.engineCapacity, unit: 'ccm' } | engineCapacity"
  ></vpfa-label-value>

  <vpfa-label-value
    [label]="'provenanceCheck.vehicleConfirmationStatus.yearOfManufacture' | translate"
    [value]="vehicleConfirmation?.yearOfManufacture"
  ></vpfa-label-value>

  <vpfa-label-value
    [label]="'provenanceCheck.vehicleConfirmationStatus.colour' | translate"
    [value]="vehicleConfirmation?.colour"
  ></vpfa-label-value>

  <vpfa-label-value
    [label]="'provenanceCheck.vehicleConfirmationStatus.engineNo' | translate"
    [value]="vehicleConfirmation?.engineNumber"
  ></vpfa-label-value>

  <vpfa-label-value
    [label]="'provenanceCheck.vehicleConfirmationStatus.bodyType' | translate"
    [value]="vehicleConfirmation?.bodyType"
  ></vpfa-label-value>

  <vpfa-label-value
    [label]="'provenanceCheck.vehicleConfirmationStatus.roadTax12Months' | translate"
    [value]="vehicleConfirmation?.roadTax12Months | currencyLocale: null:null:'1.2-2'"
  ></vpfa-label-value>
</div>
