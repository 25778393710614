import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable()
export class ChunkErrorHandler implements ErrorHandler {
  static chunkReloadKey = 'chunk-error-reload-count';
  // TODO: different error?
  readonly chunkFailedMessage = 'ChunkLoadError: Loading chunk';
  readonly maxReloadCount = 3;

  handleError(error: any): void {
    const reloadCount = Number(window.sessionStorage.getItem(ChunkErrorHandler.chunkReloadKey)) ?? 0;
    const errorMessage = error?.message as string;

    // Handle chunk loading error
    if (errorMessage?.includes('ChunkLoadError: Loading chunk')) {
      // Reloads are limited to prevent infinite reloading of the page when API is used from QA and
      // maintenance mode is enabled in there.
      if (environment.production === false && reloadCount > this.maxReloadCount) {
        console.error('ChunkErrorHandler: Max reload count reached');
        return;
      }

      // When chunk is missing and browser is onLine, we most probably hit HTTP 302 redirect,
      // and application is under deployment or maintenance. In this case, page is reloaded
      // so user will be redirected to specified address in HTTP 302 response.
      if (navigator.onLine && errorMessage.includes('missing')) {
        if (environment.production === false) {
          window.sessionStorage.setItem(ChunkErrorHandler.chunkReloadKey, (reloadCount + 1).toString());
        }

        window.location.reload();
        return;
      }
    }

    console.error(error);
  }
}
