<div *ngIf="tableName">
  <vpfa-mobile-page-title [title]="tableName"></vpfa-mobile-page-title>
</div>

<vpfa-mobile-infinite-list
  [elementTemplate]="listElementTemplate"
  [list]="listItems"
  [endElement]="19"
  [scrollStep]="10"
></vpfa-mobile-infinite-list>
<ng-template #listElementTemplate let-element>
  <vpfa-mobile-vehicle-list-cell
    [showPower]="showPower"
    [isFormInvalid]="isFormInvalid"
    [loading]="isLoadingVehicle"
    [vehicle]="element"
    [disableSpecialButton]="disableSpecialButton"
    [loadingTechSpec]="isLoadingTechSpec"
    [prodStart]="element.technicalData?.prodbegin | dateLocale: 'shortDate':null:null:true"
    [prodEnd]="element.technicalData?.prodend | dateLocale: 'shortDate':null:null:true"
    (openDetails)="onRowClick(element)"
    (openTechnicalSpec)="onActionClicked(element)"
  ></vpfa-mobile-vehicle-list-cell>

  <nz-divider></nz-divider>
</ng-template>
