/**
 * Dealer Network AdminService API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { AmsPermissions } from '../model/amsPermissions';
import { UserContext } from '../model/userContext';
import { VPError } from '../model/vPError';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserContextService {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Returns ams permissons for logged user, if user got access to more resources he can force  to be recognized as specific coutryId/businessId/branchId by passing values to parameters
     * 
     * @param countryId 
     * @param businessId 
     * @param branchId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAmsPermission(countryId?: string, businessId?: string, branchId?: string, observe?: 'body', reportProgress?: boolean): Observable<AmsPermissions>;
    public getAmsPermission(countryId?: string, businessId?: string, branchId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AmsPermissions>>;
    public getAmsPermission(countryId?: string, businessId?: string, branchId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AmsPermissions>>;
    public getAmsPermission(countryId?: string, businessId?: string, branchId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (countryId !== undefined && countryId !== null) {
          queryParameters = queryParameters.set('countryId', <any>countryId);
        }
        if (businessId !== undefined && businessId !== null) {
          queryParameters = queryParameters.set('businessId', <any>businessId);
        }
        if (branchId !== undefined && branchId !== null) {
          queryParameters = queryParameters.set('branchId', <any>branchId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<AmsPermissions>(`${this.basePath}/api/UserContext/permissions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserContext(observe?: 'body', reportProgress?: boolean): Observable<UserContext>;
    public getUserContext(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserContext>>;
    public getUserContext(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserContext>>;
    public getUserContext(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<UserContext>(`${this.basePath}/api/UserContext`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
