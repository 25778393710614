import { EquipmentFormulaBlockDto } from '@vpfa/rest-api/valuation';
import { VehicleEquipmentFormula } from '../../../models/vehicle-equipment-formula';
import { UpdateFormulaStructureProps } from '../formula-wizard.actions';
import { FormulaWizardState } from '../formula-wizard.reducer';
import { getCurrentFormula } from './get-current-formula';
import { mapApiStepToFormulaWizardStep } from './map-api-step-to-formula-wizard-step';

/**
 * Function to:
 *
 *   - remove not yet resolved formulas and steps
 *   - add new formulas and steps from action
 */
export function updateFormulas(
  state: Pick<FormulaWizardState, 'formulaIndex' | 'formulas'>,
  action: Pick<UpdateFormulaStructureProps, 'incompleteFormulas'>
): VehicleEquipmentFormula[] | undefined {
  const baseCurrentFormula = getCurrentFormula(state);
  const formulasAfterUpdate = [];

  state.formulas.forEach((formula, formulaIndex) => {
    // leave previous and current _formulas_ unchanged
    if (formulaIndex < state.formulaIndex) {
      formulasAfterUpdate.push(formula);
      return;
    }

    if (formulaIndex === state.formulaIndex) {
      const completedSteps = [];

      // leave the previous and current _steps_ unchanged (current step is updated in reducer for formulaWizardActions.nextStep)
      formula.steps.forEach((step, stepIndex) => {
        if (stepIndex <= formula.currentStepIndex) {
          completedSteps.push(step);
        }
      });

      const currentFormulaFromAction = action.incompleteFormulas.find(
        newFormula => newFormula.mainEquipmentSoaCode === formula.mainEquipmentSoaCode
      );

      const newStepsForCurrentFormula = currentFormulaFromAction?.steps?.map(mapApiStepToFormulaWizardStep) ?? [];

      // update formula with new data
      formulasAfterUpdate.push({
        ...formula,
        steps: [...completedSteps, ...newStepsForCurrentFormula],
      });
    }
  });

  const newFormulasFromAction = action.incompleteFormulas
    // skip current formula by SOA Code
    .filter(newFormula => newFormula.mainEquipmentSoaCode !== baseCurrentFormula.mainEquipmentSoaCode)
    // convert API step to Formula Wizard Step
    .map(newFormulaPart => mapApiFormulaToWizardFormula(newFormulaPart))
    // set source data to be able to return to this step and index when user uses "previous" button
    .map(formula => {
      formula.source.formulaIndex = state.formulaIndex;
      formula.source.stepIndex = baseCurrentFormula.currentStepIndex;

      return formula;
    });

  const newFormulas = [...formulasAfterUpdate, ...newFormulasFromAction];

  return newFormulas;
}

function mapApiFormulaToWizardFormula(newFormulaPart: {
  mainEquipmentSoaCode: string;
  steps: EquipmentFormulaBlockDto[];
}): VehicleEquipmentFormula {
  return {
    mainEquipmentSoaCode: newFormulaPart.mainEquipmentSoaCode,
    steps: newFormulaPart.steps.map(mapApiStepToFormulaWizardStep),
    currentStepIndex: 0,
    source: {
      formulaIndex: 0,
      stepIndex: 0,
    },
  };
}
