import { AddCaseImagesCommand } from '@vpfa/dealer/gallery-manager/data';
import { produce } from 'immer';
import { ConfirmAdvertisedImagesAction, ConfirmAdvertisedImagesActionTypes } from './confirm-advertised-images.actions';

export const CONFIRM_ADVERTISED_IMAGES_FEATURE_KEY = 'confirmAdvertisedImages';

export interface ConfirmAdvertisedImagesState {
  modal: {
    show: boolean;
    data: AddCaseImagesCommand;
  };
  advertisementQueue: {
    caseId: string;
    images: {
      fileId: string;
      isSelectedForAdvertisement: boolean;
    }[];
  };
}

export interface ConfirmAdvertisedImagesPartialState {
  readonly [CONFIRM_ADVERTISED_IMAGES_FEATURE_KEY]: ConfirmAdvertisedImagesState;
}

export const initialState: ConfirmAdvertisedImagesState = {
  modal: { show: false, data: null },
  advertisementQueue: {
    caseId: null,
    images: [],
  },
};

export function reducer(
  state: ConfirmAdvertisedImagesState = initialState,
  action: ConfirmAdvertisedImagesAction
): ConfirmAdvertisedImagesState {
  switch (action.type) {
    case ConfirmAdvertisedImagesActionTypes.ShowConfirmationModal:
      return produce(state, newState => {
        newState.modal.show = true;
        newState.modal.data = action.payload;
      });
    case ConfirmAdvertisedImagesActionTypes.UploadImagesAfterConfirmation:
      return produce(state, newState => {
        newState.modal.show = false;
      });
    case ConfirmAdvertisedImagesActionTypes.AddToAdvertiseUpdateQueue:
      return produce(state, newState => {
        if (state.advertisementQueue.caseId !== null && state.advertisementQueue.caseId !== action.payload.caseId) {
          throw new Error(
            `Invalid Case ID in advertisementQueue. Current: ${state.advertisementQueue.caseId}, expected: ${action.payload.caseId}`
          );
        }
        newState.advertisementQueue.caseId = action.payload.caseId;
        newState.advertisementQueue.images.push({
          fileId: action.payload.fileId,
          isSelectedForAdvertisement: action.payload.isSelectedForAdvertisement,
        });
      });
    case ConfirmAdvertisedImagesActionTypes.ClearAdvertiseUpdateQueue:
    case ConfirmAdvertisedImagesActionTypes.AdvertiseUpdateError:
    case ConfirmAdvertisedImagesActionTypes.CloseConfirmationModal:
      return produce(state, newState => {
        newState.modal.show = false;
        newState.modal.data = null;
        newState.advertisementQueue.caseId = null;
        newState.advertisementQueue.images = [];
      });
  }
  return state;
}
