import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { PriceWithIconComponent } from './components/price-with-icon/price-with-icon.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { UiKitSuffixPrefixValueModule } from '@vpfa/ui-kit/suffix-prefix-value';
import { UiKitModule } from '@vpfa/ui-kit';
import { TranslateModule } from '@ngx-translate/core';
import { SharedPipesModule } from '@vpfa/shared/pipes';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    NzIconModule,
    ReactiveFormsModule,
    UiKitSuffixPrefixValueModule,
    UiKitModule,
    TranslateModule,
    SharedPipesModule,
  ],
  declarations: [PriceWithIconComponent],
  exports: [PriceWithIconComponent],
})
export class UiKitPriceWithIconModule {}
