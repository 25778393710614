<div class="list-container">
  <div
    *ngFor="let row of history; let i = index"
    [fxLayout]="'row'"
    [fxLayoutAlign]="'start center'"
    class="history-row"
  >
    <div class="row-desc" [fxFlex]="'40%'">
      <!-- TODO: id -->
      <div
        id="{{ 'name' + i }}"
        class="user"
        (mouseenter)="isEllipsisActive(i)"
        nz-tooltip
        [nzTooltipOverlayClassName]="'tooltip'"
        [nzTooltipTitle]="ellipsisActive[i] ? returnName(i) : null"
        [nzTooltipTrigger]="'hover'"
        [nzTooltipPlacement]="'top'"
      >
        <span *ngIf="hasFirstNameOrLastName(i)">{{ row?.firstName }}&nbsp;{{ row?.lastName }}</span>
        <span *ngIf="!hasFirstNameOrLastName(i)">{{ row?.userName }}</span>
      </div>
    </div>
    <div [fxFlex]="'25%'" [fxLayoutAlign]="'center'">
      <span class="date">{{ row.createdAt | dateLocale: 'short' }}</span>
    </div>
    <div class="row-price" fxFlex fxLayout="column">
      <span>{{ row.price | currencyLocale: null:null:'1.2-2' | lT }}</span>
      <vpfa-price-tax
        [caseTax]="caseTax"
        [tax]="row.priceTaxAmount | currencyLocale: null:null:'1.0'"
        [taxValue]="row.taxValue"
      ></vpfa-price-tax>
    </div>
  </div>
</div>
