import { Component, ComponentRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { VehicleEquipmentActionTypes, VehicleEquipmentUpdateIsEnabledError } from '@vpfa/dealer/vehicle-equipment/data';
import { CheckboxComponent } from '@vpfa/ui-kit/checkbox-button';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'vpfa-package-content-item',
  templateUrl: './package-content-item.component.html',
  styleUrls: ['./package-content-item.component.scss'],
})
export class PackageContentItemComponent implements OnDestroy, OnInit {
  @ViewChild('checkbox', { static: false }) checkbox: CheckboxComponent;

  @Input() description: string;
  @Input() price: number;
  @Input() isCombinationEquipment: boolean;
  @Input() isDisabled = false;
  @Input() checkboxState = false;

  @Output() onCheckboxChanged = new EventEmitter<boolean>();

  private _onDestroy$ = new Subject<void>();

  constructor(private actions$: Actions) {}

  ngOnInit(): void {
    this.restoreCheckboxToInitialAfterError();
  }

  onChange(value: boolean) {
    this.onCheckboxChanged.emit(value);
  }

  private restoreCheckboxToInitialAfterError() {
    this.actions$
      .pipe(
        ofType<VehicleEquipmentUpdateIsEnabledError>(VehicleEquipmentActionTypes.VehicleEquipmentUpdateIsEnabledError),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => {
        if (!this.isDisabled) {
          this.checkbox.restoreStateToInitial();
        }
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
