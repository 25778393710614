import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AfterCustomerCreatedActionSuccessHook, CustomersFacade } from '@vpfa/dealer/customers/data';
import { CreateCustomerCommand } from '@vpfa/rest-api/valuation';
import { CustomerFormModel } from '../../models/customer-form.model';
import { ProfileFacade } from '@vpfa/profile/data';
import { UserContext } from '@vpfa/rest-api/admin';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UsersFacade } from '@vpfa/admin/users-data';
import { isNil } from 'lodash';

@Component({ template: '' })
export abstract class CustomerCreateComponent implements OnInit, OnDestroy {
  @Input() modalTitleCode: string = 'customerCreate.createCustomer';
  @Input() afterCustomerCreateSuccessHook: Readonly<AfterCustomerCreatedActionSuccessHook<undefined>>;

  userId$ = this.profileFacade.userId$;
  customerFormsOfAddress$ = this.customersFacade.customerFormsOfAddress$;
  customerFormsOfAddressLoading$ = this.customersFacade.customerFormsOfAddressLoading$;
  allUsers$ = this.usersFacade.allUsers$;
  allUsersLoading$ = this.usersFacade.usersLoading$;
  createCustomerModalProcessing$ = this.customersFacade.createCustomerModalProcessing$;

  protected _onDestroy$ = new Subject<void>();

  protected constructor(
    protected customersFacade: CustomersFacade,
    private profileFacade: ProfileFacade,
    private usersFacade: UsersFacade
  ) {}

  ngOnInit() {
    this.profileFacade.userContext$
      .pipe(
        filter(value => !isNil(value)),
        takeUntil(this._onDestroy$)
      )
      .subscribe((value: UserContext) => {
        this.usersFacade.loadUsersByUsersBusiness();
        this.customersFacade.loadFormsOfAddress(value?.countryCode);
      });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  createCustomer(customer: CustomerFormModel) {
    const command = CustomerCreateComponent.convertFormToCommand(customer);
    this.customersFacade.createCustomer(command, {
      afterSuccess: this.afterCustomerCreateSuccessHook,
    });
  }

  private static convertFormToCommand(customer: CustomerFormModel): CreateCustomerCommand {
    return {
      ...customer,
      vatRegistered: Boolean(customer.vatRegistered),
    };
  }
}
