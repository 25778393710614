import { Component, Input } from '@angular/core';
import { SelectOption } from '@vpfa/ui-kit';
import { UntypedFormGroup } from '@angular/forms';
import { isNil } from 'lodash';

@Component({
  selector: 'vpfa-unique-data-valuation-select',
  templateUrl: './unique-data-valuation-select.component.html',
  styleUrls: ['./unique-data-valuation-select.component.scss']
})
export class UniqueDataValuationSelectComponent {

  @Input() label: string; 
  @Input() options: SelectOption[];
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() fcName: string;
  @Input() allowClear = false;

  get readOnlyValue() {
    const selectedValue = this.parentFormGroup.get(this.fcName).value?.value;
    const selectedOption = this.options.find(opt => opt.value === selectedValue);
    return !isNil(selectedOption)? selectedOption.name : null;
  }

}
