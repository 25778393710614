import { Directive } from '@angular/core';
import { ShowHideDirective } from '@ngbracket/ngx-layout';

const selector = `[fxHide.gt-mv], [fxHide.lt-mv], [fxShow.gt-mv], [fxShow.lt-mv]`;
const inputs = ['fxHide.gt-mv', 'fxHide.lt-mv', 'fxShow.gt-mv', 'fxShow.lt-mv'];

/**
 * @deprecated to be removed because FlexLayout is obsolete - see migration-from-flex-layout.md for details
 */
@Directive({ selector, inputs })
export class MobileShowHideDirective extends ShowHideDirective {
  protected inputs = inputs;
}
