import { Injectable } from '@angular/core';
import { readVpErrorData } from '@vpfa/utils';
import { NotificationsFacade } from '../+state/notifications.facade';
import { NotificationType } from '../models/notification-type.enum';

@Injectable()
export class BasicNotificationsService {
  constructor(private facade: NotificationsFacade) {}

  success(title: string, content?: string, interpolateParams?: Object): void {
    this.facade.addBasic(title, content, interpolateParams, NotificationType.Success, true);
  }

  error(title: string, content?: string, interpolateParams?: Object, errorId?: string): void {
    this.facade.addBasic(title, content, interpolateParams, NotificationType.Error, true, errorId);
  }

  vpError(input: any): void {
    const { id, codeName } = readVpErrorData(input);

    this.facade.addBasic(`vpError.${codeName}`, null, null, NotificationType.Error, true, id);
  }

  warning(title: string, content?: string, interpolateParams?: Object): void {
    this.facade.addBasic(title, content, interpolateParams, NotificationType.Warning, true);
  }

  mistake(title: string, content?: string, interpolateParams?: Object): void {
    this.facade.addBasic(title, content, interpolateParams, NotificationType.Mistake, true);
  }
}
