export type SorcImageSizes = 'thumbnail' | 'small' | 'medium';

export enum SorcImageView {
  FrontLeft = 540001,
  FrontRight,
  RearLeft,
  RearRight,
  TopViewRear = 540010,
  Interior,
  DashBoard,
  CargoSpace,
  DriveSuspension = 540018,
  XRayView = 540020,
  SectionalView = 540021,
  Front = 540005,
  Rear,
  LateralLeft,
  LateralRight,
  TopViewFront,
  DetailOptionsInterior = 540014,
  DetailOptionsExterior,
  Engine,
  Transmission,
  Miscellaneous = 540023,
  NoLink = 549999
}

export const sorcImages: Array<SorcImageView> = Array.from([SorcImageView.FrontLeft]);
