import { Component } from '@angular/core';
import { FilterChangedEvent, IFloatingFilter, IFloatingFilterParams } from 'ag-grid-community';
import { AgFrameworkComponent } from 'ag-grid-angular';
import { isNil } from 'lodash';

export function dateRangeFilterComparator(filter: string, gridValue: any, filterText: string) {
  const datesRange = handleDatesRange(filterText);
  const cellDate = !isNil(gridValue) ? new Date(gridValue) : null;
  if (!isNil(cellDate)) {
    cellDate.setHours(0, 0, 0, 0);
  }

  return !isNil(cellDate) && !isNil(datesRange) && cellDate <= datesRange[1] && cellDate >= datesRange[0];
}

export function handleDatesRange(filterText: string): [Date, Date] {
  const textDates: string[] = filterText?.split(',');

  if (textDates?.length === 2 && !isNil(textDates[0]) && !isNil(textDates[1])) {
    const dateFrom = new Date(textDates[0]);
    dateFrom.setHours(0, 0, 0, 0);

    const dateTo = new Date(textDates[1]);
    dateTo.setHours(0, 0, 0, 0);

    return [dateFrom, dateTo];
  }
  return null;
}

@Component({
  selector: 'vpfa-date-range-floating-filter-old',
  templateUrl: './date-range-floating-filter-old.component.html',
  styleUrls: ['./date-range-floating-filter-old.component.scss'],
})
export class DateRangeFloatingFilterOldComponent implements IFloatingFilter, AgFrameworkComponent<IFloatingFilterParams> {
  params: IFloatingFilterParams;
  datesRange: Date[];

  agInit(params: IFloatingFilterParams): void {
    this.params = params;
  }

  onParentModelChanged(parentModel: any, filterChangedEvent?: FilterChangedEvent): void {
    if (!parentModel) {
      this.datesRange = null;
    } else {
      this.datesRange = handleDatesRange(parentModel.filter);
    }
  }

  onRangeSelect(dates: Date[]) {
    // dates to iso
    
    if (dates?.length === 2) {
      this.params.parentFilterInstance((instance: any) => {
        instance.onFloatingFilterChanged('other', [dates[0].toISOString(), dates[1].toISOString()]);
      });
    } else {
      this.params.parentFilterInstance((instance: any) => {
        instance.onFloatingFilterChanged(null, null);
      });
      return;
    }
  }
}