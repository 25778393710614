import { map } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';

export const externalValidatorKey = 'external';

export function externalValidator<T>(
  checkFunction: (value: T) => Observable<boolean>,
  callback?: (result: boolean) => void
) {
  return (control: AbstractControl) => {
    return checkFunction(control.value).pipe(
      map(result => {
        if (callback) {
          callback(result);
        }

        if (result) {
          return null;
        }
        
        return {
          [externalValidatorKey]: true,
        };
      })
    );
  };
}
