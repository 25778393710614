import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'vpfa-unique-data-valuation-switch',
  templateUrl: './unique-data-valuation-switch.component.html',
  styleUrls: ['./unique-data-valuation-switch.component.scss'],
})
export class UniqueDataValuationSwitchComponent {
  @Input() formGroup: UntypedFormGroup;
  @Input() fcName: string;
  @Input() label: string;
}
