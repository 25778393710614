<div
  nz-tooltip
  [nzTooltipOverlayClassName]="'tooltip'"
  [nzTooltipTitle]="tooltip"
  [nzTooltipTrigger]="'hover'"
  [nzTooltipPlacement]="'top'"
>
  <button
    class="reset-button"
    nz-button
    [disabled]="disabled"
    type="button"
    nzType="primary"
    (click)="reset.emit()"
  >
    <i nz-icon nzType="undo"></i>
  </button>
</div>
