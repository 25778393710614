import { Component, Input } from '@angular/core';
import { Column, GridApi } from 'ag-grid-community';
import { ActionsColumnName, CheckboxColumnName, SpecificationColumnName } from '../data-table/data-table.component';

@Component({
  selector: 'vpfa-data-table-settings-columns',
  templateUrl: './data-table-settings-columns.component.html',
  styleUrls: ['./data-table-settings-columns.component.scss'],
})
export class DataTableSettingsColumnsComponent {
  private _gridApi: GridApi;

  @Input()
  set gridApi(gridApi: GridApi) {
    this._gridApi = gridApi;
  }

  get gridApi(): GridApi {
    return this._gridApi;
  }

  get isLastActive() {
    return this.columns.filter(c => c.isVisible()).length <= 1;
  }

  get columns(): Column[] {
    if (!this._gridApi?.getAllGridColumns()) {
      return [];
    }
    return this._gridApi
      .getAllGridColumns()
      .filter(col => ![CheckboxColumnName, ActionsColumnName, SpecificationColumnName].includes(col.getColId()));
  }

  onColumnVisibleChange(colId: string, visibleNewState: boolean) {
    this._gridApi.setColumnsVisible([colId], visibleNewState);
  }
}
