/**
 * Public SSM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0-private
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { BusinessAdjustmentsCreateNumberOfOwnersBody } from '../model/businessAdjustmentsCreateNumberOfOwnersBody';
import { BusinessAdjustmentsCreateNumberOfOwnersBody1 } from '../model/businessAdjustmentsCreateNumberOfOwnersBody1';
import { BusinessAdjustmentsCreateNumberOfOwnersBody2 } from '../model/businessAdjustmentsCreateNumberOfOwnersBody2';
import { BusinessAdjustmentsCreateNumberOfOwnersBody3 } from '../model/businessAdjustmentsCreateNumberOfOwnersBody3';
import { BusinessAdjustmentsCreateSpecialUsageBody } from '../model/businessAdjustmentsCreateSpecialUsageBody';
import { BusinessAdjustmentsCreateSpecialUsageBody1 } from '../model/businessAdjustmentsCreateSpecialUsageBody1';
import { BusinessAdjustmentsCreateSpecialUsageBody2 } from '../model/businessAdjustmentsCreateSpecialUsageBody2';
import { BusinessAdjustmentsCreateSpecialUsageBody3 } from '../model/businessAdjustmentsCreateSpecialUsageBody3';
import { BusinessAdjustmentsUpdateDefaultMarketSituationCorrectionBody } from '../model/businessAdjustmentsUpdateDefaultMarketSituationCorrectionBody';
import { BusinessAdjustmentsUpdateDefaultMarketSituationCorrectionBody1 } from '../model/businessAdjustmentsUpdateDefaultMarketSituationCorrectionBody1';
import { BusinessAdjustmentsUpdateDefaultMarketSituationCorrectionBody2 } from '../model/businessAdjustmentsUpdateDefaultMarketSituationCorrectionBody2';
import { BusinessAdjustmentsUpdateDefaultMarketSituationCorrectionBody3 } from '../model/businessAdjustmentsUpdateDefaultMarketSituationCorrectionBody3';
import { BusinessAdjustmentsUpdateNumberOfOwnersBody } from '../model/businessAdjustmentsUpdateNumberOfOwnersBody';
import { BusinessAdjustmentsUpdateNumberOfOwnersBody1 } from '../model/businessAdjustmentsUpdateNumberOfOwnersBody1';
import { BusinessAdjustmentsUpdateNumberOfOwnersBody2 } from '../model/businessAdjustmentsUpdateNumberOfOwnersBody2';
import { BusinessAdjustmentsUpdateNumberOfOwnersBody3 } from '../model/businessAdjustmentsUpdateNumberOfOwnersBody3';
import { BusinessAdjustmentsUpdateSpecialColorBody } from '../model/businessAdjustmentsUpdateSpecialColorBody';
import { BusinessAdjustmentsUpdateSpecialColorBody1 } from '../model/businessAdjustmentsUpdateSpecialColorBody1';
import { BusinessAdjustmentsUpdateSpecialColorBody2 } from '../model/businessAdjustmentsUpdateSpecialColorBody2';
import { BusinessAdjustmentsUpdateSpecialColorBody3 } from '../model/businessAdjustmentsUpdateSpecialColorBody3';
import { BusinessAdjustmentsUpdateSpecialUsageBody } from '../model/businessAdjustmentsUpdateSpecialUsageBody';
import { BusinessAdjustmentsUpdateSpecialUsageBody1 } from '../model/businessAdjustmentsUpdateSpecialUsageBody1';
import { BusinessAdjustmentsUpdateSpecialUsageBody2 } from '../model/businessAdjustmentsUpdateSpecialUsageBody2';
import { BusinessAdjustmentsUpdateSpecialUsageBody3 } from '../model/businessAdjustmentsUpdateSpecialUsageBody3';
import { BusinessAdjustmentsUpdateTechnicalInspectionBody } from '../model/businessAdjustmentsUpdateTechnicalInspectionBody';
import { BusinessAdjustmentsUpdateTechnicalInspectionBody1 } from '../model/businessAdjustmentsUpdateTechnicalInspectionBody1';
import { BusinessAdjustmentsUpdateTechnicalInspectionBody2 } from '../model/businessAdjustmentsUpdateTechnicalInspectionBody2';
import { BusinessAdjustmentsUpdateTechnicalInspectionBody3 } from '../model/businessAdjustmentsUpdateTechnicalInspectionBody3';
import { CommandHandlerResponse } from '../model/commandHandlerResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class BusinessAdjustmentsService {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Lazy check AmsAction:POST/api/BusinessAdjustments/CreateNumberOfOwners AmsResource:{country}/{business}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createNumberOfOwnersSpecification(body?: BusinessAdjustmentsCreateNumberOfOwnersBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public createNumberOfOwnersSpecification(body?: BusinessAdjustmentsCreateNumberOfOwnersBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public createNumberOfOwnersSpecification(body?: BusinessAdjustmentsCreateNumberOfOwnersBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public createNumberOfOwnersSpecification(body?: BusinessAdjustmentsCreateNumberOfOwnersBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommandHandlerResponse>(`${this.basePath}/api/BusinessAdjustments/CreateNumberOfOwners`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSpecialUsageSpecification(body?: BusinessAdjustmentsCreateSpecialUsageBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public createSpecialUsageSpecification(body?: BusinessAdjustmentsCreateSpecialUsageBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public createSpecialUsageSpecification(body?: BusinessAdjustmentsCreateSpecialUsageBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public createSpecialUsageSpecification(body?: BusinessAdjustmentsCreateSpecialUsageBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommandHandlerResponse>(`${this.basePath}/api/BusinessAdjustments/CreateSpecialUsage`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/BusinessAdjustments/UpdateDefaultMarketSituationCorrection AmsResource:{country}/{business}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDefaultMarketSituation(body?: BusinessAdjustmentsUpdateDefaultMarketSituationCorrectionBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateDefaultMarketSituation(body?: BusinessAdjustmentsUpdateDefaultMarketSituationCorrectionBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateDefaultMarketSituation(body?: BusinessAdjustmentsUpdateDefaultMarketSituationCorrectionBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateDefaultMarketSituation(body?: BusinessAdjustmentsUpdateDefaultMarketSituationCorrectionBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/BusinessAdjustments/UpdateDefaultMarketSituationCorrection`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/BusinessAdjustments/UpdateNumberOfOwners AmsResource:{country}/{business}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateNumberOfOwnersSpecification(body?: BusinessAdjustmentsUpdateNumberOfOwnersBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateNumberOfOwnersSpecification(body?: BusinessAdjustmentsUpdateNumberOfOwnersBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateNumberOfOwnersSpecification(body?: BusinessAdjustmentsUpdateNumberOfOwnersBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateNumberOfOwnersSpecification(body?: BusinessAdjustmentsUpdateNumberOfOwnersBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/BusinessAdjustments/UpdateNumberOfOwners`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/BusinessAdjustments/UpdateSpecialColor AmsResource:{country}/{business}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSpecialColorSpecification(body?: BusinessAdjustmentsUpdateSpecialColorBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateSpecialColorSpecification(body?: BusinessAdjustmentsUpdateSpecialColorBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateSpecialColorSpecification(body?: BusinessAdjustmentsUpdateSpecialColorBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateSpecialColorSpecification(body?: BusinessAdjustmentsUpdateSpecialColorBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/BusinessAdjustments/UpdateSpecialColor`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/BusinessAdjustments/UpdateSpecialUsage AmsResource:{country}/{business}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSpecialUsageSpecification(body?: BusinessAdjustmentsUpdateSpecialUsageBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateSpecialUsageSpecification(body?: BusinessAdjustmentsUpdateSpecialUsageBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateSpecialUsageSpecification(body?: BusinessAdjustmentsUpdateSpecialUsageBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateSpecialUsageSpecification(body?: BusinessAdjustmentsUpdateSpecialUsageBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/BusinessAdjustments/UpdateSpecialUsage`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/BusinessAdjustments/UpdateTechnicalInspection AmsResource:{country}/{business}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTechnicalInspectionSpecification(body?: BusinessAdjustmentsUpdateTechnicalInspectionBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateTechnicalInspectionSpecification(body?: BusinessAdjustmentsUpdateTechnicalInspectionBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateTechnicalInspectionSpecification(body?: BusinessAdjustmentsUpdateTechnicalInspectionBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateTechnicalInspectionSpecification(body?: BusinessAdjustmentsUpdateTechnicalInspectionBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/BusinessAdjustments/UpdateTechnicalInspection`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
