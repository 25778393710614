import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './components/list/list.component';
import { NzListModule } from 'ng-zorro-antd/list';
import { UtilsModule } from '@vpfa/utils';
import { SharedMobileViewModule } from '@vpfa/shared/mobile-view';

@NgModule({
  imports: [CommonModule, NzListModule, UtilsModule, SharedMobileViewModule],
  declarations: [ListComponent],
  exports: [ListComponent],
})
export class UiKitListModule {}
