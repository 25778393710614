<vpfa-customer-data-web
  *vpfaResponsiveIf="'web'"
  modalTitleCode="customerUpdate.editCustomer"
  [show]="show"
  [isProcessing]="isProcessing"
  [formsOfAddress]="customerFormsOfAddress$ | async"
  [isFormsOfAddressLoading]="customerFormsOfAddressLoading$ | async"
  [customerData]="customerFormModel"
  [owners]="allUsers$ | async"
  [isOwnersLoading]="allUsersLoading$ | async"
  (closeAction)="closeModal()"
  (customerDataChange)="updateCustomer($event)"
>
</vpfa-customer-data-web>
<vpfa-customer-data-mobile
  *vpfaResponsiveIf="'mobile'"
  modalTitleCode="customerUpdate.editCustomer"
  [show]="show"
  [isProcessing]="isProcessing"
  [formsOfAddress]="customerFormsOfAddress$ | async"
  [isFormsOfAddressLoading]="customerFormsOfAddressLoading$ | async"
  [customerData]="customerFormModel"
  [owners]="allUsers$ | async"
  [isOwnersLoading]="allUsersLoading$ | async"
  (closeAction)="closeModal()"
  (customerDataChange)="updateCustomer($event)"
>
</vpfa-customer-data-mobile>
