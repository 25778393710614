import { DataTableAction, DataTableActionListItem } from '@vpfa/ui-kit/data-table';

export const countryTemplatesActionGetter = (): DataTableActionListItem[] => {
  return sharedActions;
};

export const countryTemplatesSystemAdminActionGetter = (): DataTableActionListItem[] => {
  return [...sharedActions, ...systemAdminActions];
};

const sharedActions: DataTableActionListItem[] = [
  {
    name: 'common.edit',
    action: DataTableAction.EDIT,
    order: 1,
  },
  {
    name: 'common.rename',
    action: DataTableAction.RENAME,
    order: 2,
  },
  {
    name: 'admin.printTemplates.list.modules',
    action: DataTableAction.MODULES,
    order: 3,
  },
  {
    name: 'admin.printTemplates.list.duplicate',
    action: DataTableAction.DUPLICATE,
    order: 4,
  },
  {
    name: 'common.delete',
    action: DataTableAction.DELETE,
    question: 'common.deleteQuestion',
    order: 6,
  },
];

const systemAdminActions: DataTableActionListItem[] = [
  {
    name: 'admin.printTemplates.modal.copyTo',
    action: DataTableAction.COPY_TO,
    order: 5,
  },
];
