import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { SafeUrlPipe } from './safe-url/safe-url.pipe';
import { DateLocalePipe } from './date-locale/date-locale.pipe';
import { CurrencyLocalePipe, NumberLocalePipe } from './number-locale/number-locale.pipe';
import { LocaleDateFormatPipe } from './locale-date-format/locale-date-format.pipe';
import { DataSizeUnitPipe } from './data-size-unit/data-size-unit.pipe';
import { UnitValuePipe } from './unit-value/unit-value.pipe';
import { OppositeValuePipe } from './opposite-value/opposite-value.pipe';
import { TaxTypeLocalePipe } from './tax-type-locale/tax-type-locale.pipe';
import { EnumTranslationPipe } from './enum-translation/enum-translation.pipe';
import { FindVehicleTypeByCodePipe } from './find-vehicle-type-by-code/find-vehicle-type-by-code.pipe';
import { DecimalNumberLocalePipe } from './decimal-number-locale/decimal-number-locale.pipe';
import { LTPipe } from './lt/lt.pipe';
import { IsEmptyPipe } from './is-empty/is-empty.pipe';
import { PercentValuePipe } from './percent-value/percent-value.pipe';
import { GetTwoValuePipe } from './get-two-value/get-two-value.pipe';
import { JoinArrayOfStringsPipe } from './join-array-of-strings/join-array-of-strings.pipe';
import { UnitRangeValuePipe } from './number-range-value/number-range-value.pipe';
import { DateRangePipe } from './date-range/date-range.pipe';
import { I18nTextsPipe } from './i18n-texts/i18n-texts-range.pipe';

@NgModule({
  imports: [CommonModule],
  providers: [DecimalPipe, NumberLocalePipe, DecimalNumberLocalePipe, DateLocalePipe],
  declarations: [
    SafeUrlPipe,
    DateLocalePipe,
    NumberLocalePipe,
    CurrencyLocalePipe,
    LocaleDateFormatPipe,
    DataSizeUnitPipe,
    UnitValuePipe,
    OppositeValuePipe,
    TaxTypeLocalePipe,
    EnumTranslationPipe,
    FindVehicleTypeByCodePipe,
    DecimalNumberLocalePipe,
    LTPipe,
    IsEmptyPipe,
    UnitRangeValuePipe,
    PercentValuePipe,
    GetTwoValuePipe,
    JoinArrayOfStringsPipe,
    DateRangePipe,
    I18nTextsPipe,
  ],
  exports: [
    SafeUrlPipe,
    DateLocalePipe,
    NumberLocalePipe,
    CurrencyLocalePipe,
    LocaleDateFormatPipe,
    DataSizeUnitPipe,
    UnitValuePipe,
    OppositeValuePipe,
    TaxTypeLocalePipe,
    EnumTranslationPipe,
    FindVehicleTypeByCodePipe,
    DecimalNumberLocalePipe,
    LTPipe,
    IsEmptyPipe,
    UnitRangeValuePipe,
    PercentValuePipe,
    GetTwoValuePipe,
    JoinArrayOfStringsPipe,
    DateRangePipe,
    I18nTextsPipe,
  ],
})
export class SharedPipesModule {}
