<div (click)="$event.stopImmediatePropagation()" *ngIf="listItems && listItems.length">
  <div>
    <button [disabled]="!params.data.responseContent" nz-button nzType="link" (click)="emitAction()">
      {{
        (params.data.responseContent
          ? 'broadcast.broadcastHistoryList.previewResponse'
          : 'broadcast.broadcastHistoryList.noPreviewAvailable'
        ) | translate
      }}
    </button>
  </div>
</div>
