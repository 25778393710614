import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { UiKitModule } from '@vpfa/ui-kit';
import { NzOutletModule } from 'ng-zorro-antd/core/outlet';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { SectionContainerComponent } from './components/section-container/section-container.component';
import { TranslateModule } from '@ngx-translate/core';
import { UiKitPriceWithIconModule } from '@vpfa/ui-kit/price-with-icon';
import { SharedLoadersModule } from '@vpfa/shared/loaders';

@NgModule({
  declarations: [SectionContainerComponent],
  imports: [
    CommonModule,
    UiKitModule,
    NzSpinModule,
    FlexLayoutModule,
    TranslateModule,
    UiKitPriceWithIconModule,
    SharedLoadersModule,
    NzOutletModule
  ],
  exports: [SectionContainerComponent],
})
export class UiKitSectionContainerModule {}
