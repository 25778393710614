import { BranchDto, BusinessDto, ProfileDto, UserContext } from '@vpfa/rest-api/admin';
import { produce } from 'immer';
import { ProfileActions, ProfileActionTypes } from './profile.actions';
import { DateRangeThresholdDto, NatcodePolicyDto } from '@vpfa/rest-api/valuation';

export const PROFILE_FEATURE_KEY = 'profile';

export interface State {
  userContext: UserContext;
  isLoading: boolean;
  userHasBranchAssigned: boolean;
  userBranchDetails: {
    loading: boolean;
    loaded: boolean;
    hasError: boolean;
    item: BranchDto;
  };
  userBusinessDetails: {
    loading: boolean;
    loaded: boolean;
    hasError: boolean;
    item: BusinessDto;
  };
  profile: ProfileDto;
  countryConfiguration: {
    loading: boolean;
    loaded: boolean;
    dateRangeThreshold: DateRangeThresholdDto;
    natcodePolicy: NatcodePolicyDto;
    vrmRegexPattern: string;
    kbaRegexPattern: string;
    matriculationNumberRegexPattern: string;
  };
}

export const initialState: State = {
  userContext: null,
  userHasBranchAssigned: false,
  isLoading: false,
  userBranchDetails: { item: null, loaded: false, loading: false, hasError: false },
  userBusinessDetails: { item: null, loaded: false, loading: false, hasError: false },
  profile: null,
  countryConfiguration: {
    loading: false,
    loaded: false,
    dateRangeThreshold: null,
    natcodePolicy: null,
    vrmRegexPattern: null,
    kbaRegexPattern: null,
    matriculationNumberRegexPattern: null,
  },
};

export function reducer(state = initialState, action: ProfileActions): State {
  switch (action.type) {
    case ProfileActionTypes.UserContextLoaded:
      return produce(state, newState => {
        newState.userContext = action.payload.context;
        newState.userHasBranchAssigned = action.payload.userHasBranchAssigned;
      });

    case ProfileActionTypes.LoadProfile:
      return produce(state, newState => {
        newState.isLoading = true;
      });

    case ProfileActionTypes.LoadProfileSuccess:
      return produce(state, newState => {
        newState.isLoading = false;
        newState.profile = action.payload;
      });
    case ProfileActionTypes.ResetProfile:
      return produce(state, newState => {
        Object.assign(newState, initialState);
      });

    case ProfileActionTypes.LoadUserBusinessDetails:
      return produce(state, newState => {
        newState.userBusinessDetails.loaded = false;
        newState.userBusinessDetails.loading = true;
        newState.userBusinessDetails.hasError = false;
      });

    case ProfileActionTypes.UserBusinessDetailsLoaded:
      return produce(state, newState => {
        newState.userBusinessDetails.loaded = true;
        newState.userBusinessDetails.loading = false;
        newState.userBusinessDetails.item = action.payload;
      });

    case ProfileActionTypes.UserBusinessDetailsLoadError:
      return produce(state, newState => {
        newState.userBusinessDetails.loaded = false;
        newState.userBusinessDetails.loading = false;
        newState.userBusinessDetails.hasError = false;
        newState.userBusinessDetails.item = null;
      });

    case ProfileActionTypes.LoadUserBranchDetails:
      return produce(state, newState => {
        newState.userBranchDetails.loaded = false;
        newState.userBranchDetails.loading = true;
        newState.userBranchDetails.hasError = false;
      });

    case ProfileActionTypes.UserBranchDetailsLoadError:
      return produce(state, newState => {
        newState.userBranchDetails.loaded = false;
        newState.userBranchDetails.loading = false;
        newState.userBranchDetails.hasError = false;
        newState.userBranchDetails.item = null;
      });

    case ProfileActionTypes.UserBranchDetailsLoaded:
      return produce(state, newState => {
        newState.userBranchDetails.loaded = true;
        newState.userBranchDetails.loading = false;
        newState.userBranchDetails.item = action.payload;
      });

    case ProfileActionTypes.LoadCountryConfiguration:
      return produce(state, newState => {
        newState.countryConfiguration.loading = true;
        newState.countryConfiguration.loaded = false;
      });

    case ProfileActionTypes.LoadCountryConfigurationSuccess:
      return produce(state, newState => {
        newState.countryConfiguration.loading = false;
        newState.countryConfiguration.loaded = true;
        newState.countryConfiguration.dateRangeThreshold = action.payload.dateRangeThreshold;
        newState.countryConfiguration.natcodePolicy = action.payload.natcodePolicy;
        newState.countryConfiguration.vrmRegexPattern = action.payload.vrmRegexPattern;
        newState.countryConfiguration.kbaRegexPattern = action.payload.kbaRegexPattern;
        newState.countryConfiguration.matriculationNumberRegexPattern = action.payload.matriculationNumberRegexPattern;
      });

    case ProfileActionTypes.LoadCountryConfigurationError:
      return produce(state, newState => {
        newState.countryConfiguration.loading = false;
        newState.countryConfiguration.loaded = false;
      });
    case ProfileActionTypes.UpdateLastSelectedVehicleTypeSuccess:
      return produce(state, newState => {
        newState.userContext.identificationVehicleType = action.vehicleType;
      });
    case ProfileActionTypes.UpdateUserBroadcastPremiumFeatureSettingsSuccess:
      return produce(state, newState => {
        newState.userContext.hideBroadcastPremiumFeaturePopup = action.hideBroadcastPremiumFeaturePopup;
      });
    default:
      return state;
  }
}
