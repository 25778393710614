<ng-container *vpfaResponsiveIf="'web'; else mobileView">
  <vpfa-setting-box [divider]="false">
    <ng-container label>
      <vpfa-label [text]="label"></vpfa-label>
    </ng-container>
    <ng-container content>
      <vpfa-number-field
        fieldWidth="100%"
        [parentFormGroup]="parentFormGroup"
        [fcName]="fcName"
        [disableMaxWidth]="true"
        [errorMessages]="errorMessages"
        [heightCompensationPx]="heightCompensationPx"
        [maxFractionNumber]="maxFractionNumber"
        [isCurrency]="isCurrency"
      ></vpfa-number-field>
    </ng-container>
  </vpfa-setting-box>
</ng-container>

<ng-template #mobileView>
  <vpfa-vehicle-technical-data-single-field
    [label]="label"
    [value]="parentFormGroup.get(fcName).value | numberLocale: '1.0-1'"
  ></vpfa-vehicle-technical-data-single-field>
</ng-template>
