import { Component, Input } from '@angular/core';

@Component({
  selector: 'vpfa-forecast-info-box',
  templateUrl: './forecast-info-box.component.html',
  styleUrls: ['./forecast-info-box.component.scss'],
})
export class ForecastInfoBoxComponent {
  @Input() forecastDate: string;
  @Input() mobile = false;
}
