import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintTemplatesListComponent } from './components/print-templates-list/print-templates-list.component';
import { UiKitDataTableModule } from '@vpfa/ui-kit/data-table';
import { CreateTemplateModalComponent } from './components/create-template-modal/create-template-modal.component';
import { UiKitModule } from '@vpfa/ui-kit';
import { TranslateModule } from '@ngx-translate/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedPipesModule } from '@vpfa/shared/pipes';
import { RenameTemplateModalComponent } from './components/rename-template-modal/rename-template-modal.component';
import { PdfEditorComponent } from './containers/pdf-editor/pdf-editor.component';
import { UiKitSwitchModule } from '@vpfa/ui-kit/switch';
import { ChangeModuleModalComponent } from './components/change-module-modal/change-module-modal.component';
import { UiKitSettingBoxModule } from '@vpfa/ui-kit/setting-box';
import { ModalsModule } from '@vpfa/modals';
import { CopyTemplateToCountryModalComponent } from './components/copy-template-to-country-modal/copy-template-to-country-modal.component';

@NgModule({
  imports: [
    CommonModule,
    UiKitModule,
    UiKitDataTableModule,
    TranslateModule,
    NzPopconfirmModule,
    FormsModule,
    ReactiveFormsModule,
    NzIconModule,
    NzSpinModule,
    NzButtonModule,
    SharedPipesModule,
    UiKitSwitchModule,
    UiKitSettingBoxModule,
    ModalsModule,
  ],
  declarations: [
    PrintTemplatesListComponent,
    CreateTemplateModalComponent,
    RenameTemplateModalComponent,
    PdfEditorComponent,
    ChangeModuleModalComponent,
    CopyTemplateToCountryModalComponent,
  ],
  exports: [
    PrintTemplatesListComponent,
    CreateTemplateModalComponent,
    RenameTemplateModalComponent,
    PdfEditorComponent,
    ChangeModuleModalComponent,
    CopyTemplateToCountryModalComponent,
  ],
})
export class PrintTemplatesSharedModule {}
