import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NOTIFICATIONS_FEATURE_KEY, NotificationsState } from './notifications.reducer';
import { NotificationType } from '../models/notification-type.enum';

// Lookup the 'Notifications' feature state managed by NgRx
const getNotificationsState = createFeatureSelector<NotificationsState>(NOTIFICATIONS_FEATURE_KEY);

const getAllBasicNotifications = createSelector(
  getNotificationsState,
  (state: NotificationsState) => state.basicNotificationsList
);

const getBasicErrorNotifications = createSelector(getNotificationsState, (state: NotificationsState) =>
  state.basicNotificationsList.filter(e => e.type === NotificationType.Error)
);
const getBasicWarningNotifications = createSelector(getNotificationsState, (state: NotificationsState) =>
  state.basicNotificationsList.filter(e => e.type === NotificationType.Warning)
);
const getBasicSuccessNotifications = createSelector(getNotificationsState, (state: NotificationsState) =>
  state.basicNotificationsList.filter(e => e.type === NotificationType.Success)
);
const getBasicMistakeNotifications = createSelector(getNotificationsState, (state: NotificationsState) =>
  state.basicNotificationsList.filter(e => e.type === NotificationType.Mistake)
);

const getFilesNotifications = createSelector(
  getNotificationsState,
  (state: NotificationsState) => state.filesNotificationsList
);

const getFilesUploadCount = createSelector(
  getNotificationsState,
  (state: NotificationsState) => state.filesUploadCount
);

const getInvalidFilesCount = createSelector(
  getNotificationsState,
  (state: NotificationsState) => state.invalidFilesCount
);

export const notificationsQuery = {
  getAllBasicNotifications,
  getBasicSuccessNotifications,
  getBasicErrorNotifications,
  getBasicWarningNotifications,
  getBasicMistakeNotifications,
  getFilesNotifications,
  getFilesUploadCount,
  getInvalidFilesCount,
};
