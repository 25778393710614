import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './components/modal/modal.component';
import { ModalContentComponent } from './components/modal-content/modal-content.component';
import { ConfirmModalContentComponent } from './components/modal-confirm/confirm-modal-content.component';
import { ModalHeaderComponent } from './components/modal-header/modal-header.component';
import { ModalService } from './services/modal.service';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { TranslateModule } from '@ngx-translate/core';
import { ModalButtonComponent } from './components/modal-button/modal-button.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { OKModalContentComponent } from './components/modal-ok/ok-modal-content.component';

@NgModule({
  imports: [NzModalModule, CommonModule, NzPopconfirmModule, TranslateModule],
  declarations: [
    ModalComponent,
    ModalContentComponent,
    ConfirmModalContentComponent,
    ModalHeaderComponent,
    ModalButtonComponent,
    OKModalContentComponent,
  ],
  exports: [
    ModalComponent,
    ModalContentComponent,
    ConfirmModalContentComponent,
    ModalHeaderComponent,
    ModalButtonComponent,
    OKModalContentComponent,
  ],
  providers: [ModalService],
})
export class ModalsModule {}
