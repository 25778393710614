export enum DATA_TABLE_COMPONENTS {
  DROPDOWN_FLOATING_FILTER = 'dropdownFloatingFilter',
  AG_DATE_INPUT = 'agDateInput',
  DATE_RENDERER = 'dateRenderer',
  NUMBER_RENDERER = 'numberRenderer',
  CURRENCY_RENDERER = 'currencyRenderer',
  TRANSLATE_RENDERER = 'translateRenderer',
  ACTIONS_RENDERER = 'actionsRenderer',
  MULTI_VALUE_RENDERER = 'multiValueRenderer',
  TEXT_FLOATING_FILTER = 'textFloatingFilter',
  FULL_WIDTH_CELL_GROUP_RENDER = 'fullWidthCellGroupRenderer',
  SWITCH_RENDERER = 'switchRenderer',
  SUBJECT_RENDERER = 'subjectRenderer',
  ICON_ACTION_RENDERER = 'iconActionRenderer',
  IMAGE_RENDERER = 'imageRenderer',
  LOADING_INDICATOR = 'loadingIndicator',
  NO_ROWS_INDICATOR = 'noRowsIndicator',
  TRUNCATE_RENDERER = 'truncateRenderer',
  REQUEST_PREVIEW_ACTION_RENDERER = 'requestPreviewActionRenderer',
  RESPONSE_PREVIEW_ACTION_RENDERER = 'responsePreviewActionRenderer',
  CHECKBOX_INPUT = 'checkboxInput',
  TEXT_INPUT_RENDER = 'textInputRenderer',
  TEXT_INPUT_EDITOR = 'textInputEditor',
  EMPTY_LOADING_INDICATOR = 'emptyLoadingIndicator',
  ICON_DELETE_RENDERER = 'iconDeleteRenderer',
  TABLE_HEADER_TOOLTIP_RENDERER = 'tableHeaderTooltipRenderer',
  NUMBER_FLOATING_FILTER = 'numberFloatingFilter',
  LINK = 'link',
  LINKS = 'linksRenderer',
  TAXED_PRICE_RENDERER = 'taxedPriceRenderer',
  DATE_RANGE_FLOATING_FILTER = 'dateRangeFloatingFilter',
  NUMBER_RANGE_FLOATING_FILTER = 'numberRangeFloatingFilter',
  DATE_RANGE_FLOATING_FILTER_OLD = 'dateRangeFloatingFilterOld',
  SENSITIVE_DATA_RENDERER = "sensitiveDataRenderer",
  NUMBER_RANGE_SELECT_FLOATING_FILTER = "numberRangeSelectFloatingFilter",
}
