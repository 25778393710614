import { createReducer, on } from '@ngrx/store';
import { ImageSize } from '@vpfa/utils';
import { produce } from 'immer';
import { ImageStateData } from '../interfaces/image-state-data.interface';
import { getImageFromState } from '../utils/get-image-from-state.util';
import * as actions from './images.actions';

export const IMAGES_FEATURE_KEY = 'images_store';

export interface ImagesState {
  [imageUrl: string]: { [key in ImageSize]?: ImageStateData };
}

export interface ImagesPartialState {
  readonly [IMAGES_FEATURE_KEY]: ImagesState;
}

export const initialState: ImagesState = {};

export const reducer = createReducer<ImagesState>(
  initialState,
  on(actions.imageLoad, (state, action) =>
    produce<ImagesState>(state, newState => {
      if (newState[action.payload.imageUrl] === undefined) {
        newState[action.payload.imageUrl] = {
          [action.payload.size]: {
            dataUrl: null,
            loading: true,
            loaded: false,
            lastUsed: null,
            imageKey: action.payload.imageKey,
          },
        };
      } else {
        const prevState = getImageFromState(state, action.payload.imageUrl, action.payload.size);
        newState[action.payload.imageUrl][action.payload.size] = {
          ...prevState,
          loading: true,
          imageKey: action.payload.imageKey ?? prevState?.imageKey,
          lastUsed: Date.now(),
        };
      }
    })
  ),
  on(actions.imageLoaded, (state, action) =>
    produce<ImagesState>(state, newState => {
      newState[action.payload.imageUrl][action.payload.size] = {
        isFromSorc: action.payload.isFromSorc,
        dataUrl: action.payload.dataUrl,
        loading: false,
        loaded: true,
        error: null,
        lastUsed: Date.now(),
        fileSize: action.payload.fileSize,
        imageKey: action.payload.imageKey,
      };
    })
  ),
  on(actions.imageLoadError, (state, action) =>
    produce<ImagesState>(state, newState => {
      newState[action.payload.imageUrl][action.payload.size] = {
        dataUrl: null,
        loading: false,
        loaded: false,
        error: true,
        lastUsed: null,
        imageKey: action.payload.imageKey,
      };
    })
  ),
  on(actions.imageAlreadyLoaded, (state, action) =>
    produce<ImagesState>(state, newState => {
      const prevState = getImageFromState(state, action.payload.imageUrl, action.payload.size);
      newState[action.payload.imageUrl][action.payload.size] = {
        ...prevState,
        loading: false,
        loaded: true,
        error: false,
        lastUsed: Date.now(),
      };
    })
  ),
  on(actions.bumpImageExpiration, (state, action) =>
    produce<ImagesState>(state, newState => {
      const prevState = getImageFromState(state, action.payload.imageUrl, action.payload.size);
      newState[action.payload.imageUrl][action.payload.size] = {
        ...prevState,
        lastUsed: Date.now(),
      };
    })
  ),
  on(actions.removeImage, (state, action) =>
    produce<ImagesState>(state, newState => {
      const prevState = getImageFromState(state, action.payload.imageUrl, action.payload.size);
      URL.revokeObjectURL(prevState.dataUrl);

      newState[action.payload.imageUrl][action.payload.size] = {
        ...prevState,
        dataUrl: null,
        loaded: false,
      };
    })
  )
);

export function imagesReducer(state, action) {
  return reducer(state, action);
}
