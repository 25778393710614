import { Component, Input } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TemplateBaseModal } from '../../utils/template-base-modal';
import { OpenRenameModalCommand } from '@vpfa/print-templates/data';

const EMPTY_TEMPLATE = { name: null, description: null };

@Component({
  selector: 'vpfa-rename-template-modal',
  templateUrl: './rename-template-modal.component.html',
  styleUrls: ['./rename-template-modal.component.scss']
})
export class RenameTemplateModalComponent extends TemplateBaseModal {
  @Input() modalTitle = 'admin.printTemplates.modal.renameTemplate';
  @Input() set template(template: OpenRenameModalCommand) {
    this._template = template ? template : EMPTY_TEMPLATE;
    this.resetForm();
  }

  private _template = EMPTY_TEMPLATE;
 
  constructor(fb: UntypedFormBuilder, translateService: TranslateService) {
    super(fb, translateService);
  }

  resetForm() {
    this.templateForm.reset(this._template);
  }

}
