<ng-container *ngIf="!isKeepersEmpty; else empty">
  <div [fxLayout]="'row wrap'" [fxLayoutAlign]="'start start'" [fxLayoutGap]="elementsGap">
    <vpfa-label-value
      [label]="'provenanceCheck.keepers.numberOfPreviousKeeper' | translate"
      [value]="keepersChangeDetails?.numberOfPreviousKeepers | numberLocale: '1.0-0'"
      [fxFlex]="elementSize(3)"
    ></vpfa-label-value>
    <vpfa-label-value
      [label]="'provenanceCheck.keepers.mostRecentKeeperChange' | translate"
      [value]="keepersChangeDetails?.dateOfMostRecentKeeperChange | dateLocale"
      [fxFlex]="elementSize(3)"
    ></vpfa-label-value>
    <vpfa-label-value
      [label]="'provenanceCheck.keepers.datesOfOtherChanges' | translate"
      [value]="datesOfKeeperChanges"
      [fxFlex]="elementSize(3)"
    ></vpfa-label-value>
  </div>

</ng-container>
<ng-template #empty>
  <vpfa-empty></vpfa-empty>
</ng-template>
