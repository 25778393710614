import { Component, EventEmitter, Output } from '@angular/core';
import { BaseRepairAndMaintenanceSummary } from '../base-repair-and-maintenance-summary';
import { CasesFacade } from '@vpfa/dealer/case/data';
import { UpdateCaseLightDamagePartCommand } from '@vpfa/rest-api/valuation';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { ScrollToTopService } from '@vpfa/shared/mobile-view';

type DisplayedContent = 'main' | 'damageEstimator';

@Component({
  selector: 'vpfa-mobile-repair-and-maintenance-summary',
  templateUrl: './mobile-repair-and-maintenance-summary.component.html',
  styleUrls: ['./mobile-repair-and-maintenance-summary.component.scss'],
})
export class MobileRepairAndMaintenanceSummaryComponent extends BaseRepairAndMaintenanceSummary {
  displayedContent: DisplayedContent = 'main';

  @Output() closeAction = new EventEmitter<void>();

  constructor(casesFacade: CasesFacade, private scrollService: ScrollToTopService) {
    super(casesFacade);
  }

  onCalculate() {
    this.displayedContent = 'damageEstimator';
  }

  onSave(parts: UpdateCaseLightDamagePartCommand[]) {
    this.casesFacade.updateLightDamageEstimator({
      aggregateRootId: this.caseId,
      lightDamageParts: parts,
    });

    this.casesFacade.isLightDamageEstimatorSaved$
      .pipe(
        distinctUntilChanged(),
        filter(saved => saved),
        take(1)
      )
      .subscribe(() => {
        this.onClose();
      });
  }

  onClose() {
    this.displayMain();
  }

  displayMain() {
    this.displayedContent = 'main';
    this.scrollService.scrollToTop();
  }
}
