import { Directive, OnDestroy, OnInit } from '@angular/core';
import { NgControl, UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[vpfaUpperCase]',
})
export class UpperCaseInputDirective implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();

  constructor(private control: NgControl) {}

  ngOnInit(): void {
    this.updateValueToUpperCase();
    this.control.control.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.updateValueToUpperCase();
    });
  }

  updateValueToUpperCase() {
    (this.control.control as UntypedFormControl).setValue(this.control.value?.toUpperCase(), { emitEvent: false });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
