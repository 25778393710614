import { Params } from '@angular/router';
import { Action } from '@ngrx/store';

export enum AuthLogoutActionTypes {
  Logout = '[Auth] Logout',
}

export class Logout implements Action {
  readonly type = AuthLogoutActionTypes.Logout;
  constructor(public redirectAddress?: string) {}
}

export type AuthLogoutActions = Logout;
