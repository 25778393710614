import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LightDamageAreaDto, LightDamagePartDto, UpdateCaseLightDamagePartCommand } from '@vpfa/rest-api/valuation';
import { SelectOption } from '@vpfa/ui-kit';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BasicNotificationsService } from '@vpfa/shared/notifications';
import { values, isNil } from 'lodash';
import { getPartsFromArea } from '../../../utils/estimator-parts-helpers';
import { costValidator, damageValidator } from '../../../utils/estimator-validators';

@Component({
  selector: 'vpfa-mobile-light-damage-estimator',
  templateUrl: './mobile-light-damage-estimator.component.html',
  styleUrls: ['./mobile-light-damage-estimator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileLightDamageEstimatorComponent implements OnInit {
  private _areas: LightDamageAreaDto[] = [];
  private _parts: LightDamagePartDto[] = [];
  estimatorAreas: LightDamageAreaDto[] = [];
  estimatorForm: UntypedFormGroup = this.fb.group({});
  submitted = false;
  total = 0;

  @Input() loading = false;

  @Input() set areas(newAreas: LightDamageAreaDto[]) {
    this._areas = newAreas;
    this.generateEstimatorModel(this._areas);
  }
  @Output() save = new EventEmitter<UpdateCaseLightDamagePartCommand[]>();
  @Output() closeAction  = new EventEmitter<void>();

  constructor(private fb: UntypedFormBuilder, private translatingBasicNotificationsService: BasicNotificationsService) {}

  ngOnInit(): void {}

  private generateEstimatorModel(newAreas: LightDamageAreaDto[]) {
    if (newAreas && newAreas.length) {
      this.estimatorAreas = newAreas;
      this._parts = getPartsFromArea(this.estimatorAreas);
      this.generateForm();
    }
  }

  onSave() {
    const command: UpdateCaseLightDamagePartCommand[] = values(this.estimatorForm.getRawValue()).map(value => {
      return {
        ...value,
        selectedDamageTypeId: value.selectedDamageTypeId ? value.selectedDamageTypeId.value : null,
      };
    });
    this.save.emit(command);
  }

  onSubmit() {
    this.submitted = true;
    this.estimatorForm.markAsPristine();
    if (this.estimatorForm.valid) {
      this.onSave();
    } else {
      this.translatingBasicNotificationsService.error(
        'damageEstimatorModal.estimationValidationError',
        'damageEstimatorModal.estimationValidationErrorDesc'
      );
    }
  }

  onCancel() {
    this.closeAction.emit();
  }

  onTotalChange(total: number) {
    this.total = total;
  }

  private generateForm() {
    this.estimatorForm = this.fb.group({});
    this._parts.forEach(part => {
      this.estimatorForm.addControl(
        part.id,
        this.fb.group({
          id: part.id,
          cost: [part.cost === 0 && isNil(part.selectedDamageTypeId) ? null : part.cost, { validators: costValidator }],
          selectedDamageTypeId: [this.getDamageType(part), { validators: damageValidator }],
          isIncludedInValuation: part.isIncluded,
        })
      );
    });
  }

  private getDamageType(part: LightDamagePartDto): SelectOption {
    if (!part.selectedDamageTypeId) {
      return null;
    }
    const damageType = part.damageTypes.find(dm => dm.id === part.selectedDamageTypeId);
    return {
      name: damageType.name,
      value: damageType.id,
    };
  }
}
