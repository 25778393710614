<ng-container *vpfaResponsiveIf="'mobile'">
  <vpfa-mobile-subtitle [title]="'vehicleFullTechnicalData.dimensions.label' | translate"></vpfa-mobile-subtitle>
</ng-container>
<ng-container *vpfaResponsiveIf="'web'">
  <div class="mt">
    <vpfa-expanding-header [text]="'vehicleFullTechnicalData.dimensions.label' | translate"></vpfa-expanding-header>
  </div>
</ng-container>

<div
  [fxLayout]="'row wrap'"
  fxLayoutGap.gt-mv="70px grid"
  fxLayout.lt-mv="column"
  fxLayoutGap.lt-mv="10px"
  ngClass.gt-mv="section"
>
  <vpfa-vehicle-technical-data-single-field
    *ngIf="!featureConfig?.hideWheelbase"
    [label]="
      ('vehicleFullTechnicalData.dimensions.wheelbase' | translate) + (technicalData?.dimensions?.wheelbase | unitValue)
    "
    [value]="technicalData?.dimensions?.wheelbase?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    [label]="'vehicleFullTechnicalData.dimensions.tyresFront' | translate"
    [value]="technicalData?.tyres?.summaryFirstAxle"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    [label]="'vehicleFullTechnicalData.dimensions.tyresRear' | translate"
    [value]="technicalData?.tyres?.summarySecondAxle"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="!featureConfig?.hideBootCapacity"
    [label]="
      ('vehicleFullTechnicalData.dimensions.bootCapacity' | translate) +
      (technicalData?.dimensions?.bootCapacity | unitValue)
    "
    [value]="technicalData?.dimensions?.bootCapacity | unitRangeValue"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    [label]="
      ('vehicleFullTechnicalData.dimensions.primaryFuelTankCapacity' | translate) +
      (technicalData?.dimensions?.primaryFuelTankCapacity | unitValue)
    "
    [value]="technicalData?.dimensions?.primaryFuelTankCapacity?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="!featureConfig?.hideLength"
    [label]="
      ('vehicleFullTechnicalData.dimensions.length' | translate) + (technicalData?.dimensions?.length | unitValue)
    "
    [value]="technicalData?.dimensions?.length?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    [label]="
      ('vehicleFullTechnicalData.dimensions.maxLength' | translate) + (technicalData?.body?.maxLength | unitValue)
    "
    [value]="technicalData?.body?.maxLength?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="!featureConfig?.hideHeight"
    [label]="
      ('vehicleFullTechnicalData.dimensions.height' | translate) + (technicalData?.dimensions?.height | unitValue)
    "
    [value]="technicalData?.dimensions?.height?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    [label]="
      ('vehicleFullTechnicalData.dimensions.maxHeight' | translate) + (technicalData?.body?.maxHeight | unitValue)
    "
    [value]="technicalData?.body?.maxHeight?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="!featureConfig?.hideWidth"
    [label]="
      ('vehicleFullTechnicalData.dimensions.width' | translate) +
      (technicalData?.dimensions?.widthExcMirrors | unitValue)
    "
    [value]="technicalData?.dimensions?.widthExcMirrors?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="featureConfig?.showMaxWidthForLCV"
    [label]="
      ('vehicleFullTechnicalData.dimensions.maxWidth' | translate) + (technicalData?.body?.maxWidthForLCV | unitValue)
    "
    [value]="technicalData?.body?.maxWidthForLCV?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="featureConfig?.showSeatHeightForBikes"
    [label]="
      ('vehicleFullTechnicalData.dimensions.seatHeight' | translate) +
      (technicalData?.dimensions?.seatHeightForBikes | unitValue)
    "
    [value]="technicalData?.dimensions?.seatHeightForBikes?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    [label]="
      ('vehicleFullTechnicalData.dimensions.groundClearanceFrom' | translate) +
      (technicalData?.dimensions?.groundClearanceFrom | unitValue)
    "
    [value]="technicalData?.dimensions?.groundClearanceFrom?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    [label]="
      ('vehicleFullTechnicalData.dimensions.groundClearanceTo' | translate) +
      (technicalData?.dimensions?.groundClearanceTo | unitValue)
    "
    [value]="technicalData?.dimensions?.groundClearanceTo?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>
</div>
