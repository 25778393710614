import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UpdateIdentifiedVehicleTechnicalDataCommand, VehicleDto } from '@vpfa/rest-api/valuation';
import { FeaturesTechnicalDataConfiguration } from '@vpfa/locale';
import { SelectOption } from '@vpfa/ui-kit';

@Component({
  selector: 'vpfa-vehicle-full-data-modal',
  templateUrl: './vehicle-full-data-modal.component.html',
  styleUrls: ['./vehicle-full-data-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleFullDataModalComponent {
  @Input() loading: boolean;
  @Input() isVisible = false;
  @Input() hideAllExceptEmissionsAndConsumption = false;
  @Input() vehicleData: VehicleDto;
  @Input() technicalDataFeatureConfig: FeaturesTechnicalDataConfiguration;
  @Input() aggregateRootId: string;
  @Input() emissionClassOptions: SelectOption[];
  @Output() closeAction = new EventEmitter();
  @Output() submitAction = new EventEmitter<UpdateIdentifiedVehicleTechnicalDataCommand>();

  close() {
    this.closeAction.emit();
  }

  submit(event: UpdateIdentifiedVehicleTechnicalDataCommand) {
    this.submitAction.emit(event);
  }
}
