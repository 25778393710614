import { SelectOption } from '@vpfa/ui-kit';

export enum AVAILABILITY_INTERVAL_ENUM {
  Date = 'Date',
  Days = 'Days'
}

export const DATE_AVAILABILITY_INTERVAL: SelectOption = {
  name: `broadcast.interval.${AVAILABILITY_INTERVAL_ENUM['Date'].toLowerCase()}`,
  value: AVAILABILITY_INTERVAL_ENUM.Date
};

export const DAYS_AVAILABILITY_INTERVAL: SelectOption = {
  name: `broadcast.interval.${AVAILABILITY_INTERVAL_ENUM['Days'].toLowerCase()}`,
  value: AVAILABILITY_INTERVAL_ENUM.Days
};
