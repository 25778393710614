<vpfa-section-container
  [name]="'common.notes' | translate"
  [expanded]="true"
  [expandableFromOutside]="false"
  [isExpandable]="false"
  [tooltipContent]="TOOLTIP_CONTENT | translate"
>
  <ng-container right-section>
    <span [ngClass]="{ invalid: notesForm.invalid }" class="char-counter">
      {{ notesForm?.get('notes')?.value?.length || 0 }} / {{ textareaMaxLength }}
    </span>
  </ng-container>
  <div>
    <vpfa-textarea
      id="case-notes"
      [parentFormGroup]="notesForm"
      [fcName]="'notes'"
      (blurEvent)="updateNotes()"
      [minRows]="minRows"
      [maxRows]="maxRows"
    >
    </vpfa-textarea>
  </div>
</vpfa-section-container>
