import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { collapseExpand } from '@vpfa/utils';
import { LightDamageAreaDto, LightDamagePartDto, UpdateCaseLightDamagePartCommand } from '@vpfa/rest-api/valuation';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'vpfa-estimator-subarea',
  templateUrl: './estimator-subarea.component.html',
  styleUrls: ['./estimator-subarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [collapseExpand(250)],
})
export class EstimatorSubareaComponent {
  @Input() submitted: boolean;
  @Input() estimatorForm: UntypedFormGroup;
  @Output() costChanged = new EventEmitter<LightDamagePartDto>();

  @Input() set area(area: LightDamageAreaDto) {
    this._area = area;
    this._partIds = this.area.parts.map(part => part.id);
    this.refreshCosts();
  }
  get area(): LightDamageAreaDto {
    return this._area;
  }

  collapsed = true;
  total = 0;

  private _area: LightDamageAreaDto;
  private _partIds: string[] = [];

  onPartCostChanged(part: LightDamagePartDto) {
    if (this.area) {
      this.refreshCosts();
      this.costChanged.emit(part);
    }
  }

  toggleCollapse() {
    this.collapsed = !this.collapsed;
  }

  private refreshCosts() {
    if (this.area) {
      let newTotal = 0;
      const formValue = this.estimatorForm.getRawValue();
      this._partIds.forEach(id => {
        const formPart: UpdateCaseLightDamagePartCommand = formValue[id];
        if (formPart && formPart.isIncludedInValuation) {
          newTotal += formPart.cost;
        }
      });
      this.total = newTotal;
    }
  }
}
