import { createAction, props } from '@ngrx/store';
import { CO2EmissionRangeDto, EquipmentDto } from '@vpfa/rest-api/valuation';

const prefix = '[Case][ProvenanceCheck]';

export const checkProvenance = createAction(`${prefix} Check provenance`);

export const checkProvenanceSuccess = createAction(`${prefix} Check provenance success`);

export const checkProvenanceError = createAction(`${prefix} Check provenance error`);

export const loadCO2EmissionRanges = createAction(`${prefix} Load CO2 emission ranges`);

export const loadCO2EmissionRangesSuccess = createAction(
  `${prefix} Load CO2 emission ranges success`,
  props<{ ranges: CO2EmissionRangeDto[] }>()
);

export const loadCO2EmissionRangesError = createAction(`${prefix} Load CO2 emission ranges error`);
