/**
 * Ad Broadcast Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 

export enum CabType {

      CrewCab = 0,
      DayCab = 1,
      DoubleCab = 2,
      HighSleeperCab = 3,
      LowAccessCab = 4,
      ShortCab = 5,
      SleeperCab = 6,
      StandardCab = 7,
      SuperCab = 8,
      TransporterCab = 9,

};
