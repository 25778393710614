import { SelectOption } from '@vpfa/ui-kit';

export const PAGE_SIZES: SelectOption[] = [
  {
    name: '10',
    value: 10
  },
  {
    name: '30',
    value: 30
  },
  {
    name: '50',
    value: 50
  },
  {
    name: '100',
    value: 100
  }
];

export const DEFAULT_PAGE_SIZE: SelectOption = PAGE_SIZES[1];
