<vpfa-setting-box *vpfaResponsiveIf="'web'; else mobileView" [divider]="false" [formGroup]="parentFormGroup">
  <ng-container label>
    <vpfa-label [text]="label"></vpfa-label>
  </ng-container>
  <ng-container content>
    <vpfa-select
      [allowClear]="allowClear"
      [parentFormGroup]="parentFormGroup"
      [fc]="parentFormGroup?.get(fcName)"
      [translateLabel]="true"
      [options]="options"
    >
    </vpfa-select>
  </ng-container>
</vpfa-setting-box>

<ng-template #mobileView>
  <vpfa-vehicle-technical-data-single-field [label]="label" [value]="readOnlyValue | translate"></vpfa-vehicle-technical-data-single-field>
</ng-template>
