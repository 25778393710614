import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vpfa-simple-modal',
  templateUrl: './simple-modal.component.html',
  styleUrls: ['./simple-modal.component.scss'],
})
export class SimpleModalComponent {
  @Input() modalName = null;
  @Input() isVisible: boolean;
  @Input() isLoading: boolean;
  @Input() showBackdrop = true;
  @Input() showCloseButton = true;
  @Input() width = '700px';
  @Input() confirmClose = false;
  @Input() showBottomClose = false;
  @Output() closeModal = new EventEmitter();

  onModalClose() {
    this.closeModal.emit();
  }
}
