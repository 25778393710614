import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, AbstractControl, ValidatorFn } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'vpfa-text-list-field',
  templateUrl: './text-list-field.component.html',
  styleUrls: ['./text-list-field.component.scss'],
})
export class TextListFieldComponent implements OnInit, OnDestroy {
  @Input() fcName: string;
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() componentVisible: boolean = true;
  @Input() value: string[];
  @Input() placeholder = '';
  @Input() noItemsText;
  @Input() iconType = 'plus';
  @Input() errorMessages: ReadonlyArray<{ error: string; errorMsg: string }> = [];
  @Input() mobileErrors = false;

  // these are validators for input field not whole form control
  // validating what can be added to the list
  @Input() set validators(validators: ValidatorFn[]) {
    this.formControl.setValidators(validators);
    this.formControl.updateValueAndValidity();
  }

  set list(newList: string[]) {
    if (this._list !== newList) {
      this._list = newList;
      if (this.listControl) {
        this.listControl.setValue(this._list);

        if (this.componentVisible === true) {
          this.listControl.markAsDirty();
        } else if (this.componentVisible === false) {
          this.listControl.markAsPristine();
        }
      }
    }
  }

  get list(): string[] {
    return this._list ? this._list : [];
  }

  formControl = new UntypedFormControl(null);
  shouldDisplayErrors = false;
  private onDestroy$ = new Subject<void>();
  private listControl: AbstractControl;
  private _list: string[] = [];

  ngOnInit() {
    if (this.fcName && this.parentFormGroup) {
      this.listControl = this.parentFormGroup.get(this.fcName);
      this.listControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => (this.list = value));
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  delete(text: string) {
    this.list = this.list.filter(element => element !== text);
    if (!this.list.length) {
      this.list = null;
    }
  }

  addByEnter($event) {
    $event.preventDefault();
    this.add();
  }

  add() {
    if (this.formControl.valid) {
      const text = this.formControl.value;
      if (text && text.length && this.list.indexOf(text) === -1) {
        this.list = [...this.list, text];
      }
      this.formControl.setValue(null);
      this.formControl.markAsPristine();
    }
  }

  showList(): boolean {
    return (this.list && this.list.length) || this.noItemsText;
  }

  onFocus() {
    this.shouldDisplayErrors = true;
  }

  onBlur() {
    this.shouldDisplayErrors = false;
  }
}
