<div class="header">{{ 'pricingDetailsModal.valuation' | translate }}</div>
<ng-container
  *ngFor="let price of pricesToDisplay"
  [ngTemplateOutlet]="priceTemplate"
  [ngTemplateOutletContext]="{ priceModel: price, pricesFormGroup: valuationPricesForm }"
></ng-container>
<div class="show-more">
  <button nz-button nzType="link" (click)="onShowClick()">
    {{ (itemsHidden ? 'common.showMore' : 'common.showLess') | translate }}
  </button>
</div>
