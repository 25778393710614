import { ValueFormatterParams } from 'ag-grid-community';
import {
  DataTableColDef,
  DATA_TABLE_COMPONENTS,
  DataTableActionListItem,
  DataTableValueGetterParams,
  compareMultiValue,
  ActionsColumnName,
} from '@vpfa/ui-kit/data-table';
import { PdfTemplateListItemDto } from '@vpfa/rest-api/valuation';

export const getTemplateColumnsDefinitions = (
  actionGetter: (params: DataTableValueGetterParams<PdfTemplateListItemDto>) => DataTableActionListItem[]
): DataTableColDef<PdfTemplateListItemDto>[] => {
  return [
    {
      colId: 'Name',
      headerName: 'admin.printTemplates.list.name',
      field: 'name',
      maxWidth: 570,
      valueGetter: params => {
        return [params.data.name, params.data.descritpion];
      },
      cellRenderer: DATA_TABLE_COMPONENTS.MULTI_VALUE_RENDERER,
      cellRendererParams: {
        horizontal: true
      },
      comparator: compareMultiValue(true),
    },
    {
      colId: 'CreatedAt',
      headerName: 'admin.printTemplates.list.created',
      maxWidth: 160,
      field: 'createdAt',
      cellRenderer: DATA_TABLE_COMPONENTS.DATE_RENDERER
    },
    {
      colId: 'UpdatedAt',
      headerName: 'admin.printTemplates.list.modified',
      maxWidth: 160,
      field: 'updatedAt',
      cellRenderer: DATA_TABLE_COMPONENTS.DATE_RENDERER
    },
    {
      colId: 'Status',
      headerName: 'admin.printTemplates.list.status',
      maxWidth: 160,
      field: 'isBaseTemplate',
      cellClass: ['cell-with-status'],
      valueFormatter: (params: ValueFormatterParams): string => {
        return `admin.printTemplates.status.${params.value ? 'base' : 'custom'}`;
      },
      cellRenderer: DATA_TABLE_COMPONENTS.TRANSLATE_RENDERER
    },
    {
      colId: 'Modules',
      headerName: 'admin.printTemplates.list.module(s)',
      maxWidth: 280,
      field: 'pdfTemplateModules',
      cellClass: ['cell-with-status'],
      valueFormatter: (params: ValueFormatterParams): string => {
        let formattedModules = '';
        for (let i = 0; i < params.value.length; i++) {
          formattedModules += `admin.printTemplates.list.module${params.value[i]}`;
          if (i !== params.value.length - 1) {
            formattedModules += `,`
          }
        }
        return formattedModules;
      },
      cellRenderer: DATA_TABLE_COMPONENTS.TRANSLATE_RENDERER
    },
    {
      colId: 'Visible',
      headerName: 'admin.printTemplates.list.visible',
      field: 'visible',
      maxWidth: 100,
      sortable: false,
      cellStyle: params => {
        if (params.value === null) {
          return { display: 'none' };
        }
      },
      cellRenderer: DATA_TABLE_COMPONENTS.SWITCH_RENDERER
    },
    {
      colId: ActionsColumnName,
      headerName: null,
      maxWidth: 40,
      sortable: false,
      valueGetter: actionGetter,
      cellClass: 'cell-with-actions',
      cellRenderer: DATA_TABLE_COMPONENTS.ACTIONS_RENDERER
    }
  ];
};
