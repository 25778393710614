<div *vpfaResponsiveIf="'web'; else mobileList">
  <vpfa-web-vehicle-list
    [wizardMode]="wizardMode"
    [listItems]="listItems"
    [tableName]="tableName"
    [identifyNumber]="identifyNumber"
    [searchFeatures]="searchFeatures"
    [disableSpecialButton]="disableSpecialButton"
    [stickyXScrollCustomBottomShift]="stickyXScrollCustomBottomShift"
    (showSpec)="onActionClicked($event)"
    (rowClick)="onRowClick($event)"
  ></vpfa-web-vehicle-list>
</div>

<ng-template #mobileList>
  <ng-container [ngSwitch]="displayedContent">
    <div *ngSwitchCase="'list'" class="no-hor-scroll">
      <vpfa-mobile-vehicle-list
        *ngIf="showMobileList"
        [isFormInvalid]="isFormInvalid"
        [showPower]="showPower"
        [disableSpecialButton]="disableSpecialButton"
        [listItems]="listItems"
        [tableName]="tableName"
        [identifyNumber]="identifyNumber"
        (showSpec)="onActionClicked($event)"
        (rowClick)="onRowClick($event)"
      ></vpfa-mobile-vehicle-list>
    </div>
    <div *ngSwitchCase="'technicalSpec'">
      <vpfa-full-tech-and-equipment-list-mobile
        [vehicleTechSpecData]="vehicleTechSpecData"
        [vehicleEquipmentListData]="vehicleEquipmentData"
        [technicalDataFeatureConfig]="technicalDataFeatureConfig"
        [emissionClassOptions]="emissionClassOptions"
        (closeAction)="onCloseMobileTechSpec()"
      ></vpfa-full-tech-and-equipment-list-mobile>
    </div>
  </ng-container>
</ng-template>
