<div ngClass.lt-mv="no-hor-scroll">
  <vpfa-skew-line></vpfa-skew-line>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="header-container">
    <div fxLayout="row">
      <h3 class="header">{{ title }}</h3>
      <vpfa-tooltip *ngIf="tooltipContent" class="tooltip" [content]="tooltipContent"></vpfa-tooltip>
    </div>
    <div class="right-content">
      <ng-content select="[right]"></ng-content>
    </div>
  </div>
</div>
