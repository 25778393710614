import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface LinkParam {
  text: string;
  navigateCommands: string[];
}

export interface LinksRendererParams {
  links: LinkParam[];
}

@Component({
  selector: 'vpfa-links-renderer',
  templateUrl: './links-renderer.component.html',
  styleUrls: ['./links-renderer.component.scss'],
})
export class LinksRendererComponent implements ICellRendererAngularComp {
  links: LinkParam[] = [];

  constructor(private router: Router) {}

  agInit(params: ICellRendererParams): void {
    if (params) {
      this.initValue(params.value);
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  initValue(value: LinksRendererParams) {
    this.links = value.links;
  }

  goTo(event: LinkParam) {
    this.router.navigate(event.navigateCommands);
  }
}
