import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { isEqual, isNil, uniqBy, uniqWith } from 'lodash';
import { SelectOption } from '../../interfaces';
import { NzFilterOptionType, NzSelectComponent } from 'ng-zorro-antd/select';
import { NzSelectItemInterface } from 'ng-zorro-antd/select/select.types';
import { TranslateService } from '@ngx-translate/core';
import { IsMobileViewService } from '@vpfa/shared/mobile-view';
import { NzPopconfirmDirective } from 'ng-zorro-antd/popconfirm';

const defaultSearchFilter: NzFilterOptionType = (input: string, option: NzSelectItemInterface) => {
  const value = option.nzLabel ?? option.nzValue?.value;
  return value?.toLowerCase().includes(input.toLowerCase(), 0);
};

export enum SelectMode {
  default = 'default',
  multiple = 'multiple',
  tags = 'tags',
}

export enum UiKitSelectColorTheme {
  lightWithDropShadow = 'light-drop-shadow-theme',
  grayInnerShadow = 'gray-inner-shadow-theme',
  middleLightGray = 'middle-light-gray-theme',
}

export enum GroupLabels {
  customers = 'customers.addRemoveCustomerList.customers',
  prospects = 'customers.addRemoveCustomerList.prospects',
  branches = 'customers.addRemoveCustomerList.branches',
  businessBranches = 'branchSelect.businessBranches',
  cooperativeBranches = 'branchSelect.cooperativeBranches',
  allBranches = 'branchSelect.allBranches',
}

interface ExtendedSelectOption<T = any> extends SelectOption<T> {
  hide?: boolean;
}

export const defaultSelectPlaceholder = 'select.selectValue';

@Component({
  selector: 'vpfa-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})

export class SelectComponent implements OnChanges, AfterViewInit {

  @Input() size: 'large' | 'small' | 'default' = 'default';
  @Input() disabled = false;
  @Input() maskSensitiveData = false;
  @Input() useServerSideSearch = false;
  @Input() allowClear = false;
  @Input() placeholder: string;
  @Input() enabledSearch = false;
  @Input() translateLabel = false;
  @Input() loading: boolean;
  @Input() dropdownClass = 'vpfa-select';
  @Input() colorTheme = UiKitSelectColorTheme.grayInnerShadow;
  @Input() mode: SelectMode = SelectMode.default;

  defaultSelectPlaceholder = defaultSelectPlaceholder;

  /**
   * Whether dropdown's width is the same as select width
   * For mobiles it will always be true
   * For web it will be true by default but can be changed to false
   */
  @Input() nzDropdownMatchSelectWidth = true;
  @Input() showGroups = false;

  /**
   * Hidden options are not shown in the dropdown items
   * But can be shown as default/selected value for example
   * https://ng.ant.design/components/select/en#nz-option
   */
  @Input() hiddenOptions: Array<SelectOption>;

  @Input() parentFormGroup: UntypedFormGroup;
  @Input() customOptionTemplate: TemplateRef<{ label: string; option: any; additional?: any }>;
  @Input() customSelectedTemplate: TemplateRef<{ label: string; option: any; additional?: any }>;
  @Input() set isOpen(isOpen: boolean) {
    this._isOpen = isOpen;
    if (!this._isOpen) {
      this.optionsClosed.emit();

      if (this.useServerSideSearch) {
        // when dropdown is closed, reset backend filters to its initial value
        // (this was fastest way of implementing this, maybe not the best)
        this.onSearch.emit(null);
      }
    }
  }

  /**
   * Whether to show popconfirm on changing select value
   */
  @Input() confirmChangeCondition: boolean;
  @Input() confirmChangeText: string;

  @Input() set searchFilter(value: NzFilterOptionType) {
    if (isNil(value)) {
      return;
    }
    this._searchFilter = value;
  }

  @Input() set initialValue(initialValue: SelectOption) {
    this._initialValue = initialValue;
    this.setInitValueToControl();
  }

  @Input() set fc(fc: UntypedFormControl) {
    this._fc = fc;
    this.setInitValueToControl();
  }

  @Input() options: SelectOption[];

  /**
   * Whether to load more data when scrolling to the bottom of options
   */
  @Input() useInfiniteScroll: boolean;

  /**
   * Event emitted when scrolled to the bottom of options.
   * Should be used with useInfiniteScroll flag to load more data from backend
   */
  @Output() scrollToBottom = new EventEmitter<void>();

  @Output() selected = new EventEmitter<SelectOption>();
  @Output() resetValue = new EventEmitter<void>();
  @Output() optionsClosed = new EventEmitter<void>();
  @Output() onSearch = new EventEmitter<any>();

  @ViewChild('nzSelect', { read: NzSelectComponent, static: true }) nzSelectRef: NzSelectComponent;
  @ViewChildren(NzPopconfirmDirective) popconfirmRef: QueryList<NzPopconfirmDirective>;

  private _fc: UntypedFormControl;
  private _initialValue: SelectOption;
  private _searchFilter: NzFilterOptionType;
  private _isOpen = false;

  get isOpen(): boolean {
    return this._isOpen;
  }

  get searchFilter(): NzFilterOptionType {
    return this._searchFilter || defaultSearchFilter;
  }

  get fc(): UntypedFormControl {
    return this._fc;
  }

  // INFO: even if confirmChangeText has value but confirmChangeCondition is false, popconfirm will not appear
  get popconfirmText(): string {
    return this.confirmChangeCondition === true ? this.confirmChangeText : null;
  }

  convertedOptions: ExtendedSelectOption[] = [];
  groupLabelsFromOptions: GroupLabels[] | string[] = [];
  isMobileView$ = this.isMobileViewService.isMobileView$;

  compareWith = (o1, o2) => isEqual(o1, o2);

  constructor(private translateService: TranslateService, private isMobileViewService: IsMobileViewService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!isNil(changes['options']) || !isNil(changes['hiddenOptions'])) {
      this.initializeOptions();
    }
  }

  ngAfterViewInit(): void {

    this.initializeOptions();

    if (this.useServerSideSearch) {
      this.nzSelectRef.nzServerSearch = true;
    }

  }

  onSelect(value) {
    this.selected.emit(value);
  }

  openSelect() {
    this._isOpen = true;
  }

  private setInitValueToControl() {
    if (this.options && this.options.length > 0 && this._fc && this._initialValue) {
      this._fc.setValue(this._initialValue);
    }
  }

  onSelectBeforeConfirm(event: MouseEvent, option: SelectOption) {
    if (this.confirmChangeCondition && option?.value !== this.fc.value) {
      event.stopImmediatePropagation();
      this.managePopconfirm(option, 'show');
    }
  }

  onSelectAfterConfirm(option: SelectOption) {
    if (this.confirmChangeCondition && option?.value !== this.fc.value) {
      this.managePopconfirm(option, 'hide');
      this.fc.reset(option?.value);
      this._isOpen = false;
    }
  }

  onScrollToBottom() {
    this.scrollToBottom.emit();
  }

  private initializeOptions() {
    this.convertedOptions = [];

    if (!isNil(this.options)) {
      if (this.showGroups === true) {
        this.groupLabelsFromOptions = uniqBy(this.options, 'groupLabel').map(x => x.groupLabel);
      }
      this.convertedOptions = uniqWith([...this.convertToOptions(this.options)], isEqual);
      this.setInitValueToControl();
    }

    if (!isNil(this.hiddenOptions) && this.hiddenOptions.length > 0) {
      this.convertedOptions = uniqWith(
        [...this.convertedOptions, ...this.convertToHiddenOptions(this.hiddenOptions)],
        isEqual
      );
    }
  }

  optionsByGroup(groupLabel?: GroupLabels): ExtendedSelectOption[] {
    return !isNil(groupLabel) && this.showGroups === true
      ? this.convertedOptions.filter(x => x.groupLabel === groupLabel)
      : this.convertedOptions;
  }

  private managePopconfirm(option: SelectOption, action: 'hide' | 'show') {
    const popconfirmRefList = this.popconfirmRef?.toArray();
    const clickedOptionIndex = this.convertedOptions.indexOf(option);

    if (!isNil(popconfirmRefList) && popconfirmRefList?.length >= clickedOptionIndex && clickedOptionIndex !== -1) {
      const popconfirmRef = popconfirmRefList[clickedOptionIndex];

      if (action === 'show') {
        popconfirmRef.show();
      } else if (action === 'hide') {
        popconfirmRef.hide();
      }
    }
  }

  private convertToOptions(options: SelectOption[]): ExtendedSelectOption[] {
    return options.map(option => ({
      name: option.name,
      value: option,
      groupLabel: option.groupLabel,
      additional: option.additional,
    }));
  }

  private convertToHiddenOptions(hiddenOptions: SelectOption[]): ExtendedSelectOption[] {
    return (
      hiddenOptions?.map(hiddenOption => ({
        name: hiddenOption.name,
        value: hiddenOption,
        groupLabel: hiddenOption.groupLabel,
        additional: hiddenOption.additional,
        hide: true,
      })) ?? []
    );
  }

  clear() {
    this.nzSelectRef?.writeValue(undefined);
  }

}
