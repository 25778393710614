import { SelectOption } from '@vpfa/ui-kit';
import { SorcDictionaryDto } from '../models/sorc-dictionary-dto';
import {
  OptionalSearchFilters,
  OptionalSearchFiltersKeys,
  OptionalSearchFiltersOptions,
} from '../models/optional-search-filters';

export const mapOptionalSearchFiltersOptions = (
  filterValues: OptionalSearchFilters,
  transmissionTypeList: SorcDictionaryDto[],
  bodyTypeList: SorcDictionaryDto[],
  fuelTypeList: SorcDictionaryDto[]
): OptionalSearchFiltersOptions => {
  return {
    [OptionalSearchFiltersKeys.numDoors]: mapValuesToOptions(filterValues?.numDoors),
    [OptionalSearchFiltersKeys.transmissionType]: mapDictionariesToOptions(
      filterValues?.transmissionType,
      transmissionTypeList
    ),
    [OptionalSearchFiltersKeys.bodyType]: mapDictionariesToOptions(filterValues?.bodyType, bodyTypeList),
    [OptionalSearchFiltersKeys.fuelType]: mapDictionariesToOptions(filterValues?.fuelType, fuelTypeList),
    [OptionalSearchFiltersKeys.edition]: mapValuesToOptions(filterValues?.edition),
    [OptionalSearchFiltersKeys.engineCapacity]: mapValuesToOptions(filterValues?.engineCapacity),
    [OptionalSearchFiltersKeys.wheelbase]: mapValuesToOptions(filterValues?.wheelbase),
    [OptionalSearchFiltersKeys.grossWeight]: mapValuesToOptions(filterValues?.grossWeight),
  };
};

const mapDictionariesToOptions = (values: string[], dictionaries: SorcDictionaryDto[]): SelectOption[] => {
  return values.map((filterValue: string) => {
    const dictionaryItem: SorcDictionaryDto = dictionaries.find(
      (dictionaryItem: SorcDictionaryDto) => dictionaryItem?.code === filterValue
    );
    return <SelectOption>{
      name: dictionaryItem?.value,
      value: dictionaryItem?.code,
    };
  });
};

const mapValuesToOptions = (values: any[]): SelectOption[] => {
  return values.map(value => <SelectOption>{ value: value });
};
