import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { LogoPartialState } from './logo.reducer';
import { logoQuery } from './logo.selectors';
import { LoadLogoForBusiness, LoadLogoForCountry, ClearLogo } from './logo.actions';

@Injectable({ providedIn: 'root' })
export class LogoFacade {
  get businessLogo$() {
    return this.store.pipe(select(logoQuery.getBusinessLogo));
  }

  get countryLogo$() {
    return this.store.pipe(select(logoQuery.getCountryLogo));
  }

  constructor(private store: Store<LogoPartialState>) {}

  loadLogoByBusiness(businessId: string) {
    this.store.dispatch(new LoadLogoForBusiness(businessId));
  }

  loadLogoByCountry(countryId: string) {
    this.store.dispatch(new LoadLogoForCountry(countryId));
  }

  clearLogo() {
    this.store.dispatch(new ClearLogo());
  }
}
