<vpfa-select
  [disabled]="disabled"
  [placeholder]="
    (customerListOptions$ | async)?.length
      ? ('customers.addRemoveCustomerList.' + selectLabel | translate)
      : ('customers.addRemoveCustomerList.noCustomers' | translate)
  "
  [loading]="isLoadingData"
  [options]="showGroups ? (groupedCustomerListOptions$ | async) : (customerListOptions$ | async)"
  [customOptionTemplate]="customOption"
  [customSelectedTemplate]="customOption"
  [enabledSearch]="true"
  [maskSensitiveData]="true"
  [searchFilter]="customerSearchFilter"
  [showGroups]="showGroups"
  [parentFormGroup]="parentFormGroup"
  [fc]="fc"
  [allowClear]="allowClear"
  [useInfiniteScroll]="useInfiniteScroll"
  [useServerSideSearch]="true"
  (onSearch)="onSearch.emit($event)"
  (scrollToBottom)="onLoadNextPage()"
></vpfa-select>

<ng-template #customOption let-label="label" let-option="option" let-additional="additional" let-index="index">
  <div
    maskSensitiveData="customerDropdownOption"
    class="add-remove-customer-list-customer-select-option d-flex flex-row justify-content-between gap-2px"
  >
    <div class="flex-width-20" [title]="additional.firstName | lT">{{ additional.firstName | lT }}</div>
    <div class="bold flex-width-20" [title]="additional.lastName | lT">{{ additional.lastName | lT }}</div>
    <div class="grey flex-width-40" [title]="getStreetAndAddress(additional) | lT">
      {{ getStreetAndAddress(additional) | lT }}
    </div>
    <div class="grey flex-width-20" [title]="additional.city | lT">{{ additional.city | lT }}</div>
  </div>
</ng-template>
