import {Component, Input, TemplateRef} from '@angular/core';

@Component({
  selector: 'vpfa-mobile-page-title',
  templateUrl: './mobile-page-title.component.html',
  styleUrls: ['./mobile-page-title.component.scss']
})
export class MobilePageTitleComponent {
  @Input() title: string;
  @Input() tooltipContent: string | TemplateRef<void>;
}
