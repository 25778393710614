import { SorcDictionaryDto } from '../models/sorc-dictionary-dto';
import { mapContentLanguageValue } from './map-content-language';

export const mapDictionaryDto = (
  dictionary,
  countryCode: string,
  contentLanguage: string,
  sorcContentLanguageCoversions: { [key: string]: string }
): SorcDictionaryDto => {
  return {
    market: dictionary?.market,
    code: dictionary?.code,
    value: mapContentLanguageValue(dictionary?.texts, countryCode, contentLanguage, sorcContentLanguageCoversions),
  };
};
