import { SelectOption } from '@vpfa/ui-kit'; // TODO: circular dependency
import { camelCase } from 'lodash/fp';

export const enumToOptions = (enumeration: any, prefix = '', lowerCase = true): SelectOption[] => {

  return enumToKeyNames(enumeration).map(key => {
    return {
      name: `${prefix}${lowerCase ? camelCase(key) : key}`,
      value: enumeration[key]
    };
  });
};

export const enumToOptionsNameValueEqual = (enumeration: any): SelectOption[] => {

  return enumToKeyNames(enumeration).map(key => {
    return {
      name: enumeration[key],
      value: enumeration[key]
    };
  });
};

export function enumToKeyNames(enumeration: any): string[] {
  const keys = Object.keys(enumeration);
  const enumKeyNames: string[] = [];

  // Handle enum (NAME: number)
  if (keys.length) {
    enumKeyNames.push(...(keys.filter(key => typeof enumeration[key] === 'number') as string[]));
  }

  // Handle enum (NAME: string)
  if(enumKeyNames.length === 0 && enumeration.length !== 0){
    enumKeyNames.push(...keys)
  }
  
  return enumKeyNames;
}
