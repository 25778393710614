import { Component } from '@angular/core';
import { AdjustmentsFacade, CasesFacade } from '@vpfa/dealer/case/data';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { EquipmentSectionBase } from '../equipment-section-base/equipment-section-base';

@Component({
  selector: 'vpfa-equipment-section-web',
  templateUrl: './equipment-section-web.component.html',
  styleUrls: ['./equipment-section-web.component.scss'],
})
export class EquipmentSectionWebComponent extends EquipmentSectionBase {
  constructor(fb: UntypedFormBuilder, caseFacade: CasesFacade, adjustmentsFacade: AdjustmentsFacade, router: Router, route: ActivatedRoute) {
    super(fb, caseFacade, adjustmentsFacade, router, route);
  }
}
