import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CasesFacade } from '@vpfa/dealer/case/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'vpfa-forecast',
  templateUrl: './forecast.component.html',
  styleUrls: ['./forecast.component.scss'],
})
export class ForecastComponent implements OnInit, OnDestroy {
  @Input() caseId: string;
  @Input() forecastDate: string;
  @Input() timezone: string;
  @Input() notAvailable = false;
  @Input() isDisabled = false;

  onDestroy$ = new Subject<void>();
  isModalVisible = false;
  isUpdatingValuationDate$ = this.casesFacade.isUpdatingValuationDate$;

  constructor(private casesFacade: CasesFacade) {}

  ngOnInit() {
    this.casesFacade.isUpdateValuationDateModalOpen$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(isVisible => (this.isModalVisible = isVisible));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getForecastForDate(valuationDate: string) {
    this.casesFacade.updateCaseValuationDate({
      aggregateRootId: this.caseId,
      valuationDate,
    });
  }

  onClose() {
    this.isModalVisible = false;
  }

  onForeCastClick() {
    if (this.isDisabled) {
      return;
    }
    this.isModalVisible = true;
  }
}
