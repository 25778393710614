import { Directive, ElementRef, forwardRef, HostListener, Input, OnDestroy, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { formatNumber, getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { filter, takeUntil } from 'rxjs/operators';
import { isEmpty, isInteger, isNil } from 'lodash';
import { LocaleFacade } from '@vpfa/locale';
import { interval, Subject } from 'rxjs';

const numberDecSeparator = '.';
const intervalSign = '-';

export const INTERVAL_FORMATTER_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => IntervalFormatterDirective),
  multi: true,
};

@Directive({
  selector: 'input[vpfaIntervalFormatter]',
  providers: [INTERVAL_FORMATTER_VALUE_ACCESSOR],
})
export class IntervalFormatterDirective implements ControlValueAccessor, OnDestroy {
  onChange;
  onTouched;

  private numberOfZeros = 0;
  private decimalSeparator;
  private groupSeparator = '';
  private locale: string;
  private modelValue;
  private _onDestroy$ = new Subject<void>();

  private _maxFractionNumber = 0;
  @Input() set maxFractionNumber(value: number) {
    if (value > 1) {
      throw new Error('IntervalFormatterDirective need to be refactored if maxFractionNumber is > 1. See HACK comment');
    }

    this._maxFractionNumber = value;
  }
  @Input() active = true;

  constructor(private renderer: Renderer2, private element: ElementRef, private localeFacade: LocaleFacade) {
    this.localeFacade.locale$
      .pipe(
        filter(locale => !isNil(locale)),
        takeUntil(this._onDestroy$)
      )
      .subscribe(locale => {
        this.onChangeLocale(locale);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @HostListener('keydown', ['$event'])
  onKeydown(e: any) {
    this.handleMultipleSpecialSigns(e);
    this.onBackspaceDeleteKey(e);
  }

  @HostListener('input', ['$event'])
  input(e) {
    const value = e.target.value;
    this.formatInput(value);
  }

  @HostListener('blur', ['$event'])
  onBlur(e) {
    this.numberOfZeros = this._maxFractionNumber;
    if (this._maxFractionNumber) {
      if (e.target.value.includes(this.decimalSeparator)) {
        const decimalDigits = e.target.value.split(this.decimalSeparator)[1];
        if (decimalDigits !== '0' && decimalDigits !== '00') {
          this.numberOfZeros = 1 - decimalDigits.length;
        }
      }
      this.setInputValueFromModel(false);
    }
  }

  private formatInput(value: string, isDeleting = false) {
    if (isEmpty(value)) {
      this.setNullValue();
      return;
    }

    this.numberOfZeros = this.numberOfLastZeros(value);
    const stringNumber: string = this.removeNonNumericalCharacters(this.getNumberString(value));

    // if there is only separator then remove it
    if (stringNumber === numberDecSeparator) {
      this.setNullValue();
      return;
    }

    if (isNil(stringNumber)) {
      this.setNullValue();
      return;
    }

    if (!stringNumber.includes(intervalSign)) {
      let tmpModelValue = parseFloat(stringNumber);

      if (isNaN(tmpModelValue)) {
        this.setNullValue();
        return;
      }

      this.modelValue = tmpModelValue;
    } else {
      this.modelValue = stringNumber;
    }

    this.setInputValueFromModel(
      stringNumber[stringNumber.length - 1] === numberDecSeparator && this._maxFractionNumber !== 0 && !isDeleting
    );

    this.onChange(this.modelValue);
    return;
  }

  private handleMultipleSpecialSigns(event: KeyboardEvent) {
    const element = event.target as HTMLInputElement;
    const splitedValues = element.value.split(intervalSign);
    //checking if adding decimal separator in field that already exist;
    splitedValues.forEach((intervalNumber, index) => {
      if (intervalNumber.includes(this.decimalSeparator) && event.key === this.decimalSeparator) {
        if (index === 0 && element.selectionStart <= splitedValues[0].length) {
          event.preventDefault();
        } else if (index === 1 && element.selectionStart > splitedValues[0].length) {
          event.preventDefault();
        }
      }
    });
    if (event.key === intervalSign) {
      event.preventDefault();
    }
  }

  private formatInputAfterIntervalSign(value: string) {
    if (isEmpty(value)) {
      this.setNullValue();
      return;
    }
    const stringValue: string = value
      .split(intervalSign)
      .map(splitedValue => {
        let stringNumber: string = this.removeNonNumericalCharacters([splitedValue]);
        // if there is only separator then remove it
        if (stringNumber === numberDecSeparator) {
          stringNumber = null;
        }

        if (isNil(stringNumber)) {
          stringNumber = null;
        }
        return stringNumber;
      })
      .join(intervalSign);
    this.modelValue = stringValue;

    this.onChange(this.modelValue);

    (this.element.nativeElement as HTMLInputElement).value = stringValue;
    return;
  }

  writeValue(value: any): void {
    // HACK: This will force to format integer (ex 123) to decimal 123,5.
    //       It will not work for some float numbers, ex 123,2 will not be formatted to 123,20
    //       and if you need to handle such situation below part of code need to improved.
    if (!isNil(value) && this._maxFractionNumber > 0 && Number.isInteger(value)) {
      this.numberOfZeros = this._maxFractionNumber;
    }

    // model value to input value
    this.modelValue = value;
    this.setInputValueFromModel();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   *
   * @param value string number value with locale separators
   *
   * @returns string in js number format for example '1000.5' from '1 000,5'
   */
  private getNumberString(value: string): string[] {
    return value
      .split(intervalSign)
      .map(splitted =>
        splitted.split(this.groupSeparator).join('').split(this.decimalSeparator).join(numberDecSeparator)
      );
  }

  private getNumberFormatter() {
    return `1.0${!isNil(this._maxFractionNumber) ? `-${this._maxFractionNumber}` : 0}`;
  }

  private getFormattedStringValue(value: number, decSep: boolean = true, isInterval: boolean = false): string {
    if (isNil(this.locale)) {
      return '';
    }
    let addingString = '';
    // Interval numbers shouldn't have any zeros appended after format
    if (this.numberOfZeros > 0 && decSep && !isInterval) {
      if (isInteger(Number(value))) {
        addingString = this.decimalSeparator + '0'.repeat(this.numberOfZeros);
      } else {
        addingString = '0'.repeat(this.numberOfZeros);
      }
    }

    return formatNumber(value, this.locale, this.getNumberFormatter()) + addingString;
  }

  private setNullValue() {
    this.modelValue = null;
    this.setInputValueFromModel();
    this.onChange(null);
  }

  private formatDecimalSeparator(tempModel: string, addDecimalSeparator: boolean) {
    if (addDecimalSeparator) {
      return `${tempModel}${this.decimalSeparator}`;
    }
    return tempModel;
  }

  private setInputValueFromModel(addDecimalSeparator = false) {
    let valueToPresent = '';
    let addDecSep = addDecimalSeparator;
    if (!isNil(this.modelValue)) {
      const modelString = this.modelValue.toString();
      if (modelString.includes(intervalSign)) {
        valueToPresent = modelString
          .split(intervalSign)
          .map((model, index) => {
            let tempModel = '';
            if (index === 1 && this.element.nativeElement.selectionStart < modelString.split(intervalSign)[0].length) {
              addDecSep = false;
              tempModel = this.getFormattedStringValue(model, false, true);
            } else if (
              index === 0 &&
              this.element.nativeElement.selectionStart > modelString.split(intervalSign)[0].length
            ) {
              addDecSep = false;
              tempModel = this.getFormattedStringValue(model, false, true);
            } else {
              addDecSep = addDecimalSeparator;
              tempModel = this.getFormattedStringValue(model, true, true);
            }
            return this.formatDecimalSeparator(tempModel, addDecSep);
          })
          .join(intervalSign);
      } else {
        valueToPresent = this.getFormattedStringValue(modelString);
        valueToPresent = this.formatDecimalSeparator(valueToPresent, addDecimalSeparator);
      }
    }

    const element = this.element.nativeElement;

    // counts additional chars - number group separator and so on
    const lengthChange = this.element.nativeElement.value.length - valueToPresent.length;

    const selectionStartPosition = element.selectionStart;
    const selectionEndPosition = element.selectionEnd;
    this.renderer.setProperty(element, 'value', valueToPresent);
    element.setSelectionRange( selectionStartPosition - lengthChange, selectionEndPosition - lengthChange);

  }

  private onChangeLocale(locale: string) {
    this.locale = locale;
    this.decimalSeparator = getLocaleNumberSymbol(this.locale, NumberSymbol.Decimal);
    this.groupSeparator = getLocaleNumberSymbol(this.locale, NumberSymbol.Group);
    this.setInputValueFromModel();
  }

  private removeNonNumericalCharacters(inputValue: string[]): string {
    return inputValue
      .map(input => {
        return input.split('').reduce((processed, currentCharacter) => {
          if (
            [numberDecSeparator, intervalSign, this.decimalSeparator, this.groupSeparator].includes(currentCharacter) ||
            (currentCharacter !== ' ' && isFinite(+currentCharacter))
          ) {
            return processed + currentCharacter;
          }
          return processed;
        }, '');
      })
      .join(intervalSign);
  }

  private onBackspaceDeleteKey(e: KeyboardEvent) {
    const target = e.target as HTMLInputElement;

    const selectionStartPosition = target.selectionStart;
    const selectionEndPosition = target.selectionEnd;
    const isRemovingSingleCharacter = selectionStartPosition === selectionEndPosition;
    const removeKeys = ['Backspace', 'Delete'];

    if (removeKeys.includes(e.key) && isRemovingSingleCharacter) {
      const originalValue = target.value;
      let value = target.value;

      if (value.slice(0, selectionStartPosition - 1).includes(intervalSign)) {
        if (e.key === 'Backspace') {
          const isGroupSeparatorBeforeCaret = this.groupSeparator === value[selectionStartPosition - 1];
          const valueBeforeInterval = value.split(intervalSign)[0];
          let valueAfterInterval = value.split(intervalSign)[1];
          valueAfterInterval = this.handleBackspaceKey(
            valueAfterInterval,
            selectionStartPosition - valueBeforeInterval.length - 1,
            isGroupSeparatorBeforeCaret
          );
          value = valueBeforeInterval + intervalSign + valueAfterInterval;
        }
        this.formatInputAfterIntervalSign(value);
      } else {
        if (e.key === 'Backspace') {
          const isGroupSeparatorBeforeCaret = this.groupSeparator === value[selectionStartPosition - 1];
          if (value[selectionStartPosition - 1] === intervalSign && value.split(this.decimalSeparator).length > 2) {
            value = value.replace(this.decimalSeparator, '').replace(intervalSign, '');
          } else {
            value = this.handleBackspaceKey(value, selectionStartPosition, isGroupSeparatorBeforeCaret);
          }
        }

        if (e.key === 'Delete') {
          // no need to handle removing when caret is at the end of input
          if (selectionStartPosition === value.length) {
            return;
          }
          const isGroupSeparatorAfterCaret = this.groupSeparator === value[selectionStartPosition];
          if (value[selectionStartPosition] === intervalSign && value.split(this.decimalSeparator).length > 2) {
            value = value.replace(intervalSign, '').replace(this.decimalSeparator, '');
          }
          value = this.handleDeleteKey(value, selectionStartPosition, isGroupSeparatorAfterCaret);
        }
        this.formatInput(value, true);
      }

      const lengthChange = originalValue.length - target.value.length;
      this.setSelection(e, selectionStartPosition - lengthChange, selectionEndPosition - lengthChange);
      e.preventDefault();
    }
  }

  private handleBackspaceKey(value: string, caretPosition: number, isGroupSeparatorBeforeCaret: boolean): string {
    if (isGroupSeparatorBeforeCaret) {
      return value.slice(0, caretPosition - 2) + value.slice(caretPosition);
    } else {
      return value.slice(0, caretPosition - 1 > 0 ? caretPosition - 1 : 0) + value.slice(caretPosition);
    }
  }

  private handleDeleteKey(value: string, caretPosition: number, isGroupSeparatorAfterCaret: boolean): string {
    if (isGroupSeparatorAfterCaret) {
      return value.slice(0, caretPosition + 1) + value.slice(caretPosition + 2);
    } else {
      return value.slice(0, caretPosition) + value.slice(caretPosition + 1);
    }
  }

  private setSelection(e: KeyboardEvent, start: number, end: number) {
    (e.target as HTMLInputElement).selectionStart = start > 0 ? start : 0;
    (e.target as HTMLInputElement).selectionEnd = end > 0 ? end : 0;
  }

  private numberOfLastZeros(value: string) {
    let numberOfZeros = 0;

    while (
      value.charAt(value.length - 1) === '0' &&
      value.includes(this.decimalSeparator) &&
      numberOfZeros < this._maxFractionNumber
    ) {
      numberOfZeros += 1;
      value = value.slice(0, -1);
    }
    return numberOfZeros;
  }
}
