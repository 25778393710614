<vpfa-modal
  width="750px"
  modalName="Pricing Details"
  [showBackdrop]="true"
  [isClosable]="false"
  [isVisible]="isVisible"
  [keyboard]="false"
  [maskClosable]="false"
  (closeAction)="close()"
>
  <vpfa-modal-content>
    <button class="ant-modal-close" (click)="close()">
      <i nz-icon nzType="close-circle" nzTheme="fill"></i>
    </button>
    <form>
      <h1>{{ 'pricingDetailsModal.pricingDetails' | translate }}</h1>

      <nz-spin [nzSpinning]="loading">
        <div fxLayout="column" fxLayoutGap="20px">
          <vpfa-pricing-details-sold
            *ngIf="isSoldStatus()"
            [soldPrices]="soldPrices"
            [soldPricesForm]="soldPricesFormGroup"
            [priceTemplate]="priceWithTax"
            [errorMessages]="errorMessages"
          ></vpfa-pricing-details-sold>

          <vpfa-pricing-details-stock
            [stockPrices]="stockPrices"
            [stockPricesForm]="stockPricesFormGroup"
            [priceTemplate]="priceWithTax"
            [errorMessages]="errorMessages"
          ></vpfa-pricing-details-stock>

          <vpfa-pricing-details-valuation
            *ngIf="isStockStatus()"
            [valuationPrices]="valuationPrices"
            [valuationPricesForm]="valuationPricesFormGroup"
            [priceTemplate]="priceWithTax"
            [errorMessages]="errorMessages"
          ></vpfa-pricing-details-valuation>

          <div class="tax-des">
            <vpfa-tax-information [tax]="activeCaseDataTaxes"></vpfa-tax-information>
          </div>

          <div>
            <vpfa-action-button
              *ngIf="canSave"
              [disabled]="
                valuationPricesFormGroup.invalid ||
                stockPricesFormGroup.invalid ||
                soldPricesFormGroup.invalid ||
                (valuationPricesFormGroup.pristine && stockPricesFormGroup.pristine && soldPricesFormGroup.pristine)
              "
              (click)="savePricingDetails()"
            >
              {{ 'common.save' | translate }}
            </vpfa-action-button>
            <vpfa-action-button (click)="close()" [outline]="true" [border]="false">
              {{ 'pricingDetailsModal.cancel' | translate }}
            </vpfa-action-button>
          </div>
        </div>
      </nz-spin>
    </form>
  </vpfa-modal-content>
</vpfa-modal>

<ng-template #priceWithTax let-priceModel="priceModel" let-pricesFormGroup="pricesFormGroup">
  <div [fxLayout]="'row'" [fxLayoutAlign]="'space-between center'">
    <div [fxLayout]="'column'" [fxFlex]="labelWidth(priceModel)">
      <div>
        <span class="price-name">{{ priceModel.name | translate }}</span>
        <vpfa-required-symbol *ngIf="controlIsRequired(priceModel.fcName, pricesFormGroup)"></vpfa-required-symbol>
      </div>
      <div *ngIf="priceModel.date && !priceModel.hidePrice" class="price-date-version">
        <span
          >{{ priceModel.date | dateLocale }}, {{ 'common.valuationVersion' | translate }}:
          {{ priceModel.valuationVersion | dateLocale: 'shortDate':null:null:true | lT }}</span
        >
      </div>
    </div>
    <div [fxLayout]="'column'">
      <vpfa-price-with-icon
        [hideIcon]="(!priceModel.history || !priceModel.history.length) && !priceModel.isForecast"
        [color]="getIconColor(priceModel)"
        [price]="!priceModel.hidePrice ? (priceModel.price | currencyLocale: null:null:'1.2-2') : null"
        [isEditable]="priceModel.isEditable"
        [parentFormGroup]="pricesFormGroup"
        [fcName]="priceModel.fcName"
        [errorMessages]="errorMessages"
        [readonlyPrice]="true"
        (iconClicked)="onIconClicked(priceModel)"
      ></vpfa-price-with-icon>
      <vpfa-price-tax
        *ngIf="priceModel.tax !== null && !priceModel.isEditable && !priceModel.hidePrice"
        [caseTax]="activeCaseDataTaxes"
        [tax]="priceModel.tax | currencyLocale: null:null:'1.2-2' | lT"
      ></vpfa-price-tax>
    </div>
  </div>
  <div [ngClass]="{ 'show-history': priceModel.showHistory }">
    <span *ngIf="priceModel.showHistory" class="price-history-label">
      {{ 'pricingDetailsModal.priceHistory' | translate }}
    </span>
    <ng-container *ngIf="priceModel.showHistory">
      <vpfa-price-history-list [caseTax]="activeCaseDataTaxes" [history]="priceModel.history"></vpfa-price-history-list>
      <vpfa-action-button
        class="hide-history-button"
        [outline]="true"
        [border]="false"
        (click)="priceModel.showHistory = false"
      >
        {{ 'common.hide' | translate }}
      </vpfa-action-button>
    </ng-container>
  </div>
  <nz-divider class="pricing-modal-divider"></nz-divider>
</ng-template>
