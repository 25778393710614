import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { NzPopoverDirective } from 'ng-zorro-antd/popover';
import { isNil } from 'lodash';

export type ErrorVerticalMessagePosition =
  | 'inside'
  | 'outside'
  | 'insideDate'
  | 'leftAlign'
  | 'suffixPrefix'
  | 'outsideRight'
  | 'outsideLeft'
  | 'forSelect';

@Component({
  selector: 'vpfa-field-validation-container',
  templateUrl: './field-validation-container.component.html',
  styleUrls: ['./field-validation-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldValidationContainerComponent implements AfterViewInit, OnDestroy {
  intervalId;
  @Input() set isInvalid(isInvalid) {
    this._isInvalid = isInvalid;
    if (isInvalid) {
      this.startPopoverPositionUpdate();
    } else {
      this.stopPopoverPositionUpdate();
    }
  }
  get isInvalid() {
    return this._isInvalid;
  }

  @Input()
  set heightCompensationPx(heightCompensationPx: string | number) {
    if (!isNil(heightCompensationPx)) {
      this._heightCompensationPx = heightCompensationPx;
    }
  }

  @Input() isText: boolean;
  @Input() isNumber: boolean;
  @Input() isCurrency: boolean;

  private _heightCompensationPx: string | number = 0;
  private _isInvalid = false;

  get heightCompensationPx(): string | number {
    return this._heightCompensationPx;
  }

  hostBoundingRect: ClientRect | DOMRect;
  _errorMsgVertPosition: number;

  @Input() set errorMsgVertPosition(position: ErrorVerticalMessagePosition) {
    if (!position) {
      this._errorMsgVertPosition = -25;
      this.changeDetectorRef.markForCheck();
      return;
    }
    switch (position) {
      case 'inside':
        this._errorMsgVertPosition = 5;
        break;
      case 'forSelect':
        this._errorMsgVertPosition = 30;
        break;
      case 'insideDate':
        this._errorMsgVertPosition = 25;
        break;
      case 'outside':
        this._errorMsgVertPosition = 7;
        break;
      case 'leftAlign':
        this._errorMsgVertPosition = 147;
        break;
      case 'outsideLeft':
        this._errorMsgVertPosition = 175;
        break;
      case 'suffixPrefix':
        this._errorMsgVertPosition = 215;
        break;
      case 'outsideRight':
        this._errorMsgVertPosition = -25;
        break;
    }
    this.changeDetectorRef.markForCheck();
  }
  @Input()
  set validationErrors(setValue: ValidationErrors) {
    this._validationErrors = setValue;
    this.popoverContent = this.getPopoverContent();
  }
  @Input() set errorMessages(messages: ReadonlyArray<{ error: string; errorMsg: string }>) {
    this._errorMessages = messages;
    this.popoverContent = this.getPopoverContent();
  }

  @Input() displayErrors = true;

  @ViewChild(NzPopoverDirective, { read: NzPopoverDirective }) tooltipCtrlRef: NzPopoverDirective;
  popoverContent: string = null;
  errorSignTopPos = 3;
  private _errorMessages: ReadonlyArray<{ error: string; errorMsg: string }>;
  private _validationErrors: ValidationErrors;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  getPopoverContent() {
    if (this._validationErrors && this._errorMessages) {
      for (const entry of this._errorMessages) {
        if (this._validationErrors[entry.error]) {
          return entry.errorMsg;
        }
      }
    }
  }

  getClientBoundingRect(rect: ClientRect | DOMRect) {
    if (rect && rect.height !== 0) {
      this.hostBoundingRect = rect;
      this.errorSignTopPos =
        Math.floor(this.hostBoundingRect.height / 2) + parseInt(String(this.heightCompensationPx), 10);
    }
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.stopPopoverPositionUpdate();
  }

  /**
   * Updates popover position in intervals
   * required when validation is done in modal
   */
  private startPopoverPositionUpdate() {
    if (isNil(this.intervalId)) {
      this.intervalId = setInterval(() => {
        this.tooltipCtrlRef.updatePosition();
      }, 100);
    }
  }

  private stopPopoverPositionUpdate() {
    if (!isNil(this.intervalId)) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }
}
