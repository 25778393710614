import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { CountryDto, FeaturesConfigurationDto } from '@vpfa/rest-api/admin';
import { LanguageEnum } from '@vpfa/shared/translate';
import { CsvConfigurationDto } from '@vpfa/rest-api/valuation';

export enum LocaleActionTypes {
  LocaleEffectsInitialized = '[Locale] Effects Initialized',
  SelectContentLanguage = '[Locale] Select Content Language',
  SelectUILanguage = '[Locale] Select UI Language',
  LoadUserCountry = '[Locale] Load User Country',
  CountryLoaded = '[Locale] Country Loaded',
  UserCountryLoadError = '[Locale] User Country Load Error',
  LoadLocale = '[Locale] Load Locale',
  LocaleLoaded = '[Locale] Locale Loaded',
  LoadBranchCountry = '[Locale] Load Branch Country',
  BranchCountryLoadError = '[Locale] Branch Country Load Error',
  CsvConfigurationLoad = '[Locale] Csv Configuration Load ',
  CsvConfigurationLoaded = '[Locale] Csv Configuration Loaded',
  CsvConfigurationLoadError = '[Locale] Csv Configuration Load Error',
  ExperianTermsUrlLoad = '[Locale] Experian Terms Url Load',
  ExperianTermsUrlLoaded = '[Locale] Experian Terms Url Loaded',
  ExperianTermsUrlLoadError = '[Locale] Experian Terms Url Load Error',
  CheckTranslateFile = '[Countries] Check Translate File',
  CheckTranslateFileSuccess = '[Countries] Check Translate File Success',
  CheckTranslateFileError = '[Countries] Check Translate File Error',
  FeatureConfigLoad = '[FeaturesConfig] Load',
  FeatureConfigLoaded = '[FeaturesConfig] Loaded',
  FeatureConfigLoadError = '[FeaturesConfig] Load Error',
}

export class LocaleEffectsInitialized implements Action {
  readonly type = LocaleActionTypes.LocaleEffectsInitialized;
}

export class SelectContentLanguage implements Action {
  readonly type = LocaleActionTypes.SelectContentLanguage;
  constructor(public payload: LanguageEnum) {}
}

export class SelectUILanguage implements Action {
  readonly type = LocaleActionTypes.SelectUILanguage;
  constructor(public payload: LanguageEnum) {}
}

export class LoadUserCountry implements Action {
  readonly type = LocaleActionTypes.LoadUserCountry;
}

export class CountryLoaded implements Action {
  readonly type = LocaleActionTypes.CountryLoaded;
  constructor(public payload: CountryDto) {}
}

export class UserCountryLoadError implements Action {
  readonly type = LocaleActionTypes.UserCountryLoadError;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadLocale implements Action {
  readonly type = LocaleActionTypes.LoadLocale;
  constructor(public payload: string) {}
}

export class LocaleLoaded implements Action {
  readonly type = LocaleActionTypes.LocaleLoaded;
  constructor(public payload: string) {}
}

export class LoadBranchCountry implements Action {
  readonly type = LocaleActionTypes.LoadBranchCountry;
  constructor(public payload: string) {}
}

export class BranchCountryLoadError implements Action {
  readonly type = LocaleActionTypes.BranchCountryLoadError;
  constructor(public payload: HttpErrorResponse) {}
}

export class CsvConfigurationLoad implements Action {
  readonly type = LocaleActionTypes.CsvConfigurationLoad;
}

export class CsvConfigurationLoaded implements Action {
  readonly type = LocaleActionTypes.CsvConfigurationLoaded;
  constructor(public payload: CsvConfigurationDto) {}
}

export class CsvConfigurationLoadError implements Action {
  readonly type = LocaleActionTypes.CsvConfigurationLoadError;
}

export class ExperianTermsUrlLoad implements Action {
  readonly type = LocaleActionTypes.ExperianTermsUrlLoad;
}

export class ExperianTermsUrlLoaded implements Action {
  readonly type = LocaleActionTypes.ExperianTermsUrlLoaded;
  constructor(public payload: string) {}
}

export class ExperianTermsUrlLoadError implements Action {
  readonly type = LocaleActionTypes.ExperianTermsUrlLoadError;
}

export class CheckTranslateFile implements Action {
  readonly type = LocaleActionTypes.CheckTranslateFile;
  constructor(public payload: { language: LanguageEnum; countryCode: string }) {}
}

export class CheckTranslateFileSuccess implements Action {
  readonly type = LocaleActionTypes.CheckTranslateFileSuccess;
}

export class CheckTranslateFileError implements Action {
  readonly type = LocaleActionTypes.CheckTranslateFileError;
  constructor(public payload: { language: LanguageEnum; countryCode: string }) {}
}

export class FeatureConfigLoad implements Action {
  readonly type = LocaleActionTypes.FeatureConfigLoad;
  /**
   *
   * @param payload - countryCode
   */
  constructor(public payload: string) {}
}

export class FeatureConfigLoaded implements Action {
  readonly type = LocaleActionTypes.FeatureConfigLoaded;
  constructor(public payload: FeaturesConfigurationDto & { forCountry: string }) {}
}

export class FeatureConfigLoadError implements Action {
  readonly type = LocaleActionTypes.FeatureConfigLoadError;
  constructor() {}
}

export type LocaleAction =
  | SelectContentLanguage
  | SelectUILanguage
  | LoadUserCountry
  | CountryLoaded
  | UserCountryLoadError
  | LoadLocale
  | LocaleLoaded
  | LoadBranchCountry
  | BranchCountryLoadError
  | CsvConfigurationLoad
  | CsvConfigurationLoaded
  | CsvConfigurationLoadError
  | ExperianTermsUrlLoad
  | ExperianTermsUrlLoaded
  | ExperianTermsUrlLoadError
  | LocaleEffectsInitialized
  | CheckTranslateFile
  | CheckTranslateFileSuccess
  | CheckTranslateFileError
  | FeatureConfigLoad
  | FeatureConfigLoaded
  | FeatureConfigLoadError;

export const fromLocaleActions = {
  SelectContentLanguage,
  SelectUILanguage,
  LoadUserCountry,
  CountryLoaded,
  UserCountryLoadError,
  LoadLocale,
  LocaleLoaded,
  LoadBranchCountry,
  BranchCountryLoadError,
  CsvConfigurationLoad,
  CsvConfigurationLoaded,
  CsvConfigurationLoadError,
  ExperianTermsUrlLoad,
  ExperianTermsUrlLoaded,
  ExperianTermsUrlLoadError,
  LocaleEffectsInitialized,
  CheckTranslateFile,
  CheckTranslateFileSuccess,
  CheckTranslateFileError,
  FeatureConfigLoad,
  FeatureConfigLoaded,
  FeatureConfigLoadError,
};
