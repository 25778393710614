import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vpfa-mobile-edit-mileage',
  templateUrl: './mobile-edit-mileage.component.html',
  styleUrls: ['./mobile-edit-mileage.component.scss'],
})
export class MobileEditMileageComponent {
  @Input() set mileage(mil: number) {
    if (mil) {
      this.initForm(mil);
    }
  }
  @Output() saveAction = new EventEmitter<number>();
  @Output() closeAction = new EventEmitter<void>();

  mileageForm: UntypedFormGroup;
  mileageErrorMessages: ReadonlyArray<{ error: string; errorMsg: string }> = [
    {
      error: 'required',
      errorMsg: this.translate.instant('vehicleOverviewData.mobile.mileageRequired'),
    },
  ];

  constructor(private fb: UntypedFormBuilder, private translate: TranslateService) {
    this.mileageForm = this.fb.group({
      mileage: [null, [Validators.required]],
    });
  }

  initForm(mileage: number) {
    this.mileageForm.reset({
      mileage: mileage,
    });
  }

  onSave() {
    this.saveAction.emit(this.mileageForm.get('mileage').value);
    this.onClose();
  }

  onClose() {
    this.closeAction.emit();
  }
}
