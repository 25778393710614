import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'vpfa-no-files-box',
  templateUrl: './no-files-box.component.html',
  styleUrls: ['./no-files-box.component.scss']
})
export class NoFilesBoxComponent {
  @Input() maxSizeKB: number;
  @Input() uploadPrompt: string;
  @Input() uploadText: string;
  @Output() clicked = new EventEmitter();

}
