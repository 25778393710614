import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { FormatWidth, getLocaleDateFormat } from '@angular/common';
import { LocaleFacade } from '@vpfa/locale';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export type DateFormatWidthStr = 'short' | 'medium' | 'long' | 'full';

@Pipe({
  name: 'localeDateFormat',
  pure: false
})
export class LocaleDateFormatPipe implements PipeTransform, OnDestroy {
  activeLocale: string;
  onDestroy$ = new Subject<void>();
  constructor(localeFacade: LocaleFacade) {
    localeFacade.locale$.pipe(takeUntil(this.onDestroy$)).subscribe(value => (this.activeLocale = value));
  }
  transform(dateFormatWidth: DateFormatWidthStr): string {
    return this.activeLocale ? getLocaleDateFormat(this.activeLocale, stringToFormatWidth(dateFormatWidth)) : null;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}

const stringToFormatWidth = (format: DateFormatWidthStr): FormatWidth => {
  switch (format) {
    case 'short':
      return FormatWidth.Short;
    case 'medium':
      return FormatWidth.Medium;
    case 'long':
      return FormatWidth.Long;
    case 'full':
      return FormatWidth.Full;
  }
};
