<div>
  <div fxLayoutAlign="center center">
    <div style="width: 180px">
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 1420">
        <path
          class="st0"
          d="M1836,1025.8l-15,57.9l-58.8,20c-11.5,76-62.8,133.3-124.3,133.3c-1.6,0-3.3-0.1-4.9-0.1
	c-1.8,0.1-3.5,0.1-5.3,0.1c-52.4,0-98-35.8-121-88.2l-591.2,65.6c0,0-11.3,0.4-30,1.2c-19,71.9-78.8,124.4-149.7,124.4
	c-2,0-4-0.1-6-0.1c-2.2,0.1-4.3,0.1-6.5,0.1c-68.4,0-127.3-42-153.7-102.3c-6.2,0.7-12.8,1.1-19.8,1.4l-1,24.1
	c-63.7-3.6-84.4-7.1-84.4-7.1s-18.5-22.7-20.3-22.9c-25.7,20.6-57.2,32.8-91.3,32.8c-2,0-4-0.1-6-0.1c-2.1,0.1-4.3,0.1-6.5,0.1
	c-51.8,0-98.1-24.1-129.1-61.9c-18.2,0.3-39.5-6.2-40.7-7.1c-0.9-0.6-5.7-12.1-9.4-20.7l-51.7-13.9c-8.1-2.2-12.5-11-9.2-18.8
	l4.1-9.8l-11.6-82.1c-1.2-8.3,0.9-16.8,5.8-23.7l2.2-3.1l-4.6-22.1c-1.3-6.2,0.4-12.7,4.6-17.5l10.7-12.4l4.1-33.5
	c1.6-13.3,7.2-25.9,15.9-36.1l3.2-3.7c12.7-14.9,28.9-26.5,47-33.8l18-7.3c48.5-19.6,99.2-33.3,150.9-40.8L577,785l189.7-135.3
	c19.2-13.7,40.2-24.9,62.2-33.3l44.1-16.7c408.7-93.9,746.5,9.2,815.2,32.8c7.7,2.6,12.8,9.8,12.8,17.9l110.1,138.5
	c5.5,6.9,8.1,15.7,7.2,24.5l-3.9,38.3l8,133.3l9.4,14.5C1836.8,1007.2,1838.3,1016.8,1836,1025.8z"
        />
        <path
          class="st1"
          d="M1836,1025.8l-15,57.9l-58.8,20c-11.5,76-62.8,133.3-124.3,133.3c-1.6,0-3.3-0.1-4.9-0.1
	c-1.8,0.1-3.5,0.1-5.3,0.1c-52.4,0-98-35.8-121-88.2l-591.2,65.6c0,0-11.3,0.4-30,1.2c-19,71.9-78.8,124.4-149.7,124.4
	c-2,0-4-0.1-6-0.1c-2.2,0.1-4.3,0.1-6.5,0.1c-68.4,0-127.3-42-153.7-102.3c-6.2,0.7-12.8,1.1-19.8,1.4l-1,24.1
	c-63.7-3.6-84.4-7.1-84.4-7.1s-18.5-22.7-20.3-22.9c-25.7,20.6-57.2,32.8-91.3,32.8c-2,0-4-0.1-6-0.1c-2.1,0.1-4.3,0.1-6.5,0.1
	c-51.8,0-98.1-24.1-129.1-61.9c-18.2,0.3-39.5-6.2-40.7-7.1c-0.9-0.6-5.7-12.1-9.4-20.7l-51.7-13.9c-8.1-2.2-12.5-11-9.2-18.8
	l4.1-9.8l-11.6-82.1c-1.2-8.3,0.9-16.8,5.8-23.7l2.2-3.1l-4.6-22.1c-1.3-6.2,0.4-12.7,4.6-17.5l10.7-12.4l4.1-33.5
	c1.6-13.3,7.2-25.9,15.9-36.1l3.2-3.7c12.7-14.9,28.9-26.5,47-33.8l18-7.3c48.5-19.6,99.2-33.3,150.9-40.8L577,785l189.7-135.3
	c19.2-13.7,40.2-24.9,62.2-33.3l44.1-16.7c408.7-93.9,746.5,9.2,815.2,32.8c7.7,2.6,12.8,9.8,12.8,17.9l110.1,138.5
	c5.5,6.9,8.1,15.7,7.2,24.5l-3.9,38.3l8,133.3l9.4,14.5C1836.8,1007.2,1838.3,1016.8,1836,1025.8z"
        />
        <path
          class="st2"
          d="M984.2,506.4c-230,0-416.5,186.5-416.5,416.5c0,230,186.5,416.5,416.5,416.5s416.5-186.5,416.5-416.5
	C1400.8,692.9,1214.3,506.4,984.2,506.4z M676,922.9c0-170.2,138-308.2,308.2-308.2c63,0,121.6,18.9,170.4,51.3l-427.2,427.3
	C694.9,1044.5,676,985.9,676,922.9z M984.2,1231.1c-63,0-121.6-18.9-170.4-51.3l427.2-427.2c32.4,48.8,51.3,107.4,51.3,170.4
	C1292.5,1093.1,1154.5,1231.1,984.2,1231.1z"
        />
        <path
          class="st1"
          d="M984.2,1343.9c-56.8,0-112-11.1-163.9-33.1c-50.1-21.2-95.2-51.6-133.8-90.2c-38.7-38.7-69-83.7-90.2-133.8
	c-22-51.9-33.1-107-33.1-163.9c0-56.8,11.1-112,33.1-163.9c21.2-50.1,51.6-95.2,90.2-133.8c38.7-38.7,83.7-69,133.8-90.2
	c51.9-22,107-33.1,163.9-33.1c56.8,0,112,11.1,163.9,33.1c50.1,21.2,95.2,51.6,133.8,90.2c38.7,38.7,69,83.7,90.2,133.8
	c22,51.9,33.1,107,33.1,163.9c0,56.8-11.1,112-33.1,163.9c-21.2,50.1-51.6,95.2-90.2,133.8c-38.7,38.7-83.7,69-133.8,90.2
	C1096.2,1332.8,1041.1,1343.9,984.2,1343.9z M984.2,510.9c-55.6,0-109.6,10.9-160.4,32.4c-49.1,20.8-93.1,50.5-131,88.3
	c-37.8,37.8-67.5,81.9-88.3,131c-21.5,50.8-32.4,104.7-32.4,160.4c0,55.6,10.9,109.6,32.4,160.4c20.8,49.1,50.5,93.1,88.3,131
	c37.8,37.8,81.9,67.5,131,88.3c50.8,21.5,104.7,32.4,160.4,32.4s109.6-10.9,160.4-32.4c49.1-20.8,93.1-50.5,131-88.3
	c37.8-37.8,67.5-81.9,88.3-131c21.5-50.8,32.4-104.7,32.4-160.4c0-55.6-10.9-109.6-32.4-160.4c-20.8-49.1-50.5-93.1-88.3-131
	c-37.8-37.8-81.9-67.5-131-88.3C1093.8,521.8,1039.9,510.9,984.2,510.9z M984.2,1235.6c-61.8,0-121.6-18-172.9-52.1l-4.6-3l435-435
	l3,4.6c34.1,51.3,52.1,111.1,52.1,172.9c0,83.5-32.5,162.1-91.6,221.1C1146.3,1203.1,1067.8,1235.6,984.2,1235.6z M821,1179
	c48.7,31.1,105.1,47.6,163.3,47.6c81.1,0,157.4-31.6,214.8-89c57.4-57.4,89-133.6,89-214.8c0-58.2-16.4-114.5-47.6-163.3L821,1179z
	 M726.7,1100.4l-3-4.6c-34.1-51.3-52.1-111-52.1-172.9c0-83.5,32.5-162.1,91.6-221.1c59.1-59.1,137.6-91.6,221.1-91.6
	c61.8,0,121.6,18,172.9,52.1l4.6,3L726.7,1100.4z M984.2,619.2c-81.1,0-157.4,31.6-214.8,89c-57.4,57.4-89,133.6-89,214.8
	c0,58.2,16.4,114.5,47.6,163.3l419.4-419.4C1098.8,635.6,1042.4,619.2,984.2,619.2z"
        />
      </svg>
    </div>
  </div>
</div>
<div fxLayoutAlign="center center">
  <div class="content-box">
    <ng-content></ng-content>
  </div>
</div>
