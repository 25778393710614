import { Input, Output, EventEmitter, Directive } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ProvenanceCheckDetailsDto, ProvenanceCheckStatus } from '@vpfa/rest-api/valuation';
import { camelCase, isNil } from 'lodash/fp';

@Directive()
export class BaseProvenanceCheckSectionComponent {
  ProvenanceCheckStatuses = ProvenanceCheckStatus;

  @Input() provenanceCheckData: ProvenanceCheckDetailsDto;
  @Input() isLoading = false;
  @Input() termsUrl: string;
  @Input() canCheck = true;
  @Output() check = new EventEmitter();

  termsFormGroup = new UntypedFormGroup({
    terms: new UntypedFormControl(false),
  });

  onCheckClick() {
    this.check.emit();
  }

  get status(): ProvenanceCheckStatus {
    return this.provenanceCheckData ? this.provenanceCheckData.status : ProvenanceCheckStatus.NotChecked;
  }

  get statusMessageKey(): string {
    return !isNil(ProvenanceCheckStatus[this.status])
      ? `provenanceCheck.statuses.${camelCase(ProvenanceCheckStatus[this.status])}`
      : '';
  }

  get statusClass(): string {
    switch (this.status) {
      case ProvenanceCheckStatus.AllClear:
        return `all-clear`;
      case ProvenanceCheckStatus.Caution:
        return `caution`;
      case ProvenanceCheckStatus.Warning:
        return `warning`;
      default:
        return '';
    }
  }

  get buttonLabelKey(): string {
    return `provenanceCheck.${!this.provenanceCheckData ? 'check' : 'recheck'}`;
  }

  get checkButtonDisable(): boolean {
    return !this.termsFormGroup?.get('terms')?.value || this.isLoading;
  }
}
