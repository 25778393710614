import { HttpParams } from '@angular/common/http';
import { isNil } from 'lodash';
import { OptionalSearchFiltersKeys, SelectedOptionalSearchFilters } from '../models/optional-search-filters';
import { MandatorySearchFilters } from '../models/mandatory-search-filters';

export const makesListParams = (typeCodes: number[], countryCode: string): HttpParams => {
  return new HttpParams({
    fromObject: {
      key: formatCountryVehicleTypeParams(typeCodes, countryCode),
    },
  });
};

export const vehiclesListParams = (
  pageSize: number,
  mandatorySearchFilters: MandatorySearchFilters,
  optionalSearchFilters?: SelectedOptionalSearchFilters
): HttpParams => {
  let params: HttpParams = new HttpParams({
    fromObject: {
      pageSize: `${pageSize}`,
      searchValues: `${Object.keys(OptionalSearchFiltersKeys).join()}`,
    },
  });

  if (!isNil(mandatorySearchFilters)) {
    params = mapSearchFiltersToParams(mandatorySearchFilters, params, true);
  }

  if (!isNil(optionalSearchFilters)) {
    params = mapSearchFiltersToParams(optionalSearchFilters, params);
  }

  return params;
};

const formatDateParam = (date: string): string => {
  if (date.includes('-')) {
    return date.split('-').join('');
  }
  return date;
};

const formatCountryVehicleTypeParams = (typeCodes: number[], countryCode: string) => {
  return typeCodes.map(typeCode => `${countryCode}.${typeCode}`).join();
};

const mapSearchFiltersToParams = (searchFilters: any, params: HttpParams, isDateParam = false): HttpParams => {
  Object.entries(searchFilters)
    .filter(([key, value]) => !isNil(value))
    .map(([key, value]) => ({ key, value }))
    .forEach((filter: { key; value }) => {
      let value: string;

      if (isDateParam) {
        value = formatDateParam(filter.value);
      } else if (filter.key === OptionalSearchFiltersKeys.edition) {
        value = `"${filter.value}"`;
      } else {
        value = `${filter.value}`;
      }

      // TODO: SorC returns filter param 'wheelbase' but accepts param 'wheelBase'.
      //  This is a temporary workaround until SorC resolves this issue
      let key: string = filter.key;
      if (key === OptionalSearchFiltersKeys.wheelbase) {
        key = 'wheelBase';
      }

      params = params.append(key, value);
    });
  return params;
};
