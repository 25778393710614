/**
 * Public SSM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0-private
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { CommandHandlerResponse } from '../model/commandHandlerResponse';
import { RepairEstimateProfileBusinessBody } from '../model/repairEstimateProfileBusinessBody';
import { RepairEstimateProfileBusinessBody1 } from '../model/repairEstimateProfileBusinessBody1';
import { RepairEstimateProfileBusinessBody2 } from '../model/repairEstimateProfileBusinessBody2';
import { RepairEstimateProfileBusinessBody3 } from '../model/repairEstimateProfileBusinessBody3';
import { RepairEstimateProfileBusinessBody4 } from '../model/repairEstimateProfileBusinessBody4';
import { RepairEstimateProfileBusinessBody5 } from '../model/repairEstimateProfileBusinessBody5';
import { RepairEstimateProfileBusinessBody6 } from '../model/repairEstimateProfileBusinessBody6';
import { RepairEstimateProfileBusinessBody7 } from '../model/repairEstimateProfileBusinessBody7';
import { RepairEstimateProfileCountryBody } from '../model/repairEstimateProfileCountryBody';
import { RepairEstimateProfileCountryBody1 } from '../model/repairEstimateProfileCountryBody1';
import { RepairEstimateProfileCountryBody2 } from '../model/repairEstimateProfileCountryBody2';
import { RepairEstimateProfileCountryBody3 } from '../model/repairEstimateProfileCountryBody3';
import { RepairEstimateProfileCountryBody4 } from '../model/repairEstimateProfileCountryBody4';
import { RepairEstimateProfileCountryBody5 } from '../model/repairEstimateProfileCountryBody5';
import { RepairEstimateProfileCountryBody6 } from '../model/repairEstimateProfileCountryBody6';
import { RepairEstimateProfileCountryBody7 } from '../model/repairEstimateProfileCountryBody7';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class RepairEstimateProfileService {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Lazy check AmsAction:POST/api/RepairEstimateProfile/country AmsResource:{country}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBaseEstimateProfile(body?: RepairEstimateProfileCountryBody4, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public createBaseEstimateProfile(body?: RepairEstimateProfileCountryBody4, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public createBaseEstimateProfile(body?: RepairEstimateProfileCountryBody4, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public createBaseEstimateProfile(body?: RepairEstimateProfileCountryBody4, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommandHandlerResponse>(`${this.basePath}/api/RepairEstimateProfile/country`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:POST/api/RepairEstimateProfile/business AmsResource:{country}/{business}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCustomEstimateProfile(body?: RepairEstimateProfileBusinessBody4, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public createCustomEstimateProfile(body?: RepairEstimateProfileBusinessBody4, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public createCustomEstimateProfile(body?: RepairEstimateProfileBusinessBody4, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public createCustomEstimateProfile(body?: RepairEstimateProfileBusinessBody4, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommandHandlerResponse>(`${this.basePath}/api/RepairEstimateProfile/business`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:DELETE/api/RepairEstimateProfile/country/{countryId}/{repairEstimateProfileId} AmsResource:{country}/_* 
     * @param countryId 
     * @param repairEstimateProfileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBaseEstimateProfile(countryId: string, repairEstimateProfileId: string, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public deleteBaseEstimateProfile(countryId: string, repairEstimateProfileId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public deleteBaseEstimateProfile(countryId: string, repairEstimateProfileId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public deleteBaseEstimateProfile(countryId: string, repairEstimateProfileId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (countryId === null || countryId === undefined) {
            throw new Error('Required parameter countryId was null or undefined when calling deleteBaseEstimateProfile.');
        }

        if (repairEstimateProfileId === null || repairEstimateProfileId === undefined) {
            throw new Error('Required parameter repairEstimateProfileId was null or undefined when calling deleteBaseEstimateProfile.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<CommandHandlerResponse>(`${this.basePath}/api/RepairEstimateProfile/country/${encodeURIComponent(String(countryId))}/${encodeURIComponent(String(repairEstimateProfileId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a repair estimate profile that the dealer has access it  If the profile was created by the business then it is possible to delete it.  If the user attempts to delete a base profile, then it will only be hidden from them - for this reason the business id is required.
     * Lazy check AmsAction:DELETE/api/RepairEstimateProfile/business/{businessId}/{repairEstimateProfileId} AmsResource:{country}/{business}/_* 
     * @param businessId 
     * @param repairEstimateProfileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteEstimateProfile(businessId: string, repairEstimateProfileId: string, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public deleteEstimateProfile(businessId: string, repairEstimateProfileId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public deleteEstimateProfile(businessId: string, repairEstimateProfileId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public deleteEstimateProfile(businessId: string, repairEstimateProfileId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling deleteEstimateProfile.');
        }

        if (repairEstimateProfileId === null || repairEstimateProfileId === undefined) {
            throw new Error('Required parameter repairEstimateProfileId was null or undefined when calling deleteEstimateProfile.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<CommandHandlerResponse>(`${this.basePath}/api/RepairEstimateProfile/business/${encodeURIComponent(String(businessId))}/${encodeURIComponent(String(repairEstimateProfileId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/RepairEstimateProfile/country AmsResource:{country}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBaseEstimateProfile(body?: RepairEstimateProfileCountryBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateBaseEstimateProfile(body?: RepairEstimateProfileCountryBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateBaseEstimateProfile(body?: RepairEstimateProfileCountryBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateBaseEstimateProfile(body?: RepairEstimateProfileCountryBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/RepairEstimateProfile/country`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/RepairEstimateProfile/business AmsResource:{country}/{business}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateEstimateProfile(body?: RepairEstimateProfileBusinessBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateEstimateProfile(body?: RepairEstimateProfileBusinessBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateEstimateProfile(body?: RepairEstimateProfileBusinessBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateEstimateProfile(body?: RepairEstimateProfileBusinessBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/RepairEstimateProfile/business`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
