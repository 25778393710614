/**
 * Generic Transform Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 

export enum ErrorCode {

    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7,
    NUMBER_8 = 8,
    NUMBER_9 = 9,
    NUMBER_10 = 10,
    NUMBER_11 = 11,
    NUMBER_12 = 12,
    NUMBER_13 = 13,
    NUMBER_14 = 14,
    NUMBER_15 = 15,
    NUMBER_16 = 16,
    NUMBER_17 = 17,
    NUMBER_18 = 18,
    NUMBER_19 = 19,
    NUMBER_20 = 20,
    NUMBER_21 = 21,
    NUMBER_22 = 22,
    NUMBER_23 = 23,
    NUMBER_24 = 24,
    NUMBER_25 = 25,
    NUMBER_26 = 26,
    NUMBER_27 = 27,
    NUMBER_28 = 28,
    NUMBER_29 = 29,
    NUMBER_30 = 30,
    NUMBER_31 = 31,
    NUMBER_32 = 32,
    NUMBER_33 = 33,
    NUMBER_34 = 34,
    NUMBER_35 = 35,
    NUMBER_36 = 36,
    NUMBER_37 = 37,
    NUMBER_38 = 38,
    NUMBER_39 = 39,
    NUMBER_40 = 40,
    NUMBER_41 = 41,
    NUMBER_42 = 42,
    NUMBER_43 = 43,
    NUMBER_44 = 44,
    NUMBER_45 = 45,
    NUMBER_46 = 46,
    NUMBER_47 = 47,
    NUMBER_48 = 48,
    NUMBER_49 = 49,
    NUMBER_50 = 50,
    NUMBER_51 = 51,
    NUMBER_52 = 52,
    NUMBER_53 = 53,
    NUMBER_54 = 54,
    NUMBER_55 = 55,
    NUMBER_56 = 56,
    NUMBER_57 = 57,
    NUMBER_58 = 58,
    NUMBER_59 = 59,
    NUMBER_60 = 60,
    NUMBER_61 = 61,
    NUMBER_62 = 62,
    NUMBER_63 = 63,
    NUMBER_64 = 64,
    NUMBER_65 = 65,
    NUMBER_66 = 66,
    NUMBER_67 = 67,
    NUMBER_68 = 68,
    NUMBER_69 = 69,
    NUMBER_70 = 70,
    NUMBER_71 = 71,
    NUMBER_72 = 72,
    NUMBER_73 = 73,
    NUMBER_74 = 74,
    NUMBER_75 = 75,
    NUMBER_76 = 76,
    NUMBER_77 = 77,
    NUMBER_78 = 78,
    NUMBER_79 = 79,
    NUMBER_80 = 80,
    NUMBER_81 = 81,
    NUMBER_82 = 82,
    NUMBER_83 = 83,
    NUMBER_84 = 84,
    NUMBER_85 = 85,
    NUMBER_86 = 86,
    NUMBER_87 = 87,
    NUMBER_88 = 88,
    NUMBER_89 = 89,
    NUMBER_90 = 90,
    NUMBER_91 = 91,
    NUMBER_92 = 92,
    NUMBER_93 = 93,
    NUMBER_94 = 94,
    NUMBER_95 = 95,
    NUMBER_96 = 96,
    NUMBER_97 = 97,
    NUMBER_98 = 98,
    NUMBER_99 = 99,
    NUMBER_100 = 100,
    NUMBER_101 = 101,
    NUMBER_102 = 102,
    NUMBER_103 = 103,
    NUMBER_104 = 104,
    NUMBER_105 = 105,
    NUMBER_106 = 106,
    NUMBER_107 = 107,
    NUMBER_108 = 108,
    NUMBER_109 = 109,
    NUMBER_110 = 110,
    NUMBER_111 = 111,
    NUMBER_112 = 112,
    NUMBER_113 = 113,
    NUMBER_114 = 114,
    NUMBER_115 = 115,
    NUMBER_116 = 116,
    NUMBER_117 = 117,
    NUMBER_118 = 118,
    NUMBER_119 = 119,
    NUMBER_120 = 120,
    NUMBER_121 = 121,
    NUMBER_122 = 122,
    NUMBER_123 = 123,
    NUMBER_124 = 124,
    NUMBER_125 = 125,
    NUMBER_126 = 126,
    NUMBER_127 = 127,
    NUMBER_128 = 128,
    NUMBER_129 = 129,
    NUMBER_130 = 130,
    NUMBER_131 = 131,
    NUMBER_132 = 132,
    NUMBER_133 = 133,
    NUMBER_134 = 134,
    NUMBER_135 = 135,
    NUMBER_136 = 136,
    NUMBER_137 = 137,
    NUMBER_138 = 138,
    NUMBER_139 = 139,
    NUMBER_140 = 140,
    NUMBER_141 = 141,
    NUMBER_142 = 142,
    NUMBER_143 = 143,
    NUMBER_144 = 144,
    NUMBER_145 = 145,
    NUMBER_146 = 146,
    NUMBER_147 = 147,
    NUMBER_148 = 148,
    NUMBER_149 = 149,
    NUMBER_150 = 150,
    NUMBER_151 = 151,
    NUMBER_152 = 152,
    NUMBER_153 = 153,
    NUMBER_154 = 154,
    NUMBER_155 = 155,
    NUMBER_156 = 156,
    NUMBER_157 = 157,
    NUMBER_158 = 158,
    NUMBER_159 = 159,
    NUMBER_160 = 160,
    NUMBER_161 = 161,
    NUMBER_162 = 162,
    NUMBER_163 = 163,
    NUMBER_164 = 164,
    NUMBER_165 = 165,
    NUMBER_166 = 166,
    NUMBER_167 = 167,
    NUMBER_168 = 168,
    NUMBER_169 = 169,
    NUMBER_170 = 170,
    NUMBER_171 = 171,
    NUMBER_172 = 172,
    NUMBER_173 = 173,
    NUMBER_174 = 174,
    NUMBER_175 = 175,
    NUMBER_176 = 176,
    NUMBER_177 = 177,
    NUMBER_178 = 178,
    NUMBER_179 = 179,
    NUMBER_180 = 180,
    NUMBER_181 = 181,
    NUMBER_182 = 182,
    NUMBER_183 = 183,
    NUMBER_184 = 184,
    NUMBER_185 = 185,
    NUMBER_186 = 186,
    NUMBER_187 = 187,
    NUMBER_188 = 188,
    NUMBER_189 = 189,
    NUMBER_190 = 190,
    NUMBER_191 = 191,
    NUMBER_192 = 192,
    NUMBER_193 = 193,
    NUMBER_194 = 194,
    NUMBER_195 = 195,
    NUMBER_196 = 196,
    NUMBER_197 = 197,
    NUMBER_198 = 198,
    NUMBER_199 = 199,
    NUMBER_200 = 200,
    NUMBER_201 = 201,
    NUMBER_202 = 202,
    NUMBER_203 = 203,
    NUMBER_204 = 204,
    NUMBER_205 = 205,
    NUMBER_206 = 206,
    NUMBER_207 = 207,
    NUMBER_208 = 208,
    NUMBER_209 = 209,
    NUMBER_210 = 210,
    NUMBER_211 = 211,
    NUMBER_212 = 212,
    NUMBER_213 = 213,
    NUMBER_214 = 214,
    NUMBER_215 = 215,
    NUMBER_216 = 216,
    NUMBER_217 = 217,
    NUMBER_218 = 218,
    NUMBER_219 = 219,
    NUMBER_220 = 220,
    NUMBER_221 = 221,
    NUMBER_222 = 222,
    NUMBER_223 = 223,
    NUMBER_224 = 224,
    NUMBER_225 = 225,
    NUMBER_226 = 226,
    NUMBER_227 = 227,
    NUMBER_228 = 228,
    NUMBER_229 = 229,
    NUMBER_230 = 230,
    NUMBER_231 = 231,
    NUMBER_232 = 232,
    NUMBER_233 = 233,
    NUMBER_234 = 234,
    NUMBER_235 = 235,
    NUMBER_236 = 236,
    NUMBER_237 = 237,
    NUMBER_238 = 238,
    NUMBER_239 = 239,
    NUMBER_240 = 240,
    NUMBER_241 = 241,
    NUMBER_242 = 242,
    NUMBER_243 = 243,
    NUMBER_244 = 244,
    NUMBER_245 = 245,
    NUMBER_246 = 246,
    NUMBER_247 = 247,
    NUMBER_248 = 248,
    NUMBER_249 = 249,
    NUMBER_250 = 250,
    NUMBER_251 = 251,
    NUMBER_252 = 252,
    NUMBER_253 = 253,
    NUMBER_254 = 254,
    NUMBER_255 = 255,
    NUMBER_256 = 256,
    NUMBER_257 = 257,
    NUMBER_258 = 258,
    NUMBER_259 = 259,
    NUMBER_260 = 260,
    NUMBER_261 = 261,
    NUMBER_262 = 262,
    NUMBER_263 = 263,
    NUMBER_264 = 264,
    NUMBER_265 = 265,
    NUMBER_266 = 266,
    NUMBER_267 = 267,
    NUMBER_268 = 268,
    NUMBER_269 = 269,
    NUMBER_270 = 270,
    NUMBER_271 = 271,
    NUMBER_272 = 272,
    NUMBER_273 = 273,
    NUMBER_274 = 274,
    NUMBER_275 = 275,
    NUMBER_276 = 276,
    NUMBER_277 = 277,
    NUMBER_278 = 278,
    NUMBER_279 = 279,
    NUMBER_280 = 280,
    NUMBER_281 = 281,
    NUMBER_282 = 282,
    NUMBER_283 = 283,
    NUMBER_284 = 284,
    NUMBER_285 = 285,
    NUMBER_286 = 286,
    NUMBER_287 = 287,
    NUMBER_288 = 288,
    NUMBER_289 = 289,
    NUMBER_290 = 290,
    NUMBER_291 = 291,
    NUMBER_292 = 292,
    NUMBER_293 = 293,
    NUMBER_294 = 294,
    NUMBER_295 = 295,
    NUMBER_296 = 296,
    NUMBER_297 = 297,
    NUMBER_298 = 298,
    NUMBER_299 = 299,
    NUMBER_300 = 300,
    NUMBER_301 = 301,
    NUMBER_302 = 302,
    NUMBER_303 = 303,
    NUMBER_304 = 304,
    NUMBER_305 = 305,
    NUMBER_306 = 306,
    NUMBER_307 = 307,
    NUMBER_308 = 308,
    NUMBER_309 = 309,
    NUMBER_310 = 310,
    NUMBER_311 = 311,
    NUMBER_312 = 312,
    NUMBER_313 = 313,
    NUMBER_314 = 314,
    NUMBER_315 = 315,
    NUMBER_316 = 316,
    NUMBER_317 = 317,
    NUMBER_318 = 318,
    NUMBER_319 = 319,
    NUMBER_320 = 320,
    NUMBER_321 = 321,
    NUMBER_322 = 322,
    NUMBER_323 = 323,
    NUMBER_324 = 324,
    NUMBER_325 = 325,
    NUMBER_326 = 326,
    NUMBER_327 = 327,
    NUMBER_328 = 328,
    NUMBER_329 = 329,
    NUMBER_330 = 330,
    NUMBER_331 = 331,
    NUMBER_332 = 332,
    NUMBER_333 = 333,
    NUMBER_334 = 334,
    NUMBER_335 = 335,
    NUMBER_336 = 336,
    NUMBER_337 = 337,
    NUMBER_338 = 338,
    NUMBER_339 = 339,
    NUMBER_340 = 340,
    NUMBER_341 = 341,
    NUMBER_342 = 342,
    NUMBER_343 = 343,
    NUMBER_344 = 344,
    NUMBER_345 = 345,
    NUMBER_346 = 346,
    NUMBER_347 = 347,
    NUMBER_348 = 348,
    NUMBER_349 = 349,
    NUMBER_350 = 350,
    NUMBER_351 = 351,
    NUMBER_352 = 352,
    NUMBER_353 = 353,
    NUMBER_354 = 354,
    NUMBER_355 = 355,
    NUMBER_356 = 356,
    NUMBER_357 = 357,
    NUMBER_358 = 358,
    NUMBER_359 = 359,
    NUMBER_360 = 360,
    NUMBER_361 = 361,
    NUMBER_362 = 362,
    NUMBER_363 = 363,
    NUMBER_364 = 364,
    NUMBER_365 = 365,
    NUMBER_366 = 366,
    NUMBER_367 = 367,
    NUMBER_368 = 368,
    NUMBER_369 = 369,
    NUMBER_370 = 370,
    NUMBER_371 = 371,
    NUMBER_372 = 372,
    NUMBER_373 = 373,
    NUMBER_374 = 374,
    NUMBER_375 = 375,
    NUMBER_376 = 376,
    NUMBER_377 = 377,
    NUMBER_378 = 378,
    NUMBER_379 = 379,
    NUMBER_380 = 380,
    NUMBER_381 = 381,
    NUMBER_382 = 382,
    NUMBER_383 = 383,
    NUMBER_384 = 384,
    NUMBER_385 = 385,
    NUMBER_386 = 386,
    NUMBER_387 = 387,
    NUMBER_388 = 388,
    NUMBER_389 = 389,
    NUMBER_390 = 390,
    NUMBER_391 = 391,
    NUMBER_392 = 392,
    NUMBER_393 = 393,
    NUMBER_394 = 394,
    NUMBER_395 = 395,
    NUMBER_396 = 396,
    NUMBER_397 = 397,
    NUMBER_398 = 398,
    NUMBER_399 = 399,
    NUMBER_400 = 400,
    NUMBER_401 = 401,
    NUMBER_402 = 402,
    NUMBER_403 = 403,
    NUMBER_404 = 404,
    NUMBER_405 = 405,
    NUMBER_406 = 406,
    NUMBER_407 = 407,
    NUMBER_408 = 408,
    NUMBER_409 = 409,
    NUMBER_410 = 410,
    NUMBER_411 = 411,
    NUMBER_412 = 412,
    NUMBER_413 = 413,
    NUMBER_414 = 414,
    NUMBER_415 = 415,
    NUMBER_416 = 416,
    NUMBER_417 = 417,
    NUMBER_418 = 418,
    NUMBER_419 = 419,
    NUMBER_420 = 420,
    NUMBER_421 = 421,
    NUMBER_422 = 422,
    NUMBER_423 = 423,
    NUMBER_424 = 424,
    NUMBER_425 = 425,
    NUMBER_426 = 426,
    NUMBER_427 = 427,
    NUMBER_1500 = 1500,
    NUMBER_1501 = 1501,
    NUMBER_1502 = 1502,
    NUMBER_1503 = 1503,
    NUMBER_1504 = 1504,
    NUMBER_1700 = 1700,
    NUMBER_1701 = 1701,
    NUMBER_2000 = 2000,
    NUMBER_2001 = 2001,
    NUMBER_2002 = 2002,
    NUMBER_2100 = 2100,
    NUMBER_2101 = 2101,
    NUMBER_2400 = 2400,
    NUMBER_2700 = 2700,
    NUMBER_2900 = 2900,
    NUMBER_3000 = 3000,
    NUMBER_3001 = 3001,
    NUMBER_3002 = 3002,
    NUMBER_3003 = 3003,
    NUMBER_3004 = 3004,
    NUMBER_3500 = 3500,
    NUMBER_3600 = 3600,
    NUMBER_3700 = 3700,
    NUMBER_3900 = 3900,
    NUMBER_4000 = 4000,
    NUMBER_4100 = 4100,
    NUMBER_4500 = 4500,
    NUMBER_4501 = 4501,
    NUMBER_4900 = 4900,
    NUMBER_4901 = 4901,
    NUMBER_6500 = 6500,
    NUMBER_6700 = 6700,
    NUMBER_6900 = 6900,
    NUMBER_6901 = 6901,
    NUMBER_7000 = 7000,
    NUMBER_7001 = 7001,
    NUMBER_7100 = 7100,
    NUMBER_7101 = 7101,
    NUMBER_7200 = 7200,
    NUMBER_7700 = 7700,
    NUMBER_7701 = 7701,
    NUMBER_9000 = 9000,
    NUMBER_9001 = 9001,
    NUMBER_9002 = 9002,
    NUMBER_9003 = 9003,
    NUMBER_9004 = 9004,
    NUMBER_9005 = 9005,
    NUMBER_9006 = 9006,
    NUMBER_9700 = 9700,
    NUMBER_9701 = 9701,
    NUMBER_9900 = 9900,
    NUMBER_9901 = 9901,
    NUMBER_9902 = 9902,
    NUMBER_9903 = 9903,
    NUMBER_9904 = 9904,
    NUMBER_10000 = 10000,
    NUMBER_10001 = 10001,
    NUMBER_10002 = 10002,
    NUMBER_10003 = 10003,
    NUMBER_10004 = 10004,
    NUMBER_10005 = 10005,
    NUMBER_10006 = 10006,
    NUMBER_10007 = 10007,
    NUMBER_10008 = 10008,
    NUMBER_10009 = 10009,
    NUMBER_10010 = 10010,
    NUMBER_10011 = 10011,
    NUMBER_11001 = 11001,
    NUMBER_11009 = 11009,
    NUMBER_11010 = 11010,
    NUMBER_11011 = 11011,
    NUMBER_11012 = 11012,
    NUMBER_11013 = 11013,
    NUMBER_11100 = 11100,
    NUMBER_11603 = 11603,
    NUMBER_11900 = 11900,
    NUMBER_11908 = 11908,
    NUMBER_12002 = 12002,
    NUMBER_12003 = 12003,
    NUMBER_12004 = 12004,
    NUMBER_12005 = 12005,
    NUMBER_12006 = 12006,
    NUMBER_12100 = 12100,
    NUMBER_12700 = 12700,
    NUMBER_12701 = 12701,
    NUMBER_12900 = 12900,
    NUMBER_12901 = 12901,
    NUMBER_13500 = 13500,
    NUMBER_13600 = 13600,
    NUMBER_13601 = 13601,
    NUMBER_13700 = 13700,
    NUMBER_13701 = 13701,
    NUMBER_14500 = 14500,
    NUMBER_15000 = 15000,
    NUMBER_15001 = 15001,
    NUMBER_15002 = 15002,
    NUMBER_15900 = 15900,
    NUMBER_15908 = 15908,
    NUMBER_16100 = 16100,
    NUMBER_16101 = 16101,
    NUMBER_16700 = 16700,
    NUMBER_16900 = 16900,
    NUMBER_16901 = 16901,
    NUMBER_17001 = 17001,
    NUMBER_17002 = 17002,
    NUMBER_17003 = 17003,
    NUMBER_17004 = 17004,
    NUMBER_17005 = 17005,
    NUMBER_17009 = 17009,
    NUMBER_17010 = 17010,
    NUMBER_17011 = 17011,
    NUMBER_17900 = 17900,
    NUMBER_17908 = 17908,

};
