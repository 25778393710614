import { Input, Component } from '@angular/core';
import { CasesFacade } from '@vpfa/dealer/case/data';
@Component({template: ''})
export abstract class BaseRepairAndMaintenanceSummary {
  @Input() caseId: string;
  @Input() notAvailable: boolean;
  @Input() minorTotal: number;
  @Input() majorTotal: number;
  @Input() hideRepairEstimateCost = false;
  @Input() isVinEreDataLoading: boolean;

  lightDamageEstimatorAreas$ = this.casesFacade.lightDamageEstimatorAreas$;
  isSavingDamageEstimator$ = this.casesFacade.isLightDamageEstimatorSaving$;
  isLoadingDamageEstimator$ = this.casesFacade.isLightDamageEstimatorLoading$;

  protected constructor(protected casesFacade: CasesFacade) {}
}
