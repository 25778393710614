import { createAction, props } from '@ngrx/store';
import {
  EquipmentFormulaBlockDto,
  EquipmentDto,
  EquipmentStateChange,
  UpdateIdentifiedVehicleEquipmentCommandHandlerResponse,
  EquipmentErrorEquipmentErrorDto,
} from '@vpfa/rest-api/valuation';
import { VehicleEquipmentFormula } from '../../models/vehicle-equipment-formula';
import { VehicleEquipmentFormulaStep } from '../../models/vehicle-equipment-formula-step';

const prefix = '[VehicleEquipment][FormulaWizard]';

export const startFormulaWizard = createAction(
  `${prefix} Start formula wizard`,
  props<{
    payload: { caseId: string; vehicleId: string; mainEquipmentSoaCode: string; steps: EquipmentFormulaBlockDto[] };
  }>()
);

export const clearFormulaWizard = createAction(`${prefix} Clear formula wizard`);

export const loadTempEqpList = createAction(`${prefix} Load temp eqp list`);

export const loadTempEqpListSuccess = createAction(
  `${prefix} Load temp eqp list success`,
  props<{ equipments: EquipmentDto[] }>()
);

export const loadTempEqpListFailed = createAction(`${prefix} Load temp eqp list failed`, props<{ error: any }>());

export interface ValidateStepProps {
  mainChanges: EquipmentStateChange[];
  sideChanges: VehicleEquipmentFormulaStep['sideChanges'];
  ignoreValidationErrors: boolean;
  force: boolean;
}

export const validateStep = createAction(`${prefix} Validate step`, props<ValidateStepProps>());

export const validateStepSuccess = createAction(
  `${prefix} Validate step success`,
  props<{
    mainChanges: EquipmentStateChange[];
    sideChanges: VehicleEquipmentFormulaStep['sideChanges'];
    stepIndex: number;
    formulaIndex: number;
    incompleteFormulas: { mainEquipmentSoaCode: string; steps: EquipmentFormulaBlockDto[] }[];
    force: boolean;
    errors?: UpdateIdentifiedVehicleEquipmentCommandHandlerResponse['equipmentErrors'];
  }>()
);

/**
 * Go to next step
 */
export const nextStep = createAction(
  `${prefix} Next step`,
  props<{
    mainChanges: EquipmentStateChange[];
    sideChanges: VehicleEquipmentFormulaStep['sideChanges'];
    stepIndex: number;
    formulaIndex: number;
    force: boolean;
    errors?: UpdateIdentifiedVehicleEquipmentCommandHandlerResponse['equipmentErrors'];
  }>()
);

export interface UpdateFormulaStructureProps {
  mainChanges: EquipmentStateChange[];
  sideChanges: VehicleEquipmentFormulaStep['sideChanges'];
  stepIndex: number;
  formulaIndex: number;
  incompleteFormulas: { mainEquipmentSoaCode: string; steps: EquipmentFormulaBlockDto[] }[];
  force: boolean;
  errors?: UpdateIdentifiedVehicleEquipmentCommandHandlerResponse['equipmentErrors'];
}

export const updateFormulasStructure = createAction(
  `${prefix} Update formulas structure`,
  props<UpdateFormulaStructureProps>()
);

export const validateStepErrors = createAction(
  `${prefix} Validate step errors`,
  props<{
    errors: UpdateIdentifiedVehicleEquipmentCommandHandlerResponse['equipmentErrors'];
    mainChanges: EquipmentStateChange[];
  }>()
);

export const validateStepFailed = createAction(`${prefix} Validate step failed`, props<{ error: any }>());

/**
 * Go to previous step
 */
export const previousStep = createAction(`${prefix} Previous step`);

/**
 * Send final data to API
 */
export const saveFormula = createAction(`${prefix} Save formula`);

/**
 * Terminate Formula Wizard
 */
export const saveFormulaSuccess = createAction(`${prefix} Save formula success`, props<{ caseId: string }>());

/**
 * Terminate Formula Wizard with Error Message
 */
export const saveFormulaDataIssue = createAction(
  `${prefix} Save formula data issue`,
  props<{ errors: EquipmentErrorEquipmentErrorDto[] }>()
);

export const saveFormulaFailed = createAction(`${prefix} Save formula failed`, props<{ error: any }>());
