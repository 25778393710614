<div class="d-flex flex-column">
  <ng-container *ngIf="errorNotifications">
    <div class="notification" *ngIf="errorNotifications?.length">
      <div class="icon">
        <i class="zmdi zmdi-alert-triangle error"></i>
      </div>
      <vpfa-notification-content
        [notificationGroupList]="errorNotifications"
        [hideCount]="false"
      ></vpfa-notification-content>
    </div>
  </ng-container>

  <ng-container *ngIf="warningNotifications">
    <div class="notification" *ngIf="warningNotifications?.length">
      <div class="icon">
        <i class="zmdi zmdi-alert-circle warning"></i>
      </div>

      <vpfa-notification-content
        [notificationGroupList]="warningNotifications"
        [hideCount]="false"
      ></vpfa-notification-content>
    </div>
  </ng-container>

  <ng-container *ngIf="mistakeNotifications">
    <div class="notification" *ngIf="mistakeNotifications?.length">
      <div class="icon">
        <i class="zmdi zmdi-help mistake"></i>
      </div>
      <vpfa-notification-content
        [notificationGroupList]="mistakeNotifications"
        [hideCount]="false"
      ></vpfa-notification-content>
    </div>
  </ng-container>
  <ng-container *ngIf="successNotifications">
    <div class="notification" *ngIf="successNotifications?.length">
      <div class="icon">
        <i class="zmdi zmdi-badge-check success"></i>
      </div>
      <vpfa-notification-content
        [notificationGroupList]="successNotifications"
        [hideCount]="false"
      ></vpfa-notification-content>
    </div>
  </ng-container>
</div>
