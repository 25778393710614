import { ColumnApi, ColDef } from 'ag-grid-community';
import { isNil } from 'lodash';
import { DataTableColDef } from '../models/data-table-col-def';

export function autoSizeAllColumns(columnApi: ColumnApi) {
  if (isNil(columnApi)) {
    return;
  }

  const gridColumns = columnApi.getColumns();

  if (isNil(gridColumns)) {
    return;
  }

  const allColumnIds = gridColumns.map(column => column.getColId());
  columnApi.autoSizeColumns(allColumnIds);
}

export function setColumnWidthForAutoSize<T = any>(colDefs: DataTableColDef<T>[]): DataTableColDef<T>[] {
  return colDefs.map(colDef => {
    if (!isNil(colDef.width)) {
      return colDef;
    }

    return { ...colDef, width: 0, minWidth: 0 };
  });
}
