import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNil } from 'lodash';

export interface GroupElement extends GroupBase {
  groupTranslationLabel: string;
  toggled: boolean;
  itemsCount: number;
}

export interface GroupBase {
  group?: any;
  groupIndex: number;
  fullWidth: boolean;
  visible: boolean;
}

export type ElementWithGroupInfo<T, K, R> = (T & K) | R;

export function isGroupElement<T>(obj: any): obj is GroupElement {
  if ((obj as GroupElement).groupTranslationLabel) {
    return true;
  }
  return false;
}

export const addFullWidthGroups =
  <T>(groupFieldName: keyof T, translatePrefix: string, sectionToggleState = null) =>
  (source: Observable<Array<T>>) =>
    source.pipe(map(items => buildGroups(groupFieldName, translatePrefix, items, sectionToggleState)));

function buildGroups<T>(
  groupFieldName: keyof T,
  translatePrefix: string,
  items: Array<T>,
  sectionToggleState,
): Array<ElementWithGroupInfo<T, GroupBase, GroupElement>> {
  let lastGroup = null;
  let groupIndex = 0;
  const newItems: Array<ElementWithGroupInfo<T, GroupBase, GroupElement>> = [];
  for (const item of items) {
    const group = item[groupFieldName];
    if (lastGroup !== group) {
      const groupItem: ElementWithGroupInfo<T, GroupBase, GroupElement> = {
        group,
        groupTranslationLabel: `${translatePrefix}.${group}`,
        groupIndex: ++groupIndex,
        fullWidth: true,
        visible: true,
        toggled: !isNil(sectionToggleState) ? sectionToggleState[group] : false,
        itemsCount: 0,
      };
      newItems.push(groupItem);
      lastGroup = group;
      groupIndex++;
    }
    newItems.push({
      ...item,
      groupIndex: groupIndex,
      fullWidth: false,
      visible: !isNil(sectionToggleState) ? sectionToggleState[group] : false,
    });
  }
  return newItems;
}
