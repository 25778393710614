import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isNil } from 'lodash';

@Component({
  selector: 'vpfa-short-label-inline-editable',
  templateUrl: './short-label-inline-editable.component.html',
  styleUrls: ['./short-label-inline-editable.component.scss'],
})
export class ShortLabelInlineEditableComponent implements OnDestroy {
  @Input() label: string;
  @Input() errorMessages: ReadonlyArray<{ error: string; errorMsg: string }> = [];
  @Input() required: boolean;
  @Input() uppercase = false;
  @Input() isLoading = false;
  @Input() value = null;
  @Input() showValue = true;
  @Input() showCheckedSymbol = false;
  @Input() showBlueBackground = false;
  @Output() valueChange = new EventEmitter<string>();

  private $destroy = new Subject<void>();
  private _pristineValue: string;
  isInEditMode = false;

  private _control: UntypedFormControl = null;
  @Input() set control(control: UntypedFormControl) {
    this._control = control;
    this._pristineValue = control.value;
    this.formGroup = new UntypedFormGroup({
      control: control,
    });
    this._control.statusChanges.pipe(takeUntil(this.$destroy)).subscribe(x => {
      if (this._control.pristine) {
        this._pristineValue = this._control.value;
      }
    });
  }
  get control() {
    return this._control;
  }

  private _form: UntypedFormGroup = null;
  set formGroup(form: UntypedFormGroup) {
    this._form = form;
  }
  get formGroup() {
    return this._form;
  }

  onToggleEditMode() {
    if (!this.isLoading) {
      this.isInEditMode = !this.isInEditMode;
    }
  }

  onSubmitEdit() {
    if (this.control.dirty && this.control.invalid) {
      this.isInEditMode = false;
      this.control.setValue(this._pristineValue);
      return;
    }
    if (this.control.value !== this._pristineValue && !isNil(this.control.value) && this.control.value !== '') {
      this.valueChange.emit(this.control.value);
    }
    this.isInEditMode = false;
  }

  onBlur() {
    this.onSubmitEdit();
  }

  onKeyDownEscape() {
    this.restartValue();
    this.isInEditMode = false;
  }

  restartValue() {
    this.control.reset(this._pristineValue);
  }

  onSubmit(event) {
    this.valueChange.emit(event);
  }

  reset() {
    this.restartValue();
    this.isInEditMode = false;
  }

  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
  }
}
