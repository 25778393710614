import { DataTableValueGetterParams, DataTableActionListItem, DataTableAction } from '@vpfa/ui-kit/data-table';
import { PdfTemplateListItemDto } from '@vpfa/rest-api/valuation';

export const businessTemplatesActionGetter = (
  params: DataTableValueGetterParams<PdfTemplateListItemDto>
): DataTableActionListItem[] => {
  return params.data.isBaseTemplate
    ? [
        {
          name: 'admin.printTemplates.list.duplicate',
          action: DataTableAction.DUPLICATE
        }
      ]
    : [
        {
          name: 'common.edit',
          action: DataTableAction.EDIT
        },
        {
          name: 'common.rename',
          action: DataTableAction.RENAME
        },
        {
          name: 'admin.printTemplates.list.modules',
          action: DataTableAction.MODULES
        },
        {
          name: 'admin.printTemplates.list.duplicate',
          action: DataTableAction.DUPLICATE,
        },
        {
          name: 'common.delete',
          action: DataTableAction.DELETE,
          question: 'common.deleteQuestion'
        }
      ];
};
