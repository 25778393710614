<div
  (click)="$event.stopImmediatePropagation()"
  *ngIf="listItems && listItems.length"
  class="data-table-action-buttons"
>
  <div fxFlex>
    <button
      [style.height.px]="buttonHeightPx"
      nz-button
      nzType="link"
      nz-popover
      nzPopoverTrigger="click"
      nzPopoverPlacement="bottomRight"
      [(nzPopoverVisible)]="dataTableActionMenuVisible"
      nzPopoverOverlayClassName="data-table-action-popover-overlay"
      [nzPopoverContent]="dataTableActionMenu"
    >
      <i class="zmdi zmdi-more-vert"></i>
    </button>
  </div>
  <button *ngIf="isGoToDetailsButtonEnabled()" nz-button nzType="link" (click)="onGoToDetails($event)">
    <i class="zmdi zmdi-chevron-right"> </i>
  </button>
</div>

<ng-template #dataTableActionMenu>
  <div *ngFor="let button of getDropdownItemList()" [fxLayout]="'column'">
    <button
      class="data-table-action-popover-button"
      *ngIf="!button.question"
      nz-button
      nzType="link"
      (click)="emitAction(button.action)"
    >
      {{ button.name | translate }}
    </button>
    <button
      *ngIf="button.question"
      nz-button
      nzType="link"
      nz-popconfirm
      class="data-table-action-popover-button"
      [nzPopconfirmTrigger]="'click'"
      [nzPopconfirmTitle]="button.question | translate"
      [nzCancelText]="'common.no' | translate"
      [nzOkText]="'common.yes' | translate"
      nzPopconfirmPlacement="top"
      (nzOnConfirm)="emitAction(button.action)"
      nzPopconfirmOverlayClassName="data-table-action-popover-confirm"
    >
      {{ button.name | translate }}
    </button>
  </div>
</ng-template>
