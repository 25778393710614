import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPermissions from './permissions.reducer';

export const selectPermissionsState = createFeatureSelector<fromPermissions.State>(
  fromPermissions.permissionsFeatureKey
);

const getPermissions = createSelector(selectPermissionsState, (state: fromPermissions.State) => state.permissions);
const getLoading = createSelector(selectPermissionsState, (state: fromPermissions.State) => state.loading);
const getLoaded = createSelector(selectPermissionsState, (state: fromPermissions.State) => state.loaded);

export const permissionsQuery = {
  getPermissions,
  getLoading,
  getLoaded,
};
