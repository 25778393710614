<div fxFlex fxLayout="row" fxLayoutAlign="end center">
  <!-- TODO: id may be duplicated -->
  <div id="triangle-left" ngClass.gt-mv="triangle-web" ngClass.lt-mv="triangle-mobile"></div>
  <div
    id="rectangle"
    ngClass.gt-mv="rectangle-web"
    ngClass.lt-mv="rectangle-mobile"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div>{{ co2EmissionValue() }}</div>
    <div>{{ co2EmissionClass }}</div>
  </div>
</div>
