import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  AdjustmentDto,
  BusinessConditionAdjustmentsDto,
  CaseSummaryDto,
  KeyTranslationDto,
  SpecialColorSpecificationDto,
} from '@vpfa/rest-api/valuation';
import {
  AmountAdjustment,
  MarketSituationAdjustment,
  NumberOfOwnersAdjustment,
  SpecialColorAdjustment,
  SpecialUsageAdjustment,
  TechnicalInspectionAdjustment,
  ValuationAdjustmentType,
  AppreciationDepreciationAdjustment,
} from '@vpfa/shared/interfaces';
import { isNil, isNumber, sum } from 'lodash';
import { CasesState } from '../cases.reducer';
import { getActiveCaseData, getCasesState } from '../cases.selectors';
import { ConditionAdjustmentsState } from './adjustments.reducer';
import {
  DICTIONARIES_FEATURE_KEY,
  DictionariesState,
  DictionaryState,
} from '../../../../../../dictionaries/src/lib/+state/dictionaries.reducer';

const getDictionariesState = createFeatureSelector<DictionariesState>(DICTIONARIES_FEATURE_KEY);

const getPaintColourListDictionary = createSelector(getDictionariesState, (state: DictionariesState) => {
  return state.paintColourList;
});

const getPaintColourList = createSelector(
  getPaintColourListDictionary,
  (paintColourList: DictionaryState<KeyTranslationDto>) => {
    return paintColourList.list;
  }
);

const getAdjustmentsState = createSelector(getCasesState, (state: CasesState) => state.conditionAdjustments);

// ADJUSTMENTS - taken straight from CaseSummaryDto.adjustments
const getAdjustments = createSelector(
  getActiveCaseData,
  (activeCaseData: CaseSummaryDto) => activeCaseData.adjustments
);
const getNumberOfOwners = createSelector(getAdjustments, (adjustments: AdjustmentDto[]) =>
  findAdjustment<NumberOfOwnersAdjustment>(adjustments, ValuationAdjustmentType.NumberOfOwnersAdjustment)
);
const getSpecialUsage = createSelector(getAdjustments, (adjustments: AdjustmentDto[]) =>
  findAdjustment<SpecialUsageAdjustment>(adjustments, ValuationAdjustmentType.SpecialUsageAdjustment)
);
const getSpecialColor = createSelector(getAdjustments, (adjustments: AdjustmentDto[]) =>
  findAdjustment<SpecialColorAdjustment>(adjustments, ValuationAdjustmentType.SpecialColorAdjustment)
);
const getAppreciationDepreciation = createSelector(getAdjustments, (adjustments: AdjustmentDto[]) =>
  findAdjustment<AppreciationDepreciationAdjustment>(
    adjustments,
    ValuationAdjustmentType.AppreciationDepreciationAdjustment
  )
);
const getMarketSituation = createSelector(getAdjustments, (adjustments: AdjustmentDto[]) =>
  findAdjustment<MarketSituationAdjustment>(adjustments, ValuationAdjustmentType.MarketSituationAdjustment)
);
const getTechnicalInspection = createSelector(getAdjustments, (adjustments: AdjustmentDto[]) =>
  findAdjustment<TechnicalInspectionAdjustment>(adjustments, ValuationAdjustmentType.TechnicalInspectionAdjustment)
);
const getIsReimported = createSelector(getActiveCaseData, (state: CaseSummaryDto) => state.isReimported);
const getServiceHistory = createSelector(getActiveCaseData, (state: CaseSummaryDto) => state.serviceHistory);

// SPECIFICATIONS
const getBusinessSpecification = createSelector(
  getAdjustmentsState,
  (state: ConditionAdjustmentsState) => state.businessSpecification
);
const isBusinessSpecificationLoading = createSelector(
  getAdjustmentsState,
  (state: ConditionAdjustmentsState) => state.isBusinessSpecificationLoading
);

const getBusinessSpecificationVersion = createSelector(
  getBusinessSpecification,
  (state: BusinessConditionAdjustmentsDto) => (state ? state.version : null)
);

const getNumberOfOwnersSpecifications = createSelector(
  getBusinessSpecification,
  (state: BusinessConditionAdjustmentsDto) => (state ? state.numberOfOwnersSpecifications : [])
);
const getSpecialUsageSpecifications = createSelector(
  getBusinessSpecification,
  (state: BusinessConditionAdjustmentsDto) => (state ? state.specialUsageSpecifications : [])
);

const getSpecialColorSpecifications = createSelector(
  getBusinessSpecification,
  (state: BusinessConditionAdjustmentsDto) => (state ? state.specialColorSpecifications : [])
);

const getLocalizedSpecialColorSpecifications = createSelector(
  getSpecialColorSpecifications,
  getPaintColourList,
  (specialColorSpecifications: SpecialColorSpecificationDto[], colourList: KeyTranslationDto[]) =>
    specialColorSpecifications
      .map((x: SpecialColorSpecificationDto) => {
        return {
          ...x,
          name: colourList[x.paintColour]?.name,
        };
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1))
);

// DEDUCTIONS
const getMissingEquipmentDeduction = createSelector(getAdjustments, adjustments => {
  const adjustment = adjustments
    ? (adjustments.find(
        element => element.type === ValuationAdjustmentType.MissingEquipmentAdjustment
      ) as AmountAdjustment)
    : null;
  return adjustment ? adjustment.deductedValue : null;
});

const getAdditionalEquipmentDeduction = createSelector(getAdjustments, adjustments => {
  const adjustment = adjustments
    ? (adjustments.find(element => element.type === ValuationAdjustmentType.EquipmentAdjustment) as AmountAdjustment)
    : null;

  return adjustment ? adjustment.deductedValue : null;
});

const getTotalEquipmentDeduction = createSelector(getAdditionalEquipmentDeduction, additionalEquipment => {
  let total: number = 0;
  if (isNil(additionalEquipment)) {
    total = null;
  } else {
    if (isNumber(additionalEquipment)) {
      total += additionalEquipment;
    }
  }
  return total;
});

const getTotalAdjustedConditionDeduction = createSelector(getAdjustments, adjustments => {
  const conditionAdjustmentsTypes = [
    ValuationAdjustmentType.MarketSituationAdjustment,
    ValuationAdjustmentType.NumberOfOwnersAdjustment,
    ValuationAdjustmentType.SpecialColorAdjustment,
    ValuationAdjustmentType.SpecialUsageAdjustment,
    ValuationAdjustmentType.AppreciationDepreciationAdjustment,
    ValuationAdjustmentType.TechnicalInspectionAdjustment,
    ValuationAdjustmentType.MissingEquipmentAdjustment,
  ];

  const conditionAdjustments = adjustments.filter(adjustment =>
    conditionAdjustmentsTypes.includes(adjustment.type as ValuationAdjustmentType)
  );

  let total = 0;
  if (conditionAdjustments.length) {
    total = sum(
      conditionAdjustments.map(adjustment => {
        const value = adjustment as { type: string; deductedValue: number };
        return value.deductedValue ? value.deductedValue : 0;
      })
    );
  }

  return total;
});

// PROGRESS
const getAdjustmentsInProgress = createSelector(
  getAdjustmentsState,
  (state: ConditionAdjustmentsState) => state.adjustmentsInProgress
);
const getIsUpdatingAdjustments = createSelector(
  getAdjustmentsState,
  (state: ConditionAdjustmentsState) => state.isUpdatingAdjustments
);
const getConditionAdjustmentsErrors = createSelector(
  getAdjustmentsState,
  (state: ConditionAdjustmentsState) => state.conditionAdjustmentsErrors
);

export const adjustmentsQuery = {
  getBusinessSpecificationVersion,
  getNumberOfOwnersSpecifications,
  getSpecialUsageSpecifications,
  getSpecialColorSpecifications,
  getLocalizedSpecialColorSpecifications,
  getAdjustments,
  getNumberOfOwners,
  getSpecialUsage,
  getSpecialColor,
  getAppreciationDepreciation,
  getMarketSituation,
  getTechnicalInspection,
  getIsReimported,
  getServiceHistory,
  getTotalAdjustedConditionDeduction,
  getAdditionalEquipmentDeduction,
  getMissingEquipmentDeduction,
  getTotalEquipmentDeduction,
  isBusinessSpecificationLoading,
  getAdjustmentsInProgress,
  getIsUpdatingAdjustments,
  getConditionAdjustmentsErrors,
};

function findAdjustment<T = AdjustmentDto>(
  adjustments: AdjustmentDto[],
  adjustmentType: ValuationAdjustmentType
): T | null {
  const adjustment = adjustments.find(adj => adj.type === adjustmentType);
  return adjustment ? ((<any>adjustment) as T) : null;
}
