<vpfa-field-container
  [heightCompensationPx]="heightCompensationPx"
  [displayErrors]="shouldDisplayErrors$.getValue() && getFormControl().dirty"
  [control]="getFormControl()"
  [errorMsgVertPosition]="errorMsgVertPosition"
  [errorMessages]="errorMessages"
>
  <nz-form-control>
    <input
      nzSize="small"
      #input
      vpfaCharCountLimiter
      [maxCharCount]="charCountLimit"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (keydown.enter)="onSubmit($event)"
      [placeholder]="placeholder"
      [formControl]="getFormControl()"
      nz-input
      type="text"
      class="text-field phone-number-field"
    />
  </nz-form-control>
</vpfa-field-container>
