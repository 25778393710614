import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BranchDto, BusinessDto, ProfileDto, UserContext } from '@vpfa/rest-api/admin';
import { datadogLogs } from '@datadog/browser-logs';

declare const VERSION: string;
const clientToken = 'pubb5f00aa858cbabb2f5f4ee74a2ec635b';
const site = 'datadoghq.eu';
const serviceName = 'ssm-frontend-angular';
const applicationId = '86574b3d-bba8-4b22-ac95-47e86cd9ddd3';

export type DataDogAction = 'OpenModal' | 'CloseModal';

@Injectable({
  providedIn: 'root',
})
export class DataDogService {
  public static readonly COOKIE_PATTERN = '_dd_*';

  branch: BranchDto;
  business: BusinessDto;
  userContext: UserContext;
  profile: ProfileDto;

  constructor() {
    this.initializeLogs();
  }

  enableTracking() {
    if (!environment.datadogEnabled) {
      return;
    }

    datadogLogs.setTrackingConsent('granted');
  }

  disableTracking() {
    if (!environment.datadogEnabled) {
      return;
    }

    datadogLogs.setTrackingConsent('not-granted');
  }

  setUserBranchAndBusiness(branch: BranchDto, business: BusinessDto) {
    this.branch = branch;
    this.business = business;
  }

  setUserContextAndProfile(userContext: UserContext, profile: ProfileDto) {
    this.userContext = userContext;
    this.profile = profile;
  }

  addNotificationError(title: string, message: string, errorId?: string, interpolateParams?: Object) {
    const errorContext = Object.entries({
      title,
      message,
      errorId,
      interpolateParams,
    });

    const errorData = [];

    for (const [key, value] of errorContext) {
      if (!!value) {
        errorData.push(`"${key}": "${value}"`);
      }
    }

    if (errorData.length > 0) {
      const customMessage = `UserNotifiedAboutError: ${errorData.join(', ')}`;

      const error = new Error(customMessage);

      datadogLogs.logger.error(customMessage, undefined, error);
    }
  }

  addAction(actionName: DataDogAction, context: Object) {
    // for possible future use with RUM
  }

  userLoggedOut() {
    datadogLogs.clearUser();
  }

  userLoggedIn() {
    const userData = {
      email: this.userContext.email,
      id: this.userContext.subjectId,
      name: this.userContext.name,
      branchName: this.branch.branchName,
      branchId: this.branch.branchId,
      businessName: this.business.businessName,
      businessId: this.business.businessId,
    };

    datadogLogs.setUser(userData);
  }

  private initializeLogs() {
    datadogLogs.init({
      clientToken,
      site,
      forwardErrorsToLogs: true,
      version: getVersion(),
      env: this.detectEnvironment(),
      service: serviceName,
      trackingConsent: 'not-granted',
    });
  }

  private detectEnvironment() {
    const liveEnv = 'live';

    const urlPartToEnvironmentMap: Record<string, string> = {
      qa: '.qa.',
      testdev: '.testdev.',
      uat: '.uat.',
      staging: '.staging.',
      dm: '.dm.',
      [liveEnv]: '.live.',
      localhost: 'localhost',
    };

    for (const [env, urlPart] of Object.entries(urlPartToEnvironmentMap)) {
      if (location.hostname.includes(urlPart)) {
        return env;
      }
    }

    return liveEnv;
  }
}

function getVersion() {
  return VERSION;
}
