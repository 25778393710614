import { Component, OnInit } from '@angular/core';
import { BaseFullTechSpecAndEquipmentList } from '../base-full-tech-spec-and-equipment-list/base-full-tech-spec-and-equipment-list';

@Component({
  selector: 'vpfa-full-tech-and-equipment-list-mobile',
  templateUrl: './full-tech-spec-and-equipment-list-mobile.component.html',
  styleUrls: ['./full-tech-spec-and-equipment-list-mobile.component.scss'],
})
export class FullTechSpecAndEquipmentListMobileComponent extends BaseFullTechSpecAndEquipmentList implements OnInit {
  ngOnInit(): void {
    window.scroll(0, 0);
  }
}
