import { Component, Input, OnInit } from '@angular/core';
import { InputBoolean, InputNumber } from 'ng-zorro-antd/core/util';
import { NzProgressStatusType } from 'ng-zorro-antd/progress';

@Component({
  selector: 'vpfa-step-progressbar',
  templateUrl: './step-progressbar.component.html',
  styleUrls: ['./step-progressbar.component.scss'],
})
export class StepProgressbarComponent implements OnInit {
  @Input() @InputNumber() stepsCompleted = 0;
  @Input() @InputNumber() stepCount = 0;
  @Input() @InputNumber() widthPx: number;
  @Input() @InputBoolean() showInfo = false;
  @Input() stepProgressTextPrefix: string;
  @Input() hasError: boolean;
  @Input() error = 'Error!';
  @Input() active = false;
  @Input() status: NzProgressStatusType = this.active ? 'active' : 'normal';
  @Input() layoutAlign = 'start stretch';

  constructor() {}

  ngOnInit() {}

  getPercent() {
    if (this.stepCount) {
      return Math.ceil((Number(this.stepsCompleted) / Number(this.stepCount)) * 100);
    } else {
      return 0;
    }
  }
}
