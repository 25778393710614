import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MixpanelService, MixpanelEvent, DataDogService } from '@vpfa/utils';
import { isNil } from 'lodash';
import { DEFAULT_MODAL_WIDTH } from '../../utils/modal-widths';

@Component({
  selector: 'vpfa-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() isVisible = true;

  /**
   * If set - opening and closing of this modal will be tracked by Mixpanel and DataDog
   *
   */
  @Input() modalName = null;
  @Input() trackingContext: Object | null = null;
  @Input() isClosable = true;
  @Input() disableHeader = true;
  @Input() disableFooter = true;
  @Input() title: string;
  @Input() zIndex = 1000;
  @Input() cancelBtnText = 'Cancel';
  @Input() okBtnText = 'Ok';
  @Input() showBackdrop = false;
  @Input() className: string;
  @Input() wrapClassName: string = '';
  @Input() maskClosable = true;
  @Input() width: string = DEFAULT_MODAL_WIDTH;
  @Input() keyboard = true;
  @Output() closeAction = new EventEmitter();
  @Output() afterOpenAction = new EventEmitter();
  @Output() afterCloseAction = new EventEmitter();

  getHeader(headerRef) {
    if (!this.disableHeader) {
      return this.title ? this.title : headerRef;
    }
  }

  constructor(private mixpanel: MixpanelService, private dataDogService: DataDogService) {}

  ngOnInit() {}

  afterModalOpenAction() {
    if (!isNil(this.modalName)) {
      this.mixpanel.track(MixpanelEvent.ModalOpen, { modalName: this.modalName });
      this.mixpanel.trackTime(MixpanelEvent.ModalClose);
      this.dataDogService.addAction('OpenModal', {
        modalName: this.modalName,
        ...(this.trackingContext ?? {}),
      });
    }
    this.afterOpenAction.emit();
  }

  onModalCloseAction() {
    this.closeAction.emit();
  }

  afterModalCloseAction() {
    if (!isNil(this.modalName)) {
      this.mixpanel.track(MixpanelEvent.ModalClose, { modalName: this.modalName });
      this.dataDogService.addAction('CloseModal', { modalName: this.modalName });
    }
    this.afterCloseAction.emit();
  }
}
