/**
 * Public SSM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0-private
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { VehicleValuationInternalCaseImageKeysBody } from '../model/vehicleValuationInternalCaseImageKeysBody';
import { VehicleValuationInternalCaseImageKeysBody1 } from '../model/vehicleValuationInternalCaseImageKeysBody1';
import { VehicleValuationInternalCaseImageKeysBody2 } from '../model/vehicleValuationInternalCaseImageKeysBody2';
import { VehicleValuationInternalCaseImageKeysBody3 } from '../model/vehicleValuationInternalCaseImageKeysBody3';
import { VehicleValuationInternalFeatureAvailableForCountryBody } from '../model/vehicleValuationInternalFeatureAvailableForCountryBody';
import { VehicleValuationInternalFeatureAvailableForCountryBody1 } from '../model/vehicleValuationInternalFeatureAvailableForCountryBody1';
import { VehicleValuationInternalFeatureAvailableForCountryBody2 } from '../model/vehicleValuationInternalFeatureAvailableForCountryBody2';
import { VehicleValuationInternalFeatureAvailableForCountryBody3 } from '../model/vehicleValuationInternalFeatureAvailableForCountryBody3';
import { VehicleValuationInternalGetVehicleEngineFuelTypeBody } from '../model/vehicleValuationInternalGetVehicleEngineFuelTypeBody';
import { VehicleValuationInternalGetVehicleEngineFuelTypeBody1 } from '../model/vehicleValuationInternalGetVehicleEngineFuelTypeBody1';
import { VehicleValuationInternalGetVehicleEngineFuelTypeBody2 } from '../model/vehicleValuationInternalGetVehicleEngineFuelTypeBody2';
import { VehicleValuationInternalGetVehicleEngineFuelTypeBody3 } from '../model/vehicleValuationInternalGetVehicleEngineFuelTypeBody3';
import { VehicleValuationInternalMakeRepairEstimateUneditableBody } from '../model/vehicleValuationInternalMakeRepairEstimateUneditableBody';
import { VehicleValuationInternalMakeRepairEstimateUneditableBody1 } from '../model/vehicleValuationInternalMakeRepairEstimateUneditableBody1';
import { VehicleValuationInternalMakeRepairEstimateUneditableBody2 } from '../model/vehicleValuationInternalMakeRepairEstimateUneditableBody2';
import { VehicleValuationInternalMakeRepairEstimateUneditableBody3 } from '../model/vehicleValuationInternalMakeRepairEstimateUneditableBody3';
import { VehicleValuationInternalTranslateBody } from '../model/vehicleValuationInternalTranslateBody';
import { VehicleValuationInternalTranslateBody1 } from '../model/vehicleValuationInternalTranslateBody1';
import { VehicleValuationInternalTranslateBody2 } from '../model/vehicleValuationInternalTranslateBody2';
import { VehicleValuationInternalTranslateBody3 } from '../model/vehicleValuationInternalTranslateBody3';
import { VehicleValuationInternalVehicleCostModelBody } from '../model/vehicleValuationInternalVehicleCostModelBody';
import { VehicleValuationInternalVehicleCostModelBody1 } from '../model/vehicleValuationInternalVehicleCostModelBody1';
import { VehicleValuationInternalVehicleCostModelBody2 } from '../model/vehicleValuationInternalVehicleCostModelBody2';
import { VehicleValuationInternalVehicleCostModelBody3 } from '../model/vehicleValuationInternalVehicleCostModelBody3';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class VehicleValuationInternalService {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCaseImageKeys(body?: VehicleValuationInternalCaseImageKeysBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getCaseImageKeys(body?: VehicleValuationInternalCaseImageKeysBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getCaseImageKeys(body?: VehicleValuationInternalCaseImageKeysBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getCaseImageKeys(body?: VehicleValuationInternalCaseImageKeysBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/internal/VehicleValuationInternal/CaseImageKeys`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLanguageCodeConversions(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getLanguageCodeConversions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getLanguageCodeConversions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getLanguageCodeConversions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/internal/VehicleValuationInternal/LanguageCodeConversions`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTranslations(body?: VehicleValuationInternalTranslateBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getTranslations(body?: VehicleValuationInternalTranslateBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getTranslations(body?: VehicleValuationInternalTranslateBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getTranslations(body?: VehicleValuationInternalTranslateBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/internal/VehicleValuationInternal/Translate`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVehicleCostModel(body?: VehicleValuationInternalVehicleCostModelBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getVehicleCostModel(body?: VehicleValuationInternalVehicleCostModelBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getVehicleCostModel(body?: VehicleValuationInternalVehicleCostModelBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getVehicleCostModel(body?: VehicleValuationInternalVehicleCostModelBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/internal/VehicleValuationInternal/VehicleCostModel`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVehicleEngineFuelType(body?: VehicleValuationInternalGetVehicleEngineFuelTypeBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getVehicleEngineFuelType(body?: VehicleValuationInternalGetVehicleEngineFuelTypeBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getVehicleEngineFuelType(body?: VehicleValuationInternalGetVehicleEngineFuelTypeBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getVehicleEngineFuelType(body?: VehicleValuationInternalGetVehicleEngineFuelTypeBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/internal/VehicleValuationInternal/GetVehicleEngineFuelType`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public isFeatureAvailableForCountry(body?: VehicleValuationInternalFeatureAvailableForCountryBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public isFeatureAvailableForCountry(body?: VehicleValuationInternalFeatureAvailableForCountryBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public isFeatureAvailableForCountry(body?: VehicleValuationInternalFeatureAvailableForCountryBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public isFeatureAvailableForCountry(body?: VehicleValuationInternalFeatureAvailableForCountryBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/internal/VehicleValuationInternal/FeatureAvailableForCountry`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public makeRepairEstimateUneditable(body?: VehicleValuationInternalMakeRepairEstimateUneditableBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public makeRepairEstimateUneditable(body?: VehicleValuationInternalMakeRepairEstimateUneditableBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public makeRepairEstimateUneditable(body?: VehicleValuationInternalMakeRepairEstimateUneditableBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public makeRepairEstimateUneditable(body?: VehicleValuationInternalMakeRepairEstimateUneditableBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/internal/VehicleValuationInternal/MakeRepairEstimateUneditable`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
