export class Adjustment {
  type: ValuationAdjustmentType;
  deductedValue: number;
}

export class AmountAdjustment extends Adjustment {
  deductionAmount: number;
  customDeductionAmount: number | null;
}

export class LocalizedPercentageAdjustment extends Adjustment {
  translations: { [key: string]: string };
  deductionPercentage: number;
  customDeductionPercentage: number | null;
}

export class MarketSituationAdjustment extends Adjustment {
  correctionPercentageValue: number = null;
}

export class NumberOfOwnersAdjustment extends LocalizedPercentageAdjustment {
  numberOfOwnersSpecificationId: number;
}

export class SpecialUsageAdjustment extends LocalizedPercentageAdjustment {
  specialUsageSpecificationId: number;
}

export class SpecialColorAdjustment extends AmountAdjustment {
  paintColour: number;
}

export class AppreciationDepreciationAdjustment extends AmountAdjustment {
  deductionComment: string
}

export class TechnicalInspectionAdjustment extends AmountAdjustment {
  technicalInspectionDate: string;
}

export class MissingEquipmentAdjustment extends Adjustment {
  deductionAmount: number;
}

export class RepairEstimateCalculationAdjustmentDto extends Adjustment {
  deductionAmount: number;
  sessions: RepairEstimateAdjustmentSessionDto[];
}

export type AdjustmentType =
  | MarketSituationAdjustment
  | SpecialColorAdjustment
  | TechnicalInspectionAdjustment
  | SpecialUsageAdjustment
  | NumberOfOwnersAdjustment;

export enum ValuationAdjustmentType {
  NumberOfOwnersAdjustment = 'NumberOfOwnersAdjustment',
  SpecialUsageAdjustment = 'SpecialUsageAdjustment',
  SpecialColorAdjustment = 'SpecialColorAdjustment',
  AppreciationDepreciationAdjustment = 'AppreciationDepreciationAdjustment',
  MarketSituationAdjustment = 'MarketSituationAdjustment',
  TechnicalInspectionAdjustment = 'TechnicalInspectionAdjustment',
  MissingEquipmentAdjustment = 'MissingEquipmentAdjustment',
  EquipmentAdjustment = 'EquipmentAdjustment',
  LightDamagePartsAdjustment = 'LightDamagePartsAdjustment',
  RepairEstimateCalculationAdjustment = 'RepairEstimateCalculationAdjustment',
}

export enum ValuationSpecificationType {
  DefaultMarketSituationCorrection = 'defaultMarketSituationCorrection',
  NumberOfOwnersSpecifications = 'numberOfOwnersSpecifications',
  SpecialColorSpecifications = 'specialColorSpecifications',
  SpecialUsageSpecifications = 'specialUsageSpecifications',
}


export class RepairEstimateAdjustmentSessionDto {
  repairEstimateSessionId: string;
  isIncludedInValuation: boolean;
  deliveryDate: Date | string | null;
  repairCost: number;
  description: string;
  repairProfileName: string;
  IsUneditable?: boolean
}
