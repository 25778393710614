import { ChangeDetectionStrategy, Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'vpfa-modal-button',
  templateUrl: './modal-button.component.html',
  styleUrls: ['./modal-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalButtonComponent {
  @HostBinding('class.disabled') get isDisable(): boolean {
    return this._isDisabled;
  }

  @Input() buttonType = 'button';
  @Input() set disabled(setValue: boolean) {
    this._isDisabled = setValue;
  }
  @Input() outline = false;
  @Input() arrow = false;
  @Input() border = true;
  @Input() disableOnClick = false;
  @Input() set reset(_) {
    this._isDisabled = false;
  }

  get disabled() {
    return this._isDisabled;
  }

  private _isDisabled = false;

  onClick(e: MouseEvent) {
    if (this.disableOnClick) {
      e.preventDefault();
      e.stopPropagation();
      this._isDisabled = true;
    }
  }
}
