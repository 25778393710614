import { PipeTransform } from '@angular/core';
import { isNil } from 'lodash';

export function joinNumericValues(
  firstValue: number | string,
  secondValue: number | string,
  separator: string,
  formatter: PipeTransform
) {
  const result = [];

  if (!isNil(firstValue)) {
    result.push(formatter.transform(Number(firstValue)));
  }

  if (!isNil(secondValue)) {
    result.push(formatter.transform(Number(secondValue)));
  }

  if (!result.length) {
    return null;
  }

  return `${result.join(separator)}`;
}
