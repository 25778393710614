import { find, forEach, isNil } from 'lodash';

export function isInUrlsList(url: string, list: string[]): boolean {
    const urlParts = url.split('/');
  
    if (urlParts.length === 0) {
      return false;
    }
  
    const urlsListEl = find(list, blackListUrl => {
      const listUrlParts = blackListUrl.split('/');
  
      if (listUrlParts.length === 0 || listUrlParts.length !== urlParts.length) {
        return false;
      }
  
      let isInList = true;
      forEach(listUrlParts, (listUrlPart, index) => {
        const isParam = listUrlPart.startsWith(':');
        if (!isParam && listUrlPart !== urlParts[index]) {
          isInList = false;
          return false;
        }
      });
  
      return isInList;
    });
  
    return isNil(urlsListEl) ? false : true;
  }