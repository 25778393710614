/**
 * Public SSM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0-private
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { CaseDocumentDto } from '../model/caseDocumentDto';
import { CaseImageWithOrderDto } from '../model/caseImageWithOrderDto';
import { VPError } from '../model/vPError';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CaseFilesViewService {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * AmsAction:GET/api/CaseFilesView/{caseId}/Documents/{fileKey} AmsResource:{country}/{business}/case/{caseId} 
     * @param caseId 
     * @param fileKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCaseDocument(caseId: string, fileKey: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getCaseDocument(caseId: string, fileKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getCaseDocument(caseId: string, fileKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getCaseDocument(caseId: string, fileKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getCaseDocument.');
        }

        if (fileKey === null || fileKey === undefined) {
            throw new Error('Required parameter fileKey was null or undefined when calling getCaseDocument.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Blob>(`${this.basePath}/api/CaseFilesView/${encodeURIComponent(String(caseId))}/Documents/${encodeURIComponent(String(fileKey))}`,
            {
                // @ts-ignore
                responseType:'blob',
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CaseFilesView/{caseId}/Documents/List AmsResource:{country}/{business}/case/{caseId} 
     * @param caseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCaseDocumentList(caseId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CaseDocumentDto>>;
    public getCaseDocumentList(caseId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CaseDocumentDto>>>;
    public getCaseDocumentList(caseId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CaseDocumentDto>>>;
    public getCaseDocumentList(caseId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getCaseDocumentList.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CaseDocumentDto>>(`${this.basePath}/api/CaseFilesView/${encodeURIComponent(String(caseId))}/Documents/List`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CaseFilesView/{caseId}/Images/{fileKey} AmsResource:{country}/{business}/case/{caseId} 
     * @param caseId 
     * @param fileKey 
     * @param size 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCaseImage(caseId: string, fileKey: string, size?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getCaseImage(caseId: string, fileKey: string, size?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getCaseImage(caseId: string, fileKey: string, size?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getCaseImage(caseId: string, fileKey: string, size?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getCaseImage.');
        }

        if (fileKey === null || fileKey === undefined) {
            throw new Error('Required parameter fileKey was null or undefined when calling getCaseImage.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (size !== undefined && size !== null) {
          queryParameters = queryParameters.set('size', <any>size);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Blob>(`${this.basePath}/api/CaseFilesView/${encodeURIComponent(String(caseId))}/Images/${encodeURIComponent(String(fileKey))}`,
            {
                // @ts-ignore
                responseType:'blob',
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CaseFilesView/{caseId}/Images/List AmsResource:{country}/{business}/case/{caseId} 
     * @param caseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCaseImageList(caseId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public getCaseImageList(caseId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public getCaseImageList(caseId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public getCaseImageList(caseId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getCaseImageList.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/api/CaseFilesView/${encodeURIComponent(String(caseId))}/Images/List`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CaseFilesView/{caseId}/Images/ListWithOrder AmsResource:{country}/{business}/case/{caseId} 
     * @param caseId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCaseImageListWithOrder(caseId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CaseImageWithOrderDto>>;
    public getCaseImageListWithOrder(caseId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CaseImageWithOrderDto>>>;
    public getCaseImageListWithOrder(caseId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CaseImageWithOrderDto>>>;
    public getCaseImageListWithOrder(caseId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getCaseImageListWithOrder.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CaseImageWithOrderDto>>(`${this.basePath}/api/CaseFilesView/${encodeURIComponent(String(caseId))}/Images/ListWithOrder`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * @param caseId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCasePublicImageListWithOrder(caseId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CaseImageWithOrderDto>>;
    public getCasePublicImageListWithOrder(caseId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CaseImageWithOrderDto>>>;
    public getCasePublicImageListWithOrder(caseId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CaseImageWithOrderDto>>>;
    public getCasePublicImageListWithOrder(caseId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getCasePublicImageListWithOrder.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CaseImageWithOrderDto>>(`${this.basePath}/api/CaseFilesView/${encodeURIComponent(String(caseId))}/Public/Images/ListWithOrder`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param caseId 
     * @param fileKey 
     * @param size 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublicImages(caseId: string, fileKey: string, size?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getPublicImages(caseId: string, fileKey: string, size?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getPublicImages(caseId: string, fileKey: string, size?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getPublicImages(caseId: string, fileKey: string, size?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getPublicImages.');
        }

        if (fileKey === null || fileKey === undefined) {
            throw new Error('Required parameter fileKey was null or undefined when calling getPublicImages.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (size !== undefined && size !== null) {
          queryParameters = queryParameters.set('size', <any>size);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Blob>(`${this.basePath}/api/CaseFilesView/${encodeURIComponent(String(caseId))}/public/${encodeURIComponent(String(fileKey))}`,
            {
                // @ts-ignore
                responseType:'blob',
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param caseId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getResellerCaseImageListWithOrder(caseId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CaseImageWithOrderDto>>;
    public getResellerCaseImageListWithOrder(caseId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CaseImageWithOrderDto>>>;
    public getResellerCaseImageListWithOrder(caseId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CaseImageWithOrderDto>>>;
    public getResellerCaseImageListWithOrder(caseId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling getResellerCaseImageListWithOrder.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CaseImageWithOrderDto>>(`${this.basePath}/api/CaseFilesView/${encodeURIComponent(String(caseId))}/Reseller/Images/ListWithOrder`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
