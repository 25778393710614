<div (click)="onSortRequested()" fxLayoutAlign="start center" [fxFlex]="maxWidth">
  <div
    #headerLabel
    [ngStyle]="headerLabelWidthStyle"
    class="header-label"
    nz-tooltip
    [nzTooltipOverlayClassName]="'tooltip'"
    [nzTooltipTitle]="isEllipsisActive ? headerName : null"
    [nzTooltipTrigger]="'hover'"
    [nzTooltipPlacement]="'top'"
  >
    {{ headerName }}
  </div>
  <div *ngIf="sortEnabled" class="sort-icon">
    <i *ngIf="sortAscending" class="ag-icon ag-icon-asc"></i>
    <i *ngIf="sortDescending" class="ag-icon ag-icon-desc"></i>
  </div>
</div>
