<vpfa-file-upload
  [hidden]="!alwaysVisible && isUploading"
  [fileUploadType]="fileUploadType"
  [multiple]="multiple"
  [maxFileSize]="getFileMaxSize(fileUploadType)"
  [uploadPrompt]="uploadPrompt"
  [uploadButtonLabel]="uploadButtonLabel"
  [isDisabled]="isDisabled || isUploading"
  [fileList]="fileList"
  [uiMode]="uiMode"
  [showUploadIcon]="showUploadIcon"
  (change)="onFileListChange($event)"
></vpfa-file-upload>
<div *ngIf="isUploading && uiMode === 'fullMode'">
  <h4 class="please-wait" style="text-align: center">{{ 'files.pleaseWaitForTheUploadToFinish' | translate }}</h4>
</div>
