import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  fromSorcIntegrationActions,
  LoadBodyTypeList,
  LoadBodyTypeListCompleted,
  LoadBodyTypeListError,
  LoadFuelTypeList,
  LoadFuelTypeListCompleted,
  LoadFuelTypeListError,
  LoadMakesList,
  LoadModelsList,
  LoadTransmissionTypeList,
  LoadTransmissionTypeListCompleted,
  LoadTransmissionTypeListError,
  LoadVehiclesList,
  SorcIntegrationActions,
  SorcIntegrationActionTypes,
} from './sorc-integration.actions';
import { SorcIntegrationService } from '../services/sorc-integration.service';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { delayUntil } from '@vpfa/utils';
import { SorcDictionariesKeys } from '../models/sorc-dictionary-dto';
import { EnvironmentsService } from '@vpfa/environments/data';
import { LocaleActionTypes } from '@vpfa/locale';
import { AuthFacade } from '@vpfa/auth';
import { ProfileFacade } from '@vpfa/profile/data';

@Injectable()
export class SorcIntegrationEffects {
  constructor(
    private actions$: Actions<SorcIntegrationActions>,
    private sorcIntegrationService: SorcIntegrationService,
    private profileFacade: ProfileFacade,
    private environmentsService: EnvironmentsService,
    private authFacade: AuthFacade
  ) {}

   loadVehicleMakes$ = createEffect(() => this.actions$.pipe(
    ofType<LoadMakesList>(SorcIntegrationActionTypes.LoadMakesList),
    withLatestFrom(this.profileFacade.userContextCountryCode$),
    switchMap(([action, countryCode]) =>
      this.sorcIntegrationService.getMakesList(countryCode, action.payload).pipe(
        map(response => new fromSorcIntegrationActions.LoadMakesListCompleted(response)),
        catchError(() => of(new fromSorcIntegrationActions.LoadMakesListError()))
      )
    )
  ));

   loadVehicleModels$ = createEffect(() => this.actions$.pipe(
    ofType<LoadModelsList>(SorcIntegrationActionTypes.LoadModelsList),
    withLatestFrom(this.profileFacade.userContextCountryCode$),
    switchMap(([action, countryCode]) =>
      this.sorcIntegrationService.getModelsList(countryCode, action.payload?.typeCodes, action.payload?.make).pipe(
        map(response => new fromSorcIntegrationActions.LoadModelsListCompleted(response)),
        catchError(() => of(new fromSorcIntegrationActions.LoadModelsListError()))
      )
    )
  ));

   loadVehicles$ = createEffect(() => this.actions$.pipe(
    ofType<LoadVehiclesList>(SorcIntegrationActionTypes.LoadVehiclesList),
    withLatestFrom(this.profileFacade.userContext$),
    switchMap(([action, userContext]) =>
      this.sorcIntegrationService
        .getVehiclesList(
          userContext?.countryCode,
          userContext?.contentLanguage,
          action.payload?.model,
          action.payload?.pageSize,
          action.payload?.mandatorySearchFilters,
          action.payload?.optionalSearchFilters
        )
        .pipe(
          map(response => new fromSorcIntegrationActions.LoadVehiclesListCompleted(response)),
          catchError(() => of(new fromSorcIntegrationActions.LoadVehiclesListError()))
        )
    )
  ));

   whenContentLanguageChangeLoadSorcDictionaries$ = createEffect(() => this.actions$.pipe(
    ofType(LocaleActionTypes.SelectContentLanguage),
    delayUntil(this.environmentsService.configInitialized$),
    withLatestFrom(this.authFacade.isAuthenticated$, this.profileFacade.userContext$),
    filter(([, isAuth, userContext]) => {
      if (userContext?.isCountryAdmin || userContext?.isSystemAdmin) {
        return false;
      } else {
        return isAuth;
      }
    }),
    mergeMap(() => [
      new fromSorcIntegrationActions.LoadTransmissionTypeList(),
      new fromSorcIntegrationActions.LoadBodyTypeList(),
      new fromSorcIntegrationActions.LoadFuelTypeList(),
    ])
  ));

   loadTransmissionTypeList$ = createEffect(() => this.actions$.pipe(
    ofType<LoadTransmissionTypeList>(SorcIntegrationActionTypes.LoadTransmissionTypeList),
    withLatestFrom(this.profileFacade.userContext$),
    switchMap(([, userContext]) =>
      this.sorcIntegrationService
        .getDictionariesList(
          userContext?.countryCode,
          userContext?.contentLanguage,
          SorcDictionariesKeys.transmissiontype
        )
        .pipe(
          map(res => new LoadTransmissionTypeListCompleted(res)),
          catchError(() => of(new LoadTransmissionTypeListError()))
        )
    )
  ));

   loadBodyTypeList$ = createEffect(() => this.actions$.pipe(
    ofType<LoadBodyTypeList>(SorcIntegrationActionTypes.LoadBodyTypeList),
    withLatestFrom(this.profileFacade.userContext$),
    switchMap(([, userContext]) =>
      this.sorcIntegrationService
        .getDictionariesList(userContext?.countryCode, userContext?.contentLanguage, SorcDictionariesKeys.bodytype)
        .pipe(
          map(res => new LoadBodyTypeListCompleted(res)),
          catchError(() => of(new LoadBodyTypeListError()))
        )
    )
  ));

   loadFuelTypeList$ = createEffect(() => this.actions$.pipe(
    ofType<LoadFuelTypeList>(SorcIntegrationActionTypes.LoadFuelTypeList),
    withLatestFrom(this.profileFacade.userContext$),
    switchMap(([, userContext]) =>
      this.sorcIntegrationService
        .getDictionariesList(userContext?.countryCode, userContext?.contentLanguage, SorcDictionariesKeys.fueltype)
        .pipe(
          map(res => new LoadFuelTypeListCompleted(res)),
          catchError(() => of(new LoadFuelTypeListError()))
        )
    )
  ));
}
