<div fxLayout="row">
  <div [ngStyle]="widthStyle">
    <div
      id="rectangle"
      ngClass.gt-mv="{{ 'rectangle-web ' + colorClass }}"
      ngClass.lt-mv="{{ 'rectangle-mobile ' + colorClass }}"
      fxLayout="column"
      fxLayoutAlign="center"
    >
      <div *ngFor="let range of ranges" fxLayout="row" fxLayoutAlign="space-between center">
        <div>{{ range?.range[0] }} {{ renderSymbol(range?.range) }} {{ range?.range[1] }}</div>
        <div>{{ range?.emissionClass }}</div>
      </div>
    </div>
  </div>
  <div
    id="triangle-right"
    ngClass.gt-mv="{{ 'triangle-web ' + colorClass }}"
    ngClass.lt-mv="{{ 'triangle-mobile ' + colorClass }}"
  ></div>
</div>
