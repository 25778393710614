import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TextFieldComponent } from '../text-field/text-field.component';
import { isNil, isEmpty } from 'lodash';

@Component({
  selector: 'vpfa-password-field',
  templateUrl: './password-field.component.html',
  styleUrls: ['./password-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordFieldComponent extends TextFieldComponent {
  showPassword = false;

  @Input() isPasswordSet = false;

  onShowPassword() {
    this.showPassword = !this.showPassword;
  }

  passwordChanged() {
    const password = this.getFormControl()?.value;
    return !(isEmpty(password) || isNil(password));
  }

  onBlurPassword() {
    this.showPassword = false;
    this.onBlur();
  }

  onEscape() {
    this.getFormControl().reset();
  }
}
