<div (click)="onClick($event)">
  <i
    [ngClass]="{ default: variant === 'default', enter: variant === 'enter' }"
    class="arrow-icon zmdi zmdi-long-arrow-return"
  ></i>
  <i *ngIf="icon" class="action-icon zmdi zmdi-{{ icon }}"></i>
  <span class="link-content">
    <ng-content></ng-content>
  </span>
</div>
