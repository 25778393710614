import { Component } from '@angular/core';
import { AgFrameworkComponent } from 'ag-grid-angular';
import { IFloatingFilter, IFloatingFilterParams, TextFilter, TextFilterModel } from 'ag-grid-community';

export interface TextFloatingFilterParams extends IFloatingFilterParams {
  value: string;
}

@Component({
  selector: 'vpfa-text-floating-filter',
  templateUrl: './text-floating-filter.component.html',
  styleUrls: ['./text-floating-filter.component.scss'],
})
export class TextFloatingFilterComponent implements IFloatingFilter, AgFrameworkComponent<TextFloatingFilterParams> {
  private params: TextFloatingFilterParams;

  public value: string;

  agInit(params: TextFloatingFilterParams): void {
    this.value = params.value;
    this.params = params;
  }

  valueChanged() {
    this.params.parentFilterInstance(instance => {
      if (this.value) {
        instance.setModel({ value: this.value });
        instance.onFloatingFilterChanged('contains', this.value);
      } else {
        instance.setModel({ value: null });
        instance.onFloatingFilterChanged(null, null);
      }
      this.params.api.onFilterChanged();
    });
  }

  onParentModelChanged(parentModel: TextFilterModel): void {
    if (!parentModel) {
      this.value = null;
    } else {
      this.value = parentModel.filter;
    }
  }
}
