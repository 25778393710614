import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AuthState, AUTH_FEATURE_KEY, TemporaryState } from './auth.reducer';

const getAuthState = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);

const getIsAuthenticated = createSelector(getAuthState, (state: AuthState) => state.isAuthenticated);

const getAuthToken = createSelector(getAuthState, (state: AuthState) => state.token);

const getDecodedToken = createSelector(getAuthToken, state => (state ? state.decodedToken : null));

const getAuthError = createSelector(getAuthState, (state: AuthState) => state.errorMessage);

const getLoadedFromCacheState = createSelector(getAuthState, (state: AuthState) => state.isLoadedFromCache);

const getRedirectToUrlAfterLogin = createSelector(getAuthState, (state: AuthState) => state.redirectToUrlAfterLogin);

const getLoading = createSelector(getAuthState, (state: AuthState) => state.loading);

const getCountryBaseInformation = createSelector(getAuthState, (state: AuthState) => state.countryBaseInformation);

const getTemporaryState = createSelector(getAuthState, (state: AuthState) => state.temporary);

const getTemporaryLoginData = createSelector(getTemporaryState, (state: TemporaryState) => state.loginData);

const getChangePasswordProcessing = createSelector(getTemporaryState, (state: TemporaryState) => state.processing);

const getChangePasswordSuccess = createSelector(getTemporaryState, (state: TemporaryState) => state.success);

const getPasswordCompatibility = createSelector(getTemporaryState, (state: TemporaryState) => state.compatibility);

const getIsPasswordResetRequestProcessed = createSelector(
  getAuthState,
  (state: AuthState) => state.passwordResetRequest.processing
);

const getIsPasswordResetRequestSuccessful = createSelector(
  getAuthState,
  (state: AuthState) => state.passwordResetRequest.success
);

const getIsPasswordResetRequestSent = createSelector(
  getAuthState,
  (state: AuthState) => state.passwordResetRequest.sent
);

const getIsLoadingCountryBaseInformation = createSelector(
  getAuthState,
  (state: AuthState) => state.isLoadingCountryBaseInformation
);

export const authQuery = {
  getIsAuthenticated,
  getAuthToken,
  getDecodedToken,
  getAuthError,
  getLoadedFromCacheState,
  getRedirectToUrlAfterLogin,
  getLoading,
  getCountryBaseInformation,
  getTemporaryLoginData,
  getChangePasswordProcessing,
  getChangePasswordSuccess,
  getPasswordCompatibility,
  getIsPasswordResetRequestSuccessful,
  getIsPasswordResetRequestSent,
  getIsPasswordResetRequestProcessed,
  getIsLoadingCountryBaseInformation,
};
