import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BaseProvenanceCheckSectionConfirmationStatusComponent } from '../base-provenance-check-section-confirmation-status.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vpfa-mobile-provenance-check-section-confirmation-status',
  templateUrl: './mobile-provenance-check-section-confirmation-status.component.html',
  styleUrls: ['./mobile-provenance-check-section-confirmation-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileProvenanceCheckSectionConfirmationStatusComponent extends BaseProvenanceCheckSectionConfirmationStatusComponent {
  constructor(translateService: TranslateService) {
    super(translateService);
  }
}
