import { EquipmentFormulaBlockDto } from '@vpfa/rest-api/valuation';
import { chain } from 'lodash';

/**
 * Group formula blocks by `formulaSoaCode`
 *
 * @param formulaBlocks
 * @returns
 */
export function getIncompleteFormulas(
  formulaBlocks: EquipmentFormulaBlockDto[]
): {
  mainEquipmentSoaCode: string;
  steps: EquipmentFormulaBlockDto[];
}[] {
  return chain(formulaBlocks)
    .groupBy('formulaSoaCode')
    .map((value, key) => ({ mainEquipmentSoaCode: key, steps: value }))
    .value();
}
