import { Component, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams, ICellRendererParams } from 'ag-grid-community';
import { DataTableAction, DataTableActionEvent } from '../../models/data-table-action';

export const fullWidthCellGroupHeight = 68;

@Component({
  selector: 'vpfa-full-width-cell-group',
  templateUrl: './full-width-cell-group.component.html',
  styleUrls: ['./full-width-cell-group.component.scss'],
})
export class FullWidthCellGroupComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  actionEmitter: EventEmitter<DataTableActionEvent>;
  equipmentSectionItemLabel: string = 'equipmentList.items';
  text: string;
  toggled: boolean;
  itemsCount: number;

  constructor(private translate: TranslateService, private changeDetection: ChangeDetectorRef) {}

  agInit(params: any): void {
    this.params = params;

    if (this.params) {
      this.toggled = params.data.toggled;
      this.itemsCount = params.data.itemsCount;

      const context = this.params.context;
      if (context && context.componentParent && context.componentParent.actionClicked) {
        this.actionEmitter = context.componentParent.actionClicked;
      }
    }

    this.text = this.translate.instant(params.data.groupTranslationLabel);
    this.changeDetection.detectChanges();
  }

  refresh(params: any): boolean {
    return false;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    throw new Error('Method not implemented.');
  }

  emitAction() {
    if (this.itemsCount > 0) {
      this.toggled = !this.toggled;

      if (this.actionEmitter) {
        this.actionEmitter.emit({
          action: DataTableAction.TOGGLE,
          data: this.params.data,
          value: this.toggled,
        });
      }
    }
  }
}
