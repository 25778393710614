import { Action } from '@ngrx/store';
import { LoginCountryDto } from '@vpfa/rest-api/admin';

export enum AuthLocaleActionTypes {
  LoadCountry = '[Login Page] Load Country',
  LoadCountrySuccess = '[Login Page] Load Country Success',
  LoadCountryError = '[Login Page] Load Country Error',
  LoadCountryLogoAndHelp = '[Login Page] Load Country Logo and Help',
  LoadCountryLogoAndHelpSuccess = '[Login Page] Load Country Logo and Help Success',
  LoadCountryLogoAndHelpError = '[Login Page] Load Country Logo and Help Error',
}

export class LoadCountry implements Action {
  readonly type = AuthLocaleActionTypes.LoadCountry;
  constructor(public payload: { countryUrl: string }) {}
}

export class LoadCountrySuccess implements Action {
  readonly type = AuthLocaleActionTypes.LoadCountrySuccess;
  constructor(public payload: { data: LoginCountryDto }) {}
}

export class LoadCountryError implements Action {
  readonly type = AuthLocaleActionTypes.LoadCountryError;
}

/**
 * Same Action like LoadCountry but should only use Logo and Help from response.
 * Originally created for OAuth page to load Logo and Help from country by URL and
 * language, therefore country code (from UserContext) used in LanguageService remain untouched.
 */
export class LoadCountryLogoAndHelp implements Action {
  readonly type = AuthLocaleActionTypes.LoadCountryLogoAndHelp;
  constructor(public payload: { countryUrl: string }) {}
}

export class LoadCountryLogoAndHelpSuccess implements Action {
  readonly type = AuthLocaleActionTypes.LoadCountryLogoAndHelpSuccess;
  constructor(public payload: { data: LoginCountryDto }) {}
}

export class LoadCountryLogoAndHelpError implements Action {
  readonly type = AuthLocaleActionTypes.LoadCountryLogoAndHelpError;
}

export type AuthLocaleActions =
  | LoadCountry
  | LoadCountrySuccess
  | LoadCountryError
  | LoadCountryLogoAndHelp
  | LoadCountryLogoAndHelpSuccess
  | LoadCountryLogoAndHelpError;
