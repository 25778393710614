/**
 * Public SSM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0-private
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { AdminDuplicateBody } from '../model/adminDuplicateBody';
import { AdminDuplicateBody1 } from '../model/adminDuplicateBody1';
import { AdminDuplicateBody2 } from '../model/adminDuplicateBody2';
import { AdminDuplicateBody3 } from '../model/adminDuplicateBody3';
import { AdminModulesBody } from '../model/adminModulesBody';
import { AdminModulesBody1 } from '../model/adminModulesBody1';
import { AdminModulesBody2 } from '../model/adminModulesBody2';
import { AdminModulesBody3 } from '../model/adminModulesBody3';
import { AdminVisibilityBody } from '../model/adminVisibilityBody';
import { AdminVisibilityBody1 } from '../model/adminVisibilityBody1';
import { AdminVisibilityBody2 } from '../model/adminVisibilityBody2';
import { AdminVisibilityBody3 } from '../model/adminVisibilityBody3';
import { ApiPdfTemplateBody } from '../model/apiPdfTemplateBody';
import { ApiPdfTemplateBody1 } from '../model/apiPdfTemplateBody1';
import { ApiPdfTemplateBody2 } from '../model/apiPdfTemplateBody2';
import { ApiPdfTemplateBody3 } from '../model/apiPdfTemplateBody3';
import { ApiPdfTemplateBody4 } from '../model/apiPdfTemplateBody4';
import { ApiPdfTemplateBody5 } from '../model/apiPdfTemplateBody5';
import { ApiPdfTemplateBody6 } from '../model/apiPdfTemplateBody6';
import { ApiPdfTemplateBody7 } from '../model/apiPdfTemplateBody7';
import { BaseTemplateDuplicateBody } from '../model/baseTemplateDuplicateBody';
import { BaseTemplateDuplicateBody1 } from '../model/baseTemplateDuplicateBody1';
import { BaseTemplateDuplicateBody2 } from '../model/baseTemplateDuplicateBody2';
import { BaseTemplateDuplicateBody3 } from '../model/baseTemplateDuplicateBody3';
import { BaseTemplateModulesBody } from '../model/baseTemplateModulesBody';
import { BaseTemplateModulesBody1 } from '../model/baseTemplateModulesBody1';
import { BaseTemplateModulesBody2 } from '../model/baseTemplateModulesBody2';
import { BaseTemplateModulesBody3 } from '../model/baseTemplateModulesBody3';
import { BaseTemplateVisibilityBody } from '../model/baseTemplateVisibilityBody';
import { BaseTemplateVisibilityBody1 } from '../model/baseTemplateVisibilityBody1';
import { BaseTemplateVisibilityBody2 } from '../model/baseTemplateVisibilityBody2';
import { BaseTemplateVisibilityBody3 } from '../model/baseTemplateVisibilityBody3';
import { CommandHandlerResponse } from '../model/commandHandlerResponse';
import { PdfTemplateAdminBody } from '../model/pdfTemplateAdminBody';
import { PdfTemplateAdminBody1 } from '../model/pdfTemplateAdminBody1';
import { PdfTemplateAdminBody2 } from '../model/pdfTemplateAdminBody2';
import { PdfTemplateAdminBody3 } from '../model/pdfTemplateAdminBody3';
import { PdfTemplateAdminBody4 } from '../model/pdfTemplateAdminBody4';
import { PdfTemplateAdminBody5 } from '../model/pdfTemplateAdminBody5';
import { PdfTemplateAdminBody6 } from '../model/pdfTemplateAdminBody6';
import { PdfTemplateAdminBody7 } from '../model/pdfTemplateAdminBody7';
import { PdfTemplateBaseTemplateBody } from '../model/pdfTemplateBaseTemplateBody';
import { PdfTemplateBaseTemplateBody1 } from '../model/pdfTemplateBaseTemplateBody1';
import { PdfTemplateBaseTemplateBody2 } from '../model/pdfTemplateBaseTemplateBody2';
import { PdfTemplateBaseTemplateBody3 } from '../model/pdfTemplateBaseTemplateBody3';
import { PdfTemplateBaseTemplateBody4 } from '../model/pdfTemplateBaseTemplateBody4';
import { PdfTemplateBaseTemplateBody5 } from '../model/pdfTemplateBaseTemplateBody5';
import { PdfTemplateBaseTemplateBody6 } from '../model/pdfTemplateBaseTemplateBody6';
import { PdfTemplateBaseTemplateBody7 } from '../model/pdfTemplateBaseTemplateBody7';
import { PdfTemplateDuplicateBody } from '../model/pdfTemplateDuplicateBody';
import { PdfTemplateDuplicateBody1 } from '../model/pdfTemplateDuplicateBody1';
import { PdfTemplateDuplicateBody2 } from '../model/pdfTemplateDuplicateBody2';
import { PdfTemplateDuplicateBody3 } from '../model/pdfTemplateDuplicateBody3';
import { PdfTemplateModulesBody } from '../model/pdfTemplateModulesBody';
import { PdfTemplateModulesBody1 } from '../model/pdfTemplateModulesBody1';
import { PdfTemplateModulesBody2 } from '../model/pdfTemplateModulesBody2';
import { PdfTemplateModulesBody3 } from '../model/pdfTemplateModulesBody3';
import { PdfTemplateVisibilityBody } from '../model/pdfTemplateVisibilityBody';
import { PdfTemplateVisibilityBody1 } from '../model/pdfTemplateVisibilityBody1';
import { PdfTemplateVisibilityBody2 } from '../model/pdfTemplateVisibilityBody2';
import { PdfTemplateVisibilityBody3 } from '../model/pdfTemplateVisibilityBody3';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PdfTemplateService {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Lazy check AmsAction:POST/api/PdfTemplate/admin AmsResource:{country}/{business}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAdminPdfTemplate(body?: PdfTemplateAdminBody4, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public createAdminPdfTemplate(body?: PdfTemplateAdminBody4, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public createAdminPdfTemplate(body?: PdfTemplateAdminBody4, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public createAdminPdfTemplate(body?: PdfTemplateAdminBody4, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommandHandlerResponse>(`${this.basePath}/api/PdfTemplate/admin`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:POST/api/PdfTemplate/baseTemplate AmsResource:{country}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBasePdfTemplate(body?: PdfTemplateBaseTemplateBody4, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public createBasePdfTemplate(body?: PdfTemplateBaseTemplateBody4, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public createBasePdfTemplate(body?: PdfTemplateBaseTemplateBody4, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public createBasePdfTemplate(body?: PdfTemplateBaseTemplateBody4, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommandHandlerResponse>(`${this.basePath}/api/PdfTemplate/baseTemplate`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:POST/api/PdfTemplate AmsResource:{country}/{business} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPdfTemplate(body?: ApiPdfTemplateBody4, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public createPdfTemplate(body?: ApiPdfTemplateBody4, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public createPdfTemplate(body?: ApiPdfTemplateBody4, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public createPdfTemplate(body?: ApiPdfTemplateBody4, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommandHandlerResponse>(`${this.basePath}/api/PdfTemplate`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:DELETE/api/PdfTemplate/business/{pdfTemplateId} AmsResource:{country}/{business}/_* 
     * @param pdfTemplateId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAdminPdfTemplate(pdfTemplateId: string, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public deleteAdminPdfTemplate(pdfTemplateId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public deleteAdminPdfTemplate(pdfTemplateId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public deleteAdminPdfTemplate(pdfTemplateId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pdfTemplateId === null || pdfTemplateId === undefined) {
            throw new Error('Required parameter pdfTemplateId was null or undefined when calling deleteAdminPdfTemplate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<CommandHandlerResponse>(`${this.basePath}/api/PdfTemplate/business/${encodeURIComponent(String(pdfTemplateId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:DELETE/api/PdfTemplate/country/{pdfTemplateId} AmsResource:{country}/_* 
     * @param pdfTemplateId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBasePdfTemplate(pdfTemplateId: string, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public deleteBasePdfTemplate(pdfTemplateId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public deleteBasePdfTemplate(pdfTemplateId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public deleteBasePdfTemplate(pdfTemplateId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pdfTemplateId === null || pdfTemplateId === undefined) {
            throw new Error('Required parameter pdfTemplateId was null or undefined when calling deleteBasePdfTemplate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<CommandHandlerResponse>(`${this.basePath}/api/PdfTemplate/country/${encodeURIComponent(String(pdfTemplateId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:DELETE/api/PdfTemplate/{pdfTemplateId} AmsResource:{country}/{business}/_* 
     * @param pdfTemplateId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePdfTemplate(pdfTemplateId: string, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public deletePdfTemplate(pdfTemplateId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public deletePdfTemplate(pdfTemplateId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public deletePdfTemplate(pdfTemplateId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pdfTemplateId === null || pdfTemplateId === undefined) {
            throw new Error('Required parameter pdfTemplateId was null or undefined when calling deletePdfTemplate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<CommandHandlerResponse>(`${this.basePath}/api/PdfTemplate/${encodeURIComponent(String(pdfTemplateId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:POST/api/PdfTemplate/admin/duplicate AmsResource:{country}/{business}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public duplicateAdminPdfTemplate(body?: AdminDuplicateBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public duplicateAdminPdfTemplate(body?: AdminDuplicateBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public duplicateAdminPdfTemplate(body?: AdminDuplicateBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public duplicateAdminPdfTemplate(body?: AdminDuplicateBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommandHandlerResponse>(`${this.basePath}/api/PdfTemplate/admin/duplicate`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:POST/api/PdfTemplate/baseTemplate/duplicate AmsResource:{country}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public duplicateBasePdfTemplate(body?: BaseTemplateDuplicateBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public duplicateBasePdfTemplate(body?: BaseTemplateDuplicateBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public duplicateBasePdfTemplate(body?: BaseTemplateDuplicateBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public duplicateBasePdfTemplate(body?: BaseTemplateDuplicateBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommandHandlerResponse>(`${this.basePath}/api/PdfTemplate/baseTemplate/duplicate`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:POST/api/PdfTemplate/duplicate AmsResource:{country}/{business} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public duplicatePdfTemplate(body?: PdfTemplateDuplicateBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public duplicatePdfTemplate(body?: PdfTemplateDuplicateBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public duplicatePdfTemplate(body?: PdfTemplateDuplicateBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public duplicatePdfTemplate(body?: PdfTemplateDuplicateBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommandHandlerResponse>(`${this.basePath}/api/PdfTemplate/duplicate`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/PdfTemplate/admin AmsResource:{country}/{business}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAdminPdfTemplate(body?: PdfTemplateAdminBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateAdminPdfTemplate(body?: PdfTemplateAdminBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateAdminPdfTemplate(body?: PdfTemplateAdminBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateAdminPdfTemplate(body?: PdfTemplateAdminBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/PdfTemplate/admin`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/PdfTemplate/admin/modules AmsResource:{country}/{business}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAdminPdfTemplateModules(body?: AdminModulesBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateAdminPdfTemplateModules(body?: AdminModulesBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateAdminPdfTemplateModules(body?: AdminModulesBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateAdminPdfTemplateModules(body?: AdminModulesBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/PdfTemplate/admin/modules`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/PdfTemplate/admin/visibility AmsResource:{country}/{business}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAdminPdfTemplateVisibility(body?: AdminVisibilityBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateAdminPdfTemplateVisibility(body?: AdminVisibilityBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateAdminPdfTemplateVisibility(body?: AdminVisibilityBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateAdminPdfTemplateVisibility(body?: AdminVisibilityBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/PdfTemplate/admin/visibility`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/PdfTemplate/baseTemplate AmsResource:{country}/{business}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBasePdfTemplate(body?: PdfTemplateBaseTemplateBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateBasePdfTemplate(body?: PdfTemplateBaseTemplateBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateBasePdfTemplate(body?: PdfTemplateBaseTemplateBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateBasePdfTemplate(body?: PdfTemplateBaseTemplateBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/PdfTemplate/baseTemplate`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/PdfTemplate/baseTemplate/modules AmsResource:{country}/{business}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBasePdfTemplateModules(body?: BaseTemplateModulesBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateBasePdfTemplateModules(body?: BaseTemplateModulesBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateBasePdfTemplateModules(body?: BaseTemplateModulesBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateBasePdfTemplateModules(body?: BaseTemplateModulesBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/PdfTemplate/baseTemplate/modules`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/PdfTemplate/baseTemplate/visibility AmsResource:{country}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBasePdfTemplateVisibility(body?: BaseTemplateVisibilityBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateBasePdfTemplateVisibility(body?: BaseTemplateVisibilityBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateBasePdfTemplateVisibility(body?: BaseTemplateVisibilityBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateBasePdfTemplateVisibility(body?: BaseTemplateVisibilityBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/PdfTemplate/baseTemplate/visibility`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/PdfTemplate AmsResource:{country}/{business}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePdfTemplate(body?: ApiPdfTemplateBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updatePdfTemplate(body?: ApiPdfTemplateBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updatePdfTemplate(body?: ApiPdfTemplateBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updatePdfTemplate(body?: ApiPdfTemplateBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/PdfTemplate`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/PdfTemplate/modules AmsResource:{country}/{business}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePdfTemplateModules(body?: PdfTemplateModulesBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updatePdfTemplateModules(body?: PdfTemplateModulesBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updatePdfTemplateModules(body?: PdfTemplateModulesBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updatePdfTemplateModules(body?: PdfTemplateModulesBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/PdfTemplate/modules`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/PdfTemplate/visibility AmsResource:{country}/{business}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePdfTemplateVisibility(body?: PdfTemplateVisibilityBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updatePdfTemplateVisibility(body?: PdfTemplateVisibilityBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updatePdfTemplateVisibility(body?: PdfTemplateVisibilityBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updatePdfTemplateVisibility(body?: PdfTemplateVisibilityBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/PdfTemplate/visibility`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
