<ng-container *vpfaResponsiveIf="'mobile'">
  <vpfa-mobile-subtitle [title]="'vehicleFullTechnicalData.weights.label' | translate"></vpfa-mobile-subtitle>
</ng-container>
<ng-container *vpfaResponsiveIf="'web'">
  <div class="mt">
    <vpfa-expanding-header [text]="'vehicleFullTechnicalData.weights.label' | translate"></vpfa-expanding-header>
  </div>
</ng-container>

<div
  [fxLayout]="'row wrap'"
  fxLayoutGap.gt-mv="70px grid"
  fxLayout.lt-mv="column"
  fxLayoutGap.lt-mv="10px"
  ngClass.gt-mv="section"
>
  <vpfa-vehicle-technical-data-single-field
    [label]="
      ('vehicleFullTechnicalData.weights.grossVehicleWeight' | translate) +
      (technicalDataWeights?.grossVehicleWeight | unitValue)
    "
    [value]="technicalDataWeights?.grossVehicleWeight?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="!featureConfig?.hideMaxLoad"
    [label]="
      ('vehicleFullTechnicalData.weights.payloadMin' | translate) + (technicalDataWeights?.payloadMin | unitValue)
    "
    [value]="technicalDataWeights?.payloadMin?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="!featureConfig?.hideTrailerLoadBraked"
    [label]="
      ('vehicleFullTechnicalData.weights.trailerLoadBraked' | translate) +
      (technicalDataWeights?.trailerLoadBraked | unitValue)
    "
    [value]="technicalDataWeights?.trailerLoadBraked?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="!featureConfig?.hideTrailerLoadUnbraked"
    [label]="
      ('vehicleFullTechnicalData.weights.trailerLoadUnbraked' | translate) +
      (technicalDataWeights?.trailerLoadUnbraked | unitValue)
    "
    [value]="technicalDataWeights?.trailerLoadUnbraked?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="!featureConfig?.hideRoofLoad"
    [label]="('vehicleFullTechnicalData.weights.roofLoad' | translate) + (technicalDataWeights?.roofLoad | unitValue)"
    [value]="technicalDataWeights?.roofLoad?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="!featureConfig?.hideKerbWeight"
    [label]="
      ('vehicleFullTechnicalData.weights.kerbWeightIncDriver' | translate) +
      (technicalDataWeights?.kerbWeightIncDriver | unitValue)
    "
    [value]="technicalDataWeights?.kerbWeightIncDriver?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>
</div>
