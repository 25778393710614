import { Component, Input } from '@angular/core';
import { Insurance } from '@vpfa/rest-api/valuation';
import { FeaturesTechnicalDataConfiguration } from '@vpfa/locale';

@Component({
  selector: 'vpfa-full-technical-data-insurance-information',
  templateUrl: './full-technical-data-insurance-information.component.html',
  styleUrls: ['./full-technical-data-insurance-information.component.scss'],
})
export class FullTechnicalDataInsuranceInformationComponent {
  @Input() technicalDataInsurance: Insurance;
  @Input() featureConfig: FeaturesTechnicalDataConfiguration;
}
