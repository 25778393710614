<ng-container [formGroup]="parentFormGroup">
  <nz-select
    [ngClass]="colorTheme.toString()"
    [nzAllowClear]="allowClear"
    #nzSelect="nzSelect"
    [formControl]="fc"
    [nzBackdrop]="true"
    [nzDisabled]="(!useServerSideSearch && (options | isEmpty)) || disabled || fc?.disabled"
    [nzLoading]="!options || loading"
    [nzSuffixIcon]="caretDownIcon"
    [class.selected]="fc?.value"
    (ngModelChange)="onSelect($event)"
    (reset)="resetValue.emit()"
    [nzDropdownClassName]="dropdownClass"
    [nzDropdownMatchSelectWidth]="nzDropdownMatchSelectWidth || (isMobileView$ | async)"
    [nzPlaceHolder]="placeholder ? placeholder : (defaultSelectPlaceholder | translate)"
    [compareWith]="compareWith"
    [nzFilterOption]="searchFilter"
    [nzShowSearch]="enabledSearch"
    [nzMode]="mode"
    [(nzOpen)]="isOpen"
    [nzDropdownRender]="renderTemplate"
    (nzScrollToBottom)="onScrollToBottom()"
    [nzCustomTemplate]="selectedLabel"
    (nzOnSearch)="onSearch.emit($event)"
    style="width: 100%"
  >
    <ng-container *ngIf="showGroups">
      <nz-option-group *ngFor="let groupLabel of groupLabelsFromOptions" [nzLabel]="groupLabel | translate">
        <nz-option
          *ngFor="let option of optionsByGroup(groupLabel)"
          [attr.data-dd-action-name]="maskSensitiveData ? 'selectOption' : null"
          [attr.data-dd-privacy]="maskSensitiveData ? 'mask' : null"
          nzCustomContent
          [nzLabel]="option.name"
          [nzValue]="option.value"
          [nzHide]="option.hide"
        >
          <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: option }"></ng-container>
        </nz-option>
      </nz-option-group>
    </ng-container>

    <ng-container *ngIf="!showGroups">
      <nz-option
        *ngFor="let option of optionsByGroup()"
        [attr.data-dd-action-name]="maskSensitiveData ? 'selectOption' : null"
        [attr.data-dd-privacy]="maskSensitiveData ? 'mask' : null"
        nzCustomContent
        [nzLabel]="option.name"
        [nzValue]="option.value"
        [nzHide]="option.hide"
      >
        <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: option }"></ng-container>
      </nz-option>
    </ng-container>

    <ng-template #optionTemplate let-option>
      <ng-container *ngIf="customOptionTemplate; then optionTemplateOuter; else defaultOptionTemplate"></ng-container>
      <ng-template #defaultOptionTemplate>
        <div
          nz-popconfirm
          [nzPopconfirmTitle]="popconfirmText"
          [nzPopconfirmTrigger]="'click'"
          [nzCancelText]="'common.no' | translate"
          [nzOkText]="'common.yes' | translate"
          nzPopconfirmOverlayClassName="modal-confirm-popover"
          (nzOnConfirm)="onSelectAfterConfirm(option)"
          (click)="onSelectBeforeConfirm($event, option)"
        >
          <span [title]="translateLabel ? (option?.name | translate) : option?.name" class="option-text">
            {{ translateLabel ? (option?.name | translate) : option?.name }}
          </span>
        </div>
      </ng-template>
      <ng-template #optionTemplateOuter>
        <ng-container
          *ngTemplateOutlet="
            customOptionTemplate;
            context: {
              label: translateLabel ? (option?.name | translate) : option?.name,
              option: option?.value,
              additional: option?.additional
            }
          "
        ></ng-container>
      </ng-template>
    </ng-template>
  </nz-select>
</ng-container>

<ng-template #selectedLabel let-selected>
  <ng-container *ngIf="customSelectedTemplate; then customSelectedTemplateWrap; else baseSelectedLabel"></ng-container>
  <ng-template #customSelectedTemplateWrap>
    <ng-container
      *ngTemplateOutlet="
        customSelectedTemplate;
        context: {
          label: translateLabel ? (selected.nzValue.name | translate) : selected.nzValue.name,
          option: selected.nzValue,
          additional: selected.nzValue?.additional
        }
      "
    ></ng-container>
  </ng-template>
  <ng-template #baseSelectedLabel>
    {{ translateLabel ? (selected.nzValue.name | translate) : selected.nzValue.name }}
  </ng-template>
</ng-template>

<ng-template #caretDownIcon>
  <ng-container *ngIf="isOpen; else elseTemplate">
    <span *ngIf="!enabledSearch" nz-icon nzType="caret-up" nzTheme="fill"></span>
    <span *ngIf="enabledSearch" nz-icon nzType="search" nzTheme="outline"></span>
  </ng-container>
  <ng-template #elseTemplate>
    <span nz-icon nzType="caret-down" nzTheme="fill"></span>
  </ng-template>
</ng-template>

<ng-template #renderTemplate>
  <div *ngIf="loading && useInfiniteScroll" style="height: 20px">
    <nz-spin [nzSpinning]="loading"></nz-spin>
  </div>
</ng-template>
