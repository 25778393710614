/**
 * Generic Transform Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { ModelInputData } from '../model/modelInputData';
import { ModelObject } from '../model/modelObject';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TransformService {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param templateName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public renderTemplate(body?: ModelObject, templateName?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public renderTemplate(body?: ModelObject, templateName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public renderTemplate(body?: ModelObject, templateName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public renderTemplate(body?: ModelObject, templateName?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (templateName !== undefined && templateName !== null) {
          queryParameters = queryParameters.set('templateName', <any>templateName);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/api/Transform`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param template 
     * @param jsonInputData 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public renderTemplateFromFile(template?: Blob, jsonInputData?: Blob, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public renderTemplateFromFile(template?: Blob, jsonInputData?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public renderTemplateFromFile(template?: Blob, jsonInputData?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public renderTemplateFromFile(template?: Blob, jsonInputData?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any) };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (template !== undefined) {
            formParams = formParams.append('template', <any>template) || formParams;
        }
        if (jsonInputData !== undefined) {
            formParams = formParams.append('jsonInputData', <any>jsonInputData) || formParams;
        }

        return this.httpClient.post<string>(`${this.basePath}/api/Transform/File`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Provide a template file and one or more named model files to transform - up to 5 models.  Note that it accepts Form data instead of json so that json model content can be provided without being escaped - this makes testing from the API/Swagger easier.  It is limited to 5 models due to a limitation of Swagger
     * 
     * @param template 
     * @param jsonInputDataFile1 
     * @param jsonInputDataFile2 
     * @param jsonInputDataFile3 
     * @param jsonInputDataFile4 
     * @param jsonInputDataFile5 
     * @param modelName1 
     * @param modelName2 
     * @param modelName3 
     * @param modelName4 
     * @param modelName5 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public renderTemplateFromMultipleJsonInputFiles(template?: Blob, jsonInputDataFile1?: Blob, jsonInputDataFile2?: Blob, jsonInputDataFile3?: Blob, jsonInputDataFile4?: Blob, jsonInputDataFile5?: Blob, modelName1?: string, modelName2?: string, modelName3?: string, modelName4?: string, modelName5?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public renderTemplateFromMultipleJsonInputFiles(template?: Blob, jsonInputDataFile1?: Blob, jsonInputDataFile2?: Blob, jsonInputDataFile3?: Blob, jsonInputDataFile4?: Blob, jsonInputDataFile5?: Blob, modelName1?: string, modelName2?: string, modelName3?: string, modelName4?: string, modelName5?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public renderTemplateFromMultipleJsonInputFiles(template?: Blob, jsonInputDataFile1?: Blob, jsonInputDataFile2?: Blob, jsonInputDataFile3?: Blob, jsonInputDataFile4?: Blob, jsonInputDataFile5?: Blob, modelName1?: string, modelName2?: string, modelName3?: string, modelName4?: string, modelName5?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public renderTemplateFromMultipleJsonInputFiles(template?: Blob, jsonInputDataFile1?: Blob, jsonInputDataFile2?: Blob, jsonInputDataFile3?: Blob, jsonInputDataFile4?: Blob, jsonInputDataFile5?: Blob, modelName1?: string, modelName2?: string, modelName3?: string, modelName4?: string, modelName5?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {












        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (modelName1 !== undefined && modelName1 !== null) {
          queryParameters = queryParameters.set('modelName1', <any>modelName1);
        }
        if (modelName2 !== undefined && modelName2 !== null) {
          queryParameters = queryParameters.set('modelName2', <any>modelName2);
        }
        if (modelName3 !== undefined && modelName3 !== null) {
          queryParameters = queryParameters.set('modelName3', <any>modelName3);
        }
        if (modelName4 !== undefined && modelName4 !== null) {
          queryParameters = queryParameters.set('modelName4', <any>modelName4);
        }
        if (modelName5 !== undefined && modelName5 !== null) {
          queryParameters = queryParameters.set('modelName5', <any>modelName5);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any) };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (template !== undefined) {
            formParams = formParams.append('template', <any>template) || formParams;
        }
        if (jsonInputDataFile1 !== undefined) {
            formParams = formParams.append('jsonInputDataFile1', <any>jsonInputDataFile1) || formParams;
        }
        if (jsonInputDataFile2 !== undefined) {
            formParams = formParams.append('jsonInputDataFile2', <any>jsonInputDataFile2) || formParams;
        }
        if (jsonInputDataFile3 !== undefined) {
            formParams = formParams.append('jsonInputDataFile3', <any>jsonInputDataFile3) || formParams;
        }
        if (jsonInputDataFile4 !== undefined) {
            formParams = formParams.append('jsonInputDataFile4', <any>jsonInputDataFile4) || formParams;
        }
        if (jsonInputDataFile5 !== undefined) {
            formParams = formParams.append('jsonInputDataFile5', <any>jsonInputDataFile5) || formParams;
        }

        return this.httpClient.post<string>(`${this.basePath}/api/Transform/multiplefiles`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Provide a template name (or template content for testing) and one or more named models to transform.  Note that it accepts Form data instead of json so that json model content can be provided without being escaped - making testing from the API from Postman/curl easier   (Swagger does not process this endpoint correctly so cannot be used, instead use the api/transform/multiplefiles endpoint)
     * 
     * @param templateName 
     * @param templateContent 
     * @param modelInputData 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public renderTemplateFromMultipleJsonInputs(templateName?: string, templateContent?: string, modelInputData?: Array<ModelInputData>, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public renderTemplateFromMultipleJsonInputs(templateName?: string, templateContent?: string, modelInputData?: Array<ModelInputData>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public renderTemplateFromMultipleJsonInputs(templateName?: string, templateContent?: string, modelInputData?: Array<ModelInputData>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public renderTemplateFromMultipleJsonInputs(templateName?: string, templateContent?: string, modelInputData?: Array<ModelInputData>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any) };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (templateName !== undefined) {
            formParams = formParams.append('TemplateName', <any>templateName) || formParams;
        }
        if (templateContent !== undefined) {
            formParams = formParams.append('TemplateContent', <any>templateContent) || formParams;
        }
        if (modelInputData) {
            modelInputData.forEach((element) => {
                formParams = formParams.append('ModelInputData', <any>element) || formParams;
            })
        }

        return this.httpClient.post<string>(`${this.basePath}/api/Transform/multiple`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
