import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { DataTableAction } from '@vpfa/ui-kit/data-table';
import { isString } from 'lodash';

export interface ActionListItem {
  name: string;
  action: DataTableAction;
  question?: string | ElementRef;
}

@Component({
  selector: 'vpfa-action-dropdown',
  templateUrl: './action-dropdown.component.html',
  styleUrls: ['./action-dropdown.component.scss'],
})
export class ActionDropdownComponent {
  isString = isString;

  @Output() action = new EventEmitter<{ action: DataTableAction; data: any }>();
  dataTableActionMenuVisible: any;
  @Input() data: any;
  @Input() actionList: Array<ActionListItem> = [];
  emitAction(action: DataTableAction) {
    this.dataTableActionMenuVisible = false;
    if (this.action) {
      this.action.emit({
        action: action,
        data: this.data,
      });
    }
  }
}
