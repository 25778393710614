import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import {
  ChangePasswordResult,
  CommandHandlerResponse,
  ExperianCredentialsDto,
  UpdateExperianCredentialsCommand,
  UpdateProfileCommand,
  UpdateUserDefaultLanguageCommand,
} from '@vpfa/rest-api/admin';
import { ChangePasswordDto } from '../models/change-password-command-dto';

export enum UserProfileActionTypes {
  UpdateUserDefaultLanguage = '[UserProfile] Update User Default Language',
  UserDefaultLanguageUpdated = '[UserProfile] User Default Language Updated',
  UserDefaultLanguageUpdateError = '[UserProfile] User Default Language Update Error',
  UpdateUserUILanguage = '[UserProfile] Update User UI Language',
  UserUILanguageUpdated = '[UserProfile] User UI Language Updated',
  UserUILanguageUpdateError = '[UserProfile] User UI Language Update Error',
  UpdateUserContentLanguage = '[UserProfile] Update User Content Language',
  UserContentLanguageUpdated = '[UserProfile] User Content Language Updated',
  UserContentLanguageUpdateError = '[UserProfile] User Content Language Update Error',
  UserSettingsUpdated = '[UserProfile] User Settings Updated',
  ResetFormStatus = '[UserProfile] Reset Form Status',
  ChangePassword = '[UserProfile] Change Password',
  ChangePasswordSuccess = '[UserProfile] Change Password Success',
  ChangePasswordError = '[UserProfile]  Change Password Error',
  OpenPasswordModal = '[UserProfile] Open Password Modal',
  ClosePasswordModal = '[UserProfile] Close Password Modal',
  UpdateUserProfile = '[UserProfile] Update User Profile',
  UpdateUserProfileSuccess = '[UserProfile] Update User Profile Success',
  UpdateUserProfileError = '[UserProfile] Update User Profile Error',

  PasswordCompatibility = '[UserProfile] Password Compatibility',

  LoadExperianCredentials = '[UserProfile] Load Experian Credentials',
  LoadExperianCredentialsSuccess = '[UserProfile] Load Experian Credentials Success',
  LoadExperianCredentialsError = '[UserProfile] Load Experian Credentials Error',

  UpdateExperianCredentials = '[UserProfile] Update Experian Credentials',
  UpdateExperianCredentialsSuccess = '[UserProfile] Update Experian Credentials Success',
  UpdateExperianCredentialsError = '[UserProfile] Update Experian Credentials Error',
}

export class UpdateUserDefaultLanguage implements Action {
  readonly type = UserProfileActionTypes.UpdateUserDefaultLanguage;
  constructor(public payload: UpdateUserDefaultLanguageCommand) {}
}

export class UserDefaultLanguageUpdateError implements Action {
  readonly type = UserProfileActionTypes.UserDefaultLanguageUpdateError;
  constructor(public payload: any) {}
}

export class UserDefaultLanguageUpdated implements Action {
  readonly type = UserProfileActionTypes.UserDefaultLanguageUpdated;
}

export class UpdateUserUILanguage implements Action {
  readonly type = UserProfileActionTypes.UpdateUserUILanguage;
  constructor(public payload: UpdateUserDefaultLanguageCommand) {}
}

export class UserUILanguageUpdated implements Action {
  readonly type = UserProfileActionTypes.UserUILanguageUpdated;
}

export class UserUILanguageUpdateError implements Action {
  readonly type = UserProfileActionTypes.UserUILanguageUpdateError;
  constructor(public payload: any) {}
}

export class UpdateUserContentLanguage implements Action {
  readonly type = UserProfileActionTypes.UpdateUserContentLanguage;
  constructor(public payload: UpdateUserDefaultLanguageCommand) {}
}

export class UserContentLanguageUpdated implements Action {
  readonly type = UserProfileActionTypes.UserContentLanguageUpdated;
}

export class UserContentLanguageUpdateError implements Action {
  readonly type = UserProfileActionTypes.UserContentLanguageUpdateError;
  constructor(public payload: any) {}
}

export class UserSettingsUpdated implements Action {
  readonly type = UserProfileActionTypes.UserSettingsUpdated;
}

export class ResetFormStatus implements Action {
  readonly type = UserProfileActionTypes.ResetFormStatus;
}

export class ChangePassword implements Action {
  readonly type = UserProfileActionTypes.ChangePassword;
  constructor(public payload: ChangePasswordDto) {}
}

export class ChangePasswordSuccess implements Action {
  readonly type = UserProfileActionTypes.ChangePasswordSuccess;
  constructor(public payload: ChangePasswordResult) {}
}

export class ChangePasswordError implements Action {
  readonly type = UserProfileActionTypes.ChangePasswordError;
  constructor(public payload: HttpErrorResponse) {}
}

export class OpenPasswordModal implements Action {
  readonly type = UserProfileActionTypes.OpenPasswordModal;
}

export class ClosePasswordModal implements Action {
  readonly type = UserProfileActionTypes.ClosePasswordModal;
}

export class UpdateUserProfile implements Action {
  readonly type = UserProfileActionTypes.UpdateUserProfile;
  constructor(public payload: UpdateProfileCommand) {}
}

export class UpdateUserProfileSuccess implements Action {
  readonly type = UserProfileActionTypes.UpdateUserProfileSuccess;
  constructor(public payload: CommandHandlerResponse) {}
}

export class UpdateUserProfileError implements Action {
  readonly type = UserProfileActionTypes.UpdateUserProfileError;
}

export class PasswordCompatibility implements Action {
  readonly type = UserProfileActionTypes.PasswordCompatibility;
  constructor(public payload: boolean) {}
}

export class LoadExperianCredentials implements Action {
  readonly type = UserProfileActionTypes.LoadExperianCredentials;
}

export class LoadExperianCredentialsSuccess implements Action {
  readonly type = UserProfileActionTypes.LoadExperianCredentialsSuccess;
  constructor(public payload: ExperianCredentialsDto) {}
}

export class LoadExperianCredentialsError implements Action {
  readonly type = UserProfileActionTypes.LoadExperianCredentialsError;
}

export class UpdateExperianCredentials implements Action {
  readonly type = UserProfileActionTypes.UpdateExperianCredentials;
  constructor(public payload: UpdateExperianCredentialsCommand) {}
}

export class UpdateExperianCredentialsSuccess implements Action {
  readonly type = UserProfileActionTypes.UpdateExperianCredentialsSuccess;
  constructor(public payload: CommandHandlerResponse) {}
}

export class UpdateExperianCredentialsError implements Action {
  readonly type = UserProfileActionTypes.UpdateExperianCredentialsError;
}


export type UserProfileAction =
  | UpdateUserDefaultLanguage
  | UserDefaultLanguageUpdated
  | UserDefaultLanguageUpdateError
  | UpdateUserUILanguage
  | UserUILanguageUpdated
  | UserUILanguageUpdateError
  | UpdateUserContentLanguage
  | UserContentLanguageUpdated
  | UserContentLanguageUpdateError
  | UserSettingsUpdated
  | ResetFormStatus
  | ChangePassword
  | ChangePasswordSuccess
  | ChangePasswordError
  | OpenPasswordModal
  | ClosePasswordModal
  | UpdateUserProfile
  | UpdateUserProfileSuccess
  | UpdateUserProfileError
  | PasswordCompatibility
  | LoadExperianCredentials
  | LoadExperianCredentialsSuccess
  | LoadExperianCredentialsError
  | UpdateExperianCredentials
  | UpdateExperianCredentialsSuccess
  | UpdateExperianCredentialsError;

export const fromUserProfileActions = {
  UpdateUserDefaultLanguage,
  UserDefaultLanguageUpdated,
  UserDefaultLanguageUpdateError,
  UpdateUserUILanguage,
  UserUILanguageUpdated,
  UserUILanguageUpdateError,
  UpdateUserContentLanguage,
  UserContentLanguageUpdated,
  UserContentLanguageUpdateError,
  UserSettingsUpdated,
  ResetFormStatus,
  ChangePassword,
  ChangePasswordSuccess,
  ChangePasswordError,
  OpenPasswordModal,
  ClosePasswordModal,
  UpdateUserProfile,
  UpdateUserProfileSuccess,
  UpdateUserProfileError,
  PasswordCompatibility,
  LoadExperianCredentials,
  LoadExperianCredentialsSuccess,
  LoadExperianCredentialsError,
  UpdateExperianCredentials,
  UpdateExperianCredentialsSuccess,
  UpdateExperianCredentialsError,
};
