import { PermissionsActions, PermissionsActionTypes } from './permissions.actions';
import { produce } from 'immer';
import { AmsPermissions } from '@vpfa/rest-api/admin';
export const permissionsFeatureKey = 'permissions';

export interface State {
  loading: boolean;
  loaded: boolean;
  permissions: AmsPermissions;
}

export const initialState: State = {
  loading: false,
  loaded: false,
  permissions: null,
};

export function reducer(state = initialState, action: PermissionsActions): State {
  switch (action.type) {
    case PermissionsActionTypes.LoadPermissions:
      return produce<State>(state, newState => {
        newState.loading = true;
      });
    case PermissionsActionTypes.PermissionsLoaded:
      return produce<State>(state, newState => {
        newState.permissions = action.payload;
        newState.loading = false;
        newState.loaded = true;
      });
    case PermissionsActionTypes.LoadPermissionsError:
      return produce<State>(state, newState => {
        newState.loading = false;
        newState.loaded = true;
      });
    case PermissionsActionTypes.ClearPermissions:
      return initialState;
    default:
      return state;
  }
}
