import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vpfa-reseller-responses-link',
  templateUrl: './reseller-responses-link.component.html',
  styleUrls: ['./reseller-responses-link.component.scss'],
})
export class ResellerResponsesLinkComponent {
  @Input() unreadResellerResponses: number;
  @Input() flaggedResellerResponses: number;
  @Input() loaded: boolean;
  @Output() unreadReplies = new EventEmitter();
  @Output() flaggedReplies = new EventEmitter();

  selectUnreadReplies() {
    this.unreadReplies.emit();
  }

  selectFlaggedReplies() {
    this.flaggedReplies.emit();
  }
}
