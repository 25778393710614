import { Component, ElementRef, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { isEllipsisActive } from '@vpfa/utils';

export interface DataTableMultiValueParams extends ICellRendererParams {
  showTooltip: boolean;
}

@Component({
  selector: 'vpfa-truncate-renderer',
  templateUrl: './truncate-renderer.component.html',
  styleUrls: ['./truncate-renderer.component.scss'],
})
export class TruncateRendererComponent implements ICellRendererAngularComp {
  params: DataTableMultiValueParams;

  @ViewChild('textElement', { read: ElementRef }) textElement: ElementRef;

  agInit(params: DataTableMultiValueParams): void {
    this.params = params;
  }

  get value() {
    return this.params?.value;
  }

  get showTooltip(): boolean {
    return this.params?.showTooltip;
  }

  get isEllipsisActive() {
    return this.showTooltip && this.value && this.textElement && isEllipsisActive(this.textElement?.nativeElement);
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
}
