import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {BreadcrumbService} from 'angular-crumbs';
import { CaseSummaryDto } from '@vpfa/rest-api/valuation';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { isNil } from 'lodash';

@Component({
  selector: 'vpfa-case-breadcrumb-helper',
  templateUrl: './case-breadcrumb-helper.component.html'
})
export class CaseBreadcrumbHelperComponent implements OnInit {

  constructor(private route: ActivatedRoute, private breadcrumbService: BreadcrumbService) { }

  ngOnInit() {
    of(null).pipe(delay(0)).subscribe(() => {
      const caseData: CaseSummaryDto = this.route.snapshot.data.case;
      if(!isNil(caseData)) {
        this.breadcrumbService.changeBreadcrumb(this.route.snapshot, `${caseData.vehicle.make} ${caseData.vehicle.edition}`)
      }
    })
  }

}
