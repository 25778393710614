<vpfa-modal
  width="750px"
  modalName="Price History"
  [showBackdrop]="true"
  [isVisible]="visible"
  [isClosable]="false"
  [keyboard]="true"
  [maskClosable]="false"
  (closeAction)="close()"
>
  <vpfa-modal-content>
    <button class="ant-modal-close" nz-button nzType="link" (click)="close()">
      <i nz-icon nzType="close-circle" nzTheme="fill"></i>
    </button>
    <div class="main-container price-history-modal">
      <h1>{{ name | translate }}</h1>
      <div class="current-price">
        {{ price | currencyLocale: null:null:'1.2-2' }}
      </div>
      <div [fxLayout]="'row'" [fxLayoutAlign]="'space-between center'" class="current-tax">
        <span >
          {{ 'pricingDetailsModal.priceHistory' | translate }}
        </span>
        <vpfa-price-tax *ngIf="tax" [tax]="tax | currencyLocale: null:null:'1.0'" [caseTax]="caseTax"></vpfa-price-tax>
      </div>

      <vpfa-price-history-list [caseTax]="caseTax" [history]="history"></vpfa-price-history-list>

      <div class="tax-des">
        <vpfa-tax-information [tax]="caseTax"></vpfa-tax-information>
      </div>

      <div class="cancel">
        <vpfa-action-button (click)="close()" [outline]="true" [border]="false">
          {{ 'common.close' | translate }}
        </vpfa-action-button>
      </div>
    </div>
  </vpfa-modal-content>
</vpfa-modal>
