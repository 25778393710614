import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { environment } from '@env/environment';
import { NavigationStart, Router } from '@angular/router';
import { EnvironmentsService } from '@vpfa/environments/data';
import { mixPanelInit } from './mixpanel';
import { filter } from 'rxjs/operators';
import { MixpanelEvent } from './mixpanel-event.enum';
import { BranchDto, BusinessDto, ProfileDto, UserContext } from '@vpfa/rest-api/admin';
import { isNil } from 'lodash';
import { flatObject } from '../objects/flat-object';
import { endpointsWithSensitiveData } from '../url/endpoints-with-sensitive-data';

const removedBySecurityReasonsInfo = '[REMOVED_BY_SECURITY_REASONS]';
const sensitiveDataFields = ['password', 'newPassword', 'confirmNewPassword', 'temporaryPassword'];

@Injectable({ providedIn: 'root' })
export class MixpanelService {
  public static readonly COOKIE_PATTERN = 'mp_*';

  isEnabled = false;
  isEnabledByCookies = false;

  private _isInitialized = false;
  private trackQueue: { id: MixpanelEvent; action: any }[] = [];
  private userContext: UserContext;
  private userProfile: ProfileDto;
  private userBranch: BranchDto;
  private userBusiness: BusinessDto;
  private isExcludeCloseNavigationTab = false;

  constructor(private router: Router, private environmentsService: EnvironmentsService) {
    this.isEnabled = environment.mixPanelEnabled;
    if (this.isEnabled) {
      // @ts-ignore
      mixPanelInit(document, window.mixpanel || []);
      this.router.events
        .pipe(
          filter(() => this.isEnabledByCookies),
          filter(navigation => navigation instanceof NavigationStart)
        )
        .subscribe(() => {
          this.trackWithTime(MixpanelEvent.PageView);
        });
    }
  }

  enableMixpanel() {
    if (this.isEnabled) {
      this.isEnabledByCookies = true;
    }
  }

  disableMixpanel() {
    if (this.isEnabled) {
      this._isInitialized = false;
      this.isEnabledByCookies = false;
    }
  }

  /**
   * Used to exclude CloseBrowserTab. When when user is logging off browser tab is refreshed and it is
   * incorrectly interpreted as closing.
   */
  excludeCloseNavigationTab() {
    this.isExcludeCloseNavigationTab = true;
  }

  closeNavigationTab() {
    if (!this.isExcludeCloseNavigationTab) {
      this.track(MixpanelEvent.CloseBrowserTab);
    }
  }

  setUserBranchAndBusiness(branch: BranchDto, business: BusinessDto) {
    this.userBranch = branch;
    this.userBusiness = business;
  }

  setUserContextAndProfile(userContext: UserContext, profile: ProfileDto) {
    this.userContext = userContext;
    this.userProfile = profile;
  }

  /**
   * Initialize mixpanel.
   */
  init(): void {
    if (this.isEnabledByCookies) {
      if (!this._isInitialized && this.environmentsService.config.mixpanelToken) {
        mixpanel.init(this.environmentsService.config.mixpanelToken);
        this._isInitialized = true;
        this.afterInit();
      }

      if (this.userContext && this._isInitialized) {
        mixpanel.identify(this.userContext.email);
      }
    }
  }

  get isInit(): boolean {
    return this._isInitialized;
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {MixpanelEvent} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   */
  track(id: MixpanelEvent, action: any = {}): void {
    if (!this.isEnabledByCookies || !this.isEnabled) {
      return;
    }

    if ('userData' in action) {
      console.warn('`userData` key is used internally in MixpanelService and will be rewritten');
    }

    let userContextData = {};

    if (!isNil(this.userBranch)) {
      userContextData = {
        userData: {
          branchName: this.userBranch.branchName,
          branchId: this.userBranch.branchId,
          businessName: this.userBusiness.businessName,
          businessId: this.userBusiness.businessId,
        },
      };
    }

    const data = this.removeSensitiveData(flatObject(Object.assign(userContextData, action)));

    if (!this.isInit) {
      this.trackQueue.push({ id, action: data });
      this.init();
    } else {
      if (id === MixpanelEvent.LoginSuccess) {
        mixpanel.people.set({
          $email: this.userContext.email,
          $first_name: this.userProfile.firstName,
          $last_name: this.userProfile.lastName,
          $name: `${this.userProfile.firstName} ${this.userProfile.lastName}`,
          'Branch Id': this.userBranch.branchId,
          'Branch Name': this.userBranch.branchName,
          'Business Id': this.userBusiness.businessId,
          'Business Name': this.userBusiness.businessName,
        });
        mixpanel.identify(this.userContext.email);
      }

      mixpanel.track(id, data);

      if (id === MixpanelEvent.Logout) {
        mixpanel.reset();
      }
    }
  }

  removeSensitiveData(data: { [key: string]: any }) {
    // flatObject function ignores Map object, as a side effect authorization header is absent at the moment.
    // Two ifs below are here to remove `authorization` header when flatObject starts supporting Map in future
    if (!isNil(data['request.headers.authorization'])) {
      data['request.headers.authorization'] = removedBySecurityReasonsInfo;
    }

    if (!isNil(data['request.headers.headers.authorization'])) {
      data['request.headers.headers.authorization'] = removedBySecurityReasonsInfo;
    }

    let found = false;
    for (const sensitiveDataUrl of endpointsWithSensitiveData) {
      const sensitiveDataUrlLowerCase = sensitiveDataUrl.toLowerCase();
      const urlLowerCase = data?.['request.url']?.toLowerCase();

      if (urlLowerCase?.includes(sensitiveDataUrlLowerCase)) {
        found = true;
        break;
      }
    }

    if (found === false) return data;

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        sensitiveDataFields.forEach(sensitiveFieldKey => {
          const sensitiveFieldKeyLowercase = sensitiveFieldKey.toLowerCase();
          const keyLowercase = key.toLowerCase();

          if (keyLowercase.includes(sensitiveFieldKeyLowercase)) {
            data[key] = removedBySecurityReasonsInfo;
          }
        });
      }
    }

    return data;
  }

  /**
   * Push new action to mixpanel with time tracking.
   *
   * Time will be tracked till next event of the id will be emitted.
   *
   * @param {MixpanelEvent} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   */
  trackWithTime(id: MixpanelEvent, action: any = {}): void {
    this.track(id, action);
    this.trackTime(id);
  }

  /**
   * Start tracking time fom now until track event with provided id will occur.
   *
   * @param id Name of the action to
   */
  trackTime(id: MixpanelEvent) {
    if (this.isEnabled && this.isEnabledByCookies) {
      mixpanel.time_event(id);
    }
  }

  private afterInit() {
    this.trackQueue.forEach(trackItem => {
      this.track(trackItem.id, trackItem.action);
    });

    this.trackQueue = [];
  }
}
