import { ValidatorFn, AbstractControl, Validators } from '@angular/forms';
import { isNil } from 'lodash';

// Here is a demo for this regexp https://regex101.com/r/NatuPa/1
// Regexp is converted from c# regexp to js regexp base on article https://docs.microsoft.com/en-us/dotnet/standard/base-types/how-to-verify-that-strings-are-in-valid-email-format
// Due to Safari not supporting regexp lookbehinds (https://caniuse.com/#feat=js-regexp-lookbehind), frontend validation doesn't allow two characters that are allowed by backend: # and "

// TODO: If it will be needed we also should normalize the domain somehow in the same way as it is in above article. We have test for that kind of domain but now it is skip.

const emailCSharpValidator = /^\w([\.-]?\w)*@((\[(\d{1,3}\.){3}\d{1,3}\])|(([0-9a-z][-0-9a-z]*[0-9a-z]*\.)+[a-z0-9][\-a-z0-9]{0,22}[a-z0-9]))$/i;

export const emailValidator: ValidatorFn = (control: AbstractControl) => {
  const validateErrors = Validators.pattern(emailCSharpValidator)(control);

  if (isNil(validateErrors)) {
    return null;
  }

  return {
    email: true,
  };
};
