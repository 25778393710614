<div fxLayout="column">
  <div
    [ngClass]="{ disabled: isLoading || !isExpandable, 'cursor-pointer': !isLoading && isExpandable }"
    fxLayout="row"
    fxLayoutGap="5px"
    class="expander"
    (click)="onHeaderClick()"
  >
    <div fxLayout="column">
      <span class="box skew dark-grey"></span>
      <span *ngIf="!showToggleIndicator" class="title-width container-name expander-label" fxFlexAlign="end">{{
        name
      }}</span>
      <div *ngIf="showToggleIndicator" [fxLayout]="'row'" [fxLayoutAlign]="'start end'" [fxLayoutGap]="'10px'">
        <i
          class="zmdi"
          [ngClass]="{
            'zmdi-plus': !expanded,
            'zmdi-minus': expanded
          }"
        ></i>
        <span class="title-width container-name expander-label" fxFlexAlign="end">{{ name }}</span>
      </div>
    </div>
    <div fxLayout="column" class="content-width content-height">
      <span class="box box-small skew light-grey"></span>
      <div fxLayout="row" fxFlex>
        <vpfa-tooltip
          *ngIf="tooltipContent"
          fxFlex="4"
          [content]="tooltipContent"
          class="tooltip-margin"
        ></vpfa-tooltip>
        <ng-content select="[tooltip-section]"></ng-content>
        <div fxFlex="6" fxLayout="row" fxLayoutAlign="center flex-start">
          <div class="loader">
            <vpfa-inline-loader *ngIf="isLoading"></vpfa-inline-loader>
          </div>
        </div>
        <div class="left-column">
          <div class="left-section" *ngIf="expanded"><ng-content select="[left-section]"></ng-content></div>
        </div>
        <div class="right-column">
          <div fxFlex fxLayout="row" fxLayoutAlign="end center">
            <span class="toggle-label" *ngIf="!expanded && !notAvailable">
              <ng-container *nzStringTemplateOutlet="toggleLabel">{{ toggleLabel }}</ng-container></span
            >
            <vpfa-price-with-icon
              [ngClass]="{ 'toggle-label': !expanded }"
              *ngIf="notAvailable"
              [color]="'orange'"
            ></vpfa-price-with-icon>
            <div class="right-section" *ngIf="expanded"><ng-content select="[right-section]"></ng-content></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div *ngIf="expanded" @collapseExpand>
      <ng-content></ng-content>
    </div>
  </div>
</div>
