<vpfa-modal
  modalName="Change Print Template Module"
  width="650px"
  [showBackdrop]="true"
  [isVisible]="show"
  [isClosable]="false"
  [keyboard]="false"
  [maskClosable]="false"
  (closeAction)="close()"
>
  <vpfa-modal-content>
    <vpfa-action-button-with-confirmation
      class="ant-modal-close"
      icon="close-circle"
      [showPopconfirm]="!modulesForm.pristine"
      (onConfirm)="close()"
    ></vpfa-action-button-with-confirmation>

    <form (ngSubmit)="updateModules()" [formGroup]="modulesForm">
      <nz-spin [nzSpinning]="loading">
        <h1>{{ 'admin.printTemplates.modal.changeModules' | translate }}</h1>
        <div>
          <vpfa-setting-box [divider]="false">
            <ng-container label>
              <vpfa-label [text]="'admin.printTemplates.modal.name' | translate"></vpfa-label>
            </ng-container>
            <ng-container content>{{ name }}</ng-container>
          </vpfa-setting-box>
          <div class="template-form-box modules-container">
            <vpfa-label [text]="'admin.printTemplates.modal.applicationModules' | translate"></vpfa-label>
            <div
              class="modules d-flex gap-8px flex-align-items-center"
              *ngFor="let module of moduleArray.controls"
            >
              <ng-container [formGroup]="module">
                <vpfa-switch [fc]="module.get('available')" [parentFormGroup]="modulesForm"></vpfa-switch>
                <span class="ml-2">{{
                  'admin.printTemplates.modal.module' + module.get('templateModuleEnum')?.value | translate
                }}</span>
              </ng-container>
            </div>
          </div>

          <div class="customer-button-box mt-3">
            <vpfa-action-button buttonType="submit" [disabled]="modulesForm.invalid">
              {{ 'admin.printTemplates.modal.saveTemplate' | translate }}
            </vpfa-action-button>
            <vpfa-action-button-with-confirmation
              [showPopconfirm]="!modulesForm.pristine"
              (onConfirm)="close()"
            ></vpfa-action-button-with-confirmation>
          </div>
        </div>
      </nz-spin>
    </form>
  </vpfa-modal-content>
</vpfa-modal>
