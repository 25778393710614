import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'percentValue',
})
export class PercentValuePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string | number): string {
    return !isNil(value) ? `${value}%` : this.translateService.instant('common.noValue');
  }
}
