<vpfa-modal
  modalName="Valuations Unique Data"
  [showBackdrop]="true"
  [isVisible]="open"
  [isClosable]="false"
  [keyboard]="false"
  [maskClosable]="false"
  (closeAction)="closeModal.emit()"
>
  <vpfa-modal-content>
    <button
      class="ant-modal-close"
      nz-button
      nzType="link"
      nz-popconfirm
      [nzCondition]="formGroup.pristine"
      [nzPopconfirmTitle]="'common.unsavedChanges' | translate"
      [nzCancelText]="'common.no' | translate"
      [nzOkText]="'common.yes' | translate"
      nzPopconfirmPlacement="top"
      nzPopconfirmOverlayClassName="modal-confirm-popover"
      (nzOnConfirm)="onClose()"
    >
      <i nz-icon nzType="close-circle" nzTheme="fill"></i>
    </button>
    <form (ngSubmit)="onSubmit()" [formGroup]="formGroup" fxFlex fxLayout="column">
      <h1>{{ 'uniqueData.modal.title' | translate }}</h1>
      <nz-spin [nzSpinning]="loading">
        <div class="modal-content">
          <vpfa-unique-data-tax [taxes]="taxes" [parentFormGroup]="formGroup"> </vpfa-unique-data-tax>
          <vpfa-unique-data-valuation
            [parentFormGroup]="formGroup"
            [branchList]="branchList"
            [initialData]="initialData?.valuationUniqueData"
            [dataValuationOptions]="dataValuationOptions"
            [hideInteriorTrimColour]="hideInteriorTrimColour"
            [hideBuildDate]="hideBuildDate"
            [buildDateNotAvailable]="buildDateNotAvailable"
            [isCaseIdentifiedByVin]="isCaseIdentifiedByVin"
            (isBranchIdModified)="isBranchIdModified = $event"
          ></vpfa-unique-data-valuation>
        </div>
        <div class="button-box" fxFlex fxLayout="column">
          <vpfa-action-button buttonType="submit" [disabled]="!formGroup.valid || loading">
            {{ 'common.save' | translate }}
          </vpfa-action-button>

          <vpfa-action-button
            [outline]="true"
            [border]="false"
            nz-popconfirm
            [nzCondition]="formGroup.pristine"
            [nzPopconfirmTitle]="'common.unsavedChanges' | translate"
            [nzCancelText]="'common.no' | translate"
            [nzOkText]="'common.yes' | translate"
            nzPopconfirmPlacement="top"
            nzPopconfirmOverlayClassName="modal-confirm-popover"
            (nzOnConfirm)="onClose()"
          >
            {{ 'common.cancel' | translate }}
          </vpfa-action-button>
        </div>
      </nz-spin>
    </form>
  </vpfa-modal-content>
</vpfa-modal>
