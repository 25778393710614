import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AmsPermissions } from '@vpfa/rest-api/admin';
import * as fromPermissions from './permissions.reducer';
import * as fromPermissionsActions from './permissions.actions';
import { permissionsQuery } from './permissions.selectors';
import { PermissionsContext } from '../models/permissions-context';

@Injectable()
export class PermissionsFacade {
  permissions$ = this.store.select(permissionsQuery.getPermissions);
  loading$ = this.store.select(permissionsQuery.getLoading);
  loaded$ = this.store.select(permissionsQuery.getLoaded);

  constructor(private store: Store<fromPermissions.State>) {}

  loadPermissions() {
    this.store.dispatch(new fromPermissionsActions.LoadPermissions());
  }

  loadPermissionsByContext(context: PermissionsContext) {
    this.store.dispatch(new fromPermissionsActions.LoadPermissionsByContext(context));
  } 

  permissionsLoaded(permissions: AmsPermissions) {
    this.store.dispatch(new fromPermissionsActions.PermissionsLoaded(permissions));
  }

  clearLoadedPermissions() {
    this.store.dispatch(new fromPermissionsActions.ClearPermissions());
  }
}