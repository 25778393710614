import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PriceDisplayModel } from '../pricing-details-modal/pricing-details-modal.component';
import { CaseTaxDto, PriceHistoryDto } from '@vpfa/rest-api/valuation';

@Component({
  selector: 'vpfa-price-field-with-tax-section',
  templateUrl: './price-field-with-tax-section.component.html',
  styleUrls: ['./price-field-with-tax-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceFieldWithTaxSectionComponent {
  @Input() name: PriceDisplayModel['name'];
  @Input() price: PriceDisplayModel['price'];
  @Input() tax: PriceDisplayModel['tax'];
  @Input() history: PriceDisplayModel['history'];
  @Input() isForecastAvailable: boolean = false;
  @Input() caseTax: CaseTaxDto;

  @Output() showHistoricalPrices = new EventEmitter<
    Pick<PriceDisplayModel, 'name' | 'price' | 'tax'> & { history: PriceHistoryDto[] }
  >();
}
