import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
import { isNil } from 'lodash';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { ImageActionParams, ImageParams, MultipleImagesParams } from '../interfaces/image-params.interface';
import { ImageStateData } from '../interfaces/image-state-data.interface';
import { bumpImageExpiration, imageLoad, removeImage } from './images.actions';
import { ImagesPartialState } from './images.reducer';
import { imagesQuery } from './images.selectors';

@Injectable()
export class ImagesFacade {
  imagesState = this.store.pipe(select(imagesQuery.getImagesState));
  image$ = (params: ImageParams) => this.store.pipe(select(imagesQuery.getImageDataUrl$(params.imageUrl, params.size)));
  isImageLoaded$ = (params: ImageParams) =>
    this.store.pipe(
      select(imagesQuery.getImageDataUrl$(params.imageUrl, params.size)),
      map(x => !isNil(x))
    );
  loading$ = (params: ImageParams) =>
    this.store.pipe(select(imagesQuery.getImageLoading$(params.imageUrl, params.size)));

  imageWithContext$ = (params: ImageParams) =>
    this.store.pipe(select(imagesQuery.getImage$(params.imageUrl, params.size)));

  multipleLoading$ = (params: MultipleImagesParams) => {
    const loadingObservables = params.images.map(image =>
      this.loading$({
        imageUrl: image.imageUrl,
        size: params.size,
      })
    );

    return combineLatest(loadingObservables).pipe(
      map(images => images.some(loading => loading === true)),
      distinctUntilChanged()
    );
  };

  multipleImages$ = (params: MultipleImagesParams) => {
    const observables = params.images.map(image =>
      this.imageWithContext$({
        imageUrl: image.imageUrl,
        size: params.size,
      })
    );

    return combineLatest(observables).pipe(
      filter(x => x.every(image => !isNil(image?.dataUrl))),
      map(imagesInStore => {
        const orderedResult: ImageStateData[] = [];

        params.images.forEach(imageFromParam => {
          orderedResult.push(imagesInStore.find(x => x.imageKey === imageFromParam.imageKey));
        });

        return orderedResult;
      })
    );
  };

  constructor(private store: Store<ImagesPartialState>, private domSanitizer: DomSanitizer) {}

  loadImage(params: ImageActionParams) {
    this.loading$(params)
      .pipe(take(1))
      .subscribe(isLoading => {
        // There are pages like broadcasting, where small images are shown twice. To avoid race condition,
        // when image is already loading, we just ignore those requests.
        if (!isLoading) {
          this.store.dispatch(
            imageLoad({
              payload: params,
            })
          );
        }
      });
  }

  imageBumpLastUsedTime(params: ImageActionParams) {
    this.store.dispatch(
      bumpImageExpiration({
        payload: params,
      })
    );
  }

  removeImage(params: ImageActionParams) {
    this.store.dispatch(
      removeImage({
        payload: params,
      })
    );
  }

  loadMultiple(params: MultipleImagesParams) {
    params.images.forEach(image => {
      this.loadImage({
        imageUrl: image.imageUrl,
        size: params.size,
        imageKey: image.imageKey,
      });
    });
  }
}
