import { produce } from 'immer';
import { Action } from '@ngrx/store';
import { ConfirmAdvertisedImagesActionTypes, ShowConfirmationModal } from '../+state/confirm-advertised-images.actions';
import { sanitizeImages } from '@vpfa/utils';
import { isNil } from 'lodash';
import {
  ConfirmAdvertisedImagesPartialState,
  CONFIRM_ADVERTISED_IMAGES_FEATURE_KEY,
} from '../+state/confirm-advertised-images.reducer';

export function sanitizeConfirmAdvertizedImagesState(state: ConfirmAdvertisedImagesPartialState) {
  if (isNil(state[CONFIRM_ADVERTISED_IMAGES_FEATURE_KEY]?.modal?.data)) {
    return state;
  }

  return produce({ ...state }, (draftState: ConfirmAdvertisedImagesPartialState) => {
    draftState[CONFIRM_ADVERTISED_IMAGES_FEATURE_KEY].modal.data.images = sanitizeImages(
      state[CONFIRM_ADVERTISED_IMAGES_FEATURE_KEY].modal.data.images
    );
  });
}

export function sanitizeConfirmAdvertizedImagesAction(action: Action, id: number): Action {
  if (
    action.type === ConfirmAdvertisedImagesActionTypes.ShowConfirmationModal &&
    action instanceof ShowConfirmationModal
  ) {
    return produce({ ...action }, (draftAction: ShowConfirmationModal) => {
      draftAction.payload.images = sanitizeImages(action.payload.images);
    });
  }

  return action;
}
