import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectOption } from '@vpfa/ui-kit';
import { isNil, sortBy } from 'lodash';
import { take } from 'rxjs/operators';
import { AddRemoveCustomerListItem, BaseAddRemoveCustomerComponent } from '../base-add-remove-customer.component';
import { getCustomerSorting } from '../../../utils/customers-utils';

@Component({
  selector: 'vpfa-add-remove-customer-list',
  templateUrl: './add-remove-customer-list.component.html',
  styleUrls: ['../base-add-remove-customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddRemoveCustomerListComponent extends BaseAddRemoveCustomerComponent {
  @Input() showPromoteControls = false;
  @Input() mainOwnerTooltipText = 'customers.addRemoveCustomerList.mainOwnerTooltip';
  @Input() promoteToMainOwnerTooltipText = 'customers.addRemoveCustomerList.promoteToMainOwnerTooltip';
  @Output() branchSelectionChange = new EventEmitter<string>();
  @Output() customerListOrderChange = new EventEmitter<AddRemoveCustomerListItem[]>();

  itemSelected(selected: SelectOption) {
    if (selected) {
      if (this.isCustomer(selected.additional)) {
        this.customerList$.pipe(take(1)).subscribe(cl => {
          const selectedCustomer = cl.find(el => el.id === selected.value);
          this.selectedCustomersList$.next(
            sortBy([...this.selectedCustomersList$.value, selectedCustomer], getCustomerSorting)
          );
          this.selectedBranch$.next(null);
          this.listChange.emit(this.selectedCustomersList$.getValue());
        });
      } else if (this.isBranch(selected.additional)) {
        this.selectedCustomersList$.next([]);
        this.selectedBranch$.next(selected.additional);
        this.branchSelectionChange.emit(this.selectedBranch$.getValue()?.branchId);
      }
    }
  }

  isBranchSelected(): boolean {
    return !isNil(this.selectedBranch$.getValue());
  }

  promoteToMainBuyer(customerId: string) {
    const currentCustomers = [...this.selectedCustomersList$.value];

    const foundIndex = currentCustomers.findIndex(x => x.id === customerId);

    if (foundIndex === -1) {
      console.warn(`Customer with ID: ${customerId} not found on the list`);
      return;
    }

    const customer = currentCustomers.splice(foundIndex, 1);

    currentCustomers.unshift(...customer);

    this.selectedCustomersList$.next([...currentCustomers]);
    this.customerListOrderChange.emit(this.selectedCustomersList$.getValue());
  }
}
