<div class="carousel-container" [ngClass]="{ mobile: mobile }">
  <div class="placeholder-image" *ngIf="!hasImages">
    <img class="image" [src]="DEFAULT_IMAGE" />
  </div>
  <ng-container *ngIf="hasImages && show">
    <button *ngIf="gallery.length > 1" class="button prev" (click)="carousel.pre()">
      <i class="zmdi zmdi-chevron-left"></i>
    </button>
    <button *ngIf="gallery.length > 1" class="button next" (click)="carousel.next()">
      <i class="zmdi zmdi-chevron-right"></i>
    </button>
    <nz-carousel #carousel nzEffect="scrollx" [nzEnableSwipe]="mobile" nzDots="false">
      <div nz-carousel-content class="carousel-slide" *ngFor="let image of gallery; trackBy: trackByFn">
        <img class="image" [src]="image | safeUrl" />
      </div>
    </nz-carousel>
  </ng-container>
</div>
