/**
 * Dealer Network AdminService API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 

export enum Features {

      HideCO2EmissionsDataLink = 0,
      HideRepairEstimateSection = 1,
      HideBuildDateForIdentification = 2,
      ShowOriginMarketLabel = 3,
      ShowRegistrationPlateOnMobileList = 4,
      HideInteriorTrimColor = 5,
      HideTechnicalDataDoors = 6,
      HideTechnicalDataMaxLoad = 7,
      HideTechnicalDataTrailerLoadBraked = 8,
      HideTechnicalDataTrailerLoadUnbraked = 9,
      HideTechnicalDataRoofLoad = 10,
      HideTechnicalDataKerbWeightInclDriver = 11,
      HideTechnicalDataWheelbase = 12,
      HideTechnicalDataBootCapacity = 13,
      HideTechnicalDataLength = 14,
      HideTechnicalDataHeight = 15,
      HideTechnicalDataWidth = 16,
      HideDifferentiallyTaxed = 17,
      ShowGVW = 18,
      ShowWheelbase = 19,
      ShowKBA = 20,
      HideLiveRetail = 21,
      ShowIdentificationKBA = 22,
      ShowIdentificationVIN = 23,
      ShowIdentificationVRM = 24,
      HideENVKVFuels = 25,
      ShowPerformanceImperial = 26,
      ShowVRMOnSneakPreview = 27,
      HidePartialCover = 28,
      HideComprehensiveCover = 29,
      HideRentalCarGroup = 30,
      HideNonRentalMaintenanceCosts = 31,
      HideRentalMaintenanceCosts = 32,
      HidePersonalCosts = 33,
      HideCompensationForLossOfUse = 34,
      HideLiabilityInsuranceClass = 35,
      ShowInsuranceGroup = 36,
      ShowVin = 37,
      ShowVRMFirst = 38,
      HideExperAreaLink = 39,
      HideFTPBroadcast = 40,
      ShowNovaTax = 41,
      HideBuildDate = 42,
      ShowMaxWidthForLCV = 43,
      ShowSeatHeightForBikes = 44,
      HideTradePrices = 45,
      ShowBulkValuation = 46,
      ShowSellingSpotPrice = 47,
      HidePriceDetails = 48,
      HideSpecialColorAdjustment = 49,
      HideAppreciationDepreciationAdjustment = 50,
      ShowListsV2 = 51,
      HideAverageMileageIdentificationMethod = 52,
      HideMileageAdjustment = 53,
      HideAgeAdjustment = 54,
      HideNumberOfOwnersAdjustment = 55,
      UseResidualValueAgeMileageAdjusted = 56,
      HideVRM = 57,
      ShowIdentificationMatriculationNumber = 58,
      ShowEquipmentColor = 59,
      ShowEquipmentShortDescription = 60,
      HideFuelType = 61,
      SendLanguageToPdfRePro = 62,

};
