import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { interval, Observable, of, Subscription } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DeploymentVersionService {
  constructor(private httpClient: HttpClient) {}

  public currentVersion: number = null;

  private backgroundTask: Subscription;
  private started = false;

  /**
   * Should be run once on app startup (environment-initializer).
   *
   * It will return the latest version of the app in Promise. Created Subscription will be running in background
   * to update the version number all the time.
   * @returns
   */
  async run(): Promise<number> {
    if (this.started) throw Error('Already started');

    if (!(environment.deploymentVersion.checkIntervalInSeconds > 1)) {
      console.error('Invalid interval for checking deployment version');
      return;
    }

    this.backgroundTask = interval(environment.deploymentVersion.checkIntervalInSeconds * 1000)
      .pipe(
        filter(() => navigator.onLine), // do not try to get the file, when browser is offline
        switchMap(() => this.getCurrentVersion())
      )
      .subscribe();

    this.started = true;

    return this.getCurrentVersion().toPromise();
  }

  getCurrentVersion(): Observable<number> {
    return this.httpClient.get<{ deploymentVersion: number }>(environment.deploymentVersion.filename).pipe(
      map(({ deploymentVersion }) => {
        const previousVersion = this.currentVersion;
        this.currentVersion = deploymentVersion;

        if (previousVersion > 1 && this.currentVersion > previousVersion) {
          console.info(`New version available: ${previousVersion} => ${this.currentVersion}`);

          // TODO: VP-18293: notify user about new version
        }

        return this.currentVersion;
      }),
      catchError(() => of(null))
    );
  }
}
