<div *ngIf="isPackageEquipment; else otherEquipment" fxLayout="row" fxLayoutGap="20px">
  <i
    class="zmdi"
    (click)="onClick()"
    [ngClass]="{
      'zmdi-plus': !isPackageExpanded,
      'zmdi-minus': isPackageExpanded,
      available: isPackageContentAvailable,
      'not-available-cursor': !isPackageContentAvailable,
      'not-available-colour': !isPackageContentAvailable
    }"
  ></i>
  <div [ngStyle]="equipmentDescriptionStyle()">
    <span
      (click)="onClick()"
      [ngClass]="{ available: isPackageContentAvailable, 'not-available-cursor': !isPackageContentAvailable }"
    >
      <vpfa-short-label-value
        *ngIf="valuationEquipment?.isEnabledByVin"
        [showCheckedSymbol]="true"
        class="short-label-inline"
        [label]="'vehicleBasicInfo.vin' | translate"
      ></vpfa-short-label-value>
      {{ debugText }}{{ isValuationEquipment ? valuationEquipment?.description : identificationEquipment?.description }}
    </span>
    <ng-container *ngIf="isValuationEquipment === true">
      <ul *ngIf="isPackageExpanded && isPackageContentAvailable" class="content-list">
        <ng-container *ngFor="let content of valuationEquipment?.content">
          <li class="custom-list-marker">
            {{ contentDebugText(content) }}
            <vpfa-package-content-item
              [description]="valuationEquipmentContent(content)"
              [price]="content.priceBrutto"
              [isCombinationEquipment]="content.isCombinationEquipment"
              [isDisabled]="!(isPackageEnabled$(valuationEquipment?.soaCode) | async)"
              [checkboxState]="isPackageContentEnabled$(valuationEquipment?.soaCode, content?.soaCode) | async"
              (onCheckboxChanged)="onEquipmentContentChange(content, $event)"
            ></vpfa-package-content-item>
          </li>
        </ng-container>
      </ul>
    </ng-container>
    <ng-container *ngIf="isValuationEquipment === false">
      <ul *ngIf="isPackageExpanded && isPackageContentAvailable" class="content-list">
        <ng-container *ngFor="let content of identificationEquipmentContents()">
          <li class="custom-list-marker">
            <vpfa-package-content-item
              [description]="content"
              [price]="identificationEquipment.priceBrutto"
            ></vpfa-package-content-item>
          </li>
        </ng-container>
      </ul>
    </ng-container>
    <ng-container *ngTemplateOutlet="colors"></ng-container>
  </div>
</div>

<ng-template #otherEquipment>
  <div [ngStyle]="equipmentDescriptionStyle()">
    <vpfa-short-label-value
      *ngIf="valuationEquipment?.isEnabledByVin"
      [showCheckedSymbol]="true"
      class="short-label-inline"
      [label]="'vehicleBasicInfo.vin' | translate"
    ></vpfa-short-label-value>
    {{ debugText }}{{ isValuationEquipment ? valuationEquipment?.description : identificationEquipment?.description }}
  </div>
  <ng-container *ngTemplateOutlet="colors"></ng-container>
</ng-template>

<ng-template #colors>
  <div *ngIf="hasColors && showColor" class="colors-container">
    <nz-radio-group [(ngModel)]="enabledColor" (ngModelChange)="onColorChange($event)">
      <ng-container *ngFor="let color of valuationEquipment?.colors">
        <label nz-radio [nzValue]="color">{{ color.description }}</label>
      </ng-container>
    </nz-radio-group>
  </div>
</ng-template>
