import { Component, EventEmitter } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DataTableAction, DataTableActionEvent, DataTableActionListItem } from '../../models/data-table-action';

@Component({
  selector: 'vpfa-request-preview-action-renderer',
  templateUrl: './request-preview-action-renderer.component.html',
  styleUrls: ['./request-preview-action-renderer.component.scss'],
})
export class RequestPreviewActionRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  actionEmitter: EventEmitter<DataTableActionEvent>;
  listItems: DataTableActionListItem[] = [];
  dataTableActionMenuVisible = false;
  agInit(params: ICellRendererParams): void {
    this.params = params;
    if (this.params) {
      const context = this.params.context;
      if (context && context.componentParent && context.componentParent.actionClicked) {
        this.actionEmitter = context.componentParent.actionClicked;
      }

      if (this.params.value) {
        this.listItems = this.params.value;
      } else {
        this.prepareListItems();
      }
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  emitAction() {
    if (this.actionEmitter) {
      this.actionEmitter.emit({
        action: DataTableAction.REQUEST_PREVIEW,
        data: this.params.data,
      });
    }
  }

  private prepareListItems() {
    this.listItems = [];
  }

  getDropdownItemList() {
    return this.listItems;
  }
}
