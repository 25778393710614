export enum DataTableAction {
  EDIT,
  DELETE,
  DUPLICATE,
  RENAME,
  DETAILS,
  OTHER,
  SECOND_OTHER,
  ACTIVATE,
  DEACTIVATE,
  DOWNLOAD,
  MODULES,
  DETAILS_BUTTON,
  RESEND,
  PREVIEW,
  REQUEST_PREVIEW,
  RESPONSE_PREVIEW,
  TOGGLE,
  RESET_PASSWORD,
  REVIEW_ITEMS,
  REVALUATE,
  SHARED_STOCK_PRINTOUT,
  SHARED_STOCK_PRINTOUT_GENERATE_PDF,
  COPY_TO,
  SHOW_SPECIFICATION,
}

export interface DataTableActionEvent<T = any> {
  action: DataTableAction | string;
  data: T;
  value?: any;
}

export interface DataTableActionListItem {
  name: string;
  action: DataTableAction;
  question?: string;
  order?: number;
}
