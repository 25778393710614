import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { KeyValue } from '@angular/common';
import { NotificationModel } from '../../models/notification.model';

@Component({
  selector: 'vpfa-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
  @Input() errorNotifications: KeyValue<any, NotificationModel[]>[];
  @Input() warningNotifications: KeyValue<any, NotificationModel[]>[];
  @Input() mistakeNotifications: KeyValue<any, NotificationModel[]>[];
  @Input() successNotifications: KeyValue<any, NotificationModel[]>[];

}
