<ng-template #ctr>
  <vpfa-modal-content>
    <div class="main-container">
      <div class="d-flex flex-row justify-content-center">
        <div style="margin-bottom: 20px">
          <i class="icon zmdi zmdi-alert-circle"></i>
        </div>
      </div>
      <h3 class="heading">
        <ng-container *ngFor="let infoLabelKey of infoLabelsKey; index as i">
          {{ infoLabelKey | translate }} <ng-container *ngIf="infoLabelsKey.length - 1 > i"><br /></ng-container>
        </ng-container>
      </h3>
      <div class="d-flex flex-row justify-content-center gap-10px">
        <div>
          <vpfa-modal-button [outline]="true" [border]="false" (click)="confirmResult.emit(false)">
            <span class="cancel">{{ noLabelKey | translate }}</span>
          </vpfa-modal-button>
        </div>
        <div>
          <vpfa-modal-button [outline]="true" [border]="false" (click)="confirmResult.emit(true)">
            <span class="confirm">
              {{ yesLabelKey | translate }}
            </span>
          </vpfa-modal-button>
        </div>
      </div>
    </div>
  </vpfa-modal-content>
</ng-template>
