import { EquipmentDto, VehicleDto, EquipmentType } from '@vpfa/rest-api/identification';
import { EventEmitter, Input, Output, Directive } from '@angular/core';
import { FeaturesTechnicalDataConfiguration } from '@vpfa/locale';
import { SelectOption } from '@vpfa/ui-kit';

export enum TechSpecAndEquipmentTab {
  technicalData,
  packageEquipment,
  optionalEquipment,
  standardEquipment,
  unavailableEquipment,
}

@Directive()
export class BaseFullTechSpecAndEquipmentList {
  activeTab: TechSpecAndEquipmentTab = TechSpecAndEquipmentTab.technicalData;
  tab = TechSpecAndEquipmentTab;

  packageEquipmentList: EquipmentDto[];
  optionalEquipmentList: EquipmentDto[];
  standardEquipmentList: EquipmentDto[];
  unavailableEquipmentList: EquipmentDto[];

  @Input() set vehicleEquipmentListData(equipmentList: EquipmentDto[]) {
    this.packageEquipmentList = equipmentList
      ?.filter(eq => eq.equipmentType === EquipmentType.Package)
      .sort((a, b) => (a.description?.toLowerCase() > b.description?.toLowerCase() ? 1 : -1));
    this.optionalEquipmentList = equipmentList
      ?.filter(eq => eq.equipmentType === EquipmentType.Optional)
      .sort((a, b) => (a.description?.toLowerCase() > b.description?.toLowerCase() ? 1 : -1));
    this.standardEquipmentList = equipmentList
      ?.filter(eq => eq.equipmentType === EquipmentType.Standard)
      .sort((a, b) => (a.description?.toLowerCase() > b.description?.toLowerCase() ? 1 : -1));
    this.unavailableEquipmentList = equipmentList
      ?.filter(eq => eq.equipmentType === EquipmentType.Unavailable)
      .sort((a, b) => (a.description?.toLowerCase() > b.description?.toLowerCase() ? 1 : -1));
  }
  @Input() vehicleTechSpecData: VehicleDto;
  @Input() technicalDataFeatureConfig: FeaturesTechnicalDataConfiguration;
  @Input() showEquipmentShortDescription: boolean;
  @Input() emissionClassOptions: SelectOption[];
  @Output() closeAction = new EventEmitter();

  changeTab(tab: TechSpecAndEquipmentTab) {
    this.activeTab = tab;
  }

  onClose() {
    this.closeAction.emit();
  }
}
