import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { isNumber } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vpfa-inline-editable-number',
  templateUrl: './inline-editable-number.component.html',
  styleUrls: ['./inline-editable-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineEditableNumberComponent {
  private _numericValue: string | number = null;
  @Input() set numericValue(setValue: string | number) {
    if (!isNumber(setValue)) {
      return;
    }
    this._numericValue = setValue;
    this.setupValue(setValue);
  }
  get numericValue() {
    return this._numericValue;
  }

  @Input() isCurrency = false;
  @Input() fieldWidth = 'auto';
  @Input() fieldHeight = '100%';
  @Input() isProcessing = false;
  @Input() errorsInPopover = true;
  @Input() set validators(validators: ValidatorFn | ValidatorFn[]) {
    this.editField.setValidators(validators);
  }

  @Output() submitValue = new EventEmitter<number | string>();

  inEditMode = false;
  fcName = 'editable';
  formGroup = new UntypedFormGroup({ [this.fcName]: new UntypedFormControl(null) });
  editField = this.formGroup.get(this.fcName);
  fieldErrors: ReadonlyArray<{ error: string; errorMsg: string }> = [
    { error: 'required', errorMsg: this.translateService.instant('search.valueIsRequired') },
    { error: 'min', errorMsg: this.translateService.instant('search.valueIsTooSmall') },
    { error: 'positive', errorMsg: this.translateService.instant('search.valueIsTooSmall') },
    { error: 'max', errorMsg: this.translateService.instant('search.valueIsTooLarge') }
  ];

  constructor(private translateService: TranslateService) {}

  onToggleEditMode() {
    this.inEditMode = true;
  }

  getStyle(): Partial<CSSStyleDeclaration> {
    return { width: this.fieldWidth, height: this.fieldHeight };
  }

  onSubmitEdit() {
    if (this.editField.dirty && this.editField.invalid) return;

    this.inEditMode = false;
    if (this.editField.dirty) {
      if (this.numericValue != null && this.editField.valid) {
        this.submitValue.emit(this.editField.value);
      }
    }
  }

  onBlur() {
    this.onSubmitEdit();
  }

  onKeyDownEscape() {
    this.formGroup.reset({ [this.fcName]: this.numericValue });
    this.inEditMode = false;
  }

  private setupValue(setValue) {
    this.inEditMode = false;
    this.editField.setValue(setValue);
    this.editField.markAsPristine();
  }
}
