/**
 * Dealer Network AdminService API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { Filter } from '../model/filter';
import { Order } from '../model/order';
import { VPError } from '../model/vPError';
import { VinCallDtoPageWithFiltersResult } from '../model/vinCallDtoPageWithFiltersResult';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class VinCallViewService {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Lazy check AmsAction:GET/api/VinCallView/{businessId} AmsResource:{country}/{business}/_* 
     * @param businessId 
     * @param filters 
     * @param order 
     * @param pageIndex 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public search(businessId: string, filters?: Array<Filter>, order?: Array<Order>, pageIndex?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<VinCallDtoPageWithFiltersResult>;
    public search(businessId: string, filters?: Array<Filter>, order?: Array<Order>, pageIndex?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VinCallDtoPageWithFiltersResult>>;
    public search(businessId: string, filters?: Array<Filter>, order?: Array<Order>, pageIndex?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VinCallDtoPageWithFiltersResult>>;
    public search(businessId: string, filters?: Array<Filter>, order?: Array<Order>, pageIndex?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling search.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters) {

          filters.forEach((elementItem: any, elementIndex) => {

            const isQuerySearchOrOrderParams =
                elementItem.values !== undefined ||
                elementItem.from !== undefined ||
                elementItem.to !== undefined ||
                elementItem.value !== undefined ||
                elementItem.descending !== undefined ||
                elementItem.isNull !== undefined;

            if (isQuerySearchOrOrderParams) {
                queryParameters = queryParameters.set(`Filters[${elementIndex}].fieldName`, elementItem.fieldName);
        
                if (elementItem.values && Array.isArray(elementItem.values)) {
                    elementItem.values.forEach((selectedDropdownValue, index) => {
                        queryParameters = queryParameters.set(
                        `Filters[${elementIndex}].values[${index}]`,
                        selectedDropdownValue.toString()
                        );
                    });
                }
                if (elementItem.from !== undefined && elementItem.from !== null) {
                    queryParameters = queryParameters.set(`Filters[${elementIndex}].from`, elementItem.from);
                }
                if (elementItem.to !== undefined && elementItem.to !== null) {
                    queryParameters = queryParameters.set(`Filters[${elementIndex}].to`, elementItem.to);
                }
                if (elementItem.value !== undefined  && elementItem.value !== null) {
                    queryParameters = queryParameters.set(`Filters[${elementIndex}].value`, elementItem.value as string);
                }
                if (elementItem.descending !== undefined && elementItem.descending !== null) {
                    queryParameters = queryParameters.set(`Filters[${elementIndex}].descending`, Boolean(elementItem.descending).toString());
                }
                if (elementItem.isNull !== undefined && elementItem.isNull !== null) {
                    queryParameters = queryParameters.set(`Filters[${elementIndex}].isNull`, Boolean(elementItem.isNull).toString());
                }
            } else {
                queryParameters = queryParameters.append('Filters', <any>elementItem);
            }
            
          });



        }
        if (order) {

          order.forEach((elementItem: any, elementIndex) => {

            const isQuerySearchOrOrderParams =
                elementItem.values !== undefined ||
                elementItem.from !== undefined ||
                elementItem.to !== undefined ||
                elementItem.value !== undefined ||
                elementItem.descending !== undefined ||
                elementItem.isNull !== undefined;

            if (isQuerySearchOrOrderParams) {
                queryParameters = queryParameters.set(`Order[${elementIndex}].fieldName`, elementItem.fieldName);
        
                if (elementItem.values && Array.isArray(elementItem.values)) {
                    elementItem.values.forEach((selectedDropdownValue, index) => {
                        queryParameters = queryParameters.set(
                        `Order[${elementIndex}].values[${index}]`,
                        selectedDropdownValue.toString()
                        );
                    });
                }
                if (elementItem.from !== undefined && elementItem.from !== null) {
                    queryParameters = queryParameters.set(`Order[${elementIndex}].from`, elementItem.from);
                }
                if (elementItem.to !== undefined && elementItem.to !== null) {
                    queryParameters = queryParameters.set(`Order[${elementIndex}].to`, elementItem.to);
                }
                if (elementItem.value !== undefined  && elementItem.value !== null) {
                    queryParameters = queryParameters.set(`Order[${elementIndex}].value`, elementItem.value as string);
                }
                if (elementItem.descending !== undefined && elementItem.descending !== null) {
                    queryParameters = queryParameters.set(`Order[${elementIndex}].descending`, Boolean(elementItem.descending).toString());
                }
                if (elementItem.isNull !== undefined && elementItem.isNull !== null) {
                    queryParameters = queryParameters.set(`Order[${elementIndex}].isNull`, Boolean(elementItem.isNull).toString());
                }
            } else {
                queryParameters = queryParameters.append('Order', <any>elementItem);
            }
            
          });



        }
        if (pageIndex !== undefined && pageIndex !== null) {
          queryParameters = queryParameters.set('PageIndex', <any>pageIndex);
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<VinCallDtoPageWithFiltersResult>(`${this.basePath}/api/VinCallView/${encodeURIComponent(String(businessId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
