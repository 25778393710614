<div *ngIf="(caseStatus$ | async) !== caseStatusEnum.Sold; else radarUnavailable">
  <vpfa-mobile-page-title
    fxHide.gt-mv="true"
    [title]="'radar.liveRetailsMarket' | translate"
    [tooltipContent]="'tooltip.latestPrices' | translate"
  ></vpfa-mobile-page-title>

  <vpfa-header-title
    fxHide.lt-mv="true"
    [title]="'radar.liveRetailsMarket' | translate"
    (back)="goToCaseSummaryPage()"
    [extraLeftTemplate]="extraLeft"
  ></vpfa-header-title>

  <ng-template #extraLeft>
    <vpfa-small-subtitle-extra [text]="headerTitleExtra"></vpfa-small-subtitle-extra>
  </ng-template>

  <vpfa-vehicle-basic-info
    [vehicleData]="caseData.vehicle"
    [plateNo]="caseData.plateNumber"
    [displayHorizontal]="true"
  ></vpfa-vehicle-basic-info>

  <hr />

  <vpfa-section-loader [isLoading]="!ownVehicleParams">
    <liveRetail-lib
      class="bootstrap-v5"
      *ngIf="ownVehicleParams"
      [ownVehicleParams]="ownVehicleParams"
    ></liveRetail-lib>
  </vpfa-section-loader>

  <div class="sticky-btn" fxHide.gt-mv="true">
    <vpfa-mobile-action-button (click)="goToCaseSummaryPage()">
      {{ 'common.back' | translate }}
    </vpfa-mobile-action-button>
  </div>

  <div fxHide.lt-mv="true">
    <vpfa-back-link (click)="goToCaseSummaryPage()"></vpfa-back-link>
  </div>
</div>

<ng-template #radarUnavailable>
  <vpfa-radar-unavailable></vpfa-radar-unavailable>
</ng-template>
