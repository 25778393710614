<div [formGroup]="formGroup" *vpfaResponsiveIf="'web'; else mobile">
  <vpfa-expanding-header [text]="'uniqueData.modal.stockData' | translate"></vpfa-expanding-header>

  <vpfa-setting-box [divider]="false">
    <ng-container label>
      <vpfa-label [text]="'uniqueData.tradeInDate' | translate"></vpfa-label>
    </ng-container>
    <ng-container content>
      <vpfa-iso-date-picker
        [format]="'short' | localeDateFormat"
        [formControl]="formGroup.get('avTradeInDate')"
        [disabledDate]="tradeInDisableDate"
        [allowClear]="false"
      ></vpfa-iso-date-picker>
    </ng-container>
  </vpfa-setting-box>

  <vpfa-setting-box [divider]="false">
    <ng-container label>
      <vpfa-label [text]="'uniqueData.subsidiary' | translate"></vpfa-label>
    </ng-container>
    <ng-container content>
      <vpfa-select
        [allowClear]="true"
        [parentFormGroup]="formGroup"
        [fc]="formGroup?.get('subsidiaryId')"
        [translateLabel]="true"
        [options]="branchOptions"
      >
      </vpfa-select>
    </ng-container>
  </vpfa-setting-box>

  <vpfa-setting-box [divider]="false">
    <ng-container label>
      <vpfa-label [text]="'uniqueData.branchLocation' | translate"></vpfa-label>
    </ng-container>
    <ng-container afterLabel>
      <div [ngClass]="{ 'bl-invalid': formGroup.get('branchLocation').invalid }">
        {{ formGroup?.get('branchLocation')?.value?.length || 0 }} / {{ textAreaMaxLength }}
      </div>
    </ng-container>
    <ng-container content>
      <vpfa-textarea [parentFormGroup]="formGroup" [fcName]="'branchLocation'" disabled="true"> </vpfa-textarea>
    </ng-container>
  </vpfa-setting-box>

  <vpfa-setting-box [divider]="false">
    <ng-container label>
      <vpfa-label [text]="'uniqueData.ucNumber' | translate"></vpfa-label>
    </ng-container>
    <ng-container content>
      <vpfa-text-field
        [parentFormGroup]="formGroup"
        fcName="ucNumber"
        [errorMessages]="ucNumberErrorMsgList"
      ></vpfa-text-field>
    </ng-container>
  </vpfa-setting-box>

  <vpfa-setting-box [divider]="false">
    <ng-container label>
      <vpfa-label [text]="'uniqueData.deregistrationDate' | translate"></vpfa-label>
    </ng-container>
    <ng-container content>
      <vpfa-iso-date-picker
        [format]="'short' | localeDateFormat"
        [formControl]="formGroup.get('deregistrationDate')"
      ></vpfa-iso-date-picker>
    </ng-container>
  </vpfa-setting-box>

  <vpfa-setting-box [divider]="false">
    <ng-container label>
      <vpfa-label [text]="'uniqueData.newRegistrationDate' | translate"></vpfa-label>
    </ng-container>
    <ng-container content>
      <vpfa-iso-date-picker
        [format]="'short' | localeDateFormat"
        [formControl]="formGroup.get('newRegistrationDate')"
      ></vpfa-iso-date-picker>
    </ng-container>
  </vpfa-setting-box>

  <vpfa-setting-box [divider]="false">
    <ng-container label>
      <vpfa-label [text]="'uniqueData.lastCustomerService' | translate"></vpfa-label>
    </ng-container>
    <ng-container content>
      <vpfa-iso-date-picker
        [format]="'short' | localeDateFormat"
        [formControl]="formGroup.get('lastCustomerService')"
        [disabledDate]="disableDate"
      ></vpfa-iso-date-picker>
    </ng-container>
  </vpfa-setting-box>

  <vpfa-setting-box [divider]="false">
    <ng-container label>
      <vpfa-label [text]="'uniqueData.lastToothedBeltChange' | translate"></vpfa-label>
    </ng-container>
    <ng-container content>
      <vpfa-iso-date-picker
        [format]="'short' | localeDateFormat"
        [formControl]="formGroup.get('lastToothedBeltChange')"
        [disabledDate]="disableDate"
      ></vpfa-iso-date-picker>
    </ng-container>
  </vpfa-setting-box>

  <vpfa-setting-box [divider]="false">
    <ng-container label>
      <vpfa-label [text]="'uniqueData.typeOfUseLabel' | translate"></vpfa-label>
    </ng-container>
    <ng-container content>
      <vpfa-select
        [allowClear]="true"
        [parentFormGroup]="formGroup"
        [fc]="formGroup?.get('typeOfUse')"
        [translateLabel]="true"
        [options]="typeOfUseOptions"
      >
      </vpfa-select>
    </ng-container>
  </vpfa-setting-box>
</div>

<ng-template #mobile>
  <div class="no-hor-scroll">
    <vpfa-mobile-subtitle [title]="'uniqueData.modal.stockData' | translate"></vpfa-mobile-subtitle>

    <div class="mb">
      <vpfa-vehicle-technical-data-single-field
        [label]="'uniqueData.tradeInDate' | translate"
        [value]="formGroup.get('avTradeInDate').value | dateLocale"
      ></vpfa-vehicle-technical-data-single-field>
    </div>

    <div class="mb">
      <vpfa-vehicle-technical-data-single-field
        [label]="'uniqueData.subsidiary' | translate"
        [value]="getSubsidiaryName()"
      ></vpfa-vehicle-technical-data-single-field>
    </div>

    <div class="mb">
      <vpfa-vehicle-technical-data-single-field
        [label]="'uniqueData.branchLocation' | translate"
        [value]="formGroup.get('branchLocation').value"
      ></vpfa-vehicle-technical-data-single-field>
    </div>

    <div class="mb">
      <vpfa-vehicle-technical-data-single-field
        [label]="'uniqueData.ucNumber' | translate"
        [value]="formGroup.get('ucNumber').value"
      ></vpfa-vehicle-technical-data-single-field>
    </div>

    <div class="mb">
      <vpfa-vehicle-technical-data-single-field
        [label]="'uniqueData.deregistrationDate' | translate"
        [value]="formGroup.get('deregistrationDate').value | dateLocale"
      ></vpfa-vehicle-technical-data-single-field>
    </div>

    <div class="mb">
      <vpfa-vehicle-technical-data-single-field
        [label]="'uniqueData.newRegistrationDate' | translate"
        [value]="formGroup.get('newRegistrationDate').value | dateLocale"
      ></vpfa-vehicle-technical-data-single-field>
    </div>

    <div class="mb">
      <vpfa-vehicle-technical-data-single-field
        [label]="'uniqueData.lastCustomerService' | translate"
        [value]="formGroup.get('lastCustomerService').value | dateLocale"
      ></vpfa-vehicle-technical-data-single-field>
    </div>

    <div class="mb">
      <vpfa-vehicle-technical-data-single-field
        [label]="'uniqueData.lastToothedBeltChange' | translate"
        [value]="formGroup.get('lastToothedBeltChange').value | dateLocale"
      ></vpfa-vehicle-technical-data-single-field>
    </div>

    <vpfa-vehicle-technical-data-single-field
      [label]="'uniqueData.typeOfUseLabel' | translate"
      [value]="getTypeOfUseName() | translate"
    ></vpfa-vehicle-technical-data-single-field>
  </div>
</ng-template>
