import { Input, Directive } from '@angular/core';
import {
  VehicleConfirmationDto,
  ProvenanceFinanceStatus,
  ProvenancePlateStatus,
  ProvenanceExtraDataStatus,
} from '@vpfa/rest-api/valuation';
import { TranslateService } from '@ngx-translate/core';

@Directive()
export class BaseProvenanceCheckSectionConfirmationStatusComponent {
  private readonly WARNING_CLASS = 'warning';
  private readonly CAUTION_CLASS = 'caution';

  @Input() vehicleConfirmation: VehicleConfirmationDto;

  constructor(private translateService: TranslateService) {}

  get scrappedStatus() {
    return this.vehicleConfirmation?.isScrapped
      ? 'provenanceCheck.vehicleConfirmationStatus.scrapped'
      : 'provenanceCheck.vehicleConfirmationStatus.notScrapped';
  }

  get importDataStatus() {
    return this.vehicleConfirmation?.isImported
      ? 'provenanceCheck.vehicleConfirmationStatus.imported'
      : 'provenanceCheck.vehicleConfirmationStatus.noImportData';
  }

  get exportDataStatus() {
    return this.vehicleConfirmation?.isExported
      ? 'provenanceCheck.vehicleConfirmationStatus.exported'
      : 'provenanceCheck.vehicleConfirmationStatus.noExportData';
  }

  get stolenStatus() {
    return this.vehicleConfirmation?.isStolen
      ? 'provenanceCheck.vehicleConfirmationStatus.stolen'
      : 'provenanceCheck.vehicleConfirmationStatus.notStolen';
  }

  get highRiskStatus() {
    return this.vehicleConfirmation?.isHighRisk
      ? 'provenanceCheck.vehicleConfirmationStatus.highRisk'
      : 'provenanceCheck.vehicleConfirmationStatus.noHighRisk';
  }

  get abiConditionStatus() {
    return this.vehicleConfirmation?.isABICondition
      ? 'provenanceCheck.vehicleConfirmationStatus.abiCondition'
      : 'provenanceCheck.vehicleConfirmationStatus.noAbiCondition';
  }

  get financeStatus() {
    return `provenanceCheck.vehicleConfirmationStatus.${
      ProvenanceFinanceStatus[this.vehicleConfirmation?.financeStatus]
    }`;
  }

  get plateStatus() {
    return `provenanceCheck.vehicleConfirmationStatus.${ProvenancePlateStatus[this.vehicleConfirmation?.plateStatus]}`;
  }

  get extraDataStatus() {
    return `provenanceCheck.vehicleConfirmationStatus.${
      ProvenanceExtraDataStatus[this.vehicleConfirmation?.extraDataStatus]
    }`;
  }

  get transmissionAndFuelType(): string {
    return this.vehicleConfirmation && (this.vehicleConfirmation.transmission || this.vehicleConfirmation.fuelType)
      ? `${this.getValue(this.vehicleConfirmation.transmission)} ${this.getValue(this.vehicleConfirmation.fuelType)}`
      : null;
  }

  get financeStatusStyling() {
    if (this.vehicleConfirmation?.financeStatus === ProvenanceFinanceStatus.FinanceAgreementRecorded) {
      return this.CAUTION_CLASS;
    } else if (this.vehicleConfirmation?.financeStatus === ProvenanceFinanceStatus.OutstandingFinance) {
      return this.WARNING_CLASS;
    }
  }

  get plateStatusStyling() {
    if (this.vehicleConfirmation?.plateStatus === ProvenancePlateStatus.PlateChangesRecorded) {
      return this.WARNING_CLASS;
    }
  }

  get extraDataStatusStyling() {
    if (this.vehicleConfirmation?.extraDataStatus === ProvenanceExtraDataStatus.ExtraData) {
      return this.WARNING_CLASS;
    }
  }

  getValue(value: any) {
    return value ?? this.translateService.instant('common.noValue');
  }
}
