import { Directive, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CasesFacade } from '@vpfa/dealer/case/data';
import { distinctUntilChanged } from 'rxjs/operators';

@Directive()
export abstract class NotesBase implements OnInit {
  textareaMaxLength = 10000;
  TOOLTIP_CONTENT = 'tooltip.notes';

  @Input() caseId: string;

  activeCaseNotes$ = this.casesFacade.activeCaseNotes$;

  notesForm: UntypedFormGroup;

  protected constructor(protected fb: UntypedFormBuilder, protected casesFacade: CasesFacade) {
    this.notesForm = this.fb.group({ notes: ['', Validators.maxLength(this.textareaMaxLength)] });
  }

  ngOnInit(): void {
    this.activeCaseNotes$.pipe(distinctUntilChanged()).subscribe(notes => {
      this.notesForm.get('notes').setValue(notes);
    });
  }

  updateNotes() {
    if (this.notesForm.dirty && this.notesForm.valid) {
      this.casesFacade.updateCaseNotes({
        aggregateRootId: this.caseId,
        notes: this.notesForm.get('notes').value.trim(),
      });
      this.notesForm.markAsPristine();
    }
  }
}
