import { IToken } from '../models/token';
import { AuthActions, AuthActionTypes } from './actions/auth.actions';
import { AuthLoginActionTypes } from './actions/login.actions';
import { AuthTokenActionTypes } from './actions/token.actions';
import { produce } from 'immer';
import { AuthLocaleActionTypes } from './actions/locale.actions';
import { LoginCountryDto } from '@vpfa/rest-api/admin';
import { LoginCommand } from '@vpfa/rest-api/admin';
import { AuthTemporaryActions, AuthTemporaryActionTypes } from './actions/temporary.actions';
import { isNil } from 'lodash';

export const AUTH_FEATURE_KEY = 'auth';

export interface TemporaryState {
  loginData: LoginCommand;
  processing: boolean;
  success: boolean;
  compatibility: boolean;
}

export interface RequestPasswordResetState {
  processing: boolean;
  sent: boolean | null;
  success: boolean | null;
}

export interface AuthState {
  // is a user authenticated?
  isAuthenticated: boolean;
  // if authenticated, there should be a user object
  token: IToken | null;
  errorMessage: string | null;
  isLoadedFromCache: boolean;
  redirectToUrlAfterLogin: string;
  loading: boolean;
  countryBaseInformation: LoginCountryDto;
  isLoadingCountryBaseInformation: boolean;
  temporary: TemporaryState;
  passwordResetRequest: RequestPasswordResetState;
}

const temporaryInitState: TemporaryState = {
  loginData: null,
  processing: false,
  success: false,
  compatibility: null,
};

const initialState: AuthState = {
  isAuthenticated: false,
  token: null,
  errorMessage: null,
  isLoadedFromCache: false,
  redirectToUrlAfterLogin: null,
  loading: false,
  countryBaseInformation: null,
  isLoadingCountryBaseInformation: false,
  temporary: temporaryInitState,
  passwordResetRequest: {
    processing: false,
    sent: null,
    success: null,
  },
};

export function authReducer(state = initialState, action: AuthActions | AuthTemporaryActions): AuthState {
  switch (action.type) {
    case AuthLoginActionTypes.Login: {
      return produce<AuthState>(state, newState => {
        newState.errorMessage = null;
        newState.loading = true;
      });
    }
    case AuthLoginActionTypes.LoginFailure: {
      return produce<AuthState>(state, newState => {
        newState.errorMessage = action.payload;
        newState.loading = false;
      });
    }
    case AuthLoginActionTypes.LoginCancel:
    case AuthLoginActionTypes.LoginError: {
      return produce<AuthState>(state, newState => {
        newState.loading = false;
      });
    }
    case AuthLoginActionTypes.LoginFinished: {
      return produce<AuthState>(state, newState => {
        newState.loading = false;
        newState.redirectToUrlAfterLogin = null;
      });
    }
    case AuthTokenActionTypes.SET_TOKEN:
    case AuthActionTypes.LoadTokenFromCacheSetToken: {
      return produce<AuthState>(state, newState => {
        newState.isAuthenticated = true;
        newState.token = action.payload;
      });
    }
    case AuthTokenActionTypes.CLEAR_TOKEN:
    case AuthLoginActionTypes.LoginClearToken:
    case AuthActionTypes.LoadTokenFromCacheClearToken: {
      return produce<AuthState>(state, newState => {
        newState.isAuthenticated = false;
        newState.token = null;
        if (isNil(state.redirectToUrlAfterLogin) && !isNil(action?.payload?.afterRedirectTo)) {
          newState.redirectToUrlAfterLogin = action.payload.afterRedirectTo;
        }
      });
    }
    case AuthActionTypes.LoadTokenFromCacheSuccess:
    case AuthActionTypes.LoadTokenFromCacheFailure: {
      return produce<AuthState>(state, newState => {
        newState.isLoadedFromCache = true;
      });
    }
    case AuthActionTypes.UnauthorizedUrl: {
      return produce<AuthState>(state, newState => {
        // Save just first unauthorized url, because when first request fails, browser is redirected to login page.
        // And after redirection other requests may fail and login page is saved as lastUnauthorizedUrl;
        if (isNil(state.redirectToUrlAfterLogin)) {
          newState.redirectToUrlAfterLogin = action.payload;
        }
      });
    }
    case AuthLocaleActionTypes.LoadCountrySuccess: {
      return produce<AuthState>(state, newState => {
        newState.countryBaseInformation = action.payload.data;
        newState.isLoadingCountryBaseInformation = false;
      });
    }
    case AuthLocaleActionTypes.LoadCountryLogoAndHelpSuccess: {
      return produce<AuthState>(state, newState => {
        if (isNil(state.countryBaseInformation)) {
          newState.countryBaseInformation = {
            countryId: action.payload.data.countryId,
          } as LoginCountryDto;
        }

        newState.countryBaseInformation.hotlineEmail = action.payload.data.hotlineEmail;
        newState.countryBaseInformation.hotlinePhone = action.payload.data.hotlinePhone;
        newState.isLoadingCountryBaseInformation = false;
      });
    }
    case AuthLocaleActionTypes.LoadCountry:
    case AuthLocaleActionTypes.LoadCountryLogoAndHelp: {
      return produce<AuthState>(state, newState => {
        newState.isLoadingCountryBaseInformation = true;
      });
    }
    case AuthLocaleActionTypes.LoadCountryError:
    case AuthLocaleActionTypes.LoadCountryLogoAndHelpError: {
      return produce<AuthState>(state, newState => {
        newState.isLoadingCountryBaseInformation = false;
      });
    }
    case AuthTemporaryActionTypes.TemporaryPassword: {
      return produce<AuthState>(state, newState => {
        newState.loading = false;
        newState.temporary.loginData = action.payload;
      });
    }
    case AuthTemporaryActionTypes.ClearTemporaryPassword: {
      return produce<AuthState>(state, newState => {
        newState.temporary = temporaryInitState;
      });
    }
    case AuthTemporaryActionTypes.CheckPasswordCompatibility: {
      return produce<AuthState>(state, newState => {
        newState.temporary.compatibility = null;
      });
    }
    case AuthTemporaryActionTypes.CheckPasswordCompatibilitySuccess: {
      return produce<AuthState>(state, newState => {
        newState.temporary.compatibility = action.payload;
      });
    }
    case AuthTemporaryActionTypes.ChangeTemporaryPassword: {
      return produce<AuthState>(state, newState => {
        newState.temporary.processing = true;
      });
    }
    case AuthTemporaryActionTypes.ChangeTemporaryPasswordSuccess: {
      return produce<AuthState>(state, newState => {
        newState.temporary.processing = false;
        newState.temporary.success = true;
      });
    }

    case AuthTemporaryActionTypes.ChangeTemporaryPasswordError: {
      return produce<AuthState>(state, newState => {
        newState.temporary.processing = false;
        newState.temporary.success = false;
      });
    }

    case AuthLoginActionTypes.RequestPasswordReset: {
      return produce<AuthState>(state, newState => {
        newState.passwordResetRequest.processing = true;
        newState.passwordResetRequest.sent = null;
        newState.passwordResetRequest.success = null;
      });
    }

    case AuthLoginActionTypes.PasswordResetRequestSuccess: {
      return produce<AuthState>(state, newState => {
        newState.passwordResetRequest.processing = false;
        newState.passwordResetRequest.sent = true;
        newState.passwordResetRequest.success = true;
      });
    }

    case AuthLoginActionTypes.PasswordResetRequestError: {
      return produce<AuthState>(state, newState => {
        newState.passwordResetRequest.processing = false;
        newState.passwordResetRequest.sent = true;
        newState.passwordResetRequest.success = false;
      });
    }

    case AuthLoginActionTypes.PasswordResetRequestClear: {
      return produce<AuthState>(state, newState => {
        newState.passwordResetRequest.processing = false;
        newState.passwordResetRequest.sent = false;
        newState.passwordResetRequest.success = null;
      });
    }

    default: {
      return state;
    }
  }
}
