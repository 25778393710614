import { Component, Input } from '@angular/core';
import { TextFieldComponent } from '../text-field/text-field.component';
import { UnitRangeValueDto } from '@vpfa/rest-api/valuation';
import { isString } from 'util';
import { isNil } from 'lodash';

const intervalSign = '-';

@Component({
  selector: 'vpfa-interval-number-field',
  templateUrl: './interval-number-field.component.html',
  styleUrls: ['./interval-number-field.component.scss'],
})
export class IntervalNumberFieldComponent extends TextFieldComponent {
  @Input() required = false;
  charCountLimit = 15;
  @Input() maxFractionNumber = 1;

  @Input() getFormControl() {
    if (super.getFormControl() && super.getFormControl().value) {
      const unitRangeValue: UnitRangeValueDto = super.getFormControl().value;
      if (!isNil(unitRangeValue.value)) {
        if (unitRangeValue.valueMax) {
          super.getFormControl().setValue(unitRangeValue.value + intervalSign + unitRangeValue.valueMax);
        } else {
          super.getFormControl().setValue(unitRangeValue.value);
        }
      } else if (isString(unitRangeValue.unit)) {
        super.getFormControl().setValue(null);
      }
    }
    return super.getFormControl();
  }

  @Input()
  onSubmit(event: any) {
    if (this.preventDefaultForEnterKey) {
      event.preventDefault();
    }
    this.submitValue.emit();
  }
}
