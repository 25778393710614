export const mixPanelInit = (e, b) => {
  if (!b.__SV) {
    let a, f, i, g;
    // @ts-ignore
    window.mixpanel = b;
    b._i = [];
    b.init = function (a, e, d) {
      function f(b, h) {
        const a = h.split('.');
        2 === a.length && ((b = b[a[0]]), (h = a[1]));
        b[h] = function () {
          b.push([h].concat(Array.prototype.slice.call(arguments, 0)));
        };
      }

      let c = b;
      'undefined' !== typeof d ? (c = b[d] = []) : (d = 'mixpanel');
      c.people = c.people || [];
      c.toString = function (b) {
        let a = 'mixpanel';
        'mixpanel' !== d && (a += '.' + d);
        b || (a += ' (stub)');
        return a;
      };
      c.people.toString = function () {
        return c.toString() + '.people (stub)';
      };
      i = 'disable time_event track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config people.set people.set_once people.increment people.append people.union people.track_charge people.clear_charges people.delete_user'.split(
        ' '
      );
      for (g = 0; g < i.length; g++) f(c, i[g]);
      b._i.push([a, e, d]);
    };
    b.__SV = 1.2;
    a = e.createElement('script');
    a.type = 'text/javascript';
    a.async = !0;
    a.src =
      // @ts-ignore
      'undefined' !== typeof MIXPANEL_CUSTOM_LIB_URL
        ? // @ts-ignore
          MIXPANEL_CUSTOM_LIB_URL
        : 'file:' === e.location.protocol && '//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js'.match(/^\/\//)
        ? 'https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js'
        : '//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js';
    f = e.getElementsByTagName('script')[0];
    f.parentNode.insertBefore(a, f);
  }
};
