import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'vpfa-market-badge',
  templateUrl: './market-badge.component.html',
  styleUrls: ['./market-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketBadgeComponent {
  readonly GERMAN_MARKET = 'DE';
  
  @Input() market: string;
}
