import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CUSTOMERS_FEATURE_KEY, reducer } from './+state/customers.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CustomersEffects } from './+state/customers.effects';
import { CustomersFacade } from './+state/customers.facade';
import { CustomersListService } from './services/customers-list.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(CUSTOMERS_FEATURE_KEY, reducer),
    EffectsModule.forFeature([CustomersEffects]),
  ],
  providers: [CustomersFacade, CustomersListService],
})
export class DealerCustomersDataModule {}
