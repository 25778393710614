import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { CountriesPartialState } from './countries.reducer';
import { countriesQuery } from './countries.selectors';
import {
  AddContactUsEnquiryType,
  AddCountryAlternativeContentLanguage,
  AddCountryAlternativeLanguage,
  CountryDetailsLoaded,
  CreateFtpExport,
  DeleteContactUsEnquiryType,
  DeleteCountryAlternativeContentLanguageCommand,
  DeleteCountryAlternativeLanguageCommand,
  EditFtpExport,
  fromCountriesActions,
  LoadBroadcastCertificate,
  LoadBroadcastCountryMappingList,
  LoadBroadcastDetails,
  LoadBroadcastList,
  LoadBroadcastLogo,
  LoadCountries,
  LoadCountryDetails,
  LoadCountryGdv,
  LoadCountryTaxType,
  LoadCountryTaxTypeSuccess,
  LoadExperianCountrySettings,
  LoadVinThreshold,
  LogoUploadCommand,
  RemoveCountryAlternativeContentLanguage,
  RemoveCountryAlternativeLanguage,
  UpdateBroadcastCertificate,
  UpdateBroadcastHostname,
  UpdateBroadcastIsActive,
  UpdateBroadcastLogo,
  UpdateBroadcastName,
  UpdateBroadcastPassword,
  UpdateBroadcastUsername,
  UpdateContactUsCountryCode,
  UpdateContactUsDefaultClassification,
  UpdateContactUsDefaultPriority,
  UpdateContactUsDefaultType,
  UpdateContactUsEnquiryType,
  UpdateContactUsHeaderTexts,
  UpdateCountryCurrency,
  UpdateCountryDefaultContentLanguage,
  UpdateCountryDefaultLanguage,
  UpdateCountryGdvPassword,
  UpdateCountryGdvUsername,
  UpdateCountryHotlineEmail,
  UpdateCountryHotlinePhone,
  UpdateCountryLocale,
  UpdateCountryTaxType,
  UpdateCountryTimezone,
  UpdateCountryUrl,
  UpdateExperianHostUrl,
  UpdateExperianTermsAndConditionsUrl,
  UpdateUCNumberSourceType,
  UpdateVinThreshold,
} from './countries.actions';

import {
  AddContactUsSalesforceEnquiryTypeCommand,
  AddCountryAlternativeContentLanguageCommand,
  AddCountryAlternativeLanguageCommand,
  CountryDto,
  SetExperianHostUrlCommand,
  UpdateContactUsDefaultClassificationCommand,
  UpdateContactUsDefaultPriorityCommand,
  UpdateContactUsDefaultTypeCommand,
  UpdateContactUsHeaderTextsCommand,
  UpdateContactUsSalesforceCountryCodeCommand,
  UpdateContactUsSalesforceEnquiryTypeCommand,
  UpdateCountryCurrencyCommand,
  UpdateCountryDefaultContentLanguageCommand,
  UpdateCountryDefaultLanguageCommand,
  UpdateCountryHotlineEmailCommand,
  UpdateCountryHotlinePhoneCommand,
  UpdateCountryLocaleCommand,
  UpdateCountryTimezoneCommand,
  UpdateCountryUcNumberSourceCommand,
  UpdateCountryUrlCommand,
  UpdateCountryVinThresholdCommand,
  UpdateExperianTermsAndConditionsUrlCommand,
  UpdateTableConfigurationCommand,
} from '@vpfa/rest-api/admin';
import { CountryTaxDto, UpdateCountryTaxTypeCommand } from '@vpfa/rest-api/valuation';
import {
  CreateCountryBroadcastConnectionFtpConfigurationCommand,
  UpdateCountryBroadcastConnectionConfigurationHostnameCommand,
  UpdateCountryBroadcastConnectionConfigurationIsActiveCommand,
  UpdateCountryBroadcastConnectionConfigurationNameCommand,
  UpdateCountryBroadcastConnectionConfigurationPasswordCommand,
  UpdateCountryBroadcastConnectionConfigurationUsernameCommand,
  UpdateCountryBroadcastConnectionFtpConfigurationCommand,
} from '@vpfa/rest-api/ad-broadcast';
import {
  UpdateGdvInsuranceClaimingConfigurationPasswordCommand,
  UpdateGdvInsuranceClaimingConfigurationUsernameCommand,
} from '@vpfa/rest-api/vehicle-insurance-claiming';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class CountriesFacade {
  isListLoaded$ = this.store.pipe(select(countriesQuery.getListLoaded));
  isListLoading$ = this.store.pipe(select(countriesQuery.getListLoading));
  isLoadingValuationListInitialSetOfFields$ = this.store.pipe(
    select(countriesQuery.getIsLoadingValuationListInitialSetOfFields)
  );
  isLoadingStockListInitialSetOfFields$ = this.store.pipe(
    select(countriesQuery.getIsLoadingStockListInitialSetOfFields)
  );
  isLoadingCustomersListInitialSetOfFields$ = this.store.pipe(
    select(countriesQuery.getIsLoadingCustomersListInitialSetOfFields)
  );
  getCountriesList$ = this.store.pipe(select(countriesQuery.getCountriesList));
  getCountriesListOptions$ = this.store.pipe(select(countriesQuery.getCountriesListOptions));
  getValuationListInitialSetOfFieldsData$ = this.store.pipe(
    select(countriesQuery.getValuationListInitialSetOfFieldsData)
  );
  getStockListInitialSetOfFieldsData$ = this.store.pipe(select(countriesQuery.getStockListInitialSetOfFieldsData));
  getCustomersListInitialSetOfFieldsData$ = this.store.pipe(
    select(countriesQuery.getCustomersListInitialSetOfFieldsData)
  );

  countryDetails$ = this.store.pipe(select(countriesQuery.getCountryDetails));
  countryHotlinePhone$ = this.store.pipe(select(countriesQuery.getCountryHotlinePhone));
  countryHotlineEmail$ = this.store.pipe(select(countriesQuery.getCountryHotlineEmail));
  countryCode$ = this.store.pipe(select(countriesQuery.getCountryCode));
  countryUCNumberSource$ = this.store.pipe(select(countriesQuery.getCountryUCNumberSource));
  countryCurrency$ = this.store.pipe(select(countriesQuery.getCountryCurrency));

  isProcessingCountryUCNumberSource$ = this.store.pipe(select(countriesQuery.getisProcessCountryUCNumberSource));
  isCountryDetailsLoading$ = this.store.pipe(select(countriesQuery.getCountryDetailsLoading));

  isCountryDefaultLanguageUpdating$ = this.store.pipe(select(countriesQuery.getCountryDefaultLanguageUpdating));
  isCountryDefaultContentLanguageUpdating$ = this.store.pipe(
    select(countriesQuery.getCountryDefaultContentLanguageUpdating)
  );

  isCountryAlternativeLanguageUpdating$ = this.store.pipe(select(countriesQuery.getCountryAlternativeLanguageUpdating));
  isCountryAlternativeContentLanguageUpdating$ = this.store.pipe(
    select(countriesQuery.getCountryAlternativeContentLanguageUpdating)
  );

  countryTaxType$ = this.store.pipe(select(countriesQuery.getCountryTaxType));
  isProcessingCountryTax$ = this.store.pipe(select(countriesQuery.getIsProcessingCountryTax));
  broadcastList$ = this.store.pipe(select(countriesQuery.getBroadcastList));
  broadcastListOptions$ = this.store.pipe(select(countriesQuery.getBroadcastListOptions));
  broadcastListLoading$ = this.store.pipe(select(countriesQuery.getBroadcastListLoading));
  broadcastCountryId$ = this.store.pipe(select(countriesQuery.getBroadcastCountryId));

  getCountryGdvConfigId$ = this.store.pipe(select(countriesQuery.getCountryGdvConfigId));
  getCountryGdvConfigUsername$ = this.store.pipe(select(countriesQuery.getCountryGdvConfigUsername));
  getIsCountryGdvConfigPasswordSet$ = this.store.pipe(select(countriesQuery.getIsCountryGdvConfigPasswordSet));

  isCountryGdvLoading$ = this.store.pipe(select(countriesQuery.isCountryGdvLoading));
  isCountryGdvUsernameLoading$ = this.store.pipe(select(countriesQuery.isCountryGdvUsernameLoading));
  isCountryGdvSetPasswordLoading$ = this.store.pipe(select(countriesQuery.isCountryGdvSetPasswordLoading));

  isCountryContactUsCountryCodeUpdating$ = this.store.pipe(
    select(countriesQuery.getIsCountryContactUsCountryCodeUpdating)
  );
  isCountryContactUsDefaultPriorityUpdating$ = this.store.pipe(
    select(countriesQuery.getIsCountryContactUsDefaultPriorityUpdating)
  );
  isCountryContactUsDefaultTypeUpdating$ = this.store.pipe(
    select(countriesQuery.getIsCountryContactUsDefaultTypeUpdating)
  );
  isCountryContactUsDefaultClassificationUpdating$ = this.store.pipe(
    select(countriesQuery.getIsCountryContactUsDefaultClassificationUpdating)
  );
  isCountryContactUsEnquiryTypeUpdating$ = this.store.pipe(
    select(countriesQuery.getIsCountryContactUsEnquiryTypeUpdating)
  );
  isCountryContactUsHeaderTextsUpdating$ = this.store.pipe(
    select(countriesQuery.getIsCountryContactUsHeaderTextsUpdating)
  );

  get isCountryLocaleUpdating$() {
    return this.store.pipe(select(countriesQuery.getCountryLocaleUpdating));
  }

  get isCountryCurrencyUpdating$() {
    return this.store.pipe(select(countriesQuery.getCountryCurrencyUpdating));
  }

  get isCountryTimezoneUpdating$() {
    return this.store.pipe(select(countriesQuery.getCountryTimezoneUpdating));
  }

  get logo$() {
    return this.store.pipe(select(countriesQuery.getCountryLogo));
  }

  get isLogoLoading$() {
    return this.store.pipe(select(countriesQuery.isCountryLogoLoading));
  }

  get isLogoUploading$() {
    return this.store.pipe(select(countriesQuery.isCountryLogoUploading));
  }

  get countryUrl$() {
    return this.store.pipe(select(countriesQuery.getCountryUrl));
  }

  get isProcessingCountryUrl$() {
    return this.store.pipe(select(countriesQuery.getIsProcessingCountryUrl));
  }

  get isProcessingCountryHotlinePhone$() {
    return this.store.pipe(select(countriesQuery.getIsProcessingCountryHotlinePhone));
  }

  get isProcessingCountryHotlineEmail$() {
    return this.store.pipe(select(countriesQuery.getIsProcessingCountryHotlineEmail));
  }

  get broadcastListForMappingItems$() {
    return this.store.pipe(select(countriesQuery.getBroadcastListForMappingItems));
  }

  get isBroadcastListForMappingLoading$() {
    return this.store.pipe(select(countriesQuery.getBroadcastListForMappingLoading));
  }

  broadcastDetails$ = this.store.pipe(select(countriesQuery.getBroadcastDetails));
  broadcastDetailsLoading$ = this.store.pipe(select(countriesQuery.getBroadcastDetailsLoading));
  broadcastLogo$ = this.store.pipe(select(countriesQuery.getBroadcastLogo));
  broadcastCertificate$ = this.store.pipe(select(countriesQuery.getBroadcastCertificate));
  broadcastProcessing$ = this.store.pipe(select(countriesQuery.getBroadcastProcessing));
  ftpCreated$ = this.store.pipe(select(countriesQuery.getFtpCreated));
  ftpUpdated$ = this.store.pipe(select(countriesQuery.getFtpUpdated));

  getAvailableVinCallsThreshold$ = this.store.pipe(select(countriesQuery.getVinThreshold));
  isProcessingVinCalls$ = this.store.pipe(select(countriesQuery.isVinThresholdProcessing));

  experianCountryHostUrl$ = this.store.pipe(select(countriesQuery.getExperianCountryHostUrl));
  experianCountryTermsAndConditionsUrl$ = this.store.pipe(
    select(countriesQuery.getExperianCountryTermsAndConditionsUrl)
  );
  isExperianCountrySettingsLoading$ = this.store.pipe(select(countriesQuery.isExperianCountrySettingsLoading));
  isExperianCountrySettingsProcessing$ = this.store.pipe(select(countriesQuery.isExperianCountrySettingsProcessing));

  get countryListAfterLoaded$() {
    return combineLatest([this.getCountriesList$, this.isListLoaded$]).pipe(
      filter(([_, isLoaded]) => isLoaded),
      map(([list]) => list)
    );
  }

  constructor(private store: Store<CountriesPartialState>) {}

  loadAll() {
    this.store.dispatch(new LoadCountries());
  }

  loadCountryDetails(countryId: string) {
    this.store.dispatch(new LoadCountryDetails(countryId));
  }

  loadBroadcastCountryMappingList(countryId: string) {
    this.store.dispatch(new LoadBroadcastCountryMappingList(countryId));
  }

  loadedCountryDetails(country: CountryDto) {
    this.store.dispatch(new CountryDetailsLoaded(country));
  }

  updateCountryDefaultLanguage(command: UpdateCountryDefaultLanguageCommand) {
    this.store.dispatch(new UpdateCountryDefaultLanguage(command));
  }

  updateCountryDefaultContentLanguage(command: UpdateCountryDefaultContentLanguageCommand) {
    this.store.dispatch(new UpdateCountryDefaultContentLanguage(command));
  }

  addCountryAlternativeLanguage(command: AddCountryAlternativeLanguageCommand) {
    this.store.dispatch(new AddCountryAlternativeLanguage(command));
  }

  removeCountryAlternativeLanguage(command: DeleteCountryAlternativeLanguageCommand) {
    this.store.dispatch(new RemoveCountryAlternativeLanguage(command));
  }

  addCountryAlternativeContentLanguage(command: AddCountryAlternativeContentLanguageCommand) {
    this.store.dispatch(new AddCountryAlternativeContentLanguage(command));
  }

  removeCountryAlternativeContentLanguage(command: DeleteCountryAlternativeContentLanguageCommand) {
    this.store.dispatch(new RemoveCountryAlternativeContentLanguage(command));
  }

  updateCountryLocale(command: UpdateCountryLocaleCommand) {
    this.store.dispatch(new UpdateCountryLocale(command));
  }

  updateCountryTimezone(command: UpdateCountryTimezoneCommand) {
    this.store.dispatch(new UpdateCountryTimezone(command));
  }

  updateCountryCurrency(command: UpdateCountryCurrencyCommand) {
    this.store.dispatch(new UpdateCountryCurrency(command));
  }

  loadTaxType(command: string) {
    this.store.dispatch(new LoadCountryTaxType(command));
  }

  taxTypeLoaded(command: CountryTaxDto) {
    this.store.dispatch(new LoadCountryTaxTypeSuccess(command));
  }

  updateTaxType(command: UpdateCountryTaxTypeCommand) {
    this.store.dispatch(new UpdateCountryTaxType(command));
  }

  updateCountryValuationListInitialSetOfFields(countryId: string, command: UpdateTableConfigurationCommand) {
    this.store.dispatch(new fromCountriesActions.CountryValuationListInitialSetOfFieldsUpdate({ countryId, command }));
  }

  updateCountryUcNumberSource(updateCountryUcNumberSourceCommand: UpdateCountryUcNumberSourceCommand) {
    this.store.dispatch(new UpdateUCNumberSourceType(updateCountryUcNumberSourceCommand));
  }

  loadCountryValuationListInitialSetOfFields(countryId: string, tableName: string) {
    this.store.dispatch(new fromCountriesActions.CountryValuationListInitialSetOfFieldsLoad({ countryId, tableName }));
  }

  loadLogo(businessId: string) {
    this.store.dispatch(new fromCountriesActions.LogoLoad(businessId));
  }

  uploadLogo(command: LogoUploadCommand) {
    this.store.dispatch(new fromCountriesActions.LogoUpload(command));
  }

  updateCountryStockListInitialSetOfFields(countryId: string, command: UpdateTableConfigurationCommand) {
    this.store.dispatch(new fromCountriesActions.CountryStockListInitialSetOfFieldsUpdate({ countryId, command }));
  }

  loadCountryStockListInitialSetOfFields(countryId: string, tableName: string) {
    this.store.dispatch(new fromCountriesActions.CountryStockListInitialSetOfFieldsLoad({ countryId, tableName }));
  }

  countryCustomersListInitialSetOfFieldsUpdate(countryId: string, command: UpdateTableConfigurationCommand) {
    this.store.dispatch(new fromCountriesActions.CountryCustomersListInitialSetOfFieldsUpdate({ countryId, command }));
  }

  countryCustomersListInitialSetOfFieldsLoad(countryId: string, tableName: string) {
    this.store.dispatch(new fromCountriesActions.CountryCustomersListInitialSetOfFieldsLoad({ countryId, tableName }));
  }

  updateCountryUrl(command: UpdateCountryUrlCommand) {
    this.store.dispatch(new UpdateCountryUrl(command));
  }

  updateCountryHotlinePhone(command: UpdateCountryHotlinePhoneCommand) {
    this.store.dispatch(new UpdateCountryHotlinePhone(command));
  }

  updateCountryHotlineEmail(command: UpdateCountryHotlineEmailCommand) {
    this.store.dispatch(new UpdateCountryHotlineEmail(command));
  }

  loadBroadcastList(countryId: string) {
    this.store.dispatch(new LoadBroadcastList(countryId));
  }

  loadBroadcastDetails(broadcastId: string, countryId: string) {
    this.store.dispatch(new LoadBroadcastDetails({ broadcastId, countryId }));
  }

  loadBroadcastLogo(broadcastId: string) {
    this.store.dispatch(new LoadBroadcastLogo(broadcastId));
  }

  loadBroadcastCertificate(broadcastId: string) {
    this.store.dispatch(new LoadBroadcastCertificate(broadcastId));
  }

  updateBroadcastName(command: UpdateCountryBroadcastConnectionConfigurationNameCommand) {
    this.store.dispatch(new UpdateBroadcastName(command));
  }

  updateBroadcastIsActive(command: UpdateCountryBroadcastConnectionConfigurationIsActiveCommand) {
    this.store.dispatch(new UpdateBroadcastIsActive(command));
  }
  updateBroadcastUsername(command: UpdateCountryBroadcastConnectionConfigurationUsernameCommand) {
    this.store.dispatch(new UpdateBroadcastUsername(command));
  }

  updateBroadcastPassword(command: UpdateCountryBroadcastConnectionConfigurationPasswordCommand) {
    this.store.dispatch(new UpdateBroadcastPassword(command));
  }

  updateBroadcastLogo(broadcastingId: string, file: Blob) {
    this.store.dispatch(new UpdateBroadcastLogo({ file, aggregateRootId: broadcastingId }));
  }

  updateBroadcastHostname(command: UpdateCountryBroadcastConnectionConfigurationHostnameCommand) {
    this.store.dispatch(new UpdateBroadcastHostname(command));
  }

  updateBroadcastCertificate(broadcastingId: string, countryId: string, file: Blob) {
    this.store.dispatch(new UpdateBroadcastCertificate({ file, countryId, aggregateRootId: broadcastingId }));
  }

  createFtpExport(ftpConfig: CreateCountryBroadcastConnectionFtpConfigurationCommand, file: any) {
    this.store.dispatch(new CreateFtpExport({ ftpConfig, file }));
  }

  editFtpExport(ftpConfig: UpdateCountryBroadcastConnectionFtpConfigurationCommand, file: any) {
    this.store.dispatch(new EditFtpExport({ ftpConfig, file }));
  }

  updateContactUsCountryCode(command: UpdateContactUsSalesforceCountryCodeCommand) {
    this.store.dispatch(new UpdateContactUsCountryCode(command));
  }

  updateContactUsDefaultPriority(command: UpdateContactUsDefaultPriorityCommand) {
    this.store.dispatch(new UpdateContactUsDefaultPriority(command));
  }

  updateContactUsDefaultType(command: UpdateContactUsDefaultTypeCommand) {
    this.store.dispatch(new UpdateContactUsDefaultType(command));
  }

  updateContactUsDefaultClassification(command: UpdateContactUsDefaultClassificationCommand) {
    this.store.dispatch(new UpdateContactUsDefaultClassification(command));
  }

  updateContactUsHeaderTexts(command: UpdateContactUsHeaderTextsCommand) {
    this.store.dispatch(new UpdateContactUsHeaderTexts(command));
  }

  updateContactUsEnquiryType(command: UpdateContactUsSalesforceEnquiryTypeCommand) {
    this.store.dispatch(new UpdateContactUsEnquiryType(command));
  }

  addContactUsEnquiryType(command: AddContactUsSalesforceEnquiryTypeCommand) {
    this.store.dispatch(new AddContactUsEnquiryType(command));
  }

  deleteContactUsEnquiryType(countryId: string, enquiryTypeId: number) {
    this.store.dispatch(new DeleteContactUsEnquiryType({ countryId, enquiryTypeId }));
  }

  loadVinThreshold(countryId: string) {
    this.store.dispatch(new LoadVinThreshold(countryId));
  }

  updateVinThreshold(command: UpdateCountryVinThresholdCommand) {
    this.store.dispatch(new UpdateVinThreshold(command));
  }

  loadExperianCountrySettings(countryId: string) {
    this.store.dispatch(new LoadExperianCountrySettings(countryId));
  }

  updateExperianHostUrl(countryId: string, command: SetExperianHostUrlCommand) {
    this.store.dispatch(new UpdateExperianHostUrl({ countryId, command }));
  }

  updateExperianTermsAndConditionsUrl(countryId: string, command: UpdateExperianTermsAndConditionsUrlCommand) {
    this.store.dispatch(new UpdateExperianTermsAndConditionsUrl({ countryId, command }));
  }

  loadCountryGdvConfiguration(countryId: string) {
    this.store.dispatch(new LoadCountryGdv(countryId));
  }

  updateCountryGdvUsername(countryId: string, command: UpdateGdvInsuranceClaimingConfigurationUsernameCommand) {
    this.store.dispatch(new UpdateCountryGdvUsername({ countryId, command }));
  }

  updateCountryGdvPassword(countryId: string, command: UpdateGdvInsuranceClaimingConfigurationPasswordCommand) {
    this.store.dispatch(new UpdateCountryGdvPassword({ countryId, command }));
  }
}
