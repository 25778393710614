import { Pipe, PipeTransform } from '@angular/core';
import { UnitValueDto, UnitTextValueDto } from '@vpfa/rest-api/valuation';
import { NumberLocalePipe } from '../number-locale/number-locale.pipe';
import { joinNumericValues } from '../shared/join-numeric-values.util';

type UnitValueDtos = UnitValueDto | UnitTextValueDto;

@Pipe({
  name: 'getTwoValue',
  pure: false,
})
export class GetTwoValuePipe implements PipeTransform {
  constructor(private formatterPipe: NumberLocalePipe) {}

  transform(input: UnitValueDtos[]): string {
    const firstValue = input?.[0]?.value;
    const secondValue = input?.[1]?.value;

    return joinNumericValues(firstValue, secondValue, '/', this.formatterPipe);
  }
}
