import { I18RangeValueDto } from '@vpfa/rest-api/valuation';

export const intervalSign = '-';

export function getI18RangeValue(input: I18RangeValueDto): string | null {
  let result: string[] = [];

  if (input?.value) {
    result.push(input.value.texts);
  }

  if (input?.valueMax) {
    result.push(input.valueMax.texts);
  }

  return result?.join(` ${intervalSign} `);
}
