<p class="warning" *ngIf="mileageDetails?.isMileageOutOfSequence">
  {{ 'provenanceCheck.mileage.outOfSequence' | translate }}
</p>
<div *ngIf="mileageDetails?.mileageItems?.length; else noData">
  <div fxLayout="column" *ngFor="let data of mileageDetails?.mileageItems">
    <div fxLayout="row" fxFlex="100%" fxLayoutAlign="space-between">
      <div class="label">{{ 'provenanceCheck.mileage.source' | translate }}</div>
      <div class="value">{{ data.sourceOfMileage }}</div>
    </div>
    <div fxLayout="row" fxFlex="100%" fxLayoutAlign="space-between">
      <div class="label">{{ 'provenanceCheck.mileage.date' | translate }}</div>
      <div class="value">{{ data.dateOfInformation | dateLocale: 'shortDate' }}</div>
    </div>
    <div fxLayout="row" fxFlex="100%" fxLayoutAlign="space-between">
      <div class="label">{{ 'provenanceCheck.mileage.mileage' | translate }}</div>
      <div class="value">{{ data.mileage | numberLocale: '1.0-0' }}</div>
    </div>
    <nz-divider></nz-divider>
  </div>
</div>

<ng-template #noData>
  <vpfa-empty></vpfa-empty>
</ng-template>
