<ng-template #ctr>
  <vpfa-modal-content>
    <div class="main-container">
      <div class="d-flex flex-row justify-content-center">
        <div style="margin-bottom: 20px">
          <i class="icon zmdi zmdi-alert-circle"></i>
        </div>
      </div>
      <h3 class="heading">
        {{ titleKey | translate }}
      </h3>

      <ul class="list">
        <ng-container *ngFor="let infoLabelKey of infoLabelsKey">
          <li [innerHTML]="infoLabelKey | translate"></li>
        </ng-container>
      </ul>

      <div class="d-flex flex-row justify-content-center gap-10px">
        <div>
          <vpfa-modal-button [outline]="true" [border]="false" (click)="confirmResult.emit()">
            <span class="ok">{{ okLabelKey | translate }}</span>
          </vpfa-modal-button>
        </div>
      </div>
    </div>
  </vpfa-modal-content>
</ng-template>
