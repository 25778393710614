import { casesQuery } from '../cases.selectors';
import { createSelector } from '@ngrx/store';

const getProvenanceCheckState = casesQuery.getProvenanceCheck;

const getCO2EmissionState = createSelector(getProvenanceCheckState, s => s.cO2Emission);

const getIsProvenanceCheckLoading = createSelector(getProvenanceCheckState, s => s.loading);

const getCo2EmissionRanges = createSelector(getCO2EmissionState, s => s.ranges);

const getCo2EmissionRangesLoading = createSelector(getCO2EmissionState, s => s.loading);

export const provenanceCheckQuery = {
  getIsProvenanceCheckLoading,
  getCo2EmissionRanges,
  getCo2EmissionRangesLoading,
};
