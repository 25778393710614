import { uniqWith, isEqual, isNil } from 'lodash';

export const NO_FILTER_APPLIED = 'all';
export const initialFilter = { value: NO_FILTER_APPLIED, name: 'filters.all' };
export const initialOptions = [initialFilter];

export function getUniqueOptions<T>(
  options: Array<T>,
  key: string,
  transformLabel: (v: any) => any = null,
  sort = false
) {
  var list = [
    ...uniqWith(options, isEqual)
      .map(v => v[key])
      .filter(value => !isNil(value))
      .map(value => ({
        name: transformLabel instanceof Function ? transformLabel(value) : value,
        value,
      })),
  ];

  if (sort) {
    list.sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  return [...initialOptions, ...list];
}
