import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EquipmentDto } from '@vpfa/rest-api/identification';

@Component({
  selector: 'vpfa-vehicle-special-equipment-list',
  templateUrl: './vehicle-special-equipment-list.component.html',
  styleUrls: ['./vehicle-special-equipment-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleSpecialEquipmentListComponent {
  @Input() equipmentData: EquipmentDto[];
  @Input() showEquipmentShortDescription: boolean;
}
