export function downloadBase64File(fileType: 'pdf' | 'other', fileData: string, fileName: string) {
  let prefix = '';

  switch (fileType) {
    case 'pdf':
      break;

    default:
      console.warn('Unsupported file type: ' + fileType);
      break;
  }

  const link = document.createElement('a');
  link.href = `data:application/pdf;base64,${fileData}`;
  link.download = fileName;
  link.click();
  link.remove();
}
