import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OAuthAuthorizeParams } from '../models/oauth-authorize-params';
import { Authorize, DenyAuthorize } from './oauth.actions';
import { OauthPartialState as OAuthPartialState } from './oauth.reducer';
import { oauthQuery } from './oauth.selectors';

@Injectable({ providedIn: 'root' })
export class OAuthFacade {
  loading$ = this.store.select(oauthQuery.getLoading);

  constructor(private store: Store<OAuthPartialState>) {}

  authorize(data: OAuthAuthorizeParams) {
    this.store.dispatch(new Authorize(data));
  }

  denyAuthorize(redirectUrl: string, state: string) {
    this.store.dispatch(
      new DenyAuthorize({
        redirectUrl,
        state,
      })
    );
  }
}
