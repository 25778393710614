export const urlsWithDebugging = [
  'localhost',
  'dc.qa.autovistacloud.net.int',
  'dc.testdev.autovistacloud.net.int',
  'dc.uat.autovistacloud.net.int',
  'dc.staging.autovistacloud.net.int',
  'dc.live.autovistacloud.net.int',
];

export type DebugName = 'contactUs_mailMeEnquiryResult' | 'formulaWizard';

const enabledDebug: Record<DebugName, boolean> = {
  contactUs_mailMeEnquiryResult: false,
  formulaWizard: false,
};

export function addDebug(name: DebugName, func: (...args: any) => boolean) {
  const isDebuggingAvailable = urlsWithDebugging.map(x => location.host.includes(x)).some(x => x === true);

  if (!isDebuggingAvailable) {
    return;
  }

  if ((window as any).debug === undefined) {
    (window as any).debug = {};
  }

  (window as any).debug[name] = (...args: any) => {
    enabledDebug[name] = true;
    return func(...args);
  };
}

export function isDebugEnabled(name: DebugName) {
  const isDebuggingAvailable = urlsWithDebugging.map(x => location.host.includes(x)).some(x => x === true);

  if (!isDebuggingAvailable) {
    return false;
  }

  return enabledDebug[name] === true;
}

export function removeDebug(name: DebugName) {
  if ((window as any).debug === undefined || (window as any).debug[name] === undefined) {
    return;
  }

  enabledDebug[name] = false;

  (window as any).debug[name] = undefined;
}
