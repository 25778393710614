import { produce } from 'immer';
import { SorcIntegrationActions, SorcIntegrationActionTypes } from './sorc-integration.actions';
import { MakeDto } from '../models/make-dto';
import { ModelDto } from '../models/model-dto';
import { VehicleDto } from '../models/vehicle-dto';
import { OptionalSearchFiltersOptions } from '../models/optional-search-filters';
import { mapOptionalSearchFiltersOptions } from '../utils/map-optional-search-filters-options';
import { SorcDictionaryDto } from '../models/sorc-dictionary-dto';

export const SORC_INTEGRATION_FEATURE_KEY = 'sorcIntegration';

export interface SorcIntegrationPartialState {
  readonly [SORC_INTEGRATION_FEATURE_KEY]: SorcIntegrationState;
}

export interface SorcIntegrationState {
  makes: MakesState;
  models: ModelsState;
  vehicles: VehiclesState;
  dictionaries: DictionariesState;
}

export interface MakesState {
  makesList: MakeDto[];
  loading: boolean;
}

export interface ModelsState {
  modelsList: ModelDto[];
  loading: boolean;
}

export interface VehiclesState {
  vehiclesList: VehicleDto[];
  totalResults: number;
  optionalSearchFiltersOptions: OptionalSearchFiltersOptions;
  loading: boolean;
}

export interface DictionariesState {
  transmissionTypeList: SorcDictionaryDto[];
  bodyTypeList: SorcDictionaryDto[];
  fuelTypeList: SorcDictionaryDto[];
}

export const initialState: SorcIntegrationState = {
  makes: {
    makesList: [],
    loading: false,
  },
  models: {
    modelsList: [],
    loading: false,
  },
  vehicles: {
    vehiclesList: [],
    totalResults: 0,
    optionalSearchFiltersOptions: null,
    loading: false,
  },
  dictionaries: {
    transmissionTypeList: [],
    bodyTypeList: [],
    fuelTypeList: [],
  },
};

export function reducer(state = initialState, action: SorcIntegrationActions): SorcIntegrationState {
  switch (action.type) {
    case SorcIntegrationActionTypes.LoadMakesList:
      return produce(state, newState => {
        newState.makes.loading = true;
      });
    case SorcIntegrationActionTypes.LoadMakesListCompleted:
      return produce(state, newState => {
        newState.makes.makesList = action.payload;
        newState.makes.loading = false;
        newState.models.modelsList = [];
      });
    case SorcIntegrationActionTypes.LoadMakesListError:
      return produce(state, newState => {
        newState.makes.loading = false;
        newState.models.modelsList = [];
      });
    case SorcIntegrationActionTypes.LoadModelsList:
      return produce(state, newState => {
        newState.models.loading = true;
      });
    case SorcIntegrationActionTypes.LoadModelsListCompleted:
      return produce(state, newState => {
        newState.models.modelsList = action.payload;
        newState.models.loading = false;
      });
    case SorcIntegrationActionTypes.LoadModelsListError:
      return produce(state, newState => {
        newState.models.loading = false;
      });
    case SorcIntegrationActionTypes.LoadVehiclesList:
      return produce(state, newState => {
        newState.vehicles.loading = true;
      });
    case SorcIntegrationActionTypes.LoadVehiclesListCompleted:
      return produce(state, newState => {
        newState.vehicles.vehiclesList = action.payload?.results;
        newState.vehicles.totalResults = action.payload?.totalResults;
        newState.vehicles.optionalSearchFiltersOptions = mapOptionalSearchFiltersOptions(
          action.payload?.values,
          state.dictionaries?.transmissionTypeList,
          state.dictionaries?.bodyTypeList,
          state.dictionaries?.fuelTypeList
        );
        newState.vehicles.loading = false;
      });
    case SorcIntegrationActionTypes.LoadVehiclesListError:
      return produce(state, newState => {
        newState.vehicles.loading = false;
      });
    case SorcIntegrationActionTypes.LoadTransmissionTypeListCompleted:
      return produce(state, newState => {
        newState.dictionaries.transmissionTypeList = action.payload;
      });
    case SorcIntegrationActionTypes.LoadBodyTypeListCompleted:
      return produce(state, newState => {
        newState.dictionaries.bodyTypeList = action.payload;
      });
    case SorcIntegrationActionTypes.LoadFuelTypeListCompleted:
      return produce(state, newState => {
        newState.dictionaries.fuelTypeList = action.payload;
      });
    case SorcIntegrationActionTypes.ClearMakesList:
      return produce(state, newState => {
        newState.makes = initialState.makes;
      });
    case SorcIntegrationActionTypes.ClearModelsList:
      return produce(state, newState => {
        newState.models = initialState.models;
      });
    case SorcIntegrationActionTypes.ClearVehiclesList:
      return produce(state, newState => {
        newState.vehicles = initialState.vehicles;
      });
  }
  return state;
}
