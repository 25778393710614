import { Action } from '@ngrx/store';
import { FileNotificationModel } from '../models/file-notification.model';
import { NotificationModel } from '../models/notification.model';

export enum NotificationsActionTypes {
  AddBasicNotification = '[Notifications] Add Basic Notification',
  AddFileNotification = '[Notifications] Add File Notification',
  ClearAllNotifications = '[Notifications] Clear All Notifications',
  SetCountOfFilesUpload = '[Notifications] Set Count Of Files Upload',
  SetInvalidFilesCount = '[Notifications] Set Invalid Files Count',
}

export class AddBasicNotification implements Action {
  readonly type = NotificationsActionTypes.AddBasicNotification;
  constructor(public payload: NotificationModel) {}
}

export class AddFileNotification implements Action {
  readonly type = NotificationsActionTypes.AddFileNotification;
  constructor(public payload: FileNotificationModel) {}
}

export class ClearAllNotifications implements Action {
  readonly type = NotificationsActionTypes.ClearAllNotifications;
}

export class SetCountOfFilesUpload implements Action {
  readonly type = NotificationsActionTypes.SetCountOfFilesUpload;
  constructor(public count: number) {}
}

export class SetInvalidFilesCount implements Action {
  readonly type = NotificationsActionTypes.SetInvalidFilesCount;
  constructor(public count: number) {}
}

export type NotificationsAction =
  | AddBasicNotification
  | AddFileNotification
  | ClearAllNotifications
  | SetInvalidFilesCount
  | SetCountOfFilesUpload;

export const fromNotificationsActions = {
  AddBasicNotification,
  AddFileNotification,
  ClearAllNotifications,
  SetInvalidFilesCount,
  SetCountOfFilesUpload,
};
