import { Component, Input } from '@angular/core';
import { BaseFullTechSpecAndEquipmentList } from '../base-full-tech-spec-and-equipment-list/base-full-tech-spec-and-equipment-list';

@Component({
  selector: 'vpfa-full-tech-spec-and-equipment-list-modal',
  templateUrl: './full-tech-spec-and-equipment-list-modal.component.html',
  styleUrls: ['./full-tech-spec-and-equipment-list-modal.component.scss'],
})
export class FullTechSpecAndEquipmentListModalComponent extends BaseFullTechSpecAndEquipmentList {
  @Input() isVisible: boolean;
}
