import { ChangeDetectorRef, Component } from '@angular/core';
import { elementsGap, elementSize } from '../utils';
import { BaseProvenanceCheckSectionKeepersComponent } from '../base-provenance-check-section-keepers.component';
import { LocaleFacade } from '@vpfa/locale';

@Component({
  selector: 'vpfa-provenance-check-section-keepers',
  templateUrl: './provenance-check-section-keepers.component.html',
  styleUrls: ['./provenance-check-section-keepers.component.scss'],
})
export class ProvenanceCheckSectionKeepersComponent extends BaseProvenanceCheckSectionKeepersComponent {
  elementsGap = elementsGap;
  elementSize = elementSize;

  constructor(localeFacade: LocaleFacade, ref: ChangeDetectorRef) {
    super(localeFacade, ref);
  }
}
