import { Component, EventEmitter } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DataTableAction, DataTableActionEvent } from '../../models/data-table-action';

@Component({
  selector: 'vpfa-icon-delete-renderer',
  templateUrl: './icon-delete-renderer.component.html',
  styleUrls: ['./icon-delete-renderer.component.scss'],
})
export class IconDeleteRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  actionEmitter: EventEmitter<DataTableActionEvent>;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    if (this.params) {
      const context = this.params.context;
      if (context && context.componentParent && context.componentParent.actionClicked) {
        this.actionEmitter = context.componentParent.actionClicked;
      }
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  emitAction(): void {
    if (this.actionEmitter) {
      this.actionEmitter.emit({
        action: DataTableAction.DELETE,
        data: this.params.data,
      });
    }
  }
}
