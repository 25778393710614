<div [fxLayout]="'row wrap'" [fxLayoutAlign]="'start start'" [fxLayoutGap]="elementsGap">
  <div [fxLayout]="'column'" [fxFlex]="elementSize">
    <vpfa-label [ellipsis]="true" [text]="'vehicleOverviewData.firstRegDate' | translate"></vpfa-label>
    <span class="overview-value">
      <vpfa-inline-editable-date
        id="registrationDateValue"
        [attr.data-registration-date]="overviewData?.regDate"
        class="date-input"
        [date]="overviewData?.regDate"
        [disabledDate]="disabledDate"
        [disabledDatePicker]="disableDatePicker()"
        [isProcessing]="isUpdatingRegDate"
        (submitValue)="updateRegistrationDate.emit($event)"
      ></vpfa-inline-editable-date>
    </span>
  </div>
  <div [fxLayout]="'column'" [fxFlex]="elementSize">
    <vpfa-label
      id="mileageLabel"
      [ellipsis]="true"
      [text]="('vehicleOverviewData.mileage' | translate) + (overviewData?.mileage | unitValue)"
    ></vpfa-label>
    <span class="overview-value">
      <vpfa-inline-editable-number
        id="mileageValue"
        [attr.data-mileage]="overviewData?.mileage | json"
        fieldHeight="20px"
        [errorsInPopover]="true"
        [validators]="mileageValidators"
        [isProcessing]="isUpdatingMileage"
        [numericValue]="overviewData?.mileage?.value"
        (submitValue)="updateMileageAdjustment.emit($event)"
      ></vpfa-inline-editable-number>
    </span>
  </div>
  <div [fxLayout]="'column'" [fxFlex]="elementSize">
    <vpfa-label
      id="engineSizeLabel"
      [ellipsis]="true"
      [text]="
        ('vehicleFullTechnicalData.engineInformation.engineCapacity' | translate) +
        (overviewData?.engineSize | unitValue)
      "
    ></vpfa-label>
    <span id="engineSizeValue" [attr.data-mileage]="overviewData?.engineSize | json" class="overview-value">{{
      overviewData?.engineSize | engineCapacity
    }}</span>
  </div>
  <div
    id="bodyTypeDoors"
    [attr.data-body-type]="overviewData?.bodyType"
    [attr.data-doors]="overviewData?.numberDoors"
    [fxLayout]="'column'"
    [fxFlex]="elementSize"
  >
    <vpfa-label [ellipsis]="true" [text]="bodyTypeDoorsLabel | translate"></vpfa-label>
    <span class="overview-value">{{ bodyTypeDoorsValue }}</span>
  </div>
  <div [fxLayout]="'column'" [fxFlex]="elementSize">
    <vpfa-label [ellipsis]="true" [text]="'vehicleOverviewData.listPrice' | translate"></vpfa-label>
    <span id="listPriceValue" [attr.data-list-price-value]="overviewData?.listPrice" class="overview-value">
      {{ overviewData?.listPrice | currencyLocale: null:null:'1.2-2' | lT }}
    </span>
  </div>
  <div [fxLayout]="'column'" [fxFlex]="elementSize">
    <vpfa-label [ellipsis]="true" [text]="'vehicleOverviewData.optionPrice' | translate"></vpfa-label>
    <span
      id="optionalEquipmentPriceValue"
      [attr.data-optional-equipment-value]="overviewData?.optionalEquipmentPrice"
      class="overview-value"
    >
      {{ overviewData?.optionalEquipmentPrice | currencyLocale: null:null:'1.2-2' | lT }}
    </span>
  </div>
  <div [fxLayout]="'column'" [fxFlex]="elementSize">
    <vpfa-label [ellipsis]="true" [text]="'vehicleOverviewData.totalPrice' | translate"></vpfa-label>
    <span id="totalPriceValue" [attr.data-total-price]="overviewData?.totalPrice" class="overview-value">
      {{ overviewData?.totalPrice | currencyLocale: null:null:'1.2-2' | lT }}
    </span>
  </div>
  <div [fxLayout]="'column'" [fxFlex]="elementSize" *ngIf="overviewData?.emissionClass">
    <vpfa-label [ellipsis]="true" [text]="'vehicleOverviewData.emissionClass' | translate"></vpfa-label>
    <span
      id="emissionClassValue"
      [attr.data-emission-class]="overviewData?.emissionClass | json"
      class="overview-value"
    >
      {{ overviewData?.emissionClass?.texts | lT }}
    </span>
  </div>
</div>
