import { getCurrentDate} from '@vpfa/utils';
import { DateTime } from 'luxon';

export function notEarlierThan(current: string, timezone: string, disableToday = true): boolean {
  const currentLuxon = DateTime.fromISO(current).startOf('day');
  const todayLuxon = DateTime.fromISO(getCurrentDate(timezone)).startOf('day');
  return disableToday ? (todayLuxon >= currentLuxon) : (todayLuxon > currentLuxon)
}

export function notEarlierThanMinimal(current: string, minimal: string, disableSameAsMinimal = false): boolean {
  let currentLuxon = DateTime.fromISO(current).startOf('day');
  let minimalLuxon = DateTime.fromISO(minimal).startOf('day');
  if (disableSameAsMinimal) {
    return minimalLuxon >= currentLuxon;
  } else {
    return minimalLuxon > currentLuxon;
  }
}
