<a
  [attr.id]="fieldName + 'DateRangeFilterSelectedRangeText'"
  class="ssm-input d-flex justify-content-between align-items-center"
  [ngClass]="{ 'has-focus': this.visible }"
  nz-dropdown
  nzTrigger="click"
  [nzBackdrop]="true"
  [nzDropdownMenu]="menu"
  [(nzVisible)]="visible"
  (nzVisibleChange)="onVisibleChange($event)"
>
  <span
    class="selected-range"
    title="{{ initialValueFrom | dateLocale: 'shortDate' }} &mdash; {{ initialValueTo | dateLocale: 'shortDate' }}"
  >
    <ng-container *ngIf="!isNil(initialValueFrom) || !isNil(initialValueTo)">
      {{ initialValueFrom | dateLocale: 'shortDate' }} &mdash; {{ initialValueTo | dateLocale: 'shortDate' }}
    </ng-container>
  </span>
  <span class="suffix">
    <span class="zmdi zmdi-calendar-alt"></span>
  </span>
</a>

<nz-dropdown-menu #menu="nzDropdownMenu" [formGroup]="formGroup">
  <div [attr.id]="fieldName + 'NumberRangeFilterBody'" class="filter-body">
    <div class="filter-row">
      <span class="label">{{ 'filters.from' | translate }}:</span>
      <nz-date-picker
        #startDatePicker
        [attr.id]="htmlId + 'DateRangeFilterFrom'"
        class="date-picker-no-shadow"
        formControlName="startValue"
        [nzFormat]="'short' | localeDateFormat"
        [nzSize]="'small'"
        [nzShowToday]="false"
        [nzSeparator]="'|'"
        [nzDisabledDate]="disabledStartDate"
      ></nz-date-picker>
    </div>

    <div class="filter-row">
      <span class="label">{{ 'filters.to' | translate }}:</span>
      <nz-date-picker
        #endDatePicker
        [attr.id]="htmlId + 'DateRangeFilterTo'"
        class="date-picker-no-shadow"
        formControlName="endValue"
        [nzFormat]="'short' | localeDateFormat"
        [nzSize]="'small'"
        [nzShowToday]="false"
        [nzSeparator]="'|'"
        [nzDisabledDate]="disabledEndDate"
      ></nz-date-picker>
    </div>

    <vpfa-action-button
      [attr.id]="fieldName + 'DateRangeFilterApplyBtn'"
      class="submit-button"
      [border]="false"
      [outline]="true"
      [size]="'small'"
      [textAlign]="'right'"
      [fullWidth]="false"
      (click)="onApplyFilters()"
    >
      {{ 'filters.applyRangeFilter' | translate }}
    </vpfa-action-button>
  </div>
</nz-dropdown-menu>
