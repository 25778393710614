<vpfa-mobile-subtitle [title]="'genericCaseDetails.priceAdjustments' | translate">
  <vpfa-inline-loader right *ngIf="isLoading && !notAvailable"></vpfa-inline-loader>
</vpfa-mobile-subtitle>

<vpfa-mobile-action-button (click)="openVehicleDetails.emit()">
  <vpfa-mobile-price-button-content
    [label]="'caseValuationDetails.vehicleDetails' | translate"
    [value]="vehicleDetailsValue"
    [notAvailable]="notAvailable"
  ></vpfa-mobile-price-button-content>
</vpfa-mobile-action-button>
<vpfa-mobile-action-button (click)="openEquipment.emit()">
  <vpfa-mobile-price-button-content
    [label]="'caseValuationDetails.equipment.title' | translate"
    [value]="equipmentTotal$ | async | currencyLocale: null:null:'1.2'"
    [notAvailable]="notAvailable"
  ></vpfa-mobile-price-button-content>
</vpfa-mobile-action-button>
<vpfa-mobile-action-button (click)="openRepairAndMaintenance.emit()">
  <vpfa-mobile-price-button-content
    [label]="'caseValuationDetails.repairAndMaintenance' | translate"
    [value]="repairAndMaintenanceValue"
    [notAvailable]="!canMakeValuation"
  ></vpfa-mobile-price-button-content>
</vpfa-mobile-action-button>

<vpfa-mobile-action-button (click)="openProvenanceCheck.emit()" *ngIf="permissions?.canExperian">
  {{ 'caseValuationDetails.provenanceCheck' | translate }} (<span class="status" [ngClass]="statusClass">{{
    statusMessageKey | translate
  }}</span
  >)
</vpfa-mobile-action-button>

<ng-container *ngIf="caseStatus !== CaseStatus.Valuated">
  <vpfa-mobile-action-button (click)="openProspectCustomers.emit()">
    {{ 'caseValuationDetails.prospectCustomers' | translate }} ({{ prospectCustomersCount }})
  </vpfa-mobile-action-button>
  <vpfa-mobile-action-button (click)="openPreviousOwners.emit()">
    {{ 'caseValuationDetails.previousOwners' | translate }} ({{ previousOwnersCount }})
  </vpfa-mobile-action-button>
</ng-container>
<vpfa-mobile-action-button
  *ngIf="caseStatus === CaseStatus.Valuated || caseStatus === CaseStatus.Sold"
  (click)="openOwners.emit()"
>
  {{ 'caseValuationDetails.owners' | translate }} ({{ ownersCount }})
</vpfa-mobile-action-button>

<vpfa-mobile-action-button (click)="openNotes.emit()">
  {{ 'common.notes' | translate }}
</vpfa-mobile-action-button>
