import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ConfirmAdvertisedImagesFacade } from '../../+state/confirm-advertised-images.facade';
import { BroadcastFacade } from '@vpfa/dealer/broadcasts/data';
import { CasesFacade } from '@vpfa/dealer/case/data';
import { CaseStatus } from '@vpfa/rest-api/valuation';
import { createImageFromFile } from '@vpfa/shared/files';
import { IsMobileViewService } from '@vpfa/shared/mobile-view';
import { isNil } from 'lodash';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'vpfa-common-confirm-advertised-images-modal',
  templateUrl: './common-confirm-advertised-images-modal.component.html',
  styleUrls: ['./common-confirm-advertised-images-modal.component.scss'],
})
export class CommonConfirmAdvertisedImagesModalComponent implements OnInit {
  @Output() cancel = new EventEmitter();

  images: {
    isSelected: boolean;
    isHovered: boolean;
    url: string;
  }[] = [];

  loadingImages$ = new BehaviorSubject(true);
  loading$ = combineLatest([this.broadcastFacade.isLoading$, this.loadingImages$]).pipe(
    map(x => x.some(loading => loading === true))
  );
  caseInStock$ = this.casesFacade.activeCaseData$.pipe(map(x => x?.caseStatus === CaseStatus.InStock));
  publicSiteIsEnabled$ = this.casesFacade.activeCaseData$.pipe(map(x => x?.publicSiteConfiguration?.enabled === true));
  isBroadcastEnabled$ = this.broadcastFacade.broadcastSettings$.pipe(map(x => x?.adPortals.some(x => x.isEnabled)));
  isMobile$ = this.isMobileViewService.isMobileView$;

  private _destroy$ = new Subject<void>();

  constructor(
    private isMobileViewService: IsMobileViewService,
    private casesFacade: CasesFacade,
    private broadcastFacade: BroadcastFacade,
    private confirmAdvertisedImagesFacade: ConfirmAdvertisedImagesFacade
  ) {}

  ngOnInit() {
    this.confirmAdvertisedImagesFacade.modalData$
      .pipe(
        tap(() => this.loadingImages$.next(true)),
        filter(data => !isNil(data)),
        switchMap(data => Promise.all(data.images.map(imageFile => createImageFromFile(imageFile) as Promise<string>))),
        takeUntil(this._destroy$)
      )
      .subscribe(x => {
        x.map(url => {
          this.images.push({
            isSelected: true,
            isHovered: false,
            url,
          });
        });
        this.loadingImages$.next(false);
      });

    combineLatest([this.casesFacade.activeCaseData$, this.caseInStock$])
      .pipe(
        filter(([, caseInStock]) => caseInStock),
        map(([caseData]) => caseData.id),
        takeUntil(this._destroy$)
      )
      .subscribe(caseId => {
        this.broadcastFacade.refreshStatusesAndLoadSettings(caseId);
      });
  }

  onSave() {
    this.loadingImages$.next(true);
    this.confirmAdvertisedImagesFacade.modalData$.pipe(take(1)).subscribe(data => {
      this.confirmAdvertisedImagesFacade.uploadImagesAfterConfirmation(data, this.images);
      this.images = [];
    });
  }

  onCancel() {
    this.cancel.emit();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
