import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionLoaderComponent } from './components/section-loader/section-loader.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { InlineLoaderComponent } from './components/inline-loader/inline-loader.component';
import { LoadingValueComponent } from './components/loading-value/loading-value.component';
import { LoadingValueDirective } from './components/loading-value/loading-value.directive';
import { SharedLoadersService } from './services/shared-loaders.service';
import { LoadingComponent } from './components/loading/loading.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, NzSpinModule, FlexLayoutModule, TranslateModule],
  declarations: [
    SectionLoaderComponent,
    InlineLoaderComponent,
    LoadingValueComponent,
    LoadingValueDirective,
    LoadingComponent,
  ],
  exports: [
    SectionLoaderComponent,
    InlineLoaderComponent,
    LoadingValueComponent,
    LoadingValueDirective,
    LoadingComponent,
  ],
  providers: [SharedLoadersService],
})
export class SharedLoadersModule {}
