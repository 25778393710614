import { Component, Input, TrackByFunction } from '@angular/core';
import { FileItemList } from '@vpfa/shared/files';
import { DEFAULT_IMAGE_PLACEHOLDER } from '@vpfa/utils';

@Component({
  selector: 'vpfa-sliding-gallery',
  templateUrl: './sliding-gallery.component.html',
  styleUrls: ['./sliding-gallery.component.scss'],
})
export class SlidingGalleryComponent {
  DEFAULT_IMAGE = DEFAULT_IMAGE_PLACEHOLDER;

  @Input() set images(images: FileItemList) {
    if (images) {
      this.gallery = images.filter(Boolean).map(el => String(el.file));
      this.total = this.gallery.length;
    }
  }

  @Input() show = true;
  @Input() mobile = false;

  gallery: string[] = [];
  total = 1;

  get hasImages() {
    return this.gallery && this.gallery.length > 0;
  }

  trackByFn: TrackByFunction<{ fileKey: string; file: string }> = (index, image) => image.fileKey;
}
