import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StockPricesBase } from '../stock-prices-base';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'vpfa-stock-prices-web',
  templateUrl: './stock-prices-web.component.html',
  styleUrls: ['./stock-prices-web.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StockPricesWebComponent extends StockPricesBase {
  @Input() hideExpertAreaLink = true;
  constructor(router: Router, route: ActivatedRoute) {
    super(router, route);
  }

  setTooltipContent() {
    return !this.isSold
      ? 'tooltip.stockPriceSection'
      : 'tooltip.soldPriceSection';
  }
}
