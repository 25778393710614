import { Action } from '@ngrx/store';

export enum LogoActionTypes {
  InitLogoEffects = '[Logo] Init Logo Effects',
  LoadLogoForBusiness = '[Logo] Load Logo For Business',
  LogoForBusinessLoaded = '[Logo] Logo For Business Loaded',
  LogoForBusinessLoadError = '[Logo] For Business Error',
  LoadLogoForCountry = '[Logo] Load Logo For Country',
  LogoForCountryLoaded = '[Logo] Logo For Country Loaded',
  LogoForCountryLoadError = '[Logo] For Country Error',
  ClearLogo = '[Logo] Clear Logo'
}

export class InitLogoEffects implements Action {
  readonly type = LogoActionTypes.InitLogoEffects;
  constructor() {}
}

export class LoadLogoForBusiness implements Action {
  readonly type = LogoActionTypes.LoadLogoForBusiness;
  constructor(public payload: string) {}
}

export class LogoForBusinessLoaded implements Action {
  readonly type = LogoActionTypes.LogoForBusinessLoaded;
  constructor(public payload: string) {}
}

export class LogoForBusinessLoadError implements Action {
  readonly type = LogoActionTypes.LogoForBusinessLoadError;
  constructor(public payload: any) {}
}

export class LoadLogoForCountry implements Action {
  readonly type = LogoActionTypes.LoadLogoForCountry;
  constructor(public payload: string) {}
}

export class LogoForCountryLoaded implements Action {
  readonly type = LogoActionTypes.LogoForCountryLoaded;
  constructor(public payload: string) {}
}

export class LogoForCountryLoadError implements Action {
  readonly type = LogoActionTypes.LogoForCountryLoadError;
  constructor(public payload: any) {}
}

export class ClearLogo implements Action {
  readonly type = LogoActionTypes.ClearLogo;
  constructor() {}
}

export type LogoAction =
  | LoadLogoForBusiness
  | LogoForBusinessLoaded
  | LogoForBusinessLoadError
  | LoadLogoForCountry
  | LogoForCountryLoaded
  | LogoForCountryLoadError
  | InitLogoEffects
  | ClearLogo;

export const fromLocaleActions = {
  LoadLogoForBusiness,
  LogoForBusinessLoaded,
  LogoForBusinessLoadError,
  LoadLogoForCountry,
  LogoForCountryLoaded,
  LogoForCountryLoadError,
  InitLogoEffects,
  ClearLogo
};
