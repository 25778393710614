<div class="return-report-form" fxLayout="column" fxLayoutGap="15px">
  <div class="return-report-section">
    <ng-container
      [ngTemplateOutlet]="title"
      [ngTemplateOutletContext]="{ title: 'returnReportModal.sections.documentsAndCertificates' | translate }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="field"
      [ngTemplateOutletContext]="{ key: keys.registrationDocument }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="field"
      [ngTemplateOutletContext]="{ key: keys.deregistrationCertificate }"
    ></ng-container>
    <ng-container [ngTemplateOutlet]="field" [ngTemplateOutletContext]="{ key: keys.vehicleInvoice }"></ng-container>
    <ng-container [ngTemplateOutlet]="field" [ngTemplateOutletContext]="{ key: keys.leasingDisclaimer }"></ng-container>
    <ng-container
      [ngTemplateOutlet]="field"
      [ngTemplateOutletContext]="{ key: keys.technicalModifications }"
    ></ng-container>
    <div class="subsection" *ngIf="form?.get(keys.technicalModifications).value">
      <ng-container
        [ngTemplateOutlet]="field"
        [ngTemplateOutletContext]="{ key: keys.generalOperatingLicense }"
      ></ng-container>
      <ng-container
        [ngTemplateOutlet]="field"
        [ngTemplateOutletContext]="{ key: keys.technicalModificationCertificate }"
      ></ng-container>
    </div>

    <ng-container [ngTemplateOutlet]="field" [ngTemplateOutletContext]="{ key: keys.fullExpertReport }"></ng-container>
    <ng-container
      [ngTemplateOutlet]="field"
      [ngTemplateOutletContext]="{ key: keys.receiptForDamageRepair }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="field"
      [ngTemplateOutletContext]="{ key: keys.validTechnicalInspectionAndExhaustEmissionCertificate }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="field"
      [ngTemplateOutletContext]="{ key: keys.fullServiceHistory }"
    ></ng-container>
    <ng-container [ngTemplateOutlet]="field" [ngTemplateOutletContext]="{ key: keys.tradeInContract }"></ng-container>
    <ng-container
      [ngTemplateOutlet]="field"
      [ngTemplateOutletContext]="{ key: keys.customClearanceCertificate }"
    ></ng-container>
  </div>

  <div class="return-report-section">
    <ng-container
      [ngTemplateOutlet]="title"
      [ngTemplateOutletContext]="{ title: 'returnReportModal.sections.cards' | translate }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="field"
      [ngTemplateOutletContext]="{ key: keys.leasingServiceCard }"
    ></ng-container>
    <ng-container [ngTemplateOutlet]="field" [ngTemplateOutletContext]="{ key: keys.codeCardRadio }"></ng-container>
    <div class="subsection" *ngIf="form?.get(keys.codeCardRadio).value">
      <ng-container
        [ngTemplateOutlet]="textField"
        [ngTemplateOutletContext]="{ key: keys.codeCardRadioNumber }"
      ></ng-container>
    </div>
  </div>

  <div class="return-report-section">
    <ng-container
      [ngTemplateOutlet]="title"
      [ngTemplateOutletContext]="{ title: 'returnReportModal.sections.physicalItems' | translate }"
    ></ng-container>
    <ng-container [ngTemplateOutlet]="field" [ngTemplateOutletContext]="{ key: keys.userManual }"></ng-container>
    <ng-container
      [ngTemplateOutlet]="field"
      [ngTemplateOutletContext]="{ key: keys.vehicleKeysComplete }"
    ></ng-container>
    <ng-container [ngTemplateOutlet]="field" [ngTemplateOutletContext]="{ key: keys.warningTriangle }"></ng-container>
    <ng-container [ngTemplateOutlet]="field" [ngTemplateOutletContext]="{ key: keys.firstAidKit }"></ng-container>
    <ng-container [ngTemplateOutlet]="field" [ngTemplateOutletContext]="{ key: keys.vehicleToolKit }"></ng-container>
    <ng-container [ngTemplateOutlet]="field" [ngTemplateOutletContext]="{ key: keys.fireExtinguisher }"></ng-container>
    <ng-container
      [ngTemplateOutlet]="field"
      [ngTemplateOutletContext]="{ key: keys.chargingCablesComplete }"
    ></ng-container>
    <ng-container [ngTemplateOutlet]="field" [ngTemplateOutletContext]="{ key: keys.navigation }"></ng-container>
  </div>
</div>

<ng-template #title let-title="title">
  <vpfa-expanding-header [text]="title"></vpfa-expanding-header>
</ng-template>

<ng-template #field let-key="key">
  <div class="return-report-field" [fxLayout]="'row'" [fxLayoutAlign]="'space-between center'">
    <label (click)="toggle(key)">{{ 'returnReportModal.fields.' + key | translate }}</label>
    <vpfa-switch [fc]="form?.get(key)" [parentFormGroup]="form"></vpfa-switch>
  </div>
</ng-template>

<ng-template #textField let-key="key">
  <div class="return-report-field">
    <vpfa-setting-box [divider]="false">
      <ng-container label>
        <vpfa-label [text]="'returnReportModal.fields.' + key | translate"></vpfa-label
      ></ng-container>
      <ng-container content>
        <vpfa-text-field
          [parentFormGroup]="form"
          [fcName]="key"
          [errorMessages]="[
            {
              error: 'required',
              errorMsg: 'common.forms.fieldIsRequired' | translate
            },
            {
              error: 'maxlength',
              errorMsg: 'common.forms.maxLength' | translate: { maxLength: '100' }
            }
          ]"
          [heightCompensationPx]="-14"
        >
        </vpfa-text-field>
      </ng-container>
    </vpfa-setting-box>
  </div>
</ng-template>
