<div
  (click)="$event.stopImmediatePropagation()"
  *ngIf="actionList && actionList.length"
  class="data-table-action-buttons"
>
  <div fxFlex>
    <button
      nz-button
      nzType="link"
      nz-popover
      nzPopoverTrigger="click"
      nzPopoverPlacement="bottomRight"
      [(nzPopoverVisible)]="dataTableActionMenuVisible"
      nzPopoverOverlayClassName="data-table-action-popover-overlay"
      [nzPopoverContent]="dataTableActionMenu"
    >
      <i class="dropdown-button zmdi zmdi-more-vert"></i>
    </button>
  </div>
</div>

<ng-template #dataTableActionMenu>
  <div *ngFor="let button of actionList" [fxLayout]="'column'">
    <button
      class="data-table-action-popover-button"
      *ngIf="!button.question"
      nz-button
      nzType="link"
      (click)="emitAction(button.action)"
    >
      {{ button.name | translate }}
    </button>
    <button
      *ngIf="button.question"
      nz-button
      nzType="link"
      nz-popconfirm
      class="data-table-action-popover-button"
      [nzPopconfirmTrigger]="'click'"
      [nzPopconfirmTitle]="isString(button.question) ? (button.question | translate) : button.question"
      [nzCancelText]="'common.no' | translate"
      [nzOkText]="'common.yes' | translate"
      nzPopconfirmPlacement="top"
      (nzOnConfirm)="emitAction(button.action)"
      nzPopconfirmOverlayClassName="data-table-action-popover-confirm"
    >
      {{ button.name | translate }}
    </button>
  </div>
</ng-template>
