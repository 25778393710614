<label
  class="d-flex justify-content-between align-items-center row-hover"
  [class.row-active]="isCombinationEquipment"
  [class.row-disabled]="isCombinationEquipment && isDisabled"
>
  <ng-container *ngIf="isCombinationEquipment; else justText">
    <span class="d-flex align-items-center">
      <vpfa-checkbox
        #checkbox
        class="checkbox"
        [sizeInPx]="16"
        [isChecked]="checkboxState"
        [isDisabled]="isDisabled"
        (onChange)="onChange($event)"
      ></vpfa-checkbox>
      {{ description }}
    </span>

    <div>{{ price | currencyLocale }}</div>
  </ng-container>
  <ng-template #justText>
    {{ description }}
  </ng-template>
</label>
