<vpfa-modal
  width="600px"
  modalName="Return Report"
  [showBackdrop]="true"
  [isClosable]="false"
  [isVisible]="open"
  [keyboard]="false"
  [maskClosable]="false"
  (closeAction)="close()"
  (afterCloseAction)="afterClose()"
>
  <vpfa-modal-content>
    <button
      class="ant-modal-close"
      nz-button
      nzType="link"
      nz-popconfirm
      [nzPopconfirmTrigger]="'click'"
      [nzPopconfirmTitle]="'common.unsavedChanges' | translate"
      [nzCancelText]="'common.no' | translate"
      [nzOkText]="'common.yes' | translate"
      nzPopconfirmPlacement="bottom"
      nzPopconfirmOverlayClassName="modal-confirm-popover"
      (nzOnConfirm)="close()"
      [nzCondition]="form.pristine"
    >
      <i nz-icon nzType="close-circle" nzTheme="fill"></i>
    </button>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <h1>{{ 'returnReportModal.returnReport' | translate }}</h1>
      <nz-spin [nzSpinning]="processing">
        <vpfa-return-report-form [form]="form"></vpfa-return-report-form>
        <div>
          <vpfa-action-button buttonType="submit" [disabled]="form.invalid">
            {{ 'returnReportModal.saveReport' | translate }}
          </vpfa-action-button>
          <vpfa-action-button
            [outline]="true"
            [border]="false"
            nz-popconfirm
            [nzPopconfirmTitle]="'common.unsavedChanges' | translate"
            [nzCancelText]="'common.no' | translate"
            [nzOkText]="'common.yes' | translate"
            nzPopconfirmPlacement="top"
            nzPopconfirmOverlayClassName="modal-confirm-popover"
            (nzOnConfirm)="close()"
            [nzCondition]="form.pristine"
          >
            {{ 'common.cancel' | translate }}
          </vpfa-action-button>
        </div>
      </nz-spin>
    </form>
  </vpfa-modal-content>
</vpfa-modal>
