/**
 * Dealer Network AdminService API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { CommandHandlerResponse } from '../model/commandHandlerResponse';
import { HardDeleteBranchCommand } from '../model/hardDeleteBranchCommand';
import { HardDeleteBusinessCascadeCommand } from '../model/hardDeleteBusinessCascadeCommand';
import { HardDeleteBusinessCommand } from '../model/hardDeleteBusinessCommand';
import { ResetBranchConfigurationCommand } from '../model/resetBranchConfigurationCommand';
import { ResetBusinessConfigurationCommand } from '../model/resetBusinessConfigurationCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DealerNetworkAdminServiceMaintenanceService {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Hard deletes the Branch and associated resources
     * AmsAction:DELETE/api/DealerNetworkAdminServiceMaintenance/HardDeleteBranch AmsAction:CanHardDelete AmsResource:maintenance/branch/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hardDeleteBranch(body?: HardDeleteBranchCommand, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public hardDeleteBranch(body?: HardDeleteBranchCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public hardDeleteBranch(body?: HardDeleteBranchCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public hardDeleteBranch(body?: HardDeleteBranchCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.delete<CommandHandlerResponse>(`${this.basePath}/api/DealerNetworkAdminServiceMaintenance/HardDeleteBranch`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Hard deletes the Business and associated resources
     * AmsAction:DELETE/api/DealerNetworkAdminServiceMaintenance/HardDeleteBusiness AmsAction:CanHardDelete AmsResource:maintenance/business/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hardDeleteBusiness(body?: HardDeleteBusinessCommand, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public hardDeleteBusiness(body?: HardDeleteBusinessCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public hardDeleteBusiness(body?: HardDeleteBusinessCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public hardDeleteBusiness(body?: HardDeleteBusinessCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.delete<CommandHandlerResponse>(`${this.basePath}/api/DealerNetworkAdminServiceMaintenance/HardDeleteBusiness`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Hard deletes the Business, child branches, and any related users from SSM and AMS  This will only work if all related users are deactivated - i.e. the business is not in use
     * AmsAction:DELETE/api/DealerNetworkAdminServiceMaintenance/HardDeleteBusinessCascade AmsAction:CanHardDelete AmsResource:maintenance/business/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hardDeleteBusinessCascade(body?: HardDeleteBusinessCascadeCommand, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public hardDeleteBusinessCascade(body?: HardDeleteBusinessCascadeCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public hardDeleteBusinessCascade(body?: HardDeleteBusinessCascadeCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public hardDeleteBusinessCascade(body?: HardDeleteBusinessCascadeCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.delete<CommandHandlerResponse>(`${this.basePath}/api/DealerNetworkAdminServiceMaintenance/HardDeleteBusinessCascade`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Resets the Branch and associated resources as if the branch had just been created  It will hard delete existing configuration, etc.
     * AmsAction:PUT/api/DealerNetworkAdminServiceMaintenance/ResetBranchConfiguration AmsAction:CanHardDelete AmsResource:maintenance/branch/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetBranchConfiguration(body?: ResetBranchConfigurationCommand, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public resetBranchConfiguration(body?: ResetBranchConfigurationCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public resetBranchConfiguration(body?: ResetBranchConfigurationCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public resetBranchConfiguration(body?: ResetBranchConfigurationCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/DealerNetworkAdminServiceMaintenance/ResetBranchConfiguration`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Resets the Business and associated resources as if the business had just been created  It will hard delete existing configuration, templates, etc.
     * AmsAction:PUT/api/DealerNetworkAdminServiceMaintenance/ResetBusinessConfiguration AmsAction:CanHardDelete AmsResource:maintenance/business/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetBusinessConfiguration(body?: ResetBusinessConfigurationCommand, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public resetBusinessConfiguration(body?: ResetBusinessConfigurationCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public resetBusinessConfiguration(body?: ResetBusinessConfigurationCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public resetBusinessConfiguration(body?: ResetBusinessConfigurationCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/DealerNetworkAdminServiceMaintenance/ResetBusinessConfiguration`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
