import { EquipmentFormulaBlockDto } from '@vpfa/rest-api/valuation';
import { VehicleEquipmentFormula } from '../../../models/vehicle-equipment-formula';
import { chain } from 'lodash';
import { mapApiStepToFormulaWizardStep } from './map-api-step-to-formula-wizard-step';

/**
 * Convert API result into Formula Wizards' Formulas and Steps
 */
export const stepsToFormulas = (
  formulaSteps: EquipmentFormulaBlockDto[],
  mainEquipmentSoaCode: string
): VehicleEquipmentFormula[] => {
  return chain(formulaSteps)
    .groupBy('formulaSoaCode')
    .map(
      (steps, key) =>
        <VehicleEquipmentFormula>{
          mainEquipmentSoaCode: key,
          steps: steps.map(mapApiStepToFormulaWizardStep),
          currentStepIndex: 0,
          source: null,
        }
    )
    .sort((x, y) =>
      x.mainEquipmentSoaCode === mainEquipmentSoaCode ? -1 : y.mainEquipmentSoaCode === mainEquipmentSoaCode ? 1 : 0
    )
    .value();
};
