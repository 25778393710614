<div ngClass.lt-mv="no-hor-scroll">
  <button
    *vpfaResponsiveIf="'web'"
    class="ant-modal-close"
    nz-button
    nzType="link"
    nz-popconfirm
    [nzPopconfirmTrigger]="'click'"
    [nzPopconfirmTitle]="'common.unsavedChanges' | translate"
    [nzCancelText]="'common.no' | translate"
    [nzOkText]="'common.yes' | translate"
    nzPopconfirmPlacement="bottom"
    nzPopconfirmOverlayClassName="modal-confirm-popover"
    (nzOnConfirm)="close()"
    [nzCondition]="formGroup.pristine"
  >
    <i nz-icon nzType="close-circle" nzTheme="fill"></i>
  </button>
  <div fxLayout="row" fxLayoutAlign="center space-between">
    <div [fxLayout]="'column'" class="box" ngClass.lt-mv="mobile" ngClass.gt-mv="web">
      <form (ngSubmit)="submit()" [formGroup]="formGroup">
        <ng-container *vpfaResponsiveIf="'mobile'">
          <vpfa-mobile-page-title
            [title]="'vehicleFullTechnicalData.technicalDataFields' | translate"
          ></vpfa-mobile-page-title>
        </ng-container>
        <ng-container *vpfaResponsiveIf="'web'">
          <h1 class="heading">
            {{ 'vehicleFullTechnicalData.technicalDataFields' | translate }}
          </h1>
        </ng-container>

        <nz-spin [nzSpinning]="loading">
          <!-- Vehicle information -->
          <vpfa-full-technical-data-vehicle-information
            *ngIf="hideAllExceptEmissionsAndConsumption === false"
            [technicalData]="technicalData"
            [featureConfig]="technicalDataFeatureConfig"
            [deliveryCountry]="deliveryCountry"
            [listPrice]="listPrice"
          ></vpfa-full-technical-data-vehicle-information>

          <!-- Engine information -->
          <vpfa-full-technical-data-engine-information
            *ngIf="hideAllExceptEmissionsAndConsumption === false"
            [technicalData]="technicalData"
            [featureConfig]="technicalDataFeatureConfig"
          ></vpfa-full-technical-data-engine-information>

          <!-- Common -->
          <vpfa-full-technical-data-common-information
            *ngIf="hideAllExceptEmissionsAndConsumption === false"
            [technicalDataBody]="technicalData?.body"
            [formGroup]="formGroup"
            [vehicleType]="vehicleType | findVehicleTypeByCode | async"
            [featureConfig]="technicalDataFeatureConfig"
            [errors]="seatErrors"
          ></vpfa-full-technical-data-common-information>

          <!-- Weights -->
          <vpfa-full-technical-data-weights-information
            *ngIf="hideAllExceptEmissionsAndConsumption === false"
            [technicalDataWeights]="technicalData?.weights"
            [featureConfig]="technicalDataFeatureConfig"
          ></vpfa-full-technical-data-weights-information>

          <!-- Dimensions -->
          <vpfa-full-technical-data-dimensions-information
            *ngIf="hideAllExceptEmissionsAndConsumption === false"
            [technicalData]="technicalData"
            [featureConfig]="technicalDataFeatureConfig"
          ></vpfa-full-technical-data-dimensions-information>

          <!-- Fuel consumption -->
          <ng-container *vpfaResponsiveIf="'mobile'">
            <vpfa-mobile-subtitle
              [title]="'vehicleFullTechnicalData.fuelConsumption.label' | translate"
            ></vpfa-mobile-subtitle>
          </ng-container>
          <ng-container *vpfaResponsiveIf="'web'">
            <div class="mt">
              <vpfa-expanding-header
                [text]="'vehicleFullTechnicalData.fuelConsumption.label' | translate"
              ></vpfa-expanding-header>
            </div>
          </ng-container>

          <!-- Fuel Consumption + EV range and consumption (NEDC) -->
          <vpfa-full-technical-data-nedc-information
            [technicalDataNedc]="technicalData?.consumption?.nedc"
            [originalCO2EfficiencyClass]="technicalData?.cO2EfficiencyClass"
            [customCO2EfficiencyClass]="technicalData?.cO2EfficiencyClassCustom"
            [formGroup]="formGroup"
            [errors]="errors"
            [co2Errors]="co2Errors"
            [evRangeAndConsumptionErrors]="evRangeAndConsumptionErrors"
            [emissionClassOptions]="emissionClassOptions"
            [efficiencyClassOptions]="efficiencyClassOptions$ | async"
            [efficiencyClassHiddenOptions]="efficiencyClassHiddenOptions"
          ></vpfa-full-technical-data-nedc-information>

          <!-- Fuel Consumption + EV range and consumption (WLTP) -->
          <vpfa-full-technical-data-wltp-information
            [technicalDataConsumption]="technicalData?.consumption"
            [formGroup]="formGroup"
            [errors]="errors"
            [co2Errors]="co2Errors"
            [evRangeAndConsumptionErrors]="evRangeAndConsumptionErrors"
            [userContext]="userContext$ | async"
            [efficiencyClassOptions]="efficiencyClassOptions$ | async"
            [cO2WLTPEfficiencyClass]="technicalData?.cO2WLTPEfficiencyClass"
            [cO2WLTPEfficiencyClassEB]="technicalData?.cO2WLTPEfficiencyClassEB"
            [vehicleEngineType]="vehicleEngineFuel"
            [efficiencyClassOptions]="efficiencyClassOptions$ | async"
            [efficiencyClassHiddenOptions]="efficiencyClassHiddenOptions"
            [isMobile]="isMobile"
            [isGermany]="isGermany$ | async"
          ></vpfa-full-technical-data-wltp-information>

          <!-- Insurance data -->
          <vpfa-full-technical-data-insurance-information
            *ngIf="hideAllExceptEmissionsAndConsumption === false"
            [technicalDataInsurance]="technicalData?.insurance"
            [featureConfig]="technicalDataFeatureConfig"
          ></vpfa-full-technical-data-insurance-information>

          <div *vpfaResponsiveIf="'web'" class="button-box">
            <vpfa-action-button buttonType="submit" [disabled]="!formGroup.valid || loading">
              {{ 'vehicleFullTechnicalData.buttons.save' | translate }}
            </vpfa-action-button>

            <vpfa-action-button
              [outline]="true"
              [border]="false"
              nz-popconfirm
              [nzPopconfirmTrigger]="'click'"
              [nzPopconfirmTitle]="'common.unsavedChanges' | translate"
              [nzCancelText]="'common.no' | translate"
              [nzOkText]="'common.yes' | translate"
              nzPopconfirmPlacement="top"
              nzPopconfirmOverlayClassName="modal-confirm-popover"
              (nzOnConfirm)="close()"
              [nzCondition]="formGroup.pristine"
            >
              {{ 'vehicleFullTechnicalData.buttons.cancel' | translate }}
            </vpfa-action-button>
          </div>
        </nz-spin>
      </form>
    </div>
  </div>
</div>

<ng-container *vpfaResponsiveIf="'mobile'">
  <div *ngIf="showFooter" class="sticky-btn">
    <vpfa-mobile-action-button (click)="closeAction.emit()">
      {{ 'vehicleFullTechnicalData.buttons.back' | translate }}
    </vpfa-mobile-action-button>
  </div>
</ng-container>
