/**
 * Dealer Network AdminService API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { CommandHandlerResponse } from '../model/commandHandlerResponse';
import { VPError } from '../model/vPError';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TranslationService {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * AmsAction:POST/api/Translation AmsAction:CanAdministerTranslationFiles AmsResource:translations/{command.FileName} 
     * @param file 
     * @param fileName 
     * @param cacheInvalidationSuffix 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadTranslation(file?: Blob, fileName?: string, cacheInvalidationSuffix?: string, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public uploadTranslation(file?: Blob, fileName?: string, cacheInvalidationSuffix?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public uploadTranslation(file?: Blob, fileName?: string, cacheInvalidationSuffix?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public uploadTranslation(file?: Blob, fileName?: string, cacheInvalidationSuffix?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any) };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('File', <any>file) || formParams;
        }
        if (fileName !== undefined) {
            formParams = formParams.append('FileName', <any>fileName) || formParams;
        }
        if (cacheInvalidationSuffix !== undefined) {
            formParams = formParams.append('CacheInvalidationSuffix', <any>cacheInvalidationSuffix) || formParams;
        }

        return this.httpClient.post<CommandHandlerResponse>(`${this.basePath}/api/Translation`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
