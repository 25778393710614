import { Action } from '@ngrx/store';
import {
  AdPortalStatusDto,
  CaseBroadcastHistoryListItemDto,
  CaseBroadcastSettingsDto,
  CreateCaseBroadcastSettingsCommand,
  RenewMobileBroadcastCommand,
} from '@vpfa/rest-api/ad-broadcast';
import { BroadcastValidationError } from '../models/broadcast-validation-error';
import { UpdateCaseUniqueDataCommand, UpdateIdentifiedVehicleTechnicalDataCommand } from '@vpfa/rest-api/valuation';

export enum BroadcastActionTypes {
  LoadCaseBroadcastSettings = '[Broadcast] Load Case Broadcast Settings',
  LoadCaseBroadcastSettingsSuccess = '[Broadcast] Load Case Broadcast Settings Success',
  LoadCaseBroadcastSettingsError = '[Broadcast] Load Case Broadcast Settings Error',

  ValidateAndUpdateAdvert = '[Broadcast] Validate And Update Advert',
  ValidateAdvertError = '[Broadcast] Validate Advert Error',
  UpdateAdvertConfiguration = '[Broadcast] Update Advert Configuration',
  UpdateAdvertConfigurationSuccess = '[Broadcast] Update Advert Configuration Success',
  UpdateAdvertConfigurationError = '[Broadcast] [Broadcast] Update advert configuration Error',

  LoadBroadcastingItemLogo = '[Broadcast] Load Case Broadcast Logo',
  LoadBroadcastingItemLogoSuccess = '[Broadcast] Load Case Broadcast Logo Success',
  LoadBroadcastingItemLogoError = '[Broadcast] Load Case Broadcast Logo Error',
  ToggleAllCaseBroadcastsIsActiveState = '[Broadcast] All Case Broadcasts Toggle IsActive State',

  LoadPortalBroadcastRequestsHistory = '[Broadcast] Load Portal Broadcast Requests History',
  PortalBroadcastRequestsHistoryLoadError = '[Broadcast] Portal Broadcast Requests History Load Error',
  PortalBroadcastRequestsHistoryLoadSuccess = '[Broadcast] Portal Broadcast Requests History Load Success',

  RefreshBroadcastStatuses = '[Broadcast] Refresh Broadcast Statuses',
  RefreshBroadcastStatusesError = '[Broadcast] Refresh Broadcast Statuses Error',
  RefreshBroadcastStatusesSuccess = '[Broadcast] Refresh Broadcast Statuses Success',

  LoadPortalBroadcastStatuses = '[Broadcast] Load Portal Broadcast Statuses',
  PortalBroadcastStatusesLoadError = '[Broadcast] Portal Broadcast Statuses Load Error',
  PortalBroadcastStatusesLoadSuccess = '[Broadcast] Portal Broadcast Statuses Load Success',
  PortalBroadcastStatusChanged = '[Broadcast] Portal Broadcast Status Changed',

  LoadCaseBroadcastIsActiveStatus = '[Broadcast] Load Case Broadcast isActive Status',
  LoadCaseBroadcastIsActiveStatusSuccess = '[Broadcast] Load Case Broadcast isActive Status Success',
  LoadCaseBroadcastIsActiveStatusError = '[Broadcast] Load Case Broadcast isActive Status Error',

  RenewMobileBroadcast = '[Broadcast] Renew Mobile Broadcast',
  RenewMobileBroadcastSuccess = '[Broadcast] Renew Mobile Broadcast Success',
  RenewMobileBroadcastError = '[Broadcast] Renew Mobile Broadcast Error',

  OpenBroadcastUpdateConfirmModal = '[Broadcast] Open Broadcast Update Confirm Modal',
  CloseBroadcastUpdateConfirmModal = '[Broadcast] Close Broadcast Update Confirm Modal',

  CaseBroadcastResetStore = '[Broadcast] Load Case Broadcast Reset Store',
}

export class LoadCaseBroadcastSettings implements Action {
  readonly type = BroadcastActionTypes.LoadCaseBroadcastSettings;
  constructor(public payload: string, public isUpdate = false) {}
}

export class LoadCaseBroadcastSettingsSuccess implements Action {
  readonly type = BroadcastActionTypes.LoadCaseBroadcastSettingsSuccess;
  constructor(public payload: CaseBroadcastSettingsDto, public isUpdate = false) {}
}

export class CaseBroadcastSettingsLoadError implements Action {
  readonly type = BroadcastActionTypes.LoadCaseBroadcastSettingsError;
}

export class ValidateAndUpdateAdvert implements Action {
  readonly type = BroadcastActionTypes.ValidateAndUpdateAdvert;
  constructor(
    public payload: {
      broadcastSettings: CreateCaseBroadcastSettingsCommand;
      technicalData: UpdateIdentifiedVehicleTechnicalDataCommand;
      updatedFromBroadcast: boolean;
      uniqueData?: UpdateCaseUniqueDataCommand;
    }
  ) {}
}

export class ValidateAdvertError implements Action {
  readonly type = BroadcastActionTypes.ValidateAdvertError;
  constructor(public payload: BroadcastValidationError) {}
}

export class UpdateAdvertConfiguration implements Action {
  readonly type = BroadcastActionTypes.UpdateAdvertConfiguration;
  constructor(public payload: { broadcastSettings: CreateCaseBroadcastSettingsCommand }) {}
}

export class UpdateAdvertConfigurationSuccess implements Action {
  readonly type = BroadcastActionTypes.UpdateAdvertConfigurationSuccess;
  constructor(public payload: { broadcastSettings: CreateCaseBroadcastSettingsCommand }) {}
}

export class UpdateAdvertConfigurationError implements Action {
  readonly type = BroadcastActionTypes.UpdateAdvertConfigurationError;
}

export class LoadBroadcastingItemLogo implements Action {
  readonly type = BroadcastActionTypes.LoadBroadcastingItemLogo;
  constructor(public payload: string) {}
}

export class LoadBroadcastingItemLogoSuccess implements Action {
  readonly type = BroadcastActionTypes.LoadBroadcastingItemLogoSuccess;
  constructor(public payload: { file: string; adPortalId: string }) {}
}

export class LoadBroadcastingItemLogoError implements Action {
  readonly type = BroadcastActionTypes.LoadBroadcastingItemLogoError;
  constructor(public payload: string) {}
}

export class ToggleAllCaseBroadcastsIsActiveState implements Action {
  readonly type = BroadcastActionTypes.ToggleAllCaseBroadcastsIsActiveState;
  constructor(public payload: boolean) {}
}

export class LoadPortalBroadcastRequestsHistory implements Action {
  readonly type = BroadcastActionTypes.LoadPortalBroadcastRequestsHistory;
  constructor(public payload: { caseId: string; broadcastConfigurationConnectionId: string }) {}
}

export class PortalBroadcastRequestsHistoryLoadError implements Action {
  readonly type = BroadcastActionTypes.PortalBroadcastRequestsHistoryLoadError;
  constructor(public payload: any) {}
}

export class PortalBroadcastRequestsHistoryLoadSuccess implements Action {
  readonly type = BroadcastActionTypes.PortalBroadcastRequestsHistoryLoadSuccess;
  constructor(public payload: Array<CaseBroadcastHistoryListItemDto>) {}
}

export class RefreshBroadcastStatuses implements Action {
  readonly type = BroadcastActionTypes.RefreshBroadcastStatuses;
  constructor(public payload: string) {}
}

export class RefreshBroadcastStatusesError implements Action {
  readonly type = BroadcastActionTypes.RefreshBroadcastStatusesError;
  constructor(public payload: string) {}
}

export class RefreshBroadcastStatusesSuccess implements Action {
  readonly type = BroadcastActionTypes.RefreshBroadcastStatusesSuccess;
  constructor(public payload: { caseId: string; response: any }) {}
}

export class LoadPortalBroadcastStatuses implements Action {
  readonly type = BroadcastActionTypes.LoadPortalBroadcastStatuses;
  constructor(public payload: string) {}
}

export class PortalBroadcastStatusesLoadError implements Action {
  readonly type = BroadcastActionTypes.PortalBroadcastStatusesLoadError;
  constructor(public payload: any) {}
}

export class PortalBroadcastStatusesLoadSuccess implements Action {
  readonly type = BroadcastActionTypes.PortalBroadcastStatusesLoadSuccess;
  constructor(public payload: Array<AdPortalStatusDto>) {}
}

export class PortalBroadcastStatusChanged implements Action {
  readonly type = BroadcastActionTypes.PortalBroadcastStatusChanged;
  constructor(public payload: string) {}
}

export class LoadCaseBroadcastIsActiveStatus implements Action {
  readonly type = BroadcastActionTypes.LoadCaseBroadcastIsActiveStatus;
  constructor(public payload: string, public isUpdate = false) {}
}

export class LoadCaseBroadcastIsActiveStatusSuccess implements Action {
  readonly type = BroadcastActionTypes.LoadCaseBroadcastIsActiveStatusSuccess;
  constructor(public payload: CaseBroadcastSettingsDto, public isUpdate = false) {}
}

export class LoadCaseBroadcastIsActiveStatusError implements Action {
  readonly type = BroadcastActionTypes.LoadCaseBroadcastIsActiveStatusError;
}

export class RenewMobileBroadcast implements Action {
  readonly type = BroadcastActionTypes.RenewMobileBroadcast;
  constructor(public payload: RenewMobileBroadcastCommand) {}
}

export class RenewMobileBroadcastSuccess implements Action {
  readonly type = BroadcastActionTypes.RenewMobileBroadcastSuccess;
  constructor(public caseId: string) {}
}

export class RenewMobileBroadcastError implements Action {
  readonly type = BroadcastActionTypes.RenewMobileBroadcastError;
}

export class OpenBroadcastUpdateConfirmModal implements Action {
  readonly type = BroadcastActionTypes.OpenBroadcastUpdateConfirmModal;
}

export class CloseBroadcastUpdateConfirmModal implements Action {
  readonly type = BroadcastActionTypes.CloseBroadcastUpdateConfirmModal;
}

export class CaseBroadcastResetStore implements Action {
  readonly type = BroadcastActionTypes.CaseBroadcastResetStore;
}

export type BroadcastActions =
  | LoadCaseBroadcastSettings
  | LoadCaseBroadcastSettingsSuccess
  | CaseBroadcastSettingsLoadError
  | LoadBroadcastingItemLogo
  | LoadBroadcastingItemLogoSuccess
  | LoadBroadcastingItemLogoError
  | ValidateAndUpdateAdvert
  | ValidateAdvertError
  | UpdateAdvertConfiguration
  | UpdateAdvertConfigurationSuccess
  | UpdateAdvertConfigurationError
  | ToggleAllCaseBroadcastsIsActiveState
  | LoadPortalBroadcastRequestsHistory
  | PortalBroadcastRequestsHistoryLoadError
  | PortalBroadcastRequestsHistoryLoadSuccess
  | RefreshBroadcastStatuses
  | RefreshBroadcastStatusesError
  | RefreshBroadcastStatusesSuccess
  | LoadPortalBroadcastStatuses
  | PortalBroadcastStatusesLoadError
  | PortalBroadcastStatusesLoadSuccess
  | PortalBroadcastStatusChanged
  | LoadCaseBroadcastIsActiveStatus
  | LoadCaseBroadcastIsActiveStatusSuccess
  | LoadCaseBroadcastIsActiveStatusError
  | RenewMobileBroadcast
  | RenewMobileBroadcastSuccess
  | RenewMobileBroadcastError
  | OpenBroadcastUpdateConfirmModal
  | CloseBroadcastUpdateConfirmModal
  | CaseBroadcastResetStore;

export const fromBroadcastActions = {
  LoadCaseBroadcastSettings,
  LoadCaseBroadcastSettingsSuccess,
  CaseBroadcastSettingsLoadError,
  LoadBroadcastingItemLogo,
  LoadBroadcastingItemLogoSuccess,
  LoadBroadcastingItemLogoError,
  ValidateAndUpdateAdvert,
  ValidateAdvertError,
  UpdateAdvertConfiguration,
  UpdateAdvertConfigurationSuccess,
  UpdateAdvertConfigurationError,
  ToggleAllCaseBroadcastsIsActiveState,
  LoadPortalBroadcastRequestsHistory,
  PortalBroadcastRequestsHistoryLoadError,
  PortalBroadcastRequestsHistoryLoadSuccess,
  RefreshBroadcastStatuses,
  RefreshBroadcastStatusesError,
  RefreshBroadcastStatusesSuccess,
  LoadPortalBroadcastStatuses,
  PortalBroadcastStatusesLoadError,
  PortalBroadcastStatusesLoadSuccess,
  PortalBroadcastStatusChanged,
  LoadCaseBroadcastIsActiveStatus,
  LoadCaseBroadcastIsActiveStatusSuccess,
  LoadCaseBroadcastIsActiveStatusError,
  RenewMobileBroadcast,
  RenewMobileBroadcastSuccess,
  RenewMobileBroadcastError,
  OpenBroadcastUpdateConfirmModal,
  CloseBroadcastUpdateConfirmModal,
  CaseBroadcastResetStore,
};
