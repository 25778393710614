import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlidingGalleryComponent } from './components/sliding-gallery/sliding-gallery.component';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { SharedPipesModule } from '@vpfa/shared/pipes';

@NgModule({
  declarations: [SlidingGalleryComponent],
  imports: [CommonModule, NzCarouselModule, SharedPipesModule],
  exports: [SlidingGalleryComponent]
})
export class UiKitSlidingGalleryModule {}
