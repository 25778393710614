import { Component, Input, TemplateRef } from '@angular/core';
import { PriceDisplayModel } from '../pricing-details-modal/pricing-details-modal.component';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'vpfa-pricing-details-valuation',
  templateUrl: './pricing-details-valuation.component.html',
  styleUrls: ['./pricing-details-valuation.component.scss'],
})
export class PricingDetailsValuationComponent {
  @Input() set valuationPrices(prices: PriceDisplayModel[]) {
    this._valuationPrices = prices;
    this.itemsHidden = true;
    this.filterPricesToDisplay();
  }
  @Input() valuationPricesForm: UntypedFormGroup;
  @Input() errorMessages;
  @Input() priceTemplate: TemplateRef<void>;

  private _valuationPrices: PriceDisplayModel[];
  itemsHidden: boolean = true;
  pricesToDisplay: PriceDisplayModel[];

  get valuationPrices() {
    return this._valuationPrices;
  }

  onShowClick() {
    this.itemsHidden = !this.itemsHidden;
    this.filterPricesToDisplay();
  }

  private filterPricesToDisplay() {
    this.pricesToDisplay = this._valuationPrices.filter(price => (this.itemsHidden ? !price.hiddenByDefault : true));
  }
}
