import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType, OnInitEffects } from '@ngrx/effects';
import { CountryViewService, ExperianViewService } from '@vpfa/rest-api/admin';
import { of, EMPTY } from 'rxjs';
import { catchError, map, switchMap, tap, mapTo, flatMap, pluck } from 'rxjs/operators';
import {
  fromLocaleActions,
  LocaleActionTypes,
  LoadLocale,
  LocaleLoaded,
  CountryLoaded,
  LoadBranchCountry,
  CsvConfigurationLoad,
  ExperianTermsUrlLoad,
  LocaleEffectsInitialized,
  CheckTranslateFileError,
  CheckTranslateFile,
  FeatureConfigLoad,
} from './locale.actions';
import { dynamicLocaleRegister } from '@vpfa/utils';
import { CsvConfigurationViewService } from '@vpfa/rest-api/valuation';
import { Action } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { getTranslateFilePath } from '@vpfa/shared/translate';
import { BasicNotificationsService } from '@vpfa/shared/notifications';
import { DeploymentVersionService, EnvironmentsService } from '@vpfa/environments/data';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LocaleEffects implements OnInitEffects {
  
  loadUserCountryData$ = createEffect(() => this.actions$.pipe(
    ofType(LocaleActionTypes.LoadUserCountry),
    switchMap(() =>
      this.countryViewService.getUserCountry().pipe(
        map(res => new fromLocaleActions.CountryLoaded(res)),
        catchError(err => of(new fromLocaleActions.UserCountryLoadError(err.error)))
      )
    )
  ));

  
  loadLocaleAfterCountryLoaded$ = createEffect(() => this.actions$.pipe(
    ofType<CountryLoaded>(LocaleActionTypes.CountryLoaded),
    pluck('payload'),
    map(country => new LoadLocale(country.locale))
  ));

  
  loadFeatureConfigAfterCountryLoaded$ = createEffect(() => this.actions$.pipe(
    ofType<CountryLoaded>(LocaleActionTypes.CountryLoaded),
    map(country => new FeatureConfigLoad(country.payload.countryCode))
  ));

  
  loadFeatureConfig$ = createEffect(() => this.actions$.pipe(
    ofType<FeatureConfigLoad>(LocaleActionTypes.FeatureConfigLoad),
    switchMap(action =>
      this.countryViewService.getFeaturesConfiguration(action.payload).pipe(
        map(
          res =>
            new fromLocaleActions.FeatureConfigLoaded({
              countryFeatures: res.countryFeatures,
              vehicleTypeFeaturesConfig: res.vehicleTypeFeaturesConfig,
              makeProductionDateConfig: res.makeProductionDateConfig,
              forCountry: action.payload,
            })
        ),
        catchError(err => of(new fromLocaleActions.FeatureConfigLoadError()))
      )
    )
  ));

  
  loadLocale = createEffect(() => this.actions$.pipe(
    ofType<LoadLocale>(LocaleActionTypes.LoadLocale),
    switchMap(action => {
      return dynamicLocaleRegister(action.payload).pipe(
        mapTo(action.payload),
        flatMap(locale => {
          return of(new LocaleLoaded(locale));
        }),
        catchError(error => EMPTY)
      );
    })
  ));

  
  loadBranchCountryData$ = createEffect(() => this.actions$.pipe(
    ofType<LoadBranchCountry>(LocaleActionTypes.LoadBranchCountry),
    switchMap(action =>
      this.countryViewService.getBranchCountry(action.payload).pipe(
        map(res => new fromLocaleActions.CountryLoaded(res)),
        catchError(err => of(new fromLocaleActions.BranchCountryLoadError(err.error)))
      )
    )
  ));

  
  csvConfigurationLoad$ = createEffect(() => this.actions$.pipe(
    ofType<CsvConfigurationLoad>(LocaleActionTypes.CsvConfigurationLoad),
    switchMap(() =>
      this.csvConfigurationViewService.getGeneralConfig().pipe(
        map(res => new fromLocaleActions.CsvConfigurationLoaded(res)),
        catchError(() => of(new fromLocaleActions.CsvConfigurationLoadError()))
      )
    )
  ));

  
  loadUserCountryExperianTermsUrlLoad$ = createEffect(() => this.actions$.pipe(
    ofType(LocaleActionTypes.LoadUserCountry),
    map(_ => new ExperianTermsUrlLoad())
  ));

  
  experianTermsUrlLoad$ = createEffect(() => this.actions$.pipe(
    ofType<CsvConfigurationLoad>(LocaleActionTypes.ExperianTermsUrlLoad),
    switchMap(() =>
      this.experianViewService.getExperianTermsAndConditionUrl().pipe(
        map(res => new fromLocaleActions.ExperianTermsUrlLoaded(res.termsAndConditionsUrl)),
        catchError(() => of(new fromLocaleActions.ExperianTermsUrlLoadError()))
      )
    )
  ));

   checkIfTranslateFileExist$ = createEffect(() => this.actions$.pipe(
    ofType<CheckTranslateFile>(LocaleActionTypes.CheckTranslateFile),
    switchMap(action =>
      this.httpClient
        .get(
          getTranslateFilePath(
            action.payload.language,
            action.payload.countryCode,
            this.environmentsService.config,
            this.deploymentVersionService.currentVersion
          )
        )
        .pipe(
          map(res => new fromLocaleActions.CheckTranslateFileSuccess()),
          catchError(() => of(new fromLocaleActions.CheckTranslateFileError(action.payload)))
        )
    )
  ));

   checkIfTranslateFileExistError$ = createEffect(() => this.actions$.pipe(
    ofType<CheckTranslateFileError>(LocaleActionTypes.CheckTranslateFileError),
    tap(action => {
      const missingLanguageTranslation = this.translateService.instant(
        `admin.country.languages.${action.payload.language.toLowerCase()}`
      );
      this.notification.warning('locale.error.missingTranslationFile', null, {
        language: missingLanguageTranslation,
        country: action.payload.countryCode,
      });
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private countryViewService: CountryViewService,
    private csvConfigurationViewService: CsvConfigurationViewService,
    private experianViewService: ExperianViewService,
    private httpClient: HttpClient,
    private notification: BasicNotificationsService,
    private environmentsService: EnvironmentsService,
    private translateService: TranslateService,
    private deploymentVersionService: DeploymentVersionService
  ) {}

  ngrxOnInitEffects(): Action {
    return new LocaleEffectsInitialized();
  }
}
