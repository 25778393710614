import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CasesFacade } from '@vpfa/dealer/case/data';
import {
  ContentEquipmentDto,
  EquipmentColorDto,
  EquipmentDto as ValuationEquipment,
  EquipmentType,
} from '@vpfa/rest-api/valuation';
import { EquipmentDto as IdentificationEquipment } from '@vpfa/rest-api/identification';
import { isNil } from 'lodash';
import { Observable, of } from 'rxjs';
import { getEquipmentDebugText, isDebugEnabled } from '@vpfa/utils';

// Shared style constants for calculating equipment list row height
export const CELL_WHITE_SPACE = 'normal';
export const CELL_TEXT_ALIGN = 'justify';
export const CELL_LINE_HEIGHT = 'normal';

@Component({
  selector: 'vpfa-web-equipment-list-item',
  templateUrl: './web-equipment-list-item.component.html',
  styleUrls: ['./web-equipment-list-item.component.scss'],
})
export class WebEquipmentListItemComponent {
  private _valuationEquipment: ValuationEquipment;
  private _identificationEquipment: IdentificationEquipment;
  /**
   * This flag differentiates whether equipment item is used in equipment list on summary page (valuation) or in tech spec modal (identification).
   * This is necessary because of fundamental differences in EquipmentDto models from valuation and identification services.
   */
  isValuationEquipment: boolean;
  isPackageEquipment: boolean = false;
  isPackageExpanded: boolean = false;
  isPackageContentAvailable: boolean = false;
  hasColors: boolean = false;
  isPackageEnabled = false;
  enabledColor: EquipmentColorDto;

  constructor(private casesFacade: CasesFacade) {}

  @Input() showColor = true;
  @Input() showEquipmentShortDescription: boolean;

  @Input() set valuationEquipment(equipment: ValuationEquipment) {
    if (equipment) {
      this._valuationEquipment = equipment;
      this.isValuationEquipment = true;
      this.isPackageEquipment = equipment?.equipmentType === EquipmentType.Package;
      this.isPackageContentAvailable = this.isPackageEquipment && equipment?.content?.length > 0;
      this.hasColors = equipment?.colors?.length > 0;
      this.isPackageEnabled = equipment?.isEnabled;
      if (this.hasColors) {
        this.enabledColor = equipment.colors.find(color => color.isEnabled);
      }
    }
  }

  get valuationEquipment(): ValuationEquipment {
    return this._valuationEquipment;
  }

  @Input() set identificationEquipment(equipment: IdentificationEquipment) {
    if (equipment) {
      this._identificationEquipment = equipment;
      this.isValuationEquipment = false;
      this.isPackageEquipment = equipment?.equipmentType === EquipmentType.Package;
      this.isPackageContentAvailable =
        this.isPackageEquipment &&
        (equipment?.contentShortDescription?.length > 0 || equipment?.contentDescription?.length > 0);
    }
  }

  get identificationEquipment(): IdentificationEquipment {
    return this._identificationEquipment;
  }

  get debugText(): string {
    if (!isDebugEnabled('formulaWizard')) {
      return '';
    }

    let complexFormula = '';

    if (this._valuationEquipment !== undefined && this._valuationEquipment.hasComplexFormula === true) {
      complexFormula = '[ComplexFormula]';
    }

    return `${complexFormula}` + getEquipmentDebugText(this._valuationEquipment ?? this._identificationEquipment);
  }

  contentDebugText(content: ContentEquipmentDto): string {
    if (!isDebugEnabled('formulaWizard')) {
      return '';
    }

    return getEquipmentDebugText(content);
  }

  valuationEquipmentContent(equipmentContent: ContentEquipmentDto): string {
    return this.showEquipmentShortDescription ? equipmentContent.shortDescription : equipmentContent.description;
  }

  identificationEquipmentContents(): string[] {
    return this.showEquipmentShortDescription
      ? this._identificationEquipment?.contentShortDescription
      : this._identificationEquipment?.contentDescription;
  }

  @Output() expandChange = new EventEmitter();
  @Output() colorChange = new EventEmitter<EquipmentColorDto>();
  @Output() packageContentChange = new EventEmitter<ContentEquipmentDto>();

  isPackageEnabled$(soaCode: string): Observable<boolean | null> {
    if (isNil(soaCode)) {
      return of(null);
    }

    return this.casesFacade.isEquipmentEnabledBySoaCode$(soaCode);
  }

  isPackageContentEnabled$(packageSoaCode: string, contentSoaCode: string): Observable<boolean | null> {
    if (isNil(packageSoaCode) || isNil(contentSoaCode)) {
      return of(null);
    }

    return this.casesFacade.isEquipmentContentEnabledBySoaCode$(packageSoaCode, contentSoaCode);
  }

  onClick() {
    if (this.isPackageContentAvailable) {
      this.isPackageExpanded = !this.isPackageExpanded;
      setTimeout(() => {
        this.expandChange.emit();
      }, 10);
    }
  }

  onColorChange(color: EquipmentColorDto) {
    this.enabledColor = color;
    this.colorChange.emit(this.enabledColor);
  }

  onEquipmentContentChange(content: ContentEquipmentDto, isEnabled: boolean) {
    this.packageContentChange.emit({ ...content, isEnabled });
  }

  equipmentDescriptionStyle(): Partial<CSSStyleDeclaration> {
    return { whiteSpace: CELL_WHITE_SPACE, textAlign: CELL_TEXT_ALIGN, lineHeight: CELL_LINE_HEIGHT };
  }
}
