import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'vpfa-mobile-price-button-content',
  templateUrl: './mobile-price-button-content.component.html',
  styleUrls: ['./mobile-price-button-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobilePriceButtonContentComponent{

@Input() label: string;
@Input() notAvailable: boolean;
@Input() value: string;
@Input() showValue = true;
}
