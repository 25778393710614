<div class="field-validation-container" [class.invalid]="isInvalid">
  <div
    [class.currency]="isCurrency"
    [class.number]="isNumber"
    [class.text]="isText"
    vpfaGetClientBoundingRect
    (boundingRectChanged)="getClientBoundingRect($event)"
  >
    <ng-content></ng-content>
  </div>

  <div
    [ngStyle]="{ 'top.px': errorSignTopPos, 'right.px': _errorMsgVertPosition }"
    nz-popover
    [nzPopoverVisible]="displayErrors && isInvalid"
    [nzPopoverContent]="popoverContent"
    [nzPopoverTrigger]="null"
    [hidden]="!isInvalid"
    class="error-sign"
  >
    <span *ngIf="displayErrors" class="zmdi zmdi-alert-triangle red app-theme-red"></span>
  </div>
</div>
