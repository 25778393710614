import { EquipmentErrorEquipmentErrorDto } from '@vpfa/rest-api/valuation';
import { find, isEqual, isNil } from 'lodash';

/**
 * Remove considered errors by force flags from previous steps
 * 
 * @param res
 * @param previousStepsErrors
 */
export function removeAlreadyPresentErrors(
  equipmentErrors: EquipmentErrorEquipmentErrorDto[],
  previousStepsErrors: EquipmentErrorEquipmentErrorDto[]
) {
  return equipmentErrors.filter(error => isNil(find(previousStepsErrors, prevError => isEqual(prevError, error))));
}
