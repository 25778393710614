import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormControl } from '@angular/forms';
import { dateToLocalISO, ISOToDate } from '@vpfa/utils';

export const ISO_DATE_PICKER_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => IsoDatePickerComponent),
  multi: true,
};

@Component({
  selector: 'vpfa-iso-date-picker',
  templateUrl: './iso-date-picker.component.html',
  styleUrls: ['./iso-date-picker.component.scss'],
  providers: [ISO_DATE_PICKER_VALUE_ACCESSOR],
})
export class IsoDatePickerComponent implements ControlValueAccessor {
  @Input() formControl: UntypedFormControl;
  @Input() format: string;
  @Input() allowClear = true;
  @Input() showToday = true;
  @Input() readonly = false;
  @Input() disabledDate: (date: string) => boolean;
  @Input() monthPicker = false;
  @Input() size: 'large' | 'small' | 'default';
  @Input() applyWhiteBackground = false;

  /**
   * Make date picker text input readonly
   */
  @Input() inputReadOnly = false;
  @Output() onOpenChange = new EventEmitter();

  nzDropdownClassName = 'vpfa-date-picker';

  touched: Function;
  changed: Function;

  get date(): Date {
    return this._date;
  }

  set date(newDate: Date) {
    this._date = newDate;
    this.changed(dateToLocalISO(newDate, false));
  }

  private _date: Date;

  writeValue(value: string): void {
    const date = ISOToDate(value);
    this._date = this.disabledISODate(date) ? null : date;
  }

  disabledISODate = (date: Date) => {
    return this.disabledDate ? this.disabledDate(dateToLocalISO(date, false)) : false;
  };

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.touched = fn;
  }
}
