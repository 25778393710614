<div *ngIf="!toggleAddForm; else addForm" class="info-box" fxLayoutAlign="start center">
  <i nz-icon nzType="info-circle" nzTheme="fill"></i>
  <div fxLayout="column" fxLayoutAlign="space-between start">
    <span>{{ 'createQuoteModal.infoBox.message' | translate }}</span>
    <button
      nz-button
      nzType="link"
      [disabled]="ownerSelected"
      (click)="onToggleAddForm()"
    >
      {{ 'createQuoteModal.infoBox.add' | translate }}
    </button>
  </div>
</div>

<ng-template #addForm>
  <nz-divider></nz-divider>
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h1>{{ 'createQuoteModal.addCustomerByEmail.title' | translate }}</h1>
    <button
      nz-button
      nzType="link"
      (click)="onHideAddForm()"
    >
      {{ 'createQuoteModal.infoBox.back' | translate }}
    </button>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <vpfa-setting-box [divider]="false">
      <ng-container label>
        <vpfa-label [text]="'createQuoteModal.addCustomerByEmail.firstName' | translate"></vpfa-label>
      </ng-container>
      <ng-container content>
        <vpfa-text-field
          [errorMessages]="firstNameErrorMsgList"
          [parentFormGroup]="formGroup"
          [heightCompensationPx]="-14"
          fieldWidth="50%"
          fcName="firstName"
        ></vpfa-text-field>
      </ng-container>
    </vpfa-setting-box>

    <vpfa-setting-box [divider]="false">
      <ng-container label>
        <vpfa-label [text]="'createQuoteModal.addCustomerByEmail.lastName' | translate"></vpfa-label>
      </ng-container>
      <ng-container content>
        <vpfa-text-field
          [errorMessages]="lastNameErrorMsgList"
          [parentFormGroup]="formGroup"
          [heightCompensationPx]="-14"
          fcName="lastName"
        ></vpfa-text-field>
      </ng-container>
    </vpfa-setting-box>
  </div>
  <vpfa-setting-box [divider]="false">
    <ng-container label>
      <vpfa-label [text]="'createQuoteModal.addCustomerByEmail.email' | translate"></vpfa-label>
    </ng-container>
    <ng-container content>
      <vpfa-text-field
        [errorMessages]="emailErrorMsgList"
        [parentFormGroup]="formGroup"
        [heightCompensationPx]="-14"
        fcName="emails"
      ></vpfa-text-field>
    </ng-container>
  </vpfa-setting-box>
</ng-template>
