<vpfa-field-container
  [control]="getFormControl()"
  [displayErrors]="shouldDisplayErrors$.getValue()"
  [errorMessages]="errorMessages"
  [errorMsgVertPosition]="errorMsgVertPosition"
  [heightCompensationPx]="heightCompensationPx"
>
  <nz-input-group [fxFlex]="!disableMaxWidth ? fieldWidth : ''" [nzSuffix]="suffix">
    <input
      #input
      nz-input
      type="text"
      [formControl]="getFormControl()"
      [placeholder]="isPasswordSet ? '••••••••••' : ('common.password' | translate)"
      [ngClass]="{ 'password-font': !showPassword && passwordChanged() }"
      (focus)="onFocus()"
      (blur)="onBlurPassword()"
      (keydown.enter)="submitValue.emit()"
      (keyup.esc)="onEscape()"
    />
  </nz-input-group>
</vpfa-field-container>

<ng-template #suffix>
  <div *ngIf="passwordChanged()" (click)="onShowPassword()" class="show-icon">
    <i *ngIf="showPassword" class="zmdi zmdi-eye"></i>
    <i *ngIf="!showPassword" class="zmdi zmdi-eye-off"></i>
  </div>
</ng-template>
