import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UiKitModule } from '@vpfa/ui-kit';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { ConfirmRemoveCustomerComponent } from './components/confirm-remove-customer/confirm-remove-customer.component';
import { CustomerDataComponent } from './components/customer-data/customer-data.component';
import { CustomerEditComponent } from './components/customer-edit/customer-edit.component';
import { CustomerDataMobileComponent } from './components/customer-data/customer-data-mobile/customer-data-mobile.component';
import { SharedMobileViewModule } from '@vpfa/shared/mobile-view';
import { UiKitUikitValidationModule } from '@vpfa/ui-kit/uikit-validation';
import { CustomerDataWebComponent } from './components/customer-data/customer-data-web/customer-data-web.component';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { UiKitMobileModule } from '@vpfa/ui-kit-mobile';
import { UiKitSwitchModule } from '@vpfa/ui-kit/switch';
import { CustomerCreateWebComponent } from './components/customer-create/customer-create-web/customer-create-web.component';
import { CustomerCreateMobileComponent } from './components/customer-create/customer-create-mobile/customer-create-mobile.component';
import { UiKitSettingBoxModule } from '@vpfa/ui-kit/setting-box';
import { SharedTopPanelModule } from '@vpfa/shared/top-panel';
import { AddRemoveOneCustomerComponent } from './components/add-remove-customer-list/add-remove-one-customer/add-remove-one-customer.component';
import { AddRemoveCustomerListComponent } from './components/add-remove-customer-list/add-remove-customer-list/add-remove-customer-list.component';
import { AddRemoveCustomerListOldComponent } from './components/add-remove-customer-list-old/add-remove-customer-list-old/add-remove-customer-list-old.component';
import { AddRemoveOneCustomerOldComponent } from './components/add-remove-customer-list-old/add-remove-one-customer-old/add-remove-one-customer-old.component';
import { ModalsModule } from '@vpfa/modals';
import { UiKitFormControlsModule } from '@vpfa/ui-kit/form-controls';
import { UiKitListModule } from '@vpfa/ui-kit/list';
import { SharedPipesModule } from '@vpfa/shared/pipes';
import { UsersDataModule } from '@vpfa/admin/users-data';
import { UiKitIsoDatePickerModule } from '@vpfa/ui-kit/iso-date-picker';
import { SharedDirectivesModule } from '@vpfa/shared/directives';

@NgModule({
  imports: [
    CommonModule,
    UiKitModule,
    TranslateModule,
    NzPopconfirmModule,
    NzListModule,
    NzDividerModule,
    NzInputModule,
    FormsModule,
    ReactiveFormsModule,
    NzAlertModule,
    SharedMobileViewModule,
    UiKitUikitValidationModule,
    NzIconModule,
    UiKitMobileModule,
    UiKitSwitchModule,
    UiKitSettingBoxModule,
    SharedTopPanelModule,
    ModalsModule,
    UiKitFormControlsModule,
    UiKitListModule,
    NzSpinModule,
    SharedPipesModule,
    UsersDataModule,
    UiKitIsoDatePickerModule,
    SharedDirectivesModule,
  ],
  declarations: [
    CustomerDataComponent,
    AddRemoveCustomerListComponent,
    AddRemoveCustomerListOldComponent,
    AddRemoveOneCustomerComponent,
    AddRemoveOneCustomerOldComponent,
    ConfirmRemoveCustomerComponent,
    CustomerEditComponent,
    CustomerDataMobileComponent,
    CustomerDataWebComponent,
    CustomerCreateWebComponent,
    CustomerCreateMobileComponent,
  ],
  exports: [
    AddRemoveCustomerListComponent,
    AddRemoveCustomerListOldComponent,
    AddRemoveOneCustomerComponent,
    AddRemoveOneCustomerOldComponent,
    ConfirmRemoveCustomerComponent,
    CustomerEditComponent,
    CustomerCreateWebComponent,
    CustomerCreateMobileComponent,
  ],
})
export class DealerCustomersSharedModule {}
