import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { ImageFramePartialState } from './image-frame.reducer';
import { imageFrameQuery } from './image-frame.selectors';
import { fromImageFrameActions } from './image-frame.actions';
import { CreateAdminImageFrameCommand, UpdateAdminImageFrameCommand } from '@vpfa/rest-api/valuation';

@Injectable()
export class ImageFrameFacade {
  isLoadingList$ = this.store.pipe(select(imageFrameQuery.getIsLoadingList));
  list$ = this.store.pipe(select(imageFrameQuery.getList));

  isEditorLoading$ = this.store.pipe(select(imageFrameQuery.getIsEditorLoading));
  editorUrl$ = this.store.pipe(select(imageFrameQuery.getEditorUrl));

  isFramedImagePreviewLoading$ = this.store.pipe(select(imageFrameQuery.getIsFramedImagePreviewLoading));
  framedImagePreview$ = this.store.pipe(select(imageFrameQuery.getFramedImagePreview));

  isCreateFrameModalOpen$ = this.store.pipe(select(imageFrameQuery.getIsCreateFrameModalOpen));
  isCreateFrameModalProcessing$ = this.store.pipe(select(imageFrameQuery.getIsCreateFrameModalProcessing));
  isRenameFrameModalOpen$ = this.store.pipe(select(imageFrameQuery.getIsRenameFrameModalOpen));
  isRenameFrameModalProcessing$ = this.store.pipe(select(imageFrameQuery.getIsRenameFrameModalProcessing));

  constructor(private store: Store<ImageFramePartialState>) {}

  loadImageFrames() {
    this.store.dispatch(new fromImageFrameActions.ImageFramesLoad());
  }

  loadFramedImagePreview(caseId: string, frameId: string, imageKey: string) {
    this.store.dispatch(new fromImageFrameActions.FramedImagePreviewLoad({ frameId, caseId, imageKey }));
  }

  createFrameForBusiness(command: CreateAdminImageFrameCommand) {
    this.store.dispatch(new fromImageFrameActions.BusinessImageFrameCreate(command));
  }

  deleteFrame(id: string, countryId: string, businessId: string) {
    this.store.dispatch(new fromImageFrameActions.ImageFrameDelete({ countryId, businessId, id }));
  }

  renameFrame(countryId: string, businessId: string, command: UpdateAdminImageFrameCommand) {
    this.store.dispatch(new fromImageFrameActions.BusinessImageFrameUpdate({ countryId, businessId, ...command }));
  }

  loadBusinessImageFrame(countryId: string, businessId: string) {
    this.store.dispatch(new fromImageFrameActions.BusinessImageFrameLoad({ countryId, businessId }));
  }

  loadImageFrameEditorURL(frameId: string) {
    this.store.dispatch(new fromImageFrameActions.ImageFrameEditorURLLoad({ frameId }));
  }

  openCreateFrameModal() {
    this.store.dispatch(new fromImageFrameActions.CreateImageFrameModalOpen());
  }

  closeCreateFrameModal() {
    this.store.dispatch(new fromImageFrameActions.CreateImageFrameModalClose());
  }

  openRenameFrameModal() {
    this.store.dispatch(new fromImageFrameActions.RenameImageFrameModalOpen());
  }

  closeRenameFrameModal() {
    this.store.dispatch(new fromImageFrameActions.RenameImageFrameModalClose());
  }
}
