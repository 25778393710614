export enum MixpanelEvent {
  PageView = 'Page View',
  CloseBrowserTab = 'Close Browser Tab',
  LoginSuccess = 'Login Success',
  LoginError = 'Login Error',
  Logout = 'Logout',
  Request = 'Request',
  RequestError = 'Request Error',
  ModalOpen = 'Modal Open',
  ModalClose = 'Modal Close',
}
