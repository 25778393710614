import { ElementRef, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ScrollToTopService {
  private header: ElementRef;
  private root: ElementRef;

  setHeader(header: ElementRef) {
    this.header = header;
  }

  setRoot(root: ElementRef) {
    this.root = root;
  }

  scrollToTop() {
    this.scrollIntoHeader();
  }

  private scrollIntoHeader() {
    if (this.header && this.header.nativeElement) {
      this.header.nativeElement.scrollIntoView();
    } else {
      this.root.nativeElement.scrollIntoView();
    }
  }
}
