import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '@env/environment';
import { ApiModule, BASE_PATH } from './api';

@NgModule({
  imports: [CommonModule, ApiModule],
  exports: [ApiModule],
  providers: [{ provide: BASE_PATH, useValue: environment.apiGateway }]
})
export class RestApiResellerNotificationModule {}
