import { isNil } from 'lodash';

/**
 * Converts hierarchical object to flat one for example:
 * ```
 * {
 *  "parent": {
 *    "child": value
 *    }
 * }
 * ```
 * will be converted to:
 * ```
 * {
 *  "parent.child": value
 * }
 * ```
 */
export function flatObject(input: Object): { [key: string]: any } {
  function flat(res, key, val, pre = '') {
    const prefix = [pre, key].filter(v => v).join('.');
    return typeof val === 'object' && !isNil(val)
      ? Object.keys(val).reduce((prev, curr) => flat(prev, curr, val[curr], prefix), res)
      : Object.assign(res, { [prefix]: val });
  }

  return Object.keys(input).reduce((prev, curr) => flat(prev, curr, input[curr]), {});
}
