import { Component, Input, TemplateRef } from '@angular/core';
import { IsMobileViewService } from '@vpfa/shared/mobile-view';
import { map } from 'rxjs/operators';

@Component({
  selector: 'vpfa-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input() content: string | TemplateRef<void>;
  @Input() placement:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom' = 'top';
  @Input() overlayClass: string = 'tooltip';

  @Input() color: 'blue' | 'orange' = 'blue';

  @Input() iconName = 'zmdi-info';

  @Input() forceClickTrigger = false;

  isMobileView$ = this.mobileService.isMobileView$;
  isVisible = false;

  tooltipTrigger$ = this.isMobileView$.pipe(
    map(isMobileView => {
      if (isMobileView || this.forceClickTrigger) {
        return 'click';
      }

      return 'hover';
    })
  );

  constructor(private mobileService: IsMobileViewService) {}

  onClick(event: MouseEvent) {
    event.stopPropagation();
  }

  get iconConfigClass(): string {
    return `${this.iconName} icon-${this.color}`;
  }

  get isContentTemplateRef(): boolean {
    return typeof this.content === 'object' && this.content instanceof TemplateRef;
  }
}
