import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CustomersFacade } from '@vpfa/dealer/customers/data';
import { CustomerDto, UpdateCustomerCommand } from '@vpfa/rest-api/valuation';
import { CustomerFormModel } from '@vpfa/dealer/customers/shared';
import { UserContext } from '@vpfa/rest-api/admin';
import { ProfileFacade } from '@vpfa/profile/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UsersFacade } from '@vpfa/admin/users-data';
import { ScrollToTopService } from '@vpfa/shared/mobile-view';

@Component({
  selector: 'vpfa-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.scss'],
})
export class CustomerEditComponent implements OnInit, OnDestroy {
  @Input() show: boolean;
  @Input() isProcessing: boolean;
  @Input() set customer(customerModel: CustomerDto) {
    const { id, changedOn, ...formModel } = customerModel;
    this.customerFormModel = formModel;
    this.id = id;
  }
  @Output() onClose = new EventEmitter<void>();

  customerFormsOfAddress$ = this.customersFacade.customerFormsOfAddress$;
  customerFormsOfAddressLoading$ = this.customersFacade.customerFormsOfAddressLoading$;
  allUsers$ = this.usersFacade.allUsers$;
  allUsersLoading$ = this.usersFacade.usersLoading$;

  customerFormModel: CustomerFormModel;
  private id: string;
  private _onDestroy$ = new Subject<void>();

  constructor(
    private customersFacade: CustomersFacade,
    private profileFacade: ProfileFacade,
    private usersFacade: UsersFacade,
    private scrollService: ScrollToTopService
  ) {}

  ngOnInit() {
    this.profileFacade.userContext$.pipe(takeUntil(this._onDestroy$)).subscribe((value: UserContext) => {
      this.customersFacade.loadFormsOfAddress(value.countryCode);
      this.usersFacade.loadUsersByUsersBusiness();
    });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  closeModal() {
    this.onClose.emit();
    this.scrollService.scrollToTop();
  }

  updateCustomer(customer: CustomerFormModel) {
    const command = this.convertFormToCommand(customer);
    this.customersFacade.updateCustomer(command);
  }

  private convertFormToCommand(customerForm: CustomerFormModel): UpdateCustomerCommand {
    return {
      ...customerForm,
      vatRegistered: Boolean(customerForm.vatRegistered),
      aggregateRootId: this.id,
    };
  }
}
