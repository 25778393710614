import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAX_FRACTION_NUMBER, MAX_TECHNICAL_DATA_VALUE_LENGTH } from '../../utils/vehicle-full-technical-data-utils';

@Component({
  selector: 'vpfa-vehicle-technical-data-number-field',
  templateUrl: './vehicle-technical-data-number-field.component.html',
  styleUrls: ['./vehicle-technical-data-number-field.component.scss'],
})
export class VehicleTechnicalDataNumberFieldComponent {
  @Input() label: string;
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() fcName: string;
  @Input() errorMessages: ReadonlyArray<{ error: string; errorMsg: string }>;
  @Input() maxFractionNumber = MAX_FRACTION_NUMBER;
  @Input() isCurrency: boolean = false;
  @Input() heightCompensationPx = -10;
  MAX_TECHNICAL_DATA_VALUE_LENGTH = MAX_TECHNICAL_DATA_VALUE_LENGTH;
}
