import { Component, ViewChild } from '@angular/core';
import { FilterChangedEvent, IFloatingFilter, IFloatingFilterParams } from 'ag-grid-community';
import { AgFrameworkComponent } from 'ag-grid-angular';
import { isNil } from 'lodash';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { FormControl, FormGroup } from '@angular/forms';

export interface DateRangeFilterModel {
  from: string | null;
  to: string | null;
}

export function dateRangeFilterComparator(filter: string, gridValue: any, filterText: string) {
  const datesRange = handleDatesRange(filterText);
  const cellDate = !isNil(gridValue) ? new Date(gridValue) : null;
  if (!isNil(cellDate)) {
    cellDate.setHours(0, 0, 0, 0);
  }

  return !isNil(cellDate) && !isNil(datesRange) && cellDate <= datesRange[1] && cellDate >= datesRange[0];
}

export function handleDatesRange(filterText: string): [Date, Date] {
  const data: { from: string | null; to: string | null } = JSON.parse(filterText);

  const from: Date | null = data.from != null ? new Date(data.from) : null;
  const to: Date | null = data.to != null ? new Date(data.to) : null;

  return [from, to];
}

@Component({
  selector: 'vpfa-date-range-floating-filter',
  templateUrl: './date-range-floating-filter.component.html',
  styleUrls: ['./date-range-floating-filter.component.scss'],
})
export class DateRangeFloatingFilterComponent implements IFloatingFilter, AgFrameworkComponent<IFloatingFilterParams> {
  isNil = isNil;

  @ViewChild('startDatePicker') startDatePicker: NzDatePickerComponent;
  @ViewChild('endDatePicker') endDatePicker: NzDatePickerComponent;

  public htmlId: string | null = null;

  params: IFloatingFilterParams;

  public initialValueFrom: Date | null = null;
  public initialValueTo: Date | null = null;

  public visible: boolean = false;

  formGroup = new FormGroup({
    startValue: new FormControl<Date>(null),
    endValue: new FormControl<Date>(null),
  });

  public fieldName: string | null = null;

  agInit(params: IFloatingFilterParams): void {
    this.params = params;

    this.fieldName = this.params?.column?.getColDef()?.field ?? null;
    this.htmlId = this.fieldName + 'DateRangeFilter';
  }

  onParentModelChanged(parentModel: any, filterChangedEvent?: FilterChangedEvent): void {
    if (!!parentModel) {
      const datesRange = handleDatesRange(parentModel.filter);
      this.initialValueFrom = datesRange[0];
      this.initialValueTo = datesRange[1];

      this.formGroup.reset({
        startValue: datesRange[0],
        endValue: datesRange[1],
      });
    } else {
      this.initialValueFrom = null;
      this.initialValueTo = null;

      this.formGroup.reset({
        startValue: null,
        endValue: null,
      });
    }
  }

  onApplyFilters() {
    const dates = [this.formGroup.controls.startValue?.value ?? null, this.formGroup.controls.endValue?.value ?? null];
    this.params.parentFilterInstance((instance: any) => {
      if (dates.some(x => x !== null)) {
        instance.onFloatingFilterChanged(
          'other',
          JSON.stringify({
            from: dates[0]?.toISOString()?.split('T')?.[0],
            to: dates[1]?.toISOString()?.split('T')?.[0],
          })
        );
      } else {
        instance.onFloatingFilterChanged(null, null);
      }
    });

    this.visible = false;
  }

  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.formGroup.controls.endValue.value) {
      return false;
    }
    return startValue.getTime() > this.formGroup.controls.endValue.value.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.formGroup.controls.startValue.value) {
      return false;
    }
    return endValue.getTime() <= this.formGroup.controls.startValue.value?.getTime();
  };

  onVisibleChange(isVisible: boolean) {
    if (isVisible !== true) {
      this.formGroup.controls.startValue.reset(this.initialValueFrom);
      this.formGroup.controls.endValue.reset(this.initialValueTo);
    }
  }
}
