import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { InsuranceClaimingService } from './api/insuranceClaiming.service';
import { InsuranceClaimingConfigurationService } from './api/insuranceClaimingConfiguration.service';
import { InsuranceClaimingConfigurationViewService } from './api/insuranceClaimingConfigurationView.service';
import { InsuranceClaimingFilesService } from './api/insuranceClaimingFiles.service';
import { InsuranceClaimingFilesViewService } from './api/insuranceClaimingFilesView.service';
import { InsuranceClaimingHistoryFilesViewService } from './api/insuranceClaimingHistoryFilesView.service';
import { InsuranceClaimingHistoryViewService } from './api/insuranceClaimingHistoryView.service';
import { InsuranceClaimingViewService } from './api/insuranceClaimingView.service';
import { VehicleInsuranceClaimingSeedService } from './api/vehicleInsuranceClaimingSeed.service';
import { VehicleInsuranceClaimingServiceMaintenanceService } from './api/vehicleInsuranceClaimingServiceMaintenance.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    InsuranceClaimingService,
    InsuranceClaimingConfigurationService,
    InsuranceClaimingConfigurationViewService,
    InsuranceClaimingFilesService,
    InsuranceClaimingFilesViewService,
    InsuranceClaimingHistoryFilesViewService,
    InsuranceClaimingHistoryViewService,
    InsuranceClaimingViewService,
    VehicleInsuranceClaimingSeedService,
    VehicleInsuranceClaimingServiceMaintenanceService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}