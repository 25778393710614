import { Component, HostBinding } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';
import { GridOptions, INoRowsOverlayParams } from 'ag-grid-community';

@Component({
  selector: 'vpfa-data-table-no-rows-indicator',
  templateUrl: './data-table-no-rows-indicator.component.html',
  styleUrls: ['./data-table-no-rows-indicator.component.scss'],
})
export class DataTableNoRowsIndicatorComponent implements ILoadingOverlayAngularComp {
  gridOptions: GridOptions;

  private readonly defaultHeaderHeight = 67;
  private headerCurrentHeight = this.defaultHeaderHeight;

  agInit(params: INoRowsOverlayParams): void {
    this.headerCurrentHeight =
      document.querySelector('.ag-header')?.getBoundingClientRect().height ?? this.defaultHeaderHeight;
  }

  @HostBinding('style.margin-top')
  get offsetForCenter() {
    return `${(this.headerCurrentHeight - 30) / 2}px`;
  }
}
