import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WarningModalComponent } from './warning-modal/warning-modal.component';
import { UiKitModule } from '@vpfa/ui-kit';
import { FlexModule } from '@ngbracket/ngx-layout';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { TranslateModule } from '@ngx-translate/core';
import { SimpleModalComponent } from './components/simple-modal/simple-modal.component';
import { ModalsModule } from '@vpfa/modals';

@NgModule({
  imports: [
    CommonModule,
    UiKitModule,
    FlexModule,
    NzButtonModule,
    TranslateModule,
    NzPopconfirmModule,
    NzIconModule,
    NzSpinModule,
    NzModalModule,
    ModalsModule,
  ],
  exports: [WarningModalComponent, SimpleModalComponent],
  declarations: [WarningModalComponent, SimpleModalComponent],
})
export class UiKitModalsModule {}
