<div fxLayout="row" fxLayoutAlign="space-between center">
  <div fxFlex class="pagination-total">
    {{ visibleItemsStart }}-{{ visibleItemsEnd }} {{ 'dataTable.pagination.from' | translate }}
    {{ options?.totalItems }}
  </div>
  <div fxLayoutAlign="center center" class="pagination-pages">
    <button
      nz-button
      [title]="'dataTable.pagination.firstPage' | translate"
      class="pagination-page-button hidden-button"
      [ngClass]="{ 'show-button': options?.currentPage !== 1 }"
      (click)="pageChange.emit(1)"
    >
      <i nz-icon nzType="vertical-right" nzTheme="outline"></i>
    </button>

    <button
      nz-button
      class="pagination-page-button hidden-button"
      [title]="'dataTable.pagination.previousPage' | translate"
      [ngClass]="{ 'show-button': options?.currentPage !== 1 }"
      (click)="pageChange.emit(options?.currentPage - 1)"
    >
      <i nz-icon nzType="left" nzTheme="outline"></i>
    </button>
    <button
      class="pagination-page-button"
      [ngClass]="{ 'pagination-active-page': page === options?.currentPage }"
      nz-button
      *ngFor="let page of pagesToDisplay"
      (click)="pageChange.emit(page)"
    >
      {{ page }}
    </button>
    <button
      nz-button
      [title]="'dataTable.pagination.nextPage' | translate"
      class="pagination-page-button hidden-button"
      [ngClass]="{ 'show-button': options?.currentPage !== options?.totalPages }"
      (click)="pageChange.emit(options?.currentPage + 1)"
    >
      <i nz-icon nzType="right" nzTheme="outline"></i>
    </button>
    <button
      nz-button
      [title]="'dataTable.pagination.lastPage' | translate"
      class="pagination-page-button hidden-button"
      [ngClass]="{ 'show-button': options?.currentPage !== options?.totalPages }"
      (click)="pageChange.emit(options?.totalPages)"
    >
      <i nz-icon nzType="vertical-left" nzTheme="outline"></i>
    </button>
  </div>
  <div fxFlex fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="pagination-size-selector">
    <span id="numberOfRecords" [title]="'dataTable.pagination.numberOfRecords' | translate">
      {{ 'dataTable.pagination.numberOfRecords' | translate }}
    </span>
    <vpfa-select
      [parentFormGroup]="paginationFormGroup"
      [fc]="paginationFormGroup.get('pageSize')"
      [options]="sizeOptions"
      (selected)="onPageSizeChange($event)"
      [allowClear]="false"
    ></vpfa-select>
  </div>
</div>
