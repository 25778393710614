import { Action } from '@ngrx/store';
import {
  CommandHandlerResponse,
  UpdateIdentifiedVehicleEquipmentCommand,
  CreateAfterMarketEquipmentCommand,
  UpdateAfterMarketEquipmentCommand,
  UniversalCategoryListItemDto,
  UpdateIdentifiedVehicleEquipmentCommandHandlerResponse,
  EquipmentStateChange,
  UpdateIdentifiedVehicleEquipmentColorCommand,
} from '@vpfa/rest-api/valuation';

export enum VehicleEquipmentActionTypes {
  VehicleEquipmentUpdateIsEnabled = '[VehicleEquipment] VehicleEquipment Update Is Enabled',
  VehicleEquipmentUpdateIsEnabledSuccess = '[VehicleEquipment] VehicleEquipment Update Is Enabled Success',
  VehicleEquipmentUpdateIsEnabledError = '[VehicleEquipment] VehicleEquipment Update Is Enabled Error',

  VehicleEquipmentAftermarketItemCreate = '[VehicleEquipment] VehicleEquipment Aftermarket Item Create',
  VehicleEquipmentAftermarketItemCreated = '[VehicleEquipment] VehicleEquipment Aftermarket Item Created',
  VehicleEquipmentAftermarketItemCreateError = '[VehicleEquipment] VehicleEquipment Aftermarket Item Create Error',

  VehicleEquipmentAftermarketItemUpdate = '[VehicleEquipment] VehicleEquipment Aftermarket Item Update',
  VehicleEquipmentAftermarketItemUpdated = '[VehicleEquipment] VehicleEquipment Aftermarket Item Updated',
  VehicleEquipmentAftermarketItemUpdateError = '[VehicleEquipment] VehicleEquipment Aftermarket Item Update Error',

  VehicleEquipmentAftermarketItemDelete = '[VehicleEquipment] VehicleEquipment Aftermarket Item Delete',
  VehicleEquipmentAftermarketItemDeleted = '[VehicleEquipment] VehicleEquipment Aftermarket Item Deleted',
  VehicleEquipmentAftermarketItemDeleteError = '[VehicleEquipment] VehicleEquipment Aftermarket Item Delete Error',

  VehicleEquipmentUniversalCategoryCodesLoad = '[VehicleEquipment] VehicleEquipment Universal Category Codes Load',
  VehicleEquipmentUniversalCategoryCodesLoaded = '[VehicleEquipment] VehicleEquipment Universal Category Codes Loaded',
  VehicleEquipmentUniversalCategoryCodesLoadError = '[VehicleEquipment] VehicleEquipment Universal Category Codes Load Error',

  VehicleEquipmentCancelDeselectPackageContent = '[VehicleEquipment] VehicleEquipment Cancel Deselect Package Content',
  VehicleEquipmentCancelDeselectPackage = '[VehicleEquipment] Vehicle Equipment Cancel Deselect Package',

  VehicleEquipmentColorUpdate = '[VehicleEquipment] VehicleEquipment Color Update',
  VehicleEquipmentColorUpdated = '[VehicleEquipment] VehicleEquipment Color Updated',
  VehicleEquipmentColorUpdateError = '[VehicleEquipment] VehicleEquipment Color Update Error',

  VehicleResetEquipmentListToDefault = '[VehicleEquipment] Reset Equipment List To Default',
  VehicleResetEquipmentListToDefaultSuccess = '[VehicleEquipment] Reset Equipment List To Default Success',
  VehicleResetEquipmentListToDefaultError = '[VehicleEquipment] Reset Equipment List To Default Error',
}

export type UpdateIdentifiedVehicleSingleEquipmentCommand = Omit<
  UpdateIdentifiedVehicleEquipmentCommand,
  'equipmentChanges'
> &
  EquipmentStateChange;

export class VehicleEquipmentUpdateIsEnabled implements Action {
  readonly type = VehicleEquipmentActionTypes.VehicleEquipmentUpdateIsEnabled;
  constructor(public payload: { command: UpdateIdentifiedVehicleSingleEquipmentCommand; caseId: string }) {}
}

export class VehicleEquipmentUpdateIsEnabledError implements Action {
  readonly type = VehicleEquipmentActionTypes.VehicleEquipmentUpdateIsEnabledError;
  constructor(public payload: { caseId: string; error: CommandHandlerResponse }) {}
}

export class VehicleEquipmentUpdateIsEnabledSuccess implements Action {
  readonly type = VehicleEquipmentActionTypes.VehicleEquipmentUpdateIsEnabledSuccess;
  constructor(
    public payload: {
      soaCode: string;
      /**
       * Enabled or disabled by the user (taken from __request__ to BE).
       */
      isEnabled: boolean;
      caseId: string;
      errors: UpdateIdentifiedVehicleEquipmentCommandHandlerResponse['equipmentErrors'];
      infos: UpdateIdentifiedVehicleEquipmentCommandHandlerResponse['equipmentInfos'];
      vehicleId: UpdateIdentifiedVehicleEquipmentCommand['aggregateRootId'];
      formulaBlocks: UpdateIdentifiedVehicleEquipmentCommandHandlerResponse['formulaBlocks'];
    }
  ) {}
}

export class VehicleEquipmentAftermarketItemCreate implements Action {
  readonly type = VehicleEquipmentActionTypes.VehicleEquipmentAftermarketItemCreate;
  constructor(public payload: { command: CreateAfterMarketEquipmentCommand; caseId: string }) {}
}

export class VehicleEquipmentAftermarketItemCreated implements Action {
  readonly type = VehicleEquipmentActionTypes.VehicleEquipmentAftermarketItemCreated;
  constructor(public payload: { response: CommandHandlerResponse; caseId: string }) {}
}

export class VehicleEquipmentAftermarketItemCreateError implements Action {
  readonly type = VehicleEquipmentActionTypes.VehicleEquipmentAftermarketItemCreateError;
  constructor(public payload: CommandHandlerResponse) {}
}

export class VehicleEquipmentAftermarketItemUpdate implements Action {
  readonly type = VehicleEquipmentActionTypes.VehicleEquipmentAftermarketItemUpdate;
  constructor(public payload: { command: UpdateAfterMarketEquipmentCommand; caseId: string }) {}
}

export class VehicleEquipmentAftermarketItemUpdated implements Action {
  readonly type = VehicleEquipmentActionTypes.VehicleEquipmentAftermarketItemUpdated;
  constructor(public payload: { response: CommandHandlerResponse; caseId: string }) {}
}

export class VehicleEquipmentAftermarketItemUpdateError implements Action {
  readonly type = VehicleEquipmentActionTypes.VehicleEquipmentAftermarketItemUpdateError;
  constructor(public payload: CommandHandlerResponse) {}
}

export class VehicleEquipmentAftermarketItemDelete implements Action {
  readonly type = VehicleEquipmentActionTypes.VehicleEquipmentAftermarketItemDelete;
  constructor(public payload: { caseId: string; identifiedVehicleId: string; soaCode: string }) {}
}

export class VehicleEquipmentAftermarketItemDeleted implements Action {
  readonly type = VehicleEquipmentActionTypes.VehicleEquipmentAftermarketItemDeleted;
  constructor(public payload: string) {}
}

export class VehicleEquipmentAftermarketItemDeleteError implements Action {
  readonly type = VehicleEquipmentActionTypes.VehicleEquipmentAftermarketItemDeleteError;
  constructor(public payload: CommandHandlerResponse) {}
}

export class VehicleEquipmentUniversalCategoryCodesLoad implements Action {
  readonly type = VehicleEquipmentActionTypes.VehicleEquipmentUniversalCategoryCodesLoad;
}

export class VehicleEquipmentUniversalCategoryCodesLoaded implements Action {
  readonly type = VehicleEquipmentActionTypes.VehicleEquipmentUniversalCategoryCodesLoaded;
  constructor(public payload: UniversalCategoryListItemDto[]) {}
}

export class VehicleEquipmentUniversalCategoryCodesLoadError implements Action {
  readonly type = VehicleEquipmentActionTypes.VehicleEquipmentUniversalCategoryCodesLoadError;
  constructor(public payload: CommandHandlerResponse) {}
}

export class VehicleEquipmentCancelDeselectPackageContent implements Action {
  readonly type = VehicleEquipmentActionTypes.VehicleEquipmentCancelDeselectPackageContent;
}

export class VehicleEquipmentCancelDeselectPackage implements Action {
  readonly type = VehicleEquipmentActionTypes.VehicleEquipmentCancelDeselectPackage;
}

export class VehicleEquipmentColorUpdate implements Action {
  readonly type = VehicleEquipmentActionTypes.VehicleEquipmentColorUpdate;
  constructor(public payload: { command: UpdateIdentifiedVehicleEquipmentColorCommand; caseId: string }) {}
}

export class VehicleEquipmentColorUpdated implements Action {
  readonly type = VehicleEquipmentActionTypes.VehicleEquipmentColorUpdated;
  constructor(public payload: { response: CommandHandlerResponse; caseId: string }) {}
}

export class VehicleEquipmentColorUpdateError implements Action {
  readonly type = VehicleEquipmentActionTypes.VehicleEquipmentColorUpdateError;
}

export class VehicleResetEquipmentListToDefault implements Action {
  readonly type = VehicleEquipmentActionTypes.VehicleResetEquipmentListToDefault;
}

export class VehicleResetEquipmentListToDefaultSuccess implements Action {
  readonly type = VehicleEquipmentActionTypes.VehicleResetEquipmentListToDefaultSuccess;
  constructor(public payload: string) {}
}

export class VehicleResetEquipmentListToDefaultError implements Action {
  readonly type = VehicleEquipmentActionTypes.VehicleResetEquipmentListToDefaultError;
}

export type VehicleEquipmentAction =
  | VehicleEquipmentUpdateIsEnabled
  | VehicleEquipmentUpdateIsEnabledSuccess
  | VehicleEquipmentUpdateIsEnabledError
  | VehicleEquipmentAftermarketItemCreate
  | VehicleEquipmentAftermarketItemCreated
  | VehicleEquipmentAftermarketItemCreateError
  | VehicleEquipmentAftermarketItemUpdate
  | VehicleEquipmentAftermarketItemUpdated
  | VehicleEquipmentAftermarketItemUpdateError
  | VehicleEquipmentAftermarketItemDelete
  | VehicleEquipmentAftermarketItemDeleted
  | VehicleEquipmentAftermarketItemDeleteError
  | VehicleEquipmentUniversalCategoryCodesLoad
  | VehicleEquipmentUniversalCategoryCodesLoaded
  | VehicleEquipmentUniversalCategoryCodesLoadError
  | VehicleEquipmentCancelDeselectPackageContent
  | VehicleEquipmentCancelDeselectPackage
  | VehicleEquipmentColorUpdate
  | VehicleEquipmentColorUpdated
  | VehicleEquipmentColorUpdateError
  | VehicleResetEquipmentListToDefault
  | VehicleResetEquipmentListToDefaultSuccess
  | VehicleResetEquipmentListToDefaultError;

export const fromVehicleEquipmentActions = {
  VehicleEquipmentUpdateIsEnabled,
  VehicleEquipmentUpdateIsEnabledSuccess,
  VehicleEquipmentUpdateIsEnabledError,
  VehicleEquipmentAftermarketItemCreate,
  VehicleEquipmentAftermarketItemCreated,
  VehicleEquipmentAftermarketItemCreateError,
  VehicleEquipmentAftermarketItemUpdate,
  VehicleEquipmentAftermarketItemUpdated,
  VehicleEquipmentAftermarketItemUpdateError,
  VehicleEquipmentAftermarketItemDelete,
  VehicleEquipmentAftermarketItemDeleted,
  VehicleEquipmentAftermarketItemDeleteError,
  VehicleEquipmentUniversalCategoryCodesLoad,
  VehicleEquipmentUniversalCategoryCodesLoaded,
  VehicleEquipmentUniversalCategoryCodesLoadError,
  VehicleEquipmentCancelDeselectPackageContent,
  VehicleEquipmentCancelDeselectPackage,
  VehicleEquipmentColorUpdate,
  VehicleEquipmentColorUpdated,
  VehicleEquipmentColorUpdateError,
  VehicleResetEquipmentListToDefault,
  VehicleResetEquipmentListToDefaultSuccess,
  VehicleResetEquipmentListToDefaultError,
};
