<vpfa-field-validation-container
  [isText]="isText"
  [isNumber]="isNumber"
  [isCurrency]="isCurrency"
  [heightCompensationPx]="heightCompensationPx"
  [errorMessages]="errorMessages"
  [errorMsgVertPosition]="errorMsgVertPosition"
  [displayErrors]="displayErrors && control?.dirty"
  [isInvalid]="(isInvalid$ | async) && control?.dirty"
  [validationErrors]="control?.errors"
  [ngClass]="{ 'max-width': maxWidth }"
>
  <nz-form-control>
    <ng-content></ng-content>
  </nz-form-control>
</vpfa-field-validation-container>
