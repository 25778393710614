<div
  fxLayout="row"
  fxLayoutAlign="flex-start center"
  [formGroup]="formGroup"
  [className]="disabledDatePicker ? 'disabled' : 'active'"
>
  <span class="display-text">
    {{ formGroup.get('regDate').value | dateLocale: 'shortDate' }}
  </span>
  <vpfa-inline-loader [isLoading]="isProcessing" [displayContent]="!isProcessing">
    <vpfa-iso-date-picker
      [className]="disabledDatePicker ? 'disabled' : 'active'"
      [allowClear]="false"
      [showToday]="false"
      [readonly]="disabledDatePicker"
      [disabledDate]="disabledDate"
      [format]="'short' | localeDateFormat"
      [formControl]="formGroup.get('regDate')"
      [applyWhiteBackground]="true"
      [inputReadOnly]="true"
      (onOpenChange)="onDatepickerToggle($event)"
    >
    </vpfa-iso-date-picker>
  </vpfa-inline-loader>
</div>
