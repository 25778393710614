import { BREAKPOINT } from '@ngbracket/ngx-layout';

export const mobileViewAlias = 'lt-mv'

const MOBILE_VIEW_BREAKPOINTS = [
  {
    alias: mobileViewAlias,
    suffix: 'LtMV',
    mediaQuery: 'screen and (max-width: 1023px)', // based on https://jira.autovistagroup.com/browse/VP-3718
    overlapping: true,
    priority: 2000
  },
  {
    alias: 'gt-mv',
    suffix: 'GtMV',
    mediaQuery: 'screen and (min-width: 1024px)', // based on https://jira.autovistagroup.com/browse/VP-3718
    overlapping: true,
    priority: 2001
  }
];

export const MobileBreakPointsProvider = {
  provide: BREAKPOINT,
  useValue: MOBILE_VIEW_BREAKPOINTS,
  multi: true
};
