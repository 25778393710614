import { DateTime } from 'luxon';
import { UntypedFormControl } from '@angular/forms';
import { getCurrentDate } from '@vpfa/utils';

export function notLaterThan(current: string, timezone: string, startOf: 'day' | 'month' = 'day'): boolean {
  const currentLuxon =  DateTime.fromISO(current).startOf(startOf);
  const todayLuxon = DateTime.fromISO(getCurrentDate(timezone)).startOf(startOf);
  const isLaterThanToday = todayLuxon < currentLuxon;
  const isOlderThan2000 = DateTime.fromISO('2000-01-01') > currentLuxon;
  return isLaterThanToday || isOlderThan2000;
}

export function notLaterThanFromToday(
  current: string,
  timezone: string,
  years?: number,
  months?: number,
  weeks?: number,
  days?: number,
  disableSameAsMaximal = false
): boolean {
  const currentLuxon = DateTime.fromISO(current).startOf('day');
  const maximalLuxon =  DateTime.fromISO(getCurrentDate(timezone)).startOf('day')
    .plus({
      years: years,
      months: months,
      weeks: weeks,
      days: days,
    });
  return disableSameAsMaximal ? maximalLuxon <= currentLuxon : maximalLuxon < currentLuxon;
}

export function searchVehicleBuildDisabledDate(current: string, timezone: string, regDateCtrl: UntypedFormControl): boolean {
  const disabledVehicleDate = notLaterThan(current, timezone);
  let disabledByRegDate = false;
  let regDate = regDateCtrl.value;
  if (regDateCtrl.valid && regDate && isGreaterDate(regDateCtrl.value, current)) {
    disabledByRegDate = true;
  }

  return disabledVehicleDate || disabledByRegDate;
}

export function isGreaterDate(baseDate: string, checkDate: string) {
  const firstDate = DateTime.fromISO(baseDate).startOf('day');
  const secondDate = DateTime.fromISO(checkDate).startOf('day');
  return firstDate < secondDate;
}
