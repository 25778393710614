<span
  *ngIf="icon"
  [class.disabled]="disabled"
  nz-icon
  [nzType]="icon"
  nzTheme="fill"
  class="icon-button"
  nz-popconfirm
  [nzCondition]="!showPopconfirm"
  [nzPopconfirmTitle]="popconfirmMainText | translate"
  [nzCancelText]="popconfirmCancelText | translate"
  [nzOkText]="popconfirmOkText | translate"
  [nzPopconfirmPlacement]="popconfirmPlacement"
  [nzPopconfirmTrigger]="popconfirmTrigger"
  [nzPopconfirmOverlayClassName]="popconfirmOverlayClassName"
  [nzPopconfirmOverlayStyle]="popconfirmOverlayStyle"
  (nzOnConfirm)="onConfirm.emit()"
></span>

<vpfa-action-button
  *ngIf="!icon"
  [disabled]="disabled"
  [outline]="linkMode"
  [border]="border"
  [arrow]="arrow"
  [fullWidth]="fullWidth"
  [notAvailableIcon]="notAvailableIcon"
  [tooltipContent]="tooltipContent"
  [buttonType]="buttonType"
  nz-popconfirm
  [nzCondition]="!showPopconfirm"
  [nzPopconfirmTitle]="popconfirmMainText | translate"
  [nzCancelText]="popconfirmCancelText | translate"
  [nzOkText]="popconfirmOkText | translate"
  [nzPopconfirmPlacement]="popconfirmPlacement"
  [nzPopconfirmTrigger]="popconfirmTrigger"
  [nzPopconfirmOverlayClassName]="popconfirmOverlayClassName"
  (nzOnConfirm)="onConfirm.emit()"
>
  {{ buttonText | translate }}
</vpfa-action-button>
