<nz-date-picker
  *ngIf="!monthPicker"
  [ngClass.lt-mv]="{ 'clear-always-visible': true, 'gray-bg': !applyWhiteBackground }"
  [ngClass]="{ 'gray-bg': !applyWhiteBackground }"
  [nzAllowClear]="allowClear"
  [nzShowToday]="showToday"
  [nzFormat]="format"
  [nzSize]="size"
  [nzDisabled]="readonly || formControl?.disabled"
  [nzDisabledDate]="disabledISODate"
  [nzDropdownClassName]="nzDropdownClassName"
  [nzInputReadOnly]="inputReadOnly"
  [(ngModel)]="date"
  (nzOnOpenChange)="onOpenChange.emit($event)"
></nz-date-picker>

<!-- TODO: month picker is not used at the moment, remove? -->
<nz-month-picker
  *ngIf="monthPicker"
  [ngClass.lt-mv]="{ 'clear-always-visible': true, 'gray-bg': !applyWhiteBackground }"
  [ngClass]="{ 'gray-bg': !applyWhiteBackground }"
  [nzAllowClear]="allowClear"
  [nzShowToday]="showToday"
  [nzSize]="size"
  [nzDisabled]="readonly"
  [nzDisabledDate]="disabledISODate"
  [nzDropdownClassName]="nzDropdownClassName"
  [(ngModel)]="date"
  (nzOnOpenChange)="onOpenChange.emit($event)"
>
</nz-month-picker>
