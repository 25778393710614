<vpfa-modal
  modalName="Customer Data"
  width="650px"
  [showBackdrop]="true"
  [isVisible]="visible"
  [isClosable]="false"
  [keyboard]="false"
  [maskClosable]="false"
  (closeAction)="close()"
>
  <vpfa-modal-content>
    <vpfa-action-button-with-confirmation
      class="ant-modal-close"
      icon="close-circle"
      [showPopconfirm]="!customerForm.pristine || !textListFieldsForm.pristine"
      (onConfirm)="close()"
    ></vpfa-action-button-with-confirmation>
    <form (ngSubmit)="saveCustomer()" [formGroup]="customerForm">
      <nz-spin [nzSpinning]="isProcessing">
        <h1>{{ modalTitleCode | translate }}</h1>
        <div>
          <div class="customer-form-box">
            <vpfa-setting-box [divider]="false">
              <ng-container label>
                <vpfa-label [text]="'customerDataModal.formOfAddress' | translate"></vpfa-label>
              </ng-container>
              <ng-container content>
                <vpfa-select
                  [parentFormGroup]="customerForm"
                  [fc]="customerForm.get('formOfAddress')"
                  [options]="formsOfAddressOptions"
                  [initialValue]="formOfAddressInitValue"
                  [loading]="isFormsOfAddressLoading"
                  [allowClear]="true"
                ></vpfa-select>
              </ng-container>
            </vpfa-setting-box>
            <vpfa-setting-box [divider]="false">
              <ng-container label>
                <vpfa-label [text]="'customerDataModal.firstName' | translate"></vpfa-label>
              </ng-container>
              <ng-container content>
                <vpfa-text-field
                  fieldWidth="100%"
                  [parentFormGroup]="customerForm"
                  fcName="firstName"
                  [errorMessages]="maxLengthErrorMsgList"
                ></vpfa-text-field>
              </ng-container>
            </vpfa-setting-box>
            <vpfa-setting-box [divider]="false">
              <ng-container label>
                <vpfa-label [text]="'customerDataModal.lastName' | translate"></vpfa-label>
              </ng-container>
              <ng-container content>
                <vpfa-text-field
                  [errorMessages]="lastNameFieldErrorMsgList"
                  heightCompensationPx="-12"
                  fieldWidth="100%"
                  [parentFormGroup]="customerForm"
                  fcName="lastName"
                ></vpfa-text-field>
              </ng-container>
            </vpfa-setting-box>

            <vpfa-setting-box [divider]="false">
              <ng-container label>
                <vpfa-label [text]="'customerDataModal.company' | translate"></vpfa-label>
              </ng-container>
              <ng-container afterLabel>
                <div [ngClass]="{ 'n-invalid': customerForm.get('company').invalid }">
                  {{ customerForm?.get('company')?.value?.length || 0 }} / {{ textareaMaxLength }}
                </div>
              </ng-container>
              <ng-container content>
                <vpfa-textarea [parentFormGroup]="customerForm" [fcName]="'company'"></vpfa-textarea>
              </ng-container>
            </vpfa-setting-box>
            <vpfa-setting-box [divider]="false">
              <ng-container label>
                <vpfa-label [text]="'customerDataModal.department' | translate"></vpfa-label>
              </ng-container>
              <ng-container content>
                <vpfa-text-field
                  fieldWidth="100%"
                  [parentFormGroup]="customerForm"
                  fcName="department"
                  [errorMessages]="maxLengthErrorMsgList"
                ></vpfa-text-field>
              </ng-container>
            </vpfa-setting-box>
            <vpfa-setting-box [divider]="false">
              <ng-container label>
                <vpfa-label [text]="'customerDataModal.position' | translate"></vpfa-label>
              </ng-container>
              <ng-container content>
                <vpfa-text-field
                  fieldWidth="100%"
                  [parentFormGroup]="customerForm"
                  fcName="position"
                  [errorMessages]="maxLengthErrorMsgList"
                ></vpfa-text-field>
              </ng-container>
            </vpfa-setting-box>
            <vpfa-setting-box [divider]="false">
              <ng-container label>
                <vpfa-label [text]="'customerDataModal.address' | translate"></vpfa-label>
              </ng-container>
              <ng-container content>
                <vpfa-text-field
                  fieldWidth="100%"
                  [parentFormGroup]="customerForm"
                  fcName="address"
                  [errorMessages]="maxLengthErrorMsgList"
                ></vpfa-text-field>
              </ng-container>
            </vpfa-setting-box>
            <vpfa-setting-box [divider]="false">
              <ng-container label>
                <vpfa-label [text]="'customerDataModal.street' | translate"></vpfa-label>
              </ng-container>
              <ng-container content>
                <vpfa-text-field
                  fieldWidth="100%"
                  [parentFormGroup]="customerForm"
                  fcName="street"
                  [errorMessages]="maxLengthErrorMsgList"
                ></vpfa-text-field>
              </ng-container>
            </vpfa-setting-box>
            <vpfa-setting-box [divider]="false">
              <ng-container label>
                <vpfa-label [text]="'customerDataModal.postcode' | translate"></vpfa-label>
              </ng-container>
              <ng-container content>
                <vpfa-text-field
                  fieldWidth="100%"
                  [parentFormGroup]="customerForm"
                  fcName="postcode"
                  [errorMessages]="maxLengthErrorMsgList"
                ></vpfa-text-field>
              </ng-container>
            </vpfa-setting-box>
            <vpfa-setting-box [divider]="false">
              <ng-container label>
                <vpfa-label [text]="'customerDataModal.city' | translate"></vpfa-label>
              </ng-container>
              <ng-container content>
                <vpfa-text-field
                  fieldWidth="100%"
                  [parentFormGroup]="customerForm"
                  fcName="city"
                  [errorMessages]="maxLengthErrorMsgList"
                ></vpfa-text-field>
              </ng-container>
            </vpfa-setting-box>
            <vpfa-setting-box [divider]="false">
              <ng-container label>
                <vpfa-label [text]="'customerDataModal.country' | translate"></vpfa-label>
              </ng-container>
              <ng-container content>
                <vpfa-text-field
                  fieldWidth="100%"
                  [parentFormGroup]="customerForm"
                  fcName="country"
                  [errorMessages]="maxLengthErrorMsgList"
                ></vpfa-text-field>
              </ng-container>
            </vpfa-setting-box>
            <vpfa-setting-box [divider]="false">
              <ng-container label>
                <vpfa-label [text]="'customerDataModal.phone' | translate"></vpfa-label>
              </ng-container>
              <ng-container content>
                <vpfa-text-list-field
                  [errorMessages]="maxLengthErrorMsgList"
                  #phoneNumberComponent
                  [parentFormGroup]="customerForm"
                  [validators]="[maxLengthValidator]"
                  fcName="phoneNumbers"
                  [componentVisible]="visible"
                ></vpfa-text-list-field>
              </ng-container>
            </vpfa-setting-box>
            <vpfa-setting-box [divider]="false">
              <ng-container label>
                <vpfa-label [text]="'customerDataModal.emails' | translate"></vpfa-label>
              </ng-container>
              <ng-container content>
                <vpfa-text-list-field
                  #emailsComponent
                  [errorMessages]="emailFieldErrorMsgList"
                  [validators]="[emailValidator, emailMaxLengthValidator]"
                  [parentFormGroup]="customerForm"
                  fcName="emails"
                  [componentVisible]="visible"
                ></vpfa-text-list-field>
              </ng-container>
            </vpfa-setting-box>

            <vpfa-setting-box [divider]="false">
              <ng-container label>
                <vpfa-label [text]="'customerDataModal.birthDate' | translate"></vpfa-label>
              </ng-container>
              <ng-container content>
                <vpfa-iso-date-picker
                  [allowClear]="true"
                  [format]="'short' | localeDateFormat"
                  [formControl]="customerForm.get('birthDate')"
                  [disabledDate]="disableNewerThanToday(timezone$ | async)"
                ></vpfa-iso-date-picker>
              </ng-container>
            </vpfa-setting-box>

            <vpfa-setting-box [divider]="false">
              <ng-container label>
                <vpfa-label [text]="'customerDataModal.externalId' | translate"></vpfa-label>
              </ng-container>
              <ng-container content>
                <vpfa-text-field
                  fieldWidth="100%"
                  [parentFormGroup]="customerForm"
                  fcName="externalId"
                  [errorMessages]="maxLengthErrorMsgList"
                ></vpfa-text-field>
              </ng-container>
            </vpfa-setting-box>
            <vpfa-setting-box [divider]="false">
              <ng-container label>
                <vpfa-label [text]="'customerDataModal.owner' | translate"></vpfa-label>
              </ng-container>
              <ng-container content>
                <vpfa-select
                  [parentFormGroup]="customerForm"
                  [fc]="customerForm.get('ownerId')"
                  [options]="ownersOptions"
                  [initialValue]="ownerInitValue"
                  [loading]="isOwnersLoading"
                  [allowClear]="false"
                ></vpfa-select>
              </ng-container>
            </vpfa-setting-box>
            <vpfa-setting-box [divider]="false" [inline]="true">
              <ng-container label>
                <vpfa-label [text]="'customerDataModal.vatRegistered' | translate"></vpfa-label>
              </ng-container>
              <ng-container content>
                <vpfa-switch [fc]="customerForm.get('vatRegistered')" [parentFormGroup]="customerForm"></vpfa-switch>
              </ng-container>
            </vpfa-setting-box>
            <vpfa-setting-box [divider]="false">
              <ng-container label>
                <vpfa-label [text]="'customerDataModal.vatNo' | translate"></vpfa-label>
              </ng-container>
              <ng-container content>
                <vpfa-text-field
                  fieldWidth="100%"
                  [parentFormGroup]="customerForm"
                  fcName="vatNo"
                  [errorMessages]="maxLengthErrorMsgList"
                ></vpfa-text-field>
              </ng-container>
            </vpfa-setting-box>
            <vpfa-setting-box [divider]="false">
              <ng-container label>
                <vpfa-label [text]="'customerDataModal.notes' | translate"></vpfa-label>
              </ng-container>
              <ng-container afterLabel>
                <div [ngClass]="{ 'n-invalid': customerForm.get('notes').invalid }">
                  {{ customerForm?.get('notes')?.value?.length || 0 }} / {{ textareaMaxLength }}
                </div>
              </ng-container>
              <ng-container content>
                <vpfa-textarea [parentFormGroup]="customerForm" [fcName]="'notes'"></vpfa-textarea>
              </ng-container>
            </vpfa-setting-box>
          </div>
          <div class="customer-button-box gap-10px">
            <vpfa-action-button buttonType="submit" [disabled]="saveCustomerDisabled">
              {{ 'customerDataModal.saveCustomer' | translate }}
            </vpfa-action-button>
            <vpfa-action-button-with-confirmation
              [showPopconfirm]="!customerForm.pristine || !textListFieldsForm.pristine"
              (onConfirm)="close()"
            ></vpfa-action-button-with-confirmation>
          </div>
        </div>
      </nz-spin>
    </form>
  </vpfa-modal-content>
</vpfa-modal>
