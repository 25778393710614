const fuelTypesFromSorc: {
  [sorcCode: string]: {
    isElectricConsumptionable?: boolean;
    isFuelConsumptionable?: boolean;
    GBBR?: string;
    DEDE?: string;
  };
} = {
  '00100000': { GBBR: 'Fuel type' },
  '00100001': { DEDE: 'Benzin', GBBR: 'Petrol', isFuelConsumptionable: true },
  '00100002': { GBBR: 'Petrol leaded', isFuelConsumptionable: true },
  '00100003': { DEDE: 'Diesel', GBBR: 'Diesel', isFuelConsumptionable: true },
  '00100004': { DEDE: 'Elektrischer Strom', GBBR: 'Electric (Battery)', isElectricConsumptionable: true },
  '00100005': { GBBR: 'Fuel cell', isElectricConsumptionable: true, isFuelConsumptionable: true },
  '00100006': { DEDE: 'Benzin/Gas', GBBR: 'Petrol or gas', isFuelConsumptionable: true },
  '00100007': { GBBR: 'Diesel or gas', isFuelConsumptionable: true },
  '00100008': { DEDE: 'Wasserstoff', GBBR: 'Hydrogen', isFuelConsumptionable: true },
  '00100009': { GBBR: 'Alcohol', isFuelConsumptionable: true },
  '00100010': { GBBR: 'Two stroke mixture', isFuelConsumptionable: true },
  '00100011': { GBBR: 'Natural Gas', isFuelConsumptionable: true },
  '00100012': { DEDE: 'Benzin/Alkohol', GBBR: 'Petrol/Ethanol E85', isFuelConsumptionable: true },
  '00100013': {
    DEDE: 'Benzin/Elektro',
    GBBR: 'Petrol/Electric',
    isElectricConsumptionable: true,
    isFuelConsumptionable: true,
  },
  '00100014': { GBBR: 'Diesel/Electric', isElectricConsumptionable: true, isFuelConsumptionable: true },
  '00100015': { GBBR: 'LPG', isFuelConsumptionable: true },
  '00100017': { DEDE: 'Benzin/Gas/Alkohol', GBBR: 'Petrol/LPG/Alcohol', isFuelConsumptionable: true },
  '00100022': {
    DEDE: 'Diesel/Elektro',
    GBBR: 'Diesel/Electric',
    isElectricConsumptionable: true,
    isFuelConsumptionable: true,
  },
  '00100023': {
    DEDE: 'Benzin/Elektro-PlugIn',
    GBBR: 'Petrol/Electric - PlugIn',
    isElectricConsumptionable: true,
    isFuelConsumptionable: true,
  },
  '00100024': {
    DEDE: 'Diesel/Elektro-PlugIn',
    GBBR: 'Diesel/Electric - PlugIn',
    isElectricConsumptionable: true,
    isFuelConsumptionable: true,
  },
  '00100099': { GBBR: 'Petrol', isFuelConsumptionable: true },
  '00109999': { DEDE: 'keine Zuordnung', GBBR: 'no link (0010)' },
};

export const isConsumingFuel = (fuelType: string) => {
  return fuelTypesFromSorc[fuelType] && fuelTypesFromSorc[fuelType].isFuelConsumptionable;
};

export const isConsumingOnlyFuel = (fuelType: string) => {
  return isConsumingFuel(fuelType) && !fuelTypesFromSorc[fuelType].isElectricConsumptionable;
};

export const isConsumingElectric = (fuelType: string) => {
  return fuelTypesFromSorc[fuelType] && fuelTypesFromSorc[fuelType].isElectricConsumptionable;
};

export const isConsumingOnlyElectric = (fuelType: string) => {
  return isConsumingElectric(fuelType) && !fuelTypesFromSorc[fuelType].isFuelConsumptionable;
};

// Code for generate json file base on array of result for markets

function getFuelTypeBaseOMarketTypes(arraysOfMarketsFuelTypesResponse) {
  var fuelTypes = {};
  arraysOfMarketsFuelTypesResponse.forEach(market => {
    market.results.forEach(fuelType => {
      fuelTypes[fuelType.code] = { ...fuelTypes[fuelType.code], ...fuelType.texts };
    });
  });

  return fuelTypes;
}
