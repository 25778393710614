import { BusinessConditionAdjustmentsDto } from '@vpfa/rest-api/valuation';
import { produce } from 'immer';
import { AdjustmentsAction, AdjustmentsActionTypes } from './adjustments.actions';
import { CasesAction } from '../cases.actions';
import { ValuationAdjustmentType } from '@vpfa/shared/interfaces';

export interface ConditionAdjustmentsState {
  businessSpecification: BusinessConditionAdjustmentsDto;
  isBusinessSpecificationLoading: boolean;
  isUpdatingAdjustments: boolean;
  conditionAdjustmentsErrors: { [key: string]: boolean };
  adjustmentsInProgress: number;
}

export const ConditionAdjustmentsInitialState: ConditionAdjustmentsState = {
  businessSpecification: null,
  isBusinessSpecificationLoading: false,
  isUpdatingAdjustments: false,
  conditionAdjustmentsErrors: {},
  adjustmentsInProgress: 0,
};
export function conditionAdjustmentsReducer(
  state: ConditionAdjustmentsState = ConditionAdjustmentsInitialState,
  action: CasesAction | AdjustmentsAction
): ConditionAdjustmentsState {
  switch (action.type) {
    case AdjustmentsActionTypes.LoadBusinessSpecification:
      return produce<ConditionAdjustmentsState>(state, newState => {
        newState.isBusinessSpecificationLoading = true;
      });

    case AdjustmentsActionTypes.BusinessSpecificationLoadError:
      return produce<ConditionAdjustmentsState>(state, newState => {
        newState.isBusinessSpecificationLoading = false;
      });

    case AdjustmentsActionTypes.BusinessSpecificationLoadCompleted:
      return produce<ConditionAdjustmentsState>(state, newState => {
        newState.businessSpecification = action.payload;
        newState.isBusinessSpecificationLoading = false;
      });

    case AdjustmentsActionTypes.CaseUpdateNumberOfOwnersAdjustment:
      return produce<ConditionAdjustmentsState>(state, newState => {
        newState.conditionAdjustmentsErrors[ValuationAdjustmentType.NumberOfOwnersAdjustment] = false;
      });

    case AdjustmentsActionTypes.CaseUpdateSpecialUsageAdjustment:
      return produce<ConditionAdjustmentsState>(state, newState => {
        newState.conditionAdjustmentsErrors[ValuationAdjustmentType.SpecialUsageAdjustment] = false;
      });

    case AdjustmentsActionTypes.CaseUpdateSpecialColorAdjustment:
      return produce<ConditionAdjustmentsState>(state, newState => {
        newState.conditionAdjustmentsErrors[ValuationAdjustmentType.SpecialColorAdjustment] = false;
      });

    case AdjustmentsActionTypes.CaseUpdateAppreciationDepreciationAdjustment:
      return produce<ConditionAdjustmentsState>(state, newState => {
        newState.conditionAdjustmentsErrors[ValuationAdjustmentType.AppreciationDepreciationAdjustment] = false;
      });

    case AdjustmentsActionTypes.CaseUpdateMarketSituationAdjustment:
      return produce<ConditionAdjustmentsState>(state, newState => {
        newState.conditionAdjustmentsErrors[ValuationAdjustmentType.MarketSituationAdjustment] = false;
      });

    case AdjustmentsActionTypes.CaseUpdateTechnicalInspectionAdjustment:
      return produce<ConditionAdjustmentsState>(state, newState => {
        newState.conditionAdjustmentsErrors[ValuationAdjustmentType.TechnicalInspectionAdjustment] = false;
      });

    case AdjustmentsActionTypes.CaseUpdateAdjustmentError:
      return produce<ConditionAdjustmentsState>(state, newState => {
        if (action.payload !== null) newState.conditionAdjustmentsErrors[action.payload] = true;
      });

    case AdjustmentsActionTypes.CaseAdjustmentStarted: {
      return produce<ConditionAdjustmentsState>(state, newState => {
        newState.adjustmentsInProgress++;
        newState.isUpdatingAdjustments = true;
      });
    }

    case AdjustmentsActionTypes.CaseAdjustmentComplete: {
      return produce<ConditionAdjustmentsState>(state, newState => {
        newState.adjustmentsInProgress--;
      });
    }

    case AdjustmentsActionTypes.CaseAdjustmentFinished: {
      return produce<ConditionAdjustmentsState>(state, newState => {
        newState.isUpdatingAdjustments = false;
      });
    }
  }

  return state;
}
