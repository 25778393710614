<vpfa-section-loader [isLoading]="isLoading">
  <div
    class="search-results"
    infiniteScroll
    [fromRoot]="true"
    [infiniteScrollContainer]="'.app-container'"
    [infiniteScrollThrottle]="throttle"
    (scrolled)="onScroll()"
  >
    <div *ngFor="let element of elements">
      <ng-container class="element" *ngTemplateOutlet="elementTemplate; context: { $implicit: element }"></ng-container>
    </div>
  </div>
</vpfa-section-loader>

<vpfa-empty *ngIf="!elements?.length && displayEmptyIfNoData"></vpfa-empty>
