import { ActivatedRouteSnapshot } from '@angular/router';
import { isNil } from 'lodash';

/**
 * if `paramName` is provided only this one parameter will be checked for existence.
 * if `paramName` is __not__ provided, it will check if any query params exists.
 */
export const hasUrlQueryParams = (route: ActivatedRouteSnapshot, paramName?: string) => {
  if (!isNil(paramName)) {
    return !isNil(route.queryParamMap.get(paramName));
  } else {
    return route.queryParamMap.keys?.length > 0;
  }
};
