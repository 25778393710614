<div class="no-hor-scroll">
  <h3 class="header">{{ 'fullTechSpecAndEquipmentListModal.vehicleDetails' | translate | uppercase }}</h3>
  <h3 class="edition">{{ vehicleTechSpecData?.edition }}</h3>
  <div class="d-flex flex-column">
    <button
      class="flex-width-100"
      nzType="button"
      nz-button
      (click)="changeTab(tab.technicalData)"
      [ngClass]="activeTab === tab.technicalData ? 'activeButton' : 'inactiveButton'"
    >
      {{ 'vehicleFullDataModal.technicalData' | translate }}
    </button>
    <button
      class="flex-width-100"
      nzType="button"
      nz-button
      (click)="changeTab(tab.packageEquipment)"
      [ngClass]="activeTab === tab.packageEquipment ? 'activeButton' : 'inactiveButton'"
    >
      {{ 'vehicleFullDataModal.packageEquipment' | translate }}
    </button>
    <button
      class="flex-width-100"
      nzType="button"
      nz-button
      (click)="changeTab(tab.optionalEquipment)"
      [ngClass]="activeTab === tab.optionalEquipment ? 'activeButton' : 'inactiveButton'"
    >
      {{ 'vehicleFullDataModal.optionalEquipment' | translate }}
    </button>
    <button
      class="flex-width-100"
      nzType="button"
      nz-button
      (click)="changeTab(tab.standardEquipment)"
      [ngClass]="activeTab === tab.standardEquipment ? 'activeButton' : 'inactiveButton'"
    >
      {{ 'vehicleFullDataModal.standardEquipment' | translate }}
    </button>
    <button
      class="flex-width-100"
      nzType="button"
      nz-button
      (click)="changeTab(tab.unavailableEquipment)"
      [ngClass]="activeTab === tab.unavailableEquipment ? 'activeButton' : 'inactiveButton'"
    >
      {{ 'vehicleFullDataModal.unavailableEquipment' | translate }}
    </button>
  </div>
  <vpfa-vehicle-full-technical-data
    *ngIf="activeTab === tab.technicalData"
    (closeAction)="onClose()"
    [isMobile]="true"
    [showFooter]="false"
    [vehicleData]="vehicleTechSpecData"
    [technicalDataFeatureConfig]="technicalDataFeatureConfig"
    [emissionClassOptions]="emissionClassOptions"
  >
  </vpfa-vehicle-full-technical-data>
  <vpfa-mobile-equipment-list-item
    *ngIf="packageEquipmentList && activeTab === tab.packageEquipment"
    [equipmentData]="packageEquipmentList"
  >
  </vpfa-mobile-equipment-list-item>
  <vpfa-mobile-equipment-list-item
    *ngIf="optionalEquipmentList && activeTab === tab.optionalEquipment"
    [equipmentData]="optionalEquipmentList"
  >
  </vpfa-mobile-equipment-list-item>
  <vpfa-mobile-equipment-list-item
    *ngIf="standardEquipmentList && activeTab === tab.standardEquipment"
    [equipmentData]="standardEquipmentList"
  >
  </vpfa-mobile-equipment-list-item>
  <vpfa-mobile-equipment-list-item
    *ngIf="unavailableEquipmentList && activeTab === tab.unavailableEquipment"
    [equipmentData]="unavailableEquipmentList"
  >
  </vpfa-mobile-equipment-list-item>
</div>
