<ng-container *vpfaResponsiveIf="'mobile'">
  <vpfa-mobile-subtitle
    [title]="'vehicleFullTechnicalData.vehicleInformation.label' | translate"
  ></vpfa-mobile-subtitle>
</ng-container>
<ng-container *vpfaResponsiveIf="'web'">
  <vpfa-expanding-header
    [text]="'vehicleFullTechnicalData.vehicleInformation.label' | translate"
  ></vpfa-expanding-header>
</ng-container>

<div
  [fxLayout]="'row wrap'"
  fxLayout.lt-mv="column"
  fxLayoutGap.gt-mv="70px grid"
  fxLayoutGap.lt-mv="10px"
  ngClass.gt-mv="section"
>
  <vpfa-vehicle-technical-data-single-field
    [label]="'vehicleFullTechnicalData.vehicleInformation.listPrice' | translate"
    [value]="listPrice | currencyLocale: null:null:'1.2-2'"
  ></vpfa-vehicle-technical-data-single-field>
  <vpfa-vehicle-technical-data-single-field
    *ngIf="featureConfig?.showNovaTax"
    [label]="'vehicleFullTechnicalData.vehicleInformation.novaTax' | translate"
    [value]="firstPrice?.novaTax | percentValue"
  ></vpfa-vehicle-technical-data-single-field>
  <vpfa-vehicle-technical-data-single-field
    *ngIf="featureConfig?.showNovaTax"
    [label]="'vehicleFullTechnicalData.vehicleInformation.novaTaxGas' | translate"
    [value]="firstPrice?.novaTaxGas | percentValue"
  ></vpfa-vehicle-technical-data-single-field>
  <vpfa-vehicle-technical-data-single-field
    [label]="'vehicleFullTechnicalData.vehicleInformation.nationalCode' | translate"
    [value]="technicalData?.nationalCode"
  ></vpfa-vehicle-technical-data-single-field>
  <vpfa-vehicle-technical-data-single-field
    [label]="'vehicleFullTechnicalData.vehicleInformation.countryMarket' | translate"
    [value]="deliveryCountry"
  ></vpfa-vehicle-technical-data-single-field>
  <vpfa-vehicle-technical-data-single-field
    [label]="'vehicleFullTechnicalData.vehicleInformation.productionPeriod' | translate"
    [value]="
      [technicalData?.prodbegin, technicalData?.prodend]
        | dateRange: ('vehicleFullTechnicalData.vehicleInformation.upToNow' | translate)
    "
  ></vpfa-vehicle-technical-data-single-field>
</div>
