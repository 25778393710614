/**
 * Vehicle Valuation Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { CommandHandlerResponse } from '../model/commandHandlerResponse';
import { CreateCustomerCommand } from '../model/createCustomerCommand';
import { ImportCustomersCommandHandlerResponse } from '../model/importCustomersCommandHandlerResponse';
import { UpdateCustomerCommand } from '../model/updateCustomerCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CustomerService {

    protected basePath = 'https://localhost';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * AmsAction:POST/api/Customer AmsResource:{country}/{business}/customer/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCustomer(body?: CreateCustomerCommand, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public createCustomer(body?: CreateCustomerCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public createCustomer(body?: CreateCustomerCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public createCustomer(body?: CreateCustomerCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommandHandlerResponse>(`${this.basePath}/api/Customer`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:DELETE/api/Customer/{customerId} AmsResource:{country}/{business}/customer/{customerId} 
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCustomer(customerId: string, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public deleteCustomer(customerId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public deleteCustomer(customerId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public deleteCustomer(customerId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling deleteCustomer.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<CommandHandlerResponse>(`${this.basePath}/api/Customer/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:POST/api/Customer/Import AmsResource:{country}/{business}/customer/_* 
     * @param businessId 
     * @param branchId 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importCustomers(businessId?: string, branchId?: string, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<ImportCustomersCommandHandlerResponse>;
    public importCustomers(businessId?: string, branchId?: string, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ImportCustomersCommandHandlerResponse>>;
    public importCustomers(businessId?: string, branchId?: string, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ImportCustomersCommandHandlerResponse>>;
    public importCustomers(businessId?: string, branchId?: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any) };
        let useForm = true;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (businessId !== undefined) {
            formParams = formParams.append('BusinessId', <any>businessId) || formParams;
        }
        if (branchId !== undefined) {
            formParams = formParams.append('BranchId', <any>branchId) || formParams;
        }
        if (file !== undefined) {
            formParams = formParams.append('File', <any>file) || formParams;
        }

        return this.httpClient.post<ImportCustomersCommandHandlerResponse>(`${this.basePath}/api/Customer/Import`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/Customer AmsResource:{country}/{business}/customer/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCustomer(body?: UpdateCustomerCommand, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateCustomer(body?: UpdateCustomerCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateCustomer(body?: UpdateCustomerCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateCustomer(body?: UpdateCustomerCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/Customer`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
