import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromOAuth from './+state/oauth.reducer';
import { EffectsModule } from '@ngrx/effects';
import { OAuthEffects } from './+state/oauth.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromOAuth.OAUTH_FEATURE_KEY, fromOAuth.reducer),
    EffectsModule.forFeature([OAuthEffects])
  ]
})
export class OAuthDataModule {}
