import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiKitSectionContainerModule } from '@vpfa/ui-kit/section-container';
import { CaseResolverService } from './resolvers/case-resolver.service';
import { BaseVehicleInformationComponent } from './components/base-vehicle-information/base-vehicle-information.component';
import { VehicleFullDataModalComponent } from './components/vehicle-full-data-modal/vehicle-full-data-modal.component';
import { VehicleOverviewDataComponent } from './components/vehicle-overview-data/vehicle-overview-data.component';
import { VehicleRetailMarketStatsComponentWeb } from './components/vehicle-retail-market-stats/vehicle-retail-market-stats-web/vehicle-retail-market-stats-component-web.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzTableModule } from 'ng-zorro-antd/table';
import { UiKitModule } from '@vpfa/ui-kit';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedVehicleModule } from '@vpfa/dealer/shared/vehicle';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SharedPipesModule } from '@vpfa/shared/pipes';
import { RepairAndMaintenanceModalComponent } from './components/repair-and-maintenance/repair-and-maintenance-modal/repair-and-maintenance-modal.component';
import { WebRepairAndMaintenanceSummaryComponent } from './components/repair-and-maintenance/web-repair-and-maintenance-summary/web-repair-and-maintenance-summary.component';
import { RepairAndMaintenanceContainerComponent } from './components/repair-and-maintenance/repair-and-maintenance-container/repair-and-maintenance-container.component';
import { EstimatorTableComponent } from './components/estimator-table/estimator-table.component';
import { EstimatorAreaComponent } from './components/estimator-area/estimator-area.component';
import { EstimatorSubareaComponent } from './components/estimator-subarea/estimator-subarea.component';
import { EstimatorPartComponent } from './components/estimator-part/estimator-part.component';
import { UiKitSwitchModule } from '@vpfa/ui-kit/switch';
import { CaseResolverWithStatusGuard } from './resolvers/case-resolver-with-status-guard.service';
import { CaseBreadcrumbHelperComponent } from './case-breadcrumb-helper/case-breadcrumb-helper.component';
import { ValuationUniqueDataModalComponent } from './components/unique-data-valuation-modal/unique-data-valuation-modal.component';
import { UniqueDataStockComponent } from './components/unique-data-stock/unique-data-stock.component';
import { UniqueDataValuation } from './components/unique-data-valuation/unique-data-valuation.component';
import { UniqueDataStockModalComponent } from './components/unique-data-stock-modal/unique-data-stock-modal.component';
import { UniqueDataSoldComponent } from './components/unique-data-sold/unique-data-sold.component';
import { EquipmentSectionWebComponent } from './containers/equipment-section/equipment-section-web/equipment-section-web.component';
import { UiKitArrowLinkModule } from '@vpfa/ui-kit/arrow-link';
import { MissingEquipmentAdjustmentComponent } from './components/missing-equipment-adjustment/missing-equipment-adjustment.component';
import { UiKitPriceWithIconModule } from '@vpfa/ui-kit/price-with-icon';
import { NotAvailableInfoBoxComponent } from './components/not-available-info-box/not-available-info-box.component';
import { UiKitModalsModule } from '@vpfa/ui-kit/modals';

import { PriceHistoryModalComponent } from './components/price-history-modal/price-history-modal.component';
import { PriceTaxComponent } from './components/price-tax/price-tax.component';
import { PriceHistoryListComponent } from './components/price-history-list/price-history-list.component';
import { ForecastInfoBoxComponent } from './components/forecast-info-box/forecast-info-box.component';
import { VehicleRetailMarketStatsMobileComponent } from './components/vehicle-retail-market-stats/vehicle-retail-market-stats-mobile/vehicle-retail-market-stats-mobile.component';
import { VehicleRetailMarketStatsComponent } from './components/vehicle-retail-market-stats/vehicle-retail-market-stats';
import { SharedMobileViewModule } from '@vpfa/shared/mobile-view';
import { SharedDirectivesModule } from '@vpfa/shared/directives';
import { CaseUrlService } from './services/case-url.service';
import { MobileVehicleOverviewDataComponent } from './components/vehicle-overview-data/mobile-vehicle-overview-data/mobile-vehicle-overview-data.component';
import { PriceAdjustmentsContainerMobileComponent } from './components/price-adjustments-container/price-adjustments-container-mobile/price-adjustments-container-mobile.component';
import { UniqueDataValuationSelectComponent } from './components/unique-data-valuation/unique-data-valuation-select/unique-data-valuation-select.component';
import { UniqueDataValuationTextFieldComponent } from './components/unique-data-valuation/unique-data-valuation-text-field/unique-data-valuation-text-field.component';
import { UniqueDataValuationMonthPickerComponent } from './components/unique-data-valuation/unique-data-valuation-month-picker/unique-data-valuation-month-picker.component';

import { MobileRepairAndMaintenanceSummaryComponent } from './components/repair-and-maintenance/mobile-repair-and-maintenance-summary/mobile-repair-and-maintenance-summary.component';
import { VehicleFuelSubtypeComponent } from './components/vehicle-fuel-subtype/vehicle-fuel-subtype.component';
import { VehicleEmissionInfoComponent } from './components/vehicle-emission-info/vehicle-emission-info.component';
import { NotesComponent } from './components/notes/notes/notes.component';
import { UiKitFormControlsModule } from '@vpfa/ui-kit/form-controls';
import { MobileNotesComponent } from './components/notes/mobile-notes/mobile-notes.component';
import { MobileEditMileageComponent } from './components/vehicle-overview-data/mobile-edit-mileage/mobile-edit-mileage.component';
import { UiKitUikitValidationModule } from '@vpfa/ui-kit/uikit-validation';
import { PricingDetailsModalComponent } from './components/pricing-details-modal/pricing-details-modal.component';
import { ReturnReportComponent } from './containers/return-report/return-report.component';
import { ReturnReportModalComponent } from './components/return-report-modal/return-report-modal.component';
import { ReturnReportFormComponent } from './components/return-report-form/return-report-form.component';
import { MobileBackComponent } from './components/mobile-back/mobile-back.component';
import { UiKitMobileModule } from '@vpfa/ui-kit-mobile';
import { StockPricesWebComponent } from './containers/stock-prices/stock-prices-web/stock-prices-web.component';
import { StockPricesMobileComponent } from './containers/stock-prices/stock-prices-mobile/stock-prices-mobile.component';
import { MobileLightDamageEstimatorComponent } from './components/repair-and-maintenance/mobile-light-damage-estimator/mobile-light-damage-estimator.component';
import { UiKitSettingBoxModule } from '@vpfa/ui-kit/setting-box';
import { EquipmentSectionMobileComponent } from './containers/equipment-section/equipment-section-mobile/equipment-section-mobile.component';
import { SharedLoadersModule } from '@vpfa/shared/loaders';
import { ProvenanceCheckSectionKeepersComponent } from './components/provenance-check-section/provenance-check-section-keepers/provenance-check-section-keepers.component';
import { ProvenanceCheckSectionMileageComponent } from './components/provenance-check-section/provenance-check-section-mileage/provenance-check-section-mileage.component';
import { ProvenanceCheckSectionEnvironmentalComponent } from './components/provenance-check-section/provenance-check-section-environmental/provenance-check-section-environmental.component';
import { WebProvenanceCheckSectionComponent } from './components/provenance-check-section/web-provenance-check-section/web-provenance-check-section.component';
import { MobileProvenanceCheckSectionComponent } from './components/provenance-check-section/mobile-provenance-check-section/mobile-provenance-check-section.component';
import { RightArrowBoxComponent } from './components/provenance-check-section/provenance-check-section-environmental/right-arrow-box/right-arrow-box.component';
import { EmissionClassRowComponent } from './components/provenance-check-section/provenance-check-section-environmental/emission-class-row/emission-class-row.component';
import { LeftArrowBoxComponent } from './components/provenance-check-section/provenance-check-section-environmental/left-arrow-box/left-arrow-box.component';
import { UiKitSkewLineModule } from '@vpfa/ui-kit/skew-line';
import { WebProvenanceCheckSectionConfirmationStatusComponent } from './components/provenance-check-section/web-provenance-check-section-confirmation-status/web-provenance-check-section-confirmation-status.component';
import { MobileProvenanceCheckSectionConfirmationStatusComponent } from './components/provenance-check-section/mobile-provenance-check-section-confirmation-status/mobile-provenance-check-section-confirmation-status.component';
import { UtilsModule } from '@vpfa/utils';
import { MarketBadgeComponent } from './components/market-badge/market-badge.component';
import { ResellerResponsesLinkComponent } from './components/reseller-responses-link/reseller-responses-link.component';
import { PriceFieldWithTaxSectionComponent } from './components/price-field-with-tax-section/price-field-with-tax-section.component';
import { ModalsModule } from '@vpfa/modals';
import { UniqueDataTaxComponent } from './components/unique-data-tax/unique-data-tax.component';
import { UiKitIsoDatePickerModule } from '@vpfa/ui-kit/iso-date-picker';
import { MobileProvenanceCheckSectionKeepersComponent } from './components/provenance-check-section/mobile-provenance-check-section-keepers/mobile-provenance-check-section-keepers.component';
import { MobileProvenanceCheckSectionMileageComponent } from './components/provenance-check-section/mobile-provenance-check-section-mileage/mobile-provenance-check-section-mileage.component';
import { UiKitRequiredSymbolModule } from '@vpfa/ui-kit/required-symbol';
import { PricingDetailsStockComponent } from './components/pricing-details-stock/pricing-details-stock.component';
import { PricingDetailsSoldComponent } from './components/pricing-details-sold/pricing-details-sold.component';
import { PricingDetailsValuationComponent } from './components/pricing-details-valuation/pricing-details-valuation.component';
import { UniqueDataValuationSwitchComponent } from './components/unique-data-valuation/unique-data-valuation-switch/unique-data-valuation-switch.component';
import { BlackBoxPriceDetailsComponent } from './containers/black-box-price-details/black-box-price-details.component';
import { BlackBoxPriceDetailsMobileComponent } from './components/black-box-price-details/black-box-price-details-mobile/black-box-price-details-mobile.component';
import { BlackBoxPriceDetailsWebComponent } from './components/black-box-price-details/black-box-price-details-web/black-box-price-details-web.component';
import { ValuationInfoComponent } from './components/valuation-info/valuation-info.component';
import { MobilePriceButtonContentComponent } from './components/mobile-price-button-content/mobile-price-button-content.component';
import { UniqueDataValuationTextFieldWithDropdownComponent } from './components/unique-data-valuation/unique-data-valuation-text-field-with-dropdown/unique-data-valuation-text-field-with-dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    NzIconModule,
    NzPopconfirmModule,
    NzSelectModule,
    NzInputModule,
    NzDividerModule,
    NzButtonModule,
    NzDropDownModule,
    UiKitModule,
    FormsModule,
    SharedVehicleModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    SharedPipesModule,
    UiKitSectionContainerModule,
    UiKitSwitchModule,
    UiKitArrowLinkModule,
    UiKitPriceWithIconModule,
    UiKitModalsModule,
    UiKitSettingBoxModule,
    NzSpinModule,
    SharedMobileViewModule,
    SharedDirectivesModule,
    UiKitFormControlsModule,
    UiKitUikitValidationModule,
    UiKitMobileModule,
    NzToolTipModule,
    SharedLoadersModule,
    NzTableModule,
    UiKitSkewLineModule,
    UtilsModule,
    ModalsModule,
    UiKitIsoDatePickerModule,
    UiKitRequiredSymbolModule,
  ],
  providers: [CaseResolverService, CaseResolverWithStatusGuard, CaseUrlService],
  declarations: [
    BaseVehicleInformationComponent,
    VehicleFullDataModalComponent,
    VehicleOverviewDataComponent,
    VehicleRetailMarketStatsComponentWeb,
    RepairAndMaintenanceModalComponent,
    WebRepairAndMaintenanceSummaryComponent,
    RepairAndMaintenanceContainerComponent,
    EstimatorTableComponent,
    EstimatorAreaComponent,
    EstimatorSubareaComponent,
    EstimatorPartComponent,
    CaseBreadcrumbHelperComponent,
    ValuationUniqueDataModalComponent,
    UniqueDataStockComponent,
    UniqueDataValuation,
    UniqueDataStockModalComponent,
    UniqueDataSoldComponent,
    EquipmentSectionWebComponent,
    MissingEquipmentAdjustmentComponent,
    NotAvailableInfoBoxComponent,
    PriceHistoryModalComponent,
    PriceTaxComponent,
    PriceHistoryListComponent,
    ForecastInfoBoxComponent,
    VehicleRetailMarketStatsMobileComponent,
    VehicleRetailMarketStatsComponent,
    MobileVehicleOverviewDataComponent,
    PriceAdjustmentsContainerMobileComponent,
    UniqueDataValuationSelectComponent,
    UniqueDataValuationTextFieldComponent,
    UniqueDataValuationMonthPickerComponent,
    MobileRepairAndMaintenanceSummaryComponent,
    VehicleFuelSubtypeComponent,
    VehicleEmissionInfoComponent,
    NotesComponent,
    MobileNotesComponent,
    MobileEditMileageComponent,
    PricingDetailsModalComponent,
    ReturnReportComponent,
    ReturnReportModalComponent,
    ReturnReportFormComponent,
    MobileBackComponent,
    StockPricesWebComponent,
    StockPricesMobileComponent,
    MobileLightDamageEstimatorComponent,
    EquipmentSectionMobileComponent,
    ProvenanceCheckSectionKeepersComponent,
    ProvenanceCheckSectionMileageComponent,
    ProvenanceCheckSectionEnvironmentalComponent,
    WebProvenanceCheckSectionComponent,
    MobileProvenanceCheckSectionComponent,
    WebProvenanceCheckSectionConfirmationStatusComponent,
    MobileProvenanceCheckSectionConfirmationStatusComponent,
    RightArrowBoxComponent,
    EmissionClassRowComponent,
    LeftArrowBoxComponent,
    MarketBadgeComponent,
    ResellerResponsesLinkComponent,
    PriceFieldWithTaxSectionComponent,
    UniqueDataTaxComponent,
    MobileProvenanceCheckSectionKeepersComponent,
    MobileProvenanceCheckSectionMileageComponent,
    PricingDetailsStockComponent,
    PricingDetailsSoldComponent,
    PricingDetailsValuationComponent,
    UniqueDataValuationSwitchComponent,
    BlackBoxPriceDetailsComponent,
    BlackBoxPriceDetailsWebComponent,
    BlackBoxPriceDetailsMobileComponent,
    ValuationInfoComponent,
    MobilePriceButtonContentComponent,
    UniqueDataValuationTextFieldWithDropdownComponent,
  ],
  exports: [
    BaseVehicleInformationComponent,
    VehicleFullDataModalComponent,
    VehicleOverviewDataComponent,
    VehicleRetailMarketStatsComponentWeb,
    WebRepairAndMaintenanceSummaryComponent,
    RepairAndMaintenanceContainerComponent,
    CaseBreadcrumbHelperComponent,
    ValuationUniqueDataModalComponent,
    UniqueDataStockComponent,
    UniqueDataValuation,
    UniqueDataStockModalComponent,
    UniqueDataTaxComponent,
    EquipmentSectionWebComponent,
    MissingEquipmentAdjustmentComponent,
    NotAvailableInfoBoxComponent,
    PriceHistoryModalComponent,
    PriceTaxComponent,
    PriceHistoryListComponent,
    ForecastInfoBoxComponent,
    VehicleRetailMarketStatsMobileComponent,
    MobileVehicleOverviewDataComponent,
    PriceAdjustmentsContainerMobileComponent,
    MobileRepairAndMaintenanceSummaryComponent,
    NotesComponent,
    MobileNotesComponent,
    MobileEditMileageComponent,
    ReturnReportComponent,
    MobileBackComponent,
    UniqueDataSoldComponent,
    StockPricesWebComponent,
    StockPricesMobileComponent,
    EquipmentSectionMobileComponent,
    WebProvenanceCheckSectionComponent,
    MobileProvenanceCheckSectionComponent,
    MarketBadgeComponent,
    ResellerResponsesLinkComponent,
    BlackBoxPriceDetailsComponent,
    ValuationInfoComponent,
    MobilePriceButtonContentComponent,
  ],
  // entryComponents: [MobileProvenanceCheckSectionComponent],
})
export class DealerCaseSharedModule {}
