import { SelectOption } from '@vpfa/ui-kit';

export enum OptionalSearchFiltersKeys {
  numDoors = 'numDoors',
  transmissionType = 'transmissionType',
  bodyType = 'bodyType',
  fuelType = 'fuelType',
  edition = 'edition',
  engineCapacity = 'engineCapacity',
  wheelbase = 'wheelbase',
  grossWeight = 'grossWeight',
}

/**
 * Object obtained from SorC
 */
export interface OptionalSearchFilters {
  [OptionalSearchFiltersKeys.numDoors]: number[];
  [OptionalSearchFiltersKeys.transmissionType]: string[];
  [OptionalSearchFiltersKeys.bodyType]: string[];
  [OptionalSearchFiltersKeys.fuelType]: string[];
  [OptionalSearchFiltersKeys.edition]: string[];
  [OptionalSearchFiltersKeys.engineCapacity]: number[];
  [OptionalSearchFiltersKeys.wheelbase]: number[];
  [OptionalSearchFiltersKeys.grossWeight]: number[];
}

/**
 * Object mapped from OptionalSearchFilters to be used in dropdowns
 */
export interface OptionalSearchFiltersOptions {
  [OptionalSearchFiltersKeys.numDoors]: SelectOption[];
  [OptionalSearchFiltersKeys.transmissionType]: SelectOption[];
  [OptionalSearchFiltersKeys.bodyType]: SelectOption[];
  [OptionalSearchFiltersKeys.fuelType]: SelectOption[];
  [OptionalSearchFiltersKeys.edition]: SelectOption[];
  [OptionalSearchFiltersKeys.engineCapacity]: SelectOption[];
  [OptionalSearchFiltersKeys.wheelbase]: SelectOption[];
  [OptionalSearchFiltersKeys.grossWeight]: SelectOption[];
}

/**
 * Object containing filters selected by user
 */
export interface SelectedOptionalSearchFilters {
  [OptionalSearchFiltersKeys.numDoors]: number;
  [OptionalSearchFiltersKeys.transmissionType]: string;
  [OptionalSearchFiltersKeys.bodyType]: string;
  [OptionalSearchFiltersKeys.fuelType]: string;
  [OptionalSearchFiltersKeys.edition]: string;
  [OptionalSearchFiltersKeys.engineCapacity]: number;
  [OptionalSearchFiltersKeys.wheelbase]: number;
  [OptionalSearchFiltersKeys.grossWeight]: number;
}
