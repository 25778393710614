import { getCurrencySymbol } from '@angular/common';
import { Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { LocaleFacade } from '@vpfa/locale';
import { Observable, combineLatest } from 'rxjs';
import { map, tap, switchMap } from 'rxjs/operators';
import { CountriesFacade } from '@vpfa/admin/countries/data';
import { ProfileFacade } from '@vpfa/profile/data';

interface Currency {
  text: string;
  symbol: string;
}

export enum CURRENCY_DISPLAY_FORMAT {
  CODE = 'CODE',
  SYMBOL = 'SYMBOL',
}
@Component({
  selector: 'vpfa-active-currency',
  templateUrl: './active-currency.component.html',
  styleUrls: ['./active-currency.component.scss'],
})
export class ActiveCurrencyComponent {
  CURRENCY_DISPLAY_FORMAT = CURRENCY_DISPLAY_FORMAT;

  @Input() currencyDisplayFormat: CURRENCY_DISPLAY_FORMAT;
  @Input() inputHintMode: boolean;
  @Output() activeCurrencyMode = new EventEmitter<CURRENCY_DISPLAY_FORMAT>();

  activeCurrency$: Observable<Currency> = combineLatest([
    this.profileFacade.isCountryAdmin$,
    this.profileFacade.isSystemAdmin$,
  ]).pipe(
    switchMap(([isCountryAdmin, isSystemAdmin]) => {
      const activeCurrency$: Observable<string> =
        isCountryAdmin || isSystemAdmin ? this.countriesFacade.countryCurrency$ : this.localeFacade.currency$;
      return activeCurrency$.pipe(
        map(currencyCode => {
          return {
            symbol: getCurrencySymbol(currencyCode, 'narrow'),

            text: currencyCode,
          };
        }),
        tap(x => {
          if (this.currencyDisplayFormat === CURRENCY_DISPLAY_FORMAT.SYMBOL && x.symbol !== x.text) {
            this.activeCurrencyMode.emit(CURRENCY_DISPLAY_FORMAT.SYMBOL);
          } else {
            this.activeCurrencyMode.emit(CURRENCY_DISPLAY_FORMAT.CODE);
          }
        })
      );
    })
  );

  constructor(
    private localeFacade: LocaleFacade,
    private profileFacade: ProfileFacade,
    @Optional() private countriesFacade: CountriesFacade
  ) {}
}
