import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuffixPrefixValueComponent } from './components/suffix-prefix-value/suffix-prefix-value.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedDirectivesModule } from '@vpfa/shared/directives';
import { UiKitModule } from '@vpfa/ui-kit';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  imports: [CommonModule, SharedDirectivesModule, ReactiveFormsModule, FormsModule, NzFormModule, NzInputModule, UiKitModule, TranslateModule],
  declarations: [SuffixPrefixValueComponent],
  exports: [SuffixPrefixValueComponent]
})
export class UiKitSuffixPrefixValueModule {}
