import { Injectable } from '@angular/core';

import { filter, take, switchMap, map } from 'rxjs/operators';
import { PermissionsFacade } from '../+state/permissions.facade';

@Injectable()
export class PermissionsGuard  {
  constructor(private permissionsFacade: PermissionsFacade) {}
  canActivate() {
    this.permissionsFacade.clearLoadedPermissions();
    this.permissionsFacade.loadPermissions();
    return this.permissionsFacade.loaded$.pipe(
      filter(loaded => loaded),
      switchMap(() => this.permissionsFacade.permissions$),
      take(1),
      map(permissions => {
        if (permissions) {
          return true;
        } else {
          return false;
        }
      })
    );
  }
}
