<ng-container *vpfaResponsiveIf="'mobile'">
  <vpfa-mobile-subtitle [title]="'vehicleFullTechnicalData.engineInformation.label' | translate"></vpfa-mobile-subtitle>
</ng-container>
<ng-container *vpfaResponsiveIf="'web'">
  <div class="mt">
    <vpfa-expanding-header
      [text]="'vehicleFullTechnicalData.engineInformation.label' | translate"
    ></vpfa-expanding-header>
  </div>
</ng-container>

<div
  [fxLayout]="'row wrap'"
  fxLayoutGap.gt-mv="70px grid"
  fxLayout.lt-mv="column"
  fxLayoutGap.lt-mv="10px"
  ngClass.gt-mv="section"
>
  <vpfa-vehicle-technical-data-single-field
    [label]="
      ('vehicleFullTechnicalData.engineInformation.power' | translate) +
      ([technicalData?.power?.peakPowerkW, technicalData?.power?.peakPowerHP] | unitValue: true)
    "
    [value]="[technicalData?.power?.peakPowerkW, technicalData?.power?.peakPowerHP] | getTwoValue | lT"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    [label]="'vehicleFullTechnicalData.engineInformation.drivetrain' | translate"
    [value]="technicalData?.drive?.type?.texts"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    [label]="'vehicleFullTechnicalData.engineInformation.transmissionType' | translate"
    [value]="technicalData?.transmission?.type?.texts"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    [label]="'vehicleFullTechnicalData.engineInformation.numberGears' | translate"
    [value]="technicalData?.transmission?.numberGears"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    [label]="'vehicleFullTechnicalData.engineInformation.fuelType' | translate"
    [value]="technicalData?.propulsion?.fuelType?.texts"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    [label]="'vehicleFullTechnicalData.engineInformation.engineType' | translate"
    [value]="technicalData?.propulsion?.engineType?.texts"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    [label]="
      ('vehicleFullTechnicalData.engineInformation.engineCapacity' | translate) +
      (technicalData?.propulsion?.engineCapacity | unitValue)
    "
    [value]="technicalData?.propulsion?.engineCapacity | engineCapacity"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    [label]="'vehicleFullTechnicalData.engineInformation.numberCylinders' | translate"
    [value]="technicalData?.propulsion?.numberCylinders | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    [label]="'vehicleFullTechnicalData.engineInformation.cylinderAssembly' | translate"
    [value]="technicalData?.propulsion?.cylinderArrangement?.texts"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    [label]="
      ('vehicleFullTechnicalData.engineInformation.maxSpeed' | translate) +
      (vehicleTechnicalPerformance?.maxSpeed | unitValue)
    "
    [value]="vehicleTechnicalPerformance?.maxSpeed?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    [label]="
      ('vehicleFullTechnicalData.engineInformation.acceleration100kph' | translate) +
      (vehicleTechnicalPerformance?.acceleration | unitValue)
    "
    [value]="vehicleTechnicalPerformance?.acceleration?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    [label]="'vehicleFullTechnicalData.engineInformation.catalyst' | translate"
    [value]="technicalData?.propulsion?.catalyst?.texts"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    [label]="
      ('vehicleFullTechnicalData.engineInformation.torque' | translate) +
      (technicalData?.propulsion?.torque | unitValue)
    "
    [value]="technicalData?.propulsion?.torque?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="
      technicalData?.propulsion?.hybrid?.electricEnginePowerHP?.value ||
      technicalData?.propulsion?.hybrid?.electricEnginePowerKW?.value
    "
    [label]="
      ('vehicleFullTechnicalData.engineInformation.electricPeakPower' | translate) +
      ([
        technicalData?.propulsion?.hybrid?.electricEnginePowerKW,
        technicalData?.propulsion?.hybrid?.electricEnginePowerHP
      ] | unitValue: true)
    "
    [value]="
      [
        technicalData?.propulsion?.hybrid?.electricEnginePowerKW,
        technicalData?.propulsion?.hybrid?.electricEnginePowerHP
      ] | getTwoValue
    "
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="
      technicalData?.propulsion?.electric?.peakPowerHP?.value || technicalData?.propulsion?.electric?.peakPowerKW?.value
    "
    [label]="
      ('vehicleFullTechnicalData.engineInformation.electricPower' | translate) +
      ([technicalData?.propulsion?.electric?.peakPowerKW, technicalData?.propulsion?.electric?.peakPowerHP] | unitValue)
    "
    [value]="
      [technicalData?.propulsion?.electric?.peakPowerKW, technicalData?.propulsion?.electric?.peakPowerHP] | getTwoValue
    "
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="technicalData?.propulsion?.evBattery?.powerOutputKWh?.value"
    [label]="
      ('vehicleFullTechnicalData.engineInformation.powerOutputKWh' | translate) +
      (technicalData?.propulsion?.evBattery?.powerOutputKWh | unitValue)
    "
    [value]="technicalData?.propulsion?.evBattery?.powerOutputKWh?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="technicalData?.propulsion?.evBattery?.voltage?.value"
    [label]="
      ('vehicleFullTechnicalData.engineInformation.voltage' | translate) +
      (technicalData?.propulsion?.evBattery?.voltage | unitValue)
    "
    [value]="technicalData?.propulsion?.evBattery?.voltage?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="technicalData?.propulsion?.evBattery?.batteryWarrantyMonths"
    [label]="'vehicleFullTechnicalData.engineInformation.batteryWarrantyMonths' | translate"
    [value]="technicalData?.propulsion?.evBattery?.batteryWarrantyMonths | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="technicalData?.propulsion?.evBattery?.weight?.value"
    [label]="
      ('vehicleFullTechnicalData.engineInformation.batteryWeight' | translate) +
      (technicalData?.propulsion?.evBattery?.weight | unitValue)
    "
    [value]="technicalData?.propulsion?.evBattery?.weight?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="technicalData?.propulsion?.evCharging?.timeHighVoltage80?.value"
    [label]="
      ('vehicleFullTechnicalData.engineInformation.timeHighVoltage80' | translate) +
      (technicalData?.propulsion?.evCharging?.timeHighVoltage80 | unitValue)
    "
    [value]="technicalData?.propulsion?.evCharging?.timeHighVoltage80?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="technicalData?.propulsion?.evBattery?.includedInNewPrice"
    [label]="'vehicleFullTechnicalData.engineInformation.includedInNewPrice' | translate"
    [value]="technicalData?.propulsion?.evBattery?.includedInNewPrice"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="technicalData?.propulsion?.hybrid?.numberElectricEngines"
    [label]="'vehicleFullTechnicalData.engineInformation.numberElectricEngines' | translate"
    [value]="technicalData?.propulsion?.hybrid?.numberElectricEngines | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="technicalData?.propulsion?.evBattery?.number"
    [label]="'vehicleFullTechnicalData.engineInformation.batterNumber' | translate"
    [value]="technicalData?.propulsion?.evBattery?.number | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="technicalData?.propulsion?.evBattery?.type?.texts"
    [label]="'vehicleFullTechnicalData.engineInformation.batteryType' | translate"
    [value]="technicalData?.propulsion?.evBattery?.type?.texts"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="technicalData?.propulsion?.evBattery?.capacityAh?.value"
    [label]="
      ('vehicleFullTechnicalData.engineInformation.capacityAh' | translate) +
      (technicalData?.propulsion?.evBattery?.capacityAh | unitValue)
    "
    [value]="technicalData?.propulsion?.evBattery?.capacityAh?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="technicalData?.propulsion?.evCharging?.timeStandardVoltage100?.value"
    [label]="
      ('vehicleFullTechnicalData.engineInformation.timeStandardVoltage100' | translate) +
      (technicalData?.propulsion?.evCharging?.timeStandardVoltage100 | unitValue)
    "
    [value]="technicalData?.propulsion?.evCharging?.timeStandardVoltage100?.value | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="technicalData?.propulsion?.evCharging?.chargeCycles"
    [label]="'vehicleFullTechnicalData.engineInformation.chargeCycles' | translate"
    [value]="technicalData?.propulsion?.evCharging?.chargeCycles | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="
      technicalData?.propulsion?.hybrid?.systemPowerHP?.value || technicalData?.propulsion?.hybrid?.systemPowerKW?.value
    "
    [label]="
      ('vehicleFullTechnicalData.engineInformation.hybridPower' | translate) +
      ([technicalData?.propulsion?.hybrid?.systemPowerKW, technicalData?.propulsion?.hybrid?.systemPowerHP] | unitValue)
    "
    [value]="
      [technicalData?.propulsion?.hybrid?.systemPowerKW, technicalData?.propulsion?.hybrid?.systemPowerHP] | getTwoValue
    "
  ></vpfa-vehicle-technical-data-single-field>
</div>
