import { Directive, HostListener, Input } from '@angular/core';

@Directive({ selector: '[vpfaCharCountLimiter]' })
export class CharCountLimiter {
  @Input() ignoreFormatting = true;
  @Input() maxCharCount: number;

  @HostListener('keydown', ['$event'])
  onKeydown(e: KeyboardEvent) {
    const value = (e.target as any).value;
    if (
      this.maxCharCount &&
      this.valueLength(value) >= this.maxCharCount &&
      !Boolean(this.getInputSelectionLength(e)) &&
      !this.isPressedRemovingKey(e.key) &&
      !this.isPressedLeftOrRightKey(e.key)
    ) {
      e.preventDefault();
      return false;
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(e: any) {
    const value = e.target.value;
    const trimmedTextValue = e.clipboardData.getData('Text').trim();
    if (
      (this.maxCharCount &&
        trimmedTextValue.length + this.valueLength(value) - this.getInputSelectionLength(e) > this.maxCharCount) ||
      this.valueLength(value) > this.maxCharCount
    ) {
      e.preventDefault();
      return false;
    }
  }

  private valueLength(value: string): number {
    return (this.ignoreFormatting ? value.replace(/\D+?/g, '') : value).trim().length;
  }

  private getInputSelectionLength(event): number {
    return event.target.selectionEnd - event.target.selectionStart;
  }

  private isPressedRemovingKey(key: string): boolean {
    return key === 'Backspace' || key === 'Delete';
  }

  private isPressedLeftOrRightKey(key: string): boolean {
    return key === 'ArrowLeft' || key === 'ArrowRight';
  }

}
