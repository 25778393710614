import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { IsMobileViewService } from '../../services/is-mobile-view.service';

type Platform = 'web' | 'mobile';

@Directive({
  selector: '[vpfaResponsiveIf]'
})
export class ResponsiveIfDirective implements OnInit, OnDestroy {

  @Input('vpfaResponsiveIf') platform: Platform;
  // tslint:disable-next-line:no-input-rename
  @Input('vpfaResponsiveIfElse') alternativeTemplate: TemplateRef<void>;

  private onDestroy$ = new Subject<void>();

  constructor(private templateRef: TemplateRef<void>,
    private viewContainerRef: ViewContainerRef,
    private isMobileViewService: IsMobileViewService,
    private cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
      this.checkRequiredFields(this.platform);
      this.isMobileViewService.isMobileView$.pipe(
        distinctUntilChanged(),
        tap(isMobile=> {
          this.viewContainerRef.clear();
          if(this.shouldCreateDefaultView(isMobile)) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          } else {
            this.createAlternativeTemplate();
          }
          this.cdr.markForCheck();
        }),
        takeUntil(this.onDestroy$)
      ).subscribe();
    }

    ngOnDestroy() {
      this.onDestroy$.next();
      this.onDestroy$.complete();
    }

    private shouldCreateDefaultView(isMobile: boolean): boolean {
      const webViewRequested = this.platform === 'web';
      return (webViewRequested && !isMobile) || (!webViewRequested && isMobile);
    };

    private createAlternativeTemplate() {
      if(this.alternativeTemplate) {
        this.viewContainerRef.createEmbeddedView(this.alternativeTemplate);
      }
    }

    checkRequiredFields(input) {
      if(input === null) {
         throw new Error("Attribute 'platform' is required");
      }
   }

}
