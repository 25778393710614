<div fxLayout="column">
  <div class="price">{{ price | currencyLocale: null:null:'1.2' | lT }}</div>
  <div class="tax-information" *ngIf="isPrice">
    <span *ngIf="isTaxIncluded">
      {{ 'common.taxes.incl' | translate }} {{ taxValue | numberLocale: '1.2-2' | lT }}%
      {{ 'common.taxes.tax' | translate }},
      {{ taxAmount | currencyLocale: null:null:'1.2-2' | lT }}
    </span>
    <span *ngIf="isTaxExcluded">
      {{ 'common.taxes.net' | translate }}, {{ 'common.taxes.taxRate' | translate }}:
      {{ taxValue | numberLocale: '1.2-2' | lT }}%
    </span>
    <span *ngIf="isDifferentiallyTaxed">{{ 'common.taxes.gross' | translate }}</span>
  </div>
</div>
