<vpfa-modal
  [modalName]="modalName"
  [showBackdrop]="showBackdrop"
  [width]="width"
  [isClosable]="false"
  [isVisible]="isVisible"
>
  <vpfa-modal-content>
    <button
      nz-popconfirm
      [nzCondition]="!confirmClose"
      [nzPopconfirmTitle]="'common.unsavedChanges' | translate"
      [nzCancelText]="'common.no' | translate"
      [nzOkText]="'common.yes' | translate"
      nzPopconfirmPlacement="top"
      nzPopconfirmOverlayClassName="modal-confirm-popover"
      *ngIf="showCloseButton"
      class="ant-modal-close"
      nz-button
      nzType="link"
      (nzOnConfirm)="onModalClose()"
    >
      <i nz-icon nzType="close-circle" nzTheme="fill"></i>
    </button>
    <nz-spin [nzSpinning]="isLoading">
      <div class="main-container">
        <ng-content></ng-content>
        <vpfa-action-button
          *ngIf="showBottomClose"
          nz-popconfirm
          nzPopconfirmPlacement="top"
          nzPopconfirmOverlayClassName="modal-confirm-popover"
          [nzCondition]="!confirmClose"
          [nzPopconfirmTitle]="'common.unsavedChanges' | translate"
          [nzCancelText]="'common.no' | translate"
          [nzOkText]="'common.yes' | translate"
          [outline]="true"
          [border]="false"
          (nzOnConfirm)="onModalClose()"
        >
          {{ 'common.close' | translate }}
        </vpfa-action-button>
      </div>
    </nz-spin>
  </vpfa-modal-content>
</vpfa-modal>
