import { Component, OnInit, Input, TemplateRef, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { HeaderTitleComponent } from '@vpfa/ui-kit/header-title';
import { Subscription } from 'rxjs';
import { isNil } from 'lodash';

@Component({
  selector: 'vpfa-data-table-header',
  templateUrl: './data-table-header.component.html',
  styleUrls: ['./data-table-header.component.scss'],
})
export class DataTableHeaderComponent implements OnInit, OnDestroy {
  @Input() tableName: string;

  @Input() extraLeftTemplate: TemplateRef<any>;
  @Input() extraRightTemplate: TemplateRef<any>;

  @Output() back = new EventEmitter();

  @ViewChild('headerTitle') headerTitleComponent: HeaderTitleComponent;

  private backSubscribe: Subscription;

  constructor() {}

  ngOnInit() {
    if (this.back.observers.length > 0) {
      this.backSubscribe = this.headerTitleComponent.back.subscribe(event => {
        this.back.emit(event);
      });
    }
  }

  ngOnDestroy(): void {
    if (!isNil(this.backSubscribe)) {
      this.backSubscribe.unsubscribe();
    }
  }
}
