<div [formGroup]="formGroup" *vpfaResponsiveIf="'web'; else mobile">
  <vpfa-expanding-header [text]="'uniqueData.modal.soldData' | translate"></vpfa-expanding-header>

  <vpfa-setting-box [divider]="false">
    <ng-container label>
      <vpfa-label [text]="'uniqueData.newNumberPlate' | translate"></vpfa-label>
    </ng-container>
    <ng-container content>
      <vpfa-text-field
        [placeholder]="''"
        [parentFormGroup]="formGroup"
        fcName="newNumberPlate"
        [errorMessages]="maxLengthErrorMsgList"
      ></vpfa-text-field>
    </ng-container>
  </vpfa-setting-box>

  <vpfa-setting-box [divider]="false">
    <ng-container label>
      <vpfa-label [text]="'uniqueData.saleDate' | translate"></vpfa-label>
    </ng-container>
    <ng-container content>
      <vpfa-field-container
        errorMsgVertPosition="insideDate"
        [control]="formGroup.get('saleDate')"
        [errorMessages]="saleDateErrorMsgList"
      >
        <vpfa-iso-date-picker
          [format]="'short' | localeDateFormat"
          [formControl]="formGroup.get('saleDate')"
          [disabledDate]="disabledSoldDate"
          [allowClear]="false"
        ></vpfa-iso-date-picker>
      </vpfa-field-container>
    </ng-container>
  </vpfa-setting-box>
</div>

<ng-template #mobile>
  <div class="no-hor-scroll">
    <vpfa-mobile-subtitle [title]="'uniqueData.modal.soldData' | translate"></vpfa-mobile-subtitle>

    <div class="mb">
      <vpfa-vehicle-technical-data-single-field
        [label]="'uniqueData.newNumberPlate' | translate"
        [value]="formGroup.get('newNumberPlate').value"
      ></vpfa-vehicle-technical-data-single-field>
    </div>

    <div class="mb">
      <vpfa-vehicle-technical-data-single-field
        [label]="'uniqueData.saleDate' | translate"
        [value]="formGroup.get('saleDate').value | dateLocale"
      ></vpfa-vehicle-technical-data-single-field>
    </div>
  </div>
</ng-template>
