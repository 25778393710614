import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  CellClickedEvent,
  ColDef,
  Column,
  ColumnApi,
  DisplayedColumnsChangedEvent,
  FilterChangedEvent,
  GridApi,
  GridColumnsChangedEvent,
  GridOptions,
  GridReadyEvent,
  PaginationChangedEvent,
  SortChangedEvent,
  ValueFormatterParams,
  IDatasource,
  ColumnResizedEvent,
  FilterModifiedEvent,
  IsFullWidthRowParams,
} from 'ag-grid-community';
import { invoke, isEmpty, isEqual, isNil, pickBy } from 'lodash';
import { DatepickerFloatingFilterComponent } from '../../filters/datepicker-floating-filter/datepicker-floating-filter.component';
import { DropdownFloatingFilterComponent } from '../../filters/dropdown-floating-filter/dropdown-floating-filter.component';
import { DATA_TABLE_COMPONENTS } from '../../models/data-table-custom-components.enum';
import { CurrencyRendererComponent } from '../../renderers/currency-renderer/currency-renderer.component';
import { DateRendererComponent } from '../../renderers/date-renderer/date-renderer.component';
import { NumberRendererComponent } from '../../renderers/number-renderer/number-renderer.component';
import { TranslateRendererComponent } from '../../renderers/translate-renderer/translate-renderer.component';
import { DEFAULT_PAGE_SIZE, PAGE_SIZES } from '../../models/data-table-page-sizes';
import { DataTablePaginationOptions } from '../../models/data-table-pagination-options';
import { DataTableActionEvent } from '../../models/data-table-action';
import { ActionsRendererComponent } from '../../renderers/actions-renderer/actions-renderer.component';
import { MultiValueRendererComponent } from '../../renderers/multi-value-renderer/multi-value-renderer.component';
import { TextFloatingFilterComponent } from '../../filters/text-floating-filter/text-floating-filter.component';
import { FullWidthCellGroupComponent } from '../../renderers/full-width-cell-group/full-width-cell-group.component';
import { SwitchRendererComponent } from '../../renderers/switch-renderer/switch-renderer.component';
import { SubjectRendererComponent } from '../../renderers/subject-renderer/subject-renderer.component';
import { IconActionRendererComponent } from '../../renderers/icon-action-renderer/icon-action-renderer.component';
import { DataTableNoRowsIndicatorComponent } from '../data-table-no-rows-indicator/data-table-no-rows-indicator.component';
import { ImageRendererComponent } from '../../renderers/image-renderer/image-renderer.component';
import { TruncateRendererComponent } from '../../renderers/truncate-renderer/truncate-renderer.component';
import { RequestPreviewActionRendererComponent } from '../../renderers/request-preview-action-renderer/request-preview-action-renderer.component';
import { CheckboxRendererComponent } from '../../renderers/checkbox-renderer/checkbox-renderer.component';
import { TextInputRendererComponent } from '../../renderers/text-input-renderer/text-input-renderer.component';
import { TextInputEditorComponent } from '../../editors/text-input-editor/text-input-editor.component';
import { ResponsePreviewActionRendererComponent } from '../../renderers/response-preview-action-renderer/response-preview-action-renderer.component';
import { AgGridAngular } from 'ag-grid-angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { IconDeleteRendererComponent } from '../../renderers/icon-delete-renderer/icon-delete-renderer.component';
import { TableHeaderTooltipRendererComponent } from '../../renderers/table-header-tooltip-renderer/table-header-tooltip-renderer.component';
import { NumberFloatingFilterComponent } from '../../filters/number-floating-filter/number-floating-filter.component';
import { LinksRendererComponent } from '../../renderers/links-renderer/links-renderer.component';
import { TaxedPriceRendererComponent } from '../../renderers/taxed-price-renderer/taxed-price-renderer.component';
import { DateRangeFloatingFilterComponent } from '../../filters/date-range-floating-filter/date-range-floating-filter.component';
import { SensitiveDataRendererComponent } from '../../renderers/sensitive-data-renderer/sensitive-data.renderer';
import { LinkCellRendererComponent } from '../../renderers/link-cell-renderer/link-cell-renderer.component';
import { NumberRangeFloatingFilterComponent } from '../../filters/number-range-floating-filter/number-range-floating-filter.component';
import { NumberRangeSelectFloatingFilterComponent } from '../../filters/number-range-select-floating-filter/number-range-select-floating-filter.component';
import { DateRangeFloatingFilterOldComponent } from '../../filters/date-range-floating-filter-old/date-range-floating-filter-old.component';

export type RowSelectionType = 'single' | 'multiple';
export const CheckboxColumnName = 'CheckboxColumnName';
export const ActionsColumnName = 'Actions';
export const SpecificationColumnName = 'Specification';

export const ROW_HEIGHT = 80;

@Component({
  selector: 'vpfa-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent<TItem> implements OnInit, OnDestroy {
  private readonly PREVIEW_ROW_HEIGHT = 280;
  private readonly HEADER_HEIGHT = 32;
  private readonly FLOATING_FILTERS_HEIGHT = 34;

  @Input()
  rowData: TItem[];

  @Input()
  columnVisibility = true;

  @Input()
  enableCellChangeFlash = false;

  @Input() tableName: string;
  @Input() forceClickableClass = false;

  @Input() extraSettingsTemplate: TemplateRef<any>;
  @Input() extraHeaderLeftTemplate: TemplateRef<any>;
  @Input() extraHeaderRightTemplate: TemplateRef<any>;
  @Input() extraUnderHeaderTemplate: TemplateRef<any>;
  @Input() rowClickableClass = 'row-clickable';
  @Input() disableOverlay = true;
  @Input() tooltipContent: string | TemplateRef<void>;
  @Input() suppressRowClickSelection = true;

  private _dataSource!: IDatasource;
  @Input() set dataSource(dataSource: IDatasource) {
    this._dataSource = dataSource;
    this.gridOptions.cacheBlockSize = this._currentPageSize ?? this.gridOptions.paginationPageSize;
    this.gridApi?.setDatasource(this.dataSource);
  }

  get dataSource(): IDatasource {
    return this._dataSource;
  }

  @Input()
  set loading(loading: boolean) {
    this._loading = loading;
  }
  private _loading = false;
  get loading() {
    return this._loading;
  }

  @Input()
  set defaultColDef(defaultColDef: ColDef) {
    if (isNil(defaultColDef)) {
      return;
    }
    this._defaultColDef = {
      ...this._defaultColDef,
      ...defaultColDef,
    };
  }

  get defaultColDef(): ColDef {
    return this._defaultColDef;
  }

  @Input()
  options: GridOptions;

  @Input()
  fullWidthCellRenderer = null;
  @Input() fullWidthCellRendererNodeId: string = null;

  @Input() set settingsVisible(v: boolean) {
    this.settingsVisibility = v;
    this.settingsVisibleChange.emit(this.settingsVisibility);
  }

  get settingsVisible(): boolean {
    return this.settingsVisibility;
  }

  @Input() autoSize = false;

  private _selectedComparator: (item: TItem) => boolean;
  get selectedComparator(): (item: TItem) => boolean {
    return this._selectedComparator;
  }

  @Input()
  set selectedComparator(selectedComparator: (item: TItem) => boolean) {
    if (isNil(selectedComparator)) {
      return;
    }
    this._selectedComparator = selectedComparator;
  }

  @Input() rowNodeIdFn: (data: TItem) => any = undefined;

  @Input() stickyXScroll = false;

  @Input() gridParentClass: string = null;

  @Output() settingsVisibleChange = new EventEmitter<boolean>();

  @Output()
  cellClicked: EventEmitter<CellClickedEvent> = new EventEmitter();

  /**
   * This action can be used from custom renderer to output data to parent component
   */
  @Output()
  customAction: EventEmitter<any> = new EventEmitter();

  @Output()
  columnSettingsChanged: EventEmitter<Column[]> = new EventEmitter();

  @Output()
  actionClicked: EventEmitter<DataTableActionEvent<TItem>> = new EventEmitter<DataTableActionEvent<TItem>>();

  @Output()
  selectionChanged: EventEmitter<TItem[]> = new EventEmitter();

  @Output() gridReady = new EventEmitter<GridReadyEvent>();

  /**
   * Default Ag Grid row selection
   */
  @Input() defaultAgGridRowSelection: RowSelectionType;

  /**
   * Custom row selection (with added checkbox column)
   */
  @Input()
  set rowSelection(rowSelection: RowSelectionType) {
    if (isNil(rowSelection)) {
      return;
    }
    this._rowSelection = rowSelection;
  }

  private _rowSelection: RowSelectionType = null;

  @Input()
  set suppressSizeToFitAfterPageSize(suppressSizeToFitAfterPageSize: boolean) {
    this._suppressSizeToFitAfterPageSize = suppressSizeToFitAfterPageSize;
  }

  get suppressSizeToFitAfterPageSize(): boolean {
    return this._suppressSizeToFitAfterPageSize || this.autoSize;
  }

  @Input() isFullWidthCellGroupPredicate: (rowData: TItem) => boolean;
  @Input() rowClassRules: Record<string, (params: any) => boolean>;

  @HostBinding('class.sticky-x-scroll') get isStickyXScroll() {
    return this.stickyXScroll;
  }

  @HostBinding('class.sticky-x-scroll-pagination') get isStickyXScrollPagination() {
    return this.gridOptions.pagination && this.stickyXScroll;
  }

  @Input() set stickyXScrollCustomBottomShift(stickyXScrollCustomBottomShift: number) {
    this._stickyXScrollCustomBottomShift = stickyXScrollCustomBottomShift;
    this.setCustomStickyScrollBottom();
  }

  @Output() filterChanged = new EventEmitter<void>();

  private _stickyXScrollCustomBottomShift: number;

  get stickyXScrollCustomBottomShift(): number {
    return this._stickyXScrollCustomBottomShift;
  }

  private firstDataRendered = false;

  @ViewChild(AgGridAngular, { read: ElementRef }) gridElement: ElementRef;

  private _suppressSizeToFitAfterPageSize = false;
  gridOptions: GridOptions;
  frameworkComponents;
  columnApi: ColumnApi;
  gridApi: GridApi;
  sizeOptions = PAGE_SIZES;

  paginationOptions$: BehaviorSubject<DataTablePaginationOptions> = new BehaviorSubject({
    currentPage: 0,
    totalItems: 0,
    totalPages: 0,
  });

  private _passedColumnsDef: ColDef[];
  private _columnDefs: ColDef[];
  private settingsVisibility: boolean;

  private _defaultColDef: ColDef = {
    headerValueGetter: ({ colDef }: { colDef: ColDef }) => {
      return this.translateHeader(colDef);
    },
    valueFormatter: (params: ValueFormatterParams) => {
      return !isNil(params.value) && params.value !== '' ? params.value : this.translate.instant('common.noValue');
    },
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    floatingFilter: true,
    resizable: false,
  };

  private _currentPageSize = null;

  /**
   * Percent of scroll by user from right side of scroll area
   */
  private lastScrollPercent: number = null;

  /**
   * To detect if user is using the scroll
   */
  private isMouseDown = false;

  private _onDestroy$ = new Subject<void>();

  public lastModifiedFilter: string = null;

  /**
   * It may happen that onPaginationChanged event will be triggered before gridApi is ready (paginationEventTriggeredBeforeGridReady = true)
   * causing grid overlay to freeze on loading state and not setting up pagination options.
   *
   * In that case onPaginationChanged should be invoked again after gridApi had been initialised.
   */
  private paginationEventTriggeredBeforeGridReady: boolean = false;
  private paginationEvent: PaginationChangedEvent;

  constructor(
    private translate: TranslateService,
    private renderer: Renderer2,
  ) {
    // @ts-ignore
    this.frameworkComponents = {
      [DATA_TABLE_COMPONENTS.DROPDOWN_FLOATING_FILTER]: DropdownFloatingFilterComponent,
      [DATA_TABLE_COMPONENTS.AG_DATE_INPUT]: DatepickerFloatingFilterComponent,
      [DATA_TABLE_COMPONENTS.LINK]: LinkCellRendererComponent,
      [DATA_TABLE_COMPONENTS.TEXT_FLOATING_FILTER]: TextFloatingFilterComponent,
      [DATA_TABLE_COMPONENTS.DATE_RENDERER]: DateRendererComponent,
      [DATA_TABLE_COMPONENTS.NUMBER_RENDERER]: NumberRendererComponent,
      [DATA_TABLE_COMPONENTS.SWITCH_RENDERER]: SwitchRendererComponent,
      [DATA_TABLE_COMPONENTS.SUBJECT_RENDERER]: SubjectRendererComponent,
      [DATA_TABLE_COMPONENTS.CURRENCY_RENDERER]: CurrencyRendererComponent,
      [DATA_TABLE_COMPONENTS.TRANSLATE_RENDERER]: TranslateRendererComponent,
      [DATA_TABLE_COMPONENTS.ACTIONS_RENDERER]: ActionsRendererComponent,
      [DATA_TABLE_COMPONENTS.MULTI_VALUE_RENDERER]: MultiValueRendererComponent,
      [DATA_TABLE_COMPONENTS.FULL_WIDTH_CELL_GROUP_RENDER]: FullWidthCellGroupComponent,
      [DATA_TABLE_COMPONENTS.ICON_ACTION_RENDERER]: IconActionRendererComponent,
      [DATA_TABLE_COMPONENTS.IMAGE_RENDERER]: ImageRendererComponent,
      [DATA_TABLE_COMPONENTS.NO_ROWS_INDICATOR]: DataTableNoRowsIndicatorComponent,
      [DATA_TABLE_COMPONENTS.TRUNCATE_RENDERER]: TruncateRendererComponent,
      [DATA_TABLE_COMPONENTS.SENSITIVE_DATA_RENDERER]: SensitiveDataRendererComponent,
      [DATA_TABLE_COMPONENTS.REQUEST_PREVIEW_ACTION_RENDERER]: RequestPreviewActionRendererComponent,
      [DATA_TABLE_COMPONENTS.RESPONSE_PREVIEW_ACTION_RENDERER]: ResponsePreviewActionRendererComponent,
      [DATA_TABLE_COMPONENTS.CHECKBOX_INPUT]: CheckboxRendererComponent,
      [DATA_TABLE_COMPONENTS.TEXT_INPUT_RENDER]: TextInputRendererComponent,
      [DATA_TABLE_COMPONENTS.TEXT_INPUT_EDITOR]: TextInputEditorComponent,
      [DATA_TABLE_COMPONENTS.ICON_DELETE_RENDERER]: IconDeleteRendererComponent,
      [DATA_TABLE_COMPONENTS.TABLE_HEADER_TOOLTIP_RENDERER]: TableHeaderTooltipRendererComponent,
      [DATA_TABLE_COMPONENTS.NUMBER_FLOATING_FILTER]: NumberFloatingFilterComponent,
      [DATA_TABLE_COMPONENTS.NUMBER_RANGE_FLOATING_FILTER]: NumberRangeFloatingFilterComponent,
      [DATA_TABLE_COMPONENTS.NUMBER_RANGE_SELECT_FLOATING_FILTER]: NumberRangeSelectFloatingFilterComponent,
      [DATA_TABLE_COMPONENTS.LINKS]: LinksRendererComponent,
      [DATA_TABLE_COMPONENTS.TAXED_PRICE_RENDERER]: TaxedPriceRendererComponent,
      [DATA_TABLE_COMPONENTS.DATE_RANGE_FLOATING_FILTER]: DateRangeFloatingFilterComponent,
      [DATA_TABLE_COMPONENTS.DATE_RANGE_FLOATING_FILTER_OLD]: DateRangeFloatingFilterOldComponent,
    };

    this.gridOptions = <GridOptions>{
      context: {
        componentParent: this,
      },
      getRowHeight: row => {
        return this.fullWidthCellRendererNodeId === row.node.id ? this.PREVIEW_ROW_HEIGHT : ROW_HEIGHT;
      },
      multiSortKey: 'ctrl',
      headerHeight: this.HEADER_HEIGHT,
      floatingFiltersHeight: this.FLOATING_FILTERS_HEIGHT,
      suppressHorizontalScroll: false,
      paginationPageSize: DEFAULT_PAGE_SIZE.value,
      onPaginationChanged: this.onPaginationChanged,
      suppressPaginationPanel: true,
      accentedSort: true,
    };
  }

  ngOnInit() {
    if (this.gridOptions) {
      this.gridOptions = {
        ...this.gridOptions,
        ...this.options,
      };

      if (this.forceClickableClass || (this.cellClicked.observers.length && this.rowClickableClass)) {
        this.gridOptions.rowClass = this.rowClickableClass;
      }

      if (this.autoSize) {
        this.gridOptions.suppressColumnVirtualisation = true;
      }
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @Input()
  set columnDefs(columnDefs: ColDef[]) {
    if (isNil(columnDefs)) {
      return;
    }

    this._passedColumnsDef = columnDefs;

    this.calculateColDefs();
  }

  private calculateColDefs(): void {
    // store actual columns width
    let actualWidthMap: { [colId: string]: number } = {};
    if (this.columnApi && this.columnApi.getAllGridColumns()) {
      actualWidthMap = this.columnApi.getAllGridColumns().reduce((mapObject, column) => {
        mapObject[column.getId()] = column.getActualWidth();
        return mapObject;
      }, {});
    }

    if (!isNil(this._columnDefs) && !isNil(this.gridApi)) {
      this.gridApi.setColumnDefs([]);
      this.gridApi.paginationGoToFirstPage();
    }

    // HACK, column def dosn't refresh without it

    let tempColDefs = [...this._passedColumnsDef];

    if (this.isRowSelection) {
      tempColDefs = [
        {
          colId: CheckboxColumnName,
          headerCheckboxSelection: this.isMultipleSelection && isNil(this.dataSource),
          headerCheckboxSelectionFilteredOnly: this.isMultipleSelection && isNil(this.dataSource),
          checkboxSelection: true,
          sortable: false,
          suppressMovable: true,
          headerName: '',
          width: 40,
          minWidth: 40,
          maxWidth: 40,
          lockPosition: true,
          cellClass: 'data-table-selection',
          headerClass: 'data-table-selection',
        },
        ...tempColDefs,
      ];
    }

    tempColDefs.forEach(col => {
      if (col.suppressMovable || this.defaultColDef.suppressMovable) {
        col.headerClass = `${col.headerClass ? `${col.headerClass} ` : ''}cell-static`;
      }
    });

    // restore actual columns width
    tempColDefs = tempColDefs.map(col => {
      if (isNil(actualWidthMap[col.field])) {
        return col;
      }
      return {
        ...col,
        width: actualWidthMap[col.field],
      };
    });

    this._columnDefs = tempColDefs;
  }

  get columnDefs(): ColDef[] {
    return this._columnDefs;
  }

  onCellClick(event: CellClickedEvent) {
    if (
      event.colDef.cellRenderer !== DATA_TABLE_COMPONENTS.ACTIONS_RENDERER &&
      event.colDef.cellRenderer !== DATA_TABLE_COMPONENTS.SWITCH_RENDERER &&
      event.colDef.cellRenderer !== DATA_TABLE_COMPONENTS.ICON_DELETE_RENDERER
    ) {
      this.cellClicked.emit(event);
    }
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
    this.columnApi = event.columnApi;

    this.gridApi.sizeColumnsToFit();
    this.gridApi.addEventListener('sortChanged', this.onGridSortChange);
    this.gridApi.addEventListener('filterChanged', this.onGridFilterChange);
    this.gridApi.addEventListener('filterModified', this.onFilterModified.bind(this));
    this.gridApi.addEventListener('rowDataUpdated', this.onRowDataChange);
    this.gridApi.addEventListener('selectionChanged', this.onSelectionChanged);
    this.gridApi.addEventListener('paginationChanged', this.onPaginationChanged);
    this.gridApi.addEventListener('firstDataRendered', this.onFirstDataRendered);
    this.gridApi.addEventListener('columnResized', this.restoreFiltersPositionsAfterAutoSize.bind(this));

    document.getElementsByTagName('ag-grid-angular')[0].addEventListener('mousedown', () => {
      this.isMouseDown = true;
    });

    document.getElementsByTagName('ag-grid-angular')[0].addEventListener('mouseup', () => {
      this.isMouseDown = false;
    });

    document.querySelector('.ag-center-cols-viewport').addEventListener('scroll', () => {
      const scrollNotDoneByUser = !this.isMouseDown;
      if (this.loading || scrollNotDoneByUser) {
        return;
      }

      const left = document.querySelector('.ag-center-cols-viewport').scrollLeft;
      const width = document.querySelector('.ag-center-cols-viewport').scrollWidth;
      const widthOfVisibleArea = document.querySelector('.ag-body-viewport').getBoundingClientRect().width + 1;

      const scrollPercentFromRight = (left + widthOfVisibleArea) / width;

      this.lastScrollPercent = scrollPercentFromRight;
    });

    if (this.dataSource) {
      this.gridOptions.cacheBlockSize = this._currentPageSize ?? this.gridOptions.paginationPageSize;
      this.gridApi.setDatasource(this.dataSource);
    }

    if (this.autoSize) {
      this.initializeAutoSize();
    }

    if (this.paginationEventTriggeredBeforeGridReady) {
      this.onPaginationChanged(null);
    }

    this.gridReady.emit(event);
    this.recalculatePages();
  }

  /**
   * AgGrid by default is restoring scroll from left, but after auto-resizing columns to its content,
   * scroll is restored in wrong place. So we need to restore scroll from right side (scrollLeft + visibleArea)
   */
  restoreFiltersPositionsAfterAutoSize(event: ColumnResizedEvent) {
    const userDidNotScrollYet = this.lastScrollPercent === null;

    if (
      this.loading ||
      userDidNotScrollYet ||
      !this.autoSize ||
      !event.finished ||
      event.source !== 'autosizeColumns'
    ) {
      return;
    }

    const scrollWidth = document.querySelector('.ag-center-cols-viewport').scrollWidth;
    const widthOfVisibleArea = document.querySelector('.ag-body-viewport').getBoundingClientRect().width + 1;

    const fixedLeftScroll = this.lastScrollPercent * scrollWidth - widthOfVisibleArea;

    document.querySelector('.ag-center-cols-viewport').scrollLeft = fixedLeftScroll;
  }

  onFilterModified(event: FilterModifiedEvent) {
    this.lastModifiedFilter = event.column.getColId();
  }

  onColumnMoved = () => {
    this.onColumnSettingsChange();
  };

  onColumnSettingsChange() {
    this.recalculateSorting();
    this.notifyColumnSettingsChange();
  }

  onGridSortChange = () => {
    this.recalculateSorting();
    this.notifyColumnSettingsChange();
    this.onRowDataChange();
  };

  onGridFilterChange = () => {
    this.recalculateFiltering();
    this.notifyColumnSettingsChange();
    this.onRowDataChange();
    this.filterChanged.emit();
  };

  onColumnVisibleChange = () => {
    this.recalculateSorting();
    this.recalculateFiltering();
    this.notifyColumnSettingsChange();
  };

  onSelectionChanged = () => {
    this.selectionChanged.emit(this.gridApi.getSelectedRows());
  };

  onRowDataChange = () => {
    this.recalculateSelection();
  };

  onPageChange(page: number) {
    this.gridApi.paginationGoToPage(page);
    this.onRowDataChange();
  }

  onPageSizeChange(pageSize: number) {
    if (this.dataSource) {
      this.gridOptions.cacheBlockSize = pageSize;
      this.gridApi.setDatasource(this.dataSource);
    }
    this.gridApi.paginationSetPageSize(pageSize);
  }

  get columns(): Column[] {
    const allColumns = this.gridApi?.getAllGridColumns();

    if (isNil(allColumns)) {
      return [];
    }

    return allColumns.filter(col => col.getColId() !== CheckboxColumnName);
  }

  get rowSelection(): RowSelectionType {
    return this.defaultAgGridRowSelection ?? this._rowSelection;
  }

  private translateHeader(colDef: ColDef): string {
    return translateHeaderName(colDef?.headerName, this.translate);
  }

  private notifyColumnSettingsChange() {
    this.columnSettingsChanged.emit(this.columns);
  }

  get showColumSettings(): boolean {
    return this.columnVisibility || !isNil(this.extraSettingsTemplate);
  }

  get showHeader(): boolean {
    return (
      !isNil(this.tableName) ||
      this.showColumSettings ||
      !isNil(this.extraHeaderLeftTemplate) ||
      !isNil(this.extraHeaderRightTemplate)
    );
  }

  private recalculateSorting() {
    const newSortModel = this.columns
      .filter(column => column.getSort() && column.isVisible())
      .map(column => {
        return {
          colId: column.getColId(),
          sort: column.getSort(),
        };
      });

    // TODO: getSortModel is now in columnApi.getColumnState
    // if (!isEqual(this.gridApi.getSortModel(), newSortModel)) {
    //   // Remove event listener because there is no difference between call from user action and code action
    //   this.gridApi.removeEventListener('sortChanged', this.onGridSortChange);
    //   // TODO: setSortModel is now in columnApi.applyColumnState
    //   // this.gridApi.setSortModel(newSortModel);
    //   this.gridApi.addEventListener('sortChanged', this.onGridSortChange);
    // }
  }

  private recalculateFiltering() {
    // filter are also pass for hidden column but we don't want to consider that filters so we need to remove it
    const filtersModel: { [colId: string]: any } = this.gridApi.getFilterModel();
    const visibleColumnsIds = this.columns.filter(column => column.isVisible()).map(column => column.getColId());
    const newFilersModel = pickBy(filtersModel, (filterModel, colId) => {
      return visibleColumnsIds.indexOf(colId) !== -1;
    });

    // if new filters colId are different than old one we need to update filter models
    const isFiltersDifferent = !isEqual(Object.keys(filtersModel), Object.keys(newFilersModel));
    if (isFiltersDifferent) {
      // Remove event listener because there is no difference between call from user action and code action
      this.gridApi.removeEventListener('filterChanged', this.onGridFilterChange);
      this.gridApi.setFilterModel(newFilersModel);
      this.gridApi.addEventListener('filterChanged', this.onGridFilterChange);
    }
  }

  private get isRowSelection(): boolean {
    return !isNil(this._rowSelection);
  }

  private get isMultipleSelection(): boolean {
    return this._rowSelection === 'multiple';
  }

  private recalculateSelection() {
    if (isNil(this._rowSelection) || isNil(this.selectedComparator)) return;
    this.gridApi.removeEventListener('selectionChanged', this.onSelectionChanged);
    this.gridApi.forEachNode((rowNode, id) => {
      rowNode.setSelected(this.selectedComparator(rowNode.data));
    });
    this.gridApi.addEventListener('selectionChanged', this.onSelectionChanged);
  }

  private onPaginationChanged = (event: PaginationChangedEvent) => {
    if (!isNil(event)) {
      this.paginationEvent = event;
    }

    if (this.gridApi) {
      this.paginationEventTriggeredBeforeGridReady = false;
      this.setPaginationOptions();
      this.setOverlay();
      if (!this.suppressSizeToFitAfterPageSize) {
        this.gridApi.sizeColumnsToFit();
      }
    } else {
      this.paginationEventTriggeredBeforeGridReady = true;
    }
  };

  private setPaginationOptions() {
    if (this._currentPageSize && this._currentPageSize !== this.paginationEvent.api.paginationGetPageSize()) {
      this.gridApi.paginationGoToFirstPage();
    }
    this._currentPageSize = this.paginationEvent.api.paginationGetPageSize();

    this.paginationOptions$.next({
      currentPage: this.gridApi.paginationGetCurrentPage() + 1,
      totalItems: this.gridApi.getDisplayedRowCount(),
      totalPages: this.gridApi.paginationGetTotalPages(),
    });
  }

  private setOverlay() {
    if (!this._loading) {
      if (!this.gridApi.getDisplayedRowCount()) {
        this.gridApi.showNoRowsOverlay();
      } else {
        this.gridApi.hideOverlay();
      }
    }
  }

  isFullWidthCell: (params: IsFullWidthRowParams) => boolean = params => {
    if (!params.rowNode.data || !params.rowNode.data.fullWidth) {
      return Boolean(invoke(this, 'isFullWidthCellGroupPredicate', [params.rowNode]));
    } else {
      return true;
    }
  };

  private initializeAutoSize() {
    const columnSizeShouldRecalculate = new Subject();

    this.gridApi.addEventListener('gridColumnsChanged', (gridColumnsChangedEvent: GridColumnsChangedEvent) => {
      columnSizeShouldRecalculate.next(gridColumnsChangedEvent);
    });

    this.gridApi.addEventListener('columnVisible', (displayedColumnsChangedEvent: DisplayedColumnsChangedEvent) => {
      columnSizeShouldRecalculate.next(displayedColumnsChangedEvent);
    });

    this.gridApi.addEventListener('paginationChanged', (paginationChangedEvent: PaginationChangedEvent) => {
      if (!isNil(paginationChangedEvent.newData)) {
        columnSizeShouldRecalculate.next(paginationChangedEvent);
      }
    });

    this.gridApi.addEventListener('sortChanged', (sortChangedEvent: SortChangedEvent) => {
      columnSizeShouldRecalculate.next(sortChangedEvent);
    });

    this.gridApi.addEventListener('filterChanged', (filterChanged: FilterChangedEvent) => {
      columnSizeShouldRecalculate.next(filterChanged);
    });

    columnSizeShouldRecalculate
      .pipe(
        debounceTime(1),
        // do not resize columns when we do not have any data (happens for a short time
        // when data is loading after filtering when using BE pagination)
        filter(() => this.gridApi.paginationGetRowCount() !== 0),
        takeUntil(this._onDestroy$),
      )
      .subscribe(ev => {
        this.columnApi.autoSizeAllColumns(false);
      });
  }

  //Without this "page jump" calculation with many items do not work.
  private recalculatePages() {
    this.gridApi.paginationGoToNextPage();
    this.gridApi.paginationGoToFirstPage();
  }

  private onFirstDataRendered = () => {
    this.firstDataRendered = true;
    this.setCustomStickyScrollBottom();
  };

  private setCustomStickyScrollBottom() {
    if (!this.firstDataRendered) {
      return;
    }

    let customShift = 0;
    if (this.isStickyXScroll) {
      if (this.stickyXScrollCustomBottomShift) {
        customShift += this.stickyXScrollCustomBottomShift;
      }

      if (this.isStickyXScrollPagination) {
        customShift += 61;
      }
    }

    const gridHtmlElement = this.gridElement.nativeElement as HTMLElement;
    const scrollXElement = gridHtmlElement.querySelector('.ag-body-horizontal-scroll');
    if (!isNil(scrollXElement)) {
      this.renderer.setStyle(scrollXElement, 'bottom', `${customShift}px`);
    }
  }
}

export const translateHeaderName = (headerName: string, translateService: TranslateService): string => {
  if (isEmpty(headerName)) return '';

  return headerName
    .split(' ')
    .map(possibleTranslationKey => translateService.instant(possibleTranslationKey))
    .join(' ');
};
