import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { getAcceptInfo } from '../../utils/get-accept-info';
import { FileUploadType } from '../../models/file-types';

@Component({
  selector: 'vpfa-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent {
  @Input() showUploadIcon = false;
  @Input() multiple: boolean;
  @Input() maxFileSize: number;
  @Input() uploadPrompt: string;
  @Input() uploadButtonLabel: string;
  @Input() fileList: string[] = [];
  @Input() uploadText: string;
  @Input() isDisabled: boolean;
  @Input() uploadOnFileSelected = true;
  @Input() uiMode: 'linkMode' | 'fullMode' | 'singleFileModalMode' = 'fullMode';
  @Input() fileUploadType: FileUploadType;

  @Output() fileListChange = new EventEmitter<File[] | undefined>();
  @Output() failedFiles = new EventEmitter<File[]>();
  @Output() fileUpload = new EventEmitter<File[]>();

  @ViewChild('fileInputRef', { read: ElementRef }) fileInputRef: ElementRef;

  getAcceptInfo = getAcceptInfo;
  selectedFiles: File[] = [];

  onUploadBtnClick() {
    const input = this.fileInputRef.nativeElement as HTMLInputElement;
    input.files = null;
    input.value = null;
    input.click();
  }

  onChange(e: Event) {
    this.fileListChange.emit();
    const input = e.target as HTMLInputElement;
    this.selectedFiles = Array.from(input.files);
    if (this.uploadOnFileSelected) {
      this.onFileUpload(this.selectedFiles);
    }
  }

  onFileUpload(selectedFiles: File[]) {
    this.fileUpload.emit(selectedFiles);
  }
}
