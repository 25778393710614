import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { TextFieldComponent } from '../text-field/text-field.component';

const REGEXP__GERMAN_PHONE_NUMBER = /\(?\+\(?49\)?[ ()]?([- ()]?\d[- ()]?){10}/;

@Component({
  selector: 'vpfa-phone-number-field',
  templateUrl: './phone-number-field.component.html',
  styleUrls: ['./phone-number-field.component.scss']
})
export class PhoneNumberFieldComponent extends TextFieldComponent implements OnInit {
  @Input() required = false;
  @Input() disableNationalPhoneNumberValiation = true;

  ngOnInit(): void {
    const validators = [];
    if (!this.disableNationalPhoneNumberValiation) {
      validators.push(Validators.pattern(REGEXP__GERMAN_PHONE_NUMBER));
    }
    if (this.required) {
      validators.push(Validators.required);
    }

    this.getFormControl().setValidators(validators);
    this.getFormControl().updateValueAndValidity();
  }
}
