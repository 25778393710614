import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  private _ids = [];

  private isIdUnique(id: string) {
    if (this._ids.includes(id)) {
      return false;
    } else return true;
  }
  /**
   *
   * @param url Remote script url
   * @param globallyUniqueElementId globally unique id for DOM element
   */
  addRemoteScript(url: string, globallyUniqueElementId: string) {
    if (this.isIdUnique(globallyUniqueElementId)) {
      this._ids.push(globallyUniqueElementId);
      const scriptEl = document.createElement('script');
      scriptEl.async = false;
      scriptEl.src = url;
      scriptEl.setAttribute('id', globallyUniqueElementId);
      document.body.append(scriptEl);
      return true;
    } else {
      return false;
    }
  }

  /**
   *
   * @param url Remote stylesheet url
   * @param globallyUniqueElementId globally unique id for DOM element
   */
  addRemoteStylesheet(url: string, globallyUniqueElementId: string) {
    if (this.isIdUnique(globallyUniqueElementId)) {
      this._ids.push(globallyUniqueElementId);
      const stylesheetLinkEl = document.createElement('link');
      stylesheetLinkEl.href = '';
      stylesheetLinkEl.rel = 'stylesheet';
      stylesheetLinkEl.setAttribute('id', globallyUniqueElementId);
      document.body.append(stylesheetLinkEl);
      return true;
    } else {
      return false;
    }
  }

  registerOnScriptLoadedListener() {}
}
