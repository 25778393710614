import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash';
import { DateLocalePipe } from '../date-locale/date-locale.pipe';

@Pipe({
  name: 'dateRange',
  pure: false,
})
export class DateRangePipe implements PipeTransform {
  private separator = ' - ';

  constructor(private formatterPipe: DateLocalePipe) {}

  transform(input: Date[], tillNow: string): string {
    const result = [];

    const firstValue = input?.[0];
    const secondValue = input?.[1];

    const isFirstValueValid = !isNil(firstValue);

    if (isFirstValueValid) {
      result.push(this.formatterPipe.transform(firstValue, 'shortDate', null, null, true));
    }

    if (!isNil(secondValue)) {
      result.push(this.formatterPipe.transform(secondValue, 'shortDate', null, null, true));
    } else if (isFirstValueValid) {
      result.push(tillNow);
    }

    if (!result.length) {
      return null;
    }

    return result.join(this.separator);
  }
}
