import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  IdentifiedVehicleService,
  UniversalCategoryViewService,
  EquipmentDto,
  EquipmentError,
  EquipmentInfo,
  EquipmentErrorEquipmentErrorDto,
  EquipmentFormulaBlockDto,
} from '@vpfa/rest-api/valuation';
import { of, Observable, OperatorFunction, EMPTY } from 'rxjs';
import { catchError, map, switchMap, tap, filter, withLatestFrom } from 'rxjs/operators';
import {
  fromVehicleEquipmentActions,
  VehicleEquipmentActionTypes,
  VehicleEquipmentAftermarketItemCreate,
  VehicleEquipmentAftermarketItemCreated,
  VehicleEquipmentAftermarketItemDelete,
  VehicleEquipmentAftermarketItemDeleted,
  VehicleEquipmentAftermarketItemUpdate,
  VehicleEquipmentAftermarketItemUpdated,
  VehicleEquipmentUniversalCategoryCodesLoad,
  VehicleEquipmentUpdateIsEnabled,
  VehicleEquipmentUpdateIsEnabledSuccess,
  VehicleEquipmentCancelDeselectPackageContent,
  VehicleEquipmentCancelDeselectPackage,
  VehicleEquipmentColorUpdate,
  VehicleEquipmentColorUpdated,
  VehicleResetEquipmentListToDefault,
  VehicleResetEquipmentListToDefaultSuccess,
} from './vehicle-equipment.actions';
import { BasicNotificationsService } from '@vpfa/shared/notifications';
import { fromCasesActions, CaseUpdateActiveCaseVehicleEquipmentIsEnabled, CasesFacade } from '@vpfa/dealer/case/data';
import { keyBy, flatMap, uniq, Dictionary } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Action } from '@ngrx/store';
import { startFormulaWizard } from './formula-wizard/formula-wizard.actions';
import { calculateShortDesc, getEquipmentDebugText, isDebugEnabled } from '@vpfa/utils';
import { ModalService } from '@vpfa/modals';

export interface CanStartFormulaWizard {
  canStartFormulaWizard: boolean;
  caseId: string;
  vehicleId: string;
  formulaBlocks: EquipmentFormulaBlockDto[];
  soaCode: string;
}

@Injectable()
export class VehicleEquipmentEffects {
  private equipmentsBySoaCode$ = this.casesFacade.activeCaseVehicleEquipment$.pipe(
    map(equipments => keyBy(equipments, 'soaCode'))
  );

  vehicleEquipmentUpdateIsEnabled$ = createEffect(() =>
    this.actions$.pipe(
      ofType<VehicleEquipmentUpdateIsEnabled>(VehicleEquipmentActionTypes.VehicleEquipmentUpdateIsEnabled),
      switchMap(action =>
        this.identifiedVehicleService
          .updateVehicleEquipment({
            aggregateRootId: action.payload.command.aggregateRootId,
            validateOnly: action.payload.command.validateOnly,
            equipmentChanges: [
              { isEnabled: action.payload.command.isEnabled, soaCode: action.payload.command.soaCode },
            ],
            ignoreValidationErrors: action.payload.command.ignoreValidationErrors,
            ignoreAllValidationErrors: action.payload.command.ignoreAllValidationErrors,
          })
          .pipe(
            map(response => {
              const { soaCode, isEnabled } = action.payload.command;
              return new fromVehicleEquipmentActions.VehicleEquipmentUpdateIsEnabledSuccess({
                soaCode,
                isEnabled,
                caseId: action.payload.caseId,
                errors: response.equipmentErrors,
                infos: response.equipmentInfos,
                vehicleId: response.aggregateId,
                formulaBlocks: response.formulaBlocks,
              });
            }),
            catchError(err => of(new fromVehicleEquipmentActions.VehicleEquipmentUpdateIsEnabledError(err)))
          )
      )
    )
  );

  /**
   * Reload equipment to sync state between FE <=> BE
   * For example in case of complex formula, it may revert just enabled equipment back to disabled state.
   */
  vehicleEquipmentUpdateIsEnabledInCase$ = createEffect(() =>
    this.actions$.pipe(
      ofType<VehicleEquipmentUpdateIsEnabled>(VehicleEquipmentActionTypes.VehicleEquipmentUpdateIsEnabled),
      map(
        action =>
          new CaseUpdateActiveCaseVehicleEquipmentIsEnabled({
            isEnabled: action.payload.command.isEnabled,
            soaCode: action.payload.command.soaCode,
          })
      )
    )
  );

  vehicleEquipmentUpdateIsEnabledSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<VehicleEquipmentUpdateIsEnabledSuccess>(
        VehicleEquipmentActionTypes.VehicleEquipmentUpdateIsEnabledSuccess
      ),
      map(action => {
        return new fromCasesActions.CaseLoadEquipments(action.payload.caseId);
      })
    )
  );

  vehicleEquipmentFormulaIncompatibleWarning$ = createEffect(() =>
    this.actions$.pipe(
      ofType<VehicleEquipmentUpdateIsEnabledSuccess>(
        VehicleEquipmentActionTypes.VehicleEquipmentUpdateIsEnabledSuccess
      ),
      map(action => ({
        payload: {
          ...action.payload,
          errors: action.payload.errors.filter(
            error => error.error === EquipmentError.EnabledFormulaWithDisabledEquipment
          ),
        },
      })),
      filter(action => action.payload.errors.length > 0),
      withLatestFrom(this.equipmentsBySoaCode$),
      switchMap(([action, equipmentsBySoaCode]) => {
        return this.confirmForce(
          {
            caseId: action.payload.caseId,
            soaCode: action.payload.soaCode,
            errors: action.payload.errors,
            isEnabled: action.payload.isEnabled,
            vehicleId: action.payload.vehicleId,
          },
          equipmentsBySoaCode,
          new VehicleEquipmentCancelDeselectPackageContent(),
          'equipmentList.warningModal.formulaIncompatibleSoaTitle',
          ''
        );
      })
    )
  );

  vehicleEquipmentFormulaIncompatibleStandardEquipmentWarning$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<VehicleEquipmentUpdateIsEnabledSuccess>(
          VehicleEquipmentActionTypes.VehicleEquipmentUpdateIsEnabledSuccess
        ),
        map(action => ({
          payload: {
            ...action.payload,
            infos: action.payload.infos.filter(
              info => info.error === EquipmentInfo.AutoDeselectedStandardEquipmentIncompatibility
            ),
          },
        })),
        filter(action => action.payload.infos.length > 0),
        withLatestFrom(this.equipmentsBySoaCode$),
        switchMap(([action, equipmentsBySoaCode]) => {
          return this.showOK(
            {
              caseId: action.payload.caseId,
              soaCode: action.payload.soaCode,
              errors: action.payload.infos,
              isEnabled: action.payload.isEnabled,
              vehicleId: action.payload.vehicleId,
            },
            equipmentsBySoaCode,
            undefined,
            'formulaWizard.standardEquipmentDeselectedInfo.title'
          );
        })
      ),
    { dispatch: false }
  );

  vehicleEquipmentIncompatibleWarning$ = createEffect(() =>
    this.actions$.pipe(
      ofType<VehicleEquipmentUpdateIsEnabledSuccess>(
        VehicleEquipmentActionTypes.VehicleEquipmentUpdateIsEnabledSuccess
      ),
      filterFormulaIncompatible(),
      map(action => ({
        payload: {
          ...action.payload,
          errors: action.payload.errors.filter(error => error.error === EquipmentError.IncompatibleEquipment),
        },
      })),
      filter(action => action.payload.errors.length > 0),
      withLatestFrom(this.equipmentsBySoaCode$),
      switchMap(([action, equipmentsBySoaCode]) => {
        return this.confirmForce(
          {
            caseId: action.payload.caseId,
            soaCode: action.payload.soaCode,
            errors: action.payload.errors,
            isEnabled: action.payload.isEnabled,
            vehicleId: action.payload.vehicleId,
          },
          equipmentsBySoaCode,
          new VehicleEquipmentCancelDeselectPackageContent(),
          'equipmentList.warningModal.incompatibleSoaTitle',
          'equipmentList.warningModal.incompatibleSoaFooter'
        );
      })
    )
  );

  vehicleEquipmentAutoDeselectInfo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<VehicleEquipmentUpdateIsEnabledSuccess>(
          VehicleEquipmentActionTypes.VehicleEquipmentUpdateIsEnabledSuccess
        ),
        filter(action => !action.payload.errors?.length && !action.payload.formulaBlocks?.length),
        map(action =>
          action.payload.infos.filter(info => info.error === EquipmentInfo.AutoDeselectedStandardEquipment)
        ),
        filter(infos => infos.length > 0),
        withLatestFrom(this.equipmentsBySoaCode$),
        tap(([infos, equipmentsBySoaCode]) => {
          const soaCodesForAutoDeselect = uniq(flatMap(infos.map(eqp => eqp.soaCodes)));

          const autoDeselectEquipments = soaCodesForAutoDeselect.map(soaCode => equipmentsBySoaCode[soaCode]);
          autoDeselectEquipments.forEach(autoDeselectEquipment => {
            this.notification.warning(
              'equipmentList.warningModal.autoDeselectTitle',
              calculateShortDesc(autoDeselectEquipment)
            );
          });
        })
      ),
    { dispatch: false }
  );

  vehicleCannotDeselectPartOfPackageEquipmentWarning$ = createEffect(() =>
    this.actions$.pipe(
      ofType<VehicleEquipmentUpdateIsEnabledSuccess>(
        VehicleEquipmentActionTypes.VehicleEquipmentUpdateIsEnabledSuccess
      ),
      filterIncompatible(),
      map(action => {
        return {
          soaCode: action.payload.soaCode,
          errors: action.payload.errors.filter(
            error => error.error === EquipmentError.CannotDeselectPartOfPackageEquipment
          ),
          caseId: action.payload.caseId,
          vehicleId: action.payload.vehicleId,
          isEnabled: action.payload.isEnabled,
        };
      }),
      filter(data => data.errors.length > 0),
      withLatestFrom(this.equipmentsBySoaCode$),
      switchMap(([data, equipmentsBySoaCode]) =>
        this.confirmDeselecting(
          data,
          equipmentsBySoaCode,
          new VehicleEquipmentCancelDeselectPackageContent(),
          'equipmentList.warningModal.cannotDeselectPartOfPackageEquipmentTitle',
          'equipmentList.warningModal.cannotDeselectPartOfPackageEquipmentFooter',
          'equipmentList.warningModal.deselect'
        )
      )
    )
  );

  vehicleEnabledEquipmentOfDisabledPackageWarning$ = createEffect(() =>
    this.actions$.pipe(
      ofType<VehicleEquipmentUpdateIsEnabledSuccess>(
        VehicleEquipmentActionTypes.VehicleEquipmentUpdateIsEnabledSuccess
      ),
      filterIncompatible(),
      map(action => {
        return {
          soaCode: action.payload.soaCode,
          errors: action.payload.errors.filter(
            error =>
              error.error === EquipmentError.EnabledContentEquipmentOfDisabledPackage ||
              error.error === EquipmentError.EnabledFormulaEquipmentOfDisabledPackage
          ),
          caseId: action.payload.caseId,
          vehicleId: action.payload.vehicleId,
          isEnabled: action.payload.isEnabled,
        };
      }),
      filter(data => data.errors.length > 0),
      withLatestFrom(this.equipmentsBySoaCode$),
      switchMap(([data, equipmentsBySoaCode]) =>
        this.confirmDeselecting(
          data,
          equipmentsBySoaCode,
          new VehicleEquipmentCancelDeselectPackage(),
          'equipmentList.warningModal.enabledEquipmentOfDisabledPackageTitle',
          'equipmentList.warningModal.enabledEquipmentOfDisabledPackageFooter',
          'equipmentList.warningModal.deselect'
        )
      )
    )
  );

  addAftermarketEquipment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<VehicleEquipmentAftermarketItemCreate>(VehicleEquipmentActionTypes.VehicleEquipmentAftermarketItemCreate),
      switchMap(action =>
        this.identifiedVehicleService.createAftermarketEquipment(action.payload.command).pipe(
          map(
            response =>
              new fromVehicleEquipmentActions.VehicleEquipmentAftermarketItemCreated({
                response,
                caseId: action.payload.caseId,
              })
          ),
          catchError(err => of(new fromVehicleEquipmentActions.VehicleEquipmentAftermarketItemCreateError(err)))
        )
      )
    )
  );

  addedAftermarketEquipment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<VehicleEquipmentAftermarketItemCreated>(
        VehicleEquipmentActionTypes.VehicleEquipmentAftermarketItemCreated
      ),
      tap(() => {
        this.notification.success('equipmentList.addAftermarketSuccessMessage');
      }),
      map(action => new fromCasesActions.CaseLoadEquipments(action.payload.caseId))
    )
  );

  updateAftermarketEquipment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<VehicleEquipmentAftermarketItemUpdate>(VehicleEquipmentActionTypes.VehicleEquipmentAftermarketItemUpdate),
      switchMap(action =>
        this.identifiedVehicleService.updateVehicleAftermarketEquipment(action.payload.command).pipe(
          map(
            response =>
              new fromVehicleEquipmentActions.VehicleEquipmentAftermarketItemUpdated({
                response,
                caseId: action.payload.caseId,
              })
          ),
          catchError(err => of(new fromVehicleEquipmentActions.VehicleEquipmentAftermarketItemUpdateError(err)))
        )
      )
    )
  );

  updatedAftermarketEquipment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<VehicleEquipmentAftermarketItemUpdated>(
        VehicleEquipmentActionTypes.VehicleEquipmentAftermarketItemUpdated
      ),
      tap(() => {
        this.notification.success('equipmentList.updateAftermarketSuccessMessage');
      }),
      map(action => new fromCasesActions.CaseLoadEquipments(action.payload.caseId))
    )
  );

  vehicleEquipmentAftermarketItemDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType<VehicleEquipmentAftermarketItemDelete>(VehicleEquipmentActionTypes.VehicleEquipmentAftermarketItemDelete),
      switchMap(action =>
        this.identifiedVehicleService
          .deleteAftermarketEquipment(action.payload.identifiedVehicleId, action.payload.soaCode)
          .pipe(
            map(
              response => new fromVehicleEquipmentActions.VehicleEquipmentAftermarketItemDeleted(action.payload.caseId)
            ),
            catchError(err => of(new fromVehicleEquipmentActions.VehicleEquipmentAftermarketItemDeleteError(err)))
          )
      )
    )
  );

  vehicleEquipmentAftermarketItemDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType<VehicleEquipmentAftermarketItemDeleted>(
        VehicleEquipmentActionTypes.VehicleEquipmentAftermarketItemDeleted
      ),
      tap(() => {
        this.notification.success('equipmentList.deleteAftermarketSuccessMessage');
      }),
      map(action => new fromCasesActions.CaseLoadEquipments(action.payload))
    )
  );

  vehicleEquipmentUniversalCategoryCodesLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType<VehicleEquipmentUniversalCategoryCodesLoad>(
        VehicleEquipmentActionTypes.VehicleEquipmentUniversalCategoryCodesLoad
      ),
      switchMap(() =>
        this.universalCategoryViewService.getUniversalCategories().pipe(
          map(response => new fromVehicleEquipmentActions.VehicleEquipmentUniversalCategoryCodesLoaded(response)),
          catchError(err => of(new fromVehicleEquipmentActions.VehicleEquipmentUniversalCategoryCodesLoadError(err)))
        )
      )
    )
  );

  updateEquipmentColor$ = createEffect(() =>
    this.actions$.pipe(
      ofType<VehicleEquipmentColorUpdate>(VehicleEquipmentActionTypes.VehicleEquipmentColorUpdate),
      switchMap(action =>
        this.identifiedVehicleService.updateVehicleEquipmentColor(action.payload.command).pipe(
          map(
            response =>
              new fromVehicleEquipmentActions.VehicleEquipmentColorUpdated({
                response,
                caseId: action.payload.caseId,
              })
          ),
          catchError(() => of(new fromVehicleEquipmentActions.VehicleEquipmentColorUpdateError()))
        )
      )
    )
  );

  updatedEquipmentColor$ = createEffect(() =>
    this.actions$.pipe(
      ofType<VehicleEquipmentColorUpdated>(VehicleEquipmentActionTypes.VehicleEquipmentColorUpdated),
      map(action => new fromCasesActions.CaseLoadEquipments(action.payload.caseId))
    )
  );

  resetEquipmentListToDefault$ = createEffect(() =>
    this.actions$.pipe(
      ofType<VehicleResetEquipmentListToDefault>(VehicleEquipmentActionTypes.VehicleResetEquipmentListToDefault),
      withLatestFrom(this.casesFacade.activeCaseData$),
      switchMap(([, caseData]) =>
        this.identifiedVehicleService.resetEquipment({ aggregateRootId: caseData.identifiedVehicleId }).pipe(
          map(() => new fromVehicleEquipmentActions.VehicleResetEquipmentListToDefaultSuccess(caseData.id)),
          catchError(() => of(new fromVehicleEquipmentActions.VehicleResetEquipmentListToDefaultError()))
        )
      )
    )
  );

  resetEquipmentListToDefaultSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<VehicleResetEquipmentListToDefaultSuccess>(
        VehicleEquipmentActionTypes.VehicleResetEquipmentListToDefaultSuccess
      ),
      map(action => new fromCasesActions.CaseLoadEquipments(action.payload))
    )
  );

  /**
   * Start Formula Wizard if response from API meet requirements
   */
  vehicleEquipmentStartFormulaWizard$ = createEffect(() =>
    this.actions$.pipe(
      ofType<VehicleEquipmentUpdateIsEnabledSuccess>(
        VehicleEquipmentActionTypes.VehicleEquipmentUpdateIsEnabledSuccess
      ),
      filterIncompatible(),
      filter(action => action.payload.isEnabled),
      withLatestFrom(this.equipmentsBySoaCode$),
      // check if response meet formula wizard requirements
      map(([action, equipmentsBySoaCode]) => determineIfFormulaWizardCanStart(action, equipmentsBySoaCode)),
      // continue only when can start formula wizard
      filter(data => data.canStartFormulaWizard),
      map(data =>
        startFormulaWizard({
          payload: {
            caseId: data.caseId,
            vehicleId: data.vehicleId,
            mainEquipmentSoaCode: data.soaCode,
            steps: data.formulaBlocks,
          },
        })
      )
    )
  );

  vehicleEquipmentInvalidByFormulaWarning$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<VehicleEquipmentUpdateIsEnabledSuccess>(
          VehicleEquipmentActionTypes.VehicleEquipmentUpdateIsEnabledSuccess
        ),
        filterFormulaIncompatible(),
        filter(action => action.payload.errors.length === 0 && action.payload.formulaBlocks.length > 0),
        withLatestFrom(this.equipmentsBySoaCode$),
        filter(
          ([action, equipmentsBySoaCode]) => equipmentsBySoaCode[action.payload.soaCode].hasComplexFormula === false
        ),
        switchMap(([action, equipmentsBySoaCode]) => {
          return this.showConflictFormula(
            action.payload.formulaBlocks.map(f => f.formulaSoaCode),
            equipmentsBySoaCode,
            undefined,
            'equipmentList.warningModal.conflictFormulaTitle'
          );
        })
      ),
    { dispatch: false }
  );

  private confirmDeselecting<T extends Action>(
    confirmData: ConfirmData,
    equipmentsBySoaCode: Dictionary<EquipmentDto>,
    cancelAction: T,
    title: string,
    footer: string,
    confirmTitle: string
  ): Observable<VehicleEquipmentUpdateIsEnabled | T> {
    const soaCodesForEnabledPackagesWithContent = uniq(flatMap(confirmData.errors.map(error => error.soaCodes)));

    const enabledPackagesWithContent = soaCodesForEnabledPackagesWithContent.map(
      soaCode => equipmentsBySoaCode[soaCode]
    );

    return this.modalService
      .confirm(
        [title, '', ...enabledPackagesWithContent.map(calculateShortDesc), '', footer],
        confirmTitle,
        'common.cancel'
      )
      .pipe(
        map(confirmDeselected =>
          confirmDeselected
            ? new VehicleEquipmentUpdateIsEnabled({
                caseId: confirmData.caseId,
                command: {
                  aggregateRootId: confirmData.vehicleId,
                  ignoreValidationErrors: true,
                  isEnabled: confirmData.isEnabled,
                  soaCode: confirmData.soaCode,
                  validateOnly: false,
                  ignoreAllValidationErrors: false,
                },
              })
            : cancelAction
        )
      );
  }

  private confirmForce<T extends Action>(
    confirmData: ConfirmData,
    equipmentsBySoaCode: Dictionary<EquipmentDto>,
    cancelAction: T,
    title: string,
    footer: string
  ): Observable<VehicleEquipmentUpdateIsEnabled | T> {
    const soaCodesForEnabledPackagesWithContent = uniq(flatMap(confirmData.errors.map(error => error.soaCodes)));

    const enabledPackagesWithContent = soaCodesForEnabledPackagesWithContent.map(
      soaCode => equipmentsBySoaCode[soaCode]
    );

    return this.modalService
      .confirm(
        [title, '', ...enabledPackagesWithContent.map(calculateShortDesc), '', footer],
        'formulaWizard.buttonConfirm.force',
        'common.close'
      )
      .pipe(
        map(confirmDeselected =>
          confirmDeselected
            ? new VehicleEquipmentUpdateIsEnabled({
                caseId: confirmData.caseId,
                command: {
                  aggregateRootId: confirmData.vehicleId,
                  ignoreValidationErrors: false,
                  isEnabled: confirmData.isEnabled,
                  soaCode: confirmData.soaCode,
                  validateOnly: false,
                  ignoreAllValidationErrors: true,
                },
              })
            : cancelAction
        )
      );
  }

  private showOK<T extends Action>(
    confirmData: ConfirmData,
    equipmentsBySoaCode: Dictionary<EquipmentDto>,
    actionAfterConfirm: T,
    title: string
  ): Observable<T> {
    let infos = [];

    for (const error of confirmData.errors) {
      const inDebugMode = isDebugEnabled('formulaWizard');

      const enabledEquipmentDebugText = inDebugMode
        ? getEquipmentDebugText(equipmentsBySoaCode[error.sourceSoaCode])
        : '';

      const enabledEquipment = `"${enabledEquipmentDebugText}${equipmentsBySoaCode[error.sourceSoaCode]?.description}"`;
      const disabledStandardEquipment = error.soaCodes
        .map(x => {
          const disabledStandardEquipmentDebugText = inDebugMode ? getEquipmentDebugText(equipmentsBySoaCode[x]) : '';
          return `"${disabledStandardEquipmentDebugText}${equipmentsBySoaCode[x]?.description}"`;
        })
        .join(', ');

      const infoLine =
        `<strong>${disabledStandardEquipment}</strong>` +
        this.translateService.instant(
          'formulaWizard.standardEquipmentDeselectedInfo.equipmentIncompatibleWithOtherEquipment',
          {
            conflictingEquipmentList: enabledEquipment,
          }
        );

      infos.push(infoLine);
    }

    return this.modalService.ok(title, infos).pipe(map(() => actionAfterConfirm));
  }

  private showConflictFormula<T extends Action>(
    formulasSoaCodes: string[],
    equipmentsBySoaCode: Dictionary<EquipmentDto>,
    actionAfterConfirm: T,
    title: string
  ): Observable<T> {
    let infos = [];

    const inDebugMode = isDebugEnabled('formulaWizard');

    for (const formulaSoaCode of formulasSoaCodes) {
      const enabledEquipmentDebugText = inDebugMode ? getEquipmentDebugText(equipmentsBySoaCode[formulaSoaCode]) : '';

      const enabledEquipment = `"${enabledEquipmentDebugText}${equipmentsBySoaCode[formulaSoaCode]?.description}"`;

      const infoLine = this.translateService.instant('equipmentList.warningModal.conflictFormulaDescription', {
        conflictingFormula: enabledEquipment,
      });

      infos.push(infoLine);
    }

    return this.modalService.ok(title, infos).pipe(map(() => actionAfterConfirm));
  }

  constructor(
    private actions$: Actions,
    private identifiedVehicleService: IdentifiedVehicleService,
    private notification: BasicNotificationsService,
    private universalCategoryViewService: UniversalCategoryViewService,
    private casesFacade: CasesFacade,
    private translateService: TranslateService,
    private modalService: ModalService
  ) {}
}

interface ConfirmData {
  soaCode: string;
  errors: { sourceSoaCode: string; soaCodes: Array<string> }[];
  caseId: string;
  vehicleId: string;
  isEnabled: boolean;
}

function filterFormulaIncompatible(): OperatorFunction<
  VehicleEquipmentUpdateIsEnabledSuccess,
  VehicleEquipmentUpdateIsEnabledSuccess
> {
  return input$ =>
    input$.pipe(
      filter(
        action =>
          !Boolean(
            action.payload.errors.find(error => error.error === EquipmentError.EnabledFormulaWithDisabledEquipment)
          )
      )
    );
}

function filterIncompatible(): OperatorFunction<
  VehicleEquipmentUpdateIsEnabledSuccess,
  VehicleEquipmentUpdateIsEnabledSuccess
> {
  return input$ =>
    input$.pipe(
      filterFormulaIncompatible(),
      filter(
        action => !Boolean(action.payload.errors.find(error => error.error === EquipmentError.IncompatibleEquipment))
      )
    );
}

export function determineIfFormulaWizardCanStart(
  action: VehicleEquipmentUpdateIsEnabledSuccess,
  equipmentsBySoaCode: Dictionary<EquipmentDto>
): CanStartFormulaWizard {
  {
    const hasComplexFormula = equipmentsBySoaCode[action.payload.soaCode]?.hasComplexFormula;
    const hasBlocks = action.payload.formulaBlocks?.length > 0;

    const hasNoSignificantErrors =
      action.payload.errors.filter(x => x.error !== EquipmentError.UnmetFormulaOfEnabledEquipment).length === 0;

    // start formula only when there is formulaBlocks and hasComplexFormula and (no error or only UnmetFormula error)
    return {
      canStartFormulaWizard: hasBlocks && hasComplexFormula && hasNoSignificantErrors,
      caseId: action.payload.caseId,
      vehicleId: action.payload.vehicleId,
      formulaBlocks: action.payload.formulaBlocks,
      soaCode: action.payload.soaCode,
    };
  }
}
