/**
 * Dealer Network AdminService API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { CountryDto } from '../model/countryDto';
import { CountryListItemDto } from '../model/countryListItemDto';
import { CountryVinThresholdDto } from '../model/countryVinThresholdDto';
import { FeaturesConfigurationDto } from '../model/featuresConfigurationDto';
import { LoginCountryDto } from '../model/loginCountryDto';
import { VPError } from '../model/vPError';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CountryViewService {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Lazy check AmsAction:GET/api/CountryView/{countryId} AmsResource:{country}/_* 
     * @param countryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public get(countryId: string, observe?: 'body', reportProgress?: boolean): Observable<CountryDto>;
    public get(countryId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CountryDto>>;
    public get(countryId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CountryDto>>;
    public get(countryId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (countryId === null || countryId === undefined) {
            throw new Error('Required parameter countryId was null or undefined when calling get.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CountryDto>(`${this.basePath}/api/CountryView/${encodeURIComponent(String(countryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param branchId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBranchCountry(branchId: string, observe?: 'body', reportProgress?: boolean): Observable<CountryDto>;
    public getBranchCountry(branchId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CountryDto>>;
    public getBranchCountry(branchId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CountryDto>>;
    public getBranchCountry(branchId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (branchId === null || branchId === undefined) {
            throw new Error('Required parameter branchId was null or undefined when calling getBranchCountry.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CountryDto>(`${this.basePath}/api/CountryView/Branch/${encodeURIComponent(String(branchId))}/Country`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:GET/api/CountryView/VinThreshold/{countryId} AmsResource:{country}/_* 
     * @param countryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCountryVinThreshold(countryId: string, observe?: 'body', reportProgress?: boolean): Observable<CountryVinThresholdDto>;
    public getCountryVinThreshold(countryId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CountryVinThresholdDto>>;
    public getCountryVinThreshold(countryId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CountryVinThresholdDto>>;
    public getCountryVinThreshold(countryId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (countryId === null || countryId === undefined) {
            throw new Error('Required parameter countryId was null or undefined when calling getCountryVinThreshold.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CountryVinThresholdDto>(`${this.basePath}/api/CountryView/VinThreshold/${encodeURIComponent(String(countryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param countryCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFeaturesConfiguration(countryCode: string, observe?: 'body', reportProgress?: boolean): Observable<FeaturesConfigurationDto>;
    public getFeaturesConfiguration(countryCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FeaturesConfigurationDto>>;
    public getFeaturesConfiguration(countryCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FeaturesConfigurationDto>>;
    public getFeaturesConfiguration(countryCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (countryCode === null || countryCode === undefined) {
            throw new Error('Required parameter countryCode was null or undefined when calling getFeaturesConfiguration.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<FeaturesConfigurationDto>(`${this.basePath}/api/CountryView/FeaturesConfiguration/${encodeURIComponent(String(countryCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getList(observe?: 'body', reportProgress?: boolean): Observable<Array<CountryListItemDto>>;
    public getList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CountryListItemDto>>>;
    public getList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CountryListItemDto>>>;
    public getList(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CountryListItemDto>>(`${this.basePath}/api/CountryView/List`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param countryUrl 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUrlCountry(countryUrl: string, observe?: 'body', reportProgress?: boolean): Observable<LoginCountryDto>;
    public getUrlCountry(countryUrl: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LoginCountryDto>>;
    public getUrlCountry(countryUrl: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LoginCountryDto>>;
    public getUrlCountry(countryUrl: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (countryUrl === null || countryUrl === undefined) {
            throw new Error('Required parameter countryUrl was null or undefined when calling getUrlCountry.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<LoginCountryDto>(`${this.basePath}/api/CountryView/Login/${encodeURIComponent(String(countryUrl))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserCountry(observe?: 'body', reportProgress?: boolean): Observable<CountryDto>;
    public getUserCountry(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CountryDto>>;
    public getUserCountry(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CountryDto>>;
    public getUserCountry(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CountryDto>(`${this.basePath}/api/CountryView/UserCountry`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
