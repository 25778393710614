import { Component, ChangeDetectionStrategy, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vpfa-reset-button',
  templateUrl: './reset-button.component.html',
  styleUrls: ['./reset-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetButtonComponent {
  @Input() tooltip: string;
  @Input() disabled: boolean;
  @Output() reset = new EventEmitter();
}
