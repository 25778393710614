import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { FormulaWizardState } from './formula-wizard.reducer';
import { formulaWizardQuery } from './formula-wizard.selectors';
import { pairwise, filter, map, distinctUntilChanged } from 'rxjs/operators';
import { clearFormulaWizard, validateStep, previousStep } from './formula-wizard.actions';
import { isNil } from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { EquipmentStateChange } from '@vpfa/rest-api/valuation';

@Injectable()
export class FormulaWizardFacade {
  get startingNewFormulaWizard$() {
    return this.store.pipe(
      select(formulaWizardQuery.isFormulaWizardStarted),
      pairwise(),
      filter(([a, b]) => !a && b)
    );
  }

  get currentStep$() {
    return this.store.pipe(select(formulaWizardQuery.currentStep));
  }

  get equipmentList$() {
    return this.store.pipe(select(formulaWizardQuery.equipmentList));
  }

  get currentStepNo$() {
    return this.store
      .select(formulaWizardQuery.currentStepIndex)
      .pipe(map(currentIndex => (isNil(currentIndex) ? null : currentIndex + 1)));
  }

  get lastStepNo$() {
    return this.store.select(formulaWizardQuery.stepsCount);
  }

  get currentFormulaNo$() {
    return this.store
      .select(formulaWizardQuery.currentFormulaIndex)
      .pipe(map(currentIndex => (isNil(currentIndex) ? null : currentIndex + 1)));
  }

  get lastFormulaNo$() {
    return this.store.select(formulaWizardQuery.formulaCount);
  }

  get currentStepIndex$() {
    return this.store.select(formulaWizardQuery.currentStepIndex);
  }

  get currentFormulaIndex$() {
    return this.store.select(formulaWizardQuery.currentFormulaIndex);
  }

  get hasPreviousStep$() {
    return combineLatest([this.currentStepIndex$, this.currentFormulaIndex$]).pipe(
      map(([stepIndex, formulaIndex]) => stepIndex !== 0 || formulaIndex !== 0)
    );
  }

  get currentStepIsLast$() {
    return this.store.select(formulaWizardQuery.currentStepIsLast);
  }

  get currentStepParentSoaCode$(): Observable<string> {
    return this.store.pipe(select(formulaWizardQuery.currentStepParent));
  }

  get closed$(): Observable<boolean> {
    return this.store.pipe(select(formulaWizardQuery.closed), distinctUntilChanged());
  }

  get isValidation$(): Observable<boolean> {
    return this.store.pipe(select(formulaWizardQuery.isValidation));
  }

  get isSaving$(): Observable<boolean> {
    return this.store.pipe(select(formulaWizardQuery.isSaving));
  }

  get changesThatAffectsSelection$() {
    return this.store.pipe(select(formulaWizardQuery.changesThatAffectsSelection));
  }

  clearFormulaWizard() {
    this.store.dispatch(clearFormulaWizard());
  }

  validateStep(
    mainChanges: EquipmentStateChange[],
    sideChanges?: EquipmentStateChange[],
    ignoreValidationErrors = false,
    force = false
  ) {
    this.store.dispatch(validateStep({ mainChanges, sideChanges, ignoreValidationErrors, force }));
  }

  previousStep() {
    this.store.dispatch(previousStep());
  }

  constructor(private store: Store<FormulaWizardState>) {}
}
