import { Component, Input } from '@angular/core';

@Component({
  selector: 'vpfa-vehicle-list-full-technical-data-cell',
  templateUrl: './vehicle-list-full-technical-data-cell.component.html',
  styleUrls: ['./vehicle-list-full-technical-data-cell.component.scss']
})
export class VehicleListFullTechnicalDataCellComponent  {
  @Input() label: string;
  @Input() value: string | number;
}
