import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DealerBroadcastsDataModule } from '@vpfa/dealer/broadcasts/data';
import { UiKitModule } from '@vpfa/ui-kit';
import { TranslateModule } from '@ngx-translate/core';
import { FlexModule } from '@ngbracket/ngx-layout';
import { UiKitModalsModule } from '@vpfa/ui-kit/modals';
import { WebConfirmAdvertisedImagesModalComponent } from './components/web-confirm-advertised-images-modal/web-confirm-advertised-images-modal.component';
import { ConfirmAdvertisedImagesEffects } from './+state/confirm-advertised-images.effects';
import { EffectsModule } from '@ngrx/effects';
import { SharedMobileViewModule } from '@vpfa/shared/mobile-view';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { DealerGalleryManagerDataModule } from '@vpfa/dealer/gallery-manager/data';
import { SharedPipesModule } from '@vpfa/shared/pipes';
import * as fromConfirmAdvertisedImages from './+state/confirm-advertised-images.reducer';
import { StoreModule } from '@ngrx/store';
import { MobileConfirmAdvertisedImagesModalComponent } from './components/mobile-confirm-advertised-images-modal/mobile-confirm-advertised-images-modal.component';
import { CommonConfirmAdvertisedImagesModalComponent } from './components/common-confirm-advertised-images-modal/common-confirm-advertised-images-modal.component';
import { UiKitMobileModule } from '@vpfa/ui-kit-mobile';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';

@NgModule({
  imports: [
    CommonModule,
    DealerBroadcastsDataModule,
    DealerGalleryManagerDataModule,
    UiKitModule,
    TranslateModule,
    FlexModule,
    UiKitModalsModule,
    SharedMobileViewModule,
    NzSpinModule,
    NzPopconfirmModule,
    UiKitMobileModule,
    SharedPipesModule,
    StoreModule.forFeature(
      fromConfirmAdvertisedImages.CONFIRM_ADVERTISED_IMAGES_FEATURE_KEY,
      fromConfirmAdvertisedImages.reducer
    ),
    EffectsModule.forFeature([ConfirmAdvertisedImagesEffects]),
  ],
  declarations: [
    WebConfirmAdvertisedImagesModalComponent,
    MobileConfirmAdvertisedImagesModalComponent,
    CommonConfirmAdvertisedImagesModalComponent,
  ],
  exports: [WebConfirmAdvertisedImagesModalComponent, MobileConfirmAdvertisedImagesModalComponent],
})
export class ConfirmAdvertisedImagesModule {}
