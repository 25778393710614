export const endpointsWithSensitiveData = [
  '/api/BroadcastConnectionConfiguration/UpdateCountryBroadcastConnectionConfigurationPassword',
  '/api/Twinner/Password',
  '/api/auth/CheckPassword',
  '/api/InsuranceClaimingConfiguration/gdv/password',
  '/api/Auth',
  '/api/auth/changePassword',
  '/api/User/changeTemporaryPassword',
  '/api/User',
  '/api/Experian/Credentials'
];
