import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'vpfa-mobile-total-summary',
  templateUrl: './mobile-total-summary.component.html',
  styleUrls: ['./mobile-total-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileTotalSummaryComponent  {

  @Input() totalValue: string;
  @Input() notAvailable: boolean;

}
