import { TranslateService } from '@ngx-translate/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { CreateTemplateModel } from '../models/create-template.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { notInListValidator, notInListValidatorKey } from '@vpfa/shared/validators';
import { pdfTemplateModules } from './pdf-template-modules';
import {
  pdfReProNewTemplate,
  pdfReProNewTemplateValue,
  pdfTemplateNamePattern,
  templateNameMaxLength,
  templateDescriptionMaxLength,
} from '../constants';

@Directive()
export abstract class TemplateBaseModal implements OnDestroy {
  @Input() set show(visible: boolean) {
    if (!visible) {
      this.resetForm();
    }
    this.visible = visible;
  }

  @Input() loading = false;
  private usedNames$ = new BehaviorSubject<string[]>([]);
  @Input() set usedNames(usedNames: string[] | null) {
    this.usedNames$.next(usedNames ?? []);
  }
  @Output() closeAction = new EventEmitter();
  @Output() templateDataChange = new EventEmitter<CreateTemplateModel>();

  templateForm: UntypedFormGroup;
  nameControl: AbstractControl;
  descriptionControl: AbstractControl;
  templateModuleArray: UntypedFormArray;
  visible = false;
  pattern = pdfTemplateNamePattern;
  templateNameMaxLength = templateNameMaxLength;
  templateDescriptionMaxLength = templateDescriptionMaxLength;
  nameValidationErrors: { error: string; errorMsg: string }[];
  descriptionValidationErrors: { error: string; errorMsg: string }[];

  protected onDestroy$ = new Subject<void>();

  constructor(protected fb: UntypedFormBuilder, private translateService: TranslateService) {
    this.templateForm = this.fb.group({
      name: [null],
      description: [null],
      pdfTemplateModules: this.fb.array([]),
    });

    this.nameControl = this.templateForm.get('name');
    this.descriptionControl = this.templateForm.get('description');
    this.templateModuleArray = this.templateForm.controls.pdfTemplateModules as UntypedFormArray;

    this.addModules();
    this.setUpValidation();
    this.setNameValidationErrorsList();

    this.nameControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
      const trimmed = value ? value.trimStart() : value;

      if (trimmed !== value) {
        this.nameControl.setValue(trimmed);
      }
    });
    this.descriptionControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
      const trimmed = value ? value.trimStart() : value;

      if (trimmed !== value) {
        this.descriptionControl.setValue(trimmed);
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  close() {
    this.closeAction.emit();
  }

  saveTemplate() {
    if (this.templateForm.valid) {
      localStorage.setItem(pdfReProNewTemplate, pdfReProNewTemplateValue);
      const formValue = this.templateForm.value;
      formValue.name = formValue.name.trim();
      this.templateDataChange.emit(formValue);
    }
  }

  protected addModules() {
    pdfTemplateModules.forEach(templateModule =>
      this.templateModuleArray.push(this.fb.group({ templateModuleEnum: [templateModule], available: false }))
    );
  }

  protected resetModules() {
    this.templateModuleArray.controls.forEach((templateModule, index) => {
      templateModule.patchValue({ templateModuleEnum: pdfTemplateModules[index], available: false });
    });
  }

  protected setNameValidationErrorsList() {
    this.nameValidationErrors = [
      { error: 'required', errorMsg: this.translateService.instant('admin.printTemplates.modal.nameIsRequired') },
      {
        error: 'maxlength',
        errorMsg: this.translateService.instant('admin.printTemplates.modal.nameMaxLengthExceeded', {
          maxLength: this.templateNameMaxLength,
        }),
      },
      { error: 'pattern', errorMsg: this.translateService.instant('admin.printTemplates.modal.allowedCharacters') },
      {
        error: notInListValidatorKey,
        errorMsg: this.translateService.instant('admin.printTemplates.modal.nameIsAlreadyUsed'),
      },
    ];
    this.descriptionValidationErrors = [
      {
        error: 'maxlength',
        errorMsg: this.translateService.instant('admin.printTemplates.modal.descriptionMaxLengthExceeded'),
      },
      { error: 'pattern', errorMsg: this.translateService.instant('admin.printTemplates.modal.allowedCharacters') },
    ];
  }

  protected setUpValidation() {
    this.nameControl.setValidators([
      Validators.required,
      Validators.maxLength(this.templateNameMaxLength),
      Validators.pattern(this.pattern),
      notInListValidator(this.usedNames$),
    ]);
    this.descriptionControl.setValidators([
      Validators.maxLength(this.templateDescriptionMaxLength),
      Validators.pattern(this.pattern),
    ]);
  }

  abstract resetForm();
}
