import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { LightDamageAreaDto, LightDamagePartDto, UpdateCaseLightDamagePartCommand } from '@vpfa/rest-api/valuation';
import { getPartsFromArea } from '../../../utils/estimator-parts-helpers';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { costValidator, damageValidator } from '../../../utils/estimator-validators';
import { SelectOption } from '@vpfa/ui-kit';
import { values, isNil } from 'lodash';
import { BasicNotificationsService } from '@vpfa/shared/notifications';

@Component({
  selector: 'vpfa-repair-and-maintenance-modal',
  templateUrl: './repair-and-maintenance-modal.component.html',
  styleUrls: ['./repair-and-maintenance-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RepairAndMaintenanceModalComponent {
  private _initialFormState: object;
  @Input() set show(visible: boolean) {
    this.visible = visible;
    if (!visible) {
      this.generateEstimatorModel(this._areas);
    } else {
      this._initialFormState = this.estimatorForm.getRawValue();
    }
    this.submitted = false;
  }

  @Input() loading = false;

  @Input() set areas(newAreas: LightDamageAreaDto[]) {
    this._areas = newAreas;
    this.generateEstimatorModel(this._areas);
  }

  @Output() closeAction = new EventEmitter();
  @Output() saveAction = new EventEmitter<UpdateCaseLightDamagePartCommand[]>();

  estimatorAreas: LightDamageAreaDto[] = [];
  visible = false;
  estimatorForm: UntypedFormGroup = this.fb.group({});
  submitted = false;

  private _areas: LightDamageAreaDto[] = [];
  private _parts: LightDamagePartDto[] = [];

  constructor(private fb: UntypedFormBuilder, private translatingBasicNotificationsService: BasicNotificationsService) {}

  private generateEstimatorModel(newAreas: LightDamageAreaDto[]) {
    if (newAreas && newAreas.length) {
      this.estimatorAreas = newAreas;
      this._parts = getPartsFromArea(this.estimatorAreas);
      this.generateForm();
    }
  }

  close() {
    this.closeAction.emit();
    if (this.estimatorForm.dirty) {
      this.estimatorForm.reset(this._initialFormState);
    }
  }

  save() {
    const command: UpdateCaseLightDamagePartCommand[] = values(this.estimatorForm.getRawValue()).map(value => {
      return {
        ...value,
        selectedDamageTypeId: value.selectedDamageTypeId ? value.selectedDamageTypeId.value : null,
      };
    });
    this.saveAction.emit(command);
    this.estimatorForm.markAsPristine();
  }

  onSubmit() {
    this.submitted = true;
    this.estimatorForm.markAsPristine();
    if (this.estimatorForm.valid) {
      this.save();
    } else {
      this.translatingBasicNotificationsService.error(
        'damageEstimatorModal.estimationValidationError',
        'damageEstimatorModal.estimationValidationErrorDesc'
      );
    }
  }

  private generateForm() {
    this.estimatorForm = this.fb.group({});
    this._parts.forEach(part => {
      this.estimatorForm.addControl(
        part.id,
        this.fb.group({
          id: part.id,
          cost: [part.cost === 0 && isNil(part.selectedDamageTypeId) ? null : part.cost, { validators: costValidator }],
          selectedDamageTypeId: [this.getDamageType(part), { validators: damageValidator }],
          isIncludedInValuation: part.isIncluded,
        })
      );
    });
  }

  private getDamageType(part: LightDamagePartDto): SelectOption {
    if (!part.selectedDamageTypeId) {
      return null;
    }
    const damageType = part.damageTypes.find(dm => dm.id === part.selectedDamageTypeId);
    return {
      name: damageType.name,
      value: damageType.id,
    };
  }
}
