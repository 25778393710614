<vpfa-mobile-clickable>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <div [fxFlex]="showPower ? '70%' : '50%'" fxLayout="column" (click)="cellClick()">
      <span class="mobile-dark">{{ vehicle?.make | lT }} {{ vehicle?.edition | lT }}</span>
      <span class="mobile-dark" *ngIf="showPower">
        <span *ngIf="isPowerVisible">
          {{ vehicle?.powerInKW?.value }} ({{ vehicle?.powerInKW?.unit | uppercase }}) &nbsp;&#47;&nbsp;
          {{ vehicle?.powerInHP?.value }} ({{ vehicle?.powerInHP?.unit | uppercase }})
        </span>
        <span *ngIf="!isPowerVisible">{{ 'common.noValue' | translate }}</span>
      </span>
      <span class="mobile-gray">
        {{ vehicle?.bodyType | lT }}
        <span *ngIf="isDoorsVisible"
          >({{ 'stock.list.doors' | translate | lowercase }}: {{ vehicle?.numberDoors | lT }})</span
        >
      </span>
      <span class="mobile-gray">{{ vehicle?.transmissionType | lT }}</span>
      <span class="mobile-gray">{{ vehicle?.technicalData?.propulsion?.fuelType?.texts | lT }}</span>
      <span class="mobile-gray">{{ prodStart | lT }} - {{ prodEnd | lT }}</span>
    </div>
    <div [fxFlex]="showPower ? '30%' : '50%'" fxLayout="column" fxLayoutAlign="flex-end flex-end">
      <div class="space-holder" fxFlex="grow" (click)="cellClick()"></div>
      <span
        [ngClass]="!disableSpecialButton || loadingTechSpec ? 'tech-specs' : 'tech-specs-disabled'"
        (click)="techSpecClick()"
        >{{ 'identification.vehicles.technicalSpecification' | translate }}
      </span>
    </div>
  </div>
</vpfa-mobile-clickable>
