import { Component, Input } from '@angular/core';
import { Body } from '@vpfa/rest-api/valuation';
import { UntypedFormGroup } from '@angular/forms';
import { FeaturesTechnicalDataConfiguration } from '@vpfa/locale';

@Component({
  selector: 'vpfa-full-technical-data-common-information',
  templateUrl: './full-technical-data-common-information.component.html',
  styleUrls: ['./full-technical-data-common-information.component.scss'],
})
export class FullTechnicalDataCommonInformationComponent {
  @Input() technicalDataBody: Body;
  @Input() formGroup: UntypedFormGroup;
  @Input() vehicleType: string;
  @Input() featureConfig: FeaturesTechnicalDataConfiguration;
  @Input() errors: ReadonlyArray<{ error: string; errorMsg: string }>;
}
