import { LightDamagePartDto, LightDamageAreaDto } from '@vpfa/rest-api/valuation';

export function getPartsTotalCost(parts: LightDamagePartDto[]) {
  return parts ? parts.filter(part => part.isIncluded).map(part => part.cost).reduce((prev, next) => prev + next, 0) : 0;
}

export function getPartsFromArea(areas: LightDamageAreaDto[]) {
  let parts: LightDamagePartDto[] = [];
  if (areas) {
    areas.forEach(area => {
      parts = [
        ...parts,
        ...area.parts,
        ...getPartsFromArea(area.subAreas)
      ];
    });
  }
  return parts;
}