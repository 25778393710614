import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldValidationContainerComponent } from './field-validation-container/field-validation-container.component';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { UtilsModule } from '@vpfa/utils';
import { MobileFieldValidationContainerComponent } from './components/mobile-field-validation-container/mobile-field-validation-container.component';

@NgModule({
  imports: [CommonModule, NzPopoverModule, UtilsModule],
  declarations: [FieldValidationContainerComponent, MobileFieldValidationContainerComponent],
  exports: [FieldValidationContainerComponent, MobileFieldValidationContainerComponent],
})
export class UiKitUikitValidationModule {}
