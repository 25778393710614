import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'vpfa-expanding-header',
  templateUrl: './expanding-header.component.html',
  styleUrls: ['./expanding-header.component.scss'],
})
export class ExpandingHeaderComponent {
  @Input() text: string;
  @Input() tooltipContent: string | TemplateRef<void>;
  @Input() marginBottomMobile = '15px';
}
