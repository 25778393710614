import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'vpfa-valuation-update-modal',
  templateUrl: './valuation-update-modal.component.html',
  styleUrls: ['./valuation-update-modal.component.scss'],
})
export class ValuationUpdateModalComponent {
  @Input() set show(visible: boolean) {
    this.visible = visible;
  }
  @Input() loading = false;
  @Input() vinCallsLeft = 0;
  @Input() vinCallsModel: string;
  @Input() modalName: string = 'Vin Update';
  @Input() titleLabel: string = 'vehicleOverviewData.vinUpdateModal.title';
  @Input() contentLabel: string = 'vehicleOverviewData.vinUpdateModal.content';
  @Input() updateButtonLabel: string = 'vehicleOverviewData.vinUpdateModal.updateButton';
  @Input() createNewValuationLabel: string = 'vehicleOverviewData.vinUpdateModal.createNewValuation';
  @Input() createNewValuationDisabled: boolean = true;

  @Output() closeAction = new EventEmitter();
  @Output() updateWithoutRevaluation = new EventEmitter();
  @Output() updateWithRevaluation = new EventEmitter();
  @Output() cancel = new EventEmitter();

  visible = false;
}
