<a
  [attr.id]="fieldName + 'NumberRangeFilterSelectedRangeText'"
  class="ssm-input d-flex justify-content-between align-items-center"
  [ngClass]="{ 'has-focus': this.visible }"
  nz-dropdown
  nzTrigger="click"
  [nzBackdrop]="true"
  [nzDropdownMenu]="menu"
  [(nzVisible)]="visible"
  (nzVisibleChange)="onVisibleChange($event)"
>
  <span
    class="selected-range"
    title="{{ initialValueFrom | numberLocale }} &mdash; {{ initialValueTo | numberLocale }}"
  >
    <ng-container *ngIf="!isNil(initialValueFrom) || !isNil(initialValueTo)">
      {{ initialValueFrom | numberLocale }} &mdash; {{ initialValueTo | numberLocale }}
    </ng-container>
  </span>
  <span class="suffix">
    <span nz-icon nzType="down" nzTheme="outline"></span>
  </span>
</a>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <div [attr.id]="fieldName + 'NumberRangeFilterBody'" class="filter-body" [formGroup]="formGroup">
    <div class="filter-row">
      <span class="label">{{ 'filters.from' | translate }}:</span>
      <vpfa-field-container
        class="field-container"
        [isCurrency]="isCurrency"
        [isNumber]="true"
        [isText]="false"
        [displayErrors]="true"
        [control]="formGroup.controls.from"
        [errorMessages]="[
          {
            error: 'max',
            errorMsg: 'common.forms.maximumValue' | translate: { maxValue: maxValue },
          },
          {
            error: 'min',
            errorMsg: 'common.forms.minimumValue' | translate: { minValue: minValue },
          },
          {
            error: 'fromTo',
            errorMsg: 'filters.validation.fromMustBeLessOrEqualThanTo' | translate,
          },
        ]"
        [errorMsgVertPosition]="'inside'"
        [heightCompensationPx]="errorHeightCompensationPx"
      >
        <vpfa-number-field
          [attr.id]="fieldName + 'NumberRangeFilterFrom'"
          #firstInput
          fcName="from"
          fieldWidth="100%"
          [parentFormGroup]="formGroup"
          [isCurrency]="isCurrency"
          [currencyHintMode]="CURRENCY_DISPLAY_FORMAT.SYMBOL"
          [maxFractionNumber]="maxFractionNumber"
          [allowClear]="false"
        ></vpfa-number-field>
      </vpfa-field-container>
    </div>

    <div class="filter-row">
      <span class="label">{{ 'filters.to' | translate }}:</span>
      <vpfa-field-container
        class="field-container"
        [isCurrency]="isCurrency"
        [isNumber]="true"
        [isText]="false"
        [displayErrors]="true"
        [control]="formGroup.controls.to"
        [errorMessages]="[
          {
            error: 'max',
            errorMsg: 'common.forms.maximumValue' | translate: { maxValue: maxValue },
          },
          {
            error: 'min',
            errorMsg: 'common.forms.minimumValue' | translate: { minValue: minValue },
          },
        ]"
        [errorMsgVertPosition]="'inside'"
        [heightCompensationPx]="errorHeightCompensationPx"
      >
        <vpfa-number-field
          [attr.id]="fieldName + 'NumberRangeFilterTo'"
          fcName="to"
          fieldWidth="100%"
          [parentFormGroup]="formGroup"
          [isCurrency]="isCurrency"
          [currencyHintMode]="CURRENCY_DISPLAY_FORMAT.SYMBOL"
          [maxFractionNumber]="maxFractionNumber"
          [allowClear]="false"
        ></vpfa-number-field>
      </vpfa-field-container>
    </div>

    <vpfa-action-button
      [attr.id]="fieldName + 'NumberRangeFilterApplyBtn'"
      class="submit-button"
      [border]="false"
      [outline]="true"
      [size]="'small'"
      [textAlign]="'right'"
      [fullWidth]="false"
      (click)="onApplyFilters()"
    >
      {{ 'filters.applyRangeFilter' | translate }}
    </vpfa-action-button>
  </div>
</nz-dropdown-menu>
