import { Component, Input } from '@angular/core';
import { Weights } from '@vpfa/rest-api/valuation';
import { FeaturesTechnicalDataConfiguration } from '@vpfa/locale';

@Component({
  selector: 'vpfa-full-technical-data-weights-information',
  templateUrl: './full-technical-data-weights-information.component.html',
  styleUrls: ['./full-technical-data-weights-information.component.scss'],
})
export class FullTechnicalDataWeightsInformationComponent {
  @Input() technicalDataWeights: Weights;
  @Input() featureConfig: FeaturesTechnicalDataConfiguration;
}
