const energyLabelValues: { [key: string]: MinMax } = {
  A: { min: 0, max: 0 },
  B: { min: 1, max: 95 },
  C: { min: 96, max: 115 },
  D: { min: 116, max: 135 },
  E: { min: 136, max: 155 },
  F: { min: 156, max: 175 },
  G: { min: 176, max: Infinity },
};

type MinMax = { min: number; max: number };

export function calculateCo2Certificate(co2Value: string | number | null | undefined): string | null {
  if (co2Value === null || co2Value === undefined) {
    return null;
  }

  let value = Number(co2Value);

  for (const [label, values] of Object.entries(energyLabelValues)) {
    if (value >= values.min && value <= values.max) {
      return label;
    }
  }

  return null;
}
