import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'vpfa-inline-loader',
  templateUrl: './inline-loader.component.html',
  styleUrls: ['./inline-loader.component.scss'],
})
export class InlineLoaderComponent implements OnInit {
  @Input() displayContent = true;
  @Input() isLoading = true;
  constructor() {}

  ngOnInit() {}
}
