import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoadUserContext, ProfileFacade } from '@vpfa/profile/data';
import {
  ExperianService,
  ExperianViewService,
  UserService,
  UserSettingsService,
  AuthService,
  ChangePasswordCommandHandlerResponse,
} from '@vpfa/rest-api/admin';
import { BasicNotificationsService } from '@vpfa/shared/notifications';
import { of } from 'rxjs';
import { catchError, map, mapTo, switchMap, tap } from 'rxjs/operators';
import {
  ChangePassword,
  ChangePasswordSuccess,
  fromUserProfileActions,
  UpdateUserContentLanguage,
  UpdateUserDefaultLanguage,
  UpdateUserProfile,
  UpdateUserProfileSuccess,
  UpdateUserUILanguage,
  UserProfileActionTypes,
  UserSettingsUpdated,
  LoadExperianCredentials,
  UpdateExperianCredentials,
  UpdateExperianCredentialsSuccess,
} from './user-profile.actions';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { SetTokenFromDto } from 'libs/auth/src/lib/+state/actions/token.actions';

@Injectable()
export class UserProfileEffects {
   updateDefaultLanguage$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateUserDefaultLanguage>(UserProfileActionTypes.UpdateUserDefaultLanguage),
    switchMap(action =>
      this.userSettingsService.updateDefaultLanguage(action.payload).pipe(
        map(response => new fromUserProfileActions.UserDefaultLanguageUpdated()),
        catchError(err => of(new fromUserProfileActions.UserDefaultLanguageUpdateError(err.error)))
      )
    )
  ));

   updateUILanguage$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateUserUILanguage>(UserProfileActionTypes.UpdateUserUILanguage),
    switchMap(action =>
      this.userSettingsService.updateDefaultLanguage(action.payload).pipe(
        map(() => new fromUserProfileActions.UserUILanguageUpdated()),
        catchError(err => of(new fromUserProfileActions.UserUILanguageUpdateError(err.error)))
      )
    )
  ));

   updateContentLanguage$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateUserContentLanguage>(UserProfileActionTypes.UpdateUserContentLanguage),
    switchMap(action =>
      this.userSettingsService.updateDefaultLanguage(action.payload).pipe(
        map(() => new fromUserProfileActions.UserContentLanguageUpdated()),
        catchError(err => of(new fromUserProfileActions.UserContentLanguageUpdateError(err.error)))
      )
    )
  ));

   defaultLanguageUpdated$ = createEffect(() => this.actions$.pipe(
    ofType(UserProfileActionTypes.UserDefaultLanguageUpdated),
    mapTo(new LoadUserContext('userProfile.settings.defaultLanguagesUpdated'))
  ));

   contentLanguageUpdated$ = createEffect(() => this.actions$.pipe(
    ofType(UserProfileActionTypes.UserContentLanguageUpdated),
    mapTo(new LoadUserContext('userProfile.settings.contentLanguagesUpdated'))
  ));

   uILanguageUpdated$ = createEffect(() => this.actions$.pipe(
    ofType(UserProfileActionTypes.UserUILanguageUpdated),
    mapTo(new LoadUserContext('userProfile.settings.uILanguagesUpdated'))
  ));

   userSettingsUpdated$ = createEffect(() => this.actions$.pipe(
    ofType(UserProfileActionTypes.UserDefaultLanguageUpdated),
    mapTo(new UserSettingsUpdated())
  ));

   changePassword$ = createEffect(() => this.actions$.pipe(
    ofType<ChangePassword>(UserProfileActionTypes.ChangePassword),
    switchMap(action =>
      this.httpClient.put(`${environment.apiGateway}/api/auth/changePassword`, action.payload).pipe(
        map(
          (res: ChangePasswordCommandHandlerResponse) =>
            new fromUserProfileActions.ChangePasswordSuccess(res?.changePasswordResult)
        ),
        catchError((res: HttpErrorResponse) => of(new fromUserProfileActions.ChangePasswordError(res)))
      )
    )
  ));

   changePasswordSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<ChangePasswordSuccess>(UserProfileActionTypes.ChangePasswordSuccess),
    tap(_ =>
      this.translatingBasicNotificationsService.success('userProfile.changePasswordModal.passwordHasBeenChanged')
    )
  ), { dispatch: false });

   updateUserTokenAfterChangePasswordSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<ChangePasswordSuccess>(UserProfileActionTypes.ChangePasswordSuccess),
    map(res => new SetTokenFromDto({ token: res.payload.token }))
  ));

   updateUserProfile$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateUserProfile>(UserProfileActionTypes.UpdateUserProfile),
    switchMap(action =>
      this.userService.editProfile(action.payload).pipe(
        map(res => new fromUserProfileActions.UpdateUserProfileSuccess(res)),
        catchError(() => of(new fromUserProfileActions.UpdateUserProfileError()))
      )
    )
  ));

   updateUserProfileSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateUserProfileSuccess>(UserProfileActionTypes.UpdateUserProfileSuccess),
    tap(() => {
      this.translatingBasicNotificationsService.success('userProfile.accountDetails.updateUserProfileSuccess');
    }),
    mapTo(new LoadUserContext())
  ));

   loadExperianCredentials$ = createEffect(() => this.actions$.pipe(
    ofType<LoadExperianCredentials>(UserProfileActionTypes.LoadExperianCredentials),
    switchMap(action =>
      this.experianViewService.getExperianCredentials().pipe(
        map(res => new fromUserProfileActions.LoadExperianCredentialsSuccess(res)),
        catchError(() => of(new fromUserProfileActions.LoadExperianCredentialsError()))
      )
    )
  ));

   updateExperianCredentials$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateExperianCredentials>(UserProfileActionTypes.UpdateExperianCredentials),
    switchMap(action =>
      this.experianService.updateExperianCredentials(action.payload).pipe(
        map(res => new fromUserProfileActions.UpdateExperianCredentialsSuccess(res)),
        catchError(() => of(new fromUserProfileActions.UpdateExperianCredentialsError()))
      )
    )
  ));

   UpdateExperianCredentialsSuccess = createEffect(() => this.actions$.pipe(
    ofType<UpdateExperianCredentialsSuccess>(UserProfileActionTypes.UpdateExperianCredentialsSuccess),
    tap(() =>
      this.translatingBasicNotificationsService.success('userProfile.accountDetails.experianCredentialsUpdated')
    ),
    map(() => new LoadExperianCredentials())
  ));

  constructor(
    private actions$: Actions,
    private userSettingsService: UserSettingsService,
    private translatingBasicNotificationsService: BasicNotificationsService,
    private userService: UserService,
    private experianService: ExperianService,
    private experianViewService: ExperianViewService,
    private profileFacade: ProfileFacade,
    private authService: AuthService,
    private httpClient: HttpClient
  ) {}
}
