<button
  nz-button
  nzShape="circle"
  type="button"
  nz-popconfirm
  nzPopconfirmTrigger="click"
  [nzPopconfirmTitle]="'common.deleteQuestion' | translate"
  [nzCancelText]="'common.no' | translate"
  [nzOkText]="'common.yes' | translate"
  nzPopconfirmPlacement="top"
  nzPopconfirmOverlayClassName="modal-confirm-popover"
  (nzOnConfirm)="emitAction()"
>
  <i nz-icon nzType="delete" nzTheme="fill" class="show-button button"></i>
</button>
