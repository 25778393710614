<nz-spin [nzSpinning]="loading">
  <vpfa-mobile-total-summary [totalValue]="total | currencyLocale: null:null:'1.2-2' | lT">
    <vpfa-mobile-page-title
      [title]="'damageEstimatorModal.lightDamageEstimatorList' | translate"
    ></vpfa-mobile-page-title>
    <vpfa-estimator-table
      [areas]="estimatorAreas"
      [submitted]="submitted"
      [estimatorForm]="estimatorForm"
      (totalValue)="onTotalChange($event)"
    ></vpfa-estimator-table>
  </vpfa-mobile-total-summary>
  <div class="no-hor-scroll sticky-btn">
    <vpfa-action-button (click)="onSubmit()" [disabled]="estimatorForm.pristine || estimatorForm.invalid">
      {{ 'common.save' | translate }}
    </vpfa-action-button>
    <a
      nz-popconfirm
      [nzPopconfirmTitle]="'common.cancelQuestion' | translate"
      [nzCancelText]="'common.no' | translate"
      [nzOkText]="'common.yes' | translate"
      nzPopconfirmPlacement="top"
      nzPopconfirmOverlayClassName="modal-confirm-popover"
      (nzOnConfirm)="onCancel()"
      [nzCondition]="estimatorForm.pristine"
    >
      <vpfa-action-button [outline]="true" [border]="false">
        {{ 'common.cancel' | translate }}
      </vpfa-action-button>
    </a>
  </div>
</nz-spin>
