<vpfa-setting-box *vpfaResponsiveIf="'web'; else mobileView" [divider]="false">
  <ng-container label>
    <vpfa-label [text]="label"></vpfa-label>
  </ng-container>
  <ng-container content>
    <vpfa-text-field
      [fieldWidth]="fieldWidth"
      [placeholder]="placeholder"
      [parentFormGroup]="parentFormGroup"
      [fcName]="fcName"
      [errorMessages]="errorMessages"
    ></vpfa-text-field>
  </ng-container>
</vpfa-setting-box>

<ng-template #mobileView>
  <vpfa-vehicle-technical-data-single-field
    [label]="label"
    [value]="parentFormGroup.get(fcName).value"
  ></vpfa-vehicle-technical-data-single-field>
</ng-template>
