import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseVehicleList } from './base-vehicle-list';
import { DateLocalePipe } from '@vpfa/shared/pipes';
import { EquipmentDto, VehicleDto } from '@vpfa/rest-api/identification';
import { TranslateService } from '@ngx-translate/core';
import { ScrollToTopService } from '@vpfa/shared/mobile-view';
import { FeaturesTechnicalDataConfiguration } from '@vpfa/locale';
import { SearchFeatures } from '@vpfa/shared/interfaces';
import { SelectOption } from '@vpfa/ui-kit';

type DisplayedContent = 'list' | 'technicalSpec';

@Component({
  selector: 'vpfa-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.scss'],
  providers: [DateLocalePipe],
})
export class VehicleListComponent extends BaseVehicleList {
  private _vehicleTechSpecData: VehicleDto;
  displayedContent: DisplayedContent = 'list';

  @Input() isFormInvalid = false;
  @Input() showPower = false;
  @Input() showMobileList = true;

  @Input() searchFeatures: SearchFeatures;
  @Input() technicalDataFeatureConfig: FeaturesTechnicalDataConfiguration;
  @Input() set vehicleTechSpecData(vehicleTechSpecData: VehicleDto) {
    if (vehicleTechSpecData) {
      this.onChangeDisplayContent('technicalSpec');
    }
    this._vehicleTechSpecData = vehicleTechSpecData;
  }
  @Input() vehicleEquipmentData: EquipmentDto[];
  @Input() emissionClassOptions: SelectOption[];

  get vehicleTechSpecData() {
    return this._vehicleTechSpecData;
  }

  @Output() closeMobileTechSpec = new EventEmitter<void>();

  constructor(
    protected dateLocale: DateLocalePipe,
    protected translateService: TranslateService,
    private scrollService: ScrollToTopService
  ) {
    super(dateLocale, translateService);
  }

  onCloseMobileTechSpec() {
    this.onChangeDisplayContent('list');
    this.closeMobileTechSpec.emit();
  }

  private onChangeDisplayContent(content: DisplayedContent) {
    this.displayedContent = content;
    this.scrollService.scrollToTop();
  }
}
