<div *ngIf="!alwaysGross; else grossInformation">
  <vpfa-label
    *ngIf="tax?.taxType === TaxType.DifferentiallyTaxed"
    id="differentiallyTaxedNet"
    text="{{ 'stockPrices.differentiallyTaxed' | translate | uppercase }}"
  ></vpfa-label>
  <vpfa-label
    *ngIf="tax?.taxType === TaxType.RegularlyTaxedTaxIncluded"
    id="regularlyTaxedTaxIncludedNet"
    [attr.data-tax-value]="tax?.taxValue"
    text="{{ 'stockPrices.regularlyTaxedTaxIncluded' | translate | uppercase }} {{
      tax?.taxValue | numberLocale: '1.2-2' | percentValue
    }}"
  ></vpfa-label>
  <vpfa-label
    id="regularlyTaxedTaxExcludedNet"
    *ngIf="tax?.taxType === TaxType.RegularlyTaxedTaxExcluded"
    text="{{ 'stockPrices.regularlyTaxedTaxExcluded' | translate | uppercase }}"
  ></vpfa-label>
</div>

<ng-template #grossInformation>
  <vpfa-label
    [attr.data-tax-value]="tax?.taxValue"
    id="regularlyTaxedTaxIncludedGross"
    text="{{ 'stockPrices.regularlyTaxedTaxIncluded' | translate | uppercase }} {{
      tax?.taxValue | numberLocale: '1.2-2' | percentValue
    }}"
  ></vpfa-label>
</ng-template>
