import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { collapseExpand } from '@vpfa/utils';
import { LightDamageAreaDto, LightDamagePartDto, UpdateCaseLightDamagePartCommand } from '@vpfa/rest-api/valuation';
import { UntypedFormGroup } from '@angular/forms';
import { isEmpty } from 'lodash';

@Component({
  selector: 'vpfa-estimator-area',
  templateUrl: './estimator-area.component.html',
  styleUrls: ['./estimator-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [collapseExpand(250)],
})
export class EstimatorAreaComponent {
  private _area: LightDamageAreaDto;
  collapsed = true;
  total = 0;

  @Input() set area(area: LightDamageAreaDto) {
    if (area) {
      this._area = area;
      this.initializeCosts();
    }
  }

  get area(): LightDamageAreaDto {
    return this._area;
  }

  @Input() estimatorForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() set visible(visible: boolean) {
    this.collapsed = true;
    if (this.area) {
      this.initializeCosts();
    }
  }
  @Output() costChanged = new EventEmitter<LightDamagePartDto>();

  toggleCollapse() {
    this.collapsed = !this.collapsed;
  }

  onPartCostChanged(part: LightDamagePartDto) {
    if (this.area) {
      this.refreshCosts();
      this.costChanged.emit(part);
    }
  }

  private initializeCosts() {
    this.total = this.initializeTotal();
  }

  private refreshCosts() {
    this.total = this.refreshTotal();
  }

  private initializeTotal() {
    let newTotal = 0;
    if (!this.hasSubareas()) {
      this.area.parts.forEach((part: LightDamagePartDto) => {
        if (part.isIncluded) {
          newTotal += part.cost;
        }
      });
    } else if (this.hasSubareas()) {
      this.area.subAreas.forEach((subArea: LightDamageAreaDto) => {
        subArea.parts.forEach((part: LightDamagePartDto) => {
          if (part.isIncluded) {
            newTotal += part.cost;
          }
        });
      });
    }
    return newTotal;
  }

  private refreshTotal() {
    let newTotal = 0;
    const formValue = this.estimatorForm.getRawValue();
    this.getPartIds().forEach(id => {
      const formPart: UpdateCaseLightDamagePartCommand = formValue[id];
      if (formPart && formPart.isIncludedInValuation) {
        newTotal += formPart.cost;
      }
    });
    return newTotal;
  }

  private getPartIds() {
    let partIds: string[] = [];
    if (!this.hasSubareas()) {
      partIds = this.area.parts.map(part => part.id);
    } else if (this.hasSubareas()) {
      this.area.subAreas.forEach((subArea: LightDamageAreaDto) => {
        partIds = partIds.concat(subArea.parts.map(part => part.id));
      });
    }
    return partIds;
  }

  private hasSubareas() {
    return !isEmpty(this.area.subAreas) && isEmpty(this.area.parts);
  }
}
