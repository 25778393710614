import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  PermissionsActions,
  PermissionsActionTypes,
  LoadPermissions,
  PermissionsLoaded,
  LoadPermissionsError,
  LoadPermissionsByContext,
} from './permissions.actions';
import { switchMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserContextService } from '@vpfa/rest-api/admin';

@Injectable()
export class PermissionsEffects {
  constructor(private actions$: Actions<PermissionsActions>, private userContextService: UserContextService) {}
   loadPermissions$ = createEffect(() => this.actions$.pipe(
    ofType<LoadPermissions>(PermissionsActionTypes.LoadPermissions),
    switchMap(() =>
      this.userContextService.getAmsPermission().pipe(
        map(response => new PermissionsLoaded(response)),
        catchError(() => of(new LoadPermissionsError()))
      )
    )
  ));

   loadPermissionsByContext$ = createEffect(() => this.actions$.pipe(
    ofType<LoadPermissionsByContext>(PermissionsActionTypes.LoadPermissionsByContext),
    map(action => action.payload),
    switchMap(({ countryId, businessId, branchId }) =>
      this.userContextService.getAmsPermission(countryId, businessId, branchId).pipe(
        map(response => new PermissionsLoaded(response)),
        catchError(() => of(new LoadPermissionsError()))
      )
    )
  ));
}
