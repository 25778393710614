import { Component, EventEmitter } from '@angular/core';
import { DynamicComponentStack } from '@vpfa/shared/dynamic-components-stack';
import { BaseProvenanceCheckSectionComponent } from '../base-provenance-check-section.component';

@Component({
  selector: 'vpfa-mobile-provenance-check-section',
  templateUrl: './mobile-provenance-check-section.component.html',
  styleUrls: ['./mobile-provenance-check-section.component.scss'],
})
export class MobileProvenanceCheckSectionComponent
  extends BaseProvenanceCheckSectionComponent
  implements DynamicComponentStack {
  close: EventEmitter<any> = new EventEmitter();
}
