import { Injectable } from '@angular/core';
import { NotificationsFacade } from '../+state/notifications.facade';
import { FileNotAcceptedReason } from '../models/file-not-accepted-reason.enum';
import { FileNotificationType } from '../models/file-notification-type.enum';

/**
 * Notifications about files upload
 */
@Injectable()
export class FilesNotificationsService {
  constructor(private facade: NotificationsFacade) {}

  success(fileName: string): void {
    this.facade.addFileNotification(fileName, FileNotificationType.Accepted);
  }

  error(fileName: string, reason: FileNotAcceptedReason): void {
    this.facade.addFileNotification(fileName, FileNotificationType.NotAccepted, reason);
  }

  /**
   * Used to show notifications modal when all files are uploaded (or upload failed).
   */
  setFilesCount(count: number) {
    this.facade.setCountOfFilesUpload(count);
  }

  /**
   * Used to show notifications modal for files that was validated by FE and are invalid.
   */
  setInvalidFilesCount(count: number) {
    this.facade.setInvalidFilesCount(count);
  }
}
