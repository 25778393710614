import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { provenanceCheckQuery } from './provenance-check.selectors';
import { checkProvenance, loadCO2EmissionRanges } from './provenance-check.actions';

@Injectable({ providedIn: 'root' })
export class ProvenanceCheckFacade {
  get cO2EmissionRanges$() {
    return this.store.select(provenanceCheckQuery.getCo2EmissionRanges);
  }

  get cO2EmissionRangesLoading$() {
    return this.store.select(provenanceCheckQuery.getCo2EmissionRangesLoading);
  }

  get isLoading$() {
    return this.store.select(provenanceCheckQuery.getIsProvenanceCheckLoading);
  }

  checkProvenance() {
    this.store.dispatch(checkProvenance());
  }

  loadCO2EmissionRanges() {
    this.store.dispatch(loadCO2EmissionRanges());
  }

  constructor(private store: Store) {}
}
