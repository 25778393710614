import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BranchViewService } from '@vpfa/rest-api/admin';
import { of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  DictionariesAction,
  DictionariesActionTypes,
  fromDictionariesActions,
  LoadAdminPaintColourList,
  LoadAdminPaintColourListCompleted,
  LoadAdminPaintColourListError,
  LoadCO2EfficiencyClassList,
  LoadCO2EfficiencyClassListCompleted,
  LoadCO2EfficiencyClassListError,
  LoadEmissionClassList,
  LoadEmissionClassListCompleted,
  LoadEmissionClassListError,
  LoadMakeListByTypeIds,
  LoadPaintColourList,
  LoadPaintColourListCompleted,
  LoadPaintColourListError,
} from './dictionaries.actions';
import {
  CO2EfficiencyClassViewService,
  EmissionClassViewService,
  PaintColourService,
  VehicleGroupService,
} from '@vpfa/rest-api/valuation';
import { VehicleService } from '@vpfa/rest-api/identification';
import { AuthFacade } from '@vpfa/auth';
import { LocaleActionTypes } from '@vpfa/locale';
import { ProfileFacade } from '@vpfa/profile/data';

@Injectable()
export class DictionariesEffects {
   loadBranchList$ = createEffect(() => this.actions$.pipe(
    ofType<DictionariesAction>(DictionariesActionTypes.LoadBranchList),
    switchMap(() =>
      this.branchViewService.getList().pipe(
        map(res => {
          return new fromDictionariesActions.BranchListLoaded(res);
        }),
        catchError(err => of(new fromDictionariesActions.BranchListLoadError(err)))
      )
    )
  ));

   loadVehicleGroupList$ = createEffect(() => this.actions$.pipe(
    ofType<DictionariesAction>(DictionariesActionTypes.LoadVehicleGroupList),
    switchMap(() =>
      this.vehicleGroupService.getVehicleGroupList().pipe(
        map(res => {
          return new fromDictionariesActions.VehicleGroupListLoaded(res);
        }),
        catchError(err => of(new fromDictionariesActions.VehicleGroupListLoadError(err)))
      )
    )
  ));

   loadVehicleTypes$ = createEffect(() => this.actions$.pipe(
    ofType<DictionariesAction>(DictionariesActionTypes.LoadVehicleTypeList),
    switchMap(() =>
      this.vehicleService.getVehicleTypes().pipe(
        map(res => {
          return new fromDictionariesActions.VehicleTypeListLoaded(res);
        }),
        catchError(err => of(new fromDictionariesActions.VehicleTypeListLoadError(err)))
      )
    )
  ));

   whenContentLanguageChangeLoadVehicleTypeList$ = createEffect(() => this.actions$.pipe(
    ofType(LocaleActionTypes.SelectContentLanguage),
    withLatestFrom(this.authFacade.isAuthenticated$),
    map(([, isAuth]) => {
      return isAuth
        ? new fromDictionariesActions.LoadVehicleTypeList()
        : new fromDictionariesActions.LoadVehicleTypesWhenNotAuthenticatedError();
    })
  ));

   whenContentLanguageChangeLoadEmissionAndCO2ClassList$ = createEffect(() => this.actions$.pipe(
    ofType(LocaleActionTypes.SelectContentLanguage),
    withLatestFrom(this.authFacade.isAuthenticated$, this.profileFacade.userContext$),
    filter(([, isAuth, userContext]) => {
      if (userContext?.isCountryAdmin || userContext?.isSystemAdmin) {
        return false;
      } else {
        return isAuth;
      }
    }),
    distinctUntilChanged(), // without distinctUntilChanged effect is emitting 3 times
    switchMap(() => [
      new fromDictionariesActions.LoadEmissionClassList(),
      new fromDictionariesActions.LoadCO2EfficiencyClassList(),
    ])
  ));

   loadMakeList$ = createEffect(() => this.actions$.pipe(
    ofType<LoadMakeListByTypeIds>(DictionariesActionTypes.LoadMakeListByTypeIds),
    mergeMap(({ payload }) =>
      payload.map(id =>
        this.vehicleService.getVehicleMakes(id).pipe(
          map(res => new fromDictionariesActions.LoadMakeListSuccess({ list: res, typeId: id })),
          catchError(err => of(new fromDictionariesActions.LoadMakeListError({ error: err, typeId: id })))
        )
      )
    ),
    mergeMap(x => x)
  ));

   loadAdminPaintColourList$ = createEffect(() => this.actions$.pipe(
    ofType<LoadAdminPaintColourList>(DictionariesActionTypes.LoadAdminPaintColourList),
    switchMap(action =>
      this.paintColourService.getAdminPaintColourList(action.payload).pipe(
        map(res => new LoadAdminPaintColourListCompleted(res)),
        catchError(() => of(new LoadAdminPaintColourListError()))
      )
    )
  ));

   loadPaintColourList$ = createEffect(() => this.actions$.pipe(
    ofType<LoadPaintColourList>(DictionariesActionTypes.LoadPaintColourList),
    switchMap(() =>
      this.paintColourService.getPaintColourList().pipe(
        map(res => new LoadPaintColourListCompleted(res)),
        catchError(() => of(new LoadPaintColourListError()))
      )
    )
  ));

   loadEmissionClassList$ = createEffect(() => this.actions$.pipe(
    ofType<LoadEmissionClassList>(DictionariesActionTypes.LoadEmissionClassList),
    switchMap(() =>
      this.emissionClassViewService.getEmissionClassList().pipe(
        map(res => new LoadEmissionClassListCompleted(res)),
        catchError(() => of(new LoadEmissionClassListError()))
      )
    )
  ));

   loadCO2EfficiencyClassList$ = createEffect(() => this.actions$.pipe(
    ofType<LoadCO2EfficiencyClassList>(DictionariesActionTypes.LoadCO2EfficiencyClassList),
    switchMap(() =>
      this.co2EfficiencyClassViewService.getCO2EfficiencyClassList().pipe(
        map(res => new LoadCO2EfficiencyClassListCompleted(res)),
        catchError(() => of(new LoadCO2EfficiencyClassListError()))
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private branchViewService: BranchViewService,
    private vehicleGroupService: VehicleGroupService,
    private vehicleService: VehicleService,
    private authFacade: AuthFacade,
    private profileFacade: ProfileFacade,
    private paintColourService: PaintColourService,
    private emissionClassViewService: EmissionClassViewService,
    private co2EfficiencyClassViewService: CO2EfficiencyClassViewService
  ) {}
}
