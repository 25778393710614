import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Subject, Subscription, timer } from 'rxjs';
import { isNil } from 'lodash';
import { ErrorVerticalMessagePosition } from '@vpfa/ui-kit/uikit-validation';

@Component({
  selector: 'vpfa-field-container',
  templateUrl: './field-container.component.html',
  styleUrls: ['./field-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldContainerComponent implements OnDestroy {
  onDestroy$ = new Subject<void>();
  isInvalid$ = new BehaviorSubject(false);
  @Input() isNumber = true;
  @Input() isCurrency = true;
  @Input() isText = true;
  @Input() displayErrors = true;
  @Input() errorMsgVertPosition: ErrorVerticalMessagePosition;
  @Input() heightCompensationPx: string | number;
  @Input() errorMessages: ReadonlyArray<{ error: string; errorMsg: string }> = [];
  @Input() maxWidth: boolean;

  private _pollingSubscription: Subscription;

  @Input()
  set control(control: UntypedFormControl) {
    if (control) {
      this._control = control;

      if (!isNil(this._pollingSubscription) && !this._pollingSubscription.closed) {
        this._pollingSubscription.unsubscribe();
      }

      if (this._control.asyncValidator) {
        // `control` with asyncValidator have a "bug" with `.statusChanges` so we need to do a polling of control status.
        // More info in commit message. If you change this code, please check VIN controls in Home search form and
        // CloneModal validation is working correctly.
        this._pollingSubscription = timer(0, 500)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(() => {
            this.setErrors();
          });
      } else {
        this.setErrors();
        this._control.statusChanges.pipe(takeUntil(this.onDestroy$)).subscribe(d => {
          this.setErrors();
        });
      }
    }
  }

  private _control: UntypedFormControl;

  get control(): UntypedFormControl {
    return this._control;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setErrors() {
    const status = this._control.status;

    // need to send each change of status, to be able to update validity when it changes status from PENDING to VALID/INVALID
    this.isInvalid$.next(status === 'INVALID');
  }
}
