import { environment } from '@env/environment';
import { ImageSize } from './image-size';
import { SorcImageView } from './sorc-image-view';

export const SORC_SIZE_PLACEHOLDER = '{SORC_SIZE}';

export function getCasePublicImageUrl(caseId: string, fileKey: string, size: ImageSize = ImageSize.Medium) {
  return `${getPublicCaseImageUrl(caseId, fileKey)}?size=${size.toString()}`;
}

export function getCaseImageUrl(caseId: string, imageKey: string) {
  return `${environment.apiGateway}/api/CaseFilesView/${encodeURIComponent(String(caseId))}/images/${encodeURIComponent(
    String(imageKey)
  )}`;
}

export function getPublicCaseImageUrl(caseId: string, imageKey: string) {
  return `${environment.apiGateway}/api/CaseFilesView/${encodeURIComponent(String(caseId))}/public/${encodeURIComponent(
    String(imageKey)
  )}`;
}

export function getSorcImageUrl(caseId: string, viewCode: string) {
  return `${environment.apiGateway}/api/CaseView/${encodeURIComponent(
    String(caseId)
  )}/images/${SORC_SIZE_PLACEHOLDER}/${encodeURIComponent(String(viewCode))}`;
}

export function getStockImageUrl(caseId: string, imageKey: string) {
  if (imageKey) {
    return getCaseImageUrl(caseId, imageKey);
  }

  return getSorcImageUrl(caseId, SorcImageView.FrontLeft.toString());
}
