import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileShowHideDirective } from './directives/show-hide/mobile-show-hide.directive';
import { MobileBreakPointsProvider } from './providers/mobile-breakpoints';
import { MobileLayoutDirective } from './directives/layout/mobile-layout.directive';
import { MobileLayoutAlignDirective } from './directives/align/mobile-layout-align.directive';
import { MobileFlexDirective } from './directives/flex/mobile-flex.directive';
import { MobileFlexOrderDirective } from './directives/order/mobile-flex-order.directive';
import { MobileClassDirective } from './directives/class/mobile-class.directive';
import { MobileLayoutGapDirective } from './directives/gap/mobile-gap.directive';
import { ResponsiveIfDirective } from './directives/responsive-if/responsive-if.directive';
import { MobileInfiniteListComponent } from './components/mobile-infinite-list/mobile-infinite-list.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MobileNgStyleDirective } from './directives/mobile-ng-style/mobile-ng-style.directive';
import { SharedLoadersModule } from '@vpfa/shared/loaders';
import { UtilsModule } from '@vpfa/utils';
import { MobileBackendPaginationInfiniteListComponent } from './components/mobile-backend-pagination-infinite-list/mobile-backend-pagination-infinite-list.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@NgModule({
  imports: [CommonModule, InfiniteScrollModule, SharedLoadersModule, UtilsModule, NzSpinModule],
  providers: [MobileBreakPointsProvider],
  declarations: [
    MobileShowHideDirective,
    MobileLayoutDirective,
    MobileLayoutAlignDirective,
    MobileFlexDirective,
    MobileFlexOrderDirective,
    MobileClassDirective,
    MobileLayoutGapDirective,
    ResponsiveIfDirective,
    MobileInfiniteListComponent,
    ResponsiveIfDirective,
    MobileNgStyleDirective,
    MobileBackendPaginationInfiniteListComponent,
  ],
  exports: [
    MobileShowHideDirective,
    MobileLayoutDirective,
    MobileLayoutAlignDirective,
    MobileFlexDirective,
    MobileFlexOrderDirective,
    MobileClassDirective,
    MobileLayoutGapDirective,
    ResponsiveIfDirective,
    MobileNgStyleDirective,
    MobileInfiniteListComponent,
    MobileBackendPaginationInfiniteListComponent,
  ],
})
export class SharedMobileViewModule {}
