import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CaseStatus } from '@vpfa/rest-api/valuation';

@Component({
  selector: 'vpfa-valuation-status',
  templateUrl: './valuation-status.component.html',
  styleUrls: ['./valuation-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValuationStatusComponent {
  @Input() status: CaseStatus;

  hasValuationStatus() {
    return this.status === CaseStatus.Valuated;
  }
}
