import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetClientBoundingRectDirective } from './directives/get-client-bounding-rect/get-client-bounding-rect.directive';
import { ViewCacheMapDirective } from './directives/view-cache-map/view-cache-map.directive';

import { EmptyComponent } from './components/empty/empty.component';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, NzEmptyModule, TranslateModule],
  declarations: [GetClientBoundingRectDirective, ViewCacheMapDirective, EmptyComponent],
  exports: [GetClientBoundingRectDirective, ViewCacheMapDirective, EmptyComponent],
})
export class UtilsModule {}
