import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { CheckboxButtonItem } from '../checkbox-button-item';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { isNil } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

//TODO: move to ui-kit-mobile
@Component({
  selector: 'vpfa-checkbox-button-group',
  templateUrl: './checkbox-button-group.component.html',
  styleUrls: ['./checkbox-button-group.component.scss'],
})
export class CheckboxButtonGroupComponent implements OnInit, OnDestroy {
  @Input() pageSize = 0;
  @Input() totalNumber = 0;
  @Input() loadingData = false;

  @Input() saveSelectionButtonTitle = 'common.saveSelected';
  @Input() contentTemplate: TemplateRef<any>;
  @Input() limitSelectionMessage: string = null;
  @Input() isFilterOn = false;
  @Input() filterFieldTitle = 'filters.filter';
  @Input() set checkboxesData(value: CheckboxButtonItem[]) {
    if (isNil(value)) return;
    this.data = value;
  }

  @Output() loadNextPage = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() saved = new EventEmitter<CheckboxButtonItem[]>();
  @Output() onFilter = new EventEmitter<any[]>();

  filteredGroup: UntypedFormGroup = new UntypedFormGroup({
    filter: new UntypedFormControl(null),
  });
  data: CheckboxButtonItem[] = [];
  changesCounter = 0;

  get selectedCount() {
    return [...this.data].filter(x => x.checked === true)?.length;
  }

  private _onDestroy$ = new Subject<void>();

  ngOnInit() {
    this.filteredGroup.valueChanges.pipe(distinctUntilChanged(), takeUntil(this._onDestroy$)).subscribe(value => {
      this.onFilter.emit(value);
    });
  }

  onCustomerChecked(id: string) {
    const element = this.data.find(x => x.id === id);
    if (element) {
      element.checked = true;
      this.changesCounter++;
    }
  }

  onCustomerUnchecked(id: string) {
    const element = this.data.find(x => x.id === id);
    if (element) {
      element.checked = false;
      this.changesCounter--;
    }
  }

  onLoadNextPage() {
    this.loadNextPage.emit();
  }

  saveButtonDisabled() {
    return this.changesCounter === 0;
  }

  pendingChanges() {
    return this.changesCounter > 0;
  }

  onCancel() {
    this.resetToInitial();
    this.cancel.emit();
  }

  onSave() {
    const checkedResults = this.data.filter(x => x.checked === true);
    this.saved.emit(checkedResults);
    this.changesCounter = 0;
  }

  resetToInitial() {
    this.filteredGroup.reset({ filter: null });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
