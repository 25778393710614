import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CasesFacade } from '@vpfa/dealer/case/data';
import { UpdateCaseLightDamagePartCommand } from '@vpfa/rest-api/valuation';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseRepairAndMaintenanceSummary } from '../base-repair-and-maintenance-summary';

@Component({
  selector: 'vpfa-repair-and-maintenance-summary',
  templateUrl: './web-repair-and-maintenance-summary.component.html',
  styleUrls: ['./web-repair-and-maintenance-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebRepairAndMaintenanceSummaryComponent extends BaseRepairAndMaintenanceSummary {
  isDamageEstimatorModalShown = false;

  constructor(casesFacade: CasesFacade, private router: Router, private route: ActivatedRoute) {
    super(casesFacade);
  }

  showDamageEstimatorModal() {
    this.isDamageEstimatorModalShown = true;
  }

  closeDamageEstimatorModal() {
    this.isDamageEstimatorModalShown = false;
  }

  gotToRepairEstimate() {
    if (!this.notAvailable) {
      this.router.navigate(['repairs'], { relativeTo: this.route });
    }
  }

  saveDamageEstimator(parts: UpdateCaseLightDamagePartCommand[]) {
    this.casesFacade.updateLightDamageEstimator({
      aggregateRootId: this.caseId,
      lightDamageParts: parts,
    });

    this.casesFacade.isLightDamageEstimatorSaved$
      .pipe(
        distinctUntilChanged(),
        filter(saved => saved),
        take(1)
      )
      .subscribe(() => {
        this.closeDamageEstimatorModal();
      });
  }
}
