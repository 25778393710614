<div *vpfaResponsiveIf="'web'">
  <div *ngIf="area" class="estimator-row area-row">
    <div fxFlex="46%">
      <button nz-button nzType="link" type="button" (click)="toggleCollapse()">
        <i class="zmdi" [ngClass]="{ 'zmdi-plus': collapsed, 'zmdi-minus': !collapsed }"></i>
      </button>
      {{ area.name }}
    </div>
    <div>{{ total | currencyLocale }}</div>
  </div>
  <div class="estimator-area-parts" *ngIf="!collapsed" @collapseExpand>
    <vpfa-estimator-subarea
      [submitted]="submitted"
      [estimatorForm]="estimatorForm"
      *ngFor="let subArea of area.subAreas"
      [area]="subArea"
      (costChanged)="onPartCostChanged($event)"
    ></vpfa-estimator-subarea>
    <vpfa-estimator-part
      [submitted]="submitted"
      [partForm]="estimatorForm.get(part.id)"
      *ngFor="let part of area.parts"
      [part]="part"
      (costChanged)="onPartCostChanged($event)"
    ></vpfa-estimator-part>
  </div>
</div>

<div *vpfaResponsiveIf="'mobile'">
  <div class="header-container no-hor-scroll" [ngClass]="{ 'sticky-header': !collapsed }" [fxLayoutAlign]="'center center'">
    <button
      class="mobile-area-row"
      (click)="toggleCollapse()"
      [ngClass]="{ 'border-none': collapsed }"
      [fxLayoutAlign]="'space-between center'"
    >
      <div>
        <i class="zmdi mobile-icon" [ngClass]="{ 'zmdi-plus': collapsed, 'zmdi-minus': !collapsed }"></i>
        <span>{{ area.name }}</span>
      </div>
      <span>{{ total | currencyLocale }}</span>
    </button>
  </div>
  <div *ngIf="!collapsed" @collapseExpand>
    <div [fxLayoutAlign]="'space-between center'" *ngIf="area.subAreas.length" class="subarea-label">
      <vpfa-label text="{{ 'damageEstimatorModal.part' | translate }}"></vpfa-label>
      <vpfa-label text="{{ 'damageEstimatorModal.cost' | translate }}"></vpfa-label>
    </div>
    <vpfa-estimator-subarea
      [submitted]="submitted"
      [estimatorForm]="estimatorForm"
      *ngFor="let subArea of area.subAreas"
      [area]="subArea"
      [ngClass]="{ 'border-none': !collapsed }"
      (costChanged)="onPartCostChanged($event)"
    ></vpfa-estimator-subarea>
    <vpfa-estimator-part
      [submitted]="submitted"
      [partForm]="estimatorForm.get(part.id)"
      *ngFor="let part of area.parts"
      [part]="part"
      (costChanged)="onPartCostChanged($event)"
    ></vpfa-estimator-part>
  </div>
</div>
