import { Directive } from '@angular/core';
import { LayoutDirective } from '@ngbracket/ngx-layout';

const selector = `[fxLayout.gt-mv], [fxLayout.lt-mv]`;
const inputs = ['fxLayout.gt-mv', 'fxLayout.lt-mv'];

/**
 * @deprecated to be removed because FlexLayout is obsolete - see migration-from-flex-layout.md for details
 */
@Directive({ selector, inputs })
export class MobileLayoutDirective extends LayoutDirective {
  protected inputs = inputs;
}
