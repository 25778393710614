import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  CaseStatus,
  CaseTaxDto,
  PriceHistoryDto,
  TaxedPriceDto,
  TaxType,
  UpdateCaseSoldPricesCommand,
  UpdateCaseStockPricesCommand,
} from '@vpfa/rest-api/valuation';
import { CaseHistoricalPrices } from '@vpfa/dealer/case/data';
import { PriceHistoryModel } from '@vpfa/dealer/case/shared';
import { isNil } from 'lodash';
import { PriceDisplayModel } from '../../components/pricing-details-modal/pricing-details-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { getCurrentDate, isDateAfterToday, isDateBeforeToday } from '@vpfa/utils';
import { setupStockPrices } from './helpers/setup-stock-prices';
import { setupSoldPrices } from './helpers/setup-sold-prices';
import { setupValuationPrices } from './helpers/setup-valuation-prices';

enum PRICE_LABELS {
  askingPrice = 'stockPrices.askingPrice',
  soldPrice = 'stockPrices.soldPrice',
}
@Component({
  template: '',
})
export abstract class StockPricesBase {
  @Input() caseId: string;
  @Input() caseStatus: CaseStatus;
  @Input() liveRetailPrice: number;
  @Input() activeCaseDataTaxes: CaseTaxDto;
  @Input() activeCaseDataTaxedPrice: TaxedPriceDto;
  @Input() activeCaseDataPurchasedTime: string;
  @Input() valuationVersionAtPurchase: string;
  @Input() valuationVersion: string;
  @Input() historicalPrices: CaseHistoricalPrices;
  @Input() isLoadingValuation: boolean;
  @Input() isOldVehicle: boolean;
  @Input() valuationAttemptFailed: boolean;
  @Input() isOutOfValuationRange: boolean;
  @Input() isBroadcasting = false;
  @Input() pricingDetailsModalVisible = false;
  @Input() isPricingDetailsLoading = false;
  @Input() timezone: string;
  @Input() set resellerPrice(resellerPrice: number) {
    if (resellerPrice) {
      this.setupPrices();
    }
  }
  @Input() set caseLoading(caseLoading: boolean) {
    if (!caseLoading) {
      this.setupPrices();
    }
    this._caseLoading = caseLoading;
  }

  get caseLoading() {
    return this._caseLoading;
  }

  private _caseLoading = false;

  @Input() set forecastDate(date: string) {
    if (isNil(date) || isDateBeforeToday(date, this.timezone)) {
      this._forecastDate = getCurrentDate(this.timezone);
    } else {
      this._forecastDate = date;
    }
    this.isForecast = isDateAfterToday(this._forecastDate, this.timezone);
  }

  get forecastDate(): string {
    return this._forecastDate;
  }

  @Input() canEditStockPrices = false;
  @Input() hidePriceDetails = false;

  _forecastDate: string;
  @Output() changePricingDetailsModalVisibility = new EventEmitter();
  @Output() updatePricingDetails = new EventEmitter();
  @Output() openPriceDetails = new EventEmitter();
  isForecast = false;
  priceLabels = PRICE_LABELS;

  get priceData(): PriceHistoryModel {
    if (!isNil(this.caseStatus) && !isNil(this.activeCaseDataTaxedPrice)) {
      switch (this.caseStatus) {
        case CaseStatus.Sold:
          return {
            name: this.priceLabels.soldPrice,
            price: this.activeCaseDataTaxedPrice.soldPrice,
            tax: this.activeCaseDataTaxedPrice.soldPriceTaxAmount,
            history: this.historicalPrices ? this.historicalPrices.soldPriceHistory : null,
          };
        default:
          return {
            name: this.priceLabels.askingPrice,
            price: this.activeCaseDataTaxedPrice.askingPrice,
            tax: this.activeCaseDataTaxedPrice.askingPriceTaxAmount,
            history: this.historicalPrices ? this.historicalPrices.askingPriceHistory : null,
          };
      }
    }
    return null;
  }

  get isLoading() {
    return this._caseLoading || this.isLoadingValuation;
  }

  get notAvailable(): boolean {
    return this.isOldVehicle || this.isOutOfValuationRange || this.valuationAttemptFailed;
  }

  get notAvailableMessage(): string {
    if (this.isOldVehicle || this.isOutOfValuationRange) {
      return 'vehicleOutsideValuationCoverage';
    }

    if (this.valuationAttemptFailed) {
      return 'valuationTemporarilyUnavailable';
    }
  }

  get isForecastAvailable(): boolean {
    return (
      (this.caseStatus === CaseStatus.DueInStock || this.caseStatus === CaseStatus.InStock) &&
      this.isForecast &&
      !this.notAvailable
    );
  }

  TaxType = TaxType;
  readonly CaseStatus = CaseStatus;

  historicalPricesModalVisible = false;
  valuationPrices: PriceDisplayModel[] = [];
  stockPrices: PriceDisplayModel[] = [];
  soldPrices: PriceDisplayModel[] = [];
  priceHistoryOptions: PriceHistoryModel;

  protected constructor(private router: Router, private route: ActivatedRoute) {}

  onOpenPriceDetails() {
    if (!this.isLoading) {
      this.openPriceDetails.emit();
    }
  }

  showPricingDetailsModal() {
    this.setupPrices();
    this.changePricingDetailsModalVisibility.emit(true);
  }

  onClosePricingDetailsModal() {
    this.changePricingDetailsModalVisibility.emit(false);
  }

  onShowHistoricalPrices(args: Pick<PriceDisplayModel, 'name' | 'price' | 'tax'> & { history: PriceHistoryDto[] }) {
    this.priceHistoryOptions = args;
    this.historicalPricesModalVisible = true;
  }

  goToExpertArea() {
    this.isBroadcasting
      ? this.router.navigate(['stock', this.caseId, 'expert-area'])
      : this.router.navigate(['expert-area'], { relativeTo: this.route });
  }

  onUpdatePricingDetails(updatedPricingDetails: UpdateCaseStockPricesCommand | UpdateCaseSoldPricesCommand) {
    this.updatePricingDetails.emit(updatedPricingDetails);
  }

  private setupPrices() {
    this.valuationPrices = setupValuationPrices(
      this.activeCaseDataTaxedPrice,
      this.isOldVehicle,
      this.activeCaseDataPurchasedTime,
      this.forecastDate,
      this.isForecast,
      this.valuationVersion,
      this.valuationVersionAtPurchase
    );

    this.stockPrices = setupStockPrices(
      this.activeCaseDataTaxedPrice,
      this.historicalPrices,
      this.caseStatus,
      this.canEditStockPrices
    );

    this.soldPrices = setupSoldPrices(this.activeCaseDataTaxedPrice, this.historicalPrices, this.caseStatus);
  }

  goToOfferHistory() {
    this.router.navigate(['stock', this.route.snapshot.paramMap.get('caseId'), 'offer-history']);
  }

  get isSold(): boolean {
    return !(this.caseStatus === CaseStatus.DueInStock || this.caseStatus === CaseStatus.InStock);
  }
}
