import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CaseSummaryDto } from '@vpfa/rest-api/valuation';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { isValuationStatus } from '../utils/case-status.helper';
import { CaseResolverService } from './case-resolver.service';
import { hasUrlQueryParams, parseBooleanQueryParam } from '@vpfa/utils';
import { BasicNotificationsService } from '@vpfa/shared/notifications';

@Injectable()
export class CaseResolverWithStatusGuard  {
  private readonly PURCHASE_PARAM = 'purchase';

  constructor(
    private router: Router,
    private caseResolver: CaseResolverService,
    private notifications: BasicNotificationsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): CaseSummaryDto | Observable<CaseSummaryDto> | Promise<CaseSummaryDto> {
    return this.caseResolver.resolve(route, state).pipe(
      tap(res => {
        if (res && !isValuationStatus(res.caseStatus)) {
          let stockUrl = state.url;
          if (hasUrlQueryParams(route)) {
            stockUrl = stockUrl.split('?')[0];
            if (parseBooleanQueryParam(route.queryParams[this.PURCHASE_PARAM])) {
              this.notifications.error('caseValuationDetails.vehiclePurchaseErrors.vehicleAlreadyPurchased');
            }
          }
          this.router.navigate(stockUrl.replace('valuations', 'stock').split('/'), {
            queryParams: route.queryParams,
          });
        }
      })
    );
  }
}
