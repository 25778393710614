export enum FileNotAcceptedReason {
  ExceedsFileSizeLimit,
  ExceedsDocumentFileSizeLimit,
  ExceedsLogoFileSizeLimit,
  IncorrectFileType,
  ExceedsTotalSizeLimitForCaseDocuments,
  ExceedsTotalSizeLimitForCaseImages,
  ExceedsTotalSizeLimitForBusiness,
  FileSizeIsZero,
  FileExtensionDoesNotMatchFileContent,
  FileUploadError,
}
