import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  CaseTaxDto,
  SoldUniqueDataDto,
  StockUniqueDataDto,
  UniqueDataDto,
  UpdateCaseUniqueDataCommand,
  TaxType,
} from '@vpfa/rest-api/valuation';
import { UntypedFormGroup } from '@angular/forms';
import { ValuationUniqueDataFromName } from '../unique-data-valuation/unique-data-valuation.component';
import { BranchListItemDto } from '@vpfa/rest-api/admin';
import { StockUniqueDataFormName } from '../unique-data-stock/unique-data-stock.component';
import { cloneDeep } from 'lodash';
import { SoldUniqueDataFromName } from '../unique-data-sold/unique-data-sold.component';
import { UniqDataValuationOptions } from '../../models/uniq-data-valuation-options';
import { SelectOption } from '@vpfa/ui-kit';
import {
  convertStockFormDataToUpdateCommand,
  convertValuationFormDataToUpdateCommand,
} from '../unique-data-valuation/convert-form-data';
import { UniqueDataModel } from '../../models/unique-data.model';

@Component({
  selector: 'vpfa-unique-data-stock-modal',
  templateUrl: './unique-data-stock-modal.component.html',
  styleUrls: ['./unique-data-stock-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniqueDataStockModalComponent {
  @Input() timezone: string;
  @Input() set initialData(initialData: Partial<UniqueDataDto>) {
    if (!initialData) return;
    this._initialData = initialData;
  }

  @Input() saleDate = null;

  get initialData() {
    return this._initialData;
  }

  get currentAcceptanceDate() {
    return this.formGroup?.get(StockUniqueDataFormName)?.get('avTradeInDate')?.value;
  }

  @Input() branchList: BranchListItemDto[];
  @Input() set taxes(taxes: CaseTaxDto) {
    this._taxes = taxes;
  }
  @Input() dataValuationOptions: UniqDataValuationOptions;
  @Input() caseNumber: number;
  @Input() open = false;
  @Input() loading = false;
  @Input() sold = false;
  @Input() hideInteriorTrimColour = false;
  @Input() hideBuildDate = false;
  @Input() buildDateNotAvailable = false;
  @Input() dateOfAcceptance: string;
  @Input() isCaseIdentifiedByVin = false;

  @Output() closeModal = new EventEmitter();
  @Output() save: EventEmitter<UniqueDataModel> = new EventEmitter();

  isBranchIdModified: boolean | null = null;

  get taxes(): CaseTaxDto {
    return this._taxes;
  }

  formGroup = new UntypedFormGroup({});

  private _initialData: Partial<UniqueDataDto>;
  private _taxes: CaseTaxDto;

  onClose() {
    this.resetData();
    this.closeModal.emit();
  }

  onSubmit() {
    this.formGroup?.get(SoldUniqueDataFromName)?.get('saleDate')?.updateValueAndValidity();

    if (this.formGroup.invalid) {
      return;
    }

    const formValue = this.formGroup.getRawValue();

    const valuationDataSection = convertValuationFormDataToUpdateCommand(formValue[ValuationUniqueDataFromName]);
    const stockUniqueDataSection: StockUniqueDataDto = convertStockFormDataToUpdateCommand(
      formValue[StockUniqueDataFormName]
    );
    const soldUniqueDataSection: SoldUniqueDataDto = formValue[SoldUniqueDataFromName];
    const taxOption: SelectOption<TaxType> = formValue.taxType;

    this.save.emit({
      uniqueData: {
        valuationDataSection,
        stockUniqueDataSection,
        soldUniqueDataSection,
        taxType: taxOption.value,
      },
      hasBranchBeenUpdated: this.isBranchIdModified,
    });
  }

  private resetData() {
    this._initialData = cloneDeep(this._initialData);
    this._taxes = cloneDeep(this._taxes);
  }
}
