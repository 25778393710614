import { Directive, ElementRef, forwardRef, HostListener, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { formatNumber, getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';
import { isNil, isEmpty, findLastIndex } from 'lodash';
import { LocaleFacade } from '@vpfa/locale';
import { Subject } from 'rxjs';
import { History } from 'stateshot';

const numberDecSeparator = '.';
const negativeNumberSign = '-';

export const NUMBER_FORMATTER_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NumberFormatterDirective),
  multi: true,
};

@Directive({
  selector: 'input[vpfaNumberFormatter]',
  providers: [NUMBER_FORMATTER_VALUE_ACCESSOR],
})
export class NumberFormatterDirective implements ControlValueAccessor, OnDestroy, OnInit {
  constructor(private renderer: Renderer2, private element: ElementRef, private localeFacade: LocaleFacade) {
    this.localeFacade.locale$
      .pipe(
        filter(locale => !isNil(locale)),
        takeUntil(this._onDestroy$)
      )
      .subscribe(locale => {
        this.onChangeLocale(locale);
      });
  }
  onChange;
  onTouched;

  private focused = false;
  private numberOfZeros = 0;
  private decimalSeparator;
  private groupSeparator = '';
  private locale: string;
  private modelValue;
  private _onDestroy$ = new Subject<void>();
  private history = new History();
  private historyDebounceTime = 500;
  private history$ = new Subject();

  @Input() maxFractionNumber = 0;
  @Input() allowNegativeNumber = true;
  @Input() convertNegativeValue = true;
  @Input() showFractionZeros = true;

  ngOnInit() {
    this.history$
      .pipe(
        debounceTime(this.historyDebounceTime),
        tap(value => {
          this.history.pushSync(value);
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @HostListener('focus')
  onFocus() {
    this.focused = true;
    if (this.history.get()?.v !== this.element.nativeElement.value) {
      this.history.pushSync({ v: this.element.nativeElement.value });
    }
  }

  @HostListener('blur')
  onBlur() {
    this.focused = false;

    if (this.element.nativeElement.value === '-') {
      this.setNullValue();
    }

    if (this.showFractionZeros && this.maxFractionNumber) {
      this.setInputValueFromModel(true, true);
    }
  }

  @HostListener('keydown', ['$event'])
  onKeydown(e: any) {
    this.numberOfZeros = 0;
    this.preventMultipleDecimalSing(e);
    this.onBackspaceDeleteKey(e);
    this.onUndoOrRedoKey(e);
  }

  @HostListener('input', ['$event.target.value'])
  input(value: string) {
    this.numberOfZeros = 0;
    this.formatInput(value);
    return;
  }

  writeValue(value: any): void {
    // model value to input value
    if (this.modelValue !== value) {
      this.modelValue = value;
      if (this.showFractionZeros && this.maxFractionNumber) {
        this.setInputValueFromModel(true, true);
      } else {
        this.setInputValueFromModel();
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private formatInput(value: string, saveToHistory: boolean = true) {
    // Allow negative number sign for start typing negative values
    if (value === negativeNumberSign && this.allowNegativeNumber) {
      this.writeValueToNativeElement(value);
      return;
    }

    if (isEmpty(value)) {
      this.setNullValue();
      return;
    }

    this.numberOfZeros = this.numberOfLastZeros(this.removeNonNumericalCharacters(value));
    const stringNumber: string = this.removeNonNumericalCharacters(this.getNumberString(value));
    // if there is only separator then remove it
    if (stringNumber === numberDecSeparator) {
      this.setNullValue();
      return;
    }

    let tmpModelValue = parseFloat(stringNumber);

    if (isNaN(tmpModelValue)) {
      this.setNullValue();
      return;
    }

    // remove unnecessary fraction
    tmpModelValue = parseFloat(this.getNumberString(this.getFormattedStringValue(tmpModelValue)));

    if (!this.allowNegativeNumber && tmpModelValue < 0) {
      if (this.convertNegativeValue) {
        tmpModelValue = tmpModelValue * -1;
      } else {
        this.setNullValue();
        return;
      }
    }

    const previousValue = this.modelValue;
    this.modelValue = tmpModelValue;

    // 0. should change only separator to local decimal separator
    this.setInputValueFromModel(
      stringNumber[stringNumber.length - 1] === numberDecSeparator && this.maxFractionNumber !== 0
    );

    if (previousValue !== this.modelValue) {
      if (saveToHistory) this.history$.next({ v: this.element.nativeElement.value });
      this.onChange(this.modelValue);
    }
  }

  /**
   *
   * @param value string number value with locale separators
   *
   * @returns string in js number format for example '1000.5' from '1 000,5'
   */
  private getNumberString(value: string) {
    return value.split(this.groupSeparator).join('').split(this.decimalSeparator).join(numberDecSeparator);
  }

  private getNumberFormatter() {
    return `1.0${!isNil(this.maxFractionNumber) ? `-${this.maxFractionNumber}` : 0}`;
  }

  private getFormattedStringValue(value: number): string {
    if (isNil(this.locale)) {
      return '';
    }
    let addingString = '';
    if (this.numberOfZeros) {
      addingString = this.decimalSeparator + '0'.repeat(this.numberOfZeros);
    }
    return formatNumber(value, this.locale, this.getNumberFormatter()) + addingString;
  }

  private setNullValue() {
    const previousModel = this.modelValue;

    this.modelValue = null;
    this.setInputValueFromModel();
    if (previousModel !== null) {
      this.history.pushSync({ v: null });
      this.onChange(null);
    }
  }

  private setInputValueFromModel(addDecimalSeparator = false, addZeros = false) {
    let valueToPresent = '';
    if (!isNil(this.modelValue)) {
      valueToPresent = this.getFormattedStringValue(this.modelValue);
      const arr = valueToPresent.split(this.decimalSeparator);
      if (arr.length > 1) {
        arr[0] += this.decimalSeparator;
      }
      valueToPresent = arr.join('');
      if (addDecimalSeparator) {
        valueToPresent = `${valueToPresent}${this.decimalSeparator}`;
      }
      if (addZeros && this.maxFractionNumber) {
        const [number, fraction] = valueToPresent.split(this.decimalSeparator);
        const numOfZeros = fraction ? this.maxFractionNumber - fraction.length : this.maxFractionNumber;
        valueToPresent = `${number}${this.decimalSeparator}${fraction}` + '0'.repeat(numOfZeros > 0 ? numOfZeros : 0);
      }
    }

    this.writeValueToNativeElement(valueToPresent);
  }

  private writeValueToNativeElement(valueToPresent: string) {
    const element = this.element.nativeElement;

    // counts additional chars - number group separator and so on
    const caretOffset = valueToPresent.length - this.element.nativeElement.value.length;

    // get current caret position
    const selectionStart = element.selectionStart + caretOffset;
    const selectionEnd = element.selectionEnd + caretOffset;

    this.renderer.setProperty(element, 'value', valueToPresent);

    // preserves caret position after update
    if (this.focused) {
      element.setSelectionRange(selectionStart, selectionEnd);
    }
  }

  private onChangeLocale(locale: string) {
    this.locale = locale;
    this.decimalSeparator = getLocaleNumberSymbol(this.locale, NumberSymbol.Decimal);
    this.groupSeparator = getLocaleNumberSymbol(this.locale, NumberSymbol.Group);
    this.setInputValueFromModel();
  }

  private removeNonNumericalCharacters(input: string): string {
    return input.split('').reduce((processed, currentCharacter, index) => {
      // leave negative value
      if (currentCharacter === negativeNumberSign && index === 0) {
        return negativeNumberSign + processed;
      }

      // need to check space (it casts to 0)
      if (
        [numberDecSeparator, this.decimalSeparator, this.groupSeparator].includes(currentCharacter) ||
        (currentCharacter !== ' ' && isFinite(+currentCharacter))
      ) {
        return processed + currentCharacter;
      }
      return processed;
    }, '');
  }

  private onUndoOrRedoKey(e: KeyboardEvent) {
    var isUndo = (e.ctrlKey == true && e.key == 'z') || e.key == 'Undo';
    var isRedo =
      (e.ctrlKey == true && e.key == 'y') ||
      (e.ctrlKey == true && e.shiftKey == true && e.key == 'z') ||
      e.key == 'Redo';

    if (isUndo || isRedo) {
      e.preventDefault();

      if (isUndo) {
        this.modelValue = this.history.undo().get()?.v;
      }

      if (isRedo) {
        this.modelValue = this.history.redo().get()?.v;
      }

      this.formatInput(this.modelValue?.toString(), false);
    }
  }

  private onBackspaceDeleteKey(e: KeyboardEvent) {
    const target = e.target as HTMLInputElement;

    const selectionStartPosition = target.selectionStart;
    const selectionEndPosition = target.selectionEnd;
    const isRemovingSingleCharacter = selectionStartPosition === selectionEndPosition;
    const removeKeys = ['Backspace', 'Delete'];

    if (removeKeys.includes(e.key) && isRemovingSingleCharacter) {
      const originalValue = target.value;
      let value = target.value;

      if (e.key === 'Backspace') {
        const isGroupSeparatorBeforeCaret = this.groupSeparator === value[selectionStartPosition - 1];
        value = this.handleBackspaceKey(value, selectionStartPosition, isGroupSeparatorBeforeCaret);
      }

      if (e.key === 'Delete') {
        // no need to handle removing when caret is at the end of input
        if (selectionStartPosition === value.length) {
          return;
        }
        const isGroupSeparatorAfterCaret = this.groupSeparator === value[selectionStartPosition];
        value = this.handleDeleteKey(value, selectionStartPosition, isGroupSeparatorAfterCaret);
      }

      this.formatInput(value);
      const lengthChange = originalValue.length - target.value.length;
      this.setSelection(e, selectionStartPosition - lengthChange, selectionEndPosition - lengthChange);

      e.preventDefault();
    }
  }

  private handleBackspaceKey(value: string, caretPosition: number, isGroupSeparatorBeforeCaret: boolean): string {
    if (isGroupSeparatorBeforeCaret) {
      return value.slice(0, caretPosition - 2) + value.slice(caretPosition);
    } else {
      return value.slice(0, caretPosition - 1 > 0 ? caretPosition - 1 : 0) + value.slice(caretPosition);
    }
  }

  private handleDeleteKey(value: string, caretPosition: number, isGroupSeparatorAfterCaret: boolean): string {
    if (isGroupSeparatorAfterCaret) {
      return value.slice(0, caretPosition + 1) + value.slice(caretPosition + 2);
    } else {
      return value.slice(0, caretPosition) + value.slice(caretPosition + 1);
    }
  }

  private setSelection(e: KeyboardEvent, start: number, end: number) {
    (e.target as HTMLInputElement).selectionStart = start > 0 ? start : 0;
    (e.target as HTMLInputElement).selectionEnd = end > 0 ? end : 0;
  }

  private numberOfLastZeros(value: string): number {
    let numberOfZeros = 0;

    if (!value.includes(this.decimalSeparator)) {
      return numberOfZeros;
    }

    const [integerPart, fractionPart] = value.split(this.decimalSeparator);

    // remove excess characters after decimal separator
    if (fractionPart.length > this.maxFractionNumber) {
      value = `${integerPart}${this.decimalSeparator}${fractionPart.substring(0, this.maxFractionNumber)}`;
    }

    // get value to last numeric char
    value = value.slice(0, findLastIndex(value, char => !isNaN(char as any)) + 1);

    while (
      value.charAt(value.length - 1) === '0' &&
      value.includes(this.decimalSeparator) &&
      numberOfZeros < this.maxFractionNumber
    ) {
      numberOfZeros += 1;
      value = value.slice(0, -1);
    }
    return numberOfZeros;
  }

  private preventMultipleDecimalSing(event: KeyboardEvent) {
    if (
      event.key === this.decimalSeparator &&
      (event.target as HTMLInputElement).value.includes(this.decimalSeparator)
    ) {
      event.preventDefault();
    }
  }
}
