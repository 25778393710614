import { Component, ElementRef, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface SensitiveDataRendererParams extends ICellRendererParams {
  actionName: string;
}

const defaultActionName = 'actionNameNotSet';

/**
 * Renderer that prevents storage of sensitive data by tracking tools (like DataDog) .
 */
@Component({
  selector: 'vpfa-sensitive-data-renderer',
  templateUrl: './sensitive-data.renderer.html',
})
export class SensitiveDataRendererComponent implements ICellRendererAngularComp {
  public value: string | null = null;
  public rowIndex: number | null = null;
  public actionName: string = defaultActionName;

  agInit(params: SensitiveDataRendererParams): void {
    this.reloadData(params);
  }

  refresh(params: any): boolean {
    this.reloadData(params);
    return true;
  }

  private reloadData(params: any) {
    this.value = params?.value;
    this.rowIndex = params?.rowIndex;
    this.actionName = params?.actionName ?? defaultActionName;
  }
}
