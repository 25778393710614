import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[vpfaGetClientBoundingRect]',
})
export class GetClientBoundingRectDirective implements OnInit, OnDestroy {
  @Output() boundingRectChanged = new EventEmitter<ClientRect | DOMRect>();
  nativeElem: HTMLElement;
  mutationObserver: MutationObserver;
  resizeObserver;
  constructor(elementRef: ElementRef) {
    this.nativeElem = elementRef.nativeElement as HTMLElement;
    if (window['ResizeObserver']) {

      // @ts-ignore -  currently there are no correct definitions for ResizeObserver available
      this.resizeObserver = new ResizeObserver(() => {
        this.boundingRectChanged.emit(this.nativeElem.getBoundingClientRect());
      });
    } else {
      this.mutationObserver = new MutationObserver(() => {
        this.boundingRectChanged.emit(this.nativeElem.getBoundingClientRect());
      });
    }
  }

  ngOnInit(): void {
    this.boundingRectChanged.emit(this.nativeElem.getBoundingClientRect());
    if (this.resizeObserver) {
      this.resizeObserver.observe(this.nativeElem);
    } else {
      this.mutationObserver.observe(this.nativeElem, {
        attributes: true,
        attributeFilter: ['style'],
        childList: true,
        subtree: true,
      });
    }
  }

  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.nativeElem);
    } else {
      this.mutationObserver.disconnect();
    }
  }
}
