import { Pipe, PipeTransform } from '@angular/core';
import { UnitRangeValueDto } from '@vpfa/rest-api/valuation';
import { isNil } from 'lodash';
import { NumberLocalePipe } from '../number-locale/number-locale.pipe';
import { joinNumericValues } from '../shared/join-numeric-values.util';

@Pipe({
  name: 'unitRangeValue',
  pure: false,
})
export class UnitRangeValuePipe implements PipeTransform {
  constructor(private formatterPipe: NumberLocalePipe) {}

  transform(input: Partial<UnitRangeValueDto>): string {
    const value = input?.value;
    const valueMax = input?.valueMax;

    if ((isNil(value) || value === 0) && valueMax === 0) {
      return null;
    }

    return joinNumericValues(value, valueMax, ' - ', this.formatterPipe);
  }
}
