<div class="section" (click)="emitAction()">
  <vpfa-section-container
    [name]="text | translate"
    [expanded]="true"
    [expandableFromOutside]="true"
    [isExpandable]="itemsCount > 0"
  >
    <ng-container right-section>{{ (equipmentSectionItemLabel | translate) + ': ' + itemsCount }}</ng-container>
  </vpfa-section-container>
</div>
