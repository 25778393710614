import { ChangeDetectionStrategy, Component, Input, TemplateRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'vpfa-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionButtonComponent {
  @Input() buttonType = 'button';
  @Input() disabled = false;
  @Input() outline = false;
  @Input() arrow = false;
  @Input() size: 'default' | 'small' = 'default';
  @Input() textAlign: 'center' | 'right' = 'center';
  @Input() notAvailableIcon = false;
  @Input() border = true;
  @Input() fullWidth: boolean = true;
  @Input() tooltipContent: string | TemplateRef<void>;
  @Output() buttonClicked = new EventEmitter();

  onClick() {
    this.buttonClicked.emit();
  }
}
