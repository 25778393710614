<ng-container *ngFor="let notificationGroup of notificationGroupList">
  <div class="notification" *ngIf="notificationGroup.value as items">
    <div [class]="getClass(items[0].type, 'title')">
      {{ items[0].translate ? (items[0].title | translate: items[0].interpolateParams) : items[0].title }}
      <ng-container *ngIf="items.length > 1 && !hideCount">({{ items.length }})</ng-container>
    </div>
    <ng-container *ngFor="let item of items">
      <div [class]="getClass(item.type, 'message')" *ngIf="item.message">
        <div [class]="items[0] == item ? 'first' : 'not-first'">
          {{ item.translate ? (item.message | translate: item.interpolateParams) : item.message }}
        </div>
      </div>
      <div class="message error-id" *ngIf="item.errorId">{{ item.errorId }}</div>
    </ng-container>
  </div>
</ng-container>
