import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { VehicleDto } from '@vpfa/rest-api/identification';
import { ICellRendererParams } from 'ag-grid-community';
import { get } from 'lodash';

@Component({
  selector: 'vpfa-engine-capacity-cell-renderer',
  templateUrl: './engine-capacity-cell-renderer.component.html',
  styleUrls: ['./engine-capacity-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EngineCapacityCellRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  get engineCapacity(): VehicleDto['engineCapacity'] {
    return get(this.params, ['value'], null);
  }

  get showUnit(): boolean {
    return get(this.params, ['colDef', 'cellRendererParams', 'showUnit'], false);
  }
}
