<form (ngSubmit)="saveCustomer()" [formGroup]="customerForm" [ngClass]="{ hide: !visible }">
  <nz-spin [nzSpinning]="isProcessing">
    <vpfa-mobile-page-title [title]="modalTitleCode | translate"></vpfa-mobile-page-title>

    <div>
      <vpfa-setting-box [divider]="false">
        <ng-container label>
          <vpfa-label [text]="'customerDataModal.formOfAddress' | translate"></vpfa-label>
        </ng-container>
        <ng-container content>
          <vpfa-select
            [parentFormGroup]="customerForm"
            [fc]="customerForm.get('formOfAddress')"
            [options]="formsOfAddressOptions"
            [initialValue]="formOfAddressInitValue"
            [loading]="isFormsOfAddressLoading"
            [allowClear]="true"
          ></vpfa-select>
        </ng-container>
      </vpfa-setting-box>

      <vpfa-setting-box [divider]="false">
        <ng-container label>
          <vpfa-label [text]="'customerDataModal.firstName' | translate"></vpfa-label>
        </ng-container>
        <ng-container content>
          <vpfa-mobile-field-validation-container
            [validationControl]="customerForm.get('firstName')"
            [errorMessages]="maxLengthErrorMsgList"
          >
            <vpfa-text-field fieldWidth="100%" [parentFormGroup]="customerForm" fcName="firstName"></vpfa-text-field>
          </vpfa-mobile-field-validation-container>
        </ng-container>
      </vpfa-setting-box>

      <vpfa-setting-box [divider]="false">
        <ng-container label>
          <vpfa-label [text]="'customerDataModal.lastName' | translate"></vpfa-label>
        </ng-container>
        <ng-container content>
          <vpfa-mobile-field-validation-container
            [validationControl]="customerForm.get('lastName')"
            [errorMessages]="lastNameFieldErrorMsgList"
          >
            <vpfa-text-field fieldWidth="100%" [parentFormGroup]="customerForm" fcName="lastName"></vpfa-text-field>
          </vpfa-mobile-field-validation-container>
        </ng-container>
      </vpfa-setting-box>

      <vpfa-setting-box [divider]="false">
        <ng-container label>
          <vpfa-label [text]="'customerDataModal.company' | translate"></vpfa-label>
        </ng-container>
        <ng-container afterLabel>
          <div [ngClass]="{ 'n-invalid': customerForm.get('company').invalid }">
            {{ customerForm?.get('company')?.value?.length || 0 }} / {{ textareaMaxLength }}
          </div>
        </ng-container>
        <ng-container content>
          <vpfa-textarea [parentFormGroup]="customerForm" [fcName]="'company'"></vpfa-textarea>
        </ng-container>
      </vpfa-setting-box>

      <vpfa-setting-box [divider]="false">
        <ng-container label>
          <vpfa-label [text]="'customerDataModal.department' | translate"></vpfa-label>
        </ng-container>
        <ng-container content>
          <vpfa-mobile-field-validation-container
            [validationControl]="customerForm.get('department')"
            [errorMessages]="maxLengthErrorMsgList"
          >
            <vpfa-text-field fieldWidth="100%" [parentFormGroup]="customerForm" fcName="department"></vpfa-text-field>
          </vpfa-mobile-field-validation-container>
        </ng-container>
      </vpfa-setting-box>

      <vpfa-setting-box [divider]="false">
        <ng-container label>
          <vpfa-label [text]="'customerDataModal.position' | translate"></vpfa-label>
        </ng-container>
        <ng-container content>
          <vpfa-mobile-field-validation-container
            [validationControl]="customerForm.get('position')"
            [errorMessages]="maxLengthErrorMsgList"
          >
            <vpfa-text-field fieldWidth="100%" [parentFormGroup]="customerForm" fcName="position"></vpfa-text-field>
          </vpfa-mobile-field-validation-container>
        </ng-container>
      </vpfa-setting-box>

      <vpfa-setting-box [divider]="false">
        <ng-container label>
          <vpfa-label [text]="'customerDataModal.address' | translate"></vpfa-label>
        </ng-container>
        <ng-container content>
          <vpfa-mobile-field-validation-container
            [validationControl]="customerForm.get('address')"
            [errorMessages]="maxLengthErrorMsgList"
          >
            <vpfa-text-field fieldWidth="100%" [parentFormGroup]="customerForm" fcName="address"></vpfa-text-field>
          </vpfa-mobile-field-validation-container>
        </ng-container>
      </vpfa-setting-box>

      <vpfa-setting-box [divider]="false">
        <ng-container label>
          <vpfa-label [text]="'customerDataModal.street' | translate"></vpfa-label>
        </ng-container>
        <ng-container content>
          <vpfa-mobile-field-validation-container
            [validationControl]="customerForm.get('street')"
            [errorMessages]="maxLengthErrorMsgList"
          >
            <vpfa-text-field fieldWidth="100%" [parentFormGroup]="customerForm" fcName="street"></vpfa-text-field>
          </vpfa-mobile-field-validation-container>
        </ng-container>
      </vpfa-setting-box>

      <vpfa-setting-box [divider]="false">
        <ng-container label>
          <vpfa-label [text]="'customerDataModal.postcode' | translate"></vpfa-label>
        </ng-container>
        <ng-container content>
          <vpfa-mobile-field-validation-container
            [validationControl]="customerForm.get('postcode')"
            [errorMessages]="maxLengthErrorMsgList"
          >
            <vpfa-text-field fieldWidth="100%" [parentFormGroup]="customerForm" fcName="postcode"></vpfa-text-field>
          </vpfa-mobile-field-validation-container>
        </ng-container>
      </vpfa-setting-box>

      <vpfa-setting-box [divider]="false">
        <ng-container label>
          <vpfa-label [text]="'customerDataModal.city' | translate"></vpfa-label>
        </ng-container>
        <ng-container content>
          <vpfa-mobile-field-validation-container
            [validationControl]="customerForm.get('city')"
            [errorMessages]="maxLengthErrorMsgList"
          >
            <vpfa-text-field fieldWidth="100%" [parentFormGroup]="customerForm" fcName="city"></vpfa-text-field>
          </vpfa-mobile-field-validation-container>
        </ng-container>
      </vpfa-setting-box>

      <vpfa-setting-box [divider]="false">
        <ng-container label>
          <vpfa-label [text]="'customerDataModal.country' | translate"></vpfa-label>
        </ng-container>
        <ng-container content>
          <vpfa-mobile-field-validation-container
            [validationControl]="customerForm.get('country')"
            [errorMessages]="maxLengthErrorMsgList"
          >
            <vpfa-text-field fieldWidth="100%" [parentFormGroup]="customerForm" fcName="country"></vpfa-text-field>
          </vpfa-mobile-field-validation-container>
        </ng-container>
      </vpfa-setting-box>

      <vpfa-setting-box [divider]="false">
        <ng-container label>
          <vpfa-label [text]="'customerDataModal.phone' | translate"></vpfa-label>
        </ng-container>
        <ng-container content>
          <vpfa-text-list-field
            [errorMessages]="maxLengthErrorMsgList"
            [validators]="[maxLengthValidator]"
            #phoneNumberComponent
            [parentFormGroup]="customerForm"
            fcName="phoneNumbers"
            [mobileErrors]="true"
            [componentVisible]="visible"
          ></vpfa-text-list-field>
        </ng-container>
      </vpfa-setting-box>

      <vpfa-setting-box [divider]="false">
        <ng-container label>
          <vpfa-label [text]="'customerDataModal.emails' | translate"></vpfa-label>
        </ng-container>
        <ng-container content>
          <vpfa-text-list-field
            #emailsComponent
            [errorMessages]="emailFieldErrorMsgList"
            [validators]="[emailValidator, emailMaxLengthValidator]"
            [parentFormGroup]="customerForm"
            fcName="emails"
            [mobileErrors]="true"
            [componentVisible]="visible"
          ></vpfa-text-list-field>
        </ng-container>
      </vpfa-setting-box>

      <vpfa-setting-box [divider]="false">
        <ng-container label>
          <vpfa-label [text]="'customerDataModal.birthDate' | translate"></vpfa-label>
        </ng-container>
        <ng-container content>
          <vpfa-mobile-field-validation-container [validationControl]="customerForm.get('birthDate')">
            <vpfa-iso-date-picker
              [allowClear]="true"
              [format]="'short' | localeDateFormat"
              [formControl]="customerForm.get('birthDate')"
              [disabledDate]="disableNewerThanToday(timezone$ | async)"
            ></vpfa-iso-date-picker>
          </vpfa-mobile-field-validation-container>
        </ng-container>
      </vpfa-setting-box>

      <vpfa-setting-box [divider]="false">
        <ng-container label>
          <vpfa-label [text]="'customerDataModal.externalId' | translate"></vpfa-label>
        </ng-container>
        <ng-container content>
          <vpfa-mobile-field-validation-container
            [validationControl]="customerForm.get('externalId')"
            [errorMessages]="maxLengthErrorMsgList"
          >
            <vpfa-text-field fieldWidth="100%" [parentFormGroup]="customerForm" fcName="externalId"></vpfa-text-field>
          </vpfa-mobile-field-validation-container>
        </ng-container>
      </vpfa-setting-box>

      <vpfa-setting-box [divider]="false">
        <ng-container label>
          <vpfa-label [text]="'customerDataModal.owner' | translate"></vpfa-label>
        </ng-container>
        <ng-container content>
          <vpfa-select
            [parentFormGroup]="customerForm"
            [fc]="customerForm.get('ownerId')"
            [options]="ownersOptions"
            [initialValue]="ownerInitValue"
            [loading]="isOwnersLoading"
            [allowClear]="false"
          ></vpfa-select>
        </ng-container>
      </vpfa-setting-box>

      <vpfa-setting-box [divider]="false" [inline]="true">
        <ng-container label>
          <vpfa-label [text]="'customerDataModal.vatRegistered' | translate"></vpfa-label>
        </ng-container>
        <ng-container content>
          <vpfa-switch [fc]="customerForm.get('vatRegistered')" [parentFormGroup]="customerForm"></vpfa-switch>
        </ng-container>
      </vpfa-setting-box>

      <vpfa-setting-box [divider]="false">
        <ng-container label>
          <vpfa-label [text]="'customerDataModal.vatNo' | translate"></vpfa-label>
        </ng-container>
        <ng-container content>
          <vpfa-mobile-field-validation-container
            [validationControl]="customerForm.get('vatNo')"
            [errorMessages]="maxLengthErrorMsgList"
          >
            <vpfa-text-field fieldWidth="100%" [parentFormGroup]="customerForm" fcName="vatNo"></vpfa-text-field>
          </vpfa-mobile-field-validation-container>
        </ng-container>
      </vpfa-setting-box>

      <vpfa-setting-box [divider]="false">
        <ng-container label>
          <vpfa-label [text]="'customerDataModal.notes' | translate"></vpfa-label>
        </ng-container>
        <ng-container afterLabel>
          <div [ngClass]="{ 'n-invalid': customerForm.get('notes').invalid }">
            {{ customerForm?.get('notes')?.value?.length || 0 }} / {{ textareaMaxLength }}
          </div>
        </ng-container>
        <ng-container content>
          <vpfa-textarea [parentFormGroup]="customerForm" [fcName]="'notes'"></vpfa-textarea>
        </ng-container>
      </vpfa-setting-box>
    </div>

    <div class="customer-button-box gap-10px sticky-btn">
      <vpfa-action-button buttonType="submit" [disabled]="saveCustomerDisabled">
        {{ 'customerDataModal.saveCustomer' | translate }}
      </vpfa-action-button>
      <vpfa-action-button-with-confirmation
        [showPopconfirm]="!customerForm.pristine || !textListFieldsForm.pristine"
        (onConfirm)="close()"
      ></vpfa-action-button-with-confirmation>
    </div>
  </nz-spin>
</form>
