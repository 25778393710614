import { Injectable, ComponentFactoryResolver, Injector, ApplicationRef } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ConfirmModalContentComponent } from '../components/modal-confirm/confirm-modal-content.component';
import { Subject, merge } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { OKModalContentComponent } from '../components/modal-ok/ok-modal-content.component';
import { DEFAULT_MODAL_WIDTH } from '../utils/modal-widths';
import { DataDogService, MixpanelEvent, MixpanelService } from '@vpfa/utils';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(
    private nzModalService: NzModalService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private applicationRef: ApplicationRef,
    private dataDogService: DataDogService,
    private mixpanelService: MixpanelService
  ) {}

  confirm(infoLines?: string[], acceptLabel?: string, deniedLabel?: string) {
    const modalContentRef = this.componentFactoryResolver
      .resolveComponentFactory(ConfirmModalContentComponent)
      .create(this.injector);

    this.applicationRef.attachView(modalContentRef.hostView);
    modalContentRef.changeDetectorRef.detectChanges();
    if (infoLines) {
      modalContentRef.instance.infoLabelsKey = infoLines;
    }

    if (acceptLabel) {
      modalContentRef.instance.yesLabelKey = acceptLabel;
    }
    if (deniedLabel) {
      modalContentRef.instance.noLabelKey = deniedLabel;
    }

    const modalRefResult = new Subject<boolean>();

    const modalTrackingContext = { modalName: 'Confirm' };

    this.dataDogService.addAction('OpenModal', modalTrackingContext);
    this.mixpanelService.track(MixpanelEvent.ModalOpen, modalTrackingContext);
    const modalRef = this.nzModalService.create({
      nzClassName: 'vp-modal',
      nzBodyStyle: { padding: '0' },
      nzZIndex: 1000,
      nzTitle: undefined,
      nzContent: modalContentRef.instance.modalContentTemplate,
      nzFooter: null,
      nzClosable: false,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzMask: true,
      nzOnOk: _ => modalRefResult.next(true),
      nzOnCancel: _ => modalRefResult.next(false),
    });

    modalContentRef.instance.confirmResult.asObservable().subscribe(res => {
      res ? modalRef.triggerOk() : modalRef.triggerCancel();
    });

    return merge(modalRefResult.asObservable()).pipe(
      take(1),
      tap(() => {
        this.dataDogService.addAction('CloseModal', modalTrackingContext);
        this.mixpanelService.track(MixpanelEvent.ModalClose, modalTrackingContext);
        modalContentRef.destroy();
      })
    );
  }

  ok(title: string, infoLines?: string[], acceptLabel?: string) {
    const modalContentRef = this.componentFactoryResolver
      .resolveComponentFactory(OKModalContentComponent)
      .create(this.injector);

    this.applicationRef.attachView(modalContentRef.hostView);
    modalContentRef.changeDetectorRef.detectChanges();

    modalContentRef.instance.titleKey = title;

    if (infoLines) {
      modalContentRef.instance.infoLabelsKey = infoLines;
    }

    if (acceptLabel) {
      modalContentRef.instance.okLabelKey = acceptLabel;
    }

    const modalRefResult = new Subject<boolean>();

    const modalTrackingContext = { modalName: 'OK' };

    this.dataDogService.addAction('OpenModal', modalTrackingContext);
    this.mixpanelService.track(MixpanelEvent.ModalOpen, modalTrackingContext);

    const modalRef = this.nzModalService.create({
      nzClassName: 'vp-modal',
      nzBodyStyle: { padding: '0' },
      nzZIndex: 1000,
      nzTitle: undefined,
      nzContent: modalContentRef.instance.modalContentTemplate,
      nzFooter: null,
      nzClosable: false,
      nzMaskClosable: true,
      nzKeyboard: false,
      nzMask: true,
      nzWidth: DEFAULT_MODAL_WIDTH,
      nzOnOk: _ => modalRefResult.next(true),
      nzOnCancel: _ => modalRefResult.next(false),
    });

    modalContentRef.instance.confirmResult.asObservable().subscribe(() => {
      modalRef.triggerOk();
    });

    return merge(modalRefResult.asObservable()).pipe(
      take(1),
      tap(() => {
        this.dataDogService.addAction('CloseModal', modalTrackingContext);
        this.mixpanelService.track(MixpanelEvent.ModalClose, modalTrackingContext);
        modalContentRef.destroy();
      })
    );
  }
}
