import { Component, ViewChild } from '@angular/core';
import { IFloatingFilterParams, IFloatingFilter, TextFilterModel, TextFilter } from 'ag-grid-community';
import { AgFrameworkComponent } from 'ag-grid-angular';
import { isNil } from 'lodash';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GENERIC_NUMBER_MAX, GENERIC_NUMBER_MIN, numberFromToValidator } from '@vpfa/shared/validators';
import { CURRENCY_DISPLAY_FORMAT, NumberFieldComponent } from '@vpfa/ui-kit';

export interface NumberRangeFloatingFilterParams extends IFloatingFilterParams {
  maxFractionNumber: number;
  maxCharCount: number;
  minValue?: number;
  maxValue?: number;
  isCurrency?: boolean;
}

export interface NumberRangeFilterModel {
  from: number | null;
  to: number | null;
}

@Component({
  selector: 'vpfa-number-range-floating-filter',
  templateUrl: './number-range-floating-filter.component.html',
  styleUrls: ['./number-range-floating-filter.component.scss'],
})
export class NumberRangeFloatingFilterComponent
  implements IFloatingFilter, AgFrameworkComponent<NumberRangeFloatingFilterParams>
{
  CURRENCY_DISPLAY_FORMAT = CURRENCY_DISPLAY_FORMAT;
  isNil = isNil;
  public errorHeightCompensationPx = -11;

  @ViewChild('firstInput') firstInput?: NumberFieldComponent;

  private params: NumberRangeFloatingFilterParams;

  public initialValueFrom: number | null = null;
  public initialValueTo: number | null = null;

  public formGroup = new FormGroup({
    from: new FormControl(null, []),
    to: new FormControl(null, []),
  });

  public maxValue = GENERIC_NUMBER_MAX;
  public minValue = GENERIC_NUMBER_MIN;

  public fieldName: string | null = null;

  get maxFractionNumber(): number {
    return this.params?.maxFractionNumber ?? 2;
  }

  get maxCharCount(): number {
    return this.params?.maxCharCount ?? 0;
  }

  public visible: boolean = false;
  public isCurrency: boolean = false;

  agInit(params: NumberRangeFloatingFilterParams): void {
    this.params = params;

    this.fieldName = this.params?.column?.getColDef()?.field ?? null;

    if (this.fieldName === null) {
      // When this error occurs, HTML ID has an invalid name
      console.warn('Missing field name');
    }

    this.isCurrency = params?.isCurrency ?? false;

    if (!isNil(this.params?.minValue)) {
      this.minValue = this.params?.minValue;
    }
    if (!isNil(this.params?.maxValue)) {
      this.maxValue = this.params?.maxValue;
    }

    this.formGroup.controls.from.setValidators([
      Validators.min(this.minValue),
      Validators.max(this.maxValue),
      numberFromToValidator('to'),
    ]);
    this.formGroup.controls.to.setValidators([Validators.min(this.minValue), Validators.max(this.maxValue)]);
  }

  onParentModelChanged(parentModel: TextFilterModel): void {
    if (isNil(parentModel?.filter)) {
      this.initialValueFrom = null;
      this.initialValueTo = null;
      return;
    }

    const filterData = JSON.parse(parentModel.filter) as NumberRangeFilterModel;

    this.initialValueFrom = filterData?.from ?? null;
    this.initialValueTo = filterData?.to ?? null;
  }

  onApplyFilters() {
    this.formGroup.markAllAsTouched();

    if (this.formGroup.invalid) {
      return;
    }

    this.params.parentFilterInstance((instance: TextFilter) => {
      const value: NumberRangeFilterModel = {
        from: this.formGroup.controls.from.value ?? null,
        to: this.formGroup.controls.to.value ?? null,
      };

      if (isNil(value.from) && isNil(value.to)) {
        instance.setModel(null);
        this.params.api.onFilterChanged();
        return;
      }

      instance.onFloatingFilterChanged('other', JSON.stringify(value));
    });

    this.visible = false;
  }

  onVisibleChange(isVisible: boolean) {
    if (isVisible !== true) {
      this.formGroup.controls.from.setValue(this.initialValueFrom);
      this.formGroup.controls.to.setValue(this.initialValueTo);
      return;
    }

    setTimeout(() => {
      this.firstInput?.focus();
    }, 100);
  }
}
