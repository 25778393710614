import { Action } from '@ngrx/store';
import { ClearTokenBase, SetTokenFromDtoBase } from './token.actions';
import { LoginPayload } from '../../models/login-payload';
import { UserContext } from '@vpfa/rest-api/admin';
import { VPError } from '@vpfa/rest-api/valuation';

export enum AuthLoginActionTypes {
  Login = '[Login Page] Login',
  LoginSuccess = '[Auth Login API] Login Success',
  LoginFinished = '[Auth Login API] Login Finished',
  LoginSetTokenFromDTO = '[Auth Login API] Set Token From DTO',
  LoginSetToken = '[Auth Login API] Set Token',
  LoginFailure = '[Auth Login API] Login Failure',
  LoginClearToken = '[Auth Login API] Clear Token',
  LoginVerifyAssignedBranchesAmount = '[Auth Login API] Verify Assigned Branches Amount',
  LoginSessionAlreadyExistForTheUser = '[Auth Login API] Login Session Already Exist For The User',
  LoginCancel = '[Auth Login API] LoginCancel',
  RequestPasswordReset = '[Auth Login API] Request Password Reset',
  PasswordResetRequestSuccess = '[Auth Login API] Password Reset Request Success',
  PasswordResetRequestError = '[Auth Login API] Password Reset Request Error',
  PasswordResetRequestClear = '[Auth Login API] Password Reset Request Clear',
  LoginError = '[Auth Login API] Login Error',
}

export class Login implements Action {
  readonly type = AuthLoginActionTypes.Login;
  constructor(public payload: LoginPayload) {}
}

export class LoginSuccess implements Action {
  readonly type = AuthLoginActionTypes.LoginSuccess;
  constructor(public payload: UserContext) {}
}

export class LoginFinished implements Action {
  readonly type = AuthLoginActionTypes.LoginFinished;
}

export class LoginSetTokenFromDto extends SetTokenFromDtoBase {
  readonly type = AuthLoginActionTypes.LoginSetTokenFromDTO;
}

export class LoginFailure implements Action {
  readonly type = AuthLoginActionTypes.LoginFailure;
  constructor(public payload: string) {}
}

export class LoginCancel implements Action {
  readonly type = AuthLoginActionTypes.LoginCancel;
}

export class LoginError implements Action {
  readonly type = AuthLoginActionTypes.LoginError;
  constructor(public payload: Pick<VPError, 'id' | 'codeName'>) {}
}

export class LoginClearToken extends ClearTokenBase {
  readonly type = AuthLoginActionTypes.LoginClearToken;
}

export class LoginVerifyAssignedBranchesAmount implements Action {
  readonly type = AuthLoginActionTypes.LoginVerifyAssignedBranchesAmount;
}

export class LoginSessionAlreadyExistForTheUser implements Action {
  readonly type = AuthLoginActionTypes.LoginSessionAlreadyExistForTheUser;
  constructor(public payload: LoginPayload) {}
}

export class RequestPasswordReset implements Action {
  readonly type = AuthLoginActionTypes.RequestPasswordReset;
  constructor(public payload: { email: string; recaptchaToken: string }) {}
}

export class PasswordResetRequestSuccess implements Action {
  readonly type = AuthLoginActionTypes.PasswordResetRequestSuccess;
  constructor(public payload: any) {}
}

export class PasswordResetRequestError implements Action {
  readonly type = AuthLoginActionTypes.PasswordResetRequestError;
}
export class PasswordResetRequestClear implements Action {
  readonly type = AuthLoginActionTypes.PasswordResetRequestClear;
}

export type AuthLoginActions =
  | Login
  | LoginSuccess
  | LoginFinished
  | LoginSetTokenFromDto
  | LoginFailure
  | LoginClearToken
  | LoginVerifyAssignedBranchesAmount
  | LoginSessionAlreadyExistForTheUser
  | LoginCancel
  | RequestPasswordReset
  | PasswordResetRequestSuccess
  | PasswordResetRequestError
  | PasswordResetRequestClear
  | LoginError;

export const fromLoginActions = {
  Login,
  LoginSuccess,
  LoginFinished,
  LoginSetTokenFromDto,
  LoginFailure,
  LoginClearToken,
  LoginVerifyAssignedBranchesAmount,
  LoginSessionAlreadyExistForTheUser,
  LoginCancel,
  RequestPasswordReset,
  PasswordResetRequestSuccess,
  PasswordResetRequestError,
  PasswordResetRequestClear,
  LoginError,
};
