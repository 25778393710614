import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextareaComponent } from './components/textarea/textarea.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { UiKitModule } from '@vpfa/ui-kit';
import { UtilsModule } from '@vpfa/utils';
import { FormStepperComponent } from './components/form-stepper/form-stepper.component';
import { SharedPipesModule } from '@vpfa/shared/pipes';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NzInputModule,
    UiKitModule,
    UtilsModule,
    SharedPipesModule,
    NzDatePickerModule,
    FlexLayoutModule,
    TranslateModule,
  ],
  exports: [TextareaComponent, FormStepperComponent],
  declarations: [TextareaComponent, FormStepperComponent],
})
export class UiKitFormControlsModule {}
