import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { AuthFacade, AuthLoginActionTypes, AuthLogoutActionTypes, LoginSuccess } from '@vpfa/auth';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  pluck,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import {
  CheckLogoAvailability,
  CheckLogoAvailabilityError,
  CheckLogoAvailabilitySuccess,
  InitProfileEffects,
  LoadCountryConfiguration,
  LoadCountryConfigurationError,
  LoadCountryConfigurationSuccess,
  LoadProfileFailure,
  LoadProfileSuccess,
  LoadUserBranchDetails,
  LoadUserBusinessDetails,
  LoadUserContext,
  ProfileActions,
  ProfileActionTypes,
  ResetProfile,
  UserBranchDetailsLoaded,
  UserBranchDetailsLoadError,
  UserBusinessDetailsLoaded,
  UserBusinessDetailsLoadError,
  UserContextLoaded,
  UserContextLoadError,
  UpdateLastSelectedVehicleType,
  UpdateLastSelectedVehicleTypeSuccess,
  UpdateLastSelectedVehicleTypeError,
  UpdateUserBroadcastPremiumFeatureSettings,
  UpdateUserBroadcastPremiumFeatureSettingsSuccess,
  UpdateUserBroadcastPremiumFeatureSettingsError,
} from './profile.actions';
import {
  BranchViewService,
  BusinessViewService,
  LogoViewService,
  UserContextService,
  UserViewService,
  UserSettingsService,
} from '@vpfa/rest-api/admin';
import { Action, Store } from '@ngrx/store';
import { LocaleFacade, SelectContentLanguage, SelectUILanguage } from '@vpfa/locale';
import { LanguageEnum } from '@vpfa/shared/translate';
import { LogoFacade } from '@vpfa/logo/data';
import { DataDogService, isEmptyGuid, MixpanelEvent, MixpanelService } from '@vpfa/utils';
import { BasicNotificationsService } from '@vpfa/shared/notifications';
import { isEqual, isNil } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { CountryConfigurationViewService } from '@vpfa/rest-api/valuation';

import { profileQuery } from './profile.selectors';

@Injectable()
export class ProfileEffects implements OnInitEffects {
  private emptyGuid = '00000000-0000-0000-0000-000000000000';

   onInitProfileEffects$ = createEffect(() => this.actions$.pipe(
    ofType(ProfileActionTypes.InitProfileEffects),
    withLatestFrom(this.authFacade.decodedToken$),
    filter(([, isTokenLoaded]) => Boolean(isTokenLoaded)),
    mapTo(new LoadUserContext())
  ));

  
  afterAuthenticatedLoadProfile$ = createEffect(() => this.actions$.pipe(
    ofType(AuthLoginActionTypes.LoginSuccess),
    mapTo(new LoadUserContext())
  ));

  
  afterLogoutResetProfile$ = createEffect(() => this.actions$.pipe(ofType(AuthLogoutActionTypes.Logout), mapTo(new ResetProfile())));

  
  loadUserContext$ = createEffect(() => this.actions$.pipe(
    ofType(ProfileActionTypes.LoadUserContext),
    switchMap(action =>
      this.userContextService.getUserContext().pipe(
        map(res => {
          if (action.payload) {
            this.translatingBasicNotificationsService.success(action.payload);
          }
          const userHasBranchAssigned = !isNil(res.branchId) && !isEmptyGuid(res.branchId);
          return new UserContextLoaded({ context: res, userHasBranchAssigned });
        }),
        tap(() => {
          this.localeFacade.loadUserCountry();
          this.localeFacade.loadCsvConfiguration();
        }),
        catchError(error => of(new UserContextLoadError({ error })))
      )
    )
  ));

  
  loadProfile$ = createEffect(() => this.actions$.pipe(
    ofType(ProfileActionTypes.LoadProfile, ProfileActionTypes.UserContextLoaded),
    switchMap(() =>
      this.userViewService.getUserProfile().pipe(
        map(res => new LoadProfileSuccess(res)),
        catchError(error => of(new LoadProfileFailure({ error })))
      )
    )
  ));

  
  loadUserBusinessDetails$ = createEffect(() => this.actions$.pipe(
    ofType<LoadUserBusinessDetails>(ProfileActionTypes.LoadUserBusinessDetails),
    switchMap(({ payload }) =>
      this.businessViewService.get(payload).pipe(
        map(res => new UserBusinessDetailsLoaded(res)),
        catchError(error => of(new UserBusinessDetailsLoadError()))
      )
    )
  ));

  
  loadBranchDetails$ = createEffect(() => this.actions$.pipe(
    ofType<LoadUserBranchDetails>(ProfileActionTypes.LoadUserBranchDetails),
    switchMap(({ payload }) =>
      this.branchViewService.get(payload).pipe(
        map(res => new UserBranchDetailsLoaded(res)),
        catchError(error => of(new UserBranchDetailsLoadError()))
      )
    )
  ));

  
  userContextAndProfileIsLoaded$ = createEffect(() => combineLatest([
    this.actions$.pipe(ofType<UserContextLoaded>(ProfileActionTypes.UserContextLoaded), pluck('payload')),
    this.actions$.pipe(ofType<LoadProfileSuccess>(ProfileActionTypes.LoadProfileSuccess), pluck('payload')),
  ]).pipe(
    tap(([userContext, userProfile]) => {
      this.mixpanel.setUserContextAndProfile(userContext.context, userProfile);
      this.mixpanel.init();
      this.dataDogService.setUserContextAndProfile(userContext.context, userProfile);
    }),
    map(
      ([payload]) =>
        new CheckLogoAvailability({ countryId: payload.context?.countryId, businessId: payload.context?.businessId })
    )
  ));

  
  whenUserBusinessAndBranchDetailsAreLoaded$ = createEffect(() => combineLatest([
    this.actions$.pipe(ofType<UserBusinessDetailsLoaded>(ProfileActionTypes.UserBusinessDetailsLoaded)),
    this.actions$.pipe(ofType<UserBranchDetailsLoaded>(ProfileActionTypes.UserBranchDetailsLoaded)),
  ]).pipe(
    tap(([business, branch]) => {
      this.mixpanel.setUserBranchAndBusiness(branch.payload, business.payload);
      this.dataDogService.setUserBranchAndBusiness(branch.payload, business.payload);
    })
  ), { dispatch: false });
  
  mixpanelEventAfterLoginSuccess$ = createEffect(() => combineLatest([
    combineLatest([
      this.actions$.pipe(ofType<UserBusinessDetailsLoaded>(ProfileActionTypes.UserBusinessDetailsLoaded)),
      this.actions$.pipe(ofType<UserBranchDetailsLoaded>(ProfileActionTypes.UserBranchDetailsLoaded)),
      this.actions$.pipe(ofType<UserContextLoaded>(ProfileActionTypes.UserContextLoaded)),
      this.actions$.pipe(ofType<LoadProfileSuccess>(ProfileActionTypes.LoadProfileSuccess)),
    ]).pipe(distinctUntilChanged(isEqual)),
    this.actions$.pipe(ofType<LoginSuccess>(AuthLoginActionTypes.LoginSuccess)),
  ]).pipe(
    tap(() => {
      this.mixpanel.track(MixpanelEvent.LoginSuccess);
      this.dataDogService.userLoggedIn();
    })
  ), { dispatch: false });

  
  afterUserContextLoadedFetchUserBusinesDetails$ = createEffect(() => this.actions$.pipe(
    ofType<UserContextLoaded>(ProfileActionTypes.UserContextLoaded),
    filter(action => action.payload.userHasBranchAssigned),
    map(({ payload }) => {
      if (payload.context.businessId && payload.context.businessId !== this.emptyGuid) {
        return new LoadUserBusinessDetails(payload.context.businessId);
      } else {
        return new UserBusinessDetailsLoadError();
      }
    })
  ));

  
  afterUserContextLoadedFetchUserBranchDetailsDetails$ = createEffect(() => this.actions$.pipe(
    ofType<UserContextLoaded>(ProfileActionTypes.UserContextLoaded),
    filter(action => action.payload.userHasBranchAssigned),
    map(({ payload }) => {
      if (payload.context.branchId && payload.context.branchId !== this.emptyGuid) {
        return new LoadUserBranchDetails(payload.context.branchId);
      } else {
        return new UserBranchDetailsLoadError();
      }
    })
  ));

  
  onUserContextLoadedInitializeUIAndContentLanguages$ = createEffect(() => this.actions$.pipe(
    ofType<UserContextLoaded>(ProfileActionTypes.UserContextLoaded),
    pluck('payload'),
    concatMap(({ context }) => {
      if (context.isCountryAdmin || context.isSystemAdmin) {
        return [
          new SelectUILanguage(context.uiLanguage as LanguageEnum),
          new SelectContentLanguage(context.contentLanguage as LanguageEnum),
        ];
      } else {
        return [
          new SelectUILanguage(context.uiLanguage as LanguageEnum),
          new SelectContentLanguage(context.contentLanguage as LanguageEnum),
          new LoadCountryConfiguration({ countryId: context.countryId }),
        ];
      }
    })
  ));

  
  loadCountryDateRangeThreshold = createEffect(() => this.actions$.pipe(
    ofType<LoadCountryConfiguration>(ProfileActionTypes.LoadCountryConfiguration),
    switchMap(action =>
      this.countryConfigurationViewService.getCountryConfiguration(action.payload.countryId).pipe(
        map(
          res => new LoadCountryConfigurationSuccess(res),
          catchError(() => of(new LoadCountryConfigurationError()))
        )
      )
    )
  ));

  
  checkLogoAvailability = createEffect(() => this.actions$.pipe(
    ofType<CheckLogoAvailability>(ProfileActionTypes.CheckLogoAvailability),
    switchMap(action =>
      this.logoViewService.isLogoAvaiable(action.payload.countryId, action.payload.businessId).pipe(
        map(
          res =>
            new CheckLogoAvailabilitySuccess({
              countryId: action.payload?.countryId,
              businessId: action.payload?.businessId,
              availability: res,
            }),
          catchError(() => of(new CheckLogoAvailabilityError()))
        )
      )
    )
  ));

  
  checkLogoAvailabilitySuccess$ = createEffect(() => this.actions$.pipe(
    ofType<CheckLogoAvailabilitySuccess>(ProfileActionTypes.CheckLogoAvailabilitySuccess),
    pluck('payload'),
    tap(payload => {
      if (
        payload?.businessId &&
        payload?.businessId !== this.emptyGuid &&
        payload?.availability?.isBusinessLogoAvailable
      ) {
        this.logoFacade.loadLogoByBusiness(payload.businessId);
      }
      if (
        payload?.countryId &&
        payload?.countryId !== this.emptyGuid &&
        payload?.availability?.isCountryLogoAvailable
      ) {
        this.logoFacade.loadLogoByCountry(payload.countryId);
      }
    })
  ), { dispatch: false });

  
  updateUserIdentificationVehicleType$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateLastSelectedVehicleType>(ProfileActionTypes.UpdateLastSelectedVehicleType),
    withLatestFrom(this.store.select(profileQuery.getIdentificationVehicleType)),
    filter(([action, oldType]) => action.payload.identificationVehicleType !== oldType),
    map(([action]) => action),
    switchMap(action =>
      this.userSettingsService.updateUserIdentificationVehicleType(action.payload).pipe(
        map(res => new UpdateLastSelectedVehicleTypeSuccess(action.payload.identificationVehicleType)),
        catchError(() => of(new UpdateLastSelectedVehicleTypeError()))
      )
    )
  ));

  
  updateUserBroadcastPremiumFeatureSettings$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateUserBroadcastPremiumFeatureSettings>(ProfileActionTypes.UpdateUserBroadcastPremiumFeatureSettings),
    switchMap(action =>
      this.userSettingsService.updateUpdateUserBroadcastPremiumFeatureSettings(action.payload).pipe(
        map(
          () => new UpdateUserBroadcastPremiumFeatureSettingsSuccess(action.payload?.hideBroadcastPremiumFeaturePopup)
        ),
        catchError(() => of(new UpdateUserBroadcastPremiumFeatureSettingsError()))
      )
    )
  ));

  ngrxOnInitEffects(): Action {
    return new InitProfileEffects();
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private actions$: Actions<ProfileActions>,
    private userContextService: UserContextService,
    private localeFacade: LocaleFacade,
    private logoFacade: LogoFacade,
    private authFacade: AuthFacade,
    private mixpanel: MixpanelService,
    private userViewService: UserViewService,
    private businessViewService: BusinessViewService,
    private branchViewService: BranchViewService,
    private translatingBasicNotificationsService: BasicNotificationsService,
    private logoViewService: LogoViewService,
    private countryConfigurationViewService: CountryConfigurationViewService,
    private store: Store,
    private userSettingsService: UserSettingsService,
    private dataDogService: DataDogService
  ) {}
}
