import { ChangeDetectorRef, Component } from '@angular/core';
import { BaseProvenanceCheckSectionKeepersComponent } from '../base-provenance-check-section-keepers.component';
import { LocaleFacade } from '@vpfa/locale';

@Component({
  selector: 'vpfa-mobile-provenance-check-section-keepers',
  templateUrl: './mobile-provenance-check-section-keepers.component.html',
  styleUrls: ['./mobile-provenance-check-section-keepers.component.scss'],
})
export class MobileProvenanceCheckSectionKeepersComponent extends BaseProvenanceCheckSectionKeepersComponent {
  constructor(localeFacade: LocaleFacade, ref: ChangeDetectorRef) {
    super(localeFacade, ref);
  }
}
