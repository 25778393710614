import {
  ALLOWED_CSV_MIMES,
  ALLOWED_DOCUMENT_MIMES,
  ALLOWED_IMAGE_MIMES,
  FileUploadType,
  ALLOWED_DOCUMENT_EXTENSIONS,
  ALLOWED_JPG_MIMES,
  ALLOWED_PDF_MIMES,
  ALLOWED_JSON_MIMES,
  ALLOWED_IMAGE_EXTENSIONS,
} from '../models/file-types';
import { last } from 'lodash';

export const getAcceptInfo = (fileUploadType: FileUploadType, acceptInfoType: 'string' | 'list' = 'string') => {
  const asString = acceptInfoType === 'string';
  switch (fileUploadType) {
    case FileUploadType.StandardImage:
    case FileUploadType.CaseGalleryImages:
    case FileUploadType.Logo:
    case FileUploadType.PromoImages:
    case FileUploadType.Image:
      return asString ? ALLOWED_IMAGE_EXTENSIONS.join(',') : ALLOWED_IMAGE_MIMES;
    case FileUploadType.Csv:
      return asString ? ALLOWED_CSV_MIMES.join(',') : ALLOWED_CSV_MIMES;
    case FileUploadType.Document:
      return asString ? ALLOWED_DOCUMENT_EXTENSIONS.join(',') : ALLOWED_DOCUMENT_MIMES;
    case FileUploadType.Jpg:
      return asString ? ALLOWED_JPG_MIMES.join(',') : ALLOWED_JPG_MIMES;
    case FileUploadType.Pdf:
      return asString ? ALLOWED_PDF_MIMES.join(',') : ALLOWED_PDF_MIMES;
    case FileUploadType.Json:
      return asString ? ALLOWED_JSON_MIMES.join(',') : ALLOWED_JSON_MIMES;
  }
};

export const isFileTypeAccepted = (fileType: string, fileUploadType: FileUploadType): boolean => {
  const fileAcceptInfo = getAcceptInfo(fileUploadType, 'list') as Array<string>;
  return fileAcceptInfo.includes(fileType) === true;
};

export const getFileExtension = (file: File): string => {
  if (file && file.name) {
    const nameArray = file.name.split('.');
    if (nameArray.length > 1) {
      return last(nameArray).replace('jpeg', 'jpg').replace('tiff', 'tif');
    }
  }
  return null;
};
