<vpfa-modal
  width="650px"
  [modalName]="title | translate"
  [showBackdrop]="true"
  [isClosable]="false"
  [isVisible]="isVisible"
  [keyboard]="false"
  [maskClosable]="false"
  (closeAction)="close()"
  (afterCloseAction)="afterClose()"
>
  <vpfa-modal-content>
    <button
      class="ant-modal-close"
      nz-button
      nzType="link"
      nz-popconfirm
      [nzPopconfirmTrigger]="'click'"
      [nzPopconfirmTitle]="'common.unsavedChanges' | translate"
      [nzCancelText]="'common.no' | translate"
      [nzOkText]="'common.yes' | translate"
      nzPopconfirmPlacement="bottom"
      nzPopconfirmOverlayClassName="modal-confirm-popover"
      (nzOnConfirm)="close()"
      [nzCondition]="quoteForm.pristine && templateForm.pristine"
    >
      <span>
        <i nz-icon nzType="close-circle" nzTheme="fill"></i>
      </span>
    </button>
    <form (ngSubmit)="saveQuote()" [formGroup]="quoteForm">
      <nz-spin [nzSpinning]="isProcessing || isEmailDetailsLoading || isTemplatesLoading">
        <h1>{{ title | translate }}</h1>
        <div [fxLayout]="'column'" [fxLayoutGap]="'20px'">
          <div>
            <vpfa-setting-box [divider]="false">
              <ng-container label>
                <vpfa-label [text]="translationLabelParent + '.finalPrice' | translate"></vpfa-label>
              </ng-container>
              <ng-container content>
                <vpfa-number-field
                  [errorMessages]="finalPriceErrorMessages"
                  [currencyHintMode]="CURRENCY_DISPLAY_FORMAT.SYMBOL"
                  fcName="finalPrice"
                  [parentFormGroup]="quoteForm"
                  fieldWidth="100%"
                  [maxFractionNumber]="2"
                  [heightCompensationPx]="-12"
                >
                </vpfa-number-field>
              </ng-container>
            </vpfa-setting-box>

            <vpfa-setting-box [divider]="false">
              <ng-container label>
                <vpfa-label [text]="'print.modal.template' | translate"></vpfa-label>
              </ng-container>
              <ng-container content>
                <vpfa-select
                  [parentFormGroup]="templateForm"
                  [fc]="templateControl"
                  [options]="availableTemplates"
                  [initialValue]="initialTemplate"
                  [allowClear]="false"
                ></vpfa-select>
              </ng-container>
            </vpfa-setting-box>

            <vpfa-setting-box [divider]="false">
              <ng-container label>
                <vpfa-label [text]="translationLabelParent + '.subject' | translate"></vpfa-label>
              </ng-container>
              <ng-container content>
                <vpfa-text-field
                  [parentFormGroup]="quoteForm"
                  [errorMessages]="subjectErrorMsgList"
                  fcName="subject"
                ></vpfa-text-field>
              </ng-container>
            </vpfa-setting-box>

            <vpfa-setting-box [divider]="false">
              <ng-container label>
                <vpfa-label [text]="translationLabelParent + '.message' | translate"></vpfa-label>
              </ng-container>
              <ng-container afterLabel>
                <div [ngClass]="{ 'b-invalid': quoteForm.get('body').invalid }">
                  {{ quoteForm?.get('body')?.value?.length || 0 }} / {{ textAreaMaxLength }}
                </div>
              </ng-container>
              <ng-container content>
                <vpfa-textarea [parentFormGroup]="quoteForm" [fcName]="'body'"></vpfa-textarea>
              </ng-container>
            </vpfa-setting-box>

            <vpfa-setting-box [divider]="false">
              <ng-container label>
                <vpfa-label [text]="customerLabel | translate"></vpfa-label>
              </ng-container>
              <ng-container content>
                <vpfa-add-remove-one-customer
                  [placeholderCode]="'reserveVehicleModal.selectCustomer'"
                  [customerList]="caseCustomersOptions"
                  [disabled]="!caseCustomersOptions || toggleAddForm"
                  [parentFormGroup]="quoteForm"
                  [fc]="quoteForm.get('owner')"
                  [allowClear]="true"
                ></vpfa-add-remove-one-customer>
              </ng-container>
            </vpfa-setting-box>

            <vpfa-add-customer-by-email
              [formGroup]="addCustomerForm"
              [ownerSelected]="isOwnerSelected()"
              [toggleAddForm]="toggleAddForm"
              [firstNameErrorMsgList]="firstNameErrorMsgList"
              [lastNameErrorMsgList]="lastNameErrorMsgList"
              [emailErrorMsgList]="emailErrorMsgList"
              (toggleForm)="onToggleAddForm($event)"
            ></vpfa-add-customer-by-email>

            <div class="download">
              <vpfa-switch [parentFormGroup]="templateForm" [fc]="templateForm.get('download')"></vpfa-switch>
              <span class="pl-3">{{ downloadLabel | translate }}</span>
            </div>
          </div>

          <div class="tax-des">
            <vpfa-tax-information [tax]="activeCaseDataTaxes"></vpfa-tax-information>
          </div>

          <div fxFlex fxLayout="column" fxLayoutGap="10px">
            <vpfa-action-button
              buttonType="submit"
              [disabled]="quoteForm.invalid || (addCustomerForm.invalid && toggleAddForm) || templateForm.invalid"
            >
              {{ translationLabelParent + '.sendOffer' | translate }}
            </vpfa-action-button>
            <vpfa-action-button
              nz-popconfirm
              [nzPopconfirmTrigger]="'click'"
              [nzPopconfirmTitle]="'common.unsavedChanges' | translate"
              [nzCancelText]="'common.no' | translate"
              [nzOkText]="'common.yes' | translate"
              nzPopconfirmPlacement="top"
              nzPopconfirmOverlayClassName="modal-confirm-popover"
              (nzOnConfirm)="close()"
              [nzCondition]="quoteForm.pristine && templateForm.pristine"
              [outline]="true"
              [border]="false"
            >
              {{ 'common.cancel' | translate }}
            </vpfa-action-button>
          </div>
        </div>
      </nz-spin>
    </form>
  </vpfa-modal-content>
</vpfa-modal>
