<div class="info" fxLayout="row">
  <i class="zmdi zmdi-info-outline"></i>
  <span>
    <ng-container *ngIf="(caseInStock$ | async) === false; else broadcastAvailable">
      {{ 'confirmAdvertisedImagesModal.broadcastNotAvailableYet' | translate }}
    </ng-container>
    <ng-template #broadcastAvailable>
      <ng-container *ngIf="(isBroadcastEnabled$ | async) === true; else broadcastDisabled">
        {{ 'confirmAdvertisedImagesModal.broadcastIsEnabled' | translate }}
      </ng-container>
      <ng-template #broadcastDisabled>
        {{ 'confirmAdvertisedImagesModal.broadcastIsDisabled' | translate }}
      </ng-template>
    </ng-template>
  </span>
</div>

<div class="info" fxLayout="row">
  <i class="zmdi zmdi-info-outline"></i>
  <span>
    <ng-container *ngIf="(caseInStock$ | async) === false; else publicSiteAvailable">
      {{ 'confirmAdvertisedImagesModal.publicSiteNotAvailableYet' | translate }}
    </ng-container>
    <ng-template #publicSiteAvailable>
      <ng-container *ngIf="(publicSiteIsEnabled$ | async) === true; else publicSiteDisabled">
        {{ 'confirmAdvertisedImagesModal.publicSiteIsEnabled' | translate }}
      </ng-container>
      <ng-template #publicSiteDisabled>
        {{ 'confirmAdvertisedImagesModal.publicSiteIsDisabled' | translate }}
      </ng-template>
    </ng-template>
  </span>
</div>

<nz-spin [nzSpinning]="loading$ | async">
  <div
    fxLayoutAlign="flex-start"
    fxLayoutGap="30px"
    fxLayout="row wrap"
    fxLayout.lt-mv="column"
    [ngClass]="{ 'images-container': (isMobile$ | async) === false }"
  >
    <ng-container *ngFor="let image of images">
      <div
        [ngClass]="{
          border: image.isSelected,
          'item-box': !(isMobile$ | async),
          'item-box-mobile': isMobile$ | async
        }"
        (mouseenter)="image.isHovered = true"
        (mouseleave)="image.isHovered = false"
      >
        <div
          [ngClass]="{
            'strong-border': image.isSelected,
            'img-box': !(isMobile$ | async),
            'img-box-mobile': isMobile$ | async
          }"
          (click)="image.isSelected = !image.isSelected"
        >
          <img [src]="image.url | safeUrl" />
          <div class="icon-btn-box" [ngClass]="{ 'overlay-visible': image.isHovered }" *vpfaResponsiveIf="'web'">
            <div fxLayout="row">
              <div *ngIf="!image.isSelected">
                <span class="zmdi zmdi-plus-circle icon-btn select-image"></span>
              </div>
              <div *ngIf="image.isSelected">
                <span class="zmdi icon-btn zmdi-minus-circle unselect-image"></span>
              </div>
            </div>
          </div>
          <div *vpfaResponsiveIf="'web'">
            <span
              *ngIf="!image.isHovered && image.isSelected"
              class="zmdi icon-btn zmdi-check-circle checked-unchecked-icon"
            ></span>
          </div>
          <div *vpfaResponsiveIf="'mobile'">
            <span *ngIf="!image.isSelected" class="zmdi icon-btn zmdi-circle-o"></span>
            <span *ngIf="image.isSelected" class="zmdi icon-btn zmdi-check-circle checked-unchecked-icon"></span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div [ngClass]="{ 'sticky-btn': (isMobile$ | async) === true }">
    <vpfa-action-button [disabled]="loading$ | async" (click)="onSave()">
      {{ 'common.save' | translate }}
    </vpfa-action-button>

    <vpfa-action-button
      nz-popconfirm
      nzPopconfirmPlacement="top"
      nzPopconfirmOverlayClassName="modal-confirm-popover"
      [nzCondition]="false"
      [nzPopconfirmTitle]="'common.unsavedChanges' | translate"
      [nzCancelText]="'common.no' | translate"
      [nzOkText]="'common.yes' | translate"
      [outline]="true"
      [border]="false"
      (nzOnConfirm)="onCancel()"
    >
      {{ 'common.cancel' | translate }}
    </vpfa-action-button>
  </div>
</nz-spin>
