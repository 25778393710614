import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CountryDto, LoginCountryDto } from '@vpfa/rest-api/admin';
import {
  defaultUiConfig,
  DummyFilename,
  EnInternationalFilename,
  getTranslateFileName,
  getTranslateFilePath,
  LanguageEnum,
  setNgZorroLocale,
} from '@vpfa/shared/translate';
import { CivicCookiesService } from '@vpfa/utils';
import { isEqual, isNil } from 'lodash';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { BehaviorSubject, combineLatest, EMPTY, merge } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { LocaleFacade } from './+state/locale.facade';
// FIXME: absolute due to circular dependency
import { AuthFacade } from 'libs/auth/src/lib/+state/auth.facade';
import { HttpClient } from '@angular/common/http';
import { DeploymentVersionService, EnvironmentsService } from '@vpfa/environments/data';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private uiLang = defaultUiConfig.uiLang;
  private countryCode = defaultUiConfig.countryCode;
  public loadingTranslation$ = new BehaviorSubject(true);

  constructor(
    private localeFacade: LocaleFacade,
    private i18n: NzI18nService,
    private translateService: TranslateService,
    private civicCookiesService: CivicCookiesService,
    private authFacade: AuthFacade,
    private http: HttpClient,
    private deploymentVersionService: DeploymentVersionService,
    private environmentService: EnvironmentsService
  ) {
    translateService.setDefaultLang(getTranslateFileName(this.uiLang, this.countryCode));

    combineLatest([
      this.localeFacade.selectedUiLanguage$,
      merge(this.localeFacade.country$, this.authFacade.countryBaseInformation$).pipe(
        filter((countryDetails: CountryDto | LoginCountryDto) => Boolean(countryDetails))
      ),
    ])
      .pipe(
        distinctUntilChanged(isEqual),
        switchMap(([uiLang, countryDetails]) => {
          // INFO: The same logic as in CustomTranslateHttpLoader -> getTranslation() method
          if (
            !isNil(this.environmentService.config) &&
            uiLang !== EnInternationalFilename &&
            uiLang !== DummyFilename
          ) {
            const filePath = getTranslateFilePath(
              uiLang,
              countryDetails?.countryCode,
              this.environmentService.config,
              this.deploymentVersionService.currentVersion
            );
            return this.http.get(filePath).pipe(
              map(() => this.handleLangChange(uiLang, countryDetails)),
              catchError(() => {
                this.handleLangChange(defaultUiConfig.uiLang, countryDetails);
                return EMPTY;
              })
            );
          } else if (uiLang === DummyFilename) {
            this.handleLangChange(uiLang, countryDetails);
            return EMPTY;
          } else {
            this.handleLangChange(defaultUiConfig.uiLang, countryDetails);
            return EMPTY;
          }
        })
      )
      .subscribe();
  }

  private handleLangChange(uiLang: LanguageEnum, countryDetails: CountryDto | LoginCountryDto) {
    if (!isNil(uiLang)) {
      this.uiLang = uiLang;
    }

    if (!isNil(countryDetails)) {
      this.countryCode = countryDetails?.countryCode;
    }

    //INFO: Similar functionality is in locale effects
    this.setLanguage(countryDetails?.civicCookieApiKey);
    this.loadingTranslation$.next(false);
  }

  public setLanguage(civicCookiesApiKey: string) {
    this.translateService.onLangChange.subscribe(() => {
      this.civicCookiesService.loadCookieControl(civicCookiesApiKey);
    });
    this.reloadLanguage();
  }

  public reloadLanguage() {
    setNgZorroLocale(this.uiLang, this.i18n);
    this.translateService.use(getTranslateFileName(this.uiLang, this.countryCode));
  }
}
