import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

/**
 * Bypass security because images in the API are secured with JWT, which 
 * has to be added into request headers, which prevents displaying them
 * in `<img src="path" />`
 * 
 * TODO: find secure way to display images
 */
@Pipe({
  name: 'safeUrl',
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string | SafeResourceUrl): any {
    if (typeof value !== 'string') {
      return value;
    }

    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}
