<div fxLayout="row" fxLayoutAlign="start center">
  <div fxFlex>
    <vpfa-label [text]="label"></vpfa-label>
  </div>
  <div class="value">
    <ng-container *ngIf="!notAvailable">
      {{ value }}
    </ng-container>
    <vpfa-price-with-icon *ngIf="notAvailable" [color]="'orange'"></vpfa-price-with-icon>
  </div>
</div>
