import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'vpfa-checkbox-button',
  templateUrl: './checkbox-button.component.html',
  styleUrls: ['./checkbox-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxButtonComponent {
  @Input() id: string;
  @Input() checked: boolean;
  @Input() contentTemplate: TemplateRef<any>;
  @Input() contentTemplateContext: any;

  @Output() onChecked = new EventEmitter<string>();
  @Output() onUnchecked = new EventEmitter<string>();

  onClick() {
    this.checked = !this.checked;
    if (this.checked === true) {
      this.onChecked.emit(this.id);
    } else {
      this.onUnchecked.emit(this.id);
    }
  }
}
