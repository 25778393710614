export class AfterCustomerCreatedActionSuccessHook<ContextType, Context = { context: ContextType }> {
  public STOCK__ADD_CUSTOMER_AS_PREVIOUS_OWNER?;
  public STOCK__ADD_CUSTOMER_AS_OWNER?;
  public VALUATION__ADD_CUSTOMER_AS_OWNER?;
  public STOCK__ADD_CUSTOMER_AS_PROSPECT_CUSTOMER?;

  constructor(hook: {
    STOCK__ADD_CUSTOMER_AS_PREVIOUS_OWNER?: Context;
    STOCK__ADD_CUSTOMER_AS_OWNER?: Context;
    VALUATION__ADD_CUSTOMER_AS_OWNER?: Context;
    STOCK__ADD_CUSTOMER_AS_PROSPECT_CUSTOMER?: Context;
  }) {
    for (const k of Object.keys(hook)) {
      if (hook[k]) {
        this[k] = hook[k];
        return;
      }
    }
  }
}
