<p class="warring" *ngIf="mileageDetails?.isMileageOutOfSequence">
  {{ 'provenanceCheck.mileage.outOfSequence' | translate }}
</p>

<div *ngIf="mileageDetails?.mileageItems?.length; else noData">
  <nz-table
    #table
    [nzData]="mileageDetails?.mileageItems"
    [nzShowPagination]="false"
    [nzFrontPagination]="false"
    nzSize="small"
    class="dark-table"
  >
    <thead>
      <tr>
        <th>{{ 'provenanceCheck.mileage.source' | translate }}</th>
        <th>{{ 'provenanceCheck.mileage.date' | translate }}</th>
        <th>{{ 'provenanceCheck.mileage.mileage' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of mileageDetails?.mileageItems">
        <td>{{ data.sourceOfMileage }}</td>
        <td>{{ data.dateOfInformation | dateLocale: 'shortDate' }}</td>
        <td>{{ data.mileage | numberLocale: '1.0-0' }}</td>
      </tr>
    </tbody>
  </nz-table>
</div>

<ng-template #noData>
  <vpfa-empty></vpfa-empty>
</ng-template>
