<ng-container *ngIf="equipmentData?.length > 0; else elseTemplate">
  <div *ngFor="let equipment of equipmentData">
    <div class="equipment-item gap-14px d-flex flex-row justify-content-between align-items-start">
      <vpfa-web-equipment-list-item
        [identificationEquipment]="equipment"
        [showEquipmentShortDescription]="showEquipmentShortDescription"
      ></vpfa-web-equipment-list-item>
      <span>
        {{ equipment.priceBrutto | currencyLocale }}
      </span>
    </div>
    <nz-divider></nz-divider>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <vpfa-empty></vpfa-empty>
</ng-template>
