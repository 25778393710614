import { Injectable } from '@angular/core';
import { ProfileFacade } from '@vpfa/profile/data';
import { map, filter } from 'rxjs/operators';
import { Observable, combineLatest } from 'rxjs';
import { DictionariesFacade } from '@vpfa/dealer/dictionaries';
import { CasesFacade } from '@vpfa/dealer/case/data';
import { BranchListItemDto } from '@vpfa/rest-api/admin';
import { isNil } from 'lodash';
import { LocaleFacade } from '@vpfa/locale';

@Injectable()
export class CaseUrlService {
  constructor(
    private profileFacade: ProfileFacade,
    private dictionariesFacade: DictionariesFacade,
    private caseFacade: CasesFacade,
    private localeFacade: LocaleFacade
  ) {}

  private caseUrlData$ = combineLatest([
    this.profileFacade.userBusinessDetails$,
    this.dictionariesFacade.branchList$,
    this.caseFacade.activeCaseData$,
    this.localeFacade.country$.pipe(
      map(country => country?.url),
      filter(countryUrl => !isNil(countryUrl)),
      map(this.prepareCountryUrl)
    ),
  ]).pipe(
    map(([business, branches, caseData, countryUrl]) => ({
      business,
      branch: branches.find((b: BranchListItemDto) => b.branchId === caseData.branchId),
      caseId: caseData.id,
      countryUrl,
    })),
    filter(
      ({ business, branch, caseId, countryUrl }) =>
        !isNil(business) && !isNil(branch) && !isNil(caseId) && !isNil(countryUrl)
    )
  );

  activeCasePublicOfferUrl$: Observable<string> = this.caseUrlData$.pipe(
    map(
      ({ business, branch, caseId, countryUrl }) =>
        `${countryUrl}/public/${business.urlPart}/${branch.branchUrlPart}/offer/${caseId}`
    )
  );

  activeCaseResellerOfferUrl$: Observable<string> = this.caseUrlData$.pipe(
    map(
      ({ business, branch, caseId, countryUrl }) =>
        `${countryUrl}/resellers/${business.urlPart}/${branch.branchUrlPart}/${caseId}`
    )
  );

  private prepareCountryUrl(countryUrl) {
    return countryUrl[countryUrl.length - 1] !== '/' ? countryUrl : countryUrl.slice(0, -1);
  }
}
