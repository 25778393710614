import { Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { isNil } from 'lodash';

export const vinValidatorKey = 'vin';

export const vinValidator: ValidatorFn = (control: AbstractControl) => {
  const validateErrors = Validators.pattern(/^[A-HJ-NPR-Z\d]{1,17}$/i)(control);
  if (isNil(validateErrors)) {
    return null;
  }
  return {
    [vinValidatorKey]: true
  };
};
