export interface SorcDictionaryDto {
  market: string;
  code: string;
  value: string;
}

export enum SorcDictionariesKeys {
  transmissiontype = 'transmissiontype',
  fueltype = 'fueltype',
  bodytype = 'bodytype',
}
