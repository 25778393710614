<div fxLayout="row wrap" fxLayoutAlign="center space-between">
  <div class="box" fxLayout="row wrap" fxFlex.lt-mv="50">
    <!-- #region Vehicle Information -->
    <div class="section" fxLayout="row wrap" fxLayoutGap.lt-mv="10px" id="sectionVehicleInformation">
      <vpfa-expanding-header
        [text]="'vehicleFullTechnicalData.vehicleInformation.label' | translate"
      ></vpfa-expanding-header>
      <vpfa-vehicle-list-full-technical-data-cell
        [label]="'vehicleFullTechnicalData.vehicleInformation.listPrice' | translate"
        [value]="vehicleData?.priceBrutto | currencyLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="technicalDataFeatureConfig?.showNovaTax"
        [label]="'vehicleFullTechnicalData.vehicleInformation.novaTax' | translate"
        [value]="firstPrice?.novaTax | percentValue"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="technicalDataFeatureConfig?.showNovaTax"
        [label]="'vehicleFullTechnicalData.vehicleInformation.novaTaxGas' | translate"
        [value]="firstPrice?.novaTaxGas | percentValue"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        [label]="'vehicleFullTechnicalData.vehicleInformation.nationalCode' | translate"
        [value]="vehicleData?.natcode"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        [label]="'vehicleFullTechnicalData.vehicleInformation.countryMarket' | translate"
        [value]="vehicleData?.deliveryCountry"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        [label]="'vehicleFullTechnicalData.vehicleInformation.productionPeriod' | translate"
        [value]="
          (vehicleData?.technicalData?.prodbegin | dateLocale: 'shortDate':null:null:true) +
          ' - ' +
          (vehicleData?.technicalData?.prodend
            ? (vehicleData?.technicalData?.prodend | dateLocale: 'shortDate':null:null:true)
            : ('vehicleFullTechnicalData.vehicleInformation.upToNow' | translate))
        "
      ></vpfa-vehicle-list-full-technical-data-cell>
    </div>
    <!-- #endregion -->

    <!-- #region Engine Information -->
    <div class="section" fxLayout="row wrap" fxLayoutGap.lt-mv="10px" id="sectionEngineInformation">
      <vpfa-expanding-header
        [text]="'vehicleFullTechnicalData.engineInformation.label' | translate"
      ></vpfa-expanding-header>
      <vpfa-vehicle-list-full-technical-data-cell
        [label]="
          ('vehicleFullTechnicalData.engineInformation.power' | translate) +
          ([vehicleData?.technicalData?.power?.peakPowerkW, vehicleData?.technicalData?.power?.peakPowerHP] | unitValue)
        "
        [value]="
          [vehicleData?.technicalData?.power?.peakPowerkW, vehicleData?.technicalData?.power?.peakPowerHP] | getTwoValue
        "
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        [label]="'vehicleFullTechnicalData.engineInformation.drivetrain' | translate"
        [value]="vehicleData?.technicalData?.drive?.type?.texts"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        [label]="'vehicleFullTechnicalData.engineInformation.transmissionType' | translate"
        [value]="vehicleData?.technicalData?.transmission?.type?.texts"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        [label]="'vehicleFullTechnicalData.engineInformation.numberGears' | translate"
        [value]="vehicleData?.technicalData?.transmission?.numberGears"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        [label]="'vehicleFullTechnicalData.engineInformation.fuelType' | translate"
        [value]="vehicleData?.technicalData?.propulsion?.fuelType?.texts"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        [label]="'vehicleFullTechnicalData.engineInformation.engineType' | translate"
        [value]="vehicleData?.technicalData?.propulsion?.engineType?.texts"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        [label]="
          ('vehicleFullTechnicalData.engineInformation.engineCapacity' | translate) +
          (vehicleData?.technicalData?.propulsion?.engineCapacity | unitValue)
        "
        [value]="vehicleData?.technicalData?.propulsion?.engineCapacity | engineCapacity"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        [label]="'vehicleFullTechnicalData.engineInformation.numberCylinders' | translate"
        [value]="vehicleData?.technicalData?.propulsion?.numberCylinders"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        [label]="'vehicleFullTechnicalData.engineInformation.cylinderAssembly' | translate"
        [value]="vehicleData?.technicalData?.propulsion?.cylinderArrangement?.texts"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        [label]="
          ('vehicleFullTechnicalData.engineInformation.maxSpeed' | translate) +
          (vehicleTechnicalPerformance?.maxSpeed | unitValue)
        "
        [value]="vehicleTechnicalPerformance?.maxSpeed?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        [label]="
          ('vehicleFullTechnicalData.engineInformation.acceleration100kph' | translate) +
          (vehicleTechnicalPerformance?.acceleration | unitValue)
        "
        [value]="vehicleTechnicalPerformance?.acceleration?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        [label]="'vehicleFullTechnicalData.engineInformation.catalyst' | translate"
        [value]="vehicleData?.technicalData?.propulsion?.catalyst?.texts"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        [label]="'vehicleFullTechnicalData.engineInformation.torque' | translate"
        [value]="vehicleData?.technicalData?.propulsion?.torque?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="
          vehicleData?.technicalData?.propulsion?.hybrid?.electricEnginePowerHP?.value ||
          vehicleData?.technicalData?.propulsion?.hybrid?.electricEnginePowerKW?.value
        "
        [label]="
          ('vehicleFullTechnicalData.engineInformation.electricPeakPower' | translate) +
          ([
            vehicleData?.technicalData?.propulsion?.hybrid?.electricEnginePowerKW,
            vehicleData?.technicalData?.propulsion?.hybrid?.electricEnginePowerHP
          ] | unitValue: true)
        "
        [value]="
          [
            vehicleData?.technicalData?.propulsion?.hybrid?.electricEnginePowerKW,
            vehicleData?.technicalData?.propulsion?.hybrid?.electricEnginePowerHP
          ] | getTwoValue
        "
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="
          vehicleData?.technicalData?.propulsion?.electric?.peakPowerHP?.value ||
          vehicleData?.technicalData?.propulsion?.electric?.peakPowerKW?.value
        "
        [label]="
          ('vehicleFullTechnicalData.engineInformation.electricPower' | translate) +
          ([
            vehicleData?.technicalData?.propulsion?.electric?.peakPowerKW,
            vehicleData?.technicalData?.propulsion?.electric?.peakPowerHP
          ] | unitValue: true)
        "
        [value]="
          [
            vehicleData?.technicalData?.propulsion?.electric?.peakPowerKW,
            vehicleData?.technicalData?.propulsion?.electric?.peakPowerHP
          ] | getTwoValue
        "
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="vehicleData?.technicalData?.propulsion?.evBattery?.powerOutputKWh?.value"
        [label]="
          ('vehicleFullTechnicalData.engineInformation.powerOutputKWh' | translate) +
          (vehicleData?.technicalData?.propulsion?.evBattery?.powerOutputKWh | unitValue)
        "
        [value]="vehicleData?.technicalData?.propulsion?.evBattery?.powerOutputKWh?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="vehicleData?.technicalData?.propulsion?.evBattery?.voltage?.value"
        [label]="
          ('vehicleFullTechnicalData.engineInformation.voltage' | translate) +
          (vehicleData?.technicalData?.propulsion?.evBattery?.voltage | unitValue)
        "
        [value]="vehicleData?.technicalData?.propulsion?.evBattery?.voltage?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="vehicleData?.technicalData?.propulsion?.evBattery?.batteryWarrantyMonths"
        [label]="'vehicleFullTechnicalData.engineInformation.batteryWarrantyMonths' | translate"
        [value]="vehicleData?.technicalData?.propulsion?.evBattery?.batteryWarrantyMonths | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="vehicleData?.technicalData?.propulsion?.evBattery?.weight?.value"
        [label]="
          ('vehicleFullTechnicalData.engineInformation.batteryWeight' | translate) +
          (vehicleData?.technicalData?.propulsion?.evBattery?.weight | unitValue)
        "
        [value]="vehicleData?.technicalData?.propulsion?.evBattery?.weight?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="vehicleData?.technicalData?.propulsion?.evCharging?.timeHighVoltage80?.value"
        [label]="
          ('vehicleFullTechnicalData.engineInformation.timeHighVoltage80' | translate) +
          (vehicleData?.technicalData?.propulsion?.evCharging?.timeHighVoltage80 | unitValue)
        "
        [value]="vehicleData?.technicalData?.propulsion?.evCharging?.timeHighVoltage80?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="vehicleData?.technicalData?.propulsion?.evBattery?.includedInNewPrice"
        [label]="'vehicleFullTechnicalData.engineInformation.includedInNewPrice' | translate"
        [value]="vehicleData?.technicalData?.propulsion?.evBattery?.includedInNewPrice"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="vehicleData?.technicalData?.propulsion?.hybrid?.numberElectricEngines"
        [label]="'vehicleFullTechnicalData.engineInformation.numberElectricEngines' | translate"
        [value]="vehicleData?.technicalData?.propulsion?.hybrid?.numberElectricEngines | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="vehicleData?.technicalData?.propulsion?.evBattery?.number"
        [label]="'vehicleFullTechnicalData.engineInformation.batterNumber' | translate"
        [value]="vehicleData?.technicalData?.propulsion?.evBattery?.number | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="vehicleData?.technicalData?.propulsion?.evBattery?.type?.texts"
        [label]="'vehicleFullTechnicalData.engineInformation.batteryType' | translate"
        [value]="vehicleData?.technicalData?.propulsion?.evBattery?.type?.texts"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="vehicleData?.technicalData?.propulsion?.evBattery?.capacityAh?.value"
        [label]="
          ('vehicleFullTechnicalData.engineInformation.capacityAh' | translate) +
          (vehicleData?.technicalData?.propulsion?.evBattery?.capacityAh | unitValue)
        "
        [value]="vehicleData?.technicalData?.propulsion?.evBattery?.capacityAh?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="vehicleData?.technicalData?.propulsion?.evCharging?.timeStandardVoltage100?.value"
        [label]="
          ('vehicleFullTechnicalData.engineInformation.timeStandardVoltage100' | translate) +
          (vehicleData?.technicalData?.propulsion?.evCharging?.timeStandardVoltage100 | unitValue)
        "
        [value]="vehicleData?.technicalData?.propulsion?.evCharging?.timeStandardVoltage100?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="vehicleData?.technicalData?.propulsion?.evCharging?.chargeCycles"
        [label]="'vehicleFullTechnicalData.engineInformation.chargeCycles' | translate"
        [value]="vehicleData?.technicalData?.propulsion?.evCharging?.chargeCycles | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="
          vehicleData?.technicalData?.propulsion?.hybrid?.systemPowerHP?.value ||
          vehicleData?.technicalData?.propulsion?.hybrid?.systemPowerKW?.value
        "
        [label]="
          ('vehicleFullTechnicalData.engineInformation.hybridPower' | translate) +
          ([
            vehicleData?.technicalData?.propulsion?.hybrid?.systemPowerKW,
            vehicleData?.technicalData?.propulsion?.hybrid?.systemPowerHP
          ] | unitValue)
        "
        [value]="
          [
            vehicleData?.technicalData?.propulsion?.hybrid?.systemPowerKW,
            vehicleData?.technicalData?.propulsion?.hybrid?.systemPowerHP
          ] | getTwoValue
        "
      ></vpfa-vehicle-list-full-technical-data-cell>
    </div>
    <!-- #endregion -->

    <!-- #region Common -->
    <div class="section" fxLayout="row wrap" fxLayoutGap.lt-mv="10px" id="sectionCommon">
      <vpfa-expanding-header [text]="'vehicleFullTechnicalData.common.label' | translate"></vpfa-expanding-header>
      <vpfa-vehicle-list-full-technical-data-cell
        [label]="'vehicleFullTechnicalData.common.bodyType' | translate"
        [value]="vehicleData?.technicalData?.body?.nationalType?.texts"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        [label]="'vehicleFullTechnicalData.common.vehicleType' | translate"
        [value]="vehicleData?.technicalData?.vehType | findVehicleTypeByCode | async"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="!technicalDataFeatureConfig?.hideDoors"
        [label]="'vehicleFullTechnicalData.common.doors' | translate"
        [value]="vehicleData?.technicalData?.body?.doors"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        [label]="'vehicleFullTechnicalData.common.numberSeats' | translate"
        [value]="vehicleData?.technicalData?.body?.numberSeats"
      ></vpfa-vehicle-list-full-technical-data-cell>
    </div>
    <!-- #endregion -->

    <!-- #region Weights -->
    <div
      class="section"
      fxLayout="row wrap"
      fxLayoutGap.lt-mv="10px"
      ngClass.lt-mv="splitted-section"
      id="sectionWeights"
    >
      <vpfa-expanding-header [text]="'vehicleFullTechnicalData.weights.label' | translate"></vpfa-expanding-header>
      <vpfa-vehicle-list-full-technical-data-cell
        [label]="
          ('vehicleFullTechnicalData.weights.grossVehicleWeight' | translate) +
          (vehicleData?.technicalData?.weights?.grossVehicleWeight | unitValue)
        "
        [value]="vehicleData?.technicalData?.weights?.grossVehicleWeight?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="!technicalDataFeatureConfig?.hideMaxLoad"
        [label]="
          ('vehicleFullTechnicalData.weights.payloadMin' | translate) +
          (vehicleData?.technicalData?.weights?.payloadMin | unitValue)
        "
        [value]="vehicleData?.technicalData?.weights?.payloadMin?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="!technicalDataFeatureConfig?.hideTrailerLoadBraked"
        [label]="
          ('vehicleFullTechnicalData.weights.trailerLoadBraked' | translate) +
          (vehicleData?.technicalData?.weights?.trailerLoadBraked | unitValue)
        "
        [value]="vehicleData?.technicalData?.weights?.trailerLoadBraked?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="!technicalDataFeatureConfig?.hideTrailerLoadUnbraked"
        [label]="
          ('vehicleFullTechnicalData.weights.trailerLoadUnbraked' | translate) +
          (vehicleData?.technicalData?.weights?.trailerLoadUnbraked | unitValue)
        "
        [value]="vehicleData?.technicalData?.weights?.trailerLoadUnbraked?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="!technicalDataFeatureConfig?.hideRoofLoad"
        [label]="
          ('vehicleFullTechnicalData.weights.roofLoad' | translate) +
          (vehicleData?.technicalData?.weights?.roofLoad | unitValue)
        "
        [value]="vehicleData?.technicalData?.weights?.roofLoad?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="!technicalDataFeatureConfig?.hideKerbWeight"
        [label]="
          ('vehicleFullTechnicalData.weights.kerbWeightIncDriver' | translate) +
          (vehicleData?.technicalData?.weights?.kerbWeightIncDriver | unitValue)
        "
        [value]="vehicleData?.technicalData?.weights?.kerbWeightIncDriver?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>
    </div>
    <!-- #endregion -->

    <!-- #region Dimensions -->
    <div
      class="section"
      fxLayout="row wrap"
      fxLayoutGap.lt-mv="10px"
      ngClass.lt-mv="splitted-section"
      id="sectionDimensions"
    >
      <vpfa-expanding-header [text]="'vehicleFullTechnicalData.dimensions.label' | translate"></vpfa-expanding-header>

      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="!technicalDataFeatureConfig?.hideWheelbase"
        [label]="
          ('vehicleFullTechnicalData.dimensions.wheelbase' | translate) +
          (vehicleData?.technicalData?.dimensions?.wheelbase | unitValue)
        "
        [value]="vehicleData?.technicalData?.dimensions?.wheelbase?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        [label]="'vehicleFullTechnicalData.dimensions.tyresFront' | translate"
        [value]="vehicleData?.technicalData?.tyres?.summaryFirstAxle"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        [label]="'vehicleFullTechnicalData.dimensions.tyresRear' | translate"
        [value]="vehicleData?.technicalData?.tyres?.summarySecondAxle"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="!technicalDataFeatureConfig?.hideBootCapacity"
        [label]="
          ('vehicleFullTechnicalData.dimensions.bootCapacity' | translate) +
          (vehicleData?.technicalData?.dimensions?.bootCapacity | unitValue)
        "
        [value]="vehicleData?.technicalData?.dimensions?.bootCapacity?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        [label]="
          ('vehicleFullTechnicalData.dimensions.primaryFuelTankCapacity' | translate) +
          (vehicleData?.technicalData?.dimensions?.primaryFuelTankCapacity | unitValue)
        "
        [value]="vehicleData?.technicalData?.dimensions?.primaryFuelTankCapacity?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="!technicalDataFeatureConfig?.hideLength"
        [label]="
          ('vehicleFullTechnicalData.dimensions.length' | translate) +
          (vehicleData?.technicalData?.dimensions?.length | unitValue)
        "
        [value]="vehicleData?.technicalData?.dimensions?.length?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        [label]="
          ('vehicleFullTechnicalData.dimensions.maxLength' | translate) +
          (vehicleData?.technicalData?.body?.maxLength | unitValue)
        "
        [value]="vehicleData?.technicalData?.body?.maxLength?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="!technicalDataFeatureConfig?.hideHeight"
        [label]="
          ('vehicleFullTechnicalData.dimensions.height' | translate) +
          (vehicleData?.technicalData?.dimensions?.height | unitValue)
        "
        [value]="vehicleData?.technicalData?.dimensions?.height?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        [label]="
          ('vehicleFullTechnicalData.dimensions.maxHeight' | translate) +
          (vehicleData?.technicalData?.body?.maxHeight | unitValue)
        "
        [value]="vehicleData?.technicalData?.body?.maxHeight?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="!technicalDataFeatureConfig?.hideWidth"
        [label]="
          ('vehicleFullTechnicalData.dimensions.width' | translate) +
          (vehicleData?.technicalData?.dimensions?.widthExcMirrors | unitValue)
        "
        [value]="vehicleData?.technicalData?.dimensions?.widthExcMirrors?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="technicalDataFeatureConfig?.showMaxWidthForLCV"
        [label]="
          ('vehicleFullTechnicalData.dimensions.maxWidth' | translate) +
          (vehicleData?.technicalData?.body?.maxWidthForLCV | unitValue)
        "
        [value]="vehicleData?.technicalData?.body?.maxWidthForLCV?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="technicalDataFeatureConfig?.showSeatHeightForBikes"
        [label]="
          ('vehicleFullTechnicalData.dimensions.seatHeight' | translate) +
          (vehicleData?.technicalData?.dimensions?.seatHeightForBikes | unitValue)
        "
        [value]="vehicleData?.technicalData?.dimensions?.seatHeightForBikes?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        [label]="
          ('vehicleFullTechnicalData.dimensions.groundClearanceFrom' | translate) +
          (vehicleData?.technicalData?.dimensions?.groundClearanceFrom | unitValue)
        "
        [value]="vehicleData?.technicalData?.dimensions?.groundClearanceFrom?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        [label]="
          ('vehicleFullTechnicalData.dimensions.groundClearanceTo' | translate) +
          (vehicleData?.technicalData?.dimensions?.groundClearanceTo | unitValue)
        "
        [value]="vehicleData?.technicalData?.dimensions?.groundClearanceTo?.value | numberLocale"
      ></vpfa-vehicle-list-full-technical-data-cell>
    </div>
    <!-- #endregion -->

    <!-- #region NEDC Consumption -->
    <div
      class="section"
      fxLayout="row wrap"
      fxLayoutGap.lt-mv="10px"
      ngClass.lt-mv="splitted-section"
      id="sectionFuelConsumption"
    >
      <vpfa-expanding-header
        [text]="'vehicleFullTechnicalData.fuelConsumption.label' | translate"
      ></vpfa-expanding-header>
      <vpfa-vehicle-list-full-technical-data-cell
        [label]="'vehicleFullTechnicalData.emissionClassLabel' | translate"
        [value]="vehicleData?.technicalData?.emissionClass?.texts | lT"
      ></vpfa-vehicle-list-full-technical-data-cell>
    </div>

    <div
      class="section"
      fxLayout="row wrap"
      fxLayoutGap.lt-mv="10px"
      ngClass.lt-mv="splitted-section"
      id="sectionFuelConsumptionNedc"
    >
      <vpfa-expanding-header
        [text]="'vehicleFullTechnicalData.fuelConsumption.nedc.nedcConsumptionAndEmission' | translate"
      ></vpfa-expanding-header>

      <vpfa-vehicle-list-full-technical-data-cell
        [label]="'vehicleFullTechnicalData.cO2EfficiencyClassLabel' | translate"
        [value]="efficiencyClassValueCustom()"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        [label]="'vehicleFullTechnicalData.originalCO2EfficiencyClassLabel' | translate"
        [value]="efficiencyClassValue()"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        [label]="
          ('vehicleFullTechnicalData.fuelConsumption.nedc.urban' | translate) +
          (vehicleData?.technicalData?.consumption?.nedc?.fuelConsumptionUrban | unitValue)
        "
        [value]="vehicleData?.technicalData?.consumption?.nedc?.fuelConsumptionUrban | unitRangeValue"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        [label]="
          ('vehicleFullTechnicalData.fuelConsumption.nedc.extraUrban' | translate) +
          (vehicleData?.technicalData?.consumption?.nedc?.fuelConsumptionExtraUrban | unitValue)
        "
        [value]="vehicleData?.technicalData?.consumption?.nedc?.fuelConsumptionExtraUrban | unitRangeValue"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        [label]="
          ('vehicleFullTechnicalData.fuelConsumption.nedc.combined' | translate) +
          (vehicleData?.technicalData?.consumption?.nedc?.fuelConsumptionOverallCombined | unitValue)
        "
        [value]="vehicleData?.technicalData?.consumption?.nedc?.fuelConsumptionOverallCombined | unitRangeValue"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        [label]="
          ('vehicleFullTechnicalData.fuelConsumption.nedc.cO2Emission' | translate) +
          (vehicleData?.technicalData?.consumption?.nedc?.cO2EmissionCombined | unitValue)
        "
        [value]="vehicleData?.technicalData?.consumption?.nedc?.cO2EmissionCombined | unitRangeValue"
      ></vpfa-vehicle-list-full-technical-data-cell>
    </div>
    <!-- #endregion -->

    <!-- #region NEDC - Electric Consumption -->
    <div
      class="section"
      fxLayout="row wrap"
      fxLayoutGap.lt-mv="10px"
      ngClass.lt-mv="splitted-section"
      id="sectionFuelConsumptionEvNedc"
    >
      <vpfa-expanding-header
        [text]="'vehicleFullTechnicalData.fuelConsumption.eVRangeAndConsumptionNedc.label' | translate"
      ></vpfa-expanding-header>
      <vpfa-vehicle-list-full-technical-data-cell
        [label]="
          ('vehicleFullTechnicalData.fuelConsumption.eVRangeAndConsumptionNedc.electricConsumption' | translate) +
          (vehicleData?.technicalData?.consumption?.nedc?.electricPowerConsumption | unitValue)
        "
        [value]="vehicleData?.technicalData?.consumption?.nedc?.electricPowerConsumption | unitRangeValue"
      ></vpfa-vehicle-list-full-technical-data-cell>
    </div>
    <!-- #endregion -->

    <!-- #region WLTP Consumption -->
    <div
      class="section"
      fxLayout="row wrap"
      fxLayoutGap.lt-mv="10px"
      ngClass.lt-mv="splitted-section"
      id="sectionFuelConsumptionWltp"
      [attr.data-vehicle-type]="vehicleEngineType"
    >
      <vpfa-expanding-header
        [text]="'vehicleFullTechnicalData.fuelConsumption.wltp.wltpConsumptionAndEmission' | translate"
      ></vpfa-expanding-header>

      <!----------------------------------------------------------------------->
      <!----------------------- Combustion + Hybrid --------------------------->
      <!---------------------------- Basic Info ------------------------------->
      <!----------------------------------------------------------------------->
      <ng-container
        *ngIf="vehicleEngineType === VehicleEngineType.Thermal || vehicleEngineType === VehicleEngineType.PluginHybrid"
      >
        <ng-container *ngTemplateOutlet="efficiencyClass"></ng-container>

        <ng-container *ngIf="vehicleEngineType === VehicleEngineType.PluginHybrid">
          <vpfa-vehicle-list-full-technical-data-cell
            [label]="'vehicleFullTechnicalData.phevCo2LabelCalculated' | translate"
            [value]="vehicleData?.technicalData?.cO2WLTPEfficiencyClassEBCustom | i18nTexts | lT"
          ></vpfa-vehicle-list-full-technical-data-cell>

          <vpfa-vehicle-list-full-technical-data-cell
            [label]="'vehicleFullTechnicalData.phevOriginalCO2EfficiencyDepleted' | translate"
            [value]="vehicleData?.technicalData?.cO2WLTPEfficiencyClassEB | i18nTexts | lT"
          ></vpfa-vehicle-list-full-technical-data-cell>
        </ng-container>

        <vpfa-vehicle-list-full-technical-data-cell
          [label]="
            ('vehicleFullTechnicalData.fuelConsumption.wltp.lowSpeed' | translate) +
            (vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionLowPhase | unitValue)
          "
          [value]="vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionLowPhase | unitRangeValue"
        ></vpfa-vehicle-list-full-technical-data-cell>
        <vpfa-vehicle-list-full-technical-data-cell
          [label]="
            ('vehicleFullTechnicalData.fuelConsumption.wltp.mediumSpeed' | translate) +
            (vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionMediumPhase | unitValue)
          "
          [value]="vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionMediumPhase | unitRangeValue"
        ></vpfa-vehicle-list-full-technical-data-cell>
        <vpfa-vehicle-list-full-technical-data-cell
          [label]="
            ('vehicleFullTechnicalData.fuelConsumption.wltp.highSpeed' | translate) +
            (vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionHighPhase | unitValue)
          "
          [value]="vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionHighPhase | unitRangeValue"
        ></vpfa-vehicle-list-full-technical-data-cell>
        <vpfa-vehicle-list-full-technical-data-cell
          [label]="
            ('vehicleFullTechnicalData.fuelConsumption.wltp.extraHighSpeed' | translate) +
            (vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionExtraHighPhase | unitValue)
          "
          [value]="vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionExtraHighPhase | unitRangeValue"
        ></vpfa-vehicle-list-full-technical-data-cell>
        <vpfa-vehicle-list-full-technical-data-cell
          [label]="
            ('vehicleFullTechnicalData.fuelConsumption.wltp.combined' | translate) +
            (vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionOverallCombined | unitValue)
          "
          [value]="vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionOverallCombined | unitRangeValue"
        ></vpfa-vehicle-list-full-technical-data-cell>
        <vpfa-vehicle-list-full-technical-data-cell
          [label]="
            ('vehicleFullTechnicalData.fuelConsumption.wltp.cO2Emission' | translate) +
            (vehicleData?.technicalData?.consumption?.wltp?.cO2EmissionCombined | unitValue)
          "
          [value]="vehicleData?.technicalData?.consumption?.wltp?.cO2EmissionCombined | unitRangeValue"
        ></vpfa-vehicle-list-full-technical-data-cell>

        <ng-container *ngTemplateOutlet="electricAndHybridAdditionalInfo"></ng-container>
      </ng-container>

      <!-------------------------------------------->
      <!-------- Second section for Hybrid --------->
      <!-------------------------------------------->
      <ng-container *ngIf="vehicleEngineType === VehicleEngineType.PluginHybrid">
        <vpfa-expanding-header
          [text]="'vehicleFullTechnicalData.fuelConsumption.wltp.hybridVehicles' | translate"
        ></vpfa-expanding-header>

        <div class="columns-container">
          <!---------------------------->
          <!-- Hybrid - Pure Electric -->
          <!---------------------------->
          <div class="column">
            <h2>{{ 'vehicleFullTechnicalData.fuelConsumption.wltp.powerConsumptionPureElectric' | translate }}</h2>
            <vpfa-vehicle-list-full-technical-data-cell
              [label]="
                ('vehicleFullTechnicalData.fuelConsumption.wltp.lowSpeed' | translate) +
                (vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionLowPhasePE | unitValue)
              "
              [value]="vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionLowPhasePE | unitRangeValue"
            ></vpfa-vehicle-list-full-technical-data-cell>

            <vpfa-vehicle-list-full-technical-data-cell
              [label]="
                ('vehicleFullTechnicalData.fuelConsumption.wltp.mediumSpeed' | translate) +
                (vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionMediumPhasePE | unitValue)
              "
              [value]="vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionMediumPhasePE | unitRangeValue"
            ></vpfa-vehicle-list-full-technical-data-cell>

            <vpfa-vehicle-list-full-technical-data-cell
              [label]="
                ('vehicleFullTechnicalData.fuelConsumption.wltp.highSpeed' | translate) +
                (vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionHighPhasePE | unitValue)
              "
              [value]="vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionHighPhasePE | unitRangeValue"
            ></vpfa-vehicle-list-full-technical-data-cell>

            <vpfa-vehicle-list-full-technical-data-cell
              [label]="
                ('vehicleFullTechnicalData.fuelConsumption.wltp.extraHighSpeed' | translate) +
                (vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionExtraHighPhasePE | unitValue)
              "
              [value]="vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionExtraHighPhasePE | unitRangeValue"
            ></vpfa-vehicle-list-full-technical-data-cell>

            <vpfa-vehicle-list-full-technical-data-cell
              [label]="
                ('vehicleFullTechnicalData.fuelConsumption.wltp.combined' | translate) +
                (vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionCombinedPhasePE | unitValue)
              "
              [value]="vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionCombinedPhasePE | unitRangeValue"
            ></vpfa-vehicle-list-full-technical-data-cell>
          </div>
          <!-------------------------------->
          <!-- Hybrid - Discarded Battery -->
          <!-------------------------------->
          <div class="column">
            <h2>{{ 'vehicleFullTechnicalData.fuelConsumption.wltp.fuelConsumptionBatteryDischarged' | translate }}</h2>
            <vpfa-vehicle-list-full-technical-data-cell
              [label]="
                ('vehicleFullTechnicalData.fuelConsumption.wltp.lowSpeed' | translate) +
                (vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionLowPhaseEB | unitValue)
              "
              [value]="vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionLowPhaseEB | unitRangeValue"
            ></vpfa-vehicle-list-full-technical-data-cell>

            <vpfa-vehicle-list-full-technical-data-cell
              [label]="
                ('vehicleFullTechnicalData.fuelConsumption.wltp.mediumSpeed' | translate) +
                (vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionMediumPhaseEB | unitValue)
              "
              [value]="vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionMediumPhaseEB | unitRangeValue"
            ></vpfa-vehicle-list-full-technical-data-cell>

            <vpfa-vehicle-list-full-technical-data-cell
              [label]="
                ('vehicleFullTechnicalData.fuelConsumption.wltp.highSpeed' | translate) +
                (vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionHighPhaseEB | unitValue)
              "
              [value]="vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionHighPhaseEB | unitRangeValue"
            ></vpfa-vehicle-list-full-technical-data-cell>

            <vpfa-vehicle-list-full-technical-data-cell
              [label]="
                ('vehicleFullTechnicalData.fuelConsumption.wltp.extraHighSpeed' | translate) +
                (vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionExtraHighPhaseEB | unitValue)
              "
              [value]="vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionExtraHighPhaseEB | unitRangeValue"
            ></vpfa-vehicle-list-full-technical-data-cell>

            <vpfa-vehicle-list-full-technical-data-cell
              [label]="
                ('vehicleFullTechnicalData.fuelConsumption.wltp.combined' | translate) +
                (vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionCombinedPhaseEB | unitValue)
              "
              [value]="vehicleData?.technicalData?.consumption?.wltp?.fuelConsumptionCombinedPhaseEB | unitRangeValue"
            ></vpfa-vehicle-list-full-technical-data-cell>

            <vpfa-vehicle-list-full-technical-data-cell
              [label]="
                ('vehicleFullTechnicalData.fuelConsumption.wltp.cO2Emission' | translate) +
                (vehicleData?.technicalData?.consumption?.wltp?.cO2EmissionCombinedEB | unitValue)
              "
              [value]="vehicleData?.technicalData?.consumption?.wltp?.cO2EmissionCombinedEB | unitRangeValue"
            ></vpfa-vehicle-list-full-technical-data-cell>
          </div>
        </div>
      </ng-container>

      <!--------------------------------------------------------------------->
      <!----------------------------- Pure Gas ------------------------------>
      <!---------------------------- Basic Info ----------------------------->
      <!--------------------------------------------------------------------->
      <ng-container *ngIf="vehicleEngineType === VehicleEngineType.Gas">
        <ng-container *ngTemplateOutlet="efficiencyClass"></ng-container>

        <vpfa-vehicle-list-full-technical-data-cell
          [label]="
            ('vehicleFullTechnicalData.fuelConsumption.wltp.lowSpeed' | translate) +
            (technicalDataConsumption?.wltp?.gasConsumptionLowPhase | unitValue)
          "
          [value]="technicalDataConsumption?.wltp?.gasConsumptionLowPhase | unitRangeValue"
        ></vpfa-vehicle-list-full-technical-data-cell>

        <vpfa-vehicle-list-full-technical-data-cell
          [label]="
            ('vehicleFullTechnicalData.fuelConsumption.wltp.mediumSpeed' | translate) +
            (technicalDataConsumption?.wltp?.gasConsumptionMediumPhase | unitValue)
          "
          [value]="technicalDataConsumption?.wltp?.gasConsumptionMediumPhase | unitRangeValue"
        ></vpfa-vehicle-list-full-technical-data-cell>

        <vpfa-vehicle-list-full-technical-data-cell
          [label]="
            ('vehicleFullTechnicalData.fuelConsumption.wltp.highSpeed' | translate) +
            (technicalDataConsumption?.wltp?.gasConsumptionHighPhase | unitValue)
          "
          [value]="technicalDataConsumption?.wltp?.gasConsumptionHighPhase | unitRangeValue"
        ></vpfa-vehicle-list-full-technical-data-cell>

        <vpfa-vehicle-list-full-technical-data-cell
          [label]="
            ('vehicleFullTechnicalData.fuelConsumption.wltp.extraHighSpeed' | translate) +
            (technicalDataConsumption?.wltp?.gasConsumptionExtraHighPhase | unitValue)
          "
          [value]="technicalDataConsumption?.wltp?.gasConsumptionExtraHighPhase | unitRangeValue"
        ></vpfa-vehicle-list-full-technical-data-cell>

        <vpfa-vehicle-list-full-technical-data-cell
          [label]="
            ('vehicleFullTechnicalData.fuelConsumption.wltp.combined' | translate) +
            (technicalDataConsumption?.wltp?.gasConsumptionCombinedPhase | unitValue)
          "
          [value]="technicalDataConsumption?.wltp?.gasConsumptionCombinedPhase | unitRangeValue"
        ></vpfa-vehicle-list-full-technical-data-cell>

        <vpfa-vehicle-list-full-technical-data-cell
          [label]="
            ('vehicleFullTechnicalData.fuelConsumption.wltp.cO2Emission' | translate) +
            (technicalDataConsumption?.wltp?.gasCO2EmissionCombined | unitValue)
          "
          [value]="technicalDataConsumption?.wltp?.gasCO2EmissionCombined | unitRangeValue"
        ></vpfa-vehicle-list-full-technical-data-cell>
      </ng-container>

      <!--------------------------------------------------------------------->
      <!-------------------------- Pure Electric ---------------------------->
      <!---------------------------- Basic Info ----------------------------->
      <!--------------------------------------------------------------------->
      <ng-container *ngIf="vehicleEngineType === VehicleEngineType.Electric">
        <ng-container *ngTemplateOutlet="efficiencyClass"></ng-container>

        <vpfa-vehicle-list-full-technical-data-cell
          [label]="
            ('vehicleFullTechnicalData.fuelConsumption.wltp.lowSpeed' | translate) +
            (technicalDataConsumption?.wltp?.fuelConsumptionLowPhasePE | unitValue)
          "
          [value]="technicalDataConsumption?.wltp?.fuelConsumptionLowPhasePE | unitRangeValue"
        ></vpfa-vehicle-list-full-technical-data-cell>

        <vpfa-vehicle-list-full-technical-data-cell
          [label]="
            ('vehicleFullTechnicalData.fuelConsumption.wltp.mediumSpeed' | translate) +
            (technicalDataConsumption?.wltp?.fuelConsumptionMediumPhasePE | unitValue)
          "
          [value]="technicalDataConsumption?.wltp?.fuelConsumptionMediumPhasePE | unitRangeValue"
        ></vpfa-vehicle-list-full-technical-data-cell>

        <vpfa-vehicle-list-full-technical-data-cell
          [label]="
            ('vehicleFullTechnicalData.fuelConsumption.wltp.highSpeed' | translate) +
            (technicalDataConsumption?.wltp?.fuelConsumptionHighPhasePE | unitValue)
          "
          [value]="technicalDataConsumption?.wltp?.fuelConsumptionHighPhasePE | unitRangeValue"
        ></vpfa-vehicle-list-full-technical-data-cell>

        <vpfa-vehicle-list-full-technical-data-cell
          [label]="
            ('vehicleFullTechnicalData.fuelConsumption.wltp.extraHighSpeed' | translate) +
            (technicalDataConsumption?.wltp?.fuelConsumptionExtraHighPhasePE | unitValue)
          "
          [value]="technicalDataConsumption?.wltp?.fuelConsumptionExtraHighPhasePE | unitRangeValue"
        ></vpfa-vehicle-list-full-technical-data-cell>

        <vpfa-vehicle-list-full-technical-data-cell
          [label]="
            ('vehicleFullTechnicalData.fuelConsumption.wltp.combined' | translate) +
            (technicalDataConsumption?.wltp?.fuelConsumptionCombinedPhasePE | unitValue)
          "
          [value]="technicalDataConsumption?.wltp?.fuelConsumptionCombinedPhasePE | unitRangeValue"
        ></vpfa-vehicle-list-full-technical-data-cell>

        <vpfa-vehicle-list-full-technical-data-cell
          [label]="
            ('vehicleFullTechnicalData.fuelConsumption.wltp.cO2Emission' | translate) +
            (technicalDataConsumption?.wltp?.cO2EmissionCombined | unitValue)
          "
          [value]="technicalDataConsumption?.wltp?.cO2EmissionCombined | unitRangeValue"
        ></vpfa-vehicle-list-full-technical-data-cell>

        <ng-container *ngTemplateOutlet="electricAndHybridAdditionalInfo"></ng-container>
      </ng-container>
    </div>

    <!------------------------------------------------->
    <!----------- WLTP Efficiency Class  -------------->
    <!------------- Re-usable Template ---------------->
    <!------------------------------------------------->
    <ng-template #efficiencyClass>
      <vpfa-vehicle-list-full-technical-data-cell
        [label]="'vehicleFullTechnicalData.co2LabelCalculated' | translate"
        [value]="vehicleData?.technicalData?.cO2WLTPEfficiencyClassCustom | i18nTexts | lT"
      ></vpfa-vehicle-list-full-technical-data-cell>

      <vpfa-vehicle-list-full-technical-data-cell
        [label]="'vehicleFullTechnicalData.originalCO2EfficiencyClassLabel' | translate"
        [value]="vehicleData?.technicalData?.cO2WLTPEfficiencyClass | i18nTexts | lT"
      ></vpfa-vehicle-list-full-technical-data-cell>
    </ng-template>

    <!-------------------------------------------->
    <!----------- Electric + Hybrid -------------->
    <!------------ Additional Info --------------->
    <!----------- Re-usable Template ------------->
    <!-------------------------------------------->
    <ng-template #electricAndHybridAdditionalInfo>
      <ng-container
        *ngIf="vehicleEngineType === VehicleEngineType.Electric || vehicleEngineType === VehicleEngineType.PluginHybrid"
      >
        <vpfa-vehicle-list-full-technical-data-cell
          [label]="
            ('vehicleFullTechnicalData.fuelConsumption.eVRangeAndConsumption.combinedRange' | translate) +
            (vehicleData?.technicalData?.consumption?.wltp?.drivingRange | unitValue)
          "
          [value]="vehicleData?.technicalData?.consumption?.wltp?.drivingRange?.value"
        ></vpfa-vehicle-list-full-technical-data-cell>
        <vpfa-vehicle-list-full-technical-data-cell
          [label]="
            ('vehicleFullTechnicalData.fuelConsumption.eVRangeAndConsumption.cityRange' | translate) +
            (vehicleData?.technicalData?.consumption?.wltp?.drivingRangeCity | unitValue)
          "
          [value]="vehicleData?.technicalData?.consumption?.wltp?.drivingRangeCity?.value"
        ></vpfa-vehicle-list-full-technical-data-cell>
        <vpfa-vehicle-list-full-technical-data-cell
          [label]="
            ('vehicleFullTechnicalData.fuelConsumption.eVRangeAndConsumption.electricConsumption' | translate) +
            (vehicleData?.technicalData?.consumption?.wltp?.electricPowerConsumption | unitValue)
          "
          [value]="vehicleData?.technicalData?.consumption?.wltp?.electricPowerConsumption | unitRangeValue"
        ></vpfa-vehicle-list-full-technical-data-cell>
      </ng-container>
    </ng-template>
    <!-- #endregion -->

    <div
      *ngIf="!technicalDataFeatureConfig?.hideInsuranceInformationSection"
      class="section"
      fxLayout="row wrap"
      fxLayoutGap.lt-mv="10px"
      ngClass.lt-mv="splitted-section"
      id="sectionInsuranceData"
    >
      <vpfa-expanding-header
        [text]="'vehicleFullTechnicalData.insuranceData.label' | translate"
      ></vpfa-expanding-header>
      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="technicalDataFeatureConfig?.showInsuranceGroup"
        [label]="'vehicleFullTechnicalData.insuranceData.insuranceGroup' | translate"
        [value]="vehicleData?.technicalData?.insurance?.classification"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="!technicalDataFeatureConfig?.hideLiabilityInsuranceClass"
        [label]="'vehicleFullTechnicalData.insuranceData.liabilityInsuranceClass' | translate"
        [value]="vehicleData?.technicalData?.insurance?.liabilityInsuranceClass"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="!technicalDataFeatureConfig?.hidePartialCover"
        [label]="'vehicleFullTechnicalData.insuranceData.partialCoverInsuranceClass' | translate"
        [value]="vehicleData?.technicalData?.insurance?.partialCoverInsuranceClass"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="!technicalDataFeatureConfig?.hideComprehensiveCover"
        [label]="'vehicleFullTechnicalData.insuranceData.fullComprehensiveClass' | translate"
        [value]="vehicleData?.technicalData?.insurance?.fullComprehensiveClass"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="!technicalDataFeatureConfig?.hideRentalCarGroup"
        [label]="'vehicleFullTechnicalData.insuranceData.rentalCarClass' | translate"
        [value]="vehicleData?.technicalData?.insurance?.rentalCarClass"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="!technicalDataFeatureConfig?.hideNonRentalMaintenanceCosts"
        [label]="
          ('vehicleFullTechnicalData.insuranceData.holdingCosts' | translate) +
          (vehicleData?.technicalData?.insurance?.holdingCosts | unitValue)
        "
        [value]="vehicleData?.technicalData?.insurance?.holdingCosts?.value"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="!technicalDataFeatureConfig?.hideRentalMaintenanceCosts"
        [label]="
          ('vehicleFullTechnicalData.insuranceData.holdingCostsRentalCars' | translate) +
          (vehicleData?.technicalData?.insurance?.holdingCostsRentalCars | unitValue)
        "
        [value]="vehicleData?.technicalData?.insurance?.holdingCostsRentalCars?.value"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        *ngIf="!technicalDataFeatureConfig?.hidePersonalCosts"
        [label]="
          ('vehicleFullTechnicalData.insuranceData.savedCostsRentalCars' | translate) +
          (vehicleData?.technicalData?.insurance?.savedCostsRentalCars | unitValue)
        "
        [value]="vehicleData?.technicalData?.insurance?.savedCostsRentalCars?.value"
      ></vpfa-vehicle-list-full-technical-data-cell>
    </div>
    <div
      *ngIf="!technicalDataFeatureConfig?.hideCompensationForLossOfUse"
      class="section"
      fxLayout="row wrap"
      fxLayoutGap.lt-mv="10px"
      ngClass.lt-mv="splitted-section"
      id="sectionRepairTimeCompensation"
    >
      <vpfa-expanding-header
        [text]="'vehicleFullTechnicalData.repairTimeCompensation.label' | translate"
      ></vpfa-expanding-header>
      <vpfa-vehicle-list-full-technical-data-cell
        [label]="
          ('vehicleFullTechnicalData.repairTimeCompensation.repairTimeCompensation' | translate) +
          (vehicleData?.technicalData?.insurance?.repairTimeCompensation | unitValue)
        "
        [value]="vehicleData?.technicalData?.insurance?.repairTimeCompensation?.value | numberLocale: '1.2-2'"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        [label]="
          ('vehicleFullTechnicalData.repairTimeCompensation.repairTimeCompensationGt5' | translate) +
          (vehicleData?.technicalData?.insurance?.repairTimeCompensationGt5 | unitValue)
        "
        [value]="vehicleData?.technicalData?.insurance?.repairTimeCompensationGt5?.value | numberLocale: '1.2-2'"
      ></vpfa-vehicle-list-full-technical-data-cell>
      <vpfa-vehicle-list-full-technical-data-cell
        [label]="
          ('vehicleFullTechnicalData.repairTimeCompensation.repairTimeCompensationGt10' | translate) +
          (vehicleData?.technicalData?.insurance?.repairTimeCompensationGt10 | unitValue)
        "
        [value]="vehicleData?.technicalData?.insurance?.repairTimeCompensationGt10?.value | numberLocale: '1.2-2'"
      ></vpfa-vehicle-list-full-technical-data-cell>
    </div>
  </div>
</div>
