<div class="d-flex flex-row inline-container" [ngClass]="{ required: required }" *ngIf="formGroup != null">
  <div class="label" [ngClass]="{ 'blue-bg': showBlueBackground }">
    {{ label }}<span *ngIf="showCheckedSymbol" class="check-icon zmdi zmdi-check"></span>
  </div>
  <vpfa-inline-loader [isLoading]="isLoading">
    <div class="value" *ngIf="!isInEditMode && showValue">{{ value | lT }}</div>

    <div class="content" (click)="onToggleEditMode()" *ngIf="!isInEditMode && !value">
      <ng-content></ng-content>
    </div>

    <vpfa-text-field
      [errorMessages]="errorMessages"
      heightCompensationPx="-9"
      *ngIf="isInEditMode"
      fieldWidth="100%"
      [parentFormGroup]="formGroup"
      fcName="control"
      [setFocusAfterInit]="true"
      [preventDefaultForEnterKey]="true"
      [uppercase]="uppercase"
      (fieldBlur)="onBlur()"
      (submitValue)="onSubmitEdit()"
      (keydown.esc)="onKeyDownEscape()"
    ></vpfa-text-field>

    <i
      *ngIf="isInEditMode === false && !required && !isLoading"
      class="edit-icon"
      (click)="onToggleEditMode()"
      [nzTheme]="'twotone'"
      nz-icon
      nzType="edit"
    ></i>
    <i
      *ngIf="isInEditMode === false && required"
      class="suffix-info"
      class="warning-icon"
      [nzTheme]="'fill'"
      nz-icon
      nzType="info-circle"
    >
    </i>
  </vpfa-inline-loader>
</div>
