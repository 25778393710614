import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { fromDictionariesActions } from './dictionaries.actions';
import { DictionariesPartialState } from './dictionaries.reducer';
import { dictionariesQuery } from './dictionaries.selectors';

@Injectable()
export class DictionariesFacade {
  branchList$ = this.store.pipe(select(dictionariesQuery.getBranchList));
  branchListSelectOptions$ = this.store.pipe(select(dictionariesQuery.getBranchListSelectOptions));
  branchLoaded$ = this.store.pipe(select(dictionariesQuery.getBranchLoaded));
  vehicleGroupList$ = this.store.pipe(select(dictionariesQuery.getVehicleGroupList));
  vehicleGroupLoaded$ = this.store.pipe(select(dictionariesQuery.getVehicleGroupLoaded));
  vehicleTypeList$ = this.store.pipe(select(dictionariesQuery.getVehicleTypeList));
  vehicleTypeListLoaded$ = this.store.pipe(select(dictionariesQuery.getVehicleTypeListLoaded));
  vehicleCombinedTypeList$ = this.store.pipe(select(dictionariesQuery.getVehicleCombinedTypeList));
  paintColourList$ = this.store.pipe(select(dictionariesQuery.getPaintColourList));
  paintColourListLoaded$ = this.store.pipe(select(dictionariesQuery.getPaintColourListLoaded));
  paintColourListOptions$ = this.store.pipe(select(dictionariesQuery.getPaintColourListOptions));
  emissionClassListOptions$ = this.store.pipe(select(dictionariesQuery.getEmissionClassListOptions));
  co2EfficiencyClassListOptions$ = this.store.pipe(select(dictionariesQuery.getCO2EfficiencyClassListOptions));

  paintColour$(id: number) {
    return this.store.pipe(select(dictionariesQuery.getPaintColour, { id }));
  }

  constructor(private store: Store<DictionariesPartialState>) {}

  loadBranchList() {
    this.store.dispatch(new fromDictionariesActions.LoadBranchList());
  }

  loadVehicleGroupList() {
    this.store.dispatch(new fromDictionariesActions.LoadVehicleGroupList());
  }

  loadAdminPaintColourList(countryId: string) {
    this.store.dispatch(new fromDictionariesActions.LoadAdminPaintColourList(countryId));
  }

  loadPaintColourList() {
    this.store.dispatch(new fromDictionariesActions.LoadPaintColourList());
  }
}
