import { vehicleEquipmentQuery } from '../vehicle-equipment.selectors';
import { createSelector } from '@ngrx/store';
import { EquipmentStateChange, EquipmentErrorEquipmentErrorDto } from '@vpfa/rest-api/valuation';
import { isNil, chain } from 'lodash';
import { SideChanges, VehicleEquipmentFormulaStep } from '../../models/vehicle-equipment-formula-step';
import { getCurrentFormula } from './utils/get-current-formula';
import { getCurrentStep } from './utils/get-current-step';
import { isEqual } from 'lodash/fp';
import { isFakeEquipmentSoaCode } from '@vpfa/dealer/vehicle-equipment/common';
const getFormulaWizardState = vehicleEquipmentQuery.getFormulaWizard;

const stepIsResolved = step => step.mainChanges.length > 0 || step.sideChanges.length > 0;

const isFormulaWizardStarted = createSelector(getFormulaWizardState, Boolean);
const currentStep = createSelector(getFormulaWizardState, state => {
  if (!Boolean(state)) {
    return null;
  }

  const currentStep = getCurrentStep(state);
  if (!currentStep) {
    return null;
  }
  return currentStep;
});

const equipmentList = createSelector(getFormulaWizardState, state => {
  if (!state || state.tempEquipmentList.loading) {
    return [];
  }

  return state.tempEquipmentList.list;
});

const currentStepIndex = createSelector(getFormulaWizardState, state => {
  if (!Boolean(state)) {
    return;
  }

  return getCurrentFormula(state).currentStepIndex;
});

const stepsCount = createSelector(getFormulaWizardState, state => {
  if (!Boolean(state)) {
    return;
  }

  return getCurrentFormula(state)?.steps?.length;
});

const currentStepParent = createSelector(getFormulaWizardState, state => {
  if (!Boolean(state)) {
    return;
  }

  return getCurrentFormula(state)?.mainEquipmentSoaCode;
});

const currentFormulaIndex = createSelector(getFormulaWizardState, state => {
  if (!Boolean(state)) {
    return;
  }

  return state.formulaIndex;
});

const formulaCount = createSelector(getFormulaWizardState, state => {
  if (!Boolean(state)) {
    return;
  }

  return state.formulas?.length;
});

const caseId = createSelector(getFormulaWizardState, state => state?.caseId);

const vehicleId = createSelector(getFormulaWizardState, state => state?.vehicleId);

const previousStepsChangesWithoutCurrentFormula = (onlyMainChanges = false) =>
  createSelector(getFormulaWizardState, state => {
    const formulaWizardNotStarted = !Boolean(state);

    if (formulaWizardNotStarted) {
      return [];
    }

    const currentFormula = getCurrentFormula(state);

    return chain(state.formulas)
      .slice(0, state.formulaIndex) // previous formula
      .map(formula => [
        { mainChanges: [{ isEnabled: true, soaCode: formula.mainEquipmentSoaCode }], sideChanges: [] }, // fake step with enabled finished formula
        ...formula.steps,
      ])
      .flatMap() // all steps from previous formula
      .concat(currentFormula.steps.slice(0, currentFormula.currentStepIndex)) // add steps from current formula
      .map(step => getStepChanges(step, onlyMainChanges))
      .flatMap()
      .thru(uniqChanges)
      .value();
  });

/**
 * Contains only formulas that have one or more resolved steps (notice: not all steps may be resolved)
 *
 * Step is considered resolved when main or side changes have arrays length > 0
 */
const partiallyResolvedFormulas = createSelector(getFormulaWizardState, state => {
  if (!state) {
    return [];
  }

  return state.formulas.filter(formula => formula.steps.map(step => stepIsResolved(step))?.some(x => x === true));
});

/**
 * Resolved steps from all at least partially resolved formulas
 *
 * Step is considered resolved when main or side changes have arrays length > 0
 */
const resolvedSteps = createSelector(partiallyResolvedFormulas, formulas => {
  if (!formulas) {
    return [];
  }

  return formulas.reduce(
    (prev, formula) => prev.concat(formula.steps.filter(step => stepIsResolved(step))),
    []
  ) as VehicleEquipmentFormulaStep[];
});

/**
 * Step is considered resolved when main or side changes have arrays length > 0
 */
const resolvedStepsSideChanges = createSelector(resolvedSteps, steps => {
  if (!steps) {
    return [];
  }

  return steps.reduce((prev, step) => prev.concat(step.sideChanges), []) as SideChanges[];
});

const previousStepsChangesWithCurrentFormula = (onlyMain = false) =>
  createSelector(getFormulaWizardState, previousStepsChangesWithoutCurrentFormula(onlyMain), (state, prevChanges) => {
    if (!state) {
      return [];
    }

    const currentFormula = getCurrentFormula(state);

    const changes: EquipmentStateChange[] = [
      {
        isEnabled: true,
        soaCode: currentFormula.mainEquipmentSoaCode,
      },
    ];

    return [...changes, ...prevChanges];
  });

const tmpEquipmentListQuery = (onlyMainChanges = false) =>
  createSelector(
    caseId, isFormulaWizardStarted, previousStepsChangesWithCurrentFormula(onlyMainChanges),
    (caseId, isFormulaWizardStarted, previousStepsChanges) => {
      if (!isFormulaWizardStarted) {
        return null;
      }

      return {
        caseId: caseId,
        previousStepsChanges,
      };
    }
  );

// TODO: explain in comment to make more sense
const changesThatAffectsSelection = createSelector(
  [isFormulaWizardStarted, previousStepsChangesWithCurrentFormula()],
  (isFormulaWizardStarted, previousStepsChanges) => {
    if (!isFormulaWizardStarted) {
      return null;
    }

    return (previousStepsChanges as VehicleEquipmentFormulaStep['sideChanges']).filter(
      eqp => !eqp.isNotAffectSelection
    );
  }
);

const currentFormulaIsLast = createSelector([formulaCount, currentFormulaIndex], (formulaCount, currentFormulaIndex) =>
  indexIsLast(formulaCount, currentFormulaIndex)
);

const isLastStep = createSelector(
  [stepsCount, formulaCount],
  (stepsCount, formulaCount, props: { stepIndex: number; formulaIndex: number }) =>
    indexIsLast(formulaCount, props?.formulaIndex) && indexIsLast(stepsCount, props?.stepIndex)
);

const currentStepIsLast = createSelector(
  stepsCount, currentStepIndex, currentFormulaIsLast,
  (stepsCount, currentStepIndex, isLastFormula) => isLastFormula && indexIsLast(stepsCount, currentStepIndex)
);

const allStepsChanges = () => {
  const onlyMainChanges = true;

  return createSelector(
    currentStep, previousStepsChangesWithCurrentFormula(onlyMainChanges),
    (currentStep, previousStepsChanges) => {
      if (!currentStep) {
        return [];
      }

      return uniqChanges([...previousStepsChanges, ...getStepChanges(currentStep, onlyMainChanges)]);
    }
  );
};

const closed = createSelector(getFormulaWizardState, state => (state ? !state.saving && state.closed : false));

const getFormulasMainCodes = createSelector(getFormulaWizardState, state => {
  if (!state) {
    return [];
  }

  return state.formulas.map(formula => formula.mainEquipmentSoaCode);
});

const isValidation = createSelector(getFormulaWizardState, state => state?.validating ?? false);
const isSaving = createSelector(getFormulaWizardState, state => state?.saving ?? false);

const previousStepsErrors = createSelector(getFormulaWizardState, state => {
  if (!Boolean(state)) {
    return [];
  }

  const currentFormula = getCurrentFormula(state);

  return chain(state.formulas)
    .slice(0, state.formulaIndex) // previous formula
    .map(formula => [...formula.steps])

    .flatMap() // all steps from previous formula
    .concat(currentFormula.steps.slice(0, currentFormula.currentStepIndex)) // add steps from current formula
    .map(step => step.errors ?? [])
    .flatMap()
    .thru(uniqErrors)
    .value();
});

const allStepsErrors = createSelector(currentStep, previousStepsErrors, (currentStep, previousStepsChanges) => {
  if (!currentStep) {
    return [];
  }

  return uniqErrors([...previousStepsChanges, ...(currentStep.errors ?? [])]);
});

export const formulaWizardQuery = {
  isFormulaWizardStarted,
  currentStep,
  equipmentList,
  currentStepIndex,
  stepsCount,
  currentStepParent,
  tmpEquipmentListQuery,
  caseId,
  vehicleId,
  previousStepsChangesWithCurrentFormula,
  isLastStep,
  allStepsChanges,
  currentStepIsLast,
  closed,
  currentFormulaIndex,
  formulaCount,
  getFormulasMainCodes,
  isValidation,
  isSaving,
  previousStepsErrors,
  allStepsErrors,
  resolvedStepsSideChanges,
  changesThatAffectsSelection,
  previousStepsChangesWithoutCurrentFormula,
};

export function getStepChanges(
  {
    mainChanges,
    sideChanges,
  }: {
    mainChanges: VehicleEquipmentFormulaStep['mainChanges'];
    sideChanges: VehicleEquipmentFormulaStep['sideChanges'];
  },
  onlyMainChanges = false
): EquipmentStateChange[] {
  const filterFakeSoaCodes = (changes: EquipmentStateChange[]): EquipmentStateChange[] =>
    changes.filter(equipmentStateChange => !isFakeEquipmentSoaCode(equipmentStateChange.soaCode));

  return [...filterFakeSoaCodes(mainChanges), ...(!onlyMainChanges ? sideChanges : [])];
}

function indexIsLast(stepsCount: number, stepIndex: number) {
  return isNil(stepIndex) ? false : stepIndex + 1 === stepsCount;
}

export function uniqChanges(changes: EquipmentStateChange[]): EquipmentStateChange[] {
  return chain(changes).reverse().uniqBy('soaCode').reverse().value();
}

export function uniqErrors(errors: EquipmentErrorEquipmentErrorDto[]): EquipmentErrorEquipmentErrorDto[] {
  return chain(errors).uniqWith(isEqual).value();
}
