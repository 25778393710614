<ng-container *vpfaResponsiveIf="'mobile'">
  <vpfa-mobile-subtitle [title]="'vehicleFullTechnicalData.common.label' | translate"></vpfa-mobile-subtitle>
</ng-container>
<ng-container *vpfaResponsiveIf="'web'">
  <div class="mt">
    <vpfa-expanding-header [text]="'vehicleFullTechnicalData.common.label' | translate"></vpfa-expanding-header>
  </div>
</ng-container>

<div
  [fxLayout]="'row wrap'"
  fxLayoutGap.gt-mv="70px grid"
  fxLayout.lt-mv="column"
  fxLayoutGap.lt-mv="10px"
  ngClass.gt-mv="section"
>
  <vpfa-vehicle-technical-data-single-field
    [label]="'vehicleFullTechnicalData.common.bodyType' | translate"
    [value]="technicalDataBody?.nationalType?.texts"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    [label]="'vehicleFullTechnicalData.common.vehicleType' | translate"
    [value]="vehicleType"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-single-field
    *ngIf="!featureConfig?.hideDoors"
    [label]="'vehicleFullTechnicalData.common.doors' | translate"
    [value]="technicalDataBody?.doors | numberLocale"
  ></vpfa-vehicle-technical-data-single-field>

  <vpfa-vehicle-technical-data-number-field
    [label]="'vehicleFullTechnicalData.common.numberSeats' | translate"
    [parentFormGroup]="formGroup"
    [fcName]="'numberSeats'"
    [errorMessages]="errors"
    [maxFractionNumber]="0"
    [heightCompensationPx]="0"
  ></vpfa-vehicle-technical-data-number-field>
</div>
