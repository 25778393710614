/**
 * Vehicle Insurance Claiming Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 

export enum ClaimsDivision {

      Unknown = 0,
      GeneralAccidentInsurance = 30,
      GeneralLiabilityInsurance = 40,
      MotorLiabilityInsurance = 51,
      FullVehicleInsurance = 52,
      VehiclePartsInsurance = 53,
      MotorAccidentInsurance = 54,
      OtherCarInsurance = 59,
      LegalProtection = 70,
      FireInsurance = 80,
      BurglaryAndRobberyInsurance = 90,
      TapWaterInsurance = 100,
      GlassInsurance = 110,
      StormInsurance = 120,
      AssociatedHouseholdInsurance = 130,
      AffiliatedHomeInsurance = 140,
      HailInsurance = 150,
      AnimalInsurance = 160,
      TechnicalInsurance = 170,
      UniversalInsurance = 180,
      CoverLetterInsurance = 241,
      OtherDamageInsurance = 290,

};
