import { ModelDto } from '../models/model-dto';
import { MakeDto } from '../models/make-dto';

export const mapModelDto = (make: MakeDto, model): ModelDto => {
  return {
    code: model?.code,
    name: model?.name,
    make: make,
    type: model?.vehtype?.id,
  };
};
