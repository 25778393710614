import { VehicleDto } from '../models/vehicle-dto';
import { mapContentLanguageValue } from './map-content-language';

export const mapVehicleDto = (
  vehicle,
  countryCode: string,
  contentLanguage: string,
  sorcContentLanguageCoversions: { [key: string]: string }
): VehicleDto => {
  return {
    natcode: vehicle?.identification?.nationalCode,
    vehicleType: vehicle?.identification?.vehicleType?.id,
    vehicleTypeShortDescription: mapContentLanguageValue(
      vehicle?.identification?.vehicleType?.texts,
      countryCode,
      contentLanguage,
      sorcContentLanguageCoversions
    ),
    make: vehicle?.identification?.make?.name,
    model: vehicle?.identification?.model?.nameGroup,
    edition: vehicle?.identification?.edition,
    prodStart: vehicle?.identification?.salesStart,
    prodEnd: vehicle?.identification?.salesEnd,
    transmissionType: mapContentLanguageValue(
      vehicle?.transmission?.type?.texts,
      countryCode,
      contentLanguage,
      sorcContentLanguageCoversions
    ),
    bodyType: mapContentLanguageValue(
      vehicle?.body?.type?.texts,
      countryCode,
      contentLanguage,
      sorcContentLanguageCoversions
    ),
    numberDoors: vehicle?.body?.doors,
    engineCapacity: vehicle?.propulsionEngine?.engineCapacity,
    fuelType: mapContentLanguageValue(
      vehicle?.propulsionEngine?.fuelType?.texts,
      countryCode,
      contentLanguage,
      sorcContentLanguageCoversions
    ),
    powerInKW: vehicle?.propulsionEngine?.peakPowerKW,
    powerInHP: vehicle?.propulsionEngine?.peakPowerHP,
    grossVehicleWeight: vehicle?.weights?.grossVehicleWeight,
    wheelbase: vehicle?.body?.wheelbase,
  };
};
