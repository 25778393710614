import { Component, Input, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { isEllipsisActive } from '@vpfa/utils';

@Component({
  selector: 'vpfa-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent implements OnInit {
  @Input() text: string;
  @Input() ellipsis = false;
  @ViewChild('labelText', { read: ElementRef }) labelText: ElementRef;
  constructor() {}

  ngOnInit() {}

  get isEllipsisActive() {
    return this.ellipsis && this.labelText && isEllipsisActive(this.labelText.nativeElement);
  }
}
