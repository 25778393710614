import { Pipe, PipeTransform, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { LocaleFacade } from '@vpfa/locale';
import { Subject } from 'rxjs';
import { formatNumber, getCurrencySymbol, formatCurrency } from '@angular/common';
import { isNil, isEqual } from 'lodash';
import { takeUntil, filter } from 'rxjs/operators';

@Pipe({
  name: 'numberLocale',
  pure: false,
})
export class NumberLocalePipe implements PipeTransform, OnDestroy {
  private _value: number;
  private _format: string;
  private _locale: string;
  private _lastValue: string;
  private _onDestroy$ = new Subject<void>();

  constructor(localeFacade: LocaleFacade, private _ref: ChangeDetectorRef) {
    localeFacade.locale$.pipe(takeUntil(this._onDestroy$)).subscribe(locale => {
      if (!isNil(locale)) {
        this._locale = locale;
        this.refreshValue();
      }
    });
  }

  transform(value: number, format?: string): any {
    let needRefresh = false;

    if (!isEqual(this._value, value)) {
      needRefresh = true;
      this._value = value;
    }

    if (!isEqual(this._format, format)) {
      needRefresh = true;
      this._format = format;
    }

    if (needRefresh) {
      this.refreshValue();
    }

    return this._lastValue;
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private refreshValue() {
    if (isNil(this._locale) || isNil(this._value)) {
      if (!isNil(this._lastValue)) {
        this._lastValue = null;
        this._ref.markForCheck();
      }
      return;
    }
    this._lastValue = formatNumber(this._value, this._locale, this._format);
    this._ref.markForCheck();
  }
}

@Pipe({
  name: 'currencyLocale',
  pure: false,
})
export class CurrencyLocalePipe implements PipeTransform, OnDestroy {
  private _value: number;
  private _format: string;
  private _currencyCode = 'EUR';
  private _display;
  private _onDestroy$ = new Subject<void>();
  private _locale: string;
  private _lastValue: string;
  private readonly SPACE = String.fromCharCode(160);

  constructor(localeFacade: LocaleFacade, private _ref: ChangeDetectorRef) {
    localeFacade.locale$
      .pipe(
        filter(val => !isNil(val)),
        takeUntil(this._onDestroy$)
      )
      .subscribe(locale => {
        this._locale = locale;
        this.refreshValue();
      });

    localeFacade.currency$
      .pipe(
        filter(val => !isNil(val)),
        takeUntil(this._onDestroy$)
      )
      .subscribe(currency => {
        this._currencyCode = currency;
        this.refreshValue();
      });
  }

  transform(
    value: number,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow',
    digitsInfo?: string
  ): any {
    let needRefresh = false;

    if (!isEqual(this._value, value)) {
      needRefresh = true;
      this._value = value;
    }

    const newDisplay = display ? display : 'symbol-narrow';

    if (!isEqual(this._display, newDisplay)) {
      needRefresh = true;
      this._display = newDisplay;
    }

    if (currencyCode && !isEqual(this._currencyCode, currencyCode)) {
      needRefresh = true;
      this._currencyCode = currencyCode;
    }
    if (!isEqual(this._format, digitsInfo)) {
      needRefresh = true;
      this._format = digitsInfo;
    }

    if (needRefresh) {
      this.refreshValue();
    }

    return this._lastValue;
  }

  getSymbol(): string {
    return getCurrencySymbol(this._currencyCode, 'narrow', this._locale);
  }
  getCurrencyCode(): string {
    return this._currencyCode;
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private refreshValue() {
    if (isNil(this._locale) || isNil(this._value)) {
      if (!isNil(this._lastValue)) {
        this._lastValue = null;
        this._ref.markForCheck();
      }
      return;
    }

    let currency: string = this._currencyCode || 'USD';
    if (this._display !== 'code') {
      if (this._display === 'symbol' || this._display === 'symbol-narrow') {
        currency = getCurrencySymbol(currency, this._display === 'symbol' ? 'wide' : 'narrow', this._locale);
      } else {
        currency = this._display;
      }
    }

    let amountWithCurrency = formatCurrency(this._value, this._locale, currency, this._currencyCode, this._format);
    // by default, formatCurrency returns '-' before currency symbol
    if (amountWithCurrency.indexOf('-') === 0) {
      amountWithCurrency = amountWithCurrency.replace(`-${currency}`, `${currency}-`);
    }
    const isCurrencySymbolAtStart = amountWithCurrency.indexOf(currency) === 0;

    // always separate currency symbol/code from amount by one space
    amountWithCurrency = amountWithCurrency.split(this.SPACE).join('');
    this._lastValue = isCurrencySymbolAtStart
      ? amountWithCurrency.replace(currency, `${currency}${this.SPACE}`)
      : amountWithCurrency.replace(currency, `${this.SPACE}${currency}`);

    this._ref.markForCheck();
  }
}
