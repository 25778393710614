import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SelectOption } from '@vpfa/ui-kit';
import { isNil } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'vpfa-text-field-with-dropdown',
  templateUrl: './text-field-with-dropdown.component.html',
  styleUrls: ['./text-field-with-dropdown.component.scss'],
})
export class TextFieldWithDropdownComponent implements OnInit, OnDestroy {
  @Input() formGroup: UntypedFormGroup;
  @Input() fcName: string;
  @Input() options: SelectOption[];
  @Input() errorMessages: ReadonlyArray<{ error: string; errorMsg: string }>;
  /**
   * Recognises whether provided value is custom (typed by used) or not (selected by user).
   * This can be used to manipulate text field value from outside of the component.
   */
  @Input() isCustomValueProvided: BehaviorSubject<boolean>;

  /**
   * Whether show reset button at the start of the text field, disable it or show tooltip
   */
  @Input() showResetButton = false;
  @Input() resetButtonDisabled = false;
  @Input() resetButtonTooltip: string;

  @Output() selectedValue = new EventEmitter();
  @Output() resetValue = new EventEmitter();

  selectGroup: UntypedFormGroup;
  private destroy$ = new Subject<void>();

  constructor(private fb: UntypedFormBuilder) {
    this.selectGroup = this.fb.group({
      selectControl: [null],
    });
  }

  ngOnInit(): void {
    this.fc.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.fc.dirty) {
        this.selectControl.reset();

        if (!isNil(this.isCustomValueProvided)) {
          this.isCustomValueProvided.next(true);
        }
      }
    });
  }

  get fc(): UntypedFormControl {
    return this.formGroup.get(this.fcName) as UntypedFormControl;
  }

  get selectControl(): UntypedFormControl {
    return this.selectGroup.get('selectControl') as UntypedFormControl;
  }

  onSelectedValue(option: SelectOption) {
    if (!isNil(option?.name)) {
      this.fc.reset(option.name);
      this.selectedValue.emit(option?.value);

      if (!isNil(this.isCustomValueProvided)) {
        this.isCustomValueProvided.next(true);
      }
    }
  }

  onReset() {
    this.selectControl.reset();
    this.resetValue.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
