import { SelectOption } from '@vpfa/ui-kit';

export const AVAILABILITY_TYPE_DAYS_OPTIONS: SelectOption[] = [
  { name: '1', value: 1 },
  { name: '2', value: 2 },
  { name: '3', value: 3 },
  { name: '4', value: 4 },
  { name: '5', value: 5 },
  { name: '6', value: 6 },
  { name: '7', value: 7 },
  { name: '14', value: 14 },
  { name: '42', value: 42 },
  { name: '60', value: 60 },
  { name: '90', value: 90 },
  { name: '120', value: 120 },
  { name: '150', value: 150 },
  { name: '180', value: 180 },
  { name: '270', value: 270 },
  { name: '360', value: 360 },
];

export const DEFAULT_DAYS: SelectOption = { name: '1', value: 1 };
