<nz-modal
  [nzClassName]="className ? className : 'vp-modal'"
  [nzBodyStyle]="{ padding: 0 }"
  [nzZIndex]="zIndex"
  [nzTitle]="getHeader(header)"
  [nzContent]="content"
  [nzVisible]="isVisible"
  [nzFooter]="disableFooter && null"
  [nzWrapClassName]="wrapClassName"
  [nzClosable]="isClosable"
  [nzWidth]="width"
  [nzMaskClosable]="maskClosable"
  [nzKeyboard]="keyboard"
  [nzMask]="showBackdrop"
  [nzAutofocus]="null"
  (nzOnCancel)="onModalCloseAction()"
  (nzAfterOpen)="afterModalOpenAction()"
  (nzAfterClose)="afterModalCloseAction()"
>
</nz-modal>
<ng-template #header>
  <ng-content select="vpfa-modal-header"></ng-content>
</ng-template>
<ng-template #content><ng-content select="vpfa-modal-content"></ng-content></ng-template>
