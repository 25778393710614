import { ChangeDetectorRef, Input, Directive } from '@angular/core';
import { KeepersChangeDetailsDto } from '@vpfa/rest-api/valuation';
import { LocaleFacade } from '@vpfa/locale';
import { DateLocalePipe } from '@vpfa/shared/pipes';
import { isNil } from 'lodash';

@Directive()
export class BaseProvenanceCheckSectionKeepersComponent {
  @Input()
  set keepersChangeDetails(keepersChangeDetails: KeepersChangeDetailsDto) {
    this._keepersChangeDetails = keepersChangeDetails;
    this.setKeepersChangeDates();
  }

  private _keepersChangeDetails: KeepersChangeDetailsDto;

  get keepersChangeDetails() {
    return this._keepersChangeDetails;
  }

  get isKeepersEmpty() {
    return (
      isNil(this.keepersChangeDetails?.numberOfPreviousKeepers) &&
      isNil(this.keepersChangeDetails?.dateOfMostRecentKeeperChange) &&
      isNil(this.datesOfKeeperChanges)
    );
  }

  datesOfKeeperChanges: string[] = [];

  constructor(protected localeFacade: LocaleFacade, protected ref: ChangeDetectorRef) {}
  setKeepersChangeDates() {
    if (this._keepersChangeDetails) {
      this.datesOfKeeperChanges = this._keepersChangeDetails.datesOfKeeperChanges?.map(dateOfKeeperChange => {
        const datePipe = new DateLocalePipe(this.localeFacade, this.ref);
        return datePipe.transform(dateOfKeeperChange);
      });
    } else {
      this.datesOfKeeperChanges = [];
    }
  }
}
