<vpfa-setting-box *vpfaResponsiveIf="'web'; else mobileView" [divider]="false">
  <ng-container label>
    <vpfa-label [text]="label"></vpfa-label>
  </ng-container>
  <ng-container content>
    <vpfa-select
      *ngIf="parentFormGroup"
      [parentFormGroup]="parentFormGroup"
      [fc]="parentFormGroup?.get(fcName)"
      [translateLabel]="true"
      [options]="selectOptions"
      [hiddenOptions]="hiddenOptions"
      (selected)="onSelectionChange.emit($event)"
    >
    </vpfa-select>
  </ng-container>
</vpfa-setting-box>

<ng-template #mobileView>
  <vpfa-vehicle-technical-data-single-field
    [label]="label"
    [value]="readOnlyValue | translate"
  ></vpfa-vehicle-technical-data-single-field>
</ng-template>
