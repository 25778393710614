<div [style.height]="" [fxLayout]="'column'">
  <vpfa-data-table-header
    *ngIf="showHeader"
    [tableName]="tableName"
    [extraLeftTemplate]="headerLeft"
    [extraRightTemplate]="headerRight"
  ></vpfa-data-table-header>

  <ng-container *ngTemplateOutlet="extraUnderHeaderTemplate"></ng-container>

  <div [ngClass]="gridParentClass">
    <ag-grid-angular
      fxFlex
      [gridOptions]="gridOptions"
      class="ag-theme-ssm"
      [rowData]="rowData"
      [rowClassRules]="rowClassRules"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [components]="frameworkComponents"
      [isFullWidthRow]="isFullWidthCell"
      [fullWidthCellRenderer]="fullWidthCellRenderer"
      [rowSelection]="rowSelection"
      [suppressRowClickSelection]="suppressRowClickSelection"
      [getRowId]="rowNodeIdFn"
      [enableCellChangeFlash]="enableCellChangeFlash"
      noRowsOverlayComponent="noRowsIndicator"
      [rowModelType]="dataSource ? 'infinite' : 'clientSide'"
      [maxBlocksInCache]="1"
      (cellClicked)="onCellClick($event)"
      (gridReady)="onGridReady($event)"
      (dragStopped)="onColumnMoved()"
      (columnVisible)="onColumnVisibleChange()"
    >
    </ag-grid-angular>
  </div>

  <vpfa-data-table-pagination
    *ngIf="gridOptions.pagination"
    [sizeOptions]="sizeOptions"
    [paginationOptions]="paginationOptions$ | async"
    (pageChange)="onPageChange($event - 1)"
    (pageSizeChange)="onPageSizeChange($event)"
  ></vpfa-data-table-pagination>
</div>
<ng-template #headerLeft>
  <ng-container *ngTemplateOutlet="extraHeaderLeftTemplate"></ng-container>
</ng-template>

<ng-template #headerRight>
  <ng-container *ngTemplateOutlet="extraHeaderRightTemplate"></ng-container>
  <vpfa-data-table-settings
    *ngIf="showColumSettings"
    [settingsTemplate]="settingsTemplate"
    [(visible)]="settingsVisible"
  ></vpfa-data-table-settings>
  <vpfa-tooltip *ngIf="tooltipContent" [content]="tooltipContent" class="tooltip"></vpfa-tooltip>

  <ng-template #settingsTemplate>
    <div fxLayout="column">
      <vpfa-data-table-settings-columns
        *ngIf="columnVisibility"
        [gridApi]="gridApi"
      ></vpfa-data-table-settings-columns>
      <ng-container *ngTemplateOutlet="extraSettingsTemplate"></ng-container>
    </div>
  </ng-template>
</ng-template>
