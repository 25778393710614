import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'vpfa-arrow-link',
  templateUrl: './arrow-link.component.html',
  styleUrls: ['./arrow-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArrowLinkComponent {
  @Input() variant: 'default' | 'enter' = 'default';
  @Input() icon: string;
  @Output() clicked = new EventEmitter();

  onClick(e: MouseEvent) {
    e.stopPropagation();
    this.clicked.emit();
  }
}
