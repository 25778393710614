/**
 * Dealer Network AdminService API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { GetTableauReportResponse } from '../model/getTableauReportResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TableauReportViewService {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param name 
     * @param market 
     * @param language 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTableauReportUrl(name: string, market: string, language: string, observe?: 'body', reportProgress?: boolean): Observable<GetTableauReportResponse>;
    public getTableauReportUrl(name: string, market: string, language: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetTableauReportResponse>>;
    public getTableauReportUrl(name: string, market: string, language: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetTableauReportResponse>>;
    public getTableauReportUrl(name: string, market: string, language: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling getTableauReportUrl.');
        }

        if (market === null || market === undefined) {
            throw new Error('Required parameter market was null or undefined when calling getTableauReportUrl.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling getTableauReportUrl.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
          queryParameters = queryParameters.set('Name', <any>name);
        }
        if (market !== undefined && market !== null) {
          queryParameters = queryParameters.set('Market', <any>market);
        }
        if (language !== undefined && language !== null) {
          queryParameters = queryParameters.set('Language', <any>language);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GetTableauReportResponse>(`${this.basePath}/api/TableauReportView`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
