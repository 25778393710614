import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vpfa-value',
  templateUrl: './value.component.html',
  styleUrls: ['./value.component.scss']
})
export class ValueComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
