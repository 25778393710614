<nz-spin [nzSpinning]="co2EmissionRangesLoading$ | async">
  <div *ngIf="isCO2Emission(); else noData" ngClass.lt-mv="mobile-margin">
    <p>{{ 'provenanceCheck.environmentalReportDetails.report' | translate }}</p>
    <p>
      {{ 'provenanceCheck.environmentalReportDetails.charter' | translate }} CO<sub>2</sub>
      {{ 'provenanceCheck.environmentalReportDetails.emission' | translate }}
    </p>
    <div class="charter-container" fxLayout="column" fxLayoutGap="5px">
      <vpfa-emission-class-row
        *ngFor="let range of co2EmissionRanges; let i = index"
        [ranges]="range"
        [co2Emission]="co2Emission"
        [colour]="colours[i]"
        [maxWidth]="arrowsMaxWidthsPx[i]"
        [flexWidth]="flexWidths[i]"
      ></vpfa-emission-class-row>
    </div>
    <p>CO<sub>2</sub> {{ 'provenanceCheck.environmentalReportDetails.cO2' | translate }}</p>
  </div>

  <ng-template #noData>
    <vpfa-empty></vpfa-empty>
  </ng-template>
</nz-spin>
