import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import * as formulaWizardActions from './formula-wizard.actions';
import { switchMap, withLatestFrom, map, catchError, filter, take, tap } from 'rxjs/operators';
import { CaseViewService, IdentifiedVehicleService } from '@vpfa/rest-api/valuation';
import { Store } from '@ngrx/store';
import { formulaWizardQuery, uniqChanges, getStepChanges } from './formula-wizard.selectors';
import { of } from 'rxjs';
import { fromCasesActions } from '@vpfa/dealer/case/data';
import { BasicNotificationsService } from '@vpfa/shared/notifications';
import { removeUnmetFormula } from './utils/remove-unmet-formula';
import { hasNewErrors } from './utils/has-new-errors';
import { removeAlreadyPresentErrors } from './utils/remove-duplicated-errors';
import { getIncompleteFormulas } from './utils/get-invalid-formulas';
import { getSideChanges } from './utils/get-side-changes';

@Injectable()
export class FormulaWizardEffects {
  startFormulaWizard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(formulaWizardActions.startFormulaWizard),
      map(action => formulaWizardActions.loadTempEqpList())
    )
  );

  loadTempEqpList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(formulaWizardActions.loadTempEqpList),
      withLatestFrom(this.store.select(formulaWizardQuery.tmpEquipmentListQuery())),
      switchMap(([_, tmpEquipmentListData]) =>
        this.caseViewService
          .getCaseEquipmentsWithMockedEquipmentStates(
            tmpEquipmentListData.caseId,
            tmpEquipmentListData.previousStepsChanges
          )
          .pipe(
            map(res => formulaWizardActions.loadTempEqpListSuccess({ equipments: res })),
            catchError(err => of(formulaWizardActions.loadTempEqpListFailed({ error: err.error })))
          )
      )
    )
  );

  validateStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(formulaWizardActions.validateStep),
      withLatestFrom(
        this.store.select(formulaWizardQuery.vehicleId),
        this.store.select(formulaWizardQuery.previousStepsChangesWithCurrentFormula(true)),
        this.store.select(formulaWizardQuery.currentStepIndex),
        this.store.select(formulaWizardQuery.currentFormulaIndex),
        this.store.select(formulaWizardQuery.getFormulasMainCodes),
        this.store.select(formulaWizardQuery.previousStepsErrors),
        this.store.select(formulaWizardQuery.resolvedStepsSideChanges)
      ),
      switchMap(
        ([
          action,
          vehicleId,
          prevChanges,
          stepIndex,
          formulaIndex,
          formulasMainCodes,
          previousStepsErrors,
          resolvedStepsSideChanges,
        ]) =>
          this.identifiedVehicleService
            .updateVehicleEquipment({
              validateOnly: true,
              ignoreValidationErrors: action.ignoreValidationErrors,
              aggregateRootId: vehicleId,
              equipmentChanges: uniqChanges([...prevChanges, ...getStepChanges(action)]),
              ignoreAllValidationErrors: false,
            })
            .pipe(
              map(res => {
                let equipmentErrors = removeUnmetFormula(res.equipmentErrors, formulasMainCodes);

                equipmentErrors = removeAlreadyPresentErrors(equipmentErrors, previousStepsErrors);

                if (hasNewErrors(action, equipmentErrors)) {
                  return formulaWizardActions.validateStepErrors({
                    errors: equipmentErrors,
                    mainChanges: action.mainChanges,
                  });
                }

                const sideChanges = getSideChanges(res.equipmentInfos, resolvedStepsSideChanges);

                const incompleteFormulas = getIncompleteFormulas(res.formulaBlocks);

                return formulaWizardActions.validateStepSuccess({
                  ...action,
                  sideChanges,
                  stepIndex,
                  formulaIndex,
                  incompleteFormulas,
                  errors: equipmentErrors,
                });
              }),
              catchError(err => {
                return of(formulaWizardActions.validateStepFailed({ error: err.error }));
              })
            )
      )
    )
  );

  validateStepSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(formulaWizardActions.validateStepSuccess),
      map(({ mainChanges, sideChanges, stepIndex, formulaIndex, incompleteFormulas: newFormulas, force, errors }) => {
        if (newFormulas) {
          return formulaWizardActions.updateFormulasStructure({
            mainChanges,
            sideChanges,
            stepIndex,
            formulaIndex,
            incompleteFormulas: newFormulas,
            force,
            errors,
          });
        } else {
          return formulaWizardActions.nextStep({ mainChanges, sideChanges, stepIndex, formulaIndex, force, errors });
        }
      })
    )
  );

  updateFormulasStructure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(formulaWizardActions.updateFormulasStructure),
      map(({ mainChanges, sideChanges, stepIndex, formulaIndex, force, errors }) => {
        return formulaWizardActions.nextStep({ mainChanges, sideChanges, stepIndex, formulaIndex, force, errors });
      })
    )
  );

  /**
   * Refresh temp equipment before going to the next step
   */
  refreshTmpEquipmentListOnNextStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(formulaWizardActions.nextStep),
      withLatestFrom(
        this.store.select(formulaWizardQuery.currentStepIndex),
        this.store.select(formulaWizardQuery.currentFormulaIndex)
      ),
      filter(
        ([action, currentStepIndex, currentFormulaIndex]) =>
          action.stepIndex !== currentStepIndex || action.formulaIndex !== currentFormulaIndex
      ),
      map(_ => formulaWizardActions.loadTempEqpList())
    )
  );

  /**
   * Refresh temp equipment before going to the previous step
   */
  refreshTmpEquipmentListOnPreviousStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(formulaWizardActions.previousStep),
      map(_ => formulaWizardActions.loadTempEqpList())
    )
  );

  /**
   * Emit Terminate Action if it is a last step
   */
  saveFormulaAfterAllSteps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(formulaWizardActions.nextStep),
      switchMap(action =>
        this.store
          .select(formulaWizardQuery.isLastStep, { stepIndex: action.stepIndex, formulaIndex: action.formulaIndex })
          .pipe(take(1))
      ),
      filter(isLast => isLast),
      map(_ => formulaWizardActions.saveFormula())
    )
  );

  /**
   * Terminate the Formula Wizard & send all changes to API
   */
  saveFormula$ = createEffect(() =>
    this.actions$.pipe(
      ofType(formulaWizardActions.saveFormula),
      withLatestFrom(
        this.store.select(formulaWizardQuery.allStepsChanges()),
        this.store.select(formulaWizardQuery.vehicleId),
        this.store.select(formulaWizardQuery.caseId),
        this.store.select(formulaWizardQuery.allStepsErrors)
      ),
      switchMap(([_, allStepsChanges, vehicleId, caseId, allStepsErrors]) =>
        this.identifiedVehicleService
          .updateVehicleEquipment({
            aggregateRootId: vehicleId,
            equipmentChanges: allStepsChanges,
            ignoreValidationErrors: false,
            validateOnly: false,
            // TODO: extend this comment
            // errors are only stored in the state if `force` flag was false
            ignoreAllValidationErrors: Boolean(allStepsErrors.length),
          })
          .pipe(
            map(res => {
              if (res.equipmentErrors.length === 0) {
                return formulaWizardActions.saveFormulaSuccess({ caseId });
              } else {
                return formulaWizardActions.saveFormulaDataIssue({ errors: res.equipmentErrors });
              }
            }),
            catchError(err => of(formulaWizardActions.saveFormulaFailed({ error: err.error })))
          )
      )
    )
  );

  /**
   * Load changes in equipment after formula wizard successfully terminates
   */
  saveFormulaSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(formulaWizardActions.saveFormulaSuccess),
      map(action => new fromCasesActions.CaseLoadEquipments(action.caseId))
    )
  );

  /**
   * Show notification when Formula Wizard is terminated with error
   */
  saveFormulaSuccessDataIssue$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(formulaWizardActions.saveFormulaDataIssue),
        tap(action => {
          this.notification.error('formulaWizard.dataIssue');
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private caseViewService: CaseViewService,
    private store: Store,
    private identifiedVehicleService: IdentifiedVehicleService,
    private notification: BasicNotificationsService
  ) {}
}
