import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { LocaleFacade } from '@vpfa/locale';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { isNil } from 'lodash';
import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';

@Pipe({
  name: 'decimalNumberLocale',
  pure: false,
})
export class DecimalNumberLocalePipe implements PipeTransform, OnDestroy {
  private readonly DEFAULT_DECIMAL_SEPARATOR = '.';
  private _decimalSeparator;
  private _onDestroy$ = new Subject<void>();

  constructor(localeFacade: LocaleFacade) {
    localeFacade.locale$
      .pipe(
        filter(locale => !isNil(locale)),
        takeUntil(this._onDestroy$)
      )
      .subscribe(locale => {
        this._decimalSeparator = getLocaleNumberSymbol(locale, NumberSymbol.Decimal);
      });
  }

  transform(value: number | string): string {
    return value?.toString().split(this.DEFAULT_DECIMAL_SEPARATOR).join(this._decimalSeparator);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
