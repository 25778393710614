import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromBroadcastReducer from './+state/broadcast.reducer';
import { EffectsModule } from '@ngrx/effects';
import { BroadcastEffects } from './+state/broadcast.effects';
import { BroadcastFacade } from './+state/broadcast.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromBroadcastReducer.broadcastFeatureKey, fromBroadcastReducer.reducer),
    EffectsModule.forFeature([BroadcastEffects]),
  ],
  providers: [BroadcastFacade],
})
export class DealerBroadcastsDataModule {}
