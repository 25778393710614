import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { ReturnReportDto } from '@vpfa/rest-api/valuation';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { getReturnReportDefinition, KEYS } from './get-return-report-definition';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'vpfa-return-report-modal',
  templateUrl: './return-report-modal.component.html',
  styleUrls: ['./return-report-modal.component.scss'],
})
export class ReturnReportModalComponent implements OnDestroy {
  @Input() open = false;
  @Input() processing = false;
  @Input() set report(report: ReturnReportDto) {
    if (report) {
      this._report = report;
      this.reset();
    }
  }
  @Output() closeAction = new EventEmitter();
  @Output() save = new EventEmitter<ReturnReportDto>();

  form: UntypedFormGroup;

  private onDestroy$ = new Subject<void>();
  private _report: ReturnReportDto;

  private technicalModificationsControl: AbstractControl;
  private generalOperatingLicenseControl: AbstractControl;
  private technicalModificationCertificateControl: AbstractControl;
  private codeCardRadioControl: AbstractControl;
  private codeCardRadioNumberControl: AbstractControl;

  constructor(fb: UntypedFormBuilder) {
    this._report = getReturnReportDefinition();
    this.form = fb.group(this._report);
    this.setupTechnicalModifications();
    this.setupCodeCard();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  close(): void {
    this.closeAction.emit();
  }

  afterClose() {
    this.reset();
  }

  onSubmit() {
    this.updateValidity();
    if (this.form.valid) {
      this.save.emit({
        ...getReturnReportDefinition(),
        ...this.form.value,
      });
    }
  }

  private reset() {
    this.form.reset(this._report);
  }

  private setupTechnicalModifications() {
    this.technicalModificationsControl = this.form.get(KEYS.technicalModifications);
    this.generalOperatingLicenseControl = this.form.get(KEYS.generalOperatingLicense);
    this.technicalModificationCertificateControl = this.form.get(KEYS.technicalModificationCertificate);

    this.generalOperatingLicenseControl.disable();
    this.technicalModificationCertificateControl.disable();

    this.technicalModificationsControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
      if (!value) {
        this.generalOperatingLicenseControl.setValue(null);
        this.technicalModificationCertificateControl.setValue(null);
        this.generalOperatingLicenseControl.disable();
        this.technicalModificationCertificateControl.disable();
      } else {
        this.generalOperatingLicenseControl.setValue(false);
        this.technicalModificationCertificateControl.setValue(false);
        this.generalOperatingLicenseControl.enable();
        this.technicalModificationCertificateControl.enable();
      }
    });
  }

  private setupCodeCard() {
    this.codeCardRadioControl = this.form.get(KEYS.codeCardRadio);
    this.codeCardRadioNumberControl = this.form.get(KEYS.codeCardRadioNumber);
    this.codeCardRadioNumberControl.disable();
    this.codeCardRadioNumberControl.setValidators([Validators.required, Validators.maxLength(100)]);

    this.codeCardRadioControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
      if (!value) {
        this.codeCardRadioNumberControl.setValue(null);
        this.codeCardRadioNumberControl.disable();
      } else {
        this.codeCardRadioNumberControl.enable();
      }
    });
  }

  private updateValidity() {
    this.codeCardRadioNumberControl.markAsDirty();
    this.codeCardRadioNumberControl.updateValueAndValidity();
  }
}
