import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ImageSize } from '@vpfa/utils';
import { isNil } from 'lodash';
import { ImagesState, IMAGES_FEATURE_KEY } from './images.reducer';

const getImagesState = createFeatureSelector<ImagesState>(IMAGES_FEATURE_KEY);

const getImage$ = (imageUrl: string, size: ImageSize) =>
  createSelector(getImagesState, (state: ImagesState) => {
    return state?.[imageUrl]?.[size];
  });

const getImageDataUrl$ = (imageUrl: string, size: ImageSize) =>
  createSelector(getImagesState, (state: ImagesState) => {
    return state?.[imageUrl]?.[size]?.dataUrl;
  });

const getImageLoading$ = (imageUrl: string, size: ImageSize) =>
  createSelector(getImagesState, (state: ImagesState) => {
    return state?.[imageUrl]?.[size]?.loading;
  });

const getAllImagesUrls = createSelector(getImagesState, (state: ImagesState) => {
  if (isNil(state)) {
    return null;
  }

  return Object.keys(state);
});

export const imagesQuery = {
  getImagesState,
  getImage$,
  getImageDataUrl$,
  getImageLoading$,
  getAllImagesUrls,
};
