import { createAction, props } from '@ngrx/store';
import { ImageActionParams } from '../interfaces/image-params.interface';

const prefix = '[Images]';

export const imageLoad = createAction(
  `${prefix} Image Load`,
  props<{
    payload: ImageActionParams;
  }>()
);

export const imageLoaded = createAction(
  `${prefix} Image Loaded`,
  props<{
    payload: ImageActionParams & {
      dataUrl: string;
      isFromSorc: boolean;
      fileSize: number;
    };
  }>()
);

export const imageLoadError = createAction(
  `${prefix} Image Load Error`,
  props<{
    payload: ImageActionParams;
  }>()
);

export const imageAlreadyLoaded = createAction(
  `${prefix} Image Already Loaded`,
  props<{
    payload: ImageActionParams;
  }>()
);

export const bumpImageExpiration = createAction(
  `${prefix} Image Bump Image Expiration`,
  props<{
    payload: ImageActionParams;
  }>()
);

export const removeImage = createAction(
  `${prefix} Remove Image`,
  props<{
    payload: ImageActionParams;
  }>()
);
