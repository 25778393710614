import { Directive } from '@angular/core';
import { LayoutAlignDirective } from '@ngbracket/ngx-layout';

const selector = `[fxLayoutAlign.gt-mv], [fxLayoutAlign.lt-mv]`;
const inputs = ['fxLayoutAlign.gt-mv', 'fxLayoutAlign.lt-mv'];

/**
 * @deprecated to be removed because FlexLayout is obsolete - see migration-from-flex-layout.md for details
 */
@Directive({ selector, inputs })
export class MobileLayoutAlignDirective extends LayoutAlignDirective {
  protected inputs = inputs;
}
