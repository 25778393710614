import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LocaleFacade } from '@vpfa/locale';
import { Features } from '@vpfa/rest-api/admin';
import { CaseTaxDto, TaxType } from '@vpfa/rest-api/valuation';
import { SelectOption } from '@vpfa/ui-kit';
import { enumToOptions } from '@vpfa/utils';
import { filter, take } from 'rxjs/operators';

export const TaxUniqueDataFormName = 'taxType';

@Component({
  selector: 'vpfa-unique-data-tax',
  templateUrl: './unique-data-tax.component.html',
  styleUrls: ['./unique-data-tax.component.scss'],
})
export class UniqueDataTaxComponent implements OnInit {
  @Input() set parentFormGroup(parentFormGroup: UntypedFormGroup) {
    if (parentFormGroup) {
      this._parentFormGroup = parentFormGroup;
      this._parentFormGroup.addControl('taxType', this.taxTypeControl);
    }
  }

  get parentFormGroup() {
    return this._parentFormGroup;
  }

  @Input() set taxes(taxes: CaseTaxDto) {
    this._taxes = taxes;
    this._selectedOption = this.taxOptions.find(option => option.value === taxes.taxType);
    this.taxTypeControl.reset(this._selectedOption);
  }

  get taxes(): CaseTaxDto {
    return this._taxes;
  }

  get taxCode(): string {
    return this._selectedOption ? this._selectedOption.name : null;
  }

  taxOptions = enumToOptions(TaxType, 'admin.taxType.');
  taxTypeControl = new UntypedFormControl(null, [Validators.required]);

  private _parentFormGroup: UntypedFormGroup;
  private _taxes: CaseTaxDto;
  private _selectedOption: SelectOption<TaxType>;

  constructor(private localeFacade: LocaleFacade) {}

  ngOnInit(): void {
    this.localeFacade
      .isFeatureConfigurationEnabled(Features.HideDifferentiallyTaxed)
      .pipe(
        take(1),
        filter(x => x === true)
      )
      .subscribe(() => {
        this.taxOptions = this.taxOptions.filter(x => x.value !== TaxType.DifferentiallyTaxed);
      });
  }
}
