/**
 * Public SSM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0-private
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { CommandHandlerResponse } from '../model/commandHandlerResponse';
import { EquipmentColorBody } from '../model/equipmentColorBody';
import { EquipmentColorBody1 } from '../model/equipmentColorBody1';
import { EquipmentColorBody2 } from '../model/equipmentColorBody2';
import { EquipmentColorBody3 } from '../model/equipmentColorBody3';
import { IdentifiedVehicleAddBuidibilityDataBody } from '../model/identifiedVehicleAddBuidibilityDataBody';
import { IdentifiedVehicleAddBuidibilityDataBody1 } from '../model/identifiedVehicleAddBuidibilityDataBody1';
import { IdentifiedVehicleAddBuidibilityDataBody2 } from '../model/identifiedVehicleAddBuidibilityDataBody2';
import { IdentifiedVehicleAddBuidibilityDataBody3 } from '../model/identifiedVehicleAddBuidibilityDataBody3';
import { IdentifiedVehicleAftermarketEquipmentBody } from '../model/identifiedVehicleAftermarketEquipmentBody';
import { IdentifiedVehicleAftermarketEquipmentBody1 } from '../model/identifiedVehicleAftermarketEquipmentBody1';
import { IdentifiedVehicleAftermarketEquipmentBody2 } from '../model/identifiedVehicleAftermarketEquipmentBody2';
import { IdentifiedVehicleAftermarketEquipmentBody3 } from '../model/identifiedVehicleAftermarketEquipmentBody3';
import { IdentifiedVehicleAftermarketEquipmentBody4 } from '../model/identifiedVehicleAftermarketEquipmentBody4';
import { IdentifiedVehicleAftermarketEquipmentBody5 } from '../model/identifiedVehicleAftermarketEquipmentBody5';
import { IdentifiedVehicleAftermarketEquipmentBody6 } from '../model/identifiedVehicleAftermarketEquipmentBody6';
import { IdentifiedVehicleAftermarketEquipmentBody7 } from '../model/identifiedVehicleAftermarketEquipmentBody7';
import { IdentifiedVehicleEquipmentBody } from '../model/identifiedVehicleEquipmentBody';
import { IdentifiedVehicleEquipmentBody1 } from '../model/identifiedVehicleEquipmentBody1';
import { IdentifiedVehicleEquipmentBody2 } from '../model/identifiedVehicleEquipmentBody2';
import { IdentifiedVehicleEquipmentBody3 } from '../model/identifiedVehicleEquipmentBody3';
import { IdentifiedVehicleFuelSubtypesBody } from '../model/identifiedVehicleFuelSubtypesBody';
import { IdentifiedVehicleFuelSubtypesBody1 } from '../model/identifiedVehicleFuelSubtypesBody1';
import { IdentifiedVehicleFuelSubtypesBody2 } from '../model/identifiedVehicleFuelSubtypesBody2';
import { IdentifiedVehicleFuelSubtypesBody3 } from '../model/identifiedVehicleFuelSubtypesBody3';
import { IdentifiedVehicleResetEquipmentBody } from '../model/identifiedVehicleResetEquipmentBody';
import { IdentifiedVehicleResetEquipmentBody1 } from '../model/identifiedVehicleResetEquipmentBody1';
import { IdentifiedVehicleResetEquipmentBody2 } from '../model/identifiedVehicleResetEquipmentBody2';
import { IdentifiedVehicleResetEquipmentBody3 } from '../model/identifiedVehicleResetEquipmentBody3';
import { IdentifiedVehicleTechnicalDataBody } from '../model/identifiedVehicleTechnicalDataBody';
import { IdentifiedVehicleTechnicalDataBody1 } from '../model/identifiedVehicleTechnicalDataBody1';
import { IdentifiedVehicleTechnicalDataBody2 } from '../model/identifiedVehicleTechnicalDataBody2';
import { IdentifiedVehicleTechnicalDataBody3 } from '../model/identifiedVehicleTechnicalDataBody3';
import { IdentifiedVehicleVehicleKBABody } from '../model/identifiedVehicleVehicleKBABody';
import { IdentifiedVehicleVehicleKBABody1 } from '../model/identifiedVehicleVehicleKBABody1';
import { IdentifiedVehicleVehicleKBABody2 } from '../model/identifiedVehicleVehicleKBABody2';
import { IdentifiedVehicleVehicleKBABody3 } from '../model/identifiedVehicleVehicleKBABody3';
import { IdentifiedVehicleVehicleMatriculationNumberBody } from '../model/identifiedVehicleVehicleMatriculationNumberBody';
import { IdentifiedVehicleVehicleMatriculationNumberBody1 } from '../model/identifiedVehicleVehicleMatriculationNumberBody1';
import { IdentifiedVehicleVehicleMatriculationNumberBody2 } from '../model/identifiedVehicleVehicleMatriculationNumberBody2';
import { IdentifiedVehicleVehicleMatriculationNumberBody3 } from '../model/identifiedVehicleVehicleMatriculationNumberBody3';
import { IdentifiedVehicleVehicleVinBody } from '../model/identifiedVehicleVehicleVinBody';
import { IdentifiedVehicleVehicleVinBody1 } from '../model/identifiedVehicleVehicleVinBody1';
import { IdentifiedVehicleVehicleVinBody2 } from '../model/identifiedVehicleVehicleVinBody2';
import { IdentifiedVehicleVehicleVinBody3 } from '../model/identifiedVehicleVehicleVinBody3';
import { UpdateIdentifiedVehicleEquipmentCommandHandlerResponse } from '../model/updateIdentifiedVehicleEquipmentCommandHandlerResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class IdentifiedVehicleService {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * AmsAction:POST/api/IdentifiedVehicle/AftermarketEquipment AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/IdentifiedVehicle/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAftermarketEquipment(body?: IdentifiedVehicleAftermarketEquipmentBody4, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public createAftermarketEquipment(body?: IdentifiedVehicleAftermarketEquipmentBody4, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public createAftermarketEquipment(body?: IdentifiedVehicleAftermarketEquipmentBody4, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public createAftermarketEquipment(body?: IdentifiedVehicleAftermarketEquipmentBody4, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommandHandlerResponse>(`${this.basePath}/api/IdentifiedVehicle/AftermarketEquipment`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:DELETE/api/IdentifiedVehicle/AftermarketEquipment/{identifiedVehicleId}/{soaCode} AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/IdentifiedVehicle/{identifiedVehicleId} 
     * @param identifiedVehicleId 
     * @param soaCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAftermarketEquipment(identifiedVehicleId: string, soaCode: string, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public deleteAftermarketEquipment(identifiedVehicleId: string, soaCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public deleteAftermarketEquipment(identifiedVehicleId: string, soaCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public deleteAftermarketEquipment(identifiedVehicleId: string, soaCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identifiedVehicleId === null || identifiedVehicleId === undefined) {
            throw new Error('Required parameter identifiedVehicleId was null or undefined when calling deleteAftermarketEquipment.');
        }

        if (soaCode === null || soaCode === undefined) {
            throw new Error('Required parameter soaCode was null or undefined when calling deleteAftermarketEquipment.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<CommandHandlerResponse>(`${this.basePath}/api/IdentifiedVehicle/AftermarketEquipment/${encodeURIComponent(String(identifiedVehicleId))}/${encodeURIComponent(String(soaCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/IdentifiedVehicle/{identifiedVehicleId} AmsResource:{country}/{business}/IdentifiedVehicle/{identifiedVehicleId} 
     * @param identifiedVehicleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIdentifiedVehicle(identifiedVehicleId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getIdentifiedVehicle(identifiedVehicleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getIdentifiedVehicle(identifiedVehicleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getIdentifiedVehicle(identifiedVehicleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identifiedVehicleId === null || identifiedVehicleId === undefined) {
            throw new Error('Required parameter identifiedVehicleId was null or undefined when calling getIdentifiedVehicle.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/IdentifiedVehicle/${encodeURIComponent(String(identifiedVehicleId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/IdentifiedVehicle/ResetEquipment AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/IdentifiedVehicle/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetEquipment(body?: IdentifiedVehicleResetEquipmentBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public resetEquipment(body?: IdentifiedVehicleResetEquipmentBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public resetEquipment(body?: IdentifiedVehicleResetEquipmentBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public resetEquipment(body?: IdentifiedVehicleResetEquipmentBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/IdentifiedVehicle/ResetEquipment`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/IdentifiedVehicle/AddBuidibilityData AmsResource:{country}/{business}/IdentifiedVehicle/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setBuidibilityData(body?: IdentifiedVehicleAddBuidibilityDataBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public setBuidibilityData(body?: IdentifiedVehicleAddBuidibilityDataBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public setBuidibilityData(body?: IdentifiedVehicleAddBuidibilityDataBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public setBuidibilityData(body?: IdentifiedVehicleAddBuidibilityDataBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/IdentifiedVehicle/AddBuidibilityData`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/IdentifiedVehicle/AftermarketEquipment AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/IdentifiedVehicle/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateVehicleAftermarketEquipment(body?: IdentifiedVehicleAftermarketEquipmentBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateVehicleAftermarketEquipment(body?: IdentifiedVehicleAftermarketEquipmentBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateVehicleAftermarketEquipment(body?: IdentifiedVehicleAftermarketEquipmentBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateVehicleAftermarketEquipment(body?: IdentifiedVehicleAftermarketEquipmentBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/IdentifiedVehicle/AftermarketEquipment`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/IdentifiedVehicle/Equipment AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/IdentifiedVehicle/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateVehicleEquipment(body?: IdentifiedVehicleEquipmentBody, observe?: 'body', reportProgress?: boolean): Observable<UpdateIdentifiedVehicleEquipmentCommandHandlerResponse>;
    public updateVehicleEquipment(body?: IdentifiedVehicleEquipmentBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdateIdentifiedVehicleEquipmentCommandHandlerResponse>>;
    public updateVehicleEquipment(body?: IdentifiedVehicleEquipmentBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdateIdentifiedVehicleEquipmentCommandHandlerResponse>>;
    public updateVehicleEquipment(body?: IdentifiedVehicleEquipmentBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<UpdateIdentifiedVehicleEquipmentCommandHandlerResponse>(`${this.basePath}/api/IdentifiedVehicle/Equipment`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/IdentifiedVehicle/Equipment/Color AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/IdentifiedVehicle/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateVehicleEquipmentColor(body?: EquipmentColorBody, observe?: 'body', reportProgress?: boolean): Observable<UpdateIdentifiedVehicleEquipmentCommandHandlerResponse>;
    public updateVehicleEquipmentColor(body?: EquipmentColorBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdateIdentifiedVehicleEquipmentCommandHandlerResponse>>;
    public updateVehicleEquipmentColor(body?: EquipmentColorBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdateIdentifiedVehicleEquipmentCommandHandlerResponse>>;
    public updateVehicleEquipmentColor(body?: EquipmentColorBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<UpdateIdentifiedVehicleEquipmentCommandHandlerResponse>(`${this.basePath}/api/IdentifiedVehicle/Equipment/Color`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/IdentifiedVehicle/FuelSubtypes AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/IdentifiedVehicle/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateVehicleFuelSubtypes(body?: IdentifiedVehicleFuelSubtypesBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateVehicleFuelSubtypes(body?: IdentifiedVehicleFuelSubtypesBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateVehicleFuelSubtypes(body?: IdentifiedVehicleFuelSubtypesBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateVehicleFuelSubtypes(body?: IdentifiedVehicleFuelSubtypesBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/IdentifiedVehicle/FuelSubtypes`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/IdentifiedVehicle/VehicleKBA AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/IdentifiedVehicle/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateVehicleKBA(body?: IdentifiedVehicleVehicleKBABody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateVehicleKBA(body?: IdentifiedVehicleVehicleKBABody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateVehicleKBA(body?: IdentifiedVehicleVehicleKBABody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateVehicleKBA(body?: IdentifiedVehicleVehicleKBABody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/IdentifiedVehicle/VehicleKBA`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/IdentifiedVehicle/VehicleMatriculationNumber AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/IdentifiedVehicle/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateVehicleMatriculationNumber(body?: IdentifiedVehicleVehicleMatriculationNumberBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateVehicleMatriculationNumber(body?: IdentifiedVehicleVehicleMatriculationNumberBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateVehicleMatriculationNumber(body?: IdentifiedVehicleVehicleMatriculationNumberBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateVehicleMatriculationNumber(body?: IdentifiedVehicleVehicleMatriculationNumberBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/IdentifiedVehicle/VehicleMatriculationNumber`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/IdentifiedVehicle/TechnicalData AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/IdentifiedVehicle/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateVehicleTechnicalData(body?: IdentifiedVehicleTechnicalDataBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateVehicleTechnicalData(body?: IdentifiedVehicleTechnicalDataBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateVehicleTechnicalData(body?: IdentifiedVehicleTechnicalDataBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateVehicleTechnicalData(body?: IdentifiedVehicleTechnicalDataBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/IdentifiedVehicle/TechnicalData`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:PUT/api/IdentifiedVehicle/VehicleVin AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/IdentifiedVehicle/{command.AggregateRootId} 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateVehicleVin(body?: IdentifiedVehicleVehicleVinBody, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateVehicleVin(body?: IdentifiedVehicleVehicleVinBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateVehicleVin(body?: IdentifiedVehicleVehicleVinBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateVehicleVin(body?: IdentifiedVehicleVehicleVinBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/IdentifiedVehicle/VehicleVin`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
