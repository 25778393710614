<vpfa-select
  [loading]="loading"
  [showGroups]="showGroups"
  [options]="options"
  [parentFormGroup]="formGroup"
  [fc]="formGroup.get('selectedValue')"
  [mode]="SelectMode.default"
  [translateLabel]="true"
  [placeholder]="placeholder"
  [enabledSearch]="true"
  [disabled]="disabled"
  [customOptionTemplate]="customOptionTemplate"
  [searchFilter]="searchFilter"
  [maskSensitiveData]="maskSensitiveData"
  (selected)="onSelected($event)"
  [useInfiniteScroll]="useInfiniteScroll"
  [useServerSideSearch]="useServerSideSearch"
  (scrollToBottom)="onLoadNextPage()"
  (onSearch)="onSearch.emit($event)"
></vpfa-select>
