import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiModule, BASE_PATH } from './api';
import { environment } from '@env/environment';

@NgModule({
  imports: [CommonModule, ApiModule],
  exports: [ApiModule],
  providers: [
    { provide: BASE_PATH, useValue: environment.apiGateway }
  ]
})
export class RestApiAdminModule {}
