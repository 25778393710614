<button
  [uId]="'openDataTableSettings'"
  nz-button
  nzType="link"
  nz-popover
  [nzPopoverContent]="settingsTemplate"
  nzPopoverPlacement="bottomRight"
  nzPopoverOverlayClassName="data-table-settings-ol"
  nzPopoverTrigger="click"
  [(nzPopoverVisible)]="visible"
  fxLayoutAlign="center center"
>
  <i class="zmdi zmdi-settings icon"></i>
  <span>{{ 'common.settings' | translate }}</span>
</button>
