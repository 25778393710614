import { NgModule, ModuleWithProviders, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@ngbracket/ngx-layout';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromLocale from './+state/locale.reducer';
import { LocaleEffects } from './+state/locale.effects';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { createTranslateLoader, PrefixingMissingTranslationHandler } from '@vpfa/shared/translate';
import { DeploymentVersionService, EnvironmentsService } from '@vpfa/environments/data';
import { NzI18nService } from 'ng-zorro-antd/i18n';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromLocale.LOCALE_FEATURE_KEY, fromLocale.reducer),
    EffectsModule.forFeature([LocaleEffects]),
    FlexModule,
  ],
})
export class LocaleModule {
  static forRoot(): ModuleWithProviders<LocaleModule> {
    return {
      ngModule: LocaleModule,
      providers: [
        TranslateModule.forRoot({
          useDefaultLang: false,
          missingTranslationHandler: {
            provide: MissingTranslationHandler,
            useClass: PrefixingMissingTranslationHandler,
          },
          loader: {
            provide: TranslateLoader,
            useFactory: createTranslateLoader,
            deps: [HttpClient, EnvironmentsService, DeploymentVersionService, NzI18nService],
          },
        }).providers,
      ],
    };
  }
}
