import { ImageSize, SORC_SIZE_PLACEHOLDER } from "@vpfa/utils";

export function convertImageSizeToSorc(requestUrl: string, size: ImageSize) {
    switch (size) {
    case ImageSize.Small:
      return requestUrl.replace(SORC_SIZE_PLACEHOLDER, 'thumbnail');

    case ImageSize.Medium:
    case ImageSize.Large:
        return requestUrl.replace(SORC_SIZE_PLACEHOLDER, 'medium');
  }
}