import { Component, EventEmitter } from '@angular/core';
import { DynamicComponentStack } from '@vpfa/shared/dynamic-components-stack';
import { ConfirmAdvertisedImagesFacade } from '../../+state/confirm-advertised-images.facade';

@Component({
  selector: 'vpfa-mobile-confirm-advertised-images-modal',
  templateUrl: './mobile-confirm-advertised-images-modal.component.html',
  styleUrls: ['./mobile-confirm-advertised-images-modal.component.css'],
})
export class MobileConfirmAdvertisedImagesModalComponent implements DynamicComponentStack {
  close = new EventEmitter();

  constructor(private confirmAdvertisedImagesFacade: ConfirmAdvertisedImagesFacade) {}

  public closeModal() {
    this.close.emit();
  }

  cancelUpload() {
    // INFO: Only for changing store data, it doesn't directly close mobile confirm modal
    this.confirmAdvertisedImagesFacade.closeModal();
  }
}
