import { Component, Input } from '@angular/core';
import { CaseStatus } from '@vpfa/rest-api/valuation';

@Component({
  selector: 'vpfa-stock-status',
  templateUrl: './stock-status.component.html',
  styleUrls: ['./stock-status.component.scss'],
})
export class StockStatusComponent {
  @Input() status: CaseStatus;

  getStatusText() {
    switch (this.status) {
      case CaseStatus.DueInStock:
        return 'stockStatus.dueInStock';
      case CaseStatus.InStock:
        return 'stockStatus.inStock';
    }
  }

  hasStockStatus() {
    return [CaseStatus.DueInStock, CaseStatus.InStock].find(v => v === this.status);
  }
}
