import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PermissionsFacade } from '../+state/permissions.facade';
import { isNil } from 'lodash';
import { PermissionsContext } from '../models/permissions-context';
import { environment } from '@env/environment';
@Injectable()
export class PermissionsInterceptor implements HttpInterceptor {
  private permissionsUrl = 'api/UserContext/permissions';

  constructor(private permissionsFacade: PermissionsFacade, private route: ActivatedRoute) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (
          request.url.startsWith(environment.apiGateway) &&
          err.status === 403 &&
          request.url.indexOf(this.permissionsUrl) === -1
        ) {
          const permissionsContext = this.findPermissionsContext();
          if (permissionsContext) {
            this.permissionsFacade.loadPermissionsByContext(permissionsContext);
          } else {
            this.permissionsFacade.loadPermissions();
          }
        }
        return throwError(err);
      })
    );
  }

  private findPermissionsContext(): PermissionsContext | null {
    let permissionsContext: PermissionsContext = null;
    let route = this.route;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const { params, data } = route.snapshot;

    if (this.isInAdminPanel(data)) {
      permissionsContext = {
        countryId: data.countryId ? data.countryId : params.countryId,
        businessId: params.businessId,
        branchId: params.branchId,
      };
    }
    return permissionsContext;
  }

  private isInAdminPanel(data: Data): boolean {
    return data && !isNil(data.adminLevel);
  }
}
