<ng-container *ngIf="!featureConfig?.hideInsuranceInformationSection">
  <ng-container *vpfaResponsiveIf="'mobile'">
    <vpfa-mobile-subtitle [title]="'vehicleFullTechnicalData.insuranceData.label' | translate"></vpfa-mobile-subtitle>
  </ng-container>
  <ng-container *vpfaResponsiveIf="'web'">
    <div class="mt">
      <vpfa-expanding-header
        [text]="'vehicleFullTechnicalData.insuranceData.label' | translate"
      ></vpfa-expanding-header>
    </div>
  </ng-container>

  <div
    [fxLayout]="'row wrap'"
    fxLayoutGap.gt-mv="70px grid"
    fxLayout.lt-mv="column"
    fxLayoutGap.lt-mv="10px"
    ngClass.gt-mv="section"
  >
    <vpfa-vehicle-technical-data-single-field
      *ngIf="featureConfig.showInsuranceGroup"
      [label]="'vehicleFullTechnicalData.insuranceData.insuranceGroup' | translate"
      [value]="technicalDataInsurance?.classification"
    ></vpfa-vehicle-technical-data-single-field>

    <vpfa-vehicle-technical-data-single-field
      *ngIf="!featureConfig.hideLiabilityInsuranceClass"
      [label]="'vehicleFullTechnicalData.insuranceData.liabilityInsuranceClass' | translate"
      [value]="technicalDataInsurance?.liabilityInsuranceClass"
    ></vpfa-vehicle-technical-data-single-field>

    <vpfa-vehicle-technical-data-single-field
      *ngIf="!featureConfig.hidePartialCover"
      [label]="'vehicleFullTechnicalData.insuranceData.partialCoverInsuranceClass' | translate"
      [value]="technicalDataInsurance?.partialCoverInsuranceClass"
    ></vpfa-vehicle-technical-data-single-field>

    <vpfa-vehicle-technical-data-single-field
      *ngIf="!featureConfig.hideComprehensiveCover"
      [label]="'vehicleFullTechnicalData.insuranceData.fullComprehensiveClass' | translate"
      [value]="technicalDataInsurance?.fullComprehensiveClass"
    ></vpfa-vehicle-technical-data-single-field>

    <vpfa-vehicle-technical-data-single-field
      *ngIf="!featureConfig.hideRentalCarGroup"
      [label]="'vehicleFullTechnicalData.insuranceData.rentalCarClass' | translate"
      [value]="technicalDataInsurance?.rentalCarClass"
    ></vpfa-vehicle-technical-data-single-field>

    <vpfa-vehicle-technical-data-single-field
      *ngIf="!featureConfig.hideNonRentalMaintenanceCosts"
      [label]="
        ('vehicleFullTechnicalData.insuranceData.holdingCosts' | translate) +
        (technicalDataInsurance?.holdingCosts | unitValue)
      "
      [value]="technicalDataInsurance?.holdingCosts?.value | numberLocale: '1.2-2'"
    ></vpfa-vehicle-technical-data-single-field>

    <vpfa-vehicle-technical-data-single-field
      *ngIf="!featureConfig.hideRentalMaintenanceCosts"
      [label]="
        ('vehicleFullTechnicalData.insuranceData.holdingCostsRentalCars' | translate) +
        (technicalDataInsurance?.holdingCostsRentalCars | unitValue)
      "
      [value]="technicalDataInsurance?.holdingCostsRentalCars?.value | numberLocale: '1.2-2'"
    ></vpfa-vehicle-technical-data-single-field>

    <vpfa-vehicle-technical-data-single-field
      *ngIf="!featureConfig.hidePersonalCosts"
      [label]="
        ('vehicleFullTechnicalData.insuranceData.savedCostsRentalCars' | translate) +
        (technicalDataInsurance?.savedCostsRentalCars | unitValue)
      "
      [value]="technicalDataInsurance?.savedCostsRentalCars?.value | numberLocale: '1.2-2'"
    ></vpfa-vehicle-technical-data-single-field>
  </div>
</ng-container>

<!-- Compensation for loss of use -->
<ng-container *ngIf="!featureConfig.hideCompensationForLossOfUse">
  <ng-container *vpfaResponsiveIf="'mobile'">
    <vpfa-mobile-subtitle
      [title]="'vehicleFullTechnicalData.repairTimeCompensation.label' | translate"
    ></vpfa-mobile-subtitle>
  </ng-container>
  <ng-container *vpfaResponsiveIf="'web'">
    <div class="mt">
      <vpfa-expanding-header
        [text]="'vehicleFullTechnicalData.repairTimeCompensation.label' | translate"
      ></vpfa-expanding-header>
    </div>
  </ng-container>

  <div fxLayout.gt-mv="row wrap" fxLayout.lt-mv="column" fxLayoutGap.lt-mv="10px">
    <vpfa-vehicle-technical-data-single-field
      [label]="
        ('vehicleFullTechnicalData.repairTimeCompensation.repairTimeCompensation' | translate) +
        (technicalDataInsurance?.repairTimeCompensation | unitValue)
      "
      [value]="technicalDataInsurance?.repairTimeCompensation?.value | numberLocale: '1.2-2'"
    ></vpfa-vehicle-technical-data-single-field>

    <vpfa-vehicle-technical-data-single-field
      [label]="
        ('vehicleFullTechnicalData.repairTimeCompensation.repairTimeCompensationGt5' | translate) +
        (technicalDataInsurance?.repairTimeCompensationGt5 | unitValue)
      "
      [value]="technicalDataInsurance?.repairTimeCompensationGt5?.value | numberLocale: '1.2-2'"
    ></vpfa-vehicle-technical-data-single-field>

    <vpfa-vehicle-technical-data-single-field
      [label]="
        ('vehicleFullTechnicalData.repairTimeCompensation.repairTimeCompensationGt10' | translate) +
        (technicalDataInsurance?.repairTimeCompensationGt10 | unitValue)
      "
      [value]="technicalDataInsurance?.repairTimeCompensationGt10?.value | numberLocale: '1.2-2'"
    ></vpfa-vehicle-technical-data-single-field>
  </div>
</ng-container>
