/**
 * Vehicle Insurance Claiming Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 

export enum ReceiptType {

      ReceivedInitialReportTriggeredByDamageReportCalculationEtc = 1,
      InitialNotificationReceivedRecipientNotResponsible = 2,
      TheProcessIsComplete = 3,
      OrderAccepted = 4,
      JobRefused = 5,
      CancellationAccepted = 6,
      OrderAcceptedAndForwarded = 7,
      EmergencyCallBreakdownMessageReceivedHelpIsInitiated = 20,
      EmergencyCallBreakdownNotificationReceivedNoHelpPossible = 21,
      EmergencyAccidentReportReceivedHelpIsInitiated = 30,
      EmergencyCallAccidentReportReceivedNoHelpPossible = 31,
      MiscellaneousCommentaryAdded = 41,
      ReservationsAreNotPossibleBecauseTheRentalCompanyIsFullyBooked = 51,
      ReservationIsNotPossibleBecauseTheRequestedRentalGroupIsNotAvailable = 52,
      ReservationIsNotPossibleBecauseSpecialEquipmentIsNotAvailable = 53,
      ReservationIsNotPossibleBecauseRentalCarsAreNotAvailableAtTheRequestedTime = 54,
      CoverageOrLiabilityStillUnclear = 61,
      CoverageOrLiabilityNotGiven = 62,

};
