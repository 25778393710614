import { Validators, AbstractControl, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { isNil } from 'lodash';

export const vinLengthValidatorKey = 'vinLength';

export const VIN_LENGTH = 17;

export const vinLengthValidator: ValidatorFn = (control: AbstractControl) => {
  const validateErrors = Validators.minLength(VIN_LENGTH)(control);
  if (isNil(validateErrors)) {
    return null;
  }
  return {
    [vinLengthValidatorKey]: true,
  };
};
