/**
 * Public SSM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0-private
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { DataSetTypeDto } from '../model/dataSetTypeDto';
import { EditorUrlDto } from '../model/editorUrlDto';
import { PdfDto } from '../model/pdfDto';
import { PdfTemplateListItemDto } from '../model/pdfTemplateListItemDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PdfTemplateViewService {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * AmsAction:GET/api/PdfTemplateView/generate/{caseId}/{pdfTemplateId} AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/pdfTemplate/{caseId}/{pdfTemplateId} 
     * @param caseId 
     * @param pdfTemplateId 
     * @param repairSessionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generatePdfAsync(caseId: string, pdfTemplateId: string, repairSessionId?: string, observe?: 'body', reportProgress?: boolean): Observable<PdfDto>;
    public generatePdfAsync(caseId: string, pdfTemplateId: string, repairSessionId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PdfDto>>;
    public generatePdfAsync(caseId: string, pdfTemplateId: string, repairSessionId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PdfDto>>;
    public generatePdfAsync(caseId: string, pdfTemplateId: string, repairSessionId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling generatePdfAsync.');
        }

        if (pdfTemplateId === null || pdfTemplateId === undefined) {
            throw new Error('Required parameter pdfTemplateId was null or undefined when calling generatePdfAsync.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (repairSessionId !== undefined && repairSessionId !== null) {
          queryParameters = queryParameters.set('repairSessionId', <any>repairSessionId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PdfDto>(`${this.basePath}/api/PdfTemplateView/generate/${encodeURIComponent(String(caseId))}/${encodeURIComponent(String(pdfTemplateId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/PdfTemplateView/generate/{caseId}/sharedStock AmsOptionalAction:SeeValuationsOfOthersUsers AmsResource:{country}/{business}/pdfTemplate/{caseId}/sharedStock 
     * @param caseId 
     * @param repairSessionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateSharedStockPdfAsync(caseId: string, repairSessionId?: string, observe?: 'body', reportProgress?: boolean): Observable<PdfDto>;
    public generateSharedStockPdfAsync(caseId: string, repairSessionId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PdfDto>>;
    public generateSharedStockPdfAsync(caseId: string, repairSessionId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PdfDto>>;
    public generateSharedStockPdfAsync(caseId: string, repairSessionId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (caseId === null || caseId === undefined) {
            throw new Error('Required parameter caseId was null or undefined when calling generateSharedStockPdfAsync.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (repairSessionId !== undefined && repairSessionId !== null) {
          queryParameters = queryParameters.set('repairSessionId', <any>repairSessionId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PdfDto>(`${this.basePath}/api/PdfTemplateView/generate/${encodeURIComponent(String(caseId))}/sharedStock`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param countryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAdminDataSetType(countryId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DataSetTypeDto>>;
    public getAdminDataSetType(countryId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DataSetTypeDto>>>;
    public getAdminDataSetType(countryId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DataSetTypeDto>>>;
    public getAdminDataSetType(countryId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (countryId === null || countryId === undefined) {
            throw new Error('Required parameter countryId was null or undefined when calling getAdminDataSetType.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<DataSetTypeDto>>(`${this.basePath}/api/PdfTemplateView/admin/${encodeURIComponent(String(countryId))}/dataSetTypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:GET/api/PdfTemplateView/admin/editor/{pdfTemplateId} AmsResource:{country}/{business}/_* 
     * @param pdfTemplateId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAdminEditorUrlAsync(pdfTemplateId: string, observe?: 'body', reportProgress?: boolean): Observable<EditorUrlDto>;
    public getAdminEditorUrlAsync(pdfTemplateId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EditorUrlDto>>;
    public getAdminEditorUrlAsync(pdfTemplateId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EditorUrlDto>>;
    public getAdminEditorUrlAsync(pdfTemplateId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pdfTemplateId === null || pdfTemplateId === undefined) {
            throw new Error('Required parameter pdfTemplateId was null or undefined when calling getAdminEditorUrlAsync.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<EditorUrlDto>(`${this.basePath}/api/PdfTemplateView/admin/editor/${encodeURIComponent(String(pdfTemplateId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:GET/api/PdfTemplateView/admin/editor/stockShare/{countryId} AmsResource:{country}/_* 
     * @param countryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAdminStockShareEditorUrlAsync(countryId: string, observe?: 'body', reportProgress?: boolean): Observable<EditorUrlDto>;
    public getAdminStockShareEditorUrlAsync(countryId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EditorUrlDto>>;
    public getAdminStockShareEditorUrlAsync(countryId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EditorUrlDto>>;
    public getAdminStockShareEditorUrlAsync(countryId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (countryId === null || countryId === undefined) {
            throw new Error('Required parameter countryId was null or undefined when calling getAdminStockShareEditorUrlAsync.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<EditorUrlDto>(`${this.basePath}/api/PdfTemplateView/admin/editor/stockShare/${encodeURIComponent(String(countryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDataSetType(observe?: 'body', reportProgress?: boolean): Observable<Array<DataSetTypeDto>>;
    public getDataSetType(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DataSetTypeDto>>>;
    public getDataSetType(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DataSetTypeDto>>>;
    public getDataSetType(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<DataSetTypeDto>>(`${this.basePath}/api/PdfTemplateView/dataSetTypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:GET/api/PdfTemplateView/editor/{pdfTemplateId} AmsResource:{country}/{business}/_* 
     * @param pdfTemplateId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEditorUrlAsync(pdfTemplateId: string, observe?: 'body', reportProgress?: boolean): Observable<EditorUrlDto>;
    public getEditorUrlAsync(pdfTemplateId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EditorUrlDto>>;
    public getEditorUrlAsync(pdfTemplateId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EditorUrlDto>>;
    public getEditorUrlAsync(pdfTemplateId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pdfTemplateId === null || pdfTemplateId === undefined) {
            throw new Error('Required parameter pdfTemplateId was null or undefined when calling getEditorUrlAsync.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<EditorUrlDto>(`${this.basePath}/api/PdfTemplateView/editor/${encodeURIComponent(String(pdfTemplateId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:GET/api/PdfTemplateView/list AmsResource:{country}/{business}/_* 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listAsync(observe?: 'body', reportProgress?: boolean): Observable<Array<PdfTemplateListItemDto>>;
    public listAsync(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PdfTemplateListItemDto>>>;
    public listAsync(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PdfTemplateListItemDto>>>;
    public listAsync(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<PdfTemplateListItemDto>>(`${this.basePath}/api/PdfTemplateView/list`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:GET/api/PdfTemplateView/admin/{countryId}/{businessId}/list AmsResource:{country}/{business}/_* 
     * @param countryId 
     * @param businessId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listBusinessPdfTemplatesAsync(countryId: string, businessId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PdfTemplateListItemDto>>;
    public listBusinessPdfTemplatesAsync(countryId: string, businessId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PdfTemplateListItemDto>>>;
    public listBusinessPdfTemplatesAsync(countryId: string, businessId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PdfTemplateListItemDto>>>;
    public listBusinessPdfTemplatesAsync(countryId: string, businessId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (countryId === null || countryId === undefined) {
            throw new Error('Required parameter countryId was null or undefined when calling listBusinessPdfTemplatesAsync.');
        }

        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling listBusinessPdfTemplatesAsync.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<PdfTemplateListItemDto>>(`${this.basePath}/api/PdfTemplateView/admin/${encodeURIComponent(String(countryId))}/${encodeURIComponent(String(businessId))}/list`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:GET/api/PdfTemplateView/admin/{countryId}/list AmsResource:{country}/_* 
     * @param countryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listCountryPdfTemplatesAsync(countryId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PdfTemplateListItemDto>>;
    public listCountryPdfTemplatesAsync(countryId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PdfTemplateListItemDto>>>;
    public listCountryPdfTemplatesAsync(countryId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PdfTemplateListItemDto>>>;
    public listCountryPdfTemplatesAsync(countryId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (countryId === null || countryId === undefined) {
            throw new Error('Required parameter countryId was null or undefined when calling listCountryPdfTemplatesAsync.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<PdfTemplateListItemDto>>(`${this.basePath}/api/PdfTemplateView/admin/${encodeURIComponent(String(countryId))}/list`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
