import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { isNil } from 'lodash';

@Component({
  selector: 'vpfa-mobile-field-validation-container',
  templateUrl: './mobile-field-validation-container.component.html',
  styleUrls: ['./mobile-field-validation-container.component.scss'],
})
export class MobileFieldValidationContainerComponent {
  private _validationControl: AbstractControl;

  @Input()
  set validationControl(validationControl: AbstractControl) {
    this._validationControl = validationControl;
  }

  @Input() errorMessages: { error: string; errorMsg: string }[] = [];
  @Input() errorMessagePrefix: string;
  @Input() showInvalidBeforeDirty = false;

  get validationControl() {
    return this._validationControl;
  }

  get showInvalidIndicator(): boolean {
    if (this.showInvalidBeforeDirty) {
      return this._validationControl.invalid;
    } else {
      return this._validationControl.invalid && this._validationControl.dirty;
    }
  }

  get errorMessage(): string {
    if (
      isNil(this.validationControl) ||
      isNil(this.validationControl.errors) ||
      isNil(this.errorMessages) ||
      this.errorMessages.length === 0
    ) {
      return '';
    }

    const errorMessagesFilteredByPrefix = this.errorMessagePrefix
      ? this.errorMessages
          .filter(entry => entry.error.indexOf(`${this.errorMessagePrefix}.`) !== -1)
          .map(entry => {
            return {
              ...entry,
              error: entry.error.split(`${this.errorMessagePrefix}.`)[1],
            };
          })
      : this.errorMessages;

    for (const entry of errorMessagesFilteredByPrefix) {
      if (this.validationControl.errors[entry.error]) {
        return entry.errorMsg;
      }
    }

    return '';
  }
}
