import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BranchListItemDto } from '@vpfa/rest-api/admin';
import { DICTIONARIES_FEATURE_KEY, DictionariesState, DictionaryState } from './dictionaries.reducer';
import { ColorDictionaryItemDto, KeyTranslationDto } from '@vpfa/rest-api/valuation';
import { VehicleTypeDto } from '@vpfa/rest-api/identification';
import { chain, isNil, partition } from 'lodash';

const getDictionariesState = createFeatureSelector<DictionariesState>(DICTIONARIES_FEATURE_KEY);

const getBranchState = createSelector(getDictionariesState, (state: DictionariesState) => {
  return state.branchList;
});

const getVehicleGroupState = createSelector(getDictionariesState, (state: DictionariesState) => {
  return state.vehicleGroupList;
});

const getVehicleTypeState = createSelector(getDictionariesState, (state: DictionariesState) => {
  return state.vehicleTypesList;
});

const getAdminPaintColourState = createSelector(getDictionariesState, (state: DictionariesState) => {
  return state.adminPaintColourList;
});

const getPaintColourState = createSelector(getDictionariesState, (state: DictionariesState) => {
  return state.paintColourList;
});

const getBranchList = createSelector(getBranchState, (state: DictionaryState<BranchListItemDto>) => state.list);

const getBranchListSelectOptions = createSelector(getBranchList, (state: BranchListItemDto[]) =>
  state.map(branch => {
    return { name: branch.branchName, value: branch.branchId };
  })
);

const getBranchLoaded = createSelector(getBranchState, (state: DictionaryState<BranchListItemDto>) => state.loaded);

const getVehicleGroupList = createSelector(
  getVehicleGroupState,
  (state: DictionaryState<KeyTranslationDto>) => state.list
);

const getVehicleGroupLoaded = createSelector(
  getVehicleGroupState,
  (state: DictionaryState<KeyTranslationDto>) => state.loaded
);

const getVehicleTypeList = createSelector(getVehicleTypeState, (state: DictionaryState<VehicleTypeDto>) =>
  state.list.filter(type => isNil(type.combinedVehicleTypeCodes))
);

const getVehicleTypeListLoaded = createSelector(
  getVehicleTypeState,
  (state: DictionaryState<VehicleTypeDto>) => state.loaded
);

const getVehicleCombinedTypeList = createSelector(getVehicleTypeState, (state: DictionaryState<VehicleTypeDto>) => {
  const [combinedVehicleTypes, baseTypes] = partition(state.list, type => !isNil(type.combinedVehicleTypeCodes));
  const combinedTypesChildren = combinedVehicleTypes.map(type => type.combinedVehicleTypeCodes);
  const usedBaseTypeCodes = chain(combinedTypesChildren).flatMap().uniq().value();
  const unUsedBaseTypes = baseTypes.filter(type => usedBaseTypeCodes.indexOf(type.code) === -1);

  return [...combinedVehicleTypes, ...unUsedBaseTypes];
});

const getAdminPaintColourList = createSelector(getAdminPaintColourState, state => state.list);

const getPaintColourList = createSelector(getPaintColourState, state => state.list);
const getPaintColourListLoaded = createSelector(getPaintColourState, state => state.loaded);

const getPaintColourListOptions = createSelector(getPaintColourList, state =>
  state.map(paintColour => ({ name: paintColour.name, value: paintColour }))
);

const getPaintColour = createSelector(getPaintColourList, (state: ColorDictionaryItemDto[], props) => {
  return state.find(paintColour => paintColour.id === props.id)
    ? state.find(paintColour => paintColour.id === props.id).name
    : null;
});

const getEmissionClassState = createSelector(getDictionariesState, (state: DictionariesState) => {
  return state.emissionClassList;
});

const getEmissionClassList = createSelector(getEmissionClassState, state => state.list);

const getEmissionClassListOptions = createSelector(getEmissionClassList, state =>
  state.map(emissionClass => ({ name: emissionClass.name, value: emissionClass.id }))
);

const getEmissionClass = createSelector(getEmissionClassList, (state, props) => {
  return state.find(emissionClass => emissionClass.id === props.id)
    ? state.find(emissionClass => emissionClass.id === props.id).name
    : null;
});

const getCO2EfficiencyClassState = createSelector(getDictionariesState, (state: DictionariesState) => {
  return state.cO2EfficiencyClassList;
});

const getCO2EfficiencyClassList = createSelector(getCO2EfficiencyClassState, state => state.list);

const getCO2EfficiencyClassListOptions = createSelector(getCO2EfficiencyClassList, state =>
  state.map(cO2EfficiencyClass => ({ name: cO2EfficiencyClass.name, value: cO2EfficiencyClass.id }))
);

const getCO2EfficiencyClass = createSelector(getCO2EfficiencyClassList, (state, props) => {
  return state.find(co2EfficiencyClass => co2EfficiencyClass.id === props.id)
    ? state.find(co2EfficiencyClass => co2EfficiencyClass.id === props.id).name
    : null;
});

export const dictionariesQuery = {
  getBranchList,
  getBranchListSelectOptions,
  getBranchLoaded,
  getVehicleGroupList,
  getVehicleGroupLoaded,
  getVehicleTypeList,
  getVehicleTypeListLoaded,
  getVehicleCombinedTypeList,
  getAdminPaintColourList,
  getPaintColourList,
  getPaintColourListLoaded,
  getPaintColourListOptions,
  getPaintColour,
  getEmissionClassList,
  getEmissionClassListOptions,
  getEmissionClass,
  getCO2EfficiencyClassListOptions,
  getCO2EfficiencyClass,
};
