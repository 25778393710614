import { Component, Input } from '@angular/core';

@Component({
  selector: 'vpfa-mobile-clickable',
  templateUrl: './mobile-clickable.component.html',
  styleUrls: ['./mobile-clickable.component.scss'],
})
export class MobileClickableComponent {
  @Input() enableMargin = false;
  @Input() disabled = false;
}
