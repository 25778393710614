<vpfa-autocomplete
  *ngIf="!disabled"
  [placeholder]="
    (customerListOptions$ | async)?.length
      ? ('customers.addRemoveCustomerList.' + selectLabel | translate)
      : ('customers.addRemoveCustomerList.noCustomers' | translate)
  "
  [loading]="isLoadingData"
  [showGroups]="showGroups"
  [options]="showGroups ? (groupedCustomerListOptions$ | async) : (customerListOptions$ | async)"
  [customOptionTemplate]="customOption"
  [searchFilter]="customerSearchFilter"
  [useInfiniteScroll]="useInfiniteScroll"
  [useServerSideSearch]="true"
  [maskSensitiveData]="true"
  (loadNextPage)="onLoadNextPage()"
  (onSearch)="onSearch.emit($event)"
  (selected)="itemSelected($event)"
></vpfa-autocomplete>

<div *ngIf="!isBranchSelected()" class="customer-list-container">
  <vpfa-list
    [items]="selectedCustomersList$ | async"
    [renderItem]="buyerListItem"
    [loading]="isLoadingCaseOwners"
    [animate]="true"
    maskSensitiveData
  ></vpfa-list>
</div>

<ng-template #buyerListItem let-item let-index="index">
  <div class="buyers-list-item d-flex flex-row justify-content-between align-items-center gap-5px" maskSensitiveData>
    <div [uId]="idPrefix + 'selectedCustomerListFirstName' + index" class="flex-width-20" [title]="item.firstName | lT">
      {{ item.firstName | lT }}
    </div>
    <div
      [uId]="idPrefix + 'selectedCustomerListLastName' + index"
      class="bold flex-width-20"
      [title]="item.lastName | lT"
    >
      {{ item.lastName | lT }}
    </div>
    <div
      [uId]="idPrefix + 'selectedCustomerListAddress' + index"
      class="flex-width-35"
      [title]="getStreetAndAddress(item) | lT"
    >
      {{ getStreetAndAddress(item) | lT }}
    </div>
    <div [uId]="idPrefix + 'selectedCustomerListCity' + index" class="flex-width-19" [title]="item.city | lT">
      {{ item.city | lT }}
    </div>
    <div style="min-width: 60px" class="d-flex flex-width-8 justify-content-end">
      <span
        *ngIf="!disabled && showPromoteControls && index === 0"
        class="ssm-icon-btn main-buyer-icon"
        [title]="mainOwnerTooltipText | translate"
        nz-icon
        nzType="star"
        nzTheme="fill"
      ></span>

      <button
        *ngIf="!disabled && showPromoteControls && index !== 0"
        class="ssm-icon-btn promote-to-main-buyer-btn"
        [title]="promoteToMainOwnerTooltipText | translate"
        (click)="promoteToMainBuyer(item?.id)"
      >
        <span nz-icon nzType="arrow-up" nzTheme="outline"></span>
      </button>
      <vpfa-action-button-with-confirmation
        *ngIf="!disabled"
        class="remove-client-from-list"
        icon="delete"
        [popconfirmMainText]="'customers.addRemoveCustomerList.areYouSure'"
        (onConfirm)="removeCustomer(item)"
      ></vpfa-action-button-with-confirmation>
    </div>
  </div>
</ng-template>

<ng-template #customOption let-label="label" let-option="option" let-additional="additional" let-index="index">
  <div
    *ngIf="isCustomer(additional)"
    data-dd-action-name="customerDropdownOption"
    maskSensitiveData
    class="add-remove-customer-list-customer-select-option d-flex flex-row justify-content-between gap-2px"
  >
    <div class="flex-width-20 customer-first-name" [title]="additional.firstName | lT">
      {{ additional.firstName | lT }}
    </div>
    <div class="bold flex-width-20 customer-last-name" [title]="additional.lastName | lT">
      {{ additional.lastName | lT }}
    </div>
    <div class="grey flex-width-40 customer-address" [title]="getStreetAndAddress(additional) | lT">
      {{ getStreetAndAddress(additional) | lT }}
    </div>
    <div class="grey flex-width-20 customer-city" [title]="additional.city | lT">{{ additional.city | lT }}</div>
  </div>
  <div class="customer-branch-name" *ngIf="isBranch(additional)">
    {{ additional.branchName | lT }}
  </div>
</ng-template>
