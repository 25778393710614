import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { EquipmentDto, EquipmentType } from '@vpfa/rest-api/valuation';

@Component({
  selector: 'vpfa-mobile-equipment-list-item',
  templateUrl: './mobile-equipment-list-item.component.html',
  styleUrls: ['./mobile-equipment-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileEquipmentListItemComponent implements AfterViewChecked {
  private _expandedItems = [];
  @Input() equipmentData: EquipmentDto[];

  @ViewChild('equipmentList') elementView: ElementRef;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  show(idx: number) {
    if (!this._expandedItems.includes(idx)) {
      this._expandedItems.push(idx);
    } else {
      this._expandedItems.splice(this._expandedItems.indexOf(idx), 1);
    }
  }

  isExpanded(idx: number) {
    return this._expandedItems.includes(idx);
  }

  isOneLine(idx: number) {
    if (
      this.elementView &&
      this.elementView.nativeElement &&
      this.elementView.nativeElement.getElementsByClassName('description')[idx] &&
      this.elementView.nativeElement.getElementsByClassName('price')[idx]
    ) {
      const el = this.elementView.nativeElement;
      return (
        el.getElementsByClassName('description')[idx].offsetHeight < 20 &&
        el.getElementsByClassName('description')[idx].offsetWidth +
          el.getElementsByClassName('price')[idx].offsetWidth <
          el.offsetWidth
      );
    }
  }

  isLongDescription(idx: number) {
    if (
      this.elementView &&
      this.elementView.nativeElement &&
      this.elementView.nativeElement.getElementsByClassName('description')[idx]
    ) {
      return this.elementView.nativeElement.getElementsByClassName('description')[idx].offsetHeight > 55;
    }
  }

  isPackageContentAvailable(equipment: EquipmentDto) {
    return equipment?.equipmentType === EquipmentType.Package && equipment?.content?.length > 0;
  }
}
