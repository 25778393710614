import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { SubjectDto } from '@vpfa/rest-api/valuation';

@Component({
  selector: 'vpfa-subject-renderer',
  templateUrl: './subject-renderer.component.html',
  styleUrls: ['./subject-renderer.component.scss']
})
export class SubjectRendererComponent implements ICellRendererAngularComp {
  subject: SubjectDto;

  agInit(params: ICellRendererParams): void {
    this.subject = params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
