import { DateTime } from "luxon";

export function dateToLocalISO(date: Date | null, time = true): string | null {
  let isoDate: string | null = null;

  if (date) {
    const offset = date.getTimezoneOffset() * 60000; // offset is in minutes, converting to milliseconds
    const newDate = new Date(date.getTime() - offset);
    isoDate = newDate.toISOString();
    if (!time) {
      isoDate = isoDate.split('T')[0];
    }
  }

  return isoDate;
}

export function ISOToDate(iso: string | null): Date | null {
  let date: Date | null = null;

  if (iso) {
    iso = DateTime.fromISO(iso).toISODate();
    const localDate = new Date(iso);
    const offset = localDate.getTimezoneOffset() * 60000; // offset is in minutes, converting to milliseconds
    date = new Date(localDate.getTime() + offset);
  }

  return date;
}

export function getOffsetFromDateTime(date: string | null): string {
  let sign = '-';
  let offset = '0000';

  if (date) {
      const time = date.split('T')[1];
      let offsetArray = time.split(sign);

      if (offsetArray.length > 1) {
        offset = offsetArray[1];  
      } else {
        sign = '+';
        offsetArray = time.split(sign);
        offset = offsetArray.length > 1 ? offsetArray[1] : offset;
      }
  }

  offset = `${sign}${offset.replace(':', '')}`;
  return offset;
}

export function getCurrentDateTime(timezone: string): DateTime {
  return timezone ? DateTime.fromJSDate(new Date()).setZone(timezone) :  DateTime.fromJSDate(new Date());
}

export function getCurrentDate(timezone: string): string {
  return getCurrentDateTime(timezone).toString().split('T')[0];
}