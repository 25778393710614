import { UserContext } from '@vpfa/rest-api/admin';
import { CaseSummaryDto, EquipmentDto, EquipmentType } from '@vpfa/rest-api/valuation';
import { OwnVehicleParams } from 'liveRetail-lib';
import { isNil } from 'lodash';

export function getOwnVehicleParams(
  caseData: CaseSummaryDto,
  branchZipCode: string,
  userContext: UserContext,
  caseAskingPrice: number,
): OwnVehicleParams {
  if (!caseData || !branchZipCode || !userContext) {
    return null;
  }

  const wholeEquipment = caseData.vehicle.equipments;

  const enabledOptionalAndUnavailableEquipment = wholeEquipment
    .filter(isEnabledOptionalOrUnavailableEquipmentNotInPackage)
    .map(eqp => ({
      optionPrice: eqp.priceBrutto,
      esacoCodes: eqp.esacos.map(esaco => esaco.id),
    }));

  const enabledPackageEquipmentWithContentEq = wholeEquipment.filter(getEnabledPackageEquipment).map(eqp => {
    const esacosList = eqp.esacos.map(esaco => esaco.id);

    includeEsacosFromEquipmentContent(eqp, wholeEquipment, esacosList);

    return {
      optionPrice: eqp.priceBrutto,
      esacoCodes: esacosList,
    };
  });

  return {
    ownVehicle: {
      askingPrice: caseAskingPrice,
      isoCountry: userContext.countryCode,
      isoLanguage: userContext.uiLanguage.toLowerCase(),
      vehicleType: caseData.vehicle.vehicleType.toString(),
      odometer: caseData.mileage.value,
      regDate: new Date(caseData.registrationDate).toISOString(),
      natCode: parseInt(caseData.vehicle.natcode),
      zipcode: branchZipCode,
      optionList: [...enabledOptionalAndUnavailableEquipment, ...enabledPackageEquipmentWithContentEq],
    },
  };
}

/**
 * Add esacos from `currentEquipment.content` into `esacosList`
 */
function includeEsacosFromEquipmentContent(
  currentEquipment: EquipmentDto,
  wholeEquipment: EquipmentDto[],
  esacosList: string[],
) {
  if (isNil(currentEquipment.content) || currentEquipment.content.length === 0) {
    return;
  }

  for (const contentEqp of currentEquipment.content) {
    const contentEquipmentData = wholeEquipment?.find(x => x.soaCode === contentEqp.soaCode);

    const contentEsacosList = contentEquipmentData?.esacos.map(x => x.id);

    if (contentEquipmentData?.isEnabled && contentEsacosList?.length > 0) {
      esacosList.push(...contentEsacosList);
    }
  }
}

/**
 * Checks if `currentEquipment` (is enabled) and (is not a content of any package) and
 * (is type of Optional or Unavailable)
 */
function isEnabledOptionalOrUnavailableEquipmentNotInPackage(
  currentEquipment: EquipmentDto,
  index: number,
  wholeEquipment: EquipmentDto[],
) {
  let isInPackageContent = false;

  for (const equipment of wholeEquipment) {
    if (equipment.content?.find(x => x.soaCode === currentEquipment.soaCode)) {
      isInPackageContent = true;
    }
  }

  return (
    currentEquipment.isEnabled &&
    isInPackageContent === false &&
    (currentEquipment.equipmentType === EquipmentType.Optional ||
      currentEquipment.equipmentType === EquipmentType.Unavailable)
  );
}

function getEnabledPackageEquipment(value: EquipmentDto) {
  return value.isEnabled && value.equipmentType === EquipmentType.Package;
}
