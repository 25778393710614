import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'vpfa-mobile-backend-pagination-infinite-list',
  templateUrl: './mobile-backend-pagination-infinite-list.component.html',
  styleUrls: ['./mobile-backend-pagination-infinite-list.component.scss'],
})
export class MobileBackendPaginationInfiniteListComponent {
  @Input() endElement = 9;
  @Input() startElement = 0;
  @Input() scrollStep = 5;
  @Input() set list(list: any[]) {
    this._busy = false;
    this.filteredList = list;
    this.updateList();
  }
  @Input() throttle = 4;
  @Input() elementTemplate: TemplateRef<any>;
  @Input() isLoading = false;
  @Input() displayEmptyIfNoData = true;
  @Input() totalNumberOfElements = null;

  @Output() loadNextPage = new EventEmitter();

  elements: any[];
  private filteredList: any[];
  private _busy = false;

  onScroll() {
    const allElementsLoaded =
      this.totalNumberOfElements > 0 && this.totalNumberOfElements === this.filteredList?.length;

    if (this._busy || allElementsLoaded) {
      return;
    }

    this.endElement = Number(this.endElement) + Number(this.scrollStep);
    this.loadNextPage.emit();
    this._busy = true;
  }

  private updateList() {
    this.elements = this.filteredList
      ? this.filteredList.filter((_, index) => index >= this.startElement && index <= this.endElement)
      : [];
  }
}
