import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'vpfa-mobile-total-summary-item',
  templateUrl: './mobile-total-summary-item.component.html',
  styleUrls: ['./mobile-total-summary-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileTotalSummaryItemComponent {
  @Input() label: string;
  @Input() value: string;
  @Input() notAvailable: boolean;
}
