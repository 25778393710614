<input
  class="text-filter-input"
  type="text"
  vpfaNumberFormatter
  [(ngModel)]="value"
  (ngModelChange)="valueChanged()"
  [maxFractionNumber]="maxFractionNumber"
  [maxCharCount]="maxCharCount"
  vpfaCharCountLimiter
/>
