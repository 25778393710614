import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'vpfa-unique-data-valuation-text-field',
  templateUrl: './unique-data-valuation-text-field.component.html',
  styleUrls: ['./unique-data-valuation-text-field.component.scss'],
})
export class UniqueDataValuationTextFieldComponent {
  @Input() label: string;
  @Input() placeholder: string = null;
  @Input() fieldWidth = null;
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() fcName: string;
  @Input() errorMessages: ReadonlyArray<{ error: string; errorMsg: string }>;
}
