import { Component, Input } from '@angular/core';

@Component({
  selector: 'vpfa-short-label-value',
  templateUrl: './short-label-value.component.html',
  styleUrls: ['./short-label-value.component.scss'],
})
export class ShortLabelValueComponent {
  @Input() label: string;
  @Input() showCheckedSymbol = false;
  @Input() showBlueBackground = false;
  @Input() value: string;
}
