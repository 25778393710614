import { createFeatureSelector, createSelector } from '@ngrx/store';
import { USER_PROFILE_FEATURE_KEY, UserProfileState, ExperianCredentialsState } from './user-profile.reducer';

// Lookup the 'UserProfile' feature state managed by NgRx
const getUserProfileState = createFeatureSelector<UserProfileState>(USER_PROFILE_FEATURE_KEY);

const getError = createSelector(getUserProfileState, (state: UserProfileState) => state.error);

const getIsProcessingForm = createSelector(getUserProfileState, (state: UserProfileState) => state.isProcessingForm);

const getHasFormProcessingError = createSelector(
  getUserProfileState,
  (state: UserProfileState) => state.hasSettingsFormProcessingError
);

const getIsChangingPassword = createSelector(
  getUserProfileState,
  (state: UserProfileState) => state.isChangingPassword
);

const getIsPasswordModalOpen = createSelector(
  getUserProfileState,
  (state: UserProfileState) => state.isPasswordModalOpen
);

const getIsUserProfileUpdating = createSelector(
  getUserProfileState,
  (state: UserProfileState) => state.isUserProfileUpdating
);

const getPasswordCompatible = createSelector(
  getUserProfileState,
  (state: UserProfileState) => state.passwordCompatible
);

const getExperianCredentialsState = createSelector(
  getUserProfileState,
  (state: UserProfileState) => state.experianCredentials
);

const getExperianCredentials = createSelector(
  getExperianCredentialsState,
  (state: ExperianCredentialsState) => state.credentials
);

const isExperianCredentialsProcessing = createSelector(
  getExperianCredentialsState,
  (state: ExperianCredentialsState) => state.processing
);

export const userProfileQuery = {
  getError,
  getIsProcessingForm,
  getHasFormProcessingError,
  getIsChangingPassword,
  getIsPasswordModalOpen,
  getIsUserProfileUpdating,
  getPasswordCompatible,
  getExperianCredentials,
  isExperianCredentialsProcessing,
};
