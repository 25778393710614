import { HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { isNil } from 'lodash';
import { Observable, of } from 'rxjs';

/**
 * Whitelist with regular expressions of URLs that will be cached for X hours
 */
const CACHE_WHITELIST: CacheWhitelistItem[] = [
  { url: new RegExp(`sorc\/dictionaries\/transmissiontype\/[A-Za-z]{2}`), validFor: 24 },
  { url: new RegExp(`sorc\/dictionaries\/bodytype\/[A-Za-z]{2}`), validFor: 24 },
  { url: new RegExp(`sorc\/dictionaries\/fueltype\/[A-Za-z]{2}`), validFor: 24 },
];

export const getItemToCache = (cacheKey): CacheWhitelistItem => {
  return CACHE_WHITELIST.find(item => cacheKey.match(item.url));
};

export const shouldCache = (req: HttpRequest<any>, itemToCache: CacheWhitelistItem): boolean => {
  return req.method === 'GET' && !isNil(itemToCache);
};

export const checkExpired = (item: CacheStorageRecord): Observable<HttpResponse<any> | null> => {
  return Date.now() < item.expires ? of(new HttpResponse({ body: item.body })) : of(null);
};

export const isResponseSuccessful = (event: HttpEvent<any>): boolean => {
  return event instanceof HttpResponse && event?.status === 200;
};

export const convertHoursToMilliseconds = (hours: number): number => {
  return hours * 60 * 60 * 1000;
};

/**
 * Whitelisted cache item
 *
 * url - regular expression (of URL) to be cached
 * validFor - number of hours that the cache should be valid for
 */
export interface CacheWhitelistItem {
  url: RegExp;
  validFor: number;
}

/**
 * Item stored in cache
 *
 * body - cached value from response
 * expires - expiry time of cache item
 */
export interface CacheStorageRecord {
  body: any;
  expires: number;
}
