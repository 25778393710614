import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedDirectivesModule } from '@vpfa/shared/directives';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { LabelComponent } from './label/label.component';
import { TextFieldComponent } from './text-field/text-field.component';
import { ValueComponent } from './value/value.component';
import { SelectComponent } from './select/select.component';
import { ActiveCurrencyComponent } from './active-currency/active-currency.component';
import { NumberFieldComponent } from './number-field/number-field.component';
import { TranslateModule } from '@ngx-translate/core';
import { ActionButtonComponent } from './action-button/action-button.component';
import { ActionButtonWithConfirmationComponent } from './action-button-with-confirmation/action-button-with-confirmation.component';
import { AmountBoxComponent } from './amount-box/amount-box.component';
import { InlineEditableNumberComponent } from './inline-editable-number/inline-editable-number.component';
import { TextListFieldComponent } from './text-list-field/text-list-field.component';
import { LabelValueComponent } from './label-value/label-value.component';
import { ExpandingHeaderComponent } from './expanding-header/expanding-header.component';
import { SharedPipesModule } from '@vpfa/shared/pipes';
import { ShortLabelInlineEditableComponent } from './short-label-inline-editable/short-label-inline-editable.component';
import { StockStatusComponent } from './stock-status/stock-status.component';
import { ValuationStatusComponent } from './valuation-status/valuation-status.component';
import { SoldStatusComponent } from './sold-status/sold-status.component';
import { FieldContainerComponent } from './field-container/field-container.component';
import { UiKitUikitValidationModule } from '@vpfa/ui-kit/uikit-validation';
import { CharCountLimiter } from './number-field/char-count-limiter.directive';
import { DropdownButtonComponent } from './dropdown-button/components/dropdown-button/dropdown-button.component';
import { PhoneNumberFieldComponent } from './phone-number-field/phone-number-field.component';
import { PortalModule } from '@angular/cdk/portal';
import { StepProgressbarComponent } from './step-progressbar/step-progressbar.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { ShortLabelInlineEditableSelectComponent } from './short-label-inline-select/short-label-inline-editable-select.component';
import { InlineEditableDateComponent } from './inline-editable-date/inline-editable-date.component';
import { IntervalNumberFieldComponent } from './interval-number-field/interval-number-field.component';
import { NonEditableFieldComponent } from './non-editable-field/non-editable-field.component';
import { SharedMobileViewModule } from '@vpfa/shared/mobile-view';
import { CheckboxLabelComponent } from './checkbox-label/checkbox-label.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { SharedLoadersModule } from '@vpfa/shared/loaders';
import { PasswordFieldComponent } from './password-field/password-field.component';
import { ModalsModule } from '@vpfa/modals';
import { UiKitIsoDatePickerModule } from '@vpfa/ui-kit/iso-date-picker';
import { ReservedStatusComponent } from './reserved-status/reserved-status.component';
import { BadgeComponent } from './badge/badge.component';
import { ResetButtonComponent } from './reset-button/reset-button.component';
import { TextFieldWithDropdownComponent } from './text-field-with-dropdown/text-field-with-dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    NzModalModule,
    NzTypographyModule,
    NzTabsModule,
    NzButtonModule,
    FlexLayoutModule,
    NzSelectModule,
    FormsModule,
    NzIconModule,
    NzDividerModule,
    NzRadioModule,
    ReactiveFormsModule,
    NzInputModule,
    SharedDirectivesModule,
    NzDatePickerModule,
    NzListModule,
    TranslateModule,
    NzCheckboxModule,
    NzPopoverModule,
    SharedPipesModule,
    NzFormModule,
    NzPopconfirmModule,
    NzInputNumberModule,
    UiKitUikitValidationModule,
    NzBadgeModule,
    NzDropDownModule,
    SharedMobileViewModule,
    NzToolTipModule,
    PortalModule,
    NzSpinModule,
    NzProgressModule,
    SharedLoadersModule,
    ModalsModule,
    UiKitIsoDatePickerModule,
  ],
  exports: [
    LabelComponent,
    ValueComponent,
    SelectComponent,
    TextFieldComponent,
    ActiveCurrencyComponent,
    NumberFieldComponent,
    ActionButtonComponent,
    ActionButtonWithConfirmationComponent,
    AmountBoxComponent,
    InlineEditableNumberComponent,
    TextListFieldComponent,
    LabelValueComponent,
    ExpandingHeaderComponent,
    ShortLabelInlineEditableComponent,
    ValuationStatusComponent,
    StockStatusComponent,
    SoldStatusComponent,
    ReservedStatusComponent,
    FieldContainerComponent,
    CharCountLimiter,
    DropdownButtonComponent,
    PhoneNumberFieldComponent,
    StepProgressbarComponent,
    AutocompleteComponent,
    ShortLabelInlineEditableSelectComponent,
    InlineEditableDateComponent,
    IntervalNumberFieldComponent,
    NonEditableFieldComponent,
    CheckboxLabelComponent,
    TooltipComponent,
    PasswordFieldComponent,
    BadgeComponent,
    ResetButtonComponent,
    TextFieldWithDropdownComponent,
  ],
  declarations: [
    LabelComponent,
    ValueComponent,
    SelectComponent,
    TextFieldComponent,
    ActiveCurrencyComponent,
    NumberFieldComponent,
    ActionButtonComponent,
    ActionButtonWithConfirmationComponent,
    AmountBoxComponent,
    InlineEditableNumberComponent,
    TextListFieldComponent,
    LabelValueComponent,
    ExpandingHeaderComponent,
    ShortLabelInlineEditableComponent,
    StockStatusComponent,
    ValuationStatusComponent,
    SoldStatusComponent,
    ReservedStatusComponent,
    FieldContainerComponent,
    CharCountLimiter,
    DropdownButtonComponent,
    PhoneNumberFieldComponent,
    StepProgressbarComponent,
    AutocompleteComponent,
    ShortLabelInlineEditableSelectComponent,
    InlineEditableDateComponent,
    IntervalNumberFieldComponent,
    NonEditableFieldComponent,
    CheckboxLabelComponent,
    TooltipComponent,
    PasswordFieldComponent,
    BadgeComponent,
    ResetButtonComponent,
    TextFieldWithDropdownComponent,
  ],
})
export class UiKitModule {}
