import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BroadcastFacade, LoadCaseBroadcastSettings } from '@vpfa/dealer/broadcasts/data';
import { CasesFacade } from '@vpfa/dealer/case/data';
import {
  AddImages,
  GalleryManagerActionTypes,
  GalleryManagerFacade,
  ImageAdded,
  ImageReplaced,
  UpdatePromotionalImages,
} from '@vpfa/dealer/gallery-manager/data';
import { CaseBroadcastSettingsService } from '@vpfa/rest-api/ad-broadcast';
import { CaseStatus } from '@vpfa/rest-api/valuation';
import { BasicNotificationsService, FilesNotificationsService } from '@vpfa/shared/notifications';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  AddToAdvertiseUpdateQueue,
  AdvertiseUpdateError,
  ClearAdvertiseUpdateQueue,
  ConfirmAdvertisedImagesActionTypes,
  SaveChangesFromQueue,
  ShowConfirmationModal,
  UploadImagesAfterConfirmation,
} from './confirm-advertised-images.actions';
import { ConfirmAdvertisedImagesFacade } from './confirm-advertised-images.facade';

@Injectable()
export class ConfirmAdvertisedImagesEffects {
   deferFileUploadAndShowConfirmationModal$ = createEffect(() => this.actions$.pipe(
    ofType<AddImages>(GalleryManagerActionTypes.AddImages),
    map(action => new ShowConfirmationModal(action.payload))
  ));

   uploadImagesAfterConfirmationModal$ = createEffect(() => this.actions$.pipe(
    ofType<UploadImagesAfterConfirmation>(ConfirmAdvertisedImagesActionTypes.UploadImagesAfterConfirmation),
    tap(({ payload }) => {
      const images = payload.command.images;
      if (images && images.length) {
        this.filesNotification.setFilesCount(images.length);
        images.forEach((image, index) => {
          this.galleryManagerFacade.addImage(
            { aggregateRootId: payload.command.aggregateRootId, file: image, isFirst: index === 0 },
            payload.selectedForAdvertisement[index].isSelected
          );
        });
      }
    })
  ), { dispatch: false });

   collectImagesListToBeUpdatedForBroadcasting$ = createEffect(() => this.actions$.pipe(
    ofType<ImageAdded>(GalleryManagerActionTypes.ImageAdded),
    map(
      action =>
        new AddToAdvertiseUpdateQueue({
          caseId: action.payload.caseId,
          fileId: action.payload.fileId,
          isSelectedForAdvertisement: action.payload.selectedForAdvertisement,
        })
    )
  ));

   collectImagesFramesListToBeUpdatedForBroadcasting$ = createEffect(() => this.actions$.pipe(
    ofType<ImageReplaced>(GalleryManagerActionTypes.ImageReplaced),
    switchMap(action => [
      new AddToAdvertiseUpdateQueue({
        caseId: action.payload.caseId,
        fileId: action.payload.newFileKey,
        isSelectedForAdvertisement: action.payload.isSelectedForAdvertisement,
      }),
      new SaveChangesFromQueue(),
    ])
  ));

   collectPromotionalImagesListToBeUpdatedForBroadcasting$ = createEffect(() => this.actions$.pipe(
    ofType<UpdatePromotionalImages>(GalleryManagerActionTypes.UpdatePromotionalImages),
    switchMap(action =>
      action.payload.promoImages.map(
        imageKey =>
          new AddToAdvertiseUpdateQueue({
            caseId: action.payload.aggregateRootId,
            fileId: imageKey,
            isSelectedForAdvertisement: true,
          })
      )
    )
  ));

   updatePromotionalImagesForBroadcasting$ = createEffect(() => this.actions$.pipe(
    ofType(GalleryManagerActionTypes.PromotionalImagesUpdated),
    map(() => new SaveChangesFromQueue())
  ));

   updateAdvertisedImagesSelectionWhenUploadIsFinished$ = createEffect(() => this.actions$.pipe(
    ofType(GalleryManagerActionTypes.ImageUploadFinished),
    map(() => new SaveChangesFromQueue())
  ));

   saveChangesFromQueue$ = createEffect(() => this.actions$.pipe(
    ofType(ConfirmAdvertisedImagesActionTypes.SaveChangesFromQueue),
    withLatestFrom(
      this.confirmAdvertisedImagesFacade.advertisementQueue$.pipe(filter(queue => queue?.images?.length > 0))
    ),
    concatMap(([, queue]) =>
      this.caseBroadcastSettingsService
        .manageImages({
          caseId: queue.caseId,
          images: queue.images.map(x => ({
            isDisabled: !x.isSelectedForAdvertisement,
            key: x.fileId,
          })),
        })
        .pipe(
          switchMap(() => [new ClearAdvertiseUpdateQueue(), new LoadCaseBroadcastSettings(queue.caseId)]),
          catchError(() => of(new AdvertiseUpdateError()))
        )
    )
  ));

   notificationAfterAdvertiseUpdate$ = createEffect(() => this.actions$.pipe(
    ofType(ConfirmAdvertisedImagesActionTypes.ClearAdvertiseUpdateQueue),
    withLatestFrom(
      this.caseFacade.activeCaseData$.pipe(map(x => x?.caseStatus)),
      this.caseFacade.activeCaseData$.pipe(map(x => x?.publicSiteConfiguration?.enabled === true)),
      this.broadcastFacade.broadcastSettings$.pipe(map(x => x?.adPortals.some(x => x.isEnabled)))
    ),
    tap(([, caseStatus, publicSiteIsEnabled, isBroadcastEnabled]) => {
      if (caseStatus === CaseStatus.Valuated) {
        this.notification.success(
          'confirmAdvertisedImagesModal.uploadSuccessInValuationBroadcastWillBeAvailableInStock'
        );
        this.notification.success(
          'confirmAdvertisedImagesModal.uploadSuccessInValuationpublicSiteWillBeAvailableInStock'
        );
      } else {
        if (isBroadcastEnabled) {
          this.notification.success('confirmAdvertisedImagesModal.uploadSuccessInStockBroadcastIsEnabled');
        } else {
          this.notification.success('confirmAdvertisedImagesModal.uploadSuccessInStockBroadcastIsDisabled');
        }

        if (publicSiteIsEnabled) {
          this.notification.success('confirmAdvertisedImagesModal.uploadSuccessInStockPublicSiteIsEnabled');
        } else {
          this.notification.success('confirmAdvertisedImagesModal.uploadSuccessInStockPublicSiteIsDisabled');
        }
      }
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private galleryManagerFacade: GalleryManagerFacade,
    private confirmAdvertisedImagesFacade: ConfirmAdvertisedImagesFacade,
    private filesNotification: FilesNotificationsService,
    private caseBroadcastSettingsService: CaseBroadcastSettingsService,
    private notification: BasicNotificationsService,
    private caseFacade: CasesFacade,
    private broadcastFacade: BroadcastFacade
  ) {}
}
