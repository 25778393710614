import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Subscription } from 'rxjs';
import {isNil} from "lodash";

export function notEqualValidator(controlNametoCompare: string): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (isNil(c.value)) {
        return null;
      }
      const controlToCompare = c.root.get(controlNametoCompare);
      if (controlToCompare) {
        const subscription: Subscription = controlToCompare.valueChanges.subscribe(() => {
          c.updateValueAndValidity();
          subscription.unsubscribe();
        });
      }
      return controlToCompare && controlToCompare.value === c.value ? { notEqual: true } : null;
    };
  }
  