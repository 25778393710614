import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { BasicNotificationsService, FilesNotificationsService } from '@vpfa/shared/notifications';
import { getFileExtension, isFileTypeAccepted } from '../../utils/get-accept-info';
import { TranslateService } from '@ngx-translate/core';
import {
  ALLOWED_DOCUMENT_EXTENSIONS,
  ALLOWED_IMAGE_EXTENSIONS,
  ALLOWED_JSON_EXTENSIONS,
  FileItemList,
  FileUploadType,
} from '../../models/file-types';
import { filesNotAllowed, getFileMaxSize, perFileSizeLimitFilter, totalSizeLimitFilter } from './helpers';
import { fromBuffer } from 'file-type/core';

export enum UploadSizeLimitType {
  PerFileLimit = 'PerFileLimit',
  TotalSizeLimit = 'TotalSizeLimit',
  Mixed = 'Mixed',
}

@Component({
  selector: 'vpfa-file-upload-container',
  templateUrl: './file-upload-container.component.html',
  styleUrls: ['./file-upload-container.component.scss'],
})
export class FileUploadContainerComponent implements OnDestroy {
  @Input() isDisabled = false;
  @Input() showUploadIcon = false;
  @Input() isUploading = false;
  @Input() fileList: FileItemList = [];
  @Input() fileUploadType: FileUploadType;
  @Input() uploadButtonLabel: string;
  @Input() multiple = true;
  @Input() totalUploadSize: number;
  @Input() uploadPrompt: string;
  @Input() uiMode: 'linkMode' | 'fullMode' | 'singleFileModalMode' = 'fullMode';
  @Input() uploadSizeLimitType: UploadSizeLimitType = UploadSizeLimitType.PerFileLimit;
  @Input() alwaysVisible = false;
  @Input() duplicateFileList: File[];

  @Output() fileListChange = new EventEmitter<File[]>();

  @ViewChild('fileInputRef', { read: ElementRef }) fileInputRef: ElementRef;
  private onDestroy$ = new Subject<void>();

  getFileMaxSize = getFileMaxSize;

  constructor(
    private translateService: TranslateService,
    private translatingBasicNotificationsService: BasicNotificationsService,
    private filesNotifications: FilesNotificationsService
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  async onFileListChange($event: Event) {
    const input = $event.target as HTMLInputElement;
    let uploadList = Array.from(input.files);

    const filesSizeZero = [];
    const filesExtensionDoesntMatchFileContent = [];
    const filesWrongFileType = [];
    const allowed = [];

    let fileTypeList = await Promise.all(
      uploadList.map(async file => fromBuffer(await new Response(file).arrayBuffer()))
    );

    uploadList = uploadList.filter((file, index) => {
      const fileType = file.type !== '' ? file.type : null;
      let fileExtension = getFileExtension(file);
      fileExtension = fileExtension ? fileExtension.toLowerCase() : fileExtension;
      const expectedType = fileTypeList[index] ? fileTypeList[index].mime : fileType;
      const expectedExtension = fileTypeList[index] ? fileTypeList[index].ext : fileExtension;
      if (file.size === 0) {
        filesSizeZero.push(file.name);
        return false;
      }

      if (this.fileUploadType === FileUploadType.Json) {
        if (fileExtension && !ALLOWED_JSON_EXTENSIONS.includes(`.${fileExtension}`)) {
          filesWrongFileType.push(file.name);
          return false;
        }
      } else {
        if (fileExtension && !ALLOWED_DOCUMENT_EXTENSIONS.includes(`.${fileExtension}`)) {
          filesWrongFileType.push(file.name);
          return false;
        }

        if (this.fileUploadType !== FileUploadType.Document || ALLOWED_IMAGE_EXTENSIONS.includes(`.${fileExtension}`)) {
          if (fileType && fileType !== expectedType) {
            filesExtensionDoesntMatchFileContent.push(file.name);
            return false;
          }
          if (fileExtension && fileExtension !== expectedExtension) {
            filesExtensionDoesntMatchFileContent.push(file.name);
            return false;
          }
          if (fileType && !isFileTypeAccepted(expectedType, this.fileUploadType)) {
            filesWrongFileType.push(file.name);
            return false;
          }
        }
      }

      allowed.push(file.name);
      return true;
    });

    if (filesSizeZero.length) {
      filesNotAllowed(
        filesSizeZero,
        'fileSizeIsZero',
        this.fileUploadType,
        this.translateService,
        this.filesNotifications
      );
    }
    if (filesExtensionDoesntMatchFileContent.length) {
      filesNotAllowed(
        filesExtensionDoesntMatchFileContent,
        'fileExtensionDoesntMatchFileContent',
        this.fileUploadType,
        this.translateService,
        this.filesNotifications
      );
    }
    if (filesWrongFileType.length) {
      filesNotAllowed(
        filesWrongFileType,
        'wrongFileType',
        this.fileUploadType,
        this.translateService,
        this.filesNotifications
      );
    }

    let exceededMaxSize = 0;

    if (uploadList.length && this.uploadSizeLimitType) {
      let beforeCount = uploadList.length;
      switch (this.uploadSizeLimitType) {
        case UploadSizeLimitType.PerFileLimit:
          uploadList = perFileSizeLimitFilter(
            uploadList,
            this.fileUploadType,
            this.translateService,
            this.filesNotifications
          );
          break;
        case UploadSizeLimitType.TotalSizeLimit:
          uploadList = totalSizeLimitFilter(
            uploadList,
            this.fileUploadType,
            this.translateService,
            this.filesNotifications,
            this.totalUploadSize,
            this.duplicateFileList
          );
          break;
        case UploadSizeLimitType.Mixed:
          uploadList = perFileSizeLimitFilter(
            uploadList,
            this.fileUploadType,
            this.translateService,
            this.filesNotifications
          );
          uploadList = totalSizeLimitFilter(
            uploadList,
            this.fileUploadType,
            this.translateService,
            this.filesNotifications,
            this.totalUploadSize,
            this.duplicateFileList
          );
      }

      exceededMaxSize = beforeCount - uploadList.length;
    }

    const invalidFilesAmount =
      filesSizeZero.length + filesExtensionDoesntMatchFileContent.length + filesWrongFileType.length + exceededMaxSize;

    this.filesNotifications.setInvalidFilesCount(invalidFilesAmount);
    this.filesNotifications.setFilesCount(uploadList.length);

    if (uploadList.length) {
      this.fileListChange.emit(uploadList);
    }
  }
}
