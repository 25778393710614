/**
 * Public SSM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0-private
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 

export enum Merchantability {

      CurrentValue = 1,
      InGreatDemand = 2,
      InDemand = 3,
      Common = 4,
      LessCommon = 5,
      NotDemanded = 6,

};
