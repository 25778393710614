import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CreateCaseCustomersState,
  CreateCustomerModalState,
  CustomerDetailsState,
  CustomerFormsOfAddressState,
  CustomerLatestQuoteListState,
  CustomerListState,
  CUSTOMERS_FEATURE_KEY,
  CustomersState,
  SelectedCustomerDetailsForSellModalState,
  UpdateCustomerModalState,
} from './customers.reducer';

const getCustomersState = createFeatureSelector<CustomersState>(CUSTOMERS_FEATURE_KEY);

const getCreateCustomerModalState = createSelector(
  getCustomersState,
  (state: CustomersState) => state.createCustomerModal
);
const getCreateCustomerModalOpen = createSelector(
  getCreateCustomerModalState,
  (state: CreateCustomerModalState) => state.open
);
const getCreateCustomerModalProcessing = createSelector(
  getCreateCustomerModalState,
  (state: CreateCustomerModalState) => state.processing
);

const getCreateCaseCustomersState = createSelector(
  getCustomersState,
  (state: CustomersState) => state.createCaseCustomers
);
const getCreateCaseOwnerModalOpen = createSelector(
  getCreateCaseCustomersState,
  (state: CreateCaseCustomersState) => state.createCaseOwnerModalOpen
);
const getCreateCasePreviousOwnerModalOpen = createSelector(
  getCreateCaseCustomersState,
  (state: CreateCaseCustomersState) => state.createCasePreviousOwnerModalOpen
);
const getCreateCaseProspectCustomerModalOpen = createSelector(
  getCreateCaseCustomersState,
  (state: CreateCaseCustomersState) => state.createCaseProspectCustomerModalOpen
);

const getUpdateCustomerModalState = createSelector(
  getCustomersState,
  (state: CustomersState) => state.updateCustomerModel
);
const getUpdateCustomerModalOpen = createSelector(
  getUpdateCustomerModalState,
  (state: UpdateCustomerModalState) => state.open
);
const getUpdateCustomerModalProcessing = createSelector(
  getUpdateCustomerModalState,
  (state: UpdateCustomerModalState) => state.processing
);

const getCustomerListState = createSelector(getCustomersState, (state: CustomersState) => {
  return state.list;
});

const getIsListLoaded = createSelector(getCustomerListState, (state: CustomerListState) => state.isLoaded);

const getIsListLoading = createSelector(getCustomerListState, (state: CustomerListState) => state.isLoading);

const getErrorList = createSelector(getCustomerListState, (state: CustomerListState) => state.error);

const getAllCustomerList = createSelector(
  getCustomerListState,
  getIsListLoaded,
  (state: CustomerListState, isLoaded) => {
    return isLoaded ? state.list : [];
  }
);

const getSelectedCustomersId = createSelector(getCustomerListState, state => state.selectedIds);

const getSelectedCustomers = createSelector(getSelectedCustomersId, getAllCustomerList, (selectedIds, customerList) => {
  return customerList.filter(customer => selectedIds.indexOf(customer.id) !== -1);
});

const getCustomerDetailsState = createSelector(getCustomersState, (state: CustomersState) => {
  return state.details;
});

const getCustomerDetails = createSelector(getCustomerDetailsState, (state: CustomerDetailsState) => state.customer);

const getCustomerDetailsLoading = createSelector(
  getCustomerDetailsState,
  (state: CustomerDetailsState) => state.loading
);

const getCustomerDetailsProcessing = createSelector(
  getCustomerDetailsState,
  (state: CustomerDetailsState) => state.processing
);

const getCustomerDetailsError = createSelector(getCustomerDetailsState, (state: CustomerDetailsState) => state.error);

const getSelectedForSellModalDetails = createSelector(getCustomersState, (state: CustomersState) => {
  return state.selectedForSellModalDetails;
});

const getSelectedCustomersForSellModalDetails = createSelector(
  getSelectedForSellModalDetails,
  (state: SelectedCustomerDetailsForSellModalState) => {
    return state.customers;
  }
);

const getSelectedForSellModalDetailsLoaded = createSelector(
  getSelectedForSellModalDetails,
  (state: SelectedCustomerDetailsForSellModalState) => {
    return state.loaded;
  }
);

const getCustomerHaveRelatedCase = createSelector(getCustomersState, state => state.haveRelatedCase);

const getHaveRelatedCaseLoading = createSelector(getCustomerHaveRelatedCase, state => state.loading);

const getHaveRelatedCase = createSelector(getCustomerHaveRelatedCase, getHaveRelatedCaseLoading, (state, isLoading) => {
  return isLoading ? null : state.haveRelatedCase;
});

const getFormsOfAddressState = createSelector(getCustomersState, (state: CustomersState) => {
  return state.formsOfAddress;
});

const getCustomerFormsOfAddress = createSelector(getFormsOfAddressState, (state: CustomerFormsOfAddressState) => {
  return state.list;
});

const getCustomerFormsOfAddressLoading = createSelector(
  getFormsOfAddressState,
  (state: CustomerFormsOfAddressState) => {
    return state.loading;
  }
);

const getLastestQuoteList = createSelector(getCustomersState, (state: CustomersState) => {
  return state.latestQuotes;
});

const getIsCustomerLatestQuoteListLoading = createSelector(
  getLastestQuoteList,
  (state: CustomerLatestQuoteListState) => {
    return state.loading;
  }
);

const getCustomerLatestQuoteList = createSelector(getLastestQuoteList, (state: CustomerLatestQuoteListState) => {
  return state.list;
});

// Paged list selectors
const getCustomerPagedListState = createSelector(getCustomersState, (state: CustomersState) => {
  return state.pagedList;
});

const getCustomerPagedListProcessing = createSelector(getCustomerPagedListState, state => state.isProcessing);

const getPagedSelectedCustomersId = createSelector(getCustomerPagedListState, state => state.selectedIds);

const getIsPagedCustomersListLoading = createSelector(getCustomerPagedListState, state => state.isLoading);

const getPagedCustomersList = createSelector(getCustomerPagedListState, state => {
  return state.list;
});

const getIsPagedCustomersListAllRowLoaded = createSelector(getCustomerPagedListState, state => {
  return state.total === state.list?.length;
});

const getPagedCustomersListTotalRows = createSelector(getCustomerPagedListState, state => {
  return state.total;
});

export const customersQuery = {
  getCreateCustomerModalOpen,
  getCreateCustomerModalProcessing,
  getUpdateCustomerModalOpen,
  getUpdateCustomerModalProcessing,
  getCreateCaseOwnerModalOpen,
  getCreateCasePreviousOwnerModalOpen,
  getCreateCaseProspectCustomerModalOpen,
  getIsListLoaded,
  getIsListLoading,
  getErrorList,
  getAllCustomerList,
  getSelectedCustomers,
  getCustomerPagedListProcessing,
  getPagedSelectedCustomersId,
  getCustomerDetails,
  getCustomerDetailsLoading,
  getCustomerDetailsProcessing,
  getCustomerDetailsError,
  getSelectedCustomersForSellModalDetails,
  getSelectedForSellModalDetailsLoaded,
  getHaveRelatedCaseLoading,
  getHaveRelatedCase,
  getCustomerFormsOfAddress,
  getCustomerFormsOfAddressLoading,
  getIsCustomerLatestQuoteListLoading,
  getCustomerLatestQuoteList,
  getIsPagedCustomersListLoading,
  getPagedCustomersList,
  getIsPagedCustomersListAllRowLoaded,
  getPagedCustomersListTotalRows,
};
