import { Directive, ElementRef, Renderer2, AfterViewInit, HostBinding, OnInit, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[vpfaSticky]',
})
export class StickyDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input() stickyClass = 'is-sticky';

  @HostBinding('style.position')
  position: string = 'sticky';
  @HostBinding('style.display')
  display: string = 'block';

  private observer: IntersectionObserver;

  constructor(private element: ElementRef, private renderer: Renderer2) {}
  ngOnInit(): void {
    this.observer = new IntersectionObserver(([e]) => this.onIntersectionObserve(e), {
      threshold: [1],
    });
  }
  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  ngAfterViewInit(): void {
    this.setStickyStyle();
    this.observer.observe(this.element.nativeElement);
  }

  private setStickyStyle() {
    this.renderer.setStyle(this.element.nativeElement, 'top', '-1px');
    const currentPaddingTop = (this.element.nativeElement as HTMLElement).style.paddingTop;
    this.renderer.setStyle(
      this.element.nativeElement,
      'margin-top',
      currentPaddingTop ? `calc(${currentPaddingTop} + 1px)` : '1px'
    );
  }

  private onIntersectionObserve(entry: IntersectionObserverEntry) {
    if ((this.element.nativeElement as HTMLElement).getBoundingClientRect().top < 0) {
      this.renderer.addClass(this.element.nativeElement, this.stickyClass);
    } else {
      this.renderer.removeClass(this.element.nativeElement, this.stickyClass);
    }
  }
}
