import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { CasesFacade } from '@vpfa/dealer/case/data';
import { Subject } from 'rxjs';
import { getCaseDetailsLink, getRetailPrice } from '@vpfa/dealer/case/shared';
import { Router } from '@angular/router';
import { CaseStatus, CaseSummaryDto, EquipmentType } from '@vpfa/rest-api/valuation';
import { OwnVehicleParams } from 'liveRetail-lib';
import { BranchDto, UserContext } from '@vpfa/rest-api/admin';
import { BranchesFacade } from '@vpfa/admin/branches/data';
import { ProfileFacade } from '@vpfa/profile/data';
import {
  CaseDetailsMobileViewQueryParams,
  CaseDetailsMobileViewQueryParamsScrollToValue,
} from '@vpfa/dealer/shared/vehicle';
import { getOwnVehicleParams } from '../../utils/get-own-vehicle-params.util';

@Component({
  selector: 'vpfa-radar',
  templateUrl: './radar.component.html',
  styleUrls: ['./radar.component.scss'],
})
export class RadarComponent implements OnInit, OnDestroy, OnChanges {
  caseData$ = this.caseDataFacade.activeCaseData$;
  caseStatus$ = this.caseDataFacade.activeCaseStatus$;
  caseStatusEnum = CaseStatus;

  caseData: CaseSummaryDto;
  branchZipCode: string;
  headerTitleExtra = null;
  userContext: UserContext;

  ownVehicleParams: OwnVehicleParams = undefined;

  private onDestroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private caseDataFacade: CasesFacade,
    private branchFacade: BranchesFacade,
    private profileFacade: ProfileFacade
  ) {}

  ngOnInit() {
    this.caseData$.pipe(takeUntil(this.onDestroy$)).subscribe(data => {
      this.caseData = data;
      this.headerTitleExtra = `${data.vehicle.make} ${data.vehicle.edition}`;
      this.branchFacade.loadBranchDetails(this.caseData.branchId);
      this.refreshOwnVehicleParams();
    });

    this.branchFacade.getBranchDetails$.pipe(takeUntil(this.onDestroy$)).subscribe((branch: BranchDto) => {
      this.branchZipCode = branch?.branchPostCode;
      this.refreshOwnVehicleParams();
    });

    this.profileFacade.userContext$.pipe(takeUntil(this.onDestroy$)).subscribe(uc => {
      this.userContext = uc;
      this.refreshOwnVehicleParams();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refreshOwnVehicleParams();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  goToCaseSummaryPage() {
    this.router.navigate(getCaseDetailsLink(this.caseData.caseStatus, this.caseData.id), {
      queryParams: {
        [CaseDetailsMobileViewQueryParams.SCROLL_TO]: CaseDetailsMobileViewQueryParamsScrollToValue.RETAIL_MARKET,
      },
    });
  }

  get caseAskingPrice(): number {
    if (!this.caseData) {
      return null;
    }

    switch (this.caseData.caseStatus) {
      case CaseStatus.Valuated:
        return getRetailPrice(this.caseData.taxedPrice);
      case CaseStatus.InStock:
      case CaseStatus.DueInStock:
        return this.caseData?.taxedPrice?.askingPrice ?? null;
      default:
        return null;
    }
  }

  private refreshOwnVehicleParams() {
    this.ownVehicleParams = getOwnVehicleParams(
      this.caseData,
      this.branchZipCode,
      this.userContext,
      this.caseAskingPrice
    );
  }
}
