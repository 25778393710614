import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SelectOption } from '@vpfa/ui-kit';
import { enumToOptions } from '@vpfa/utils';
import {
  ColorDictionaryItemDto,
  DamagedVehicleType,
  ManufacturerColorDto,
  MileageType,
  PaintBrightness,
  PaintType,
  ValuationUniqueDataDto,
  VehicleCategory,
} from '@vpfa/rest-api/valuation';
import { isNil } from 'lodash';
import { UniqDataValuationOptions } from '../../models/uniq-data-valuation-options';
import { BranchListItemDto } from '@vpfa/rest-api/admin';
import { branchListDto2Options } from '../../utils/branch-list-dto-2-option';
import { TEXT_LINE_MAX_LENGTH } from '@vpfa/shared/validators';
import { TranslateService } from '@ngx-translate/core';
import { DictionariesFacade } from '@vpfa/dealer/dictionaries';
import { filter, map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import {
  initialPaintColourSelectOptionValue,
  initialUniqueDataSelectOptionValue,
} from '../../utils/unique-data-helper';

export const ValuationUniqueDataFromName = 'valuationUniqData';

const VehicleCategoryOrder = [VehicleCategory.NewVehicle, VehicleCategory.UsedVehicle, VehicleCategory.DayLicense];

const DamagedVehicleTypeOrder = [
  DamagedVehicleType.RepairedOldDamage,
  DamagedVehicleType.NotRepairedCurrentDamage,
  DamagedVehicleType.NotDamaged,
  DamagedVehicleType.Unknown,
];

@Component({
  selector: 'vpfa-unique-data-valuation',
  templateUrl: './unique-data-valuation.component.html',
  styleUrls: ['./unique-data-valuation.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class UniqueDataValuation implements OnDestroy, OnInit {
  maxLengthErrorMsgList: ReadonlyArray<{ error: string; errorMsg: string }> = [
    {
      error: 'maxlength',
      errorMsg: this.translateService.instant('common.forms.maxLength', { maxLength: TEXT_LINE_MAX_LENGTH }),
    },
  ];

  mileageTypesOptions: SelectOption[] = enumToOptions(MileageType, 'uniqueData.mileageType.');

  paintColourOptions: SelectOption<ColorDictionaryItemDto>[];

  paintBrightnessOptions: SelectOption[] = enumToOptions(PaintBrightness, 'uniqueData.paintBrightness.');

  paintTypeOptions: SelectOption[] = enumToOptions(PaintType, 'uniqueData.paintType.');

  vehicleCategoryOptions: SelectOption[] = enumToOptions(VehicleCategory, 'uniqueData.vehicleCategory.').sort(
    (a, b) => VehicleCategoryOrder.indexOf(a.value) - VehicleCategoryOrder.indexOf(b.value)
  );

  damagedVehicleTypeOptions: SelectOption[] = enumToOptions(DamagedVehicleType, 'uniqueData.damagedVehicleType.').sort(
    (a, b) => DamagedVehicleTypeOrder.indexOf(a.value) - DamagedVehicleTypeOrder.indexOf(b.value)
  );

  manufacturerColorOptions: SelectOption[];
  interiorColourOptions: SelectOption[];
  originalManufacturerColorOptions: SelectOption[];
  trimTypeOptions: SelectOption[];
  originalTrimTypeOptions: SelectOption[];

  private _initialData: ValuationUniqueDataDto;

  dictionariesInitialized$ = new Subject<void>();
  branchListInitialized$ = new Subject<void>();
  initialFormDataReceived$ = new Subject<void>();
  initializeForm$ = combineLatest([
    this.dictionariesInitialized$,
    this.initialFormDataReceived$,
    this.branchListInitialized$,
  ]);

  originalBranchId: string | null = null;

  @Input() set initialData(initialData: ValuationUniqueDataDto) {
    if (isNil(initialData)) return;
    this._initialData = initialData;
    this.initialFormDataReceived$.next();
  }
  get initialData(): ValuationUniqueDataDto {
    return this._initialData;
  }
  @Input() set dataValuationOptions(vehicleData: UniqDataValuationOptions) {
    if (vehicleData) {
      this.originalManufacturerColorOptions = vehicleData.manufacturerColors
        ? vehicleData.manufacturerColors.map(manufColor => {
            return {
              name: manufColor.description,
              value: manufColor,
            };
          })
        : [];

      if (isNil(this.manufacturerColorOptions)) {
        this.manufacturerColorOptions = this.originalManufacturerColorOptions;
      }

      this.originalTrimTypeOptions = vehicleData.trimTypes
        ? vehicleData.trimTypes.map(trimType => {
            return {
              name: trimType.description,
              value: trimType,
            };
          })
        : [];

      if (isNil(this.trimTypeOptions)) {
        this.trimTypeOptions = this.originalTrimTypeOptions;
      }
    }
  }

  @Input()
  set parentFormGroup(parentFormGroup: UntypedFormGroup) {
    if (parentFormGroup) {
      parentFormGroup.addControl(ValuationUniqueDataFromName, this.formGroup);
      this.formGroup.get('valuationNumber').disable();
    }
  }

  @Input() set branchList(branches: BranchListItemDto[]) {
    if (!isNil(branches)) {
      this.branchOptions = branchListDto2Options(branches);
      this.branchListInitialized$.next();
    }
  }

  @Input() hideInteriorTrimColour = false;
  @Input() hideBuildDate = false;
  @Input() buildDateNotAvailable = false;
  @Input() isCaseIdentifiedByVin = false;

  @Output() isBranchIdModified = new EventEmitter<boolean>();

  branchOptions: SelectOption[];

  formGroupControls: {
    [key in keyof Omit<ValuationUniqueDataDto, 'manufacturerOriginalColor' | 'originalTrimType'>]: UntypedFormControl;
  } = {
    valuationNumber: new UntypedFormControl(null),
    buildDate: new UntypedFormControl(null),
    mileageType: new UntypedFormControl(null),
    paintColour: new UntypedFormControl(null),
    paintBrightness: new UntypedFormControl(null),
    paintType: new UntypedFormControl(null),
    registrationDocumentNumber: new UntypedFormControl(null, [Validators.maxLength(TEXT_LINE_MAX_LENGTH)]),
    keyNumber: new UntypedFormControl(null, [Validators.maxLength(TEXT_LINE_MAX_LENGTH)]),
    vehicleCategory: new UntypedFormControl(null, [Validators.required]),
    manufacturerColor: new UntypedFormControl(null, [Validators.maxLength(TEXT_LINE_MAX_LENGTH)]),
    trimType: new UntypedFormControl(null, [Validators.maxLength(TEXT_LINE_MAX_LENGTH)]),
    interiorTrimColor: new UntypedFormControl(null),
    damagedVehicleType: new UntypedFormControl(null),
    isRoadworthy: new UntypedFormControl(null),
    hasUnrepairedDamage: new UntypedFormControl(null),
    hadAccident: new UntypedFormControl(null),
    branchId: new UntypedFormControl(null),
  };

  formGroup = new UntypedFormGroup(this.formGroupControls);
  isManufacturerColourCustomValueProvided = new BehaviorSubject<boolean>(false);

  private destroy$ = new Subject<void>();

  constructor(private translateService: TranslateService, private dictionariesFacade: DictionariesFacade) {
    this.dictionariesFacade.loadPaintColourList();
    this.dictionariesFacade.paintColourListOptions$.pipe(takeUntil(this.destroy$)).subscribe(paintColourListOptions => {
      this.paintColourOptions = paintColourListOptions;
      this.interiorColourOptions = paintColourListOptions;
      this.dictionariesInitialized$.next();
    });

    this.initializeForm$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.resetFormToInitial(this._initialData);
    });
  }

  ngOnInit(): void {
    // INFO: If any of paintColour / paintBrightness / paintType controls change and manufacturerColor doesn't have any custom value provided by user,
    // then manufacturerColor value should be updated based on those values.
    combineLatest([
      this.formGroup.get('paintColour').valueChanges,
      this.formGroup.get('paintBrightness').valueChanges,
      this.formGroup.get('paintType').valueChanges,
    ])
      .pipe(
        filter(
          () =>
            this.isManufacturerColourCustomValueProvided.getValue() === false ||
            this.formGroup.get('manufacturerColor').value?.length === 0
        ),
        filter(
          () =>
            this.formGroup.get('paintColour')?.dirty ||
            this.formGroup.get('paintBrightness')?.dirty ||
            this.formGroup.get('paintType')?.dirty
        ),
        map(([paintColourCtrl, paintBrightnessCtrl, paintTypeCtrl]) => [
          paintColourCtrl?.name,
          paintBrightnessCtrl?.name ? this.translateService.instant(paintBrightnessCtrl?.name) : null,
          paintTypeCtrl?.name ? this.translateService.instant(paintTypeCtrl?.name) : null,
        ]),
        takeUntil(this.destroy$)
      )
      .subscribe(([paintColour, paintBrightness, paintType]) => {
        this.formGroup.get('manufacturerColor').markAsDirty();
        this.formGroup
          .get('manufacturerColor')
          .setValue([paintColour, paintBrightness, paintType].filter(x => !isNil(x)).join(' '));
        this.isManufacturerColourCustomValueProvided.next(false);
      });

    // INFO: If value of paintColour control changes, sorc values for manufacturer colour should be filtered by colorCode
    this.formGroup
      .get('paintColour')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((paintColour: SelectOption<ColorDictionaryItemDto>) =>
        this.filterManufacturerColourOptionsBasedOnSelectedPaintColour(
          paintColour?.value?.code,
          !isNil(paintColour?.value?.id)
        )
      );

    // INFO: If value of interiorTrimColor control changes, sorc values for TrimType Options should be filtered by colorCode
    this.formGroup
      .get('interiorTrimColor')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((interiorTrimColor: SelectOption<ColorDictionaryItemDto>) =>
        this.filterTrimTypeOptionsBasedOnSelectedInteriorColour(
          interiorTrimColor?.value?.code,
          !isNil(interiorTrimColor?.value?.id)
        )
      );

    // set original branch ID
    const originalBranchIdSubscription = this.formGroup
      .get('branchId')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((currentBranchId: any) => {
        if (this.originalBranchId === null && !isNil(currentBranchId?.value)) {
          this.originalBranchId = currentBranchId.value;
          originalBranchIdSubscription.unsubscribe();
        }
      });

    const branchIdControl = this.formGroup.get('branchId');
    branchIdControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      const branchIdModified = branchIdControl.dirty && value?.value !== this.originalBranchId;
      this.isBranchIdModified.emit(branchIdModified);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  manufacturerColourResetButtonDisabled() {
    return (
      this.formGroup.get('manufacturerColor').value === this.initialData?.manufacturerOriginalColor ||
      isNil(this.initialData?.manufacturerOriginalColor)
    );
  }

  onManufacturerColourReset() {
    this.formGroup.get('manufacturerColor').reset(this.initialData?.manufacturerOriginalColor);
  }

  onManufacturerColourSelectedValue(value: ManufacturerColorDto) {
    if (this.paintColourOptions?.length) {
      const paintColour = this.paintColourOptions.find(
        (paintColour: SelectOption<ColorDictionaryItemDto>) => paintColour?.value?.code === value?.colorCode
      );

      if (!isNil(paintColour)) {
        this.formGroup.get('paintColour').setValue(paintColour);
      }
    }
  }

  private filterManufacturerColourOptionsBasedOnSelectedPaintColour(code: string, optionIsSelected: boolean) {
    // some colors have `code === null` in that case we need to set manufacturerColorOptions to empty list.
    if (optionIsSelected && code === null) {
      this.manufacturerColorOptions = [];
      return;
    }

    this.manufacturerColorOptions = !isNil(code)
      ? this.originalManufacturerColorOptions.slice().filter(manuColor => manuColor?.value?.colorCode === code)
      : this.originalManufacturerColorOptions;
  }

  private filterTrimTypeOptionsBasedOnSelectedInteriorColour(code: string, optionIsSelected: boolean) {
    // some colors have `code === null` in that case we need to set originalTrimTypeOptions to empty list.
    if (optionIsSelected && code === null) {
      this.trimTypeOptions = [];
      return;
    }
    this.trimTypeOptions = !isNil(code)
      ? this.originalTrimTypeOptions.slice().filter(trimType => trimType?.value?.colorCode === code)
      : this.originalTrimTypeOptions;
  }

  onTrimTypeSelectedValue(value: ManufacturerColorDto) {
    if (this.trimTypeOptions?.length) {
      const paintColour = this.paintColourOptions.find(
        (paintColour: SelectOption<ColorDictionaryItemDto>) => paintColour?.value?.code === value?.colorCode
      );

      if (!isNil(paintColour)) {
        this.formGroup.get('interiorTrimColor').setValue(paintColour);
      }
    }
  }

  trimTypeResetButtonDisabled() {
    return (
      this.formGroup.get('trimType').value === this.initialData?.originalTrimType ||
      isNil(this.initialData?.originalTrimType)
    );
  }

  onTrimTypeReset() {
    this.formGroup.get('trimType').reset(this.initialData?.originalTrimType);
  }

  private resetFormToInitial(initialData: ValuationUniqueDataDto) {
    this.formGroup.reset({
      valuationNumber: initialData.valuationNumber,
      buildDate: initialData.buildDate,
      mileageType: initialUniqueDataSelectOptionValue(initialData.mileageType, this.mileageTypesOptions),
      paintColour: initialPaintColourSelectOptionValue(initialData.paintColour, this.paintColourOptions),
      paintBrightness: initialUniqueDataSelectOptionValue(initialData.paintBrightness, this.paintBrightnessOptions),
      paintType: initialUniqueDataSelectOptionValue(initialData.paintType, this.paintTypeOptions),
      registrationDocumentNumber: initialData.registrationDocumentNumber,
      keyNumber: initialData.keyNumber,
      vehicleCategory: initialUniqueDataSelectOptionValue(initialData.vehicleCategory, this.vehicleCategoryOptions),
      manufacturerColor: initialData.manufacturerColor,
      trimType: initialData.trimType,
      interiorTrimColor: initialPaintColourSelectOptionValue(initialData.interiorTrimColor, this.interiorColourOptions),
      damagedVehicleType: initialUniqueDataSelectOptionValue(
        initialData.damagedVehicleType,
        this.damagedVehicleTypeOptions
      ),
      isRoadworthy: initialData.isRoadworthy,
      hasUnrepairedDamage: initialData.hasUnrepairedDamage,
      hadAccident: initialData.hadAccident,
      branchId: initialUniqueDataSelectOptionValue(initialData.branchId, this.branchOptions),
    });

    // INFO: Initial value is a custom value as well
    if (!isNil(initialData.manufacturerColor)) {
      this.isManufacturerColourCustomValueProvided.next(true);
    }
  }
}
