<div [fxLayout]="'row'" [fxLayoutAlign]="'space-between baseline'">
  <div [fxLayout]="'column'" [fxLayoutAlign]="'start baseline'">
    <div [fxLayout]="'row'" [fxLayoutAlign]="'start baseline'">
      <div *ngIf="showBackButton" class="back-btn" (click)="back.emit($event)">
        <i class="zmdi zmdi-chevron-left icon"></i>
      </div>
      <p class="title">{{ title }}</p>
      <vpfa-tooltip *ngIf="tooltipContent" class="tooltip" [content]="tooltipContent"></vpfa-tooltip>
      <ng-container *ngTemplateOutlet="extraLeftTemplate"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="extraBottomTemplate"></ng-container>
  </div>
  <div [fxLayout]="'row'" [fxLayoutAlign]="'end baseline'">
    <ng-container *ngTemplateOutlet="extraRightTemplate; context: extraRightTemplateContext"></ng-container>
  </div>
</div>
