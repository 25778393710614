import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UsersFacade } from '@vpfa/admin/users-data';
import { CustomersFacade } from '@vpfa/dealer/customers/data';
import { ProfileFacade } from '@vpfa/profile/data';
import { CustomerCreateComponent } from '../customer-create.component';

@Component({
  selector: 'vpfa-customer-create-web',
  templateUrl: './customer-create-web.component.html',
  styleUrls: ['./customer-create-web.component.scss'],
})
export class CustomerCreateWebComponent extends CustomerCreateComponent implements OnInit, OnDestroy {
  @Input() show: boolean;
  @Output() onClose = new EventEmitter<void>();

  constructor(customersFacade: CustomersFacade, profileFacade: ProfileFacade, usersFacade: UsersFacade) {
    super(customersFacade, profileFacade, usersFacade);
  }

  closeModal() {
    this.onClose.emit();
  }
}
