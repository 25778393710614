import { Component, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { CasesFacade } from '@vpfa/dealer/case/data';
import { ActivatedRoute } from '@angular/router';
import { getRouteParam } from '@vpfa/utils';
import { ReturnReportDto, UpdateCaseReturnReportCommand } from '@vpfa/rest-api/valuation';

@Component({
  selector: 'vpfa-return-report',
  templateUrl: './return-report.component.html',
  styleUrls: ['./return-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReturnReportComponent implements OnDestroy {
  report$ = this.caseFacade.returnReport$;
  processing$ = this.caseFacade.isReturnReportProcessing$;
  open$ = this.caseFacade.isReturnReportModalOpen$;

  private caseId: string;
  constructor(private caseFacade: CasesFacade, private route: ActivatedRoute) {
    this.caseId = getRouteParam(this.route.snapshot, 'caseId');
    this.caseFacade.loadReturnReport(this.caseId);
  }

  ngOnDestroy() {
    this.caseFacade.returnReportLoaded(null);
  }

  close() {
    this.caseFacade.closeReturnReportModal();
  }

  save(report: ReturnReportDto) {
    if (report) {
      const command: UpdateCaseReturnReportCommand = {
        ...report,
        aggregateRootId: this.caseId,
      };
      this.caseFacade.updateReturnReport(command);
    }
  }
}
