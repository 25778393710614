import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ImageSize } from '@vpfa/utils';
import { take } from 'rxjs/operators';
import { ImagesFacade } from '../+state/images.facade';
import { ImagesState } from '../+state/images.reducer';
import { ImageStateData } from '../interfaces/image-state-data.interface';

const IMAGE_TTL = 1000 * 60 * 30; // 30 mins
const INTERVAL_TIME = 1000 * 60 * 15; // 15 mins
const STORAGE_SIZE_WARNING_MB = 200;

type StoredImageWithContext = ImageStateData & { fileUrl: string; imageSize: ImageSize };

/*
 * Service to trigger cleanup unused images to save memory
 */
@Injectable({
  providedIn: 'root',
})
export class ImagesCleanupService {
  constructor(private imagesFacade: ImagesFacade) {}

  start() {
    setInterval(() => {
      this.imagesFacade.imagesState.pipe(take(1)).subscribe(state => {
        this.processImages(state);
      });
    }, INTERVAL_TIME);
  }

  private processImages(state: ImagesState) {
    let totalStateSizeBytes = 0;
    let removedImages = 0;
    let freedMemory = 0;

    const allImages = this.getAllImagesFromStore(state);

    if (!allImages?.length) {
      return;
    }

    const imagesUrlsInDOM = this.getAllImagesUrlsFromDOM();

    for (const image of allImages) {
      totalStateSizeBytes += image.fileSize;

      const imageExpired = image.lastUsed + IMAGE_TTL < Date.now();
      const imageFound = imagesUrlsInDOM.find(url => image.dataUrl === url);

      const imageParams = {
        imageUrl: image.fileUrl,
        size: image.imageSize,
        imageKey: image.imageKey,
      };

      if (imageFound) {
        // user can have page opened for a long time, so we do not want to remove images in this situation.
        this.imagesFacade.imageBumpLastUsedTime(imageParams);
        continue;
      }

      if (imageExpired) {
        totalStateSizeBytes -= image.fileSize;
        freedMemory += image.fileSize;
        removedImages++;
        this.imagesFacade.removeImage(imageParams);
      }
    }

    const totalStateSizeMegaBytes = Math.round(totalStateSizeBytes / 1024 / 1024);
    const freedMemoryKiloBytes = Math.round(freedMemory / 1024);

    if (removedImages > 0 && !environment.production) {
      console.log(
        `Removed ${removedImages} images from store, which freed about: ${freedMemoryKiloBytes.toLocaleString()} KB`
      );
    }

    if (totalStateSizeMegaBytes > STORAGE_SIZE_WARNING_MB) {
      console.warn(
        `Total size of stored images: ${totalStateSizeMegaBytes.toLocaleString()} MB, images count: ${allImages.length}`
      );
    }
  }

  private getAllImagesFromStore(state: ImagesState) {
    const result: StoredImageWithContext[] = [];

    Object.keys(state).forEach(url => {
      Object.keys(state[url]).forEach(imageSize => {
        const imageData = state[url][imageSize] as ImageStateData;

        if (imageData?.loaded === true) {
          result.push({
            ...imageData,
            imageSize: imageSize as ImageSize,
            fileUrl: url,
          });
        }
      });
    });

    return result;
  }

  private getAllImagesUrlsFromDOM() {
    return Array.from(document.querySelectorAll('img')).map(x => x.src);
  }
}
