import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TaxType } from '@vpfa/rest-api/valuation';
import { isNil } from 'lodash';

@Component({
  selector: 'vpfa-taxed-price-renderer',
  templateUrl: './taxed-price-renderer.component.html',
  styleUrls: ['./taxed-price-renderer.component.scss'],
})
export class TaxedPriceRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  price: number;
  taxAmount: number;
  taxValue: number;
  taxType: TaxType;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.price = this.params.value;
    this.taxAmount = this.params.data?.finalPriceTaxAmount;
    this.taxValue = this.params.data?.taxValue;
    this.taxType = this.params.colDef.cellRendererParams?.taxType;
  }

  get isPrice() {
    return !isNil(this.price);
  }

  get isTaxExcluded() {
    return this.taxType === TaxType.RegularlyTaxedTaxExcluded;
  }

  get isTaxIncluded() {
    return this.taxType === TaxType.RegularlyTaxedTaxIncluded;
  }

  get isDifferentiallyTaxed() {
    return this.taxType === TaxType.DifferentiallyTaxed;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
