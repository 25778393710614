import { FormBuilder, Validators } from '@angular/forms';
import { positiveValidator } from '@vpfa/shared/validators';
import {
  MAX_EV_RANGE_AND_CONSUMPTION_DATA_VALUE,
  MAX_TECHNICAL_DATA_VALUE,
} from '../../utils/vehicle-full-technical-data-utils';

export const technicalDataValidator = Validators.compose([positiveValidator, Validators.max(MAX_TECHNICAL_DATA_VALUE)]);
const evRangeAndConsumptionDataValidator = Validators.compose([
  positiveValidator,
  Validators.max(MAX_EV_RANGE_AND_CONSUMPTION_DATA_VALUE),
]);

export const MIN_SEATS = 0;
export const MAX_SEATS = 30;

export function emptyTechnicalDataForm(fb: FormBuilder) {
  return fb.group({
    numberSeats: [null, [Validators.min(MIN_SEATS), Validators.max(MAX_SEATS)]],
    emissionClass: [null],
    nedc: fb.group({
      urban: [null, technicalDataValidator],
      extraUrban: [null, technicalDataValidator],
      combined: [null, technicalDataValidator],
      cO2Emissions: [null, technicalDataValidator],
      efficiencyClass: [null],
      electricConsumption: [null, evRangeAndConsumptionDataValidator],
      consumptionRating: [null],
    }),
    wltp: fb.group({
      lowSpeed: [null, technicalDataValidator],
      mediumSpeed: [null, technicalDataValidator],
      highSpeed: [null, technicalDataValidator],
      extraHighSpeed: [null, technicalDataValidator],
      combined: [null, technicalDataValidator],
      cO2EmissionCombined: [null, technicalDataValidator],
      combinedRange: [null, evRangeAndConsumptionDataValidator],
      cityRange: [null, evRangeAndConsumptionDataValidator],
      electricPowerConsumption: [null, evRangeAndConsumptionDataValidator],

      // #region CSG-81
      co2LabelCalculated: [null],
      phevCo2LabelCalculated: [null],
      gasConsumptionLowPhase: [null, technicalDataValidator],
      gasConsumptionMediumPhase: [null, technicalDataValidator],
      gasConsumptionHighPhase: [null, technicalDataValidator],
      gasConsumptionExtraHighPhase: [null, technicalDataValidator],
      gasConsumptionCombinedPhase: [null, technicalDataValidator],
      gasCO2EmissionCombined: [null, technicalDataValidator],
      cO2EmissionCombinedEB: [null, technicalDataValidator],
      consumptionRatingEB: [null, technicalDataValidator],
      fuelConsumptionLowPhaseEB: [null, technicalDataValidator],
      fuelConsumptionMediumPhaseEB: [null, technicalDataValidator],
      fuelConsumptionHighPhaseEB: [null, technicalDataValidator],
      fuelConsumptionExtraHighPhaseEB: [null, technicalDataValidator],
      fuelConsumptionCombinedPhaseEB: [null, technicalDataValidator],
      fuelConsumptionLowPhasePE: [null, technicalDataValidator],
      fuelConsumptionMediumPhasePE: [null, technicalDataValidator],
      fuelConsumptionHighPhasePE: [null, technicalDataValidator],
      fuelConsumptionExtraHighPhasePE: [null, technicalDataValidator],
      fuelConsumptionCombinedPhasePE: [null, technicalDataValidator],
      cO2WLTPEfficiencyClassEBCustom: [null],
      cO2WLTPEfficiencyClassCustom: [null],
      // #endregion CSG-81
    }),
  });
}
