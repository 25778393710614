import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumberFormatterDirective } from './number-formatter/number-formatter.directive';
import { SubmitIfValidDirective } from './submit-if-valid/submit-if-valid.directive';
import { IntervalFormatterDirective } from './interval-formater/interval-formatter.directive';
import { MultipleTransclusionDirective } from './mulitple-transclusion/multiple-transclusion.directive';
import { UpperCaseInputDirective } from './uppercase-input/uppercase-input.directive';
import { StickyDirective } from './sticky/sticky.directive';
import { TrimInputDirective } from './trim-input/trim-input.directive';
import { UniqueIdDirective } from './unique-id-directive/unique-id.directive';
import { SensitiveDataDirective } from './sensitive-data/sensitive-data.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    NumberFormatterDirective,
    SubmitIfValidDirective,
    IntervalFormatterDirective,
    MultipleTransclusionDirective,
    UpperCaseInputDirective,
    StickyDirective,
    TrimInputDirective,
    UniqueIdDirective,
    SensitiveDataDirective,
  ],
  exports: [
    NumberFormatterDirective,
    SubmitIfValidDirective,
    IntervalFormatterDirective,
    MultipleTransclusionDirective,
    UpperCaseInputDirective,
    StickyDirective,
    TrimInputDirective,
    UniqueIdDirective,
    SensitiveDataDirective,
  ],
})
export class SharedDirectivesModule {}
