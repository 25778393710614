<vpfa-field-container
  [heightCompensationPx]="heightCompensationPx"
  [displayErrors]="shouldDisplayErrors$.getValue()"
  [control]="getFormControl()"
  [errorMsgVertPosition]="errorMsgVertPosition"
  [errorMessages]="errorMessages"
>
  <nz-form-control>
    <input
      *ngIf="!uppercase"
      nzSize="small"
      #input
      vpfaCharCountLimiter
      (focus)="onFocus()"
      (blur)="onBlur()"
      (keydown.enter)="onSubmit($event)"
      [placeholder]="placeholder"
      [formControl]="getFormControl()"
      [readOnly]="readonly"
      nz-input
      [type]="inputType"
      class="text-field"
      [autocomplete]="autocomplete"
    />
    <input
      *ngIf="uppercase"
      nzSize="small"
      #inputUppercase
      vpfaCharCountLimiter
      vpfaUpperCase
      (focus)="onFocus()"
      (blur)="onBlur()"
      (keydown.enter)="onSubmit($event)"
      [placeholder]="placeholder"
      [formControl]="getFormControl()"
      [readOnly]="readonly"
      nz-input
      [type]="inputType"
      class="text-field"
      [autocomplete]="autocomplete"
    />
  </nz-form-control>
</vpfa-field-container>
