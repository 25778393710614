import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackLinkComponent } from './components/back-link/back-link.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, TranslateModule],
  declarations: [BackLinkComponent],
  exports: [BackLinkComponent],
})
export class UiKitBackLinkModule {}
