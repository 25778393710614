import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DateFormatWidthStr } from '@vpfa/shared/pipes';

@Component({
  selector: 'vpfa-unique-data-valuation-month-picker',
  templateUrl: './unique-data-valuation-month-picker.component.html',
  styleUrls: ['./unique-data-valuation-month-picker.component.scss'],
})
export class UniqueDataValuationMonthPickerComponent {
  @Input() label: string;
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() fcName: string;
  @Input() notAvailable = false;

  dateFormat: DateFormatWidthStr = 'short';
}
