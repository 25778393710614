import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vpfa-mobile-back',
  templateUrl: './mobile-back.component.html',
  styleUrls: ['./mobile-back.component.scss'],
})
export class MobileBackComponent {
  @Output() closeAction = new EventEmitter<void>();
}
