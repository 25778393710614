import { HttpClient } from '@angular/common/http';
import { defaultUiConfig, getTranslateFileName } from '../utils/language.utils';
import { CustomTranslateHttpLoader } from './custom-translate-http.loader';
import { DeploymentVersionService, EnvironmentsService } from '@vpfa/environments/data';
import { NzI18nService } from 'ng-zorro-antd/i18n';

export function createTranslateLoader(
  http: HttpClient,
  environmentsService: EnvironmentsService,
  deploymentVersionService: DeploymentVersionService,
  i18n: NzI18nService
): CustomTranslateHttpLoader {
  return new CustomTranslateHttpLoader(
    http,
    environmentsService,
    '.json',
    getTranslateFileName(defaultUiConfig.uiLang, defaultUiConfig.countryCode),
    deploymentVersionService,
    i18n
  );
}
