import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SelectOption } from '@vpfa/ui-kit';
import { UntypedFormBuilder } from '@angular/forms';
import { PAGE_SIZES, DEFAULT_PAGE_SIZE } from '../../models/data-table-page-sizes';
import { DataTablePaginationOptions } from '../../models/data-table-pagination-options';

@Component({
  selector: 'vpfa-data-table-pagination',
  templateUrl: './data-table-pagination.component.html',
  styleUrls: ['./data-table-pagination.component.scss'],
})
export class DataTablePaginationComponent {
  @Input() sizeOptions: SelectOption[] = PAGE_SIZES;
  @Input() set paginationOptions(options: DataTablePaginationOptions) {
    this.options = options;

    if (options.totalPages === 0) {
      options.totalPages = 1;
    } else if (options.currentPage < 1) {
      options.currentPage = 1;
    }

    if (options.currentPage > options.totalPages) {
      options.currentPage = options.totalPages;
    }

    this.setPagesToDisplay();
    this.setVisibleItems();
  }

  @Output() pageSizeChange = new EventEmitter<number>();
  @Output() pageChange = new EventEmitter<number>();

  paginationFormGroup = this.fb.group({ pageSize: DEFAULT_PAGE_SIZE });

  pagesToDisplay = [1];
  numOfVisiblePages = 7;
  pageSize: SelectOption = DEFAULT_PAGE_SIZE;
  visibleItemsStart = 0;
  visibleItemsEnd = 0;

  options: DataTablePaginationOptions = {
    currentPage: 1,
    totalItems: 0,
    totalPages: 1,
  };

  constructor(private fb: UntypedFormBuilder) {}

  onPageSizeChange(size: SelectOption) {
    if (size && size !== this.pageSize) {
      this.pageSize = size;
      this.pageSizeChange.emit(size.value);
    }
  }

  private setPagesToDisplay() {
    let start = 1;
    let end = this.options.totalPages;
    const center = Math.floor(this.numOfVisiblePages / 2);
    if (this.options.totalPages > this.numOfVisiblePages) {
      if (this.options.currentPage < center + 1) {
        start = 1;
        end = this.numOfVisiblePages;
      } else if (this.options.currentPage > this.options.totalPages - center) {
        start = this.options.totalPages - this.numOfVisiblePages + 1;
        end = this.options.totalPages;
      } else {
        start = this.options.currentPage - center;
        end = this.options.currentPage + center;
      }
    }
    this.pagesToDisplay = [];
    for (let i = start; i <= end; i++) {
      this.pagesToDisplay.push(i);
    }
  }

  private setVisibleItems() {
    this.visibleItemsEnd = this.options.currentPage * this.pageSize.value;
    this.visibleItemsEnd =
      this.visibleItemsEnd > this.options.totalItems ? this.options.totalItems : this.visibleItemsEnd;
    this.visibleItemsStart = 0;
    if (this.visibleItemsEnd) {
      this.visibleItemsStart = (this.options.currentPage - 1) * this.pageSize.value + 1;
    }
  }
}
