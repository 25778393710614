import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedLoadersService {
  readonly isSectionLoaderActiveSubject$ = new BehaviorSubject(false);

  setIsSectionLoaderActiveSubjectState(value: boolean) {
    this.isSectionLoaderActiveSubject$.next(value);
  }
}
