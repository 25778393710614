<div fxLayout="row" fxLayoutAlign="space-between center" class="mb">
  <vpfa-label [text]="label | translate"></vpfa-label>
  <vpfa-switch *vpfaResponsiveIf="'web'" [parentFormGroup]="formGroup" [fc]="formGroup?.get(fcName)"></vpfa-switch>
  <vpfa-switch
    *vpfaResponsiveIf="'mobile'"
    [parentFormGroup]="formGroup"
    [fc]="formGroup?.get(fcName)"
    [disabled]="true"
  ></vpfa-switch>
</div>
