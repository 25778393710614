import { Action } from '@ngrx/store';
import {
  BranchDto,
  BusinessDto,
  IsLogoAvailableDto,
  ProfileDto,
  UserContext,
  CommandHandlerResponse,
  UpdateUserIdentificationVehicleTypeCommand,
  UpdateUserBroadcastPremiumFeatureSettingsCommand,
} from '@vpfa/rest-api/admin';
import { CountryConfigurationDto } from '@vpfa/rest-api/valuation';

export enum ProfileActionTypes {
  InitProfileEffects = '[Init Auth Effects] Init Profile Effects',
  LoadProfile = '[Profile] Load Profile',
  LoadProfileSuccess = '[Profile] Load Profile Success',
  LoadProfileFailure = '[Profile] Load Profile Failure',
  ResetProfile = '[Profile] Reset Profile',
  LoadUserContext = '[Profile] Load UserContext',
  UserContextLoaded = '[Profile] UserContext Loaded',
  UserContextLoadError = '[Profile] UserContext Load Error',
  LoadUserBusinessDetails = '[Profile] Load User Business Details',
  UserBusinessDetailsLoaded = '[Profile] User Business Details Loaded',
  UserBusinessDetailsLoadError = '[Profile] User Business Details Load Error',
  LoadUserBranchDetails = '[Profile] Load User Branch Details',
  UserBranchDetailsLoaded = '[Profile] User Branch Details Loaded',
  UserBranchDetailsLoadError = '[Profile] User Branch Details Load Error',
  CheckLogoAvailability = '[Profile] Check Logo Availability',
  CheckLogoAvailabilitySuccess = '[Profile] Check Logo Availability Success',
  CheckLogoAvailabilityError = '[Profile] Check Logo Availability Error',
  LoadCountryConfiguration = '[Profile] Load Country Configuration',
  LoadCountryConfigurationSuccess = '[Profile] Load Country Configuration Success',
  LoadCountryConfigurationError = '[Profile] Load Country Configuration Error',

  UpdateLastSelectedVehicleType = '[Profile] Update Last Selected Vehicle Type',
  UpdateLastSelectedVehicleTypeSuccess = '[Profile] Update Last Selected Vehicle Type Success',
  UpdateLastSelectedVehicleTypeError = '[Profile] Update Last Selected Vehicle Type Error',

  UpdateUserBroadcastPremiumFeatureSettings = '[Profile] Update User Broadcast Premium Feature Settings',
  UpdateUserBroadcastPremiumFeatureSettingsSuccess = '[Profile] Update User Broadcast Premium Feature Settings Success',
  UpdateUserBroadcastPremiumFeatureSettingsError = '[Profile] Update User Broadcast Premium Feature Settings Error',
}

export class InitProfileEffects implements Action {
  readonly type = ProfileActionTypes.InitProfileEffects;
}

export class LoadProfile implements Action {
  readonly type = ProfileActionTypes.LoadProfile;
}

export class LoadProfileSuccess implements Action {
  readonly type = ProfileActionTypes.LoadProfileSuccess;
  constructor(public payload: ProfileDto) {}
}

export class LoadProfileFailure implements Action {
  readonly type = ProfileActionTypes.LoadProfileFailure;
  constructor(public payload: { error: any }) {}
}

export class ResetProfile implements Action {
  readonly type = ProfileActionTypes.ResetProfile;
}

export class LoadUserContext implements Action {
  readonly type = ProfileActionTypes.LoadUserContext;
  constructor(public payload?: string) {}
}

export class UserContextLoadError implements Action {
  readonly type = ProfileActionTypes.UserContextLoadError;
  constructor(public payload: any) {}
}

export class UserContextLoaded implements Action {
  readonly type = ProfileActionTypes.UserContextLoaded;
  constructor(public payload: { context: UserContext; userHasBranchAssigned: boolean }) {}
}

export class LoadUserBusinessDetails implements Action {
  readonly type = ProfileActionTypes.LoadUserBusinessDetails;
  constructor(public payload: string) {}
}

export class UserBusinessDetailsLoadError implements Action {
  readonly type = ProfileActionTypes.UserBusinessDetailsLoadError;
}

export class UserBusinessDetailsLoaded implements Action {
  readonly type = ProfileActionTypes.UserBusinessDetailsLoaded;
  constructor(public payload: BusinessDto) {}
}

export class LoadUserBranchDetails implements Action {
  readonly type = ProfileActionTypes.LoadUserBranchDetails;
  constructor(public payload: string) {}
}

export class UserBranchDetailsLoadError implements Action {
  readonly type = ProfileActionTypes.UserBranchDetailsLoadError;
}

export class UserBranchDetailsLoaded implements Action {
  readonly type = ProfileActionTypes.UserBranchDetailsLoaded;
  constructor(public payload: BranchDto) {}
}

export class CheckLogoAvailability implements Action {
  readonly type = ProfileActionTypes.CheckLogoAvailability;
  constructor(public payload: { countryId: string; businessId: string }) {}
}

export class CheckLogoAvailabilitySuccess implements Action {
  readonly type = ProfileActionTypes.CheckLogoAvailabilitySuccess;
  constructor(public payload: { countryId: string; businessId: string; availability: IsLogoAvailableDto }) {}
}

export class CheckLogoAvailabilityError implements Action {
  readonly type = ProfileActionTypes.CheckLogoAvailabilityError;
}

export class LoadCountryConfiguration implements Action {
  readonly type = ProfileActionTypes.LoadCountryConfiguration;
  constructor(public payload: { countryId: string }) {}
}

export class LoadCountryConfigurationSuccess implements Action {
  readonly type = ProfileActionTypes.LoadCountryConfigurationSuccess;
  constructor(public payload: CountryConfigurationDto) {}
}

export class LoadCountryConfigurationError implements Action {
  readonly type = ProfileActionTypes.LoadCountryConfigurationError;
}

export class UpdateLastSelectedVehicleType implements Action {
  readonly type = ProfileActionTypes.UpdateLastSelectedVehicleType;
  constructor(public payload: UpdateUserIdentificationVehicleTypeCommand) {}
}

export class UpdateLastSelectedVehicleTypeSuccess implements Action {
  readonly type = ProfileActionTypes.UpdateLastSelectedVehicleTypeSuccess;
  constructor(public vehicleType: number) {}
}

export class UpdateLastSelectedVehicleTypeError implements Action {
  readonly type = ProfileActionTypes.UpdateLastSelectedVehicleTypeError;
}

export class UpdateUserBroadcastPremiumFeatureSettings implements Action {
  readonly type = ProfileActionTypes.UpdateUserBroadcastPremiumFeatureSettings;
  constructor(public payload: UpdateUserBroadcastPremiumFeatureSettingsCommand) {}
}

export class UpdateUserBroadcastPremiumFeatureSettingsSuccess implements Action {
  readonly type = ProfileActionTypes.UpdateUserBroadcastPremiumFeatureSettingsSuccess;
  constructor(public hideBroadcastPremiumFeaturePopup: boolean) {}
}

export class UpdateUserBroadcastPremiumFeatureSettingsError implements Action {
  readonly type = ProfileActionTypes.UpdateUserBroadcastPremiumFeatureSettingsError;
}

export type ProfileActions =
  | InitProfileEffects
  | LoadProfile
  | LoadProfileSuccess
  | LoadProfileFailure
  | ResetProfile
  | LoadUserContext
  | UserContextLoaded
  | UserContextLoadError
  | LoadUserBusinessDetails
  | UserBusinessDetailsLoaded
  | UserBusinessDetailsLoadError
  | LoadUserBranchDetails
  | UserBranchDetailsLoaded
  | UserBranchDetailsLoadError
  | CheckLogoAvailability
  | CheckLogoAvailabilitySuccess
  | CheckLogoAvailabilityError
  | LoadCountryConfiguration
  | LoadCountryConfigurationSuccess
  | LoadCountryConfigurationError
  | UpdateLastSelectedVehicleType
  | UpdateLastSelectedVehicleTypeSuccess
  | UpdateLastSelectedVehicleTypeError
  | UpdateUserBroadcastPremiumFeatureSettings
  | UpdateUserBroadcastPremiumFeatureSettingsSuccess
  | UpdateUserBroadcastPremiumFeatureSettingsError;
