import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { isNil } from 'lodash';
import { SelectOption } from '@vpfa/ui-kit';

@Component({
  selector: 'vpfa-vehicle-technical-data-select-field',
  templateUrl: './vehicle-technical-data-select-field.component.html',
  styleUrls: ['./vehicle-technical-data-select-field.component.scss'],
})
export class VehicleTechnicalDataSelectFieldComponent {
  @Input() label: string;
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() fcName: string;
  @Input() selectOptions: SelectOption[];
  @Input() hiddenOptions: SelectOption[];

  @Output() onSelectionChange = new EventEmitter();

  get readOnlyValue() {
    const option = this.selectOptions?.find(opt => opt.value === this.parentFormGroup.get(this.fcName).value?.value);
    const hiddenOption = this.hiddenOptions?.find(
      opt => opt.value === this.parentFormGroup.get(this.fcName).value?.value
    );
    return option?.name ?? hiddenOption?.name;
  }
}
