<div class="settings-container">
  <div *ngFor="let column of columns" class="height">
    <label
      nz-checkbox
      [ngModel]="column.isVisible()"
      [nzDisabled]="column.getColDef().lockVisible || (column.isVisible() && isLastActive)"
      (ngModelChange)="onColumnVisibleChange(column.getColId(), $event)"
    >
      {{ gridApi.getDisplayNameForColumn(column, 'header') }}
    </label>
    <span class="extra-width">&nbsp;</span>
  </div>
</div>
