import { Component, OnDestroy, Input } from '@angular/core';
import { BaseVehicleList } from '../base-vehicle-list';
import {
  GridReadyEvent,
  RowClickedEvent,
  PaginationChangedEvent,
  ColumnApi,
  ColDef,
  GridOptions,
  ValueFormatterParams,
} from 'ag-grid-community';
import { Subject } from 'rxjs';
import { isNil } from 'lodash';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {
  autoSizeAllColumns,
  DataTableColDef,
  setColumnWidthForAutoSize,
  DataTableActionEvent,
} from '@vpfa/ui-kit/data-table';
import { VehicleDto } from '@vpfa/rest-api/identification';
import { vehicleListColDef, specIconParams } from './vehicle-list-col-defs';
import { DateLocalePipe } from '@vpfa/shared/pipes';
import { TranslateService } from '@ngx-translate/core';
import { SearchFeatures } from '@vpfa/shared/interfaces';

@Component({
  selector: 'vpfa-web-vehicle-list',
  templateUrl: './web-vehicle-list.component.html',
  styleUrls: ['./web-vehicle-list.component.scss'],
  providers: [DateLocalePipe],
})
export class WebVehicleListComponent extends BaseVehicleList implements OnDestroy {
  @Input()
  set searchFeatures(searchFeatures: SearchFeatures) {
    if (isNil(searchFeatures)) {
      return;
    }
    this.colDefs = setColumnWidthForAutoSize(
      vehicleListColDef(
        data => this.prodDateFormatter(data),
        data => this.isSpecIconDisable(data),
        data => this.specIconErrorInfo(data),
        this.translateService.instant('common.noValue'),
        searchFeatures
      )
    );
  }

  private columnApi: ColumnApi;
  private onDestroy$ = new Subject<void>();

  colDefs: DataTableColDef<VehicleDto>[] = setColumnWidthForAutoSize(
    vehicleListColDef(
      data => this.prodDateFormatter(data),
      data => this.isSpecIconDisable(data),
      data => this.specIconErrorInfo(data),
      this.translateService.instant('common.noValue')
    )
  );

  defaultColDef: ColDef = {
    suppressMovable: true,
    suppressMenu: true,
    sortable: true,
    filter: false,
    floatingFilter: false,
  };

  gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    getRowHeight: () => {
      // HACK: gridOptions.rowHeight not working
      return 35;
    },
  };

  wizardModeClass = {
    'wizard-mode': () => this.wizardMode === true,
  };

  constructor(protected dateLocale: DateLocalePipe, protected translateService: TranslateService) {
    super(dateLocale, translateService);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
  onActionClicked(event: DataTableActionEvent) {
    if (event.action === specIconParams.actionName) {
      this.showSpec.emit(event.data);
    }
  }

  prodDateFormatter = (params: ValueFormatterParams) => {
    const formattedDate = this.dateLocale.transform(params.value, 'shortDate', null, null, true);
    return formattedDate ? formattedDate : this.translateService.instant('common.noValue');
  };

  onGridReady(event: GridReadyEvent) {
    this.columnApi = event.columnApi;

    const columnSizeShouldRecalculate = new Subject();

    event.api.addEventListener('rowClicked', (clickEvent: RowClickedEvent) => this.onRowClick(clickEvent.data));

    event.api.addEventListener('gridColumnsChanged', columnChangeEvent => {
      columnSizeShouldRecalculate.next(columnChangeEvent);
    });

    // Add this because only actual rendered column will we autoSized
    event.api.addEventListener('virtualColumnsChanged', virtualColumnsChangedEvent => {
      columnSizeShouldRecalculate.next(virtualColumnsChangedEvent);
    });

    event.api.addEventListener('paginationChanged', (paginationChangedEvent: PaginationChangedEvent) => {
      if (!isNil(paginationChangedEvent.newData)) {
        columnSizeShouldRecalculate.next(paginationChangedEvent);
      }
    });

    columnSizeShouldRecalculate.pipe(debounceTime(100), takeUntil(this.onDestroy$)).subscribe(ev => {
      // TODO: should autoSize one column only once when it is rendered, after change page it should be autoSize again
      autoSizeAllColumns(this.columnApi);
    });

    columnSizeShouldRecalculate.next(null);
  }
}
