import { FileUploadType, UploadSizeLimit } from '../../../models/file-types';
import { TranslateService } from '@ngx-translate/core';
import {
  BasicNotificationsService,
  FileNotAcceptedReason,
  FilesNotificationsService,
} from '@vpfa/shared/notifications';
import { isNil } from 'lodash';
import { FileRejectedReason } from '@vpfa/shared/interfaces';

export const perFileSizeLimitFilter = (
  uploadList,
  fileUploadType: FileUploadType,
  translateService: TranslateService,
  filesNotifications: FilesNotificationsService
) => {
  const list = uploadList.filter(file => file.size < getFileMaxSize(fileUploadType));
  filesNotAllowed(
    uploadList.filter(file => file.size > getFileMaxSize(fileUploadType)).map(file => file.name),
    'excceedsFileSizeLimit',
    fileUploadType,
    translateService,
    filesNotifications
  );
  return list;
};

export const totalSizeLimitFilter = (
  uploadList,
  fileUploadType: FileUploadType,
  translateService: TranslateService,
  filesNotifications: FilesNotificationsService,
  totalUploadSize: number,
  duplicateFileList: File[] = []
) => {
  const list = uploadList.filter((file, fileIndex) => {
    const prognosedUploadSize =
      totalUploadSize +
      uploadList.reduce((previousValue, currentFile, currentIndex) => {
        const foundDuplicateFile = duplicateFileList.find(el => el.name === currentFile.name);
        return (
          (currentIndex > fileIndex ? previousValue : previousValue + currentFile.size) -
          (foundDuplicateFile ? foundDuplicateFile.size : 0)
        );
      }, 0);

    return prognosedUploadSize < getTotalMaxSize(fileUploadType);
  });
  const notAllowed = uploadList.filter(file => list.indexOf(file) < 0).map(file => file.name);

  filesNotAllowed(notAllowed, 'excceedsTotalSizeLimit', fileUploadType, translateService, filesNotifications);

  return list;
};

export const getFileMaxSize = (fileUploadType: FileUploadType): UploadSizeLimit => {
  switch (fileUploadType) {
    case FileUploadType.CaseGalleryImages:
      return UploadSizeLimit.PerImage;
    case FileUploadType.Logo:
      return UploadSizeLimit.PerImage;

    case FileUploadType.Document:
    case FileUploadType.Pdf:
      return UploadSizeLimit.PerSingleDocument;

    case FileUploadType.Image:
    case FileUploadType.Jpg:
      return UploadSizeLimit.PerImage;

    case FileUploadType.PromoImages:
      return UploadSizeLimit.PerBusiness;
    case FileUploadType.StandardImage:
      return UploadSizeLimit.PerStandardImage;
  }
};

export const getTotalMaxSize = (fileUploadType: FileUploadType): UploadSizeLimit => {
  switch (fileUploadType) {
    case FileUploadType.CaseGalleryImages:
      return UploadSizeLimit.PerCaseGallery;
    case FileUploadType.Logo:
      return UploadSizeLimit.PerImage;

    case FileUploadType.Document:
      return UploadSizeLimit.PerSingleDocument;

    case FileUploadType.PromoImages:
      return UploadSizeLimit.PerBusiness;
    case FileUploadType.StandardImage:
      return UploadSizeLimit.PerStandardImage;
    case FileUploadType.Json:
      return UploadSizeLimit.AdminFile;
  }
};

export const filesNotAllowed = (
  fileNames: string[],
  rejectReason: FileRejectedReason,
  fileUploadType: FileUploadType,
  translateService: TranslateService,
  notification: FilesNotificationsService
) => {
  if (!isNil(fileNames) && fileNames.length) {
    fileNames.forEach(name => {
      notification.error(name, getFileRejectionReason(rejectReason, fileUploadType));
    });
  }
};

export const getFileRejectionReason = (
  frr:
    | 'excceedsFileSizeLimit'
    | 'excceedsTotalSizeLimit'
    | 'wrongFileType'
    | 'fileSizeIsZero'
    | 'fileExtensionDoesntMatchFileContent',
  fileUploadType: FileUploadType
) => {
  switch (frr) {
    case 'excceedsFileSizeLimit':
      return fileUploadType === FileUploadType.Document
        ? FileNotAcceptedReason.ExceedsDocumentFileSizeLimit
        : FileNotAcceptedReason.ExceedsFileSizeLimit;
    case 'fileExtensionDoesntMatchFileContent':
      return FileNotAcceptedReason.FileExtensionDoesNotMatchFileContent;
    case 'fileSizeIsZero':
      return FileNotAcceptedReason.FileSizeIsZero;

    case 'excceedsTotalSizeLimit':
      switch (fileUploadType) {
        case FileUploadType.CaseGalleryImages:
          return FileNotAcceptedReason.ExceedsTotalSizeLimitForCaseImages;
        case FileUploadType.Csv:
        case FileUploadType.Document:
          return FileNotAcceptedReason.ExceedsTotalSizeLimitForCaseDocuments;
        case FileUploadType.PromoImages:
        case FileUploadType.StandardImage:
          return FileNotAcceptedReason.ExceedsTotalSizeLimitForBusiness;
      }
      break;
    case 'wrongFileType':
      return FileNotAcceptedReason.IncorrectFileType;
  }
};
