import { Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { isNil } from 'lodash';

export const natCodeLengthValidatorKey = 'natCodeLength';
export function natCodeLengthValidator(min: number): ValidatorFn {
  return (control: AbstractControl) => {
    const validateErrors = Validators.minLength(min)(control);
    if (isNil(validateErrors)) {
      return null;
    }
    return {
      [natCodeLengthValidatorKey]: true
    };
  }
}