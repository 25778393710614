<vpfa-setting-box *vpfaResponsiveIf="'web'; else mobileView" [divider]="false" [formGroup]="parentFormGroup">
  <ng-container label>
    <vpfa-label [text]="label"></vpfa-label>
  </ng-container>
  <ng-container content>
    <vpfa-iso-date-picker
      *ngIf="!notAvailable; else notAvailableTemplate"
      [readonly]="true"
      [allowClear]="false"
      style="width: 100%; height: 35px"
      [formControl]="parentFormGroup.get(fcName)"
      [format]="dateFormat | localeDateFormat"
    >
    </vpfa-iso-date-picker>
  </ng-container>
</vpfa-setting-box>

<ng-template #mobileView>
  <vpfa-vehicle-technical-data-single-field
    [label]="label"
    [value]="!notAvailable ? (parentFormGroup.get(fcName)?.value | dateLocale) : ('common.noValue' | translate)"
  ></vpfa-vehicle-technical-data-single-field>
</ng-template>

<ng-template #notAvailableTemplate>
  {{ 'common.noValue' | translate }}
</ng-template>

