<div
  class="input-container"
  [ngClass]="{ readonly: readonly || this.formGroup?.get(this.valueControlName)?.disabled }"
  [formGroup]="formGroup"
>
  <vpfa-field-container
    [displayErrors]="!formGroup.get(valueControlName).valid && focused"
    [control]="formGroup.get(valueControlName)"
    [errorMessages]="errorMessages"
    [errorMsgVertPosition]="errorMsgVertPosition"
    [heightCompensationPx]="'-10px'"
  >
    <nz-input-group
      [nzAddOnBefore]="prefixTemplate"
      [nzAddOnAfter]="suffixTemplate"
      [nzPrefix]="simplePrefix"
      [nzSuffix]="simpleSuffix"
    >
      <input
        type="text"
        nz-input
        vpfaNumberFormatter
        (blur)="onBlur()"
        (focus)="onFocus()"
        [readOnly]="readonly"
        [maxFractionNumber]="maxFractionNumber"
        [formControlName]="valueControlName"
        [showFractionZeros]="showFractionZeros"
        [allowNegativeNumber]="allowNegativeNumber"
        [convertNegativeValue]="convertNegativeValue"
        vpfaCharCountLimiter
        [maxCharCount]="maxValueLength"
        [ngClass]="{ 'suffix-prefix': !isDropdownSuffix }"
      />
    </nz-input-group>
  </vpfa-field-container>
</div>
