import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { isNil } from 'lodash';

@Component({
  selector: 'vpfa-date-renderer',
  templateUrl: './date-renderer.component.html',
  styleUrls: ['./date-renderer.component.scss']
})
export class DateRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  public format = 'shortDate';

  agInit(params: ICellRendererParams): void {
    this.params = params;

    if (!isNil(params.colDef.cellRendererParams)) {
      this.format = params.colDef.cellRendererParams;
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
