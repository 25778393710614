import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AuthState } from './auth.reducer';
import { authQuery } from './auth.selectors';
import { LoginPayload } from '../models/login-payload';
import { Login, PasswordResetRequestClear, RequestPasswordReset } from './actions/login.actions';
import { Logout } from './actions/logout.actions';
import { LoadCountry, LoadCountryLogoAndHelp } from './actions/locale.actions';
import {
  CheckPasswordCompatibility,
  ClearTemporaryPassword,
  ChangeTemporaryPassword,
} from './actions/temporary.actions';
import { Params } from '@angular/router';

@Injectable()
export class AuthFacade {
  error$ = this.store.pipe(select(authQuery.getAuthError));
  loading$ = this.store.pipe(select(authQuery.getLoading));
  decodedToken$ = this.store.pipe(select(authQuery.getDecodedToken));
  isAuthenticated$ = this.store.pipe(select(authQuery.getIsAuthenticated));
  tokenLoadedFromCache$ = this.store.pipe(select(authQuery.getLoadedFromCacheState));
  countryBaseInformation$ = this.store.pipe(select(authQuery.getCountryBaseInformation));
  temporaryLoginData$ = this.store.pipe(select(authQuery.getTemporaryLoginData));
  isChangePasswordProcessing$ = this.store.pipe(select(authQuery.getChangePasswordProcessing));
  isChangePasswordSuccess$ = this.store.pipe(select(authQuery.getChangePasswordSuccess));
  isPasswordCompatible$ = this.store.pipe(select(authQuery.getPasswordCompatibility));
  isPasswordResetRequestProcessed$ = this.store.pipe(select(authQuery.getIsPasswordResetRequestProcessed));
  isPasswordResetRequestSuccessful$ = this.store.pipe(select(authQuery.getIsPasswordResetRequestSuccessful));
  getIsPasswordResetRequestSent$ = this.store.pipe(select(authQuery.getIsPasswordResetRequestSent));
  isLoadingCountryBaseInformation$ = this.store.pipe(select(authQuery.getIsLoadingCountryBaseInformation));

  constructor(private store: Store<AuthState>) {}

  login(payload: LoginPayload) {
    this.store.dispatch(new Login(payload));
  }

  logout(redirectAddress?: string) {
    this.store.dispatch(new Logout(redirectAddress));
  }

  loadCountry(countryUrl: string,) {
    this.store.dispatch(new LoadCountry({ countryUrl }));
  }

  loadCountryLogoAndHelp(countryUrl: string) {
    this.store.dispatch(new LoadCountryLogoAndHelp({ countryUrl }));
  }

  checkPasswordCompatibility(password: string) {
    this.store.dispatch(new CheckPasswordCompatibility(password));
  }

  clearTemporaryPassword() {
    this.store.dispatch(new ClearTemporaryPassword());
  }

  changePassword(password: string) {
    this.store.dispatch(new ChangeTemporaryPassword(password));
  }

  requestPasswordReset(email: string, recaptchaToken: string) {
    this.store.dispatch(new RequestPasswordReset({ email, recaptchaToken }));
  }

  requestClear() {
    this.store.dispatch(new PasswordResetRequestClear());
  }
}
