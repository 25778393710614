import { Component, EventEmitter } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DataTableActionEvent, DataTableActionListItem, DataTableAction } from '../../models/data-table-action';
import { isNil } from 'lodash';

export interface IActionsRendererParams extends ICellRendererParams {
  buttonHeightPx?: number;
}

@Component({
  selector: 'vpfa-actions-renderer',
  templateUrl: './actions-renderer.component.html',
  styleUrls: ['./actions-renderer.component.scss'],
})
export class ActionsRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  actionEmitter: EventEmitter<DataTableActionEvent>;
  listItems: DataTableActionListItem[] = [];
  dataTableActionMenuVisible = false;

  buttonHeightPx = 40;

  agInit(params: IActionsRendererParams): void {
    this.params = params;

    this.buttonHeightPx = params?.buttonHeightPx ?? 40;

    if (this.params) {
      const context = this.params.context;
      if (context && context.componentParent && context.componentParent.actionClicked) {
        this.actionEmitter = context.componentParent.actionClicked;
      }

      if (!isNil(this.params.value)) {
        this.setListItems(this.params.value);
      } else {
        this.setDefaultListItems();
      }
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  emitAction(action: DataTableAction) {
    this.dataTableActionMenuVisible = false;
    if (this.actionEmitter) {
      this.actionEmitter.emit({
        action: action,
        data: this.params.data,
      });
    }
  }

  onGoToDetails(e: MouseEvent) {
    e.stopImmediatePropagation();
    this.emitAction(DataTableAction.DETAILS_BUTTON);
  }

  getDropdownItemList() {
    return this.listItems.filter(el => el.action !== DataTableAction.DETAILS_BUTTON);
  }

  isGoToDetailsButtonEnabled() {
    return Boolean(this.listItems.find(el => el.action === DataTableAction.DETAILS_BUTTON));
  }

  private setListItems(items: DataTableActionListItem[]) {
    this.listItems = items;
    this.sortListItemsByOrder();
  }

  private sortListItemsByOrder() {
    if (this.listItems.every(x => !isNil(x.order))) {
      this.listItems = this.listItems.slice().sort((a, b) => a?.order - b?.order);
    }
  }

  private setDefaultListItems() {
    this.listItems = [
      {
        name: 'common.edit',
        action: DataTableAction.EDIT,
      },
      {
        name: 'common.delete',
        action: DataTableAction.DELETE,
        question: 'common.deleteQuestion',
      },
    ];
  }
}
