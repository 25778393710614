import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShortLabelValueComponent } from './components/short-label-value/short-label-value.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

@NgModule({
  imports: [CommonModule, FlexLayoutModule],
  declarations: [ShortLabelValueComponent],
  exports: [ShortLabelValueComponent]
})
export class UiKitShortLabelValueModule {}
