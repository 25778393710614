import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { isNil } from 'lodash';

@Component({
  selector: 'vpfa-translate-renderer',
  templateUrl: './translate-renderer.component.html',
  styleUrls: ['./translate-renderer.component.scss'],
})
export class TranslateRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  translationKeys: string[] = [];

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.setTranslationKeys();
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.setTranslationKeys();
    return true;
  }

  setTranslationKeys() {
    const value = isNil(this.params.valueFormatted) ? this.params.value : this.params.valueFormatted;

    this.translationKeys = [];

    if (!isNil(value) && value !== '') {
      this.translationKeys = typeof value === 'string' ? value.split(',') : [...value];
    }
  }

  isNotLastElement(index: number): boolean {
    return index != this.translationKeys.length - 1;
  }
}
