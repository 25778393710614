import { Component, EventEmitter, OnInit } from '@angular/core';
import { DynamicComponentStack } from '@vpfa/shared/dynamic-components-stack';
import { CustomersFacade } from '@vpfa/dealer/customers/data';
import { ProfileFacade } from '@vpfa/profile/data';
import { CustomerCreateComponent } from '../customer-create.component';
import { UsersFacade } from '@vpfa/admin/users-data';
import { filter, pairwise, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'vpfa-customer-create-mobile',
  templateUrl: './customer-create-mobile.component.html',
  styleUrls: ['./customer-create-mobile.component.scss'],
})
export class CustomerCreateMobileComponent extends CustomerCreateComponent implements DynamicComponentStack, OnInit {
  constructor(customersFacade: CustomersFacade, profileFacade: ProfileFacade, usersFacade: UsersFacade) {
    super(customersFacade, profileFacade, usersFacade);
  }

  ngOnInit() {
    super.ngOnInit();

    // INFO: Close view after customer created
    this.customersFacade.createCustomerModalOpen$
      .pipe(
        pairwise(),
        filter(([prev, curr]) => prev === true && curr === false),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => this.closeModal());
  }

  close = new EventEmitter<any>();

  closeModal() {
    this.close.emit();
    this.customersFacade.closeCreateCustomerModal(); // INFO: Necessary for correct state
  }
}
