<nz-select
  [nzBackdrop]="true"
  [nzMaxTagCount]="0"
  [nzMode]="'multiple'"
  [nzSize]="'small'"
  [nzMaxTagPlaceholder]="tagPlaceHolder"
  [compareWith]="compareOptions"
  [(ngModel)]="selectedValues"
  [nzShowArrow]="true"
  (ngModelChange)="onValueChange($event)"
  (nzOpenChange)="dropdownOpen = $event"
>
  <nz-option
    *ngFor="let option of options"
    [nzValue]="option.value"
    [nzLabel]="isTranslatable() ? (option.name | translate) : option.name"
    nzCustomContent
  >
    <span [title]="isTranslatable() ? (option.name | translate) : option.name">
      {{ isTranslatable() ? (option.name | translate) : option.name }}
    </span>
  </nz-option>
</nz-select>

<ng-template #tagPlaceHolder let-selectedList>
  {{ selectedList.length }} {{ 'dataTable.dropdownFilter.selected' | translate }}
</ng-template>
