import { Component, EventEmitter } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DataTableAction, DataTableActionEvent } from '../../models/data-table-action';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { isNil } from 'lodash';

export interface SwitchRendererParams extends ICellRendererParams {
  action: DataTableAction;
}

@Component({
  selector: 'vpfa-switch-renderer',
  templateUrl: './switch-renderer.component.html',
  styleUrls: ['./switch-renderer.component.scss'],
})
export class SwitchRendererComponent implements ICellRendererAngularComp {
  params: SwitchRendererParams;
  actionEmitter: EventEmitter<DataTableActionEvent>;
  formGroup = new UntypedFormGroup({
    control: new UntypedFormControl(null),
  });
  showSwitch: boolean = true;

  agInit(params: SwitchRendererParams): void {
    if (params.action) {
      this.params = params;
    } else {
      this.params = { ...params, action: DataTableAction.OTHER };
    }

    if (this.params) {
      const context = this.params.context;
      if (context && context.componentParent && context.componentParent.actionClicked) {
        this.actionEmitter = context.componentParent.actionClicked;
      }
    }

    this.formGroup.get('control').setValue(this.params.value);

    if (!isNil(this.showSwitchFn)) {
      this.showSwitch = this.showSwitchFn(this.params);
    }
  }

  private get showSwitchFn(): (params: ICellRendererParams) => boolean {
    return this.params?.colDef?.cellRendererParams?.showSwitchFn;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  emitAction() {
    if (this.actionEmitter) {
      this.actionEmitter.emit({
        action: this.params.action,
        data: this.params.data,
        value: this.formGroup.get('control').value,
      });
    }
  }
}
