import { DateRangeFilterModel } from '../filters/date-range-floating-filter/date-range-floating-filter.component';
import { NumberRangeFilterModel } from '../filters/number-range-floating-filter/number-range-floating-filter.component';

export function parseRangeSelectFilter(filter: string, fieldName: string) {
  const parsedFilter: NumberRangeFilterModel = JSON.parse(filter);

  return {
    fieldName: fieldName,
    values: [`[${[parsedFilter?.from ?? null, parsedFilter?.to ?? null].join(',')}]`],
    isNull: !parsedFilter?.from && !parsedFilter?.to,
  };
}

export function parseRangeFilter(filter: string, fieldName: string) {
    const parsedFilter: NumberRangeFilterModel = JSON.parse(filter);
  
    return {
      fieldName: fieldName,
      value: `[${[parsedFilter?.from ?? null, parsedFilter?.to ?? null].join(',')}]`,
      isNull: !parsedFilter?.from && !parsedFilter?.to,
    };
  }

export function parseDateRangeFilter(filter: string, fieldName: string) {
  const parsedFilter: DateRangeFilterModel = JSON.parse(filter);

  if (!parsedFilter?.from && !parsedFilter?.to) {
    return { fieldName: fieldName, from: null, to: null };
  }

  // BE does not support providing just `from` or `to` date range. So we are emulating this feature by providing
  // very wide date range instead of `null`
  return { fieldName: fieldName, from: parsedFilter?.from ?? '0001-01-01', to: parsedFilter?.to ?? '2999-12-31' };
}
