import { Component, Input, TemplateRef, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'vpfa-suffix-prefix-value',
  templateUrl: './suffix-prefix-value.component.html',
  styleUrls: ['./suffix-prefix-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuffixPrefixValueComponent {
  @Input() maxValueLength: number;
  @Input() prefixTemplate: TemplateRef<any>;
  @Input() simpleSuffix: TemplateRef<any>;
  @Input() simplePrefix: TemplateRef<any>;
  @Input() suffixTemplate: TemplateRef<any>;
  @Input() valueControlName: string;
  @Input() formGroup: UntypedFormGroup;
  @Input() readonly = false;
  @Input() maxFractionNumber = 2;
  @Input() showFractionZeros = true;
  @Input() allowNegativeNumber = true;
  @Input() convertNegativeValue = true;
  @Input() errorMessages: ReadonlyArray<{ error: string; errorMsg: string }> = [];
  @Input() errorMsgVertPosition: 'inside' | 'outside' | 'leftAlign' | 'suffixPrefix' | 'outsideRight' = 'leftAlign';
  @Input() isDropdownSuffix = false;
  @Output() inputBlur = new EventEmitter();
  @Output() inputFocus = new EventEmitter();

  focused = false;

  onBlur() {
    if (this.inputBlur.observers.length) {
      this.inputBlur.emit(this.formGroup.get(this.valueControlName).value);
    }
    this.focused = false;
  }

  onFocus() {
    if (this.inputFocus.observers.length) {
      this.inputFocus.emit(this.formGroup.get(this.valueControlName).value);
    }
    this.focused = true;
  }
}
