<vpfa-mobile-action-button>
  <ng-container *ngIf="!filtered">
    {{ title }}
  </ng-container>
  <ng-container *ngIf="filtered && resultsLength > 0">
    <span class="success"
      >{{ 'filter.activeFiltering' | translate }}: {{ resultsLength }}
      {{ 'filter.results' | translate }}</span
    >
  </ng-container>
  <ng-container *ngIf="filtered && !resultsLength">
    <span class="error"
      >{{ 'filter.activeFiltering' | translate }}: {{ 'filter.noResults' | translate }}</span
    >
  </ng-container>
</vpfa-mobile-action-button>
