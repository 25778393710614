import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ILinkCellRendererParams extends ICellRendererParams {
  link: (data: any) => string;
}

@Component({
  selector: 'vpfa-link-cell-renderer',
  templateUrl: './link-cell-renderer.component.html',
  styleUrls: ['./link-cell-renderer.component.scss'],
})
export class LinkCellRendererComponent implements ICellRendererAngularComp {
  public text: string = '';
  public link: string = '';

  agInit(params: ILinkCellRendererParams): void {
    if (params) {
      this.text = params.value ?? '';

      if (typeof params.link !== 'function') {
        console.warn('`ILinkCellRendererParams.link()` is not a function');
      }

      this.link = params.link?.(params.data) ?? '';
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  // To disable <a href> default left click navigation. Navigation from AgGrid should work as normal.
  onClick() {
    return false;
  }
}
