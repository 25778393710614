import { Action } from '@ngrx/store';
import {
  ImageFrameListItemDto,
  UpdateAdminImageFrameCommand,
  CreateAdminImageFrameCommand,
} from '@vpfa/rest-api/valuation';

export enum ImageFrameActionTypes {
  BusinessImageFrameLoad = '[ImageFrame] Businesses ImageFrames List Load',
  BusinessImageFrameLoaded = '[ImageFrame] Businesses ImageFrames List Loaded',
  BusinessImageFrameLoadError = '[ImageFrame] Businesses ImageFrames List Load Error ',

  BusinessImageFrameUpdate = '[ImageFrame] Businesses ImageFrames Update',
  BusinessImageFrameUpdated = '[ImageFrame] Businesses ImageFrames Updated',
  BusinessImageFrameUpdateError = '[ImageFrame] Businesses ImageFrames Update Error',

  BusinessImageFrameCreate = '[ImageFrame] Businesses ImageFrames Create',
  BusinessImageFrameCreated = '[ImageFrame] Businesses ImageFrames Created',
  BusinessImageFrameCreateError = '[ImageFrame] Businesses ImageFrames Create Error',

  ImageFrameEditorURLLoad = '[ImageFrame] Businesses ImageFrames Editor URL Load',
  ImageFrameEditorURLLoaded = '[ImageFrame] Businesses ImageFrames Editor URL Loaded',
  ImageFrameEditorURLLoadError = '[ImageFrame] Businesses ImageFrames Editor URL Load Error',

  ImageFrameDelete = '[ImageFrame] Businesses ImageFrames Delete',
  ImageFrameDeleted = '[ImageFrame] Businesses ImageFrames Deleted',
  ImageFrameDeleteError = '[ImageFrame] Businesses ImageFrames Delete Error',

  ImageFramesLoad = '[ImageFrame] ImageFrames Load',
  ImageFramesLoaded = '[ImageFrame] ImageFrames Loaded',
  ImageFramesLoadError = '[ImageFrame] ImageFrames Load Error',

  FramedImagePreviewLoad = '[ImageFrame] Framed Image Preview Load',
  FramedImagePreviewLoaded = '[ImageFrame] Framed Image Preview Loaded',
  FramedImagePreviewLoadError = '[ImageFrame] Framed Image Preview Load Error',

  CreateImageFrameModalOpen = '[ImageFrame] Businesses ImageFrames Create Modal Open',
  CreateImageFrameModalClose = '[ImageFrame] Businesses ImageFrames Create Modal Close',
  RenameImageFrameModalOpen = '[ImageFrame] Businesses ImageFrames Rename Modal Open',
  RenameImageFrameModalClose = '[ImageFrame] Businesses ImageFrames Rename Modal Close',

  FramedImagePreviewClear = '[ImageFrame] Framed Image Preview Clear',
}

export class BusinessImageFrameLoad implements Action {
  readonly type = ImageFrameActionTypes.BusinessImageFrameLoad;
  constructor(public payload: { countryId: string; businessId: string }) {}
}

export class BusinessImageFrameLoaded implements Action {
  readonly type = ImageFrameActionTypes.BusinessImageFrameLoaded;
  constructor(public payload: ImageFrameListItemDto[]) {}
}

export class BusinessImageFrameLoadError implements Action {
  readonly type = ImageFrameActionTypes.BusinessImageFrameLoadError;
}

export class BusinessImageFrameUpdate implements Action {
  readonly type = ImageFrameActionTypes.BusinessImageFrameUpdate;
  constructor(public payload: UpdateAdminImageFrameCommand & { businessId: string; countryId: string }) {}
}

export class BusinessImageFrameUpdated implements Action {
  readonly type = ImageFrameActionTypes.BusinessImageFrameUpdated;
  constructor(public payload: UpdateAdminImageFrameCommand & { businessId: string; countryId: string }) {}
}

export class BusinessImageFrameUpdateError implements Action {
  readonly type = ImageFrameActionTypes.BusinessImageFrameUpdateError;
}

export class BusinessImageFrameCreate implements Action {
  readonly type = ImageFrameActionTypes.BusinessImageFrameCreate;
  constructor(public payload: CreateAdminImageFrameCommand & { businessId: string; countryId: string }) {}
}

export class BusinessImageFrameCreated implements Action {
  readonly type = ImageFrameActionTypes.BusinessImageFrameCreated;
  constructor(public payload: { businessId: string; countryId: string; id: string }) {}
}

export class BusinessImageFrameCreateError implements Action {
  readonly type = ImageFrameActionTypes.BusinessImageFrameCreateError;
}

export class ImageFrameEditorURLLoad implements Action {
  readonly type = ImageFrameActionTypes.ImageFrameEditorURLLoad;
  constructor(public payload: { frameId: string }) {}
}

export class ImageFrameEditorURLLoaded implements Action {
  readonly type = ImageFrameActionTypes.ImageFrameEditorURLLoaded;
  constructor(public payload: { url: string }) {}
}

export class ImageFrameEditorURLLoadError implements Action {
  readonly type = ImageFrameActionTypes.ImageFrameEditorURLLoadError;
}

export class ImageFrameDelete implements Action {
  readonly type = ImageFrameActionTypes.ImageFrameDelete;
  constructor(public payload: { id: string; businessId: string; countryId: string }) {}
}

export class ImageFrameDeleted implements Action {
  readonly type = ImageFrameActionTypes.ImageFrameDeleted;
  constructor(public payload: { businessId: string; countryId: string }) {}
}

export class ImageFrameDeleteError implements Action {
  readonly type = ImageFrameActionTypes.ImageFrameDeleteError;
}

export class ImageFramesLoad implements Action {
  readonly type = ImageFrameActionTypes.ImageFramesLoad;
}

export class ImageFramesLoaded implements Action {
  readonly type = ImageFrameActionTypes.ImageFramesLoaded;
  constructor(public payload: ImageFrameListItemDto[]) {}
}

export class ImageFramesLoadError implements Action {
  readonly type = ImageFrameActionTypes.ImageFramesLoadError;
}

export class FramedImagePreviewLoad implements Action {
  readonly type = ImageFrameActionTypes.FramedImagePreviewLoad;
  constructor(public payload: { frameId: string; imageKey: string; caseId: string }) {}
}

export class FramedImagePreviewLoaded implements Action {
  readonly type = ImageFrameActionTypes.FramedImagePreviewLoaded;
  constructor(public payload: { preview: string }) {}
}

export class FramedImagePreviewLoadError implements Action {
  readonly type = ImageFrameActionTypes.FramedImagePreviewLoadError;
}

export class CreateImageFrameModalOpen implements Action {
  readonly type = ImageFrameActionTypes.CreateImageFrameModalOpen;
}

export class CreateImageFrameModalClose implements Action {
  readonly type = ImageFrameActionTypes.CreateImageFrameModalClose;
}

export class RenameImageFrameModalOpen implements Action {
  readonly type = ImageFrameActionTypes.RenameImageFrameModalOpen;
}

export class RenameImageFrameModalClose implements Action {
  readonly type = ImageFrameActionTypes.RenameImageFrameModalClose;
}

export class FramedImagePreviewClear implements Action {
  readonly type = ImageFrameActionTypes.FramedImagePreviewClear;
}

export type ImageFrameAction =
  | BusinessImageFrameLoad
  | BusinessImageFrameLoaded
  | BusinessImageFrameLoadError
  | BusinessImageFrameUpdate
  | BusinessImageFrameUpdated
  | BusinessImageFrameUpdateError
  | BusinessImageFrameCreate
  | BusinessImageFrameCreated
  | BusinessImageFrameCreateError
  | ImageFrameEditorURLLoad
  | ImageFrameEditorURLLoaded
  | ImageFrameEditorURLLoadError
  | CreateImageFrameModalOpen
  | CreateImageFrameModalClose
  | RenameImageFrameModalOpen
  | RenameImageFrameModalClose
  | ImageFrameDelete
  | ImageFrameDeleted
  | ImageFrameDeleteError
  | ImageFramesLoad
  | ImageFramesLoaded
  | ImageFramesLoadError
  | FramedImagePreviewLoad
  | FramedImagePreviewLoaded
  | FramedImagePreviewLoadError
  | FramedImagePreviewClear;

export const fromImageFrameActions = {
  BusinessImageFrameLoad,
  BusinessImageFrameLoaded,
  BusinessImageFrameLoadError,
  BusinessImageFrameUpdate,
  BusinessImageFrameUpdated,
  BusinessImageFrameUpdateError,
  BusinessImageFrameCreate,
  BusinessImageFrameCreated,
  BusinessImageFrameCreateError,
  ImageFrameEditorURLLoad,
  ImageFrameEditorURLLoaded,
  ImageFrameEditorURLLoadError,
  CreateImageFrameModalOpen,
  CreateImageFrameModalClose,
  RenameImageFrameModalOpen,
  RenameImageFrameModalClose,
  ImageFrameDelete,
  ImageFrameDeleted,
  ImageFrameDeleteError,
  ImageFramesLoad,
  ImageFramesLoaded,
  ImageFramesLoadError,
  FramedImagePreviewLoad,
  FramedImagePreviewLoaded,
  FramedImagePreviewLoadError,
  FramedImagePreviewClear,
};
