import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  EmissionWLTP,
  I18RangeValueDto,
  TechnicalDataDto,
  UpdateIdentifiedVehicleTechnicalDataCommand,
  VehicleDto,
} from '@vpfa/rest-api/valuation';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { isNil } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import {
  getUnitRangeValueOrNull,
  getUnitRangeValueOrNullValue,
  getUnitValueOrNull,
  intervalSign,
  MAX_CO2_EMISSION_VALUE,
  MAX_EV_RANGE_AND_CONSUMPTION_DATA_VALUE,
  MAX_TECHNICAL_DATA_VALUE,
} from '../../utils/vehicle-full-technical-data-utils';
import { positiveValidator, valueHasToBeSameValidator } from '@vpfa/shared/validators';
import { ScrollToTopService } from '@vpfa/shared/mobile-view';
import { FeaturesTechnicalDataConfiguration } from '@vpfa/locale';
import { SelectOption } from '@vpfa/ui-kit';
import { DictionariesFacade } from '@vpfa/dealer/dictionaries';
import { Subject } from 'rxjs';
import { ProfileFacade } from '@vpfa/profile/data';
import { CasesFacade, VehicleEngineType, vehicleEngineFuelParser } from '@vpfa/dealer/case/data';
import { MAX_SEATS, MIN_SEATS, emptyTechnicalDataForm, technicalDataValidator } from './technical-data-form.utils';
import { first, map } from 'rxjs/operators';

/**
 * Technical Data for Case Details (Mobile & Web) and Make and Modal Search (Mobile only)
 */
@Component({
  selector: 'vpfa-vehicle-full-technical-data',
  templateUrl: './vehicle-full-technical-data.component.html',
  styleUrls: ['./vehicle-full-technical-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleFullTechnicalDataComponent implements OnDestroy {
  MAX_TECHNICAL_DATA_VALUE = MAX_TECHNICAL_DATA_VALUE;

  technicalData: TechnicalDataDto;
  vehicleType;
  deliveryCountry: string;
  listPrice: number;
  formGroup: UntypedFormGroup = emptyTechnicalDataForm(this.fb);

  errors: ReadonlyArray<{ error: string; errorMsg: string }> = [
    {
      error: 'positive',
      errorMsg: this.translateService.instant('vehicleFullTechnicalData.errors.min'),
    },
    {
      error: 'max',
      errorMsg: this.translateService.instant('common.forms.maximumValue', { maxValue: MAX_TECHNICAL_DATA_VALUE }),
    },
    {
      error: 'intervalInvalid',
      errorMsg: this.translateService.instant('vehicleFullTechnicalData.errors.intervalInvalid'),
    },
  ];
  co2Errors: ReadonlyArray<{ error: string; errorMsg: string }> = [
    {
      error: 'positive',
      errorMsg: this.translateService.instant('vehicleFullTechnicalData.errors.min'),
    },
    {
      error: 'max',
      errorMsg: this.translateService.instant('common.forms.maximumValue', { maxValue: MAX_CO2_EMISSION_VALUE }),
    },
    {
      error: 'intervalInvalid',
      errorMsg: this.translateService.instant('vehicleFullTechnicalData.errors.intervalInvalid'),
    },
  ];
  evRangeAndConsumptionErrors: ReadonlyArray<{ error: string; errorMsg: string }> = [
    {
      error: 'positive',
      errorMsg: this.translateService.instant('vehicleFullTechnicalData.errors.min'),
    },
    {
      error: 'max',
      errorMsg: this.translateService.instant('common.forms.maximumValue', {
        maxValue: MAX_EV_RANGE_AND_CONSUMPTION_DATA_VALUE,
      }),
    },
    {
      error: 'intervalInvalid',
      errorMsg: this.translateService.instant('vehicleFullTechnicalData.errors.intervalInvalid'),
    },
  ];
  seatErrors: ReadonlyArray<{ error: string; errorMsg: string }> = [
    {
      error: 'min',
      errorMsg: this.translateService.instant('common.forms.minimumValue', { minValue: MIN_SEATS }),
    },
    {
      error: 'max',
      errorMsg: this.translateService.instant('common.forms.maximumValue', { maxValue: MAX_SEATS }),
    },
  ];
  @Input() hideAllExceptEmissionsAndConsumption = false;
  @Input() isMobile = false;
  @Input() loading = false;
  @Input() id: string;
  @Input() showFooter = true;
  @Input() technicalDataFeatureConfig: FeaturesTechnicalDataConfiguration;
  @Input() set vehicleData(vehicleDto: VehicleDto) {
    this.vehicleType = vehicleDto?.vehicleType;
    this.deliveryCountry = vehicleDto?.deliveryCountry;
    this.listPrice = vehicleDto?.priceBrutto;
    this.vehicleEngineFuel = vehicleEngineFuelParser(vehicleDto?.technicalData?.vehicleEngineFuel);
    if (this.isMobile) {
      this.scrollService.scrollToTop();
    }
    if (!isNil(vehicleDto.technicalData)) {
      this.technicalData = vehicleDto.technicalData;
      this.setFilledForm();
      this.setEfficiencyClassHiddenOptions();
    }
  }

  // TODO: Need to set form value after setting this one
  @Input() set emissionClassOptions(emissionClassOptions: SelectOption[]) {
    this._emissionClassOptions = emissionClassOptions;
    this.onEmissionClassOptionsChange();
  }
  get emissionClassOptions(): SelectOption[] {
    return this._emissionClassOptions;
  }
  private _emissionClassOptions: SelectOption[];

  @Output() closeAction = new EventEmitter();
  @Output() submitAction = new EventEmitter<UpdateIdentifiedVehicleTechnicalDataCommand>();

  efficiencyClassOptions$ = this.dictionaryFacade.co2EfficiencyClassListOptions$;
  efficiencyClassHiddenOptions: SelectOption[] = [];

  userContext$ = this.profileFacade.userContext$;

  vehicleEngineFuel: VehicleEngineType = VehicleEngineType.Thermal;

  isGermany$ = this.userContext$.pipe(map(x => x?.countryCode?.toUpperCase() === 'DE'));

  private _onDestroy$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private scrollService: ScrollToTopService,
    private dictionaryFacade: DictionariesFacade,
    private casesFacade: CasesFacade,
    private profileFacade: ProfileFacade
  ) {}

  setFilledForm() {
    if (!isNil(this.technicalData.body)) {
      this.formGroup.get('numberSeats').reset(this.technicalData.body.numberSeats);
      this.formGroup.get('emissionClass').reset(this.emissionClassControlValue());
    }

    if (!isNil(this.technicalData.consumption)) {
      const consumption = this.technicalData.consumption;
      if (!isNil(consumption.nedc)) {
        const formNedc = this.formGroup.get('nedc');
        formNedc.get('urban').reset(consumption.nedc.fuelConsumptionUrban);
        this.setIntervalValidator(this.formGroup.get('nedc').get('urban'), consumption.nedc.fuelConsumptionUrban);
        formNedc.get('extraUrban').reset(consumption.nedc.fuelConsumptionExtraUrban);
        this.setIntervalValidator(
          this.formGroup.get('nedc').get('extraUrban'),
          consumption.nedc.fuelConsumptionExtraUrban
        );
        formNedc.get('combined').reset(consumption.nedc.fuelConsumptionOverallCombined);
        this.setIntervalValidator(
          this.formGroup.get('nedc').get('combined'),
          consumption.nedc.fuelConsumptionOverallCombined
        );
        formNedc.get('cO2Emissions').reset(consumption.nedc.cO2EmissionCombined);
        this.setIntervalValidator(this.formGroup.get('nedc').get('cO2Emissions'), consumption.nedc.cO2EmissionCombined);
        formNedc.get('electricConsumption').reset(consumption.nedc.electricPowerConsumption);
        this.setIntervalValidator(
          this.formGroup.get('nedc').get('electricConsumption'),
          consumption.nedc.electricPowerConsumption
        );
      }
      if (!isNil(consumption.wltp)) {
        const formWltp = this.formGroup.get('wltp');
        formWltp.get('lowSpeed').reset(consumption.wltp.fuelConsumptionLowPhase);
        this.setIntervalValidator(this.formGroup.get('wltp').get('lowSpeed'), consumption.wltp.fuelConsumptionLowPhase);
        formWltp.get('mediumSpeed').reset(consumption.wltp.fuelConsumptionMediumPhase);
        this.setIntervalValidator(
          this.formGroup.get('wltp').get('mediumSpeed'),
          consumption.wltp.fuelConsumptionMediumPhase
        );
        formWltp.get('highSpeed').reset(consumption.wltp.fuelConsumptionHighPhase);
        this.setIntervalValidator(
          this.formGroup.get('wltp').get('highSpeed'),
          consumption.wltp.fuelConsumptionHighPhase
        );
        formWltp.get('extraHighSpeed').reset(consumption.wltp.fuelConsumptionExtraHighPhase);
        this.setIntervalValidator(
          this.formGroup.get('wltp').get('extraHighSpeed'),
          consumption.wltp.fuelConsumptionExtraHighPhase
        );
        formWltp.get('combined').reset(consumption.wltp.fuelConsumptionOverallCombined);
        this.setIntervalValidator(
          this.formGroup.get('wltp').get('combined'),
          consumption.wltp.fuelConsumptionOverallCombined
        );
        formWltp.get('cO2EmissionCombined').reset(consumption.wltp.cO2EmissionCombined);
        this.setIntervalValidator(
          this.formGroup.get('wltp').get('cO2EmissionCombined'),
          consumption.wltp.cO2EmissionCombined
        );
        formWltp.get('combinedRange').reset(consumption.wltp.drivingRange?.value);
        formWltp.get('cityRange').reset(consumption.wltp.drivingRangeCity?.value);
        formWltp.get('electricPowerConsumption').reset(consumption?.wltp?.electricPowerConsumption);
        this.setIntervalValidator(
          this.formGroup.get('wltp').get('electricPowerConsumption'),
          consumption?.wltp?.electricPowerConsumption
        );

        // #region CSG-81
        this.resetWltpIntervalFormControl(formWltp, consumption.wltp, 'gasConsumptionLowPhase');
        this.resetWltpIntervalFormControl(formWltp, consumption.wltp, 'gasConsumptionMediumPhase');
        this.resetWltpIntervalFormControl(formWltp, consumption.wltp, 'gasConsumptionHighPhase');
        this.resetWltpIntervalFormControl(formWltp, consumption.wltp, 'gasConsumptionExtraHighPhase');
        this.resetWltpIntervalFormControl(formWltp, consumption.wltp, 'gasConsumptionCombinedPhase');
        this.resetWltpIntervalFormControl(formWltp, consumption.wltp, 'gasCO2EmissionCombined');
        this.resetWltpIntervalFormControl(formWltp, consumption.wltp, 'cO2EmissionCombinedEB');
        this.resetWltpIntervalFormControl(formWltp, consumption.wltp, 'consumptionRatingEB');
        this.resetWltpIntervalFormControl(formWltp, consumption.wltp, 'fuelConsumptionLowPhaseEB');
        this.resetWltpIntervalFormControl(formWltp, consumption.wltp, 'fuelConsumptionMediumPhaseEB');
        this.resetWltpIntervalFormControl(formWltp, consumption.wltp, 'fuelConsumptionHighPhaseEB');
        this.resetWltpIntervalFormControl(formWltp, consumption.wltp, 'fuelConsumptionExtraHighPhaseEB');
        this.resetWltpIntervalFormControl(formWltp, consumption.wltp, 'fuelConsumptionCombinedPhaseEB');
        this.resetWltpIntervalFormControl(formWltp, consumption.wltp, 'fuelConsumptionLowPhasePE');
        this.resetWltpIntervalFormControl(formWltp, consumption.wltp, 'fuelConsumptionMediumPhasePE');
        this.resetWltpIntervalFormControl(formWltp, consumption.wltp, 'fuelConsumptionHighPhasePE');
        this.resetWltpIntervalFormControl(formWltp, consumption.wltp, 'fuelConsumptionExtraHighPhasePE');
        this.resetWltpIntervalFormControl(formWltp, consumption.wltp, 'fuelConsumptionCombinedPhasePE');
        // #endregion CSG-81
      }
    }
    this.formGroup.get('nedc').get('efficiencyClass').reset(this.setEfficiencyClassControl());

    this.isGermany$.pipe(first()).subscribe(isGermany => {
      if (isGermany) {
        this.formGroup
          .get('wltp')
          .get('cO2WLTPEfficiencyClassCustom')
          .reset(this.getI18IntervalText(this.technicalData.cO2WLTPEfficiencyClassCustom));
        this.formGroup
          .get('wltp')
          .get('cO2WLTPEfficiencyClassEBCustom')
          .reset(this.getI18IntervalText(this.technicalData.cO2WLTPEfficiencyClassEBCustom));
      } else {
        this.formGroup
          .get('wltp')
          .get('cO2WLTPEfficiencyClassCustom')
          .reset({
            name: this.technicalData.cO2WLTPEfficiencyClassCustom?.value?.texts,
            value: this.technicalData.cO2WLTPEfficiencyClassCustom?.value?.id
              ? Number(this.technicalData.cO2WLTPEfficiencyClassCustom.value.id)
              : null,
          });
        this.formGroup
          .get('wltp')
          .get('cO2WLTPEfficiencyClassEBCustom')
          .reset({
            name: this.technicalData.cO2WLTPEfficiencyClassEBCustom?.value?.texts,
            value: this.technicalData.cO2WLTPEfficiencyClassEBCustom?.value?.id
              ? Number(this.technicalData.cO2WLTPEfficiencyClassEBCustom.value.id)
              : null,
          });
      }

      this.formGroup.updateValueAndValidity();
    });
  }

  private getI18IntervalText(input: I18RangeValueDto): string | null {
    const min = input?.value?.texts;
    const max = input?.valueMax?.texts;

    if (min === undefined && max === undefined) {
      return null;
    }

    if (max !== undefined) {
      return `${min} ${intervalSign} ${max}`;
    }

    return min;
  }

  private resetWltpIntervalFormControl(
    formWltp: AbstractControl,
    consumptionWltp: EmissionWLTP,
    key: keyof EmissionWLTP
  ) {
    formWltp.get(key).reset(consumptionWltp[key]);
    this.setIntervalValidator(formWltp.get(key), consumptionWltp[key]);
  }

  private emissionClassControlValue(): SelectOption {
    return this.emissionClassOptions?.find(x => x.name === this.technicalData.emissionClass?.texts);
  }

  private setEfficiencyClassControl(): SelectOption {
    let efficiencyClassControl: SelectOption = {
      name: '',
      value: '',
    };

    if (this.technicalData.cO2EfficiencyClass?.value && !this.technicalData.cO2EfficiencyClassCustom?.id) {
      efficiencyClassControl = this.setEfficiencyClass();
    }

    if (this.technicalData.cO2EfficiencyClassCustom?.id) {
      efficiencyClassControl = this.setCustomEfficiencyClass();
    }

    return efficiencyClassControl;
  }

  private setEfficiencyClass(): SelectOption {
    if (this.technicalData.cO2EfficiencyClass?.valueMax) {
      return {
        name: `${this.technicalData.cO2EfficiencyClass?.value?.texts} - ${this.technicalData.cO2EfficiencyClass?.valueMax?.texts}`,
        value: null,
      };
    } else {
      return {
        name: this.technicalData.cO2EfficiencyClass?.value?.texts,
        value: this.technicalData.cO2EfficiencyClass?.value?.id
          ? Number(this.technicalData.cO2EfficiencyClass.value.id)
          : null,
      };
    }
  }

  private setCustomEfficiencyClass(): SelectOption {
    if (this.technicalData.cO2EfficiencyClassCustom?.id === this.technicalData.cO2EfficiencyClass?.value?.id) {
      return this.setEfficiencyClass();
    } else {
      return {
        name: this.technicalData.cO2EfficiencyClassCustom?.texts,
        value: this.technicalData.cO2EfficiencyClassCustom?.id
          ? Number(this.technicalData.cO2EfficiencyClassCustom.id)
          : null,
      };
    }
  }

  private setEfficiencyClassHiddenOptions(): void {
    if (this.technicalData.cO2EfficiencyClass?.value && this.technicalData.cO2EfficiencyClass?.valueMax) {
      this.efficiencyClassHiddenOptions = [
        {
          name: `${this.technicalData.cO2EfficiencyClass.value.texts} - ${this.technicalData.cO2EfficiencyClass.valueMax.texts}`,
          value: null,
        },
      ];
    }
  }

  close() {
    this.closeAction.emit();
    this.setFilledForm();
  }

  submit() {
    if (this.formGroup.valid) {
      const nedc = this.technicalData.consumption.nedc;
      const wltp = this.technicalData.consumption.wltp;

      this.submitAction.emit({
        aggregateRootId: this.id,
        seats: this.formGroup.get('numberSeats').value,
        emissionClass: this.formGroup.get('emissionClass')?.value?.value,

        // #region CSG-81
        nedc: {
          cO2EmissionCombined: getUnitRangeValueOrNull(
            this.formGroup.get('nedc').get('cO2Emissions'),
            nedc,
            nedc?.cO2EmissionCombined
          ),
          electricPowerConsumption: getUnitRangeValueOrNull(
            this.formGroup.get('nedc').get('electricConsumption'),
            nedc,
            nedc?.electricPowerConsumption
          ),
          fuelConsumptionExtraUrban: getUnitRangeValueOrNull(
            this.formGroup.get('nedc').get('extraUrban'),
            nedc,
            nedc?.fuelConsumptionExtraUrban
          ),
          fuelConsumptionUrban: getUnitRangeValueOrNull(
            this.formGroup.get('nedc').get('urban'),
            nedc,
            nedc?.fuelConsumptionUrban
          ),
          fuelConsumptionOverallCombined: getUnitRangeValueOrNull(
            this.formGroup.get('nedc').get('combined'),
            nedc,
            nedc?.fuelConsumptionOverallCombined
          ),
          cO2EfficiencyClassCustom: this.formGroup.get('nedc')?.get('efficiencyClass')?.value?.value,
          consumptionRating: null,
        },
        wltp: {
          fuelConsumptionLowPhase: getUnitRangeValueOrNull(
            this.formGroup.get('wltp').get('lowSpeed'),
            wltp,
            wltp?.fuelConsumptionLowPhase
          ),
          fuelConsumptionMediumPhase: getUnitRangeValueOrNull(
            this.formGroup.get('wltp').get('mediumSpeed'),
            wltp,
            wltp?.fuelConsumptionMediumPhase
          ),
          fuelConsumptionHighPhase: getUnitRangeValueOrNull(
            this.formGroup.get('wltp').get('highSpeed'),
            wltp,
            wltp?.fuelConsumptionHighPhase
          ),
          fuelConsumptionExtraHighPhase: getUnitRangeValueOrNull(
            this.formGroup.get('wltp').get('extraHighSpeed'),
            wltp,
            wltp?.fuelConsumptionExtraHighPhase
          ),
          fuelConsumptionOverallCombined: getUnitRangeValueOrNull(
            this.formGroup.get('wltp').get('combined'),
            wltp,
            wltp?.fuelConsumptionOverallCombined
          ),
          cO2EmissionCombined: getUnitRangeValueOrNull(
            this.formGroup.get('wltp').get('cO2EmissionCombined'),
            wltp,
            wltp?.cO2EmissionCombined
          ),
          drivingRange: getUnitValueOrNull(this.formGroup.get('wltp').get('combinedRange'), wltp, wltp?.drivingRange),
          drivingRangeCity: getUnitValueOrNull(
            this.formGroup.get('wltp').get('cityRange'),
            wltp,
            wltp?.drivingRangeCity
          ),
          electricPowerConsumption: getUnitRangeValueOrNull(
            this.formGroup.get('wltp').get('electricPowerConsumption'),
            wltp,
            wltp?.electricPowerConsumption
          ),

          // #region CSG-81
          gasConsumptionLowPhase: getUnitRangeValueOrNullValue(
            this.formGroup.get('wltp').get('gasConsumptionLowPhase'),
            wltp,
            wltp?.gasConsumptionLowPhase
          ),
          gasConsumptionMediumPhase: getUnitRangeValueOrNullValue(
            this.formGroup.get('wltp').get('gasConsumptionMediumPhase'),
            wltp,
            wltp?.gasConsumptionMediumPhase
          ),
          gasConsumptionHighPhase: getUnitRangeValueOrNullValue(
            this.formGroup.get('wltp').get('gasConsumptionHighPhase'),
            wltp,
            wltp?.gasConsumptionHighPhase
          ),
          gasConsumptionExtraHighPhase: getUnitRangeValueOrNullValue(
            this.formGroup.get('wltp').get('gasConsumptionExtraHighPhase'),
            wltp,
            wltp?.gasConsumptionExtraHighPhase
          ),
          gasConsumptionCombinedPhase: getUnitRangeValueOrNullValue(
            this.formGroup.get('wltp').get('gasConsumptionCombinedPhase'),
            wltp,
            wltp?.gasConsumptionCombinedPhase
          ),
          gasCO2EmissionCombined: getUnitRangeValueOrNullValue(
            this.formGroup.get('wltp').get('gasCO2EmissionCombined'),
            wltp,
            wltp?.gasCO2EmissionCombined
          ),
          cO2EmissionCombinedEB: getUnitRangeValueOrNullValue(
            this.formGroup.get('wltp').get('cO2EmissionCombinedEB'),
            wltp,
            wltp?.cO2EmissionCombinedEB
          ),
          consumptionRating: wltp?.consumptionRating?.value ? Number(wltp?.consumptionRating?.value) : null,
          consumptionRatingEB: wltp?.consumptionRatingEB?.value ? Number(wltp?.consumptionRatingEB?.value) : null,
          fuelConsumptionLowPhaseEB: getUnitRangeValueOrNullValue(
            this.formGroup.get('wltp').get('fuelConsumptionLowPhaseEB'),
            wltp,
            wltp?.fuelConsumptionLowPhaseEB
          ),
          fuelConsumptionMediumPhaseEB: getUnitRangeValueOrNullValue(
            this.formGroup.get('wltp').get('fuelConsumptionMediumPhaseEB'),
            wltp,
            wltp?.fuelConsumptionMediumPhaseEB
          ),
          fuelConsumptionHighPhaseEB: getUnitRangeValueOrNullValue(
            this.formGroup.get('wltp').get('fuelConsumptionHighPhaseEB'),
            wltp,
            wltp?.fuelConsumptionHighPhaseEB
          ),
          fuelConsumptionExtraHighPhaseEB: getUnitRangeValueOrNullValue(
            this.formGroup.get('wltp').get('fuelConsumptionExtraHighPhaseEB'),
            wltp,
            wltp?.fuelConsumptionExtraHighPhaseEB
          ),
          fuelConsumptionCombinedPhaseEB: getUnitRangeValueOrNullValue(
            this.formGroup.get('wltp').get('fuelConsumptionCombinedPhaseEB'),
            wltp,
            wltp?.fuelConsumptionCombinedPhaseEB
          ),
          fuelConsumptionLowPhasePE: getUnitRangeValueOrNullValue(
            this.formGroup.get('wltp').get('fuelConsumptionLowPhasePE'),
            wltp,
            wltp?.fuelConsumptionLowPhasePE
          ),
          fuelConsumptionMediumPhasePE: getUnitRangeValueOrNullValue(
            this.formGroup.get('wltp').get('fuelConsumptionMediumPhasePE'),
            wltp,
            wltp?.fuelConsumptionMediumPhasePE
          ),
          fuelConsumptionHighPhasePE: getUnitRangeValueOrNullValue(
            this.formGroup.get('wltp').get('fuelConsumptionHighPhasePE'),
            wltp,
            wltp?.fuelConsumptionHighPhasePE
          ),
          fuelConsumptionExtraHighPhasePE: getUnitRangeValueOrNullValue(
            this.formGroup.get('wltp').get('fuelConsumptionExtraHighPhasePE'),
            wltp,
            wltp?.fuelConsumptionExtraHighPhasePE
          ),
          fuelConsumptionCombinedPhasePE: getUnitRangeValueOrNullValue(
            this.formGroup.get('wltp').get('fuelConsumptionCombinedPhasePE'),
            wltp,
            wltp?.fuelConsumptionCombinedPhasePE
          ),
          cO2WLTPEfficiencyClassCustom: this.formGroup.get('wltp')?.get('cO2WLTPEfficiencyClassCustom')?.value?.value,
          cO2WLTPEfficiencyClassCustomEB: this.formGroup.get('wltp')?.get('cO2WLTPEfficiencyClassEBCustom')?.value
            ?.value,

          // FE do not have those fields, sending them back without changes
          gasCO2EmissionCombinedEB: wltp?.gasCO2EmissionCombinedEB,
          gasConsumptionLowPhaseEB: wltp?.gasConsumptionLowPhaseEB,
          gasConsumptionMediumPhaseEB: wltp?.gasConsumptionMediumPhaseEB,
          gasConsumptionHighPhaseEB: wltp?.gasConsumptionHighPhaseEB,
          gasConsumptionCombinedPhaseEB: wltp?.gasConsumptionCombinedPhaseEB,
          gasConsumptionExtraHighPhaseEB: wltp?.gasConsumptionExtraHighPhaseEB,
          // #endregion CSG-81
        },
      });
    }
  }

  setIntervalValidator(control, startingValue) {
    control.setValidators(Validators.compose([technicalDataValidator, valueHasToBeSameValidator(startingValue)]));
  }

  onEmissionClassOptionsChange() {
    this.formGroup.get('emissionClass').reset(this.emissionClassControlValue());
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
