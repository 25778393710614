<vpfa-section-container
  [isLoading]="isLoading"
  [name]="name | translate"
  [toggleLabel]="toggleLabel"
  [tooltipContent]="'tooltip.repairAndMaintenance' | translate"
  [notAvailable]="notAvailable"
>
  <ng-container right-section>
    <span *ngIf="!notAvailable" id="repairAndMaintenanceAdjustmentTotalValue" class="repairs-total">
      {{ toggleLabel }}
    </span>
  </ng-container>
  <vpfa-repair-and-maintenance-summary
    [isVinEreDataLoading]="isVinEreDataLoading"
    [minorTotal]="minorTotal"
    [majorTotal]="majorTotal"
    [caseId]="caseId"
    [notAvailable]="notAvailable"
    [hideRepairEstimateCost]="hideRepairEstimateCost"
  ></vpfa-repair-and-maintenance-summary>
</vpfa-section-container>
