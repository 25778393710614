<nz-checkbox-wrapper (nzOnChange)="emitAction()" [formGroup]="formGroup">
  <label
    nz-checkbox
    [formControlName]="'control'"
    [nzDisabled]="formGroup.get('disabled').value"
    nz-tooltip
    [nzTooltipOverlayClassName]="'tooltip'"
    [nzTooltipTitle]="disabledMessage | translate"
    [nzTooltipTrigger]="formGroup.get('disabled').value && disabledMessage ? 'hover' : null"
    [nzTooltipPlacement]="'top'"
  >
  </label>
</nz-checkbox-wrapper>
