import { Component } from '@angular/core';
import { StockPricesBase } from '../stock-prices-base';
import { ActivatedRoute, Router } from '@angular/router';
import { CaseStatus } from '@vpfa/rest-api/valuation';

@Component({
  selector: 'vpfa-stock-prices-mobile',
  templateUrl: './stock-prices-mobile.component.html',
  styleUrls: ['./stock-prices-mobile.component.scss'],
})
export class StockPricesMobileComponent extends StockPricesBase {
  constructor(router: Router, route: ActivatedRoute) {
    super(router, route);
  }

  setTooltipContent() {
    return !this.isSold
      ? 'tooltip.stockPriceSectionMobile'
      : 'tooltip.soldPriceSectionMobile';
  }
}
