<vpfa-setting-box *vpfaResponsiveIf="'web'" [divider]="false" [formGroup]="parentFormGroup">
  <ng-container label>
    <vpfa-label [text]="label"></vpfa-label>
  </ng-container>
  <ng-container content>
    <vpfa-text-field-with-dropdown
      [formGroup]="parentFormGroup"
      [fcName]="fcName"
      [options]="options"
      [errorMessages]="errorMessages"
      [isCustomValueProvided]="isCustomValueProvided"
      [showResetButton]="showResetButton"
      [resetButtonDisabled]="resetButtonDisabled"
      [resetButtonTooltip]="resetButtonTooltip"
      (selectedValue)="onSelectedValue($event)"
      (resetValue)="onResetValue()"
    ></vpfa-text-field-with-dropdown>
  </ng-container>
</vpfa-setting-box>

<vpfa-vehicle-technical-data-single-field
  *vpfaResponsiveIf="'mobile'"
  [label]="label"
  [value]="parentFormGroup.get(fcName)?.value"
></vpfa-vehicle-technical-data-single-field>
