import { isNil } from 'lodash';
import { VehicleEngineType } from '../types/vehicle-engine-fuel.enum';

export function vehicleEngineFuelParser(input: string): VehicleEngineType {
  if (input?.toUpperCase() === 'H2') {
    return VehicleEngineType.Thermal;
  }

  const result = VehicleEngineType[input];

  if (isNil(result)) {
    return VehicleEngineType.Thermal;
  }

  return result;
}
