import { Component, ChangeDetectionStrategy } from '@angular/core';
import { elementsGap, elementSize } from '../utils';
import { BaseProvenanceCheckSectionConfirmationStatusComponent } from '../base-provenance-check-section-confirmation-status.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vpfa-web-provenance-check-section-confirmation-status',
  templateUrl: './web-provenance-check-section-confirmation-status.component.html',
  styleUrls: ['./web-provenance-check-section-confirmation-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebProvenanceCheckSectionConfirmationStatusComponent extends BaseProvenanceCheckSectionConfirmationStatusComponent {
  elementsGap = elementsGap;
  elementSize = elementSize;

  constructor(translateService: TranslateService) {
    super(translateService);
  }
}
