import { VPError } from '@vpfa/rest-api/valuation';

export function readVpErrorData(input: any): Pick<VPError, 'id' | 'codeName'> {
  // BE can return just error object or wrapped in CommandHandlerErrorDto or similar.
  const errorBase1 = input?.error?.error ?? input?.error?.Error ?? input?.error ?? input?.Error ?? input;

  // BE can return nested vpError param in two different text cases or just error directly.
  const errorBase2 = errorBase1?.vpError ?? errorBase1?.VpError ?? errorBase1;

  const codeName = errorBase2?.codeName ?? errorBase2?.code ?? errorBase2?.errorCode ?? errorBase2?.Code;
  const id = errorBase2?.id;

  return {
    id,
    codeName,
  };
}
