import { Component } from '@angular/core';
import { BaseProvenanceCheckSectionComponent } from '../base-provenance-check-section.component';
import { elementsGap, elementSize } from '../utils';

@Component({
  selector: 'vpfa-web-provenance-check-section',
  templateUrl: './web-provenance-check-section.component.html',
  styleUrls: ['./web-provenance-check-section.component.scss'],
})
export class WebProvenanceCheckSectionComponent extends BaseProvenanceCheckSectionComponent {
  elementsGap = elementsGap;
  elementSize = elementSize;
}
