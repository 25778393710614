import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fromImageFrameActions } from '@vpfa/shared/image-frames/data';

import {
  AddImage,
  fromGalleryManagerActions,
  GalleryImageOrderListLoaded,
  GalleryImageOrderListLoadError,
  GalleryManagerActionTypes,
  GalleryReloadCase,
  GalleryReset,
  ImageAdded,
  ImageAddError,
  ImageDelete,
  ImageDeleted,
  ImageDeleteError,
  ImageListReordered,
  ImageListReorderError,
  ImageReplace,
  ImageReplaced,
  ImageReplaceError,
  ImageUploadFinished,
  LoadGalleryImageOrderList,
  PromotionalImagesUpdated,
  PromotionalImagesUpdateError,
  PublicGalleryImageOrderListLoad,
  ReorderImageList,
  ResellerGalleryImageOrderListLoad,
  SyncImagesTotalSize,
  UpdatePromotionalImages,
} from './gallery-manager.actions';
import {
  catchError,
  concatMap,
  debounceTime,
  filter,
  map,
  mergeMap,
  pairwise,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { CaseFilesService, CaseFilesViewService } from '@vpfa/rest-api/valuation';
import { BasicNotificationsService, FilesNotificationsService } from '@vpfa/shared/notifications';
import { mapFileToDataUrl } from '@vpfa/utils';
import { GalleryManagerFacade } from './gallery-manager.facade';
import { CasesFacade } from '@vpfa/dealer/case/data';

@Injectable()
export class GalleryManagerEffects {
   determineIfGalleryShouldBeReset = createEffect(() => this.actions$.pipe(
    ofType<GalleryReloadCase>(GalleryManagerActionTypes.GalleryReloadCase),
    withLatestFrom(this.galleryManagerFacade.getGalleryCaseId$),
    filter(([action, currentCaseId]) => action.payload.caseId !== currentCaseId || action.payload.forceReload),
    switchMap(([action, _]) => of(new GalleryReset({ isClone: false, nextAction: action.payload.nextAction })))
  ));

   loadCaseImagesAfterReset$ = createEffect(() => this.actions$.pipe(
    ofType<GalleryReset>(GalleryManagerActionTypes.GalleryReset),
    filter(action => action.payload.nextAction !== null),
    switchMap(action => of(action.payload.nextAction))
  ));

   onReorderImageList$ = createEffect(() => this.actions$.pipe(
    ofType<ImageListReordered>(GalleryManagerActionTypes.ImageListReordered),
    map(action => new LoadGalleryImageOrderList(action.payload.aggregateId))
  ));

   addImage$ = createEffect(() => this.actions$.pipe(
    ofType<AddImage>(GalleryManagerActionTypes.AddImage),
    concatMap(action =>
      this.caseFilesService.addImages(action.payload.command.aggregateRootId, [action.payload.command.file]).pipe(
        switchMap(res =>
          mapFileToDataUrl([action.payload.command.file as any, res.fileKeys[0]]).pipe(
            map(
              file =>
                new ImageAdded({
                  caseId: action.payload.command.aggregateRootId,
                  file,
                  fileName: action.payload.command.file.name,
                  isFirst: action.payload.command.isFirst,
                  selectedForAdvertisement: action.payload.selectedForAdvertisement,
                  fileId: res.fileKeys[0],
                })
            )
          )
        ),
        catchError(err =>
          of(
            new ImageAddError({
              error: err.error,
              fileName: action.payload.command.file.name,
              isFirst: action.payload.command.isFirst,
            })
          )
        )
      )
    )
  ));

   fileAdded$ = createEffect(() => this.actions$.pipe(
    ofType<ImageAdded>(GalleryManagerActionTypes.ImageAdded),
    tap(action => {
      this.filesNotification.success(action.payload.fileName);
    }),
    map(action => new LoadGalleryImageOrderList(action.payload.caseId))
  ));

   fileAddError$ = createEffect(() => this.actions$.pipe(
    ofType<ImageAddError>(GalleryManagerActionTypes.ImageAddError),
    tap(action => {
      this.filesNotification.error(action.payload.fileName, action.payload.error);
    }),
    withLatestFrom(this.galleryManagerFacade.isUploading$)
  ), { dispatch: false });

   caseImageDelete$ = createEffect(() => this.actions$.pipe(
    ofType<ImageDelete>(GalleryManagerActionTypes.ImageDelete),
    switchMap(({ payload }) =>
      this.caseFilesService.deleteImage(payload.caseId, payload.fileKey).pipe(
        map(() => new ImageDeleted(payload)),
        catchError(err => of(new ImageDeleteError({ error: err.error, fileKey: payload.fileKey })))
      )
    )
  ));

   caseImageReplace$ = createEffect(() => this.actions$.pipe(
    ofType<ImageReplace>(GalleryManagerActionTypes.ImageReplace),
    switchMap(action =>
      this.caseFilesService
        .replaceImage(action.payload.aggregateRootId, action.payload.image, action.payload.originalFileKey)
        .pipe(
          map(
            response =>
              new ImageReplaced({
                caseId: action.payload.aggregateRootId,
                oldFileKey: action.payload.originalFileKey,
                newFileKey: response.fileKeys?.[0],
                isSelectedForAdvertisement: action.isSelectedForAdvertisement,
              })
          ),
          catchError(err => of(new ImageReplaceError()))
        )
    )
  ));

   caseImageReplaced$ = createEffect(() => this.actions$.pipe(
    ofType<ImageReplaced>(GalleryManagerActionTypes.ImageReplaced),
    tap(() => {
      this.notification.success('files.caseFiles.caseImageReplaced');
    }),
    switchMap(action => [
      new fromGalleryManagerActions.LoadGalleryImageOrderList(action.payload.caseId),
      new fromImageFrameActions.FramedImagePreviewClear(),
    ])
  ));

   caseFileDeleted$ = createEffect(() => this.actions$.pipe(
    ofType<ImageDeleted>(GalleryManagerActionTypes.ImageDeleted),
    tap(() => {
      this.notification.success('files.caseFiles.caseImageDeleted');
    }),
    map(action => new LoadGalleryImageOrderList(action.payload.caseId))
  ));

   updateImagesOrder$ = createEffect(() => this.actions$.pipe(
    ofType<ReorderImageList>(GalleryManagerActionTypes.ReorderImageList),
    switchMap(({ payload }) =>
      this.caseFilesService.updateImagesOrder(payload).pipe(
        map(res => new ImageListReordered(res)),
        tap(() => {
          this.notification.success('vehicleGallery.galleryImageOrderUpdated');
        }),
        catchError(err => of(new ImageListReorderError()))
      )
    )
  ));

   addPromotionalImages$ = createEffect(() => this.actions$.pipe(
    ofType<UpdatePromotionalImages>(GalleryManagerActionTypes.UpdatePromotionalImages),
    mergeMap(({ payload }) =>
      this.caseFilesService.updatePromoImages(payload).pipe(
        map(res => new PromotionalImagesUpdated(res)),
        catchError(err => of(new PromotionalImagesUpdateError()))
      )
    )
  ));

   onPromotionalImagesUpdated$ = createEffect(() => this.actions$.pipe(
    ofType<PromotionalImagesUpdated>(GalleryManagerActionTypes.PromotionalImagesUpdated),
    tap(() => {
      this.notification.success('vehicleGallery.promotionalImagesUpdated');
    }),
    map(({ payload }) => new LoadGalleryImageOrderList(payload.aggregateId, true))
  ));

   syncImageOrderList$ = createEffect(() => this.actions$.pipe(
    ofType<LoadGalleryImageOrderList>(GalleryManagerActionTypes.LoadGalleryImageOrderList),
    switchMap(({ payload, synchronize }) =>
      this.caseFilesViewService.getCaseImageListWithOrder(payload).pipe(
        map(res => new GalleryImageOrderListLoaded(res, synchronize)),
        catchError(err => of(new GalleryImageOrderListLoadError()))
      )
    )
  ));

   loadResellerGalleryImageOrderList$ = createEffect(() => this.actions$.pipe(
    ofType<ResellerGalleryImageOrderListLoad>(GalleryManagerActionTypes.ResellerGalleryImageOrderListLoad),
    switchMap(action =>
      this.caseFilesViewService.getResellerCaseImageListWithOrder(action.caseId).pipe(
        map(res => new fromGalleryManagerActions.ResellerGalleryImageOrderListLoaded(res)),
        catchError(err => of(new fromGalleryManagerActions.ResellerGalleryImageOrderListLoadError()))
      )
    )
  ));

   loadPublicGalleryImageOrderList$ = createEffect(() => this.actions$.pipe(
    ofType<PublicGalleryImageOrderListLoad>(GalleryManagerActionTypes.PublicGalleryImageOrderListLoad),
    switchMap(action =>
      this.caseFilesViewService.getCasePublicImageListWithOrder(action.caseId).pipe(
        map(res => new fromGalleryManagerActions.PublicGalleryImageOrderListLoaded(res)),
        catchError(err => of(new fromGalleryManagerActions.PublicGalleryImageOrderListLoadError()))
      )
    )
  ));

   uploadFinished$ = createEffect(() => this.actions$.pipe(
    ofType(GalleryManagerActionTypes.ImageAdded),
    withLatestFrom(
      this.galleryManagerFacade.isUploading$.pipe(
        pairwise(),
        map(([wasUploading, isUploading]) => wasUploading === true && isUploading === false)
      )
    ),
    filter(([, uploadFinished]) => uploadFinished === true),
    map(() => new ImageUploadFinished())
  ));

   syncTotalSize$ = createEffect(() => this.actions$.pipe(
    ofType(
      GalleryManagerActionTypes.PromotionalImagesUpdated,
      GalleryManagerActionTypes.ImageAdded,
      GalleryManagerActionTypes.ImageDeleted
    ),
    withLatestFrom(this.casesFacade.activeCaseId$),
    debounceTime(1100),
    switchMap(([, caseId]) =>
      this.caseFilesViewService.getCaseImageListWithOrder(caseId).pipe(
        map(res => new SyncImagesTotalSize(res)),
        catchError(err => of(new GalleryImageOrderListLoadError()))
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private galleryManagerFacade: GalleryManagerFacade,
    private caseFilesService: CaseFilesService,
    private caseFilesViewService: CaseFilesViewService,
    private notification: BasicNotificationsService,
    private filesNotification: FilesNotificationsService,
    private casesFacade: CasesFacade
  ) {}
}
