import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'vpfa-not-available-info-box',
  templateUrl: './not-available-info-box.component.html',
  styleUrls: ['./not-available-info-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotAvailableInfoBoxComponent {
  @Input() mobile = false;
}
