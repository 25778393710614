import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  GALLERY_MANAGER_FEATURE_KEY,
  GalleryImageOrderListState,
  GalleryManagerState,
  PromotionalImagesState,
} from './gallery-manager.reducer';
import { FileItemList } from '@vpfa/shared/files';
import { sortImagesByOrder } from '../utils/images.utils';

// Lookup the 'Files' feature state managed by NgRx
const getGalleryManagerState = createFeatureSelector<GalleryManagerState>(GALLERY_MANAGER_FEATURE_KEY);
const getIsImagesLoading = createSelector(
  getGalleryManagerState,
  (state: GalleryManagerState) => state.loadingCounter > 0 || state.isLoading
);
const getIsUploading = createSelector(getGalleryManagerState, (state: GalleryManagerState) => state.isUploading);

const getImagePreviewState = createSelector(getGalleryManagerState, (state: GalleryManagerState) => state.imagePreview);
const getIsImagePreviewOpen = createSelector(getImagePreviewState, state => state.open);

const getUploadQueueSize = createSelector(
  getGalleryManagerState,
  (state: GalleryManagerState) => state.uploadQueue.size
);

const getUploadQueueCurrentItemIndex = createSelector(
  getGalleryManagerState,
  (state: GalleryManagerState) => state.uploadQueue.currentItemIndex
);

const getImagesOrderListState = createSelector(
  getGalleryManagerState,
  (state: GalleryManagerState) => state.galleryImageOrderList
);
const getImagesOrderList = createSelector(getImagesOrderListState, (state: GalleryImageOrderListState) => state.items);
const getIsImagesOrderListLoading = createSelector(
  getImagesOrderListState,
  (state: GalleryImageOrderListState) => state.loading
);
const getIsImagesOrderListLoaded = createSelector(
  getImagesOrderListState,
  (state: GalleryImageOrderListState) => state.loaded
);

const getGalleryImagesTotalSize = createSelector(
  getImagesOrderListState,
  (state: GalleryImageOrderListState) => state.totalSize
);

const getGalleryImagesTotalCount = createSelector(
  getImagesOrderListState,
  (state: GalleryImageOrderListState) => state.items.length
);

const getPromotionalImagesState = createSelector(getGalleryManagerState, state => state.promotionalImages);

const getIsProcessingPromotionalImages = createSelector(
  getPromotionalImagesState,
  (state: PromotionalImagesState) => state.isProcessing
);

const getGalleryCaseId = createSelector(getGalleryManagerState, (state: GalleryManagerState) => state.caseId);

export const galleryManagerQuery = {
  getIsImagesLoading,
  getIsUploading,
  getUploadQueueSize,
  getUploadQueueCurrentItemIndex,
  getImagesOrderList,
  getIsImagesOrderListLoading,
  getIsImagesOrderListLoaded,
  getGalleryImagesTotalSize,
  getIsImagePreviewOpen,
  getIsProcessingPromotionalImages,
  getGalleryImagesTotalCount,
  getGalleryCaseId,
};
