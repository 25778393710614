import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromVehicleEquipment from './+state/vehicle-equipment.reducer';
import { VehicleEquipmentEffects } from './+state/vehicle-equipment.effects';
import { VehicleEquipmentFacade } from './+state/vehicle-equipment.facade';
import { FormulaWizardEffects } from './+state/formula-wizard/formula-wizard.effects';
import { FormulaWizardFacade } from './+state/formula-wizard/formula-wizard.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromVehicleEquipment.VEHICLEEQUIPMENT_FEATURE_KEY, fromVehicleEquipment.reducer),
    EffectsModule.forFeature([VehicleEquipmentEffects, FormulaWizardEffects]),
  ],
  providers: [VehicleEquipmentFacade, FormulaWizardFacade],
})
export class DealerVehicleEquipmentDataModule {}
