<div *vpfaResponsiveIf="'web'; else mobile">
  <vpfa-expanding-header [text]="'uniqueData.modal.taxData' | translate"></vpfa-expanding-header>
  <vpfa-setting-box [divider]="false">
    <ng-container label>
      <vpfa-label [text]="'uniqueData.taxType' | translate"></vpfa-label>
    </ng-container>
    <ng-container content>
      <vpfa-select
        *ngIf="parentFormGroup"
        [allowClear]="false"
        [parentFormGroup]="parentFormGroup"
        [fc]="taxTypeControl"
        [options]="taxOptions"
        [translateLabel]="true"
        [customOptionTemplate]="customOption"
        [customSelectedTemplate]="customOption"
      >
      </vpfa-select>
    </ng-container>
  </vpfa-setting-box>
</div>

<ng-template #mobile>
  <div class="no-hor-scroll">
    <vpfa-mobile-subtitle [title]="'uniqueData.modal.taxData' | translate"></vpfa-mobile-subtitle>
    <div class="mb">
      <vpfa-vehicle-technical-data-single-field
        [label]="'uniqueData.taxType' | translate"
        [value]="(taxes.taxValue | numberLocale: '1.2-2' | lT) + '% ' + (taxCode ? (taxCode | translate) : '')"
      ></vpfa-vehicle-technical-data-single-field>
    </div>
  </div>
</ng-template>

<ng-template #customOption let-label="label">
  <span>{{ taxes.taxValue | numberLocale: '1.2-2' | lT }}% - {{ label | translate }}</span>
</ng-template>
