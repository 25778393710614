<div
  class="checkbox-button no-hor-scroll"
  [ngClass]="{ checked: checked }"
  (click)="onClick()"
  fxLayout="row"
  fxLayoutAlign="space-between"
>
  <div class="content">
    <ng-container *ngTemplateOutlet="contentTemplate; context: contentTemplateContext"></ng-container>
  </div>
  <div class="tick" *ngIf="checked">
    <i class="zmdi zmdi-check"></i>
  </div>
</div>
