import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@ngbracket/ngx-layout';
import { VehicleOrOfferNotFoundComponent } from './vehicle-or-offer-not-found/vehicle-or-offer-not-found.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [VehicleOrOfferNotFoundComponent],
  imports: [CommonModule, FlexModule, TranslateModule],
  exports: [VehicleOrOfferNotFoundComponent],
})
export class SharedComponentsModule {}
