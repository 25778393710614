import { PriceDisplayModel } from '../../../components/pricing-details-modal/pricing-details-modal.component';
import { CaseStatus, TaxedPriceDto } from '@vpfa/rest-api/valuation';
import { CaseHistoricalPrices } from '@vpfa/dealer/case/data';
import { StockPrices } from './stock-prices.enum';

export const setupStockPrices = (
  activeCaseDataTaxedPrice: TaxedPriceDto,
  historicalPrices: CaseHistoricalPrices,
  caseStatus: CaseStatus,
  canEditStockPrices: boolean
): PriceDisplayModel[] => {
  return [
    {
      name: 'pricingDetailsModal.effectiveTradeInPrice',
      date: null,
      price: activeCaseDataTaxedPrice?.effectiveTradeInPrice,
      tax: activeCaseDataTaxedPrice?.effectiveTradeInPriceTaxAmount,
      history: historicalPrices ? historicalPrices.effectiveTradeInPriceHistory : null,
      fcName: StockPrices.EFFECTIVE_TRADE_IN_PRICE,
      isEditable: canEditStockPrices,
      showHistory: false,
    },
    {
      name: 'pricingDetailsModal.askingPrice',
      date: null,
      price: activeCaseDataTaxedPrice?.askingPrice,
      tax: activeCaseDataTaxedPrice?.askingPriceTaxAmount,
      history: historicalPrices ? historicalPrices.askingPriceHistory : null,
      fcName: StockPrices.ASKING_PRICE,
      isEditable: canEditStockPrices,
      showHistory: false,
    },
    {
      name: 'pricingDetailsModal.resalePrice',
      date: null,
      price: activeCaseDataTaxedPrice?.resalePrice,
      tax: activeCaseDataTaxedPrice?.resalePriceTaxAmount,
      fcName: StockPrices.RESALE_PRICE,
      isEditable: canEditStockPrices,
      isNullable: true,
      showHistory: false,
    },
  ];
};
