<div ngClass.lt-mv="no-hor-scroll">
  <vpfa-action-button
    class="mobile"
    [buttonType]="buttonType"
    [disabled]="disabled"
    [outline]="outline"
    [border]="border"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </vpfa-action-button>
</div>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
