import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FileItemList } from '@vpfa/shared/files';
import { ImagesFacade } from '@vpfa/shared/images/data';
import { isEmpty, isNil } from 'lodash';

@Component({
  selector: 'vpfa-full-screen-gallery-modal',
  templateUrl: './full-screen-gallery-modal.component.html',
  styleUrls: ['./full-screen-gallery-modal.component.scss'],
})
export class FullScreenGalleryModalComponent {
  @Input() set images(images: FileItemList) {
    this._images = images;
    this.orderImages();
  }

  @Input() set selectedImageKey(key: string) {
    this._selectedImageKey = key;
    this.orderImages();
  }

  @Input() open = false;
  @Input() loading = false;
  @Input() imagesTotalCount: number = 0;
  @Output() closeModal = new EventEmitter();

  get images(): FileItemList {
    return this._images;
  }

  get selectedImageKey(): string {
    return this._selectedImageKey;
  }

  orderedImages: FileItemList = [];
  private _images: FileItemList;
  private _selectedImageKey: string;

  constructor(private imagesFacade: ImagesFacade) {}

  private orderImages() {
    if (!isNil(this.selectedImageKey) && !isEmpty(this.images) && this.allImagesLoaded(this.images) === true) {
      let start = this.images.map(image => image.fileKey).indexOf(this.selectedImageKey);
      if (start === -1) {
        start = 0;
      }
      if (this.images.length > 1 && start > 0) {
        this.orderedImages = [...this.images.slice(start, this.images.length), ...this.images.slice(0, start)];
      } else {
        this.orderedImages = this.images;
      }
    }
  }

  private allImagesLoaded(images: FileItemList): boolean {
    return images.length >= this.imagesTotalCount;
  }
}
