<div class="items">
  <ng-content></ng-content>
</div>

<vpfa-mobile-divider></vpfa-mobile-divider>

<div fxLayout="row" fxLayoutAlign="start center" ngClass.lt-mv="no-hor-scroll">
  <div fxFlex>
    <div class="total-label">{{ 'caseValuationDetails.total' | translate }}</div>
  </div>
  <div class="total-value">
    <ng-container *ngIf="!notAvailable">
      {{ totalValue }}
    </ng-container>
    <vpfa-price-with-icon *ngIf="notAvailable" [color]="'orange'"></vpfa-price-with-icon>
  </div>
</div>
