<span
  nz-tooltip
  [nzTooltipOverlayClassName]="!(isMobileView$ | async) ? overlayClass : 'tooltip-mobile'"
  [nzTooltipTitle]="isContentTemplateRef ? content : tooltipParsedContent"
  [nzTooltipTrigger]="tooltipTrigger$ | async"
  [nzTooltipPlacement]="placement"
  [(nzTooltipVisible)]="isVisible"
  (click)="onClick($event)"
>
  <i
    class="zmdi"
    ngClass.gt-mv="{{ 'icon-web ' + iconConfigClass }}"
    ngClass.lt-mv="{{ 'icon-mobile ' + iconConfigClass }}"
  ></i>
</span>

<ng-template #tooltipParsedContent>
  <span [innerHtml]="content"></span>
</ng-template>
