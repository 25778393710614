import { ReturnReportDto } from '@vpfa/rest-api/valuation';

export enum KEYS {
  registrationDocument = 'registrationDocument',
  deregistrationCertificate = 'deregistrationCertificate',
  vehicleInvoice = 'vehicleInvoice',
  leasingDisclaimer = 'leasingDisclaimer',
  technicalModifications = 'technicalModifications',
  generalOperatingLicense = 'generalOperatingLicense',
  technicalModificationCertificate = 'technicalModificationCertificate',
  fullExpertReport = 'fullExpertReport',
  receiptForDamageRepair = 'receiptForDamageRepair',
  validTechnicalInspectionAndExhaustEmissionCertificate = 'validTechnicalInspectionAndExhaustEmissionCertificate',
  fullServiceHistory = 'fullServiceHistory',
  tradeInContract = 'tradeInContract',
  customClearanceCertificate = 'customClearanceCertificate',
  leasingServiceCard = 'leasingServiceCard',
  codeCardRadio = 'codeCardRadio',
  codeCardRadioNumber = 'codeCardRadioNumber',
  userManual = 'userManual',
  vehicleKeysComplete = 'vehicleKeysComplete',
  warningTriangle = 'warningTriangle',
  firstAidKit = 'firstAidKit',
  vehicleToolKit = 'vehicleToolKit',
  fireExtinguisher = 'fireExtinguisher',
  chargingCablesComplete = 'chargingCablesComplete',
  navigation = 'navigation',
}

export function getReturnReportDefinition(): ReturnReportDto {
  const form = {};

  Object.keys(KEYS).forEach(key => {
    form[key] = [
      KEYS.codeCardRadioNumber,
      KEYS.generalOperatingLicense,
      KEYS.technicalModificationCertificate,
    ].includes(KEYS[key])
      ? null
      : false;
  });

  return form as ReturnReportDto;
}
