import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'vpfa-inline-editable-date',
  templateUrl: './inline-editable-date.component.html',
  styleUrls: ['./inline-editable-date.component.scss'],
})
export class InlineEditableDateComponent implements OnInit, OnDestroy {
  private _isInitialized = false;

  @Input() set date(setValue: string) {
    this._value = setValue;
    this.setupValue(this._value);
  }
  @Input() disabledDate;
  @Input() disabledDatePicker;
  @Input() isProcessing = false;
  @Output() submitValue = new EventEmitter<string>();

  formGroup = new UntypedFormGroup({
    regDate: new UntypedFormControl(null),
  });

  regDate = this.formGroup.get('regDate');
  inEditMode = false;

  private _onDestroy$ = new Subject<void>();
  private _value: string;

  constructor() {}

  ngOnInit() {
    this.regDate.valueChanges
      .pipe(
        filter(value => value !== this._value),
        takeUntil(this._onDestroy$)
      )
      .subscribe(value => {
        this.onSubmitEdit(value);
      });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  onSubmitEdit(value: string) {
    if (this._isInitialized) {
      this.submitValue.emit(value);
      this.inEditMode = false;
    }
  }

  onDatepickerToggle(isOpen: boolean) {
    this.inEditMode = isOpen;
  }

  private setupValue(setValue) {
    this._isInitialized = false;
    this.regDate.reset(setValue);

    this._isInitialized = true;
  }
}
