import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@ngbracket/ngx-layout';
import { TopPanelComponent } from './components/top-panel/top-panel.component';
import { InputLabelDirective } from './directives/input-label/input-label.directive';
@NgModule({
  imports: [
    CommonModule,
    FlexModule
  ],
  declarations: [
    TopPanelComponent,
    InputLabelDirective
  ],
  exports: [
    TopPanelComponent,
    InputLabelDirective
  ]
})
export class SharedTopPanelModule {}
