import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AdminSeedService } from './api/adminSeed.service';
import { AmsSynchronizeService } from './api/amsSynchronize.service';
import { AnnouncementService } from './api/announcement.service';
import { AnnouncementViewService } from './api/announcementView.service';
import { AuthService } from './api/auth.service';
import { BranchService } from './api/branch.service';
import { BranchViewService } from './api/branchView.service';
import { BusinessService } from './api/business.service';
import { BusinessViewService } from './api/businessView.service';
import { CountryService } from './api/country.service';
import { CountryViewService } from './api/countryView.service';
import { DealerNetworkAdminServiceMaintenanceService } from './api/dealerNetworkAdminServiceMaintenance.service';
import { EnvironmentConfigViewService } from './api/environmentConfigView.service';
import { ExperianService } from './api/experian.service';
import { ExperianViewService } from './api/experianView.service';
import { LogoService } from './api/logo.service';
import { LogoViewService } from './api/logoView.service';
import { SubscriptionViewService } from './api/subscriptionView.service';
import { TableService } from './api/table.service';
import { TableConfigurationViewService } from './api/tableConfigurationView.service';
import { TableauReportViewService } from './api/tableauReportView.service';
import { TranslationService } from './api/translation.service';
import { TranslationViewService } from './api/translationView.service';
import { TwinnerService } from './api/twinner.service';
import { TwinnerViewService } from './api/twinnerView.service';
import { UserService } from './api/user.service';
import { UserContextService } from './api/userContext.service';
import { UserGroupService } from './api/userGroup.service';
import { UserGroupViewService } from './api/userGroupView.service';
import { UserSettingsService } from './api/userSettings.service';
import { UserViewService } from './api/userView.service';
import { VideoViewService } from './api/videoView.service';
import { VinCallViewService } from './api/vinCallView.service';
import { VinPackageViewService } from './api/vinPackageView.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AdminSeedService,
    AmsSynchronizeService,
    AnnouncementService,
    AnnouncementViewService,
    AuthService,
    BranchService,
    BranchViewService,
    BusinessService,
    BusinessViewService,
    CountryService,
    CountryViewService,
    DealerNetworkAdminServiceMaintenanceService,
    EnvironmentConfigViewService,
    ExperianService,
    ExperianViewService,
    LogoService,
    LogoViewService,
    SubscriptionViewService,
    TableService,
    TableConfigurationViewService,
    TableauReportViewService,
    TranslationService,
    TranslationViewService,
    TwinnerService,
    TwinnerViewService,
    UserService,
    UserContextService,
    UserGroupService,
    UserGroupViewService,
    UserSettingsService,
    UserViewService,
    VideoViewService,
    VinCallViewService,
    VinPackageViewService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}