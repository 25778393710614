<vpfa-field-container
  [isText]="false"
  [isNumber]="true"
  [isCurrency]="false"
  [heightCompensationPx]="0"
  [displayErrors]="shouldDisplayErrors$.getValue() && getFormControl().dirty"
  [control]="getFormControl()"
  [errorMsgVertPosition]="errorMsgVertPosition"
  [errorMessages]="errorMessages"
>
  <input
    #input
    vpfaCharCountLimiter
    [maxCharCount]="charCountLimit"
    vpfaIntervalFormatter
    [maxFractionNumber]="maxFractionNumber"
    (focus)="onFocus()"
    (blur)="onBlur()"
    (keydown.enter)="onSubmit($event)"
    [placeholder]="placeholder"
    [formControl]="getFormControl()"
    nz-input
    type="text"
    class="text-field text-align-right interval-number-field"
  />
</vpfa-field-container>
