<vpfa-repair-and-maintenance-modal
  [areas]="lightDamageEstimatorAreas$ | async"
  (closeAction)="closeDamageEstimatorModal()"
  (saveAction)="saveDamageEstimator($event)"
  [loading]="(isSavingDamageEstimator$ | async) || (isLoadingDamageEstimator$ | async)"
  [show]="isDamageEstimatorModalShown"
></vpfa-repair-and-maintenance-modal>

<div class="damage-section mt" fxLayout="row wrap" fxLayoutAlign="space-between flex-end">
  <div fxLayout="row" fxLayoutGap="10px">
    <vpfa-arrow-link id="openMinorDamageEstimatorButton" (clicked)="showDamageEstimatorModal()">
      <span>
        {{ 'caseValuationDetails.calculateWithLightDamage' | translate }}
      </span>
    </vpfa-arrow-link>
    <vpfa-label text="{{ 'caseValuationDetails.minor' | translate }}"></vpfa-label>
  </div>
  <div>
    <span id="minorDamageValue" class="total-price">
      {{ minorTotal | oppositeValue | currencyLocale: null:null:'1.2-2' | lT }}
    </span>
  </div>
</div>

<div *ngIf="!hideRepairEstimateCost" class="damage-section" fxLayout="row wrap" fxLayoutAlign="space-between flex-end">
  <div fxLayout="row" fxLayoutGap="10px" [ngClass]="{ disabled: notAvailable }" class="labels-container">
    <vpfa-arrow-link
      id="goToMajorDamageEstimatorPageButton"
      (clicked)="gotToRepairEstimate()"
      [ngClass]="{ disabled: notAvailable }"
    >
      <span>
        {{ 'caseValuationDetails.calculateWithRepairEstimate' | translate }}
      </span>
    </vpfa-arrow-link>
    <vpfa-label text="{{ 'caseValuationDetails.major' | translate }}"></vpfa-label>
    <span>
      <i class="zmdi orange-color" [ngClass]="{ 'zmdi-info': notAvailable }"></i>
    </span>
    <vpfa-inline-loader [isLoading]="isVinEreDataLoading"></vpfa-inline-loader>
  </div>
  <div>
    <span *ngIf="!notAvailable" id="majorDamageValue" class="total-price">
      {{ majorTotal | oppositeValue | currencyLocale: null:null:'1.2-2' | lT }}
    </span>
    <vpfa-price-with-icon *ngIf="notAvailable" [color]="'orange'"></vpfa-price-with-icon>
  </div>
</div>
