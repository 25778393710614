import { NotificationsAction, NotificationsActionTypes } from './notifications.actions';
import { NotificationModel } from '../models/notification.model';
import { produce } from 'immer';
import { FileNotificationModel } from '../models/file-notification.model';

export const NOTIFICATIONS_FEATURE_KEY = 'notifications';

export interface NotificationsState {
  basicNotificationsList: NotificationModel[];
  filesNotificationsList: FileNotificationModel[];
  filesUploadCount: number;
  invalidFilesCount: number;
}

export interface NotificationsPartialState {
  readonly [NOTIFICATIONS_FEATURE_KEY]: NotificationsState;
}

export const initialState: NotificationsState = {
  basicNotificationsList: [],
  filesNotificationsList: [],
  invalidFilesCount: 0,
  filesUploadCount: 0,
};

/**
 * List to show just one notification for enlisted titles. For example Unauthorized error may be fired multiple times
 * when user is logged out for some reason, and all further requests will be rejected. So it is sufficient to show
 * this error only once to the user.
 */
const ignoreWhenMultiple = ['vpError.Unauthorized'];

export function reducer(state: NotificationsState = initialState, action: NotificationsAction): NotificationsState {
  switch (action.type) {
    case NotificationsActionTypes.AddBasicNotification: {
      return produce(state, newState => {
        if (
          ignoreWhenMultiple.includes(action.payload.title) &&
          state.basicNotificationsList.find(x => x.title === action.payload.title)
        ) {
          return;
        }

        const index = state.basicNotificationsList.map(entity => entity.title).lastIndexOf(action.payload.title);
        if (index !== -1 && index !== state.basicNotificationsList.length - 1) {
          newState.basicNotificationsList.splice(index + 1, 0, action.payload);
        } else {
          newState.basicNotificationsList.push(action.payload);
        }
      });
    }
    case NotificationsActionTypes.AddFileNotification: {
      return produce(state, newState => {
        newState.filesNotificationsList.push(action.payload);
      });
    }
    case NotificationsActionTypes.SetCountOfFilesUpload: {
      return produce(state, newState => {
        newState.filesUploadCount = action.count;
      });
    }
    case NotificationsActionTypes.SetInvalidFilesCount: {
      return produce(state, newState => {
        newState.invalidFilesCount = action.count;
      });
    }
    case NotificationsActionTypes.ClearAllNotifications: {
      return produce(state, newState => {
        newState.basicNotificationsList = [];
        newState.filesNotificationsList = [];
        newState.filesUploadCount = 0;
      });
    }
  }
  return state;
}
