import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SorcIntegrationPartialState } from './sorc-integration.reducer';
import { fromSorcIntegrationActions } from './sorc-integration.actions';
import { sorcIntegrationQuery } from './sorc-integration.selectors';
import { MakeDto } from '../models/make-dto';
import { ModelDto } from '../models/model-dto';
import { SelectedOptionalSearchFilters } from '../models/optional-search-filters';
import { MandatorySearchFilters } from '../models/mandatory-search-filters';

@Injectable()
export class SorcIntegrationFacade {
  get makesList$() {
    return this.store.pipe(select(sorcIntegrationQuery.getMakesList));
  }

  get makesListLoading$() {
    return this.store.pipe(select(sorcIntegrationQuery.getMakesListLoading));
  }

  get modelsList$() {
    return this.store.pipe(select(sorcIntegrationQuery.getModelsList));
  }

  get modelsListLoading$() {
    return this.store.pipe(select(sorcIntegrationQuery.getModelsListLoading));
  }

  get vehiclesList$() {
    return this.store.pipe(select(sorcIntegrationQuery.getVehiclesList));
  }

  get vehiclesListTotalResults$() {
    return this.store.pipe(select(sorcIntegrationQuery.getVehiclesListTotalResults));
  }

  get vehiclesOptionalSearchFiltersOptions$() {
    return this.store.pipe(select(sorcIntegrationQuery.getVehiclesOptionalSearchFiltersOptions));
  }

  get vehiclesListLoading$() {
    return this.store.pipe(select(sorcIntegrationQuery.getVehiclesListLoading));
  }

  constructor(private store: Store<SorcIntegrationPartialState>) {}

  loadMakesList(typeCodes: number[]) {
    this.store.dispatch(new fromSorcIntegrationActions.LoadMakesList(typeCodes));
  }

  loadModelsList(typeCodes: number[], make: MakeDto) {
    this.store.dispatch(new fromSorcIntegrationActions.LoadModelsList({ typeCodes: typeCodes, make: make }));
  }

  loadVehiclesList(
    model: ModelDto,
    pageSize: number,
    mandatorySearchFilters: MandatorySearchFilters,
    optionalSearchFilters?: SelectedOptionalSearchFilters
  ) {
    this.store.dispatch(
      new fromSorcIntegrationActions.LoadVehiclesList({
        model,
        pageSize,
        mandatorySearchFilters,
        optionalSearchFilters,
      })
    );
  }

  clearMakesList() {
    this.store.dispatch(new fromSorcIntegrationActions.ClearMakesList());
  }

  clearModelsList() {
    this.store.dispatch(new fromSorcIntegrationActions.ClearModelsList());
  }

  clearVehiclesList() {
    this.store.dispatch(new fromSorcIntegrationActions.ClearVehiclesList());
  }
}
