import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromCasesReducer from './+state/cases.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CasesEffects } from './+state/cases.effects';
import { AdjustmentsEffects } from './+state/adjustments/adjustments.effects';
import { CasesFacade } from './+state/cases.facade';
import { ProvenanceCheckEffects } from './+state/provenance-check/provenance-check.effects';
import { AdjustmentsFacade } from './+state/adjustments/adjustments.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromCasesReducer.CASES_FEATURE_KEY, fromCasesReducer.reducer),
    EffectsModule.forFeature([CasesEffects, AdjustmentsEffects, ProvenanceCheckEffects])
  ],
  providers: [CasesFacade, AdjustmentsFacade],
})
export class CaseDataModule {}
