import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[maskSensitiveData]',
})
export class SensitiveDataDirective implements OnInit {
  @Input() maskSensitiveData: string | null = null;

  constructor(public renderer: Renderer2, public hostElement: ElementRef) {}

  ngOnInit() {
    this.renderer.setAttribute(this.hostElement.nativeElement, 'data-dd-privacy', 'mask');

    if (!!this.maskSensitiveData) {
      this.renderer.setAttribute(this.hostElement.nativeElement, 'data-dd-action-name', this.maskSensitiveData);
    }
  }
}
