import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { isString, isEmpty } from 'lodash';
import { isEllipsisActive } from '@vpfa/utils';

@Component({
  selector: 'vpfa-label-value',
  templateUrl: './label-value.component.html',
  styleUrls: ['./label-value.component.scss'],
})
export class LabelValueComponent {
  @Input() label: string;
  @Input() set value(v: string | string[]) {
    if (isEmpty(v)) {
      this.valueArray = [];
      return;
    }
    this.valueArray = isString(v) ? [v] : [...v];
  }

  @Input() boldLabel = false;

  /**
   * @deprecated use `displayType` instead.
   */
  @Input()
  public set pre(isEnabled: boolean) {
    if (isEnabled) {
      this.displayType = 'pre';
    }
  }

  @Input() displayType: 'email' | 'phone' | 'pre' | 'text' = 'text';
  @Input() tooltipContent: string;

  @ViewChild('valueElement', { read: ElementRef }) valueElement: ElementRef;

  valueArray: string[] = [];
  ellipsisActive = false;

  isEllipsisActive() {
    this.ellipsisActive = isEllipsisActive(this.valueElement.nativeElement);
  }
}
