import { Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { isNil } from 'lodash';

export const plateNoLengthValidatorKey = 'plateNoLength';

export const PLATE_NO_LENGTH = 10;

export const plateNoLengthValidator: ValidatorFn = (control: AbstractControl) => {
  const validateErrors = Validators.maxLength(PLATE_NO_LENGTH)(control);
  if (isNil(validateErrors)) {
    return null;
  }
  return {
    [plateNoLengthValidatorKey]: true
  };
};