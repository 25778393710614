import { ChangeDetectionStrategy, Component, EventEmitter } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DataTableAction, DataTableActionEvent } from '../../models/data-table-action';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'vpfa-checkbox-renderer',
  templateUrl: './checkbox-renderer.component.html',
  styleUrls: ['./checkbox-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  actionEmitter: EventEmitter<DataTableActionEvent>;
  formGroup = new UntypedFormGroup({
    control: new UntypedFormControl(null),
    disabled: new UntypedFormControl(false),
  });

  disabledMessage: string;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    if (this.params) {
      const context = this.params.context;
      if (context?.componentParent?.actionClicked) {
        this.actionEmitter = context.componentParent.actionClicked;
      }
    }
    this.formGroup.get('control').setValue(this.params.value.value);
    this.formGroup.get('disabled').setValue(this.params.value.disabled);
    this.disabledMessage = this.params.value.disabledMessage;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  emitAction() {
    if (this.actionEmitter) {
      this.actionEmitter.emit({
        action: DataTableAction.ACTIVATE,
        data: this.params.data,
      });
    }
  }
}
