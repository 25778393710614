import { Component, Input } from '@angular/core';
import { SharedLoadersService } from '../../services/shared-loaders.service';

@Component({
  selector: 'vpfa-section-loader',
  templateUrl: './section-loader.component.html',
  styleUrls: ['./section-loader.component.scss'],
})
export class SectionLoaderComponent {
  @Input() isSectionInline = false;

  private _isLoading = false;
  @Input() set isLoading(setValue: boolean) {
    this._isLoading = setValue;
    this.sharedLoadersService.setIsSectionLoaderActiveSubjectState(setValue);
  }
  get isLoading() {
    return this._isLoading;
  }

  constructor(private sharedLoadersService: SharedLoadersService) {}
}
