<div *vpfaResponsiveIf="'web'">
  <div class="estimator-container" fxLayout="column">
    <div class="estimator-header" fxLayout="row" fxLayoutAlign="start center">
      <div class="estimator-col-area area-label-header" fxFlex></div>
      <div class="estimator-col-part part-label-header" fxFlex>
        {{ 'damageEstimatorModal.area' | translate }} / {{ 'damageEstimatorModal.part' | translate }}
      </div>
      <div class="estimator-col-cost" fxFlex>{{ 'damageEstimatorModal.cost' | translate }}</div>
      <div class="estimator-col-damage damage-label-header" fxFlex>{{ 'damageEstimatorModal.damage' | translate }}</div>
      <div class="estimator-col-skip" fxFlex>{{ 'damageEstimatorModal.includeIntoValuation' | translate }}</div>
    </div>
    <div class="estimator-content">
      <vpfa-estimator-area
        *ngFor="let area of filteredAreas"
        [visible]="visible"
        [submitted]="submitted"
        (costChanged)="updateTotalPrice()"
        [area]="area"
        [estimatorForm]="estimatorForm"
      ></vpfa-estimator-area>
    </div>
  </div>
  <div fxLayout="row" class="estimator-total-price" fxLayoutAlign="space-between">
    <span class="total-label">{{ 'damageEstimatorModal.total' | translate }}:</span>
    <span class="total-value">{{ total | currencyLocale }}</span>
  </div>
</div>

<div *vpfaResponsiveIf="'mobile'">
  <div [fxLayoutAlign]="'space-between center'" class="area-label">
    <vpfa-label text="{{ 'damageEstimatorModal.area' | translate }}"></vpfa-label>
    <vpfa-label text="{{ 'damageEstimatorModal.cost' | translate }}"></vpfa-label>
  </div>
  <vpfa-estimator-area
    *ngFor="let area of filteredAreas"
    [submitted]="submitted"
    (costChanged)="emitTotal()"
    [area]="area"
    [estimatorForm]="estimatorForm"
  ></vpfa-estimator-area>
</div>
