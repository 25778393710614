<div class="price">
  <vpfa-price-with-icon
    [attr.data-vehicle-price]="price"
    (iconClicked)="showHistoricalPrices.emit({ name: name, price: price, tax: tax, history: history })"
    [hideIcon]="price === null || !history || !history.length"
    [price]="price !== null ? (price | currencyLocale: null:null:'1.2-2' | lT) : price"
    [color]="isForecastAvailable ? 'green' : 'dark-gray'"
  ></vpfa-price-with-icon>
  <vpfa-price-tax
    [attr.data-vehicle-price-tax]="tax"
    [caseTax]="caseTax"
    [tax]="tax | currencyLocale: null:null:'1.2-2' | lT"
    [hideWhenTaxIncluded]="true"
  ></vpfa-price-tax>
</div>
