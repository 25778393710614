import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { DictionariesFacade } from '@vpfa/dealer/dictionaries';
import { VehicleTypeDto } from '@vpfa/rest-api/identification';

@Pipe({
  name: 'findVehicleTypeByCode',
})
export class FindVehicleTypeByCodePipe implements PipeTransform {
  constructor(private dictionariesFacade: DictionariesFacade) {}

  transform(vehicleTypeCode: number): Observable<string> {
    return this.dictionariesFacade.vehicleTypeList$.pipe(
      filter(vehicleTypeList => Boolean(vehicleTypeList)),
      map(
        (vehicleTypeList: VehicleTypeDto[]) =>
          vehicleTypeList.find((vehicleType: VehicleTypeDto) => vehicleType.code === vehicleTypeCode)?.name
      )
    );
  }
}
