import { LogoAction, LogoActionTypes } from './logo.actions';
import { produce } from 'immer';

export const LOGO_FEATURE_KEY = 'logo';

export interface LogoEntityState {
  logo: string;
  isLoading: boolean;
}

export interface LogoState {
  business: LogoEntityState;
  country: LogoEntityState;
}

export interface LogoPartialState {
  readonly [LOGO_FEATURE_KEY]: LogoState;
}

export const initialState: LogoState = {
  business: {
    logo: null,
    isLoading: false,
  },
  country: {
    logo: null,
    isLoading: false,
  },
};

export function reducer(state: LogoState = initialState, action: LogoAction): LogoState {
  switch (action.type) {
    case LogoActionTypes.LoadLogoForBusiness:
      return produce(state, newState => {
        newState.business.logo = null;
        newState.business.isLoading = true;
      });
    case LogoActionTypes.LogoForBusinessLoaded:
      return produce(state, newState => {
        newState.business.logo = action.payload;
        newState.business.isLoading = false;
      });
    case LogoActionTypes.LogoForBusinessLoadError:
      return produce(state, newState => {
        newState.business.isLoading = false;
      });

    case LogoActionTypes.LoadLogoForCountry:
      return produce(state, newState => {
        newState.country.isLoading = true;
      });
    case LogoActionTypes.LogoForCountryLoaded:
      return produce(state, newState => {
        newState.country.logo = action.payload;
        newState.country.isLoading = false;
      });
    case LogoActionTypes.LogoForCountryLoadError:
      return produce(state, newState => {
        newState.country.isLoading = false;
      });

    case LogoActionTypes.ClearLogo:
      return initialState
  }

  return state;
}
