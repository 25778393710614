<vpfa-modal
  [modalName]="
    hideAllExceptEmissionsAndConsumption ? 'Vehicle Reduced Technical Data' : 'Vehicle Full Technical Data'
  "
  [showBackdrop]="true"
  [isVisible]="isVisible"
  [isClosable]="false"
  [keyboard]="false"
  [maskClosable]="false"
  width="60vw"
  (closeAction)="close()"
  disableHeader="true"
  disableFooter="true"
>
  <vpfa-modal-content>
    <vpfa-vehicle-full-technical-data
      *ngIf="vehicleData"
      [hideAllExceptEmissionsAndConsumption]="hideAllExceptEmissionsAndConsumption"
      [vehicleData]="vehicleData"
      [technicalDataFeatureConfig]="technicalDataFeatureConfig"
      [id]="aggregateRootId"
      [loading]="loading"
      [emissionClassOptions]="emissionClassOptions"
      (closeAction)="close()"
      (submitAction)="submit($event)"
    ></vpfa-vehicle-full-technical-data>
  </vpfa-modal-content>
</vpfa-modal>
