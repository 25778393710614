import { Injectable, ComponentRef, EventEmitter, ComponentFactoryResolver, Injector, Type } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DynamicComponentsStackService {
  private componentsRef: ComponentRef<DynamicComponentStack>[] = [];
  private currentComponentRefSubject = new BehaviorSubject<ComponentRef<DynamicComponentStack>>(null);
  get currentComponentRef$() {
    return this.currentComponentRefSubject.asObservable();
  }

  constructor(
    private injector: Injector,
    private router: Router
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(() => {
      this.removeAll();
    });
  }

  add<T extends DynamicComponentStack>(component: Type<T>, injector: Injector = this.injector): ComponentRef<T> {
    const componentFactoryResolver =  injector.get(ComponentFactoryResolver);
    const componentRef = componentFactoryResolver.resolveComponentFactory(component).create(injector);
    return this.addComponentRef(componentRef);
  }

  addComponentRef<T extends DynamicComponentStack>(componentRef: ComponentRef<T>) {
    componentRef.instance.close.subscribe(() => {
      this.removeTop();
    });
    this.componentsRef.push(componentRef);
    this.changeLast();
    return componentRef;
  }

  removeTop() {
    const lastElement = this.componentsRef.pop();
    if (lastElement) {
      lastElement.destroy();
    }
    this.changeLast();
  }

  removeAll() {
    if (this.componentsRef.length > 0) {
      this.componentsRef.forEach(componentRef => {
        componentRef.destroy();
      });
      this.componentsRef = [];
    }
    this.changeLast();
  }

  changeLast() {
    if (this.componentsRef.length === 0) {
      this.currentComponentRefSubject.next(null);
    } else {
      this.currentComponentRefSubject.next(this.componentsRef[this.componentsRef.length - 1]);
    }
  }
}

export interface DynamicComponentStack {
  close: EventEmitter<any>;
}
