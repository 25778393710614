import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SORC_SIZE_PLACEHOLDER } from '@vpfa/utils';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { convertImageSizeToSorc } from '../utils/convert-image-size-to-sorc.util';
import { getImageFromState } from '../utils/get-image-from-state.util';
import { imageAlreadyLoaded, imageLoad, imageLoaded, imageLoadError } from './images.actions';
import { ImagesFacade } from './images.facade';

@Injectable()
export class ImagesEffects {
  imageLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(imageLoad),
      withLatestFrom(this.imagesFacade.imagesState),
      map(([action, currentState]) => {
        return {
          action,
          isImageAlreadyLoaded: getImageFromState(currentState, action.payload.imageUrl, action.payload.size)?.loaded,
        };
      }),
      mergeMap(({ action, isImageAlreadyLoaded }) => {
        if (isImageAlreadyLoaded) {
          return of(imageAlreadyLoaded({ payload: action.payload }));
        }

        let requestUrl = action.payload.imageUrl;
        const size = action.payload.size;
        let isFromSorc = false;

        if (requestUrl.includes(SORC_SIZE_PLACEHOLDER)) {
          requestUrl = convertImageSizeToSorc(requestUrl, size);
          isFromSorc = true;
        } else {
          requestUrl = requestUrl.includes('?')
            ? `${requestUrl}&size=${size.toString()}`
            : `${requestUrl}?size=${size.toString()}`;
        }

        return this.httpClient
          .get(requestUrl, {
            responseType: 'blob',
          })
          .pipe(
            map(response =>
              imageLoaded({
                payload: {
                  ...action.payload,
                  isFromSorc,
                  dataUrl: URL.createObjectURL(response),
                  fileSize: response.size,
                },
              })
            ),
            catchError(() => of(imageLoadError({ payload: { ...action.payload } })))
          );
      })
    )
  );

  constructor(private actions$: Actions, private httpClient: HttpClient, private imagesFacade: ImagesFacade) {}
}
