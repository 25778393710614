import { Component, OnDestroy, ViewChild } from '@angular/core';
import { IFloatingFilterParams, IFloatingFilter, TextFilterModel, TextFilter, RowNode } from 'ag-grid-community';
import { AgFrameworkComponent } from 'ag-grid-angular';
import { isNil } from 'lodash';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GENERIC_NUMBER_MAX, GENERIC_NUMBER_MIN, numberFromToValidator } from '@vpfa/shared/validators';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { Option } from '../dropdown-floating-filter/dropdown-floating-filter.component';
import { SelectComponent, SelectOption } from '@vpfa/ui-kit';
import { TranslateService } from '@ngx-translate/core';
import { NumberRangeFilterModel } from '../number-range-floating-filter/number-range-floating-filter.component';
import { isCallable } from '@vpfa/utils';

export interface NumberRangeSelectFloatingFilterParams extends IFloatingFilterParams {
  maxFractionNumber: number;
  maxCharCount: number;
  minValue?: number;
  maxValue?: number;
  options$: Observable<Option[]>;
}

@Component({
  selector: 'vpfa-number-range-select-floating-filter',
  templateUrl: './number-range-select-floating-filter.component.html',
  styleUrls: ['./number-range-select-floating-filter.component.scss'],
})
export class NumberRangeSelectFloatingFilterComponent
  implements IFloatingFilter, AgFrameworkComponent<NumberRangeSelectFloatingFilterParams>, OnDestroy
{
  isNil = isNil;

  @ViewChild('selectFromElement') selectFromElement?: SelectComponent;
  @ViewChild('selectToElement') selectToElement?: SelectComponent;

  private params: NumberRangeSelectFloatingFilterParams;
  public fieldName: string | null = null;

  public initialValueFrom: Option | null = null;
  public initialValueTo: Option | null = null;
  public options: SelectOption[] = [];
  public errorHeightCompensationPx = -7;

  public formGroup = new FormGroup({
    from: new FormControl(null, []),
    to: new FormControl(null, []),
  });

  maxValue = GENERIC_NUMBER_MAX;
  minValue = GENERIC_NUMBER_MIN;

  public visible: boolean = false;

  get maxFractionNumber(): number {
    return this.params?.maxFractionNumber ?? 2;
  }

  get maxCharCount(): number {
    return this.params?.maxCharCount ?? 0;
  }
  public isCurrency: boolean = false;

  private readonly _onDestroy$ = new Subject<void>();

  constructor(private translateService: TranslateService) {}

  agInit(params: NumberRangeSelectFloatingFilterParams): void {
    this.params = params;

    this.fieldName = this.params?.column?.getColDef()?.field ?? null;

    if (this.fieldName === null) {
      // When this error occurs, HTML ID has an invalid name
      console.warn('Missing field name');
    }

    if (!isNil(this.params?.minValue)) {
      this.minValue = this.params?.minValue;
    }
    if (!isNil(this.params?.maxValue)) {
      this.maxValue = this.params?.maxValue;
    }

    this.formGroup.controls.from.setValidators([
      Validators.min(this.minValue),
      Validators.max(this.maxValue),
      numberFromToValidator('to'),
    ]);
    this.formGroup.controls.to.setValidators([Validators.min(this.minValue), Validators.max(this.maxValue)]);

    this.initSelect();
  }

  onParentModelChanged(parentModel: TextFilterModel): void {
    if (isNil(parentModel?.filter)) {
      this.initialValueFrom = null;
      this.initialValueTo = null;
      return;
    }

    const filterData = JSON.parse(parentModel.filter) as NumberRangeFilterModel;

    this.initialValueFrom = this.options.find(x => x.value === (filterData?.from ?? null));
    this.initialValueTo = this.options.find(x => x.value === (filterData?.to ?? null));
  }

  onApplyFilters() {
    this.formGroup.markAllAsTouched();

    if (this.formGroup.invalid) {
      return;
    }

    this.params.parentFilterInstance((instance: TextFilter) => {
      const value: NumberRangeFilterModel = {
        from: this.formGroup.controls.from.value?.value ?? null,
        to: this.formGroup.controls.to.value?.value ?? null,
      };

      if (!value?.from) {
        // changes "N/A" into "Select value" for consistency
        this.selectFromElement?.clear();
      }

      if (!value?.to) {
        // changes "N/A" into "Select value" for consistency
        this.selectToElement?.clear();
      }

      if (isNil(value.from) && isNil(value.to)) {
        instance.setModel(null);
        this.params.api.onFilterChanged();
        return;
      }

      instance.onFloatingFilterChanged('other', JSON.stringify(value));
    });

    this.visible = false;
  }

  onVisibleChange(isVisible: boolean) {
    if (isVisible !== true) {
      this.formGroup.controls.from.reset(this.initialValueFrom);
      this.formGroup.controls.to.reset(this.initialValueTo);
      return;
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private initSelect() {
    this.params?.options$
      ?.pipe(
        filter(options => !isNil(options)),
        distinctUntilChanged(),
        takeUntil(this._onDestroy$),
      )
      .subscribe(optionsData => {
        const options = optionsData.map(optionData =>
          // TODO: add tests
          this.params.filterParams.valueGetter({
            data: {
              [this.fieldName]: optionData,
            },
          } as any),
        );

        this.updateDropdown(options);
      });
  }

  private updateDropdown(options: Option[]) {
    this.options = [];

    options.sort((a, b) => (Number(a) > Number(b) ? 1 : -1)).forEach(x => this.addOption(x));

    this.addNullOption();
  }

  private addNullOption() {
    const alreadyContainsNull = this.options.find(x => x.value === null || x.value === 'null');

    if (alreadyContainsNull) {
      alreadyContainsNull.name = 'common.noValue';
      alreadyContainsNull.value = null;
      return;
    }

    this.options.unshift({ name: 'common.noValue', value: null });
  }

  private addOption(cellValue: Option) {
    if (cellValue.name !== undefined && cellValue.value !== undefined) {
      this.options.push({ name: cellValue.name, value: cellValue.value });
      return;
    }

    let formattedValue: string = !isNil(cellValue)
      ? JSON.stringify(cellValue)
      : this.translateService.instant('common.noValue');

    const formatter = this.params.column.getColDef().valueFormatter;

    if (isCallable(formatter)) {
      const valueFormatterParams: any = { value: cellValue };
      formattedValue = formatter(valueFormatterParams);
    }

    formattedValue = formattedValue?.toString();

    if (formattedValue?.includes('|')) {
      formattedValue = formattedValue.replace('|', ' / ');
    }

    this.options.push({ name: formattedValue, value: cellValue });
  }
}
