import { EquipmentDto } from '@vpfa/rest-api/valuation';
import { isDebugEnabled } from './debug/debug.utils';
import { getEquipmentDebugText } from './debug/equipment-debug.util';

export function calculateShortDesc(equipment: EquipmentDto): string {
  const maxSize = 40;
  const desc = equipment.shortDescription?.trim() || equipment.description;

  const soaCode = isDebugEnabled('formulaWizard') ? getEquipmentDebugText(equipment) : '';

  if (!desc) {
    return '';
  }

  if (desc?.length <= maxSize) {
    return soaCode + desc;
  }

  return `${desc.slice(0, maxSize)}...`;
}

export function recalculateShortDesc(equipment: EquipmentDto): EquipmentDto {
  return {
    ...equipment,
    shortDescription: calculateShortDesc(equipment),
  };
}
