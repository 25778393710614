import { PriceDisplayModel } from '../../../components/pricing-details-modal/pricing-details-modal.component';
import { CaseStatus, TaxedPriceDto } from '@vpfa/rest-api/valuation';
import { CaseHistoricalPrices } from '@vpfa/dealer/case/data';
import { StockPrices } from './stock-prices.enum';

export const setupSoldPrices = (
  activeCaseDataTaxedPrice: TaxedPriceDto,
  historicalPrices: CaseHistoricalPrices,
  caseStatus: CaseStatus
): PriceDisplayModel[] => {
  return [
    {
      name: 'pricingDetailsModal.soldPrice',
      date: null,
      price: activeCaseDataTaxedPrice?.soldPrice,
      tax: activeCaseDataTaxedPrice?.soldPriceTaxAmount,
      history: historicalPrices ? historicalPrices.soldPriceHistory : null,
      fcName: StockPrices.SOLD_PRICE,
      isEditable: caseStatus === CaseStatus.Sold,
      showHistory: false,
    },
  ];
};
