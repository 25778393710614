import { ActivatedRoute, Router } from '@angular/router';

export const clearQueryParamsFromUrl = (router: Router, route: ActivatedRoute) => {
  router.navigate([], { queryParams: {}, replaceUrl: true, relativeTo: route });
};

export const clearSpecifiedQueryParamsFromUrl = (router: Router, route: ActivatedRoute, queryParams: string[]) => {
  let paramsToClear = {};
  queryParams.forEach(param => (paramsToClear[param] = null));

  router.navigate([], {
    queryParams: paramsToClear,
    replaceUrl: true,
    relativeTo: route,
    queryParamsHandling: 'merge',
  });
};
