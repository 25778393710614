import {
  CaseDocumentDto,
  CaseFuelSubtypeDto,
  CaseListItemDto,
  CaseSummaryDto,
  EmissionInfoDto,
  LightDamageAreaDto,
  LiveRetailInsightsDto,
  ReturnReportDto,
  RoadTaxDataDto,
  VinEreDataStatus,
} from '@vpfa/rest-api/valuation';
import { produce } from 'immer';
import { CasesAction, CasesActionTypes } from './cases.actions';
import { initialState } from './initialState';
import {
  PROVENANCE_CHECK_FEATURE_KEY,
  provenanceCheckReducer,
  ProvenanceCheckState,
} from './provenance-check/provenance-check.reducer';
import { conditionAdjustmentsReducer, ConditionAdjustmentsState } from './adjustments/adjustments.reducer';
import { ValuationRequestPriceDetailsDto } from '@vpfa/rest-api/valuation';
import { VehicleEngineType } from '../types/vehicle-engine-fuel.enum';
import { vehicleEngineFuelParser } from '../utils/vehicle-engine-fuel.parser.util';

export const CASES_FEATURE_KEY = 'cases';

/**
 * Interface for the 'Cases' data used in
 *  - CasesState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

export interface LightDamageEstimatorState {
  areas: LightDamageAreaDto[];
  loading: boolean;
  saving: boolean;
  saved: boolean;
}

interface PublishToResellersState {
  open: boolean;
  isProcessing: boolean;
  hasProcessingError: boolean;
  isProcessingCompleted: boolean;
}

interface PurchaseState {
  processing: boolean;
  open: boolean;
}

interface ReturnReportState {
  report: ReturnReportDto;
  processing: boolean;
  open: boolean;
}

interface MoveToStockModalState {
  processing: boolean;
  open: boolean;
}

interface StockOfferModalState {
  processing: boolean;
  usedCustomerId: string;
  open: boolean;
}

interface QuoteModalState {
  processing: boolean;
  open: boolean;
}

interface SellModalState {
  processing: boolean;
  open: boolean;
}

interface DocumentsState {
  items: CaseDocumentDto[];
  loaded: boolean;
  loading: boolean;
  totalSize: number;
  isProcessingForm: boolean;
  hasFormProcessingError: boolean;
  upload: {
    totalCount: number;
    currentIndex: number;
    completed: boolean;
    processing: boolean;
  };
}

interface EmissionStickersState {
  items: { [key: number]: string };
  loaded: boolean;
  loading: boolean;
  hasError: boolean;
}

interface ResendCaseLatestQuoteState {
  isProcessing: boolean;
}

export interface CasesState {
  list: CaseListItemDto[]; // list of Cases; analogous to a sql normalized table
  selectedId?: string | number; // which Cases record has been selected
  loaded: boolean; // has the Cases list been loaded
  loading: boolean;
  error?: any; // last none error (if any)
  activeCaseData: CaseSummaryDto;
  activeCaseVehicleEngineFuel: VehicleEngineType | null;
  activeCaseId: string;
  activeCaseNotes: string;
  activeCaseQrCode: Blob;
  activeCaseBlackBoxPricingDetails: { loading: boolean; data: ValuationRequestPriceDetailsDto };
  purchase: PurchaseState;
  publishToResellers: PublishToResellersState;
  isReservingVehicle: boolean;
  isUpdatingVin: boolean;
  isUpdatingVinError: boolean;
  isUpdatingMatriculationNumber: boolean;
  isUpdatingMatriculationNumberError: boolean;
  isUpdatingKba: boolean;
  isUpdatingMileage: boolean;
  isUpdatingRegDate: boolean;
  isUpdatingValuationDate: boolean;
  isUpdateValuationDateModalOpen: boolean;
  lightDamageEstimator: LightDamageEstimatorState;
  isUpdatingPublicSite: boolean;
  isProcessingForm: boolean;
  hasFormProcessingError: boolean;
  isReserveModalOpen: boolean;
  isReservationViewModalOpen: boolean;
  isCancellingReservation: boolean;
  isUpdatingValuationUniqueData: boolean;
  isUniqueDataModalOpen: boolean;
  isUpdatingTechnicalData: boolean;
  isTechnicalDataModalOpen: boolean;
  documents: DocumentsState;
  retailMarketDetails: LiveRetailInsightsDto;
  isReturnToStockModalOpen: boolean;
  isReturningVehicleToStock: boolean;
  moveToStock: MoveToStockModalState;
  stockOfferModal: StockOfferModalState;
  quoteModal: QuoteModalState;
  sellModal: SellModalState;
  emissionStickers: EmissionStickersState;
  isUpdatingPricingDetails: boolean;
  isPricingDetailsModalOpen: boolean;
  isUpdatingPlateNo: boolean;
  isUpdatingPlateNoError: boolean;
  resendCaseLatestQuote: ResendCaseLatestQuoteState;
  resendCaseLatestOffer: ResendCaseLatestQuoteState;
  fuelSubtypes: {
    list: CaseFuelSubtypeDto[];
    isLoading: boolean;
    isProcessing: boolean;
  };
  emissionInfo: {
    item: EmissionInfoDto;
    isLoading: boolean;
  };
  roadTax: {
    item: RoadTaxDataDto;
    loaded: boolean;
  };
  isValuating: boolean;
  valuationAttemptFailed: boolean;
  returnReport: ReturnReportState;
  isUpdatingGalleryFromTwinner: boolean;
  isUpdatedGalleryFromTwinner: boolean;
  activeCaseEquipmentsValid: boolean;
  isProcessingCaseOwnersList: boolean;
  isProcessingPreviousCaseOwnersList: boolean;
  isProcessingProspectCaseOwnersList: boolean;
  [PROVENANCE_CHECK_FEATURE_KEY]: ProvenanceCheckState;
  // defined in adjustments/adjustments.reducer
  conditionAdjustments: ConditionAdjustmentsState;
  isUpdatingBuildabilityData: boolean;
  canUserMakeValuation: boolean;
}

export interface CasesPartialState {
  readonly [CASES_FEATURE_KEY]: CasesState;
}

export function reducer(state: CasesState = initialState, action: CasesAction): CasesState {
  switch (action.type) {
    case CasesActionTypes.ResetCaseData:
      return initialState;
    case CasesActionTypes.CasesLoaded:
      return produce<CasesState>(state, newState => {
        newState.list = action.payload;
        newState.loaded = true;
      });

    case CasesActionTypes.LoadCase:
      return produce<CasesState>(state, newState => {
        newState.loading = true;
      });

    case CasesActionTypes.CaseLoadCompleted:
      return produce<CasesState>(state, newState => {
        newState.activeCaseData = action.payload;
        newState.activeCaseVehicleEngineFuel = vehicleEngineFuelParser(
          action.payload?.vehicle?.technicalData?.vehicleEngineFuel
        );
        newState.activeCaseId = action.payload.id;
        newState.activeCaseNotes = action.payload.notes;
        newState.activeCaseQrCode = null;
        newState.loading = false;
        newState.loaded = true;
        newState.isUpdatingMileage = false;
        newState.isUpdatingRegDate = false;
        newState.retailMarketDetails = action.payload.liveRetailInsights;
      });

    case CasesActionTypes.CaseLoadError:
      return produce<CasesState>(state, newState => {
        newState.loading = false;
        newState.loaded = false;
      });

    // TODO: remove when dedicated permission endpoint created
    case CasesActionTypes.ResetCaseLoadedStatus: {
      return produce<CasesState>(state, newState => {
        newState.loaded = false;
      });
    }

    case CasesActionTypes.UpdateCaseRequestValuation:
      return produce<CasesState>(state, newState => {
        newState.isValuating = true;
      });
    case CasesActionTypes.CaseUpdateRequestValuationCompleted:
      return produce<CasesState>(state, newState => {
        newState.isValuating = false;
        newState.valuationAttemptFailed = false;
      });
    case CasesActionTypes.CaseUpdateRequestValuationError:
      return produce<CasesState>(state, newState => {
        newState.isValuating = false;
        newState.valuationAttemptFailed = true;
      });
    case CasesActionTypes.CancelUpdateCaseRequestValuation:
      return produce<CasesState>(state, newState => {
        newState.isValuating = false;
      });

    case CasesActionTypes.CaseUpdateSaveQuote:
      return produce<CasesState>(state, newState => {
        newState.quoteModal.processing = true;
      });

    case CasesActionTypes.CaseUpdateSaveQuoteCompleted:
    case CasesActionTypes.CaseUpdateSaveQuoteError:
      return produce<CasesState>(state, newState => {
        newState.quoteModal.processing = false;
        newState.quoteModal.open = false;
      });

    case CasesActionTypes.CaseUpdateStockOffer:
      return produce<CasesState>(state, newState => {
        newState.stockOfferModal.processing = true;

        newState.stockOfferModal.usedCustomerId = initialState.stockOfferModal.usedCustomerId;
      });

    case CasesActionTypes.CaseUpdateStockOfferCompleted:
      return produce<CasesState>(state, newState => {
        newState.stockOfferModal.processing = false;
        newState.stockOfferModal.open = false;
        newState.stockOfferModal.usedCustomerId = action.payload.customerId;
      });
    case CasesActionTypes.CaseUpdateStockOfferError:
      return produce<CasesState>(state, newState => {
        newState.stockOfferModal.processing = false;
        newState.stockOfferModal.open = false;
      });

    case CasesActionTypes.CaseUpdatePurchaseVehicle:
      return produce<CasesState>(state, newState => {
        newState.purchase.processing = true;
      });

    case CasesActionTypes.CaseUpdatePurchaseVehicleCompleted:
      return produce<CasesState>(state, newState => {
        newState.purchase.processing = false;
        newState.purchase.open = false;
      });

    case CasesActionTypes.CaseUpdatePurchaseVehicleError:
      return produce<CasesState>(state, newState => {
        newState.purchase.processing = false;
      });

    case CasesActionTypes.CasePurchaseModalOpen:
      return produce<CasesState>(state, newState => {
        newState.purchase.open = true;
      });

    case CasesActionTypes.CasePurchaseModalClose:
      return produce<CasesState>(state, newState => {
        newState.purchase.open = false;
      });

    case CasesActionTypes.CaseUpdateReserveVehicle:
      return produce<CasesState>(state, newState => {
        newState.isReservingVehicle = true;
      });

    case CasesActionTypes.CaseUpdateReserveVehicleCompleted:
      return produce<CasesState>(state, newState => {
        newState.isReservingVehicle = false;
        newState.isReserveModalOpen = false;
      });

    case CasesActionTypes.CaseUpdateReserveVehicleError:
      return produce<CasesState>(state, newState => {
        newState.isReservingVehicle = false;
      });

    case CasesActionTypes.CaseUpdateOpenReservationModal:
      return produce<CasesState>(state, newState => {
        const reservation = state.activeCaseData.reservation;
        if (reservation && reservation.isActive) {
          newState.isReservationViewModalOpen = true;
        } else {
          newState.isReserveModalOpen = true;
        }
      });

    case CasesActionTypes.CaseUpdateCloseReservationModal:
      return produce<CasesState>(state, newState => {
        newState.isReserveModalOpen = false;
        newState.isReservationViewModalOpen = false;
      });

    case CasesActionTypes.CaseUpdateCancelReservation:
      return produce<CasesState>(state, newState => {
        newState.isCancellingReservation = true;
      });

    case CasesActionTypes.CaseUpdateCancelReservationCompleted:
      return produce<CasesState>(state, newState => {
        newState.isCancellingReservation = false;
        newState.isReservationViewModalOpen = false;
        newState.isReserveModalOpen = false;
      });

    case CasesActionTypes.CaseUpdateCancelReservationError:
      return produce<CasesState>(state, newState => {
        newState.isCancellingReservation = false;
      });

    case CasesActionTypes.CaseUpdateSellVehicle:
      return produce<CasesState>(state, newState => {
        newState.sellModal.processing = true;
      });

    case CasesActionTypes.CaseUpdateSellVehicleCompleted:
    case CasesActionTypes.CaseUpdateSellVehicleError:
      return produce<CasesState>(state, newState => {
        newState.sellModal.open = false;
        newState.sellModal.processing = false;
      });

    case CasesActionTypes.CaseOpenSellModal:
      return produce<CasesState>(state, newState => {
        newState.sellModal.open = true;
      });

    case CasesActionTypes.CaseCloseSellModal:
      return produce<CasesState>(state, newState => {
        newState.sellModal.open = false;
      });

    case CasesActionTypes.CaseQRCodeLoaded:
      return produce<CasesState>(state, newState => {
        newState.activeCaseQrCode = action.payload;
      });
    case CasesActionTypes.CaseQRCodeLoadError:
      return produce<CasesState>(state, newState => {
        newState.activeCaseQrCode = null;
      });
    case CasesActionTypes.CaseUpdateVin:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingVin = true;
        newState.isUpdatingVinError = false;
      });

    case CasesActionTypes.CaseUpdateVinCompleted:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingVin = false;
        newState.isUpdatingVinError = false;
      });

    case CasesActionTypes.CaseUpdateVinError:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingVin = false;
        newState.isUpdatingVinError = true;
      });

    case CasesActionTypes.CaseUpdateMatriculationNumber:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingMatriculationNumber = true;
        newState.isUpdatingMatriculationNumberError = false;
      });

    case CasesActionTypes.CaseUpdateMatriculationNumberCompleted:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingMatriculationNumber = false;
        newState.isUpdatingMatriculationNumberError = false;
      });

    case CasesActionTypes.CaseUpdateMatriculationNumberError:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingMatriculationNumber = false;
        newState.isUpdatingMatriculationNumberError = true;
      });

    case CasesActionTypes.CaseUpdateVehicleKba:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingKba = true;
      });

    case CasesActionTypes.CaseUpdateVehicleKbaCompleted:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingKba = false;
        newState.activeCaseData.vehicle.kba = action.payload.newKba;
      });
    case CasesActionTypes.CaseUpdateVehicleKbaError:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingKba = false;
      });

    case CasesActionTypes.CaseUpdateSaveMileageAdjustment:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingMileage = true;
      });
    case CasesActionTypes.CaseUpdateSaveMileageAdjustmentError:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingMileage = false;
      });

    case CasesActionTypes.CaseUpdateRegistrationDate:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingRegDate = true;
      });
    case CasesActionTypes.CaseUpdateRegistrationDateError:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingRegDate = false;
      });

    case CasesActionTypes.CaseUpdateValuationDate:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingValuationDate = true;
        newState.isUpdateValuationDateModalOpen = true;
      });

    case CasesActionTypes.CaseUpdateValuationDateCompleted:
    case CasesActionTypes.CaseUpdateValuationDateError:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingValuationDate = false;
        newState.isUpdateValuationDateModalOpen = false;
      });

    case CasesActionTypes.CaseLoadLightDamageEstimator:
      return produce<CasesState>(state, newState => {
        newState.lightDamageEstimator.loading = true;
      });

    case CasesActionTypes.CaseLightDamageEstimatorLoadCompleted:
      return produce<CasesState>(state, newState => {
        newState.lightDamageEstimator.areas = action.payload;
        newState.lightDamageEstimator.loading = false;
      });

    case CasesActionTypes.CaseLightDamageEstimatorLoadError:
      return produce<CasesState>(state, newState => {
        newState.lightDamageEstimator.loading = false;
      });

    case CasesActionTypes.CaseUpdateLightDamageEstimator:
      return produce<CasesState>(state, newState => {
        newState.lightDamageEstimator.saving = true;
        newState.lightDamageEstimator.saved = false;
      });

    case CasesActionTypes.CaseLightDamageEstimatorUpdateCompleted:
      return produce<CasesState>(state, newState => {
        newState.lightDamageEstimator.saving = false;
        newState.lightDamageEstimator.saved = true;
      });

    case CasesActionTypes.CaseLightDamageEstimatorUpdateError:
      return produce<CasesState>(state, newState => {
        newState.lightDamageEstimator.saving = false;
        newState.lightDamageEstimator.saved = false;
      });

    case CasesActionTypes.CaseUpdateSavePublicSiteConfiguration:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingPublicSite = true;
      });

    case CasesActionTypes.CaseUpdateSavePublicSiteConfigurationCompleted:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingPublicSite = false;
        newState.activeCaseData.publicSiteConfiguration = {
          description: action.payload.command.description,
          enabled: action.payload.command.enabled,
          highlight: action.payload.command.highlight,
          showVin: action.payload.command.showVin,
        };
      });

    case CasesActionTypes.CaseUpdateSavePublicSiteConfigurationError:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingPublicSite = false;
      });

    case CasesActionTypes.OpenResellerSiteConfigurationModal:
      return produce<CasesState>(state, newState => {
        newState.publishToResellers.open = true;
      });

    case CasesActionTypes.CloseResellerSiteConfigurationModal:
      return produce<CasesState>(state, newState => {
        newState.publishToResellers.open = false;
      });

    case CasesActionTypes.CaseUpdateCaseResellerSiteConfiguration:
      return produce<CasesState>(state, newState => {
        newState.publishToResellers.isProcessing = true;
        newState.publishToResellers.hasProcessingError = null;
        newState.publishToResellers.isProcessingCompleted = false;
      });

    case CasesActionTypes.CaseUpdateCaseResellerSiteConfigurationCompleted:
      return produce<CasesState>(state, newState => {
        newState.publishToResellers.open = false;
        newState.publishToResellers.isProcessing = false;
        newState.publishToResellers.isProcessingCompleted = true;
        newState.activeCaseData.resellerSiteConfiguration = {
          ...action.payload.request,
          wasEvenEnabled:
            action.payload.request.isEnabled == true
              ? true
              : state.activeCaseData.resellerSiteConfiguration.wasEvenEnabled,
        };
      });

    case CasesActionTypes.CaseUpdateCaseResellerSiteConfigurationError:
      return produce<CasesState>(state, newState => {
        newState.publishToResellers.open = true;
        newState.publishToResellers.isProcessing = false;
        newState.publishToResellers.isProcessingCompleted = false;
        newState.publishToResellers.hasProcessingError = true;
      });

    case CasesActionTypes.CaseUpdateValuationUniqueDataValidateBroadcast:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingValuationUniqueData = true;
      });
    case CasesActionTypes.CaseUpdateValuationUniqueDataValidateBroadcastShowWarning:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingValuationUniqueData = false;
      });

    case CasesActionTypes.CaseUpdateValuationUniqueData:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingValuationUniqueData = true;
      });

    case CasesActionTypes.CaseUpdateValuationUniqueDataCompleted:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingValuationUniqueData = false;
        newState.isUniqueDataModalOpen = false;
      });

    case CasesActionTypes.CaseUpdateValuationUniqueDataError:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingValuationUniqueData = false;
      });

    case CasesActionTypes.CaseOpenUniqueDataModal:
      return produce<CasesState>(state, newState => {
        newState.isUniqueDataModalOpen = true;
      });

    case CasesActionTypes.CaseCloseUniqueDataModal:
      return produce<CasesState>(state, newState => {
        newState.isUniqueDataModalOpen = false;
      });

    case CasesActionTypes.CaseUpdateTechnicalData:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingTechnicalData = true;
      });

    case CasesActionTypes.CaseUpdateTechnicalDataCompleted:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingTechnicalData = false;
        newState.isTechnicalDataModalOpen = false;
      });

    case CasesActionTypes.CaseUpdateTechnicalDataError:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingTechnicalData = false;
      });

    case CasesActionTypes.CaseOpenTechnicalDataModal:
      return produce<CasesState>(state, newState => {
        newState.isTechnicalDataModalOpen = true;
      });

    case CasesActionTypes.CaseCloseTechnicalDataModal:
      return produce<CasesState>(state, newState => {
        newState.isTechnicalDataModalOpen = false;
      });

    case CasesActionTypes.LoadCaseDocumentList:
      return produce(state, newState => {
        newState.documents.loading = true;
      });

    case CasesActionTypes.CaseDocumentListLoadError:
      return produce(state, newState => {
        newState.documents.loading = false;
        newState.documents.loaded = false;
      });
    case CasesActionTypes.CaseDocumentListLoaded:
      return produce(state, newState => {
        newState.documents.loaded = true;
        newState.documents.loading = false;
        newState.documents.items = action.payload;
        newState.documents.totalSize = newState.documents.items.reduce(
          (previousValue, currentValue) => previousValue + currentValue.fileSize,
          0
        );
      });

    case CasesActionTypes.SetIsProcessingCaseDocumentForm:
      return produce(state, newState => {
        newState.documents.isProcessingForm = action.payload;
      });

    case CasesActionTypes.SetHasCaseDocumentFormProcessingError:
      return produce(state, newState => {
        newState.documents.hasFormProcessingError = action.payload;
      });

    case CasesActionTypes.CaseDocumentAdded:
    case CasesActionTypes.CaseDocumentAddError:
      return produce(state, newState => {
        newState.documents.upload.completed =
          newState.documents.upload.totalCount - 1 === newState.documents.upload.currentIndex;
        if (!newState.documents.upload.completed) {
          newState.documents.upload.currentIndex = newState.documents.upload.currentIndex + 1;
        } else {
          newState.documents.upload.processing = false;
        }
      });
    case CasesActionTypes.CaseDocumentUploadSetTotalCount:
      return produce(state, newState => {
        newState.documents.upload = {
          totalCount: action.payload,
          completed: false,
          currentIndex: 0,
          processing: true,
        };
      });

    case CasesActionTypes.CaseDocumentUploadReset:
      return produce(state, newState => {
        newState.documents.upload = {
          totalCount: null,
          completed: null,
          currentIndex: null,
          processing: null,
        };
      });

    case CasesActionTypes.CaseUpdateOpenReturnToStockModal:
      return produce<CasesState>(state, newState => {
        newState.isReturnToStockModalOpen = true;
      });

    case CasesActionTypes.CaseUpdateCloseReturnToStockModal:
      return produce<CasesState>(state, newState => {
        newState.isReturnToStockModalOpen = false;
      });
    case CasesActionTypes.CaseUpdateReturnToStock:
      return produce<CasesState>(state, newState => {
        newState.isReturningVehicleToStock = true;
      });
    case CasesActionTypes.CaseUpdateReturnToStockSuccess:
      return produce<CasesState>(state, newState => {
        newState.isReturningVehicleToStock = false;
        newState.isReturnToStockModalOpen = false;
      });
    case CasesActionTypes.CaseUpdateDueToInStock:
      return produce<CasesState>(state, newState => {
        newState.moveToStock.processing = true;
      });
    case CasesActionTypes.CaseUpdateDueToInStockSuccess:
    case CasesActionTypes.CaseUpdateDueToInStockError:
      return produce<CasesState>(state, newState => {
        newState.moveToStock.processing = false;
        newState.moveToStock.open = false;
      });
    case CasesActionTypes.CaseOpenMoveToStockModal:
      return produce<CasesState>(state, newState => {
        newState.moveToStock.open = true;
      });
    case CasesActionTypes.CaseCloseMoveToStockModal:
      return produce<CasesState>(state, newState => {
        newState.moveToStock.open = false;
      });
    case CasesActionTypes.CaseUpdateReturnToStockError:
      return produce<CasesState>(state, newState => {
        newState.isReturningVehicleToStock = false;
      });

    case CasesActionTypes.CaseOpenStockOfferModal:
      return produce<CasesState>(state, newState => {
        newState.stockOfferModal.open = true;
        newState.stockOfferModal.usedCustomerId = initialState.stockOfferModal.usedCustomerId;
      });
    case CasesActionTypes.CaseCloseStockOfferModal:
      return produce<CasesState>(state, newState => {
        newState.stockOfferModal.open = false;
        newState.stockOfferModal.usedCustomerId = initialState.stockOfferModal.usedCustomerId;
      });
    case CasesActionTypes.CaseOpenQuoteModal:
      return produce<CasesState>(state, newState => {
        newState.quoteModal.open = true;
      });

    case CasesActionTypes.CaseCloseQuoteModal:
      return produce<CasesState>(state, newState => {
        newState.quoteModal.open = false;
      });

    case CasesActionTypes.CaseUpdateActiveCaseVehicleEquipmentIsEnabled:
      return produce<CasesState>(state, newState => {
        newState.activeCaseData.vehicle.equipments = state.activeCaseData.vehicle.equipments.reduce(
          (arr, processed) => {
            let content = processed.content?.map(x => ({ ...x }));

            const contentModified = content?.find(x => x.soaCode === action.payload.soaCode);

            if (contentModified) {
              contentModified.isEnabled = action.payload.isEnabled;
            }

            return [
              ...arr,
              processed.soaCode === action.payload.soaCode
                ? {
                    ...processed,
                    isEnabled: action.payload.isEnabled,
                    content,
                  }
                : {
                    ...processed,
                    content,
                  },
            ];
          },
          []
        );
      });

    case CasesActionTypes.LoadEmissionStickerImage:
      return produce<CasesState>(state, newState => {
        newState.emissionStickers.loading = true;
        newState.emissionStickers.loaded = false;
        newState.emissionStickers.hasError = false;
      });

    case CasesActionTypes.EmissionStickerImageLoadCompleted:
      return produce<CasesState>(state, newState => {
        newState.emissionStickers.loading = false;
        newState.emissionStickers.loaded = true;
        const { efficiencyClass, item } = action.payload;
        newState.emissionStickers.items[efficiencyClass] = item;
      });

    case CasesActionTypes.EmissionStickerImageLoadError:
      return produce<CasesState>(state, newState => {
        newState.emissionStickers.loading = false;
        newState.emissionStickers.loaded = false;
        newState.emissionStickers.hasError = true;
      });
    case CasesActionTypes.CaseUpdateStockPricingDetails:
    case CasesActionTypes.CaseUpdateSoldPricingDetails: {
      return produce<CasesState>(state, newState => {
        newState.isUpdatingPricingDetails = true;
      });
    }
    case CasesActionTypes.CaseUpdatePricingSuccess: {
      return produce<CasesState>(state, newState => {
        newState.isUpdatingPricingDetails = false;
        newState.isPricingDetailsModalOpen = false;
      });
    }
    case CasesActionTypes.CaseUpdatePricingDetailsError: {
      return produce<CasesState>(state, newState => {
        newState.isUpdatingPricingDetails = false;
      });
    }
    case CasesActionTypes.CaseChangePricingDetailsModalVisibility:
      return produce<CasesState>(state, newState => {
        newState.isPricingDetailsModalOpen = action.payload;
      });
    case CasesActionTypes.UpdateCaseNotes:
      return produce<CasesState>(state, newState => {
        newState.activeCaseNotes = action.payload.notes;
      });
    case CasesActionTypes.CaseUpdatePlateNo:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingPlateNo = true;
        newState.isUpdatingPlateNoError = false;
      });

    case CasesActionTypes.CaseUpdatePlateNoCompleted:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingPlateNo = false;
        newState.isUpdatingPlateNoError = false;
      });

    case CasesActionTypes.CaseUpdatePlateNoError:
      return produce<CasesState>(state, newState => {
        newState.isUpdatingPlateNo = false;
        newState.isUpdatingPlateNoError = true;
      });

    case CasesActionTypes.ResendCaseLatestQuote: {
      return produce<CasesState>(state, newState => {
        newState.resendCaseLatestQuote.isProcessing = true;
      });
    }

    case CasesActionTypes.ResendCaseLatestQuoteCompleted:
    case CasesActionTypes.ResendCaseLatestQuoteError: {
      return produce<CasesState>(state, newState => {
        newState.resendCaseLatestQuote.isProcessing = false;
      });
    }

    case CasesActionTypes.ResendCaseLatestOffer: {
      return produce<CasesState>(state, newState => {
        newState.resendCaseLatestOffer.isProcessing = true;
      });
    }

    case CasesActionTypes.ResendCaseLatestOfferCompleted:
    case CasesActionTypes.ResendCaseLatestOfferError: {
      return produce<CasesState>(state, newState => {
        newState.resendCaseLatestOffer.isProcessing = false;
      });
    }

    case CasesActionTypes.CaseLoadFuelSubtypes:
      return produce<CasesState>(state, newState => {
        newState.fuelSubtypes.isLoading = true;
      });
    case CasesActionTypes.CaseLoadFuelSubtypesCompleted:
      return produce<CasesState>(state, newState => {
        newState.fuelSubtypes.isLoading = false;
        newState.fuelSubtypes.list = action.payload;
      });

    case CasesActionTypes.CaseLoadEmissionInfo:
      return produce<CasesState>(state, newState => {
        newState.emissionInfo.isLoading = true;
      });
    case CasesActionTypes.CaseLoadEmissionInfoCompleted:
      return produce<CasesState>(state, newState => {
        newState.emissionInfo.isLoading = false;
        newState.emissionInfo.item = action.payload;
      });
    case CasesActionTypes.CaseLoadEmissionInfoError:
      return produce<CasesState>(state, newState => {
        newState.emissionInfo.isLoading = false;
      });

    case CasesActionTypes.CaseLoadRoadTax:
      return produce<CasesState>(state, newState => {
        newState.roadTax.loaded = false;
      });
    case CasesActionTypes.CaseLoadRoadTaxCompleted:
      return produce<CasesState>(state, newState => {
        newState.roadTax.item = action.payload;
        newState.roadTax.loaded = true;
      });
    case CasesActionTypes.CaseLoadRoadTaxError:
      return produce<CasesState>(state, newState => {
        newState.roadTax.loaded = false;
      });

    case CasesActionTypes.ResetWasValuation:
      return produce<CasesState>(state, newState => {
        newState.valuationAttemptFailed = false;
      });
    case CasesActionTypes.CaseLoadReturnReport: {
      return produce<CasesState>(state, newState => {
        newState.returnReport.processing = true;
      });
    }
    case CasesActionTypes.CaseLoadReturnReportCompleted: {
      return produce<CasesState>(state, newState => {
        newState.returnReport.processing = false;
        newState.returnReport.report = action.payload;
      });
    }
    case CasesActionTypes.CaseUpdateReturnReport: {
      return produce<CasesState>(state, newState => {
        newState.returnReport.processing = true;
      });
    }
    case CasesActionTypes.CaseUpdateReturnReportCompleted: {
      return produce<CasesState>(state, newState => {
        newState.returnReport.open = false;
      });
    }
    case CasesActionTypes.CaseLoadReturnReportError:
    case CasesActionTypes.CaseUpdateReturnReportError: {
      return produce<CasesState>(state, newState => {
        newState.returnReport.processing = false;
      });
    }
    case CasesActionTypes.CaseOpenReturnReportModal: {
      return produce<CasesState>(state, newState => {
        newState.returnReport.open = true;
      });
    }
    case CasesActionTypes.CaseCloseReturnReportModal: {
      return produce<CasesState>(state, newState => {
        newState.returnReport.open = false;
      });
    }
    case CasesActionTypes.UpdateYoutubeUrlSuccess: {
      return produce<CasesState>(state, newState => {
        newState.activeCaseData.youtubeUrl = action.payload;
      });
    }
    case CasesActionTypes.CaseLoadEquipments: {
      return produce<CasesState>(state, newState => {
        newState.loading = true;
      });
    }
    case CasesActionTypes.CaseLoadEquipmentsCompleted: {
      return produce<CasesState>(state, newState => {
        newState.activeCaseData.vehicle.equipments = action.payload;
        newState.loading = false;
      });
    }
    case CasesActionTypes.CaseLoadEquipmentsError: {
      return produce<CasesState>(state, newState => {
        newState.loading = false;
      });
    }

    case CasesActionTypes.CaseUpdateTwinnerImages: {
      return produce<CasesState>(state, newState => {
        newState.isUpdatingGalleryFromTwinner = true;
      });
    }

    case CasesActionTypes.CaseUpdateTwinnerImagesCompleted: {
      return produce<CasesState>(state, newState => {
        newState.isUpdatingGalleryFromTwinner = false;
        newState.isUpdatedGalleryFromTwinner = true;
      });
    }

    case CasesActionTypes.CaseUpdateTwinnerImagesError: {
      return produce<CasesState>(state, newState => {
        newState.isUpdatingGalleryFromTwinner = false;
        newState.isUpdatedGalleryFromTwinner = false;
      });
    }

    case CasesActionTypes.CaseLoadEquipmentsValidCompleted: {
      return produce<CasesState>(state, newState => {
        newState.activeCaseEquipmentsValid = action.payload;
      });
    }

    case CasesActionTypes.CaseUpdateUpdateCaseOwners: {
      return produce<CasesState>(state, newState => {
        newState.isProcessingCaseOwnersList = true;
      });
    }

    case CasesActionTypes.CaseUpdateUpdateCaseOwnersCompleted:
    case CasesActionTypes.CaseUpdateUpdateCaseOwnersError: {
      return produce<CasesState>(state, newState => {
        newState.isProcessingCaseOwnersList = false;
      });
    }

    case CasesActionTypes.CaseUpdateUpdateCasePreviousOwners: {
      return produce<CasesState>(state, newState => {
        newState.isProcessingPreviousCaseOwnersList = true;
      });
    }

    case CasesActionTypes.CaseUpdateUpdateCasePreviousOwnersCompleted:
    case CasesActionTypes.CaseUpdateUpdateCasePreviousOwnersError: {
      return produce<CasesState>(state, newState => {
        newState.isProcessingPreviousCaseOwnersList = false;
      });
    }

    case CasesActionTypes.CaseUpdateUpdateCaseProspectCustomers: {
      return produce<CasesState>(state, newState => {
        newState.isProcessingProspectCaseOwnersList = true;
      });
    }

    case CasesActionTypes.CaseUpdateUpdateCaseProspectCustomersCompleted:
    case CasesActionTypes.CaseUpdateUpdateCaseProspectCustomersError: {
      return produce<CasesState>(state, newState => {
        newState.isProcessingProspectCaseOwnersList = false;
      });
    }

    case CasesActionTypes.LoadCaseBlackBoxPriceDetails: {
      return produce<CasesState>(state, newState => {
        newState.activeCaseBlackBoxPricingDetails.data = initialState.activeCaseBlackBoxPricingDetails.data;
        newState.activeCaseBlackBoxPricingDetails.loading = true;
      });
    }
    case CasesActionTypes.LoadCaseBlackBoxPriceDetailsCompleted: {
      return produce<CasesState>(state, newState => {
        newState.activeCaseBlackBoxPricingDetails.data = action.data;
        newState.activeCaseBlackBoxPricingDetails.loading = false;
      });
    }
    case CasesActionTypes.LoadCaseBlackBoxPriceDetailsError: {
      return produce<CasesState>(state, newState => {
        newState.activeCaseBlackBoxPricingDetails.loading = false;
      });
    }

    case CasesActionTypes.CaseLoadVinEreData: {
      return produce<CasesState>(state, newState => {
        newState.activeCaseData = { ...state.activeCaseData, vinEreDataStatus: VinEreDataStatus.Pending };
      });
    }

    case CasesActionTypes.CaseLoadVinEreDataCompleted: {
      return produce<CasesState>(state, newState => {
        newState.activeCaseData = { ...state.activeCaseData, vinEreDataStatus: action.payload?.vinEreDataStatus };
      });
    }

    case CasesActionTypes.UpdateIdentifiedVehicleBuildabilityData: {
      return produce<CasesState>(state, newState => {
        newState.isUpdatingBuildabilityData = true;
      });
    }
    case CasesActionTypes.UpdateIdentifiedVehicleBuildabilityDataCompleted:
    case CasesActionTypes.UpdateIdentifiedVehicleBuildabilityDataError: {
      return produce<CasesState>(state, newState => {
        newState.isUpdatingBuildabilityData = false;
      });
    }

    case CasesActionTypes.CaseResetLightDamageEstimator: {
      return produce<CasesState>(state, newState => {
        newState.lightDamageEstimator.areas.forEach((_, index) => {
          {
            newState.lightDamageEstimator.areas[index].parts.forEach((part, pIndex) => {
              part.cost = null;
              part.selectedDamageTypeId = null;
              part.isIncluded = true;
            });
            newState.lightDamageEstimator.areas[index].subAreas.forEach(subArea => {
              subArea.parts.forEach(p => {
                p.cost = null;
                p.selectedDamageTypeId = null;
                p.isIncluded = true;
              });
            });
          }
        });
      });
    }

    case CasesActionTypes.ResetCaseDocumentList: {
      return produce<CasesState>(state, newState => {
        newState.documents.loading = false;
        newState.documents.items = [];
        newState.documents.hasFormProcessingError = false;
        newState.documents.loaded = false;
        newState.documents.totalSize = null;
      });
    }

    case CasesActionTypes.ResetCaseNotes: {
      return produce<CasesState>(state, newState => {
        newState.activeCaseNotes = null;
      });
    }
  }

  return {
    ...state,
    [PROVENANCE_CHECK_FEATURE_KEY]: provenanceCheckReducer(state[PROVENANCE_CHECK_FEATURE_KEY], action),
    conditionAdjustments: conditionAdjustmentsReducer(state.conditionAdjustments, action),
  };
}
