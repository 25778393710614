import { Component } from '@angular/core';

@Component({
  selector: 'vpfa-loading-value',
  templateUrl: './loading-value.component.html',
  styleUrls: ['./loading-value.component.scss'],
})
export class LoadingValueComponent {
  constructor() {}
}
