import { Component, Input } from '@angular/core';
import { TechnicalDataDto } from '@vpfa/rest-api/valuation';
import { FeaturesTechnicalDataConfiguration } from '@vpfa/locale';

@Component({
  selector: 'vpfa-full-technical-data-dimensions-information',
  templateUrl: './full-technical-data-dimensions-information.component.html',
  styleUrls: ['./full-technical-data-dimensions-information.component.scss'],
})
export class FullTechnicalDataDimensionsInformationComponent {
  @Input() technicalData: TechnicalDataDto;
  @Input() featureConfig: FeaturesTechnicalDataConfiguration;
}
