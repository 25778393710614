import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { collapseExpand } from '@vpfa/utils';

@Component({
  selector: 'vpfa-section-container',
  templateUrl: './section-container.component.html',
  styleUrls: ['./section-container.component.scss'],
  animations: [collapseExpand()],
})
export class SectionContainerComponent {
  @Input() name: string;
  @Input() toggleLabel: string | TemplateRef<void>;
  @Input() expanded = false;
  @Input() isLoading: boolean;
  @Input() expandableFromOutside = false;
  @Input() notifyOnexpandedChanged: boolean;
  @Input() isExpandable = true;
  @Input() notAvailable: boolean;
  @Input() tooltipContent: string | TemplateRef<void>;
  /**
   * Indicates whether plus / minus icon should be displayed in section title
   */
  @Input() showToggleIndicator = false;
  @Output() expandedChanged = new EventEmitter<boolean>();

  onHeaderClick() {
    if (this.isLoading || !this.isExpandable) return;
    if (this.expandableFromOutside) {
      this.expandedChanged.emit(!this.expanded);
    } else {
      this.expanded = !this.expanded;
      this.expandedChanged.emit(this.expanded);
    }
  }
}
