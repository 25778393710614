<vpfa-modal
  width="550px"
  zIndex="2000"
  [showBackdrop]="true"
  [isVisible]="(modalOpen$ | async) && (isClosing$ | async) === false"
  [isClosable]="false"
  [keyboard]="false"
  [maskClosable]="modalMaskClosable"
  (afterCloseAction)="afterClose()"
  (closeAction)="onClose()"
>
  <vpfa-modal-content>
    <div class="padding-top d-flex flex-column">
      <vpfa-notification
        [errorNotifications]="errorNotifications$ | async | keyvalue"
        [warningNotifications]="warningNotifications$ | async | keyvalue"
        [mistakeNotifications]="mistakeNotifications$ | async | keyvalue"
        [successNotifications]="successNotifications$ | async | keyvalue"
      ></vpfa-notification>

      <vpfa-file-notifications
        [errorList]="filesErrorsList$ | async"
        [successList]="filesSuccessList$ | async"
        [filesUploadCount]="filesUploadCount$ | async"
        [invalidFilesCount]="invalidFilesCount$ | async"
      ></vpfa-file-notifications>

      <div class="notification-close">
        <button id="closeNotificationBtn" nz-button nzType="link" (click)="onClose()">
          {{ 'common.close' | translate }}
        </button>
      </div>
    </div>
  </vpfa-modal-content>
</vpfa-modal>
