import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArrowLinkComponent } from './components/arrow-link/arrow-link.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ArrowLinkComponent],
  exports: [ArrowLinkComponent]
})
export class UiKitArrowLinkModule {}
