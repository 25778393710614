import { EquipmentError, EquipmentErrorEquipmentErrorDto } from '@vpfa/rest-api/valuation';

/**
 * Remove unmet formula errors if already stored in wizard
 *
 * @param res
 * @param formulasMainCodes
 */
export function removeUnmetFormula(
  equipmentErrors: Array<EquipmentErrorEquipmentErrorDto>,
  formulasMainCodes: string[]
): EquipmentErrorEquipmentErrorDto[] {
  return equipmentErrors.filter(
    err =>
      !(
        err.error === EquipmentError.UnmetFormulaOfEnabledEquipment && formulasMainCodes.indexOf(err.soaCodes[0]) !== -1
      )
  );
}
