import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionDropdownComponent } from './action-dropdown/action-dropdown.component';
import { FlexModule } from '@ngbracket/ngx-layout';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, FlexModule, NzButtonModule, NzPopoverModule, NzPopconfirmModule, TranslateModule],
  exports: [ActionDropdownComponent],
  declarations: [ActionDropdownComponent],
})
export class UiKitActionDropdownModule {}
