import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { notEarlierThan, notLaterThanFromToday } from '@vpfa/ui-kit/uikit-validation';
import { isSameDate } from '@vpfa/utils';

@Component({
  selector: 'vpfa-forecast-modal',
  templateUrl: './forecast-modal.component.html',
  styleUrls: ['./forecast-modal.component.scss'],
})
export class ForecastModalComponent implements OnInit {
  @Input() isLoading: boolean;
  @Input() isVisible: boolean;
  @Input() forecastDate: string;
  @Input() timezone: string;

  @Output() forecastRequest = new EventEmitter<string>();
  @Output() closeAction = new EventEmitter();

  forecastForm: UntypedFormGroup = this.fb.group({
    forecastDate: [null, [Validators.required]],
  });

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.initialize();
  }

  disabledDate = (date: string) =>
    notEarlierThan(date, this.timezone, false) || notLaterThanFromToday(date, this.timezone, 0, 6, 0, 0, true);

  requestForecast() {
    this.forecastRequest.emit(this.forecastForm.get('forecastDate').value);
    this.forecastForm.markAsPristine();
  }

  cancel() {
    this.initialize();
    this.closeAction.emit();
  }

  sameDateCheck(date: string) {
    return isSameDate(date, this.forecastDate);
  }

  private initialize() {
    this.forecastForm.reset({
      forecastDate: this.forecastDate,
    });
  }
}
