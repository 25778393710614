import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { NoFilesBoxComponent } from './components/no-files-box/no-files-box.component';
import { FileUploadContainerComponent } from './containers/file-upload-container/file-upload-container.component';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { TranslateModule } from '@ngx-translate/core';
import { SharedMobileViewModule } from '@vpfa/shared/mobile-view';
import { UiKitModule } from '@vpfa/ui-kit';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    NzUploadModule,
    TranslateModule,
    NzButtonModule,
    SharedMobileViewModule,
    UiKitModule,
  ],
  declarations: [FileUploadComponent, NoFilesBoxComponent, FileUploadContainerComponent],
  exports: [FileUploadComponent, NoFilesBoxComponent, FileUploadContainerComponent],
})
export class SharedFilesModule {}
