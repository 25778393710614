import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { mergeMap } from 'rxjs/operators';
import { EnvironmentsService } from '@vpfa/environments/data';
import {
  ACCEPT_HEADER_KEY,
  ACCEPT_HEADER_VALUE,
  AUTHORIZATION_HEADER_BEARER,
  AUTHORIZATION_HEADER_KEY,
  CONTENT_TYPE_HEADER_KEY,
  CONTENT_TYPE_HEADER_VALUE,
  X_AVH_REALM_HEADER_KEY,
  X_AVH_REALM_HEADER_VALUE,
} from '@vpfa/auth';

@Injectable({ providedIn: 'root' })
export class LiveRetailInterceptor implements HttpInterceptor {
  constructor(private jwtHelperService: JwtHelperService, private environmentsService: EnvironmentsService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.search('^/live-retail-api') === 0) {
      var newUrl = this.environmentsService.config.liveRetailUrl + req.url.replace('/live-retail-api', '');

      const token$ = from((this.jwtHelperService.tokenGetter() as any) as Promise<string>);

      const changedUrlReq = req.clone({
        url: newUrl,
        headers: new HttpHeaders({
          [ACCEPT_HEADER_KEY]: ACCEPT_HEADER_VALUE,
          [CONTENT_TYPE_HEADER_KEY]: CONTENT_TYPE_HEADER_VALUE,
          [X_AVH_REALM_HEADER_KEY]: X_AVH_REALM_HEADER_VALUE,
        }),
      });

      return token$.pipe(
        mergeMap((asyncToken: string | null) => {
          const authReq = changedUrlReq.clone({
            setHeaders: {
              [AUTHORIZATION_HEADER_KEY]: `${AUTHORIZATION_HEADER_BEARER} ${asyncToken}`,
            },
          });
          return next.handle(authReq);
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
