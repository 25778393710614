import { Injectable } from '@angular/core';
import { MixpanelService } from '../mixpanel/mixpanel.service';
import { TranslateService } from '@ngx-translate/core';
import { DataDogService } from '../data-dog/data-dog.service';

@Injectable({ providedIn: 'root' })
export class CivicCookiesService {
  private readonly FONT_COLOR = '#fff';
  private readonly BACKGROUND_COLOR = '#0097cd';
  private readonly BUTTON_TOGGLE_COLOR = '#b30186';
  private readonly TOGGLE_BACKGROUND_COLOR = '#2b0766';

  constructor(
    private mixpanelService: MixpanelService,
    private translateService: TranslateService,
    private readonly dataDogService: DataDogService,
  ) {}

  loadCookieControl(civicCookiesApiKey: string) {
    const config = this.generateConfigObject(civicCookiesApiKey);
    // @ts-ignore
    window.loadCookieControl(config);
  }

  private generateConfigObject(civicCookiesApiKey: string) {
    return {
      apiKey: civicCookiesApiKey,
      product: 'PRO_MULTISITE',
      logConsent: false,
      notifyOnce: false,
      initialState: 'OPEN',
      position: 'RIGHT',
      layout: 'SLIDEOUT',
      toggleType: 'slider',
      acceptBehaviour: 'all',
      closeOnGlobalChange: true,
      iabCMP: false,
      closeStyle: 'icon',
      rejectButton: false,
      settingsStyle: 'button',
      setInnerHTML: true,
      branding: {
        fontColor: this.FONT_COLOR,
        fontSizeTitle: '1.5em',
        fontSizeHeaders: '1.5em',
        fontSize: '1.1em',
        backgroundColor: this.BACKGROUND_COLOR,
        toggleText: this.FONT_COLOR,
        toggleColor: this.BUTTON_TOGGLE_COLOR,
        toggleBackground: this.TOGGLE_BACKGROUND_COLOR,
        acceptText: this.FONT_COLOR,
        acceptBackground: this.BUTTON_TOGGLE_COLOR,
        buttonIconWidth: 64,
        buttonIconHeight: 64,
        removeIcon: false,
        removeAbout: true,
      },
      text: {
        title: this.translateService.instant('civicCookies.title'),
        intro: this.translateService.instant('civicCookies.intro'),
        necessaryTitle: this.translateService.instant('civicCookies.necessaryTitle'),
        necessaryDescription: this.translateService.instant('civicCookies.necessaryDescription'),
        on: this.translateService.instant('civicCookies.on'),
        off: this.translateService.instant('civicCookies.off'),
        acceptSettings: this.translateService.instant('civicCookies.acceptSettings'),
      },
      necessaryCookies: ['CookieControl', 'token', 'dms_*'],
      optionalCookies: [
        {
          name: 'analytics',
          description: this.translateService.instant('civicCookies.optionalCookies.description'),
          label: this.translateService.instant('civicCookies.optionalCookies.label'),
          onAccept: () => this.enableTracking(),
          onRevoke: () => this.disableTracking(),
          recommendedState: 'ON',
          cookies: [DataDogService.COOKIE_PATTERN, MixpanelService.COOKIE_PATTERN],
        },
      ],
      statement: {
        description: this.translateService.instant('civicCookies.statement.description'),
        name: this.translateService.instant('civicCookies.statement.name'),
        url: this.translateService.instant('civicCookies.statement.url'),
        updated: '01/07/2020',
      },
    };
  }

  private enableTracking() {
    this.mixpanelService.enableMixpanel();
    this.dataDogService.enableTracking();
  }

  private disableTracking() {
    this.mixpanelService.disableMixpanel();
    this.dataDogService.disableTracking();
  }
}
