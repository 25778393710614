import { PriceDisplayModel } from '../../../components/pricing-details-modal/pricing-details-modal.component';
import { TaxedPriceDto } from '@vpfa/rest-api/valuation';
import { getCurrentDate } from '@vpfa/utils';

export const setupValuationPrices = (
  activeCaseDataTaxedPrice: TaxedPriceDto,
  isOldVehicle: boolean,
  activeCaseDataPurchasedTime: string,
  valuationForDate: string,
  isForecast: boolean,
  valuationVersion: string,
  valuationVersionAtPurchase: string,
): PriceDisplayModel[] => {
  return [
    {
      name: isForecast ? 'pricingDetailsModal.myAdjustedRetailForecasted' : 'pricingDetailsModal.myAdjustedRetailNow',
      date: valuationForDate,
      price: activeCaseDataTaxedPrice?.avRetailRepairedPrice,
      tax: activeCaseDataTaxedPrice?.avRetailRepairedPriceTaxAmount,
      hidePrice: isOldVehicle,
      showHistory: false,
      isForecast: isForecast,
      valuationVersion: valuationVersion,
    },
    {
      name: isForecast ? 'pricingDetailsModal.myAdjustedTradeForecasted' : 'pricingDetailsModal.myAdjustedTradeNow',
      date: valuationForDate,
      price: activeCaseDataTaxedPrice?.avTradeInPrice,
      tax: activeCaseDataTaxedPrice?.avTradeInPriceTaxAmount,
      hidePrice: isOldVehicle,
      showHistory: false,
      isForecast: isForecast,
      valuationVersion: valuationVersion,
    },
    {
      name: 'pricingDetailsModal.myAdjustedRetailAtPurchase',
      date: activeCaseDataPurchasedTime,
      price: activeCaseDataTaxedPrice?.avRetailRepairedPriceAtPurchase,
      tax: activeCaseDataTaxedPrice?.avRetailRepairedPriceAtPurchaseTaxAmount,
      hidePrice: isOldVehicle,
      showHistory: false,
      hiddenByDefault: true,
      valuationVersion: valuationVersionAtPurchase,
    },
    {
      name: 'pricingDetailsModal.myAdjustedTradeAtPurchase',
      date: activeCaseDataPurchasedTime,
      price: activeCaseDataTaxedPrice?.avTradeInPriceAtPurchase,
      tax: activeCaseDataTaxedPrice?.avTradeInPriceAtPurchaseTaxAmount,
      hidePrice: isOldVehicle,
      showHistory: false,
      hiddenByDefault: true,
      valuationVersion: valuationVersionAtPurchase,
    },
    {
      name: 'pricingDetailsModal.myCalculatedTrade',
      date: activeCaseDataPurchasedTime,
      price: activeCaseDataTaxedPrice?.calculatedTradeInPrice,
      tax: activeCaseDataTaxedPrice?.calculatedTradeInPriceTaxAmount,
      hidePrice: isOldVehicle,
      showHistory: false,
      hiddenByDefault: true,
      valuationVersion: valuationVersionAtPurchase,
    },
  ];
};
