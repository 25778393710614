<div class="filter-box" fxLayout="row" fxFlexAlign="center center">
  <span class="label light-fw" fxFlexAlign="center center" *vpfaResponsiveIf="'web'">
    {{ 'vehicleOverviewData.resellerReplies' | translate }}
  </span>
  <div class="loader">
    <vpfa-inline-loader *ngIf="!loaded"></vpfa-inline-loader>
  </div>
  <div *ngIf="loaded">
    <button nz-button class="unread-count-box" (click)="selectUnreadReplies()">
      <span>
        {{ unreadResellerResponses }}
      </span>
    </button>
    <button nz-button class="flagged-count-box" (click)="selectFlaggedReplies()">
      <span>
        {{ flaggedResellerResponses }}
        <span nz-icon nzType="flag" nzTheme="fill"></span>
      </span>
    </button>
  </div>
</div>
