<div class="label-value-container" fxLayout="column">
  <span *ngIf="!tooltipContent" class="label" [ngClass]="{ 'bold-label': boldLabel }">{{ label }}</span>
  <div *ngIf="tooltipContent" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <span class="label" [ngClass]="{ 'bold-label': boldLabel }">{{ label }}</span>
    <vpfa-tooltip [content]="tooltipContent"></vpfa-tooltip>
  </div>
  <ng-container *ngFor="let v of valueArray">
    <div
      #valueElement
      (mouseenter)="isEllipsisActive()"
      nz-tooltip
      [nzTooltipOverlayClassName]="'tooltip'"
      [nzTooltipTitle]="ellipsisActive ? v : null"
      [nzTooltipTrigger]="'hover'"
      [nzTooltipPlacement]="'top'"
    >
      <ng-container [ngSwitch]="displayType">
        <a *ngSwitchCase="'email'" href="mailto:{{ v }}">{{ v }}</a>
        <a *ngSwitchCase="'phone'" href="tel:{{ v }}">{{ v }}</a>
        <pre *ngSwitchCase="'pre'">{{ v }}</pre>
        <span *ngSwitchDefault>{{ v }}</span>
      </ng-container>
    </div>
  </ng-container>
  <span *ngIf="!valueArray.length">{{ 'common.noValue' | translate }}</span>
</div>
