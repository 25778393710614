import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { NumberLocalePipe } from '@vpfa/shared/pipes';
import { VehicleDto } from '@vpfa/rest-api/identification';
import { LocaleFacade } from '@vpfa/locale';
import { isNil } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'engineCapacity',
  pure: false,
})
export class EngineCapacityPipe implements PipeTransform {
  constructor(private translateService: TranslateService, localeFacade: LocaleFacade, _ref: ChangeDetectorRef) {
    this.numberPipe = new NumberLocalePipe(localeFacade, _ref);
  }
  private numberPipe: NumberLocalePipe;
  transform(engineCapacity: VehicleDto['engineCapacity'], showUnit: boolean = false): any {
    if (isNil(engineCapacity) || isNil(engineCapacity.value)) {
      return this.translateService.instant('common.noValue');
    }
    const format = engineCapacity.unit === 'l' ? '1.1-1' : '1.0-0';
    return `${this.numberPipe.transform(engineCapacity.value, format)}${showUnit ? ` ${engineCapacity.unit}` : ''}`;
  }
}
