import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Features } from '@vpfa/rest-api/admin';
import { LanguageEnum } from '@vpfa/shared/translate';
import { isNil, uniq } from 'lodash';
import { LOCALE_FEATURE_KEY, LocaleState } from './locale.reducer';

// Lookup the 'Locale' feature state managed by NgRx

const getLocaleState = createFeatureSelector<LocaleState>(LOCALE_FEATURE_KEY);
const localeEffectsInitialized = createSelector(getLocaleState, (state: LocaleState) => state.isCountryLoaded);

const getCountry = createSelector(getLocaleState, (state: LocaleState) => state.country);

const getIsCountryLoading = createSelector(getLocaleState, (state: LocaleState) => state.isCountryLoading);

const getCurrency = createSelector(getCountry, state => (isNil(state) ? null : state.currency));

const getCsvConfiguration = createSelector(getLocaleState, state => (isNil(state) ? null : state.csvConfig));

const getCountryDefaultLanguage = createSelector(
  getLocaleState,
  (state: LocaleState) => state.country?.defaultLanguage
);

const getSelectedContentLocaleId = createSelector(
  getLocaleState,
  (state: LocaleState) => state.selectedContentLanguageId
);

const getSelectedUILocaleId = createSelector(getLocaleState, (state: LocaleState) => state.selectedUILanguageId);

const getSelectedContentLanguage = createSelector(getSelectedContentLocaleId, id => {
  const result: LanguageEnum = LanguageEnum[id];
  return result || null;
});

const getSelectedUILanguage = createSelector(getSelectedUILocaleId, id => {
  const result: LanguageEnum = LanguageEnum[id];
  return result || null;
});

const getTimezone = createSelector(getCountry, state => (isNil(state) ? null : state.timezone));

const getLocale = createSelector(getLocaleState, state => state.locale);

const getIsCurrencyPrefix = createSelector(getLocaleState, state => state.currencyPrefix);

const getExperianTermsUrl = createSelector(getLocaleState, state => state.countryExperianTermsUrl);

const getFeatureConfigState = createSelector(getLocaleState, localeState => localeState.featureConfig);

const getFeatureConfigLoading = createSelector(getFeatureConfigState, featureConfigState => featureConfigState.loading);

const getFeatureConfigLoaded = createSelector(getFeatureConfigState, featureConfigState => featureConfigState.loaded);

const getFeaturesForCountry = createSelector(
  getFeatureConfigState,
  featureConfigState => featureConfigState.forCountry
);

const getFeatureConfigCountry = createSelector(
  getFeatureConfigState,
  featureConfigState => featureConfigState.config?.countryFeatures
);

const getFeatureConfigVehicle = () =>
  createSelector(
    getFeatureConfigState,
    (featureConfigState: LocaleState['featureConfig'], { vehicleType }: { vehicleType: string }) => {
      const featuresConfig: Features[] = [];
      if (featureConfigState.config?.countryFeatures) {
        featuresConfig.push(...featureConfigState.config?.countryFeatures);
      }

      if (featureConfigState.config?.vehicleTypeFeaturesConfig[vehicleType]) {
        featuresConfig.push(...featureConfigState.config.vehicleTypeFeaturesConfig[vehicleType]);
      }

      return uniq(featuresConfig);
    }
  );

const getFeatureConfigAllVehicleTypes = createSelector(
  getFeatureConfigState,
  (featureConfigState: LocaleState['featureConfig']) => featureConfigState.config?.vehicleTypeFeaturesConfig
);

const getBuildDateNotAvailableForMake = () =>
  createSelector(
    getFeatureConfigState,
    (featureConfigState: LocaleState['featureConfig'], { makeCode }: { makeCode: number }) => {
      return featureConfigState?.config?.makeProductionDateConfig?.includes(makeCode) ?? false;
    }
  );

export const localeQuery = {
  getSelectedContentLanguage,
  getSelectedUILanguage,
  getCountry,
  getCurrency,
  getTimezone,
  getLocale,
  getIsCurrencyPrefix,
  getCsvConfiguration,
  getIsCountryLoading,
  getExperianTermsUrl,
  getCountryDefaultLanguage,
  localeEffectsInitialized,
  getFeatureConfigCountry,
  getFeatureConfigVehicle,
  getFeaturesForCountry,
  getFeatureConfigLoading,
  getBuildDateNotAvailableForMake,
  getFeatureConfigAllVehicleTypes,
  getFeatureConfigLoaded,
};
