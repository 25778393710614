import { Component, EventEmitter, ViewChild, TemplateRef, Input } from '@angular/core';
import { ModalContentComponent } from '../modal-content/modal-content.component';

@Component({
  selector: 'vpfa-ok-modal-content',
  templateUrl: './ok-modal-content.component.html',
  styleUrls: ['./ok-modal-content.component.scss'],
})
export class OKModalContentComponent {
  @ViewChild('ctr') modalContentTemplate: TemplateRef<ModalContentComponent>;

  @Input() okLabelKey = 'common.ok';
  @Input() titleKey: string = '';
  @Input() infoLabelsKey: string[] = [];

  confirmResult = new EventEmitter<void>();
}
