import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IMAGE_FRAME_FEATURE_KEY, ImageFrameState } from './image-frame.reducer';

// Lookup the 'ImageFrame' feature state managed by NgRx
const getImageFrameState = createFeatureSelector<ImageFrameState>(IMAGE_FRAME_FEATURE_KEY);

const getIsLoadingList = createSelector(getImageFrameState, (state: ImageFrameState) => state.list.loading);
const getList = createSelector(getImageFrameState, (state: ImageFrameState) => state.list.data);

const getIsFramedImagePreviewLoading = createSelector(
  getImageFrameState,
  (state: ImageFrameState) => state.framedImage.loading
);
const getFramedImagePreview = createSelector(
  getImageFrameState,
  (state: ImageFrameState) => state.framedImage.preview
);

const getIsEditorLoading = createSelector(getImageFrameState, (state: ImageFrameState) => state.editor.loading);
const getEditorUrl = createSelector(getImageFrameState, (state: ImageFrameState) => state.editor.url);

const getIsCreateFrameModalOpen = createSelector(
  getImageFrameState,
  (state: ImageFrameState) => state.createFrameModal.isOpen
);
const getIsCreateFrameModalProcessing = createSelector(
  getImageFrameState,
  (state: ImageFrameState) => state.createFrameModal.isProcessing
);

const getIsRenameFrameModalOpen = createSelector(
  getImageFrameState,
  (state: ImageFrameState) => state.renameFrameModal.isOpen
);
const getIsRenameFrameModalProcessing = createSelector(
  getImageFrameState,
  (state: ImageFrameState) => state.renameFrameModal.isProcessing
);

export const imageFrameQuery = {
  getIsLoadingList,
  getList,
  getIsEditorLoading,
  getEditorUrl,
  getIsCreateFrameModalOpen,
  getIsCreateFrameModalProcessing,
  getIsRenameFrameModalOpen,
  getIsRenameFrameModalProcessing,
  getIsFramedImagePreviewLoading,
  getFramedImagePreview,
};
