<vpfa-data-table
  class="print-templates-list"
  [columnVisibility]="false"
  [options]="gridOptions"
  [columnDefs]="colDefs"
  [rowData]="templates"
  [loading]="loading"
  [defaultColDef]="defaultColDef"
  (actionClicked)="onActionClicked($event)"
></vpfa-data-table>
