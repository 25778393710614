import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { isNil } from 'lodash';

@Component({
  selector: 'vpfa-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent implements AfterViewInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  private readonly ROW_TO_HEIGHT_RATIO = 1.7;
  private readonly DEFAULT_MIN_ROWS = 4;
  private readonly DEFAULT_MAX_ROWS = 20;
  private _minRows: number;
  private _maxRows: number;

  minHeight: number = this.ROW_TO_HEIGHT_RATIO * this.DEFAULT_MIN_ROWS;
  maxHeight: number = this.ROW_TO_HEIGHT_RATIO * this.DEFAULT_MAX_ROWS;

  @Input() parentFormGroup: UntypedFormGroup;
  @Input() fcName: string;
  @Input() set minRows(minRows: number) {
    this._minRows = minRows;
    this.minHeight = this.calculateHeight(this.DEFAULT_MIN_ROWS, minRows);
  }
  get minRows() {
    return this._minRows;
  }
  @Input() set maxRows(maxRows: number) {
    this._maxRows = maxRows;
    this.maxHeight = this.calculateHeight(this.DEFAULT_MAX_ROWS, maxRows);
  }
  get maxRows() {
    return this._maxRows;
  }
  @Input() errorMessages: ReadonlyArray<{ error: string; errorMsg: string }>;
  @Input() placeholder = '';
  @Output() blurEvent = new EventEmitter();
  @Output() focusEvent = new EventEmitter();

  @ViewChild('textarea', { static: true }) textareaElement: ElementRef;

  ngAfterViewInit() {
    this.setTextareaHeight();
    this.getFormControl()
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe(() => {
        // nzAutosize property was causing automatic scrolling error (VP-10586) so autosizing needs to be handled manually
        if (this.textareaElement.nativeElement.getBoundingClientRect().top >= 0) {
          this.adjustToMinHeight();
          this.adjustToMaxContent();
        }
      });
  }

  onFocus() {
    this.focusEvent.emit();
    this.adjustToMaxContent();
  }

  onBlur() {
    this.blurEvent.emit();
    this.adjustToMinHeight();
  }

  getFormControl() {
    return this.parentFormGroup.get(this.fcName) as UntypedFormControl;
  }

  private calculateHeight(defaultRows: number, givenRows: number) {
    if (!isNil(givenRows)) {
      defaultRows = givenRows;
    }
    return this.ROW_TO_HEIGHT_RATIO * defaultRows;
  }

  private setTextareaHeight() {
    this.textareaElement.nativeElement.style.maxHeight = `${this.maxHeight}em`;
    this.textareaElement.nativeElement.style.minHeight = `${this.minHeight}em`;
  }

  private adjustToMinHeight() {
    this.textareaElement.nativeElement.style.height = 'auto';
  }

  private adjustToMaxContent() {
    this.textareaElement.nativeElement.style.height = `${this.textareaElement.nativeElement.scrollHeight}px`;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
