import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { NzTooltipTrigger } from 'ng-zorro-antd/tooltip';
import { NgStyleInterface } from 'ng-zorro-antd/core/types';

@Component({
  selector: 'vpfa-action-button-with-confirmation',
  templateUrl: './action-button-with-confirmation.component.html',
  styleUrls: ['./action-button-with-confirmation.component.scss'],
})
export class ActionButtonWithConfirmationComponent {
  @Input() buttonType = 'button';
  @Input() disabled: boolean = false;
  @Input() icon: string;
  @Input() buttonText: string = 'common.cancel';
  @Input() border: boolean = false;
  @Input() arrow: boolean = false;
  @Input() linkMode: boolean = true;
  @Input() fullWidth: boolean = true;
  @Input() notAvailableIcon = false;
  @Input() tooltipContent: string | TemplateRef<void>;

  /**
   * Expression that resolves to boolean to determine whether popconfirm should be triggered
   *
   * false - popconfirm will not be triggered
   * true - popconfirm will be triggered
   */
  @Input() showPopconfirm: boolean = true;
  @Input() popconfirmMainText: string | TemplateRef<void> = 'common.unsavedChanges';
  @Input() popconfirmCancelText: string = 'common.no';
  @Input() popconfirmOkText: string = 'common.yes';
  @Input() popconfirmPlacement:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom'
    | Array<string> = 'top';
  @Input() popconfirmOverlayClassName: string = 'modal-confirm-popover';
  @Input() popconfirmOverlayStyle: NgStyleInterface;
  @Input() popconfirmTrigger: NzTooltipTrigger = 'click';
  @Output() onConfirm = new EventEmitter<void>();
}
