<vpfa-setting-box *vpfaResponsiveIf="'web'; else mobileView" [divider]="false">
  <ng-container label>
    <vpfa-label [text]="label"></vpfa-label>
  </ng-container>
  <ng-container content>
    <vpfa-interval-number-field
      [parentFormGroup]="parentFormGroup"
      [fcName]="fcName"
      [disableMaxWidth]="true"
      [errorMessages]="errorMessages"
      [heightCompensationPx]="-10"
      [maxFractionNumber]="maxFractionNumber"
    ></vpfa-interval-number-field>
  </ng-container>
</vpfa-setting-box>

<ng-template #mobileView>
  <vpfa-vehicle-technical-data-single-field
    [label]="label"
    [value]="readOnlyValue | unitRangeValue"
  ></vpfa-vehicle-technical-data-single-field>
</ng-template>
