<div ngClass.lt-mv="mobile">
  <input
    [accept]="getAcceptInfo(fileUploadType)"
    class="file-input"
    #fileInputRef
    type="file"
    (change)="onChange($event)"
    [multiple]="multiple"
  />
  <ng-container *ngIf="uiMode == 'fullMode'">
    <vpfa-no-files-box
      [uploadPrompt]="uploadPrompt"
      [uploadText]="uploadText || uploadButtonLabel"
      [maxSizeKB]="maxFileSize"
      *ngIf="!fileList?.length"
      (click)="onUploadBtnClick()"
    ></vpfa-no-files-box>
    <vpfa-action-button *ngIf="fileList?.length" [disabled]="isDisabled" (click)="onUploadBtnClick()">
      <span>
        <i *ngIf="showUploadIcon" class="zmdi zmdi-upload icon"></i>
        {{ uploadButtonLabel }}
      </span>
    </vpfa-action-button>
  </ng-container>
  <ng-container *ngIf="uiMode === 'linkMode'">
    <button class="upload-button" [disabled]="isDisabled" (click)="onUploadBtnClick()" nz-button nzType="link">
      <span>
        <i *ngIf="showUploadIcon" class="zmdi zmdi-upload icon"></i>
        {{ uploadButtonLabel }}
      </span>
    </button>
  </ng-container>
  <ng-container *ngIf="uiMode === 'singleFileModalMode'">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <button class="upload-button" [disabled]="isDisabled" (click)="onUploadBtnClick()" nz-button nzType="link">
        <i *ngIf="showUploadIcon" class="zmdi zmdi-upload icon"></i>
        {{ uploadButtonLabel }}
      </button>
      <div *ngIf="fileList?.length">{{ selectedFiles[0]?.name }}</div>
      <div *ngIf="!fileList?.length">{{ 'files.noFileSelected' | translate }}</div>
    </div>
  </ng-container>
</div>
