import { Input, Output, EventEmitter, Directive } from '@angular/core';
import { VehicleDto } from '@vpfa/rest-api/identification';
import { DateLocalePipe } from '@vpfa/shared/pipes';
import { TranslateService } from '@ngx-translate/core';

@Directive()
export abstract class BaseVehicleList {
  @Input() wizardMode: boolean;
  @Input() listItems: VehicleDto[];
  @Input() disableSpecialButton = false;
  @Input() identifyNumber: string;
  @Input() tableName: string;
  @Input() isLoadingTechSpec = false;
  @Input() stickyXScrollCustomBottomShift: number;

  @Output() showSpec = new EventEmitter<VehicleDto>();
  @Output() rowClick = new EventEmitter<VehicleDto>();

  isLoadingVehicle = false;

  protected constructor(protected dateLocale: DateLocalePipe, protected translateService: TranslateService) {}

  onRowClick(data: VehicleDto) {
    if (this.wizardMode === false) {
      this.isLoadingVehicle = true;
    }
    this.rowClick.emit(data);
  }

  isSpecIconDisable(data: VehicleDto) {
    return this.disableSpecialButton;
  }

  specIconErrorInfo(data: VehicleDto) {
    return this.translateService.instant('identification.vehicles.requiredDate');
  }

  onActionClicked(event) {
    this.showSpec.emit(event);
  }
}
