export function getFormControlValueAsString(formControlValue: unknown) {
  if (typeof formControlValue === 'string') {
    return formControlValue;
  }

  if (typeof formControlValue === 'number') {
    return formControlValue.toString();
  }

  return null;
}
