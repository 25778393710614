import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VEHICLEEQUIPMENT_FEATURE_KEY, VehicleEquipmentState } from './vehicle-equipment.reducer';

// Lookup the 'VehicleEquipment' feature state managed by NgRx
const getVehicleEquipmentState = createFeatureSelector<VehicleEquipmentState>(VEHICLEEQUIPMENT_FEATURE_KEY);

const getIsLoading = createSelector(getVehicleEquipmentState, state => state.loading);
const getIsAddingAftermarketEquipment = createSelector(
  getVehicleEquipmentState,
  state => state.isAddingAftermarketEquipment
);
const getIsUpdatingAftermarketEquipment = createSelector(
  getVehicleEquipmentState,
  state => state.isUpdatingAftermarketEquipment
);

const getUniversalCategoryCodes = createSelector(getVehicleEquipmentState, state => state.universalCategoryCodes);

const getFormulaWizard = createSelector(getVehicleEquipmentState, state => state.formulaWizard);

export const vehicleEquipmentQuery = {
  getIsLoading,
  getIsAddingAftermarketEquipment,
  getIsUpdatingAftermarketEquipment,
  getUniversalCategoryCodes,
  getFormulaWizard,
};
