import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromGalleryManager from './+state/gallery-manager.reducer';
import { GalleryManagerEffects } from './+state/gallery-manager.effects';
import { GalleryManagerFacade } from './+state/gallery-manager.facade';
import { CaseDataModule } from '@vpfa/dealer/case/data';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromGalleryManager.GALLERY_MANAGER_FEATURE_KEY, fromGalleryManager.reducer),
    EffectsModule.forFeature([GalleryManagerEffects]),
    CaseDataModule,
  ],
  providers: [GalleryManagerFacade],
})
export class DealerGalleryManagerDataModule {}
