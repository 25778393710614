import { Component, Input, OnInit } from '@angular/core';

import { Dictionary, groupBy } from 'lodash';
import { FileNotAcceptedReason } from '../../models/file-not-accepted-reason.enum';
import { FileNotificationModel } from '../../models/file-notification.model';

@Component({
  selector: 'vpfa-file-notifications',
  templateUrl: './file-notifications.component.html',
  styleUrls: ['./file-notifications.component.scss'],
})
export class FileNotificationsComponent implements OnInit {
  @Input()
  successList: FileNotificationModel[] = [];

  @Input()
  filesUploadCount = 0;

  @Input()
  invalidFilesCount = 0;

  groupedErrorList: Dictionary<FileNotificationModel[]>;
  errorGroups: FileNotAcceptedReason[];

  @Input()
  public set errorList(value: FileNotificationModel[]) {
    this._errorList = value;
    this.groupedErrorList = groupBy<FileNotificationModel>(value, x => x.reason);
    this.errorGroups = Object.keys(this.groupedErrorList).map(x => Number(x) as FileNotAcceptedReason);
  }
  public get errorList() {
    return this._errorList;
  }
  private _errorList: FileNotificationModel[];

  get successFilesNo() {
    return this.successList?.length ?? 0;
  }

  get errorFilesNo() {
    return this.errorList?.length ?? 0;
  }

  get hasErrors() {
    return this.errorFilesNo > 0;
  }

  get totalFilesNo() {
    return this.filesUploadCount + this.invalidFilesCount;
  }

  get isAllSuccess() {
    return this.successFilesNo > 0 && !this.hasErrors;
  }

  get isOneSuccess() {
    return this.successFilesNo === 1;
  }

  get isMoreThanOneSuccess() {
    return this.successFilesNo > 1;
  }

  get isPartialError() {
    return this.hasErrors && this.totalFilesNo !== this.errorFilesNo;
  }

  get isAllFailed() {
    return this.hasErrors && this.totalFilesNo === this.errorFilesNo;
  }

  getReasonTranslation(reason: FileNotAcceptedReason) {
    switch (reason) {
      case FileNotAcceptedReason.ExceedsFileSizeLimit:
        return 'files.exceedsFileSizeLimit';
      case FileNotAcceptedReason.ExceedsTotalSizeLimitForBusiness:
        return 'files.exceedsTotalSizeLimitForBusiness';
      case FileNotAcceptedReason.FileExtensionDoesNotMatchFileContent:
        return 'files.fileExtensionDoesntMatchFileContent';
      case FileNotAcceptedReason.FileSizeIsZero:
        return 'files.fileSizeIsZero';
      case FileNotAcceptedReason.IncorrectFileType:
        return 'files.wrongFileType';
      case FileNotAcceptedReason.ExceedsDocumentFileSizeLimit:
        return 'files.exceedsDocumentSizeLimit';
      case FileNotAcceptedReason.ExceedsTotalSizeLimitForCaseDocuments:
        return 'files.exceedsTotalSizeLimitForCaseDocuments';
      case FileNotAcceptedReason.ExceedsTotalSizeLimitForCaseImages:
        return 'files.exceedsTotalSizeLimitForCaseImages';
      case FileNotAcceptedReason.FileUploadError:
        return 'files.fileUploadError';
    }

    return 'files.fileUploadError';
  }

  constructor() {}

  ngOnInit(): void {}
}
