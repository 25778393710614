import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequiredSymbolComponent } from './components/required-symbol/required-symbol.component';

@NgModule({
  imports: [CommonModule],
  declarations: [RequiredSymbolComponent],
  exports: [RequiredSymbolComponent],
})
export class UiKitRequiredSymbolModule {}
