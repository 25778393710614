import { Action } from '@ngrx/store';
import { MakeDto } from '../models/make-dto';
import { ModelDto } from '../models/model-dto';
import { VehicleDto } from '../models/vehicle-dto';
import { SorcResponseDto } from '../models/sorc-response-dto';
import { SelectedOptionalSearchFilters } from '../models/optional-search-filters';
import { MandatorySearchFilters } from '../models/mandatory-search-filters';
import { SorcDictionaryDto } from '../models/sorc-dictionary-dto';

export enum SorcIntegrationActionTypes {
  LoadMakesList = '[Sorc Integration] Load Makes List',
  LoadMakesListCompleted = '[Sorc Integration] Load Makes List Completed',
  LoadMakesListError = '[Sorc Integration] Load Makes List Error',

  LoadModelsList = '[Sorc Integration] Load Models List',
  LoadModelsListCompleted = '[Sorc Integration] Load Models List Completed',
  LoadModelsListError = '[Sorc Integration] Load Models List Error',

  LoadVehiclesList = '[Sorc Integration] Load Vehicles List',
  LoadVehiclesListCompleted = '[Sorc Integration] Load Vehicles List Completed',
  LoadVehiclesListError = '[Sorc Integration] Load Vehicles List Error',

  LoadTransmissionTypeList = '[Sorc Integration] Load Transmission Type List',
  LoadTransmissionTypeListCompleted = '[Sorc Integration] Load Transmission Type List Completed',
  LoadTransmissionTypeListError = '[Sorc Integration] Load Transmission Type List Error',

  LoadBodyTypeList = '[Sorc Integration] Load Body Type List',
  LoadBodyTypeListCompleted = '[Sorc Integration] Load Body Type List Completed',
  LoadBodyTypeListError = '[Sorc Integration] Load Body Type List Error',

  LoadFuelTypeList = '[Sorc Integration] Load Fuel Type List',
  LoadFuelTypeListCompleted = '[Sorc Integration] Load Fuel Type List Completed',
  LoadFuelTypeListError = '[Sorc Integration] Load Fuel Type List Error',

  ClearMakesList = '[Sorc Integration] Clear Makes List',
  ClearModelsList = '[Sorc Integration] Clear Models List',
  ClearVehiclesList = '[Sorc Integration] Clear Vehicles List',
}

export class LoadMakesList implements Action {
  readonly type = SorcIntegrationActionTypes.LoadMakesList;
  constructor(public payload: number[]) {}
}

export class LoadMakesListCompleted implements Action {
  readonly type = SorcIntegrationActionTypes.LoadMakesListCompleted;
  constructor(public payload: MakeDto[]) {}
}

export class LoadMakesListError implements Action {
  readonly type = SorcIntegrationActionTypes.LoadMakesListError;
}

export class LoadModelsList implements Action {
  readonly type = SorcIntegrationActionTypes.LoadModelsList;
  constructor(public payload: { typeCodes: number[]; make: MakeDto }) {}
}

export class LoadModelsListCompleted implements Action {
  readonly type = SorcIntegrationActionTypes.LoadModelsListCompleted;
  constructor(public payload: ModelDto[]) {}
}

export class LoadModelsListError implements Action {
  readonly type = SorcIntegrationActionTypes.LoadModelsListError;
}

export class LoadVehiclesList implements Action {
  readonly type = SorcIntegrationActionTypes.LoadVehiclesList;
  constructor(
    public payload: {
      model: ModelDto;
      pageSize: number;
      mandatorySearchFilters: MandatorySearchFilters;
      optionalSearchFilters?: SelectedOptionalSearchFilters;
    }
  ) {}
}

export class LoadVehiclesListCompleted implements Action {
  readonly type = SorcIntegrationActionTypes.LoadVehiclesListCompleted;
  constructor(public payload: SorcResponseDto<VehicleDto>) {}
}

export class LoadVehiclesListError implements Action {
  readonly type = SorcIntegrationActionTypes.LoadVehiclesListError;
}

export class LoadTransmissionTypeList implements Action {
  readonly type = SorcIntegrationActionTypes.LoadTransmissionTypeList;
}

export class LoadTransmissionTypeListCompleted implements Action {
  readonly type = SorcIntegrationActionTypes.LoadTransmissionTypeListCompleted;
  constructor(public payload: SorcDictionaryDto[]) {}
}

export class LoadTransmissionTypeListError implements Action {
  readonly type = SorcIntegrationActionTypes.LoadTransmissionTypeListError;
}

export class LoadBodyTypeList implements Action {
  readonly type = SorcIntegrationActionTypes.LoadBodyTypeList;
}

export class LoadBodyTypeListCompleted implements Action {
  readonly type = SorcIntegrationActionTypes.LoadBodyTypeListCompleted;
  constructor(public payload: SorcDictionaryDto[]) {}
}

export class LoadBodyTypeListError implements Action {
  readonly type = SorcIntegrationActionTypes.LoadBodyTypeListError;
}

export class LoadFuelTypeList implements Action {
  readonly type = SorcIntegrationActionTypes.LoadFuelTypeList;
}

export class LoadFuelTypeListCompleted implements Action {
  readonly type = SorcIntegrationActionTypes.LoadFuelTypeListCompleted;
  constructor(public payload: SorcDictionaryDto[]) {}
}

export class LoadFuelTypeListError implements Action {
  readonly type = SorcIntegrationActionTypes.LoadFuelTypeListError;
}

export class ClearMakesList implements Action {
  readonly type = SorcIntegrationActionTypes.ClearMakesList;
}

export class ClearModelsList implements Action {
  readonly type = SorcIntegrationActionTypes.ClearModelsList;
}

export class ClearVehiclesList implements Action {
  readonly type = SorcIntegrationActionTypes.ClearVehiclesList;
}

export type SorcIntegrationActions =
  | LoadMakesList
  | LoadMakesListCompleted
  | LoadMakesListError
  | LoadModelsList
  | LoadModelsListCompleted
  | LoadModelsListError
  | LoadVehiclesList
  | LoadVehiclesListCompleted
  | LoadVehiclesListError
  | LoadTransmissionTypeList
  | LoadTransmissionTypeListCompleted
  | LoadTransmissionTypeListError
  | LoadBodyTypeList
  | LoadBodyTypeListCompleted
  | LoadBodyTypeListError
  | LoadFuelTypeList
  | LoadFuelTypeListCompleted
  | LoadFuelTypeListError
  | ClearMakesList
  | ClearModelsList
  | ClearVehiclesList;

export const fromSorcIntegrationActions = {
  LoadMakesList,
  LoadMakesListCompleted,
  LoadMakesListError,
  LoadModelsList,
  LoadModelsListCompleted,
  LoadModelsListError,
  LoadVehiclesList,
  LoadVehiclesListCompleted,
  LoadVehiclesListError,
  LoadTransmissionTypeList,
  LoadTransmissionTypeListCompleted,
  LoadTransmissionTypeListError,
  LoadBodyTypeList,
  LoadBodyTypeListCompleted,
  LoadBodyTypeListError,
  LoadFuelTypeList,
  LoadFuelTypeListCompleted,
  LoadFuelTypeListError,
  ClearMakesList,
  ClearModelsList,
  ClearVehiclesList,
};
