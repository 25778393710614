import { EquipmentDto, FormulaBlockType, StepElementDto, SubStepDto } from '@vpfa/rest-api/valuation';
import { has, Dictionary } from 'lodash';
import { FormulaWizardTableElement, FormulaWizardTableComplexElement } from '../models';
import { EquipmentFormulaBlockElement } from '../models/equipmentFormulaBlockElement';

const FAKE_EQUIPMENT_SOA_CODE_PREFIX = 'f_soa_code_';

export const isSimpleFormulaBlockElement = (
  equipmentFormulaBlockElement: EquipmentFormulaBlockElement
): equipmentFormulaBlockElement is StepElementDto => has(equipmentFormulaBlockElement, 'soaCode');

export const convertEquipmentFormulaBlockElementsToFormulaWizardTableElements = (
  equipmentFormulaBlockElements: EquipmentFormulaBlockElement[],
  eqpMap: Dictionary<EquipmentDto>,
  parentSoaCode: string,
  shouldNegateEnableValue = false
): FormulaWizardTableElement[] =>
  equipmentFormulaBlockElements
    .map((element, index) => {
      if (isSimpleFormulaBlockElement(element)) {
        const eqpBaseOnFormulaBlock = eqpMap[element.soaCode];
        return {
          ...eqpBaseOnFormulaBlock,
          isEnabled: shouldNegateEnableValue ? !eqpBaseOnFormulaBlock.isEnabled : eqpBaseOnFormulaBlock.isEnabled,
        };
      } else {
        const complexFormula = element;
        const generatedFakeSoaCode = generateFakeEquipmentSoaCode(complexFormula, index, parentSoaCode);
        return {
          ...element,
          isEnabled: false,
          fakeSoaCode: generatedFakeSoaCode,
          elements: convertEquipmentFormulaBlockElementsToFormulaWizardTableElements(
            complexFormula.elements,
            eqpMap,
            generatedFakeSoaCode,
            complexFormula.type === FormulaBlockType.Not ? !shouldNegateEnableValue : shouldNegateEnableValue
          ),
        } as FormulaWizardTableComplexElement;
      }
    })
    .filter(element => element);

export const generateFakeEquipmentSoaCode = (element: SubStepDto, indexInElementList: number, parentSoaCode: string): string =>
  `${FAKE_EQUIPMENT_SOA_CODE_PREFIX}${FormulaBlockType[element.type]}_${indexInElementList}_(FROM_${parentSoaCode})`;

export const isFakeEquipmentSoaCode = (soaCode: string): boolean =>
  soaCode.indexOf(FAKE_EQUIPMENT_SOA_CODE_PREFIX) === 0;

export const isFormulaWizardTableSipleElement = (element: FormulaWizardTableElement): element is EquipmentDto =>
  has(element, 'soaCode');

export const getFormulaWizardTableElementSoaCode = (
  formulaWizardTableElement: FormulaWizardTableElement
): EquipmentDto['soaCode'] => {
  if (isFormulaWizardTableSipleElement(formulaWizardTableElement)) {
    return (formulaWizardTableElement as EquipmentDto).soaCode;
  } else {
    return (formulaWizardTableElement as FormulaWizardTableComplexElement).fakeSoaCode;
  }
};
