import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'vpfa-mobile-filter-button',
  templateUrl: './mobile-filter-button.component.html',
  styleUrls: ['./mobile-filter-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileFilterButtonComponent {

  @Input() filtered = false;
  @Input() resultsLength;
  @Input() title: string;
}
