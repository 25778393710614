<div
  *ngIf="!question && errorInfo() && isDisabled()"
  nz-tooltip
  [nzTooltipOverlayClassName]="'tooltip'"
  [nzTooltipTitle]="errorInfo()"
  [nzTooltipTrigger]="'hover'"
  [nzTooltipPlacement]="'top'"
>
  <button
    *ngIf="!question"
    [disabled]="isDisabled()"
    nz-button
    nzType="link"
    (click)="onIconClick(); $event.stopPropagation()"
  >
    <i class="zmdi" [ngClass]="iconName"> </i>
  </button>
</div>

<button
  *ngIf="!question && (!errorInfo() || !isDisabled())"
  [disabled]="isDisabled()"
  nz-button
  nzType="link"
  (click)="onIconClick(); $event.stopPropagation()"
>
  <i class="zmdi" [ngClass]="iconName"> </i>
</button>

<button
  *ngIf="question"
  nz-button
  nzType="link"
  nz-popconfirm
  [disabled]="isDisabled()"
  (click)="$event.stopPropagation()"
  class="data-table-action-popover-button"
  [nzPopconfirmTrigger]="'click'"
  [nzPopconfirmTitle]="question | translate"
  [nzCancelText]="'common.no' | translate"
  [nzOkText]="'common.yes' | translate"
  nzPopconfirmPlacement="top"
  (nzOnConfirm)="onIconClick()"
  nzPopconfirmOverlayClassName="data-table-action-popover-confirm"
>
  <i class="zmdi" [ngClass]="iconName"> </i>
</button>
