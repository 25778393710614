import { Component, EventEmitter, Input, Output } from '@angular/core';
import { isNil } from 'lodash';
import { UnitValueDto, VehicleDto, TechnicalDataDto, RangeValueDto } from '@vpfa/rest-api/valuation';
import { FeaturesTechnicalDataConfiguration } from '@vpfa/locale';
import { SelectOption } from '@vpfa/ui-kit';
import { firstVehiclePrice } from './../../utils/first-vehicle-price';
import { VehicleEngineType, vehicleEngineFuelParser } from '@vpfa/dealer/case/data';

/**
 * Technical Data in Make and Modal Search (Web only)
 */
@Component({
  selector: 'vpfa-vehicle-list-full-technical-data',
  templateUrl: './vehicle-list-full-technical-data.component.html',
  styleUrls: ['./vehicle-list-full-technical-data.component.scss'],
})
export class VehicleListFullTechnicalDataComponent {
  VehicleEngineType = VehicleEngineType;

  @Input() vehicleData: VehicleDto;
  @Input() technicalDataFeatureConfig: FeaturesTechnicalDataConfiguration;
  @Input() emissionClassOptions: SelectOption[];
  @Output() closeAction = new EventEmitter<void>();

  get vehicleEngineType() {
    return vehicleEngineFuelParser(this.vehicleData?.technicalData?.vehicleEngineFuel);
  }

  getNedcConsumptionRatingValue(consumptionRating: RangeValueDto): string {
    if (isNil(consumptionRating)) {
      return null;
    }
    if (isNil(consumptionRating.valueMax)) {
      return consumptionRating.value;
    }
    return `${consumptionRating.value} - ${consumptionRating.valueMax}`;
  }

  get vehicleTechnicalPerformance(): { maxSpeed: UnitValueDto; acceleration: UnitValueDto } {
    if (this.technicalDataFeatureConfig?.showPerformanceImperial) {
      return {
        maxSpeed: this.vehicleData?.technicalData?.performanceImperial?.maxSpeed,
        acceleration: this.vehicleData?.technicalData?.performanceImperial?.acceleration60mph,
      };
    }

    return {
      maxSpeed: this.vehicleData?.technicalData?.performance?.maxSpeed,
      acceleration: this.vehicleData?.technicalData?.performance?.acceleration100kph,
    };
  }

  efficiencyClassValue(): string {
    if (this.vehicleData?.technicalData?.cO2EfficiencyClass?.valueMax) {
      return `${this.vehicleData?.technicalData?.cO2EfficiencyClass?.value?.texts} - ${this.vehicleData?.technicalData?.cO2EfficiencyClass?.valueMax?.texts}`;
    } else {
      return this.vehicleData?.technicalData?.cO2EfficiencyClass?.value?.texts;
    }
  }

  efficiencyClassValueCustom(): string {
    return this.vehicleData?.technicalData?.cO2EfficiencyClassCustom?.texts;
  }

  get firstPrice(): TechnicalDataDto['vehiclePrices'][0] | null {
    return firstVehiclePrice(this.vehicleData?.technicalData);
  }
}
