import { ContentEquipmentDto, EquipmentDto } from '@vpfa/rest-api/valuation';
import { EquipmentDto as IdentificationEquipment } from '@vpfa/rest-api/identification';
import { isNil } from 'lodash';

/**
 * Returns string with SOA and/or OEM codes for debugging purposes
 */
export function getEquipmentDebugText(equipment: EquipmentDto | IdentificationEquipment | ContentEquipmentDto) {
  if (isNil(equipment)) {
    return '[Empty EQ]';
  }

  const info = [];

  if (!isNil(equipment.soaCode)) {
    info.push('SOA:' + equipment.soaCode);
  }

  if ('oemCode' in equipment && !isNil(equipment.oemCode)) {
    info.push('OEM:' + equipment.oemCode);
  }

  return `[${info.join(',')}]`;
}
