import { Component, Input } from '@angular/core';
import { TechnicalDataDto, UnitValueDto } from '@vpfa/rest-api/valuation';
import { FeaturesTechnicalDataConfiguration } from '@vpfa/locale';
import { isNil } from 'lodash';

@Component({
  selector: 'vpfa-full-technical-data-engine-information',
  templateUrl: './full-technical-data-engine-information.component.html',
  styleUrls: ['./full-technical-data-engine-information.component.scss'],
})
export class FullTechnicalDataEngineInformationComponent {
  @Input() technicalData: TechnicalDataDto;

  @Input() featureConfig: FeaturesTechnicalDataConfiguration;

  get vehicleTechnicalPerformance(): { maxSpeed: UnitValueDto; acceleration: UnitValueDto } {
    if (this.featureConfig.showPerformanceImperial) {
      return {
        maxSpeed: this.technicalData?.performanceImperial?.maxSpeed,

        acceleration: this.technicalData?.performanceImperial?.acceleration60mph,
      };
    }

    return {
      maxSpeed: this.technicalData?.performance?.maxSpeed,

      acceleration: this.technicalData?.performance?.acceleration100kph,
    };
  }
}
