<div
  [formGroup]="formGroup"
  fxLayout="row"
  fxLayoutAlign="flex-start center"
  [ngStyle]="getStyle()"
  class="inline-editable-container"
>
  <span class="displayText" *ngIf="!inEditMode">{{ editField.value | numberLocale }}</span>
  <vpfa-inline-loader [displayContent]="!isProcessing" [isLoading]="isProcessing">
    <ng-container *ngIf="numericValue || numericValue === 0">
      <vpfa-number-field
        [heightCompensationPx]="-8"
        [displayErrors]="inEditMode"
        [errorMessages]="fieldErrors"
        [errorsInPopover]="errorsInPopover"
        [parentFormGroup]="formGroup"
        [fcName]="fcName"
        [isCurrency]="isCurrency"
        [fieldWidth]="fieldWidth"
        *ngIf="inEditMode"
        (submitValue)="onSubmitEdit()"
        (fieldBlur)="onBlur()"
        [setFocusAfterInit]="true"
        (keydown.esc)="onKeyDownEscape()"
      ></vpfa-number-field>
    </ng-container>
  </vpfa-inline-loader>
  <span
    *ngIf="!(inEditMode || isProcessing)"
    (click)="onToggleEditMode()"
    class="edit-icon"
    [nzTheme]="'twotone'"
    nz-icon
    nzType="edit"
  ></span>
</div>
