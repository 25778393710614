import { from, zip } from 'rxjs';
import { pluck, map } from 'rxjs/operators';
import { registerLocaleData } from '@angular/common';

export function dynamicLocaleRegister(localeId: string) {
  return from(
    import(`@/../@angular/common/locales/${localeId}.mjs`).then(localModule => {
      registerLocaleData(localModule.default);
    })
  );

  // TODO: AG-UPDATE - are extras import required?
  // const localeModule = from(import(`@angular/common/locales/${localeId}.mjs`)).pipe(pluck('default'));
  // const localeExtraModule = from(import(`@angular/common/locales/extra/${localeId}`));
  // return zip(localeModule, localeExtraModule).pipe(
  //   map(([locale, localeExtra]) => {
  //     registerLocaleData(locale, localeId, localeExtra);
  //     return null;
  //   })
  // );
}
