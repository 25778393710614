import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ProvenanceCheckFacade } from '@vpfa/dealer/case/data';
import { isNil } from 'lodash';
import { map, takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CO2EmissionRangeDto } from '@vpfa/rest-api/valuation';

export type EmissionColour = 'green' | 'light-green' | 'yellow' | 'brown' | 'orange' | 'red';

@Component({
  selector: 'vpfa-provenance-check-section-environmental',
  templateUrl: './provenance-check-section-environmental.component.html',
  styleUrls: ['./provenance-check-section-environmental.component.scss'],
})
export class ProvenanceCheckSectionEnvironmentalComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();
  readonly colours: EmissionColour[] = ['green', 'green', 'light-green', 'yellow', 'brown', 'orange', 'red'];
  readonly arrowsMaxWidthsPx: number[] = [100, 120, 140, 160, 180, 200, 220];
  readonly flexWidths: number[] = [36, 40, 44, 48, 52, 56, 60];

  @Input() co2Emission: number;
  @Input() isMobile = false;
  co2EmissionRanges: CO2EmissionRangeDto[][] = [];
  co2EmissionRangesLoading$ = this.provenanceCheckFacade.cO2EmissionRangesLoading$;

  constructor(private provenanceCheckFacade: ProvenanceCheckFacade) {
    this.provenanceCheckFacade.loadCO2EmissionRanges();
  }

  ngOnInit(): void {
    this.provenanceCheckFacade.cO2EmissionRanges$
      .pipe(
        filter((ranges: CO2EmissionRangeDto[]) => !isNil(ranges)),
        map((ranges: CO2EmissionRangeDto[]) =>
          [...ranges].sort((a, b) => a.emissionClass?.localeCompare(b.emissionClass))
        ),
        takeUntil(this._destroy$)
      )
      .subscribe((ranges: CO2EmissionRangeDto[]) => {
        if (!isNil(ranges)) {
          this.mapRangesForChart(ranges);
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  mapRangesForChart(ranges: CO2EmissionRangeDto[]) {
    let mappedResponse = [[ranges[0]]];
    for (let i = 1; i < ranges.length; i = i + 2) {
      mappedResponse.push([ranges[i], ranges[i + 1]]);
    }
    this.co2EmissionRanges = mappedResponse;
  }

  isCO2Emission() {
    return !isNil(this.co2Emission);
  }
}
