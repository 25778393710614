import { getCurrentDate } from './get-local-date';
import { DateTime } from 'luxon';

export function isDateAfterToday(current: string, timezone: string) {
  const currentDate = DateTime.fromISO(getCurrentDate(timezone)).startOf('day');
  const forecastDate = DateTime.fromISO(current).startOf('day');

  return currentDate < forecastDate;
}

export function isDateBeforeToday(current: string, timezone: string) {
  const currentDate = DateTime.fromISO(getCurrentDate(timezone)).startOf('day');
  const forecastDate = DateTime.fromISO(current).startOf('day');

  return currentDate > forecastDate;
}

export function isSameDate(pickedDate: string, forecastedDate: string) {
  const pickDate = DateTime.fromISO(pickedDate).startOf('day');
  const forecastDate = DateTime.fromISO(forecastedDate).startOf('day');
  return pickDate === forecastDate;
}
