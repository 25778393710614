<vpfa-modal
  className="full-height-modal"
  modalName="Fullscreen Gallery"
  [showBackdrop]="true"
  [isVisible]="open"
  [isClosable]="false"
  [keyboard]="false"
  [maskClosable]="false"
  (closeAction)="closeModal.emit()"
>
  <vpfa-modal-content>
    <nz-spin [nzSpinning]="loading">
      <button class="ant-modal-close" nz-button nzType="link" (click)="closeModal.emit()">
        <i nz-icon nzType="close-circle" nzTheme="fill"></i>
      </button>
      <div class="main-container">
        <h1>{{ 'common.image' | translate }}</h1>
        <vpfa-sliding-gallery [images]="orderedImages" [show]="open"></vpfa-sliding-gallery>
        <vpfa-action-button [outline]="true" [border]="false" (click)="closeModal.emit()">
          {{ 'common.close' | translate }}
        </vpfa-action-button>
      </div>
    </nz-spin>
  </vpfa-modal-content>
</vpfa-modal>
