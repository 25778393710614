import { Pipe, PipeTransform } from '@angular/core';
import { camelCase, isNil } from 'lodash';

@Pipe({
  name: 'enumTranslation',
  pure: true,
})
export class EnumTranslationPipe implements PipeTransform {
  transform(value: any, [valueEnum, namespace]: [any, string] = [null, null]) {
    const status = !isNil(value) && valueEnum[value] ? camelCase(valueEnum[value]) : 'noStatus';
    return `${namespace}.${status}`;
  }
}
