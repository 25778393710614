import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BaseAddRemoveCustomerComponent } from '../base-add-remove-customer.component';

@Component({
  selector: 'vpfa-add-remove-one-customer',
  templateUrl: './add-remove-one-customer.component.html',
  styleUrls: ['../base-add-remove-customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddRemoveOneCustomerComponent extends BaseAddRemoveCustomerComponent {
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() fc: UntypedFormControl;
  @Input() allowClear = false;
}
