import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { TextFieldComponent } from '@vpfa/ui-kit';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
  selector: 'vpfa-text-input-editor',
  templateUrl: './text-input-editor.component.html',
  styleUrls: ['./text-input-editor.component.scss'],
})
export class TextInputEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  @ViewChild('input') fieldComponent: TextFieldComponent;
  formGroup: UntypedFormGroup;
  control: UntypedFormControl;
  private params: ICellEditorParams;

  constructor() {
    this.control = new UntypedFormControl(null);
    this.formGroup = new UntypedFormGroup({
      field: this.control,
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.fieldComponent.inputElementRef) {
        this.fieldComponent.inputElementRef.nativeElement.focus();
      }
    });
  }

  agInit(params: ICellEditorParams): void {
    if (params) {
      this.params = params;
      this.initValue(params.value);
    }
  }

  onBlur() {
    this.params.stopEditing();
  }

  initValue(value: string) {
    this.formGroup.reset({ field: value });
  }

  getValue(): string {
    return this.control.value;
  }
}
