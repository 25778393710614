import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullScreenGalleryModalComponent } from './components/full-screen-gallery-modal/full-screen-gallery-modal.component';
import { UiKitModule } from '@vpfa/ui-kit';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { UiKitSlidingGalleryModule } from '@vpfa/ui-kit/sliding-gallery';
import { ModalsModule } from '@vpfa/modals';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@NgModule({
  imports: [
    CommonModule,
    UiKitModule,
    TranslateModule,
    NzButtonModule,
    NzIconModule,
    NzSpinModule,
    FlexLayoutModule,
    UiKitSlidingGalleryModule,
    ModalsModule,
  ],
  declarations: [FullScreenGalleryModalComponent],
  exports: [FullScreenGalleryModalComponent],
})
export class UiKitFullScreenGalleryModule {}
