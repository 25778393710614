import { Component, Input } from '@angular/core';
import { isNil, inRange } from 'lodash';
import { CO2EmissionRangeDto } from '@vpfa/rest-api/valuation';
import { EmissionColour } from '../provenance-check-section-environmental.component';

@Component({
  selector: 'vpfa-emission-class-row',
  templateUrl: './emission-class-row.component.html',
  styleUrls: ['./emission-class-row.component.scss'],
})
export class EmissionClassRowComponent {
  @Input() ranges: CO2EmissionRangeDto[] = [];
  @Input() co2Emission: number;
  @Input() colour: EmissionColour;
  @Input() maxWidth: number;
  @Input() flexWidth: number;

  co2EmissionClass: string;

  emissionInRange(): boolean {
    let bottomRange;
    let topRange;
    let roundedEmission = Math.round(this.co2Emission);
    let range = this.ranges.find(range => {
      bottomRange = range.range[0];
      topRange = range.range[1];

      if (!isNil(bottomRange)) {
        if (isNil(topRange)) {
          return roundedEmission >= bottomRange;
        } else {
          return inRange(roundedEmission, bottomRange, topRange + 1);
        }
      } else {
        return roundedEmission <= topRange;
      }
    });
    this.co2EmissionClass = range?.emissionClass;
    return !isNil(range);
  }
}
