import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { LocaleFacade } from '@vpfa/locale';
import { ProfileFacade } from '@vpfa/profile/data';
import {
  UpdateExperianCredentialsCommand,
  UpdateProfileCommand,
  UpdateUserDefaultLanguageCommand,
} from '@vpfa/rest-api/admin';
import { LanguageEnum } from '@vpfa/shared/translate';
import { take } from 'rxjs/operators';
import { ChangePasswordDto } from '../models/change-password-command-dto';
import { ChangePasswordError, fromUserProfileActions, LoadExperianCredentials, UpdateExperianCredentials, UserProfileActionTypes } from './user-profile.actions';
import { userProfileQuery } from './user-profile.selectors';

@Injectable()
export class UserProfileFacade {
  isProcessingForm$ = this.store.select(userProfileQuery.getIsProcessingForm);
  hasFormProcessingError$ = this.store.select(userProfileQuery.getHasFormProcessingError);
  isChangingPassword$ = this.store.select(userProfileQuery.getIsChangingPassword);
  isPasswordModalOpen$ = this.store.select(userProfileQuery.getIsPasswordModalOpen);
  isUserProfileUpdating$ = this.store.select(userProfileQuery.getIsUserProfileUpdating);
  passwordCompatible$ = this.store.select(userProfileQuery.getPasswordCompatible);
  experianCredentials$ = this.store.pipe(select(userProfileQuery.getExperianCredentials));
  isExperianCredentialsProcessing$ = this.store.pipe(select(userProfileQuery.isExperianCredentialsProcessing));

  constructor(private store: Store<any>, private localeFacade: LocaleFacade, private profileFacade: ProfileFacade, private actions$: Actions) {}

  updateUiLanguage(updateUserSettingsCommand: UpdateUserDefaultLanguageCommand) {
    this.store.dispatch(new fromUserProfileActions.UpdateUserUILanguage(updateUserSettingsCommand));
    this.profileFacade.userContext$.pipe(take(1)).subscribe(context => {
      this.localeFacade.checkTranslateFile(updateUserSettingsCommand.uiLanguage as LanguageEnum, context.countryCode);
    });
  }
  updateContentLanguage(updateUserSettingsCommand: UpdateUserDefaultLanguageCommand) {
    this.store.dispatch(new fromUserProfileActions.UpdateUserContentLanguage(updateUserSettingsCommand));
  }

  updateUserLanguageSettings(updateUserSettingsCommand: UpdateUserDefaultLanguageCommand) {
    this.store.dispatch(new fromUserProfileActions.UpdateUserDefaultLanguage(updateUserSettingsCommand));
  }

  changePassword(command: ChangePasswordDto) {
    this.store.dispatch(new fromUserProfileActions.ChangePassword(command));
    return this.actions$.pipe(ofType<ChangePasswordError>(UserProfileActionTypes.ChangePasswordError));
  }

  resetFormStatus() {
    this.store.dispatch(new fromUserProfileActions.ResetFormStatus());
  }

  openChangePasswordModal() {
    this.store.dispatch(new fromUserProfileActions.OpenPasswordModal());
  }

  closeChangePasswordModal() {
    this.store.dispatch(new fromUserProfileActions.ClosePasswordModal());
  }

  updateUserProfile(command: UpdateProfileCommand) {
    this.store.dispatch(new fromUserProfileActions.UpdateUserProfile(command));
  }

  passwordCompatibility(compatible: boolean) {
    this.store.dispatch(new fromUserProfileActions.PasswordCompatibility(compatible));
  }

  loadExperianCredentials() {
    this.store.dispatch(new LoadExperianCredentials());
  }

  updateExperianCredentials(command: UpdateExperianCredentialsCommand) {
    this.store.dispatch(new UpdateExperianCredentials(command));
  }
}
