<nz-spin [nzSpinning]="hasRelatedCaseLoading$ | async">
  <nz-alert
    *ngIf="hasRelatedCase$ | async"
    nzType="warning"
    [nzMessage]="'customerList.deletedCustomerHasRelatedCase' | translate"
  >
  </nz-alert>

  <p>
    {{ 'customerList.confirmDeleteCustomerText' | translate }}
  </p>
</nz-spin>
