import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { pdfTemplateModules } from '../../utils/pdf-template-modules';
import { OpenModulesModalCommand } from '@vpfa/print-templates/data';
import { TemplateModulesModel } from '../../models/template-modules.model';

@Component({
  selector: 'vpfa-change-module-modal',
  templateUrl: './change-module-modal.component.html',
  styleUrls: ['./change-module-modal.component.scss'],
})
export class ChangeModuleModalComponent implements OnInit {
  @Input() set currentModules(currentModules: OpenModulesModalCommand) {
    if (currentModules) {
      this.id = currentModules.id;
      this.name = currentModules.name;

      this.moduleArray.controls.forEach((templateModule, index) => {
        if (currentModules.currentModules.includes(index)) {
          templateModule.patchValue({
            templateModuleEnum: templateModule.get('templateModuleEnum').value,
            available: true,
          });
        }
      });
    }
  }
  @Input() loading = false;
  @Input() show = false;

  @Output() closeAction = new EventEmitter();
  @Output() templateDataChange = new EventEmitter<TemplateModulesModel>();

  modulesForm: UntypedFormGroup;
  moduleArray: UntypedFormArray;
  id: string;
  name: string;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.modulesForm = this.fb.group({
      pdfTemplateModules: this.fb.array([]),
    });

    this.moduleArray = this.modulesForm.controls.pdfTemplateModules as UntypedFormArray;
    this.addModules();
  }

  addModules() {
    pdfTemplateModules.forEach(templateModule =>
      this.moduleArray.push(this.fb.group({ templateModuleEnum: [templateModule], available: false }))
    );
  }

  updateModules() {
    this.templateDataChange.emit({
      id: this.id,
      pdfTemplateModules: this.moduleArray.value,
    });
  }

  close() {
    this.closeAction.emit();
    this.resetModules();
  }

  resetModules() {
    this.moduleArray.controls.forEach((templateModule, index) => {
      templateModule.reset({ templateModuleEnum: pdfTemplateModules[index], available: false });
    });
  }
}
