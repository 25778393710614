import { EquipmentFormulaBlockDto } from '@vpfa/rest-api/valuation';
import { VehicleEquipmentFormulaStep } from '../../../models/vehicle-equipment-formula-step';

export function mapApiStepToFormulaWizardStep(step: EquipmentFormulaBlockDto): VehicleEquipmentFormulaStep {
  return {
    ...step,
    mainChanges: [],
    sideChanges: [],
    errors: null,
  };
}
