import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VehicleOverviewData } from '../../models/vehicle-overview-data.model';
import { Validators } from '@angular/forms';
import { CaseStatus, DateRangeThresholdDto } from '@vpfa/rest-api/valuation';
import { firstRegistrationRange } from '@vpfa/ui-kit/uikit-validation';
import { MILEAGE_MAX, MILEAGE_MIN } from '@vpfa/shared/validators';
import { isNil } from 'lodash';

@Component({
  selector: 'vpfa-vehicle-overview-data',
  templateUrl: './vehicle-overview-data.component.html',
  styleUrls: ['./vehicle-overview-data.component.scss'],
})
export class VehicleOverviewDataComponent {
  @Output() updateMileageAdjustment = new EventEmitter<number>();
  @Output() updateRegistrationDate = new EventEmitter<Date>();
  @Input() overviewData: VehicleOverviewData;
  @Input() caseStatus: CaseStatus;
  @Input() set hideDoors(hide: boolean) {
    this.bodyTypeDoorsLabel = hide ? 'vehicleOverviewData.bodyType' : 'vehicleOverviewData.bodyTypeDoors';
    this.bodyTypeDoorsValue = this.showBodyTypeAndDoors(hide);
  }
  @Input() isUpdatingMileage = false;
  @Input() isUpdatingRegDate = false;
  @Input() timezone: string;
  @Input() countryDateRangeThreshold: DateRangeThresholdDto;

  bodyTypeDoorsLabel: string;
  bodyTypeDoorsValue: string;
  mileageValidators = [Validators.required, Validators.min(MILEAGE_MIN), Validators.max(MILEAGE_MAX)];
  elementPerRow = 4;
  elementsGap = '10px';
  elementSize = `0 1 calc(${100 / this.elementPerRow}% - ${this.elementsGap})`;

  disabledDate = (date: string) =>
    firstRegistrationRange(
      date,
      this.overviewData.prodbegin,
      this.overviewData.prodend,
      this.countryDateRangeThreshold,
      this.timezone
    );

  disableDatePicker() {
    return this.caseStatus === CaseStatus.Sold;
  }

  private showBodyTypeAndDoors(hideDoors: boolean) {
    if (!isNil(this.overviewData.bodyType)) {
      return hideDoors || isNil(this.overviewData.numberDoors)
        ? `${this.overviewData.bodyType}`
        : `${this.overviewData.bodyType}, ${this.overviewData.numberDoors}`;
    } else {
      return '';
    }
  }
}
