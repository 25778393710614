<vpfa-field-container
  [control]="formControl"
  [errorMessages]="errorMessages"
  [displayErrors]="shouldDisplayErrors && !mobileErrors"
  [heightCompensationPx]="6"
>
  <vpfa-mobile-field-validation-container
    *ngIf="mobileErrors; else input"
    [errorMessages]="errorMessages"
    [validationControl]="formControl"
  >
    <ng-container [ngTemplateOutlet]="input"></ng-container>
  </vpfa-mobile-field-validation-container>
</vpfa-field-container>

<ng-template #input>
  <nz-input-group [nzSuffix]="suffixAddButton">
    <input
      (focus)="onFocus()"
      (blur)="onBlur()"
      nz-input
      [formControl]="formControl"
      type="text"
      (keydown.enter)="addByEnter($event)"
      [placeholder]="placeholder"
      class="input-with-button"
    />
  </nz-input-group>
</ng-template>

<ng-template #suffixAddButton>
  <button
    [disabled]="formControl.pristine || formControl.invalid"
    nz-button
    nzType="primary"
    type="button"
    (click)="add()"
    class="add-element-button"
    nzSearch
  >
    <i class="plus-icon" nz-icon [nzType]="iconType"></i>
  </button>
</ng-template>

<div class="text-field-list" *ngIf="showList()">
  <nz-list [nzDataSource]="list" nzBordered nzSize="small" [nzRenderItem]="smallItem" [nzNoResult]="noResult">
    <ng-template #smallItem let-item>
      <li nz-list-item [nzActions]="[opAction]" [nzContent]="item" nzNoFlex class="text-field-list-element"></li>
      <ng-template #opAction
        ><a (click)="delete(item)"><i class="delete-icon" nz-icon nzType="delete" nzTheme="fill"></i></a
      ></ng-template>
    </ng-template>
    <ng-template #noResult
      ><p style="margin: 0;">{{ noItemsText }}</p></ng-template
    >
  </nz-list>
</div>
