/**
 * Vehicle Valuation Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { CommandHandlerResponse } from '../model/commandHandlerResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class BusinessImagesService {

    protected basePath = 'https://localhost';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Lazy check AmsAction:POST/api/BusinessImages/AddStandardImage AmsResource:{country}/{business}/_* 
     * @param aggregateRootId 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addImages(aggregateRootId?: string, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public addImages(aggregateRootId?: string, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public addImages(aggregateRootId?: string, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public addImages(aggregateRootId?: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any) };
        let useForm = true;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (aggregateRootId !== undefined) {
            formParams = formParams.append('AggregateRootId', <any>aggregateRootId) || formParams;
        }
        if (file !== undefined) {
            formParams = formParams.append('File', <any>file) || formParams;
        }

        return this.httpClient.post<CommandHandlerResponse>(`${this.basePath}/api/BusinessImages/AddStandardImage`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:POST/api/BusinessImages/AddPromotionalImages AmsResource:{country}/{business}/_* 
     * @param aggregateRootId 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addPromotionalImages(aggregateRootId?: string, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public addPromotionalImages(aggregateRootId?: string, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public addPromotionalImages(aggregateRootId?: string, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public addPromotionalImages(aggregateRootId?: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any) };
        let useForm = true;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (aggregateRootId !== undefined) {
            formParams = formParams.append('AggregateRootId', <any>aggregateRootId) || formParams;
        }
        if (file !== undefined) {
            formParams = formParams.append('File', <any>file) || formParams;
        }

        return this.httpClient.post<CommandHandlerResponse>(`${this.basePath}/api/BusinessImages/AddPromotionalImages`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:DELETE/api/BusinessImages/DeletePromotionalImage/{businessId}/filekey/{filekey} AmsResource:{country}/{business}/_* 
     * @param businessId 
     * @param filekey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePromotionalImage(businessId: string, filekey: string, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public deletePromotionalImage(businessId: string, filekey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public deletePromotionalImage(businessId: string, filekey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public deletePromotionalImage(businessId: string, filekey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling deletePromotionalImage.');
        }

        if (filekey === null || filekey === undefined) {
            throw new Error('Required parameter filekey was null or undefined when calling deletePromotionalImage.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<CommandHandlerResponse>(`${this.basePath}/api/BusinessImages/DeletePromotionalImage/${encodeURIComponent(String(businessId))}/filekey/${encodeURIComponent(String(filekey))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:DELETE/api/BusinessImages/DeleteStandardImage/{businessId} AmsResource:{country}/{business}/_* 
     * @param businessId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteStandardImage(businessId: string, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public deleteStandardImage(businessId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public deleteStandardImage(businessId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public deleteStandardImage(businessId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling deleteStandardImage.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<CommandHandlerResponse>(`${this.basePath}/api/BusinessImages/DeleteStandardImage/${encodeURIComponent(String(businessId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
