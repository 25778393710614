import { trigger, transition, style, animate } from '@angular/animations';
import { AnimationCurves } from 'ng-zorro-antd/core/animation';

export function collapseExpand(time = 150) {
  return trigger('collapseExpand', [
    transition(':enter', [style({ height: 0, opacity: 0 }), animate(`${time}ms ${AnimationCurves.EASE_IN_OUT}`)]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate(`${time}ms ${AnimationCurves.EASE_IN_OUT}`, style({ height: 0, opacity: 0 }))
    ])
  ]);
}
