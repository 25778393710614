/**
 * Generic Transform Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { MappingConfigDto } from '../model/mappingConfigDto';
import { MappingConfigListItemDto } from '../model/mappingConfigListItemDto';
import { MappingName } from '../model/mappingName';
import { VPError } from '../model/vPError';
import { VehicleTypeListItemDto } from '../model/vehicleTypeListItemDto';
import { VehicleTypeMappingListItemDto } from '../model/vehicleTypeMappingListItemDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MappingViewService {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * AmsAction:GET/api/MappingView/{mappingId} AmsAction:CanAdministerMappings AmsResource:* 
     * @param mappingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public get(mappingId: string, observe?: 'body', reportProgress?: boolean): Observable<MappingConfigDto>;
    public get(mappingId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MappingConfigDto>>;
    public get(mappingId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MappingConfigDto>>;
    public get(mappingId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mappingId === null || mappingId === undefined) {
            throw new Error('Required parameter mappingId was null or undefined when calling get.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MappingConfigDto>(`${this.basePath}/api/MappingView/${encodeURIComponent(String(mappingId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/MappingView/List AmsAction:CanAdministerMappings AmsResource:* 
     * @param portal 
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getList(portal?: string, type?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MappingConfigListItemDto>>;
    public getList(portal?: string, type?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MappingConfigListItemDto>>>;
    public getList(portal?: string, type?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MappingConfigListItemDto>>>;
    public getList(portal?: string, type?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (portal !== undefined && portal !== null) {
          queryParameters = queryParameters.set('portal', <any>portal);
        }
        if (type !== undefined && type !== null) {
          queryParameters = queryParameters.set('type', <any>type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MappingConfigListItemDto>>(`${this.basePath}/api/MappingView/List`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/MappingView/makeModelsLastUpdate/{market} AmsAction:CanAdministerMappings AmsResource:* 
     * @param market 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMakeModelLastRefresh(market: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getMakeModelLastRefresh(market: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getMakeModelLastRefresh(market: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getMakeModelLastRefresh(market: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (market === null || market === undefined) {
            throw new Error('Required parameter market was null or undefined when calling getMakeModelLastRefresh.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/api/MappingView/makeModelsLastUpdate/${encodeURIComponent(String(market))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/MappingView/makeModelMapping/{mappingId}/{market} AmsAction:CanAdministerMappings AmsResource:* 
     * @param mappingId 
     * @param market 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMakeModelMapping(mappingId: string, market: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VehicleTypeMappingListItemDto>>;
    public getMakeModelMapping(mappingId: string, market: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VehicleTypeMappingListItemDto>>>;
    public getMakeModelMapping(mappingId: string, market: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VehicleTypeMappingListItemDto>>>;
    public getMakeModelMapping(mappingId: string, market: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mappingId === null || mappingId === undefined) {
            throw new Error('Required parameter mappingId was null or undefined when calling getMakeModelMapping.');
        }

        if (market === null || market === undefined) {
            throw new Error('Required parameter market was null or undefined when calling getMakeModelMapping.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<VehicleTypeMappingListItemDto>>(`${this.basePath}/api/MappingView/makeModelMapping/${encodeURIComponent(String(mappingId))}/${encodeURIComponent(String(market))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/MappingView/makeModels/{market} AmsAction:CanAdministerMappings AmsResource:* 
     * @param market 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMakeModels(market: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VehicleTypeListItemDto>>;
    public getMakeModels(market: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VehicleTypeListItemDto>>>;
    public getMakeModels(market: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VehicleTypeListItemDto>>>;
    public getMakeModels(market: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (market === null || market === undefined) {
            throw new Error('Required parameter market was null or undefined when calling getMakeModels.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<VehicleTypeListItemDto>>(`${this.basePath}/api/MappingView/makeModels/${encodeURIComponent(String(market))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/MappingView/mappingKeyNames/{mappingId} AmsAction:CanAdministerMappings AmsResource:* 
     * @param mappingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMappingKeyNames(mappingId: string, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: Array<MappingName>; }>;
    public getMappingKeyNames(mappingId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: Array<MappingName>; }>>;
    public getMappingKeyNames(mappingId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: Array<MappingName>; }>>;
    public getMappingKeyNames(mappingId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mappingId === null || mappingId === undefined) {
            throw new Error('Required parameter mappingId was null or undefined when calling getMappingKeyNames.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<{ [key: string]: Array<MappingName>; }>(`${this.basePath}/api/MappingView/mappingKeyNames/${encodeURIComponent(String(mappingId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/MappingView/mappingValueNames/{mappingId} AmsAction:CanAdministerMappings AmsResource:* 
     * @param mappingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMappingValueNames(mappingId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MappingName>>;
    public getMappingValueNames(mappingId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MappingName>>>;
    public getMappingValueNames(mappingId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MappingName>>>;
    public getMappingValueNames(mappingId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mappingId === null || mappingId === undefined) {
            throw new Error('Required parameter mappingId was null or undefined when calling getMappingValueNames.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MappingName>>(`${this.basePath}/api/MappingView/mappingValueNames/${encodeURIComponent(String(mappingId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
