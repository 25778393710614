export const KB = 1024;
// x KB
export enum UploadSizeLimit {
  AdminFile = 50 * 1024 * KB,
  PerStandardImage = 50 * 1024 * KB,
  PerImage = 2048 * KB,
  PerSingleDocument = 10 * 1024 * KB,
  PerBusiness = 50 * 1024 * KB,
  PerCaseGallery = 50 * 1024 * KB,
}

export enum FileUploadType {
  CaseGalleryImages = 'CaseGalleryImages',
  Logo = 'Logo',
  PromoImages = 'PromoImages',
  Document = 'Document',
  Csv = 'Csv',
  StandardImage = 'StandardImage',
  Image = 'Image',
  Jpg = 'Jpg',
  Json = 'Json',
  Pdf = 'Pdf',
}

export const ALLOWED_EXCEL_MIMES = ['application/excel'];
export const ALLOWED_IMAGE_MIMES = ['image/jpeg', 'image/png'];
export const ALLOWED_JPG_MIMES = ['image/jpeg'];
export const ALLOWED_JSON_MIMES = ['application/json'];
export const ALLOWED_PDF_MIMES = ['application/pdf'];
export const ALLOWED_CSV_MIMES = ['text/csv'];
export const ALLOWED_DOCUMENT_MIMES = [
  'text/plain', // .txt
  'image/jpeg', // .jpeg, .jpg
  'image/pjpeg', // .jpeg, .jpg
  'image/png', // .png
  'image/tiff', // .tiff .tif
  'image/x-tiff', // .tiff .tif
  'application/pdf', // .pdf
  'application/msword', // .doc
  'application/doc', // .doc
  'application/ms-doc', //.doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/excel', // .xls
  'application/vnd.ms-excel', //xls
  'application/x-excel', // .xls
  'application/x-msexcel', // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
];

export const ALLOWED_IMAGE_EXTENSIONS = ['.jpg', '.jpeg', '.png'];

export const ALLOWED_DOCUMENT_EXTENSIONS = [
  ...ALLOWED_IMAGE_EXTENSIONS,
  '.xls',
  '.xlsx',
  '.txt',
  '.doc',
  '.docx',
  '.pdf',
  '.tiff',
  '.tif',
];

export const ALLOWED_JSON_EXTENSIONS = ['.json'];

export type FileItemList = Array<{ fileKey: string; file: string; mimeType?: string }>;
