import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { KEYS } from '../return-report-modal/get-return-report-definition';

@Component({
  selector: 'vpfa-return-report-form',
  templateUrl: './return-report-form.component.html',
  styleUrls: ['./return-report-form.component.scss'],
})
export class ReturnReportFormComponent {
  @Input() form: UntypedFormGroup;
  keys = KEYS;

  toggle(key: string) {
    if (this.form) {
      const field = this.form.get(key);
      if (field && !field.disabled) {
        field.setValue(!field.value);
      }
    }
  }
}
