import { Component, Input, OnInit } from '@angular/core';
import { LiveRetailInsightsDto } from '@vpfa/rest-api/valuation';
import { isNil, isEmpty } from 'lodash';
import { ProfileFacade } from '@vpfa/profile/data';
import { filter, take } from 'rxjs/operators';
import { LocaleFacade } from '@vpfa/locale';
import { Features } from '@vpfa/rest-api/admin';

@Component({ template: '' })
export class VehicleRetailMarketStatsComponent implements OnInit {
  @Input() retailMarketDetails: LiveRetailInsightsDto;
  @Input() isInClientMode = false;
  @Input() notAvailable: boolean;
  @Input() isLoading: boolean;
  mileageUnit: string;

  showSellingSpotPrice$ = this.localeFacade.isFeatureConfigurationEnabled(Features.ShowSellingSpotPrice);

  constructor(private profileFacade: ProfileFacade, private localeFacade: LocaleFacade) {}

  ngOnInit(): void {
    this.profileFacade.userContext$
      .pipe(
        filter(context => !isNil(context)),
        take(1)
      )
      .subscribe(context => {
        this.mileageUnit = ` (${context.mileageUnit})`;
      });
  }
}
