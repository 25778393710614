export function sanitizeImages(images: File[]): File[] {
  // @ts-ignore: sanitizing array of blobs to reduce size in StoreDevtools
  return `<<IMAGES BLOBS: ${images.length}>>`;
}

export function sanitizeImage(): File | string {
  // @ts-ignore: sanitizing array of blobs to reduce size in StoreDevtools
  return `<<IMAGE BLOB>>`;
}

export function sanitizeFiles(files: File[]): File[] {
  // @ts-ignore: sanitizing array of blobs to reduce size in StoreDevtools
  return `<<FILES BLOBS: ${files.length}>>`;
}

export function sanitizeFile(): File | string {
  // @ts-ignore: sanitizing array of blobs to reduce size in StoreDevtools
  return `<<FILE BLOB>>`;
}
