<div fxLayout="row" fxLayoutAlign="space-between center">
  <vpfa-right-arrow-box
    [fxFlex]="flexWidth"
    [maxWidth]="maxWidth"
    [ranges]="ranges"
    [colour]="colour"
  ></vpfa-right-arrow-box>
  <vpfa-left-arrow-box
    *ngIf="emissionInRange()"
    [fxFlex]="40"
    [co2EmissionClass]="co2EmissionClass"
    [co2Emission]="co2Emission"
  ></vpfa-left-arrow-box>
</div>
