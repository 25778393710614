import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'vpfa-mobile-infinite-list',
  templateUrl: './mobile-infinite-list.component.html',
  styleUrls: ['./mobile-infinite-list.component.scss'],
})
export class MobileInfiniteListComponent {
  @Input() endElement = 9;
  @Input() startElement = 0;
  @Input() scrollStep = 5;
  @Input() set list(list: any[]) {
    this.filteredList = list;
    this.updateList();
  }
  @Input() throttle = 4;
  @Input() elementTemplate: TemplateRef<any>;
  @Input() isLoading = false;
  @Input() displayEmptyIfNoData = true;

  elements: any[];
  private filteredList: any[];

  onScroll() {
    this.endElement = Number(this.endElement) + Number(this.scrollStep);
    this.updateList();
  }

  private updateList() {
    this.elements = this.filteredList
      ? this.filteredList.filter((_, index) => index >= this.startElement && index <= this.endElement)
      : [];
  }
}
