import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SoldUniqueDataDto } from '@vpfa/rest-api/valuation';
import { isNil } from 'lodash';
import { firstDateIsAfterOrEqualSecondDateValidator, plateNoLengthValidator } from '@vpfa/shared/validators';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

export const SoldUniqueDataFromName = 'soldUniqueData';

@Component({
  selector: 'vpfa-unique-data-sold',
  templateUrl: './unique-data-sold.component.html',
  styleUrls: ['./unique-data-sold.component.scss'],
})
export class UniqueDataSoldComponent {
  maxLengthErrorMsgList: ReadonlyArray<{ error: string; errorMsg: string }> = [
    {
      error: 'plateNoLength',
      errorMsg: this.translateService.instant('search.plateNoLengthError'),
    },
  ];

  saleDateErrorMsgList: ReadonlyArray<{ error: string; errorMsg: string }> = [
    {
      error: 'dateIsBefore',
      errorMsg: this.translateService.instant('uniqueData.validationErrors.saleDateIsBeforeTradeInDate'),
    },
  ];

  @Input() dateOfAcceptance: string;

  @Input()
  set parentFormGroup(parentFormGroup: UntypedFormGroup) {
    if (parentFormGroup) {
      parentFormGroup.addControl(SoldUniqueDataFromName, this.formGroup);
    }
  }

  @Input() set initialData(initialData: SoldUniqueDataDto) {
    if (isNil(initialData)) return;
    this.resetFormToInitial(initialData);
  }

  formGroup = new UntypedFormGroup({
    newNumberPlate: new UntypedFormControl(null, [plateNoLengthValidator]),
    saleDate: new UntypedFormControl(
      null,
      [Validators.required],
      [
        control => {
          return of(control.value).pipe(
            map(saleDate => firstDateIsAfterOrEqualSecondDateValidator(saleDate, this.dateOfAcceptance))
          );
        },
      ]
    ),
  });

  disabledSoldDate = (current: string) => {
    return DateTime.fromISO(current) < DateTime.fromISO(this.dateOfAcceptance);
  };

  constructor(private translateService: TranslateService) {}

  private resetFormToInitial(initialData: SoldUniqueDataDto) {
    this.formGroup.reset({
      newNumberPlate: initialData.newNumberPlate,
      saleDate: initialData.saleDate,
    });
  }
}
