import { EquipmentDto } from '@vpfa/rest-api/valuation';
import { createReducer, on, Action } from '@ngrx/store';
import * as formulaWizardActions from './formula-wizard.actions';
import { produce } from 'immer';
import { VehicleEquipmentFormula } from '../../models/vehicle-equipment-formula';
import { updateFormulas } from './utils/update-formulas';
import { getCurrentStep } from './utils/get-current-step';
import { hasNextStepInLevel } from './utils/has-next-step-in-level';
import { getCurrentFormula } from './utils/get-current-formula';
import { stepsToFormulas } from './utils/steps-to-formulas';
import { hasNextFormula } from './utils/has-next-formula';

export interface FormulaWizardState {
  caseId: string;
  vehicleId: string;
  formulas: VehicleEquipmentFormula[];
  autoResolvedFormulasMainSoaCodes: string[];
  formulaIndex: number;

  /**
   * Current state of equipment after all decisions made by the user in `formulas`
   */
  tempEquipmentList: {
    loading: boolean;
    list: EquipmentDto[];
  };
  saving: boolean;
  closed: boolean;
  validating: boolean;
}

export const initialState: FormulaWizardState = null;

export const formulaWizardReducer = createReducer<FormulaWizardState>(
  initialState,
  on(formulaWizardActions.startFormulaWizard, (state, action) => ({
    caseId: action.payload.caseId,
    vehicleId: action.payload.vehicleId,
    tempEquipmentList: {
      loading: false,
      list: [],
    },
    saving: false,
    closed: false,
    validating: false,
    formulas: stepsToFormulas(action.payload.steps, action.payload.mainEquipmentSoaCode),
    autoResolvedFormulasMainSoaCodes: [],
    formulaIndex: 0,
  })),
  on(formulaWizardActions.clearFormulaWizard, (state, action) => initialState),
  on(formulaWizardActions.loadTempEqpList, (state, action) =>
    produce<FormulaWizardState>(state, newState => {
      newState.tempEquipmentList.loading = true;
    })
  ),
  on(formulaWizardActions.loadTempEqpListSuccess, formulaWizardActions.loadTempEqpListFailed, state =>
    produce<FormulaWizardState>(state, newState => {
      if (state) {
        newState.tempEquipmentList.loading = false;
      }
    })
  ),
  on(formulaWizardActions.loadTempEqpListSuccess, (state, action) =>
    produce<FormulaWizardState>(state, newState => {
      if (state) {
        newState.tempEquipmentList.list = action.equipments;
      }
    })
  ),

  on(formulaWizardActions.updateFormulasStructure, (state, action) =>
    produce<FormulaWizardState>(state, newState => {
      const newFormulas = updateFormulas(state, action);
      newState.formulas = newFormulas;
    })
  ),
  on(formulaWizardActions.nextStep, (state, action) =>
    produce<FormulaWizardState>(state, newState => {
      getCurrentStep(state, newState).mainChanges = action.mainChanges;
      getCurrentStep(state, newState).sideChanges = action.sideChanges;

      // TODO: add context and extend comment
      // if not force then set to null in other case use action errors
      getCurrentStep(state, newState).errors = action.force ? action.errors : null;

      const baseCurrentFormula = getCurrentFormula(state);

      if (hasNextStepInLevel(baseCurrentFormula)) {
        getCurrentFormula(state, newState).currentStepIndex = baseCurrentFormula.currentStepIndex + 1;
      } else if (hasNextFormula(state)) {
        newState.formulaIndex = state.formulaIndex + 1;
      }
    })
  ),
  on(formulaWizardActions.validateStep, (state, action) =>
    produce<FormulaWizardState>(state, newState => {
      newState.validating = true;
    })
  ),
  on(
    formulaWizardActions.validateStepErrors,
    formulaWizardActions.validateStepFailed,
    formulaWizardActions.validateStepSuccess,
    (state, action) =>
      produce<FormulaWizardState>(state, newState => {
        newState.validating = false;
      })
  ),
  on(formulaWizardActions.validateStepErrors, (state, action) =>
    produce<FormulaWizardState>(state, newState => {
      getCurrentStep(state, newState).mainChanges = action.mainChanges;
      getCurrentStep(state, newState).errors = action.errors;
    })
  ),
  on(formulaWizardActions.previousStep, (state, action) =>
    produce<FormulaWizardState>(state, newState => {
      const baseCurrentFormula = getCurrentFormula(state);
      const prevStepIndex = baseCurrentFormula.currentStepIndex - 1;

      getCurrentStep(state, newState).mainChanges = [];
      getCurrentStep(state, newState).sideChanges = [];
      getCurrentStep(state, newState).errors = null;

      // prevStepIndex === -1 means we need to go back to previous formula, otherwise we need to go back to previous step
      if (prevStepIndex >= 0) {
        getCurrentFormula(state, newState).currentStepIndex = prevStepIndex;
      } else {
        newState.formulaIndex = state.formulaIndex - 1;
      }
    })
  ),
  on(formulaWizardActions.saveFormula, (state, _) =>
    produce<FormulaWizardState>(state, newState => {
      newState.saving = true;
      newState.closed = false;
    })
  ),
  on(formulaWizardActions.saveFormulaFailed, (state, _) =>
    produce<FormulaWizardState>(state, newState => {
      newState.saving = false;
      newState.closed = false;
    })
  ),
  on(formulaWizardActions.saveFormulaDataIssue, (state, _) =>
    produce<FormulaWizardState>(state, newState => {
      newState.saving = false;
      newState.closed = true;
    })
  ),
  on(formulaWizardActions.saveFormulaSuccess, (state, _) =>
    produce<FormulaWizardState>(state, newState => {
      newState.saving = false;
      newState.closed = true;
    })
  )
);

export function reducer(state: FormulaWizardState | undefined, action: Action) {
  return formulaWizardReducer(state, action);
}
