import { Observable, BehaviorSubject, Subscription } from 'rxjs';


export function convertObservableToBehaviorSubject<T>(observable: Observable<T>, initValue: T): BehaviorSubject<T> {
  const subject = new BehaviorSubject(initValue);
  let subscriptionBase: Subscription;
  let subscriptionChild: Subscription

  const closeSubscriptions = () => {
    if(subscriptionBase){
      subscriptionBase.unsubscribe();
    }

    if(subscriptionChild){
      subscriptionChild.unsubscribe();
    }
  }

  subscriptionBase = observable.subscribe({
      complete: () => subject.complete(),
      error: x => subject.error(x),
      next: x => subject.next(x)
  });

  subscriptionChild = subject.asObservable().subscribe({
    complete: () => {
      closeSubscriptions();
    }
  })

  return subject;
}