import { DateTime } from 'luxon';

export const firstDateIsAfterOrEqualSecondDateValidator = (firstDate: string, secondDate: string) => {
  if (!firstDate || !secondDate) {
    return null;
  }

  if (DateTime.fromISO(firstDate) < DateTime.fromISO(secondDate)) {
    return { dateIsBefore: true };
  }

  return null;
};
