import { Component, Input } from '@angular/core';
import { EmissionNEDC, I18RangeValueDto, I18Type } from '@vpfa/rest-api/valuation';
import { UntypedFormGroup } from '@angular/forms';
import { SelectOption } from '@vpfa/ui-kit';
import { getI18RangeValue } from '@vpfa/utils';

@Component({
  selector: 'vpfa-full-technical-data-nedc-information',
  templateUrl: './full-technical-data-nedc-information.component.html',
  styleUrls: ['./full-technical-data-nedc-information.component.scss'],
})
export class FullTechnicalDataNedcInformationComponent {
  @Input() technicalDataNedc: EmissionNEDC;
  @Input() originalCO2EfficiencyClass?: I18RangeValueDto;
  @Input() customCO2EfficiencyClass?: I18Type;
  @Input() errors: ReadonlyArray<{ error: string; errorMsg: string }>;
  @Input() co2Errors: ReadonlyArray<{ error: string; errorMsg: string }>;
  @Input() evRangeAndConsumptionErrors: ReadonlyArray<{ error: string; errorMsg: string }>;
  @Input() emissionClassOptions: SelectOption[];
  @Input() efficiencyClassOptions: SelectOption[];
  @Input() efficiencyClassHiddenOptions: SelectOption[];

  @Input() set formGroup(value: UntypedFormGroup) {
    this._formGroup = value;
  }

  get formGroup(): UntypedFormGroup {
    return this._formGroup;
  }

  private _formGroup: UntypedFormGroup;

  getCo2EfficiencyClassTranslation() {
    return getI18RangeValue(this.originalCO2EfficiencyClass);
  }
}
