import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CasesFacade } from '@vpfa/dealer/case/data';
import { NotesBase } from '../notes-base/notes-base';

@Component({
  selector: 'vpfa-mobile-notes',
  templateUrl: './mobile-notes.component.html',
  styleUrls: ['./mobile-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileNotesComponent extends NotesBase {
  @Output() closeAction = new EventEmitter<void>();

  notesControlValue: string;
  editing = false;

  constructor(fb: UntypedFormBuilder, casesFacade: CasesFacade) {
    super(fb, casesFacade);
  }

  openEdit() {
    this.notesControlValue = this.notesForm.get('notes').value;
    this.editing = true;
  }

  saveEdit() {
    this.updateNotes();
    this.editing = false;
  }

  cancelEdit() {
    this.notesForm.get('notes').reset(this.notesControlValue);
    this.editing = false;
  }
}
