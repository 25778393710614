import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'vpfa-add-customer-by-email',
  templateUrl: './add-customer-by-email.component.html',
  styleUrls: ['./add-customer-by-email.component.scss']
})
export class AddCustomerByEmailComponent {
  @Input() ownerSelected = false;
  @Input() toggleAddForm = false;
  @Input() formGroup: UntypedFormGroup;
  @Input() firstNameErrorMsgList: ReadonlyArray<{ error: string; errorMsg: string }>;
  @Input() lastNameErrorMsgList: ReadonlyArray<{ error: string; errorMsg: string }>;
  @Input() emailErrorMsgList: ReadonlyArray<{ error: string; errorMsg: string }>;

  @Output() toggleForm = new EventEmitter<boolean>();

  onToggleAddForm() {
    this.toggleForm.emit(true);
  }

  onHideAddForm() {
    this.toggleForm.emit(false);
    this.formGroup.reset();
  }

}
