<div class="d-flex flex-column">
  <div class="notification" *ngIf="errorFilesNo > 0">
    <div class="icon">
      <i *ngIf="isAllFailed" class="zmdi zmdi-alert-triangle error"></i>
      <i *ngIf="isPartialError" class="zmdi zmdi-alert-circle warning"></i>
    </div>
    <div id="errors" class="notification">
      <div class="title" [ngClass]="{ error: isAllFailed, warning: isPartialError }">
        <span id="errorTitle">
          <ng-container *ngIf="isAllFailed">
            {{ 'files.allFilesNotAccepted' | translate: { files: errorFilesNo, allFiles: totalFilesNo } }}
          </ng-container>
          <ng-container *ngIf="isPartialError">
            {{ 'files.someFilesNotAccepted' | translate: { files: errorFilesNo, allFiles: totalFilesNo } }}
          </ng-container>
        </span>

        <div *ngFor="let group of errorGroups">
          <div class="error title small-margin error-sub-title">
            {{ getReasonTranslation(group) | translate }}
          </div>
          <div class="error message small-margin">
            <ul>
              <li *ngFor="let item of groupedErrorList[group]">{{ item.fileName }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="successes" class="notification" *ngIf="successFilesNo > 0">
    <div class="icon" *ngIf="isAllSuccess">
      <i class="zmdi zmdi-badge-check success"></i>
    </div>
    <div class="notification">
      <div class="success title">
        <span id="successTitle">
          <ng-container *ngIf="!hasErrors">
            {{ 'files.caseFiles.allFilesAdded' | translate: { files: successFilesNo } }}
          </ng-container>
          <ng-container *ngIf="hasErrors">
            {{ 'files.caseFiles.filesAdded' | translate: { files: successFilesNo } }}
          </ng-container>
        </span>

        <div class="success title small-margin">
          <span id="successSubTitle">
            <ng-container *ngIf="isOneSuccess">{{ 'files.caseFiles.fileAdded' | translate }}</ng-container>
            <ng-container *ngIf="isMoreThanOneSuccess">
              {{ 'files.caseFiles.someFilesAdded' | translate: { files: errorFilesNo, allFiles: totalFilesNo } }}
            </ng-container>
          </span>
        </div>
        <div class="success message small-margin">
          <ul>
            <!-- Cannot use maskSensitiveData directive (its module SharedDirectivesModule to be precise) - it causes
              failing of a lot of unit tests for unknown reason (circular dependency?)  -->
            <li *ngFor="let item of successList" data-dd-privacy="mask" data-dd-action-name="fileItem">
              {{ item.fileName }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
