import { Action } from '@ngrx/store';
import { BranchListItemDto } from '@vpfa/rest-api/admin';
import { ColorDictionaryItemDto, KeyTranslationDto } from '@vpfa/rest-api/valuation';
import { VehicleMakeDto, VehicleTypeDto } from '@vpfa/rest-api/identification';

export enum DictionariesActionTypes {
  LoadBranchList = '[Dictionaries] Load Branch List',
  BranchListLoaded = '[Dictionaries] Branch List :: Loaded',
  BranchListLoadError = '[Dictionaries] Branch List :: Load Error',

  LoadVehicleGroupList = '[Dictionaries] Load Vehicle Group List',
  VehicleGroupListLoaded = '[Dictionaries] Vehicle Group List :: Loaded',
  VehicleGroupListLoadError = '[Dictionaries] Vehicle Group List :: Load Error',

  LoadVehicleTypeList = '[Dictionaries] Load VehicleTypes',
  VehicleTypeListLoaded = '[Dictionaries] VehicleTypes Loaded',
  VehicleTypeListLoadError = '[Dictionaries] VehicleTypes Load Error',

  LoadMakeListByTypeIds = '[Dictionaries] Load Make List By Type Ids',
  LoadMakeListSuccess = '[Dictionaries] Load Make List By Type Id Success',
  LoadMakeListError = '[Dictionaries] Load Make List By Type Id Error',

  LoadAdminPaintColourList = '[Dictionaries] Load Admin Paint Colour List',
  LoadAdminPaintColourListCompleted = '[Dictionaries] Load Admin Paint Colour List Completed',
  LoadAdminPaintColourListError = '[Dictionaries] Load Admin Paint Colour List Error',

  LoadPaintColourList = '[Dictionaries] Load Paint Colour List',
  LoadPaintColourListCompleted = '[Dictionaries] Load Paint Colour List Completed',
  LoadPaintColourListError = '[Dictionaries] Load Paint Colour List Error',

  LoadEmissionClassList = '[Dictionaries] Load Emission Class List',
  LoadEmissionClassListCompleted = '[Dictionaries] Load Emission Class List Completed',
  LoadEmissionClassListError = '[Dictionaries] Load Emission Class List Error',

  LoadCO2EfficiencyClassList = '[Dictionaries] Load CO2 Efficiency Class List',
  LoadCO2EfficiencyClassListCompleted = '[Dictionaries] Load CO2 Efficiency Class List Completed',
  LoadCO2EfficiencyClassListError = '[Dictionaries] Load CO2 Efficiency Class List Error',

  LoadVehicleTypesWhenNotAuthenticatedError = '[Dictionaries] VehicleTypes Load When Not Authenticated Error',
}

export class LoadBranchList implements Action {
  readonly type = DictionariesActionTypes.LoadBranchList;
  constructor() {}
}

export class BranchListLoaded implements Action {
  readonly type = DictionariesActionTypes.BranchListLoaded;
  constructor(public payload: BranchListItemDto[]) {}
}

export class BranchListLoadError implements Action {
  readonly type = DictionariesActionTypes.BranchListLoadError;
  constructor(public payload: any) {}
}

export class LoadVehicleGroupList implements Action {
  readonly type = DictionariesActionTypes.LoadVehicleGroupList;
  constructor() {}
}

export class VehicleGroupListLoaded implements Action {
  readonly type = DictionariesActionTypes.VehicleGroupListLoaded;
  constructor(public payload: KeyTranslationDto[]) {}
}

export class VehicleGroupListLoadError implements Action {
  readonly type = DictionariesActionTypes.VehicleGroupListLoadError;
  constructor(public payload: any) {}
}

export class LoadVehicleTypeList implements Action {
  readonly type = DictionariesActionTypes.LoadVehicleTypeList;
  constructor() {}
}

export class VehicleTypeListLoadError implements Action {
  readonly type = DictionariesActionTypes.VehicleTypeListLoadError;
  constructor(public payload: any) {}
}

export class VehicleTypeListLoaded implements Action {
  readonly type = DictionariesActionTypes.VehicleTypeListLoaded;
  constructor(public payload: VehicleTypeDto[]) {}
}

export class LoadMakeListByTypeIds implements Action {
  readonly type = DictionariesActionTypes.LoadMakeListByTypeIds;
  constructor(public payload: number[]) {}
}

export class LoadMakeListError implements Action {
  readonly type = DictionariesActionTypes.LoadMakeListError;
  constructor(public payload: { typeId: number; error: any }) {}
}

export class LoadMakeListSuccess implements Action {
  readonly type = DictionariesActionTypes.LoadMakeListSuccess;
  constructor(public payload: { list: VehicleMakeDto[]; typeId: number }) {}
}

export class LoadAdminPaintColourList implements Action {
  readonly type = DictionariesActionTypes.LoadAdminPaintColourList;
  constructor(public payload: string) {}
}

export class LoadAdminPaintColourListCompleted implements Action {
  readonly type = DictionariesActionTypes.LoadAdminPaintColourListCompleted;
  constructor(public payload: KeyTranslationDto[]) {}
}

export class LoadAdminPaintColourListError implements Action {
  readonly type = DictionariesActionTypes.LoadAdminPaintColourListError;
}

export class LoadPaintColourList implements Action {
  readonly type = DictionariesActionTypes.LoadPaintColourList;
}

export class LoadPaintColourListCompleted implements Action {
  readonly type = DictionariesActionTypes.LoadPaintColourListCompleted;
  constructor(public payload: ColorDictionaryItemDto[]) {}
}

export class LoadPaintColourListError implements Action {
  readonly type = DictionariesActionTypes.LoadPaintColourListError;
}

export class LoadEmissionClassList implements Action {
  readonly type = DictionariesActionTypes.LoadEmissionClassList;
}

export class LoadEmissionClassListCompleted implements Action {
  readonly type = DictionariesActionTypes.LoadEmissionClassListCompleted;
  constructor(public payload: KeyTranslationDto[]) {}
}

export class LoadEmissionClassListError implements Action {
  readonly type = DictionariesActionTypes.LoadEmissionClassListError;
}

export class LoadCO2EfficiencyClassList implements Action {
  readonly type = DictionariesActionTypes.LoadCO2EfficiencyClassList;
}

export class LoadCO2EfficiencyClassListCompleted implements Action {
  readonly type = DictionariesActionTypes.LoadCO2EfficiencyClassListCompleted;
  constructor(public payload: KeyTranslationDto[]) {}
}

export class LoadCO2EfficiencyClassListError implements Action {
  readonly type = DictionariesActionTypes.LoadCO2EfficiencyClassListError;
}

export class LoadVehicleTypesWhenNotAuthenticatedError implements Action {
  readonly type = DictionariesActionTypes.LoadVehicleTypesWhenNotAuthenticatedError;
  constructor() {}
}

export type DictionariesAction =
  | LoadBranchList
  | BranchListLoadError
  | BranchListLoaded
  | LoadVehicleGroupList
  | VehicleGroupListLoadError
  | VehicleGroupListLoaded
  | LoadVehicleTypeList
  | VehicleTypeListLoadError
  | VehicleTypeListLoaded
  | LoadMakeListByTypeIds
  | LoadMakeListError
  | LoadMakeListSuccess
  | LoadAdminPaintColourList
  | LoadAdminPaintColourListCompleted
  | LoadAdminPaintColourListError
  | LoadPaintColourList
  | LoadPaintColourListCompleted
  | LoadPaintColourListError
  | LoadEmissionClassList
  | LoadEmissionClassListCompleted
  | LoadEmissionClassListError
  | LoadVehicleTypesWhenNotAuthenticatedError
  | LoadCO2EfficiencyClassList
  | LoadCO2EfficiencyClassListCompleted
  | LoadCO2EfficiencyClassListError;

export const fromDictionariesActions = {
  LoadBranchList,
  BranchListLoadError,
  BranchListLoaded,
  LoadVehicleGroupList,
  VehicleGroupListLoadError,
  VehicleGroupListLoaded,
  LoadVehicleTypeList,
  VehicleTypeListLoaded,
  VehicleTypeListLoadError,
  LoadMakeListByTypeIds,
  LoadMakeListError,
  LoadMakeListSuccess,
  LoadAdminPaintColourList,
  LoadAdminPaintColourListCompleted,
  LoadAdminPaintColourListError,
  LoadPaintColourList,
  LoadPaintColourListCompleted,
  LoadPaintColourListError,
  LoadEmissionClassList,
  LoadEmissionClassListCompleted,
  LoadEmissionClassListError,
  LoadVehicleTypesWhenNotAuthenticatedError,
  LoadCO2EfficiencyClassList,
  LoadCO2EfficiencyClassListCompleted,
  LoadCO2EfficiencyClassListError,
};
