import { Component, Input } from '@angular/core';
import { BaseVehicleList } from '../base-vehicle-list';
import { DateLocalePipe } from '@vpfa/shared/pipes';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'vpfa-mobile-vehicle-list',
  templateUrl: './mobile-vehicle-list.component.html',
  styleUrls: ['./mobile-vehicle-list.component.scss'],
  providers: [DateLocalePipe],
})
export class MobileVehicleListComponent extends BaseVehicleList {
  @Input() isFormInvalid = false;
  @Input() showPower = false;
  constructor(protected dateLocale: DateLocalePipe, protected translateService: TranslateService) {
      super(dateLocale, translateService);
  }
}
