import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { StockUniqueDataDto, TypeOfUse } from '@vpfa/rest-api/valuation';
import { SelectOption } from '@vpfa/ui-kit';
import { DateTime } from 'luxon';
import { get, isNil } from 'lodash';
import { enumToOptions, getCurrentDate, optionValueToName } from '@vpfa/utils';
import { BranchListItemDto } from '@vpfa/rest-api/admin';
import { branchListDto2Options } from '../../utils/branch-list-dto-2-option';
import { TEXT_AREA_MAX_LENGTH, UC_NUMBER_MAX_LENGTH } from '@vpfa/shared/validators';
import { TranslateService } from '@ngx-translate/core';
import { initialUniqueDataSelectOptionValue } from '../../utils/unique-data-helper';

export const StockUniqueDataFormName = 'stockUniqueData';

@Component({
  selector: 'vpfa-unique-data-stock',
  templateUrl: './unique-data-stock.component.html',
  styleUrls: ['./unique-data-stock.component.scss'],
})
export class UniqueDataStockComponent implements OnInit {
  readonly textAreaMaxLength = TEXT_AREA_MAX_LENGTH;

  @Input() timezone: string;
  @Input() set parentFormGroup(parentFormGroup: UntypedFormGroup) {
    if (parentFormGroup) {
      parentFormGroup.addControl(StockUniqueDataFormName, this.formGroup);
    }
  }

  private _initialData: StockUniqueDataDto;
  @Input() set initialData(initialData: StockUniqueDataDto) {
    if (isNil(initialData)) return;
    this._initialData = initialData;
    this.resetFormToInitial(initialData);
  }
  get initialData(): StockUniqueDataDto {
    return this._initialData;
  }

  @Input() saleDate = null;

  @Input() set branchList(branches: BranchListItemDto[]) {
    if (!isNil(branches)) {
      this.branchOptions = branchListDto2Options(branches);
      if (this._initialData !== undefined) {
        this.resetFormToInitial(this._initialData);
      }
    }
  }

  @Input() locale: string;

  formGroupControls: { [key in keyof StockUniqueDataDto]: UntypedFormControl } = {
    avTradeInDate: new UntypedFormControl(null, Validators.required),
    ucNumber: new UntypedFormControl(null, [Validators.required, Validators.maxLength(UC_NUMBER_MAX_LENGTH)]),
    deregistrationDate: new UntypedFormControl(null),
    newRegistrationDate: new UntypedFormControl(null),
    lastCustomerService: new UntypedFormControl(null),
    lastToothedBeltChange: new UntypedFormControl(null),
    subsidiaryId: new UntypedFormControl(null),
    branchLocation: new UntypedFormControl(null, Validators.maxLength(TEXT_AREA_MAX_LENGTH)),
    typeOfUse: new UntypedFormControl(null),
  };

  formGroup = new UntypedFormGroup(this.formGroupControls);
  typeOfUseOptions: SelectOption[] = enumToOptions(TypeOfUse, 'uniqueData.typeOfUse.');
  branchOptions: SelectOption[];

  ucNumberErrorMsgList: ReadonlyArray<{ error: string; errorMsg: string }> = [
    {
      error: 'required',
      errorMsg: this.translateService.instant('common.forms.fieldIsRequired'),
    },
    {
      error: 'maxlength',
      errorMsg: this.translateService.instant('common.forms.maxLength', { maxLength: UC_NUMBER_MAX_LENGTH }),
    },
  ];

  branchLocationErrorMsgList: ReadonlyArray<{ error: string; errorMsg: string }> = [
    {
      error: 'maxlength',
      errorMsg: this.translateService.instant('common.forms.maxLength', { maxLength: TEXT_AREA_MAX_LENGTH }),
    },
  ];

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.formGroup.get('subsidiaryId').valueChanges.subscribe(newLocationBranchId => {
      this.onSubsidiaryChange(newLocationBranchId?.value);
    });
  }

  getSubsidiaryName() {
    return optionValueToName(this.formGroup.get('subsidiaryId').value?.value, this.branchOptions);
  }

  getTypeOfUseName() {
    return optionValueToName(this.formGroup.get('typeOfUse').value?.value, this.typeOfUseOptions);
  }

  onSubsidiaryChange(newLocationBranchId: string) {
    const branch = this.branchOptions.find(el => el.value == newLocationBranchId);
    const branchName = get(branch, ['name'], null);
    this.formGroup.get('branchLocation').reset(branchName);
  }

  disableDate = (current: string) => DateTime.fromISO(getCurrentDate(this.timezone)) < DateTime.fromISO(current);
  tradeInDisableDate = (current: string) =>
    this.saleDate != null && DateTime.fromISO(this.saleDate) < DateTime.fromISO(current);

  isBranchLocationDisable(): boolean {
    return !this.formGroup.get('subsidiaryId').value;
  }

  private resetFormToInitial(initialData: StockUniqueDataDto) {
    this.formGroup.reset({
      deregistrationDate: initialData.deregistrationDate,
      newRegistrationDate: initialData.newRegistrationDate,
      lastCustomerService: initialData.lastCustomerService,
      lastToothedBeltChange: initialData.lastToothedBeltChange,
      subsidiaryId: initialUniqueDataSelectOptionValue(initialData.subsidiaryId, this.branchOptions),
      branchLocation: initialData.branchLocation,
      ucNumber: initialData.ucNumber,
      typeOfUse: initialUniqueDataSelectOptionValue(initialData.typeOfUse, this.typeOfUseOptions),
      avTradeInDate: initialData.avTradeInDate,
    });
  }
}
