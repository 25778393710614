import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

@Injectable()
export class PrefixingMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    const defaultTranslation = params.translateService.parser.interpolate(
      params.translateService.parser.getValue(
        params.translateService.translations[params.translateService.defaultLang],
        params.key
      ),
      params.interpolateParams
    );
    const selectedLang = params.translateService.currentLang;

    // when we do not have default translation
    if (defaultTranslation === undefined) {
      return params.key;
    }

    if (!selectedLang) {
      // selectedLang is null for very short moment when app is loading so we need to skip prefix temporary
      return `${defaultTranslation}`;
    }

    return `[${selectedLang}] ${defaultTranslation}`;
  }
}
