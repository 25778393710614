<ng-container *vpfaResponsiveIf="'mobile'">
  <vpfa-mobile-subtitle
    [title]="'vehicleFullTechnicalData.fuelConsumption.wltp.wltpConsumptionAndEmission' | translate"
  ></vpfa-mobile-subtitle>
</ng-container>
<ng-container *vpfaResponsiveIf="'web'">
  <div class="mt">
    <vpfa-expanding-header
      [text]="'vehicleFullTechnicalData.fuelConsumption.wltp.wltpConsumptionAndEmission' | translate"
    ></vpfa-expanding-header>
  </div>
</ng-container>

<!----------------------------------------------------------------------->
<!----------------------- Combustion + Hybrid --------------------------->
<!---------------------------- Basic Info ------------------------------->
<!----------------------------------------------------------------------->
<div
  *ngIf="vehicleEngineType === VehicleEngineType.Thermal || vehicleEngineType === VehicleEngineType.PluginHybrid"
  [fxLayout]="'row wrap'"
  fxLayoutGap.gt-mv="70px grid"
  fxLayout.lt-mv="column"
  fxLayoutGap.lt-mv="10px"
  ngClass.gt-mv="section"
  [attr.data-vehicle-engine-type]="vehicleEngineType"
>
  <ng-container *ngTemplateOutlet="efficiencyClass"></ng-container>
  <ng-container *ngTemplateOutlet="hybridVehicleEfficiencyClass"></ng-container>

  <vpfa-vehicle-technical-data-interval-number-field
    id="wltpLowSpeed"
    [label]="
      ('vehicleFullTechnicalData.fuelConsumption.wltp.lowSpeed' | translate) +
      (technicalDataConsumption?.wltp?.fuelConsumptionLowPhase | unitValue)
    "
    [parentFormGroup]="formGroup.get('wltp')"
    [fcName]="'lowSpeed'"
    [errorMessages]="errors"
  ></vpfa-vehicle-technical-data-interval-number-field>

  <vpfa-vehicle-technical-data-interval-number-field
    id="wltpMediumSpeed"
    [label]="
      ('vehicleFullTechnicalData.fuelConsumption.wltp.mediumSpeed' | translate) +
      (technicalDataConsumption?.wltp?.fuelConsumptionMediumPhase | unitValue)
    "
    [parentFormGroup]="formGroup.get('wltp')"
    [fcName]="'mediumSpeed'"
    [errorMessages]="errors"
  ></vpfa-vehicle-technical-data-interval-number-field>

  <vpfa-vehicle-technical-data-interval-number-field
    id="wltpHighSpeed"
    [label]="
      ('vehicleFullTechnicalData.fuelConsumption.wltp.highSpeed' | translate) +
      (technicalDataConsumption?.wltp?.fuelConsumptionHighPhase | unitValue)
    "
    [parentFormGroup]="formGroup.get('wltp')"
    [fcName]="'highSpeed'"
    [errorMessages]="errors"
  ></vpfa-vehicle-technical-data-interval-number-field>

  <vpfa-vehicle-technical-data-interval-number-field
    id="wltpExtraHighSpeed"
    [label]="
      ('vehicleFullTechnicalData.fuelConsumption.wltp.extraHighSpeed' | translate) +
      (technicalDataConsumption?.wltp?.fuelConsumptionExtraHighPhase | unitValue)
    "
    [parentFormGroup]="formGroup.get('wltp')"
    [fcName]="'extraHighSpeed'"
    [errorMessages]="errors"
  ></vpfa-vehicle-technical-data-interval-number-field>

  <vpfa-vehicle-technical-data-interval-number-field
    id="wltpCombined"
    [label]="
      ('vehicleFullTechnicalData.fuelConsumption.wltp.combined' | translate) +
      (technicalDataConsumption?.wltp?.fuelConsumptionOverallCombined | unitValue)
    "
    [parentFormGroup]="formGroup.get('wltp')"
    [fcName]="'combined'"
    [errorMessages]="errors"
  ></vpfa-vehicle-technical-data-interval-number-field>

  <vpfa-vehicle-technical-data-interval-number-field
    id="wltpCO2Emission"
    [label]="
      ('vehicleFullTechnicalData.fuelConsumption.wltp.cO2Emission' | translate) +
      (technicalDataConsumption?.wltp?.cO2EmissionCombined | unitValue)
    "
    [parentFormGroup]="formGroup.get('wltp')"
    [fcName]="'cO2EmissionCombined'"
    [errorMessages]="co2Errors"
    [maxFractionNumber]="0"
  ></vpfa-vehicle-technical-data-interval-number-field>

  <ng-container *ngTemplateOutlet="electricAndHybridAdditionalInfo"></ng-container>
</div>

<!-------------------------------------------->
<!-------- Second section for Hybrid --------->
<!-------------------------------------------->
<ng-container *ngIf="vehicleEngineType === VehicleEngineType.PluginHybrid">
  <ng-container *vpfaResponsiveIf="'mobile'">
    <vpfa-mobile-subtitle
      [title]="'vehicleFullTechnicalData.fuelConsumption.wltp.hybridVehicles' | translate"
    ></vpfa-mobile-subtitle>
  </ng-container>
  <ng-container *vpfaResponsiveIf="'web'">
    <div class="mt">
      <vpfa-expanding-header
        [text]="'vehicleFullTechnicalData.fuelConsumption.wltp.hybridVehicles' | translate"
      ></vpfa-expanding-header>
    </div>
  </ng-container>

  <div ngClass.lt-mv="rows-container" ngClass.gt-mv="columns-container">
    <!---------------------------->
    <!-- Hybrid - Pure Electric -->
    <!---------------------------->
    <div class="column">
      <h2>{{ 'vehicleFullTechnicalData.fuelConsumption.wltp.powerConsumptionPureElectric' | translate }}</h2>
      <vpfa-vehicle-technical-data-interval-number-field
        id="wltpLowSpeedPE"
        [label]="
          ('vehicleFullTechnicalData.fuelConsumption.wltp.lowSpeed' | translate) +
          (technicalDataConsumption?.wltp?.fuelConsumptionLowPhasePE | unitValue)
        "
        [parentFormGroup]="formGroup.get('wltp')"
        [fcName]="'fuelConsumptionLowPhasePE'"
        [errorMessages]="errors"
      ></vpfa-vehicle-technical-data-interval-number-field>

      <vpfa-vehicle-technical-data-interval-number-field
        id="wltpMediumSpeedPE"
        [label]="
          ('vehicleFullTechnicalData.fuelConsumption.wltp.mediumSpeed' | translate) +
          (technicalDataConsumption?.wltp?.fuelConsumptionMediumPhasePE | unitValue)
        "
        [parentFormGroup]="formGroup.get('wltp')"
        [fcName]="'fuelConsumptionMediumPhasePE'"
        [errorMessages]="errors"
      ></vpfa-vehicle-technical-data-interval-number-field>

      <vpfa-vehicle-technical-data-interval-number-field
        id="wltpHighSpeedPE"
        [label]="
          ('vehicleFullTechnicalData.fuelConsumption.wltp.highSpeed' | translate) +
          (technicalDataConsumption?.wltp?.fuelConsumptionHighPhasePE | unitValue)
        "
        [parentFormGroup]="formGroup.get('wltp')"
        [fcName]="'fuelConsumptionHighPhasePE'"
        [errorMessages]="errors"
      ></vpfa-vehicle-technical-data-interval-number-field>

      <vpfa-vehicle-technical-data-interval-number-field
        id="wltpExtraHighSpeedPE"
        [label]="
          ('vehicleFullTechnicalData.fuelConsumption.wltp.extraHighSpeed' | translate) +
          (technicalDataConsumption?.wltp?.fuelConsumptionExtraHighPhasePE | unitValue)
        "
        [parentFormGroup]="formGroup.get('wltp')"
        [fcName]="'fuelConsumptionExtraHighPhasePE'"
        [errorMessages]="errors"
      ></vpfa-vehicle-technical-data-interval-number-field>

      <vpfa-vehicle-technical-data-interval-number-field
        id="wltpCombinedPE"
        [label]="
          ('vehicleFullTechnicalData.fuelConsumption.wltp.combined' | translate) +
          (technicalDataConsumption?.wltp?.fuelConsumptionCombinedPhasePE | unitValue)
        "
        [parentFormGroup]="formGroup.get('wltp')"
        [fcName]="'fuelConsumptionCombinedPhasePE'"
        [errorMessages]="errors"
      ></vpfa-vehicle-technical-data-interval-number-field>
    </div>
    <!-------------------------------->
    <!-- Hybrid - Discarded Battery -->
    <!-------------------------------->
    <div class="column">
      <h2>{{ 'vehicleFullTechnicalData.fuelConsumption.wltp.fuelConsumptionBatteryDischarged' | translate }}</h2>
      <vpfa-vehicle-technical-data-interval-number-field
        id="wltpLowSpeedEB"
        [label]="
          ('vehicleFullTechnicalData.fuelConsumption.wltp.lowSpeed' | translate) +
          (technicalDataConsumption?.wltp?.fuelConsumptionLowPhaseEB | unitValue)
        "
        [parentFormGroup]="formGroup.get('wltp')"
        [fcName]="'fuelConsumptionLowPhaseEB'"
        [errorMessages]="errors"
      ></vpfa-vehicle-technical-data-interval-number-field>

      <vpfa-vehicle-technical-data-interval-number-field
        id="wltpMediumSpeedEB"
        [label]="
          ('vehicleFullTechnicalData.fuelConsumption.wltp.mediumSpeed' | translate) +
          (technicalDataConsumption?.wltp?.fuelConsumptionMediumPhaseEB | unitValue)
        "
        [parentFormGroup]="formGroup.get('wltp')"
        [fcName]="'fuelConsumptionMediumPhaseEB'"
        [errorMessages]="errors"
      ></vpfa-vehicle-technical-data-interval-number-field>

      <vpfa-vehicle-technical-data-interval-number-field
        id="wltpHighSpeedEB"
        [label]="
          ('vehicleFullTechnicalData.fuelConsumption.wltp.highSpeed' | translate) +
          (technicalDataConsumption?.wltp?.fuelConsumptionHighPhaseEB | unitValue)
        "
        [parentFormGroup]="formGroup.get('wltp')"
        [fcName]="'fuelConsumptionHighPhaseEB'"
        [errorMessages]="errors"
      ></vpfa-vehicle-technical-data-interval-number-field>

      <vpfa-vehicle-technical-data-interval-number-field
        id="wltpExtraHighSpeedEB"
        [label]="
          ('vehicleFullTechnicalData.fuelConsumption.wltp.extraHighSpeed' | translate) +
          (technicalDataConsumption?.wltp?.fuelConsumptionExtraHighPhaseEB | unitValue)
        "
        [parentFormGroup]="formGroup.get('wltp')"
        [fcName]="'fuelConsumptionExtraHighPhaseEB'"
        [errorMessages]="errors"
      ></vpfa-vehicle-technical-data-interval-number-field>

      <vpfa-vehicle-technical-data-interval-number-field
        id="wltpCombinedEB"
        [label]="
          ('vehicleFullTechnicalData.fuelConsumption.wltp.combined' | translate) +
          (technicalDataConsumption?.wltp?.fuelConsumptionCombinedPhaseEB | unitValue)
        "
        [parentFormGroup]="formGroup.get('wltp')"
        [fcName]="'fuelConsumptionCombinedPhaseEB'"
        [errorMessages]="errors"
      ></vpfa-vehicle-technical-data-interval-number-field>

      <vpfa-vehicle-technical-data-interval-number-field
        id="wltpCO2EmissionEB"
        [label]="
          ('vehicleFullTechnicalData.fuelConsumption.wltp.cO2Emission' | translate) +
          (technicalDataConsumption?.wltp?.cO2EmissionCombinedEB | unitValue)
        "
        [parentFormGroup]="formGroup.get('wltp')"
        [fcName]="'cO2EmissionCombinedEB'"
        [errorMessages]="co2Errors"
        [maxFractionNumber]="0"
      ></vpfa-vehicle-technical-data-interval-number-field>
    </div>
  </div>
</ng-container>

<!--------------------------------------------------------------------->
<!----------------------------- Pure Gas ------------------------------>
<!---------------------------- Basic Info ----------------------------->
<!--------------------------------------------------------------------->
<div
  *ngIf="vehicleEngineType === VehicleEngineType.Gas"
  [fxLayout]="'row wrap'"
  fxLayoutGap.gt-mv="70px grid"
  fxLayout.lt-mv="column"
  fxLayoutGap.lt-mv="10px"
  ngClass.gt-mv="section"
  [attr.data-vehicle-engine-type]="vehicleEngineType"
>
  <ng-container *ngTemplateOutlet="efficiencyClass"></ng-container>

  <vpfa-vehicle-technical-data-interval-number-field
    id="wltpLowSpeed"
    [label]="
      ('vehicleFullTechnicalData.fuelConsumption.wltp.lowSpeed' | translate) +
      (technicalDataConsumption?.wltp?.gasConsumptionLowPhase | unitValue)
    "
    [parentFormGroup]="formGroup.get('wltp')"
    [fcName]="'gasConsumptionLowPhase'"
    [errorMessages]="errors"
  ></vpfa-vehicle-technical-data-interval-number-field>

  <vpfa-vehicle-technical-data-interval-number-field
    id="wltpMediumSpeed"
    [label]="
      ('vehicleFullTechnicalData.fuelConsumption.wltp.mediumSpeed' | translate) +
      (technicalDataConsumption?.wltp?.gasConsumptionMediumPhase | unitValue)
    "
    [parentFormGroup]="formGroup.get('wltp')"
    [fcName]="'gasConsumptionMediumPhase'"
    [errorMessages]="errors"
  ></vpfa-vehicle-technical-data-interval-number-field>

  <vpfa-vehicle-technical-data-interval-number-field
    id="wltpHighSpeed"
    [label]="
      ('vehicleFullTechnicalData.fuelConsumption.wltp.highSpeed' | translate) +
      (technicalDataConsumption?.wltp?.gasConsumptionHighPhase | unitValue)
    "
    [parentFormGroup]="formGroup.get('wltp')"
    [fcName]="'gasConsumptionHighPhase'"
    [errorMessages]="errors"
  ></vpfa-vehicle-technical-data-interval-number-field>

  <vpfa-vehicle-technical-data-interval-number-field
    id="wltpExtraHighSpeed"
    [label]="
      ('vehicleFullTechnicalData.fuelConsumption.wltp.extraHighSpeed' | translate) +
      (technicalDataConsumption?.wltp?.gasConsumptionExtraHighPhase | unitValue)
    "
    [parentFormGroup]="formGroup.get('wltp')"
    [fcName]="'gasConsumptionExtraHighPhase'"
    [errorMessages]="errors"
  ></vpfa-vehicle-technical-data-interval-number-field>

  <vpfa-vehicle-technical-data-interval-number-field
    id="wltpCombined"
    [label]="
      ('vehicleFullTechnicalData.fuelConsumption.wltp.combined' | translate) +
      (technicalDataConsumption?.wltp?.gasConsumptionCombinedPhase | unitValue)
    "
    [parentFormGroup]="formGroup.get('wltp')"
    [fcName]="'gasConsumptionCombinedPhase'"
    [errorMessages]="errors"
  ></vpfa-vehicle-technical-data-interval-number-field>

  <vpfa-vehicle-technical-data-interval-number-field
    id="wltpCO2Emission"
    [label]="
      ('vehicleFullTechnicalData.fuelConsumption.wltp.cO2Emission' | translate) +
      (technicalDataConsumption?.wltp?.gasCO2EmissionCombined | unitValue)
    "
    [parentFormGroup]="formGroup.get('wltp')"
    [fcName]="'gasCO2EmissionCombined'"
    [errorMessages]="co2Errors"
    [maxFractionNumber]="0"
  ></vpfa-vehicle-technical-data-interval-number-field>
</div>

<!--------------------------------------------------------------------->
<!-------------------------- Pure Electric ---------------------------->
<!---------------------------- Basic Info ----------------------------->
<!--------------------------------------------------------------------->
<ng-container *ngIf="vehicleEngineType === VehicleEngineType.Electric">
  <div
    [fxLayout]="'row wrap'"
    fxLayoutGap.gt-mv="70px grid"
    fxLayout.lt-mv="column"
    fxLayoutGap.lt-mv="10px"
    ngClass.gt-mv="section"
    [attr.data-vehicle-engine-type]="vehicleEngineType"
  >
    <ng-container *ngTemplateOutlet="efficiencyClass"></ng-container>

    <vpfa-vehicle-technical-data-interval-number-field
      id="wltpLowSpeed"
      [label]="
        ('vehicleFullTechnicalData.fuelConsumption.wltp.lowSpeed' | translate) +
        (technicalDataConsumption?.wltp?.fuelConsumptionLowPhasePE | unitValue)
      "
      [parentFormGroup]="formGroup.get('wltp')"
      [fcName]="'fuelConsumptionLowPhasePE'"
      [errorMessages]="errors"
    ></vpfa-vehicle-technical-data-interval-number-field>

    <vpfa-vehicle-technical-data-interval-number-field
      id="wltpMediumSpeed"
      [label]="
        ('vehicleFullTechnicalData.fuelConsumption.wltp.mediumSpeed' | translate) +
        (technicalDataConsumption?.wltp?.fuelConsumptionMediumPhasePE | unitValue)
      "
      [parentFormGroup]="formGroup.get('wltp')"
      [fcName]="'fuelConsumptionMediumPhasePE'"
      [errorMessages]="errors"
    ></vpfa-vehicle-technical-data-interval-number-field>

    <vpfa-vehicle-technical-data-interval-number-field
      id="wltpHighSpeed"
      [label]="
        ('vehicleFullTechnicalData.fuelConsumption.wltp.highSpeed' | translate) +
        (technicalDataConsumption?.wltp?.fuelConsumptionHighPhasePE | unitValue)
      "
      [parentFormGroup]="formGroup.get('wltp')"
      [fcName]="'fuelConsumptionHighPhasePE'"
      [errorMessages]="errors"
    ></vpfa-vehicle-technical-data-interval-number-field>

    <vpfa-vehicle-technical-data-interval-number-field
      id="wltpExtraHighSpeed"
      [label]="
        ('vehicleFullTechnicalData.fuelConsumption.wltp.extraHighSpeed' | translate) +
        (technicalDataConsumption?.wltp?.fuelConsumptionExtraHighPhasePE | unitValue)
      "
      [parentFormGroup]="formGroup.get('wltp')"
      [fcName]="'fuelConsumptionExtraHighPhasePE'"
      [errorMessages]="errors"
    ></vpfa-vehicle-technical-data-interval-number-field>

    <vpfa-vehicle-technical-data-interval-number-field
      id="wltpCombined"
      [label]="
        ('vehicleFullTechnicalData.fuelConsumption.wltp.combined' | translate) +
        (technicalDataConsumption?.wltp?.fuelConsumptionCombinedPhasePE | unitValue)
      "
      [parentFormGroup]="formGroup.get('wltp')"
      [fcName]="'fuelConsumptionCombinedPhasePE'"
      [errorMessages]="errors"
    ></vpfa-vehicle-technical-data-interval-number-field>

    <vpfa-vehicle-technical-data-interval-number-field
      id="wltpCO2Emission"
      [label]="
        ('vehicleFullTechnicalData.fuelConsumption.wltp.cO2Emission' | translate) +
        (technicalDataConsumption?.wltp?.cO2EmissionCombined | unitValue)
      "
      [parentFormGroup]="formGroup.get('wltp')"
      [fcName]="'cO2EmissionCombined'"
      [errorMessages]="co2Errors"
      [maxFractionNumber]="0"
    ></vpfa-vehicle-technical-data-interval-number-field>

    <ng-container *ngTemplateOutlet="electricAndHybridAdditionalInfo"></ng-container>
  </div>
</ng-container>

<!-------------------------------------------->
<!----------- Efficiency Class  -------------->
<!-------------------------------------------->
<ng-template #efficiencyClass>
  <!-- Read only -->
  <ng-container *ngIf="isGermany || isMobile">
    <vpfa-vehicle-technical-data-single-field
      id="co2WltpCustomReadOnly"
      [label]="'vehicleFullTechnicalData.co2LabelCalculated' | translate"
      [value]="formGroup.get('wltp')?.get('cO2WLTPEfficiencyClassCustom')?.value | lT"
    ></vpfa-vehicle-technical-data-single-field>
  </ng-container>

  <!-- Editable -->
  <ng-container *ngIf="!isGermany && !isMobile">
    <vpfa-vehicle-technical-data-select-field
      id="co2WltpCustomEditable"
      [label]="'vehicleFullTechnicalData.co2LabelCalculated' | translate"
      [parentFormGroup]="formGroup.get('wltp')"
      fcName="cO2WLTPEfficiencyClassCustom"
      [selectOptions]="efficiencyClassOptions"
      [hiddenOptions]="efficiencyClassHiddenOptions"
    ></vpfa-vehicle-technical-data-select-field>
  </ng-container>

  <vpfa-vehicle-technical-data-single-field
    id="co2WltpOriginal"
    [label]="'vehicleFullTechnicalData.originalCO2EfficiencyClassLabel' | lT | translate"
    [value]="cO2WLTPEfficiencyClassText"
  ></vpfa-vehicle-technical-data-single-field>
</ng-template>

<ng-template #hybridVehicleEfficiencyClass>
  <ng-container *ngIf="vehicleEngineType === VehicleEngineType.PluginHybrid">
    <!-- Read only -->
    <ng-container *ngIf="isGermany || isMobile">
      <vpfa-vehicle-technical-data-single-field
        id="hybridCo2WltpCustomReadOnly"
        [label]="'vehicleFullTechnicalData.phevCo2LabelCalculated' | translate"
        [value]="formGroup.get('wltp')?.get('cO2WLTPEfficiencyClassEBCustom')?.value | lT"
      ></vpfa-vehicle-technical-data-single-field>
    </ng-container>

    <!-- Editable -->
    <ng-container *ngIf="!isGermany && !isMobile">
      <vpfa-vehicle-technical-data-select-field
        id="phevCo2LabelCalculated"
        [label]="'vehicleFullTechnicalData.phevCo2LabelCalculated' | translate"
        [parentFormGroup]="formGroup.get('wltp')"
        fcName="cO2WLTPEfficiencyClassEBCustom"
        [selectOptions]="efficiencyClassOptions"
        [hiddenOptions]="efficiencyClassHiddenOptions"
      ></vpfa-vehicle-technical-data-select-field>
    </ng-container>

    <vpfa-vehicle-technical-data-single-field
      id="phevOriginalCO2EfficiencyDepleted"
      [label]="'vehicleFullTechnicalData.phevOriginalCO2EfficiencyDepleted' | translate"
      [value]="cO2WLTPEfficiencyClassEBText"
    ></vpfa-vehicle-technical-data-single-field>
  </ng-container>
</ng-template>

<!-------------------------------------------->
<!----------- Electric + Hybrid -------------->
<!------------ Additional Info --------------->
<!-------------------------------------------->
<ng-template #electricAndHybridAdditionalInfo>
  <ng-container
    *ngIf="vehicleEngineType === VehicleEngineType.Electric || vehicleEngineType === VehicleEngineType.PluginHybrid"
  >
    <vpfa-vehicle-technical-data-number-field
      id="eVRangeAndConsumptionCombinedRange"
      [label]="
        ('vehicleFullTechnicalData.fuelConsumption.eVRangeAndConsumption.combinedRange' | translate) +
        (technicalDataConsumption?.wltp?.drivingRange | unitValue)
      "
      [parentFormGroup]="formGroup.get('wltp')"
      [fcName]="'combinedRange'"
      [errorMessages]="evRangeAndConsumptionErrors"
      [heightCompensationPx]="0"
    ></vpfa-vehicle-technical-data-number-field>

    <vpfa-vehicle-technical-data-number-field
      id="eVRangeAndConsumptionCityRange"
      [label]="
        ('vehicleFullTechnicalData.fuelConsumption.eVRangeAndConsumption.cityRange' | translate) +
        (technicalDataConsumption?.wltp?.drivingRangeCity | unitValue)
      "
      [parentFormGroup]="formGroup.get('wltp')"
      [fcName]="'cityRange'"
      [errorMessages]="evRangeAndConsumptionErrors"
      [heightCompensationPx]="0"
    ></vpfa-vehicle-technical-data-number-field>

    <vpfa-vehicle-technical-data-interval-number-field
      id="eVRangeAndConsumptionElectricConsumption"
      [label]="
        ('vehicleFullTechnicalData.fuelConsumption.eVRangeAndConsumption.electricConsumption' | translate) +
        (technicalDataConsumption?.wltp?.electricPowerConsumption | unitValue)
      "
      [parentFormGroup]="formGroup.get('wltp')"
      [fcName]="'electricPowerConsumption'"
      [errorMessages]="evRangeAndConsumptionErrors"
    ></vpfa-vehicle-technical-data-interval-number-field>
  </ng-container>
</ng-template>
