import { Injectable } from '@angular/core';
import { EnvironmentConfigDto, EnvironmentConfigViewService } from '@vpfa/rest-api/admin';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EnvironmentsService {
  constructor(private environmentConfigViewService: EnvironmentConfigViewService) {}

  private _config: EnvironmentConfigDto = {
    cdnUrl: '',
    googleReCaptchaApiKey: '',
    mixpanelToken: '',
    liveRetailUrl: '',
    groupSiteUrl: '',
    sorcUrl: '',
    xasisUrl: '',
    sorcLanguageCodeConversions: null,
  };

  configLoaded$ = new BehaviorSubject<boolean>(false);
  configInitialized$ = new Subject<void>();

  get config(): EnvironmentConfigDto {
    return this._config;
  }

  loadConfig() {
    return this.environmentConfigViewService
      .getEnvironmentConfig()
      .toPromise()
      .then(config => {
        this._config = config;
        this.configLoaded$.next(true);
        this.configInitialized$.next();
      })
      .catch(error => console.error('Environments config load failed: ', error))
  }
}
