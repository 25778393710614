import { Action } from '@ngrx/store';
import { AmsPermissions } from '@vpfa/rest-api/admin';
import { PermissionsContext } from '../models/permissions-context';

export enum PermissionsActionTypes {
  LoadPermissions = '[Permissions] Load permissions',
  LoadPermissionsByContext = '[Permissions] Load permissions by context',
  PermissionsLoaded = '[Permissions] Permissions loaded',
  LoadPermissionsError = '[Permissions] Load permissions error',
  ClearPermissions = '[Permissions] Clear permissions',
}

export class LoadPermissions implements Action {
  readonly type = PermissionsActionTypes.LoadPermissions;
}

export class LoadPermissionsByContext implements Action {
  readonly type = PermissionsActionTypes.LoadPermissionsByContext;
  constructor(public payload: PermissionsContext) {}
}

export class PermissionsLoaded implements Action {
  readonly type = PermissionsActionTypes.PermissionsLoaded;
  constructor(public payload: AmsPermissions) {}
}

export class LoadPermissionsError implements Action {
  readonly type = PermissionsActionTypes.LoadPermissionsError;
}

export class ClearPermissions implements Action {
  readonly type = PermissionsActionTypes.ClearPermissions;
}

export type PermissionsActions =
  | LoadPermissions
  | LoadPermissionsByContext
  | PermissionsLoaded
  | LoadPermissionsError
  | ClearPermissions;
