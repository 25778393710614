import { VehicleEquipmentAction, VehicleEquipmentActionTypes } from './vehicle-equipment.actions';
import { produce } from 'immer';
import { UniversalCategoryListItemDto } from '@vpfa/rest-api/valuation';
import { FormulaWizardState, reducer as formulaWizardReducer } from './formula-wizard/formula-wizard.reducer';

export const VEHICLEEQUIPMENT_FEATURE_KEY = 'vehicleEquipment';

export interface VehicleEquipmentState {
  loading: boolean;
  isAddingAftermarketEquipment: boolean;
  isUpdatingAftermarketEquipment: boolean;
  universalCategoryCodes: UniversalCategoryListItemDto[];
  formulaWizard: FormulaWizardState;
}

export interface VehicleEquipmentPartialState {
  readonly [VEHICLEEQUIPMENT_FEATURE_KEY]: VehicleEquipmentState;
}

export const initialState: VehicleEquipmentState = {
  loading: false,
  isAddingAftermarketEquipment: false,
  isUpdatingAftermarketEquipment: false,
  universalCategoryCodes: null,
  formulaWizard: null,
};

export function reducer(
  state: VehicleEquipmentState = initialState,
  action: VehicleEquipmentAction
): VehicleEquipmentState {
  switch (action.type) {
    // update equipment
    case VehicleEquipmentActionTypes.VehicleEquipmentUpdateIsEnabled:
      return produce(state, newState => {
        newState.loading = true;
      });
    case VehicleEquipmentActionTypes.VehicleEquipmentUpdateIsEnabledSuccess:
      return produce(state, newState => {
        newState.loading = false;
      });
    case VehicleEquipmentActionTypes.VehicleEquipmentUpdateIsEnabledError:
      return produce(state, newState => {
        newState.loading = false;
      });

    // create aftermarket equipment
    case VehicleEquipmentActionTypes.VehicleEquipmentAftermarketItemCreate:
      return produce(state, newState => {
        newState.isAddingAftermarketEquipment = true;
        newState.loading = true;
      });
    case VehicleEquipmentActionTypes.VehicleEquipmentAftermarketItemCreated:
    case VehicleEquipmentActionTypes.VehicleEquipmentAftermarketItemCreateError:
      return produce(state, newState => {
        newState.isAddingAftermarketEquipment = false;
        newState.loading = false;
      });

    // update aftermarket equipment
    case VehicleEquipmentActionTypes.VehicleEquipmentAftermarketItemUpdate:
      return produce(state, newState => {
        newState.isUpdatingAftermarketEquipment = true;
        newState.loading = true;
      });
    case VehicleEquipmentActionTypes.VehicleEquipmentAftermarketItemUpdated:
    case VehicleEquipmentActionTypes.VehicleEquipmentAftermarketItemUpdateError:
      return produce(state, newState => {
        newState.isUpdatingAftermarketEquipment = false;
        newState.loading = false;
      });

    // update equipment color
    case VehicleEquipmentActionTypes.VehicleEquipmentColorUpdate:
      return produce(state, newState => {
        newState.loading = true;
      });
    case VehicleEquipmentActionTypes.VehicleEquipmentColorUpdated:
    case VehicleEquipmentActionTypes.VehicleEquipmentColorUpdateError:
      return produce(state, newState => {
        newState.loading = false;
      });

    case VehicleEquipmentActionTypes.VehicleEquipmentUniversalCategoryCodesLoad:
      return produce(state, newState => {
        newState.loading = true;
      });
    case VehicleEquipmentActionTypes.VehicleEquipmentUniversalCategoryCodesLoaded:
      return produce(state, newState => {
        newState.loading = false;
        newState.universalCategoryCodes = action.payload;
      });
    case VehicleEquipmentActionTypes.VehicleEquipmentUniversalCategoryCodesLoadError:
      return produce(state, newState => {
        newState.loading = false;
      });

    case VehicleEquipmentActionTypes.VehicleResetEquipmentListToDefault:
      return produce(state, newState => {
        newState.loading = true;
      });
    case VehicleEquipmentActionTypes.VehicleResetEquipmentListToDefaultSuccess:
    case VehicleEquipmentActionTypes.VehicleResetEquipmentListToDefaultError:
      return produce(state, newState => {
        newState.loading = false;
      });
  }
  return {
    ...state,
    formulaWizard: formulaWizardReducer(state.formulaWizard, action),
  };
}
