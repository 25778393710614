import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CaseStatus, CaseSummaryDto, ProvenanceCheckDetailsDto, ProvenanceCheckStatus } from '@vpfa/rest-api/valuation';
import { AdjustmentsFacade } from '@vpfa/dealer/case/data';
import { AmsPermissions } from '@vpfa/rest-api/admin';
import { camelCase, isNil } from 'lodash/fp';

@Component({
  selector: 'vpfa-price-adjustments-container-mobile',
  templateUrl: './price-adjustments-container-mobile.component.html',
  styleUrls: ['./price-adjustments-container-mobile.component.scss'],
})
export class PriceAdjustmentsContainerMobileComponent {
  @Input() isLoading: boolean;

  @Input() canMakeValuation: boolean;
  @Input() valuationNotAvailable: boolean;

  @Input() vehicleDetailsValue: string;
  @Input() repairAndMaintenanceValue: string;
  @Input() ownersCount: number;
  @Input() prospectCustomersCount: number;
  @Input() previousOwnersCount: any;
  @Input() caseStatus: CaseStatus;
  @Input() permissions: AmsPermissions;
  @Input() provenanceCheckData: ProvenanceCheckDetailsDto;

  @Output() openVehicleDetails = new EventEmitter();
  @Output() openRepairAndMaintenance = new EventEmitter();
  @Output() openOwners = new EventEmitter();
  @Output() openProspectCustomers = new EventEmitter();
  @Output() openPreviousOwners = new EventEmitter();
  @Output() openNotes = new EventEmitter();
  @Output() openEquipment = new EventEmitter();
  @Output() openProvenanceCheck = new EventEmitter();
  CaseStatus = CaseStatus;

  equipmentTotal$ = this.adjustmentsFacade.getTotalEquipmentDeduction$;

  constructor(private adjustmentsFacade: AdjustmentsFacade) {}

  get status(): ProvenanceCheckStatus {
    return this.provenanceCheckData ? this.provenanceCheckData.status : ProvenanceCheckStatus.NotChecked;
  }

  get statusMessageKey(): string {
    return !isNil(ProvenanceCheckStatus[this.status])
      ? `provenanceCheck.statuses.${camelCase(ProvenanceCheckStatus[this.status])}`
      : '';
  }

  get statusClass(): string {
    switch (this.status) {
      case ProvenanceCheckStatus.AllClear:
        return `all-clear`;
      case ProvenanceCheckStatus.Caution:
        return `caution`;
      case ProvenanceCheckStatus.Warning:
        return `warning`;
      default:
        return '';
    }
  }

  get notAvailable() {
    return !this.canMakeValuation || this.valuationNotAvailable;
  }
}
