import { EquipmentInfo, EquipmentInfoEquipmentErrorDto } from '@vpfa/rest-api/valuation';
import { chain, differenceWith } from 'lodash';
import { isEqual } from 'lodash';
import { SideChanges } from '../../../models/vehicle-equipment-formula-step';

export const infosThatAutoEnabledSideChanges = [EquipmentInfo.AutoSelectedPackageContentEquipment];

export const infosThatAutoDisablesSideChanges = [
  EquipmentInfo.AutoDeselectedPackageContentEquipment,
  EquipmentInfo.AutoDeselectedPackageFormulaEquipment,
  EquipmentInfo.AutoDeselectedStandardEquipment,
];

export function getSideChanges(
  equipmentInfos: EquipmentInfoEquipmentErrorDto[],
  resolvedStepsSideChanges: SideChanges[]
): SideChanges[] {
  const autoSelected = chain(equipmentInfos)
    .filter(info => infosThatAutoEnabledSideChanges.includes(info.error))
    .map(info => info.soaCodes)
    .flatMap()
    // auto-enable
    .map(soaCode => ({ soaCode, isEnabled: true, isNotAffectSelection: true } as SideChanges))
    .value();

  const autoDeselected = chain(equipmentInfos)
    .filter(info => infosThatAutoDisablesSideChanges.includes(info.error))
    .map(info => info.soaCodes)
    .flatMap()
    // auto-disable
    .map(soaCode => ({ soaCode, isEnabled: false }))
    .value();

  const sideChanges = differenceWith([...autoSelected, ...autoDeselected], resolvedStepsSideChanges, isEqual);
  return sideChanges;
}
