import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromProfile from './+state/profile.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ProfileEffects } from './+state/profile.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromProfile.PROFILE_FEATURE_KEY, fromProfile.reducer),
    EffectsModule.forFeature([ProfileEffects])
  ],
})
export class ProfileDataModule {}
