<ng-container *ngIf="!parentFormGroup || !fc; else elseEnabledControlTemplate">
  <nz-switch [nzDisabled]="true"></nz-switch>
</ng-container>
<ng-template #elseEnabledControlTemplate>
  <ng-container [formGroup]="parentFormGroup">
    <nz-switch
      [ngClass]="{ controlled: controlled }"
      [nzDisabled]="disabled"
      [formControl]="fc"
      (ngModelChange)="onSwitched($event)"
    ></nz-switch>
  </ng-container>
</ng-template>
