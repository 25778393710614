import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isNil } from 'lodash';
import { Subscription } from 'rxjs';

/**
 * To be used with "from", "to" or "min" and "max" filters. Checks for contradicting values.
 *
 * Do not use on both fields, as it will popup on both at the same time and will cause infinite loop error.
 */
export function numberFromToValidator(
  secondControlNameToCompare: string,
  direction: 'fromTo' | 'toFrom' = 'fromTo'
): ValidatorFn {
  return (firstControl: AbstractControl): ValidationErrors | null => {
    if (isNil(firstControl.value)) {
      return null;
    }

    const secondControl = firstControl.root.get(secondControlNameToCompare);

    if (secondControl) {
      const subscription: Subscription = secondControl.valueChanges.subscribe(() => {
        firstControl.updateValueAndValidity();
        subscription.unsubscribe();
      });
    }

    const firstControlValue = firstControl?.value?.value ?? firstControl?.value;
    const secondControlValue = secondControl?.value?.value ?? secondControl?.value;

    if (!firstControlValue || !secondControlValue) {
      return null;
    }

    if (direction === 'fromTo' && Number(firstControlValue) > Number(secondControlValue)) {
      return { fromTo: true };
    }

    if (direction === 'toFrom' && Number(firstControlValue) < Number(secondControlValue)) {
      return { fromTo: true };
    }

    return null;
  };
}
