import { concat, NEVER, Observable, OperatorFunction } from 'rxjs';
import { buffer, mergeAll, publish, take } from 'rxjs/operators';

/**
 * Source: https://ncjamieson.com/how-to-write-delayuntil/
 *
 * @param notifier
 */
export const delayUntil = <T>(notifier: Observable<any>): OperatorFunction<T, T> => {
  return source =>
    source.pipe(
      publish(published => concat(concat(published, NEVER).pipe(buffer(notifier), take(1), mergeAll()), published))
    );
};
