import { Component, Input, ChangeDetectionStrategy, TemplateRef } from '@angular/core';

@Component({
  selector: 'vpfa-mobile-subtitle',
  templateUrl: './mobile-subtitle.component.html',
  styleUrls: ['./mobile-subtitle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileSubtitleComponent {
  @Input() title: string;
  @Input() tooltipContent: string | TemplateRef<void>;
}
