import { createFeatureSelector, createSelector } from '@ngrx/store';
import { broadcastFeatureKey, BroadcastSettingsState } from './broadcast.reducer';

const getBroadcastSettingsState = createFeatureSelector<BroadcastSettingsState>(broadcastFeatureKey);

const getBroadcastSettings = createSelector(
  getBroadcastSettingsState,
  (state: BroadcastSettingsState) => state.settings
);

const getIsLoadingSettings = createSelector(
  getBroadcastSettingsState,
  (state: BroadcastSettingsState) => state.isLoading
);

const getIsCreatingAdvert = createSelector(
  getBroadcastSettingsState,
  (state: BroadcastSettingsState) => state.isCreatingAdvert
);

const getIsUpdateError = createSelector(
  getBroadcastSettingsState,
  (state: BroadcastSettingsState) => state.updateError
);

const getBroadcastingItemLogo = createSelector(
  getBroadcastSettingsState,
  (state: BroadcastSettingsState, adPortalId: string) => state.logo[adPortalId]?.item
);

const getIsBroadcastingItemLogoLoading = createSelector(
  getBroadcastSettingsState,
  (state: BroadcastSettingsState, adPortalId: string) => state.logo[adPortalId].isLoading
);

const getAdPortalCaseBroadcastRequestHistoryList = createSelector(
  getBroadcastSettingsState,
  (state: BroadcastSettingsState) => state.adPortalRequestHistory.items
);

const getIsAdPortalCaseBroadcastRequestHistoryListLoading = createSelector(
  getBroadcastSettingsState,
  (state: BroadcastSettingsState) => state.adPortalRequestHistory.isLoading
);

const getIsAdPortalCaseBroadcastStatusListLoading = createSelector(
  getBroadcastSettingsState,
  (state: BroadcastSettingsState) => state.broadcastAdPortalsStatusList.isLoading
);

const getAdPortalCaseBroadcastStatusList = createSelector(
  getBroadcastSettingsState,
  (state: BroadcastSettingsState) => state.broadcastAdPortalsStatusList.items
);

const getActiveCaseId = createSelector(
  getBroadcastSettingsState,
  (state: BroadcastSettingsState) => state.activeCaseId
);

const getConfirmModalOpen = createSelector(getBroadcastSettingsState, state => state.confirmUpdateModal?.open);

export const broadcastQuery = {
  getBroadcastSettings,
  getIsLoadingSettings,
  getIsCreatingAdvert,
  getIsUpdateError,
  getBroadcastingItemLogo,
  getIsBroadcastingItemLogoLoading,
  getAdPortalCaseBroadcastRequestHistoryList,
  getIsAdPortalCaseBroadcastRequestHistoryListLoading,
  getIsAdPortalCaseBroadcastStatusListLoading,
  getAdPortalCaseBroadcastStatusList,
  getActiveCaseId,
  getConfirmModalOpen,
};
