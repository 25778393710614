<vpfa-simple-modal
  modalName="Full Spec and Equipment List"
  (closeModal)="onClose()"
  [showBottomClose]="true"
  [isVisible]="isVisible"
  [showBackdrop]="true"
  [width]="'60vw'"
  class="full-eq-modal"
>
  <h1>{{ 'fullTechSpecAndEquipmentListModal.vehicleDetails' | translate | uppercase }}</h1>
  <h3 class="edition">{{ vehicleTechSpecData?.edition }}</h3>
  <ul nz-menu nzMode="horizontal" fxLayout="row wrap">
    <li nz-menu-item (click)="changeTab(tab.technicalData)" [nzSelected]="activeTab === tab.technicalData">
      {{ 'vehicleFullDataModal.technicalData' | translate }}
    </li>
    <li nz-menu-item (click)="changeTab(tab.packageEquipment)" [nzSelected]="activeTab === tab.packageEquipment">
      {{ 'vehicleFullDataModal.packageEquipment' | translate }}
    </li>
    <li nz-menu-item (click)="changeTab(tab.optionalEquipment)" [nzSelected]="activeTab === tab.optionalEquipment">
      {{ 'vehicleFullDataModal.optionalEquipment' | translate }}
    </li>
    <li nz-menu-item (click)="changeTab(tab.standardEquipment)" [nzSelected]="activeTab === tab.standardEquipment">
      {{ 'vehicleFullDataModal.standardEquipment' | translate }}
    </li>
    <li
      nz-menu-item
      (click)="changeTab(tab.unavailableEquipment)"
      [nzSelected]="activeTab === tab.unavailableEquipment"
    >
      {{ 'vehicleFullDataModal.unavailableEquipment' | translate }}
    </li>
  </ul>
  <vpfa-vehicle-list-full-technical-data
    *ngIf="vehicleTechSpecData && activeTab === tab.technicalData"
    [vehicleData]="vehicleTechSpecData"
    [technicalDataFeatureConfig]="technicalDataFeatureConfig"
    [emissionClassOptions]="emissionClassOptions"
  ></vpfa-vehicle-list-full-technical-data>
  <vpfa-vehicle-special-equipment-list
    *ngIf="packageEquipmentList && activeTab === tab.packageEquipment"
    [equipmentData]="packageEquipmentList"
    [showEquipmentShortDescription]="showEquipmentShortDescription"
  >
  </vpfa-vehicle-special-equipment-list>
  <vpfa-vehicle-special-equipment-list
    *ngIf="optionalEquipmentList && activeTab === tab.optionalEquipment"
    [equipmentData]="optionalEquipmentList"
  >
  </vpfa-vehicle-special-equipment-list>
  <vpfa-vehicle-special-equipment-list
    *ngIf="standardEquipmentList && activeTab === tab.standardEquipment"
    [equipmentData]="standardEquipmentList"
  >
  </vpfa-vehicle-special-equipment-list>
  <vpfa-vehicle-special-equipment-list
    *ngIf="unavailableEquipmentList && activeTab === tab.unavailableEquipment"
    [equipmentData]="unavailableEquipmentList"
  >
  </vpfa-vehicle-special-equipment-list>
</vpfa-simple-modal>
