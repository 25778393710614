import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, TemplateRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { isNil } from 'lodash';
import { SelectOption } from '../../interfaces';
import { GroupLabels, SelectMode } from '../select/select.component';
import { NzFilterOptionType } from 'ng-zorro-antd/select';

@Component({
  selector: 'vpfa-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent implements OnDestroy {
  @Input() placeholder: string;
  @Input() options: SelectOption[];
  @Input() loading: boolean;
  @Input() showGroups: boolean;
  @Input() disabled = false;
  @Input() maskSensitiveData = false;
  @Input() customOptionTemplate: TemplateRef<{ label: string; option: any; additional?: any }>;
  @Input() searchFilter: NzFilterOptionType;
  @Input() useInfiniteScroll: boolean;
  @Input() useServerSideSearch = false;

  @Output() loadNextPage = new EventEmitter();
  @Output() onSearch = new EventEmitter();
  @Output() selected = new EventEmitter<SelectOption>();

  onDestroy$ = new Subject<void>();

  formGroup = this.fb.group({ selectedValue: [null, [Validators.required]] });
  SelectMode = SelectMode;

  constructor(private fb: UntypedFormBuilder) {}

  onSelected(selectedOption: SelectOption) {
    this.selected.emit(selectedOption);

    if (!isNil(selectedOption) && !this.afterMakingSelectionClearControlShouldBeStopped(selectedOption)) {
      this.formGroup.setValue({ selectedValue: null });
    }
  }

  onLoadNextPage() {
    this.loadNextPage.emit();
  }

  /**
   * By default control will be cleared (its value set to null) after selecting option,
   * it will not happen when group label is `GroupLabels.branches`
   */
  private afterMakingSelectionClearControlShouldBeStopped(selectedOption: SelectOption): boolean {
    return !isNil(selectedOption) && [GroupLabels.branches].includes(selectedOption?.groupLabel as GroupLabels);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
