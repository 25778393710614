<button
  *ngIf="!outline"
  [disabled]="disabled"
  (click)="onClick($event)"
  [type]="buttonType"
  [ngClass]="{ 'button-arrow': arrow, 'button-no-border': !border }"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
  <span *ngIf="arrow" [ngClass]="{ 'arrow-disabled': disabled }" class="zmdi zmdi-long-arrow-right"></span>
</button>
<button
  *ngIf="outline"
  [disabled]="disabled"
  (click)="onClick($event)"
  [type]="buttonType"
  [ngClass]="{ 'button-outline': outline, 'button-arrow': arrow, 'button-no-border': !border }"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
  <span *ngIf="arrow" [ngClass]="{ 'arrow-disabled': disabled }" class="zmdi zmdi-long-arrow-right"></span>
</button>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
