import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'vpfa-vehicle-technical-data-interval-number-field',
  templateUrl: './vehicle-technical-data-interval-number-field.component.html',
  styleUrls: ['./vehicle-technical-data-interval-number-field.component.scss'],
})
export class VehicleTechnicalDataIntervalNumberFieldComponent {
  @Input() label: string;
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() fcName: string;
  @Input() maxFractionNumber = 1;
  @Input() errorMessages: ReadonlyArray<{ error: string; errorMsg: string }>;

  get readOnlyValue() {
    return this.parentFormGroup.get(this.fcName).value;
  }
}
