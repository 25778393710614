import { DateTime } from 'luxon';
import { VehicleDto } from '@vpfa/rest-api/identification';
import { isNil } from 'lodash';

export function minProductionBegin(dateToCompare: string, productionBeginDiff: number) {
  return DateTime.fromISO(dateToCompare)
    .startOf('month')
    .plus({ months: productionBeginDiff });
}

export function maxProductionEnd(dateToCompare: string, productionEndDiff: number) {
  return DateTime.fromISO(dateToCompare)
    .startOf('month')
    .minus({ months: productionEndDiff });
}

export function isValidProdDate(vehicle: VehicleDto, minProdBegin: DateTime, maxProdEnd: DateTime): boolean {
  const isStillProduced = isNil(vehicle.technicalData.prodend);
  return (
    minProdBegin.valueOf() >= DateTime.fromISO(vehicle.technicalData.prodbegin).valueOf() &&
    (isStillProduced || maxProdEnd.valueOf() <= DateTime.fromISO(vehicle.technicalData.prodend).valueOf())
  );
}
