<div ngClass.lt-mv="no-hor-scroll">
  <vpfa-mobile-page-title [title]="'vehicleOverviewData.mobile.title' | translate"></vpfa-mobile-page-title>
  <vpfa-mobile-subtitle [title]="'vehicleOverviewData.mobile.editMileage' | translate"></vpfa-mobile-subtitle>

  <form [formGroup]="mileageForm" (ngSubmit)="onSave()">
    <vpfa-setting-box [divider]="false">
      <ng-container label>
        <vpfa-label [text]="'vehicleOverviewData.mileage' | translate"></vpfa-label>
      </ng-container>
      <ng-container content>
        <vpfa-mobile-field-validation-container
          [validationControl]="mileageForm.get('mileage')"
          [errorMessages]="mileageErrorMessages"
        >
          <input
            id="editMileage"
            [maxCharCount]="7"
            vpfaCharCountLimiter
            nz-input
            [allowNegativeNumber]="false"
            [convertNegativeValue]="false"
            [formControl]="mileageForm.get('mileage')"
            placeholder="{{ 'search.enterNumber' | translate }}"
            vpfaNumberFormatter
          />
        </vpfa-mobile-field-validation-container>
      </ng-container>
    </vpfa-setting-box>
    <div class="mt">
      <vpfa-action-button
        id="saveMileageChanges"
        buttonType="submit"
        [disabled]="!mileageForm.dirty || mileageForm.invalid"
      >
        {{ 'common.save' | translate }}
      </vpfa-action-button>
      <vpfa-action-button-with-confirmation
        id="cancelMileageChanges"
        [showPopconfirm]="!mileageForm.pristine"
        (onConfirm)="onClose()"
      ></vpfa-action-button-with-confirmation>
    </div>
  </form>
</div>
