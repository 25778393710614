<div>
  <ng-container [ngSwitch]="displayedContent">
    <div *ngSwitchCase="'equipmentOverview'">
      <ng-container *ngTemplateOutlet="equipmentOverview"></ng-container>
    </div>
  </ng-container>
</div>

<ng-template #equipmentOverview>
  <div>
    <div class="no-hor-scroll">
      <vpfa-mobile-page-title [title]="'caseValuationDetails.equipment.title' | translate"></vpfa-mobile-page-title>

      <div class="m-total">
        <vpfa-mobile-total-summary
          id="equipmentAdjustmentTotalValue"
          [totalValue]="
            (missingEquipmentDeduction$ | async) + (additionalEquipment$ | async)
              | currencyLocale: null:null:'1.2-2'
              | lT
          "
          [notAvailable]="notAvailable"
        >
          <vpfa-mobile-total-summary-item
            id="equipmentAdjustmentAdditionalEquipmentValue"
            [label]="'vehicleConditionValuation.additionalEquipment' | translate"
            [value]="additionalEquipment$ | async | currencyLocale: null:null:'1.2-2' | lT"
            [notAvailable]="notAvailable"
          ></vpfa-mobile-total-summary-item>
        </vpfa-mobile-total-summary>
      </div>

      <vpfa-action-button id="equipmentAdjustmentPageButton" (click)="goToEquipment()">
        {{ 'equipmentList.title' | translate }}
      </vpfa-action-button>
    </div>

    <div class="sticky-btn">
      <vpfa-mobile-action-button id="equipmentAdjustmentBackButton" (click)="closeAction.emit()">
        {{ 'common.back' | translate }}
      </vpfa-mobile-action-button>
    </div>
  </div>
</ng-template>
