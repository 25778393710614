import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingBoxComponent } from './components/setting-box/setting-box.component';
import { UiKitRequiredSymbolModule } from '@vpfa/ui-kit/required-symbol';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { FlexModule } from '@ngbracket/ngx-layout';

@NgModule({
  imports: [CommonModule, UiKitRequiredSymbolModule, NzDividerModule, FlexModule],
  declarations: [SettingBoxComponent],
  exports: [SettingBoxComponent],
})
export class UiKitSettingBoxModule {}
