import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ConfirmAdvertisedImagesState,
  CONFIRM_ADVERTISED_IMAGES_FEATURE_KEY,
} from './confirm-advertised-images.reducer';

const getConfirmAdvertisedImagesState = createFeatureSelector<ConfirmAdvertisedImagesState>(
  CONFIRM_ADVERTISED_IMAGES_FEATURE_KEY
);

const getShowModal = createSelector(
  getConfirmAdvertisedImagesState,
  (state: ConfirmAdvertisedImagesState) => state.modal.show
);
const getAdvertisementQueue = createSelector(
  getConfirmAdvertisedImagesState,
  (state: ConfirmAdvertisedImagesState) => state.advertisementQueue
);
const getModalData = createSelector(
  getConfirmAdvertisedImagesState,
  (state: ConfirmAdvertisedImagesState) => state.modal.data
);

export const confirmAdvertisedImagesQuery = {
  getShowModal,
  getAdvertisementQueue,
  getModalData,
};
