import { Directive, ElementRef, Renderer2, Input, OnInit, AfterViewInit } from '@angular/core';
import { environment } from '@env/environment';

/**
 * Directive that creates unique identification for element:
 *
 *  - HTML ID attribute for automated testing
 *  - Data attribute for DataDog action name - if user clicks on element with this attribute (or its children) DataDog
 *    will register ID instead of its text. Useful for privacy like surnames, addresses etc. and in places where
 *    DataDog is registering clicks incorrectly.
 */
@Directive({
  selector: '[uId]',
})
export class UniqueIdDirective implements OnInit, AfterViewInit {
  @Input('uId') uId: string;

  constructor(public renderer: Renderer2, public hostElement: ElementRef) {}
  ngOnInit() {
    this.renderer.setAttribute(this.hostElement.nativeElement, 'id', this.uId);
    this.renderer.setAttribute(this.hostElement.nativeElement, 'data-dd-action-name', this.uId);
  }

  ngAfterViewInit() {
    this.detectIfActionIdIsUnique();
  }

  private detectIfActionIdIsUnique() {
    // it can degrade performance a bit, so it is disabled in production.
    if (environment.production) {
      return;
    }

    // Not really possible to hook on fully rendered page (and when change detection is settled), so we will
    // just wait some time. Should be enough to catch most of the cases.
    setTimeout(() => {
      const elementsWithSameIds = document.querySelectorAll(`[id="${this.uId}"]`);

      if (elementsWithSameIds.length > 1) {
        console.error(`actionId: "${this.uId}" is not unique`, elementsWithSameIds);
      }
    }, 2000);
  }
}
