import { Pipe, PipeTransform } from '@angular/core';
import { TaxType } from '@vpfa/rest-api/valuation';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash';
@Pipe({
  name: 'taxTypeLocale',
})
export class TaxTypeLocalePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(value: TaxType): any {
    if (!isNil(value)) {
      return this.translateService.instant(`stock.list.taxTypes.${TaxType[value]}`);
    } else {
      return this.translateService.instant('common.noValue');
    }
  }
}
