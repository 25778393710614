import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectOption } from '@vpfa/ui-kit';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'vpfa-unique-data-valuation-text-field-with-dropdown',
  templateUrl: './unique-data-valuation-text-field-with-dropdown.component.html',
  styleUrls: ['./unique-data-valuation-text-field-with-dropdown.component.scss'],
})
export class UniqueDataValuationTextFieldWithDropdownComponent {
  @Input() label: string;
  @Input() options: SelectOption[];
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() fcName: string;
  @Input() errorMessages: ReadonlyArray<{ error: string; errorMsg: string }>;
  @Input() isCustomValueProvided: BehaviorSubject<boolean>;
  @Input() showResetButton = false;
  @Input() resetButtonDisabled = false;
  @Input() resetButtonTooltip: string;

  @Output() selectedValue = new EventEmitter();
  @Output() resetValue = new EventEmitter();

  onSelectedValue(value: any) {
    this.selectedValue.emit(value);
  }

  onResetValue() {
    this.resetValue.emit();
  }
}
