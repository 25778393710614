<div
  [fxLayout]="'row wrap'"
  fxLayoutGap.gt-mv="70px grid"
  fxLayout.lt-mv="column"
  fxLayoutGap.lt-mv="10px"
  ngClass.gt-mv="section"
>
  <vpfa-vehicle-technical-data-select-field
    [label]="'vehicleFullTechnicalData.emissionClassLabel' | translate"
    [parentFormGroup]="formGroup"
    fcName="emissionClass"
    [selectOptions]="emissionClassOptions"
  ></vpfa-vehicle-technical-data-select-field>
</div>

<ng-container *vpfaResponsiveIf="'mobile'">
  <vpfa-mobile-subtitle
    [title]="'vehicleFullTechnicalData.fuelConsumption.nedc.nedcConsumptionAndEmission' | translate"
  ></vpfa-mobile-subtitle>
</ng-container>
<ng-container *vpfaResponsiveIf="'web'">
  <div class="mt">
    <vpfa-expanding-header
      [text]="'vehicleFullTechnicalData.fuelConsumption.nedc.nedcConsumptionAndEmission' | translate"
    ></vpfa-expanding-header>
  </div>
</ng-container>

<div
  [fxLayout]="'row wrap'"
  fxLayoutGap.gt-mv="70px grid"
  fxLayout.lt-mv="column"
  fxLayoutGap.lt-mv="10px"
  ngClass.gt-mv="section"
>
  <vpfa-vehicle-technical-data-select-field
    [label]="'vehicleFullTechnicalData.cO2EfficiencyClassLabel' | translate"
    [parentFormGroup]="formGroup.get('nedc')"
    fcName="efficiencyClass"
    [selectOptions]="efficiencyClassOptions"
    [hiddenOptions]="efficiencyClassHiddenOptions"
  ></vpfa-vehicle-technical-data-select-field>

  <vpfa-setting-box
    *vpfaResponsiveIf="'web'; else mobileOriginalCO2Efficiency"
    style="flex: 0 1 50%"
    [divider]="false"
    class="no-padding"
  >
    <ng-container label>
      <vpfa-label [text]="'vehicleFullTechnicalData.originalCO2EfficiencyClassLabel' | translate"></vpfa-label>
    </ng-container>
    <ng-container content>
      <span class="text-content">{{ getCo2EfficiencyClassTranslation() | translate | lT }}</span>
    </ng-container>
  </vpfa-setting-box>

  <vpfa-vehicle-technical-data-interval-number-field
    [label]="
      ('vehicleFullTechnicalData.fuelConsumption.nedc.urban' | translate) +
      (technicalDataNedc?.fuelConsumptionUrban | unitValue)
    "
    [parentFormGroup]="formGroup.get('nedc')"
    [fcName]="'urban'"
    [errorMessages]="errors"
  ></vpfa-vehicle-technical-data-interval-number-field>

  <vpfa-vehicle-technical-data-interval-number-field
    [label]="
      ('vehicleFullTechnicalData.fuelConsumption.nedc.extraUrban' | translate) +
      (technicalDataNedc?.fuelConsumptionExtraUrban | unitValue)
    "
    [parentFormGroup]="formGroup.get('nedc')"
    [fcName]="'extraUrban'"
    [errorMessages]="errors"
  ></vpfa-vehicle-technical-data-interval-number-field>

  <vpfa-vehicle-technical-data-interval-number-field
    [label]="
      ('vehicleFullTechnicalData.fuelConsumption.nedc.combined' | translate) +
      (technicalDataNedc?.fuelConsumptionOverallCombined | unitValue)
    "
    [parentFormGroup]="formGroup.get('nedc')"
    [fcName]="'combined'"
    [errorMessages]="errors"
  ></vpfa-vehicle-technical-data-interval-number-field>

  <vpfa-vehicle-technical-data-interval-number-field
    [label]="
      ('vehicleFullTechnicalData.fuelConsumption.nedc.cO2Emission' | translate) +
      (technicalDataNedc?.cO2EmissionCombined | unitValue)
    "
    [parentFormGroup]="formGroup.get('nedc')"
    [fcName]="'cO2Emissions'"
    [errorMessages]="co2Errors"
    [maxFractionNumber]="0"
  ></vpfa-vehicle-technical-data-interval-number-field>
</div>

<!-- EV Range and Consumption (NEDC) -->
<ng-container *vpfaResponsiveIf="'mobile'">
  <vpfa-mobile-subtitle
    [title]="'vehicleFullTechnicalData.fuelConsumption.eVRangeAndConsumptionNedc.label' | translate"
  ></vpfa-mobile-subtitle>
</ng-container>
<ng-container *vpfaResponsiveIf="'web'">
  <div class="mt">
    <vpfa-expanding-header
      [text]="'vehicleFullTechnicalData.fuelConsumption.eVRangeAndConsumptionNedc.label' | translate"
    ></vpfa-expanding-header>
  </div>
</ng-container>

<div
  [fxLayout]="'row wrap'"
  fxLayoutGap.gt-mv="70px grid"
  fxLayout.lt-mv="column"
  fxLayoutGap.lt-mv="10px"
  ngClass.gt-mv="section"
>
  <vpfa-vehicle-technical-data-interval-number-field
    [label]="
      ('vehicleFullTechnicalData.fuelConsumption.eVRangeAndConsumptionNedc.electricConsumption' | translate) +
      (technicalDataNedc?.electricPowerConsumption | unitValue)
    "
    [parentFormGroup]="formGroup.get('nedc')"
    [fcName]="'electricConsumption'"
    [errorMessages]="evRangeAndConsumptionErrors"
  ></vpfa-vehicle-technical-data-interval-number-field>
</div>

<ng-template #mobileOriginalCO2Efficiency>
  <vpfa-vehicle-technical-data-single-field
    [label]="'vehicleFullTechnicalData.originalCO2EfficiencyClassLabel' | translate"
    [value]="getCo2EfficiencyClassTranslation() | translate"
  ></vpfa-vehicle-technical-data-single-field>
</ng-template>
