import { Component, Input } from '@angular/core';

@Component({
  selector: 'vpfa-required-symbol',
  templateUrl: './required-symbol.component.html',
  styleUrls: ['./required-symbol.component.scss'],
})
export class RequiredSymbolComponent {
  @Input() disabled = false;
}
