import { Pipe, PipeTransform } from '@angular/core';
import { isString, isNumber } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

interface I18Type {
  id: string;
  texts: string;
}

@Pipe({
  name: 'lT',
  pure: false,
})
export class LTPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: I18Type | string | number | null, noValueTranslationKey = 'common.noValue'): any {
    if (isString(value) && value !== '') {
      return value;
    } else if (isNumber(value)) {
      return value;
    } else {
      return this.translateService.instant(noValueTranslationKey);
    }
  }
}
