import { VehicleEquipmentFormulaStep } from '../../../models/vehicle-equipment-formula-step';
import { FormulaWizardState } from '../formula-wizard.reducer';
import { getCurrentFormula } from './get-current-formula';

export function getCurrentStep(state: FormulaWizardState, newState?: FormulaWizardState): VehicleEquipmentFormulaStep {
  const baseFormula = getCurrentFormula(state);

  // TODO: probably this `if`` is redundant because getCurrentFormula can handle situation when newState is null
  if (!Boolean(newState)) {
    return baseFormula.steps[baseFormula.currentStepIndex];
  }

  const recipeFormula = getCurrentFormula(state, newState);

  return recipeFormula.steps[baseFormula.currentStepIndex];
}
