<div fxLayout="row" class="margin-bottom-10"></div>
<div class="box-container">
  <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <div id="price-summary-tooltip" fxLayoutAlign="start center">
      <span class="label">{{ 'valuationResult.priceSummary' | translate }}</span>
      <vpfa-tooltip class="tooltip" [content]="setTooltipContent() | translate"></vpfa-tooltip>
    </div>
    <vpfa-inline-loader *ngIf="isLoading"></vpfa-inline-loader>
  </div>
  <vpfa-forecast-info-box *ngIf="isForecastAvailable" [forecastDate]="forecastDate"></vpfa-forecast-info-box>
  <vpfa-not-available-info-box *ngIf="notAvailable">
    {{ 'caseValuationDetails.' + notAvailableMessage | translate }}
  </vpfa-not-available-info-box>
  <div class="tax-des">
    <vpfa-tax-information [tax]="activeCaseDataTaxes"></vpfa-tax-information>
  </div>
  <div class="main" [ngClass]="{ 'old-tax': isOldVehicle }">
    <div class="margin-bottom-10" [fxLayout]="'row'" [fxLayoutAlign]="'space-between center'">
      <div [fxFlex]="'44%'">
        <span *ngIf="caseStatus === CaseStatus.DueInStock || caseStatus === CaseStatus.InStock" class="label">
          {{ priceLabels.askingPrice | translate }}
        </span>
        <span *ngIf="caseStatus === CaseStatus.Sold" class="label">{{ priceLabels.soldPrice | translate }}</span>
      </div>
      <div [fxFlex] class="tax-row">
        <vpfa-price-field-with-tax-section
          id="vehiclePrice"
          [history]="priceData.history"
          [price]="priceData.price"
          [tax]="priceData.tax"
          [name]="priceData.name"
          (showHistoricalPrices)="onShowHistoricalPrices($event)"
          [caseTax]="activeCaseDataTaxes"
        ></vpfa-price-field-with-tax-section>
      </div>
    </div>
    <vpfa-price-history-modal
      [(visible)]="historicalPricesModalVisible"
      [caseTax]="activeCaseDataTaxes"
      [prices]="priceHistoryOptions"
    ></vpfa-price-history-modal>
  </div>

  <div [fxLayout]="'column'">
    <nz-divider></nz-divider>
    <!-- Price Details Link -->
    <ng-container *ngIf="!hidePriceDetails">
      <div
        *ngIf="!notAvailable"
        id="priceDetailsLink"
        class="list-link"
        [ngClass]="{ disabled: isLoading }"
        (click)="onOpenPriceDetails()"
        [fxLayout]="'row'"
        [fxLayoutAlign]="'space-between'"
      >
        <span> {{ 'priceDetails.title' | translate }}</span>
        <span class="link-arrow zmdi zmdi-long-arrow-right"></span>
      </div>

      <!-- Price Details Link Not Available -->
      <vpfa-price-with-icon
        *ngIf="notAvailable"
        id="priceDetailsLinkNotAvailable"
        class="list-link disabled"
        [price]="'priceDetails.title' | translate"
        [color]="'orange'"
      ></vpfa-price-with-icon>

      <nz-divider></nz-divider>
    </ng-container>

    <!-- Forecast Link -->
    <vpfa-forecast
      id="forecastLink"
      [caseId]="caseId"
      [timezone]="timezone"
      [forecastDate]="forecastDate"
      [notAvailable]="notAvailable"
      [isDisabled]="caseStatus === CaseStatus.Sold"
    ></vpfa-forecast>
    <nz-divider></nz-divider>

    <!-- Expert Area Link -->
    <div
      id="expertAreaLink"
      class="list-link"
      (click)="goToExpertArea()"
      [fxLayout]="'row'"
      [fxLayoutAlign]="'space-between'"
      *ngIf="!hideExpertAreaLink"
    >
      <span>
        {{ 'stockPrices.expertArea' | translate }}
      </span>
      <span class="link-arrow zmdi zmdi-long-arrow-right"></span>
    </div>
    <nz-divider *ngIf="!hideExpertAreaLink"></nz-divider>

    <!-- Pricing Details History Link -->
    <div
      id="pricingDetailsHistoryLink"
      class="list-link"
      (click)="showPricingDetailsModal()"
      [fxLayout]="'row'"
      [fxLayoutAlign]="'space-between'"
    >
      <span>
        {{ 'stockPrices.pricingDetailsHistory' | translate }}
      </span>
      <span class="link-arrow zmdi zmdi-long-arrow-right"></span>
    </div>

    <!-- Offer History Link -->
    <ng-container *ngIf="!isSold">
      <nz-divider></nz-divider>

      <div
        id="offerHistoryLink"
        class="list-link"
        (click)="goToOfferHistory()"
        [fxLayout]="'row'"
        [fxLayoutAlign]="'space-between'"
      >
        <span>
          {{ 'stockPrices.offerHistory' | translate }}
        </span>
        <span class="link-arrow zmdi zmdi-long-arrow-right"></span></div
    ></ng-container>

    <vpfa-pricing-details-modal
      [isVisible]="pricingDetailsModalVisible"
      [notAvailable]="isOldVehicle"
      [caseId]="caseId"
      [caseStatus]="caseStatus"
      [activeCaseDataTaxes]="activeCaseDataTaxes"
      [valuationPrices]="valuationPrices"
      [stockPrices]="stockPrices"
      [soldPrices]="soldPrices"
      [loading]="isPricingDetailsLoading || caseLoading"
      (closeAction)="onClosePricingDetailsModal()"
      (updatePricingDetails)="onUpdatePricingDetails($event)"
    ></vpfa-pricing-details-modal>
  </div>
</div>
