<vpfa-section-loader>
  <div
    class="search-results"
    infiniteScroll
    [fromRoot]="true"
    [infiniteScrollContainer]="'.app-container'"
    [infiniteScrollThrottle]="throttle"
    (scrolled)="onScroll()"
  >
    <div *ngFor="let element of elements">
      <ng-container class="element" *ngTemplateOutlet="elementTemplate; context: { $implicit: element }"></ng-container>
    </div>
  </div>
</vpfa-section-loader>

<vpfa-empty *ngIf="!elements?.length && displayEmptyIfNoData && !isLoading"></vpfa-empty>

<div *ngIf="isLoading" class="d-flex justify-content-center mt-4" style="height: 50px">
  <nz-spin [nzSpinning]="true"></nz-spin>
</div>