import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromSorcIntegration from './+state/sorc-integration.reducer';
import { SorcIntegrationEffects } from './+state/sorc-integration.effects';
import { SorcIntegrationFacade } from './+state/sorc-integration.facade';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(fromSorcIntegration.SORC_INTEGRATION_FEATURE_KEY, fromSorcIntegration.reducer),
    EffectsModule.forFeature([SorcIntegrationEffects]),
  ],
  providers: [SorcIntegrationFacade],
})
export class SorcIntegrationModule {}
