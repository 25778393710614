import { Component, Input, TemplateRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'vpfa-data-table-settings',
  templateUrl: './data-table-settings.component.html',
  styleUrls: ['./data-table-settings.component.scss']
})
export class DataTableSettingsComponent {
  @Input() settingsTemplate: TemplateRef<any>;
  @Input() set visible(v: boolean) {
    this.popoverVisible = v;
    this.visibleChange.emit(this.popoverVisible);
  }

  @Output() visibleChange = new EventEmitter<boolean>();

  get visible(): boolean {
    return this.popoverVisible;
  }

  private popoverVisible: boolean;
}
