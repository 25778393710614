import { Pipe, PipeTransform } from '@angular/core';
import { UnitRangeValueDto, UnitTextValueDto, UnitValueDto } from '@vpfa/rest-api/valuation';
import { every, isNil } from 'lodash';

type UnitValueDtos = UnitValueDto | UnitTextValueDto | UnitRangeValueDto;

@Pipe({
  name: 'unitValue',
  pure: false,
})
export class UnitValuePipe implements PipeTransform {
  transform(fields: UnitValueDtos | UnitValueDtos[], uppercase = false): string {
    const fieldsArray = Array.isArray(fields) ? fields : [fields];
    const fieldsUnits = fieldsArray.map(field => (uppercase ? field?.unit?.toUpperCase() : field?.unit));

    if (every(fieldsUnits, unit => isNil(unit))) {
      return '';
    }

    return ` (${fieldsUnits.filter(x => !isNil(x)).join('/')})`;
  }
}
