<vpfa-mobile-page-title [title]="'common.notes' | translate" [tooltipContent]="TOOLTIP_CONTENT | translate">
  <div right class="edit-btn" *ngIf="!editing">
    <span (click)="openEdit()">{{ 'common.edit' | translate }}</span>
  </div>
</vpfa-mobile-page-title>

<div *ngIf="editing" class="no-hor-scroll">
  <nz-divider class="divider"></nz-divider>
  <div class="edit">{{ 'common.edit' | translate }}</div>
  <ng-container right-section>
    <span [ngClass]="{ invalid: notesForm.invalid }" class="char-counter"
      >{{ notesForm?.get('notes')?.value?.length || 0 }} / {{ textareaMaxLength }}
    </span></ng-container
  >
  <vpfa-textarea [parentFormGroup]="notesForm" [fcName]="'notes'" [minRows]="1" [maxRows]="50"> </vpfa-textarea>
</div>

<div *ngIf="editing" class="actions sticky-btn">
  <vpfa-action-button buttonType="submit" [disabled]="!notesForm.valid || notesForm.pristine" (click)="saveEdit()">
    {{ 'common.saveChanges' | translate }}
  </vpfa-action-button>
  <vpfa-action-button-with-confirmation
    [showPopconfirm]="!notesForm.pristine"
    (onConfirm)="cancelEdit()"
  ></vpfa-action-button-with-confirmation>
</div>

<div *ngIf="!editing">
  <div class="note no-hor-scroll">{{ notesForm.get('notes').value }}</div>
  <div class="back-button sticky-btn">
    <vpfa-action-button (click)="closeAction.emit()">
      {{ 'common.back' | translate }}
    </vpfa-action-button>
  </div>
</div>
