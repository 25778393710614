import { Component, ElementRef, ViewChild } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import { isEllipsisActive } from '@vpfa/utils';

@Component({
  selector: 'vpfa-table-header-tooltip-renderer',
  templateUrl: './table-header-tooltip-renderer.component.html',
  styleUrls: ['./table-header-tooltip-renderer.component.scss'],
})
export class TableHeaderTooltipRendererComponent implements IHeaderAngularComp {
  params: any;

  private readonly SORT_ICON_WIDTH = 16; // sort icon width with margin
  private readonly HEADER_SIDE_PADDING = 24; // header cell left & right padding

  @ViewChild('headerLabel', { read: ElementRef }) headerLabel: ElementRef;

  agInit(params: IHeaderParams): void {
    this.params = params;
  }

  refresh(params: IHeaderParams): boolean {
    return true;
  }

  get headerName(): string {
    return this.params.displayName;
  }

  get sortEnabled(): boolean {
    return this.params.enableSorting;
  }

  get sortAscending(): boolean {
    return this.params.column.isSortAscending();
  }

  get sortDescending(): boolean {
    return this.params.column.isSortDescending();
  }

  get sortNone(): boolean {
    return this.params.column.isSortNone();
  }

  get isEllipsisActive(): boolean {
    return this.headerLabel && isEllipsisActive(this.headerLabel?.nativeElement);
  }

  get headerLabelWidthStyle(): Partial<CSSStyleDeclaration> {
    const columnMaxWidth = this.params.column.getActualWidth() - this.HEADER_SIDE_PADDING;
    const sortWidth = columnMaxWidth - this.SORT_ICON_WIDTH;

    if (this.isEllipsisActive) {
      return this.sortNone ? { minWidth: '100%' } : { maxWidth: `${sortWidth}px` };
    }
    return null;
  }

  get maxWidth(): string {
    const columnMaxWidth = this.params.column.getActualWidth() - this.HEADER_SIDE_PADDING;
    return `${columnMaxWidth}px`;
  }

  onSortRequested(): void {
    this.params.progressSort();
  }
}
