<vpfa-section-loader [isLoading]="isLoading">
  <div>
    <vpfa-mobile-page-title [title]="'caseValuationDetails.provenanceCheck' | translate">
      <p right class="status" [ngClass]="statusClass">{{ statusMessageKey | translate }}</p>
    </vpfa-mobile-page-title>
    <div class="check-info">
      <p *ngIf="!provenanceCheckData; else lastCheckDate">{{ 'provenanceCheck.notRequestedInfo' | translate }}</p>
    </div>
    <ng-container *ngIf="canCheck">
      <p>
        <vpfa-switch
          [fc]="termsFormGroup.get('terms')"
          [parentFormGroup]="termsFormGroup"
          style="margin-right: 10px"
        ></vpfa-switch>
        {{ 'provenanceCheck.termsLabel.info' | translate }}
        <a [href]="termsUrl ? termsUrl : ''" target="_blank">{{ 'provenanceCheck.termsLabel.link' | translate }}</a>
      </p>

      <vpfa-action-button (click)="onCheckClick()" [disabled]="checkButtonDisable" *ngIf="canCheck">
        {{ buttonLabelKey | translate }}
      </vpfa-action-button>
    </ng-container>

    <div style="margin-top: 35px" *ngIf="provenanceCheckData" fxLayout="column" fxLayoutGap="35px">
      <div>
        <vpfa-mobile-subtitle
          vpfaSticky
          [title]="'provenanceCheck.vehicleConfirmationStatus.title' | translate"
        ></vpfa-mobile-subtitle>
        <vpfa-mobile-provenance-check-section-confirmation-status
          [vehicleConfirmation]="provenanceCheckData.vehicleConfirmation"
        ></vpfa-mobile-provenance-check-section-confirmation-status>
      </div>
      <div>
        <vpfa-mobile-subtitle
          vpfaSticky
          class="keepers-subtitle"
          [title]="'provenanceCheck.keepers.title' | translate"
        ></vpfa-mobile-subtitle>
        <vpfa-mobile-provenance-check-section-keepers
          [keepersChangeDetails]="provenanceCheckData?.keepersChangeDetails"
        ></vpfa-mobile-provenance-check-section-keepers>
      </div>
      <div>
        <vpfa-mobile-subtitle
          vpfaSticky
          [title]="'provenanceCheck.mileage.title' | translate"
        ></vpfa-mobile-subtitle>
        <vpfa-mobile-provenance-check-section-mileage
          [mileageDetails]="provenanceCheckData?.mileageDetails"
        ></vpfa-mobile-provenance-check-section-mileage>
      </div>
      <div>
        <vpfa-mobile-subtitle
          vpfaSticky
          [title]="'provenanceCheck.environmentalReportDetails.title' | translate"
        ></vpfa-mobile-subtitle>
        <vpfa-provenance-check-section-environmental
          [isMobile]="true"
          [co2Emission]="provenanceCheckData?.vehicleConfirmation?.cO2Emissions"
        ></vpfa-provenance-check-section-environmental>
      </div>
    </div>
  </div>

  <div class="sticky-btn">
    <vpfa-mobile-action-button (click)="close.emit()">
      {{ 'common.back' | translate }}
    </vpfa-mobile-action-button>
  </div>
</vpfa-section-loader>

<ng-template #lastCheckDate>
  <p>
    {{ 'provenanceCheck.checkTime' | translate }}: {{ provenanceCheckData?.dateOfLastCheck | dateLocale: 'medium' }}.
  </p>
</ng-template>