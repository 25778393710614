<vpfa-modal
  width="550px"
  modalName="Forecast"
  [showBackdrop]="true"
  [isVisible]="isVisible"
  [isClosable]="false"
  [keyboard]="false"
  [maskClosable]="false"
  (closeAction)="cancel()"
>
  <vpfa-modal-content>
    <button
      class="ant-modal-close"
      nz-button
      nzType="link"
      nz-popconfirm
      [nzPopconfirmTrigger]="'click'"
      [nzCondition]="forecastForm.pristine"
      [nzPopconfirmTitle]="'common.unsavedChanges' | translate"
      [nzCancelText]="'common.no' | translate"
      [nzOkText]="'common.yes' | translate"
      nzPopconfirmPlacement="bottom"
      nzPopconfirmOverlayClassName="modal-confirm-popover"
      (nzOnConfirm)="cancel()"
    >
      <i nz-icon nzType="close-circle" nzTheme="fill"></i>
    </button>
    <form (ngSubmit)="requestForecast()" [formGroup]="forecastForm">
      <h1>{{ 'forecastModal.title' | translate }}</h1>
      <nz-spin [nzSpinning]="isLoading">
        <div class="purchase-form-box">
          <vpfa-setting-box [divider]="false">
            <ng-container label>
              <vpfa-label [text]="'forecastModal.forecastDate' | translate"></vpfa-label>
            </ng-container>
            <ng-container content>
              <vpfa-iso-date-picker
                [allowClear]="false"
                [disabledDate]="disabledDate"
                [format]="'short' | localeDateFormat"
                [formControlName]="'forecastDate'"
              >
              </vpfa-iso-date-picker>
            </ng-container>
          </vpfa-setting-box>
        </div>
        <div class="quote-button-box" fxLayoutGap="10px">
          <vpfa-action-button
            buttonType="submit"
            [disabled]="!forecastForm.valid || sameDateCheck(forecastForm.get('forecastDate').value)"
          >
            {{ 'forecastModal.submit' | translate }}
          </vpfa-action-button>

          <vpfa-action-button
            [outline]="true"
            [border]="false"
            nz-popconfirm
            [nzCondition]="forecastForm.pristine"
            [nzPopconfirmTitle]="'common.unsavedChanges' | translate"
            [nzCancelText]="'common.no' | translate"
            [nzOkText]="'common.yes' | translate"
            nzPopconfirmPlacement="top"
            nzPopconfirmOverlayClassName="modal-confirm-popover"
            (nzOnConfirm)="cancel()"
          >
            {{ 'forecastModal.cancel' | translate }}
          </vpfa-action-button>
        </div>
      </nz-spin>
    </form>
  </vpfa-modal-content>
</vpfa-modal>
