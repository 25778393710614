<vpfa-mobile-subtitle
  [title]="'vehicleRetailMarketStats.retailMarket' | translate"
  [tooltipContent]="'tooltip.retailMarket' | translate"
>
  <vpfa-inline-loader right *ngIf="isLoading && !notAvailable"></vpfa-inline-loader>
</vpfa-mobile-subtitle>

<ng-container *ngIf="retailMarketDetails">
  <div class="stats-entry" *ngIf="!isInClientMode" [fxLayout]="'row'" [fxLayoutAlign]="'space-between flex-start'">
    <vpfa-label
      [text]="
        ('vehicleRetailMarketStats.liveRetailPrice' | translate) +
        ' (' +
        ('vehicleRetailMarketStats.gross' | translate) +
        ')'
      "
    >
    </vpfa-label>
    <ng-container
      [ngTemplateOutlet]="price"
      [ngTemplateOutletContext]="{ price: retailMarketDetails.liveRetailPrice, htmlId: 'retailMarketLiveRetailPrice' }"
    ></ng-container>
  </div>
  <nz-divider class="middle-divider" *ngIf="!isInClientMode"></nz-divider>

  <ng-container *ngIf="(showSellingSpotPrice$ | async) && !isInClientMode">
    <div class="stats-entry" [fxLayout]="'row'" [fxLayoutAlign]="'space-between flex-start'">
      <vpfa-label text="{{ 'vehicleRetailMarketStats.sellingRate' | translate }}"></vpfa-label>
      <ng-container
        [ngTemplateOutlet]="price"
        [ngTemplateOutletContext]="{
          price: retailMarketDetails.sellingSpotPrice,
          htmlId: 'retailMarketSellingSpotPrice'
        }"
      ></ng-container>
    </div>
    <nz-divider class="middle-divider"></nz-divider>
  </ng-container>

  <div class="stats-entry" [fxLayout]="'row'" [fxLayoutAlign]="'space-between flex-start'">
    <vpfa-label text="{{ 'vehicleRetailMarketStats.stockDays' | translate }}"></vpfa-label>
    <ng-container
      [ngTemplateOutlet]="value"
      [ngTemplateOutletContext]="{
        value: retailMarketDetails.averageTimeToSell,
        htmlId: 'retailMarketAverageTimeToSell'
      }"
    ></ng-container>
  </div>
  <nz-divider class="middle-divider"></nz-divider>

  <div class="stats-entry" [fxLayout]="'row'" [fxLayoutAlign]="'space-between flex-start'">
    <vpfa-label text="{{ 'vehicleRetailMarketStats.mileage' | translate }}"></vpfa-label>
    <ng-container
      [ngTemplateOutlet]="value"
      [ngTemplateOutletContext]="{
        value: retailMarketDetails.averageMileage,
        unit: mileageUnit,
        htmlId: 'retailMarketAverageMileage'
      }"
    ></ng-container>
  </div>
  <nz-divider class="middle-divider"></nz-divider>
  <div class="stats-entry" [fxLayout]="'row'" [fxLayoutAlign]="'space-between flex-start'">
    <vpfa-label text="{{ 'vehicleRetailMarketStats.desirability' | translate }}"></vpfa-label>
    <ng-container
      [ngTemplateOutlet]="desirability"
      [ngTemplateOutletContext]="{ htmlId: 'retailMarketDesirability' }"
    ></ng-container>
  </div>
</ng-container>
<ng-container *ngIf="!retailMarketDetails">
  <div class="no-zip-code">
    {{ 'vehicleRetailMarketStats.liveRetailNotAvailable' | translate }}
  </div>
</ng-container>

<ng-template #price let-price="price" let-htmlId="htmlId">
  <vpfa-value *ngIf="!notAvailable" [attr.id]="htmlId" [attr.data-price]="price">
    {{ price | currencyLocale | lT }}
  </vpfa-value>
  <vpfa-price-with-icon
    *ngIf="notAvailable"
    [attr.id]="htmlId"
    [attr.data-price]="price"
    [color]="'orange'"
    [price]="price | currencyLocale | lT"
  ></vpfa-price-with-icon>
</ng-template>

<ng-template #value let-value="value" let-unit="unit" let-htmlId="htmlId">
  <vpfa-value *ngIf="!notAvailable" [attr.id]="htmlId" [attr.data-value]="value">
    {{ value | numberLocale | lT }}{{ unit ? ' ' + unit : '' }}
  </vpfa-value>
  <vpfa-price-with-icon
    *ngIf="notAvailable"
    [attr.id]="htmlId"
    [attr.data-value]="value"
    [color]="'orange'"
    [price]="value | numberLocale | lT"
  ></vpfa-price-with-icon>
</ng-template>

<ng-template #desirability let-htmlId="htmlId">
  <vpfa-value
    *ngIf="!notAvailable"
    [attr.id]="htmlId"
    [attr.data-desirability]="retailMarketDetails.desirability"
    class="desirability"
  >
    {{ retailMarketDetails.desirability | lT }}
  </vpfa-value>
  <vpfa-price-with-icon
    *ngIf="notAvailable"
    [attr.id]="htmlId"
    [attr.data-desirability]="retailMarketDetails.desirability"
    [color]="'orange'"
    [price]="retailMarketDetails.desirability | lT"
  ></vpfa-price-with-icon>
</ng-template>
