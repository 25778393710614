<ng-container *ngIf="isEnabled$ | async; else content">
  <div [ngStyle]="style" fxLayoutAlign="center center" fxLayout="row wrap">
    <div>
      <div class="gradient-container" fxFlex="195px" fxLayoutAlign="space-between center">
        <div class="search-container">
          <span class="search-back-box"></span>
          <span class="search main zmdi zmdi-search-in-file"></span>
        </div>
        <span *ngFor="let idx of range; count as count" class="active secondary zmdi zmdi-file file"></span>
      </div>
    </div>
    <div fxFlex="100%" class="loading-info-text">{{ 'common.loader' | translate }}</div>
  </div>
</ng-container>
<ng-template #content>
  <ng-content></ng-content>
</ng-template>
