import { Component, Input, Output, EventEmitter, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { LightDamagePartDto } from '@vpfa/rest-api/valuation';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { CURRENCY_DISPLAY_FORMAT, SelectOption, UiKitSelectColorTheme } from '@vpfa/ui-kit';
import { Subject, Subscription } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { F_PRICE_MAX } from '@vpfa/shared/validators';

@Component({
  selector: 'vpfa-estimator-part',
  templateUrl: './estimator-part.component.html',
  styleUrls: ['./estimator-part.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EstimatorPartComponent implements OnDestroy {
  readonly costMaxValue = F_PRICE_MAX;

  uiKitSelectColorTheme = UiKitSelectColorTheme;

  @Input() set part(part: LightDamagePartDto) {
    if (part) {
      this.currentPart = part;
      this.damageTypes = part.damageTypes.map(damage => {
        return {
          name: damage.name,
          value: damage.id,
        };
      });
    }
  }
  @Input() submitted: boolean;
  @Input() set partForm(form: UntypedFormGroup) {
    this.formGroup = form;
    this.cost = this.formGroup.get('cost');
    this.damage = this.formGroup.get('selectedDamageTypeId');
    this.isIncludedInValuation = this.formGroup.get('isIncludedInValuation');

    this.setupSubscriptions();
  }
  @Output() costChanged = new EventEmitter<LightDamagePartDto>();

  CURRENCY_DISPLAY_FORMAT = CURRENCY_DISPLAY_FORMAT;
  currentPart: LightDamagePartDto;
  damageTypes: SelectOption[] = [];
  cost: AbstractControl;
  damage: AbstractControl;
  isIncludedInValuation: AbstractControl;
  formGroup: UntypedFormGroup;

  private subscriptions: Subscription[] = [];
  private onDestroy$ = new Subject<void>();

  private readonly maxVisibleLabel = 33;
  private readonly maxVisibleSelect = 13;

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  get isLabelOverlapping() {
    return this.currentPart.name.length > this.maxVisibleLabel;
  }

  get isSelectOverlapping() {
    const damage = this.damage && this.damage.value;
    return damage && damage.name.length > this.maxVisibleSelect;
  }

  private emitValueChange(value, isIncludedInValuation) {
    const newPart: LightDamagePartDto = {
      ...this.currentPart,
      cost: value,
      isIncluded: isIncludedInValuation,
    };
    this.costChanged.emit(newPart);
  }

  private setupSubscriptions() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
    this.subscriptions.push(
      this.cost.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.onDestroy$)).subscribe(value => {
        this.emitValueChange(value, this.isIncludedInValuation.value);
        this.damage.updateValueAndValidity();
      })
    );
    this.subscriptions.push(
      this.damage.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.onDestroy$)).subscribe(() => {
        this.cost.updateValueAndValidity();
      })
    );

    this.subscriptions.push(
      this.isIncludedInValuation.valueChanges
        .pipe(distinctUntilChanged(), takeUntil(this.onDestroy$))
        .subscribe(value => {
          this.emitValueChange(this.cost.value, value);
        })
    );
  }
}
