import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'vpfa-amount-box',
  templateUrl: './amount-box.component.html',
  styleUrls: ['./amount-box.component.scss']
})
export class AmountBoxComponent implements OnInit {
  @Input() amount: number;
  constructor() {}

  ngOnInit() {}
}
