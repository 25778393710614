import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { fromConfirmAdvertisedImagesActions } from './confirm-advertised-images.actions';
import { AddCaseImagesCommand } from '@vpfa/dealer/gallery-manager/data';
import { ConfirmAdvertisedImagesPartialState } from './confirm-advertised-images.reducer';
import { confirmAdvertisedImagesQuery } from './confirm-advertised-images.selectors';

@Injectable({ providedIn: 'root' })
export class ConfirmAdvertisedImagesFacade {
  advertisementQueue$ = this.store.pipe(select(confirmAdvertisedImagesQuery.getAdvertisementQueue));
  showModal$ = this.store.pipe(select(confirmAdvertisedImagesQuery.getShowModal));
  modalData$ = this.store.pipe(select(confirmAdvertisedImagesQuery.getModalData));

  constructor(private store: Store<ConfirmAdvertisedImagesPartialState>) {}

  uploadImagesAfterConfirmation(command: AddCaseImagesCommand, selectedForAdvertisement: { isSelected: boolean }[]) {
    this.store.dispatch(
      new fromConfirmAdvertisedImagesActions.UploadImagesAfterConfirmation({ command, selectedForAdvertisement })
    );
  }

  closeModal() {
    this.store.dispatch(new fromConfirmAdvertisedImagesActions.CloseConfirmationModal());
  }
}
