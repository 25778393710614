import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { mergeMap } from 'rxjs/operators';
import { EnvironmentsService } from '@vpfa/environments/data';
import {
  ACCEPT_HEADER_KEY,
  ACCEPT_HEADER_VALUE,
  AUTHORIZATION_HEADER_BEARER,
  AUTHORIZATION_HEADER_KEY,
  CONTENT_TYPE_HEADER_KEY,
  CONTENT_TYPE_HEADER_VALUE,
  X_AVH_REALM_HEADER_KEY,
  X_AVH_REALM_HEADER_VALUE,
} from '@vpfa/auth';
import { isNil } from 'lodash';

@Injectable({ providedIn: 'root' })
export class SorcIntegrationInterceptor implements HttpInterceptor {
  constructor(private jwtHelperService: JwtHelperService, private environmentsService: EnvironmentsService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const sorcUrl = this.environmentsService.config?.sorcUrl;
    if (!isNil(sorcUrl) && sorcUrl !== '' && req.url?.includes(sorcUrl)) {
      const token$ = from((this.jwtHelperService.tokenGetter() as any) as Promise<string>);
      return token$.pipe(
        mergeMap((asyncToken: string | null) => {
          const authReq = req.clone({
            setHeaders: {
              [ACCEPT_HEADER_KEY]: ACCEPT_HEADER_VALUE,
              [CONTENT_TYPE_HEADER_KEY]: CONTENT_TYPE_HEADER_VALUE,
              [X_AVH_REALM_HEADER_KEY]: X_AVH_REALM_HEADER_VALUE,
              [AUTHORIZATION_HEADER_KEY]: `${AUTHORIZATION_HEADER_BEARER} ${asyncToken}`,
            },
          });
          return next.handle(authReq);
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
