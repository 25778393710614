import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as provenanceCheckActions from './provenance-check.actions';
import { CaseService, CaseViewService } from '@vpfa/rest-api/valuation';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { LoadCase } from '../cases.actions';
import { CasesFacade } from '../cases.facade';

@Injectable()
export class ProvenanceCheckEffects {
  checkProvenance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(provenanceCheckActions.checkProvenance),
      withLatestFrom(this.caseFacade.activeCaseId$),
      switchMap(([_, activeCaseId]) =>
        this.caseService.provenanceCheck({ aggregateRootId: activeCaseId }).pipe(
          map(_ => provenanceCheckActions.checkProvenanceSuccess()),
          catchError(err => of(provenanceCheckActions.checkProvenanceError()))
        )
      )
    )
  );

  reloadCaseAfterCheckProvenance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(provenanceCheckActions.checkProvenanceSuccess),
      withLatestFrom(this.caseFacade.activeCaseId$),
      map(([_, caseId]) => this.caseFacade.updateCaseRequestValuation(caseId, true))
    ),
    { dispatch: false }
  );

  loadCO2EmissionRanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(provenanceCheckActions.loadCO2EmissionRanges),
      switchMap(() =>
        this.caseViewService.getCo2EmissionRanges().pipe(
          map(res => provenanceCheckActions.loadCO2EmissionRangesSuccess({ ranges: res })),
          catchError(() => of(provenanceCheckActions.loadCO2EmissionRangesError()))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private caseService: CaseService,
    private caseViewService: CaseViewService,
    private caseFacade: CasesFacade
  ) {}
}
