import { UntypedFormGroup } from '@angular/forms';
import { CaseStockUniqueDataSection, CaseValuationDataSection } from '@vpfa/rest-api/valuation';

export function convertValuationFormDataToUpdateCommand(formData: UntypedFormGroup): CaseValuationDataSection {
  return {
    mileageType: formData['mileageType']?.value ?? null,
    paintColour: formData['paintColour']?.value?.id ?? null,
    paintBrightness: formData['paintBrightness']?.value ?? null,
    paintType: formData['paintType']?.value ?? null,
    vehicleCategory: formData['vehicleCategory']?.value ?? null,
    registrationDocumentNumber: formData['registrationDocumentNumber'],
    keyNumber: formData['keyNumber'],
    manufacturerColor: formData['manufacturerColor'],
    trimType: formData['trimType'],
    interiorTrimColor: formData['interiorTrimColor']?.value?.id ?? null,
    damagedVehicle: formData['damagedVehicleType']?.value ?? null,
    isRoadworthy: formData['isRoadworthy'],
    hasUnrepairedDamage: formData['hasUnrepairedDamage'],
    hadAccident: formData['hadAccident'],
    branchId: formData['branchId']?.value ?? null,
  };
}

export function convertStockFormDataToUpdateCommand(formData: UntypedFormGroup): CaseStockUniqueDataSection {
  return {
    deregistrationDate: formData['deregistrationDate'],
    newRegistrationDate: formData['newRegistrationDate'],
    lastCustomerService: formData['lastCustomerService'],
    lastToothedBeltChange: formData['lastToothedBeltChange'],
    subsidiaryId: formData['subsidiaryId']?.value ?? null,
    branchLocation: formData['branchLocation'],
    ucNumber: formData['ucNumber'],
    typeOfUse: formData['typeOfUse']?.value ?? null,
    avTradeInDate: formData['avTradeInDate'],
  };
}
