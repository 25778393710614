import { Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { isNil } from 'lodash';

export const urlWithProtocol = 'urlWithProtocol';

export const urlWithProtocolValidator: ValidatorFn = (control: AbstractControl) => {
  const validateErrors = Validators.pattern(/^https?:\/\/[a-zA-Z0-9]+\.[^\s]{2,}/gm)(control);
  if (isNil(validateErrors)) {
    return null;
  }
  return {
    [urlWithProtocol]: true
  };
};
