import { Action } from '@ngrx/store';
import { IToken } from '../../models/token';
import { TokenDto } from '@vpfa/rest-api/admin';

export enum AuthTokenActionTypes {
  SET_TOKEN_FROM_DTO = '[Auth API] Set Token From dto',
  SET_TOKEN = '[Auth] Set Token',
  CLEAR_TOKEN = '[Auth] Clear Token',
  CLEAR_TOKEN_COMPLETED = '[Auth] Clear Token Completed',
}

export abstract class SetTokenFromDtoBase implements Action {
  abstract get type();
  constructor(public payload: TokenDto) {}
}

export abstract class ClearTokenBase implements Action {
  abstract get type();
  constructor(public payload?: { afterRedirectTo?: string }) {}
}

export abstract class SetTokenBase implements Action {
  abstract get type();
  constructor(public payload: IToken) {}
}

export class SetTokenFromDto extends SetTokenFromDtoBase {
  readonly type = AuthTokenActionTypes.SET_TOKEN_FROM_DTO;
}

export class SetToken extends SetTokenBase {
  readonly type = AuthTokenActionTypes.SET_TOKEN;
}

export class ClearToken extends ClearTokenBase {
  readonly type = AuthTokenActionTypes.CLEAR_TOKEN;
}

export class ClearTokenCompleted implements Action {
  readonly type = AuthTokenActionTypes.CLEAR_TOKEN_COMPLETED;
}

export type AuthTokenActions = SetTokenFromDto | SetToken | ClearToken | ClearTokenCompleted;
