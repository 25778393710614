/**
 * Public SSM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0-private
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 

export enum EquipmentInfo {

      AutoDeselectedStandardEquipment = 0,
      AutoSelectedPackageContentEquipment = 1,
      AutoDeselectedPackageContentEquipment = 2,
      AutoDeselectedPackageFormulaEquipment = 3,
      AutoSelectedPackageFormulaEquipment = 4,
      AutoDeselectedCombinationPricePackageContentEquipment = 5,
      AutoDeselectedStandardEquipmentIncompatibility = 6,

};
