<div fxLayout="row" fxLayoutGap="10px" [fxLayoutAlign]="layoutAlign">
  <div [fxFlex]="stepProgressTextPrefix ? '40' : '50'">
    <nz-progress
      [nzStatus]="status"
      [nzShowInfo]="showInfo"
      [nzWidth]="widthPx"
      [nzPercent]="getPercent()"
      nzStatus="active"
    ></nz-progress>
  </div>
  <div [fxFlex]="stepProgressTextPrefix ? '60' : '50'">
    <div *ngIf="!hasError; else errorTpl" fxLayout="row" fxLayoutAlign="start center">
      <div *ngIf="stepProgressTextPrefix; else noTextTpl" class="text">
        {{ stepProgressTextPrefix }} {{ stepsCompleted }} {{ 'progressbar.of' | translate }} {{ stepCount }}
      </div>
      <vpfa-inline-loader></vpfa-inline-loader>
    </div>
  </div>
</div>
<ng-template #errorTpl>{{ error }}</ng-template>
<ng-template #noTextTpl>
  <div *ngIf="!showInfo" class="text">{{ stepsCompleted }} {{ 'progressbar.of' | translate }} {{ stepCount }}</div>
</ng-template>
