import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { VPError } from '@vpfa/rest-api/valuation';
import { OAuthAuthorizeParams } from '../models/oauth-authorize-params';
import { OAuthAuthorizationResultDto } from '../models/oauth-result-dto';

export enum OAuthActionTypes {
  Authorize = '[OAuth] Authorize',
  AuthorizeSuccess = '[OAuth] Authorize Success',
  AuthorizeError = '[OAuth] Authorize Error',
  DenyAuthorize = '[OAuth] Deny Authorize',
}

export class Authorize implements Action {
  readonly type = OAuthActionTypes.Authorize;
  constructor(public payload: OAuthAuthorizeParams) {}
}

export class AuthorizeSuccess implements Action {
  readonly type = OAuthActionTypes.AuthorizeSuccess;
  constructor(public payload: OAuthAuthorizationResultDto) {}
}

export class AuthorizeError implements Action {
  readonly type = OAuthActionTypes.AuthorizeError;
  constructor(public payload: HttpErrorResponse) {}
}

export class DenyAuthorize implements Action {
  readonly type = OAuthActionTypes.DenyAuthorize;
  constructor(
    public payload: {
      redirectUrl: string;
      state: string;
    }
  ) {}
}

export type OAuthAction = Authorize | AuthorizeSuccess | AuthorizeError | DenyAuthorize;

export const fromOAuthActions = {
  Authorize,
  AuthorizeSuccess,
  AuthorizeError,
  DenyAuthorize,
};
