import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  MakesState,
  ModelsState,
  SORC_INTEGRATION_FEATURE_KEY,
  SorcIntegrationState,
  VehiclesState,
} from './sorc-integration.reducer';

const getSorcIntegrationState = createFeatureSelector<SorcIntegrationState>(SORC_INTEGRATION_FEATURE_KEY);

const getMakesState = createSelector(getSorcIntegrationState, (state: SorcIntegrationState) => state.makes);
const getMakesList = createSelector(getMakesState, (state: MakesState) => state.makesList);
const getMakesListLoading = createSelector(getMakesState, (state: MakesState) => state.loading);

const getModelsState = createSelector(getSorcIntegrationState, (state: SorcIntegrationState) => state.models);
const getModelsList = createSelector(getModelsState, (state: ModelsState) => state.modelsList);
const getModelsListLoading = createSelector(getModelsState, (state: ModelsState) => state.loading);

const getVehiclesState = createSelector(getSorcIntegrationState, (state: SorcIntegrationState) => state.vehicles);
const getVehiclesList = createSelector(getVehiclesState, (state: VehiclesState) => state.vehiclesList);
const getVehiclesListTotalResults = createSelector(getVehiclesState, (state: VehiclesState) => state.totalResults);
const getVehiclesOptionalSearchFiltersOptions = createSelector(
  getVehiclesState,
  (state: VehiclesState) => state.optionalSearchFiltersOptions
);
const getVehiclesListLoading = createSelector(getVehiclesState, (state: VehiclesState) => state.loading);

export const sorcIntegrationQuery = {
  getMakesList,
  getMakesListLoading,
  getModelsList,
  getModelsListLoading,
  getVehiclesList,
  getVehiclesListTotalResults,
  getVehiclesOptionalSearchFiltersOptions,
  getVehiclesListLoading,
};
