import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  InitLogoEffects,
  LogoActionTypes,
  LoadLogoForBusiness,
  LogoForBusinessLoaded,
  LogoForBusinessLoadError,
  LoadLogoForCountry,
  LogoForCountryLoadError,
  LogoForCountryLoaded
} from './logo.actions';
import { map, switchMap, catchError } from 'rxjs/operators';
import { LogoViewService } from '@vpfa/rest-api/admin';
import { mapFileToDataUrl } from '@vpfa/utils';
import { of } from 'rxjs';

@Injectable()
export class LogoEffects {
  
  loadLogoForBusiness = createEffect(() => this.actions$.pipe(
    ofType<LoadLogoForBusiness>(LogoActionTypes.LoadLogoForBusiness),
    switchMap(action =>
      this.logoViewService.getBusinessLogo(action.payload).pipe(
        switchMap((logoBlob: any) => mapFileToDataUrl([logoBlob, ''])),
        map(({ file }) => new LogoForBusinessLoaded(file)),
        catchError(err => of(new LogoForBusinessLoadError(err)))
      )
    )
  ));

  
  loadLogoForCountry = createEffect(() => this.actions$.pipe(
    ofType<LoadLogoForCountry>(LogoActionTypes.LoadLogoForCountry),
    switchMap(action =>
      this.logoViewService.getCountryLogo(action.payload).pipe(
        switchMap((logoBlob: any) => mapFileToDataUrl([logoBlob, ''])),
        map(({ file }) => new LogoForCountryLoaded(file)),
        catchError(err => of(new LogoForCountryLoadError(err)))
      )
    )
  ));

  ngrxOnInitEffects(): Action {
    return new InitLogoEffects();
  }

  constructor(private actions$: Actions, private logoViewService: LogoViewService) {}
}
