import { ActivatedRouteSnapshot } from '@angular/router';
import { isNil } from 'lodash';

export function getRouteParam(routeSnapshot: ActivatedRouteSnapshot, key: string): any {
  let paramVal;
  while(!isNil(routeSnapshot) && paramVal === undefined){
    if(routeSnapshot.paramMap.has(key)){
      paramVal = routeSnapshot.paramMap.get(key);
      continue;
    }

    routeSnapshot = routeSnapshot.parent;
  }

  return paramVal;
}