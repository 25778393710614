import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { formatDate, FormatWidth, getLocaleDateFormat } from '@angular/common';
import { Subject } from 'rxjs';
import { isEqual, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { LocaleFacade } from '@vpfa/locale';
import { filter, takeUntil } from 'rxjs/operators';
import { getOffsetFromDateTime } from '@vpfa/utils';

@Pipe({
  name: 'dateLocale',
  pure: false,
})
export class DateLocalePipe implements PipeTransform, OnDestroy {
  private _value: string | number | Date;
  private _format = 'shortDate';
  private _timezone: string;
  private _locale: string;
  private _lastValue: string;
  private _isMonthYearOnly: boolean;
  private _onDestroy$ = new Subject<void>();

  constructor(localeFacade: LocaleFacade, private _ref: ChangeDetectorRef) {
    localeFacade.locale$
      .pipe(
        filter(val => !isNil(val)),
        takeUntil(this._onDestroy$)
      )
      .subscribe(locale => {
        this._locale = locale;
        this.refreshValue();
      });

    localeFacade.timezone$
      .pipe(
        filter(val => !isNil(val)),
        takeUntil(this._onDestroy$)
      )
      .subscribe(timezone => {
        this._timezone = timezone;
        this.refreshValue();
      });
  }

  transform(
    value: string | number | Date,
    format = 'shortDate',
    timezone: string = null,
    locale: string = null,
    monthYearOnly: boolean = false
  ): any {
    let needRefresh = false;
    this._isMonthYearOnly = monthYearOnly;

    if (!isEqual(this._value, value)) {
      needRefresh = true;
      this._value = value;
    }

    if (!isEqual(this._format, format)) {
      needRefresh = true;
      this._format = format;
    }

    if (!isNil(locale)) {
      this._locale = locale;
    }

    if (!isNil(timezone)) {
      this._timezone = timezone;
    }

    if (needRefresh) {
      this.refreshValue();
    }

    return this._lastValue;
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private refreshValue() {
    if (isNil(this._locale) || isNil(this._value)) {
      if (!isNil(this._lastValue)) {
        this._lastValue = null;
        this._ref.markForCheck();
      }
      return;
    }
    let date: DateTime;
    switch (typeof this._value) {
      case 'string':
        date = DateTime.fromISO(this._value, { zone: this._timezone });
        break;
      case 'number':
        date = DateTime.fromMillis(this._value, { zone: this._timezone });
        break;
      default:
        date = DateTime.fromJSDate(this._value).setZone(this._timezone);
        break;
    }

    const dateString = date.toString();
    const offset = getOffsetFromDateTime(dateString);

    this._lastValue = formatDate(dateString, this._format, this._locale, offset);

    if (this._isMonthYearOnly && this._format === 'shortDate') {
      this.formatMonthYear();
    }

    this._ref.markForCheck();
  }

  private formatMonthYear() {
    const possibleDayFormats = ['d', 'dd'];
    let localeDateFormat = getLocaleDateFormat(this._locale, FormatWidth.Short);
    let separator = localeDateFormat.replace(/[A-Za-z]/g, '')?.charAt(0);
    let dayPosition = -1;

    localeDateFormat.split(separator).forEach((formatEl: string, index: number) => {
      if (possibleDayFormats.includes(formatEl)) {
        dayPosition = index;
      }
    });

    if (dayPosition !== -1) {
      let formatValue = this._lastValue.split(separator);
      formatValue.splice(dayPosition, 1);
      this._lastValue = formatValue.join(separator);
    }
  }
}
