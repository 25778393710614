import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import {
  CaseDocumentDto,
  CaseDueToInStockCommand,
  CaseFilesCommandHandlerResponse,
  CaseFuelSubtypeDto,
  CaseListItemDto,
  CaseLoadVinEreDataCommandHandlerResponse,
  CasePurchaseVehicleCommand,
  CaseReturnToStockCommand,
  CaseSellVehicleCommand,
  CaseSummaryDto,
  CommandHandlerResponse,
  CreateCaseQuoteCommand,
  CreateCaseStockOfferCommand,
  CreateStockOfferCommandHandlerResponse,
  EmissionInfoDto,
  EquipmentDto,
  LightDamageAreaDto,
  ResendCaseLatestQuoteCommand,
  ResendCaseLatestStockOfferCommand,
  ReturnReportDto,
  RoadTaxDataDto,
  StockStatus,
  UpdateCaseLightDamagePartsCommand,
  UpdateCaseNotesCommand,
  UpdateCaseOwnersCommand,
  UpdateCasePlateNumberCommand,
  UpdateCasePreviousOwnersCommand,
  UpdateCaseProspectCustomersCommand,
  UpdateCasePublicSiteConfigurationCommand,
  UpdateCaseRegistrationDateCommand,
  UpdateCaseResellerSiteConfigurationCommand,
  UpdateCaseReturnReportCommand,
  UpdateCaseSoldPricesCommand,
  UpdateCaseStockPricesCommand,
  UpdateCaseUniqueDataCommand,
  UpdateCaseValuationDateCommand,
  UpdateCaseVehicleMileageCommand,
  UpdateCaseYoutubeUrlCommand,
  UpdateIdentifiedVehicleFuelSubtypesCommand,
  UpdateIdentifiedVehicleKBACommand,
  UpdateIdentifiedVehicleTechnicalDataCommand,
  UpdateIdentifiedVehicleVinCommand,
  UpdateTwinnerImagesCommand,
  UpdateIdentifiedVehicleMatriculationNumberCommand,
} from '@vpfa/rest-api/valuation';

import { FileEntity } from '../models/file-entity';
import { CreateCaseBroadcastSettingsCommand } from '@vpfa/rest-api/ad-broadcast';
import { ValuationRequestPriceDetailsDto } from '@vpfa/rest-api/valuation';

export enum CasesActionTypes {
  ResetCaseData = '[Cases] Reset Case Data',
  LoadCases = '[Cases] Load Cases',
  CasesLoaded = '[Cases] Cases :: Loaded',
  CasesLoadError = '[Cases] Cases Load Error',
  ResetCaseLoadedStatus = '[Cases] Reset Case Loaded Status',

  LoadCaseDocumentList = '[Cases] Load Case Document List',
  CaseDocumentListLoaded = '[Cases] Cases :: Case Document List Loaded',
  CaseDocumentListLoadError = '[Cases] Cases Document List Load Error',
  ResetCaseDocumentList = '[Cases] Reset Case Document List',

  AddCaseDocument = '[Cases] Update :: Add Case Document',
  CaseDocumentAdded = '[Cases] Update :: Case Document Added',
  CaseDocumentAddError = '[Cases] Update :: Case Document Add Error',

  CaseDocumentUploadSetTotalCount = '[Cases] Update :: Case Document Upload Set Total Count',
  CaseDocumentUploadReset = '[Cases] Update :: Case Document Upload Reset',
  DeleteCaseDocument = '[Cases] Update :: Delete Case Document',
  CaseDocumentDeleted = '[Cases] Update :: Case Document Deleted',
  CaseDocumentDeleteError = '[Cases] Update :: Case Document Delete Error',

  DownloadCaseDocument = '[Cases] Download :: Download Case Document',
  CaseDocumentDownloaded = '[Cases] Download :: Case Document Downloaded',
  CaseDocumentDownloadError = '[Cases] Download :: Case Document Download Error',

  SetIsProcessingCaseDocumentForm = '[Cases] Set *isProcessingCaseDocumentForm*',
  SetHasCaseDocumentFormProcessingError = '[Cases] Set *hasCaseDocumentFormProcessingError*',

  UpdateCaseRequestValuation = '[Cases] Case Update Request :: Request Valuation',
  CaseUpdateRequestValuationCompleted = '[Cases] Case Update Request :: Valuation Completed',
  CaseUpdateRequestValuationError = '[Cases] Case Update Request :: Valuation Error',
  CancelUpdateCaseRequestValuation = '[Cases] Case Update Request :: Cancel Request Valuation',

  CaseUpdateSaveMileageAdjustment = '[Cases] Case Update :: Save Mileage Adjustment',
  CaseUpdateSaveMileageAdjustmentCompleted = '[Cases] Case Update :: Save Mileage Adjustment Completed',
  CaseUpdateSaveMileageAdjustmentError = '[Cases] Case Update :: Save Mileage Adjustment Error',

  CaseUpdateValuationDate = '[Cases] Case Update :: Save Valuation Date',
  CaseUpdateValuationDateCompleted = '[Cases] Case Update :: Save Valuation Date Completed',
  CaseUpdateValuationDateError = '[Cases] Case Update :: Save Valuation Date Error',

  CaseUpdateRegistrationDate = '[Cases] Case Update :: Save Registration Date',
  CaseUpdateRegistrationDateCompleted = '[Cases] Case Update :: Save Registration Date Completed',
  CaseUpdateRegistrationDateError = '[Cases] Case Update :: Save Registration Date Error',

  CaseUpdateVin = '[Cases] Case Update :: Update VIN',
  CaseUpdateVinCompleted = '[Cases] Case Update :: Update VIN Completed',
  CaseUpdateVinError = '[Cases] Case Update :: Update VIN Error',

  CaseUpdateMatriculationNumber = '[Cases] Case Update :: Update Matriculation Number',
  CaseUpdateMatriculationNumberCompleted = '[Cases] Case Update :: Update Matriculation Number Completed',
  CaseUpdateMatriculationNumberError = '[Cases] Case Update :: Update Matriculation Number Error',

  CaseUpdateSaveQuote = '[Cases] Case Update :: Save Quote',
  CaseUpdateSaveQuoteCompleted = '[Cases] Case Update :: Save Quote Completed',
  CaseUpdateSaveQuoteError = '[Cases] Case Update :: Save Quote Error',

  CaseUpdateStockOffer = '[Cases] Case Update :: Save Stock Offer',
  CaseUpdateStockOfferCompleted = '[Cases] Case Update :: Save Stock Offer Completed',
  CaseUpdateStockOfferError = '[Cases] Case Update :: Save Stock Offer Error',

  LoadCase = '[Cases] Case Load :: Load Case',
  CaseLoadCompleted = '[Cases] Case Load :: Completed',
  CaseLoadError = '[Cases] Case Load :: Error',

  LoadCaseBlackBoxPriceDetails = '[Cases] Load Case Black Box Price Details',
  LoadCaseBlackBoxPriceDetailsCompleted = '[Cases] Load Case Black Box Price Details Completed',
  LoadCaseBlackBoxPriceDetailsError = '[Cases] Load Case Black Box Price Details Error',

  CaseLoadVinEreData = '[Cases] Case Load Vin Ere Data',
  CaseLoadVinEreDataCompleted = '[Cases] Case Load Vin Ere Data Completed',
  CaseLoadVinEreDataError = '[Cases] Case Load Vin Ere Data Error',
  CaseReloadVinEreData = '[Cases] Case Reload Vin Ere Data',

  LoadEmissionStickerImage = '[Cases] EmissionStickerImage Load :: Load Emission Sticker Image',
  EmissionStickerImageLoadCompleted = '[Cases] Emission Sticker Image Load :: Completed',
  EmissionStickerImageLoadError = '[Cases] Emission Sticker Image Load :: Error',

  CaseUpdateOpenReservationModal = '[Cases] Case Update :: Open Reservation Modal',
  CaseUpdateCloseReservationModal = '[Cases] Case Update :: Close Reservation Modal',

  CaseUpdateReserveVehicle = '[Cases] Case Update :: Reserve Vehicle',
  CaseUpdateReserveVehicleCompleted = '[Cases] Case Update :: Reserve Vehicle Completed',
  CaseUpdateReserveVehicleError = '[Cases] Case Update :: Reserve Vehicle Error',

  CaseUpdateCancelReservation = '[Cases] Case Update :: Cancel Reservation',
  CaseUpdateCancelReservationCompleted = '[Cases] Case Update :: Cancel Reservation Completed',
  CaseUpdateCancelReservationError = '[Cases] Case Update :: Cancel Reservation Error',

  CaseUpdatePurchaseVehicle = '[Cases] Case Update :: Purchase Vehicle',
  CaseUpdatePurchaseVehicleCompleted = '[Cases] Case Update :: Purchase Vehicle Completed',
  CaseUpdatePurchaseVehicleError = '[Cases] Case Update :: Purchase Vehicle Error',
  CasePurchaseModalOpen = '[Cases] Case Update :: Purchase Modal Open',
  CasePurchaseModalClose = '[Cases] Case Update :: Purchase Modal Close',

  CaseUpdateSellVehicle = '[Cases] Case Update :: Sell Vehicle',
  CaseUpdateSellVehicleCompleted = '[Cases] Case Update :: Sell Vehicle Completed',
  CaseUpdateSellVehicleError = '[Cases] Case Update :: Sell Vehicle Error',

  CaseUpdateVehicleKba = '[Cases] Case Update :: Vehicle KBA',
  CaseUpdateVehicleKbaCompleted = '[Cases] Case Update :: Vehicle KBA Completed',
  CaseUpdateVehicleKbaError = '[Cases] Case Update :: Vehicle KBA Error',

  CaseUpdateUpdateCaseOwners = '[Cases] Case Update :: Update Case Owners',
  CaseUpdateUpdateCaseOwnersCompleted = '[Cases] Case Update :: Update Case Owners Completed',
  CaseUpdateUpdateCaseOwnersError = '[Cases] Case Update :: Update Case Owners Error',

  CaseUpdateUpdateCasePreviousOwners = '[Cases] Case Update :: Update Case Previous Owners',
  CaseUpdateUpdateCasePreviousOwnersCompleted = '[Cases] Case Update :: Update Case Previous Owners Completed',
  CaseUpdateUpdateCasePreviousOwnersError = '[Cases] Case Update :: Update Case Previous Owners Error',

  CaseUpdateUpdateCaseProspectCustomers = '[Cases] Case Update :: Update Case ProspectCustomers',
  CaseUpdateUpdateCaseProspectCustomersCompleted = '[Cases] Case Update :: Update Case ProspectCustomers Completed',
  CaseUpdateUpdateCaseProspectCustomersError = '[Cases] Case Update :: Update Case ProspectCustomers Error',

  CaseQRCodeLoad = '[Cases] Create :: Case QR Code Load',
  CaseQRCodeLoaded = '[Cases] Create :: Case QR Code Loaded',
  CaseQRCodeLoadError = '[Cases] Create :: Case QR Code Load Error',

  CaseLoadLightDamageEstimator = '[Cases] Case Load :: Load Light Damage Estimator',
  CaseLightDamageEstimatorLoadCompleted = '[Cases] Case Load :: Light Damage Estimator Load Completed',
  CaseLightDamageEstimatorLoadError = '[Cases] Case Load :: Light Damage Estimator Load Error',
  CaseUpdateLightDamageEstimator = '[Case] Case Update:: Update Light Damage Estimator',
  CaseLightDamageEstimatorUpdateCompleted = '[Case] Case Update:: Update Light Damage Estimator Completed',
  CaseLightDamageEstimatorUpdateError = '[Case] Case Update :: Update Light Damage Estimator Error',
  CaseUpdateLightDamagePartsAdjustment = '[Case] Case Update :: Update Light Damage Parts Adjustment',

  CaseUpdateSavePublicSiteConfiguration = '[Cases] Case Update :: Save Public Site Configuration',
  CaseUpdateSavePublicSiteConfigurationCompleted = '[Cases] Case Update :: Save Public Site Configuration Completed',
  CaseUpdateSavePublicSiteConfigurationError = '[Cases] Case Update :: Save Public Site Configuration Error',

  OpenResellerSiteConfigurationModal = '[Cases] Case Update :: Open Reseller Site Configuration Modal',
  CloseResellerSiteConfigurationModal = '[Cases] Case Update :: Close Reseller Site Configuration Modal',
  CaseUpdateCaseResellerSiteConfiguration = '[Cases] Case Update :: Reseller Site Configuration',
  CaseUpdateCaseResellerSiteConfigurationCompleted = '[Cases] Case Update :: Reseller Site Configuration Completed',
  CaseUpdateCaseResellerSiteConfigurationError = '[Cases] Case Update :: Reseller Site Configuration Error',

  CaseUpdateValuationUniqueDataValidateBroadcast = '[Cases] Case Update :: Case Update Valuation Unique Data :: Validate Broadcast',
  CaseUpdateValuationUniqueDataValidateBroadcastShowWarning = '[Cases] Case Update :: Case Update Valuation Unique Data :: Validate Broadcast Warning',
  CaseUpdateValuationUniqueDataUserCancelled = '[Cases] Case Update :: Case Update Valuation Unique Data :: User Cancelled',

  CaseUpdateValuationUniqueData = '[Cases] Case Update :: Case Update Valuation Unique Data',
  CaseUpdateValuationUniqueDataCompleted = '[Cases] Case Update :: Case Update Valuation Unique Data Completed',
  CaseUpdateValuationUniqueDataError = '[Cases] Case Update :: Case Update Valuation Unique Data Error',
  CaseOpenUniqueDataModal = '[Cases] Case Update :: Case Open Unique Data Modal',
  CaseCloseUniqueDataModal = '[Cases] Case Update :: Case Close Unique Data Modal',

  CaseUpdateOpenReturnToStockModal = '[Cases] Case Update :: Open Return To Stock Modal',
  CaseUpdateCloseReturnToStockModal = '[Cases] Case Update :: Close Return To Stock Modal',

  CaseUpdateReturnToStock = '[Cases] Case Update :: Return Vehicle To Stock',
  CaseUpdateReturnToStockSuccess = '[Cases] Case Update :: Return Vehicle To Stock Success',
  CaseUpdateReturnToStockError = '[Cases] Case Update :: Return Vehicle To Stock Error',

  CaseUpdateDueToInStock = '[Cases] Case Update :: Move Vehicle To Stock From Due In Stock',
  CaseUpdateDueToInStockSuccess = '[Cases] Case Update :: Move Vehicle To Stock From Due In Stock Success',
  CaseUpdateDueToInStockError = '[Cases] Case Update :: Move Vehicle To Stock From Due In Stock Error',

  CaseUpdateTechnicalData = '[Cases] Case Update :: Update Technical Data',
  CaseUpdateTechnicalDataCompleted = '[Cases] Case Update :: Update Technical Data Completed',
  CaseUpdateTechnicalDataError = '[Cases] Case Update :: Update Technical Data Error',
  CaseOpenTechnicalDataModal = '[Cases] Case Update :: Case Open Technical Data Modal',
  CaseCloseTechnicalDataModal = '[Cases] Case Update :: Case Close Technical Data Modal',

  UpdateCaseNotes = '[Cases] Update Case :: Update Case Notes',
  CaseNotesUpdateCompleted = '[Cases] Case Update Request :: Case Notes Updated',
  CaseNotesUpdateError = '[Cases] Case Update Request :: Case Notes Update Error',
  ResetCaseNotes = '[Cases] Update Case :: Reset Case Notes',

  CaseUpdateActiveCaseVehicleEquipmentIsEnabled = '[Cases] Case Update :: Update Active Case Vehicle Equipment Is Enabled',

  CaseUpdateStockPricingDetails = '[Cases] Case Update :: Update Stock Pricing Details',
  CaseUpdateSoldPricingDetails = '[Cases] Case Update :: Update Sold Pricing Details',
  CaseUpdatePricingSuccess = '[Cases] Case Update :: Update Pricing Details Success',
  CaseUpdatePricingDetailsError = '[Cases] Case Update :: Update Pricing Details Error',

  CaseChangePricingDetailsModalVisibility = '[Cases] Case Update :: Change Pricing Details Modal Visibility',

  CaseUpdatePlateNo = '[Cases] Case Update :: PlateNo',
  CaseUpdatePlateNoCompleted = '[Cases] Case Update :: PlateNo Completed',
  CaseUpdatePlateNoError = '[Cases] Case Update :: PlateNo Error',

  ResendCaseLatestQuote = '[Cases] Resend Case Latest Quote',
  ResendCaseLatestQuoteCompleted = '[Cases] Resend Case Latest Quote Completed',
  ResendCaseLatestQuoteError = '[Cases] Resend Case Latest Quote Error',

  ResendCaseLatestOffer = '[Cases] Resend Case Latest Offer',
  ResendCaseLatestOfferCompleted = '[Cases] Resend Case Latest Offer Completed',
  ResendCaseLatestOfferError = '[Cases] Resend Case Latest Offer Error',

  CaseCloseCO2EmissionDataModal = '[Cases] Case Update :: Case Close CO2 Emission Data Modal',

  CaseLoadFuelSubtypes = '[Cases] Case Load Fuel Subtypes',
  CaseLoadFuelSubtypesCompleted = '[Cases] Case Load Fuel Subtypes Completed',
  CaseLoadFuelSubtypesError = '[Cases] Case Load Fuel Subtypes Error',

  CaseLoadEmissionInfo = '[Cases] Case Load Emission Info',
  CaseLoadEmissionInfoCompleted = '[Cases] Case Load Emission Info Completed',
  CaseLoadEmissionInfoError = '[Cases] Case Load Emission Info Error',

  CaseLoadRoadTax = '[Cases] Case Load Road Tax',
  CaseLoadRoadTaxCompleted = '[Cases] Case Load Road Tax Completed',
  CaseLoadRoadTaxError = '[Cases] Case Load Road Tax Error',

  ResetWasValuation = '[Cases] Reset Was Valuation',

  CaseLoadReturnReport = '[Cases] Case Load Return Report',
  CaseLoadReturnReportCompleted = '[Cases] Case Load Return Report Completed',
  CaseLoadReturnReportError = '[Cases] Case Load Return Report Error',

  CaseUpdateReturnReport = '[Cases] Case Update Return Report',
  CaseUpdateReturnReportCompleted = '[Cases] Case Update Return Report Completed',
  CaseUpdateReturnReportError = '[Cases] Case Update Return Report Error',

  CaseOpenReturnReportModal = '[Cases] Case Open Return Report Modal',
  CaseCloseReturnReportModal = '[Cases] Case Close Return Report Modal',

  UpdateYoutubeUrl = '[Cases] Update Youtube Url',
  UpdateYoutubeUrlSuccess = '[Cases] Update Youtube Url Success',
  UpdateYoutubeUrlError = '[Cases] Update Youtube Url Error',

  CaseLoadEquipments = '[Cases] Case Load Equipments',
  CaseLoadEquipmentsCompleted = '[Cases]  Case Load Equipments Completed',
  CaseLoadEquipmentsError = '[Cases]  Case Load Equipments Error',

  CaseUpdateTwinnerImages = '[Cases] Case Update Images',
  CaseUpdateTwinnerImagesCompleted = '[Cases] Case Update Images Completed',
  CaseUpdateTwinnerImagesError = '[Cases] Case Update Images Error',
  DoNotUpdateTwinnerImages = '[Case] Do Not Update Twinner Images',

  CaseUpdateTwinnerImagesAfterValuation = '[Case] Update Twinner Images After Valuation',
  CaseUpdateTwinnerImagesAfterValuationCompleted = '[Case] Update Twinner Images After Valuation Completed',
  CaseUpdateTwinnerImagesAfterValuationError = '[Case] Update Twinner Images After Valuation Error',

  CaseOpenMoveToStockModal = '[Cases] Case Open Move To Stock Modal',
  CaseCloseMoveToStockModal = '[Cases] Case Close Move To Stock Modal',

  CaseOpenStockOfferModal = '[Cases] Case Open Stock Offer Modal',
  CaseCloseStockOfferModal = '[Cases] Case Close Stock Offer Modal',

  CaseOpenQuoteModal = '[Cases] Case Open Quote Modal',
  CaseCloseQuoteModal = '[Cases] Case Close Quote Modal',

  CaseOpenSellModal = '[Cases] Case Open Sell Modal',
  CaseCloseSellModal = '[Cases] Case Close Sell Modal',

  CaseLoadEquipmentsValid = '[Case] Load Equipments Valid',
  CaseLoadEquipmentsValidCompleted = '[Case] Load Equipments Valid Completed',
  CaseLoadEquipmentsValidError = '[Case] Load Equipments Valid Error',

  CaseResetLightDamageEstimator = '[Case] Reset Light Damage Estimator',

  UpdateIdentifiedVehicleBuildabilityData = '[Case] Update Identified Vehicle Buildability Data',
  UpdateIdentifiedVehicleBuildabilityDataCompleted = '[Case] Update Identified Vehicle Buildability Data Completed',
  UpdateIdentifiedVehicleBuildabilityDataError = '[Case] Update Identified Vehicle Buildability Data Error',
}

export class ResetCaseData implements Action {
  readonly type = CasesActionTypes.ResetCaseData;
}

export class LoadCases implements Action {
  readonly type = CasesActionTypes.LoadCases;
}

export class CasesLoadError implements Action {
  readonly type = CasesActionTypes.CasesLoadError;
}

export class CasesLoaded implements Action {
  readonly type = CasesActionTypes.CasesLoaded;
  constructor(public payload: CaseListItemDto[]) {}
}

export class ResetCaseLoadedStatus implements Action {
  readonly type = CasesActionTypes.ResetCaseLoadedStatus;
  constructor() {}
}

export class CaseLoadVinEreData implements Action {
  readonly type = CasesActionTypes.CaseLoadVinEreData;
  constructor(public caseId: string, public failCount: number = 0) {}
}

export class CaseLoadVinEreDataCompleted implements Action {
  readonly type = CasesActionTypes.CaseLoadVinEreDataCompleted;
  constructor(public payload: CaseLoadVinEreDataCommandHandlerResponse) {}
}

export class CaseLoadVinEreDataError implements Action {
  readonly type = CasesActionTypes.CaseLoadVinEreDataError;
}

export class CaseReloadVinEreData implements Action {
  readonly type = CasesActionTypes.CaseReloadVinEreData;
  constructor(public caseId: string, public failCount: number) {}
}

export class LoadCaseDocumentList implements Action {
  readonly type = CasesActionTypes.LoadCaseDocumentList;
  constructor(public payload: string) {}
}

export class CaseDocumentListLoadError implements Action {
  readonly type = CasesActionTypes.CaseDocumentListLoadError;
}

export class CaseDocumentListLoaded implements Action {
  readonly type = CasesActionTypes.CaseDocumentListLoaded;
  constructor(public payload: CaseDocumentDto[]) {}
}

export class ResetCaseDocumentList implements Action {
  readonly type = CasesActionTypes.ResetCaseDocumentList;
}

export class AddCaseDocument implements Action {
  readonly type = CasesActionTypes.AddCaseDocument;
  constructor(public payload: FileEntity, public isUpdate: boolean) {}
}

export class CaseDocumentAddError implements Action {
  readonly type = CasesActionTypes.CaseDocumentAddError;
  constructor(public payload: { error: HttpErrorResponse; name: string }) {}
}

export class CaseDocumentAdded implements Action {
  readonly type = CasesActionTypes.CaseDocumentAdded;
  constructor(public payload: CaseFilesCommandHandlerResponse, public isUpdate: boolean, public fileName: string) {}
}

export class CaseDocumentUploadSetTotalCount implements Action {
  readonly type = CasesActionTypes.CaseDocumentUploadSetTotalCount;
  constructor(public payload: number) {}
}

export class CaseDocumentUploadReset implements Action {
  readonly type = CasesActionTypes.CaseDocumentUploadReset;
}

export class DeleteCaseDocument implements Action {
  readonly type = CasesActionTypes.DeleteCaseDocument;
  constructor(public payload: { aggregateRootId: string; fileKey: string }) {}
}

export class CaseDocumentDeleteError implements Action {
  readonly type = CasesActionTypes.CaseDocumentDeleteError;
}

export class CaseDocumentDeleted implements Action {
  readonly type = CasesActionTypes.CaseDocumentDeleted;
  constructor(public payload: CommandHandlerResponse) {}
}

export class DownloadCaseDocument implements Action {
  readonly type = CasesActionTypes.DownloadCaseDocument;
  constructor(public payload: { caseId: string; fileKey: string; fileName: string }) {}
}

export class CaseDocumentDownloadError implements Action {
  readonly type = CasesActionTypes.CaseDocumentDownloadError;
}

export class CaseDocumentDownloaded implements Action {
  readonly type = CasesActionTypes.CaseDocumentDownloaded;
  constructor(public payload: { data: Blob; fileName }) {}
}

export class SetIsProcessingCaseDocumentForm implements Action {
  readonly type = CasesActionTypes.SetIsProcessingCaseDocumentForm;
  constructor(public payload: boolean) {}
}

export class SetHasCaseDocumentFormProcessingError implements Action {
  readonly type = CasesActionTypes.SetHasCaseDocumentFormProcessingError;
  constructor(public payload: boolean) {}
}

export class UpdateCaseRequestValuation implements Action {
  readonly type = CasesActionTypes.UpdateCaseRequestValuation;
  constructor(public payload: { caseId: string; force: boolean }) {}
}

export class CaseUpdateRequestValuationCompleted implements Action {
  readonly type = CasesActionTypes.CaseUpdateRequestValuationCompleted;
  constructor(public payload: { caseId: string }) {}
}

export class CaseUpdateRequestValuationError implements Action {
  readonly type = CasesActionTypes.CaseUpdateRequestValuationError;
  constructor(public payload: { caseId: string }) {}
}

export class CancelUpdateCaseRequestValuation implements Action {
  readonly type = CasesActionTypes.CancelUpdateCaseRequestValuation;
  constructor(public payload: { caseId: string }) {}
}

export class LoadCase implements Action {
  readonly type = CasesActionTypes.LoadCase;
  constructor(public payload: string) {}
}

export class CaseLoadCompleted implements Action {
  readonly type = CasesActionTypes.CaseLoadCompleted;
  constructor(public payload: CaseSummaryDto) {}
}

export class CaseLoadError implements Action {
  readonly type = CasesActionTypes.CaseLoadError;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadEmissionStickerImage implements Action {
  readonly type = CasesActionTypes.LoadEmissionStickerImage;
  constructor() {}
}

export class EmissionStickerImageLoadCompleted implements Action {
  readonly type = CasesActionTypes.EmissionStickerImageLoadCompleted;
  constructor(
    public payload: {
      efficiencyClass?: number;
      item: string;
    }
  ) {}
}

export class EmissionStickerImageLoadError implements Action {
  readonly type = CasesActionTypes.EmissionStickerImageLoadError;
}

export class CaseUpdateVehicleKba implements Action {
  readonly type = CasesActionTypes.CaseUpdateVehicleKba;
  constructor(public payload: { vehicle: UpdateIdentifiedVehicleKBACommand; caseId: string }) {}
}

export class CaseUpdateVehicleKbaCompleted implements Action {
  readonly type = CasesActionTypes.CaseUpdateVehicleKbaCompleted;
  constructor(public payload: { response: CommandHandlerResponse; caseId: string; newKba: string }) {}
}

export class CaseUpdateVehicleKbaError implements Action {
  readonly type = CasesActionTypes.CaseUpdateVehicleKbaError;
}

export class CaseUpdateSaveMileageAdjustment implements Action {
  readonly type = CasesActionTypes.CaseUpdateSaveMileageAdjustment;
  constructor(public payload: UpdateCaseVehicleMileageCommand) {}
}

export class CaseUpdateSaveMileageAdjustmentCompleted implements Action {
  readonly type = CasesActionTypes.CaseUpdateSaveMileageAdjustmentCompleted;
  constructor(public payload: CommandHandlerResponse) {}
}

export class CaseUpdateSaveMileageAdjustmentError implements Action {
  readonly type = CasesActionTypes.CaseUpdateSaveMileageAdjustmentError;
}

export class CaseUpdateValuationDate implements Action {
  readonly type = CasesActionTypes.CaseUpdateValuationDate;
  constructor(public payload: UpdateCaseValuationDateCommand) {}
}

export class CaseUpdateValuationDateCompleted implements Action {
  readonly type = CasesActionTypes.CaseUpdateValuationDateCompleted;
  constructor(public payload: CommandHandlerResponse) {}
}

export class CaseUpdateValuationDateError implements Action {
  readonly type = CasesActionTypes.CaseUpdateValuationDateError;
}

export class CaseUpdateRegistrationDate implements Action {
  readonly type = CasesActionTypes.CaseUpdateRegistrationDate;
  constructor(public payload: UpdateCaseRegistrationDateCommand) {}
}

export class CaseUpdateRegistrationDateCompleted implements Action {
  readonly type = CasesActionTypes.CaseUpdateRegistrationDateCompleted;
  constructor(public payload: CommandHandlerResponse) {}
}

export class CaseUpdateRegistrationDateError implements Action {
  readonly type = CasesActionTypes.CaseUpdateRegistrationDateError;
}

export class CaseUpdateSaveQuote implements Action {
  readonly type = CasesActionTypes.CaseUpdateSaveQuote;
  constructor(public payload: CreateCaseQuoteCommand) {}
}

export class CaseUpdateSaveQuoteCompleted implements Action {
  readonly type = CasesActionTypes.CaseUpdateSaveQuoteCompleted;
  constructor(public payload: CommandHandlerResponse) {}
}

export class CaseUpdateSaveQuoteError implements Action {
  readonly type = CasesActionTypes.CaseUpdateSaveQuoteError;
  constructor(public payload: HttpErrorResponse) {}
}

export class CaseUpdateStockOffer implements Action {
  readonly type = CasesActionTypes.CaseUpdateStockOffer;
  constructor(public payload: CreateCaseStockOfferCommand) {}
}

export class CaseUpdateStockOfferCompleted implements Action {
  readonly type = CasesActionTypes.CaseUpdateStockOfferCompleted;
  constructor(public payload: CreateStockOfferCommandHandlerResponse) {}
}

export class CaseUpdateStockOfferError implements Action {
  readonly type = CasesActionTypes.CaseUpdateStockOfferError;
  constructor(public payload: HttpErrorResponse) {}
}

export class CaseUpdatePurchaseVehicle implements Action {
  readonly type = CasesActionTypes.CaseUpdatePurchaseVehicle;
  constructor(public payload: CasePurchaseVehicleCommand) {}
}

export class CaseUpdatePurchaseVehicleCompleted implements Action {
  readonly type = CasesActionTypes.CaseUpdatePurchaseVehicleCompleted;
  constructor(public payload: { status: StockStatus; caseId: string; vin: string }) {}
}

export class CaseUpdatePurchaseVehicleError implements Action {
  readonly type = CasesActionTypes.CaseUpdatePurchaseVehicleError;
}

export class CasePurchaseModalOpen implements Action {
  readonly type = CasesActionTypes.CasePurchaseModalOpen;
}

export class CasePurchaseModalClose implements Action {
  readonly type = CasesActionTypes.CasePurchaseModalClose;
}

export class CaseUpdateSellVehicle implements Action {
  readonly type = CasesActionTypes.CaseUpdateSellVehicle;
  constructor(public payload: CaseSellVehicleCommand) {}
}

export class CaseUpdateSellVehicleCompleted implements Action {
  readonly type = CasesActionTypes.CaseUpdateSellVehicleCompleted;
  constructor(public payload: string) {}
}

export class CaseUpdateSellVehicleError implements Action {
  readonly type = CasesActionTypes.CaseUpdateSellVehicleError;
  constructor(public payload: HttpErrorResponse) {}
}

export class CaseUpdateUpdateCaseOwners implements Action {
  readonly type = CasesActionTypes.CaseUpdateUpdateCaseOwners;
  constructor(public payload: UpdateCaseOwnersCommand) {}
}

export class CaseUpdateUpdateCaseOwnersCompleted implements Action {
  readonly type = CasesActionTypes.CaseUpdateUpdateCaseOwnersCompleted;
  constructor(public payload: { response: CommandHandlerResponse; listLength: number }) {}
}

export class CaseUpdateUpdateCaseOwnersError implements Action {
  readonly type = CasesActionTypes.CaseUpdateUpdateCaseOwnersError;
}

export class CaseUpdateUpdateCasePreviousOwners implements Action {
  readonly type = CasesActionTypes.CaseUpdateUpdateCasePreviousOwners;
  constructor(public payload: UpdateCasePreviousOwnersCommand) {}
}

export class CaseUpdateUpdateCasePreviousOwnersCompleted implements Action {
  readonly type = CasesActionTypes.CaseUpdateUpdateCasePreviousOwnersCompleted;
  constructor(public payload: { response: CommandHandlerResponse; listLength: number }) {}
}

export class CaseUpdateUpdateCasePreviousOwnersError implements Action {
  readonly type = CasesActionTypes.CaseUpdateUpdateCasePreviousOwnersError;
}

export class CaseUpdateUpdateCaseProspectCustomers implements Action {
  readonly type = CasesActionTypes.CaseUpdateUpdateCaseProspectCustomers;
  constructor(public payload: UpdateCaseProspectCustomersCommand) {}
}

export class CaseUpdateUpdateCaseProspectCustomersCompleted implements Action {
  readonly type = CasesActionTypes.CaseUpdateUpdateCaseProspectCustomersCompleted;
  constructor(public payload: { response: CommandHandlerResponse; listLength: number }) {}
}

export class CaseUpdateUpdateCaseProspectCustomersError implements Action {
  readonly type = CasesActionTypes.CaseUpdateUpdateCaseProspectCustomersError;
}

export class CaseUpdateVin implements Action {
  readonly type = CasesActionTypes.CaseUpdateVin;
  constructor(public payload: { vehicle: UpdateIdentifiedVehicleVinCommand; caseId: string }) {}
}

export class CaseUpdateVinCompleted implements Action {
  readonly type = CasesActionTypes.CaseUpdateVinCompleted;
  constructor(public payload: { response: CommandHandlerResponse; caseId: string }) {}
}

export class CaseUpdateVinError implements Action {
  readonly type = CasesActionTypes.CaseUpdateVinError;
}

export class CaseUpdateMatriculationNumber implements Action {
  readonly type = CasesActionTypes.CaseUpdateMatriculationNumber;
  constructor(public payload: UpdateIdentifiedVehicleMatriculationNumberCommand) {}
}

export class CaseUpdateMatriculationNumberCompleted implements Action {
  readonly type = CasesActionTypes.CaseUpdateMatriculationNumberCompleted;
  constructor(public payload: CommandHandlerResponse) {}
}

export class CaseUpdateMatriculationNumberError implements Action {
  readonly type = CasesActionTypes.CaseUpdateMatriculationNumberError;
}

export class OpenResellerSiteConfigurationModal implements Action {
  readonly type = CasesActionTypes.OpenResellerSiteConfigurationModal;
}

export class CloseResellerSiteConfigurationModal implements Action {
  readonly type = CasesActionTypes.CloseResellerSiteConfigurationModal;
}

export class CaseUpdateCaseResellerSiteConfiguration implements Action {
  readonly type = CasesActionTypes.CaseUpdateCaseResellerSiteConfiguration;
  constructor(
    public payload: { command: UpdateCaseResellerSiteConfigurationCommand; initialPublishedValue: boolean }
  ) {}
}

export class CaseUpdateCaseResellerSiteConfigurationCompleted implements Action {
  readonly type = CasesActionTypes.CaseUpdateCaseResellerSiteConfigurationCompleted;
  constructor(
    public payload: {
      response: CommandHandlerResponse;
      request: UpdateCaseResellerSiteConfigurationCommand;
      initialPublishedValue: boolean;
    }
  ) {}
}

export class CaseUpdateCaseResellerSiteConfigurationError implements Action {
  readonly type = CasesActionTypes.CaseUpdateCaseResellerSiteConfigurationError;
}

export class CaseLoadLightDamageEstimator implements Action {
  readonly type = CasesActionTypes.CaseLoadLightDamageEstimator;
  constructor(public payload: string) {}
}

export class CaseLightDamageEstimatorLoadCompleted implements Action {
  readonly type = CasesActionTypes.CaseLightDamageEstimatorLoadCompleted;
  constructor(public payload: LightDamageAreaDto[]) {}
}

export class CaseLightDamageEstimatorLoadError implements Action {
  readonly type = CasesActionTypes.CaseLightDamageEstimatorLoadError;
}

export class CaseUpdateLightDamageEstimator implements Action {
  readonly type = CasesActionTypes.CaseUpdateLightDamageEstimator;
  constructor(public payload: UpdateCaseLightDamagePartsCommand) {}
}

export class CaseLightDamageEstimatorUpdateCompleted implements Action {
  readonly type = CasesActionTypes.CaseLightDamageEstimatorUpdateCompleted;
  constructor(public payload: UpdateCaseLightDamagePartsCommand) {}
}

export class CaseLightDamageEstimatorUpdateError implements Action {
  readonly type = CasesActionTypes.CaseLightDamageEstimatorUpdateError;
  constructor(public payload: HttpErrorResponse) {}
}

export class CaseUpdateLightDamagePartsAdjustment implements Action {
  readonly type = CasesActionTypes.CaseUpdateLightDamagePartsAdjustment;
  constructor(public payload: { aggregateId: string; deductionAmount: number }) {}
}

export class CaseUpdateSavePublicSiteConfiguration implements Action {
  readonly type = CasesActionTypes.CaseUpdateSavePublicSiteConfiguration;
  constructor(public payload: { command: UpdateCasePublicSiteConfigurationCommand; initialPublishedValue: boolean }) {}
}

export class CaseUpdateSavePublicSiteConfigurationCompleted implements Action {
  readonly type = CasesActionTypes.CaseUpdateSavePublicSiteConfigurationCompleted;
  constructor(public payload: { command: UpdateCasePublicSiteConfigurationCommand; initialPublishedValue: boolean }) {}
}

export class CaseUpdateSavePublicSiteConfigurationError implements Action {
  readonly type = CasesActionTypes.CaseUpdateSavePublicSiteConfigurationError;
  constructor(public payload: HttpErrorResponse) {}
}

export class CaseUpdateReserveVehicle implements Action {
  readonly type = CasesActionTypes.CaseUpdateReserveVehicle;
  constructor(public payload: { endDate: string; customerId: string; information: string }) {}
}

export class CaseUpdateReserveVehicleCompleted implements Action {
  readonly type = CasesActionTypes.CaseUpdateReserveVehicleCompleted;
  constructor(public payload: string) {}
}

export class CaseUpdateReserveVehicleError implements Action {
  readonly type = CasesActionTypes.CaseUpdateReserveVehicleError;
}

export class CaseUpdateOpenReservationModal implements Action {
  readonly type = CasesActionTypes.CaseUpdateOpenReservationModal;
}
export class CaseUpdateCloseReservationModal implements Action {
  readonly type = CasesActionTypes.CaseUpdateCloseReservationModal;
}

export class CaseUpdateCancelReservation implements Action {
  readonly type = CasesActionTypes.CaseUpdateCancelReservation;
}
export class CaseUpdateCancelReservationCompleted implements Action {
  readonly type = CasesActionTypes.CaseUpdateCancelReservationCompleted;
  constructor(public payload: string) {}
}
export class CaseUpdateCancelReservationError implements Action {
  readonly type = CasesActionTypes.CaseUpdateCancelReservationError;
}

export class CaseQRCodeLoad implements Action {
  readonly type = CasesActionTypes.CaseQRCodeLoad;
  constructor(public payload: { caseId: string }) {}
}
export class CaseQRCodeLoaded implements Action {
  readonly type = CasesActionTypes.CaseQRCodeLoaded;
  constructor(public payload: any) {}
}
export class CaseQRCodeLoadError implements Action {
  readonly type = CasesActionTypes.CaseQRCodeLoadError;
}

export class CaseUpdateValuationUniqueDataValidateBroadcast implements Action {
  readonly type = CasesActionTypes.CaseUpdateValuationUniqueDataValidateBroadcast;
  constructor(
    public payload: {
      uniqueData: UpdateCaseUniqueDataCommand;
      // TODO: remove as it is unused (always false)?
      updatedFromBroadcast: boolean;
      broadcastSettings?: CreateCaseBroadcastSettingsCommand;
      changedBranch: boolean;
    }
  ) {}
}
export class CaseUpdateValuationUniqueDataValidateBroadcastShowWarning implements Action {
  readonly type = CasesActionTypes.CaseUpdateValuationUniqueDataValidateBroadcastShowWarning;
  constructor(
    public payload: {
      uniqueData: UpdateCaseUniqueDataCommand;
      // TODO: remove as it is unused (always false)?
      updatedFromBroadcast: boolean;
      broadcastSettings?: CreateCaseBroadcastSettingsCommand;
    }
  ) {}
}
export class CaseUpdateValuationUniqueDataUserCancelled implements Action {
  readonly type = CasesActionTypes.CaseUpdateValuationUniqueDataUserCancelled;
}
export class CaseUpdateValuationUniqueData implements Action {
  readonly type = CasesActionTypes.CaseUpdateValuationUniqueData;
  constructor(
    public payload: {
      uniqueData: UpdateCaseUniqueDataCommand;
      // TODO: remove as it is unused (always false)?
      updatedFromBroadcast: boolean;
      broadcastSettings?: CreateCaseBroadcastSettingsCommand;
    }
  ) {}
}
export class CaseUpdateValuationUniqueDataCompleted implements Action {
  readonly type = CasesActionTypes.CaseUpdateValuationUniqueDataCompleted;
  constructor(
    public payload: {
      caseId: string;
      // TODO: remove as it is unused (always false)?
      updatedFromBroadcast: boolean;
      broadcastSettings?: CreateCaseBroadcastSettingsCommand;
    }
  ) {}
}
export class CaseUpdateValuationUniqueDataError implements Action {
  readonly type = CasesActionTypes.CaseUpdateValuationUniqueDataError;
}

export class CaseOpenUniqueDataModal implements Action {
  readonly type = CasesActionTypes.CaseOpenUniqueDataModal;
}

export class CaseCloseUniqueDataModal implements Action {
  readonly type = CasesActionTypes.CaseCloseUniqueDataModal;
}

export class CaseUpdateOpenReturnToStockModal implements Action {
  readonly type = CasesActionTypes.CaseUpdateOpenReturnToStockModal;
}
export class CaseUpdateCloseReturnToStockModal implements Action {
  readonly type = CasesActionTypes.CaseUpdateCloseReturnToStockModal;
}

export class CaseUpdateReturnToStock implements Action {
  readonly type = CasesActionTypes.CaseUpdateReturnToStock;
  constructor(public payload: CaseReturnToStockCommand) {}
}
export class CaseUpdateReturnToStockSuccess implements Action {
  readonly type = CasesActionTypes.CaseUpdateReturnToStockSuccess;
  constructor(public payload: string) {}
}
export class CaseUpdateReturnToStockError implements Action {
  readonly type = CasesActionTypes.CaseUpdateReturnToStockError;
}

export class CaseUpdateDueToInStock implements Action {
  readonly type = CasesActionTypes.CaseUpdateDueToInStock;
  constructor(public payload: CaseDueToInStockCommand) {}
}

export class CaseUpdateDueToInStockSuccess implements Action {
  readonly type = CasesActionTypes.CaseUpdateDueToInStockSuccess;
  constructor(public payload: string) {}
}

export class CaseUpdateDueToInStockError implements Action {
  readonly type = CasesActionTypes.CaseUpdateDueToInStockError;
}

export class CaseUpdateTechnicalData implements Action {
  readonly type = CasesActionTypes.CaseUpdateTechnicalData;
  constructor(
    public payload: {
      caseId: string;
      broadcastSettings: CreateCaseBroadcastSettingsCommand;
      technicalData: UpdateIdentifiedVehicleTechnicalDataCommand;
      updatedFromBroadcast: boolean;
      uniqueData?: UpdateCaseUniqueDataCommand;
    }
  ) {}
}

export class CaseUpdateTechnicalDataCompleted implements Action {
  readonly type = CasesActionTypes.CaseUpdateTechnicalDataCompleted;
  constructor(
    public payload: {
      caseId: string;
      broadcastSettings: CreateCaseBroadcastSettingsCommand;
      technicalData: UpdateIdentifiedVehicleTechnicalDataCommand;
      updatedFromBroadcast: boolean;
      uniqueData?: UpdateCaseUniqueDataCommand;
    }
  ) {}
}

export class CaseUpdateTechnicalDataError implements Action {
  readonly type = CasesActionTypes.CaseUpdateTechnicalDataError;
}

export class CaseOpenTechnicalDataModal implements Action {
  readonly type = CasesActionTypes.CaseOpenTechnicalDataModal;
}

export class CaseCloseTechnicalDataModal implements Action {
  readonly type = CasesActionTypes.CaseCloseTechnicalDataModal;
}

export class UpdateCaseNotes implements Action {
  readonly type = CasesActionTypes.UpdateCaseNotes;
  constructor(public payload: UpdateCaseNotesCommand) {}
}

export class CaseNotesUpdateCompleted implements Action {
  readonly type = CasesActionTypes.CaseNotesUpdateCompleted;
  constructor(public payload: CommandHandlerResponse) {}
}
export class CaseNotesUpdateError implements Action {
  readonly type = CasesActionTypes.CaseNotesUpdateError;
}

export class ResetCaseNotes implements Action {
  readonly type = CasesActionTypes.ResetCaseNotes;
}

export class CaseUpdateActiveCaseVehicleEquipmentIsEnabled implements Action {
  readonly type = CasesActionTypes.CaseUpdateActiveCaseVehicleEquipmentIsEnabled;
  constructor(public payload: { soaCode: string; isEnabled: boolean }) {}
}

export class CaseUpdateStockPricingDetails implements Action {
  readonly type = CasesActionTypes.CaseUpdateStockPricingDetails;
  constructor(public payload: UpdateCaseStockPricesCommand) {}
}
export class CaseUpdateSoldPricingDetails implements Action {
  readonly type = CasesActionTypes.CaseUpdateSoldPricingDetails;
  constructor(public payload: UpdateCaseSoldPricesCommand) {}
}
export class CaseUpdatePricingSuccess implements Action {
  readonly type = CasesActionTypes.CaseUpdatePricingSuccess;
  constructor(public payload: string) {}
}
export class CaseUpdatePricingDetailsError implements Action {
  readonly type = CasesActionTypes.CaseUpdatePricingDetailsError;
}

export class CaseChangePricingDetailsModalVisibility implements Action {
  readonly type = CasesActionTypes.CaseChangePricingDetailsModalVisibility;
  constructor(public payload: boolean) {}
}

export class CaseUpdatePlateNo implements Action {
  readonly type = CasesActionTypes.CaseUpdatePlateNo;
  constructor(public payload: UpdateCasePlateNumberCommand) {}
}

export class CaseUpdatePlateNoCompleted implements Action {
  readonly type = CasesActionTypes.CaseUpdatePlateNoCompleted;
  constructor(public payload: CommandHandlerResponse) {}
}

export class CaseUpdatePlateNoError implements Action {
  readonly type = CasesActionTypes.CaseUpdatePlateNoError;
}

export class ResendCaseLatestQuote implements Action {
  readonly type = CasesActionTypes.ResendCaseLatestQuote;
  constructor(public payload: ResendCaseLatestQuoteCommand) {}
}

export class ResendCaseLatestQuoteCompleted implements Action {
  readonly type = CasesActionTypes.ResendCaseLatestQuoteCompleted;
  constructor(public payload: CommandHandlerResponse) {}
}

export class ResendCaseLatestQuoteError implements Action {
  readonly type = CasesActionTypes.ResendCaseLatestQuoteError;
}

export class ResendCaseLatestOffer implements Action {
  readonly type = CasesActionTypes.ResendCaseLatestOffer;
  constructor(public payload: ResendCaseLatestStockOfferCommand) {}
}

export class ResendCaseLatestOfferCompleted implements Action {
  readonly type = CasesActionTypes.ResendCaseLatestOfferCompleted;
  constructor(public payload: CommandHandlerResponse) {}
}

export class ResendCaseLatestOfferError implements Action {
  readonly type = CasesActionTypes.ResendCaseLatestOfferError;
}

export class CaseLoadFuelSubtypes implements Action {
  readonly type = CasesActionTypes.CaseLoadFuelSubtypes;
  constructor(public payload: string) {}
}

export class CaseLoadFuelSubtypesCompleted implements Action {
  readonly type = CasesActionTypes.CaseLoadFuelSubtypesCompleted;
  constructor(public payload: CaseFuelSubtypeDto[]) {}
}

export class CaseLoadFuelSubtypesError implements Action {
  readonly type = CasesActionTypes.CaseLoadFuelSubtypesError;
}

export class CaseLoadEmissionInfo implements Action {
  readonly type = CasesActionTypes.CaseLoadEmissionInfo;
  constructor(public payload: string) {}
}

export class CaseLoadEmissionInfoCompleted implements Action {
  readonly type = CasesActionTypes.CaseLoadEmissionInfoCompleted;
  constructor(public payload: EmissionInfoDto) {}
}

export class CaseLoadEmissionInfoError implements Action {
  readonly type = CasesActionTypes.CaseLoadEmissionInfoError;
}

export class CaseLoadRoadTax implements Action {
  readonly type = CasesActionTypes.CaseLoadRoadTax;
  constructor(public payload: string) {}
}

export class CaseLoadRoadTaxCompleted implements Action {
  readonly type = CasesActionTypes.CaseLoadRoadTaxCompleted;
  constructor(public payload: RoadTaxDataDto) {}
}

export class CaseLoadRoadTaxError implements Action {
  readonly type = CasesActionTypes.CaseLoadRoadTaxError;
}

export class ResetWasValuation implements Action {
  readonly type = CasesActionTypes.ResetWasValuation;
}

export class CaseLoadReturnReport implements Action {
  readonly type = CasesActionTypes.CaseLoadReturnReport;
  constructor(public payload: string) {}
}

export class CaseLoadReturnReportCompleted implements Action {
  readonly type = CasesActionTypes.CaseLoadReturnReportCompleted;
  constructor(public payload: ReturnReportDto) {}
}

export class CaseLoadReturnReportError implements Action {
  readonly type = CasesActionTypes.CaseLoadReturnReportError;
}

export class CaseUpdateReturnReport implements Action {
  readonly type = CasesActionTypes.CaseUpdateReturnReport;
  constructor(public payload: UpdateCaseReturnReportCommand) {}
}

export class CaseUpdateReturnReportCompleted implements Action {
  readonly type = CasesActionTypes.CaseUpdateReturnReportCompleted;
  constructor(public payload: string) {}
}

export class CaseUpdateReturnReportError implements Action {
  readonly type = CasesActionTypes.CaseUpdateReturnReportError;
}

export class CaseOpenReturnReportModal implements Action {
  readonly type = CasesActionTypes.CaseOpenReturnReportModal;
}

export class CaseCloseReturnReportModal implements Action {
  readonly type = CasesActionTypes.CaseCloseReturnReportModal;
}

export class CaseOpenMoveToStockModal implements Action {
  readonly type = CasesActionTypes.CaseOpenMoveToStockModal;
}

export class CaseCloseMoveToStockModal implements Action {
  readonly type = CasesActionTypes.CaseCloseMoveToStockModal;
}

export class CaseOpenStockOfferModal implements Action {
  readonly type = CasesActionTypes.CaseOpenStockOfferModal;
}

export class CaseCloseStockOfferModal implements Action {
  readonly type = CasesActionTypes.CaseCloseStockOfferModal;
}

export class CaseOpenQuoteModal implements Action {
  readonly type = CasesActionTypes.CaseOpenQuoteModal;
}

export class CaseCloseQuoteModal implements Action {
  readonly type = CasesActionTypes.CaseCloseQuoteModal;
}

export class CaseOpenSellModal implements Action {
  readonly type = CasesActionTypes.CaseOpenSellModal;
}

export class CaseCloseSellModal implements Action {
  readonly type = CasesActionTypes.CaseCloseSellModal;
}

export class UpdateYoutubeUrl implements Action {
  readonly type = CasesActionTypes.UpdateYoutubeUrl;
  constructor(public payload: UpdateCaseYoutubeUrlCommand) {}
}

export class UpdateYoutubeUrlSuccess implements Action {
  readonly type = CasesActionTypes.UpdateYoutubeUrlSuccess;
  constructor(public payload: string) {}
}

export class UpdateYoutubeUrlError implements Action {
  readonly type = CasesActionTypes.UpdateYoutubeUrlError;
}

export class CaseLoadEquipments implements Action {
  readonly type = CasesActionTypes.CaseLoadEquipments;
  /**
   *
   * @param payload caseId
   */
  constructor(public payload: string) {}
}

export class CaseLoadEquipmentsError implements Action {
  readonly type = CasesActionTypes.CaseLoadEquipmentsError;
}

export class CaseLoadEquipmentsCompleted implements Action {
  readonly type = CasesActionTypes.CaseLoadEquipmentsCompleted;
  constructor(public payload: EquipmentDto[]) {}
}

export class CaseUpdateTwinnerImages implements Action {
  readonly type = CasesActionTypes.CaseUpdateTwinnerImages;
  constructor(public payload: UpdateTwinnerImagesCommand) {}
}

export class CaseUpdateTwinnerImagesCompleted implements Action {
  readonly type = CasesActionTypes.CaseUpdateTwinnerImagesCompleted;
  constructor(public payload: CaseFilesCommandHandlerResponse) {}
}

export class CaseUpdateTwinnerImagesError implements Action {
  readonly type = CasesActionTypes.CaseUpdateTwinnerImagesError;
}

export class DoNotUpdateTwinnerImages implements Action {
  readonly type = CasesActionTypes.DoNotUpdateTwinnerImages;
}

export class CaseUpdateTwinnerImagesAfterValuation implements Action {
  readonly type = CasesActionTypes.CaseUpdateTwinnerImagesAfterValuation;
  constructor(public payload: UpdateTwinnerImagesCommand) {}
}

export class CaseUpdateTwinnerImagesAfterValuationCompleted implements Action {
  readonly type = CasesActionTypes.CaseUpdateTwinnerImagesAfterValuationCompleted;
}

export class CaseUpdateTwinnerImagesAfterValuationError implements Action {
  readonly type = CasesActionTypes.CaseUpdateTwinnerImagesAfterValuationError;
}

export class CaseLoadEquipmentsValid implements Action {
  readonly type = CasesActionTypes.CaseLoadEquipmentsValid;
}

export class CaseLoadEquipmentsValidCompleted implements Action {
  readonly type = CasesActionTypes.CaseLoadEquipmentsValidCompleted;
  constructor(public payload: boolean) {}
}

export class CaseLoadEquipmentsValidError implements Action {
  readonly type = CasesActionTypes.CaseLoadEquipmentsValidError;
}

export class UpdateIdentifiedVehicleBuildabilityData implements Action {
  readonly type = CasesActionTypes.UpdateIdentifiedVehicleBuildabilityData;
  constructor(public payload: { identifiedVehicleId: string; caseId: string }) {}
}

export class UpdateIdentifiedVehicleBuildabilityDataCompleted implements Action {
  readonly type = CasesActionTypes.UpdateIdentifiedVehicleBuildabilityDataCompleted;
  constructor(public caseId: string) {}
}

export class UpdateIdentifiedVehicleBuildabilityDataError implements Action {
  readonly type = CasesActionTypes.UpdateIdentifiedVehicleBuildabilityDataError;
}

export class CaseResetLightDamageEstimator implements Action {
  readonly type = CasesActionTypes.CaseResetLightDamageEstimator;
}

export class LoadCaseBlackBoxPriceDetails implements Action {
  readonly type = CasesActionTypes.LoadCaseBlackBoxPriceDetails;
}

export class LoadCaseBlackBoxPriceDetailsCompleted implements Action {
  readonly type = CasesActionTypes.LoadCaseBlackBoxPriceDetailsCompleted;
  constructor(public data: ValuationRequestPriceDetailsDto) {}
}

export class LoadCaseBlackBoxPriceDetailsError implements Action {
  readonly type = CasesActionTypes.LoadCaseBlackBoxPriceDetailsError;
}

export type CasesAction =
  | ResetCaseData
  | LoadCases
  | CasesLoaded
  | CasesLoadError
  | ResetCaseLoadedStatus
  | UpdateCaseRequestValuation
  | CaseUpdateRequestValuationCompleted
  | CaseUpdateRequestValuationError
  | CancelUpdateCaseRequestValuation
  | LoadCase
  | CaseLoadCompleted
  | CaseLoadError
  | CaseLoadVinEreData
  | CaseLoadVinEreDataCompleted
  | CaseLoadVinEreDataError
  | CaseReloadVinEreData
  | CaseUpdateSaveQuote
  | CaseUpdateSaveQuoteCompleted
  | CaseUpdateSaveQuoteError
  | CaseUpdateStockOffer
  | CaseUpdateStockOfferCompleted
  | CaseUpdateStockOfferError
  | CaseUpdatePurchaseVehicle
  | CaseUpdatePurchaseVehicleCompleted
  | CaseUpdatePurchaseVehicleError
  | CasePurchaseModalOpen
  | CasePurchaseModalClose
  | CaseUpdateSellVehicle
  | CaseUpdateSellVehicleCompleted
  | CaseUpdateSellVehicleError
  | CaseUpdateUpdateCaseOwners
  | CaseUpdateUpdateCaseOwnersCompleted
  | CaseUpdateUpdateCaseOwnersError
  | CaseUpdateUpdateCasePreviousOwners
  | CaseUpdateUpdateCasePreviousOwnersError
  | CaseUpdateUpdateCasePreviousOwnersCompleted
  | CaseUpdateUpdateCaseProspectCustomers
  | CaseUpdateUpdateCaseProspectCustomersCompleted
  | CaseUpdateUpdateCaseProspectCustomersError
  | CaseUpdateVin
  | CaseUpdateVinCompleted
  | CaseUpdateVinError
  | CaseUpdateMatriculationNumber
  | CaseUpdateMatriculationNumberCompleted
  | CaseUpdateMatriculationNumberError
  | CaseLoadLightDamageEstimator
  | CaseLightDamageEstimatorLoadCompleted
  | CaseLightDamageEstimatorLoadError
  | CaseUpdateLightDamageEstimator
  | CaseLightDamageEstimatorUpdateCompleted
  | CaseLightDamageEstimatorUpdateError
  | CaseUpdateLightDamagePartsAdjustment
  | CaseUpdateVehicleKba
  | CaseUpdateVehicleKbaCompleted
  | CaseUpdateVehicleKbaError
  | CaseUpdateSavePublicSiteConfiguration
  | CaseUpdateSavePublicSiteConfigurationCompleted
  | CaseUpdateSavePublicSiteConfigurationError
  | OpenResellerSiteConfigurationModal
  | CloseResellerSiteConfigurationModal
  | CaseUpdateCaseResellerSiteConfiguration
  | CaseUpdateCaseResellerSiteConfigurationCompleted
  | CaseUpdateCaseResellerSiteConfigurationError
  | CaseUpdateReserveVehicle
  | CaseUpdateReserveVehicleCompleted
  | CaseUpdateReserveVehicleError
  | CaseUpdateOpenReservationModal
  | CaseUpdateCloseReservationModal
  | CaseUpdateCancelReservation
  | CaseUpdateCancelReservationCompleted
  | CaseUpdateCancelReservationError
  | CaseQRCodeLoad
  | CaseQRCodeLoaded
  | CaseQRCodeLoadError
  | CaseUpdateSaveMileageAdjustment
  | CaseUpdateSaveMileageAdjustmentCompleted
  | CaseUpdateSaveMileageAdjustmentError
  | CaseUpdateRegistrationDate
  | CaseUpdateRegistrationDateCompleted
  | CaseUpdateRegistrationDateError
  | CaseUpdateValuationDate
  | CaseUpdateValuationDateCompleted
  | CaseUpdateValuationDateError
  | CaseUpdateValuationUniqueDataValidateBroadcast
  | CaseUpdateValuationUniqueDataValidateBroadcastShowWarning
  | CaseUpdateValuationUniqueDataUserCancelled
  | CaseUpdateValuationUniqueData
  | CaseUpdateValuationUniqueDataCompleted
  | CaseUpdateValuationUniqueDataError
  | CaseOpenUniqueDataModal
  | CaseCloseUniqueDataModal
  | LoadCaseDocumentList
  | CaseDocumentListLoaded
  | CaseDocumentListLoadError
  | AddCaseDocument
  | CaseDocumentAdded
  | CaseDocumentUploadSetTotalCount
  | CaseDocumentUploadReset
  | CaseDocumentAddError
  | DownloadCaseDocument
  | CaseDocumentDownloaded
  | CaseDocumentDownloadError
  | DeleteCaseDocument
  | CaseDocumentDeleted
  | CaseDocumentDeleteError
  | SetIsProcessingCaseDocumentForm
  | SetHasCaseDocumentFormProcessingError
  | CaseUpdateOpenReturnToStockModal
  | CaseUpdateCloseReturnToStockModal
  | CaseUpdateReturnToStock
  | CaseUpdateReturnToStockSuccess
  | CaseUpdateReturnToStockError
  | CaseUpdateTechnicalData
  | CaseUpdateTechnicalDataCompleted
  | CaseUpdateTechnicalDataError
  | CaseOpenTechnicalDataModal
  | CaseCloseTechnicalDataModal
  | UpdateCaseNotes
  | ResetCaseNotes
  | CaseNotesUpdateCompleted
  | CaseNotesUpdateError
  | CaseUpdateActiveCaseVehicleEquipmentIsEnabled
  | LoadEmissionStickerImage
  | EmissionStickerImageLoadCompleted
  | EmissionStickerImageLoadError
  | CaseUpdateDueToInStock
  | CaseUpdateDueToInStockSuccess
  | CaseUpdateDueToInStockError
  | CaseUpdateStockPricingDetails
  | CaseUpdateSoldPricingDetails
  | CaseUpdatePricingSuccess
  | CaseUpdatePricingDetailsError
  | CaseChangePricingDetailsModalVisibility
  | CaseUpdatePlateNo
  | CaseUpdatePlateNoCompleted
  | CaseUpdatePlateNoError
  | ResendCaseLatestQuote
  | ResendCaseLatestQuoteCompleted
  | ResendCaseLatestQuoteError
  | ResendCaseLatestOffer
  | ResendCaseLatestOfferCompleted
  | ResendCaseLatestOfferError
  | CaseLoadFuelSubtypes
  | CaseLoadFuelSubtypesCompleted
  | CaseLoadFuelSubtypesError
  | CaseLoadEmissionInfo
  | CaseLoadEmissionInfoCompleted
  | CaseLoadEmissionInfoError
  | CaseLoadRoadTax
  | CaseLoadRoadTaxCompleted
  | CaseLoadRoadTaxError
  | ResetWasValuation
  | CaseLoadReturnReport
  | CaseLoadReturnReportCompleted
  | CaseLoadReturnReportError
  | CaseUpdateReturnReport
  | CaseUpdateReturnReportCompleted
  | CaseUpdateReturnReportError
  | CaseOpenReturnReportModal
  | CaseCloseReturnReportModal
  | UpdateYoutubeUrl
  | UpdateYoutubeUrlSuccess
  | UpdateYoutubeUrlError
  | CaseLoadEquipments
  | CaseLoadEquipmentsError
  | CaseLoadEquipmentsCompleted
  | CaseUpdateTwinnerImages
  | CaseUpdateTwinnerImagesCompleted
  | CaseUpdateTwinnerImagesError
  | DoNotUpdateTwinnerImages
  | CaseUpdateTwinnerImagesAfterValuation
  | CaseUpdateTwinnerImagesAfterValuationCompleted
  | CaseUpdateTwinnerImagesAfterValuationError
  | CaseLoadEquipmentsValid
  | CaseLoadEquipmentsValidCompleted
  | CaseLoadEquipmentsValidError
  | UpdateIdentifiedVehicleBuildabilityData
  | UpdateIdentifiedVehicleBuildabilityDataCompleted
  | UpdateIdentifiedVehicleBuildabilityDataError
  | CaseResetLightDamageEstimator
  | ResetCaseDocumentList
  | CaseOpenMoveToStockModal
  | CaseCloseMoveToStockModal
  | CaseOpenStockOfferModal
  | CaseCloseStockOfferModal
  | CaseOpenQuoteModal
  | CaseCloseQuoteModal
  | CaseOpenSellModal
  | CaseCloseSellModal
  | LoadCaseBlackBoxPriceDetails
  | LoadCaseBlackBoxPriceDetailsCompleted
  | LoadCaseBlackBoxPriceDetailsError;

export const fromCasesActions = {
  ResetCaseData,
  LoadCases,
  CasesLoaded,
  CasesLoadError,
  ResetCaseLoadedStatus,
  UpdateCaseRequestValuation,
  CaseUpdateRequestValuationCompleted,
  CaseUpdateRequestValuationError,
  CancelUpdateCaseRequestValuation,
  LoadCase,
  CaseLoadCompleted,
  CaseLoadError,
  CaseLoadVinEreData,
  CaseLoadVinEreDataCompleted,
  CaseLoadVinEreDataError,
  CaseReloadVinEreData,
  CaseUpdateSaveMileageAdjustment,
  CaseUpdateSaveMileageAdjustmentCompleted,
  CaseUpdateSaveMileageAdjustmentError,
  CaseUpdateValuationDate,
  CaseUpdateValuationDateCompleted,
  CaseUpdateValuationDateError,
  CaseUpdateRegistrationDate,
  CaseUpdateRegistrationDateCompleted,
  CaseUpdateRegistrationDateError,
  CaseUpdateVehicleKba,
  CaseUpdateVehicleKbaCompleted,
  CaseUpdateVehicleKbaError,
  CaseUpdateSaveQuote,
  CaseUpdateSaveQuoteCompleted,
  CaseUpdateSaveQuoteError,
  CaseUpdateStockOffer,
  CaseUpdateStockOfferCompleted,
  CaseUpdateStockOfferError,
  CaseUpdatePurchaseVehicle,
  CaseUpdatePurchaseVehicleCompleted,
  CaseUpdatePurchaseVehicleError,
  CasePurchaseModalOpen,
  CasePurchaseModalClose,
  CaseUpdateSellVehicle,
  CaseUpdateSellVehicleCompleted,
  CaseUpdateSellVehicleError,
  CaseUpdateUpdateCaseOwners,
  CaseUpdateUpdateCaseOwnersCompleted,
  CaseUpdateUpdateCaseOwnersError,
  CaseUpdateUpdateCasePreviousOwners,
  CaseUpdateUpdateCasePreviousOwnersCompleted,
  CaseUpdateUpdateCasePreviousOwnersError,
  CaseUpdateUpdateCaseProspectCustomers,
  CaseUpdateUpdateCaseProspectCustomersCompleted,
  CaseUpdateUpdateCaseProspectCustomersError,
  CaseUpdateVin,
  CaseUpdateVinCompleted,
  CaseUpdateVinError,
  CaseUpdateMatriculationNumber,
  CaseUpdateMatriculationNumberCompleted,
  CaseUpdateMatriculationNumberError,
  CaseLoadLightDamageEstimator,
  CaseLightDamageEstimatorLoadCompleted,
  CaseLightDamageEstimatorLoadError,
  CaseUpdateLightDamageEstimator,
  CaseLightDamageEstimatorUpdateCompleted,
  CaseLightDamageEstimatorUpdateError,
  CaseUpdateLightDamagePartsAdjustment,
  CaseUpdateSavePublicSiteConfiguration,
  CaseUpdateSavePublicSiteConfigurationCompleted,
  CaseUpdateSavePublicSiteConfigurationError,
  OpenResellerSiteConfigurationModal,
  CloseResellerSiteConfigurationModal,
  CaseUpdateCaseResellerSiteConfiguration,
  CaseUpdateCaseResellerSiteConfigurationCompleted,
  CaseUpdateCaseResellerSiteConfigurationError,
  CaseUpdateReserveVehicle,
  CaseUpdateReserveVehicleCompleted,
  CaseUpdateReserveVehicleError,
  CaseUpdateOpenReservationModal,
  CaseUpdateCloseReservationModal,
  CaseUpdateCancelReservation,
  CaseUpdateCancelReservationCompleted,
  CaseUpdateCancelReservationError,
  CaseQRCodeLoad,
  CaseQRCodeLoaded,
  CaseQRCodeLoadError,
  CaseUpdateValuationUniqueDataValidateBroadcast,
  CaseUpdateValuationUniqueDataValidateBroadcastShowWarning,
  CaseUpdateValuationUniqueDataUserCancelled,
  CaseUpdateValuationUniqueData,
  CaseUpdateValuationUniqueDataCompleted,
  CaseUpdateValuationUniqueDataError,
  CaseOpenUniqueDataModal,
  CaseCloseUniqueDataModal,
  LoadCaseDocumentList,
  CaseDocumentListLoaded,
  CaseDocumentListLoadError,
  AddCaseDocument,
  CaseDocumentAdded,
  CaseDocumentUploadSetTotalCount,
  CaseDocumentUploadReset,
  CaseDocumentAddError,
  DownloadCaseDocument,
  CaseDocumentDownloaded,
  CaseDocumentDownloadError,
  DeleteCaseDocument,
  CaseDocumentDeleted,
  CaseDocumentDeleteError,
  SetIsProcessingCaseDocumentForm,
  SetHasCaseDocumentFormProcessingError,
  CaseUpdateOpenReturnToStockModal,
  CaseUpdateCloseReturnToStockModal,
  CaseUpdateReturnToStock,
  CaseUpdateReturnToStockSuccess,
  CaseUpdateReturnToStockError,
  CaseUpdateTechnicalData,
  CaseUpdateTechnicalDataCompleted,
  CaseUpdateTechnicalDataError,
  CaseOpenTechnicalDataModal,
  CaseCloseTechnicalDataModal,
  UpdateCaseNotes,
  CaseNotesUpdateCompleted,
  CaseNotesUpdateError,
  CaseUpdateActiveCaseVehicleEquipmentIsEnabled,
  LoadEmissionStickerImage,
  EmissionStickerImageLoadCompleted,
  EmissionStickerImageLoadError,
  CaseUpdateDueToInStock,
  CaseUpdateDueToInStockSuccess,
  CaseUpdateDueToInStockError,
  CaseUpdateStockPricingDetails,
  CaseUpdateSoldPricingDetails,
  CaseUpdatePricingSuccess,
  CaseUpdatePricingDetailsError,
  CaseChangePricingDetailsModalVisibility,
  CaseUpdatePlateNo,
  CaseUpdatePlateNoCompleted,
  CaseUpdatePlateNoError,
  ResendCaseLatestQuote,
  ResendCaseLatestQuoteCompleted,
  ResendCaseLatestQuoteError,
  ResendCaseLatestOffer,
  ResendCaseLatestOfferCompleted,
  ResendCaseLatestOfferError,
  CaseLoadFuelSubtypes,
  CaseLoadFuelSubtypesCompleted,
  CaseLoadFuelSubtypesError,
  CaseLoadEmissionInfo,
  CaseLoadEmissionInfoCompleted,
  CaseLoadEmissionInfoError,
  CaseLoadRoadTax,
  CaseLoadRoadTaxCompleted,
  CaseLoadRoadTaxError,
  ResetWasValuation,
  CaseLoadReturnReport,
  CaseLoadReturnReportCompleted,
  CaseLoadReturnReportError,
  CaseUpdateReturnReport,
  CaseUpdateReturnReportCompleted,
  CaseUpdateReturnReportError,
  CaseOpenReturnReportModal,
  CaseCloseReturnReportModal,
  UpdateYoutubeUrl,
  UpdateYoutubeUrlSuccess,
  UpdateYoutubeUrlError,
  CaseLoadEquipments,
  CaseLoadEquipmentsError,
  CaseLoadEquipmentsCompleted,
  CaseUpdateTwinnerImages,
  CaseUpdateTwinnerImagesCompleted,
  CaseUpdateTwinnerImagesError,
  DoNotUpdateTwinnerImages,
  CaseUpdateTwinnerImagesAfterValuation,
  CaseUpdateTwinnerImagesAfterValuationCompleted,
  CaseUpdateTwinnerImagesAfterValuationError,
  CaseLoadEquipmentsValid,
  CaseLoadEquipmentsValidCompleted,
  CaseLoadEquipmentsValidError,
  UpdateIdentifiedVehicleBuildabilityData,
  UpdateIdentifiedVehicleBuildabilityDataCompleted,
  UpdateIdentifiedVehicleBuildabilityDataError,
  CaseResetLightDamageEstimator,
  ResetCaseNotes,
  ResetCaseDocumentList,
  CaseOpenMoveToStockModal,
  CaseCloseMoveToStockModal,
  CaseOpenStockOfferModal,
  CaseCloseStockOfferModal,
  CaseOpenQuoteModal,
  CaseCloseQuoteModal,
  CaseOpenSellModal,
  CaseCloseSellModal,
  LoadCaseBlackBoxPriceDetails,
  LoadCaseBlackBoxPriceDetailsCompleted,
  LoadCaseBlackBoxPriceDetailsError,
};
