import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { GalleryManagerPartialState } from './gallery-manager.reducer';
import { galleryManagerQuery } from './gallery-manager.selectors';
import {
  AddImage,
  AddImages,
  ImageDelete,
  ImagePreviewClose,
  ImagePreviewOpen,
  ImageReplace,
  LoadGalleryImageOrderList,
  PublicGalleryImageOrderListLoad,
  ReorderImageList,
  ResellerGalleryImageOrderListLoad,
  UpdatePromotionalImages,
} from './gallery-manager.actions';
import { UpdateCaseImagesOrderCommand, UpdateCasePromoImagesCommand } from '@vpfa/rest-api/valuation';

export interface AddCaseFileCommand {
  aggregateRootId: string;
  file: File;
  isFirst: boolean;
}

export interface AddCaseImagesCommand {
  aggregateRootId: string;
  images: File[];
}

@Injectable({ providedIn: 'root' })
export class GalleryManagerFacade {
  isUploading$ = this.store.pipe(select(galleryManagerQuery.getIsUploading));
  uploadQueueSize$ = this.store.pipe(select(galleryManagerQuery.getUploadQueueSize));
  uploadQueueCurrentItemIndex$ = this.store.pipe(select(galleryManagerQuery.getUploadQueueCurrentItemIndex));
  imagesOrderList$ = this.store.pipe(select(galleryManagerQuery.getImagesOrderList));
  isLoading$ = this.store.pipe(select(galleryManagerQuery.getIsImagesLoading));
  isImagesOrderListLoading$ = this.store.pipe(select(galleryManagerQuery.getIsImagesOrderListLoading));
  galleryImagesTotalSize$ = this.store.pipe(select(galleryManagerQuery.getGalleryImagesTotalSize));
  galleryImagesTotalCount$ = this.store.pipe(select(galleryManagerQuery.getGalleryImagesTotalCount));
  getGalleryCaseId$ = this.store.pipe(select(galleryManagerQuery.getGalleryCaseId));
  isProcessingPromotionalImages$ = this.store.pipe(select(galleryManagerQuery.getIsProcessingPromotionalImages));
  isImagePreviewOpen$ = this.store.pipe(select(galleryManagerQuery.getIsImagePreviewOpen));
  isImagesOrderListLoaded$ = this.store.pipe(select(galleryManagerQuery.getIsImagesOrderListLoaded));

  constructor(private store: Store<GalleryManagerPartialState>) {}

  loadGalleryImageOrderList(caseId: string, synchronize = false) {
    this.store.dispatch(new LoadGalleryImageOrderList(caseId, synchronize));
  }

  loadPublicOfferImageListOrder(caseId: string) {
    this.store.dispatch(new PublicGalleryImageOrderListLoad(caseId));
  }

  loadResellerOfferImageListOrder(caseId: string) {
    this.store.dispatch(new ResellerGalleryImageOrderListLoad(caseId));
  }

  deleteCaseFiles(aggregateRootId: string, fileKey: string) {
    this.store.dispatch(new ImageDelete({ caseId: aggregateRootId, fileKey }));
  }

  addImage(command: AddCaseFileCommand, selectedForAdvertisement: boolean) {
    this.store.dispatch(new AddImage({ command, selectedForAdvertisement }));
  }

  addImages(command: AddCaseImagesCommand) {
    this.store.dispatch(new AddImages(command));
  }

  replaceImage(aggregateRootId: string, image: any, originalFileKey: string, isSelectedForAdvertisement = false) {
    this.store.dispatch(new ImageReplace({ image, aggregateRootId, originalFileKey }, isSelectedForAdvertisement));
  }

  reorderImageList(command: UpdateCaseImagesOrderCommand) {
    this.store.dispatch(new ReorderImageList(command));
  }

  updatePromotionalImages(command: UpdateCasePromoImagesCommand) {
    this.store.dispatch(new UpdatePromotionalImages(command));
  }

  openImagePreview() {
    this.store.dispatch(new ImagePreviewOpen());
  }

  closeImagePreview() {
    this.store.dispatch(new ImagePreviewClose());
  }
}
