<div *vpfaResponsiveIf="'web'">
  <div *ngIf="currentPart" class="estimator-row part-row">
    <div class="estimator-col estimator-col-area" fxFlex></div>
    <div
      nz-tooltip
      [nzTooltipOverlayClassName]="'tooltip'"
      [nzTooltipTitle]="isLabelOverlapping ? currentPart.name : null"
      [nzTooltipTrigger]="'hover'"
      [nzTooltipPlacement]="'top'"
      class="estimator-col estimator-col-part"
      fxFlex
    >
      {{ currentPart.name }}
    </div>
    <div class="estimator-col estimator-col-cost" fxFlex>
      <vpfa-number-field
        [ngClass]="{ invalid: cost.invalid }"
        [currencyHintMode]="CURRENCY_DISPLAY_FORMAT.SYMBOL"
        fcName="cost"
        [parentFormGroup]="formGroup"
        fieldWidth="90%"
        [allowNegativeNumber]="false"
        [maxFractionNumber]="2"
      >
      </vpfa-number-field>
    </div>
    <div
      class="estimator-col-damage"
      fxFlex
      nz-tooltip
      [nzTooltipOverlayClassName]="'tooltip'"
      [nzTooltipTitle]="isSelectOverlapping ? damage.value.name : null"
      [nzTooltipTrigger]="'hover'"
      [nzTooltipPlacement]="'top'"
    >
      <vpfa-select
        [allowClear]="true"
        [parentFormGroup]="formGroup"
        [fc]="damage"
        [options]="damageTypes"
        size="small"
        [placeholder]="'damageEstimatorModal.choose' | translate"
        [nzDropdownMatchSelectWidth]="false"
        [customOptionTemplate]="customOption"
      >
      </vpfa-select>
    </div>
    <div class="estimator-col-skip" fxFlex>
      <vpfa-switch [parentFormGroup]="formGroup" [fc]="isIncludedInValuation"></vpfa-switch>
    </div>
  </div>
</div>

<div *vpfaResponsiveIf="'mobile'">
  <div class="mobile-part-container no-hor-scroll">
    <nz-divider class="mobile-divider"></nz-divider>
    <h3 class="part-title">{{ currentPart.name | uppercase }}</h3>
    <div [fxLayout]="'column'">
      <vpfa-mobile-field-validation-container
        [validationControl]="formGroup.get('cost')"
        [errorMessages]="[
          {
            error: 'costInvalid',
            errorMsg: 'damageEstimatorModal.costInvalid' | translate: { costMax: costMaxValue }
          }
        ]"
        [showInvalidBeforeDirty]="true"
      >
        <vpfa-label text="{{ 'damageEstimatorModal.cost' | translate }}"></vpfa-label>
        <vpfa-number-field
          [currencyHintMode]="CURRENCY_DISPLAY_FORMAT.SYMBOL"
          fcName="cost"
          [parentFormGroup]="formGroup"
          fieldWidth="100%"
          [maxFractionNumber]="2"
          [allowNegativeNumber]="false"
        ></vpfa-number-field>
      </vpfa-mobile-field-validation-container>
      <vpfa-mobile-field-validation-container
        [validationControl]="formGroup.get('selectedDamageTypeId')"
        [errorMessages]="[
          {
            error: 'damageInvalid',
            errorMsg: 'damageEstimatorModal.damageInvalid' | translate: { costMax: costMaxValue }
          }
        ]"
        [showInvalidBeforeDirty]="true"
      >
        <vpfa-label text="{{ 'damageEstimatorModal.damage' | translate }}"></vpfa-label>
        <vpfa-select
          [allowClear]="true"
          [parentFormGroup]="formGroup"
          [fc]="damage"
          [options]="damageTypes"
          size="small"
          [placeholder]="'damageEstimatorModal.choose' | translate"
        ></vpfa-select>
      </vpfa-mobile-field-validation-container>
      <vpfa-setting-box [divider]="false" [fxLayout]="'row'" [fxLayoutAlign]="'space-between center'">
        <ng-container label>
          <vpfa-label text="{{ 'damageEstimatorModal.includeIntoValuation' | translate }}"></vpfa-label>
        </ng-container>
        <ng-container content>
          <vpfa-switch [parentFormGroup]="formGroup" [fc]="isIncludedInValuation"></vpfa-switch>
        </ng-container>
      </vpfa-setting-box>
    </div>
  </div>
</div>
<ng-template #customOption let-label="label">
  <div class="estimator-part-option">{{ label }}</div>
</ng-template>
