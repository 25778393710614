import { Component, Input } from '@angular/core';
import { CaseTaxDto, TaxType } from '@vpfa/rest-api/valuation';

@Component({
  selector: 'vpfa-tax-information',
  templateUrl: './tax-information.component.html',
  styleUrls: ['./tax-information.component.scss'],
})
export class TaxInformationComponent  {
  @Input() tax: CaseTaxDto;
  @Input() alwaysGross: boolean = false;

  TaxType = TaxType;
}
