import { Component, Input } from '@angular/core';
import { CaseStatus } from '@vpfa/rest-api/valuation';

@Component({
  selector: 'vpfa-sold-status',
  templateUrl: './sold-status.component.html',
  styleUrls: ['./sold-status.component.scss']
})
export class SoldStatusComponent {
  @Input() status: CaseStatus;

  hasSoldStatus() {
    return this.status === CaseStatus.Sold;
  }
}
