/**
 * Ad Broadcast Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { CommandHandlerResponse } from '../model/commandHandlerResponse';
import { CreateBusinessBroadcastConnectionFtpConfigurationCommand } from '../model/createBusinessBroadcastConnectionFtpConfigurationCommand';
import { CreateCountryBroadcastConnectionFtpConfigurationCommand } from '../model/createCountryBroadcastConnectionFtpConfigurationCommand';
import { UpdateBusinessBroadcastConnectionConfigurationCommand } from '../model/updateBusinessBroadcastConnectionConfigurationCommand';
import { UpdateCountryBroadcastConnectionConfigurationHostnameCommand } from '../model/updateCountryBroadcastConnectionConfigurationHostnameCommand';
import { UpdateCountryBroadcastConnectionConfigurationIsActiveCommand } from '../model/updateCountryBroadcastConnectionConfigurationIsActiveCommand';
import { UpdateCountryBroadcastConnectionConfigurationNameCommand } from '../model/updateCountryBroadcastConnectionConfigurationNameCommand';
import { UpdateCountryBroadcastConnectionConfigurationPasswordCommand } from '../model/updateCountryBroadcastConnectionConfigurationPasswordCommand';
import { UpdateCountryBroadcastConnectionConfigurationUsernameCommand } from '../model/updateCountryBroadcastConnectionConfigurationUsernameCommand';
import { UpdateCountryBroadcastConnectionFtpConfigurationCommand } from '../model/updateCountryBroadcastConnectionFtpConfigurationCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class BroadcastConnectionConfigurationService {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Lazy check AmsAction:POST/api/BroadcastConnectionConfiguration/BusinessFtp AmsResource:{country}/{business}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBusinessBroadcastConnectionFtpConfiguration(body?: CreateBusinessBroadcastConnectionFtpConfigurationCommand, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public createBusinessBroadcastConnectionFtpConfiguration(body?: CreateBusinessBroadcastConnectionFtpConfigurationCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public createBusinessBroadcastConnectionFtpConfiguration(body?: CreateBusinessBroadcastConnectionFtpConfigurationCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public createBusinessBroadcastConnectionFtpConfiguration(body?: CreateBusinessBroadcastConnectionFtpConfigurationCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommandHandlerResponse>(`${this.basePath}/api/BroadcastConnectionConfiguration/BusinessFtp`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:POST/api/BroadcastConnectionConfiguration/Ftp AmsResource:{country}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCountryBroadcastConnectionFtpConfiguration(body?: CreateCountryBroadcastConnectionFtpConfigurationCommand, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public createCountryBroadcastConnectionFtpConfiguration(body?: CreateCountryBroadcastConnectionFtpConfigurationCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public createCountryBroadcastConnectionFtpConfiguration(body?: CreateCountryBroadcastConnectionFtpConfigurationCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public createCountryBroadcastConnectionFtpConfiguration(body?: CreateCountryBroadcastConnectionFtpConfigurationCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommandHandlerResponse>(`${this.basePath}/api/BroadcastConnectionConfiguration/Ftp`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/BroadcastConnectionConfiguration/UpdateBusinessBroadcastConnectionConfiguration AmsResource:{country}/{business}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBusinessBroadcastConnectionConfiguration(body?: UpdateBusinessBroadcastConnectionConfigurationCommand, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateBusinessBroadcastConnectionConfiguration(body?: UpdateBusinessBroadcastConnectionConfigurationCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateBusinessBroadcastConnectionConfiguration(body?: UpdateBusinessBroadcastConnectionConfigurationCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateBusinessBroadcastConnectionConfiguration(body?: UpdateBusinessBroadcastConnectionConfigurationCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/BroadcastConnectionConfiguration/UpdateBusinessBroadcastConnectionConfiguration`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/BroadcastConnectionConfiguration/UpdateCountryBroadcastConnectionConfigurationHostname AmsResource:{country}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCountryBroadcastConnectionConfigurationHostname(body?: UpdateCountryBroadcastConnectionConfigurationHostnameCommand, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateCountryBroadcastConnectionConfigurationHostname(body?: UpdateCountryBroadcastConnectionConfigurationHostnameCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateCountryBroadcastConnectionConfigurationHostname(body?: UpdateCountryBroadcastConnectionConfigurationHostnameCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateCountryBroadcastConnectionConfigurationHostname(body?: UpdateCountryBroadcastConnectionConfigurationHostnameCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/BroadcastConnectionConfiguration/UpdateCountryBroadcastConnectionConfigurationHostname`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCountryBroadcastConnectionConfigurationIsActive(body?: UpdateCountryBroadcastConnectionConfigurationIsActiveCommand, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateCountryBroadcastConnectionConfigurationIsActive(body?: UpdateCountryBroadcastConnectionConfigurationIsActiveCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateCountryBroadcastConnectionConfigurationIsActive(body?: UpdateCountryBroadcastConnectionConfigurationIsActiveCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateCountryBroadcastConnectionConfigurationIsActive(body?: UpdateCountryBroadcastConnectionConfigurationIsActiveCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/BroadcastConnectionConfiguration/UpdateCountryBroadcastConnectionConfigurationIsActive`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/BroadcastConnectionConfiguration/UpdateCountryBroadcastConnectionConfigurationName AmsResource:{country}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCountryBroadcastConnectionConfigurationName(body?: UpdateCountryBroadcastConnectionConfigurationNameCommand, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateCountryBroadcastConnectionConfigurationName(body?: UpdateCountryBroadcastConnectionConfigurationNameCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateCountryBroadcastConnectionConfigurationName(body?: UpdateCountryBroadcastConnectionConfigurationNameCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateCountryBroadcastConnectionConfigurationName(body?: UpdateCountryBroadcastConnectionConfigurationNameCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/BroadcastConnectionConfiguration/UpdateCountryBroadcastConnectionConfigurationName`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/BroadcastConnectionConfiguration/UpdateCountryBroadcastConnectionConfigurationPassword AmsResource:{country}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCountryBroadcastConnectionConfigurationPassword(body?: UpdateCountryBroadcastConnectionConfigurationPasswordCommand, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateCountryBroadcastConnectionConfigurationPassword(body?: UpdateCountryBroadcastConnectionConfigurationPasswordCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateCountryBroadcastConnectionConfigurationPassword(body?: UpdateCountryBroadcastConnectionConfigurationPasswordCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateCountryBroadcastConnectionConfigurationPassword(body?: UpdateCountryBroadcastConnectionConfigurationPasswordCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/BroadcastConnectionConfiguration/UpdateCountryBroadcastConnectionConfigurationPassword`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/BroadcastConnectionConfiguration/UpdateCountryBroadcastConnectionConfigurationUsername AmsResource:{country}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCountryBroadcastConnectionConfigurationUsername(body?: UpdateCountryBroadcastConnectionConfigurationUsernameCommand, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateCountryBroadcastConnectionConfigurationUsername(body?: UpdateCountryBroadcastConnectionConfigurationUsernameCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateCountryBroadcastConnectionConfigurationUsername(body?: UpdateCountryBroadcastConnectionConfigurationUsernameCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateCountryBroadcastConnectionConfigurationUsername(body?: UpdateCountryBroadcastConnectionConfigurationUsernameCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/BroadcastConnectionConfiguration/UpdateCountryBroadcastConnectionConfigurationUsername`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/BroadcastConnectionConfiguration/UpdateCountryBroadcastConnectionFtpConfiguration AmsResource:{country}/_* 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCountryBroadcastConnectionFtpConfiguration(body?: UpdateCountryBroadcastConnectionFtpConfigurationCommand, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public updateCountryBroadcastConnectionFtpConfiguration(body?: UpdateCountryBroadcastConnectionFtpConfigurationCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public updateCountryBroadcastConnectionFtpConfiguration(body?: UpdateCountryBroadcastConnectionFtpConfigurationCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public updateCountryBroadcastConnectionFtpConfiguration(body?: UpdateCountryBroadcastConnectionFtpConfigurationCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/BroadcastConnectionConfiguration/UpdateCountryBroadcastConnectionFtpConfiguration`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/BroadcastConnectionConfiguration/BusinessLogo AmsResource:{country}/{business}/_* 
     * @param aggregateRootId 
     * @param businessId 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadBusinessBroadcastConnectionConfigurationLogo(aggregateRootId?: string, businessId?: string, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public uploadBusinessBroadcastConnectionConfigurationLogo(aggregateRootId?: string, businessId?: string, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public uploadBusinessBroadcastConnectionConfigurationLogo(aggregateRootId?: string, businessId?: string, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public uploadBusinessBroadcastConnectionConfigurationLogo(aggregateRootId?: string, businessId?: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any) };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (aggregateRootId !== undefined) {
            formParams = formParams.append('AggregateRootId', <any>aggregateRootId) || formParams;
        }
        if (businessId !== undefined) {
            formParams = formParams.append('BusinessId', <any>businessId) || formParams;
        }
        if (file !== undefined) {
            formParams = formParams.append('File', <any>file) || formParams;
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/BroadcastConnectionConfiguration/BusinessLogo`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/BroadcastConnectionConfiguration/Certificate AmsResource:{country}/_* 
     * @param aggregateRootId 
     * @param countryId 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadCountryBroadcastConnectionConfigurationCertificate(aggregateRootId?: string, countryId?: string, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public uploadCountryBroadcastConnectionConfigurationCertificate(aggregateRootId?: string, countryId?: string, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public uploadCountryBroadcastConnectionConfigurationCertificate(aggregateRootId?: string, countryId?: string, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public uploadCountryBroadcastConnectionConfigurationCertificate(aggregateRootId?: string, countryId?: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any) };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (aggregateRootId !== undefined) {
            formParams = formParams.append('AggregateRootId', <any>aggregateRootId) || formParams;
        }
        if (countryId !== undefined) {
            formParams = formParams.append('CountryId', <any>countryId) || formParams;
        }
        if (file !== undefined) {
            formParams = formParams.append('File', <any>file) || formParams;
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/BroadcastConnectionConfiguration/Certificate`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:PUT/api/BroadcastConnectionConfiguration/CountryLogo AmsResource:{country}/_* 
     * @param aggregateRootId 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadCountryBroadcastConnectionConfigurationLogo(aggregateRootId?: string, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<CommandHandlerResponse>;
    public uploadCountryBroadcastConnectionConfigurationLogo(aggregateRootId?: string, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandHandlerResponse>>;
    public uploadCountryBroadcastConnectionConfigurationLogo(aggregateRootId?: string, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandHandlerResponse>>;
    public uploadCountryBroadcastConnectionConfigurationLogo(aggregateRootId?: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any) };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (aggregateRootId !== undefined) {
            formParams = formParams.append('AggregateRootId', <any>aggregateRootId) || formParams;
        }
        if (file !== undefined) {
            formParams = formParams.append('File', <any>file) || formParams;
        }

        return this.httpClient.put<CommandHandlerResponse>(`${this.basePath}/api/BroadcastConnectionConfiguration/CountryLogo`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
