<ul class="vehicle-data" *ngIf="vehicleData" [class.horizontal]="displayHorizontal">
  <ng-container *ngFor="let identifier of featureIdentifiersOrder$ | async">
    <ng-container *ngTemplateOutlet="getTemplateBasedOnIdentificationMethod(identifier)"></ng-container>
  </ng-container>

  <ng-template #vinElement>
    <li *vpfaResponsiveIf="'web'; else mobileVin" [ngClass]="{ 'empty-margin': !vehicleData.vin && !isVinEditable }">
      <ng-container *ngIf="vehicleData.vin && !isVinEditable">
        <ng-container *ngTemplateOutlet="nonEditableVin"></ng-container>
      </ng-container>
      <ng-container *ngIf="isVinEditable">
        <vpfa-short-label-inline-editable
          id="vinShortLabelEditable"
          [isLoading]="isLoadingCaseData || isUpdatingVin"
          [label]="'vehicleBasicInfo.vin' | translate"
          [errorMessages]="[
            {
              error: 'required',
              errorMsg: 'search.vinIsRequired' | translate
            },
            {
              error: 'vin',
              errorMsg: 'search.incorrectVINFormat' | translate
            },
            {
              error: 'vinLength',
              errorMsg: 'search.vinLengthError' | translate
            }
          ]"
          [control]="vinControl"
          [(value)]="vin"
          [showValue]="!noVinWarning"
          [required]="noVinWarning"
          [uppercase]="true"
          [showCheckedSymbol]="showCheckedSymbolOnVin"
          [showBlueBackground]="showBlueBackgroundOnVin"
        >
          {{ noVinWarning ? ('vehicleBasicInfo.addVin' | translate) : '' }}
        </vpfa-short-label-inline-editable>
      </ng-container>
    </li>
  </ng-template>

  <ng-template #mobileVin>
    <li *ngIf="vehicleData.vin">
      <ng-container *ngTemplateOutlet="nonEditableVin"></ng-container>
    </li>
  </ng-template>

  <ng-template #natcodeElement>
    <li *ngIf="vehicleData.natcode">
      <vpfa-short-label-value
        id="natcodeShortLabel"
        [label]="'vehicleBasicInfo.natCode' | translate"
        [value]="vehicleData.natcode"
      ></vpfa-short-label-value>
    </li>
  </ng-template>

  <ng-template #kbaElement>
    <li *vpfaResponsiveIf="'web'; else mobileKba" [ngClass]="{ 'empty-margin': !vehicleData.kba && !isKbaEditable }">
      <ng-container *ngIf="vehicleData.kba && !isKbaEditable">
        <ng-container *ngTemplateOutlet="nonEditableKba"></ng-container>
      </ng-container>
      <ng-container *ngIf="isKbaEditable">
        <vpfa-short-label-inline-editable-select
          *vpfaResponsiveIf="'web'; else nonEditableKba"
          id="kbaShortLabelEditable"
          [label]="'vehicleBasicInfo.kba' | translate"
          [displayValue]="vehicleData.kba"
          [initOption]="vehicleData.kba"
          (valueChange)="kbaChange.emit($event)"
          [allowClear]="allowKbaClear"
          [isLoading]="isLoadingCaseData || isProcessingKba"
          [isProcessing]="isProcessingKba"
          [options]="possibleKBAs"
          [allowEmptyOptions]="false"
          (emptyListError)="onEmptyKbaList()"
        ></vpfa-short-label-inline-editable-select>
      </ng-container>
    </li>
    <ng-template #mobileKba>
      <li *ngIf="vehicleData.kba">
        <ng-container *ngTemplateOutlet="nonEditableKba"></ng-container>
      </li>
    </ng-template>
  </ng-template>

  <ng-template #vrmElement>
    <li *vpfaResponsiveIf="'web'; else mobilePlateNo" [ngClass]="{ 'empty-margin': plateNo && !isPlateNoEditable }">
      <ng-container *ngIf="plateNo && !isPlateNoEditable">
        <ng-container *ngTemplateOutlet="nonEditablePlate"></ng-container>
      </ng-container>
      <ng-container *ngIf="isPlateNoEditable">
        <vpfa-short-label-inline-editable
          *vpfaResponsiveIf="'web'; else nonEditablePlate"
          id="plateNumberShortLabelEditable"
          [isLoading]="isLoadingCaseData || isUpdatingPlateNo"
          [label]="'vehicleBasicInfo.plateNo' | translate"
          [errorMessages]="[
            {
              error: 'required',
              errorMsg: 'search.plateNoIsRequired' | translate
            },
            {
              error: 'plateNoLength',
              errorMsg: 'search.plateNoLengthError' | translate
            },
            {
              error: 'pattern',
              errorMsg: 'search.plateNoPatternError' | translate
            }
          ]"
          [control]="plateNoControl"
          [(value)]="plate"
          [showValue]="!noPlateWarning"
          [required]="noPlateWarning"
          [uppercase]="true"
          >{{ noPlateWarning ? ('vehicleBasicInfo.addPlateNumber' | translate) : '' }}</vpfa-short-label-inline-editable
        >
      </ng-container>
    </li>
    <ng-template #mobilePlateNo>
      <li *ngIf="plateNo">
        <ng-container *ngTemplateOutlet="nonEditablePlate"></ng-container>
      </li>
    </ng-template>
  </ng-template>

  <ng-template #matriculationnumberElement>
    <li
      *vpfaResponsiveIf="'web'; else mobileMatriculationNumber"
      [ngClass]="{ 'empty-margin': !matriculationNumber && !isMatriculationNumberEditable }"
    >
      <ng-container *ngIf="matriculationNumber && !isMatriculationNumberEditable">
        <ng-container *ngTemplateOutlet="nonEditableMatriculationNumber"></ng-container>
      </ng-container>
      <ng-container *ngIf="isMatriculationNumberEditable">
        <vpfa-short-label-inline-editable
          id="matriculationNumberShortLabelEditable"
          [isLoading]="isLoadingCaseData || isUpdatingMatriculationNumber"
          [label]="'vehicleBasicInfo.matriculationNumber' | translate"
          [errorMessages]="[
            {
              error: 'required',
              errorMsg: 'search.matriculationNumberIsRequired' | translate
            },
            {
              error: 'pattern',
              errorMsg: 'search.incorrectMatriculationNumberFormat' | translate
            }
          ]"
          [control]="matriculationNumberControl"
          [(value)]="matriculationNumberValue"
          [uppercase]="true"
        ></vpfa-short-label-inline-editable>
      </ng-container>
    </li>
  </ng-template>

  <ng-template #mobileMatriculationNumber>
    <li *ngIf="matriculationNumber">
      <ng-container *ngTemplateOutlet="nonEditableMatriculationNumber"></ng-container>
    </li>
  </ng-template>
</ul>

<ng-template #nonEditableVin>
  <vpfa-short-label-value
    id="vinShortLabel"
    [label]="'vehicleBasicInfo.vin' | translate"
    [value]="vehicleData.vin | uppercase"
    [showCheckedSymbol]="showCheckedSymbolOnVin"
    [showBlueBackground]="showBlueBackgroundOnVin"
  ></vpfa-short-label-value>
</ng-template>

<ng-template #nonEditableKba>
  <vpfa-short-label-value
    id="kbaShortLabel"
    [label]="'vehicleBasicInfo.kba' | translate"
    [value]="vehicleData.kba | uppercase"
  ></vpfa-short-label-value>
</ng-template>

<ng-template #nonEditablePlate>
  <vpfa-short-label-value
    id="plateNumberShortLabel"
    [label]="'vehicleBasicInfo.plateNo' | translate"
    [value]="plateNo"
  ></vpfa-short-label-value>
</ng-template>

<ng-template #nonEditableMatriculationNumber>
  <vpfa-short-label-value
    id="matriculationNumberShortLabel"
    [label]="'vehicleBasicInfo.matriculationNumber' | translate"
    [value]="matriculationNumber | uppercase"
  ></vpfa-short-label-value>
</ng-template>
