import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedPipesModule } from '@vpfa/shared/pipes';
import { UiKitHeaderTitleModule } from '@vpfa/ui-kit/header-title';
import { AgGridModule } from 'ag-grid-angular';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { DataTableHeaderComponent } from './components/data-table-header/data-table-header.component';
import { DataTableSettingsColumnsComponent } from './components/data-table-settings-columns/data-table-settings-columns.component';
import { DataTableSettingsComponent } from './components/data-table-settings/data-table-settings.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { DatepickerFloatingFilterComponent } from './filters/datepicker-floating-filter/datepicker-floating-filter.component';
import { DropdownFloatingFilterComponent } from './filters/dropdown-floating-filter/dropdown-floating-filter.component';
import { CurrencyRendererComponent } from './renderers/currency-renderer/currency-renderer.component';
import { DateRendererComponent } from './renderers/date-renderer/date-renderer.component';
import { NumberRendererComponent } from './renderers/number-renderer/number-renderer.component';
import { TranslateRendererComponent } from './renderers/translate-renderer/translate-renderer.component';
import { DataTablePaginationComponent } from './components/data-table-pagination/data-table-pagination.component';
import { UiKitModule } from '@vpfa/ui-kit';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { ActionsRendererComponent } from './renderers/actions-renderer/actions-renderer.component';
import { MultiValueRendererComponent } from './renderers/multi-value-renderer/multi-value-renderer.component';
import { TextFloatingFilterComponent } from './filters/text-floating-filter/text-floating-filter.component';
import { FullWidthCellGroupComponent } from './renderers/full-width-cell-group/full-width-cell-group.component';
import { SwitchRendererComponent } from './renderers/switch-renderer/switch-renderer.component';
import { UiKitSwitchModule } from '@vpfa/ui-kit/switch';
import { SubjectRendererComponent } from './renderers/subject-renderer/subject-renderer.component';
import { IconActionRendererComponent } from './renderers/icon-action-renderer/icon-action-renderer.component';
import { DataTableNoRowsIndicatorComponent } from './components/data-table-no-rows-indicator/data-table-no-rows-indicator.component';
import { ImageRendererComponent } from './renderers/image-renderer/image-renderer.component';
import { UiKitArrowLinkModule } from '@vpfa/ui-kit/arrow-link';
import { UiKitActionDropdownModule } from '@vpfa/ui-kit/action-dropdown';
import { TruncateRendererComponent } from './renderers/truncate-renderer/truncate-renderer.component';
import { RequestPreviewActionRendererComponent } from './renderers/request-preview-action-renderer/request-preview-action-renderer.component';
import { CheckboxRendererComponent } from './renderers/checkbox-renderer/checkbox-renderer.component';
import { TextInputRendererComponent } from './renderers/text-input-renderer/text-input-renderer.component';
import { TextInputEditorComponent } from './editors/text-input-editor/text-input-editor.component';
import { ResponsePreviewActionRendererComponent } from './renderers/response-preview-action-renderer/response-preview-action-renderer.component';
import { SharedLoadersModule } from '@vpfa/shared/loaders';
import { UiKitSectionContainerModule } from '@vpfa/ui-kit/section-container';
import { IconDeleteRendererComponent } from './renderers/icon-delete-renderer/icon-delete-renderer.component';
import { TableHeaderTooltipRendererComponent } from './renderers/table-header-tooltip-renderer/table-header-tooltip-renderer.component';
import { NumberFloatingFilterComponent } from './filters/number-floating-filter/number-floating-filter.component';
import { SharedDirectivesModule } from '@vpfa/shared/directives';
import { LinksRendererComponent } from './renderers/links-renderer/links-renderer.component';
import { TaxedPriceRendererComponent } from './renderers/taxed-price-renderer/taxed-price-renderer.component';
import { DateRangeFloatingFilterComponent } from './filters/date-range-floating-filter/date-range-floating-filter.component';
import { SensitiveDataRendererComponent } from './renderers/sensitive-data-renderer/sensitive-data.renderer';
import { LinkCellRendererComponent } from './renderers/link-cell-renderer/link-cell-renderer.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NumberRangeFloatingFilterComponent } from './filters/number-range-floating-filter/number-range-floating-filter.component';
import { NumberRangeSelectFloatingFilterComponent } from './filters/number-range-select-floating-filter/number-range-select-floating-filter.component';
import { DateRangeFloatingFilterOldComponent } from './filters/date-range-floating-filter-old/date-range-floating-filter-old.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AgGridModule,
    TranslateModule,
    NzSelectModule,
    NzDatePickerModule,
    NzButtonModule,
    NzPopoverModule,
    NzPopconfirmModule,
    SharedPipesModule,
    FlexLayoutModule,
    NzSpinModule,
    NzCheckboxModule,
    NzIconModule,
    UiKitHeaderTitleModule,
    UiKitModule,
    UiKitSwitchModule,
    UiKitArrowLinkModule,
    UiKitActionDropdownModule,
    NzToolTipModule,
    SharedLoadersModule,
    UiKitSectionContainerModule,
    SharedDirectivesModule,
    NzDropDownModule,
  ],
  declarations: [
    LinkCellRendererComponent,
    DataTableComponent,
    DropdownFloatingFilterComponent,
    DatepickerFloatingFilterComponent,
    TextFloatingFilterComponent,
    DateRendererComponent,
    NumberRendererComponent,
    CurrencyRendererComponent,
    TranslateRendererComponent,
    DataTableSettingsColumnsComponent,
    DataTableSettingsComponent,
    DataTableHeaderComponent,
    DataTablePaginationComponent,
    ActionsRendererComponent,
    MultiValueRendererComponent,
    FullWidthCellGroupComponent,
    SwitchRendererComponent,
    SubjectRendererComponent,
    IconActionRendererComponent,
    DataTableNoRowsIndicatorComponent,
    ImageRendererComponent,
    TruncateRendererComponent,
    SensitiveDataRendererComponent,
    RequestPreviewActionRendererComponent,
    CheckboxRendererComponent,
    TextInputRendererComponent,
    TextInputEditorComponent,
    ResponsePreviewActionRendererComponent,
    IconDeleteRendererComponent,
    TableHeaderTooltipRendererComponent,
    NumberFloatingFilterComponent,
    NumberRangeFloatingFilterComponent,
    NumberRangeSelectFloatingFilterComponent,
    LinksRendererComponent,
    TaxedPriceRendererComponent,
    DateRangeFloatingFilterComponent,
    DateRangeFloatingFilterOldComponent,
  ],
  exports: [DataTableComponent, DataTablePaginationComponent],
})
export class UiKitDataTableModule {}
