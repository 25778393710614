<div *vpfaResponsiveIf="'web'">
  <div *ngIf="area && area.parts && area.parts.length" class="estimator-row subarea-row" (click)="toggleCollapse()">
    <div class="estimator-col estimator-col-area" fxFlex>
      <i class="zmdi" [ngClass]="{ 'zmdi-plus': collapsed, 'zmdi-minus': !collapsed }"></i>
    </div>
    <div class="estimator-col estimator-col-part" fxFlex>{{ area.name }}</div>
    <div class="estimator-col estimator-col-cost" fxFlex>{{ total | currencyLocale }}</div>
    <div class="estimator-col estimator-col-damage" fxFlex></div>
    <div class="estimator-col estimator-col-skip" fxFlex></div>
  </div>
  <div class="estimator-subarea-parts" *ngIf="!collapsed" @collapseExpand>
    <vpfa-estimator-part
      [submitted]="submitted"
      [partForm]="estimatorForm.get(part.id)"
      *ngFor="let part of area.parts"
      [part]="part"
      (costChanged)="onPartCostChanged($event)"
    ></vpfa-estimator-part>
  </div>
</div>

<div *vpfaResponsiveIf="'mobile'">
  <div class="header-container no-hor-scroll" [ngClass]="{ 'sticky-header': !collapsed }" [fxLayoutAlign]="'center center'">
  <button
    class="mobile-subarea-row"
    (click)="toggleCollapse()"
    [fxLayoutAlign]="'space-between center'"
    [ngClass]="{ 'border-none': collapsed }"
  >
    <div>
      <i class="zmdi mobile-icon" [ngClass]="{ 'zmdi-plus': collapsed, 'zmdi-minus': !collapsed }"></i>
      <span>{{ area.name }}</span>
    </div>
    <span>{{ total | currencyLocale }}</span>
  </button>
  </div>
  <div *ngIf="!collapsed" @collapseExpand>
    <vpfa-estimator-part
      [submitted]="submitted"
      [partForm]="estimatorForm.get(part.id)"
      *ngFor="let part of area.parts"
      [part]="part"
      (costChanged)="onPartCostChanged($event)"
    ></vpfa-estimator-part>
  </div>
</div>
