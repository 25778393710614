import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromLogo from './+state/logo.reducer';
import { EffectsModule } from '@ngrx/effects';
import { LogoEffects } from './+state/logo.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromLogo.LOGO_FEATURE_KEY, fromLogo.reducer),
    EffectsModule.forFeature([LogoEffects])
  ]
})
export class LogoDataModule {}
