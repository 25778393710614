import { BroadcastActions, BroadcastActionTypes } from './broadcast.actions';
import { produce } from 'immer';
import {
  AdPortalStatusDto,
  CaseBroadcastHistoryListItemDto,
  CaseBroadcastSettingsDto,
} from '@vpfa/rest-api/ad-broadcast';

export const broadcastFeatureKey = 'broadcast';

export interface BroadcastSettingsState {
  activeCaseId: string;
  settings: CaseBroadcastSettingsDto;
  isLoading: boolean;
  logo: {
    [adPortalId: string]: BroadcastLogo;
  };
  isCreatingAdvert: boolean;
  adPortalRequestHistory: AdPortalRequestHistoryDtoStatePartial;
  broadcastAdPortalsStatusList: {
    isLoading: boolean;
    items: Array<AdPortalStatusDto>;
  };
  updateError: boolean;
  confirmUpdateModal: {
    open: boolean;
  };
}

export interface BroadcastLogo {
  item: string;
  isLoading: boolean;
}

export interface AdPortalRequestHistoryDtoStatePartial {
  adPortalId: string;
  items: Array<CaseBroadcastHistoryListItemDto>;
  isLoading: boolean;
}

export const initialState: BroadcastSettingsState = {
  activeCaseId: null,
  settings: null,
  isLoading: false,
  adPortalRequestHistory: { adPortalId: null, isLoading: false, items: [] },
  logo: {},
  isCreatingAdvert: false,
  broadcastAdPortalsStatusList: { isLoading: false, items: [] },
  updateError: false,
  confirmUpdateModal: {
    open: false,
  },
};

export interface BroadcastPartialState {
  readonly [broadcastFeatureKey]: BroadcastSettingsState;
}

export function reducer(
  state: BroadcastSettingsState = initialState,
  action: BroadcastActions
): BroadcastSettingsState {
  switch (action.type) {
    case BroadcastActionTypes.RefreshBroadcastStatuses:
      return produce<BroadcastSettingsState>(state, newState => {
        newState.isLoading = true;
      });
    case BroadcastActionTypes.RefreshBroadcastStatusesSuccess:
    case BroadcastActionTypes.RefreshBroadcastStatusesError:
      return produce<BroadcastSettingsState>(state, newState => {
        newState.isLoading = false;
      });
    case BroadcastActionTypes.LoadCaseBroadcastSettings:
      return produce<BroadcastSettingsState>(state, newState => {
        newState.isLoading = true;
        newState.activeCaseId = action.payload;
      });
    case BroadcastActionTypes.LoadCaseBroadcastSettingsSuccess:
      return produce<BroadcastSettingsState>(state, newState => {
        newState.isLoading = false;
        newState.settings = action.payload;
      });
    case BroadcastActionTypes.LoadCaseBroadcastSettingsError:
      return produce<BroadcastSettingsState>(state, newState => {
        newState.isLoading = false;
      });
    case BroadcastActionTypes.UpdateAdvertConfiguration:
      return produce<BroadcastSettingsState>(state, newState => {
        newState.isCreatingAdvert = true;
        newState.updateError = false;
      });
    case BroadcastActionTypes.UpdateAdvertConfigurationSuccess:
      return produce<BroadcastSettingsState>(state, newState => {
        newState.isCreatingAdvert = false;
        newState.confirmUpdateModal.open = false;
      });
    case BroadcastActionTypes.UpdateAdvertConfigurationError:
      return produce<BroadcastSettingsState>(state, newState => {
        newState.isCreatingAdvert = false;
        newState.confirmUpdateModal.open = false;
        newState.updateError = true;
      });
    case BroadcastActionTypes.LoadBroadcastingItemLogo:
      return produce<BroadcastSettingsState>(state, newState => {
        newState.logo[action.payload] = { isLoading: true, item: null };
      });
    case BroadcastActionTypes.LoadBroadcastingItemLogoSuccess:
      return produce<BroadcastSettingsState>(state, newState => {
        const isAdPortalStored = !!state?.logo && !!state.logo[action.payload.adPortalId];
        if (isAdPortalStored) {
          newState.logo[action.payload.adPortalId].isLoading = false;
          newState.logo[action.payload.adPortalId].item = action.payload.file;
        }
      });
    case BroadcastActionTypes.LoadBroadcastingItemLogoError:
      return produce<BroadcastSettingsState>(state, newState => {
        const isAdPortalStored = !!state?.logo && !!state.logo[action.payload];
        if (isAdPortalStored) {
          newState.logo[action.payload].isLoading = false;
        }
      });

    case BroadcastActionTypes.LoadPortalBroadcastRequestsHistory:
      return produce<BroadcastSettingsState>(state, newState => {
        newState.isLoading = true;
        newState.adPortalRequestHistory.isLoading = true;
      });

    case BroadcastActionTypes.PortalBroadcastRequestsHistoryLoadSuccess:
      return produce<BroadcastSettingsState>(state, newState => {
        newState.isLoading = false;
        newState.adPortalRequestHistory.isLoading = false;
        newState.adPortalRequestHistory.items = action.payload;
      });

    case BroadcastActionTypes.PortalBroadcastRequestsHistoryLoadError:
      return produce<BroadcastSettingsState>(state, newState => {
        newState.adPortalRequestHistory.isLoading = false;
        newState.isLoading = false;
      });

    case BroadcastActionTypes.LoadPortalBroadcastStatuses:
      return produce<BroadcastSettingsState>(state, newState => {
        newState.broadcastAdPortalsStatusList.isLoading = true;
      });

    case BroadcastActionTypes.PortalBroadcastStatusesLoadSuccess:
      return produce<BroadcastSettingsState>(state, newState => {
        newState.broadcastAdPortalsStatusList.items = action.payload;
        newState.broadcastAdPortalsStatusList.isLoading = false;
      });

    case BroadcastActionTypes.PortalBroadcastStatusesLoadError:
      return produce<BroadcastSettingsState>(state, newState => {
        newState.broadcastAdPortalsStatusList.isLoading = false;
      });

    case BroadcastActionTypes.ValidateAdvertError:
      return produce<BroadcastSettingsState>(state, newState => {
        newState.updateError = true;
        newState.confirmUpdateModal.open = false;
      });

    case BroadcastActionTypes.LoadCaseBroadcastIsActiveStatusSuccess:
      return produce<BroadcastSettingsState>(state, newState => {
        newState.settings.adPortals = action.payload.adPortals;
      });

    case BroadcastActionTypes.RenewMobileBroadcast:
      return produce<BroadcastSettingsState>(state, newState => {
        newState.isLoading = true;
      });

    case BroadcastActionTypes.RenewMobileBroadcastSuccess:
      return produce<BroadcastSettingsState>(state, newState => {
        newState.isLoading = false;
      });

    case BroadcastActionTypes.OpenBroadcastUpdateConfirmModal:
      return produce<BroadcastSettingsState>(state, newState => {
        newState.confirmUpdateModal.open = true;
      });

    case BroadcastActionTypes.CloseBroadcastUpdateConfirmModal:
      return produce<BroadcastSettingsState>(state, newState => {
        newState.confirmUpdateModal.open = false;
      });

    case BroadcastActionTypes.CaseBroadcastResetStore:
      state = initialState;
      return state;
    default:
      return state;
  }
}
