import { Directive } from '@angular/core';
import { StyleDirective } from '@ngbracket/ngx-layout';

const selector = `[ngStyle.gt-mv], [ngStyle.lt-mv]`;
const inputs = ['ngStyle.gt-mv', 'ngStyle.lt-mv'];

/**
 * @deprecated to be removed because FlexLayout is obsolete - see migration-from-flex-layout.md for details
 */
@Directive({ selector, inputs })
export class MobileNgStyleDirective extends StyleDirective {
  protected inputs = inputs;
}
