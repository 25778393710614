import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  BroadcastActionTypes,
  fromBroadcastActions,
  LoadBroadcastingItemLogo,
  LoadCaseBroadcastIsActiveStatus,
  LoadCaseBroadcastSettings,
  LoadPortalBroadcastRequestsHistory,
  LoadPortalBroadcastStatuses,
  PortalBroadcastRequestsHistoryLoadError,
  PortalBroadcastStatusChanged,
  RefreshBroadcastStatuses,
  RefreshBroadcastStatusesError,
  RefreshBroadcastStatusesSuccess,
  ToggleAllCaseBroadcastsIsActiveState,
  UpdateAdvertConfiguration,
  UpdateAdvertConfigurationSuccess,
  ValidateAdvertError,
  ValidateAndUpdateAdvert,
  RenewMobileBroadcast,
  RenewMobileBroadcastSuccess,
} from './broadcast.actions';
import {
  BroadcastConnectionConfigurationViewService,
  CaseBroadcastSettingsService,
  CaseBroadcastSettingsViewService,
  OfferType,
} from '@vpfa/rest-api/ad-broadcast';
import {
  catchError,
  concatMap,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { isCo2EfficiencyClassHasNoValue, mapFileToDataUrl } from '@vpfa/utils';
import { BasicNotificationsService } from '@vpfa/shared/notifications';
import { isEqual, isNil } from 'lodash';
import { BroadcastValidationError } from '../models/broadcast-validation-error';
// TODO: relative due to circular dependency
import { CasesFacade, fromCasesActions } from '../../../../../case/data/src/index';
import { BroadcastFacade } from './broadcast.facade';
import {
  isConsumingElectric,
  isConsumingFuel,
  isConsumingOnlyElectric,
  isConsumingOnlyFuel,
} from '../utils/fuel-type-recognizer';
import { ErrorCode } from '@vpfa/rest-api/valuation';

const VEHICLE_TYPE_CAR = 10;
const MAX_MILEAGE_FOR_NEW_CAR = 500;

@Injectable()
export class BroadcastEffects {
   loadBroadcastSettings$ = createEffect(() => this.actions$.pipe(
    ofType<LoadCaseBroadcastSettings>(BroadcastActionTypes.LoadCaseBroadcastSettings),
    switchMap(action =>
      this.caseBroadcastSettingsView.getCaseBroadcastSettingsByCaseId(action.payload).pipe(
        map(res => new fromBroadcastActions.LoadCaseBroadcastSettingsSuccess(res, action.isUpdate)),
        catchError(() => of(new fromBroadcastActions.CaseBroadcastSettingsLoadError()))
      )
    )
  ));

   validateAndUpdateAdvert$ = createEffect(() => this.actions$.pipe(
    ofType<ValidateAndUpdateAdvert>(BroadcastActionTypes.ValidateAndUpdateAdvert),
    withLatestFrom(this.casesFacade.activeCaseData$),
    map(([action, activeCase]) => {
      if (action.payload.broadcastSettings.vehicleType === VEHICLE_TYPE_CAR) {
        if (action.payload.broadcastSettings.offerType === OfferType.Used && activeCase.mileage.value <= 0) {
          return new fromBroadcastActions.ValidateAdvertError(BroadcastValidationError.ConsumptionUsedCar);
        }
        if (
          action.payload.broadcastSettings.offerType === OfferType.New &&
          activeCase.mileage.value > MAX_MILEAGE_FOR_NEW_CAR
        ) {
          return new fromBroadcastActions.ValidateAdvertError(BroadcastValidationError.ConsumptionNewCar);
        }
        if (
          action.payload.broadcastSettings.offerType === OfferType.New &&
          isConsumingFuel(activeCase.vehicle?.technicalData?.propulsion?.fuelType?.id) &&
          isNil(action.payload?.technicalData?.nedc?.fuelConsumptionOverallCombined?.value)
        ) {
          return new fromBroadcastActions.ValidateAdvertError(BroadcastValidationError.ConsumptionNewCarNotElectric);
        }
        if (
          isConsumingOnlyElectric(activeCase.vehicle?.technicalData?.propulsion?.fuelType?.id) &&
          !isNil(action.payload?.technicalData?.nedc?.fuelConsumptionOverallCombined?.value)
        ) {
          return new fromBroadcastActions.ValidateAdvertError(
            BroadcastValidationError.ConsumptionNewCarElectricCombinedConsumption
          );
        }
        if (
          action.payload.broadcastSettings.offerType === OfferType.New &&
          isConsumingElectric(activeCase.vehicle?.technicalData?.propulsion?.fuelType?.id) &&
          isNil(action.payload?.technicalData?.nedc?.electricPowerConsumption?.value)
        ) {
          return new fromBroadcastActions.ValidateAdvertError(
            BroadcastValidationError.ConsumptionNewCarElectricElectricConsumption
          );
        }
        if (
          isConsumingOnlyFuel(activeCase.vehicle?.technicalData?.propulsion?.fuelType?.id) &&
          !isNil(action.payload?.technicalData?.nedc?.electricPowerConsumption?.value)
        ) {
          return new fromBroadcastActions.ValidateAdvertError(
            BroadcastValidationError.ConsumptionNewCarNotElectricElectricConsumption
          );
        }
        if (
          action.payload.broadcastSettings.offerType === OfferType.New &&
          (isNil(action.payload?.technicalData?.nedc?.cO2EmissionCombined?.value) ||
            (isCo2EfficiencyClassHasNoValue(action.payload?.technicalData?.nedc?.consumptionRating) &&
              // When valueMax is not null, we are assuming that consumptionRating was not changed by the user
              // and it is still a range value ("A - B" or similar), so validation error shouldn't be thrown.
              // BE will take care of selecting which value is correct.
              isNil(activeCase.vehicle?.technicalData?.consumption?.nedc?.consumptionRating?.valueMax)))
        ) {
          return new fromBroadcastActions.ValidateAdvertError(BroadcastValidationError.CO2EmissionInformationNewCar);
        }
      }
      return new fromCasesActions.CaseUpdateTechnicalData({
        caseId: activeCase.id,
        technicalData: action.payload.technicalData,
        broadcastSettings: action.payload.broadcastSettings,
        updatedFromBroadcast: action.payload.updatedFromBroadcast,
        uniqueData: action.payload.uniqueData,
      });
    })
  ));

   validateAdvertError$ = createEffect(() => this.actions$.pipe(
    ofType<ValidateAdvertError>(BroadcastActionTypes.ValidateAdvertError),
    tap(action => {
      let interpolationParams: { [key: string]: string } | undefined;

      if (action.payload === BroadcastValidationError.ConsumptionNewCar) {
        interpolationParams = {
          maxMileageForNewCar: MAX_MILEAGE_FOR_NEW_CAR.toString(),
        };
      }

      this.notifications.error(
        'broadcast.broadcastError',
        `broadcast.validationError.${action.payload}`,
        interpolationParams
      );
    })
  ), { dispatch: false });

   updateAdvertConfiguration$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateAdvertConfiguration>(BroadcastActionTypes.UpdateAdvertConfiguration),
    switchMap(action =>
      this.caseBroadcastSettings.createCaseBroadcastSettings(action.payload.broadcastSettings).pipe(
        map(() => new fromBroadcastActions.UpdateAdvertConfigurationSuccess(action.payload)),
        catchError(() => of(new fromBroadcastActions.UpdateAdvertConfigurationError()))
      )
    )
  ));

   updateAdvertConfigurationSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateAdvertConfigurationSuccess>(BroadcastActionTypes.UpdateAdvertConfigurationSuccess),
    tap(() => this.notifications.success('broadcast.advertUpdatedSuccessfully')),
    withLatestFrom(this.casesFacade.activeCaseId$),
    map(([_, caseId]) => this.casesFacade.updateCaseRequestValuation(caseId, true))
  ), { dispatch: false });

   loadBroadcastingItemLogo$ = createEffect(() => this.actions$.pipe(
    ofType<LoadBroadcastingItemLogo>(BroadcastActionTypes.LoadBroadcastingItemLogo),
    mergeMap(action =>
      this.broadcastConnectionConfigurationViewService
        .getCountryBroadcastConnectionConfigurationLogo(action.payload)
        .pipe(
          switchMap(logoBlob =>
            mapFileToDataUrl([logoBlob as any, '']).pipe(
              map(
                ({ file }) =>
                  new fromBroadcastActions.LoadBroadcastingItemLogoSuccess({
                    file: file,
                    adPortalId: action.payload,
                  })
              )
            )
          ),
          catchError(() => of(new fromBroadcastActions.LoadBroadcastingItemLogoError(action.payload)))
        )
    )
  ));

   toggleAllCaseBroadcastsIsActiveState$ = createEffect(() => this.actions$.pipe(
    ofType<ToggleAllCaseBroadcastsIsActiveState>(BroadcastActionTypes.ToggleAllCaseBroadcastsIsActiveState),
    tap(({ payload }) => {
      payload
        ? this.notifications.success('broadcast.allPortalsEnabledSuccessfully')
        : this.notifications.success('broadcast.allPortalsDisabledSuccessfully');
    })
  ), { dispatch: false });

   loadAdPortalCaseBroadcastRequestHistoryList$ = createEffect(() => this.actions$.pipe(
    ofType<LoadPortalBroadcastRequestsHistory>(BroadcastActionTypes.LoadPortalBroadcastRequestsHistory),
    switchMap(action =>
      this.caseBroadcastSettingsView
        .getCaseBroadcastHistoryListItemDtoByCaseIdAndAdPortalId(
          action.payload.caseId,
          action.payload.broadcastConfigurationConnectionId
        )
        .pipe(
          map(res => new fromBroadcastActions.PortalBroadcastRequestsHistoryLoadSuccess(res)),
          catchError(err => of(new fromBroadcastActions.PortalBroadcastRequestsHistoryLoadError(err)))
        )
    )
  ));

   broadcastSettingsLoadError$ = createEffect(() => this.actions$.pipe(
    ofType<PortalBroadcastRequestsHistoryLoadError>(BroadcastActionTypes.PortalBroadcastRequestsHistoryLoadError),
    tap(err => {
      if (err?.payload?.error?.code === ErrorCode.AmsSubjectNotFound) {
        this.notifications.error('vpError.AmsSubjectNotFound');
      }
    })
  ), { dispatch: false });

   refreshBroadcastStatuses$ = createEffect(() => this.actions$.pipe(
    ofType<RefreshBroadcastStatuses>(BroadcastActionTypes.RefreshBroadcastStatuses),
    switchMap(action =>
      this.caseBroadcastSettings.refreshBroadcastsStatus({ caseId: action.payload }).pipe(
        map(res => new fromBroadcastActions.RefreshBroadcastStatusesSuccess({ response: res, caseId: action.payload })),
        catchError(() => of(new fromBroadcastActions.RefreshBroadcastStatusesError(action.payload)))
      )
    )
  ));

   refreshBroadcastStatusesSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<RefreshBroadcastStatusesSuccess>(BroadcastActionTypes.RefreshBroadcastStatusesSuccess),
    tap(action => {
      if (action.payload.response.unavailablePortals) {
        this.notifications.warning('broadcast.portalsNotAccessible', '', {
          unavailablePortals: action.payload.response.unavailablePortals,
        });
      }
    }),
    map(action => new LoadCaseBroadcastSettings(action.payload.caseId))
  ));

   refreshBroadcastStatusesError$ = createEffect(() => this.actions$.pipe(
    ofType<RefreshBroadcastStatusesError>(BroadcastActionTypes.RefreshBroadcastStatusesError),
    map(action => new LoadCaseBroadcastSettings(action.payload))
  ));

   loadAdPortalCaseBroadcastStatuses$ = createEffect(() => this.actions$.pipe(
    ofType<LoadPortalBroadcastStatuses>(BroadcastActionTypes.LoadPortalBroadcastStatuses),
    withLatestFrom(this.broadcastFacade.adPortalCaseBroadcastStatusList$, this.broadcastFacade.activeCaseId$),
    mergeMap(([action, prevStatusList, activeCaseId]) =>
      this.caseBroadcastSettingsView.getAdPortalStatusesCaseBroadcastSettingsId(action.payload).pipe(
        concatMap(currentStatusList => {
          const actions = [new fromBroadcastActions.PortalBroadcastStatusesLoadSuccess(currentStatusList)];
          if (!isEqual(prevStatusList, currentStatusList)) {
            return [...actions, new fromBroadcastActions.PortalBroadcastStatusChanged(activeCaseId)];
          } else {
            return actions;
          }
        }),

        catchError(err => of(new fromBroadcastActions.PortalBroadcastStatusesLoadError(err)))
      )
    )
  ));

   onAdPortalStatusChanged$ = createEffect(() => this.actions$.pipe(
    ofType<PortalBroadcastStatusChanged>(BroadcastActionTypes.PortalBroadcastStatusChanged),
    withLatestFrom(this.broadcastFacade.activeCaseId$),
    map(([, activeCaseId]) => new fromBroadcastActions.LoadCaseBroadcastIsActiveStatus(activeCaseId, true))
  ));

   loadBroadcastIsActiveStatus$ = createEffect(() => this.actions$.pipe(
    ofType<LoadCaseBroadcastIsActiveStatus>(BroadcastActionTypes.LoadCaseBroadcastIsActiveStatus),
    switchMap(action =>
      this.caseBroadcastSettingsView.getCaseBroadcastSettingsByCaseId(action.payload).pipe(
        map(res => new fromBroadcastActions.LoadCaseBroadcastIsActiveStatusSuccess(res, action.isUpdate)),
        catchError(() => of(new fromBroadcastActions.LoadCaseBroadcastIsActiveStatusError())),
        // cancel request if user leaves broadcasting page
        takeUntil(this.broadcastFacade.broadcastSettings$.pipe(filter(x => isNil(x))))
      )
    )
  ));

   renewMobileBroadcast$ = createEffect(() => this.actions$.pipe(
    ofType<RenewMobileBroadcast>(BroadcastActionTypes.RenewMobileBroadcast),
    switchMap(action =>
      this.caseBroadcastSettings.renewMobileBroadcast(action.payload).pipe(
        map(() => new fromBroadcastActions.RenewMobileBroadcastSuccess(action.payload.caseId)),
        catchError(() => of(new fromBroadcastActions.RenewMobileBroadcastError()))
      )
    )
  ));

   reloadCaseBroadcastSettingsAfterRenewMobileBroadcastSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<RenewMobileBroadcastSuccess>(BroadcastActionTypes.RenewMobileBroadcastSuccess),
    tap(() => this.notifications.success('broadcast.mobileBroadcastRenewed')),
    map(action => new LoadCaseBroadcastSettings(action.caseId))
  ));

  constructor(
    private actions$: Actions,
    private caseBroadcastSettingsView: CaseBroadcastSettingsViewService,
    private caseBroadcastSettings: CaseBroadcastSettingsService,
    private broadcastConnectionConfigurationViewService: BroadcastConnectionConfigurationViewService,
    private casesFacade: CasesFacade,
    private notifications: BasicNotificationsService,
    private broadcastFacade: BroadcastFacade
  ) {}
}
