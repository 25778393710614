<vpfa-section-container
  [isLoading]="!notAvailable && (isLoadingValuationPrices$ | async)"
  [notAvailable]="notAvailable"
  [name]="'caseValuationDetails.equipment.title' | translate"
  [toggleLabel]="equipmentTotal$ | async | currencyLocale: null:null:'1.2'"
  [tooltipContent]="'tooltip.equipment' | translate"
>
  <ng-container right-section>
    <span id="equipmentAdjustmentTotalValue" class="equipment-total" *ngIf="!notAvailable">
      {{ equipmentTotal$ | async | currencyLocale }}
    </span>
  </ng-container>
  <div class="equipment-section-container">
    <vpfa-arrow-link id="equipmentAdjustmentPageLink" icon="file-text" (clicked)="goToEquipment()">
      {{ 'caseValuationDetails.equipment.equipmentList' | translate }}
    </vpfa-arrow-link>
    <vpfa-setting-box [divider]="false">
      <ng-container content>
        <div fxLayoutAlign="space-between center">
          <vpfa-label [text]="'vehicleConditionValuation.additionalEquipment' | translate"></vpfa-label>
          <vpfa-amount-box
            id="equipmentAdjustmentAdditionalEquipmentValue"
            *ngIf="!notAvailable"
            [amount]="additionalEquipment$ | async"
          ></vpfa-amount-box>
          <vpfa-price-with-icon
            id="equipmentAdjustmentAdditionalEquipmentNotAvailable"
            *ngIf="notAvailable"
            [color]="'orange'"
          ></vpfa-price-with-icon>
        </div>
      </ng-container>
    </vpfa-setting-box>
  </div>
</vpfa-section-container>
