import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CURRENCY_DISPLAY_FORMAT } from '@vpfa/ui-kit';

@Component({
  selector: 'vpfa-price-with-icon',
  templateUrl: './price-with-icon.component.html',
  styleUrls: ['./price-with-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceWithIconComponent {
  @Input() price: string | number;
  @Input() icon = 'info-circle';
  @Input() iconPlacement: 'space-between' | 'start' = 'space-between';
  @Input() hideIcon = false;
  @Input() color: 'dark-gray' | 'orange' | 'green' | 'dark' = 'dark-gray';
  @Input() isEditable = false;
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() fcName: string;
  @Input() errorMessages: ReadonlyArray<{ error: string; errorMsg: string }> = [];
  @Input() heightCompensationPx = '0';
  @Input() readonlyPrice = false;
  @Output() iconClicked = new EventEmitter();

  CURRENCY_DISPLAY_FORMAT = CURRENCY_DISPLAY_FORMAT;

  get iconClass() {
    return `${this.color} ${this.iconClicked.observers.length ? 'clickable' : ''}`;
  }
}
