<div *ngIf="!textField" class="label-box">
  <ng-container *ngTemplateOutlet="content"></ng-container>
  <vpfa-required-symbol *ngIf="isRequired" [disabled]="disabled"></vpfa-required-symbol>
</div>
<div *ngIf="textField" class="label-box" fxLayout="row" fxLayoutAlign="space-between stretch">
  <div>
    <ng-container *ngTemplateOutlet="content"></ng-container>
    <vpfa-required-symbol *ngIf="isRequired" [disabled]="disabled"></vpfa-required-symbol>
  </div>
  <div>
    <ng-content select="[afterLabel]"></ng-content>
  </div>
</div>
<div class="content-box" ngClass.lt-mv="mobile">
  <ng-content select="[content]"></ng-content>
</div>
<nz-divider *ngIf="divider"></nz-divider>

<ng-template #content>
  <ng-content select="[label]"></ng-content>
</ng-template>
