import { Component, Input, SimpleChanges, TemplateRef } from '@angular/core';

@Component({
  selector: 'vpfa-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent {
  @Input() items: any[] = [];
  @Input() renderItem: TemplateRef<void>;
  @Input() emptyTemplate: TemplateRef<void>;
  @Input() loading: boolean;
  @Input() isRowClickable: boolean = false;
  @Input() bordered: boolean = false;
  @Input() displayEmptyTemplate: boolean = false;

  /**
   * If this value is `true` re-ordering and adding new rows will be animated.
   * 
   * WARNING: there is a catch - rows needs to have fixed height, because elements positions
   * are calculated by `transform` method. Multiline rows will overflow `style.min-height.px`
   * in HTML file.
   */
  @Input() animate = false;

  index = 0;

  ngOnChanges(changes: SimpleChanges): void {
    this.index = 0;
  }

  /**
   * Required for animating sorting
   */
  transform(index: number) {
    if (!this.animate) {
      return '';
    }

    return `translateY(${index * 100}%)`;
  }
}
