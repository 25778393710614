import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DictionariesFacade } from './+state/dictionaries.facade';
import { DictionariesEffects } from './+state/dictionaries.effects';
import { StoreModule } from '@ngrx/store';
import { DICTIONARIES_FEATURE_KEY, reducer } from './+state/dictionaries.reducer';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(DICTIONARIES_FEATURE_KEY, reducer),
    EffectsModule.forFeature([DictionariesEffects]),
  ],
  providers: [DictionariesFacade],
})
export class DealerDictionariesModule {}
