import { SelectOption } from '@vpfa/ui-kit';
import { isNil } from 'lodash';
import { ColorDictionaryItemDto } from '@vpfa/rest-api/valuation';

export const initialUniqueDataSelectOptionValue = (
  initialValue: string | number,
  options: SelectOption[]
): SelectOption | null => {
  return !isNil(initialValue)
    ? {
        value: initialValue,
        name: options?.find(x => x.value === initialValue)?.name,
      }
    : null;
};

export const initialPaintColourSelectOptionValue = (
  initialValue: number,
  options: SelectOption<ColorDictionaryItemDto>[]
): SelectOption<ColorDictionaryItemDto> | null => {
  const option = options?.find(x => x?.value?.id === initialValue);
  return !isNil(option)
    ? {
        value: option?.value,
        name: option?.name,
      }
    : null;
};
