import { Component, Output, EventEmitter, Input } from '@angular/core';
import { CaseTaxDto } from '@vpfa/rest-api/valuation';
import { PriceHistoryModel } from '../../models/price-history-model';

@Component({
  selector: 'vpfa-price-history-modal',
  templateUrl: './price-history-modal.component.html',
  styleUrls: ['./price-history-modal.component.scss']
})
export class PriceHistoryModalComponent {
  @Input() visible: boolean;
  @Input() caseTax: CaseTaxDto;
  @Input() prices: PriceHistoryModel;

  @Output() visibleChange = new EventEmitter<boolean>();

  get name() {
    return this.prices ? this.prices.name : '';
  }

  get price() {
    return this.prices ? this.prices.price : null;
  }

  get tax() {
    return this.prices ? this.prices.tax : null;
  }

  get history() {
    return this.prices && this.prices.history ? this.prices.history : [];
  }

  close() {
    this.prices = null;
    this.visibleChange.emit(false);
  }
}
