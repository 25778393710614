import { Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { isNil } from 'lodash';

export const urlPartAllowedCharValidatorKey = 'urlPartAllowedChar';

export const urlPartForbiddenCharValidator: ValidatorFn = (control: AbstractControl) => {
  const validateErrors = Validators.pattern(/^[A-Za-z\d-_]*$/i)(control);
  if (isNil(validateErrors)) {
    return null;
  }
  return {
    [urlPartAllowedCharValidatorKey]: true
  };
};
