import {
  CaseSummaryDto,
  CaseListItemDto,
  CaseStatus,
  CaseImagesStatus,
  IdentificationMethod,
} from '@vpfa/rest-api/valuation';
import { RowNode } from 'ag-grid-community';

export const isValuationStatus = (status: number) => status === CaseStatus.Valuated;

export const isSoldStatus = (status: number) => status === CaseStatus.Sold;

export const allowKbaClear = (identificationType: IdentificationMethod) =>
  identificationType !== IdentificationMethod.KBA;

const imagesStatusOrder = [CaseImagesStatus.NoImage, CaseImagesStatus.StandardOrPromotional, CaseImagesStatus.Uploaded];

export const imagesStatusSortComparator = (
  valueA: any,
  valueB: any,
  nodeA: RowNode,
  nodeB: RowNode,
  isInverted: boolean
): number => imagesStatusOrder.indexOf(nodeA.data.imagesStatus) - imagesStatusOrder.indexOf(nodeB.data.imagesStatus);
