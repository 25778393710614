import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import {
  DataTableColDef,
  DataTableActionEvent,
  DataTableAction,
  DataTableValueGetterParams,
  DataTableActionListItem
} from '@vpfa/ui-kit/data-table';
import { getTemplateColumnsDefinitions } from './get-template-columns-definitions';
import { PdfTemplateListItemDto } from '@vpfa/rest-api/valuation';
import { defaultActionGetter } from './default-action-getter';

@Component({
  selector: 'vpfa-print-templates-list',
  templateUrl: './print-templates-list.component.html',
  styleUrls: ['./print-templates-list.component.scss']
})
export class PrintTemplatesListComponent implements OnInit {
  @Input() templates: PdfTemplateListItemDto[];
  @Input() loading = false;
  @Input() actionGetter: (params: DataTableValueGetterParams<PdfTemplateListItemDto>) => DataTableActionListItem[];

  colDefs: DataTableColDef<PdfTemplateListItemDto>[] = [];
  gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    pagination: true,
    getRowHeight: () => {
      //HACK: gridOptions.rowHeight not working
      return 40;
    }
  };
  
  defaultColDef: DataTableColDef<any> = {
    floatingFilter: false,
    sortable: true,
    resizable: true,
    autoHeight: true,
    suppressMenu: true,
    filter: false,
    suppressMovable: true
  };

  @Output() edit: EventEmitter<PdfTemplateListItemDto> = new EventEmitter<PdfTemplateListItemDto>();
  @Output() duplicate: EventEmitter<PdfTemplateListItemDto> = new EventEmitter<PdfTemplateListItemDto>();
  @Output() copyTo: EventEmitter<PdfTemplateListItemDto> = new EventEmitter<PdfTemplateListItemDto>();
  @Output() delete: EventEmitter<PdfTemplateListItemDto> = new EventEmitter<PdfTemplateListItemDto>();
  @Output() rename: EventEmitter<PdfTemplateListItemDto> = new EventEmitter<PdfTemplateListItemDto>();
  @Output() modules: EventEmitter<PdfTemplateListItemDto> = new EventEmitter<PdfTemplateListItemDto>();
  @Output() visible: EventEmitter<PdfTemplateListItemDto> = new EventEmitter<PdfTemplateListItemDto>();

  ngOnInit() {
    this.colDefs = getTemplateColumnsDefinitions(this.actionGetter ? this.actionGetter : defaultActionGetter);
  }

  onActionClicked(actionEvent: DataTableActionEvent<PdfTemplateListItemDto>) {
    switch (actionEvent.action) {
      case DataTableAction.EDIT:
        this.edit.emit(actionEvent.data);
        break;
      case DataTableAction.DUPLICATE:
        this.duplicate.emit(actionEvent.data);
        break;
      case DataTableAction.COPY_TO:
        this.copyTo.emit(actionEvent.data);
        break;
      case DataTableAction.DELETE:
        this.delete.emit(actionEvent.data);
        break;
      case DataTableAction.RENAME:
        this.rename.emit(actionEvent.data);
        break;
      case DataTableAction.OTHER:
        this.visible.emit(actionEvent.data);
        break;
      case DataTableAction.MODULES:
        this.modules.emit(actionEvent.data);
        break;
    }
  }
}
