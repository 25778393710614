import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { imagesReducer, IMAGES_FEATURE_KEY } from './+state/images.reducer';
import { ImagesEffects } from './+state/images.effects';
import { ImagesFacade } from './+state/images.facade';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(IMAGES_FEATURE_KEY, imagesReducer),
    EffectsModule.forFeature([ImagesEffects]),
  ],
  providers: [ImagesFacade],
})
export class SharedImagesDataModule {}
