import { Action } from '@ngrx/store';
import { FileItem } from '@vpfa/utils';
import { AddCaseFileCommand, AddCaseImagesCommand } from './gallery-manager.facade';
import {
  CaseFilesCommandHandlerResponse,
  CaseImageWithOrderDto,
  CommandHandlerResponse,
  UpdateCaseImagesOrderCommand,
  UpdateCasePromoImagesCommand,
} from '@vpfa/rest-api/valuation';

export enum GalleryImageType {
  ImageFile,
  Sorc,
}

export enum GalleryManagerActionTypes {
  AddImages = '[GalleryManager] Add Images',

  AddImage = '[GalleryManager] Add Image',
  ImageAdded = '[GalleryManager] Image Added',
  ImageAddError = '[GalleryManager] Image Add Error',
  ImageUploadFinished = '[GalleryManager] Image Upload Finished',

  ImageDelete = '[GalleryManager] Image Delete',
  ImageDeleted = '[GalleryManager] Image Deleted',
  ImageDeleteError = '[GalleryManager] Image Delete Error',

  ImageReplace = '[GalleryManager] Image Replace',
  ImageReplaced = '[GalleryManager] Image Replaced',
  ImageReplaceError = '[GalleryManager] Image Replace Error',

  GalleryQueueReset = '[GalleryManager] Gallery Queue Reset',
  GalleryReset = '[GalleryManager] Gallery Reset',
  GalleryReloadCase = '[GalleryManager] Gallery Reload Case',
  ReorderImageList = '[GalleryManager] Reorder Image List',
  ImageListReordered = '[GalleryManager] Image List Reordered',
  ImageListReorderError = '[GalleryManager] Image List Reorder Error',
  LoadGalleryImageOrderList = '[GalleryManager] Load Gallery Image Order List',
  GalleryImageOrderListLoaded = '[GalleryManager] Gallery Image Order List Loaded',
  GalleryImageOrderListLoadError = '[GalleryManager] Gallery Image Order List Load Error',

  UpdatePromotionalImages = '[GalleryManager] Update Promotional Images',
  PromotionalImagesUpdated = '[GalleryManager] Promotional Images Updated',
  PromotionalImagesUpdateError = '[GalleryManager] Promotional Images Update Error',
  RemoveItemsFromImageListByKeyList = '[GalleryManager] Remove Images From Image List By Key',

  SyncImagesTotalSize = '[GalleryManager] Sync Images Total Size',

  ImagePreviewOpen = '[GalleryManager] Open Image Preview',
  ImagePreviewClose = '[GalleryManager] Close Image Preview',

  PublicGalleryImageOrderListLoad = '[GalleryManager] Public Gallery Image Order List Load',
  PublicGalleryImageOrderListLoaded = '[GalleryManager] Public Gallery Image Order List Loaded',
  PublicGalleryImageOrderListLoadError = '[GalleryManager] Public Gallery Image Order List Load Error',

  ResellerGalleryImageOrderListLoad = '[GalleryManager] Reseller Gallery Image Order List Load',
  ResellerGalleryImageOrderListLoaded = '[GalleryManager] Reseller Gallery Image Order List Loaded',
  ResellerGalleryImageOrderListLoadError = '[GalleryManager] Reseller Gallery Image Order List Load Error',
}

export class AddImage implements Action {
  readonly type = GalleryManagerActionTypes.AddImage;
  constructor(public payload: { command: AddCaseFileCommand; selectedForAdvertisement: boolean }) {}
}

export class ImageAdded implements Action {
  readonly type = GalleryManagerActionTypes.ImageAdded;
  constructor(
    public payload: {
      caseId: string;
      file: FileItem;
      fileName: string;
      isFirst: boolean;
      selectedForAdvertisement: boolean;
      fileId: string;
    }
  ) {}
}

export class ImageAddError implements Action {
  readonly type = GalleryManagerActionTypes.ImageAddError;
  constructor(public payload: { error: any; fileName: string; isFirst: boolean }) {}
}

export class AddImages implements Action {
  readonly type = GalleryManagerActionTypes.AddImages;
  constructor(public payload: AddCaseImagesCommand) {}
}

export class ImageDelete implements Action {
  readonly type = GalleryManagerActionTypes.ImageDelete;
  constructor(public payload: { caseId: string; fileKey: string }) {}
}

export class ImageDeleted implements Action {
  readonly type = GalleryManagerActionTypes.ImageDeleted;
  constructor(public payload: { caseId: string; fileKey: string }) {}
}

export class ImageDeleteError implements Action {
  readonly type = GalleryManagerActionTypes.ImageDeleteError;
  constructor(public payload: { error: CommandHandlerResponse; fileKey: string }) {}
}

export class ImageUploadFinished implements Action {
  readonly type = GalleryManagerActionTypes.ImageUploadFinished;
}

export class ImageReplace implements Action {
  readonly type = GalleryManagerActionTypes.ImageReplace;
  constructor(
    public payload: { image: any; aggregateRootId: string; originalFileKey: string },
    public isSelectedForAdvertisement: boolean
  ) {}
}

export class ImageReplaced implements Action {
  readonly type = GalleryManagerActionTypes.ImageReplaced;
  constructor(
    public payload: { caseId: string; newFileKey: string; oldFileKey: string; isSelectedForAdvertisement: boolean }
  ) {}
}

export class ImageReplaceError implements Action {
  readonly type = GalleryManagerActionTypes.ImageReplaceError;
}

export class GalleryQueueReset implements Action {
  readonly type = GalleryManagerActionTypes.GalleryQueueReset;
}

export class GalleryReset implements Action {
  readonly type = GalleryManagerActionTypes.GalleryReset;
  constructor(public payload: { isClone: boolean; nextAction: Action } = { isClone: false, nextAction: null }) {}
}

export class GalleryReloadCase implements Action {
  readonly type = GalleryManagerActionTypes.GalleryReloadCase;
  constructor(public payload: { caseId: string; forceReload: boolean; nextAction: Action }) {}
}

export class ReorderImageList implements Action {
  readonly type = GalleryManagerActionTypes.ReorderImageList;
  constructor(public payload: UpdateCaseImagesOrderCommand) {}
}

export class ImageListReordered implements Action {
  readonly type = GalleryManagerActionTypes.ImageListReordered;
  constructor(public payload: CaseFilesCommandHandlerResponse) {}
}

export class ImageListReorderError implements Action {
  readonly type = GalleryManagerActionTypes.ImageListReorderError;
}

export class LoadGalleryImageOrderList implements Action {
  readonly type = GalleryManagerActionTypes.LoadGalleryImageOrderList;
  constructor(public payload: string, public synchronize: boolean = false) {}
}

export class GalleryImageOrderListLoaded implements Action {
  readonly type = GalleryManagerActionTypes.GalleryImageOrderListLoaded;
  constructor(public payload: Array<CaseImageWithOrderDto>, public synchronize: boolean) {}
}

export class GalleryImageOrderListLoadError implements Action {
  readonly type = GalleryManagerActionTypes.GalleryImageOrderListLoadError;
}

export class UpdatePromotionalImages implements Action {
  readonly type = GalleryManagerActionTypes.UpdatePromotionalImages;
  constructor(public payload: UpdateCasePromoImagesCommand) {}
}

export class PromotionalImagesUpdated implements Action {
  readonly type = GalleryManagerActionTypes.PromotionalImagesUpdated;
  constructor(public payload: CaseFilesCommandHandlerResponse) {}
}

export class PromotionalImagesUpdateError implements Action {
  readonly type = GalleryManagerActionTypes.PromotionalImagesUpdateError;
}

export class RemoveItemsFromImageListByKeyList implements Action {
  readonly type = GalleryManagerActionTypes.RemoveItemsFromImageListByKeyList;
  constructor(public payload: string[]) {}
}

export class ImagePreviewOpen implements Action {
  readonly type = GalleryManagerActionTypes.ImagePreviewOpen;
}

export class ImagePreviewClose implements Action {
  readonly type = GalleryManagerActionTypes.ImagePreviewClose;
}

export class SyncImagesTotalSize implements Action {
  readonly type = GalleryManagerActionTypes.SyncImagesTotalSize;
  constructor(public payload: Array<CaseImageWithOrderDto>) {}
}

export class PublicGalleryImageOrderListLoad implements Action {
  readonly type = GalleryManagerActionTypes.PublicGalleryImageOrderListLoad;
  constructor(public caseId: string) {}
}

export class PublicGalleryImageOrderListLoaded implements Action {
  readonly type = GalleryManagerActionTypes.PublicGalleryImageOrderListLoaded;
  constructor(public payload: Array<CaseImageWithOrderDto>) {}
}

export class PublicGalleryImageOrderListLoadError implements Action {
  readonly type = GalleryManagerActionTypes.PublicGalleryImageOrderListLoadError;
}

export class ResellerGalleryImageOrderListLoad implements Action {
  readonly type = GalleryManagerActionTypes.ResellerGalleryImageOrderListLoad;
  constructor(public caseId: string) {}
}

export class ResellerGalleryImageOrderListLoaded implements Action {
  readonly type = GalleryManagerActionTypes.ResellerGalleryImageOrderListLoaded;
  constructor(public payload: Array<CaseImageWithOrderDto>) {}
}

export class ResellerGalleryImageOrderListLoadError implements Action {
  readonly type = GalleryManagerActionTypes.ResellerGalleryImageOrderListLoadError;
}

export type GalleryManagerAction =
  | AddImage
  | ImageAdded
  | ImageAddError
  | AddImages
  | ImageDelete
  | ImageDeleted
  | ImageDeleteError
  | ImageUploadFinished
  | GalleryQueueReset
  | GalleryReset
  | GalleryReloadCase
  | ReorderImageList
  | ImageListReordered
  | ImageListReorderError
  | LoadGalleryImageOrderList
  | GalleryImageOrderListLoaded
  | GalleryImageOrderListLoadError
  | ImageReplace
  | ImageReplaced
  | ImageReplaceError
  | ImagePreviewOpen
  | ImagePreviewClose
  | UpdatePromotionalImages
  | PromotionalImagesUpdated
  | PromotionalImagesUpdateError
  | RemoveItemsFromImageListByKeyList
  | SyncImagesTotalSize
  | PublicGalleryImageOrderListLoad
  | PublicGalleryImageOrderListLoaded
  | PublicGalleryImageOrderListLoadError
  | ResellerGalleryImageOrderListLoad
  | ResellerGalleryImageOrderListLoaded
  | ResellerGalleryImageOrderListLoadError;

export const fromGalleryManagerActions = {
  AddImage,
  ImageAdded,
  ImageAddError,
  AddImages,
  ImageDelete,
  ImageDeleted,
  ImageDeleteError,
  ImageUploadFinished,
  GalleryQueueReset,
  GalleryReset,
  GalleryReloadCase,
  ReorderImageList,
  ImageListReordered,
  ImageListReorderError,
  LoadGalleryImageOrderList,
  GalleryImageOrderListLoaded,
  GalleryImageOrderListLoadError,
  UpdatePromotionalImages,
  PromotionalImagesUpdated,
  PromotionalImagesUpdateError,
  RemoveItemsFromImageListByKeyList,
  ImageReplace,
  ImageReplaced,
  ImageReplaceError,
  ImagePreviewOpen,
  ImagePreviewClose,
  SyncImagesTotalSize,
  PublicGalleryImageOrderListLoad,
  PublicGalleryImageOrderListLoaded,
  PublicGalleryImageOrderListLoadError,
  ResellerGalleryImageOrderListLoad,
  ResellerGalleryImageOrderListLoaded,
  ResellerGalleryImageOrderListLoadError,
};
