import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { VehicleEquipmentPartialState } from './vehicle-equipment.reducer';
import { vehicleEquipmentQuery } from './vehicle-equipment.selectors';
import {
  VehicleEquipmentAftermarketItemCreate,
  VehicleEquipmentAftermarketItemDelete,
  VehicleEquipmentAftermarketItemUpdate,
  VehicleEquipmentUniversalCategoryCodesLoad,
  VehicleEquipmentUpdateIsEnabled,
  UpdateIdentifiedVehicleSingleEquipmentCommand,
  VehicleEquipmentColorUpdate,
  VehicleResetEquipmentListToDefault,
} from './vehicle-equipment.actions';
import {
  CreateAfterMarketEquipmentCommand,
  UpdateAfterMarketEquipmentCommand,
  UpdateIdentifiedVehicleEquipmentColorCommand,
} from '@vpfa/rest-api/valuation';

@Injectable()
export class VehicleEquipmentFacade {
  loading$ = this.store.pipe(select(vehicleEquipmentQuery.getIsLoading));
  isAddingAftermarketEquipment$ = this.store.pipe(select(vehicleEquipmentQuery.getIsAddingAftermarketEquipment));
  isUpdatingAftermarketEquipment$ = this.store.pipe(select(vehicleEquipmentQuery.getIsUpdatingAftermarketEquipment));
  getUniversalCategoryCodes$ = this.store.pipe(select(vehicleEquipmentQuery.getUniversalCategoryCodes));

  constructor(private store: Store<VehicleEquipmentPartialState>) {}

  updateIsEnabled(command: Omit<UpdateIdentifiedVehicleSingleEquipmentCommand, 'validateOnly'>, caseId: string) {
    this.store.dispatch(new VehicleEquipmentUpdateIsEnabled({ command: { ...command, validateOnly: false }, caseId }));
  }

  createAftermarketEquipment(command: CreateAfterMarketEquipmentCommand, caseId: string) {
    this.store.dispatch(new VehicleEquipmentAftermarketItemCreate({ command, caseId }));
  }

  updateAftermarketEquipment(command: UpdateAfterMarketEquipmentCommand, caseId: string) {
    this.store.dispatch(new VehicleEquipmentAftermarketItemUpdate({ command, caseId }));
  }

  deleteAftermarketEquipment(caseId: string, identifiedVehicleId: string, soaCode: string) {
    this.store.dispatch(new VehicleEquipmentAftermarketItemDelete({ caseId, identifiedVehicleId, soaCode }));
  }

  updateEquipmentColor(caseId: string, command: UpdateIdentifiedVehicleEquipmentColorCommand) {
    this.store.dispatch(new VehicleEquipmentColorUpdate({ command, caseId }));
  }

  loadUniversalCategoryCodes() {
    this.store.dispatch(new VehicleEquipmentUniversalCategoryCodesLoad());
  }

  resetEquipmentListToDefault() {
    this.store.dispatch(new VehicleResetEquipmentListToDefault());
  }
}
