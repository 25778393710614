import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { RadarComponent } from './containers/radar/radar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { SharedPipesModule } from '@vpfa/shared/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { CaseResolverService, DealerCaseSharedModule } from '@vpfa/dealer/case/shared';
import { SharedVehicleModule } from '@vpfa/dealer/shared/vehicle';
import { UiKitHeaderTitleModule } from '@vpfa/ui-kit/header-title';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { SharedLoadersModule } from '@vpfa/shared/loaders';
import { LiveRetailLibModule } from 'liveRetail-lib';
import { UiKitMobileModule } from '@vpfa/ui-kit-mobile';
import { SharedMobileViewModule } from '@vpfa/shared/mobile-view';
import { UiKitBackLinkModule } from '@vpfa/ui-kit/back-link';
import { RadarUnavailableComponent } from './components/radar-unavailable/radar-unavailable.component';
import { SharedComponentsModule } from '@vpfa/shared/components';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzIconModule,
    NzAlertModule,
    NzSpinModule,
    SharedPipesModule,
    TranslateModule,
    DealerCaseSharedModule,
    SharedVehicleModule,
    UiKitHeaderTitleModule,
    SharedComponentsModule,
    RouterModule.forChild([{ path: '', pathMatch: 'full', component: RadarComponent }]),
    SharedLoadersModule,
    LiveRetailLibModule,
    UiKitMobileModule,
    SharedMobileViewModule,
    UiKitBackLinkModule,
    NzButtonModule,
  ],
  declarations: [RadarComponent, RadarUnavailableComponent],
  providers: [CaseResolverService],
})
export class DealerRadarModule {}
