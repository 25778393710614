import { Component, Input } from '@angular/core';
import { isNil } from 'lodash';

@Component({
  selector: 'vpfa-left-arrow-box',
  templateUrl: './left-arrow-box.component.html',
  styleUrls: ['./left-arrow-box.component.scss'],
})
export class LeftArrowBoxComponent {
  @Input() co2EmissionClass: string;
  @Input() co2Emission: number;

  co2EmissionValue() {
    return !isNil(this.co2Emission) ? `${this.co2Emission} g/km` : '';
  }
}
