import { Action } from '@ngrx/store';
import { AddCaseImagesCommand } from '@vpfa/dealer/gallery-manager/data';

export enum ConfirmAdvertisedImagesActionTypes {
  UploadImagesAfterConfirmation = '[ConfirmAdvertisedImages] Upload Images After Confirmation',
  ShowConfirmationModal = '[ConfirmAdvertisedImages] Show Confirmation Modal',
  CloseConfirmationModal = '[ConfirmAdvertisedImages] Close Confirmation Modal',
  AddToAdvertiseUpdateQueue = '[ConfirmAdvertisedImages] Add To Advertise Update Queue',
  ClearAdvertiseUpdateQueue = '[ConfirmAdvertisedImages] Clear Advertise Update Queue',
  AdvertiseUpdateError = '[ConfirmAdvertisedImages] Advertise Update Error',
  SaveChangesFromQueue = '[ConfirmAdvertisedImages] Save Changes From Queue',
}

export class UploadImagesAfterConfirmation implements Action {
  readonly type = ConfirmAdvertisedImagesActionTypes.UploadImagesAfterConfirmation;
  constructor(public payload: { command: AddCaseImagesCommand; selectedForAdvertisement: { isSelected: boolean }[] }) {}
}

export class ShowConfirmationModal implements Action {
  readonly type = ConfirmAdvertisedImagesActionTypes.ShowConfirmationModal;
  constructor(public payload: AddCaseImagesCommand) {}
}

export class CloseConfirmationModal implements Action {
  readonly type = ConfirmAdvertisedImagesActionTypes.CloseConfirmationModal;
}

export class AddToAdvertiseUpdateQueue implements Action {
  readonly type = ConfirmAdvertisedImagesActionTypes.AddToAdvertiseUpdateQueue;
  constructor(
    public payload: {
      caseId: string;
      fileId: string;
      isSelectedForAdvertisement: boolean;
    }
  ) {}
}

export class AdvertiseUpdateError implements Action {
  readonly type = ConfirmAdvertisedImagesActionTypes.AdvertiseUpdateError;
}

export class ClearAdvertiseUpdateQueue implements Action {
  readonly type = ConfirmAdvertisedImagesActionTypes.ClearAdvertiseUpdateQueue;
}

export class SaveChangesFromQueue implements Action {
  readonly type = ConfirmAdvertisedImagesActionTypes.SaveChangesFromQueue;
}

export type ConfirmAdvertisedImagesAction =
  | UploadImagesAfterConfirmation
  | ShowConfirmationModal
  | CloseConfirmationModal
  | AddToAdvertiseUpdateQueue
  | ClearAdvertiseUpdateQueue
  | AdvertiseUpdateError
  | SaveChangesFromQueue;

export const fromConfirmAdvertisedImagesActions = {
  UploadImagesAfterConfirmation,
  ShowConfirmationModal,
  CloseConfirmationModal,
  AddToAdvertiseUpdateQueue,
  ClearAdvertiseUpdateQueue,
  AdvertiseUpdateError,
  SaveChangesFromQueue,
};
