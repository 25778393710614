import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { HeaderTitleComponent } from './components/header-title/header-title.component';
import { SmallSubtitleExtraComponent } from './components/small-subtitle-extra/small-subtitle-extra.component';
import { StickyHeaderTitleComponent } from './components/sticky-header-title/sticky-header-title.component';
import { UiKitModule } from '@vpfa/ui-kit';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, UiKitModule],
  declarations: [HeaderTitleComponent, SmallSubtitleExtraComponent, StickyHeaderTitleComponent],
  exports: [HeaderTitleComponent, SmallSubtitleExtraComponent, StickyHeaderTitleComponent],
})
export class UiKitHeaderTitleModule {}
