<nz-list
  [class.animate]="animate"
  [nzLoading]="loading"
  [nzDataSource]="items"
  [nzRenderItem]="listItem"
  [nzBordered]="bordered"
  nzSize="small"
  [nzNoResult]="emptyTemplate ? emptyTemplate : emptyDefault"
  [style.min-height.px]="(items?.length ?? 0) * 40 + 15"
>
  <ng-template #listItem let-item let-index="index">
    <nz-list-item
      [style.transform]="transform(index)"
      [class.row-clickable]="isRowClickable"
      ngClass.gt-mv="web"
      ngClass.lt-mv="mobile"
    >
      <ng-template
        [ngTemplateOutlet]="renderItem"
        [ngTemplateOutletContext]="{ $implicit: item, index: index }"
      ></ng-template>
    </nz-list-item>
  </ng-template>
</nz-list>

<ng-template #emptyDefault>
  <vpfa-empty *ngIf="displayEmptyTemplate"></vpfa-empty>
</ng-template>
