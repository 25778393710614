<vpfa-customer-data-web
  [modalTitleCode]="modalTitleCode"
  [show]="show"
  [isProcessing]="createCustomerModalProcessing$ | async"
  [formsOfAddress]="customerFormsOfAddress$ | async"
  [isFormsOfAddressLoading]="customerFormsOfAddressLoading$ | async"
  [owners]="allUsers$ | async"
  [isOwnersLoading]="allUsersLoading$ | async"
  [initialOwnerId]="userId$ | async"
  (closeAction)="closeModal()"
  (customerDataChange)="createCustomer($event)"
></vpfa-customer-data-web>
