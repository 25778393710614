import { NOT_AVAILABLE_FOR_REQUESTED_LANGUAGE } from './sorc-constants';
import { SorcTextsResponseDto } from '../models/sorc-texts-response-dto';
import { isNil, isString } from 'lodash';

export const mapContentLanguageValue = (
  texts: { [key: string]: string },
  countryCode: string,
  contentLanguage: string,
  sorcContentLanguageCoversions: { [key: string]: string }
): string => {
  const sorcContentLanguage: string = mapSsmContentLanguage(
    countryCode,
    contentLanguage,
    sorcContentLanguageCoversions
  );
  let mappedValue: string = NOT_AVAILABLE_FOR_REQUESTED_LANGUAGE;

  if (!isNil(sorcContentLanguage)) {
    let description = texts[sorcContentLanguage];
    if (isString(description)) {
      mappedValue = description;
    } else if (hasShortDescription(description)) {
      mappedValue = description['descrshort'];
    }
  }
  return mappedValue;
};

const mapSsmContentLanguage = (
  countryCode: string,
  contentLanguage: string,
  sorcContentLanguageCoversions: { [key: string]: string }
): string => {
  const ssmContentLanguageCode = `${countryCode}${contentLanguage}`;
  const key = Object.keys(sorcContentLanguageCoversions)?.find(conversion => conversion === ssmContentLanguageCode);
  return !isNil(key) ? sorcContentLanguageCoversions[key] : ssmContentLanguageCode;
};

const hasShortDescription = (object: any): object is SorcTextsResponseDto => {
  return !isNil(object) && 'descrshort' in object;
};
