import { IdentificationMethod, UnitValueDto } from '@vpfa/rest-api/valuation';

export class IdentificationDataModel {
  natCode: string;
  edition: string;
  peakPowerkW: UnitValueDto;
  peakPowerHP: UnitValueDto;
  fuelTypeName: string;
  fuelSubtypeName: string;
  transmissionTypeName: string;
  vinNumber: string;
  resellerPromo: boolean;
  wasPromoEvenEnabled: boolean;
  isPublicSiteEnable: boolean;
  make: string;
  identificationMethod: IdentificationMethod;
}
