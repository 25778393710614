import { isNil } from 'lodash';

export const isCo2EfficiencyClassHasNoValue = (value?: number): boolean => {
  if (isNil(value)) {
    return true;
  }

  const co2NoValueIds = [
    519999, // no value for DE
  ];

  return co2NoValueIds.includes(value);
};
