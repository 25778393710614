<vpfa-modal
  width="900px"
  modalName="Repair & Maintenance"
  [showBackdrop]="true"
  [isVisible]="visible"
  [isClosable]="false"
  [keyboard]="false"
  [maskClosable]="false"
  (closeAction)="close()"
>
  <vpfa-modal-content>
    <button
      class="ant-modal-close"
      nz-button
      nzType="link"
      nz-popconfirm
      nzPopconfirmTrigger="click"
      [nzCondition]="estimatorForm.pristine"
      [nzPopconfirmTitle]="'common.unsavedChanges' | translate"
      [nzCancelText]="'common.no' | translate"
      [nzOkText]="'common.yes' | translate"
      nzPopconfirmPlacement="bottom"
      nzPopconfirmOverlayClassName="modal-confirm-popover"
      (nzOnConfirm)="close()"
    >
      <i nz-icon nzType="close-circle" nzTheme="fill"></i>
    </button>
    <form (ngSubmit)="onSubmit()" [formGroup]="estimatorForm">
      <nz-spin [nzSpinning]="loading">
        <h1>{{ 'damageEstimatorModal.lightDamageEstimatorList' | translate }}</h1>
        <div>
          <vpfa-estimator-table
            [visible]="visible"
            [areas]="estimatorAreas"
            [submitted]="submitted"
            [estimatorForm]="estimatorForm"
          ></vpfa-estimator-table>
          <div fxLayoutGap="10px">
            <vpfa-action-button
              buttonType="submit"
              [disabled]="loading || estimatorForm.pristine || estimatorForm.invalid"
            >
              {{ 'damageEstimatorModal.save' | translate }}
            </vpfa-action-button>

            <vpfa-action-button
              [disabled]="loading"
              [outline]="true"
              [border]="false"
              nz-popconfirm
              [nzCondition]="estimatorForm.pristine"
              [nzPopconfirmTitle]="'common.unsavedChanges' | translate"
              [nzCancelText]="'common.no' | translate"
              [nzOkText]="'common.yes' | translate"
              nzPopconfirmPlacement="top"
              nzPopconfirmOverlayClassName="modal-confirm-popover"
              (nzOnConfirm)="close()"
            >
              {{ 'damageEstimatorModal.cancel' | translate }}
            </vpfa-action-button>
          </div>
        </div>
      </nz-spin>
    </form>
  </vpfa-modal-content>
</vpfa-modal>
