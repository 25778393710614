import { ValidatorFn, AbstractControl, Validators } from '@angular/forms';
import { isNil } from 'lodash';

export const positiveValidatorKey = 'positive';

export const positiveValidator: ValidatorFn = (control: AbstractControl) => {
  const validateErrors = Validators.pattern(/^[^-]/)(control);
  if (isNil(validateErrors)) {
    return null;
  }
  return {
    [positiveValidatorKey]: true
  };
};
