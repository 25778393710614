/**
 * Ad Broadcast Service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 

export enum OriginCountryCode {

      AD = 0,
      AE = 1,
      AF = 2,
      AG = 3,
      AI = 4,
      AL = 5,
      AM = 6,
      AO = 7,
      AQ = 8,
      AR = 9,
      AS = 10,
      AT = 11,
      AU = 12,
      AW = 13,
      AX = 14,
      AZ = 15,
      BA = 16,
      BB = 17,
      BD = 18,
      BE = 19,
      BF = 20,
      BG = 21,
      BH = 22,
      BI = 23,
      BJ = 24,
      BL = 25,
      BM = 26,
      BN = 27,
      BO = 28,
      BQ = 29,
      BR = 30,
      BS = 31,
      BT = 32,
      BV = 33,
      BW = 34,
      BY = 35,
      BZ = 36,
      CA = 37,
      CC = 38,
      CD = 39,
      CF = 40,
      CG = 41,
      CH = 42,
      CI = 43,
      CK = 44,
      CL = 45,
      CM = 46,
      CN = 47,
      CO = 48,
      CR = 49,
      CU = 50,
      CV = 51,
      CW = 52,
      CX = 53,
      CY = 54,
      CZ = 55,
      DE = 56,
      DJ = 57,
      DK = 58,
      DM = 59,
      DO = 60,
      DZ = 61,
      EC = 62,
      EE = 63,
      EG = 64,
      EH = 65,
      ER = 66,
      ES = 67,
      ET = 68,
      EU = 69,
      FI = 70,
      FJ = 71,
      FK = 72,
      FM = 73,
      FO = 74,
      FR = 75,
      GA = 76,
      GB = 77,
      GD = 78,
      GE = 79,
      GF = 80,
      GG = 81,
      GH = 82,
      GI = 83,
      GL = 84,
      GM = 85,
      GN = 86,
      GP = 87,
      GQ = 88,
      GR = 89,
      GS = 90,
      GT = 91,
      GU = 92,
      GW = 93,
      GY = 94,
      HK = 95,
      HM = 96,
      HN = 97,
      HR = 98,
      HT = 99,
      HU = 100,
      ID = 101,
      IE = 102,
      IL = 103,
      IM = 104,
      IN = 105,
      IO = 106,
      IQ = 107,
      IR = 108,
      IS = 109,
      IT = 110,
      JE = 111,
      JM = 112,
      JO = 113,
      JP = 114,
      KE = 115,
      KG = 116,
      KH = 117,
      KI = 118,
      KM = 119,
      KN = 120,
      KP = 121,
      KR = 122,
      KW = 123,
      KY = 124,
      KZ = 125,
      LA = 126,
      LB = 127,
      LC = 128,
      LI = 129,
      LK = 130,
      LR = 131,
      LS = 132,
      LT = 133,
      LU = 134,
      LV = 135,
      LY = 136,
      MA = 137,
      MC = 138,
      MD = 139,
      ME = 140,
      MF = 141,
      MG = 142,
      MH = 143,
      MK = 144,
      ML = 145,
      MM = 146,
      MN = 147,
      MO = 148,
      MP = 149,
      MQ = 150,
      MR = 151,
      MS = 152,
      MT = 153,
      MU = 154,
      MV = 155,
      MW = 156,
      MX = 157,
      MY = 158,
      MZ = 159,
      NA = 160,
      NC = 161,
      NE = 162,
      NF = 163,
      NG = 164,
      NI = 165,
      NL = 166,
      NO = 167,
      NP = 168,
      NR = 169,
      NU = 170,
      NZ = 171,
      OM = 172,
      PA = 173,
      PE = 174,
      PF = 175,
      PG = 176,
      PH = 177,
      PK = 178,
      PL = 179,
      PM = 180,
      PN = 181,
      PR = 182,
      PS = 183,
      PT = 184,
      PW = 185,
      PY = 186,
      QA = 187,
      RE = 188,
      RO = 189,
      RS = 190,
      RU = 191,
      RW = 192,
      SA = 193,
      SB = 194,
      SC = 195,
      SD = 196,
      SE = 197,
      SG = 198,
      SH = 199,
      SI = 200,
      SJ = 201,
      SK = 202,
      SL = 203,
      SM = 204,
      SN = 205,
      SO = 206,
      SR = 207,
      SS = 208,
      ST = 209,
      SV = 210,
      SX = 211,
      SY = 212,
      SZ = 213,
      TC = 214,
      TD = 215,
      TF = 216,
      TG = 217,
      TH = 218,
      TJ = 219,
      TK = 220,
      TL = 221,
      TM = 222,
      TN = 223,
      TO = 224,
      TR = 225,
      TT = 226,
      TV = 227,
      TW = 228,
      TZ = 229,
      UA = 230,
      UG = 231,
      US = 232,
      UY = 233,
      UZ = 234,
      VA = 235,
      VC = 236,
      VE = 237,
      VG = 238,
      VI = 239,
      VN = 240,
      VU = 241,
      WF = 242,
      WS = 243,
      YE = 244,
      YT = 245,
      ZA = 246,
      ZM = 247,
      ZW = 248,

};
