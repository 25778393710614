import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Consumption, I18RangeValueDto } from '@vpfa/rest-api/valuation';
import { UntypedFormGroup } from '@angular/forms';
import { UserContext } from '@vpfa/rest-api/admin';
import { SelectOption } from '@vpfa/ui-kit';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { calculateCo2Certificate } from '../../../utils/calculate-co2-cert.util';
import { getFormControlValueAsString } from '../../../utils/get-form-control-value-as-string.util';
import { intervalSign } from '../../../utils/vehicle-full-technical-data-utils';
import { VehicleEngineType } from '@vpfa/dealer/case/data';
import { getI18RangeValue } from '@vpfa/utils';

@Component({
  selector: 'vpfa-full-technical-data-wltp-information',
  templateUrl: './full-technical-data-wltp-information.component.html',
  styleUrls: ['./full-technical-data-wltp-information.component.scss'],
})
export class FullTechnicalDataWltpInformationComponent implements OnInit, OnChanges, OnDestroy {
  VehicleEngineType = VehicleEngineType;
  @Input() technicalDataConsumption: Consumption;
  @Input() cO2WLTPEfficiencyClass: I18RangeValueDto;
  @Input() cO2WLTPEfficiencyClassEB: I18RangeValueDto;
  @Input() formGroup: UntypedFormGroup;
  @Input() errors: ReadonlyArray<{ error: string; errorMsg: string }>;
  @Input() co2Errors: ReadonlyArray<{ error: string; errorMsg: string }>;
  @Input() evRangeAndConsumptionErrors: ReadonlyArray<{ error: string; errorMsg: string }>;

  @Input() userContext: UserContext;
  @Input() efficiencyClassOptions: SelectOption[];
  @Input() efficiencyClassHiddenOptions: SelectOption[];

  @Input() vehicleEngineType: VehicleEngineType = VehicleEngineType.Thermal;
  @Input() isMobile: boolean;
  @Input() isGermany: boolean;

  isCo2LabelImplemented = false;

  private readonly _onDestroy$ = new Subject<void>();

  cO2WLTPEfficiencyClassText: string | null = null;
  cO2WLTPEfficiencyClassEBText: string | null = null;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.cO2WLTPEfficiencyClass) {
      this.cO2WLTPEfficiencyClassText = getI18RangeValue(this.cO2WLTPEfficiencyClass);
    }

    if (this.cO2WLTPEfficiencyClassEB) {
      this.cO2WLTPEfficiencyClassEBText = getI18RangeValue(this.cO2WLTPEfficiencyClassEB);
    }
  }

  ngOnInit(): void {
    this.updateCo2Certificate();
  }

  private updateCo2Certificate() {
    if (!this.isGermany) {
      return;
    }

    this.formGroup.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe(formValue => {
      let emissionValue: string | null = null;
      let emissionValueEmptyBattery: string | null = null;

      // form typeof value === string => range value
      // form typeof value === number => user input
      // form typeof value === object => DTO that will be converted into string => ignore
      emissionValue = getFormControlValueAsString(formValue?.wltp?.cO2EmissionCombined);

      if (this.vehicleEngineType === VehicleEngineType.Gas) {
        emissionValue = getFormControlValueAsString(formValue?.wltp?.gasCO2EmissionCombined);
      }

      if (this.vehicleEngineType === VehicleEngineType.PluginHybrid) {
        emissionValueEmptyBattery = getFormControlValueAsString(formValue?.wltp?.cO2EmissionCombinedEB);
      }

      const emissionIsRange = emissionValue?.includes(intervalSign);
      const emissionEmptyBatteryIsRange = emissionValueEmptyBattery?.includes(intervalSign);

      let label: string | null = null;
      let labelEmptyBattery: string | null = null;

      if (emissionIsRange) {
        label = this.getEmissionClassRange(emissionValue);
      } else {
        label = calculateCo2Certificate(emissionValue);
      }

      if (emissionEmptyBatteryIsRange) {
        labelEmptyBattery = this.getEmissionClassRange(emissionValueEmptyBattery);
      } else {
        labelEmptyBattery = calculateCo2Certificate(emissionValueEmptyBattery);
      }

      if (this.vehicleEngineType === VehicleEngineType.PluginHybrid) {
        this.formGroup
          .get('wltp')
          .get('cO2WLTPEfficiencyClassEBCustom')
          .setValue(labelEmptyBattery, { onlySelf: true, emitEvent: false });
      }

      this.formGroup
        .get('wltp')
        .get('cO2WLTPEfficiencyClassCustom')
        .setValue(label, { onlySelf: true, emitEvent: false });
    });
  }

  getEmissionClassRange(emissionValue: string): string {
    const values = emissionValue.split(intervalSign);

    const result = [];

    if (values?.[0] !== null && values?.[0] !== undefined) {
      result.push(calculateCo2Certificate(values?.[0]));
    }

    if (values?.[1] !== null && values?.[1] !== undefined) {
      result.push(calculateCo2Certificate(values?.[1]));
    }

    return result.join(` ${intervalSign} `);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
