import { EquipmentError, EquipmentErrorEquipmentErrorDto } from '@vpfa/rest-api/valuation';
import { ValidateStepProps } from '../formula-wizard.actions';

/**
 * Check if has some new errors different that unmet formula in comparison to previous steps
 * @param action
 * @param res
 */
export function hasNewErrors(
  action: Pick<ValidateStepProps, 'force'>,
  equipmentErrors: Array<Pick<EquipmentErrorEquipmentErrorDto, 'error'>>
) {
  if (action.force) {
    return false;
  }

  return equipmentErrors.filter(error => error.error !== EquipmentError.UnmetFormulaOfEnabledEquipment).length > 0;
}
