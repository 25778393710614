import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import {
  ImageFrameActionTypes,
  fromImageFrameActions,
  ImageFrameEditorURLLoad,
  BusinessImageFrameCreated,
  BusinessImageFrameCreate,
  BusinessImageFrameUpdate,
  BusinessImageFrameLoad,
  BusinessImageFrameUpdated,
  ImageFrameDelete,
  ImageFrameDeleted,
  ImageFramesLoad,
  FramedImagePreviewLoad,
  ImageFrameEditorURLLoaded,
} from './image-frame.actions';
import { ImageFrameService, ImageFrameViewService, ImageFrameFilesViewService } from '@vpfa/rest-api/valuation';
import { tap, map, catchError, flatMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { BasicNotificationsService } from '@vpfa/shared/notifications';
import {
  pdfReProFrame,
  pdfReProFrameValue,
  pdfReProNewTemplate,
  pdfReProNewTemplateValue,
} from '@vpfa/print-templates/shared';

@Injectable()
export class ImageFrameEffects {
   businessImageFrameLoad$ = createEffect(() => this.actions$.pipe(
    ofType<BusinessImageFrameLoad>(ImageFrameActionTypes.BusinessImageFrameLoad),
    flatMap(action =>
      this.imageFrameViewService.listBusinessImageFramesAsync(action.payload.countryId, action.payload.businessId).pipe(
        map(response => new fromImageFrameActions.BusinessImageFrameLoaded(response)),
        catchError(() => of(new fromImageFrameActions.BusinessImageFrameLoadError()))
      )
    )
  ));

   imageFramesLoad$ = createEffect(() => this.actions$.pipe(
    ofType<ImageFramesLoad>(ImageFrameActionTypes.ImageFramesLoad),
    flatMap(action =>
      this.imageFrameViewService.listImageFramesAsync().pipe(
        map(response => new fromImageFrameActions.ImageFramesLoaded(response)),
        catchError(() => of(new fromImageFrameActions.ImageFramesLoadError()))
      )
    )
  ));

   framedImagePreviewLoad$ = createEffect(() => this.actions$.pipe(
    ofType<FramedImagePreviewLoad>(ImageFrameActionTypes.FramedImagePreviewLoad),
    flatMap(action => {
      return this.imageFrameFilesViewService
        .generateJpgAsync(action.payload.caseId, action.payload.frameId, action.payload.imageKey)
        .pipe(
          map(
            response => new fromImageFrameActions.FramedImagePreviewLoaded({ preview: URL.createObjectURL(response) })
          ),
          catchError(error => of(new fromImageFrameActions.FramedImagePreviewLoadError()))
        );
    })
  ));

   businessImageFrameUpdate$ = createEffect(() => this.actions$.pipe(
    ofType<BusinessImageFrameUpdate>(ImageFrameActionTypes.BusinessImageFrameUpdate),
    flatMap(action =>
      this.imageFrameService.updateAdminImageFrame(action.payload).pipe(
        map(() => new fromImageFrameActions.BusinessImageFrameUpdated(action.payload)),
        catchError(() => of(new fromImageFrameActions.BusinessImageFrameUpdateError()))
      )
    )
  ));

   businessImageFrameUpdated$ = createEffect(() => this.actions$.pipe(
    ofType<BusinessImageFrameUpdated>(ImageFrameActionTypes.BusinessImageFrameUpdated),
    tap(() => this.notifications.success('admin.businesses.imagesAndFrames.renameFrameSuccess')),
    switchMap(action => [
      new fromImageFrameActions.BusinessImageFrameLoad({
        countryId: action.payload.countryId,
        businessId: action.payload.businessId,
      }),
      new fromImageFrameActions.CreateImageFrameModalClose(),
    ])
  ));

   businessImageFrameCreate$ = createEffect(() => this.actions$.pipe(
    ofType<BusinessImageFrameCreate>(ImageFrameActionTypes.BusinessImageFrameCreate),
    flatMap(action =>
      this.imageFrameService.createAdminImageFrame(action.payload).pipe(
        map(
          response =>
            new fromImageFrameActions.BusinessImageFrameCreated({
              businessId: action.payload.businessId,
              countryId: action.payload.countryId,
              id: response.aggregateId,
            })
        ),
        catchError(() => of(new fromImageFrameActions.BusinessImageFrameCreateError()))
      )
    )
  ));

   businessImageFrameCreated$ = createEffect(() => this.actions$.pipe(
    ofType<BusinessImageFrameCreated>(ImageFrameActionTypes.BusinessImageFrameCreated),
    tap(() => {
      localStorage.setItem(pdfReProFrame, pdfReProFrameValue);
      localStorage.setItem(pdfReProNewTemplate, pdfReProNewTemplateValue);
    }),
    flatMap(action => [
      new fromImageFrameActions.BusinessImageFrameLoad({
        countryId: action.payload.countryId,
        businessId: action.payload.businessId,
      }),
      new fromImageFrameActions.ImageFrameEditorURLLoad({ frameId: action.payload.id }),
    ])
  ));

   businessImageFrameDelete$ = createEffect(() => this.actions$.pipe(
    ofType<ImageFrameDelete>(ImageFrameActionTypes.ImageFrameDelete),
    flatMap(action =>
      this.imageFrameService.deleteAdminImageFrame(action.payload.id).pipe(
        map(
          response =>
            new fromImageFrameActions.ImageFrameDeleted({
              businessId: action.payload.businessId,
              countryId: action.payload.countryId,
            })
        ),
        catchError(() => of(new fromImageFrameActions.ImageFrameDeleteError()))
      )
    )
  ));

   imageFrameDeleted$ = createEffect(() => this.actions$.pipe(
    ofType<ImageFrameDeleted>(ImageFrameActionTypes.ImageFrameDeleted),
    tap(() => this.notifications.success('admin.businesses.imagesAndFrames.deleteFrameSuccess')),
    flatMap(action => [
      new fromImageFrameActions.BusinessImageFrameLoad({
        countryId: action.payload.countryId,
        businessId: action.payload.businessId,
      }),
    ])
  ));

   loadTemplateEditURL$ = createEffect(() => this.actions$.pipe(
    ofType<ImageFrameEditorURLLoad>(ImageFrameActionTypes.ImageFrameEditorURLLoad),
    flatMap(action =>
      this.imageFrameViewService.getAdminEditorUrlAsync(action.payload.frameId).pipe(
        map(res => new fromImageFrameActions.ImageFrameEditorURLLoaded({ url: res.url })),
        catchError(() => of(new fromImageFrameActions.ImageFrameEditorURLLoadError()))
      )
    )
  ));

   imageFrameEditorURLLoaded$ = createEffect(() => this.actions$.pipe(
    ofType<ImageFrameEditorURLLoaded>(ImageFrameActionTypes.ImageFrameEditorURLLoaded),
    tap(() => {
      localStorage.setItem(pdfReProFrame, pdfReProFrameValue);
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private notifications: BasicNotificationsService,
    private imageFrameService: ImageFrameService,
    private imageFrameViewService: ImageFrameViewService,
    private imageFrameFilesViewService: ImageFrameFilesViewService
  ) {}
}
