<vpfa-data-table
  [forceClickableClass]="true"
  [defaultColDef]="defaultColDef"
  [columnDefs]="colDefs"
  [rowData]="listItems"
  [rowClassRules]="wizardModeClass"
  [columnVisibility]="false"
  [options]="gridOptions"
  [tableName]="tableName"
  [extraHeaderLeftTemplate]="identifyNumberTmpl"
  [defaultAgGridRowSelection]="'single'"
  [suppressRowClickSelection]="false"
  [suppressSizeToFitAfterPageSize]="true"
  [stickyXScroll]="true"
  [stickyXScrollCustomBottomShift]="wizardMode ? undefined : stickyXScrollCustomBottomShift"
  (actionClicked)="onActionClicked($event)"
  (gridReady)="onGridReady($event)"
></vpfa-data-table>

<ng-template #identifyNumberTmpl>{{ identifyNumber }}</ng-template>
