import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EquipmentSectionBase } from '../equipment-section-base/equipment-section-base';
import { UntypedFormBuilder } from '@angular/forms';
import { AdjustmentsFacade, CasesFacade } from '@vpfa/dealer/case/data';
import { takeUntil, pairwise, filter } from 'rxjs/operators';

type DisplayedContent = 'equipmentOverview';

@Component({
  selector: 'vpfa-equipment-section-mobile',
  templateUrl: './equipment-section-mobile.component.html',
  styleUrls: ['./equipment-section-mobile.component.scss'],
})
export class EquipmentSectionMobileComponent extends EquipmentSectionBase implements OnInit, OnDestroy {
  @Output() closeAction = new EventEmitter();

  displayedContent: DisplayedContent = 'equipmentOverview';

  constructor(
    fb: UntypedFormBuilder,
    caseFacade: CasesFacade,
    adjustmentsFacade: AdjustmentsFacade,
    router: Router,
    route: ActivatedRoute
  ) {
    super(fb, caseFacade, adjustmentsFacade, router, route);
  }

  ngOnInit() {
    super.ngOnInit();

    this.isUpdatingAdjustments$
      .pipe(
        pairwise(),
        filter(([prev, curr]) => prev === true && curr === false),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.displayedContent = 'equipmentOverview';
      });
  }

  back() {
    this.displayedContent = 'equipmentOverview';
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
