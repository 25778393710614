<div
  *ngIf="!notAvailable"
  class="list-link"
  [ngClass]="isDisabled ? 'disabled' : ''"
  (click)="onForeCastClick()"
  [fxLayout]="'row'"
  [fxLayoutAlign]="'space-between'"
>
  <span>
    {{ 'valuationResult.forecast' | translate }}
  </span>
  <span class="link-arrow zmdi zmdi-long-arrow-right"></span>
</div>
<vpfa-price-with-icon
  *ngIf="notAvailable"
  class="list-link disabled"
  [price]="'valuationResult.forecast' | translate"
  [color]="'orange'"
></vpfa-price-with-icon>

<vpfa-forecast-modal
  [isLoading]="isUpdatingValuationDate$ | async"
  [isVisible]="isModalVisible"
  [forecastDate]="forecastDate"
  [timezone]="timezone"
  (forecastRequest)="getForecastForDate($event)"
  (closeAction)="onClose()"
></vpfa-forecast-modal>
