<div [formGroup]="formGroup" ngClass.lt-mv="no-hor-scroll">
  <ng-container *vpfaResponsiveIf="'web'; else mobileHeader">
    <vpfa-expanding-header [text]="'uniqueData.modal.valuationData' | translate"></vpfa-expanding-header>
  </ng-container>
  <div fxLayout.lt-mv="column" fxLayoutGap.lt-mv="10px" [ngClass.lt-mv]="'margin-bottom'">
    <vpfa-unique-data-valuation-select
      [parentFormGroup]="formGroup"
      [label]="'uniqueData.vehicleCategoryLabel' | translate"
      [options]="vehicleCategoryOptions"
      fcName="vehicleCategory"
    ></vpfa-unique-data-valuation-select>

    <vpfa-unique-data-valuation-text-field
      [label]="'uniqueData.valuationNumber' | translate"
      [parentFormGroup]="formGroup"
      fcName="valuationNumber"
      [fieldWidth]="'100%'"
    ></vpfa-unique-data-valuation-text-field>

    <vpfa-unique-data-valuation-month-picker
      *ngIf="!hideBuildDate"
      [notAvailable]="buildDateNotAvailable"
      [label]="'uniqueData.buildDate' | translate"
      [parentFormGroup]="formGroup"
      fcName="buildDate"
    ></vpfa-unique-data-valuation-month-picker>

    <vpfa-unique-data-valuation-select
      [parentFormGroup]="formGroup"
      [label]="'uniqueData.mileageTypeLabel' | translate"
      [options]="mileageTypesOptions"
      fcName="mileageType"
    ></vpfa-unique-data-valuation-select>

    <vpfa-unique-data-valuation-text-field-with-dropdown
      [label]="'uniqueData.manufacturerColor' | translate"
      [parentFormGroup]="formGroup"
      [fcName]="'manufacturerColor'"
      [options]="manufacturerColorOptions"
      [errorMessages]="maxLengthErrorMsgList"
      [isCustomValueProvided]="isManufacturerColourCustomValueProvided"
      [showResetButton]="isCaseIdentifiedByVin"
      [resetButtonDisabled]="manufacturerColourResetButtonDisabled()"
      [resetButtonTooltip]="'uniqueData.resetManufacturerColor' | translate"
      (selectedValue)="onManufacturerColourSelectedValue($event)"
      (resetValue)="onManufacturerColourReset()"
    ></vpfa-unique-data-valuation-text-field-with-dropdown>

    <vpfa-unique-data-valuation-select
      [parentFormGroup]="formGroup"
      [label]="'uniqueData.paintColourLabel' | translate"
      [options]="paintColourOptions"
      fcName="paintColour"
      [allowClear]="true"
    ></vpfa-unique-data-valuation-select>

    <vpfa-unique-data-valuation-select
      [parentFormGroup]="formGroup"
      [label]="'uniqueData.paintBrightnessLabel' | translate"
      [options]="paintBrightnessOptions"
      fcName="paintBrightness"
      [allowClear]="true"
    ></vpfa-unique-data-valuation-select>

    <vpfa-unique-data-valuation-select
      [parentFormGroup]="formGroup"
      [label]="'uniqueData.paintTypeLabel' | translate"
      [options]="paintTypeOptions"
      fcName="paintType"
      [allowClear]="true"
    ></vpfa-unique-data-valuation-select>

    <vpfa-unique-data-valuation-text-field-with-dropdown
      *ngIf="!hideInteriorTrimColour"
      [label]="'uniqueData.trimType' | translate"
      [parentFormGroup]="formGroup"
      [fcName]="'trimType'"
      [options]="trimTypeOptions"
      [errorMessages]="maxLengthErrorMsgList"
      [showResetButton]="isCaseIdentifiedByVin"
      [resetButtonDisabled]="trimTypeResetButtonDisabled()"
      [resetButtonTooltip]="'uniqueData.resetTrimType' | translate"
      (resetValue)="onTrimTypeReset()"
      (selectedValue)="onTrimTypeSelectedValue($event)"
    ></vpfa-unique-data-valuation-text-field-with-dropdown>

    <vpfa-unique-data-valuation-select
      [parentFormGroup]="formGroup"
      [label]="'uniqueData.interiorColour' | translate"
      [options]="interiorColourOptions"
      fcName="interiorTrimColor"
      [allowClear]="true"
    ></vpfa-unique-data-valuation-select>

    <vpfa-unique-data-valuation-text-field
      [label]="'uniqueData.registrationDocumentNumber' | translate"
      [parentFormGroup]="formGroup"
      fcName="registrationDocumentNumber"
      [placeholder]="''"
      [errorMessages]="maxLengthErrorMsgList"
    ></vpfa-unique-data-valuation-text-field>

    <vpfa-unique-data-valuation-text-field
      [label]="'uniqueData.keyNumber' | translate"
      [parentFormGroup]="formGroup"
      fcName="keyNumber"
      [placeholder]="''"
      [errorMessages]="maxLengthErrorMsgList"
    ></vpfa-unique-data-valuation-text-field>
  </div>

  <ng-container *ngIf="initialData?.damagedVehicleType; then damagedVehicleType; else complexVehicleType">
  </ng-container>

  <vpfa-unique-data-valuation-select
    [parentFormGroup]="formGroup"
    [label]="'uniqueData.branch' | translate"
    [options]="branchOptions"
    fcName="branchId"
  ></vpfa-unique-data-valuation-select>
</div>

<ng-template #mobileHeader>
  <vpfa-mobile-subtitle [title]="'uniqueData.modal.valuationData' | translate"></vpfa-mobile-subtitle>
</ng-template>

<ng-template #damagedVehicleType>
  <vpfa-unique-data-valuation-select
    [parentFormGroup]="formGroup"
    [label]="'uniqueData.damagedVehicleTypeLabel' | translate"
    [options]="damagedVehicleTypeOptions"
    fcName="damagedVehicleType"
    [allowClear]="true"
    [ngClass.lt-mv]="'margin-bottom'"
  ></vpfa-unique-data-valuation-select>
</ng-template>

<ng-template #complexVehicleType>
  <vpfa-unique-data-valuation-switch
    [formGroup]="formGroup"
    [fcName]="'isRoadworthy'"
    [label]="'uniqueData.isRoadworthy' | translate"
  ></vpfa-unique-data-valuation-switch>

  <vpfa-unique-data-valuation-switch
    [formGroup]="formGroup"
    [fcName]="'hasUnrepairedDamage'"
    [label]="'uniqueData.hasUnrepairedDamage' | translate"
  ></vpfa-unique-data-valuation-switch>

  <vpfa-unique-data-valuation-switch
    [formGroup]="formGroup"
    [fcName]="'hadAccident'"
    [label]="'uniqueData.hadAccident' | translate"
    [ngClass.lt-mv]="'margin-bottom'"
  ></vpfa-unique-data-valuation-switch>
</ng-template>
