import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { BranchConfigurationService } from './api/branchConfiguration.service';
import { BranchConfigurationViewService } from './api/branchConfigurationView.service';
import { BulkValuationService } from './api/bulkValuation.service';
import { BulkValuationViewService } from './api/bulkValuationView.service';
import { BusinessAdjustmentsService } from './api/businessAdjustments.service';
import { BusinessAdjustmentsViewService } from './api/businessAdjustmentsView.service';
import { BusinessConfigurationService } from './api/businessConfiguration.service';
import { BusinessConfigurationViewService } from './api/businessConfigurationView.service';
import { BusinessDefaultCostsService } from './api/businessDefaultCosts.service';
import { BusinessDefaultCostsViewService } from './api/businessDefaultCostsView.service';
import { BusinessImagesService } from './api/businessImages.service';
import { BusinessImagesViewService } from './api/businessImagesView.service';
import { BusinessMakeSpecificCorrectionsService } from './api/businessMakeSpecificCorrections.service';
import { BusinessMakeSpecificCorrectionsViewService } from './api/businessMakeSpecificCorrectionsView.service';
import { CO2EfficiencyClassViewService } from './api/cO2EfficiencyClassView.service';
import { CaseService } from './api/case.service';
import { CaseFilesService } from './api/caseFiles.service';
import { CaseFilesViewService } from './api/caseFilesView.service';
import { CaseListingService } from './api/caseListing.service';
import { CaseViewService } from './api/caseView.service';
import { CooperativeViewService } from './api/cooperativeView.service';
import { CountryAdjustmentsService } from './api/countryAdjustments.service';
import { CountryAdjustmentsViewService } from './api/countryAdjustmentsView.service';
import { CountryConfigurationService } from './api/countryConfiguration.service';
import { CountryConfigurationViewService } from './api/countryConfigurationView.service';
import { CountryFuelSubtypePriceService } from './api/countryFuelSubtypePrice.service';
import { CountryFuelSubtypePriceViewService } from './api/countryFuelSubtypePriceView.service';
import { CsvConfigurationViewService } from './api/csvConfigurationView.service';
import { CustomerService } from './api/customer.service';
import { CustomerViewService } from './api/customerView.service';
import { DbAdminService } from './api/dbAdmin.service';
import { EmissionClassViewService } from './api/emissionClassView.service';
import { EmissionStickerViewService } from './api/emissionStickerView.service';
import { EsacoViewService } from './api/esacoView.service';
import { FileStorageService } from './api/fileStorage.service';
import { IdentifiedVehicleService } from './api/identifiedVehicle.service';
import { ImageFrameService } from './api/imageFrame.service';
import { ImageFrameFilesViewService } from './api/imageFrameFilesView.service';
import { ImageFrameViewService } from './api/imageFrameView.service';
import { OfferEmailTemplateService } from './api/offerEmailTemplate.service';
import { OfferEmailTemplateViewService } from './api/offerEmailTemplateView.service';
import { PaintColourService } from './api/paintColour.service';
import { PdfTemplateService } from './api/pdfTemplate.service';
import { PdfTemplateViewService } from './api/pdfTemplateView.service';
import { QRCodeViewService } from './api/qRCodeView.service';
import { QuoteEmailTemplateService } from './api/quoteEmailTemplate.service';
import { QuoteEmailTemplateViewService } from './api/quoteEmailTemplateView.service';
import { QuoteViewService } from './api/quoteView.service';
import { RepairEstimateProfileService } from './api/repairEstimateProfile.service';
import { RepairEstimateProfileViewService } from './api/repairEstimateProfileView.service';
import { RepairEstimateSessionService } from './api/repairEstimateSession.service';
import { RepairEstimateSessionFilesViewService } from './api/repairEstimateSessionFilesView.service';
import { RepairEstimateSessionViewService } from './api/repairEstimateSessionView.service';
import { StockOfferViewService } from './api/stockOfferView.service';
import { StockViewService } from './api/stockView.service';
import { SubjectService } from './api/subject.service';
import { UniversalCategoryViewService } from './api/universalCategoryView.service';
import { ValuationInternalService } from './api/valuationInternal.service';
import { ValuationKernelViewService } from './api/valuationKernelView.service';
import { ValuationSeedService } from './api/valuationSeed.service';
import { VehicleGroupService } from './api/vehicleGroup.service';
import { VehicleValuationInternalService } from './api/vehicleValuationInternal.service';
import { VehicleValuationServiceMaintenanceService } from './api/vehicleValuationServiceMaintenance.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    BranchConfigurationService,
    BranchConfigurationViewService,
    BulkValuationService,
    BulkValuationViewService,
    BusinessAdjustmentsService,
    BusinessAdjustmentsViewService,
    BusinessConfigurationService,
    BusinessConfigurationViewService,
    BusinessDefaultCostsService,
    BusinessDefaultCostsViewService,
    BusinessImagesService,
    BusinessImagesViewService,
    BusinessMakeSpecificCorrectionsService,
    BusinessMakeSpecificCorrectionsViewService,
    CO2EfficiencyClassViewService,
    CaseService,
    CaseFilesService,
    CaseFilesViewService,
    CaseListingService,
    CaseViewService,
    CooperativeViewService,
    CountryAdjustmentsService,
    CountryAdjustmentsViewService,
    CountryConfigurationService,
    CountryConfigurationViewService,
    CountryFuelSubtypePriceService,
    CountryFuelSubtypePriceViewService,
    CsvConfigurationViewService,
    CustomerService,
    CustomerViewService,
    DbAdminService,
    EmissionClassViewService,
    EmissionStickerViewService,
    EsacoViewService,
    FileStorageService,
    IdentifiedVehicleService,
    ImageFrameService,
    ImageFrameFilesViewService,
    ImageFrameViewService,
    OfferEmailTemplateService,
    OfferEmailTemplateViewService,
    PaintColourService,
    PdfTemplateService,
    PdfTemplateViewService,
    QRCodeViewService,
    QuoteEmailTemplateService,
    QuoteEmailTemplateViewService,
    QuoteViewService,
    RepairEstimateProfileService,
    RepairEstimateProfileViewService,
    RepairEstimateSessionService,
    RepairEstimateSessionFilesViewService,
    RepairEstimateSessionViewService,
    StockOfferViewService,
    StockViewService,
    SubjectService,
    UniversalCategoryViewService,
    ValuationInternalService,
    ValuationKernelViewService,
    ValuationSeedService,
    VehicleGroupService,
    VehicleValuationInternalService,
    VehicleValuationServiceMaintenanceService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}