import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import { isNil, isObject } from 'lodash';
import { UnitRangeValueDto } from '@vpfa/rest-api/valuation';

const intervalSign = '-';

/**
 * Checks if interval values remain unchanged
 *
 * TODO: rename this validator
 */
export function valueHasToBeSameValidator(initialValue: UnitRangeValueDto): ValidatorFn {
  return (form: UntypedFormControl): { [key: string]: any } => {
    let intervalValue: string | null = null;
    let initial = String(initialValue?.value);

    if (typeof form.value === 'string') {
      intervalValue = form.value;
    }

    if (form.value && form.value.value && form.value.valueMax) {
      intervalValue = form.value.value + intervalSign + form.value.valueMax;
    }

    if (initialValue && initialValue.value && initialValue.valueMax) {
      initial = initialValue.value + intervalSign + initialValue.valueMax;
    }

    intervalValue = intervalValue ? String(intervalValue).split(' ').join('') : null;
    initial = initial ? String(initial).split(' ').join('') : null;

    if (!isNil(intervalValue) && !Number(intervalValue) && intervalValue !== '0' && intervalValue !== initial) {
      return { intervalInvalid: true };
    }
    return null;
  };
}
