import { Component, Input, TemplateRef } from '@angular/core';
import { PriceDisplayModel } from '../pricing-details-modal/pricing-details-modal.component';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'vpfa-pricing-details-sold',
  templateUrl: './pricing-details-sold.component.html',
  styleUrls: ['./pricing-details-sold.component.scss'],
})
export class PricingDetailsSoldComponent {
  @Input() soldPrices: PriceDisplayModel[];
  @Input() soldPricesForm: UntypedFormGroup;
  @Input() errorMessages;
  @Input() priceTemplate: TemplateRef<void>;
}
