<div class="expanding-header d-flex flex-row gap-5px" [ngStyle.lt-mv]="{ marginBottom: marginBottomMobile }">
  <div class="d-flex flex-column mobile">
    <span class="box skew dark-grey mobile"></span>
    <div>
      <span class="expander-label">{{ text }}</span>
      <ng-container *vpfaResponsiveIf="'mobile'">
        <vpfa-tooltip *ngIf="tooltipContent" class="tooltip" [content]="tooltipContent"></vpfa-tooltip>
      </ng-container>
    </div>
  </div>
  <div *vpfaResponsiveIf="'web'" class="right-column d-flex flex-column justify-content-between align-items-start">
    <span class="box box-small skew light-grey"></span>
    <vpfa-tooltip *ngIf="tooltipContent" class="tooltip" [content]="tooltipContent"></vpfa-tooltip>
  </div>
</div>
