import { Component, Input } from '@angular/core';
import { isNil } from 'lodash';
import { CO2EmissionRangeDto } from '@vpfa/rest-api/valuation';
import { EmissionColour } from '../provenance-check-section-environmental.component';

@Component({
  selector: 'vpfa-right-arrow-box',
  templateUrl: './right-arrow-box.component.html',
  styleUrls: ['./right-arrow-box.component.scss'],
})
export class RightArrowBoxComponent {
  @Input() ranges: CO2EmissionRangeDto[] = [];
  @Input() colour: EmissionColour = 'green';
  @Input() maxWidth: number = 100;

  renderSymbol(range) {
    if (range.length > 1) {
      let rangeBottom = range[0];
      let rangeTop = range[1];
      if (!isNil(rangeBottom)) {
        if (isNil(rangeTop)) {
          return ' + ';
        } else {
          return ' - ';
        }
      } else {
        return ' < ';
      }
    }
  }

  get colorClass() {
    return `${this.colour}`;
  }

  get widthStyle() {
    return { 'max-width.px': this.maxWidth, width: '100%' };
  }
}
