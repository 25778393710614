<div #equipmentList class="equipment-list">
  <div *ngFor="let equipment of equipmentData; let i = index" class="equipment-item" [fxLayout]="'column'">
    <div [fxLayout]="'row'">
      <div class="description" [ngClass]="{ fade: !isExpanded(i) && isLongDescription(i) }">
        <span>{{ equipment.description }}</span>
        <ul *ngIf="isPackageContentAvailable(equipment)" class="content-list">
          <ng-container *ngFor="let content of equipment?.content">
            <li>{{ content.shortDescription }}</li>
          </ng-container>
        </ul>
      </div>
      <div
        class="price"
        [ngClass]="{ 'price-right': !isLongDescription(i) }"
        *ngIf="isOneLine(i)"
        [fxLayoutAlign]="'end end'"
      >
        {{ equipment.priceBrutto | currencyLocale }}
      </div>
    </div>
    <div class="show-more" [fxLayout]="'row'" [fxLayoutAlign]="'space-between'">
      <button nzType="link" nz-button (click)="show(i)" *ngIf="isLongDescription(i)">
        <i *ngIf="!isExpanded(i) && isLongDescription(i)" nz-icon nzType="down" nzTheme="outline"></i>
        <i *ngIf="!(!isExpanded(i) && isLongDescription(i))" nz-icon nzType="up" nzTheme="outline"></i>
        {{ (!isExpanded(i) && isLongDescription(i) ? 'common.showMore' : 'common.showLess') | translate }}
      </button>
      <div
        class="price"
        [ngClass]="{ 'price-right': !isLongDescription(i) }"
        [fxLayoutAlign]="'end end'"
        *ngIf="!isOneLine(i)"
      >
        {{ equipment.priceBrutto | currencyLocale }}
      </div>
    </div>
    <nz-divider></nz-divider>
  </div>
</div>
