import { CountryDto, FeaturesConfigurationDto } from '@vpfa/rest-api/admin';
import { produce } from 'immer';
import { LocaleAction, LocaleActionTypes } from './locale.actions';
import { getCurrencySymbol, formatCurrency } from '@angular/common';
import { CsvConfigurationDto } from '@vpfa/rest-api/valuation';

export const LOCALE_FEATURE_KEY = 'locale';
export const DEFAULT_CSV_DELIMITER = ',';

export interface Entity {
  id: string;
}

export interface LocaleState {
  localeEffectsInitialized: boolean;
  isCountryLoading: boolean;
  isCountryLoaded: boolean;
  country: CountryDto;
  selectedContentLanguageId: string | number;
  selectedUILanguageId: string | number;
  locale: string;
  currencyPrefix: boolean;
  csvConfig: CsvConfigurationDto;
  countryExperianTermsUrl: string;
  featureConfig: {
    loaded: boolean;
    loading: boolean;
    config: FeaturesConfigurationDto;
    forCountry: string;
  };
}

export interface LocalePartialState {
  readonly [LOCALE_FEATURE_KEY]: LocaleState;
}
export const initialState: LocaleState = {
  localeEffectsInitialized: false,
  isCountryLoading: false,
  isCountryLoaded: false,
  country: null,
  selectedContentLanguageId: null,
  selectedUILanguageId: null,
  locale: null,
  currencyPrefix: false,
  csvConfig: {
    delimiter: DEFAULT_CSV_DELIMITER,
  },
  countryExperianTermsUrl: null,
  featureConfig: {
    loaded: false,
    config: null,
    loading: false,
    forCountry: null,
  },
};

export function reducer(state: LocaleState = initialState, action: LocaleAction): LocaleState {
  switch (action.type) {
    case LocaleActionTypes.CountryLoaded:
      return produce(state, newState => {
        newState.country = action.payload;
        newState.isCountryLoading = false;
      });
    case LocaleActionTypes.LoadUserCountry:
      return produce(state, newState => {
        newState.isCountryLoading = true;
      });
    case LocaleActionTypes.UserCountryLoadError:
      return produce(state, newState => {
        newState.isCountryLoading = false;
      });

    case LocaleActionTypes.SelectUILanguage:
      return produce(state, newState => {
        newState.selectedUILanguageId = action.payload;
      });

    case LocaleActionTypes.SelectContentLanguage:
      return produce(state, newState => {
        newState.selectedContentLanguageId = action.payload;
      });
    case LocaleActionTypes.LocaleLoaded:
      return produce(state, newState => {
        newState.locale = action.payload;
        newState.currencyPrefix =
          state.country && action.payload ? currencyPrefix(action.payload, state.country.currency) : false;
      });
    case LocaleActionTypes.CsvConfigurationLoaded:
      return produce(state, newState => {
        newState.csvConfig = action.payload;
      });
    case LocaleActionTypes.CsvConfigurationLoadError:
      return produce(state, newState => {
        newState.csvConfig.delimiter = DEFAULT_CSV_DELIMITER;
      });
    case LocaleActionTypes.ExperianTermsUrlLoad:
      return produce(state, newState => {
        newState.countryExperianTermsUrl = null;
      });
    case LocaleActionTypes.ExperianTermsUrlLoaded:
      return produce(state, newState => {
        newState.countryExperianTermsUrl = action.payload;
      });
    case LocaleActionTypes.FeatureConfigLoad:
      return produce(state, newState => {
        newState.featureConfig.loading = true;
      });
    case LocaleActionTypes.FeatureConfigLoaded:
      return produce(state, newState => {
        newState.featureConfig.loading = false;
        newState.featureConfig.loaded = true;
        newState.featureConfig.forCountry = action.payload.forCountry;
        newState.featureConfig.config = action.payload;
      });
    case LocaleActionTypes.FeatureConfigLoadError:
      return produce(state, newState => {
        newState.featureConfig.loading = false;
      });
  }
  return state;
}

const currencyPrefix = (locale: string, code: string) => {
  const symbol = getCurrencySymbol(code, 'narrow', locale);
  return !Boolean(formatCurrency(1, locale, symbol, code).indexOf(symbol));
};
