import { BranchListItemDto } from '@vpfa/rest-api/admin';
import { DictionariesAction, DictionariesActionTypes } from './dictionaries.actions';
import { produce } from 'immer';
import { ColorDictionaryItemDto, KeyTranslationDto } from '@vpfa/rest-api/valuation';
import { VehicleMakeDto, VehicleTypeDto } from '@vpfa/rest-api/identification';

export const DICTIONARIES_FEATURE_KEY = 'dictionaries';

export interface DictionaryState<T> {
  list: T[];
  loaded: boolean;
  error?: any;
}

interface MakeListEntity {
  loading: boolean;
  list: VehicleMakeDto[];
}

export interface DictionariesState {
  branchList: DictionaryState<BranchListItemDto>;
  vehicleGroupList: DictionaryState<KeyTranslationDto>;
  vehicleTypesList: DictionaryState<VehicleTypeDto>;
  makeListDictionary: { [id: number]: MakeListEntity };
  adminPaintColourList: DictionaryState<KeyTranslationDto>;
  paintColourList: DictionaryState<ColorDictionaryItemDto>;
  emissionClassList: DictionaryState<KeyTranslationDto>;
  cO2EfficiencyClassList: DictionaryState<KeyTranslationDto>;
}

export interface DictionariesPartialState {
  readonly [DICTIONARIES_FEATURE_KEY]: DictionariesState;
}

const emptyDictionaryState = {
  list: [],
  loaded: false,
};

export const dictionaryInitState: DictionariesState = {
  branchList: emptyDictionaryState,
  vehicleGroupList: emptyDictionaryState,
  vehicleTypesList: emptyDictionaryState,
  makeListDictionary: {},
  adminPaintColourList: emptyDictionaryState,
  paintColourList: emptyDictionaryState,
  emissionClassList: emptyDictionaryState,
  cO2EfficiencyClassList: emptyDictionaryState,
};

export function reducer(state = dictionaryInitState, action: DictionariesAction): DictionariesState {
  switch (action.type) {
    case DictionariesActionTypes.BranchListLoaded:
      return produce<DictionariesState>(state, newState => {
        newState.branchList.list = action.payload;
        newState.branchList.loaded = true;
      });
    case DictionariesActionTypes.VehicleGroupListLoaded:
      return produce<DictionariesState>(state, newState => {
        newState.vehicleGroupList.list = action.payload;
        newState.vehicleGroupList.loaded = true;
      });
    case DictionariesActionTypes.VehicleTypeListLoaded:
      return produce<DictionariesState>(state, newState => {
        newState.vehicleTypesList.list = [...action.payload].sort((a, b) => (a.name > b.name ? 1 : -1));
        newState.vehicleTypesList.loaded = true;
      });
    case DictionariesActionTypes.LoadMakeListByTypeIds:
      return produce<DictionariesState>(state, newState => {
        const makeList = action.payload.reduce(
          (makes: { [id: number]: MakeListEntity }, id) => {
            return {
              ...makes,
              [id]: {
                ...makes[id],
                loading: true,
              },
            };
          },
          { ...state.makeListDictionary }
        );
        newState.makeListDictionary = {
          ...state.makeListDictionary,
          ...makeList,
        };
      });
    case DictionariesActionTypes.LoadMakeListError:
      return produce<DictionariesState>(state, newState => {
        newState.makeListDictionary[action.payload.typeId].loading = false;
      });
    case DictionariesActionTypes.LoadMakeListSuccess:
      return produce<DictionariesState>(state, newState => {
        const { typeId, list } = action.payload;
        newState.makeListDictionary[typeId] = {
          list: list,
          loading: false,
        };
      });

    case DictionariesActionTypes.LoadAdminPaintColourListCompleted:
      return produce<DictionariesState>(state, newState => {
        newState.adminPaintColourList.list = action.payload;
        newState.adminPaintColourList.loaded = true;
      });

    case DictionariesActionTypes.LoadAdminPaintColourListError:
      return produce<DictionariesState>(state, newState => {
        newState.adminPaintColourList.loaded = false;
      });

    case DictionariesActionTypes.LoadPaintColourListCompleted:
      return produce<DictionariesState>(state, newState => {
        newState.paintColourList.list = action.payload;
        newState.paintColourList.loaded = true;
      });

    case DictionariesActionTypes.LoadPaintColourListError:
      return produce<DictionariesState>(state, newState => {
        newState.paintColourList.loaded = false;
      });

    case DictionariesActionTypes.LoadEmissionClassListCompleted:
      return produce<DictionariesState>(state, newState => {
        newState.emissionClassList.list = action.payload;
        newState.emissionClassList.loaded = true;
      });

    case DictionariesActionTypes.LoadEmissionClassListError:
      return produce<DictionariesState>(state, newState => {
        newState.emissionClassList.loaded = false;
      });

    case DictionariesActionTypes.LoadCO2EfficiencyClassListCompleted:
      return produce<DictionariesState>(state, newState => {
        newState.cO2EfficiencyClassList.list = action.payload;
        newState.cO2EfficiencyClassList.loaded = true;
      });

    case DictionariesActionTypes.LoadCO2EfficiencyClassListError:
      return produce<DictionariesState>(state, newState => {
        newState.cO2EfficiencyClassList.loaded = false;
      });

    default:
      return state;
  }
}
