import { Component, Input } from '@angular/core';
import { CaseTaxDto, PriceHistoryDto } from '@vpfa/rest-api/valuation';
import { isEllipsisActive } from '@vpfa/utils';

@Component({
  selector: 'vpfa-price-history-list',
  templateUrl: './price-history-list.component.html',
  styleUrls: ['./price-history-list.component.scss'],
})
export class PriceHistoryListComponent {
  @Input() history: PriceHistoryDto[];
  @Input() caseTax: CaseTaxDto;

  ellipsisActive: boolean[] = [];

  hasFirstNameOrLastName(index: number) {
    return this.history[index]?.firstName || this.history[index]?.lastName;
  }

  returnName(index: number) {
    if (this.hasFirstNameOrLastName(index)) {
      return `${this.history[index]?.firstName} ${this.history[index]?.lastName}`;
    } else {
      return this.history[index]?.userName;
    }
  }

  isEllipsisActive(index: number) {
    const nameElement = document.getElementById('name' + index);
    this.ellipsisActive[index] = isEllipsisActive(nameElement);
  }
}
