import { Observable } from 'rxjs';

export interface FileItem {
  file: string;
  fileKey: string;
}

export const mapFileToDataUrl = ([blob, fileKey]: [Blob, string]): Observable<FileItem> =>
  new Observable(obs => {
    const reader = new FileReader();
    reader.onerror = err => obs.error(err);
    reader.onabort = err => obs.error(err);
    reader.onload = () => obs.next({ file: String(reader.result), fileKey });
    reader.onloadend = () => obs.complete();
    reader.readAsDataURL(blob);
  });
