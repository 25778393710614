import { Component, Input, TemplateRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PriceDisplayModel } from '../pricing-details-modal/pricing-details-modal.component';

@Component({
  selector: 'vpfa-pricing-details-stock',
  templateUrl: './pricing-details-stock.component.html',
  styleUrls: ['./pricing-details-stock.component.scss'],
})
export class PricingDetailsStockComponent {
  @Input() stockPrices: PriceDisplayModel[];
  @Input() stockPricesForm: UntypedFormGroup;
  @Input() errorMessages;
  @Input() priceTemplate: TemplateRef<void>;
}
