import { Validators } from '@angular/forms';
import { CustomerFormModel } from '@vpfa/dealer/customers/shared';
import { TEXT_LINE_MAX_LENGTH, TEXT_AREA_MAX_LENGTH } from '@vpfa/shared/validators';

export const getCustomerDataFormDefinition = (): { [k in keyof CustomerFormModel]: any } => {
  return {
    firstName: [null, Validators.maxLength(TEXT_LINE_MAX_LENGTH)],
    lastName: [null, [Validators.required, Validators.maxLength(TEXT_LINE_MAX_LENGTH)]],
    address: [null, Validators.maxLength(TEXT_LINE_MAX_LENGTH)],
    formOfAddress: [null, Validators.maxLength(TEXT_LINE_MAX_LENGTH)],
    company: [null, Validators.maxLength(TEXT_AREA_MAX_LENGTH)],
    department: [null, Validators.maxLength(TEXT_LINE_MAX_LENGTH)],
    position: [null, Validators.maxLength(TEXT_LINE_MAX_LENGTH)],
    street: [null, Validators.maxLength(TEXT_LINE_MAX_LENGTH)],
    postcode: [null, Validators.maxLength(TEXT_LINE_MAX_LENGTH)],
    city: [null, Validators.maxLength(TEXT_LINE_MAX_LENGTH)],
    country: [null, Validators.maxLength(TEXT_LINE_MAX_LENGTH)],
    notes: [null, Validators.maxLength(TEXT_AREA_MAX_LENGTH)],
    vatRegistered: [null, Validators.maxLength(TEXT_LINE_MAX_LENGTH)],
    vatNo: [null, Validators.maxLength(TEXT_LINE_MAX_LENGTH)],
    birthDate: [null],
    phoneNumbers: [null],
    emails: [null],
    externalId: [null, Validators.maxLength(TEXT_LINE_MAX_LENGTH)],
    ownerId: [null, [Validators.required, Validators.maxLength(TEXT_LINE_MAX_LENGTH)]]
  };
};
