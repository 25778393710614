import { produce } from 'immer';
import { UserProfileAction, UserProfileActionTypes } from './user-profile.actions';
import { ExperianCredentialsDto } from '@vpfa/rest-api/admin';

export const USER_PROFILE_FEATURE_KEY = 'userProfile';

export interface UserProfileState {
  error?: any; // last none error (if any)
  isProcessingForm: boolean;
  hasSettingsFormProcessingError: boolean;
  isChangingPassword: boolean;
  isPasswordModalOpen: boolean;
  isUserProfileUpdating: boolean;
  passwordCompatible: boolean;
  experianCredentials: ExperianCredentialsState;
}

export interface ExperianCredentialsState {
  credentials: ExperianCredentialsDto;
  processing: boolean;
}

export interface UserProfilePartialState {
  readonly [USER_PROFILE_FEATURE_KEY]: UserProfileState;
}

export const initialState: UserProfileState = {
  isProcessingForm: null,
  hasSettingsFormProcessingError: null,
  isChangingPassword: false,
  isPasswordModalOpen: false,
  isUserProfileUpdating: false,
  passwordCompatible: false,
  experianCredentials: {
    credentials: null,
    processing: null,
  },
};

export function reducer(state: UserProfileState = initialState, action: UserProfileAction): UserProfileState {
  switch (action.type) {
    case UserProfileActionTypes.UpdateUserDefaultLanguage:
    case UserProfileActionTypes.UpdateUserContentLanguage:
    case UserProfileActionTypes.UpdateUserUILanguage:
      return produce(state, newState => {
        newState.isProcessingForm = true;
      });
    case UserProfileActionTypes.UserDefaultLanguageUpdated:
    case UserProfileActionTypes.UserContentLanguageUpdated:
    case UserProfileActionTypes.UserUILanguageUpdated:
      return produce(state, newState => {
        newState.isProcessingForm = false;
      });
    case UserProfileActionTypes.UserDefaultLanguageUpdateError:
    case UserProfileActionTypes.UserContentLanguageUpdateError:
    case UserProfileActionTypes.UserUILanguageUpdateError:
      return produce(state, newState => {
        newState.isProcessingForm = false;
        newState.hasSettingsFormProcessingError = true;
      });
    case UserProfileActionTypes.ChangePassword:
      return produce(state, newState => {
        newState.isChangingPassword = true;
      });
    case UserProfileActionTypes.ChangePasswordSuccess:
      return produce(state, newState => {
        newState.isChangingPassword = false;
        newState.isPasswordModalOpen = false;
      });
    case UserProfileActionTypes.ChangePasswordError:
      return produce(state, newState => {
        newState.isChangingPassword = false;
      });
    case UserProfileActionTypes.OpenPasswordModal:
      return produce(state, newState => {
        newState.isPasswordModalOpen = true;
      });
    case UserProfileActionTypes.ClosePasswordModal:
      return produce(state, newState => {
        newState.isPasswordModalOpen = false;
        newState.passwordCompatible = false;
      });
    case UserProfileActionTypes.UpdateUserProfile:
      return produce(state, newState => {
        newState.isUserProfileUpdating = true;
      });
    case UserProfileActionTypes.UpdateUserProfileSuccess:
    case UserProfileActionTypes.UpdateUserProfileError:
      return produce(state, newState => {
        newState.isUserProfileUpdating = false;
      });
    case UserProfileActionTypes.PasswordCompatibility:
      return produce(state, newState => {
        newState.passwordCompatible = action.payload;
      });
    case UserProfileActionTypes.LoadExperianCredentials:
      return produce<UserProfileState>(state, newState => {
        newState.experianCredentials.processing = true;
      });
    case UserProfileActionTypes.LoadExperianCredentialsSuccess:
      return produce<UserProfileState>(state, newState => {
        newState.experianCredentials.processing = false;
        newState.experianCredentials.credentials = action.payload;
      });
    case UserProfileActionTypes.LoadExperianCredentialsError:
      return produce<UserProfileState>(state, newState => {
        newState.experianCredentials.processing = false;
      });
    case UserProfileActionTypes.UpdateExperianCredentials:
      return produce<UserProfileState>(state, newState => {
        newState.experianCredentials.processing = true;
      });
    case UserProfileActionTypes.UpdateExperianCredentialsSuccess:
    case UserProfileActionTypes.UpdateExperianCredentialsError:
      return produce<UserProfileState>(state, newState => {
        newState.experianCredentials.processing = false;
      });
  }
  return state;
}
