import { PagedApiResponse } from '@vpfa/shared/data-table-backend-pagination';
import { CustomerListItem } from '../interfaces';
import { CustomerListItemDto, CustomerListItemDtoPageWithFiltersResult } from '@vpfa/rest-api/valuation';

export const mapCustomerListResponse = (
  customerList: CustomerListItemDtoPageWithFiltersResult,
  noValuePlaceholder: string
): PagedApiResponse<CustomerListItem> =>
  <PagedApiResponse<CustomerListItem>>{
    ...customerList,
    data: mapCustomerListData(customerList.data),
    filters: mapCustomerListFilters(customerList.filters, noValuePlaceholder),
  };

const mapCustomerListData = (list: CustomerListItemDto[]): CustomerListItem[] => {
  return list.map(
    item =>
      <CustomerListItem>{
        ...item,
        concatPhones: item.phoneNumbers?.length ? item.phoneNumbers.join(', ') : null,
        concatEmails: item.emails?.length ? item.emails.join(', ') : null,
      }
  );
};

const mapCustomerListFilters = (
  filters: { [key: string]: any },
  noValuePlaceholder: string
): { [key: string]: any } => {
  if (!Object.keys(filters).length) {
    return { ...filters };
  }

  Object.keys(filters).forEach(filter => {
    if (filters[filter].includes('null')) {
      let currentFilter: string[] = filters[filter];
      const nullValueIndex = currentFilter?.indexOf('null');

      if (nullValueIndex !== -1) {
        currentFilter[nullValueIndex] = noValuePlaceholder;
      }
    }
  });

  return filters;
};
