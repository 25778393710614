<vpfa-field-container [errorMessages]="errorMessages" [control]="getFormControl()" [displayErrors]="false">
  <textarea
    #textarea
    nzSize="small"
    nz-input
    (blur)="onBlur()"
    (focus)="onFocus()"
    [placeholder]="placeholder"
    [formControl]="getFormControl()"
  ></textarea>
</vpfa-field-container>
