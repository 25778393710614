import { CaseImageWithOrderDto, ImageType } from '@vpfa/rest-api/valuation';
import { produce } from 'immer';
import { orderBy } from 'lodash';
import { GalleryManagerAction, GalleryManagerActionTypes } from './gallery-manager.actions';

export const GALLERY_MANAGER_FEATURE_KEY = 'gallery-manager';

interface UploadQueueProgress {
  size: number;
  currentItemIndex: number;
}

export interface GalleryImageOrderListState {
  totalSize: number;
  items: Array<CaseImageWithOrderDto>;
  loading: boolean;
  loaded: boolean;
  hasError: boolean;
}

export interface PromotionalImagesState {
  isProcessing: boolean;
  hasError: boolean;
}

export interface GalleryManagerState {
  caseId: string;
  isUploading: boolean;
  isLoading: boolean;
  loadingCounter: number;
  uploadQueue: UploadQueueProgress;
  galleryImageOrderList: GalleryImageOrderListState;
  imagePreview: {
    open: boolean;
  };
  promotionalImages: PromotionalImagesState;
}

export interface GalleryManagerPartialState {
  readonly [GALLERY_MANAGER_FEATURE_KEY]: GalleryManagerState;
}

export const initialState: GalleryManagerState = {
  caseId: null,
  isUploading: false,
  isLoading: false,
  loadingCounter: 0,
  imagePreview: {
    open: false,
  },
  uploadQueue: { currentItemIndex: null, size: null },
  galleryImageOrderList: { totalSize: null, loaded: false, loading: false, items: [], hasError: false },
  promotionalImages: { isProcessing: false, hasError: false },
};

export function reducer(state: GalleryManagerState = initialState, action: GalleryManagerAction): GalleryManagerState {
  switch (action.type) {
    case GalleryManagerActionTypes.AddImages:
      return produce(state, (newState: GalleryManagerState) => {
        newState.uploadQueue = {
          currentItemIndex: 0,
          size: action.payload.images ? action.payload.images.length : null,
        };
      });
    case GalleryManagerActionTypes.AddImage:
      return produce(state, (newState: GalleryManagerState) => {
        newState.isUploading = true;
      });
    case GalleryManagerActionTypes.ImageAdded:
      return produce(state, (newState: GalleryManagerState) => {
        newState.uploadQueue.currentItemIndex = state.uploadQueue.currentItemIndex + 1;
        if (newState.uploadQueue.currentItemIndex === newState.uploadQueue.size) {
          newState.isUploading = false;
        }
      });
    case GalleryManagerActionTypes.ImageAddError:
      return produce(state, (newState: GalleryManagerState) => {
        newState.uploadQueue.currentItemIndex = state.uploadQueue.currentItemIndex + 1;
        if (newState.uploadQueue.currentItemIndex === newState.uploadQueue.size) {
          newState.isUploading = false;
        }
      });
    case GalleryManagerActionTypes.ImageDeleted:
      return produce(state, (newState: GalleryManagerState) => {
        newState.galleryImageOrderList.items = state.galleryImageOrderList.items.filter(
          image => image.imageKey !== action.payload.fileKey && image.imageKey !== ''
        );
      });
    case GalleryManagerActionTypes.GalleryQueueReset:
      return produce(state, (newState: GalleryManagerState) => {
        newState.uploadQueue = {
          currentItemIndex: 0,
          size: null,
        };
      });

    case GalleryManagerActionTypes.LoadGalleryImageOrderList:
      return produce(state, (newState: GalleryManagerState) => {
        newState.galleryImageOrderList.loaded = false;
        newState.galleryImageOrderList.loading = true;
      });

    case GalleryManagerActionTypes.ReorderImageList:
      return produce(state, (newState: GalleryManagerState) => {
        newState.galleryImageOrderList.items = action.payload.imagesOrder.map(el => ({
          size: el['size'],
          imageType: el['imageType'],
          order: el.order,
          imageKey: el.key,
        }));
      });

    case GalleryManagerActionTypes.ImageListReordered:
      return produce(state, (newState: GalleryManagerState) => {
        newState.galleryImageOrderList.loaded = true;
        newState.galleryImageOrderList.loading = false;
      });

    case GalleryManagerActionTypes.GalleryImageOrderListLoaded:
      return produce(state, (newState: GalleryManagerState) => {
        newState.galleryImageOrderList = {
          hasError: false,
          loading: false,
          loaded: true,
          items: orderBy(action.payload, ['order']),
          totalSize: action.payload
            .filter(el => el.imageType === ImageType.Uploaded)
            .reduce((previousValue, currentValue) => previousValue + Number(currentValue['size']), 0),
        };
      });

    case GalleryManagerActionTypes.PublicGalleryImageOrderListLoad:
    case GalleryManagerActionTypes.ResellerGalleryImageOrderListLoad:
      return produce(state, (newState: GalleryManagerState) => {
        newState.galleryImageOrderList.loaded = false;
        newState.galleryImageOrderList.loading = true;
      });
    case GalleryManagerActionTypes.PublicGalleryImageOrderListLoaded:
    case GalleryManagerActionTypes.ResellerGalleryImageOrderListLoaded:
      return produce(state, (newState: GalleryManagerState) => {
        newState.galleryImageOrderList = {
          hasError: false,
          loading: false,
          loaded: true,
          totalSize: null,
          items: orderBy(action.payload, ['order']),
        };
      });
    case GalleryManagerActionTypes.PublicGalleryImageOrderListLoadError:
    case GalleryManagerActionTypes.ResellerGalleryImageOrderListLoadError:
      return produce(state, (newState: GalleryManagerState) => {
        newState.galleryImageOrderList.loaded = false;
        newState.galleryImageOrderList.loading = false;
      });

    case GalleryManagerActionTypes.ImagePreviewOpen:
      return produce(state, (newState: GalleryManagerState) => {
        newState.imagePreview.open = true;
      });
    case GalleryManagerActionTypes.ImagePreviewClose:
      return produce(state, (newState: GalleryManagerState) => {
        newState.imagePreview.open = false;
      });

    case GalleryManagerActionTypes.ImageReplace:
      return produce(state, (newState: GalleryManagerState) => {
        newState.galleryImageOrderList.loading = true;
      });
    case GalleryManagerActionTypes.ImageReplaced:
      return produce(state, (newState: GalleryManagerState) => {
        newState.galleryImageOrderList.loading = false;
        newState.imagePreview.open = false;
      });
    case GalleryManagerActionTypes.ImageReplaceError:
      return produce(state, (newState: GalleryManagerState) => {
        newState.galleryImageOrderList.loading = false;
      });

    case GalleryManagerActionTypes.GalleryImageOrderListLoadError:
      return produce(state, (newState: GalleryManagerState) => {
        newState.galleryImageOrderList.hasError = true;
        newState.galleryImageOrderList.loaded = false;
        newState.galleryImageOrderList.loading = false;
      });

    case GalleryManagerActionTypes.UpdatePromotionalImages:
      return produce(state, (newState: GalleryManagerState) => {
        newState.promotionalImages.hasError = false;
        newState.promotionalImages.isProcessing = true;
      });

    case GalleryManagerActionTypes.PromotionalImagesUpdated:
      return produce(state, (newState: GalleryManagerState) => {
        newState.promotionalImages.isProcessing = false;
        newState.promotionalImages.hasError = false;
      });

    case GalleryManagerActionTypes.PromotionalImagesUpdateError:
      return produce(state, (newState: GalleryManagerState) => {
        newState.promotionalImages.hasError = true;
        newState.promotionalImages.isProcessing = false;
      });

    case GalleryManagerActionTypes.RemoveItemsFromImageListByKeyList:
      return produce(state, (newState: GalleryManagerState) => {
        newState.promotionalImages.isProcessing = true;
        newState.promotionalImages.isProcessing = false;
      });

    case GalleryManagerActionTypes.SyncImagesTotalSize:
      return produce(state, (newState: GalleryManagerState) => {
        newState.galleryImageOrderList.totalSize = action.payload
          .filter(el => el.imageType === ImageType.Uploaded)
          .reduce((previousValue, currentValue) => previousValue + Number(currentValue['size']), 0);
      });

    case GalleryManagerActionTypes.GalleryReset:
      return { ...initialState };
  }

  return state;
}
