/**
 * Public SSM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0-private
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { CustomerDto } from '../model/customerDto';
import { CustomerListItemDto } from '../model/customerListItemDto';
import { CustomerListItemDtoPageWithFiltersResult } from '../model/customerListItemDtoPageWithFiltersResult';
import { Filter } from '../model/filter';
import { Order } from '../model/order';
import { OwnerVehicleCaseDto } from '../model/ownerVehicleCaseDto';
import { OwnerVehicleRecentOfferDto } from '../model/ownerVehicleRecentOfferDto';
import { VPError } from '../model/vPError';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CustomerViewService {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * AmsAction:GET/api/CustomerView/{customerId} AmsResource:{country}/{business}/customer/{customerId} 
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomer(customerId: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerDto>;
    public getCustomer(customerId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerDto>>;
    public getCustomer(customerId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerDto>>;
    public getCustomer(customerId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getCustomer.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CustomerDto>(`${this.basePath}/api/CustomerView/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CustomerView/List AmsResource:{country}/{business}/customer/_* 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getList(observe?: 'body', reportProgress?: boolean): Observable<Array<CustomerListItemDto>>;
    public getList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CustomerListItemDto>>>;
    public getList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CustomerListItemDto>>>;
    public getList(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CustomerListItemDto>>(`${this.basePath}/api/CustomerView/List`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CustomerView/List/v2 AmsResource:{country}/{business}/customer/_* 
     * @param filters 
     * @param order 
     * @param pageIndex 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListAsync(filters?: Array<Filter>, order?: Array<Order>, pageIndex?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<CustomerListItemDtoPageWithFiltersResult>;
    public getListAsync(filters?: Array<Filter>, order?: Array<Order>, pageIndex?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerListItemDtoPageWithFiltersResult>>;
    public getListAsync(filters?: Array<Filter>, order?: Array<Order>, pageIndex?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerListItemDtoPageWithFiltersResult>>;
    public getListAsync(filters?: Array<Filter>, order?: Array<Order>, pageIndex?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters) {

          filters.forEach((elementItem: any, elementIndex) => {

            const isQuerySearchOrOrderParams =
                elementItem.values !== undefined ||
                elementItem.from !== undefined ||
                elementItem.to !== undefined ||
                elementItem.value !== undefined ||
                elementItem.descending !== undefined ||
                elementItem.isNull !== undefined;

            if (isQuerySearchOrOrderParams) {
                queryParameters = queryParameters.set(`Filters[${elementIndex}].fieldName`, elementItem.fieldName);
        
                if (elementItem.values && Array.isArray(elementItem.values)) {
                    elementItem.values.forEach((selectedDropdownValue, index) => {
                        queryParameters = queryParameters.set(
                        `Filters[${elementIndex}].values[${index}]`,
                        selectedDropdownValue.toString()
                        );
                    });
                }
                if (elementItem.from !== undefined && elementItem.from !== null) {
                    queryParameters = queryParameters.set(`Filters[${elementIndex}].from`, elementItem.from);
                }
                if (elementItem.to !== undefined && elementItem.to !== null) {
                    queryParameters = queryParameters.set(`Filters[${elementIndex}].to`, elementItem.to);
                }
                if (elementItem.value !== undefined  && elementItem.value !== null) {
                    queryParameters = queryParameters.set(`Filters[${elementIndex}].value`, elementItem.value as string);
                }
                if (elementItem.descending !== undefined && elementItem.descending !== null) {
                    queryParameters = queryParameters.set(`Filters[${elementIndex}].descending`, Boolean(elementItem.descending).toString());
                }
                if (elementItem.isNull !== undefined && elementItem.isNull !== null) {
                    queryParameters = queryParameters.set(`Filters[${elementIndex}].isNull`, Boolean(elementItem.isNull).toString());
                }
            } else {
                queryParameters = queryParameters.append('Filters', <any>elementItem);
            }
            
          });



        }
        if (order) {

          order.forEach((elementItem: any, elementIndex) => {

            const isQuerySearchOrOrderParams =
                elementItem.values !== undefined ||
                elementItem.from !== undefined ||
                elementItem.to !== undefined ||
                elementItem.value !== undefined ||
                elementItem.descending !== undefined ||
                elementItem.isNull !== undefined;

            if (isQuerySearchOrOrderParams) {
                queryParameters = queryParameters.set(`Order[${elementIndex}].fieldName`, elementItem.fieldName);
        
                if (elementItem.values && Array.isArray(elementItem.values)) {
                    elementItem.values.forEach((selectedDropdownValue, index) => {
                        queryParameters = queryParameters.set(
                        `Order[${elementIndex}].values[${index}]`,
                        selectedDropdownValue.toString()
                        );
                    });
                }
                if (elementItem.from !== undefined && elementItem.from !== null) {
                    queryParameters = queryParameters.set(`Order[${elementIndex}].from`, elementItem.from);
                }
                if (elementItem.to !== undefined && elementItem.to !== null) {
                    queryParameters = queryParameters.set(`Order[${elementIndex}].to`, elementItem.to);
                }
                if (elementItem.value !== undefined  && elementItem.value !== null) {
                    queryParameters = queryParameters.set(`Order[${elementIndex}].value`, elementItem.value as string);
                }
                if (elementItem.descending !== undefined && elementItem.descending !== null) {
                    queryParameters = queryParameters.set(`Order[${elementIndex}].descending`, Boolean(elementItem.descending).toString());
                }
                if (elementItem.isNull !== undefined && elementItem.isNull !== null) {
                    queryParameters = queryParameters.set(`Order[${elementIndex}].isNull`, Boolean(elementItem.isNull).toString());
                }
            } else {
                queryParameters = queryParameters.append('Order', <any>elementItem);
            }
            
          });



        }
        if (pageIndex !== undefined && pageIndex !== null) {
          queryParameters = queryParameters.set('PageIndex', <any>pageIndex);
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CustomerListItemDtoPageWithFiltersResult>(`${this.basePath}/api/CustomerView/List/v2`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CustomerView/{customerId}/OwnedVehicles AmsResource:{country}/{business}/customer/{customerId} 
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOwnedVehicles(customerId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<OwnerVehicleCaseDto>>;
    public getOwnedVehicles(customerId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OwnerVehicleCaseDto>>>;
    public getOwnedVehicles(customerId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OwnerVehicleCaseDto>>>;
    public getOwnedVehicles(customerId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getOwnedVehicles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<OwnerVehicleCaseDto>>(`${this.basePath}/api/CustomerView/${encodeURIComponent(String(customerId))}/OwnedVehicles`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CustomerView/{customerId}/RecentOffers AmsResource:{country}/{business}/customer/{customerId} 
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRecentOffers(customerId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<OwnerVehicleRecentOfferDto>>;
    public getRecentOffers(customerId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OwnerVehicleRecentOfferDto>>>;
    public getRecentOffers(customerId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OwnerVehicleRecentOfferDto>>>;
    public getRecentOffers(customerId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getRecentOffers.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<OwnerVehicleRecentOfferDto>>(`${this.basePath}/api/CustomerView/${encodeURIComponent(String(customerId))}/RecentOffers`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CustomerView/{customerId}/HasRelatedCases AmsResource:{country}/{business}/customer/{customerId} 
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public isOwnerWithReferences(customerId: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public isOwnerWithReferences(customerId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public isOwnerWithReferences(customerId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public isOwnerWithReferences(customerId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling isOwnerWithReferences.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<boolean>(`${this.basePath}/api/CustomerView/${encodeURIComponent(String(customerId))}/HasRelatedCases`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/CustomerView/countryCode/{countryCode}/ListFormOfAddresses AmsResource:{country}/{business}/customer/{countryCode} 
     * @param countryCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listFormOfAddresses(countryCode: string, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public listFormOfAddresses(countryCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public listFormOfAddresses(countryCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public listFormOfAddresses(countryCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (countryCode === null || countryCode === undefined) {
            throw new Error('Required parameter countryCode was null or undefined when calling listFormOfAddresses.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/api/CustomerView/countryCode/${encodeURIComponent(String(countryCode))}/ListFormOfAddresses`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
