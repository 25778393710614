import { AbstractControl } from '@angular/forms';
import { F_PRICE_MAX } from '@vpfa/shared/validators';

export const costValidator = (control: AbstractControl) => {
  if (control.parent) {
    const cost = control.value;
    const costValid = cost !== null && cost >= 0 && cost <= F_PRICE_MAX;
    const damage = control.parent.get('selectedDamageTypeId').value;

    if ((!costValid && damage) || cost > F_PRICE_MAX) {
      return { costInvalid: true };
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const damageValidator = (control: AbstractControl) => {
  if (control.parent) {
    const costValue = control.parent.get('cost').value;
    const costValid = costValue !== null;
    const damage = control.value;

    if (costValid && !damage) {
      return { damageInvalid: true };
    } else {
      return null;
    }
  } else {
    return null;
  }
};
