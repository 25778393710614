import { Component, Input, Output, EventEmitter } from '@angular/core';
import { VehicleDto } from '@vpfa/rest-api/identification';
import { BasicNotificationsService } from '@vpfa/shared/notifications';
import { isNil } from 'lodash';

@Component({
  selector: 'vpfa-mobile-vehicle-list-cell',
  templateUrl: './mobile-vehicle-list-cell.component.html',
  styleUrls: ['./mobile-vehicle-list-cell.component.scss'],
})
export class MobileVehicleListCellComponent {
  private _vehicle: VehicleDto;
  isDoorsVisible = true;
  isPowerVisible = true;

  @Input() set vehicle(data: VehicleDto) {
    this._vehicle = data;
    this.isDoorsVisible = !isNil(data.numberDoors);
    this.isPowerVisible = !isNil(data.powerInKW.value) || !isNil(data.powerInHP.value);
  }
  get vehicle() {
    return this._vehicle;
  }
  @Input() prodStart: string;
  @Input() prodEnd: string;
  @Input() isFormInvalid = false;
  @Input() disableSpecialButton = false;
  @Input() showPower = false;
  @Input() loadingTechSpec = false;
  @Input() loading = false;

  displayErrors = false;
  displayTechSpecErrors = false;

  @Output() openTechnicalSpec = new EventEmitter<void>();
  @Output() openDetails = new EventEmitter<void>();

  constructor(private notificationService: BasicNotificationsService) {}

  cellClick() {
    if (!this.isFormInvalid) {
      this.openDetails.emit();
    } else {
      this.notificationService.warning('identification.vehicles.invalidForm');
    }
  }

  techSpecClick() {
    if (!this.disableSpecialButton) {
      this.openTechnicalSpec.emit();
    } else {
      this.notificationService.warning('identification.vehicles.requiredDateError');
    }
  }
}
