import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobilePageTitleComponent } from './components/mobile-page-title/mobile-page-title.component';
import { MobileActionButtonComponent } from './components/mobile-action-button/mobile-action-button.component';
import { UiKitModule } from '@vpfa/ui-kit';
import { MobileDividerComponent } from './components/mobile-divider/mobile-divider.component';
import { MobileTotalSummaryComponent } from './components/mobile-total-summary/mobile-total-summary.component';
import { MobileTotalSummaryItemComponent } from './components/mobile-total-summary-item/mobile-total-summary-item.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MobileFilterButtonComponent } from './components/mobile-filter-button/mobile-filter-button.component';
import { MobileFiltersComponent } from './components/mobile-filters/mobile-filters.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { MobileSubtitleComponent } from './components/mobile-subtitle/mobile-subtitle.component';
import { UiKitSkewLineModule } from '@vpfa/ui-kit/skew-line';
import { SharedMobileViewModule } from '@vpfa/shared/mobile-view';
import { MobileClickableComponent } from './components/mobile-clickable/mobile-clickable.component';
import { UiKitPriceWithIconModule } from '@vpfa/ui-kit/price-with-icon';

@NgModule({
  imports: [
    CommonModule,
    UiKitModule,
    NzDividerModule,
    TranslateModule,
    FlexLayoutModule,
    NzButtonModule,
    UiKitSkewLineModule,
    SharedMobileViewModule,
    UiKitPriceWithIconModule
  ],
  declarations: [
    MobilePageTitleComponent,
    MobileActionButtonComponent,
    MobileDividerComponent,
    MobileTotalSummaryComponent,
    MobileTotalSummaryItemComponent,
    MobileFilterButtonComponent,
    MobileFiltersComponent,
    MobileSubtitleComponent,
    MobileClickableComponent,
  ],
  exports: [
    MobilePageTitleComponent,
    MobileActionButtonComponent,
    MobileDividerComponent,
    MobileTotalSummaryComponent,
    MobileTotalSummaryItemComponent,
    MobileFilterButtonComponent,
    MobileFiltersComponent,
    MobileSubtitleComponent,
    MobileClickableComponent,
  ],
})
export class UiKitMobileModule {}
