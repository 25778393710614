import { Directive } from '@angular/core';
import { LayoutGapDirective } from '@ngbracket/ngx-layout';

const selector = `[fxLayoutGap.gt-mv], [fxLayoutGap.lt-mv]`;
const inputs = ['fxLayoutGap.gt-mv', 'fxLayoutGap.lt-mv'];

/**
 * @deprecated to be removed because FlexLayout is obsolete - see migration-from-flex-layout.md for details
 */
@Directive({ selector, inputs })
export class MobileLayoutGapDirective extends LayoutGapDirective {
  protected inputs = inputs;
}
