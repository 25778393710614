import { ConditionAdjustmentsInitialState } from './adjustments/adjustments.reducer';
import { CasesState } from './cases.reducer';
import { PROVENANCE_CHECK_FEATURE_KEY } from './provenance-check/provenance-check.reducer';

export const retailMarketInitState = {
  averageTimeToSell: null,
  averageMileage: null,
  liveRetailPrice: null,
  desirability: null,
  sellingSpotPrice: null,
  sellingSpotPriceDiscount: null,
};

export const initialState: CasesState = {
  isProcessingCaseOwnersList: false,
  isProcessingPreviousCaseOwnersList: false,
  isProcessingProspectCaseOwnersList: false,
  list: [],
  loaded: false,
  loading: false,
  activeCaseId: null,
  activeCaseData: null,
  activeCaseVehicleEngineFuel: null,
  activeCaseNotes: null,
  activeCaseQrCode: null,
  activeCaseBlackBoxPricingDetails: {
    loading: false,
    data: null,
  },
  purchase: {
    processing: false,
    open: false,
  },
  publishToResellers: { open: false, hasProcessingError: false, isProcessing: false, isProcessingCompleted: false },
  isReservingVehicle: false,
  isUpdatingVin: false,
  isUpdatingVinError: false,
  isUpdatingMatriculationNumber: false,
  isUpdatingMatriculationNumberError: false,
  isUpdatingKba: false,
  isUpdatingMileage: false,
  isUpdatingRegDate: false,
  isUpdatingValuationDate: false,
  isUpdateValuationDateModalOpen: false,
  lightDamageEstimator: {
    areas: [],
    loading: false,
    saving: false,
    saved: true,
  },
  isUpdatingPublicSite: false,
  isProcessingForm: null,
  hasFormProcessingError: null,
  isReserveModalOpen: false,
  isReservationViewModalOpen: false,
  isCancellingReservation: false,
  isUpdatingValuationUniqueData: false,
  isUniqueDataModalOpen: false,
  isUpdatingTechnicalData: false,
  isTechnicalDataModalOpen: false,
  retailMarketDetails: retailMarketInitState,
  documents: {
    items: [],
    loaded: true,
    loading: null,
    totalSize: null,
    hasFormProcessingError: null,
    isProcessingForm: null,
    upload: {
      completed: null,
      currentIndex: null,
      totalCount: null,
      processing: null,
    },
  },
  isReturnToStockModalOpen: false,
  isReturningVehicleToStock: false,
  moveToStock: {
    processing: false,
    open: false,
  },
  stockOfferModal: {
    processing: false,
    open: false,
    usedCustomerId: null,
  },
  quoteModal: {
    processing: false,
    open: false,
  },
  sellModal: {
    processing: false,
    open: false,
  },
  emissionStickers: { items: {}, loaded: false, loading: false, hasError: false },
  isUpdatingPricingDetails: false,
  isPricingDetailsModalOpen: false,
  isUpdatingPlateNo: false,
  isUpdatingPlateNoError: false,
  resendCaseLatestQuote: {
    isProcessing: false,
  },
  resendCaseLatestOffer: {
    isProcessing: false,
  },

  fuelSubtypes: {
    list: null,
    isLoading: false,
    isProcessing: false,
  },
  emissionInfo: {
    isLoading: false,
    item: null,
  },
  roadTax: {
    item: null,
    loaded: false,
  },
  isValuating: false,
  valuationAttemptFailed: false,
  returnReport: {
    report: null,
    open: false,
    processing: false,
  },
  isUpdatingGalleryFromTwinner: false,
  isUpdatedGalleryFromTwinner: false,
  activeCaseEquipmentsValid: true,
  [PROVENANCE_CHECK_FEATURE_KEY]: undefined,
  conditionAdjustments: ConditionAdjustmentsInitialState,
  isUpdatingBuildabilityData: false,
  canUserMakeValuation: false
};
