import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromImageFrame from './+state/image-frame.reducer';
import { ImageFrameEffects } from './+state/image-frame.effects';
import { ImageFrameFacade } from './+state/image-frame.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromImageFrame.IMAGE_FRAME_FEATURE_KEY, fromImageFrame.reducer),
    EffectsModule.forFeature([ImageFrameEffects])
  ],
  providers: [ImageFrameFacade]
})
export class SharedImageFramesDataModule {}
