import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { CaseService, CaseSummaryDto, CaseViewService } from '@vpfa/rest-api/valuation';
import { PermissionsFacade } from '@vpfa/permissions/data';
import { CasesFacade } from '@vpfa/dealer/case/data';

@Injectable()
export class CaseResolverService  {
  constructor(
    private caseViewService: CaseViewService,
    private caseService: CaseService,
    private caseFacade: CasesFacade,
    private permissionsFacade: PermissionsFacade,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const caseId = route.paramMap.get('caseId');

    this.caseFacade.resetCaseLoadedStatus();
    return this.caseService.updateValuation({ aggregateRootId: caseId, force: false }).pipe(
      // INFO: Case should be loaded no matter the response.
      // This is the same logic as in updateCaseValuation$ and afterValuationRequestCompletedLoadCase$ effects.
      switchMap(() => this.loadCase(caseId)),
      catchError(() => this.loadCase(caseId))
    );
  }

  private loadCase(caseId: string) {
    return this.caseViewService.getCase(caseId).pipe(
      tap(res => this.caseFacade.loadedCase(res)),
      catchError(error => {
        this.caseFacade.loadCaseFailed(error);
        this.router.navigate(['valuations', 'not-found']);
        return EMPTY;
      })
    );
  }
}
