import { BranchListItemDto } from '@vpfa/rest-api/admin';
import { SelectOption } from '@vpfa/ui-kit';

export function branchListDto2Options(branchList: BranchListItemDto[]): SelectOption[]{
   return  branchList.map(branchItem => {
        return {
          name: branchItem.branchName,
          value: branchItem.branchId
        };
      });
}