import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer as userProfileReducer, USER_PROFILE_FEATURE_KEY } from './+state/user-profile.reducer';
import { UserProfileEffects } from './+state/user-profile.effects';
import { UserProfileFacade } from './+state/user-profile.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(USER_PROFILE_FEATURE_KEY, userProfileReducer),
    EffectsModule.forFeature([UserProfileEffects]),
  ],
  providers: [UserProfileFacade],
})
export class SharedUserProfileDataModule {}
