import { Pipe, PipeTransform } from '@angular/core';
import { I18RangeValueDto } from '@vpfa/rest-api/valuation';
import { getI18RangeValue } from '@vpfa/utils';

@Pipe({ name: 'i18nTexts' })
export class I18nTextsPipe implements PipeTransform {
  transform(value: I18RangeValueDto): string | null {
    return getI18RangeValue(value);
  }
}
