import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { collapseExpand } from '@vpfa/utils';

@Component({
  selector: 'vpfa-repair-and-maintenance-container',
  templateUrl: './repair-and-maintenance-container.component.html',
  styleUrls: ['./repair-and-maintenance-container.component.scss'],
  animations: [collapseExpand()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RepairAndMaintenanceContainerComponent {
  @Input() caseId: string;
  @Input() name: string = 'caseValuationDetails.repairAndMaintenance';
  @Input() toggleLabel: string;
  @Input() isLoading: boolean;
  @Input() notAvailable: boolean;
  @Input() majorTotal: number;
  @Input() minorTotal: number;
  @Input() hideRepairEstimateCost = false;
  @Input() isVinEreDataLoading: boolean;
}
