import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ScrollToTopService } from '@vpfa/shared/mobile-view';

@Component({
  selector: 'vpfa-mobile-filters',
  templateUrl: './mobile-filters.component.html',
  styleUrls: ['./mobile-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileFiltersComponent {
  @Input() abstractCtrl: AbstractControl;
  @Input() defaultValue: any;
  @Output() apply = new EventEmitter();

  constructor(private scrollToTopService: ScrollToTopService){ }

  onApplyClick() {
    this.applyEmit();
  }

  onResetClick() {
    this.abstractCtrl.reset(this.defaultValue);
    this.applyEmit();
  }

  applyEmit(){
    this.apply.emit();
    this.scrollToTopService.scrollToTop();
  }
}
