/**
 * Dealer Network AdminService API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { BranchDto } from '../model/branchDto';
import { BranchListItemDto } from '../model/branchListItemDto';
import { BranchPublicParamsDto } from '../model/branchPublicParamsDto';
import { VPError } from '../model/vPError';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class BranchViewService {

    protected basePath = '/';
    protected defaultHeaders = new HttpHeaders();
    protected configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Lazy check AmsAction:GET/api/BranchView/{branchId} AmsResource:{country}/{business}/{branch}/_* 
     * @param branchId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public get(branchId: string, observe?: 'body', reportProgress?: boolean): Observable<BranchDto>;
    public get(branchId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BranchDto>>;
    public get(branchId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BranchDto>>;
    public get(branchId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (branchId === null || branchId === undefined) {
            throw new Error('Required parameter branchId was null or undefined when calling get.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<BranchDto>(`${this.basePath}/api/BranchView/${encodeURIComponent(String(branchId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * AmsAction:GET/api/BranchView/admin/List AmsResource:* 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAdminList(observe?: 'body', reportProgress?: boolean): Observable<Array<BranchListItemDto>>;
    public getAdminList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BranchListItemDto>>>;
    public getAdminList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BranchListItemDto>>>;
    public getAdminList(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<BranchListItemDto>>(`${this.basePath}/api/BranchView/admin/List`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:GET/api/BranchView/admin/{businessId}/List AmsResource:{country}/{business}/_* 
     * @param businessId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBranchListForBusiness(businessId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<BranchListItemDto>>;
    public getBranchListForBusiness(businessId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BranchListItemDto>>>;
    public getBranchListForBusiness(businessId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BranchListItemDto>>>;
    public getBranchListForBusiness(businessId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling getBranchListForBusiness.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<BranchListItemDto>>(`${this.basePath}/api/BranchView/admin/${encodeURIComponent(String(businessId))}/List`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:GET/api/BranchView/admin/{businessId}/ListForDealerNetwork AmsResource:{country}/{business}/_* 
     * @param businessId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBranchesListForDealerNetwork(businessId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<BranchListItemDto>>;
    public getBranchesListForDealerNetwork(businessId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BranchListItemDto>>>;
    public getBranchesListForDealerNetwork(businessId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BranchListItemDto>>>;
    public getBranchesListForDealerNetwork(businessId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (businessId === null || businessId === undefined) {
            throw new Error('Required parameter businessId was null or undefined when calling getBranchesListForDealerNetwork.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<BranchListItemDto>>(`${this.basePath}/api/BranchView/admin/${encodeURIComponent(String(businessId))}/ListForDealerNetwork`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Lazy check AmsAction:GET/api/BranchView/List AmsResource:{country}/{business}/_* 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getList(observe?: 'body', reportProgress?: boolean): Observable<Array<BranchListItemDto>>;
    public getList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BranchListItemDto>>>;
    public getList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BranchListItemDto>>>;
    public getList(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<BranchListItemDto>>(`${this.basePath}/api/BranchView/List`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param branchId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublic(branchId: string, observe?: 'body', reportProgress?: boolean): Observable<BranchDto>;
    public getPublic(branchId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BranchDto>>;
    public getPublic(branchId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BranchDto>>;
    public getPublic(branchId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (branchId === null || branchId === undefined) {
            throw new Error('Required parameter branchId was null or undefined when calling getPublic.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<BranchDto>(`${this.basePath}/api/BranchView/public/${encodeURIComponent(String(branchId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param branchId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublicBranchParamsById(branchId: string, observe?: 'body', reportProgress?: boolean): Observable<BranchPublicParamsDto>;
    public getPublicBranchParamsById(branchId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BranchPublicParamsDto>>;
    public getPublicBranchParamsById(branchId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BranchPublicParamsDto>>;
    public getPublicBranchParamsById(branchId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (branchId === null || branchId === undefined) {
            throw new Error('Required parameter branchId was null or undefined when calling getPublicBranchParamsById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<BranchPublicParamsDto>(`${this.basePath}/api/BranchView/public/publicParams/${encodeURIComponent(String(branchId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param businessUrlPart 
     * @param branchUrlPart 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublicByUrlParts(businessUrlPart: string, branchUrlPart: string, observe?: 'body', reportProgress?: boolean): Observable<BranchDto>;
    public getPublicByUrlParts(businessUrlPart: string, branchUrlPart: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BranchDto>>;
    public getPublicByUrlParts(businessUrlPart: string, branchUrlPart: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BranchDto>>;
    public getPublicByUrlParts(businessUrlPart: string, branchUrlPart: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (businessUrlPart === null || businessUrlPart === undefined) {
            throw new Error('Required parameter businessUrlPart was null or undefined when calling getPublicByUrlParts.');
        }

        if (branchUrlPart === null || branchUrlPart === undefined) {
            throw new Error('Required parameter branchUrlPart was null or undefined when calling getPublicByUrlParts.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<BranchDto>(`${this.basePath}/api/BranchView/public/${encodeURIComponent(String(businessUrlPart))}/${encodeURIComponent(String(branchUrlPart))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
