import { Component } from '@angular/core';
import { DateTime } from 'luxon';
import { SelectMode } from '@vpfa/ui-kit';
import { isNil } from 'lodash';

export function dateComparator(filterLocalDateAtMidnight: Date, cellValue: string) {
  const cellDate = DateTime.fromISO(cellValue.split('T')[0]); // get date without time
  const filterDate = DateTime.fromJSDate(filterLocalDateAtMidnight);

  if (filterDate.equals(cellDate)) {
    return 0;
  }

  if (filterDate < cellDate) {
    return -1;
  }

  if (filterDate > cellDate) {
    return 1;
  }

  console.error('Date comparison failed');
}

@Component({
  selector: 'vpfa-datepicker-floating-filter',
  templateUrl: './datepicker-floating-filter.component.html',
  styleUrls: ['./datepicker-floating-filter.component.scss'],
})
export class DatepickerFloatingFilterComponent {
  params: any;
  model: Date;
  mode: SelectMode = SelectMode.multiple;
  size: 'large' | 'small' | 'default' = 'small';
  previousFilterSelection: Date;
  keepSelectedFilters = false;

  agInit(params: any): void {
    this.params = params;
    this.keepSelectedFilters = params?.filterParams?.newRowsAction === 'keep';
  }

  onDateSelect(date: Date) {
    this.model = date;
    this.previousFilterSelection = date;
    this.params.onDateChanged();
  }

  getDate(): Date {
    return this.model;
  }

  setDate(value: any) {
    if (this.keepSelectedFilters && !isNil(this.previousFilterSelection) && isNil(value)) {
      this.model = this.previousFilterSelection;
    } else {
      this.model = value;
    }
  }
}
