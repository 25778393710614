<a
  class="ssm-input d-flex justify-content-between align-items-center"
  [attr.id]="fieldName + 'NumberRangeDropdownFilterSelectedRangeText'"
  [ngClass]="{ 'has-focus': this.visible }"
  nz-dropdown
  nzTrigger="click"
  [nzBackdrop]="true"
  [nzDropdownMenu]="menu"
  [(nzVisible)]="visible"
  (nzVisibleChange)="onVisibleChange($event)"
>
  <span class="selected-range">
    <span
      *ngIf="!isNil(initialValueFrom) || !isNil(initialValueTo)"
      title="{{ initialValueFrom?.value }} &mdash; {{ initialValueTo?.value }}"
    >
      {{ initialValueFrom?.value }} &mdash; {{ initialValueTo?.value }}
    </span>
  </span>
  <span class="suffix">
    <span nz-icon nzType="down" nzTheme="outline"></span>
  </span>
</a>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <div [attr.id]="fieldName + 'NumberRangeDropdownFilterBody'" class="filter-body" [formGroup]="formGroup">
    <div class="filter-row">
      <span class="label">{{ 'filters.from' | translate }}:</span>
      <vpfa-field-container
        class="field-container"
        [isCurrency]="isCurrency"
        [isNumber]="true"
        [isText]="false"
        [displayErrors]="true"
        [control]="formGroup.controls.from"
        [maxWidth]="true"
        [errorMessages]="[
          {
            error: 'max',
            errorMsg: 'common.forms.maximumValue' | translate: { maxValue: maxValue },
          },
          {
            error: 'min',
            errorMsg: 'common.forms.minimumValue' | translate: { minValue: minValue },
          },
          {
            error: 'fromTo',
            errorMsg: 'filters.validation.fromMustBeLessOrEqualThanTo' | translate,
          },
        ]"
        [errorMsgVertPosition]="'forSelect'"
        [heightCompensationPx]="errorHeightCompensationPx"
      >
        <vpfa-select
          #selectFromElement
          [allowClear]="true"
          [attr.id]="fieldName + 'NumberRangeDropdownFilterSelectFrom'"
          [parentFormGroup]="formGroup"
          [fc]="formGroup.controls.from"
          [options]="this.options"
          [translateLabel]="true"
        ></vpfa-select>
      </vpfa-field-container>
    </div>

    <div class="filter-row">
      <span class="label">{{ 'filters.to' | translate }}:</span>
      <vpfa-field-container
        class="field-container"
        [isCurrency]="isCurrency"
        [isNumber]="true"
        [isText]="false"
        [displayErrors]="true"
        [control]="formGroup.controls.to"
        [maxWidth]="true"
        [errorMessages]="[
          {
            error: 'max',
            errorMsg: 'common.forms.maximumValue' | translate: { maxValue: maxValue },
          },
          {
            error: 'min',
            errorMsg: 'common.forms.minimumValue' | translate: { minValue: minValue },
          },
        ]"
        [errorMsgVertPosition]="'forSelect'"
        [heightCompensationPx]="errorHeightCompensationPx"
      >
        <vpfa-select
          #selectToElement
          [allowClear]="true"
          [attr.id]="fieldName + 'NumberRangeDropdownFilterSelectTo'"
          [parentFormGroup]="formGroup"
          [fc]="formGroup.controls.to"
          [options]="this.options"
          [translateLabel]="true"
        ></vpfa-select>
      </vpfa-field-container>
    </div>

    <vpfa-action-button
      [attr.id]="fieldName + 'NumberRangeDropdownFilterApplyBtn'"
      class="submit-button"
      [border]="false"
      [outline]="true"
      [size]="'small'"
      [textAlign]="'right'"
      [fullWidth]="false"
      (click)="onApplyFilters()"
    >
      {{ 'filters.applyRangeFilter' | translate }}
    </vpfa-action-button>
  </div>
</nz-dropdown-menu>
