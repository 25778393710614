import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromPermissions from './+state/permissions.reducer';
import { PermissionsEffects } from './+state/permissions.effects';
import { PermissionsFacade } from './+state/permissions.facade';
import { PermissionsGuard } from './guards/permissions.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PermissionsInterceptor } from './interceptors/permissions.interceptor';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromPermissions.permissionsFeatureKey, fromPermissions.reducer),
    EffectsModule.forFeature([PermissionsEffects]),
  ],
  providers: [
    PermissionsFacade,
    PermissionsGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PermissionsInterceptor,
      multi: true,
    },
  ],
})
export class PermissionsDataModule {
  constructor() {}
}
