import { Input, OnDestroy, OnInit, Component } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AdjustmentsFacade, CasesFacade } from '@vpfa/dealer/case/data';
import { ActivatedRoute, Router } from '@angular/router';
import { F_PRICE_MAX, PRICE_ZERO } from '@vpfa/shared/validators';

@Component({ template: '' })
export abstract class EquipmentSectionBase implements OnInit, OnDestroy {
  @Input() notAvailable: boolean;

  loaded$ = this.caseFacade.loaded$;

  additionalEquipment$ = this.adjustmentsFacade.additionalEquipmentDeduction$;
  missingEquipmentDeduction$ = this.adjustmentsFacade.missingEquipmentDeduction$;
  equipmentTotal$ = this.adjustmentsFacade.getTotalEquipmentDeduction$;
  isUpdatingAdjustments$ = this.adjustmentsFacade.isUpdatingAdjustments$;

  isLoadingValuationPrices$ = combineLatest([this.isUpdatingAdjustments$, this.caseFacade.loading$]).pipe(
    map(x => x.some(x => x === true))
  );

  protected missingEquipmentAmount: number = null;
  protected destroy$ = new Subject<void>();
  private caseId: string;

  constructor(
    protected fb: UntypedFormBuilder,
    protected caseFacade: CasesFacade,
    protected adjustmentsFacade: AdjustmentsFacade,
    protected router: Router,
    protected route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.caseFacade.activeCaseId$.pipe(takeUntil(this.destroy$)).subscribe(id => (this.caseId = id));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  goToEquipment() {
    this.router.navigate(['equipment'], { relativeTo: this.route });
  }
}
