import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'vpfa-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
  @Input() set isChecked(isChecked: boolean) {
    this.initialState = isChecked;
    this.formGroup.controls.control.setValue(isChecked);
  }

  @Input() set isDisabled(isDisabled: boolean) {
    if (isDisabled) {
      this.formGroup.controls.control.disable();
    } else {
      this.formGroup.controls.control.enable();
    }
  }

  @Input() sizeInPx = 12;

  /**
   * Reactive Form to control checkbox "checked" state.
   * Other methods like [ngModel] or [checked] cannot revert state from checked to unchecked and vice-versa.
   */
  formGroup = new UntypedFormGroup({
    control: new UntypedFormControl(false),
  });

  @Output() onChange = new EventEmitter<boolean>();

  private initialState = false;

  checkboxStateChanged(event: Event) {
    this.onChange.emit((event.target as HTMLInputElement).checked);
  }

  restoreStateToInitial() {
    this.formGroup.controls.control.reset(this.initialState);
  }
}
