import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  Authorize,
  AuthorizeError,
  AuthorizeSuccess,
  DenyAuthorize,
  fromOAuthActions,
  OAuthActionTypes,
} from './oauth.actions';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { OAuthAuthorizeParams } from '../models/oauth-authorize-params';
import { OAuthAuthorizationResultDto } from '../models/oauth-result-dto';
import { BasicNotificationsService } from '@vpfa/shared/notifications';
import { OAuthError } from '../constants/oauth-error';
import { isNil } from 'lodash';

@Injectable()
export class OAuthEffects {
  
  authorize$ = createEffect(() => this.actions$.pipe(
    ofType<Authorize>(OAuthActionTypes.Authorize),
    switchMap(action =>
      this.httpClient
        .get(`${environment.apiGateway}/api/oauth/authorize`, {
          params: this.toParams(action.payload),
        })
        .pipe(
          map((res: OAuthAuthorizationResultDto) => new fromOAuthActions.AuthorizeSuccess(res)),
          catchError((res: HttpErrorResponse) => of(new fromOAuthActions.AuthorizeError(res)))
        )
    )
  ));

  
  authorizeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<AuthorizeSuccess>(OAuthActionTypes.AuthorizeSuccess),
    tap(({ payload }) => {
      window.location.replace(decodeURIComponent(payload.redirect_uri));
    })
  ), { dispatch: false });

  
  denyAuthorize$ = createEffect(() => this.actions$.pipe(
    ofType<DenyAuthorize>(OAuthActionTypes.DenyAuthorize),
    tap(({ payload }) => {
      const sign = payload.redirectUrl.indexOf('?') === -1 ? '?' : '&';

      const queryParams = new URLSearchParams({
        error: OAuthError.AccessDenied,
        state: payload.state,
      }).toString();

      window.location.replace(`${payload.redirectUrl}${sign}${queryParams}`);
    })
  ), { dispatch: false });

  
  authorizeError$ = createEffect(() => this.actions$.pipe(
    ofType<AuthorizeError>(OAuthActionTypes.AuthorizeError),
    tap(({ payload }) => {
      const errorData = payload.error?.VpError ?? payload.error?.vpError;
      const codeName = errorData.codeName ?? errorData.CodeName;
      const redirectUri = payload.error.redirect_uri;

      if (codeName != "InvalidOAuthUri" && !isNil(redirectUri)) {
          window.location.replace(redirectUri);
      }

    })
  ), { dispatch: false });

  private toParams(input: OAuthAuthorizeParams): HttpParams {
    let params = new HttpParams();

    Object.entries(input).forEach(([key, value]) => {
      const result = Array.isArray(value) && value.length > 1 ? value.join(' ') : value;

      params = params.append(key, result);
    });

    return params;
  }

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private notifications: BasicNotificationsService
  ) {}
}
