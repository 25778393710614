<vpfa-mobile-subtitle
  id="price-summary-tooltip"
  [title]="'valuationResult.priceSummary' | translate"
  [tooltipContent]="setTooltipContent() | translate"
>
  <vpfa-inline-loader right *ngIf="isLoading"></vpfa-inline-loader>
</vpfa-mobile-subtitle>

<vpfa-forecast-info-box
  *ngIf="isForecastAvailable"
  [forecastDate]="forecastDate"
  [mobile]="true"
></vpfa-forecast-info-box>

<vpfa-not-available-info-box *ngIf="notAvailable" [mobile]="true">
  {{ 'caseValuationDetails.' + notAvailableMessage | translate }}
</vpfa-not-available-info-box>

<div class="tax-des">
  <vpfa-tax-information [tax]="activeCaseDataTaxes"></vpfa-tax-information>
</div>

<div class="box-container">
  <div class="main">
    <div [fxLayout]="'row'" [fxLayoutAlign]="'space-between flex-start'" [fxLayoutGap]="'5px'">
      <div [fxFlex] [fxLayout]="'row wrap'" [fxLayoutAlign]="'start center'">
        <span *ngIf="caseStatus === CaseStatus.DueInStock || caseStatus === CaseStatus.InStock" class="label">
          {{ priceLabels.askingPrice | translate }}
        </span>
        <span *ngIf="caseStatus === CaseStatus.Sold" class="label">{{ priceLabels.soldPrice | translate }}</span>
      </div>
      <vpfa-price-with-icon
        id="vehiclePrice"
        [attr.data-vehicle-price]="priceData?.price"
        (iconClicked)="onShowHistoricalPrices(priceData)"
        [hideIcon]="true"
        [price]="
          priceData.price !== null ? (priceData.price | currencyLocale: null:null:'1.2-2' | lT) : priceData.price
        "
      ></vpfa-price-with-icon>
    </div>
  </div>
</div>

<div fxLayout="column" fxLayoutGap="12px">
  <ng-container *ngIf="!hidePriceDetails">
    <vpfa-mobile-action-button [disabled]="notAvailable || isLoading" (click)="openPriceDetails.emit()">
      <vpfa-mobile-price-button-content
        id="price-details-button"
        [label]="'priceDetails.title' | translate"
        [showValue]="false"
        [notAvailable]="notAvailable"
      ></vpfa-mobile-price-button-content>
    </vpfa-mobile-action-button>
  </ng-container>

  <vpfa-mobile-action-button id="offer-history-button" (click)="goToOfferHistory()" *ngIf="!isSold">
    {{ 'stockPrices.offerHistory' | translate }}
  </vpfa-mobile-action-button>
</div>
