import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsoDatePickerComponent } from './components/iso-date-picker/iso-date-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { SharedMobileViewModule } from '@vpfa/shared/mobile-view';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzDatePickerModule,
    FlexLayoutModule,
    SharedMobileViewModule,
  ],
  declarations: [IsoDatePickerComponent],
  exports: [IsoDatePickerComponent],
})
export class UiKitIsoDatePickerModule {}
