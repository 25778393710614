import {
  EmissionNEDC,
  EmissionWLTP,
  UnitRangeValueDto,
  UnitTextValueDto,
  UnitValueDto,
} from '@vpfa/rest-api/valuation';
import { AbstractControl } from '@angular/forms';
import { isNil } from 'lodash';

export const MAX_TECHNICAL_DATA_VALUE = 999.9;
export const MAX_EV_RANGE_AND_CONSUMPTION_DATA_VALUE = 999999.9;
export const MAX_CO2_EMISSION_VALUE = 999;
export const MAX_FRACTION_NUMBER = 1;
export const MAX_TECHNICAL_DATA_VALUE_LENGTH = 9;
export const intervalSign = '-';

export const getUnitValueOrNull = (
  control: AbstractControl,
  group: EmissionWLTP | EmissionNEDC,
  field: UnitValueDto | UnitTextValueDto
) => {
  return !isNil(control.value) || (group && field)
    ? {
        value: control.value,
        unit: group ? (field ? field.unit : '-') : '-',
      }
    : null;
};

export const getUnitRangeValueOrNull = (
  control: AbstractControl,
  group: EmissionWLTP | EmissionNEDC,
  field: UnitRangeValueDto
): UnitRangeValueDto => {
  const unitRange: UnitRangeValueDto = {
    value: null,
    valueMax: null,
    unit: group ? (field ? field.unit : null) : null,
  };

  if (!isNil(control?.value)) {
    unitRange.value = Math.round(Number(control.value) * 10) / 10;
    if (String(control.value).includes(intervalSign)) {
      const splitValue = String(control.value).split(intervalSign);
      unitRange.value = Number(splitValue[0]);
      unitRange.valueMax = Number(splitValue[1]);
    }
  }
  return unitRange;
};

export const getUnitRangeValueOrNullValue = (
  control: AbstractControl,
  group: EmissionWLTP | EmissionNEDC,
  field: UnitRangeValueDto
): UnitRangeValueDto | null => {
  if (isNil(control?.value)) {
    return null;
  }

  return getUnitRangeValueOrNull(control, group, field);
};
