/**
 * Public SSM API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0-private
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
 

export enum EquipmentError {

      IncompatibleEquipment = 0,
      CannotDeselectPartOfPackageEquipment = 1,
      DisabledContentEquipmentOfEnabledPackage = 2,
      EnabledContentEquipmentOfDisabledPackage = 3,
      EnabledFormulaEquipmentOfDisabledPackage = 4,
      UnmetFormulaOfEnabledEquipment = 5,
      AgainstFormulaOfAlreadyEnabledEquipment = 6,
      UnmetSingleElementFormulaOfEnabledEquipment = 7,
      EnabledFormulaWithDisabledEquipment = 8,
      EnabledContentEquipmentOfCombinationPriceDisabledPackage = 9,
      StandardEquipmentIncompatibility = 10,

};
