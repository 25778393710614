import { Component, EventEmitter } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { isNil, get, isFunction } from 'lodash';
import { DataTableActionEvent, DataTableAction } from '../../models/data-table-action';

export interface IconActionParams {
  iconName: string;
  actionName?: string;
  isDisabled?: (rowData) => boolean;
  question?: string;
}

@Component({
  selector: 'vpfa-icon-action-renderer',
  templateUrl: './icon-action-renderer.component.html',
  styleUrls: ['./icon-action-renderer.component.scss']
})
export class IconActionRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  get colDefParams(): IconActionParams {
    return get(this.params, ['colDef', 'cellRendererParams'], {});
  }

  get actionName() {
    return get(this.colDefParams,'actionName');
  }

  get actionEmitter(): EventEmitter<DataTableActionEvent> {
    return get(this.params, ['context', 'componentParent', 'actionClicked']);
  } 

  get iconName() {
    return this.colDefParams.iconName ? `zmdi-${this.colDefParams.iconName}` : null;
  }

  get question() {
    return get(this.colDefParams, 'question');
  }

  isDisabled(): boolean {
    const disableFn = get(this.colDefParams, 'isDisabled');
    if(!isFunction(disableFn)){
      return false;
    }

    return disableFn(this.params.data)
  }

  errorInfo(): string {
    const errorFn = get(this.colDefParams, 'errorInfo') as Function;
    if(!isFunction(errorFn) || isNil(errorFn)){
      return;
    }

    return errorFn(this.params.data)
  }

  onIconClick() {
    const actionEmitter = this.actionEmitter;
    if (isNil(actionEmitter)) {
      return;
    }

    actionEmitter.emit({
      action: this.actionName || DataTableAction.OTHER,
      data: this.params.data
    });
    return false;
  }
}
